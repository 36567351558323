import React from 'react';
import { connect } from 'react-redux';
import {siwtchTransactionType} from '../../../../helpers/helpers'

class AuditExchange extends React.PureComponent {

    dateParse = (date) => {

        const language = navigator.language || 'en'
        const options = {year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' };
        const parseDate = new Date(date);
        const showDate = parseDate.toLocaleDateString(language,options)

        return showDate

    }

    getLabel = (label) => {

        switch(label) {
            case 'base_currency': {
                return 'Base currency'
            }
            case 'quote_currency': {
                return 'Quote currency'
            }
            case 'fee_currency': {
                return 'Fee currency'
            }
            case 'amount': {
                return 'Amount'
            }
            case 'unit_price': {
                return 'Unit Price'
            }
            case 'user_transaction': {
                return 'User'
            }
            case 'transaction_description': {
                return 'Transaction type'
            }
            case 'fee': {
                return 'Fee'
            }
            case 'date': {
                return 'Date'
            }
            case 'destination': {
                return 'Exchange / Wallet'
            }
            case 'name': {
                return 'Name'
            }
            case 'portfolio_ownership': {
                return 'Type of portfolio'
            }
            case 'currency': {
                return 'Currency'
            }
            case 'wallet_coin': {
                return 'Wallet coin'
            }
            case 'address': {
                return 'Wallet address'
            }
            case 'exchange_name': {
                return 'Exchange'
            }
            case 'account_name': {
                return 'Account'
            }
            case 'ex_ex_name': {
                return 'Exchange'
            }
            case 'ex_acc_name': {
                return 'Account'
            }
            default: 
                return null
        }

    }
    handleCompare = (key, newData, oldData) => {

        let old_value = null;
        let new_value = null;

        try { 

            ['base_currency', 'fee_currency','quote_currency'].includes(key) ? 
                new_value = newData[key].name

            :   
            key === 'user_transaction' ? 
                new_value = `${newData[key].first_name} ${newData[key].last_name}`
            :
            key === 'destination' ?

                newData['account'] === null ? 

                    newData['storage'] === null ? 
                        new_value = null 
                    :
                    new_value = newData['storage'].name

                :

                    new_value = newData['account'].name 
            :
            key === 'date' ?
                new_value = this.dateParse(newData[key])
            :

            key === 'transaction_description'?
                new_value = siwtchTransactionType(newData[key])[0]
            :

            key === 'wallet_coin'?

                new_value = newData['coin'].name

            :

            key === 'exchange_name'?

                newData['exchange'].custom_exchange_check === true ?

                    new_value = newData['exchange'].custom_exchange 

                :
                    newData['exchange'].exchange !== null ? 
                        new_value = newData['exchange'].exchange.name 
                    :
                        new_value = null


            :

            key === 'ex_ex_name'?

                newData.custom_exchange_check === true ?

                    new_value = newData.custom_exchange 

                :
                    newData.exchange !== null ? 
                        new_value = newData.exchange.name 
                    :
                        new_value = null


            :

            key === 'ex_acc_name'?

                new_value = newData.name

            :

            key === 'account_name'?

                new_value = newData['exchange'].name

            :

                new_value = newData[key]

        }
        catch(e)
        {

        }

        try { 

            ['base_currency', 'fee_currency','quote_currency'].includes(key) ? 
                old_value = oldData[key].name

            :   
            key === 'user_transaction' ? 
                old_value = `${oldData[key].first_name} ${oldData[key].last_name}`
            :
            key === 'destination' ?

                oldData['account'] === null ? 

                    oldData['storage'] === null ? 
                        old_value = null 
                    :
                    old_value = oldData['storage'].name

                :

                    old_value = oldData['account'].name
            :

            key === 'date' ?
                old_value = this.dateParse(oldData[key])
            :
            key === 'transaction_description'?
                old_value = siwtchTransactionType(oldData[key])[0]
            :
            key === 'wallet_coin'?

                old_value = oldData['coin'].name

            :

            key === 'exchange_name'?

                oldData['exchange'].custom_exchange_check === true ?

                    old_value = oldData['exchange'].custom_exchange 

                :
                    oldData['exchange'].exchange !== null ? 
                        old_value = oldData['exchange'].exchange.name 
                    :
                        old_value = null


            :

            key === 'ex_ex_name'?

                oldData.custom_exchange_check === true ?

                    old_value = oldData.custom_exchange 

                :
                    oldData.exchange !== null ? 
                        old_value = oldData.exchange.name 
                    :
                        old_value = null


            :

            key === 'ex_acc_name'?

                old_value = oldData.name

            :

            key === 'account_name'?

                old_value = oldData['exchange'].name

            :

                old_value = oldData[key]

        }
        catch(e)
        {

        }

        let change = false 
        if (old_value !== null) {
            change = old_value !== new_value
        }

        return [old_value, new_value, change]

    }

    render () {

        const new_scope = this.props.data.new_data
        const old_scope = this.props.data.old_data

        return (
            
            <div style={{width: '100%'}}>
            
            {
                new_scope !== null ? 

                    <div>
                    <div style={{marginBottom: 24, display: 'flex', fontWeight: 900, color: '#0078DB'}} className="basier-p4-caps">
                        <div style={{width: 300, paddingLeft: 12,}}>Field</div>
                        {old_scope !== null && old_scope !== undefined ? 
                        <div style={{width: 300}}>
                            Old value (before edit)
                        </div>
                        :
                        null
                        }
                        <div style={{width: 300}}>
                            {old_scope !== null && old_scope !== undefined ? 
                                'New value (after edit)'
                                :
                                'Values'
                            }
                            </div>
                    </div>
                    {this.props.data.scope === 'transaction' ? 

                        ['date', 'user_transaction', 'transaction_description', 'base_currency', 'quote_currency', 'amount', 'unit_price', 'fee', 'fee_currency', 'destination'].map((val, key) => {
                            
                            const line_data = this.handleCompare(val, new_scope, old_scope)

                            return (
                            
                            
                            <div key={key} style={{display: 'flex', width: '100%', alignItems: 'center', height: 35, marginTop: 4, marginBottom: 4, backgroundColor: line_data[2] ? 'rgba(249, 240, 125, 0.5)' : 'transparent'}}>

                                <div style={{paddingLeft: 12, width: 300, fontWeight: 900}} className="basier-p4-caps">
                                    {this.getLabel(val)}
                                </div>
                                {old_scope !== null && old_scope !== undefined ? 
                                <div style={{width: 300, }}>
                                    {line_data[0]}
                                </div>
                                :
                                null
                                }
                                <div style={{width: 300, }}>{line_data[1]}</div>

                            </div>

                            )

                        })
                    
                        :

                        this.props.data.scope === 'portfolio' ? 

                        ['name', 'currency', 'portfolio_ownership'].map((val, key) => {
                            
                            const line_data = this.handleCompare(val, new_scope, old_scope)

                            return (
                            
                            
                            <div key={key} style={{display: 'flex', width: '100%', alignItems: 'center', height: 35, marginTop: 4, marginBottom: 4, backgroundColor: line_data[2] ? 'rgba(249, 240, 125, 0.5)' : 'transparent'}}>

                                <div style={{paddingLeft: 12, width: 300, fontWeight: 900}} className="basier-p4-caps">
                                    {this.getLabel(val)}
                                </div>
                                {old_scope !== null && old_scope !== undefined ? 
                                <div style={{width: 300, }}>
                                    {line_data[0]}
                                </div>
                                :
                                null
                                }
                                <div style={{width: 300, }}>{line_data[1]}</div>

                            </div>

                            )

                        })

                        :

                        this.props.data.scope === 'wallet' ? 

                            new_scope.wallet_type === 'storage' ?

                        
                            ['name', 'wallet_coin', 'address'].map((val, key) => {
                                
                                const line_data = this.handleCompare(val, new_scope, old_scope)

                                return (
                                
                                
                                <div key={key} style={{display: 'flex', width: '100%', alignItems: 'center', height: 35, marginTop: 4, marginBottom: 4, backgroundColor: line_data[2] ? 'rgba(249, 240, 125, 0.5)' : 'transparent'}}>

                                    <div style={{paddingLeft: 12, width: 300, fontWeight: 900}} className="basier-p4-caps">
                                        {this.getLabel(val)}
                                    </div>
                                    {old_scope !== null && old_scope !== undefined ? 
                                    <div style={{width: 300, }}>
                                        {line_data[0]}
                                    </div>
                                    :
                                    null
                                    }
                                    <div style={{width: 300, textTransform: 'uppercase' }}>{line_data[1]}</div>

                                </div>

                                )

                            })

                            :

                            new_scope.wallet_type === 'exchange' ?

                            
                                ['exchange_name', 'account_name', 'wallet_coin', 'address'].map((val, key) => {
                                    
                                    const line_data = this.handleCompare(val, new_scope, old_scope)

                                    return (
                                    
                                    
                                    <div key={key} style={{display: 'flex', width: '100%', alignItems: 'center', height: 35, marginTop: 4, marginBottom: 4, backgroundColor: line_data[2] ? 'rgba(249, 240, 125, 0.5)' : 'transparent'}}>

                                        <div style={{paddingLeft: 12, width: 300, fontWeight: 900}} className="basier-p4-caps">
                                            {this.getLabel(val)}
                                        </div>
                                        {old_scope !== null && old_scope !== undefined ? 
                                        <div style={{width: 300, }}>
                                            {line_data[0]}
                                        </div>
                                        :
                                        null
                                        }
                                        <div style={{width: 300, textTransform: 'uppercase' }}>{line_data[1]}</div>

                                    </div>

                                    )

                                })

                                :

                                null

                        :

                        this.props.data.scope === 'exchange' ? 

                        ['ex_ex_name', 'ex_acc_name'].map((val, key) => {
                            
                            const line_data = this.handleCompare(val, new_scope, old_scope)

                            return (
                            
                            
                            <div key={key} style={{display: 'flex', width: '100%', alignItems: 'center', height: 35, marginTop: 4, marginBottom: 4, backgroundColor: line_data[2] ? 'rgba(249, 240, 125, 0.5)' : 'transparent'}}>

                                <div style={{paddingLeft: 12, width: 300, fontWeight: 900}} className="basier-p4-caps">
                                    {this.getLabel(val)}
                                </div>
                                {old_scope !== null && old_scope !== undefined ? 
                                <div style={{width: 300, }}>
                                    {line_data[0]}
                                </div>
                                :
                                null
                                }
                                <div style={{width: 300, textTransform: 'uppercase' }}>{line_data[1]}</div>

                            </div>

                            )

                        })

                        :

                        null
                    }
                    </div>
                    
                :   
                <div style={{width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', fontStyle: 'italic', textAlign: 'center', marginTop: 120}}>This object has been deleted</div>

            }
            </div>
        )
    }


}

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = dispatch => {
    return {
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(AuditExchange)
