import React from 'react';
import Select, { components }  from 'react-select';
import { connect } from 'react-redux';


const colourStyles = {
  control: styles => ({ ...styles, backgroundColor: 'transparent', width: '100%', fontSize: 12, border: '0px', color: 'black', cursor: 'text', dispay:'inline-block'}),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {

    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected ? 'white' : isFocused ? '#F3F5F6' : null,
      color: isDisabled
        ? '#F3F5F6'
        : isSelected ? 'black' : 'black',
      opacity: isDisabled
        ? '1'
        : isSelected ? '1' : '0.4',
    };
  },

};


class MenuSelector extends React.Component {

  constructor(props) {
    super(props)

    const textName = this.props.selected
    this.state = {
      selectedOption: {value: this.props.selected, label: textName.toUpperCase()}
    }
  }
  

  componentDidMount() {
    

  }


  handleChange = (selectedOption) => {

    if (selectedOption.value !== this.state.selectedOption.value) {

      this.setState({ selectedOption });
      this.props.changeType(selectedOption.value)


    }
    
  }

    options = this.props.roleList

  render() {
    const { selectedOption } = this.state;

    return (
      <Select
        value={selectedOption}
        onChange={this.handleChange}
        options={this.options}
        isSearchable={true}
        styles={colourStyles}
        maxMenuHeight={210}
        minMenuHeight={50}
        components={{  }}
        classNamePrefix="react-select-roles"
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
          ...theme.colors,
            primary25: '#F3F5F6',
            primary: '#F3F5F6',
          },
        })}
      />
    );
  }
}

const mapStateToProps = state => {
  return {

  };
};

const mapDispatchToProps = dispatch => {
  return {
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MenuSelector);
