import React from "react";
import Grid from "@material-ui/core/Grid";
import seeMore from "../../../../../Media/seeMore.svg";
import warning from "../../../../../Media/warning.svg";
import currencyDict from "../../../.././../helpers/currency.json";
import Tooltip from "../../../../AppWide/Tooltip";
import CustomContextMenu from "../../../../AppWide/customContextMenu";

class assetDetail extends React.PureComponent {
  state = {
    mouseX: null,
    mouseY: null,
    menuOpen: false,
  };
  onClick = (e) => {
    e.preventDefault();
    e.persist();

    this.setState({
      mouseX: null,
      mouseY: null,
      menuOpen: false,
    });

    setTimeout(() => {
      this.setState({
        mouseX: e.clientX - 2,
        mouseY: e.clientY - 4,
        menuOpen: true,
      });
    }, 1);
  };
  handleClose = () => {
    this.setState({
      mouseX: null,
      mouseY: null,
      menuOpen: false,
    });
  };

  render() {
    let proportion = 0;
    try {
        proportion = Math.max(this.props.item.total_value / this.props.total_value, 0);
        proportion = Math.min(proportion, 1);
    } catch (e) {}
    return (
      <Grid
        onContextMenu={(e) => {
          this.props.openContext(e);
        }}
        container
        style={{
          height: 60,
          paddingTop: 8,
          paddingBottom: 8,
          display: "flex",
          alignItems: "center",
          paddingLeft: 6,
          paddingRight: 6,
          width: "100%",
          borderBottom: "1px solid #CCC",
        }}
        index={this.props.index}
        className="basier-p3 light-hover"
      >
        <Grid
          item
          style={{ width: "28%", display: "flex", alignItems: "center" }}
        >
          {this.props.item.image_large !== "" &&
          this.props.item.image_large !== null ? (
            <span>
              <img
                style={{ height: 25, width: 25, marginRight: 12 }}
                src={this.props.item.image_large}
                alt=""
              />
            </span>
          ) : (
            currencyDict[this.props.item.coin_symbol] && currencyDict[this.props.item.coin_symbol]["symbol"].length > 1 ?
            <span
              style={{
                height: 25,
                width: 25,
                marginRight: 12,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: 20,
              }}
            >
              {currencyDict[this.props.item.coin_symbol]["symbol"]}
            </span>
            :
            <div
              style={{
                minHeight: 35,
                minWidth: 35,
                marginRight: 12,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: 14,
                backgroundColor: '#E1E5E9', 
                borderRadius: 40, 
                border: '1px solid #6A7E93',
              }}
            >
              {this.props.item.coin_symbol.slice(0,1)}
            </div>            
          )}

          <span className="truncate" style={{width: '100%'}}>
            <div style={{ marginTop: 2, marginBottom: 2 }}>
              {this.props.item.coin_symbol}
            </div>
            <div
              className="basier-p4-caps white-hover truncate"
              style={{ color: "#6A7E93" }}
            >
              {this.props.item.short_name}
            </div>
          </span>
        </Grid>

        <Grid item style={{ width: "13%", textAlign: "left" }}>
          <div
            className="percent-container"
            style={{ display: "inline-block", position: "relative" }}
          >
            <div
              className="percent-amount"
              style={{
                display: "inline-block",
                position: "absolute",
                left: 0,
                width: `${proportion * 100}%`,
              }}
            />
          </div>
        </Grid>

        <Grid item style={{ width: "16%", textAlign: "right" }}>
          <div
            style={{
              paddingTop: 4,
              marginBottom: 2,
            }}
          >
            {this.props.item.error && (
              <span>
                <Tooltip
                  title={`You have sold or used ${
                    this.props.item.oversold > 1
                      ? this.props.item.oversold.toLocaleString("en", {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2,
                        })
                      : this.props.item.oversold <= 1
                      ? this.props.item.oversold.toLocaleString("en", {
                          maximumFractionDigits: 8,
                          minimumFractionDigits: 8,
                        })
                      : ""
                  } more ${
                    this.props.item.coin_symbol
                  } than you own, your actual balance should hence be different. Please go to the portfolio settings to find out more about transaction errors and fix them.`}
                  aria-label="read"
                >
                  <img
                    src={warning}
                    alt=""
                    style={{ height: 20, marginRight: 12 }}
                  />
                </Tooltip>
              </span>
            )}
            <span>
              {this.props.errors === true
                ? this.props.item.balance == 0
                  ? (-1 * this.props.item.oversold).toLocaleString("en", {
                      maximumFractionDigits: 8,
                      minimumFractionDigits: 8,
                    })
                  : this.props.item.balance < -1
                  ? this.props.item.balance.toLocaleString("en", {
                      maximumFractionDigits: 4,
                      minimumFractionDigits: 4,
                    })
                  : this.props.item.balance.toLocaleString("en", {
                      maximumFractionDigits: 8,
                      minimumFractionDigits: 8,
                    })
                : this.props.item.balance > 1
                ? this.props.item.balance.toLocaleString("en", {
                    maximumFractionDigits: 4,
                    minimumFractionDigits: 4,
                  })
                : this.props.item.balance.toLocaleString("en", {
                    maximumFractionDigits: 8,
                    minimumFractionDigits: 8,
                  })}
            </span>
          </div>
        </Grid>

        <Grid item style={{ width: "16%", textAlign: "right" }}>
          <span>
            <div style={{ paddingTop: 4, marginBottom: 2 }}>
              {this.props.currency_symbol}{" "}
              {this.props.item.total_value.toLocaleString("en", {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              })}
            </div>
          </span>
        </Grid>
        <Grid item style={{ width: "16%", textAlign: "right" }}>
          <div style={{ paddingTop: 4, marginBottom: 2 }}>
            {this.props.currency_symbol}&nbsp;
            {this.props.item.current_price > 1
              ? this.props.item.current_price.toLocaleString("en", {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                })
              : this.props.item.current_price.toLocaleString("en", {
                  maximumFractionDigits: 6,
                  minimumFractionDigits: 6,
                })}
          </div>

          <div
            className="basier-p4 white-hover"
            style={{
              width: 65,
              fontStyle: "italic",
              display: "inline-block",
              color: `${
                this.props.item.change_24h > 0
                  ? "#4CDAC1"
                  : this.props.item.change_24h < 0
                  ? "#F9716B"
                  : "#6A7E93"
              }`,
            }}
          >
            {this.props.item.asset_type === "crypto"
              ? `${
                  this.props.item.change_24h > 0 ? "+" : ""
                }${this.props.item.change_24h.toLocaleString("en", {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                })}%`
              : "-"}
          </div>
        </Grid>

        <Grid item style={{ width: "10%", textAlign: "right" }}>
          <div
            style={{
              borderRadius: 35,
              height: 27,
              textAlign: "right",
            }}
            className={`roi-container ${
              this.props.status === "exit"
                ? "neutral-bk"
                : this.props.roi >= 1
                ? "green-bk"
                : this.props.roi < 1
                ? "red-bk"
                : "neutral-bk"
            }`}
          >
            {this.props.status === "exit"
              ? "-"
              : `${this.props.roi.toLocaleString("en", {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                })}x`}
          </div>
        </Grid>
        <div
          value={this.props.item.coin}
          onClick={this.props.handleModalOpen}
          className="see-more-icon"
          style={{
            position: "absolute",
            right: `${this.props.contained ? "-5px" : "55px"}`,
          }}
        >
          <img src={seeMore} alt="" />
        </div>
        {this.props.menuItems !== undefined && (
          <CustomContextMenu
            selected={this.props.item}
            menuOpen={this.props.menuOpen === this.props.item.coin}
            menuItems={this.props.menuItems}
            mouseX={this.props.mouseX}
            mouseY={this.props.mouseY}
            handleClose={() => this.props.handleClose()}
          />
        )}
      </Grid>
    );
  }
}

export default assetDetail;
