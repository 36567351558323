import React from "react";
import { FixedSizeList as List } from "react-window";
import CloseIcon from '../../Media/CloseIcon.svg'
import Port1 from '../../Media/Portfolio-1.svg'
import Port2 from '../../Media/Portfolio-2.svg'
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Divider from '@material-ui/core/Divider';


class PortflioFlatList extends React.PureComponent {


    hanleMenuChoose = (val) => {
        let toGo = val
        if (val === 'support') {
            window.open("https://support.wisly.io", "_blank")
            return
        }
        if (val === 'audit_trail') {
            toGo = 'settings/audit'
        } 
        this.props.history.push(`/${toGo}`)
        this.props.closeAction()
    }

    componentDidMount() {
        document.body.ontouchmove = (e) => { 
          e.preventDefault(); 
          return false;
        }
    }

    componentDidUnmount() {
        document.body.ontouchmove = (e) => { 
            return true;
        }
      }

    componentDidUpdate() {
        document.body.ontouchmove = (e) => { 
            return true;
        }
    }


      render() {

        return (
            
            <div className="filter-list">

                <div style={{backgroundColor: '#F8F9FA', 'boxShadow': 'none', height: 75}}>
                    <Grid
                        container
                        direction="row"
                        justify="space-between"
                        alignItems="center"
                    > 
            
                    <Grid  style={{zIndex: 1500}} className="over-all">
                    <img style={{marginLeft: '12px', paddingTop: '12px'}} src={CloseIcon} alt="" onClick={this.props.closeAction}/> 
                    </Grid>
                    <Grid  style={{zIndex: 1500, textAlign: 'centre', color: 'black', marginLeft: -24}} className="over-all basier-p2">
                        Additional menu
                    </Grid>
                    <Grid style={{zIndex: 1500}} className="over-all">
                    <div style={{marginLeft: 24}} className="basier-p2" onClick={this.clearInput}></div>
                    </Grid>

                </Grid>

                </div>
                
                <div style={{marginTop: 0}}>

                    {['wallets', 'audit_trail', 'invitations', 'watchlist', 'support'].map((val, key) => {
                        
                        const displayName = val.replace("_", " ")
                        return (

                            <div 
                                key={key}
                                style={{ 
                                height: 60,
                                paddingLeft: 24, 
                                paddingRight: 24, 
                                width: '100%',
                                }} value={val}
                                className="coin-flat-list-item basier-p2"
                                onClick={() => this.hanleMenuChoose(val)}>
                        
                        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%',}}>
                            <div>
                                <div style={{color: 'black', textTransform: 'capitalize'}} className="basier-p2">{displayName}</div>
                            </div>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0.999999 0.856573L7 6.85413L1 12.8541" stroke="#000000" stroke-miterlimit="10"/>
                                </svg>
                            </div>
    
                        </div>
    
                    
                    </div>

                        )


                    })}
                    


            </div>
        </div>

        )
      }

}


const mapStateToProps = state => {
    return {
    };
  };
  
  
  export default connect(
    mapStateToProps,
    null
  )(withRouter(PortflioFlatList));
