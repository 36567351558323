import React from "react";
import { formattedNumber } from "../../../helpers/helpers";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import * as actions from "../../../store/actions/data";
import * as interactionActions from "../../../store/actions/interactions";
import * as authActions from "../../../store/actions/auth";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import currencyData from "../../../helpers/currency.json";


const styles = theme => ({
  root: {
    color: "#6A7E93",
    "&$checked": {
      color: "#0078DB",
    },
  },
  checked: {
    color: "#0078DB",
  },
  rootCheck: {
    width: 200,
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
    border: 0,
	  background: '#FFFFFF',
	  borderRadius: 20,
	  color: '#0078DB'
  },
  smallColumn: {
    alignItems: "center",
    justifyContent: "space-between",
    paddingRight: 24,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      paddingLeft: 12,
    },
  },
  smallMargin: {
    [theme.breakpoints.down('sm')]: {
      marginTop: 4, 
      marginBottom: 4,
    },
  }
  });


class TopSectionOverview extends React.PureComponent {
  componentDidMount() {}

  handleBTCChange = (e) => {
    const new_scope = e.target.value;
    //console.log(`new scope is ${new_scope}`)
    const globalData = {};

    this.props.setPfScope(new_scope);
    this.props.componentDataFetch(
      "update",
      this.props.currentView,
      this.props.currency,
      this.props.user,
      this.props.portfolioId,
      globalData,
      globalData,
      e.target.value,
      this.props.token
    );
    if (new_scope === "bitcoin") {
      this.props.setBtcSymbol("₿");
    } else {
      let currency_symbol = null;
      try {
        currency_symbol = currencyData[this.props.currency_name]["symbol"];
      } catch (e) {
        currency_symbol = this.props.currency_name;
      }
      this.props.setBtcSymbol(currency_symbol);
    }
  };

  render() {
    // const symbol_to_use =
    //   this.props.coinGraph === true
    //     ? "$"
    //     : this.props.currencyScope === "bitcoin"
    //     ? "₿"
    //     : this.props.currency_symbol;
    const { classes } = this.props;
    // let positionsProfit = null; 
    // try {
    //     positionsProfit = this.props.accountPositions.open_profit
    //     if (positionsProfit === undefined || positionsProfit === null || isNaN(positionsProfit)) {
    //         positionsProfit = null
    //     }
    // } catch(e) {}

    // let spotValue = null; 
    // try {
    //     spotValue = this.props.totalValue
    //     if (spotValue === undefined || spotValue === null || isNaN(spotValue)) {
    //         spotValue = null
    //     }
    // } catch(e) {}

    // let totalValue = null; 
    // try {
    //     totalValue = positionsProfit + spotValue
    //     if (totalValue === undefined || totalValue === null || isNaN(totalValue)) {
    //         totalValue = null
    //     }
    // } catch(e) {}



    return (
      <div>
        <div className="basier-p4-caps" style={{marginLeft: 6}}>Display currency</div>
        <span
          className={classes.rootCheck}
          style={{
            marginTop: 4,
            marginBottom: 12,
            height: 42,
            paddingLeft: 0,
            display: "inline-flex",
            justifyContent: "space-around",
            alignItems: "center",
            border: '1px solid #E1E5E94D',
            boxShadow: '0px 3px 3px rgba(225, 229, 233, 0.3)'
          }}
        >
          <div className="form-check form-check-inline sp-l-12">
            <RadioGroup
              name="btcScope"
              value={this.props.inBitcoin}
              onChange={this.handleBTCChange}
              style={{ display: "inline-block" }}
            >
              <FormControlLabel
                value="fiat"
                control={
                  <Radio
                    classes={{
                      root: classes.root,
                      checked: classes.checked,
                    }}
                  />
                }
                label={this.props.currency_name}
              />
              <FormControlLabel
                value="bitcoin"
                control={
                  <Radio
                    classes={{
                      root: classes.root,
                      checked: classes.checked,
                    }}
                  />
                }
                label="BTC"
              />
            </RadioGroup>
          </div>
        </span>

        {/* <div
          className={classes.smallColumn}
          style={{
            display: "flex",
          }}
        >
            <div className={classes.smallMargin}>
              <div
                className="basier-p4-caps"
                style={{ color: "#6A7E93", display: "inline-block" }}
              >
                Total <span style={{color: 'black', fontWeight: 900}}>Spot</span> Value
              </div>
              <br />
              <h2 style={{ display: "inline-block" }}>
                {symbol_to_use}
                {formattedNumber(spotValue)}
              </h2>
            </div>

            <div className={classes.smallMargin}>
              <div
                className="basier-p4-caps"
                style={{ color: "#6A7E93", display: "inline-block" }}
              >
                Open <span style={{color: '#C174FD', fontWeight: 900}}>Position</span> Profit
              </div>
              <br />
              <h2 style={{ display: "inline-block" }}>
                {symbol_to_use}
                {formattedNumber(positionsProfit)}
              </h2>
            </div>

            <div className={classes.smallMargin}>
              <div
                className="basier-p4-caps"
                style={{ color: "#6A7E93", display: "inline-block" }}
              >
                Total <span style={{color: '#0078DB', fontWeight: 900}}>Account</span> Value
              </div>
              <br />
              <h2 style={{ display: "inline-block" }}>
                {symbol_to_use}
                {formattedNumber(totalValue)}
              </h2>
            </div>
        </div> */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currency: state.auth.currency.id,
    user: state.auth.user_id,
    inBitcoin: state.data.bitcoinPf,
    token: state.auth.token,
    currentView: state.data.currentView,
    currency_name: state.auth.currency.id,
    currency_symbol: state.auth.currency.symbol,
    accountPositions: state.data.accountPositions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    componentDataFetch: (
      scope,
      view,
      currency,
      user,
      portfolio,
      globalGraphData,
      portfolioGraphData,
      inBitcoin,
      token
    ) =>
      dispatch(
        actions.componentDataFetch(
          scope,
          view,
          currency,
          user,
          portfolio,
          globalGraphData,
          portfolioGraphData,
          inBitcoin,
          token
        )
      ),
    setMenuPortfolio: (path) => dispatch(actions.setMenuPortfolio(path)),
    updateTime: (time) => dispatch(actions.setGlobalTime(time)),
    updateRequestTime: (time) =>
      dispatch(interactionActions.updateRequestTime(time)),
    setCurrentView: (view) => dispatch(actions.setCurrentView(view)),
    setGraphScope: (currency, scope) =>
      dispatch(actions.setGraphScope(currency, scope)),
    setPfScope: (currency) => dispatch(actions.setPfScope(currency)),
    toggleModal: (data) => dispatch(interactionActions.toggleModal(data)),
    setBtcSymbol: (s) => dispatch(authActions.setBtcSymbol(s)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(TopSectionOverview));
