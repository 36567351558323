import React from "react";
import { connect } from "react-redux";
import Plus from "../../../Media/Plus.svg";

class TopSection extends React.PureComponent {
  render() {
    let limited;
    try {
      limited =
        this.props.limits.transactions > this.props.limits.transaction_limit &&
        this.props.limits.transaction_limit != -1
    } catch (e) {
      limited = undefined;
    }

    return (
      <div style={{ paddingLeft: 24, paddingRight: 24, display: "flex" }}>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <h3 style={{ margin: 0 }}>Accounts & Wallets</h3>
              <div
                onClick={() => this.props.openModal("new_wallet")}
                className="basier-p4-caps opacity-hover"
                style={{
                  userSelect: "none",
                  color: "black",
                  marginLeft: 18,
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div>
                  <img
                    src={Plus}
                    alt=""
                    style={{ marginRight: 12, height: 24, width: 24 }}
                  />
                </div>
                <div style={{ paddingTop: 0 }}>Add or connect new account</div>
              </div>
            </div>
            {limited && 
            <div style={{ fontWeight: "bold", color: "red", marginTop: 12 }}>
              Your account limits have been exceeded. All automatic API imports
              have been paused.
            </div>
            }
          </div>

          <div>Address book</div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    limits: state.appwide.limits,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(TopSection);
