import React from "react";
import { connect } from "react-redux";
import * as interActions from "../../store/actions/interactions";
import { withRouter } from "react-router-dom"; /* MAY NEED TO REMOVE */
import { backendUrl } from "../../variables";
import axios from "axios";

class CoinbaseFeedback extends React.PureComponent {
  constructor(props) {
    super(props);

    const status = this.props.match.params.status;
    const id = this.props.match.params.scopeId;

    if (status === "success" && id !== undefined) {
      this.props.showSnack({
        message: "Account connected. Importing transactions",
        variant: "success",
      });
    } else if (status === "error" && id !== undefined) {
      this.props.showSnack({
        message:
          "Error authorizing app. Please try again or contact coinbase if the problem persits",
        variant: "error",
      });
      this.deleteWallet(id);
    }
    this.props.history.push("/");
  }

  deleteWallet = (id) => {
    let headers = { "content-type": "application/json" };
    if (this.props.token !== null && this.props.token !== undefined) {
      headers["Authorization"] = `Token ${this.props.token}`;
    }

    const url_call = `${backendUrl}/v1/exchange_account/${id}/?u=${this.props.user_id}&scope=cascade`;
    this.setState((state) => ({ ...state, loading: true }));
    axios
      .delete(url_call, { headers: headers })
      .then((json) => {
        this.setState((state) => ({ ...state, loading: false, success: true }));
        // load new wallet list
        //this.props.getWallets(this.props.user_id);
        this.props.getWallets(this.props.user_id);
        //
      })
      .catch((e) => console.log(e));
  };

  render() {
    return <div />;
  }
}

const mapStateToProps = (state) => {
  return {
    user_id: state.auth.user_id,
    token: state.auth.token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showSnack: (data) => dispatch(interActions.showSnack(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CoinbaseFeedback));
