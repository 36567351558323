import React from 'react';
import { connect } from 'react-redux';

class SmallCollabList extends React.PureComponent {

    render () {

        return (

            <div style={{
                overflowScrolling: "touch",
                WebkitOverflowScrolling: "touch",
                }}>
                {
                    this.props.data['users'].filter(v => v.user_id == this.props.user_id).concat(this.props.data['users'].filter(v => (v.role == 'admin' || v.role == 'manager') && ( v.user_id != this.props.user_id))).concat(this.props.data['users'].filter(v => v.role == 'observer' && ( v.user_id != this.props.user_id) )).map((val, key)=> {
                        return (

                            <div 
                                className={ (val.user_id === this.props.data.owner) || (val.user_id === this.props.user_id)  ? 'coin-flat-list-item-nohover' : 'coin-flat-list-item' }
                                key={key}
                                onClick={ ( val.user_id !== this.props.data.owner ) || ( val.user_id !== this.props.user_id ) ? () => this.props.openDrawer(val) : null}
                                style={{
                                    
                                    display: 'flex',
                                    alignItems: 'center', 
                                    height: 60,
                                    width: '100%',
                                    marginBottom: '1px solid #E1E5E9',
                                    justifyContent: 'space-between',
                                    }}>
                                        <div style={{display: 'flex', alignItems: 'center'}} >
                                            <div>
                                                <div style={{marginLeft: 12}} className="basier-p2">{val.first_name} {val.last_name}
                                                </div>
                                                <div style={{marginLeft: 12, color: val.user_id === this.props.data.owner ? '#0078DB' : 'black'}} className="basier-p4-caps">
                                                    
                                                    {
                                                        val.user_id === this.props.data.owner ? 
                                                        'Owner'
                                                        :
                                                        val.role
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                        <div style={{marginRight: 24}}>
                                            {val.user_id !== this.props.data.owner &&
                                            <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M0.999999 0.856573L7 6.85413L1 12.8541" stroke="#000000" stroke-miterlimit="10"/>
                                            </svg>
                                            }
                                        </div>
                            </div>
                        )
                    })
                }
            </div>
            
        )
    }


}

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = dispatch => {
    return {
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(SmallCollabList)
