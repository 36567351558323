import React from "react";
import { connect } from 'react-redux';
import ProfitLine from "./ProfitLine";

class ProfitTable extends React.PureComponent {

 
    state ={
        rotate: 0,
    }

    expandData = () => {
        this.setState(state => ({...state, rotate: state.rotate === 0 ? 180 : 0 }))
    }

    render() {

        return (


           this.props.data.map((item, index) => { 
            

            return (

                <ProfitLine item={item} index={index} />

            )   
    


            })


        )
    }
}

const mapStateToProps = state => {
    return {
        currency_symbol: state.auth.currency.symbol,
    };
};


export default connect(
    mapStateToProps,
    null
)(ProfitTable);