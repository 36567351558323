import React from "react";
import { connect } from "react-redux";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import CalendarImg from "../../../Media/calendar.svg";
import MultiSelect from "../../AppWide/CoinMultiSelect";
import MutiFilter from "../../AppWide/SearchableFilter/SFWrapper";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRangePicker } from "react-date-range";
import { addDays } from "date-fns";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import * as interactionActions from "../../../store/actions/interactions";

class TableWrapper extends React.PureComponent {
  state = {
    sort: "desc",
    dates: [
      {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      },
    ],
    showDates: false,
    datesFiltered: false,
  };

  toggleDates = () => {
    this.setState((state) => ({ ...state, showDates: !state.showDates }));
  };

  componentDidMount() {
    if (
      this.props.transactions.filters["startDate"] !== undefined &&
      this.props.transactions.filters["endDate"]
    ) {
      this.setState((state) => ({
        ...state,
        dates: [
          {
            startDate: this.props.transactions.filters["startDate"],
            endDate: new Date(),
            key: "selection",
          },
        ],
      }));
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.transactions.filters["startDate"] !==
        prevProps.filterValues["startDate"] &&
      this.props.transactions.filters["startDate"] !== undefined &&
      this.props.transactions.filters["endDate"]
    ) {
      //const daysRemove = Math.ceil(Math.abs(new Date() - this.props.transactions.filters['startDate']) / (1000 * 60 * 60 * 24))
      this.setState((state) => ({
        ...state,
        dates: [
          {
            startDate: this.props.transactions.filters["startDate"],
            endDate: new Date(),
            key: "selection",
          },
        ],
      }));
    }
  }

  toClose = () => {
    this.setState((state) => ({ ...state, showDates: false }));
  };

  handleClose = () => {
    setTimeout(() => {
      this.toClose();
    }, 10);
  };

  handleSortChange = (e) => {
    e.persist();
    const val = e.target.value;
    this.setState((state) => ({ ...state, sort: val }));
    this.sort(val);
  };

  setDate = (data) => {
    const start = data[0]["startDate"];
    let end = data[0]["endDate"];
    //end = addDays(start, 1)

    this.setState((state) => ({ ...state, dates: data }));
    this.setFilter("date", { start: start, end: end });

    if (
      start != this.props.transactions.filters["startDate"] ||
      end != this.props.transactions.filters["endDate"]
    ) {
      this.setState((state) => ({ ...state, datesFiltered: true }));
    } else {
      this.setState((state) => ({ ...state, datesFiltered: false }));
    }
  };

  clearDates = () => {
    this.setState((state) => ({
      ...state,
      dates: [
        {
          startDate: this.props.transactions.filters["startDate"],
          endDate: new Date(),
          key: "selection",
        },
      ],
      datesFiltered: false,
    }));
    this.setFilter("date", "all");
  };

  setFilter = (type, value) => {
    const oldFilters = this.props.filters;
    let newFilters;
    if (value === 'account') {
      newFilters = {
        ...oldFilters,
        source_id: {id: value.id, type: value.type},
      };
    } else {
      newFilters = {
        ...oldFilters,
        [type]: value,
      };
    }
    this.props.setFilter(newFilters);
    this.props.getData(0, "0", newFilters);
    this.props.resetPage();
  };

  render() {
    return (
      <div
        style={{ marginBottom: 12, padding: 24, backgroundColor: "#F8F9FA" }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "space-between",
          }}
        >
          <div style={{ width: 120 }}>
            <div className="basier-p4-caps" style={{ marginBottom: 6 }}>
              Sort by
            </div>
            <div>
              <Select
                value={this.props.filters["sort"]}
                onChange={(e) => this.setFilter("sort", e.target.value)}
              >
                <MenuItem value="desc">Newest First</MenuItem>
                <MenuItem value="asc">Oldest first</MenuItem>
              </Select>
            </div>
          </div>

          <div style={{ width: 120 }}>
            <div className="basier-p4-caps" style={{ marginBottom: 6 }}>
              Show
            </div>
            <div>
              <Select
                value={this.props.filters["scope"]}
                onChange={(e) => this.setFilter("scope", e.target.value)}
              >
                <MenuItem value="all">All Imports</MenuItem>
                <MenuItem value="pending">Unreconciled</MenuItem>
                <MenuItem value="reconciled">Reconciled</MenuItem>
                <MenuItem value="deleted">Deleted</MenuItem>
                <MenuItem value="unsupported">Unsupported</MenuItem>
              </Select>
            </div>
          </div>

          <div style={{ width: 120 }}>
            <div className="basier-p4-caps" style={{ marginBottom: 6 }}>
              Type
            </div>
            <div style={{ position: "absolute" }}>
              <MutiFilter
                values={this.props.transactions.filters["transaction_description"]}
                filters={this.props.filters["transaction_description"]}
                resetPage={this.props.resetPage}
                filterData={(d) => this.setFilter("transaction_description", d)}
              />
            </div>
          </div>

          <div style={{ width: 120 }}>
            <div className="basier-p4-caps" style={{ marginBottom: 6 }}>
              Base Coin
            </div>
            <div style={{ position: "absolute" }}>
              <MutiFilter
                values={this.props.transactions.filters["base_id"]}
                filters={this.props.filters["base_id"]}
                resetPage={this.props.resetPage}
                filterData={(d) => this.setFilter("base_id", d)}
              />
            </div>
          </div>

          <div style={{ width: 120 }}>
            <div className="basier-p4-caps" style={{ marginBottom: 6 }}>
              Account
            </div>
            <div style={{ position: "absolute" }}>
              <MutiFilter
                values={this.props.transactions.filters["source_id"]}
                filters={this.props.filters["source_id"]}
                resetPage={this.props.resetPage}
                filterData={(d) => this.setFilter("source_id", d)}
              />
            </div>
          </div>

          <div style={{ width: 120 }}>
            <div className="basier-p4-caps" style={{ marginBottom: 6 }}>
              Dates
            </div>
            <div style={{ position: "absolute" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  className="opacity-hover"
                  onClick={this.toggleDates}
                  src={CalendarImg}
                  alt=""
                  style={{
                    height: 30,
                    width: 30,
                    marginRight: 12,
                    cursor: "pointer",
                  }}
                />
                {!this.state.datesFiltered ? (
                  <div>Filter Dates</div>
                ) : (
                  <div
                    onClick={this.clearDates}
                    style={{ color: "#0078DB", cursor: "pointer" }}
                    className="opacity-hover"
                  >
                    Clear Dates
                  </div>
                )}
              </div>

              {this.state.showDates && (
                <ClickAwayListener onClickAway={() => this.handleClose()}>
                  <div
                    style={{
                      backgroundColor: "white",
                      marginTop: 20,
                      padding: 0,
                      border: "1px solid #CCC",
                      position: "relative",
                      left: "-70%",
                      zIndex: 911,
                    }}
                  >
                    <DateRangePicker
                      onChange={(item) => this.setDate([item.selection])}
                      showSelectionPreview={true}
                      moveRangeOnFirstSelection={false}
                      //months={2}
                      ranges={this.state.dates}
                      scroll={{ enabled: true }}
                      //direction="horizontal"
                      shownDate={new Date()}
                    />
                  </div>
                </ClickAwayListener>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    transactions: state.data.importedTransactions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(TableWrapper);
