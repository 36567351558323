import React from "react";
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import * as actions from "../../../../../store/actions/interactions";
import worker from "../../../../../workers/marketcapWorker";
import WebWorker from "../../../../../workers/WebWorker";
import CapGraph from './CapGraph'

class StatsTable extends React.PureComponent {

    state = {
        graphData: null,
        micro_coins: null,
        low_coins: null,
        mid_coins: null,
        high_coins: null,
        fiat_coins: null,
    }


    componentDidMount() {
        
        this.worker = new WebWorker(worker);

        this.worker.addEventListener('message', event => {
            const returned_data = event.data
            this.setState(state => ({...state, 
                                    graphData: returned_data.graphData, 
                                    fiat_coins: returned_data.fiat_coins, 
                                    micro_coins: returned_data.micro_coins,
                                    low_coins: returned_data.low_coins,
                                    mid_coins: returned_data.mid_coins,
                                    high_coins: returned_data.high_coins,
                                    fees: returned_data.fees, 

                                    }))

        });

        this.worker.postMessage(this.props.data.balances)

    }

    componentDidUpdate(prevProps, prevState) {
        
        if (prevProps !== this.props) {

            this.worker = new WebWorker(worker);

            this.worker.addEventListener('message', event => {
                const returned_data = event.data
                this.setState(state => ({...state, 
                                        graphData: returned_data.graphData, 
                                        fiat_coins: returned_data.fiat_coins, 
                                        micro_coins: returned_data.micro_coins,
                                        low_coins: returned_data.low_coins,
                                        mid_coins: returned_data.mid_coins,
                                        high_coins: returned_data.high_coins,
                                        fees: returned_data.fees, 
    
                                        }))
    
            });
    
            this.worker.postMessage(this.props.data.balances)

        }
       
    }

    render() {


        return (

            <Grid 
                container
                justify='space-between'
                spacing={40}
                
                >

                <Grid item lg={6} md={6} sm={6} xl={6} xs={6}
                    style={{
                       // marginRight: 12,
                }}> 

                        <div className="basier" style={{textTransform: 'uppercase', paddingLeft: 12, borderBottom: '1px solid #E1E5E9', fontSize: 16, color: '#6A7E93'}}>
                            Portfolio Metrics
                        </div>

                        <div style={{height: '100%', width: '100%', justifyContent: 'center', alignItems: 'center', display: 'flex'}}>Coming soon</div>

                </Grid>
                
                <Grid item lg={6} md={6} sm={6} xl={6} xs={6}
                style={{
                       // marginLeft: 12,
                    }} >

                        <div className="basier" style={{textTransform: 'uppercase', paddingLeft: 12, borderBottom: '1px solid #E1E5E9', fontSize: 16, color: '#6A7E93'}}>
                            Portfolio Composition
                        </div>

                    {this.state.graphData !== null ? 
                    <div style={{width: '45%', marginTop: 48, marginLeft: 12, height: 400, marginBottom: 48}}>
                        <CapGraph values={this.state.graphData} />
                    </div>
                    : null}     

                </Grid>

            </Grid>
          
    )
    

    }
}

const mapStateToProps = state => {
    return {
        currency_symbol: state.auth.currency.symbol,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        toggleAssetModal: (currency, scope) => 
            dispatch(actions.toggleAssetModal(currency, scope)), 
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(StatsTable);