import React from 'react';
import { connect } from 'react-redux';

class SFTop extends React.PureComponent {

    render () {

        return (

            <div style={{width: '100%', minHeight: 40, maxHeight: 40, padding: 8, display: 'flex', alignItems: 'center', borderBottom: '1px solid #CCC'}}>

                <div>
                    <input onChange={(e) => this.props.filterList(e.target.value)} autoFocus className="light-input basier-p4" style={{height: 25, flex: 1, width: 170, padding: '2px 5px'}} />
                </div>
                <div onClick={() => this.props.toClose()} style={{paddingLeft: 8, cursor: 'pointer', color: '#0078DB'}} className='basier-p4-caps opacity-hover noselect'>Close</div>

            </div>
            
        )
    }


}

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = dispatch => {
    return {
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(SFTop)
