import React from 'react';
import { connect } from 'react-redux';
import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import { backendUrl } from '../../../variables'
import 'filepond/dist/filepond.min.css';
import loadingSpinner from '../../../Media/loadingSpinner.gif'
import PendingUpload from './ModalComps/PendingUpload'
import ErrorUpload from './ModalComps/ErrorUpload'
import okIcon from '../../../Media/okIcon.svg'
import gearIcon from '../../../Media/gears.svg'
import axios from 'axios'; 
import Tooltip from '../../AppWide/Tooltip'
import questionMark from '../../../Media/questionMark.svg'
import * as appwideActions from "../../../store/actions/appwide";
import * as dataActions from "../../../store/actions/data";
import * as interActions from "../../../store/actions/interactions";

registerPlugin(FilePondPluginFileValidateType);


const simple_tooltip = "Simple templates do not include exchange and storage information. They are quicker to input but you will not be able to use the full features of Wisly (e.g. account balances, or transaction flow views)."

const complete_tooltip = "Complete templates are longer to fill in, but allow you to use the full feature set of Wisly and give you much more insight! We recommend investing the extra time upfront in inputing your transactions."


const instructions = (
    <div style={{width: '100%', height: '100%', overflow: 'scroll'}}>
        <div style={{display: 'flex'}}>
            <div className="basier-p3" style={{marginBottom: 24, textTransform: 'uppercase', color: 'black', fontWeight: 900, paddingLeft: 6}}>
                Field instructions
            </div>
            <div className="basier-p4" style={{marginLeft: 6}}>(scroll to see all)</div>
        </div>

        <div style={{paddingLeft: 12, paddingRight: 12, width: '100%'}}>

        <div style={{ marginBottom: 12,}}>
            <div style={{display: 'flex', alignItems: 'baseline', marginBottom: 4, width: '100%'}}>

                <div className="basier-p4-caps" style={{color: '#0078DB', fontWeight: 900, marginRight: 6}}>User</div>
                <div className="basier-p4-caps" style={{color: '#6A7E93', fontStyle: 'italic', marginRight: 6}}></div>
            </div>
            <div className="basier-p4">Email address of the user executing the transaction.</div>
        </div>

        <div style={{ marginBottom: 12,}}>
            <div style={{display: 'flex', alignItems: 'baseline', marginBottom: 4, width: '100%'}}>

                <div className="basier-p4-caps" style={{color: '#0078DB', fontWeight: 900, marginRight: 6}}>Date</div>
                <div className="basier-p4-caps" style={{color: '#6A7E93', fontStyle: 'italic', marginRight: 6}}></div>
            </div>
            <div className="basier-p4">Date format is "YYYY-MM-DD HH:mm:ss". UTC timezone will be used if not specified through "+HH:mm" at the end of the date.</div>
        </div>

        <div style={{ marginBottom: 12,}}>
            <div style={{display: 'flex', alignItems: 'baseline', marginBottom: 4, width: '100%'}}>

                <div className="basier-p4-caps" style={{color: '#0078DB', fontWeight: 900, marginRight: 6}}>Type</div>
                <div className="basier-p4-caps" style={{color: '#6A7E93', fontStyle: 'italic', marginRight: 6}}></div>
            </div>
            <div className="basier-p4">Click here to see allowed types and their explanation.</div>
        </div>

        <div style={{ marginBottom: 12,}}>
            <div style={{display: 'flex', alignItems: 'baseline', marginBottom: 4, width: '100%'}}>

                <div className="basier-p4-caps" style={{color: '#0078DB', fontWeight: 900, marginRight: 6}}>Base, Quote, Fee_currency</div>
                <div className="basier-p4-caps" style={{color: '#6A7E93', fontStyle: 'italic', marginRight: 6}}></div>
            </div>
            <div className="basier-p4">Please use coin tickers to identify coins. Quote and Fee_currency can be left blank if relevant. Note: Quote is required for buy, sell and ICO transactions.</div>
        </div>

        <div style={{ marginBottom: 12,}}>
            <div style={{display: 'flex', alignItems: 'baseline', marginBottom: 4, width: '100%'}}>

                <div className="basier-p4-caps" style={{color: '#0078DB', fontWeight: 900, marginRight: 6}}>Amount, Price, Fees</div>
                <div className="basier-p4-caps" style={{color: '#6A7E93', fontStyle: 'italic', marginRight: 6}}></div>
            </div>
            <div className="basier-p4">Amount, unit price and fees. Please use positive numbers only. Price and Fees can be left blank if relevant. Note: Price is required for buy, sell and ICO transactions.</div>
        </div>

        <div style={{ marginBottom: 12,}}>
            <div style={{display: 'flex', alignItems: 'baseline', marginBottom: 4, width: '100%'}}>

                <div className="basier-p4-caps" style={{color: '#0078DB', fontWeight: 900, marginRight: 6}}>Comments</div>
                <div className="basier-p4-caps" style={{color: '#6A7E93', fontStyle: 'italic', marginRight: 6}}>- optional</div>
            </div>
            <div className="basier-p4">Any comments on transactions can be added here.</div>
        </div>

        <div style={{ marginBottom: 12,}}>
            <div style={{display: 'flex', alignItems: 'baseline', marginBottom: 4, width: '100%'}}>

                <div className="basier-p4-caps" style={{color: '#0078DB', fontWeight: 900, marginRight: 6}}>Exchange</div>
                <div className="basier-p4-caps" style={{color: '#6A7E93', fontStyle: 'italic', marginRight: 6}}>- optional</div>
            </div>
            <div className="basier-p4">Name of the exchange transaction was made on or transfer was made from, if applicable.</div>
        </div>

        <div style={{ marginBottom: 12,}}>
            <div style={{display: 'flex', alignItems: 'baseline', marginBottom: 4, width: '100%'}}>

                <div className="basier-p4-caps" style={{color: '#0078DB', fontWeight: 900, marginRight: 6}}>Account</div>
                <div className="basier-p4-caps" style={{color: '#6A7E93', fontStyle: 'italic', marginRight: 6}}>- optional</div>
            </div>
            <div className="basier-p4">If you have multiple accounts per exchange, you can identify them here or leave blank to use default account.</div>
        </div>

        <div style={{ marginBottom: 12,}}>
            <div style={{display: 'flex', alignItems: 'baseline', marginBottom: 4, width: '100%'}}>

                <div className="basier-p4-caps" style={{color: '#0078DB', fontWeight: 900, marginRight: 6}}>Storage</div>
                <div className="basier-p4-caps" style={{color: '#6A7E93', fontStyle: 'italic', marginRight: 6}}>- optional</div>
            </div>
            <div className="basier-p4">Name of storage wallet transaction was made on or transfer was made from, if applicable.</div>
        </div>

        <div style={{ marginBottom: 12,}}>
            <div style={{display: 'flex', alignItems: 'baseline', marginBottom: 4, width: '100%'}}>

                <div className="basier-p4-caps" style={{color: '#0078DB', fontWeight: 900, marginRight: 6}}>Network</div>
                <div className="basier-p4-caps" style={{color: '#6A7E93', fontStyle: 'italic', marginRight: 6}}>- optional</div>
            </div>
            <div className="basier-p4">Ticker for the network coin on which the storage wallet is created.</div>
        </div>

        <div style={{ marginBottom: 12,}}>
            <div style={{display: 'flex', alignItems: 'baseline', marginBottom: 4, width: '100%'}}>

                <div className="basier-p4-caps" style={{color: '#0078DB', fontWeight: 900, marginRight: 6}}>Destination_user</div>
                <div className="basier-p4-caps" style={{color: '#6A7E93', fontStyle: 'italic', marginRight: 6}}>- only for transfers</div>
            </div>
            <div className="basier-p4">e-mail of the user coins were transfered to if different from transaction user.</div>
        </div>

        <div style={{ marginBottom: 12,}}>
            <div style={{display: 'flex', alignItems: 'baseline', marginBottom: 4, width: '100%'}}>

                <div className="basier-p4-caps" style={{color: '#0078DB', fontWeight: 900, marginRight: 6}}>Destination_exchange</div>
                <div className="basier-p4-caps" style={{color: '#6A7E93', fontStyle: 'italic', marginRight: 6}}>- only for transfers</div>
            </div>
            <div className="basier-p4">Name of the exchange transfer was made to.</div>
        </div>

        <div style={{ marginBottom: 12,}}>
            <div style={{display: 'flex', alignItems: 'baseline', marginBottom: 4, width: '100%'}}>

                <div className="basier-p4-caps" style={{color: '#0078DB', fontWeight: 900, marginRight: 6}}>Destination_account</div>
                <div className="basier-p4-caps" style={{color: '#6A7E93', fontStyle: 'italic', marginRight: 6}}>- only for transfers</div>
            </div>
            <div className="basier-p4">If you have multiple accounts per exchange, you can identify them here or leave blank to use default account.</div>
        </div>

        <div style={{ marginBottom: 12,}}>
            <div style={{display: 'flex', alignItems: 'baseline', marginBottom: 4, width: '100%'}}>

                <div className="basier-p4-caps" style={{color: '#0078DB', fontWeight: 900, marginRight: 6}}>Destination_storage</div>
                <div className="basier-p4-caps" style={{color: '#6A7E93', fontStyle: 'italic', marginRight: 6}}>- only for transfers</div>
            </div>
            <div className="basier-p4">Name of the storage wallet the transfer was made to.</div>
        </div>
        

         


        </div>


    </div>
)



class UploadView extends React.PureComponent {

    state = {
        // Set initial files, type 'local' means this is a file
        // that has already been uploaded to the server (see docs)
        files: [], 
        progress: {},
        scope: 'instructions',
        portfolio_id: null,
        pending: false, 
        pendingData: null,
        error: false, 
        task: false,
        errorData: {
            data: {}
        }, 
        success: false,
        uploadId: null,
    }

    updateState = (newData) => {

        this.setState(state => ({...state, ...newData}))


    }

    downloadTemplate = (scope) => {

        let headers = { "content-type": "application/json" };
        if (this.props.token !== null && this.props.token !== undefined) {

            headers['Authorization'] = `Token ${this.props.token}`

        }

        const url_call = `${backendUrl}/v1/transaction_template/?u=${this.props.user_id}&scope=${scope}`

        axios.get(url_call, {headers: headers})
            .then(json => {
               
            })
            .catch( e => {

            })

    }

    componentDidMount() {
        
        if (this.props.overridePort === true) {
            this.setState(state => ({...state, portfolio_id: this.props.overridePortId}))
          } else {
            this.setState(state => ({...state, portfolio_id: this.props.currentPortfolio}))
          }

    }
    handleInit() {
    }

    addTask = (data) => {
        const currentTasks = this.props.tasks
        const newTasks = [data, ...currentTasks]

        this.props.setInteraction({label: "tasks", value: newTasks})
    }

    render () {

        let token = this.props.token;
        let auth_header = `Token ${token}`

        return (
            <div style={{padding: '24px 48px', display: 'flex', height: '100%'}}>
                
                <div style={{width: '50%', paddingRight: 6, height: '100%', marginTop: 24}}>


                    <div style={{marginBottom: 24}}>Please follow the below steps to upload your transactions CSV.</div>

                    <div style={{display: 'flex', marginBottom: 24, alignItems: 'center'}}>
                        <div style={{width: 24, height: 24, display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: 100, backgroundColor: '#0078DB', color: 'white', fontWeight: 900, marginRight: 12}} className="basier-p2">
                            1
                        </div>
                        <div className="basier-p3" style={{textTransform: 'uppercase', color: 'black', fontWeight: 900}}>
                            Download the template
                        </div>
                    </div>

                    <div style={{marginBottom: 24}}>

                        <div>
                            <span>
                                Simple template 
                            </span>
                            <Tooltip title={simple_tooltip} placement="right">
                                <img style={{height: 20, width: 20, marginLeft: 12, marginRight: 12}} src={questionMark} alt=""/>
                            </Tooltip>
                            <a href={`${backendUrl}/v1/transaction_template/?u=${this.props.user_id}&scope=simple`}><span className="opacity-hover" style={{marginLeft: 12, color: '#0078DB', cursor: 'pointer'}}>Download here</span></a>
                        </div>  

                        <div style={{marginTop: 12}}>
                            <span>
                                Complete template 
                            </span>
                            <Tooltip title={complete_tooltip} placement="right">
                                <img style={{height: 20, width: 20, marginLeft: 12, marginRight: 12}} src={questionMark} alt=""/>
                            </Tooltip>
                            <a href={`${backendUrl}/v1/transaction_template/?u=${this.props.user_id}&scope=complete`}><span className="opacity-hover" style={{marginLeft: 12, color: '#0078DB', cursor: 'pointer'}}>Download here</span></a>
                        </div> 

                    </div>

                    <div style={{display: 'flex', marginBottom: 24, alignItems: 'center'}}>
                        <div style={{width: 24, height: 24, display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: 100, backgroundColor: '#0078DB', color: 'white', fontWeight: 900, marginRight: 12}} className="basier-p2">
                            2
                        </div>
                        <div className="basier-p3" style={{textTransform: 'uppercase', color: 'black', fontWeight: 900}}>
                            Read and follow the instructions
                        </div>
                    </div>

                     <div style={{marginBottom: 24}}>

                        In order to make your import easier and frictionless, you can follow the instructions on our support page. 
                        In addition some basic infomation on each column is available on the right
                        
                    </div>

                    <div style={{display: 'flex', marginBottom: 24, alignItems: 'center'}}>
                        <div style={{width: 24, height: 24, display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: 100, backgroundColor: '#0078DB', color: 'white', fontWeight: 900, marginRight: 12}} className="basier-p2">
                            3
                        </div>
                        <div className="basier-p3" style={{textTransform: 'uppercase', color: 'black', fontWeight: 900}}>
                            Upload the file
                        </div>
                    </div>

                    <div style={{width: '95%', height: 150}}>

                        <FilePond ref={ref => this.pond = ref}
                        files={this.state.files}
                        allowMultiple={false}
                        maxFiles={1}
                        //allowFileTypeValidation={true}
                        //acceptedFileTypes={["text/csv", "text/plain", "text/*"]}
                        server={
                            {
                                url: `${backendUrl}/v1`,
                                process: {
                                    url: '/upload_csv/', 
                                    method: 'POST',
                                    headers: {
                                        "Authorization": auth_header,
                                        "x-portfolio": this.state.portfolio_id,
                                        "x-user": this.props.user_id,

                                    },
                                    onload: res => {

                                        res = JSON.parse(res)

                                        if (res['status'] === 'pending') {
                                            this.setState(state => ({...state, pending: true, error: false, success: false, task: false, pendingData: res, uploadId: res['upload_id']}))
                                        } else if (res['status'] === 'task') {
                                            
                                            const data = res['data'][0]
                                            
                                            const language = navigator.language || 'en'
                                            const options = {year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit' };
                                            const parseDate = new Date();
                                            const showDate = parseDate.toLocaleDateString(language,options)

                                            const newTaskData = {description: `CSV Import`, sub: `Created ${showDate}`, status: 'pending', type: 'import', task_id: data['task_id']}
                                            this.addTask(newTaskData)
                                            this.setState(state => ({...state, pending: false, success: false, error: false, task: true}))
                                        
                                        }  else if (res['status'] === 'OK') {
                                            const request_data = {
                                                period: 'max',
                                                start: null,
                                                end: null,
                                              }

                                            this.props.componentDataFetch('update', this.props.currentView, this.props.currency_id, null, this.props.currentPortfolio, null, request_data, this.props.inBitcoin);
                                            this.props.getWallets(this.props.user_id);
                                            this.props.getUserLimits(this.props.user_id)
                                            this.setState(state => ({...state, pending: false, success: true, error: false}))
                                            
                                        } 

                                    },
                                    onerror: res => {

                                        res = JSON.parse(res)

                                        if (res['status'] === 'error') {
                                            this.setState(state => ({...state, error: true, pending: false, success: false, errorData: res, uploadId: res['upload_id']}))
                                        } 
                                    },
                                
                                
                            }
                        }}
                        onaddfilestart={() => {
                            this.setState(state => ({...state, scope: 'upload', error: false, success: false, pending: false}))
                        }}
                        oninit={() => this.handleInit()}
                        onupdatefiles={fileItems => {
                            this.setState(state => ({...state, scope: 'upload', error: false, success: false, pending: false}))
                        }}>
                    </FilePond>
                    
                    </div>


                </div>



                <div style={{width: '50%', paddingLeft: 6, height: '100%'}}>
                    <div style={{backgroundColor: 'rgba(243, 245, 246, 0.5)', borderRadius: 20, width: '100%', height: '100%', paddingTop: 18, paddingBottom:18, paddingLeft: 12, overflow: 'scroll'}}>

                        { this.state.scope === 'instructions' ?

                            instructions

                        :

                            this.state.scope === 'upload' ?
                                
                                <div>
                                    {!this.state.pending && !this.state.error && !this.state.success && !this.state.task ? 
                                    <div style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                                        <div><img src={loadingSpinner} style={{height: 40, width: 40}} alt=""/></div>
                                        <div>Upload in progress</div>
                                    </div>
                                    :
                                    null
                                    }
                                    <div>

                                    {this.state.success ? 

                                        <div style={{width: '100%', marginTop: 72, display: 'flex', justifyContent: 'center', textAlign: 'center', alignItems: 'center', flexDirection: 'column'}}>
                                            <img src={okIcon} alt="" />
                                            <div style={{marginTop: 12}} className="basier-p3">Upload successful</div>
                                        </div>
                                    :

                                    null
                                    }

                                    {this.state.task ? 

                                        <div style={{width: '100%', marginTop: 72, display: 'flex', justifyContent: 'center', textAlign: 'center', alignItems: 'center', flexDirection: 'column'}}>
                                            <img src={gearIcon} alt="" />
                                            <div style={{marginTop: 12}} className="basier-p3">You upload file contained more than 5,000 lines. We have queued your upload request and will process it in the background. You can continue using Wisly, and we will notify you when your upload is complete.</div>
                                        </div>
                                    :

                                    null
                                    }

                                    {this.state.pending ? 

                                        <PendingUpload tasks={this.props.tasks} addTask={this.addTask} data={this.state.pendingData['data']} uploadId={this.state.uploadId} updateState={this.updateState} />
                                    :

                                    null
                                    }
                                    {this.state.error ? 

                                        <ErrorUpload data={this.state.errorData['data']} uploadId={this.state.uploadId} />
                                    :

                                    null
                                    }
                                    </div>  
                                </div> 
                            
                            :

                            null

                        }
                    </div>
                </div>



            </div>
        )
    }


}

const mapStateToProps = state => {
    return {
        token: state.auth.token,
        user_id: state.auth.user_id,
        currentPortfolio: state.data.menu_selected, 
        inBitcoin: state.data.bitcoinPf, 
        currentView: state.data.currentView, 
        currency_id: state.auth.currency.id, 
        tasks: state.interactions.tasks, 
    };
};

const mapDispatchToProps = dispatch => {
    return {
        componentDataFetch: (scope, view, currency, user, portfolio, globalGraphData, portfolioGraphData, inBitcoin) =>
        dispatch(dataActions.componentDataFetch(scope, view, currency, user, portfolio, globalGraphData, portfolioGraphData, inBitcoin)),
        getUserLimits: (user) => dispatch(appwideActions.getUserLimits(user)), 
        getWallets: (user) => dispatch(appwideActions.getWallets(user)), 
        setInteraction: (data) => 
            dispatch(interActions.setInteraction(data)),

    };
};


export default connect(mapStateToProps, mapDispatchToProps)(UploadView)
