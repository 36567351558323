import React from 'react';
import { connect } from 'react-redux';
import BackIcon from "../../../../../Media/BackIcon.svg";
import MobileAudit from '../../../../MobileOnly/MobileAuditTrail';
import * as interActions from "../../../../../store/actions/interactions";
import SmallCollabList from './SmallCollabList';
import CollabEdit from '../../../../MobileOnly/CollabDrawer';

class SmallGeneral extends React.PureComponent {

    state = {
        editColab: false,
        data: null, 
        drawerData: null,
    }

    componentDidMount() {
        const data = this.props.portfoliosData[this.props.portfolioId]
        if (data !== undefined) {
            this.setState(state => ({...state, data: data}))
        }
        document.body.ontouchmove = (e) => { 
            return true;
        }
    }
    
    componentWillUnmount() {
        document.body.ontouchmove = (e) => { 
            return true;
        }
        this.props.setInteraction({label: 'pin', value: true});
    }

    getData = () => {
        this.props.componentDataFetch('get', 6, null, null, this.props.portfolioId, null, null, this.props.inBitcoin)
    }

    componentDidUpdate(prevProps) {
        if (this.props.portfoliosData !== prevProps.portfoliosData) {
            document.body.ontouchmove = (e) => { 
                return true;
            }

            const data = this.props.portfoliosData[this.props.portfolioId]
            if (data !== undefined) {
                this.setState(state => ({...state, data: data}))    
            }
        }

    }

    addCollab = () => {
        this.props.toggleModal({type: 'add_collab', open: true, payload: {data: {
            portfolio_limit: this.state.data['user_limit'] || '10',
            users: this.state.data['users'],
            org: this.state.data['organization'],
            portfolioId:this.props.portfolioId,
        },
        getData: this.getData,
    }})
    }


    editName = () => {
        this.setState(state => ({...state, nameEdit: true})) 
    }

    openDrawer = (val) => {
        this.setState(state => ({...state, drawerData: val, editColab: true}));
    }

    closeDrawer = () => {
        this.setState(state => ({...state, drawerData: null, editColab: false}));  
    }

    editHandler = () => {
        this.setState(state => ({...state, edit: true}))     
    }

    goBackAction = () => {
        this.props.goBack()
    }
    
    updateState = (newData) => {

        this.setState(state => ({...state, data: newData}))
        this.getData()

    }

    confirmDelete = (val) => {

        if (val !== undefined && val.transaction_number > 0) {
        this.props.toggleModal({
            type: 'delete_collab', 
            open: true, 
            payload: {
                handleDelete: this.deleteUser,  
                updateState: this.updateState,
                data: {
                    id: val.id, 
                    users: this.state.data['users'], 
                    userName: `${val.first_name} ${val.last_name}`,
                    numberTx: val.transaction_number,
                    oldUser: val.user_id,
                }
            }
        })
        } else {
            if (window.confirm(`Are you sure you want to remove ${val.first_name} ${val.last_name} from the portfolio. ${val.first_name} ${val.last_name} had no transactions so it will be a simple removal.`)) {
                this.deleteUser(val.id)
            }
        }
    }

    render () {
        

        return (
            this.state.data === null ? 
            null :

                <React.Fragment>
                    
                            
                    <div style={{position: 'absolute', top: 0, zIndex: 1300, width: '100%'}}>

                        <div style={{backgroundColor: '#F8F9FA', zIndex: 1300, height: 75, width: '100%', display:'flex', alignItems: 'center', position: 'fixed', justifyContent: 'space-between'}} id="responsive-dialog-title">

                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <div onClick={() => this.goBackAction()}>
                                        <img style={{paddingTop: 24}} src={BackIcon} alt=""/>
                                    </div>
                                </div>

                                <div className="basier-p2" style={{color: 'black', fontWeight: 900, textTransform: 'uppercase'}}>Audit Trail</div>

                                <div>
                                    <div className="basier"  style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end', fontSize: '1.2rem', width: 35}}>
                             
                                    </div> 
                                </div>

                        </div>

                        <div style={{position: 'fixed', top: 75, height: 200, width: '100%', zIndex: 1200, backgroundColor: 'white'}} />




                            <div style={{position: 'absolute', top: 75, zIndex: 120000, backgroundColor: 'white', minHeight: 100, width: '100%'}}>
                                 
                                <SmallCollabList data={this.state.data} openDrawer={(v) => this.openDrawer(v)} user_id={this.props.user_id} />

                            </div>

                    </div>
                    {this.state.editColab &&
                    <CollabEdit data={this.state.drawerData} openModal={this.state.editColab} closeModal={this.closeDrawer} confirmDelete={(val) => this.confirmDelete(val)} handleSelect={(res) => this.handleUser(res)}/>
                    }
                </React.Fragment>
            
        )
    }


}

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setInteraction: (data) => 
            dispatch(interActions.setInteraction(data)), 
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(SmallGeneral)



