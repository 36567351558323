import React from "react";
import { connect } from 'react-redux';
import * as actions from "../../store/actions/data";
import * as appwideActions from "../../store/actions/appwide";
import * as authActions from "../../store/actions/auth";
import {createLoadingSelector} from '../../store/reducers/selectors';
import {updatePortfolios} from '../../helpers/portfolioHelpers'
import axios from 'axios';
import LargeInvitation from './LargeInvitation'
import Hidden from '@material-ui/core/Hidden';
import SmallInvitation from "./SmallInvitation";
import { backendUrl } from '../../variables';

// Import FilePond styles

class Settings extends React.Component {

    state = {
        // Set initial files, type 'local' means this is a file
        // that has already been uploaded to the server (see docs)
        files: [], 
        progress: {}, 
        loading: {}
    }

    handleInit() {
    }

    handleSocket(data) {

        

    }

    handleRequestResponse = (invite, response) => {

        let headers = { "content-type": "application/json" };
        if (this.props.token !== null && this.props.token !== undefined) {
    
            headers['Authorization'] = `Token ${this.props.token}`
    
        }
        
        const data = {
            id: invite.id, 
            user_id: this.props.user_id,
            status: response,
        }

        const url_call = `${backendUrl}/v1/portfolio_requests/`
    
        axios.post(url_call, data, {headers: headers})
            .then(json => {
            
            this.setState(state => ({...state, loading: {...state.loading, [invite.id]: true}}))
            
            if (response === 'approved') {
                const success = updatePortfolios(json['data']['data'])
                if (success) {
                    this.props.setPorts(json['data']['data'])
                }
            }
            
            const old_invites = this.props.invites
            const old_notifs = this.props.notifs.notifications
            const scope_notif = old_notifs.filter(v => (v.scope_id == invite.id && v.action === 'portfolio_request'))
            //const new_number = !notif.is_read ? Math.max(this.props.notifs.unread - 1,0) : this.props.notifs.unread + 1

            let newData = old_invites.map(el => {
                if(el.id == invite.id)
                return Object.assign({}, el, {status: response})
                return el
                });
                const inviteData = newData

                this.props.writeInvites(inviteData)
            

            if (scope_notif !== undefined && scope_notif.length > 0) {

                let proceed = false
                const new_number = !scope_notif[0].is_read ? Math.max(this.props.notifs.unread - 1,0) : this.props.notifs.unread 
                let newNotifs = old_notifs.map(el => { 
                        if(el.id == scope_notif[0].id) {
                        proceed = true
                        return Object.assign({}, el, {is_read: true})
                        }
                        return el
                        });
                const notifData = {
                    unread: new_number, 
                    notifications: newNotifs
                }

                this.props.writeInvites(inviteData)
                this.props.writeNotifs(notifData)

                if (proceed) {
                    const url_call = `${backendUrl}/v1/notifications/${this.props.user_id}/${scope_notif[0].id}/`
                    const notifData = {
                        is_read: true,
                    }

                    axios.patch(url_call, notifData, {headers: headers})
                        .then(json => {
                
                        })
                        .catch( e => {
                
                            console.log(e)
                
                        })
        
                }
                
            }

            this.setState(state => ({...state, loading: {...state.loading, [invite.id]: false}}))
        
            })
            .catch( e => {
    
                console.log(e)
    
            })

        
        }


    componentDidMount() {

        this.props.setCurrentView(98);
        this.props.setMenuPortfolio(null);
    }

    render() {

        return (
            
            <div>
                <Hidden smDown>
                    <LargeInvitation 
                        invites={this.props.invites}   
                        handleRequestResponse={this.handleRequestResponse}
                        isLoading={this.props.isLoading}
                        loading={this.state.loading}
                        />
                </Hidden>
                <Hidden mdUp>
                    <SmallInvitation 
                        invites={this.props.invites}   
                        handleRequestResponse={this.handleRequestResponse}
                        isLoading={this.props.isLoading}
                        loading={this.state.loading}
                        />
                </Hidden>

            </div>

        )
    }
}

const dataLoadingSelector = createLoadingSelector(['APPWIDE_INVITES']);


const mapStateToProps = state => {
    return {
        token: state.auth.token,
        portfolio_id: state.auth.default_portfolio_id,
        user_id: state.auth.user_id,
        invites: state.appwide.invites,
        notifs: state.appwide.notifs,
        isLoading: dataLoadingSelector(state),
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setMenuPortfolio: (path) => 
            dispatch(actions.setMenuPortfolio(path)), 
        setCurrentView: (view) => 
            dispatch(actions.setCurrentView(view)),
        writeNotifs: (data) => dispatch(appwideActions.updateNotifs(data)), 
        writeInvites: (data) => dispatch(appwideActions.updateInvites(data)), 
        setPorts: (ports) => dispatch(authActions.setPorts(ports)),
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(Settings);
