import {
  withStyles
} from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";


const WislyTooltip = withStyles({
  tooltip: {
    color: "#0078DB",
    backgroundColor: 'white',
    border: '1px solid #0078DB',
    padding: 10,
    boxShadow: '0px 10px 10px rgba(225, 229, 233, 0.3)',
    opacity: 1,
    fontFamily: 'Basier Circle',
    fontSize: 11,
  }
})(Tooltip);

export default WislyTooltip