import React from 'react';
import Select, { components }  from 'react-select';
import { connect } from 'react-redux';




class MenuSelector extends React.Component {

    state = {
        selectedOption: null,
        }

  colourStyles = {
    control: styles => ({ ...styles, backgroundColor: 'transparent', width: this.props.width, height: this.props.height === undefined ? 42 : this.props.height, fontSize: this.props.scope === 'mobile' ? 15 : 12, paddingLeft: 6, border: this.props.error === true ? '2px solid red !important' : '0px', cursor: 'text', dispay:'inline-block', borderRadius: this.props.float === 'right' ? '0px 5px 5px 0px' : '5px 0px 0px 5px'}),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
  
      return {
        ...styles,
        backgroundColor: isDisabled
          ? null
          : isSelected ? 'white' : isFocused ? '#F3F5F6' : null,
        color: isDisabled
          ? '#F3F5F6'
          : isSelected ? 'black' : 'black',
        opacity: isDisabled
          ? '1'
          : isSelected ? '1' : '0.4',
      };
    },
  
  };

  componentDidMount() {

    if (this.props.options !== null) {
        let textName = null
        try { textName = this.props.options.filter(v => v.value === this.props.selection)[0]['label'] } catch(e) {}
        
        const selectedOption = this.props.selection !== undefined ? {label: textName, value: this.props.selection} : null

        this.setState(state => ({...state, options: this.props.options, selectedOption: selectedOption}))
    }

  }

  componentDidUpdate(prevProps) {
      if (prevProps.options !== this.props.options && this.props.options !== null) {

        let textName = null
        try { textName = this.props.options.filter(v => v.value === this.props.selection)[0]['label'] } catch(e) {}
        
        const selectedOption = this.props.selection !== undefined ? {label: textName, value: this.props.selection} : null

        this.setState(state => ({...state, options: this.props.options, selectedOption: selectedOption}))

      }
  }

  keyDown = (e) => {
    if (e.keyCode === 13 || e.keyCode === 9) {
        e.persist()
        //e.preventDefault()
        /* try {
          this.props.nextRef.focus({preventScroll: true})
        } catch(e) {} */
    }
  }
  handleChange = (selectedOption) => {

    if (selectedOption.value !== this.state.selectedOption.value) {

      this.setState({ selectedOption });
      this.props.handleSelect(selectedOption.value)

    }
    
  }
  render() {
    const { selectedOption } = this.state;

    return (
      <Select
        value={selectedOption}
        onChange={this.handleChange}
        options={this.state.options}
        isSearchable={true}
        ref={this.props.innerRef}
        onKeyDown={(e) => this.keyDown(e)}
        styles={this.colourStyles}
        maxMenuHeight={210}
        minMenuHeight={50}
        classNamePrefix={this.props.scope === 'mobile' ? 'react-select-extraSelect-mobile' : 'react-select-priceType'}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
          ...theme.colors,
            primary25: '#F3F5F6',
            primary: '#F3F5F6',
          },
        })}
      />
    );
  }
}

const mapStateToProps = state => {
  return {

  };
};

const mapDispatchToProps = dispatch => {
  return {
};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MenuSelector);
