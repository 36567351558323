import React from 'react';
import Highcharts from 'highcharts/highstock'
import Exporter from 'highcharts/modules/exporting'
import { connect } from 'react-redux';
import * as actions from "../../../../../store/actions/auth";
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { backendUrl } from '../../../../../variables';
import {loadData} from '../../../../../helpers/helpers'
import BullBearDetail from './BullBearDetail';
import contentLoader from '../../../../../Media/contentLoader.svg'


Exporter(Highcharts)

/* (function(H) {
    H.Pointer.prototype.reset = function() {
      return undefined;
    };
  
    H.Point.prototype.highlight = function(event) {
      event = this.series.chart.pointer.normalize(event);
      this.onMouseOver(); // Show the hover marker
      //this.series.chart.tooltip.refresh(this); // Show the tooltip
      this.series.chart.xAxis[0].drawCrosshair(event, this); // Show the crosshair
    };
  
    H.syncExtremes = function(e) {
      var thisChart = this.chart;
  
      if (e.trigger !== "syncExtremes") {
        // Prevent feedback loop
        Highcharts.each(Highcharts.charts, function(chart) {
          if (chart && chart !== thisChart) {
            if (chart.xAxis[0].setExtremes) {
              // It is null while updating
              chart.xAxis[0].setExtremes(e.min, e.max, undefined, false, {
                trigger: "syncExtremes"
              });
            }
          }
        });
      }
    };
  })(Highcharts); */

const styles = {
    root: {
      color: '#6A7E93',
      '&$checked': {
        color: '#0078DB',
      },
    },
    checked: {
        color: '#0078DB'
    },
  };


class BalanceGraph extends React.PureComponent {

    constructor(props) {

        super(props);
        this.state = {
            dateSelected: null,
            chart: null,
            data: null
        }

    }

    componentDidMount() {

        let headers = { "content-type": "application/json" };
        headers["Authorization"] = `Token ${this.props.token}`;

        this.setState(state => ({...state, loading: true}))
        const data_fetch = loadData(`${backendUrl}/v1/analyses/?u=${this.props.user_id}&scope=bull_bear`, headers)
        data_fetch
            .then(json => {

            this.setState(state => ({...state, data: json['data'], loading: false}))
                
            })
            .catch(e => {

                const errorDate = {
                    balances: null
                }

                this.setState(state => ({...state, data: errorDate, loading: false}))

            })



    }
    

    render () {

        return (
            this.state.data !== null ? 
            <div style={{width: '100%', paddingLeft: 12}}>
                
                <BullBearDetail data={this.state.data} />

            </div>

            : 

            <div style={{display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
                <img src={contentLoader} alt="" style={{height: 40}} />
                <div className="basier-p2">Loading</div>
            </div>
            
            
        )
    }


        
};

BalanceGraph.propTypes = {
    classes: PropTypes.object.isRequired,
  };
  

const mapStateToProps = state => {
    return {
        currency_symbol: state.auth.currency.symbol,
        currency_name: state.auth.currency.id,
        currentView: state.data.currentView,
        inBitcoin: state.data.bitcoinPf, 
        token: state.auth.token,
        user_id: state.auth.user_id,

    };
};

const mapDispatchToProps = dispatch => {
    return {
        setBtcSymbol: (s) =>
            dispatch(actions.setBtcSymbol(s)), 
        
    };
  };

export default connect(
    mapStateToProps,
    mapDispatchToProps
)( withStyles(styles)(BalanceGraph));


