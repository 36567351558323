import React from 'react';
import { connect } from 'react-redux';

const analysisCard = (id, renderer, onClick) => (
    <div 
        onClick={() => onClick(id)}
        style={{
        marginTop: 12, marginBottom: 12, 
        width: '100%', padding: 24, 
        height: 120, display: 'flex', alignItems: 'center',
        justifyContent: 'space-between',
        boxShadow: '0px 10px 10px rgba(225, 229, 233, 0.3)',
        border: '1px solid #E1E5E9', 
        borderRadius: 10, 
        cursor: 'pointer'
    }}>

        <div>
            <h5>
                {renderer(id)['name']}
            </h5>
            <div className="basier-p3">
                {renderer(id)['description']}
            </div>
        </div>

        <div>
            <img src={renderer(id)['image']} alt="" style={{height: 80}}/>
        </div>


    </div>
)

class ReportPresentation extends React.PureComponent {

    render () {

        return (
            <div>
                {this.props.noTitle !== true && 
                <div style={{height: 124, display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', flexDirection: 'column'}} className="basier-p3">
                    <div>No reports have been generated for this portfolio yet. Select one of our reports below to get started!</div>
                    <div>
                        Reports can take some time to be ready. Once completed, you will find them here. 
                    </div>
                </div>
                
                }

                {
                    ['portfolioReport', 'valueAtRisk'].map((v, k) => {
                        return (
                            <div key={k}>
                                {analysisCard(v, this.props.reportRenderer, this.props.renderType)}
                            </div>
                        )
                    })
                }
            </div>
            
        )
    }


}

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = dispatch => {
    return {
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(ReportPresentation)
