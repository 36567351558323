import React from 'react';


class NoSupport extends React.PureComponent {

    componentDidMount() {

    }
    render () {
       
        return (
            
        <div></div>

        )   
    }


}

export default NoSupport
