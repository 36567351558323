import React from 'react'; 
import { connect } from 'react-redux';

class Summary extends React.PureComponent {

    state = {
        numdigits: 0,
      }

    componentDidMount() {

    if (this.props.inBitcoin === 'bitcoin') {
        this.setState(state => ({...state, numdigits: 2}))
    } else {
        this.setState(state => ({...state, numdigits: 0}))
    }

    }

    componentDidUpdate(prevProps) {

        if ((this.props.inBitcoin !== prevProps.inBitcoin)) {
          if (this.props.inBitcoin === 'bitcoin') {
              this.setState(state => ({...state, numdigits: 2}))
            } else {
              this.setState(state => ({...state, numdigits: 0}))
            }
  
        }
      }


    render() {
      return (

        this.props.data[this.props.portfolioId] !== undefined ? 

            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-around', textAlign: 'center'}}>

            <div style={{maxWidth: 200, width: 200}}>
                
                <p className="basier-p2" style={{marginBottom: 6, color: 'black'}}>Unrealized profit</p>
        
                    <h4 style={{marginBottom: 0, marginTop:0, color: 'black', fontSize: 24}}>
                        <span>{this.props.currency_symbol}&thinsp;</span>
                        <span>{this.props.data[this.props.portfolioId].total_active_profit.toLocaleString('en', {minimumFractionDigits:this.state.numdigits, maximumFractionDigits: this.state.numdigits})}</span>
                    </h4>
                
                
            </div>



            <div style={{maxWidth: 200, width: 200}}>
                <p className="basier-p2" style={{marginBottom: 6, color: 'black',}}>Realized profit</p>
                <h4 style={{marginBottom: 0, marginTop:0, color: 'black', fontSize: 24}}>
                    <span>{this.props.currency_symbol}&thinsp;</span>
                    <span>{this.props.data[this.props.portfolioId].total_realized_profit.toLocaleString('en', {minimumFractionDigits:this.state.numdigits, maximumFractionDigits: this.state.numdigits})}</span>
                </h4>
            </div>
                
            </div>

        : 

            <div style={{height: 112}}></div> 
        

    )
  }
}


const mapStateToProps = state => {
    return {
        currency_symbol: state.auth.currency.symbol,
        currency_name: state.auth.currency.id,
        inBitcoin: state.data.bitcoinPf
    };
};

export default connect(
    mapStateToProps,
    null
)(Summary);