import React from 'react';
import { connect } from 'react-redux';
import BriefImage from '../../../../Media/GroupPort.svg';
import CoinSearch from '../../../AppWide/CoinSearch';
import loadingSpinner from '../../../../Media/loadingSpinner.gif'
import okIcon from '../../../../Media/okIcon.svg'
import errorIcon from '../../../../Media/errorIcon.svg'

class ExchangeAdd extends React.PureComponent {

    constructor(props) {

        super(props);

        this.state = {
            
        }

        this.inputName = React.createRef();
    }

    componentDidMount() {
        this.props.updateData('user_id', this.props.user_id)
    }

    focusIn = () => {
        this.inputName.current.focus();
    }

    handleSelect = (value) => {
        if (value.custom === true) {
            this.props.updateData('custom_exchange', {id: value.value, name: value.name})
            this.props.updateData('exchange', null)
        } else {
            this.props.updateData('exchange', value.value)
            this.props.updateData('custom_exchange', null)

        }
        
    }
    
    render () {

        return (

            this.props.loading ? 

            <div style={{width: '100%', height: 530, backgroundColor: 'white', opacity: 0.6, display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'absolute', top: 90, zIndex: 13000}}>
                <img style={{height: 64, with: 64}} src={loadingSpinner} alt="Loading..."/>
            </div>

            :

            this.props.success ? 

            <div style={{width: '100%', height: 530, backgroundColor: 'white', opacity: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', position: 'absolute', top: 90, zIndex: 13000}}>
                <img style={{height: 64, with: 64}} src={okIcon} alt="OK!"/>
                <div style={{marginTop: 24}} className="basier-p2">
                  Exchange account created!
                </div>
            </div>

            :

            this.props.error ? 

            <div style={{width: '100%', height: 530, backgroundColor: 'white', opacity: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', position: 'absolute', top: 90, zIndex: 13000}}>
                <img style={{height: 64, with: 64}} src={errorIcon} alt="OK!"/>
                <div style={{marginTop: 24}} className="basier-p2">
                  Oops! Error creating the account. Please try again.
                </div>

                <div className='button-blue opacity-hover' onClick={() => this.props.updateServer()}  style={{height: 40, borderRadius: 35, marginLeft: 'auto', marginRight: 'auto', cursor: 'pointer', marginTop: 24,}}>
                  Retry
                </div>
            </div>

            :

            
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', flex:1, paddingLeft: 0}} >


                <div style={{display: 'flex', alignItems: 'flex-start',flex: 1, marginTop: 0, justifyContent: 'center'}}>

                <div style={{flex: 1}}>

                    <div style={{marginTop: 48}}>
                        <div style={{paddingLeft: 12, color: 'black', marginBottom: 12, display: 'flex', alignItems: 'center'}}>
                            <div className="basier-p4-caps" style={{fontWeight: 900, }}>Exchange</div>
                            <div style={{color: 'red', marginLeft: 6, fontStyle: 'italic'}} className="basier-p4">
                            {
                                !this.props.validation.exchange ? 
                                
                                'Required'

                                :

                                null
                            }
                            </div>
                        </div>
                        <div style={{marginLeft: 12,}}>

                            <CoinSearch 
                                data_filter={this.props.exchanges}
                                dataBind={this.props.exchangeName}
                                ref={this.props.passRef}
                                autoFocus={true}
                                width={324}
                                keepInput={true}
                                overrideClass={true} 
                                focusIn={this.focusIn}
                                type="exchange"
                                allowOther={false}
                                clearRedux="exchange"
                                clearWallets={false}
                                //removeWallets={this.props.removeWallets}
                                updaterMethod={(ref, value) => this.props.updateData(ref, value)}
                                handleSelect={(value) => this.handleSelect(value)}
                                masterClass={`${!this.props.validation.exchange ? "transaction-coin-search-large error-input" : "transaction-coin-search-large"}`}/>

                        </div>
                    </div>

                    <div style={{marginTop: 24,}}>
                        <div className="basier-p4-caps" style={{paddingLeft: 12, color: 'black', textTransform: 'uppercase', fontWeight: 900}}>
                            Account name
                        </div>
                        <div>
                            <input ref={this.inputName} onChange={(e) => this.props.updateData('name', e.target.value)} className={`light-input ${!this.props.validation.name ? 'error-input' : ''}`} value={this.props.data.name} placeholder="Optional name"
                            style={{marginLeft: 12, paddingLeft: 24, width: 324, marginTop: 12}} />
                        </div>
                        {!this.props.validation.name  && 
                        <div style={{paddingLeft: 12, height: 36, color: 'red', fontStyle: 'italic', marginTop: 6}} className="basier-p4">
                            You already have an account with that name. Please select a different name.
                        </div>
                        }
                    </div>

                    <div style={{width: 350, marginTop: 48, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>

                      <div className='basier' onClick={this.props.handleClose} 
                          style={{height: 40, borderRadius: 35, marginLeft: 12, marginRight: 12, marginTop: 24, cursor: 'pointer', color: 'white', backgroundColor: '#F9716B', width: 150, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                          Cancel
                      </div>

                      <div className='button-next-blue' onClick={() => this.props.updateServer()} 
                          style={{height: 40, borderRadius: 35, marginLeft: 12, marginRight: 12, marginTop: 24, cursor: 'pointer'}}>
                          Submit
                      </div>
                    </div> 
                

                </div>

            </div>
        </div>
            

        )
    }


}

const mapStateToProps = state => {
    return {
        exchanges: state.appwide.exchanges, 
        user_id: state.auth.user_id
    };
};

const mapDispatchToProps = dispatch => {
    return {
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(ExchangeAdd)
