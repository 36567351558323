
export default () => {
	self.addEventListener('message', e => { // eslint-disable-line no-restricted-globals
		
		if (!e) return;

		let return_data = []
		let profit = []
		let loss = []
		let positives = 0
		let negatives = 0
		const data = e.data[0]
		const total_pnl = e.data[1]
		const positions = e.data[2]

		let positionProfit = 0.0

		positions.map(v => {
			positionProfit += v.active_profit
		})

		if ( Math.abs(positionProfit) > 0.0000001 ) {
			data.push({coin_ticker: "Futures", total_profit: positionProfit})
		}
		const sortedProfit = data.sort(function compare(a, b) {
			return b.total_profit - a.total_profit;
		})

		sortedProfit.map((balance, index) => {

			if (balance.total_profit > 0) {
				profit.push({
						name: balance.coin_ticker,
						y: balance.total_profit
					})
				
				positives = positives + balance.total_profit
				
			}
			else if (balance.total_profit < 0) {

				loss.push({
					name: balance.coin_ticker,
					y: balance.total_profit
				})
			
				negatives = negatives + balance.total_profit

			}

		})

		let gain_step = []
		if (profit.length > 1) {
			gain_step = [{
				name: 'Gains',
				isIntermediateSum: true,
				color: '#4CDAC1',
			}]
		}

		const sortedLoss = loss.sort(function compare(a, b) {
			return a.y - b.y;
		})
		
		const final_step = [{
			name: 'Total P&L',
			isSum: true,
			color: total_pnl >= 0 ? '#4CDAC1' : '#F9716B'
		}]

		return_data = profit.concat(gain_step).concat(sortedLoss).concat(final_step)
		

		postMessage(return_data);

	})
}
