import React from "react";
import { FilePond, registerPlugin } from 'react-filepond';
import { connect } from 'react-redux';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import { Progress } from 'antd';
import { backendUrl } from './variables'

// Import FilePond styles
import 'filepond/dist/filepond.min.css';

registerPlugin(FilePondPluginFileValidateType);

class _Other extends React.Component {

    state = {
        // Set initial files, type 'local' means this is a file
        // that has already been uploaded to the server (see docs)
        files: [], 
        progress: {}
    }

    handleInit() {
    }

    handleSocket(data) {

        

    }

    render() {

        let token = this.props.token;
        let auth_header = `Token ${token}`

        const progress_content = (
            <div>
                        
                <ul>
                    <Progress percent={((this.state.progress['current'] / this.state.progress['total'])*100).toFixed(2)} />       
                </ul>

            </div> 
        )

        const completed_upload = (
            <div>
                        
                <p>
                    UPLOAD COMPLETED       
                </p>

            </div> 
        )

        const error_upload = (
            <div>
                        
                <p>
                    ERROR
                </p>

            </div> 
        )


        return (
            <div>
                {/* <Websocket  url={"ws://127.0.0.1:8000/ws/upload_status/" + this.props.user_id}
                                        onMessage={this.handleSocket.bind(this)}
                                    /> */}

                <p>Upload</p>
                <FilePond ref={ref => this.pond = ref}
                    files={this.state.files}
                    allowMultiple={false}
                    maxFiles={1}
                    allowFileTypeValidation={true}
                    acceptedFileTypes={["text/csv",  "text/plain"]}
                    server={
                        {
                            url: `${backendUrl}/v1`,
                            process: {
                                url: '/upload_csv/', 
                                method: 'POST',
                                headers: {
                                    "Authorization": auth_header,
                                    "x-portfolio": this.props.portfolio,
                                    "x-user": this.props.user_id,

                                },
                                onload: res => {
                                    if (res['status'] === 'OK') {

                                    } 

                                }
                            }
                            
                            
                        }
                    }
                    oninit={() => this.handleInit()}
                    onupdatefiles={fileItems => {
                        // Set currently active file objects to this.state

                        this.setState({
                            files: fileItems.map(fileItem => fileItem.file)
                        });
                    }}>
                </FilePond>
                
                {this.state.progress['uploading'] ? progress_content : null }
                {this.state.progress['complete'] ? completed_upload : null}
                {this.state.progress['error'] ? error_upload : null}
                
            </div>

        )
    }
}

const mapStateToProps = state => {
    return {
        token: state.auth.token,
        portfolio_id: state.auth.default_portfolio_id,
        user_id: state.auth.user_id,
    };
};

const Other = connect(mapStateToProps)(_Other)
export default Other;