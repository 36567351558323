import React from "react";
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import * as actions from '../../../../../store/actions/interactions'
import { withRouter } from "react-router-dom";
import SmallPositionDetail from './SmallPositionDetail'
import Switch from '../../../../Assets/Switch'
import currencyDict from '../../../../../helpers/currency.json'

const getCurrency = (ticker) => {
    let returnData = ticker;
    try {
        returnData = currencyDict[ticker]['symbol']
    } catch(e) {}

    return returnData
} 

class AssetTable extends React.PureComponent {

    state = {

        small: false, 
        exited: false, 
        errors: true, 
    }

    handleDetail = (e) => {
       this.props.history.push({pathname: '/position-detail', 
                                state: { coinId: e.currentTarget.getAttribute('value'), 
                                          portfolioId: this.props.portfolioId, 
                                          portfoliosData: this.props.portfoliosData }})
    }

    handleSmallToggle = () => {
        if (this.state.small === false) {
            this.setState(state => ({...state, small: true}));
        } else {
            this.setState(state => ({...state, small: false}));
        }
    }

    handleExitedToggle = () => {
        if (this.state.exited === false) {
            this.setState(state => ({...state, exited: true}));
        } else {
            this.setState(state => ({...state, exited: false}));
        }
    }

    handleErrorToggle = () => {
        if (this.state.errors === false) {
            this.setState(state => ({...state, errors: true}));
        } else {
            this.setState(state => ({...state, errors: false}));
        }
    }

    


    render() {
        return (

           <div>

               <div>

            {   
                this.props.open.map((item, index) => { 
                
                const pnlQuoteCurrency = ['usd'].includes(item['quote_ticker'].toLowerCase()) ? '$' : ['btc', 'xbt'].includes(item['quote_ticker'].toLowerCase()) ? 'BTC' : getCurrency(item['quote_ticker'])

                const currency_sym = ['usd'].includes(item['base_ticker'].toLowerCase()) ? '$' : ['btc', 'xbt'].includes(item['base_ticker'].toLowerCase()) ? 'BTC' : getCurrency(item['base_ticker'])

                return (

                    <SmallPositionDetail status="small" currency_symbol={currency_sym} handleDetail={this.handleDetail} key={index} index={index} item={item} quote_currency={pnlQuoteCurrency} />                

                )
    
                })

            }   

            </div>
            
        </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        currency_symbol: state.auth.currency.symbol,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        toggleAssetModal: (currency, scope) => 
            dispatch(actions.toggleAssetModal(currency, scope)), 
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(AssetTable));