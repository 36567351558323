import React from 'react';
import { connect } from 'react-redux';
import {siwtchTransactionType} from '../../../../../../helpers/helpers';
import CoinList from '../../../../../MobileOnly/MobileCurrency'
import TypeList from '../../../../../MobileOnly/MobileType'
import * as actions from "../../../../../../store/actions/interactions";
import * as dataActions from "../../../../../../store/actions/data";
import Switch from '../../../../../Assets/Switch';
import UserList from '../../../../../MobileOnly/UserDrawer';

var moment = require('moment');
require('moment/locale/en-gb');

function getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
  
        // Windows Phone must come first because its UA also contains "Android"
      if (/windows phone/i.test(userAgent)) {
          return "Windows Phone";
      }
  
      if (/android/i.test(userAgent)) {
          return "Android";
      }
  
      // iOS detection from: http://stackoverflow.com/a/9039885/177710
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
          return "iOS";
      }
  
      return "unknown";
  }

class assetModalContent extends React.PureComponent {

    

    constructor(props) {

        super(props)
        

          this.state = {
            
          }
  
      }

    componentDidMount() {

        if ( (this.props.passState.transactionData['transaction_description'] === "buy"|| this.props.passState.transactionData['transaction_description']  === "sell") || ( (this.props.passState.transactionData['transaction_description']  === "deposit" || this.props.passState.transactionData['transaction_description']  === "withdraw") && this.props.passState.transactionData['base_currency']['asset_type'] === 'fiat'  ) ) {

        this.props.selectSource('exchange')

        }

    }

    
    render () {

        return (    

                    <div>

                        { this.props.passState.transactionData['base_currency'] !== null && this.props.passState.transactionData['base_currency'] !== undefined ? 
                                    
                                ( (this.props.passState.transactionData['transaction_description'] === "buy"|| this.props.passState.transactionData['transaction_description']  === "sell") || ( (this.props.passState.transactionData['transaction_description']  === "deposit" || this.props.passState.transactionData['transaction_description']  === "withdraw") && this.props.passState.transactionData['base_currency']['asset_type'] === 'fiat'  ) ) ?

                                    null
                        
                                    :

                                    <div style={{width: '100%', display: 'flex', alignItems: 'center', paddingLeft: 12, paddingRight: 12}}>
                                        <div 
                                            className='basier-p4-caps transaction-bubble'
                                            onClick={() => this.props.selectSource('exchange')}
                                            style={{
                                                backgroundColor: `${ this.props.passState.source === 'exchange' ? '#0078DB' : '#E1E5E9'}`,
                                                marginBottom: 12, 
                                                fontSize: 20,
                                                fontWeight: 900, 
                                                padding: 20, 
                                                marginRight: 12,
                                                marginLeft: 0,
                                                minWidth: 120, 
                                                flexGrow: 1,
                                                height: 50, 
                                                borderRadius: 10, 
                                                
                                                }}>

                                                Exchange
                                        </div>

                                        <div 
                                            className='basier-p4-caps transaction-bubble'
                                            onClick={() => this.props.selectSource('storage')}                                    
                                            style={{
                                                backgroundColor: `${ this.props.passState.source === 'storage' ? '#0078DB' : '#E1E5E9'}`,
                                                marginBottom: 12, 
                                                fontSize: 20,
                                                fontWeight: 900, 
                                                padding: 20, 
                                                marginLeft: 0,
                                                minWidth: 120, 
                                                flexGrow: 1,
                                                height: 50, 
                                                borderRadius: 10
                                                
                                                }}>

                                                Storage
                                        </div>
                                    </div>
                            :

                            null
                        }


                        {
                            
                                this.props.passState.source === 'exchange' ?

                                <div style={{marginTop: 0, display: 'flex', alignItems: 'center',}}>

                                <div 
                                        style={{
                                        height: 60,
                                        display: 'flex', 
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        paddingLeft: 24,
                                        borderBottom: '1px solid #E1E5E9',
                                        borderTop: '1px solid #E1E5E9',
                                        width: '100%',
                                        textTransform: 'uppercase',
                                        fontWeight: 900
                                    }}
                                        onClick={() => this.props.exchangeModalOpen()}
                                        className="basier-p3">

                                        
                                        <div style={{color: 'black', fontWeight: 900}}>
                                            Exchange
                                        </div>

                                        <div style={{marginRight: 24, display: 'flex', alignItems: 'center'}}>

                                            <div style={{marginRight: 24, color: '#6A7E93'}}>
                                                {
                                                    this.props.passState.transactionData['exchange'] !== undefined && this.props.passState.transactionData['exchange'] !== null ? 
                                                    
                                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                                        <img src={this.props.passState.transactionData['exchange']['image_thumb']} style={{height: 24, width: 24, marginRight: 12}} alt="" />
                                                        <div>{this.props.passState.transactionData['exchange']['name']}</div>
                                                    </div>
                                                    
                                                    :
                                                    <div>select...</div>
                                                }
                                            </div>

                                            <div>
                                                >
                                            </div>
                                        </div>

                                        

                                    </div>

                                </div>

                                :

                             null

                        }

                        {  this.props.passState.source === 'exchange' && this.props.passState.transactionData['exchange'] !== null && this.props.passState.transactionData['exchange'] !== undefined && this.props.passState.transactionData['account'] !== -1 ? 


                            <div style={{marginTop: 0, display: 'flex', alignItems: 'center',}}>

                            <div 
                                    style={{
                                    height: 60,
                                    display: 'flex', 
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    paddingLeft: 24,
                                    borderBottom: '1px solid #E1E5E9',
                                    width: '100%',
                                    textTransform: 'uppercase',
                                    fontWeight: 900
                                }}
                                    onClick={() => this.props.accountModalOpen()}
                                    className="basier-p3">

                                    
                                    <div style={{color: 'black', fontWeight: 900}}>
                                        Account
                                    </div>

                                    <div style={{marginRight: 24, display: 'flex', alignItems: 'center'}}>

                                        <div style={{marginRight: 24, color: '#6A7E93'}}>
                                            {
                                                this.props.passState.transactionData['account'] !== undefined && this.props.passState.transactionData['account'] !== null ? 
                                                
                                                <div style={{display: 'flex', alignItems: 'center'}}>
                                                    <div>{this.props.passState.transactionData['account']['name']}</div>
                                                </div>
                                                
                                                :
                                                <div>select...</div>
                                            }
                                        </div>

                                        <div>
                                            >
                                        </div>
                                    </div>

                                    

                                </div>

                            </div>


                        : 
                        
                        null
                        
                        }

                        {  this.props.passState.source === 'storage' ? 


                            <div style={{marginTop: 0, display: 'flex', alignItems: 'center',}}>

                            <div 
                                    style={{
                                    height: 60,
                                    display: 'flex', 
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    paddingLeft: 24,
                                    borderBottom: '1px solid #E1E5E9',
                                    borderTop: '1px solid #E1E5E9',
                                    width: '100%',
                                    textTransform: 'uppercase',
                                    fontWeight: 900
                                }}
                                    onClick={() => this.props.storageWalletModalOpen()}
                                    className="basier-p3">

                                    
                                    <div style={{color: 'black', fontWeight: 900}}>
                                        Wallet
                                    </div>

                                    <div style={{marginRight: 24, display: 'flex', alignItems: 'center'}}>

                                        <div style={{marginRight: 24, color: '#6A7E93'}}>
                                            {
                                                this.props.passState.transactionData['storage'] !== undefined && this.props.passState.transactionData['storage'] !== null ? 

                                                this.props.passState.transactionData['storage'] !== -1 ? 
                                                
                                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                                        <div>{this.props.passState.transactionData['storage']['name']}</div>
                                                    </div>
                                                
                                                : 

                                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                                        <div>{this.props.passState.transactionData['storage_name']}</div>
                                                    </div>

                                                
                                                :
                                                <div>select...</div>
                                            }
                                        </div>

                                        <div>
                                            >
                                        </div>
                                    </div>

                                    

                                </div>

                            </div>


                        : 
                        
                        null
                        
                        }


                    </div>
                            
        )

    }
   
}

const mapStateToProps = state => {
    return {

        currency_symbol: state.auth.currency.symbol,
        coins: state.appwide.all_coins,
        transaction: state.interactions.transactionAddData,
        portfolio_users: state.data.scopedUsers, 
        user_id: state.auth.user_id, 
        user_first_name: state.auth.user_first_name,
        user_last_name: state.auth.user_last_name,
        
    };
};

const mapDispatchToProps = dispatch => {
    return {
        addTransaction: (data) =>
            dispatch(actions.addTransaction(data)),
        updateTransaction: (data) =>
            dispatch(actions.updateTransaction(data)),
        removeTransaction: (data) =>
            dispatch(actions.removeTransaction(data)), 
        callUserList: (portfolio) =>
          dispatch(dataActions.callUserList(portfolio)), 
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(assetModalContent);
