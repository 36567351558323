
import * as actionTypes from '../actions/actionTypes'

const initialState = {
    profit_data: {
        total_usd: 0,
        total_btc: 0,
        deposited: 0,
        net_profit: 0,
        total_active_profit: 0,
        total_realized_profit: 0,
        total_roi_usd: 0,
        balances: [],
        list_fiat: [],
        list_ico: [],
        list_crypto: [],
        balance_history: [],
        start_year: null,
        start_month: null,
        start_day: null,
    },
    loading: true,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.INIT_PROFITS:

            return {
                ...state,
                profit_data: { ...action.payload },
                loading: false
            }

        default:
            return state;

    }
}


export default reducer;