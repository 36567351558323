
export default () => {

  const getValue = (object, keys, defaultValue) => {
    let ret;
    let i = 0;
    const len = keys.length;
    try {
      while (i < len) {
        ret = object[keys[i]];
        object = ret;
        i++;
      }
    } catch (e) {}
    if (ret === undefined && defaultValue !== undefined) {
      ret = defaultValue;
    }
    return ret;
  }

	self.addEventListener('message', e => { // eslint-disable-line no-restricted-globals
    if (!e) return;
    let nftValues = {}
		const nfts = e.data[0]
		const collectionData = e.data[1]

    const collections = {}

    const nftContracts = Object.keys(nfts)
    const contractLength = nftContracts.length
    let i = 0;

    while (i < contractLength) {
      const contract = nftContracts[i]
      const lines = nfts[contract]

      const tokenKeys = Object.keys(lines)
      const tokenLen = tokenKeys.length
      let j = 0

      while (j < tokenLen) {
        const token = tokenKeys[j]
        const line = lines[token]
        const network = line.network
        if (!network) {
          j++ 
          continue
        }

        const thisNft = getValue(collectionData, [network, contract, token], null)
        if (thisNft) {
          const collectionSlug = thisNft.collection
          if (!collections[collectionSlug]) {
            collections[collectionSlug] = {
              id: collectionSlug, 
              network: network,
              name: thisNft.collectionName,
              address: thisNft.contractAddress,
              image: thisNft.image,
              original_purchase: 0,
              purchase: 0,
              quote_price: line.quote_price,
              tokens: []
            }
          }
          collections[collectionSlug].original_purchase += line.original_purchase
          collections[collectionSlug].purchase += line.purchase_price
          collections[collectionSlug].tokens.push({
            id: collectionSlug,
            name: thisNft.name,
            image: thisNft.image,
            original_purchase: line.original_purchase,
            purchase_price: line.purchase_price,
            quote_img: line.quote_img,
            quote_name: line.quote_name,
            quote_price: line.quote_price, 
            network: network,
          })
        }
        j++
      }
      i++
    }

    // const nfts = e.data[1]

		// balances.map((balance) => {
    //   const address = balance.short_name
    //   if (!(address in nftValues)) {
    //     const line = {
    //       id: balance.short_name, 
    //       purchase: balance.total_value,
    //       current: balance.total_value,
    //       current_price: balance.purchase_price,
    //       unit_purchase: balance.purchase_price,
    //       balance: balance.balance,
    //       network: balance.network,
    //       name: balance.coin_symbol,
    //       quote_price: 0,
    //       profit: 0,
    //       link_price: []
    //     }
    //     const nftData = nfts[address]
    //     const done = {}
    //     if (nftData) {
    //       const nftLines = Object.values(nftData)
    //       if (nftLines.length > 0) {
    //         nftLines.forEach(nftLine => {
    //           if (!(nftLine.contract_id in done)) {
    //             if (nftLine.link_price === "") {
    //               line.current = nftLine.current_price * line.balance
    //               line.current_price = nftLine.current_price
    //               line.profit = (nftLine.current_price * line.balance) - line.purchase
    //             } 
    //             line.quote_price = nftLine.quote_price
    //             line.image = nftLine.image
    //             line.quote_img = nftLine.quote_img
    //           }
    //           if (nftLine.link_price) {
    //             line.link_price.push({id: nftLine.token_id, url: nftLine.link_price})
    //           }
    //         })
    //         //const nftLine = nftLines[0]
    //       }
    //   }
    //   nftValues[address] = line
    //   }
		// })

    //console.log(collections)
    const data = Object.values(collections)
    const sortedNft = data.sort(function compare(a, b) {
			return b.purchase - a.purchase;
		})
		postMessage(Object.values(sortedNft));

	})
}
