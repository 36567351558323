import React from 'react'

const getValue = (dict, val) => {
    let ret = null; 
    try {ret = dict[val]} catch(e) {}
    return ret
}
const TableRow = (props) => {

   const { data } = props;

   return (
   <div style={{ 
        height: 75,
        paddingTop: 8, 
        paddingBottom: 8,  
        display: 'flex', 
        justifyContent: 'center', 
        flexDirection: 'column',
        paddingLeft: 6, 
        paddingRight: 6,
        width: '100%',
        cursor: 'pointer',
        borderBottom: '1px solid #CCC'
        }} 
        onClick={() => props.selectAccount(data)}
        className={`light-hover ${getValue(props.selected, 'id') == data.id ? 'selected-row' : '' }`}>  
        
        <div style={{width: '100%', minWidth: 220, display: 'flex', justifyContent: 'space-between'}} >
            <div className="truncate">
                <div className="basier-p3 truncate">{data.name}</div>
                <div className="basier-p4-caps" style={{color: '#6A7E93' }}>

                    <div style={{flex: 1, minWidth: 220}} className="basier-p3 ">

                        <div style={{display: 'flex', alignItems: 'center'}} >
                            {data.custom_exchange_check === true ? 
                            <div style={{height: 16, width: 16, marginRight: 6, textTransform: 'uppercase', fontSize: 11, color: 'black', backgroundColor: '#CCC', borderRadius: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>{data.source_name.slice(0,1)}</div>
                            :
                            <div style={{marginRight: 6}}><img src={data.image} alt="" style={{height: 16, width: 16, }} /></div>
                            }
                            <div className="basier-p4-caps truncate">{data.source_name}</div>
                        </div>

                    
                    </div>


                </div>
            </div>

            {data.connected && <div style={{color: '#4CDAC1', fontStyle: 'italic'}} className="basier-p4">Connected</div>}
        </div>
        <div className="basier-p4 truncate" style={{fontStyle: 'italic', color: '#6A7E93'}}>
            {data.address}
        </div>
        

        <div>
            
        </div>

    </div>
   )

}

export default TableRow