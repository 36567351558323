import React from "react";
import { connect } from "react-redux";
import TransactionWrapper from "./NTransactionWrapper";
import Hidden from "@material-ui/core/Hidden";
import * as actions from "../../../../../store/actions/data";
import * as interActions from "../../../../../store/actions/interactions";
import queryString from "query-string";
import Plus from "../../../../../Media/Plus.svg";
import Export from "../../../../../Media/exportCSV.svg";
import { PreviousButton } from "nuka-carousel";
import ExportTable from "../../../../AppWide/ExportTable";
import { backendUrl } from "../../../../../variables";
import { getValue } from "../../../../../helpers/helpers";

const transactionFilters = {
  base_id: "all",
  quote_id: "all",
  date: "all",
  user_id: "all",
  transaction_description: "all",
  sort: "desc",
};

const positionFilters = {
  base_id: "all",
  exchange_id: "all",
  position_id: "all",
  date: "all",
  user_id: "all",
  sort: "desc",
};

class TransactionSelect extends React.PureComponent {
  state = {
    page: 0,
    currentView: "transaction",
    filterValues: {},
  };

  componentDidMount() {
    this.getData(0, "0", transactionFilters, this.state.currentView);
  }

  componentDidUpdate(prevState) {
    if (
      this.props.account === true &&
      this.props.portfolioId !== prevState.portfolioId
    ) {
      this.getData(0, "0", transactionFilters, this.state.currentView);
      this.setState({
        page: 0,
        currentView: "transaction",
        filterValues: {},
      });
    }
  }

  exportData = () => {
    this.props.toggleModal({
      type: "export_modal",
      open: true,
      payload: {
        scope: `record of transactions for this ${
          this.props.account ? "account" : "portfolio"
        }`,
        submit: this.commitExport,
      },
    });
  };
  commitExport = () => {
    let url;
    if (this.props.account) {
      url = `${backendUrl}/v1/transactions/?u=${this.props.user_id}&a=${this.props.portfolioId}&ascope=${this.props.ascope}&export=export`;
    } else {
      url = `${backendUrl}/v1/transactions/?u=${this.props.user_id}&p=${this.props.portfolioId}&export=export`;
    }

    let headers = { "content-type": "application/json" };
    headers["Authorization"] = `Token ${this.props.token}`;
    fetch(url, { headers: headers }).then((res) => {
      this.props.showSnack({ message: "Export requested", variant: "success" });
      window.setTimeout(() => {
        this.setState((state) => ({ ...state, currentView: "export" }));
      }, 500);
    });
  };

  refreshAddData = () => {
    this.getTransactions(0, "0", transactionFilters);
    this.getOverallData();
    this.resetPage();
  };

  componentWillUnmount() {
    this.props.setTrFilter(transactionFilters);
    this.props.setPosFilter(positionFilters);
  }

  resetPage = () => {
    this.setState((state) => ({ ...state, page: 0 }));
  };

  movePage = (page) => {
    this.setState((state) => ({ ...state, page: page }));
    this.getData(page);
  };

  resetFilter = () => {
    this.getData(0, "1", transactionFilters, this.state.currentView);
  };

  toggleModalTransaction = () => {
    //props.toggleTransactionAddModal({open: true});
    this.props.toggleModal({
      type: "add_transaction",
      open: true,
      payload: { refresher: this.getOverallData },
    });
  };

  /* shouldComponentUpdate(nextProps) {
        if ( (this.props.transactionFilter != nextProps.transactionFilter) || (this.props.positionFilter != nextProps.positionFilter) ) {
            console.log('not re-rendering Transactions') 
            return false
        }
        console.log('re-rendering Transactions')
        return true
   } */

  switchType = (type) => {
    this.setState({ currentView: type, page: 0 });
    const filter =
      type === "position"
        ? this.props.positionFilter
        : this.props.transactionFilter;
    this.getData(0, "0", filter, type);
  };

  getTransactions = (page, skp, filters) => {
    this.props.getTransaction(
      this.props.user_id,
      this.props.portfolioId,
      page,
      skp,
      filters,
      this.props.account,
      this.props.ascope
    );
  };

  getPositions = (page, skp, filters) => {
    this.props.getPositions(
      this.props.user_id,
      this.props.portfolioId,
      page,
      skp,
      filters,
      this.props.account,
      this.props.ascope
    );
  };

  getData = (page, skp, filters, type) => {
    let toSkip = skp;
    let newFilters = filters;
    let getType = type;
    let getPage = page;

    if (type === undefined) {
      getType = this.state.currentView;
    }

    if (page === undefined) {
      getPage = this.state.page;
    }

    if (skp === undefined) {
      toSkip = "1";
    }

    if (newFilters === undefined) {
      newFilters =
        getType === "transaction"
          ? this.props.transactionFilter
          : this.props.positionFilter;
    }

    if (getType === "transaction") {
      this.getTransactions(getPage, toSkip, newFilters);
    } else {
      this.getPositions(getPage, toSkip, newFilters);
    }
  };

  getOverallData = (skip) => {
    if (skip === true) {
      this.getData();
    } else {
      this.props.componentDataFetch(
        "update",
        5,
        this.props.currency_id,
        this.props.user_id,
        this.props.portfolioId,
        {},
        {},
        this.props.inBitcoin
      );
      this.getData();
    }
  };

  render() {
    return (
      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <Hidden smDown>
          <div
            style={{
              width: "100%",
              minWidth: this.props.account === true ? 800 : 960,
              maxWidth: this.props.account === true ? "none" : 1440,
              paddingRight: 24,
              paddingLeft: 24,
            }}
          >
            <div
              className="basier-p4-caps"
              style={{
                width: "100",
                height: 20,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                {["transaction", "position", "export"].map((val, key) => {
                  return (
                    <React.Fragment key={key}>
                      <div
                        onClick={() => this.switchType(val)}
                        style={{
                          color:
                            this.state.currentView == val
                              ? "#0078DB"
                              : "#6A7E93",
                          fontWeight: this.state.currentView == val ? 900 : 400,
                          cursor: "pointer",
                        }}
                      >
                        {val === "export" ? "Exports" : `${val} List`}
                      </div>
                      {key !== 2 && (
                        <div style={{ marginLeft: 12, marginRight: 12 }}>|</div>
                      )}
                    </React.Fragment>
                  );
                })}
              </div>
              {this.state.currentView !== "export" && (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div
                    onClick={this.exportData}
                    style={{
                      marginRight: 24,
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    className="opacity-hover"
                  >
                    <div>
                      <img
                        src={Export}
                        alt="export"
                        style={{ height: 24, width: 24 }}
                      />
                    </div>
                    <div className="basier-p4-caps" style={{ marginLeft: 6 }}>
                      Export All
                    </div>
                  </div>
                  {this.props.account
                    ? null
                    : (getValue(this.props.roles, [this.props.portfolioId]) ===
                        "admin" || getValue(this.props.roles, [this.props.portfolioId]) ===
                        "manager") && (
                        <div
                          className="basier-p2 opacity-hover"
                          onClick={this.toggleModalTransaction}
                          style={{
                            textTransform: "uppercase",
                            display: "flex",
                            alignItems: "center",
                            cursor: "pointer",
                          }}
                        >
                          <div>
                            <img
                              src={Plus}
                              alt=""
                              style={{ marginRight: 6, height: 24 }}
                            />
                          </div>
                          <div className="basier-p4-caps">
                            Add A Transaction
                          </div>
                        </div>
                      )}
                </div>
              )}
            </div>

            {this.state.currentView === "export" ? (
              <ExportTable
                portfolioId={this.props.portfolioId}
                scope="transaction"
                account={this.props.account}
                ascope={this.props.ascope}
              />
            ) : (
              <TransactionWrapper
                resetPage={this.resetPage}
                getData={(page, skp, filters) =>
                  this.getData(page, skp, filters)
                }
                movePage={this.movePage}
                getOverallData={this.getOverallData}
                scope={this.state.currentView}
                portfolioId={this.props.portfolioId}
                page={this.state.page}
                account={this.props.account}
                ascope={this.props.ascope}
              />
            )}
          </div>
        </Hidden>

        <Hidden mdUp>
          <div style={{ width: "100%", paddingRight: 0, paddingLeft: 0 }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                marginRight: 6,
                maginLeft: 6,
              }}
            >
              {["transaction", "position"].map((val, key) => {
                return (
                  <React.Fragment key={key}>
                    <div
                      onClick={() => this.switchType(val)}
                      style={{
                        width: "50%",
                        height: 30,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: `${
                          this.state.currentView === val ? "#0078DB" : "#E1E5E9"
                        }`,
                        color: `${
                          this.state.currentView === val ? "white" : "black"
                        }`,
                        fontWeight: `${
                          this.state.currentView === val ? 900 : 400
                        }`,
                        textTransform: "uppercase",
                        marginRight: 6,
                        marginLeft: 6,
                        borderRadius: 10,
                      }}
                      className="basier-p4-caps touchableOpacity"
                    >
                      {`${val} List`}
                    </div>
                  </React.Fragment>
                );
              })}
            </div>

            <TransactionWrapper
              portfoliosData={this.props.portfoliosData}
              resetPage={this.resetPage}
              getData={(page, skp, filters) => this.getData(page, skp, filters)}
              movePage={this.movePage}
              getOverallData={this.getOverallData}
              scope={this.state.currentView}
              portfolioId={this.props.portfolioId}
              page={this.state.page}
              refreshAddData={this.refreshAddData}
            />
          </div>
        </Hidden>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user_id: state.auth.user_id,
    transactionFilter: state.interactions.transactionFilter,
    positionFilter: state.interactions.positionFilter,
    inBitcoin: state.data.bitcoinPf,
    currency_id: state.auth.currency.id,
    token: state.auth.token,
    roles: state.auth.roles,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getTransaction: (uid, pid, page, skp, filters, account, ascope) =>
      dispatch(
        actions.getTransaction(uid, pid, page, skp, filters, account, ascope)
      ),
    getPositions: (uid, pid, page, skp, filters, account, ascope) =>
      dispatch(
        actions.getPositionEntries(
          uid,
          pid,
          page,
          skp,
          filters,
          account,
          ascope
        )
      ),
    componentDataFetch: (
      scope,
      view,
      currency,
      user,
      portfolio,
      globalGraphData,
      portfolioGraphData,
      inBitcoin
    ) =>
      dispatch(
        actions.componentDataFetch(
          scope,
          view,
          currency,
          user,
          portfolio,
          globalGraphData,
          portfolioGraphData,
          inBitcoin
        )
      ),
    setTrFilter: (data) => dispatch(interActions.setTrFilter(data)),
    setPosFilter: (data) => dispatch(interActions.setPosFilter(data)),
    toggleModal: (data) => dispatch(interActions.toggleModal(data)),
    showSnack: (data) => dispatch(interActions.showSnack(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TransactionSelect);
