import React from 'react';
import PropTypes from 'prop-types';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import * as actions from "../../../store/actions/interactions";
import * as appwideActions from "../../../store/actions/appwide";
import * as authActions from "../../../store/actions/auth";
import * as dataActions from "../../../store/actions/data";
import { connect } from 'react-redux';
import BaseModalTop from '../../Portfolio/PortfolioComponents/Transactions/BaseModalTop';
import DateTimePicker from 'react-datetime';
import TypeList from '../../Portfolio/PortfolioComponents/Transactions/NewTransaction/QuestionBlocks/TypeList';
import loadingSpinner from '../../../Media/loadingSpinner.gif';
import CoinSearch from '../../AppWide/CoinSearch';
import okIcon from '../../../Media/okIcon.svg';
import SoloPort from '../../../Media/SoloPort.svg';
import FiatLarge from '../../AppWide/FiatLarge';
import OrgLarge from '../../AppWide/OrgList';
import Collablist from '../../AppWide/CollabList';
import axios from 'axios';
import {updatePortfolios} from '../../../helpers/portfolioHelpers';
import fieldValidator from '../../../helpers/validators';
import { backendUrl } from '../../../variables';
import Oops from '../../../Media/oops.svg';
import { withRouter} from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import UserList from '../../Portfolio/PortfolioComponents/Transactions/NewTransaction/UserList'
import UserFlatList from '../../MobileOnly/UserDrawer';
import Hidden from '@material-ui/core/Hidden';

const styles = theme => ({

    container: {
        width: '100%', 
        padding: 48, 
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            padding: 12, 
        },
    },

    fiatContainer: {
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        },
        [theme.breakpoints.up('md')]: {
            width: 324
        }
    },

    subcontainer: {
        [theme.breakpoints.up('md')]: {
            height: 320
        }
    }, 

    buttonWrapper: {
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 12, 
            paddingRight: 12, 
        },
        [theme.breakpoints.up('md')]: {

        }
    }

    });

class ResponsiveDialog extends React.Component {

    state = {
        userModal: false,
        loading: false,
        success: false, 
        action: 'transfer',
        data: {
            id: this.props.data.id,
            prevUser: this.props.data.oldUser,
            user_id: this.props.user_id, 
            newUser: this.props.user_id,
            scope: "extra_delete"
        }, 
        newUserName:  `${this.props.user_first} ${this.props.user_last}`,
        validation: {
            email: true, 
            tr: true, 
        },
        userList: []
    }

    userModalOpen = () => {
        this.setState(state => ({...state, userModal: true}))
    }

    userModalClose = () => {
        this.setState(state => ({...state, userModal: false}))
    }

    componentDidMount() {
        let userList = []
        this.props.data.users.map(val => {
            if (val.role != 'observer') {
                userList.push({value: val.user_id, label: `${val.first_name} ${val.last_name}`})
            }
        })
        this.setState(state => ({...state, userList: userList}))
    }
    handleClose = () => {
        this.props.toggleModal({type: null, open: false, payload: null})   
    }

    setConfirmEmail = (e) => {
        e.persist();
        this.setState(state => ({...state, data: {...state.data, confirmEmail: e.target.value}}))
    }

    handleUserChange = (val) => {
        this.setState(state => ({...state, newUserName: val.label, data: {...state.data, newUser: val.id}}))
    }

    handleUser = (val) => {
        this.setState(state => ({...state, newUserName: val.label, data: {...state.data, newUser: val.value}}))
        this.userModalClose()
    }

    updateState = (label, val) => {
        this.setState(state => ({...state, [label]: val}))
    }

    updateServer = () => {

        let email_val = false;
        let tr_val = false;
        if (this.state['action'] === 'transfer') {
            tr_val = fieldValidator(this.state.data['newUser'], ['isNotEmpty'])
            this.setState(state => ({...state, validation: {...state.validation, tr: tr_val}}))
        } else if (this.state['action'] === 'delete') {
            email_val = fieldValidator(this.state.data['confirmEmail'], ['isNotEmpty'])
            this.setState(state => ({...state, validation: {...state.validation, email: email_val}}))
        }
            
        let valid = this.state['action'] === 'transfer' ? tr_val : email_val

        if (valid) {

        //console.log(this.state)
            this.setState(state => ({...state, loading: true}))
            let headers = { "content-type": "application/json" };
            if (this.props.token !== null && this.props.token !== undefined) {

                headers['Authorization'] = `Token ${this.props.token}`

            }

            const url_call = `${backendUrl}/v1/portfolio/users/`

            const SendData = {
                ...this.state.data, 
                action: this.state.action,
            }
            axios.post(url_call, SendData, {headers: headers})
                .then(json => {

                    let newUsers = this.props.data['users'].filter(v => v.id !== this.props.data.id)
                    
                    const newData = {
                        ...this.state.data, 
                        users: newUsers,
                    }

                    this.props.showSnack({message: 'User deleted successfully', variant: "success"})
                    this.props.updateState(newData)

                })
                .catch( e => {
                    console.log(e)
                    this.setState(state => ({...state, loading: false, error: true}))
                    this.props.showSnack({message: 'Error in deleting user from portfolio', variant: "error"})

                })
        
        }

        this.handleClose()

    }


    render() {

    const { classes } = this.props;

    return (

      <div>
        
          <BaseModalTop forceSingle={true} title="Remove Collaborator" handleClose={this.handleClose} onClick={this.backToWelcome} welcome={this.state.welcome} entryType={this.state.type_selected} />


          {

            this.state.loading ? 

                <div style={{width: '100%', height: 530, backgroundColor: 'white', opacity: 0.6, display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'absolute', top: 90, zIndex: 15000}}>
                    <img style={{height: 64, with: 64}} src={loadingSpinner} alt="Loading..."/>
                </div>


            :

            this.state.success ? 

                <div style={{width: '100%', height: '100%', backgroundColor: 'white', opacity: 1, display: 'flex', flexDirection: 'column', paddingTop: 100, alignItems: 'center', position: 'absolute', top: 90, zIndex: 15000}}>
                    
                    <img style={{height: 64, with: 64}} src={okIcon} alt="OK!"/>
                    
                    <div style={{marginTop: 24}} className="basier-p2">
                    User deleted
                    </div>

                    <div className="basier-p3">
                        The user was successfully deleted.
                    </div>

                    <div style={{marginTop: 24, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>

                        <div className='basier' onClick={this.handleClose} 
                            style={{height: 40, borderRadius: 35, marginLeft: 12, marginRight: 12, marginTop: 24, cursor: 'pointer', color: 'white', backgroundColor: '#6A7E93', width: 150, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            Close
                        </div>

                    </div>
                </div>

            :

            null 

            }


          

            <div className={classes.container}>

                <div style={{width: '100%'}} className={classes.subcontainer}>
                    <div className="basier-p3" >
                        You are about to delete <span style={{fontWeight: 900, color: '#0078DB'}}>{this.props.data.userName}</span> from this portfolio. {this.props.data.userName} had <span style={{fontWeight: 900, color: '#0078DB'}}>{this.props.data.numberTx} transactions</span>. In order to ensure continuity, please select what to do with these transactions.
                    </div>

                    <div className="basier-p3" style={{fontWeight: 900, marginTop: 24}}>
                        What happens to {this.props.data.userName}'s transactions?
                    </div>

                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <div 
                            className={`${this.state['action'] === 'transfer' ? 'toggle-button-active' : 'toggle-button'}`} 
                            onClick={() => this.updateState('action', 'transfer')} 
                            style={{height: 40, width: 150, borderRadius: 35, marginRight: 12, marginTop: 24, cursor: 'pointer'}}>
                                Transfer
                        </div>

                        <div 
                            className={`${this.state['action'] === 'delete' ? 'toggle-button-active' : 'toggle-button'}`} 
                            onClick={() => this.updateState('action', 'delete')} 
                            style={{height: 40, width: 150, borderRadius: 35, marginRight: 12, marginTop: 24, cursor: 'pointer'}}>
                                Delete
                        </div>

                        <div 
                            className={`${this.state['action'] === 'keep' ? 'toggle-button-active' : 'toggle-button'}`} 
                            onClick={() => this.updateState('action', 'keep')} 
                            style={{height: 40, width: 150, borderRadius: 35, marginRight: 12, marginTop: 24, cursor: 'pointer'}}>
                                Keep
                        </div>
                    </div>

                    { this.state.action === 'transfer' && 
                    <React.Fragment>
                        <div className="basier-p3" style={{fontWeight: 900, marginTop: 24}}>
                            Who should the transactions be transferred to? 
                        </div>

                        <Hidden smDown>
                            <div style={{marginTop: 24, width: 250}}>
                                <UserList prov={true} userProvided={this.state.userList} initator={{value: this.state.data.newUser, label: this.state.newUserName}} handleChange={v => this.handleUserChange(v)}/>
                            </div>
                        </Hidden>

                        <Hidden mdUp>

                        <div style={{display: 'flex', alignItems: 'center', marginTop: 12}}>

                            <div 
                                    style={{
                                    height: 60,
                                    display: 'flex', 
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    paddingLeft: 12,
                                    borderBottom: '1px solid #E1E5E9',
                                    borderTop: '1px solid #E1E5E9',
                                    width: '100%',
                                    textTransform: 'uppercase',
                                    fontWeight: 900
                                }}
                                    onClick={() => this.userModalOpen()}
                                    className="basier-p3">

                                    
                                    <div style={{color: 'black', fontWeight: 900}}>
                                        User
                                    </div>

                                    <div style={{marginRight: 24, display: 'flex', alignItems: 'center'}}>

                                        <div style={{marginRight: 24, color: '#6A7E93'}}>
                                            {
                                                this.state.newUserName !== undefined && this.state.newUserName !== null ? 
                                                
                                                <div style={{display: 'flex', alignItems: 'center'}}>
                                                    <div>
                                                        {this.state.newUserName}
                                                    </div>
                                                </div>
                                                
                                                :
                                                <div>select...</div>
                                            }
                                        </div>

                                        <div>
                                            
                                        </div>
                                    </div>

                                    

                            </div>

                            </div> 

                        </Hidden>

                    </React.Fragment>
                    }

                    { this.state.action === 'delete' && 
                    <React.Fragment>
                        <div className="basier-p2" style={{fontWeight: 900, marginTop: 24, color: '#F9716B', textTransform: 'uppercase'}}>
                            Warning
                        </div>

                        <div className="basier-p3" style={{marginTop: 12}}>
                            Deleting transactions is permanent. To confirm the deletion request, please input your account email address in the field below.
                        </div>

                        <div style={{marginTop: 12, width: 250}}>
                            <input type='email' 
                            className={`light-input basier-p4 ${!this.state.validation.email ? 'error-input' : null}`}
                            value={this.state.data.confirmEmail} onChange={this.setConfirmEmail} />
                        </div>
                    </React.Fragment>
                    }


                </div>
            
            </div>

            <div style={{height: 70}}>
                    <div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}} className={classes.buttonWrapper}>

                        <div className='basier' onClick={this.handleClose} 
                            style={{height: 40, borderRadius: 35, marginRight: 12, marginTop: 24, cursor: 'pointer', color: 'white', backgroundColor: '#F9716B', width: 150, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            Cancel
                        </div>

                        <div className='button-next-blue' onClick={() => this.updateServer()} 
                            style={{height: 40, borderRadius: 35, marginLeft: 12,  marginTop: 24, cursor: 'pointer'}}>
                            Submit
                        </div>

                    </div> 
                </div>
            
            <UserFlatList users={this.state.userList} openModal={this.state.userModal} closeModal={this.userModalClose} handleSelect={(res) => this.handleUser(res)}/>

        </div>

    )
  }
}

ResponsiveDialog.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
};


const mapStateToProps = state => {
    return {

        modalPref: state.interactions.transactionAddModal,
        portfoliosData: state.data.portfoliosData,
        transaction: state.interactions.transactionAddData,
        all_coins: state.appwide.all_coins, 
        exchangeCheck: state.interactions.exchangeCheck, 
        exchanges: state.appwide.exchanges, 
        wallets: state.appwide.wallets, 
        user_id: state.auth.user_id,
        user_first: state.auth.user_first_name,
        user_last: state.auth.user_last_name,
        token: state.auth.token,
        currentView: state.data.currentView,
        currency: state.auth.currency.id,
        graphData: state.data.globalBalance,
        plan: state.auth.subscription,
        limits: state.appwide.limits,
        
    };
};

const mapDispatchToProps = dispatch => {
    return {
       toggleModal: (data) => 
            dispatch(actions.toggleModal(data)), 
        showSnack: (data) => dispatch(actions.showSnack(data)), 
        removeTransaction: (data) =>
            dispatch(actions.removeTransaction(data)), 
        setTrTime: (data) => 
            dispatch(actions.setTrTime(data)), 
        setInteraction: (data) => 
            dispatch(actions.setInteraction(data)), 
        getWallets: (user) => dispatch(appwideActions.getWallets(user)), 
        addPort: () => dispatch(appwideActions.addPort()), 
        setPorts: (ports) => dispatch(authActions.setPorts(ports)),
        componentDataFetch: (scope, view, currency, user, portfolio, globalGraphData, portfolioGraphData, inBitcoin) =>
            dispatch(dataActions.componentDataFetch(scope, view, currency, user, portfolio, globalGraphData, portfolioGraphData, inBitcoin)), 
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withMobileDialog()(withRouter(withStyles(styles)(ResponsiveDialog))));
