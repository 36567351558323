import React from 'react';
import { connect } from 'react-redux';
import ExchangeWallets from '../QuestionBlocks/ExchangeWallets';
import NewAccount from './NewAccount';

class ExchangeAccountQuestion extends React.PureComponent {

    componentDidMount() {
        this.props.updateTransaction("account", undefined)
        this.props.updateTransaction("account_name", undefined)
    }

    componentWillUnmount() {
        this.props.updateTransaction("account", undefined)
        this.props.updateTransaction("account_name", undefined)
    }

    handleUpdate = (data) => {
        this.props.updateTransaction(data.label, data.value)

    }

    handleNewAccount = (name) => {
        this.props.updateTransaction("account", -1)
        this.props.updateTransaction("account_name", name)
    }

    render () {
        let existingExchanges = null; 
        try {
            existingExchanges = this.props.wallets[this.props.user_id]['exchange'][this.props.transaction['exchange']['value']]
        } catch(e) {
            existingExchanges = null
        }
        let customExchanges = null; 
        try {
            customExchanges = this.props.wallets[this.props.user_id]['custom'][this.props.transaction['exchange']['value']]
        } catch(e) {
            customExchanges = null
        }
        // try {
        //     if (!custom) {
        //         name = this.props.transaction['exchange']['name']
        //     } else {
        //         name = this.props.transaction.account_data.custom_exchange
        //     }
        // } catch(e) {
            
        // }

        return (


            <div>
                {this.props.noTitle === true ?  null :
                <div className="basier-p4-caps" style={{marginBottom: 2, color: 'black', fontWeight: 900, display: 'flex', alignItems: 'center'}}>

                { (!this.props.transaction.exchange) ? 
                <div>Select or Create Account</div>
                :
                this.props.wallets[this.props.user_id]['exchange'][this.props.transaction['exchange']['value']] === undefined && 
                this.props.wallets[this.props.user_id]['custom'][this.props.transaction['exchange']['value']] === undefined ? 
                <div>New Account</div>
                :
                <div>Select or Create Account</div>
                }

                </div>}
                
                { !this.props.transaction.exchange ? 
                
                    <ExchangeWallets 
                        accounts={[]}
                        exchange={null}
                        updateTransaction={this.handleUpdate}
                        selected={this.props.transaction.account}
                        ref={this.props.innerRef}
                        getNextRef={this.props.getNextRef}
                        backToPrevious={this.props.backToPrevious}
                        propagateKey={true}
                    />
                :
                (!existingExchanges) && (!customExchanges)  ? 
                
                <div>
                    <NewAccount 
                        handleNewAccount={this.handleNewAccount} 
                        accountName={this.props.transaction.account_name}
                        name={this.props.transaction['exchange']['name']}
                        ref={this.props.innerRef}
                         type="exchange"
                    />
                </div>
                :
                    <ExchangeWallets 
                        accounts={this.props.wallets[this.props.user_id]['exchange']}
                        customs={this.props.wallets[this.props.user_id]['custom']}
                        exchange={this.props.transaction['exchange']}
                        updateTransaction={this.handleUpdate}
                        selected={this.props.transaction.account}
                        ref={this.props.innerRef}
                        getNextRef={this.props.getNextRef}
                        backToPrevious={this.props.backToPrevious}
                        propagateKey={true}
                    />

                
                }

                { (!this.props.transaction.exchange) ? 
                <div className="basier-p4" style={{color: '#0078DB', fontStyle: 'italic'}}>Select account or type new name to create.</div>
                :
                (this.props.wallets[this.props.user_id]['exchange'][this.props.transaction['exchange']['value']] === undefined) && (this.props.wallets[this.props.user_id]['custom'][this.props.transaction['exchange']['value']] === undefined) &&
                (this.props.wallets[this.props.user_id]['custom'][this.props.transaction['exchange']['value']] === undefined) && (this.props.wallets[this.props.user_id]['custom'][this.props.transaction['exchange']['value']] === undefined)
                ? 
                    <div className="basier-p4" style={{color: '#4CDAC1', fontStyle: 'italic'}}>We will create a new account with this name.</div>
                :
                this.props.transaction['account'] == -1 && this.props.transaction['account_name'] !== null && this.props.transaction['account_name'] !== undefined && this.props.transaction['account_name'] !== '' ? 

                    <div className="basier-p4" style={{color: '#4CDAC1', fontStyle: 'italic'}}>We will create a new account with this name.</div>
                    
                :

                this.props.transaction['account'] == -1 && (this.props.transaction['account_name'] === null || this.props.transaction['account_name'] === undefined || this.props.transaction['account_name'] === '') ? 
                    
                    <div className="basier-p4" style={{color: '#0078DB', fontStyle: 'italic'}}>Select account or type new name to create.</div>

                :

                    null

                }

            </div>

            
        )
    }


}

export default ExchangeAccountQuestion