import React from 'react';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { backendUrl } from '../../../../../../variables';
import { loadData } from '../../../../../../helpers/helpers';
import MonteCarloDetail from './MonteCarloDetail'



const styles = {
    root: {
      color: '#6A7E93',
      '&$checked': {
        color: '#0078DB',
      },
    },
    checked: {
        color: '#0078DB'
    },
  };

class BalanceGraph extends React.PureComponent {

    state = {
        data: null,
    }
    componentDidMount() {

        let headers = { "content-type": "application/json" };
        headers["Authorization"] = `Token ${this.props.token}`;

        const prod_url = `${backendUrl}/v1/analysis-detail/?p=${this.props.portfolioId}&u=${this.props.user_id}&id=${this.props.scopeId}`

        this.setState(state => ({...state, loading: true}))
        const data_fetch = loadData(prod_url, headers)
        data_fetch
            .then(json => {

                this.setState({data: json['data'] })
            })
        } 

    render () {
    
        return (

            this.state.data !== null && this.state.data !== undefined ?
            <div style={{width: '100%'}}>
                    

                        <div id="correl_container" style={{width: '100%', marginLeft: 0, paddingRight: 0, position: 'relative'}}>
                            <MonteCarloDetail data={this.state.data} />
                        </div>


            </div>

            :

            null
            
            
        )
    }


        
};

BalanceGraph.propTypes = {
    classes: PropTypes.object.isRequired,
  };
  

const mapStateToProps = state => {
    return {
        token: state.auth.token, 
        user_id: state.auth.user_id

    };
};

const mapDispatchToProps = dispatch => {
    return {
        
        
    };
  };

export default connect(
    mapStateToProps,
    mapDispatchToProps
)( withStyles(styles)(BalanceGraph));


