import React, { useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import ItemTypes from './ItemTypes'
import axios from 'axios';
import { backendUrl } from '../../variables'
import Grid from '@material-ui/core/Grid';
import bin from '../../Media/bin.svg'
import dragImg from '../../Media/drag.svg'

const style = {
  border: '1px dashed gray',
  padding: '0.5rem 1rem',
  marginBottom: '.5rem',
  backgroundColor: 'white',
  cursor: 'move',
}
const Card = ({ id, subs, token, user_id, current, short_name, change, symbol, image_thumb, price, index, moveCard, updateSubs, scope, deleteRecord}) => {
  const oldIndex = index
  const ref = useRef(null)
  const [, drop] = useDrop({
    accept: ItemTypes.CARD,
    hover(item, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }
      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex)
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex
    },
  })
  const [{ isDragging }, drag, preview] = useDrag({
    item: { type: ItemTypes.CARD, id, index },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
    end: (dropResult, monitor) => {

      let newOrder = []

      for (let i = 0; i < subs.length; ++i ) {
        let temp_new = {
          id: subs[i].id,
          rank: i + 1,
        }
        newOrder.push(temp_new)
        const payloadData = {
          "user_id": user_id, 
          "data": newOrder
        }
        
        updateSubs(subs)
        let headers = { "content-type": "application/json" };
            if (token !== null && token !== undefined) {

                headers['Authorization'] = `Token ${token}`

            }

        const url_call = `${backendUrl}/v1/coin_subs/`

        axios.patch(url_call, payloadData, {headers: headers})
            .then(json => {
                
            })
            .catch( e => {

            })

      }

    }
  })

  const opacity = isDragging ? 0 : 1
  drag(drop(ref))

  const handleDeleteRecord = (id) => {
    if (window.confirm("Are you sure you want to delete this coin from the watchlist?")) {
      deleteRecord(id)
    }
  }
  
  return (

    scope === 'desktop' ?

    <div 
        className="watch-list-container jiggle"
        ref={ref}
        style={{
            height: 200, width: 200, 
            padding: 24,
            opacity: opacity,
            display: 'flex',
            alignItems: 'flex-start', 
            flexDirection: 'column', 
            justifyContent: 'flex-start',
            border: change > 0 ? '1px solid #4CDAC1' : '1px solid #F9716B',
            cursor: 'move'

        }}
    >   
        <div style={{display: 'flex', alignItems: 'center'}}>
            <img src={image_thumb} style={{marginRight: 12, height: 35, width: 35}} alt="" />
            <div style={{display: 'flex', flexDirection: 'column'}}>
            <h4 style={{margin: 0}}>{symbol}</h4>
            <div className="basier-p4-caps truncate" style={{width: 110}}>{short_name}</div>
            </div>
        </div>
        
        <div style={{width: '100%', marginTop: 24, justifyContent: 'flex-start', textAlign: 'left', paddingLeft: 12}}>
            <div className="basier-p4-caps">
              Price
            </div>
            <h4>
                {price > 1 ? 
                    `${price.toLocaleString('en', {minimumFractionDigits:2, maximumFractionDigits: 2})} $`
                :
                    `${price.toLocaleString('en', {minimumFractionDigits:6, maximumFractionDigits: 6})}
                    $`
                }
            </h4>
            <div className="basier-p4-caps">
              24h change
            </div>
            <div style={{
                    fontWeight: 900,
                    color: change > 0 ? '#4CDAC1' : '#F9716B'}}>
                {
                change !== null ?   
                  change > 0 ? 

                  `+${change.toLocaleString('en', {minimumFractionDigits:2, maximumFractionDigits: 2})}%`
                  : 

                  `${change.toLocaleString('en', {minimumFractionDigits:2, maximumFractionDigits: 2})}%`
                  :
                  null
                }
            </div>
        </div>
      </div>

      :
      <div ref={preview}>
      <Grid container 
            style={{ 
            height: 65,
            display: 'flex', 
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingLeft: 12,
            borderBottom: '1px solid #E1E5E9',
            width: '100%',
            }} 
            className="asset-line basier-p3">

                    <Grid item style={{}} >

                    <span><img style={{height: 30, width: 30, marginRight: 6}} src={image_thumb} alt=""/></span>
                    <span className="basier-p2" style={{fontWeight: 'bold'}}>{symbol}</span>

                    </Grid> 

                   

                    <Grid id item style={{textAlign: 'right', paddingRight: 24, display: 'flex', alignItems: 'center'}} >
                     
                     <div style={{marginRight: 48}}>
                      <img onClick={() => handleDeleteRecord(id)} src={bin} style={{height: 25, width: 25}} alt="del" />
                    </div>

                    <div ref={ref} className="basier-p2" style={{marginTop: 6}}>
                          <img src={dragImg} style={{height: 25, width: 25}} alt="move" />
                    </div>

                    </Grid>

            </Grid>
            </div>

  )
}
export default Card
