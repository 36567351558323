import React from 'react'; 
import { connect } from 'react-redux';


const downArrow = 
    (
    <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.3333 8.35083L6.68421 13.9999L1 8.35083" stroke="#F9716B" stroke-width="2" stroke-miterlimit="10"/>
    <path d="M6.68457 14V0" stroke="#F9716B" stroke-width="2" stroke-miterlimit="10"/>
    </svg>
    )

const upArrow = 
(
<svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.999933 7.50329L6.64905 1.85418L12.3333 7.5033" stroke="#4CDAC1" stroke-width="2" stroke-miterlimit="10"/>
<path d="M6.64868 1.85414L6.64868 15.8541" stroke="#4CDAC1" stroke-width="2" stroke-miterlimit="10"/>
</svg>
)

class Summary extends React.PureComponent {

    state = {
        numdigits: 0,
      }

    componentDidMount() {

    if (this.props.inBitcoin === 'bitcoin') {
        this.setState(state => ({...state, numdigits: 2}))
    } else {
        this.setState(state => ({...state, numdigits: 0}))
    }

    }

    componentDidUpdate(prevProps) {

        if ((this.props.inBitcoin !== prevProps.inBitcoin)) {
          if (this.props.inBitcoin === 'bitcoin') {
              this.setState(state => ({...state, numdigits: 2}))
            } else {
              this.setState(state => ({...state, numdigits: 0}))
            }
  
        }
      }


    render() {
      return (

        this.props.data[this.props.portfolioId] !== undefined ? 

        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-around', textAlign: 'center'}}>

            <div>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    
                    <p className="basier-p2" style={{marginBottom: 12, color: 'black'}}>Investment</p>

                </div>

                <div style={{display: 'flex', justifyContent: 'center'}}>

                    <h4 style={{fontSize: 24}}>
                        {this.props.currency_symbol}&nbsp;
                        {this.props.data[this.props.portfolioId].deposit_check.toLocaleString('en', {minimumFractionDigits:this.state.numdigits, maximumFractionDigits: this.state.numdigits})}
                    </h4>

                </div>

                <div style={{display: 'flex', justifyContent: 'center'}}>
                    
                <p className="basier-p2" style={{marginBottom: 6, marginTop:6, color: '#6A7E93'}}>{this.props.currency_name}</p>

                </div>

            </div>

            <div>

                <div style={{display: 'flex', justifyContent: 'center'}}>
                    
                    <p className="basier-p2" style={{marginBottom: 12, color: 'black'}}>Total Profit</p>

                </div>

                <div style={{display: 'flex', justifyContent: 'center'}}>

                        <h4 style={{fontSize: 24}}>
                        {this.props.currency_symbol}&nbsp;
                        {this.props.data[this.props.portfolioId].net_profit.toLocaleString('en', {minimumFractionDigits:this.state.numdigits, maximumFractionDigits: this.state.numdigits})}
                        </h4>

                    </div>

                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                
                <div>
                        {
                            this.props.data[this.props.portfolioId].net_profit >= 0 ? 
                            <div className="arrow-container-large">{upArrow}</div>
                            : 
                            <div className="arrow-container-large">{downArrow}</div>

                        }
                        </div>
                        <p className={`basier-p2 ${this.props.data[this.props.portfolioId].net_profit > 0 ? 'growth' : 'decline'}`} style={{marginBottom: 6, marginTop:6}}>
                            { ((this.props.data[this.props.portfolioId].percent_change) * 100).toLocaleString('en', {minimumFractionDigits:2, maximumFractionDigits: 2}) }%
                        </p>

                </div>

            </div>

            <div>

                <div style={{display: 'flex', justifyContent: 'center'}}>
                    
                    <p className="basier-p2" style={{marginBottom: 6, color: 'black'}}>Transactions</p>

                </div>

                <div style={{display: 'flex', justifyContent: 'center'}}>

                        <h4 style={{fontSize: 24}}>
                        {this.props.data[this.props.portfolioId].number_transactions}
                        </h4>

                    </div>

                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                
                    <p className="basier-p2" style={{marginBottom: 6, marginTop:6, color: '#6A7E93'}}>{`${this.props.data[this.props.portfolioId].recent_transactions} in the last month`}</p>

                </div>

            </div>

            </div>

        : 

            <div style={{height: 112}}></div> 
        

    )
  }
}


const mapStateToProps = state => {
    return {
        currency_symbol: state.auth.currency.symbol,
        currency_name: state.auth.currency.id,
        inBitcoin: state.data.bitcoinPf
    };
};

export default connect(
    mapStateToProps,
    null
)(Summary);