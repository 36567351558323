import React from 'react'
import PortfolioBK from '../../../Media/PortfolioBK.svg'
import ArrowUp from '../../../Media/ArrowUp.svg'
import ArrowDown from '../../../Media/ArrowDown.svg'
import { connect } from 'react-redux';
import worker from "../../../workers/cardWorker";
import WebWorker from "../../../workers/WebWorker";
import { nFormatter } from '../../../helpers/helpers.js'

class PortfolioCard extends React.PureComponent {

    state = {
        coins: [],
        remaining: null,
    }

    componentDidMount() {

        this.worker = new WebWorker(worker);

        this.worker.addEventListener('message', event => {
            const coins = event.data[0]
            const remanining = event.data[1]

            //console.log(event.data)

            this.setState(state => ({...state, 
                                    coins: coins, 
                                    remaining: remanining
                                    }))

        });

        this.worker.postMessage(this.props.data.balances)



    }
    render() {
    
    return (
        
        <div style= {{
            height: 431, 
            width: 280, 
            borderRadius: 20, 
            boxShadow: '0px 10px 10px rgba(225, 229, 233, 0.3)',
            backgroundColor: '#fff',
            position: 'relative', 
            display: 'inline-block', 
            overflow: 'hidden',

        }}
        className="portfolio-card carousel-card hvr-grow-shadow-medium">

            <div style={{
                position: 'absolute',
                bottom: 0, 
                left: 0,
                transform: this.props.data.percent_change < 0 ? 'scale(-1, 1)' : null
            }}>
            <img src={PortfolioBK} alt=""/>
            </div>

            <div style={{
                position: 'absolute',
                top: 27, 
                left: 15,
                color: 'black'
                }} 
                className="basier-p2">
                {this.props.data.portfolio_name}
            </div>

            <div style={{
                position: 'absolute', 
                left: 15, 
                top: 63}}>
                <h4>{this.props.currency_symbol} {this.props.data.total_value.toLocaleString('en', {minimumFractionDigits:2, maximumFractionDigits: 2})}</h4>
            </div>
            
            { this.props.data.percent_change === 'TBD' ? null : 
            <div style={{
                position: 'absolute', 
                display:'flex',
                alignItems: 'center',
                width: '90%',
                left: 15, 
                top: 97}}>

                {
                    this.props.data.percent_change > 0 ? 
                        <div style={{display: 'flex', alignItems: 'center', marginBottom: 2}}><img src={ArrowUp} alt=""/></div>
                        :
                        <div style={{display: 'flex', alignItems: 'center', marginBottom: 2}}><img src={ArrowDown} alt=""/></div>
                }

                

                <div className={`basier-p4 ${this.props.data.percent_change > 0 ? 'growth' : 'decline'}`} style={{position: 'absolute', display:'flex', left: 15, width: '90%'}}>
                
                    <div style={{display:'flex', alignItems: 'center'}}>
                        {`${(this.props.data.percent_change * 100).toLocaleString('en', {minimumFractionDigits:2, maximumFractionDigits: 2})}%`}
                    </div>
                    <div style={{color: '#6A7E93', fontStyle: 'italic'}}>
                        &nbsp; since inception
                    </div>
                
                </div>
            </div>}

            <div style={{
                position: 'absolute',
                top: 233, 
                left: 15,
                }} 
                 >
                
                <span style={{color: '#6A7E93'}} className="basier-p4">
                    <span style={{position: 'absolute'}}>Investment</span>
                    <span style={{position: 'absolute', left: 150}}>Transactions</span>
                </span>
            </div>

            <div style={{
                position: 'absolute',
                top: 255, 
                left: 15,
                }} 
                 >
                 <span>
                    
                    <span style={{position: 'absolute', width: 120}}>
                        <h5>{this.props.currency_symbol} 
                            {this.props.data.deposited > 1000000 ? 
                            <span>&nbsp;{nFormatter(this.props.data.deposited, 2)}</span>
                            :
                            <span>&nbsp;{this.props.data.deposited.toLocaleString('en', {minimumFractionDigits:2, maximumFractionDigits: 2})}</span>
                            }
                        </h5>
                    </span>
                    
                    
                    
                    <span style={{position: 'absolute', left: 150}}><h5>{this.props.data.number_transactions.toLocaleString('en', {minimumFractionDigits:0, maximumFractionDigits: 0})}</h5></span>
                </span>
            </div>

            <div style={{
                position: 'absolute',
                top: 300, 
                left: 15,
                }} 
                 >
                
                <span style={{color: '#6A7E93'}} className="basier-p4">
                    <span style={{position: 'absolute'}}>Coins</span>
                </span>
            </div>

            <div style={{
                position: 'absolute',
                top: 325, 
                left: 15,
                width: '100%'
                }} 
                 >
                
                <span style={{color: '#6A7E93', width: '90%', position:'relative', display: 'flex', flexWrap: 'wrap'}} className="basier-p4">

                    
                    {      

                        this.state.coins.map((coin, index) =>
                            <div key={index} style={{marginRight: 10, marginBottom: 10}}>
                                <img style={{height: 15, width: 15}} src={coin.image} alt=""/>
                            </div>

                      )
                    }

                    { this.state.remaining > 0 ? <span>and {this.state.remaining} more...</span> : null }
                   
                </span>
            </div>


            <div style={{
                position: 'absolute',
                top: 390, 
                left: 15,
                }} 
                 >
                
                <span style={{color: '#6A7E93', fontWeight: 400}} className="basier-p4-caps">
                    <span style={{width: '90%'}}>
                        {
                            this.props.data.portfolio_type === 'individual' ? 
                            <span>Solo portfolio</span>
                            : 
                            <span>Multi user - {this.props.data.number_users} users</span>
                        }
                    </span>
                </span>
            </div>

        </div>
    )
  }   
}


const mapStateToProps = state => {
    return {
        currency_symbol: state.auth.currency.symbol,
    };
};


export default connect(
    mapStateToProps,
    null
)(PortfolioCard);
