import React from 'react';
import { connect } from 'react-redux';

class PortfolioTable extends React.PureComponent {

    render () {

        return (
            <div 
                style={{paddingBottom: 0, paddingRight: 24, paddingLeft: 48, display: 'flex', justifyContent: 'flex-start', alignItems: 'baseline'}}>

                <div style={{textAlign: 'left', width: '30%'}} className="basier-p4-caps">
                    Portfolio Name
                </div>
                

                <div style={{textAlign: 'right', paddingRight: 5, flex: 1}}>
                    <span className="basier-p4-caps">Total Value</span>
                </div>


                <div style={{textAlign: 'right', paddingRight: 30, width: 150}}>
                    <span className="basier-p4-caps">24h %</span>
                </div>

            </div>
        )
    }


}

const mapStateToProps = state => {
    return {

    };
};

const mapDispatchToProps = dispatch => {
    return {
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(PortfolioTable)
