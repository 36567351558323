import React from "react";
import { connect } from "react-redux";
import portSelect from "../../../../../Media/PortSelect.svg";
import editIcon from "../../../../../Media/editBlue.svg";
import Divider from "@material-ui/core/Divider";
import axios from "axios";
import { backendUrl } from "../../../../../variables";
import * as authActions from "../../../../../store/actions/auth";
import okGreen from "../../../../../Media/okGreen.svg";
import cancelRed from "../../../../../Media/cancelRed.svg";
import UserList from "../../Transactions/NewTransaction/UserList";
import { updatePortfolios } from "../../../../../helpers/portfolioHelpers";
import * as interActions from "../../../../../store/actions/interactions";
import { getValue } from "../../../../../helpers/helpers";

class GeneralSettings extends React.PureComponent {
  state = {
    newName: null,
    newOwner: null,
    nameEdit: false,
    ownerEdit: false,
    data: undefined,
  };

  componentDidMount() {
    if (
      this.props.portfoliosData !== undefined &&
      this.props.portfoliosData[this.props.portfolioId] !== undefined
    ) {
      let usersScope = [];
      let inScope = this.props.portfoliosData[
        this.props.portfolioId
      ].users.filter(
        (v) =>
          (v.plan_type === "pro" || v.plan_type === "unlimited") &&
          v.active == 1
      );

      inScope.map((val) => {
        let temp_list = {
          label: `${val.first_name} ${val.last_name}`,
          value: val.user_id,
        };
        usersScope.push(temp_list);
      });

      let currentOwner = {
        value: this.props.portfoliosData[this.props.portfolioId].owner,
        label: this.props.portfoliosData[this.props.portfolioId].owner_name,
      };

      this.setState((state) => ({
        ...state,
        usersScope: usersScope,
        currentOwner: currentOwner,
        data: this.props.portfoliosData[this.props.portfolioId],
      }));
    }
  }

  componentDidUpdate(prevProps) {
    const today = new Date();
    if (this.props.portfoliosData !== prevProps.portfoliosData) {
      if (this.props.portfoliosData !== undefined) {
        let usersScope = [];
        let inScope = this.props.portfoliosData[
          this.props.portfolioId
        ].users.filter(
          (v) =>
            (v.plan_type === "pro" || v.plan_type === "unlimited") &&
            v.active == 1
        );

        inScope.map((val) => {
          const sendDate = new Date(val.expiry);

          if (sendDate >= today || val.plan_type === "unlimited") {
            let temp_list = {
              label: `${val.first_name} ${val.last_name}`,
              value: val.user_id,
            };
            usersScope.push(temp_list);
          }
        });

        let currentOwner = {
          value: this.props.portfoliosData[this.props.portfolioId].owner,
          label: this.props.portfoliosData[this.props.portfolioId].owner_name,
        };

        this.setState((state) => ({
          ...state,
          usersScope: usersScope,
          currentOwner: currentOwner,
          data: this.props.portfoliosData[this.props.portfolioId],
        }));
      }
    }
  }

  onChangeName = (e) => {
    e.persist();
    this.setState((state) => ({ ...state, newName: e.target.value }));
  };

  handleOwnerChange = (v) => {
    this.setState((state) => ({ ...state, newOwner: v }));
  };

  toggleOwnerEdit = () => {
    this.setState((state) => ({ ...state, newName: null, ownerEdit: true }));
  };

  closeName = () => {
    this.setState((state) => ({ ...state, newName: null, nameEdit: false }));
  };

  closeOwner = () => {
    this.setState((state) => ({ ...state, newOwner: null, ownerEdit: false }));
  };

  editName = () => {
    this.setState((state) => ({ ...state, newName: null, nameEdit: true }));
  };

  onKeyDown = (e) => {
    switch (e.keyCode) {
      case 27: {
        this.closeName();
        break;
      }
      case 13: {
        this.submitEditServer("name", this.state.newName);
        break;
      }

      default: {
        break;
      }
    }
  };

  convertToMulti = () => {
    this.props.toggleModal({
      open: true,
      type: "convert_portfolio",
      payload: { data: { portfolioId: this.props.portfolioId } },
      onClose: null,
    });
  };

  deletePort = () => {
    this.props.toggleModal({
      open: true,
      type: "delete_portfolio",
      payload: {
        data: {
          portfolioId: this.props.portfolioId,
          numberTransactions: this.state.data["number_transactions"],
        },
      },
      onClose: null,
    });
  };

  submitEditServer = (field, value) => {
    if (field === "name") {
      if (value === null || value == this.state.data["portfolio_name"]) {
        this.closeName();
        return;
      }
    } else if (field === "owner") {
      if (value === null || value == this.state.data["owner"]) {
        this.closeOwner();
        return;
      }
    }

    const sendData = {
      [field]: value,
      user_id: this.props.user_id,
    };
    let headers = { "content-type": "application/json" };
    if (this.props.token !== null && this.props.token !== undefined) {
      headers["Authorization"] = `Token ${this.props.token}`;
    }

    const url_call = `${backendUrl}/v1/portfolio/${this.props.portfolioId}/`;

    axios
      .patch(url_call, sendData, { headers: headers })
      .then((json) => {
        if (field === "name") {
          this.setState((state) => ({
            ...state,
            data: { ...state.data, portfolio_name: value },
          }));
          const oldPorts = this.props.list_portfolios;
          let newPorts = [];

          oldPorts.map((val) => {
            if (val.id == this.props.portfolioId) {
              val.name = value;
            }
            newPorts.push(val);
          });
          const success = updatePortfolios(newPorts);
          if (success) {
            this.props.setPorts(newPorts);
          }
          this.props.showSnack({
            message: "Portfolio name changed successfully",
            variant: "success",
          });
          this.closeName();
        } else if (field === "owner") {
          const newOwnerData = this.state.usersScope.filter(
            (v) => v.value == value
          );
          const newOwnerName = newOwnerData[0]["label"];
          const newOwnerId = newOwnerData[0]["value"];
          this.setState((state) => ({
            ...state,
            currentOwner: newOwnerData[0],
            data: {
              ...state.data,
              owner: newOwnerId,
              owner_name: newOwnerName,
            },
          }));
          this.props.showSnack({
            message: "Portfolio owner changed successfully",
            variant: "success",
          });
          this.closeOwner();
        }
      })
      .catch(() => {
        this.props.showSnack({
          message: "Error in updating portfolio",
          variant: "error",
        });
      });
  };

  render() {
    return this.state.data !== undefined ? (
      <div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: 0,
            marginBottom: 48,
          }}
        >
          <img
            style={{ height: 50, width: 50 }}
            className="no-select"
            src={portSelect}
            alt=""
          />
          <div style={{ marginLeft: 12 }}>
            {this.state.nameEdit ? (
              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <input
                    autoFocus={true}
                    autoComplete="false"
                    className="light-input basier-p2"
                    style={{ width: 200 }}
                    onKeyDown={(e) => this.onKeyDown(e)}
                    onChange={(e) => this.onChangeName(e)}
                  />
                  <div
                    style={{
                      width: "30%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{ marginLeft: 6, marginRight: 6 }}
                      onClick={() =>
                        this.submitEditServer("name", this.state.newName)
                      }
                    >
                      <img
                        className="opacity-hover"
                        src={okGreen}
                        style={{ height: 36, width: 36, cursor: "pointer" }}
                        alt="ok"
                      />
                    </div>
                    <div
                      style={{ marginRight: 6 }}
                      onClick={() => this.closeName()}
                    >
                      <img
                        className="opacity-hover"
                        src={cancelRed}
                        style={{ height: 36, width: 36, cursor: "pointer" }}
                        alt="no"
                      />
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div style={{ display: "flex", alignItems: "baseline" }}>
                <div>
                  <h2 style={{ margin: 0 }}>
                    <span style={{}}>{this.state.data["portfolio_name"]}</span>
                  </h2>
                  <div className="basier-p4-caps" style={{ marginTop: 4 }}>
                    {this.state.data["portfolio_type"] === "individual"
                      ? "Solo Portfolio"
                      : "Multi-User Portfolio"}
                  </div>
                </div>
                {getValue(this.props.roles, [this.props.portfolioId]) ===
                  "admin" && (
                  <div
                    className="basier-p4-caps opacity-hover"
                    onClick={this.editName}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginLeft: 12,
                      cursor: "pointer",
                    }}
                  >
                    <img
                      src={editIcon}
                      alt=""
                      style={{ width: 20, height: 20 }}
                    />
                    <div style={{ marginLeft: 6, color: "#0078DB" }}>
                      Change Name
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>

        <Divider />

        <div style={{ height: 150 }}>
          <div className="basier-p4-caps" style={{ marginTop: 24 }}>
            Owner
          </div>

          {this.state.ownerEdit ? (
            <div>
              <div
                style={{ display: "flex", alignItems: "center", marginTop: 24 }}
              >
                <div style={{ width: 250 }}>
                  <UserList
                    prov={true}
                    userProvided={this.state.usersScope}
                    initator={this.state.currentOwner}
                    handleChange={(v) => this.handleOwnerChange(v)}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: 12,
                  }}
                >
                  <div
                    style={{ marginLeft: 6, marginRight: 6 }}
                    onClick={() =>
                      this.submitEditServer("owner", this.state.newOwner)
                    }
                  >
                    <img
                      className="opacity-hover"
                      src={okGreen}
                      style={{ height: 36, width: 36, cursor: "pointer" }}
                      alt="ok"
                    />
                  </div>
                  <div
                    style={{ marginRight: 6 }}
                    onClick={() => this.closeOwner()}
                  >
                    <img
                      className="opacity-hover"
                      src={cancelRed}
                      style={{ height: 36, width: 36, cursor: "pointer" }}
                      alt="no"
                    />
                  </div>
                </div>
              </div>

              <div style={{ marginTop: 6 }}>
                Note: Only users with active Pro plans can be selected as owners
                for collaborative porfolios.
              </div>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                height: 150,
              }}
            >
              <h3 style={{ marginTop: 24 }}>{this.state.data["owner_name"]}</h3>
              {getValue(this.props.roles, [this.props.portfolioId]) === "admin" && (
                <button
                  className={`button-blue opacity-hover`}
                  style={{
                    height: 40,
                    borderRadius: 35,
                    marginTop: 12,
                    width: 150,
                    cursor: "pointer",
                    marginRight: 12,
                  }}
                  onClick={() => this.toggleOwnerEdit()}
                >
                  Change owner
                </button>
              )}
            </div>
          )}
        </div>

        <Divider />

        {getValue(this.props.roles, [this.props.portfolioId]) === "admin" && (
          <div style={{ marginTop: 24 }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              {this.props.sub !== undefined &&
              this.state.data["portfolio_type"] === "individual" &&
              (this.props.sub["plan"]["plan_id"] === "unlimited" ||
                (this.props.sub["plan"]["plan_id"] === "pro" &&
                  this.props.sub["subscription_status"] == 1 &&
                  new Date() < new Date(this.props.sub["end"]))) ? (
                <button
                  className={`button-blue opacity-hover`}
                  style={{
                    height: 40,
                    borderRadius: 35,
                    marginTop: 12,
                    width: 180,
                    cursor: "pointer",
                    marginRight: 12,
                  }}
                  onClick={() => this.convertToMulti()}
                >
                  Convert to Multi-User
                </button>
              ) : null}

              <button
                className={`button opacity-hover`}
                style={{
                  backgroundColor: "#F9716B",
                  color: "white",
                  height: 40,
                  borderRadius: 35,
                  marginTop: 12,
                  width: 150,
                  cursor: "pointer",
                  marginRight: 12,
                  border: "none",
                }}
                onClick={() => this.deletePort()}
              >
                Delete Portfolio
              </button>
            </div>
          </div>
        )}
      </div>
    ) : null;
  }
}

const mapStateToProps = (state) => {
  return {
    user_id: state.auth.user_id,
    token: state.auth.token,
    sub: state.auth.subscription,
    list_portfolios: state.auth.list_portfolios,
    roles: state.auth.roles,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setPorts: (ports) => dispatch(authActions.setPorts(ports)),
    showSnack: (data) => dispatch(interActions.showSnack(data)),
    toggleModal: (data) => dispatch(interActions.toggleModal(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GeneralSettings);
