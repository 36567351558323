import React from "react";
import { connect } from "react-redux";
import { CardElement, ElementsConsumer } from "@stripe/react-stripe-js";
import axios from "axios";
import { backendUrl } from "../../../variables";
import { withStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";

const style = {
  base: {
    color: "#32325d",
    fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
    fontSmoothing: "antialiased",
    fontSize: "16px",
    "::placeholder": {
      color: "#aab7c4",
    },
  },
  invalid: {
    color: "#fa755a",
    iconColor: "#fa755a",
  },
  checkBoxRoot: {
    padding: 0,
  },
};

class PaymentForm extends React.PureComponent {
  state = {
    checkedConsent: false,
  };

  handleCheck = () => {
    this.setState((state) => ({
      ...state,
      checkedConsent: !state.checkedConsent,
    }));
  };

  onSubscriptionComplete = ({error}) => {


    if (error === true) {

        return;
    }

    this.props.handleSuccess();
    this.props.handleClose();
  };

  handlePaymentThatRequiresCustomerAction = ({
    subscription,
    invoice,
    paymentMethodId,
    priceId,
    isRetry
  }) => {
    if (subscription && subscription.status === "active") {
      // Subscription is active, no customer actions required.
      /* this.props.handleSuccess();
      this.props.handleClose(); */

      return { subscription, priceId, paymentMethodId };
    }

    // If it's a first payment attempt, the payment intent is on the subscription latest invoice.
    // If it's a retry, the payment intent will be on the invoice itself.
    let paymentIntent = invoice
      ? invoice.payment_intent
      : subscription.latest_invoice.payment_intent;

    if (
      paymentIntent.status === "requires_action" ||
      (isRetry === true && paymentIntent.status === "requires_payment_method")
    ) {
      return this.props.stripe
        .confirmCardPayment(paymentIntent.client_secret, {
          payment_method: paymentMethodId,
        })
        .then((result) => {
          if (result.error) {
            // Start code flow to handle updating the payment details.
            // Display error message in your UI.
            // The card was declined (i.e. insufficient funds, card has expired, etc).


            const error = true;
            //this.props.setError("Your card has been declined. Please try again with a new card");
            //return { subscription, priceId, paymentMethodId, error };
            throw result
          } else {
            if (result.paymentIntent.status === "succeeded") {
              // Show a success message to your customer.
              // There's a risk of the customer closing the window before the callback.
              // We recommend setting up webhook endpoints later in this guide.

              /* this.props.handleSuccess();
              this.props.handleClose(); */
              return { subscription, priceId, paymentMethodId };
            } else {

            }
          }
        })
        .catch((error) => {

          return { subscription, priceId, paymentMethodId };
        });
    } else {
      // No customer action needed.
      /* this.props.handleSuccess();
      this.props.handleClose(); */

      return { subscription, priceId, paymentMethodId };
    }
  }

  handleRequiresPaymentMethod = ({ subscription, paymentMethodId, priceId, error }) => {
    if (subscription.status === "active" && error !== true) {
      // subscription is active, no customer actions required.

      return { subscription, priceId, paymentMethodId };
    } else if (
      subscription.latest_invoice.payment_intent.status ===
      "requires_payment_method"
    ) {

      // Using localStorage to store the state of the retry here
      // (feel free to replace with what you prefer)
      // Store the latest invoice ID and status
      localStorage.setItem("latestInvoiceId", subscription.latest_invoice.id);
      localStorage.setItem(
        "latestInvoicePaymentIntentStatus",
        subscription.latest_invoice.payment_intent.status
      );
      this.props.setError("Your card was declined. Please try a new card");
      return { error: true }
    } else {

      return { subscription, priceId, paymentMethodId };
    }
  }

  handleSubmit = (ev) => {
    // We don't want to let default form submission happen here, which would refresh the page.
    ev.preventDefault();

    if (this.state.checkedConsent) {
      if (this.props.setupOnly === true) {
      } else {
        this.props.setError(null);
        this.props.setLoading(true, "Authorizing card with your bank");
      }

      if (!this.props.stripe || !this.props.elements) {
        // Stripe.js has not yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
      }
      // Get a reference to a mounted CardElement. Elements knows how
      // to find your CardElement because there can only ever be one of
      // each type of element.
      const card = this.props.elements.getElement(CardElement);
      // If a previous payment was attempted, get the latest invoice
      const latestInvoicePaymentIntentStatus = localStorage.getItem(
        "latestInvoicePaymentIntentStatus"
      );

      if (latestInvoicePaymentIntentStatus === "requires_payment_method") {
        const invoiceId = localStorage.getItem("latestInvoiceId");
        const isPaymentRetry = true;
        // create new payment method & retry payment on invoice with new payment method
        this.createPaymentMethod({
          card,
          isPaymentRetry,
          invoiceId,
        });
      } else {
        // create new payment method & create subscription
        this.createPaymentMethod({ card });
      }
    } else {
      this.props.setError(
        "Please agree with the Terms and conditions and the payment consent."
      );
    }
  };

  createPaymentMethod = ({ card, isPaymentRetry, invoiceId }) => {
    this.props.stripe.createPaymentMethod({
      type: "card",
      card: card,
    }).then(result => {

    if (result.error) {
      this.props.setError("There was an issue setting up your card for payment. Please try again.")

    } else {

      const paymentMethodId = result.paymentMethod.id;
      // Create the subscription
      if (isPaymentRetry) {
        // Update the payment method and retry invoice payment
        this.retryInvoiceWithNewPaymentMethod({
          customerId: this.props.curstomer_id,
          paymentMethodId: result.paymentMethod.id,
          invoiceId: invoiceId,
          priceId: this.props.planId,
        });
      } else {
      this.props
        .submit(paymentMethodId)
        .then((result) => {
          return {
            // Use the Stripe 'object' property on the
            // returned result to understand what object is returned.
            subscription: result.subscription,
            paymentMethodId: paymentMethodId,
            priceId: result.priceId,
          };
        })
        .then(this.handlePaymentThatRequiresCustomerAction)
        .then(this.handleRequiresPaymentMethod)
        .then(this.onSubscriptionComplete)
        .catch((error) => {
          // An error has happened. Display the failure to the user here.
          // We utilize the HTML element we created.
          //this.props.setError(error);

        })
        /* .then((res) => {
          const data = res;
          if (data.subscription.latest_invoice.payment_intent.status === "requires_action") {
            this.handlePaymentThatRequiresCustomerAction(
              data.subscription,
              false,
              paymentMethodId,
              data.priceId,
            );
          }
        }); */
        }
    }
  })
}
  retryInvoiceWithNewPaymentMethod = ({
    customerId,
    paymentMethodId,
    invoiceId,
    priceId,
  }) => {
    let headers = { "content-type": "application/json" };
    if (this.props.token !== null && this.props.token !== undefined) {
      headers["Authorization"] = `Token ${this.props.token}`;
    }

    const sendData = {
        user_id: this.props.user_id,
        payment_id: paymentMethodId,
        invoice_id: invoiceId,
        old_payment: null,
    }

    const url_call = `${backendUrl}/v1/sub/retry/`;
    return axios.post(url_call, sendData, { headers: headers })
        // If the card is declined, display an error to the user.
        .then((response) => {
          const result = response.data.data
          if (result.error) {
            // The card had an error when trying to attach it to a customer
            throw result;
          }
          return result;
        })
        // Normalize the result to contain the object returned
        // by Stripe. Add the addional details we need.
        .then((result) => {
          return {
            // Use the Stripe 'object' property on the
            // returned result to understand what object is returned.
            invoice: result,
            paymentMethodId: paymentMethodId,
            priceId: priceId,
            isRetry: true,
          };
        })
        // Some payment methods require a customer to be on session
        // to complete the payment process. Check the status of the
        // payment intent to handle these actions.
        .then(this.handlePaymentThatRequiresCustomerAction)
        // No more actions required. Provision your service for the user.
        .then(this.onSubscriptionComplete)
        .catch((error) => {
          // An error has happened. Display the failure to the user here.
          // We utilize the HTML element we created.

          //this.props.setError(error);
        })
  }

  render() {
    const { classes } = this.props;

    return (
      <form onSubmit={this.handleSubmit}>
        <div
          style={{
            marginBottom: 12,
            textTransform: "uppercase",
            color: "black",
          }}
          className="basier-p3"
        >
          New card details
        </div>
        <CardElement
          className="MyCardElement"
          options={{ style: style, className: "MyCardElement" }}
        />

        {this.props.setupOnly === true ? null : (
          <div>
            <div
              style={{
                marginTop: 24,
                paddingLeft: 12,
                display: "flex",
                alignItems: "flex-start",
              }}
            >
              <Checkbox
                onClick={this.handleCheck}
                checked={this.state.checkedConsent}
                color="primary"
                className={classes.checkBoxRoot}
              />

              <div className="basier-p4" style={{ paddingLeft: 12 }}>
                I agree with the <a href="#">Terms and Conditions</a> of Wisly
                UK Limited, and to being charged for the amount of the
                subscription that I have selected and at the frequency that I
                have selected.
              </div>
            </div>
            <button
              className="button-blue opacity-hover"
              style={{
                height: 40,
                borderRadius: 35,
                marginTop: 24,
                cursor: "pointer",
              }}
            >
              Submit
            </button>
          </div>
        )}
      </form>
    );
  }
}

const SplitForm = (props) => (
  <ElementsConsumer>
    {({ stripe, elements }) => (
      <PaymentForm {...props} stripe={stripe} elements={elements} />
    )}
  </ElementsConsumer>
);

export default withStyles(style)(SplitForm);
