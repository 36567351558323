import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import IconButton from '@material-ui/core/IconButton';
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import MenuList from "@material-ui/core/MenuList";
import * as actions from "../../../store/actions/auth";
import { connect } from "react-redux";
import { withRouter } from 'react-router';


class LongMenu extends React.Component {

    state = {
        open: false,
        notifs: false,
    }

    handleToggle = () => {
        
        this.setState(state => ({ open: !state.open }));
      };
    
      handleClose = () => {
        this.setState({ open: false });
      }


      handleLogout = () => {
        this.setState({ open: false });
        this.props.history.push('/login')
        this.props.logout()
      }


    render() {
        const { open } = this.state;


        return (
        <div>
            <IconButton
            buttonRef={node => {
                this.anchorEl = node;
              }}
              aria-owns={open ? 'menu-list-grow' : undefined}
              aria-haspopup="true"
              onClick={this.handleToggle}
            >
            <MoreVertIcon />
            </IconButton>
            <Popper open={open} anchorEl={this.anchorEl} transition disablePortal placement="bottom-end" >
                {({ TransitionProps, placement }) => (
                <Grow
                    {...TransitionProps}
                    id="menu-list-grow"
                >
                    <Paper>
                    <ClickAwayListener onClickAway={this.handleClose}>
                        <MenuList>
                        <MenuItem onClick={this.handleClose}>Profile</MenuItem>
                        <MenuItem onClick={this.handleClose}>My account</MenuItem>
                        <MenuItem onClick={this.handleLogout}>Logout</MenuItem>
                        </MenuList>
                    </ClickAwayListener>
                    </Paper>
                </Grow>
                )}
            </Popper>
        </div>
        );
    }
    }

const mapDispatchToProps = dispatch => {
    return {
        logout: () =>
            dispatch(actions.logout())
    };
};

export default connect(
    null,
    mapDispatchToProps
)(withRouter(LongMenu));

