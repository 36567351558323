import React, { useState, useCallback, useRef } from 'react'
import Card from './Card'
import update from 'immutability-helper'
const style = {
  width: '100%',
  display: 'flex', 
  alignItems: 'center', 
  flexWrap: 'wrap',
}

function usePrevious(value) {
  const ref = useRef();
  React.useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const Container = ({subs, token, user_id, updateSubs, scope, deleteRecord}) => {
  { 
    const [cards, setCards] = useState(subs)
    const prevSubs = usePrevious({subs});

    React.useEffect(() => {
       if (prevSubs !== undefined) 
          if(prevSubs.subs !== subs) { 
            setCards(subs) 
          } 
      });

    const moveCard = useCallback(
      (dragIndex, hoverIndex) => {

        const dragCard = cards[dragIndex]
        const newCards = update(cards, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard],
          ],
        })
        setCards(
          newCards
        )
      },
      [cards],
    )
    const renderCard = (card, index) => {
      return (
        <Card
          subs={cards}
          user_id={user_id}
          token={token}
          current={card}
          key={card.id}
          index={index}
          id={card.id} 
          updateSubs={updateSubs}
          short_name={card.short_name}
          symbol={card.symbol}
          price={card.price}
          change={card.change}
          image_thumb={card.image_thumb}          
          moveCard={moveCard}
          scope={scope}
          deleteRecord={deleteRecord}
        />
      )
    }

    return (
      <>
        
        {scope === 'desktop' ?
          <div style={style}>{cards.map((card, i) => renderCard(card, i))}</div>
        :
          <div>{cards.map((card, i) => renderCard(card, i))}</div>
        }
      </>
    )
  }
}
export default Container
