
export default () => {
	self.addEventListener('message', e => { // eslint-disable-line no-restricted-globals
		if (!e) return;

		const coins = []

		const data = e.data

		data.map((balance, index) => {

            if (balance.asset_type === 'crypto') {
                coins.push(balance)
            }

		})
        
        let coinData = null;
        coins.length > 16 ? coinData = coins.slice(0, 16) : coinData = coins
        const remainingLength = coins.length - coinData.length

		postMessage([coinData, remainingLength]);

	})
}
