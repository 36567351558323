import React from 'react';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import * as actions from "../../../../../store/actions/auth";
//import loadingSpinner from '../../Media/loadingSpinner.gif'
/* import HighchartsReact from 'highcharts-react-official'*/
/* import HighchartsReact from 'highcharts-react-official'*/
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
//import currencyData from '../../helpers/currency.json'


const styles = {
    root: {
      color: '#6A7E93',
      '&$checked': {
        color: '#0078DB',
      },
    },
    checked: {
        color: '#0078DB'
    },
  };




const BalanceGraph = (props) => {

    const { classes } = props;

    let exchange_color = '#0078DB';
    let storage_color = '#E1E5E9';
    let user_color = '#4CDAC1';
    let graphData = []; 
    let userData = []
    
    if (props.scope === 'storage') {
        let exchangeData = []
        props.values.data.filter(v => v.type === 'exchange').map((val, key) => {
            let color = Highcharts.Color(exchange_color).brighten((key - 1) / 15).get()
            exchangeData.push({name: val.name, y: val.value, color: color})
        })

        props.values.data.filter(v => v.type === 'storage').map((val, key) => {
            let color = storage_color
            exchangeData.push({name: val.name, y: val.value, color: color})
        })

        graphData = exchangeData

    } else if (props.scope === 'user') {
        
        props.values.map((val, key) => {
            let color = Highcharts.Color(user_color).brighten((key - 1) / 10).get()
            userData.push({name: val.name, y: val.value, color: color})
        })

        graphData = userData

    }
    

    const options = {
        chart: {
            type: 'pie',
        },

        credits: false, 

        title: false,
        xAxis: {
            categories: false, 
            minorGridLineWidth: 0, 
            gridLineWidth: 0,
        },
        yAxis: {
            title: {
                text: false,
            }, 
            minorGridLineWidth: 0, 
            gridLineWidth: 0,
        },
        series: [{
            name: '',
            data: graphData,
            innerSize: '50%',
            showInLegend: true, 
            label: false, 
            dataLabels: false,
            minSize: 300,
            size: 300,
        }], 

        legend: {
            floating: false,
            align: 'right',
            verticalAlign: 'top',
            layout: 'vertical',
            symbolPadding: 0,
            y: 20,
            symbolWidth: 0.1,
            symbolHeight: 0.1,
            symbolRadius: 20,
            useHTML: true,
            labelFormatter: function() {
                
                return (
                    `
                        <div class="basier" style="text-transform: uppercase; display: flex; align-items: center;">
                            <div style="display: 'inline-block'; width: 20px; height: 20px; margin-left: 12px; margin-right: 12px; background-color: ${this.color};">
                        </div> 
                        <span style="font-weight: 900;">${this.percentage.toLocaleString('en', {minimumFractionDigits: 2, maximumFractionDigits: 2})}%</span> <span style="font-weight: 200;">&nbsp;${this.name}</span> 
                        `
                    )
          
            },
            itemStyle: {
              fontSize: '14px', 
              paddingBottom: '28px',
            }
          },

        tooltip: {
        useHTML: true,
        formatter: function () {
            return '<b>' + this.point.name +
                '</b> : ' + parseFloat(this.y.toFixed(2)).toLocaleString('en') + "%";
                }
        }

    }


        return (

            <div>
                
                <Grid container 
                    spacing={0}>

                    <div style={{width: '100%', marginLeft: 0, height: 250, paddingRight: 0, position: 'relative'}}>
                        
                    
                        <HighchartsReact
                            highcharts={Highcharts}
                            containerProps={{ style: { height: "400px", width: "550px"} }}
                            options={options}
                            oneToOne={true}
                        />

                    </div>
                    

                </Grid>

                </div>
            
            
        )


        
};


BalanceGraph.propTypes = {
    classes: PropTypes.object.isRequired,
  };
  

const mapStateToProps = state => {
    return {
        currency_symbol: state.auth.currency.symbol,
        currency_name: state.auth.currency.id,
        currentView: state.data.currentView,
        inBitcoin: state.data.bitcoinPf

    };
};
const mapDispatchToProps = dispatch => {
    return {
        setBtcSymbol: (s) =>
            dispatch(actions.setBtcSymbol(s)), 
        
    };
  };

export default connect(
    mapStateToProps,
    mapDispatchToProps
)( withStyles(styles)(BalanceGraph));

