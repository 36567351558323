import React from 'react';
import { connect } from 'react-redux';
import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from '@material-ui/core/styles';
import Logo from '../Media/Logo.svg'
import {zdToken} from '../variables'

const ColorLinearProgress = withStyles({
    colorPrimary: {
      backgroundColor: 'rgba(0, 120, 219, 0.2)',
    },
    barColorPrimary: {
      backgroundColor: '#0078DB',
    },
  })(LinearProgress);

class SupportSSO extends React.PureComponent {

    constructor(props) {
        super(props);

        var jwt = require('jwt-simple');
        var uuid = require('uuid');

        var subdomain = 'wisly';
        var shared_key = zdToken;

        var payload = {
            iat: (new Date().getTime() / 1000),
            jti: uuid.v4(),
            name: `${props.user_fist} ${props.user_last}`,
            email: props.email,
        }

        var token = jwt.encode(payload, shared_key);
        var redirect = 'https://' + subdomain + '.zendesk.com/access/jwt?jwt=' + token;

        window.location.replace(redirect)

    }

    render () {

        

        return (

            <div style={{display: 'flex', justifyContent: 'center', position: 'fixed', top: 0, height: '100%', width: '100%', backgroundColor: 'white', zIndex: 900000}}>

            <div style={{width: '100%', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center'}}>
                <div>
                <img src={Logo} alt=""/>
                <div style={{width: 280, marginTop: 12}}>
                    
                    <div className="basier-p2" style={{marginBottom: 12}}>Authenticating to support portal</div>
                    <ColorLinearProgress />
                    
                </div>
                </div>
            </div>
                

            </div>
            
        )
    }


}

const mapStateToProps = state => {
    return {
        user_fist: state.auth.user_first_name,
        user_last: state.auth.user_last_name,
        email: state.auth.email,
    };
};

const mapDispatchToProps = dispatch => {
    return {
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(SupportSSO)
