import React from "react";
import Grid from "@material-ui/core/Grid";
import seeMore from "../../../Media/seeMore.svg";
import warning from "../../../Media/warning.svg";
import currencyDict from "../../../helpers/currency.json";
import CustomContextMenu from "../../AppWide/customContextMenu";
import WislyTooltip from '../../AppWide/Tooltip';
import { formattedNumber } from "../../../helpers/helpers";


class AssetLine extends React.PureComponent {
  state = {
    mouseX: null,
    mouseY: null,
    menuOpen: false,
  };
  onClick = (e) => {
    e.preventDefault();
    e.persist();

    this.setState({
      mouseX: null,
      mouseY: null,
      menuOpen: false,
    });

    setTimeout(() => {
      this.setState({
        mouseX: e.clientX - 2,
        mouseY: e.clientY - 4,
        menuOpen: true,
      });
    }, 1);
  };
  handleClose = () => {
    this.setState({
      mouseX: null,
      mouseY: null,
      menuOpen: false,
    });
  };

  render() {
    let proportion = 0;
    let proprtionNumber = 0;
    try {
        proportion = Math.max(this.props.item.value / this.props.value, 0);
        proportion = Math.min(proportion, 1)
        proprtionNumber = proportion * 100
        if (isNaN(proprtionNumber)) {
          proprtionNumber = 0
        }
    } catch (e) {}
    return (
      <Grid
        container
        style={{
          height: 60,
          paddingTop: 8,
          paddingBottom: 8,
          display: "flex",
          alignItems: "center",
          paddingLeft: 6,
          paddingRight: 6,
          width: "100%",
          borderBottom: "1px solid #CCC",
        }}
        index={this.props.index}
        className="basier-p3 light-hover"
      >
        <Grid
          item
          style={{ width: "28%", display: "flex", alignItems: "center", paddingLeft: 12, textOverflow: 'ellipsis' }}
        >
          {this.props.item.image !== "" &&
          this.props.item.image !== null ? (
            <span>
              <img
                style={{ height: 25, width: 25, marginRight: 12 }}
                src={this.props.item.image}
                alt=""
              />
            </span>
          ) : (
            <span
              style={{
                height: 25,
                width: 25,
                marginRight: 12,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: 20,
              }}
            >
              {currencyDict[this.props.item.symbol] === undefined
                ? null
                : currencyDict[this.props.item.symbol]["symbol"].length > 1
                ? null
                : currencyDict[this.props.item.symbol]["symbol"]}
            </span>
          )}

          <span className="truncate">
            <div style={{ marginTop: 2, marginBottom: 2 }}>
              {this.props.item.symbol}
            </div>
            <div
              className="basier-p4-caps white-hover truncate"
              style={{ color: "#6A7E93" }}
            >
              {this.props.item.name}
            </div>
          </span>
        </Grid>

        <Grid item style={{ width: "13%", textAlign: "left" }}>
          <WislyTooltip title={`${formattedNumber(proprtionNumber,2)}%`}>
          <div
            className="percent-container"
            style={{ display: "inline-block", position: "relative" }}
          >
            <div
              className="percent-amount"
              style={{
                display: "inline-block",
                position: "absolute",
                left: 0,
                width: `${proprtionNumber}%`,
              }}
            />
          </div>
          </WislyTooltip>
        </Grid>

        <Grid item style={{ width: "16%", textAlign: "right" }}>
          <div
            style={{
              paddingTop: 4,
              marginBottom: 2,
            }}
          > 
            {this.props.errorDelta === true && (
              <span>
                <WislyTooltip
                  title={`There is a discrepancy between imported balances and reported balances (from the API). Some transactions may not have been imported and need to be manually added. Please check your connected accounts.`}
                >
                  <img
                    src={warning}
                    alt=""
                    style={{ height: 16, marginRight: 12 }}
                  />
                </WislyTooltip>
              </span>
            )}
            <span>
              {this.props.errors === true
                ? this.props.item.amount == 0
                  ? (-1 * this.props.item.oversold).toLocaleString("en", {
                      maximumFractionDigits: 8,
                      minimumFractionDigits: 8,
                    })
                  : this.props.item.amount < -1
                  ? this.props.item.amount.toLocaleString("en", {
                      maximumFractionDigits: 4,
                      minimumFractionDigits: 4,
                    })
                  : this.props.item.amount.toLocaleString("en", {
                      maximumFractionDigits: 8,
                      minimumFractionDigits: 8,
                    })
                : this.props.item.amount > 1
                ? this.props.item.amount.toLocaleString("en", {
                    maximumFractionDigits: 4,
                    minimumFractionDigits: 4,
                  })
                : this.props.item.amount.toLocaleString("en", {
                    maximumFractionDigits: 8,
                    minimumFractionDigits: 8,
                  })}
            </span>
          </div>
          {this.props.errorDelta === true &&
            <div className="basier-p4" style={{color: '#e0a03d'}}>Needs review</div>
          }
        </Grid>

        <Grid item style={{ width: "16%", textAlign: "right" }}>
          <span>
            <div style={{ paddingTop: 4, marginBottom: 2 }}>
              {this.props.currency_symbol}{" "}
              {!this.props.item.value ? null : this.props.item.value.toLocaleString("en", {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              })}
            </div>
          </span>
        </Grid>
        <Grid item style={{ width: "16%", textAlign: "right" }}>
          <div style={{ paddingTop: 4, marginBottom: 2 }}>
            {this.props.currency_symbol}&nbsp;
            {!this.props.item.price ? null :
            this.props.item.price > 1
              ? this.props.item.price.toLocaleString("en", {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                })
              : this.props.item.price.toLocaleString("en", {
                  maximumFractionDigits: 6,
                  minimumFractionDigits: 6,
                })}
          </div>
        </Grid>

        <Grid item style={{ width: "10%", textAlign: "right", paddingRight: 12, }}>
          <div
            className="basier-p3"
            style={{
              width: 65,
              fontWeight: 'bold',
              display: "inline-block",
              color: `${
                this.props.item.change > 0
                  ? "#4CDAC1"
                  : this.props.item.change < 0
                  ? "#F9716B"
                  : "#6A7E93"
              }`,
            }}
          >
            {this.props.item.asset_type === "crypto"
              ? !this.props.item.change ? null : `${
                  this.props.item.change > 0 ? "+" : ""
                }${this.props.item.change.toLocaleString("en", {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                })}%`
              : "-"}
          </div> 
        </Grid>
      </Grid>
    );
  }
}

export default AssetLine;
