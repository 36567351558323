import React from 'react';
import { connect } from 'react-redux';
import * as actions from "../../../store/actions/data";
import * as interActions from "../../../store/actions/interactions";
import * as appwideActions from "../../../store/actions/appwide";
import '../../Portfolio/PortfolioComponents/Transactions/TransactionStyle.css'
import {loadData, siwtchTransactionType, dateParser, formattedNumber} from '../../../helpers/helpers'
import BaseModalTop from '../../Portfolio/PortfolioComponents/Transactions/BaseModalTop';
import { withRouter } from "react-router-dom";
import axios from 'axios';
import { backendUrl } from '../../../variables'
import Tooltip from '../../AppWide/Tooltip';
import TypeSelector from '../../AppWide/TableTypeSelector';

class TransactionModalDetail extends React.PureComponent {

    state = {

        transactionId: this.props.transactionId,
        date: null,
        transctionData: null,
        editConnected: false,
        connectedEdit: {
            unit_price: null
        }, 
        editComment: false,
    }


    changeValue = (label, e) => {
        
        e.persist();
        this.setState(state => ({...state, connectedEdit: {...state.connectedEdit, [label]: e.target.value}}))

    }

    changeValueStraight = (val) => {
        
        this.setState(state => ({...state, connectedEdit: {...state.connectedEdit, ...val}}))

    }

    handleClose = () => {

        this.props.toggleModal({type: null, open: false, payload: null})
       
      }
    

    toggleModal = (type, payload) => {
        this.props.toggleModal(
            {
                type: type, 
                payload: {
                    data: payload, 
                    transactionId: this.state.transactionId, 
                    getData: this.getData, 
                    setDetailTr: this.updateId,
                    getOverallData: this.props.getOverallData
                }, 
                open: true
            }
        )
    }

    toggleConfirm = (type) => {

        this.props.toggleModal(
            {
                type: type, 
                payload: {
                    transactionId: this.state.transactionId, 
                    getOverallData: this.props.getOverallData
                }, 
                open: true
            }
        )


    }

    editTransaction = (connected, uneditable) => {

        if (connected) {
            if (!uneditable) {
                this.setState(state => ({...state, editConnected: true}))
            }

        } else {

            this.toggleModal('edit_transaction', this.state.transctionData)

        }

    }

    stopEditConnected = () => {

        this.setState(state => ({...state, editConnected: false}))

    }

    updateConnected = (callType) => {
         
        let headers = { "content-type": "application/json" };
        if (this.props.token !== null && this.props.token !== undefined) {

            headers['Authorization'] = `Token ${this.props.token}`

        }

        let sendData = null; 

        let url = `${backendUrl}/v1/connectors/transactions/${this.state.transctionData.connection}`
        if (callType === 'delete') {
            url = `${backendUrl}/v1/connectors/transactions/${this.state.transctionData.connection}?u=${this.props.user_id}`
        } else {
            sendData = {

                ...this.state.connectedEdit,
                user_id: this.props.user_id, 
                
            }
        }

        axios({method: callType, url: url, data: sendData, headers: headers})
            .then(json => { 
                this.props.componentDataFetch('get', 5, this.props.currency, this.props.user_id, this.props.portfolioId, {}, {}, this.props.inBitcoin)
                this.props.getImports(this.props.user_id)
                this.handleClose()
             })

            .catch(err => {  })



    }


    deleteTransaction = () => {
        
    }

    componentDidMount() {

        this.getData(this.props.transactionId)
        //this.props.setCurrentView(5)
       //this.props.componentDataFetch('get', 5, null, null, this.props.portfolioId, null, null, this.props.inBitcoin)

    } 

    updateId = (transaction_id) => {

        this.setState(state => ({...state, transactionId: transaction_id}))

    }

    toggleComment = () => {
        this.setState(state => ({...state, editComment: true}))
    }
    handleCommentChange = (e) => {
        e.persist();
        const val = e.target.value;
        this.setState(state => ({...state, comments: val}))
    }

    discardComment = (e) => {
        this.setState(state => ({...state, comments: state.initialComments, editComment: false}))
    }

    submitComment = (e) => {
        this.setState(state => ({...state, editComment: false}))
        if (this.state.comments !== this.state.initialComments) {
            let headers = { "content-type": "application/json" };
            headers['x-user'] = this.props.user_id
            if (this.props.token !== null && this.props.token !== undefined) {
    
                headers['Authorization'] = `Token ${this.props.token}`
    
            }
    
            const data = {
                value: this.state.comments,
                field: "comments",
                user: this.props.user_id,
                user_id: this.props.user_id,
            }
            const url_call = `${backendUrl}/v1/transaction/simple_edit/${this.state.transactionId}`
    
            axios.patch(url_call, data, {headers: headers})
                .then(json => {})
        }

    }

    getData = (transaction_id) => {
        let headers = { "content-type": "application/json" };
        if (this.props.token !== null && this.props.token !== undefined) {

            headers['Authorization'] = `Token ${this.props.token}`

        }

        const url_call = `${backendUrl}/v1/transaction_detail/${transaction_id}`

        const transactionDetail = loadData(url_call, headers)
        transactionDetail
            .then(json => {

                this.props.getFlatWallets(this.props.user_id)
                this.props.getAllPortfolioList(this.props.user_id)

                this.setState(state => ({...state, transctionData: json['data'], editComment:false, initialComments: json['data']['comments'], comments: json['data']['comments'], connectedEdit: {...this.state.connectedEdit, unit_price: json['data']['unit_price'], transaction_description: json['data']['transaction_description']}}))

            })

    }




    deleteData = () => {

        let headers = { "content-type": "application/json" };
        headers['x-user'] = this.props.user_id
        if (this.props.token !== null && this.props.token !== undefined) {

            headers['Authorization'] = `Token ${this.props.token}`

        }

        const url_call = `${backendUrl}/v1/transaction_detail/${this.state.transactionId}`

        axios.delete(url_call, {headers: headers})
            .then(json => {

                this.props.getOverallData()
                this.setState(state => ({...state, transctionData: null, transactionId: null}))
                //this.props.selectTransaction(null)
                this.props.getUserLimits(this.props.user_id)
                //console.log(json['data'])

            })



    }



    componentDidUpdate() {

    }
    
    toggleTxModal = () => {

        //this.props.toggleTransactionAddModal({open: true});

    }

    render() {

        const { transctionData }  = this.state;
        let isLiquidity = false;
        try {
            isLiquidity = ['liquidity_in', 'liquidity_out'].includes(transctionData.transaction_description);
        } catch(e) {}
        return (

            this.state.transctionData !== null ? 
            <div>
                
                <BaseModalTop forceSingle={true} sub={this.state.transctionData.connection !== null ? <div style={{color: '#4CDAC1', fontWeight: 900}}>Connected Transaction</div> : null} title="Transaction Detail" handleClose={this.handleClose} onClick={this.backToWelcome} welcome={this.state.welcome} entryType={this.state.type_selected} />

            <div>
                <div
                    style={{

                        position: 'relative',
                        width: '100%',
                        paddingBottom: 24,
                    }}>

                <div style={{width: '100%'}}>
                        
                        <div style={{ marginBottom: 24, width: '100%', paddingTop: 25 }}>   

                            <div style={{display: 'flex', alignItems: 'center', marginTop: 6, paddingLeft: 40,  justifyContent: 'space-between'}}>

                                {!this.state.editConnected ? 
                                    
                                    <div style={{
                                        fontWeight: 900, 
                                        height: 40, 
                                        padding: '0px 24px', 
                                        display: 'flex', 
                                        alignItems: 'center', 
                                        justifyContent: 'center', 
                                        borderRadius: 20, 
                                        textTransform: 'uppercase', 
                                        color: 'white', 
                                        backgroundColor: siwtchTransactionType(transctionData.transaction_description)[1]
                                        }} 
                                        className="basier-p2">
                                            {siwtchTransactionType(transctionData.transaction_description)[0]}
                                    </div>

                                : 

                                    <TypeSelector valid={false} loading={false} updateServer={(data) => this.changeValueStraight(data)} value={this.state.connectedEdit.transaction_description} coinType={transctionData.base_currency.asset_type} largeSelect={true}/>

                                }

                                
                                { (this.props.roles[this.props.portfolioId] === 'admin' || ( this.props.roles[this.props.portfolioId] === 'manager' && transctionData['user_transaction']['id'] == this.props.user_id)) ?

                                    !this.state.editConnected ? 
                                        <div style={{display: 'flex'}}>

                                            {
                                            isLiquidity ? 
                                            null : 
                                            transctionData.connection !== null && ['buy', 'sell'].includes(transctionData.transaction_description) ? 
                                                
                                                <Tooltip title="You cannot edit buy/sell transactions imported from an active exchange or wallet API. You can only remove the transaction from your portfolio." aria-label="read">
                                                    <div className="basier-p2 opacity-hover" style={{fontWeight: 900, height: 40, backgroundColor: (transctionData.connection !== null && ['buy', 'sell'].includes(transctionData.transaction_description)) ? '#E1E5E9' :'#0078DB', width: 150, color: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 20, marginRight: 12, cursor: (transctionData.connection !== null && ['buy', 'sell'].includes(transctionData.transaction_description)) ? 'not-allowed' : 'pointer'}} onClick={() => this.editTransaction(transctionData.connection !== null , transctionData.connection !== null && ['buy', 'sell'].includes(transctionData.transaction_description))}>
                                                    Edit
                                                    </div>
                                                </Tooltip>

                                            :

                                                <div className="basier-p2 opacity-hover" style={{fontWeight: 900, height: 40, backgroundColor: (transctionData.connection !== null && ['buy', 'sell'].includes(transctionData.transaction_description)) ? '#E1E5E9' :'#0078DB', width: 150, color: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 20, marginRight: 12, cursor: (transctionData.connection !== null && ['buy', 'sell'].includes(transctionData.transaction_description)) ? 'not-allowed' : 'pointer'}} onClick={() => this.editTransaction(transctionData.connection !== null, transctionData.connection !== null && ['buy', 'sell'].includes(transctionData.transaction_description))}>
                                                    Edit
                                                </div>

                                            }

                                            <div className="basier-p2 opacity-hover" style={{fontWeight: 900, height: 40, backgroundColor: '#F9716B', width: 150, color: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 20, marginRight: 40, cursor: 'pointer'}} onClick={() => this.toggleConfirm('delete_transaction', transctionData)}>
                                                Delete
                                            </div>

                                        </div>

                                        :

                                        <div style={{display: 'flex', alignItems: 'center', marginBottom: 30}}>

                                            <div className="basier-p2 opacity-hover" style={{fontWeight: 900, height: 40, backgroundColor: '#4CDAC1', width: 150, color: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 20, marginRight: 12, cursor: 'pointer'}} onClick={() => this.updateConnected('patch')}>
                                                Save
                                            </div> 
                                            <div className="basier-p2 opacity-hover" style={{fontWeight: 900, height: 40, backgroundColor: '#F9716B', width: 150, color: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 20, marginRight: 40, cursor: 'pointer'}} onClick={() => this.stopEditConnected()}>
                                                Cancel
                                            </div> 

                                        </div>



                                    :

                                    null 
                                }
                                
                            </div>
                            
                            {!this.state.editConnected &&
                            <div style={{display: 'flex', alignItems: 'center', marginTop: 6, paddingLeft: 40, }}>
                                <div className="basier" style={{fontSize: 16, paddingLeft: 12}}>
                                    { dateParser(transctionData.date) }
                                </div >
                            </div>}
                            
                            
                            <div style={{display: 'flex', alignItems: 'center', marginTop: 18, marginBottom: 18, height: 60, backgroundColor: 'rgba(204, 237, 254,0.3)', paddingLeft: 40,  }}>
                                <div style={{fontWeight: 900, textTransform: 'uppercase'}} className="basier-p3">{!isLiquidity ? 'Base currency' : 'Liquidity Token'} </div>
                                <div style={{marginLeft: 6, fontSize: 16}} className="basier-p3">{transctionData.custom_base ? transctionData.custom_base : transctionData.base_currency.name}</div>
                                
                                { (!isLiquidity) && (transctionData.quote_currency !== null && (transctionData.transaction_description === 'buy' || transctionData.transaction_description === 'ico' || transctionData.transaction_description === 'sell' )) ? 

                                    <React.Fragment>
                                    <div style={{marginLeft: 12, marginRight: 12, fontSize: 20}}>|</div>
                                    <div style={{fontWeight: 900, textTransform: 'uppercase'}} className="basier-p3">Quote currency </div>
                                    <div style={{marginLeft: 6, fontSize: 16}} className="basier-p3" >{transctionData.quote_currency.name}</div>
                                    </React.Fragment>
                                
                                :
                                
                                    null 
                                
                                }
                                
                            </div>

                            <div style={{display: 'flex', alignItems: 'center', paddingLeft: 40, }}>
                                <div style={{fontWeight: 900, textTransform: 'uppercase', width: 200}} className="basier-p3">Amount </div>
                                <div style={{marginLeft: 6, fontSize: 16}} className="basier-p3">
                                    {
                                    isLiquidity ? 
                                        transctionData.amount.toLocaleString('en', {minimumFractionDigits:8, maximumFractionDigits: 8})
                                    :

                                        transctionData.base_currency.asset_type === 'crypto'? 
                                        transctionData.amount.toLocaleString('en', {minimumFractionDigits:8, maximumFractionDigits: 8})
                                    :
                                        transctionData.amount.toLocaleString('en', {minimumFractionDigits:2, maximumFractionDigits: 2})
                                    }
                                </div>
                            </div>
                            
                            {   
                                isLiquidity ? 

                                <div style={{display: 'flex', alignItems: 'center', marginTop: 6, paddingLeft: 40, }}>
                                        <div style={{fontWeight: 900, textTransform: 'uppercase', width: 200}} className="basier-p3">Quotes </div>
                                        
                                        <div style={{marginLeft: 6, fontSize: 16}} className="basier-p3">

                                            <div style={{display: 'flex', alignItems: 'baseline'}}>
                                                {Object.values(transctionData.multiple_quotes).map((v, k) => {
                                                    return (
                                                        <span>
                                                        <span>{formattedNumber(v.amount, 8)} <b>{v.symbol}</b></span>
                                                        {k === 0 ? <span style={{marginLeft: 6, marginRight: 6}}>+</span> : null}
                                                        </span>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                        
                                    
                                    </div>

                                :

                                !['loss', 'fee'].includes(transctionData.transaction_description) ? 
                                    <div style={{display: 'flex', alignItems: 'center', marginTop: 6, paddingLeft: 40, }}>
                                        <div style={{fontWeight: 900, textTransform: 'uppercase', width: 200}} className="basier-p3">Unit Price </div>
                                        
                                        {!this.state.editConnected ?
                                        <div style={{marginLeft: 6, fontSize: 16}} className="basier-p3">

                                        {transctionData.unit_price !== null ? 
                                         transctionData.unit_price >= 1 ? 
                                            transctionData.unit_price.toLocaleString('en', {minimumFractionDigits:2, maximumFractionDigits: 2})
                                            :
                                            transctionData.unit_price === 0 ?
                                            null
                                            :
                                            transctionData.unit_price.toLocaleString('en', {minimumFractionDigits:8, maximumFractionDigits: 8})
                                            :
                                            null
                                        }
                                        </div>
                                        :

                                        <input onChange={(e) => this.changeValue('unit_price', e)} className="transaction-coin-search" value={this.state.connectedEdit.unit_price} />

                                        }
                                    
                                    </div>

                            :

                            null 

                            }

                            <div style={{display: 'flex', alignItems: 'center', marginTop: 6, paddingLeft: 40, }}>
                                <div style={{fontWeight: 900, textTransform: 'uppercase', width: 200}} className="basier-p3">Fee </div>
                                <div style={{marginLeft: 6, fontSize: 16}} className="basier-p3">
                                    
                                    {
                                        transctionData.fee !== null && transctionData.fee !== undefined  ? 

                                            transctionData.fee >= 1 ? 
                                                transctionData.fee.toLocaleString('en', {minimumFractionDigits:2, maximumFractionDigits: 2})
                                            :
                                                transctionData.fee === 0 ?
                                            null
                                            :
                                                transctionData.fee.toLocaleString('en', {minimumFractionDigits:8, maximumFractionDigits: 8})
                                    
                                        : 
                                    
                                            null 
                                    
                                    }

                                </div>
                            </div>

                            <div style={{display: 'flex', alignItems: 'center', marginTop: 6, paddingLeft: 40,}}>
                                <div style={{fontWeight: 900, textTransform: 'uppercase', width: 200}} className="basier-p3">Fee currency </div>
                                <div style={{marginLeft: 6, fontSize: 16}} className="basier-p3">

                                    {
                                        transctionData.fee_currency !== null && transctionData.fee_currency !== undefined  ? 

                                            transctionData.fee_currency.name

                                        :

                                            null

                                    }

                                </div>
                            </div>

                            <div style={{display: 'flex', alignItems: 'center', marginTop: 6, paddingLeft: 40,}}>
                                <div style={{fontWeight: 900, textTransform: 'uppercase', width: 200}} className="basier-p3">
                                    {transctionData.transaction_description === 'transfer' ? 
                                    'Transferred From' 
                                    :
                                    'Wallet'
                                    }
                                </div>
                                <div style={{marginLeft: 6, fontSize: 16}} className="basier-p3">

                                    
                                    {transctionData.account !== null ? 
                                    
                                    <div style={{display: 'flex'}}>
                                        <div>{transctionData.account.name}</div>
                                        
                                        { transctionData.account.exchange !== null && transctionData.account.exchange !== undefined ? 
                                            <div style={{marginLeft: 6}}>
                                                <span style={{fontStyle: 'italic'}}>({transctionData.account.exchange.name})</span>
                                            </div>
                                            :

                                            null
                                        }

                                        { transctionData.account.custom_exchange_check === true ? 
                                            <div style={{marginLeft: 6}}>
                                                <span style={{fontStyle: 'italic'}}>({transctionData.account.custom_exchange})</span>
                                            </div>
                                            :

                                            null
                                        }
                                        
                                    </div>
                                        
                                    : 
                                    
                                    transctionData.storage !== null ?

                                    <div style={{display: 'flex'}}>
                                        <div>{transctionData.storage.name}</div>
                                        <div style={{marginLeft: 6}}>
                                                <span style={{fontStyle: 'italic'}}>(Storage wallet)</span>
                                        </div>
                                        
                                    </div>

                                    : 

                                    <div style={{fontStyle: 'italic'}}>Not specified</div>
                                    
                                    }




                                </div>
                            </div>
                            
                            {transctionData.transaction_description === 'transfer' ? 
                            <div style={{display: 'flex', alignItems: 'center', marginTop: 6, paddingLeft: 40,}}>
                                <div style={{fontWeight: 900, textTransform: 'uppercase', width: 200}} className="basier-p3">
                                    Transferred To
                                </div>
                                <div style={{marginLeft: 6, fontSize: 16}} className="basier-p3">

                                    
                                    {transctionData.destination !== null && transctionData.destination !== undefined  ? 
                                    
                                        transctionData.destination.wallet_type === 'exchange' && transctionData.destination.exchange !== null  ? 

                                    
                                            <div style={{display: 'flex'}}>
                                                <div>{transctionData.destination.exchange.name}</div>
                                                
                                                    <div style={{marginLeft: 6}}>
                                                        <span style={{fontStyle: 'italic'}}>
                                                            ({
                                                                transctionData.destination.exchange.custom_exchange_check === true ? 
                                                                
                                                                transctionData.destination.exchange.custom_exchange
                                                                :
                                                                
                                                                transctionData.destination.exchange.exchange.name
                                                            })
                                                        </span>
                                                    </div>
                                            </div>
                                        :

                                            <div style={{display: 'flex'}}>
                                                <div>{transctionData.destination.name}</div>
                                                <div style={{marginLeft: 6}}>
                                                        <span style={{fontStyle: 'italic'}}>(Storage wallet)</span>
                                                </div>
                                        
                                            </div>
                                        
                                    : 
                                    
                                    <div style={{fontStyle: 'italic'}}>Not specified</div>
                                    
                                    }

                                </div>
                            </div>
                            :
                            null
                            }
                            
                            <div style={{display: 'flex', alignItems: 'center', marginTop: 6, paddingLeft: 40, }}>
                                <div style={{fontWeight: 900, textTransform: 'uppercase', width: 200}} className="basier-p3">Tx Hash </div>
                                <div style={{marginLeft: 6, width: 650}} className="basier-p3">
                                    
                                    {
                                        transctionData.tid !== null && transctionData.tid !== undefined  ? 
                                        <div className="truncate">
                                            {transctionData.tid}
                                        </div>
                                        :
                                        null

                                    }

                                </div>
                            </div>


                            <div style={{display: 'flex', alignItems: 'flex-start', marginTop: 6, paddingLeft: 40,}}>
                                <div style={{fontWeight: 900, textTransform: 'uppercase', width: 200}} className="basier-p3">Comments </div>
                                <div style={{fontSize: 16, marginLeft: 6}} className="basier-p3">

                                    {   
                                        this.state.editComment ? 
                                            <div>
                                                <textarea
                                                    autoFocus={true}
                                                    style={{width: 650, resize: 'none', height: 70, padding: 4,}}
                                                    value={this.state.comments}
                                                    onChange={this.handleCommentChange}
                                                />
                                                <div style={{display: 'flex', alignItems: 'baseline'}}>
                                                    <div style={{cursor: 'pointer', color: "#0078DB", marginRight: 6}} className="opacity-hover noselect" onClick={this.submitComment}>Save</div>
                                                    <div style={{cursor: 'pointer', color: "#F9716B", marginLeft: 6}} className="opacity-hover noselect" onClick={this.discardComment}>Discard</div>
                                                </div>
                                            </div>
                                        :
                                            this.state.comments  ? 
                                            
                                            <div style={{display: 'flex', alignItems: 'baseline'}}>
                                                <div>
                                                    {this.state.comments}
                                                </div>
                                                <div style={{cursor: 'pointer', color: "#0078DB", marginLeft: 12, fontStyle: 'italic'}} className="opacity-hover noselect" onClick={this.toggleComment}>Edit</div>
                                            </div>
                                            
                                            :
                                            
                                            <div style={{cursor: 'pointer', color: "#0078DB"}} className="opacity-hover noselect" onClick={this.toggleComment}>Add comments</div>
                                        
                                    }
                                    
                                </div>
                            </div>

                        </div>



                    </div>

                </div>

            </div>
          </div>
           :

           null

        )
    }
  
};

const mapStateToProps = state => {
    return {
        inBitcoin: state.data.bitcoinPf, 
        token: state.auth.token,
        user_id: state.auth.user_id,
        roles: state.auth.roles,
        currency: state.auth.currency.id,

    };
};

const mapDispatchToProps = dispatch => {
    return {
        setCurrentView: (view) => 
            dispatch(actions.setCurrentView(view)),
        componentDataFetch: (scope, view, currency, user, portfolio, globalGraphData, portfolioGraphData, inBitcoin) =>
            dispatch(actions.componentDataFetch(scope, view, currency, user, portfolio, globalGraphData, portfolioGraphData, inBitcoin)), 
        toggleModal: (data) => 
            dispatch(interActions.toggleModal(data)), 
        getFlatWallets: (user_id) => 
            dispatch(appwideActions.getFlatWalletList(user_id)), 
        getAllPortfolioList: (user_id) => 
            dispatch(appwideActions.getAllPortfolioList(user_id)), 
        getUserLimits: (user) => dispatch(appwideActions.getUserLimits(user)), 
        getImports: (data) => dispatch(actions.getImports(data)), 
        
        
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(TransactionModalDetail));