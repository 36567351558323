import React from "react";
import { connect } from "react-redux";
import FreePlan from "../../Media/FreePlan.svg";
import HobbyPlan from "../../Media/HobbyPlan.svg";
import ProPlan from "../../Media/ProPlan.svg";
import arrowRight from "../../Media/arrowRight.svg";
import * as interactionActions from "../../store/actions/interactions";
import * as appwideActions from "../../store/actions/appwide";
import ProgressBar from "../AppWide/Components/LinearProgress";

const getNested = (dictData, key) => {
  try {
    return dictData[key];
  } catch (e) {
    return null;
  }
};

const renderImage = (plan) => {
  switch (plan) {
    case "pro": {
      return (
        <img
          src={ProPlan}
          style={{ height: 50, width: 50, marginRight: 12 }}
          alt=""
        />
      );
    }
    case "hobby": {
      return (
        <img
          src={HobbyPlan}
          style={{ height: 50, width: 50, marginRight: 12 }}
          alt=""
        />
      );
    }
    case "free": {
      return (
        <img
          src={FreePlan}
          style={{ height: 50, width: 50, marginRight: 12 }}
          alt=""
        />
      );
    }
    case "unlimited": {
      return (
        <img
          src={ProPlan}
          style={{ height: 50, width: 50, marginRight: 12 }}
          alt=""
        />
      );
    }
    default:
      return null;
  }
};

const displayDate = (dateSend) => {
  let date = new Date(dateSend);
  const language = navigator.language || "en";
  const options = { year: "numeric", month: "long", day: "numeric" };
  return date.toLocaleDateString(language, options);
};

const renderStatus = (status, plan_id) => {
  if (plan_id === "free") {
    return ["Active", "#4CDAC1"];
  } else {
    switch (status) {
      case "active": {
        return ["Active", "#4CDAC1"];
      }
      default:
        return ["Pending", "#F9716B"];
    }
  }
};

class ProfileLarge extends React.PureComponent {
  componentDidMount() {
    this.props.getUserLimits(this.props.user);
  }

  changePlan = (scope) => {
    const payloadData = {
      monthlyPlans: this.props.billingData["monthly"],
      yearlyPlans: this.props.billingData["yearly"],
      currentPlan: this.props.billingData["plan_id"],
      wsPlans: this.props.billingData["ws_plans"],
      currentPlanData: this.props.billingData["plan_data"],
      currentPlanEnd: this.props.billingData["plan_end"],
      currentWsPlanId: this.props.accountData["plan"]["plan_id"],
      customerBalance: this.props.billingData["balance"],
      changing: this.props.billingData["changing"],
      customer_id: this.props.billingData["customer_id"],
    };
    this.props.toggleModal({
      type: "change_plan",
      open: true,
      payload: { data: payloadData, scope: scope },
    });
  };

  renderFullPlan = (scopePlan, newPlan, upcoming) => {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div>{renderImage(scopePlan["plan_id"])}</div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <h3 style={{ margin: 0 }}>{scopePlan["plan_name"]}</h3>
              <div
                className="basier-p4-caps"
                style={{
                  color: renderStatus(
                    newPlan["status"],
                    scopePlan["plan_id"]
                  )[1],
                }}
              >
                {!upcoming
                  ? renderStatus(newPlan["status"], scopePlan["plan_id"])[0]
                  : "Upcoming"}
              </div>
            </div>
          </div>

          {scopePlan["plan_id"] !== "unlimited" ? (
            scopePlan["plan_id"] === "free" ? null : (
              <div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: 2,
                  }}
                >
                  <div
                    style={{ marginRight: 6, fontWeight: 900, color: "black" }}
                    className="basier-p2"
                  >
                    Plan cost:
                  </div>
                  <div className="basier-p2">
                    {`${(
                      newPlan["plan"]["amount_decimal"] / 100
                    ).toLocaleString("en", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })} ${newPlan["plan"]["currency"].toUpperCase()} / ${
                      newPlan["plan"]["interval"]
                    }`}
                  </div>
                </div>

                <div style={{ display: "flex", alignItems: "center" }}>
                  <div
                    style={{ marginRight: 6, fontWeight: 900, color: "black" }}
                    className="basier-p2"
                  >
                    {this.props.billingData["changing"]
                      ? upcoming
                        ? "Plan start date"
                        : "Plan end date: "
                      : "Next billing date: "}
                  </div>
                  <div className="basier-p2">
                    {displayDate(this.props.billingData["plan_end"])}
                  </div>
                </div>
              </div>
            )
          ) : (
            <div
              className="basier"
              style={{ display: "flex", alignItems: "center", marginTop: 12 }}
            >
              Lifetime unlimited plan! No billing, no limits.
            </div>
          )}
        </div>
      </div>
    );
  };

  render() {
    return (
      <div style={{ padding: 24 }}>
        {this.props.accountData !== undefined &&
        this.props.accountData !== null &&
        this.props.billingData !== undefined &&
        this.props.billingData !== null ? (
          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <div className="basier-p4-caps" style={{ marginBottom: 12 }}>
                Current Plan
              </div>
              {this.renderFullPlan(
                this.props.accountData["plan"],
                this.props.billingData.plan_data[0],
                false
              )}

              {this.props.billingData["changing"] ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <div
                    className="basier-p4-caps"
                    style={{ marginBottom: 12, marginTop: 12 }}
                  >
                    Upcoming Plan
                  </div>
                  {this.renderFullPlan(
                    this.props.billingData["new_plan_data"],
                    this.props.billingData.new_data[0],
                    true
                  )}
                </div>
              ) : null}
            </div>

            {this.props.accountData["plan"]["plan_id"] !== "unlimited" ? (
              this.props.billingData["changing"] === true &&
              getNested(this.props.billingData["new_plan_data"], "plan_id") ===
                "free" ? (
                <div
                  className="basier opacity-hover"
                  onClick={() => this.changePlan("revert_cancel")}
                  style={{
                    height: 40,
                    borderRadius: 35,
                    marginLeft: 0,
                    marginRight: 12,
                    marginTop: 12,
                    cursor: "pointer",
                    color: "white",
                    backgroundColor: "#F9716B",
                    width: 150,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  Undo Cancelation
                </div>
              ) : (
                <div
                  style={{
                    marginTop: 12,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div
                    className="button-blue opacity-hover"
                    onClick={() => this.changePlan("change")}
                    style={{
                      height: 40,
                      borderRadius: 35,
                      marginTop: 0,
                      cursor: "pointer",
                    }}
                  >
                    {this.props.accountData["plan"]["plan_id"] !== "free"
                      ? "Change Plan"
                      : "Upgrade"}
                  </div>

                  {this.props.accountData["plan"]["plan_id"] !== "free" ? (
                    <div
                      className="basier opacity-hover"
                      onClick={() => this.changePlan("cancel")}
                      style={{
                        height: 40,
                        borderRadius: 35,
                        marginLeft: 12,
                        marginRight: 12,
                        marginTop: 0,
                        cursor: "pointer",
                        color: "white",
                        backgroundColor: "#F9716B",
                        width: 150,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      Cancel plan
                    </div>
                  ) : null}
                </div>
              )
            ) : null}
          </div>
        ) : (
          <div style={{ height: 153 }}></div>
        )}

        <div
          className="basier-p4-caps"
          style={{ marginBottom: 24, marginTop: 24 }}
        >
          Account Usage & Limits
        </div>

        {this.props.limits !== null && this.props.limits !== undefined ? (
          <React.Fragment>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                marginBottom: 24,
              }}
            >
              <div
                className="basier-p3"
                style={{
                  marginBottom: 6,
                  textTransform: "uppercase",
                  fontWeight: 900,
                  width: 120,
                }}
              >
                Investments
              </div>
              <ProgressBar
                value={
                  this.props.limits.investment === null
                    ? 0
                    : this.props.limits.investment
                }
                limit={this.props.limits.investment_limit}
              />
              <div
                className="basier-p3"
                style={{
                  marginTop: 6,
                  textTransform: "uppercase",
                  fontWeight: 400,
                  width: 170,
                }}
              >
                {`${
                  this.props.limits.investment === null
                    ? "0"
                    : this.props.limits.investment.toLocaleString("en", {
                        maximumFractionDigits: 0,
                        minimumFractionDigits: 0,
                      })
                }$ / ${
                  this.props.limits.investment_limit == -1
                    ? "Unlimited"
                    : `${this.props.limits.investment_limit.toLocaleString(
                        "en",
                        { maximumFractionDigits: 0, minimumFractionDigits: 0 }
                      )}$`
                }`}
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                marginBottom: 24,
              }}
            >
              <div
                className="basier-p3"
                style={{
                  marginBottom: 6,
                  textTransform: "uppercase",
                  fontWeight: 900,
                  width: 120,
                }}
              >
                Transactions
              </div>
              <ProgressBar
                value={this.props.limits.transactions}
                limit={this.props.limits.transaction_limit}
              />
              <div
                className="basier-p3"
                style={{
                  marginTop: 6,
                  textTransform: "uppercase",
                  fontWeight: 400,
                  width: 170,
                }}
              >
                {`${this.props.limits.transactions.toLocaleString("en", {
                  maximumFractionDigits: 0,
                  minimumFractionDigits: 0,
                })} / ${
                  this.props.limits.transaction_limit == -1
                    ? "Unlimited"
                    : this.props.limits.transaction_limit
                }`}
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                marginBottom: 24,
              }}
            >
              <div
                className="basier-p3"
                style={{
                  marginBottom: 6,
                  textTransform: "uppercase",
                  fontWeight: 900,
                  width: 120,
                }}
              >
                Portfolios
              </div>
              <ProgressBar
                value={this.props.limits.portfolios}
                limit={this.props.limits.portfolio_limit}
              />
              <div
                className="basier-p3"
                style={{
                  marginTop: 6,
                  textTransform: "uppercase",
                  fontWeight: 400,
                  width: 170,
                }}
              >
                {`${this.props.limits.portfolios} / ${
                  this.props.limits.portfolio_limit == -1
                    ? "Unlimited"
                    : this.props.limits.portfolio_limit
                }`}
              </div>
            </div>
          </React.Fragment>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    accountData: state.auth.subscription,
    billingData: state.appwide.plan,
    limits: state.appwide.limits,
    user: state.auth.user_id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleModal: (data) => dispatch(interactionActions.toggleModal(data)),
    getUserLimits: (user) => dispatch(appwideActions.getUserLimits(user)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileLarge);
