import React from 'react';
import PropTypes from 'prop-types';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import * as actions from "../../../store/actions/interactions";
import * as dataActions from "../../../store/actions/data";
import { connect } from 'react-redux';
import PriceDenom from '../../AppWide/InputExtraSelect';
import axios from 'axios';
import { backendUrl } from '../../../variables';

class ResponsiveDialog extends React.Component {

state = {

    amount: null, 
    currency: 'target', 

}

sumbit = () => {

    let data = {
        user_id: this.props.user_id, 
        portfolio: this.props.portfolioId, 
        amount: this.state.amount, 
        currency: this.state.currency
    }

    let  url_call = `${backendUrl}/v1/position/${this.props.positionId}/`

    let headers = { "content-type": "application/json" };
        if (this.props.token !== null && this.props.token !== undefined) {

            headers['Authorization'] = `Token ${this.props.token}`

        }

        axios({method: 'post', url: url_call, data: data, headers: headers})
            .then(json => {
                
                this.props.componentDataFetch('update', this.props.currentView, this.props.currency, this.props.user_id, this.props.portfolioId, {},{},this.props.inBitcoin, this.props.token)

                setTimeout(() => {
                    this.handleClose()
                   }, 200);

            })
            .catch(e => {console.log(e)})

}

handleClose = () => {

    this.props.toggleModal({type: null, open: false, payload: null})
    
}

handleChange = (e) => {
    e.persist();
    this.setState(state => ({...state, amount: e.target.value}))
}

handleCurrencyChange = (val) => {
    this.setState(state => ({...state, currency: val.id}))
}

submit = () => {

}
  
  render() {

    
    return (

      <div style={{padding: 24, height: 200, witdth: 500, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
        
            <div className="basier-p2" style={{fontWeight: 900, color: 'black'}}>Close Position</div>
            
            <div className="basier-p4-caps" style={{marginBottom: 12}}>Realized P&L (enter negative values for loss)</div>

            <div style={{marginTop: 0, display: 'flex', alignItems: 'center'}}>
                <input autoFocus onChange={(e) => this.handleChange(e)} value={this.state.amount || ""} type="text" className="light-input" style={{marginLeft: 12, width: 200, borderRadius: '5px 0px 0px 5px'}} />
                <span className="basier-p4-caps">
                    <PriceDenom width={100} handleSelect={(val) => this.handleCurrencyChange(val)}  selection={this.state.currency} options={this.props.currencies} float='right' />
                </span>
            </div>


            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 24}}>

            <div 
                className='basier' onClick={this.handleClose} 
                style={{height: 40, borderRadius: 35, marginLeft: 6, marginRight: 6, cursor: 'pointer', color: 'white', backgroundColor: '#F9716B', width: 150, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                Cancel
            </div>

            <div 
                className='basier' 
                onClick={this.sumbit} 
                style={{height: 40, borderRadius: 35, marginLeft: 6, marginRight: 6, cursor: 'pointer', color: 'white', backgroundColor: '#0078DB', width: 150, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                Submit
            </div>
            

            </div>

      </div>

    )
  }
}

ResponsiveDialog.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
};



const mapStateToProps = state => {
    return {
        user_id: state.auth.user_id, 
        contracts: state.appwide.contracts, 
        token: state.auth.token, 
        portfolioId: state.data.menu_selected,
        currentView: state.data.currentView,
        currency: state.auth.currency.id,
        graphData: state.data.globalBalance,
        inBitcoin: state.data.bitcoinPf, 
    };
};

const mapDispatchToProps = dispatch => {
    return {
        toggleModal: (data) => 
            dispatch(actions.toggleModal(data)), 
        componentDataFetch: (scope, view, currency, user, portfolio, globalGraphData, portfolioGraphData, inBitcoin, tokenProvided) =>
            dispatch(dataActions.componentDataFetch(scope, view, currency, user, portfolio, globalGraphData, portfolioGraphData, inBitcoin, tokenProvided)), 
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withMobileDialog()(ResponsiveDialog));
