import React, { Component } from "react";
import LpQuoteSummary from "./LPQuoteSummary";
import LPPoolToken from "./LPPoolToken";
import LpTokenBalance from "./LPTokenBalance";
import Moment from "moment";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

class LPProfitLine extends Component {
  render() {
    const { label, value, size } = this.props;
    return (
      <div
        style={{
          width: "100%",
          paddingLeft: 0,
          display: "flex",
          textAlign: "left",
          justifyContent: "space-between",
          marginTop: 0,
        }}
      >
        <div
          className={size === "main" ? "basier-p3" : "basier-p4"}
          style={{
            fontStyle: size === "main" ? undefined : "italic",
            paddingLeft: size === "main" ? 0 : 6,
            fontWeight: size === "main" ? "bold" : 400,
          }}
        >
          {label}
        </div>
        <div
          style={{
            borderBottom: "1px dotted #6A7E934A",
            flex: 1,
            marginBottom: 8,
            marginLeft: 6,
            marginRight: 4,
          }}
        />
        <div
          className={size === "main" ? "basier-p3" : "basier-p4"}
          style={{
            display: "flex",
            alignItems: "center",
            color: value > 0 ? "#4CDAC1" : value < 0 ? "#F9716B" : "#6A7E93",
            fontWeight: size === "main" ? "bold" : 400,
          }}
        >
          {value > 0 ? (
            <ArrowDropUpIcon style={{ fontSize: size === "main" ? 26 : 20 }} />
          ) : value < 0 ? (
            <ArrowDropDownIcon style={{ fontSize: size === "main" ? 26 : 20 }} />
          ) : null}
          {Math.abs(value).toLocaleString("en", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </div>
      </div>
    );
  }
}

const styles = {
  overallBox: {
    minHeight: 150,
    width: "100%",
    backgroundColor: "white",
    borderRadius: 20,
    marginTop: 12,
    marginBottom: 12,
    display: "flex",
    flexDirection: "column",
    boxShadow: "0px 10px 10px rgba(225, 229, 233, 0.3)",
    border: "1px solid #E1E5E9",
    padding: "12px 24px",
    paddingBottom: 24,
  },
  top: {
    height: 120,
    width: "100%",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    marginBottom: 12,
  },
  bottom: {
    padding: "6px 12px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    border: "1px solid #e0a03d",
    borderRadius: 10,
    backgroundColor: "#e0a03d1A",
    marginTop: 12,
    height: 40,
  },
  bottomContainer: {},
  titleText: {
    fontWeight: "bold",
    textTransform: "uppercase",
  },
  bodyDivider: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
};
export default LPProfitLine;
