import React from 'react';
import { Creatable, components }  from 'react-select';
import { connect } from 'react-redux';
import plusButton from '../../../../../../Media/Plus.svg'
import * as actions from "../../../../../../store/actions/interactions";



const optionDisplay = (account) => {
    return (
      <div className="basier-p3" style={{textAlign: 'left'}}>
          {account.name}
      </div>
     
    )
}

const ValueContainer = ({ children, ...props }) => {
  return (
      <components.ValueContainer {...props}>
        <div className="basier-p3">
          {children}
        </div>
      </components.ValueContainer>
  )
};

const menuHeaderStyle = {
    padding: '8px 12px',
    background: 'white',
    color: 'black',
    fontWeight: 900,
  };

const MenuList = props => {
    return (
      <components.MenuList {...props}>
        <div className="basier-p4-caps" style={menuHeaderStyle}>Exchange accounts</div>
        {props.children}
      </components.MenuList>
    );
  };

let options = [];

class MenuSelector extends React.Component {

  state = {
    selectedOption: null
  }
  

  colourStyles = {
    control: styles => ({ ...styles, backgroundColor: 'transparent', width: this.props.width === undefined ? '250px' : this.props.width, height: this.props.height !== undefined ? this.props.height : 42, fontSize: 16, border: '0px', color: 'black', cursor: 'text', dispay:'inline-block'}),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
  
      return {
        ...styles,
        backgroundColor: isDisabled
          ? null
          : isSelected ? 'white' : isFocused ? '#F3F5F6' : null,
        color: isDisabled
          ? '#F3F5F6'
          : isSelected ? 'black' : 'black',
        opacity: isDisabled
          ? '1'
          : isSelected ? '1' : '0.7',
      };
    },
  
  }

  handleWalletSeed = () => {

    let accounts = null
    let customs = null
    if (this.props.straight) {
      accounts = this.props.accounts[this.props.exchange]
      customs = this.props.customs[this.props.exchange]
    } else {
      if (this.props.accounts !== undefined && this.props.exchange !== undefined && this.props.exchange !== null ) {
        accounts = this.props.accounts[this.props.exchange['value']]
      }
      if (this.props.customs !== undefined && this.props.exchange !== undefined && this.props.exchange !== null ) {
        customs = this.props.customs[this.props.exchange['value']]
      }
    }

    if (!accounts) {
      accounts = customs
    }

    options = [];
    let firstSelect = null; 

    if ( accounts !== undefined &&  accounts !== null) {

      const account_keys = Object.keys(accounts)

      firstSelect = {value: accounts[account_keys[0]]['id'], label: accounts[account_keys[0]]['name']}

      account_keys.map( (key) => {
        let values = {id: accounts[key]['id'], value: accounts[key]['name'], label: optionDisplay(accounts[key])};
        options.push(values)

        })

    }

    let selectedItem = []
    if (this.props.selected !== undefined) {

      selectedItem = options.filter(v => v.id == this.props.selected)

    }
    let selection = null;

    if (selectedItem.length === 1) {
      selection = selectedItem[0]
    } else {
      selection = firstSelect
      if (firstSelect !== null && firstSelect !== undefined) {
        this.props.updateTransaction({key: this.props.transactionId, label: "account", value: firstSelect['value']})
      }
      
    }

    this.setState(state => ({...state, options: options, selectedOption: selection}))


  }

  componentDidMount() {
    
    this.handleWalletSeed()
    
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.accounts !== this.props.accounts || this.props.exchange !== prevProps.exchange ) {

    this.handleWalletSeed();
    //   let accounts = null 
    //   if (this.props.straight) {
    //     accounts = this.props.accounts[this.props.exchange]
    //   } else {
    //     if (this.props.accounts !== undefined && this.props.exchange !== undefined && this.props.exchange !== null) {
    //       accounts = this.props.accounts[this.props.exchange['value']]
    //     }
    //   }

    //   options = [];

    //   if ( accounts !== undefined &&  accounts !== null) {

    //     const account_keys = Object.keys(accounts)

    //     this.setState(state => ({...state, selectedOption: {value: accounts[account_keys[0]]['id'], label: accounts[account_keys[0]]['name']}}))
    //     this.props.updateTransaction({key: this.props.transactionId, label: "account", value: accounts[account_keys[0]]['id']})

    //     account_keys.map( (key) => {
    //       let values = {id: accounts[key]['id'], value: accounts[key]['name'], label: optionDisplay(accounts[key])};
    //       options.push(values)
  
    //       })

  
    //   } else {
    //     this.setState(state => ({...state, selectedOption: null}))
    //   }

    //   this.setState(state => ({...state, options: options}))

    // }
    
    }
  }

  handleClear = () => {

    this.setState(state => ({...state,  selectedOption: null }));
    this.props.updateTransaction({key: this.props.transactionId, label: "account", value: -1})
    this.props.updateTransaction({key: this.props.transactionId, label: "account_name", value: null})
    //this.props.removeWallets()

  }
  handleChange = (selectedOption) => {
    
    if ( selectedOption.hasOwnProperty('__isNew__') ) {
      
      const selectedTest = selectedOption['value'].toLowerCase().trim()
      let existingIndex = null; 
      for (let i = 0; i < this.state.options.length; i++) {
        if (this.state.options[i]['value'].toLowerCase().trim() == selectedTest) {
          existingIndex = i
          break;
        }
      }
      if (existingIndex === null) { 

        if (selectedOption['__isNew__'] === true) {
          this.setState(state => ({...state,  selectedOption: selectedOption }));
          this.props.updateTransaction({key: this.props.transactionId, label: "account", value: -1})
          this.props.updateTransaction({key: this.props.transactionId, label: "account_name", value: selectedOption.value})

        }
      } else {

        const actualValue = this.state.options[existingIndex]
        if (selectedOption.value !== 'adder') {
          this.setState(state => ({...state,  selectedOption: actualValue }));
          this.props.updateTransaction({key: this.props.transactionId, label: "account", value: actualValue.id})
          this.props.updateTransaction({key: this.props.transactionId, label: "account_name", value: null})
  
        } else if (selectedOption.value === 'adder') {
          this.props.setNewWallet(false)
        }

      }

    }
    else if (selectedOption.value !== 'adder') {
        this.setState(state => ({...state,  selectedOption: selectedOption }));
        this.props.updateTransaction({key: this.props.transactionId, label: "account", value: selectedOption.id})
        this.props.updateTransaction({key: this.props.transactionId, label: "account_name", value: selectedOption.value})

    } else if (selectedOption.value === 'adder') {
        this.props.setNewWallet(false)
    }
    
  }
  
  getFocusedOption() {
    let selectedData = undefined;
    try { selectedData = this.myInput.select.select.state.focusedOption } catch(e) {}
   return selectedData
  }

  onKeyDown = (e) => {
    if (this.props.propagateKey === true) {

      if (e.key === 'Tab' || e.key === 'Enter' || e.keyCode === 13 || e.keyCode === 9) {
      e.preventDefault();
      const isShift = !!e.shiftKey
        if (isShift) {
          this.props.backToPrevious()
        } else {
          const data = this.getFocusedOption()
          if (data !== undefined) {
            this.handleChange(data)
          }
          this.props.getNextRef()
        }
      }
    }
  }

  render() {
    const { selectedOption } = this.state;

    return (
      <Creatable
        value={selectedOption}
        ref={(ip)=> this.myInput = ip}
        onChange={this.handleChange}
        onMenuOpen={() => this.handleClear()}
        options={this.state.options}
        isSearchable={true}
        styles={this.colourStyles}
        maxMenuHeight={180}
        minMenuHeight={180}
        components={{ ValueContainer, MenuList }}
        onKeyDown={this.onKeyDown}
        classNamePrefix="react-select-user"
        placeholder="Select or type new..."
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
          ...theme.colors,
            primary25: '#F3F5F6',
            primary: '#F3F5F6',
          },
        })}
      />
    );
  }
}



export default MenuSelector;
