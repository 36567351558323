import React from 'react';
import { connect } from 'react-redux';
import CloseIcon from '../../Media/smallClose.svg';
import loading from '../../Media/whiteloader.gif';
import okIcon from '../../Media/okIcon.svg';
import * as interActions from "../../store/actions/interactions";
import * as dataActions from "../../store/actions/data";
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import { backendUrl } from '../../variables'

const ColorCircularProgress = withStyles({
    
    root: {
        color: '#0078DB',
      },
      
  })(CircularProgress);


class TaskViewer extends React.Component {

    state = {
        min: false,
        done: false,
        hidden: true, 
    }

    
    componentDidMount = () => {
        
        const data = [
            //{"description": "Connecting exchange", "sub": "HitBTC", "status": 'pending'},
            //{"description": "Connecting exchange", "sub": "Binance", "status": 'pending'},
            //{"description": "Connecting exchange", "sub": "CoinBase", "status": 'completed'},
        ]

        this.props.setInteraction({label: "tasks", value: data})


        if (this.props.tasks !== undefined) {

            const activeTasks = this.props.tasks.filter(v => v.status === 'pending')
            if (activeTasks.length == 0) {
                this.setState(state => ({...state, hidden: true}))
            } else {
                this.setState(state => ({...state, hidden: false}))
                this.connectForUpdate()
            }

        }


    }

    componentDidUpdate = (prevProps) => {

        if (this.props.tasks !== prevProps.tasks && this.props.tasks !== undefined) {

            const activeTasks = this.props.tasks.filter(v => v.status === 'pending')
            if (activeTasks.length == 0) {
                this.setState(state => ({...state, done: true}))
            } else {
                this.setState(state => ({...state, done: false, hidden: false}))
            }

        }

    }


    showDetail = () => {
        this.setState(state => ({...state, min: false}))
    }

    hideDetail = () => {
        this.setState(state => ({...state, min: true}))
    }

    closePannel = () => {
        this.setState(state => ({...state, hidden: true}))
        const data = this.props.tasks.filter(v => v.status === 'pending')
        this.props.setInteraction({label: "tasks", value: []})
    }

    render () {
        
        return (
            this.state.hidden ? 
            null 
            :
            <div style={{overflowX: 'hidden'}}>
            
            {
                this.state.min ?

                    <div className="taskviewer" style={{height: 30, width: 210}}>
                        <div onClick={this.showDetail} style={{cursor: 'pointer', height: 30, backgroundColor: '#0078DB', color: 'white', fontWeight: 900, display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingLeft: 12}}>
                            
                            <div className="basier-p4-caps">Tasks in progress</div>
                            {this.state.done ? 
                            
                            <div onClick={this.closePannel} className="basier-p4 opacity-hover" style={{fontStyle: 'italic', paddingRight: 12}}>
                                <img src={CloseIcon} alt="" style={{height: 10, width: 10}} />
                            </div>

                            :
                            
                            <div onClick={this.hideDetail} className="basier-p4 opacity-hover" style={{fontStyle: 'italic', paddingRight: 12}}>
                                <img src={loading} alt="" style={{width: 15, height: 15}} />
                            </div>
                            
                            }

                        </div>
                    </div>

                :
                    <div className="taskviewer" style={{oveflowX: 'hidden', height: this.props.tasks.length >= 2 ? 150 : 110, width: 300, boxShadow: '0px 10px 10px rgba(225, 229, 233, 0.3)'}}>
                        <div  onClick={this.hideDetail} style={{cursor: 'pointer', height: 30, backgroundColor: '#0078DB', color: 'white', fontWeight: 900, display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingLeft: 12}}>
                            
                            <div className="basier-p4-caps">Tasks in progress</div>
                            {this.state.done ? 
                            
                            <div onClick={this.closePannel} className="basier-p4 opacity-hover" style={{fontStyle: 'italic', paddingRight: 12}}>
                                <img src={CloseIcon} alt="" style={{height: 10, width: 10}} />
                            </div>

                            :
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <div>
                                    <div onClick={this.hideDetail} className="basier-p4 opacity-hover" style={{fontStyle: 'italic', paddingRight: 12, width: 'fit-content'}}>hide</div>
                                </div>
                                <div onClick={this.closePannel} className="basier-p4 opacity-hover" style={{fontStyle: 'italic', marginRight: 12}}>
                                    <img src={CloseIcon} alt="" style={{height: 10, width: 10}} />
                                </div>
                            </div>
                            
                            }

                        </div>

                        <div style={{height: this.props.tasks.length >= 2 ? 120 : 70, width: '100%', overflow: 'scroll', padding: 12}}>
                            
                           {
                           
                                this.props.tasks.map((val, key) => {

                                    return (

                                        <div style={{height: 45, display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: key !== this.props.tasks.length -1 ? '1px solid #CCC' : null}} key={key}>

                                            <div style={{paddingLeft: 6}}>
                                                <div className="basier-p4-caps" style={{color: 'black'}}>{val.description}</div>
                                                <div className="basier-p4" style={{color: '#6A7E93'}}>{val.sub}</div>
                                            </div>
                                            
                                            <div style={{height: 15, paddingRight: 6}}>
                                            {val.status === 'pending' ?
                                                <ColorCircularProgress size={20} thickness={2} />
                                                :
                                                <div style={{display: 'flex', alignItems: 'center'}}>
                                                <img src={okIcon} alt="" style={{height: 25}} />
                                                </div>
                                            }
                                            </div>
                                            
                                            

                                        </div>

                                    )

                                })
                           
                           }

                        </div>
                    </div>

            }

            </div>
            
            
        )
    }


}

const mapStateToProps = state => {
    return {
        tasks: state.interactions.tasks, 
        token: state.auth.token,
        user_id: state.auth.user_id,
        inBitcoin: state.data.bitcoinPf, 
        currency_id: state.auth.currency.id, 
        currentPortfolio: state.data.menu_selected, 
        currentView: state.data.currentView, 
        graphSettings: state.data.globalBalance,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getImports: (data) => dispatch(dataActions.getImports(data)), 
        setInteraction: (data) => dispatch(interActions.setInteraction(data)), 
        componentDataFetch: (scope, view, currency, user, portfolio, globalGraphData, portfolioGraphData, inBitcoin) =>
            dispatch(dataActions.componentDataFetch(scope, view, currency, user, portfolio, globalGraphData, portfolioGraphData, inBitcoin)), 
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(TaskViewer)
