import React from 'react';
import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import * as actions from "../../store/actions/auth";
import loadingSpinner from '../../Media/contentLoader.svg'
/* import HighchartsReact from 'highcharts-react-official'*/
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import currencyData from '../../helpers/currency.json';
import {nFormatter} from '../../helpers/helpers';
const moment = require('moment-timezone');

/* (function(H) {
    H.Pointer.prototype.reset = function() {
      return undefined;
    };
  
    H.Point.prototype.highlight = function(event) {
      event = this.series.chart.pointer.normalize(event);
      this.onMouseOver(); // Show the hover marker
      //this.series.chart.tooltip.refresh(this); // Show the tooltip
      this.series.chart.xAxis[0].drawCrosshair(event, this); // Show the crosshair
    };
  
    H.syncExtremes = function(e) {
      var thisChart = this.chart;
  
      if (e.trigger !== "syncExtremes") {
        // Prevent feedback loop
        Highcharts.each(Highcharts.charts, function(chart) {
          if (chart && chart !== thisChart) {
            if (chart.xAxis[0].setExtremes) {
              // It is null while updating
              chart.xAxis[0].setExtremes(e.min, e.max, undefined, false, {
                trigger: "syncExtremes"
              });
            }
          }
        });
      }
    };
  })(Highcharts); */

const positionMarker = (series) => {

var chart = series.chart;
var lastPoint = series.points[series.points.length - 1];
if (lastPoint !== undefined) {
    chart.pulseMarker.attr({
        x: lastPoint.plotX - chart.plotLeft - chart.spacing[0],
        y: lastPoint.plotY + chart.plotTop + chart.spacing[2] - 3
    }, true);
}
}

const styles = {
    root: {
      color: '#6A7E93',
      '&$checked': {
        color: '#0078DB',
      },
    },
    checked: {
        color: '#0078DB'
    },
  };

const arrowDownSvg = 
  `<svg width="24" height="24" viewBox="12 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g filter="url(#filter0_d)">
  <rect x="10" y="0.00415039" width="24" height="24" rx="5" fill="white"/>
  </g>
  <path d="M26.2664 13.0848L21.7471 17.6041L17.1997 13.0848" stroke="#F9716B" stroke-width="2" stroke-miterlimit="10"/>
  <path d="M21.7473 17.6042V6.40417" stroke="#F9716B" stroke-width="2" stroke-miterlimit="10"/>
  <defs>
  <filter id="filter0_d" x="0" y="0.00415039" width="44" height="44" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
  <feFlood flood-opacity="0" result="BackgroundImageFix"/>
  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
  <feOffset dy="10"/>
  <feGaussianBlur stdDeviation="5"/>
  <feColorMatrix type="matrix" values="0 0 0 0 0.882353 0 0 0 0 0.898039 0 0 0 0 0.913725 0 0 0 0.3 0"/>
  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
  <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
  </filter>
  </defs>
  </svg>`

const arrowUp = 
`<svg width="24" height="24" viewBox="12 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d)">
<rect x="10" width="24" height="24" rx="5" fill="white"/>
</g>
<path d="M18 10.3684L22.4861 5.88234L27 10.3684" stroke="#4CDAC1" stroke-width="2" stroke-miterlimit="10"/>
<path d="M22.4854 5.88235L22.4854 17" stroke="#4CDAC1" stroke-width="2" stroke-miterlimit="10"/>
<defs>
<filter id="filter0_d" x="0" y="0" width="44" height="44" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
<feOffset dy="10"/>
<feGaussianBlur stdDeviation="5"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.882353 0 0 0 0 0.898039 0 0 0 0 0.913725 0 0 0 0.3 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
</filter>
</defs>
</svg>`

class BalanceGraph extends React.PureComponent {

    constructor(props) {

        super(props);
        this.state = {
            dateSelected: null,
            chart: null,
        }

    }

    handleBTCChange = e => {

            
        const new_scope = e.target.value;
        const globalData = {
            period: this.props.timePeriod,
            start: this.props.data.start,
            end: this.props.data.end, 
            scope: new_scope
        }
        
        this.props.setPfScope(new_scope)
        this.props.updateGraph('update', this.props.currentView, this.props.currency, this.props.user, this.props.portfolio, globalData, globalData, e.target.value)
        if (new_scope === 'bitcoin') {
            this.props.setBtcSymbol('BTC')
        } else {
            let currency_symbol = null;
            try { currency_symbol = currencyData[this.props.currency_name]["symbol"]} catch (e) { currency_symbol = this.props.currency_name}
            this.props.setBtcSymbol(currency_symbol)
        }
        
    }

    render () {

    const component = this
    const { classes } = this.props;
    const start_date = moment.tz(this.props.start_date, 'UTC')
    let data_date = Date.UTC(start_date.year(), start_date.month(), start_date.date())
    const symbol_to_use = this.props.coinGraph === true ? "$" : this.props.currencyScope === 'bitcoin' ? "BTC" : this.props.currency_symbol

    const options = {
        chart: {
            type: 'area', 
            backgroundColor: this.props.background,  
            animation: true,
            height: 250,
            events: {
                load: function() {
                    var chart = this;
                    chart.pulseMarker = this.renderer.text('<div class="ring-container"><div class="ringring"></div><div class="circle"></div></div>', chart.chartWidth - 50, -100, true).add();

                    // set up the updating of the chart each second
                    var series = this.series[0];
                    // change the position of pulse marker					
                    positionMarker(series);

                }, 
                redraw: function() {
                    var series = this.series[0];
                    // change the position of pulse marker					
                    positionMarker(series);

                }
            }
        },
        credits: false,
        title: false,
        xAxis: {
            className: 'basier-p4-caps',    
            minorGridLineWidth: 0,
            gridLineWidth: 0,
            type: 'datetime',
            minPadding:0,
            maxPadding:0,
            tickLength: 0,
            tickColor: 'black',
            labels: {
                padding: 30,
                style: {
                    fontSize: '12px',
                },
            }

        },
        yAxis: {
            className: 'basier-p4 graph-labels-x',
            title: {
                text: false,
            },
            align: 'left',
            opposite: false,
            minorGridLineWidth: 1,
            gridLineWidth: 1,
            gridLineColor: '#E1E5E9',
            labels: {
                x: window.innerWidth - 24, 
                style: {
                    fontSize: '12px',
                    textAlign: 'right',
                    align: 'left'
                },
                formatter: function() {
                    return nFormatter(this.value)
                }
            }
            
        },
        exporting: {
            enabled: false,
        },
        series: [{
            name: 'Portfolio value in USD',
            data: this.props.data,
            pointStart: data_date,
            pointInterval: 24 * 3600 * 1000,
            color: '#0078DB',
            lineWidth: 1.5,
            marker: {
                fillColor: "#fff", 
                lineColor: "#0078DB", 
                lineWidth: 2,
            }
        }],
        legend: {
            enabled: false
        },
        navigator: {
            enabled: false
        },
        scrollbar: {
            enabled: false
        },
        
        plotOptions: {
            series: {
                animation: false, 
                marker: {
                    enabledThreshold: 3
                },
            },
            area: {
                fillColor: {
                    linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1},
                    stops: [
                        [0, "rgba(0, 120, 219, 0.22)"],
                        [1, "rgba(0, 120, 219, 0)"]
                    ]
                },
            },
        },
        tooltip: {
            backgroundColor: 'transparent',
            borderWidth: 0,
            padding:6,
            shadow: false,
            style: {
            },
            useHTML: true,
            positioner: function () {
                return { x: 10, y: 40 };
            },
            formatter: function () {
                const changePrevious = this.y / this.series.yData[this.point.index - 1];
                let changeVue = null;
    
                if (changePrevious < 1) {
                    changeVue = arrowDownSvg
                } else if (changePrevious > 1) {
                    changeVue = arrowUp
                } else { 
                    changeVue = arrowUp
                }

                let pointformat = null;
                let momentDate = moment.tz(this.x, 'UTC')
                let currentDateUTC = moment.utc()
                if (
                    momentDate.year() === currentDateUTC.year() && 
                    momentDate.month() === currentDateUTC.month() && 
                    momentDate.date() === currentDateUTC.date() 
                    ) 
                {
                    
                    let hourShow = currentDateUTC.hours()
                    hourShow = ("0" + hourShow).slice(-2);
                    let minuteShow = currentDateUTC.minutes()
                    minuteShow = ("0" + minuteShow).slice(-2);

                    pointformat = `${Highcharts.dateFormat('%e %b %Y',new Date(this.x))} ${hourShow}:${minuteShow} UTC`
                } else {
                    pointformat = `${Highcharts.dateFormat('%e %b %Y',new Date(this.x))} 23:59 UTC`
                }
        
                return `<div><div class="small-tooltip"><span class="small-direction-tooltip">${changeVue} </span><span class="basier-p2">${symbol_to_use} ${parseFloat(this.y.toFixed(2)).toLocaleString('en', {minimumFractionDigits:2, maximumFractionDigits: 2})} </span></div><div class="basier-p4-caps">${pointformat}</div></div>`
                    }
        },
             
        rangeSelector: {
            enabled: true, 
            buttonPosition: {
                align: 'left', 
            }, 
            selected: 4,
            inputEnabled: false, 
            buttonSpacing: 3, 
            buttonTheme: { // styles for the buttons
                fill: 'none',
                stroke: 'none',
                padding: 10,
                alignItems: 'center',
                width: window.innerWidth / 6 - (3*4) - (16),
                'stroke-width': 0,
                r: 8,
                style: {
                    color: '#6A7E93',
                    fontSize: 16, 
                    display: 'flex', 
                    alignItems: 'center'
                },
                states: {
                    hover: {
                        fill: 'none',
                        style: {
                            color: '#0078DB'
                        },
                    },
                    select: {
                        fill: '#0078DB',
                        style: {
                            color: 'white',
                            fontWeight: 'bold',
                        }
                    }, 
                    // disabled: { ... }
                }
            },
            labelStyle: {
                color: 'white',
                display: 'none',
            },
            scrollbar: {
                enabled: false,
            },
        },
    } 
     
        return (
        
            <div>

            <Grid container 
            spacing={0}
            justify="space-between"
            alignItems="center"
            >       
                <Grid item style={{
                    marginLeft: 24,
                    display: 'flex'
                }}> 
                { this.props.loadedGraph ? 
                    
                    <div style={{marginLeft: 0, height: 83, width: '100%', display: 'flex', alignItems: 'center'}}>
                        <div className="pulse-skeleton" style={{width: 75, height: 75, borderRadius: 100, }}></div>

                        <div style={{marginLeft: 24, display: 'flex', flexDirection: 'column'}}>
                            <div className="pulse-skeleton" style={{width: 200, height: 30, borderRadius: 10, marginRight: 0}}></div>
                            <div className="pulse-skeleton" style={{width: 200, height: 18, marginTop: 12, borderRadius: 10, marginRight: 0}}></div>
                        </div>
                    </div>

                    :
                
                    <div style={{display: 'inline-block'}}>
                        <div 
                            className="basier-p4-caps"
                            style={{color: '#6A7E93',
                                    display: 'inline-block'}}
                            >
                            Total Balance
                        </div><br />
                        <h2 style={{display: 'inline-block'}}>{symbol_to_use} &nbsp;
                        {
                               this.props.today_value > 1 ? 
                               this.props.today_value.toLocaleString('en', {maximumFractionDigits: 2, minimumFractionDigits: 2})
                               :
                               this.props.today_value.toLocaleString('en', {maximumFractionDigits: 8, minimumFractionDigits: 8})

                        } 
                        </h2>
                    </div>
                    }
                </Grid>

                </Grid>
                
                <Grid container 
                    spacing={0}>

                    <div style={{width: '100%', marginLeft: 0, height: 250, paddingRight: 0, position: 'relative'}}>
                    { (this.props.loadedGraph === true) ?  <div className="loader-graph"><img style={{height: 64, with: 64}} src={loadingSpinner} alt="Loading..."/></div> : null }
                        
                    

                        <HighchartsReact
                            highcharts={Highcharts}
                            options={options}
                            oneToOne={true}
                        />

                    </div>
                    
                    <div class="form-check form-check-inline sp-l-12" style={{marginLeft: 'auto', marginRight: 'auto'}}>

                            <RadioGroup
                                name="btcScope"
                                value={this.props.inBitcoin}
                                onChange={this.handleBTCChange}
                                style={{display: 'inline-block'}}

                            >                              
                                <FormControlLabel value="fiat" 
                                                  control={<Radio classes={{
                                                      root: classes.root,
                                                      checked: classes.checked
                                                  }}/>} 
                                                  label={this.props.currency_name} />
                                <FormControlLabel value="bitcoin" 
                                                  control={<Radio classes={{
                                                      root: classes.root,
                                                      checked: classes.checked
                                                  }} />} 
                                                  label="BTC" />

                        
                            </RadioGroup>

                        </div>

                </Grid>

            </div>

            
            
        )

    }


        
};

BalanceGraph.propTypes = {
    classes: PropTypes.object.isRequired,
  };
  

const mapStateToProps = state => {
    return {
        currency_symbol: state.auth.currency.symbol,
        currency_name: state.auth.currency.id,
        currentView: state.data.currentView,
        inBitcoin: state.data.bitcoinPf, 

    };
};

const mapDispatchToProps = dispatch => {
    return {
        setBtcSymbol: (s) =>
            dispatch(actions.setBtcSymbol(s)), 
        
    };
  };

export default connect(
    mapStateToProps,
    mapDispatchToProps
)( withStyles(styles)(BalanceGraph));


