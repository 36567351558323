import React from 'react';
import { connect } from 'react-redux';
import Hidden from '@material-ui/core/Hidden';
import LargeNotif from './LargeNotifications';
import SmallNotif from './SmallNotifications';
import axios from 'axios';
import { backendUrl } from '../../variables'
import {handleNotifClick} from '../../helpers/helpers'
import * as actions from "../../store/actions/appwide";
import * as dataActions from "../../store/actions/data";


class NotificationsOverview extends React.PureComponent {

    handleClose = () => {

    }

    componentDidMount() {

        this.props.setCurrentView(999)

        if (this.props.notifs !== undefined) {
            if (this.props.notifs.unseen > 0) {
                let headers = { "content-type": "application/json" };
                if (this.props.token !== null && this.props.token !== undefined) {
        
                    headers['Authorization'] = `Token ${this.props.token}`
        
                }
                
                const url_call = `${backendUrl}/v1/see_notifs/`
                const data = {
                    "user_id": this.props.user_id
                }
        
                axios.post(url_call, data, {headers: headers})
                    .then(json => {
        
                    const new_number = 0
                        const notif_data = {
                            unseen: new_number,
                            unread: this.props.notifs.unread,
                            notifications: this.props.notifs.notifications
                        }
        
                        this.props.writeNotifs(notif_data)
        
                    })
                    .catch( e => {
        
                        console.log(e)
        
                    })
            }
        }


    }

    componentDidUpdate(prevProps) {
        
        if (this.props.notifs !== undefined && prevProps.notifs !== this.props.notifs) {
            if (this.props.notifs.unseen > 0) {
                let headers = { "content-type": "application/json" };
                if (this.props.token !== null && this.props.token !== undefined) {
        
                    headers['Authorization'] = `Token ${this.props.token}`
        
                }
                
                const url_call = `${backendUrl}/v1/see_notifs/`
                const data = {
                    "user_id": this.props.user_id
                }
        
                axios.post(url_call, data, {headers: headers})
                    .then(json => {
        
                    const new_number = 0
                        const notif_data = {
                            unseen: new_number,
                            unread: this.props.notifs.unread,
                            notifications: this.props.notifs.notifications
                        }
        
                        this.props.writeNotifs(notif_data)
        
                    })
                    .catch( e => {
        
                        console.log(e)
        
                    })
            }
        }


    }

    readAll = () => {

        if (this.props.notifs.unread > 0) {
            let headers = { "content-type": "application/json" };
            if (this.props.token !== null && this.props.token !== undefined) {
    
                headers['Authorization'] = `Token ${this.props.token}`
    
            }
            
            const url_call = `${backendUrl}/v1/notifications/${this.props.user_id}/`
            const data = {
                "user_id": this.props.user_id
            }
    
            axios.post(url_call, data, {headers: headers})
                .then(json => {
    
                    const old_notifs = this.props.notifs.notifications
                    let newData = old_notifs.map(el => {
                        return Object.assign({}, el, {is_read:true})
                        });
                        const notif_data = {
                            unread: 0,
                            unseen: 0,
                            notifications: newData
                        }

                        this.props.writeNotifs(notif_data)
        
                    })

                .catch( e => {
    
                    console.log(e)
    
                })
        }
    }

    toggleReadUnread = (e, notif, forceRead) => {

        if (e !== null ) {
            e.stopPropagation();
            e.preventDefault();
        }

        let headers = { "content-type": "application/json" };
        if (this.props.token !== null && this.props.token !== undefined) {
    
            headers['Authorization'] = `Token ${this.props.token}`
    
        }
        
        let proceed = false
        let data = {}
        if (forceRead === true) {

            if(notif.is_read === false) {
                data = {
                    is_read: true
                }
                proceed = true
            }
           

        } else {
            data = {
                is_read: !notif.is_read
            }
            proceed = true
        }
        
        if (proceed) {
            const url_call = `${backendUrl}/v1/notifications/${this.props.user_id}/${notif.id}/`
        
            axios.patch(url_call, data, {headers: headers})
                .then(json => {
        
                const old_notifs = this.props.notifs.notifications
                const new_number = !notif.is_read ? Math.max(this.props.notifs.unread - 1,0) : this.props.notifs.unread + 1
                let newData = old_notifs.map(el => {
                    if(el.id == notif.id)
                    return Object.assign({}, el, {is_read:!notif.is_read})
                    return el
                    });
                    const notif_data = {
                        unread: new_number,
                        unseen: this.props.notifs.unseen,
                        notifications: newData
                    }

                    this.props.writeNotifs(notif_data)
        
                })
                .catch( e => {
        
                    console.log(e)
        
                })

        }
    
    
    }

    render () {

        return (
            this.props.notifs !== null && this.props.notifs !== undefined ? 
            <div>
                <Hidden smDown>
                    <LargeNotif notifs={this.props.notifs} toggleReadUnread={this.toggleReadUnread} handleNotifClick={handleNotifClick} handleClose={this.handleClose} readAll={this.readAll} />
                </Hidden>

                <Hidden mdUp>
                    <SmallNotif notifs={this.props.notifs} toggleReadUnread={this.toggleReadUnread} handleNotifClick={handleNotifClick} handleClose={this.handleClose} readAll={this.readAll} />
                </Hidden>
            </div>
            :
            null
        )
    }


}

const mapStateToProps = state => {
    return {
        notifs: state.appwide.notifs, 
        token: state.auth.token,
        user_id: state.auth.user_id,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        writeNotifs: (data) => dispatch(actions.updateNotifs(data)), 
        setCurrentView: (view) => 
            dispatch(dataActions.setCurrentView(view)),
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(NotificationsOverview)
