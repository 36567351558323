import React from 'react';
import PropTypes from 'prop-types';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import * as actions from "../../../store/actions/interactions";
import { connect } from 'react-redux';

class ResponsiveDialog extends React.Component {

state = {
    subScope: this.props.subscopeValue !== undefined ? this.props.subscopeValue : null,
}

onSelectSub = (sub) => {
    this.setState(state => ({...state, subScope: sub}))
}

handleClose = () => {

    this.props.toggleModal({type: null, open: false, payload: null})
    
}

handleConfirm = () => {

    if (this.state.subScope !== null) {
        this.props.handleConfirm(this.state.subScope)
    } else {
        this.props.handleConfirm()
    }
    
    this.handleClose()

}

  
  render() {

    
    return (

      <div style={{height: 200, witdth: 500, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-around', padding: 12}}>
        
            <div className="basier-p3" style={{width: '100%', paddingLeft: 24, paddingRight: 24, textAlign: 'center'}}>{this.props.message}</div>

            {this.props.subscope !== undefined && <div><this.props.subscope onSelect={this.onSelectSub} subValue={this.state.subScope} /></div>}


            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center',}}>

            <div 
                className='basier' 
                onClick={this.handleConfirm} 
                style={{height: 40, borderRadius: 35, marginLeft: 12, marginRight: 12, cursor: 'pointer', color: 'white', backgroundColor: '#4CDAC1', width: 150, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                {this.props.forceYes ? this.props.forceYes : 'Yes'}
            </div>

            <div 
                className='basier' onClick={this.handleClose} 
                style={{height: 40, borderRadius: 35, marginLeft: 12, marginRight: 12, cursor: 'pointer', color: 'white', backgroundColor: '#F9716B', width: 150, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                {this.props.forceNo ? this.props.forceNo : 'No'}
            </div>

            </div>

      </div>

    )
  }
}

ResponsiveDialog.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
};


const mapStateToProps = state => {
    return {

        modalPref: state.interactions.transactionAddModal,
        portfoliosData: state.data.portfoliosData,
        transaction: state.interactions.transactionAddData,
        all_coins: state.appwide.all_coins, 
        exchangeCheck: state.interactions.exchangeCheck, 
        exchanges: state.appwide.exchanges, 
        wallets: state.appwide.wallets, 
        user_id: state.auth.user_id,
        user_first: state.auth.user_first_name,
        user_last: state.auth.user_last_name
        
    };
};

const mapDispatchToProps = dispatch => {
    return {
       toggleModal: (data) => 
            dispatch(actions.toggleModal(data)), 
        removeTransaction: (data) =>
            dispatch(actions.removeTransaction(data)), 
        setTrTime: (data) => 
            dispatch(actions.setTrTime(data)), 
        setInteraction: (data) => 
            dispatch(actions.setInteraction(data)), 
        
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withMobileDialog()(ResponsiveDialog));
