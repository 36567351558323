import React from 'react';
import currencyDict from '../../../../helpers/currency.json'
import okGreen from '../../../../Media/okGreen.svg'
import cancelRed from '../../../../Media/cancelRed.svg'
//import refreshSmall from "../../../../Media/refreshSmall.svg";
import { backendUrl } from '../../../../variables'
import { connect } from 'react-redux';
import * as appwideActions from "../../../../store/actions/appwide";
import * as actions from "../../../../store/actions/interactions";
import axios from 'axios';
import bin from '../../../../Media/bin.svg'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/core/styles';


const styles = theme => ({
    container: {
        width: '100%', 
        padding: '24px 48px', 
        display: 'flex',
        flexDirection: 'column'
    },
    managementBox: {
        width: '100%', 
        height: 70, 
        backgroundColor: '#F8F9FA', 
        display: 'flex', 
        justifyContent: 'space-between', 
        padding: '12px 24px',
    }, 
    transactionBox: {
        height: 380,
        display: 'flex', 
        flexDirection: 'row',
    },
    checkRoot: {
        color: '#6A7E93',
        '&$checked': {
          color: '#0078DB',
        },
      },
      checked: {
          color: '#0078DB'
      },
    });


const getValue = (dict, val) => {
    let ret = null
    try {ret = dict[val]} catch(e) {}
    return ret
}

const getNumber = (dict, sub) => {
    let ret = 0.0
    try {
        ret = dict[sub]
        if (ret === undefined) {
            ret = 0
        }
    } catch(e) {}
    return ret
}

const formatNum = (num) => {
    const digits = Math.abs(num) > 1 || num === 0 ? 2 : 6
    let form = null;
    try {
        form = num.toLocaleString('en', {minimumFractionDigits: digits, maximumFractionDigits: digits})
    } catch(e) {

    }
    return form
}

class WalletLine extends React.PureComponent {

    state = {
        walletEdit: false,
        newAddress: null,
    }

    onKeyDown = (e) => {

        switch (e.keyCode) {
            case 27: {
                this.commitEditAddress('cancel')
                break;
            }
            case 13: {
                this.commitEditAddress('ok')
                break;
            }

            default: {
                break;
            }
        }

    }

    showAddressEdit = (address) => {
        this.setState(state => ({...state, walletEdit:  true, newAddress: address }))
    }

    editAddress = (e) => {
        e.persist();
        this.setState(state => ({...state, newAddress: e.target.value }))
    }

    commitEditAddress = (action) => {
        if (action === 'cancel') {
            this.setState(state => ({...state, walletEdit: false }))
        } else if (action === 'ok') {
            this.setState(state => ({...state, loading:  true }))
            const data = {
                id: this.props.data.id, 
                address: this.state.newAddress
            }

            const newAdd = this.editWallet(data)
            newAdd
                .then(
                    data => {

                        let walletData  = {...this.props.data, address: this.state.newAddress}

                        const payload = {

                            data: walletData, 
                            coin: this.props.data.coin_id, 
                            account: this.props.accountId, 
                            user_id: this.props.user_id,
                            scope: 'exchange', 
                            exchange: this.props.exchange

                        }

                        this.props.updateSingleWallet(payload)
                        this.setState(state => ({...state, loading: false} ))
                        

                    }
                )
                .catch( err => console.log('err', err))
            this.setState(state => ({...state, walletEdit: false })) 


        }
        
    }

    editWallet = (data) => new Promise ((resolve, reject) => {

        
        //console.log(this.state)
        let headers = { "content-type": "application/json" };
        if (this.props.token !== null && this.props.token !== undefined) {

            headers['Authorization'] = `Token ${this.props.token}`

        }

        const send_data = {
            ...data, 
            user_id: this.props.user_id
        }

        const url_call = `${backendUrl}/v1/wallets/`

        
            axios.patch(url_call, send_data, {headers: headers})
                .then(json => {

                    
                    resolve(json['data'])

                })
                .catch( e => {

                    reject('error')

                })
        
        
    })

    deleteWallet = (scope) => {

        let headers = { "content-type": "application/json" };
        if (this.props.token !== null && this.props.token !== undefined) {

            headers['Authorization'] = `Token ${this.props.token}`

        }

        const url_call = `${backendUrl}/v1/wallets/${this.props.data.id}/?tx=${scope}&u=${this.props.user_id}`

        axios.delete(url_call, {headers: headers})
            .then(json => {

                // load new wallet list
                this.props.getWallets(this.props.user_id)
                //console.log(json['data'])

            })

    }

    subscopeComponent = (props) => {
        const {classes} = props;
        return (
            <div>
                <div className="basier-p3" style={{color: '#0078DB'}}>What should we do with transactions linked to this account</div>
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <RadioGroup
                        name="deleteScope"
                        value={props.subValue}
                        style={{display: 'flex', flexDirection: 'row'}}
                        onChange={(e) => props.onSelect(e.target.value)}
                        //style={{display: 'inline-block'}}

                    >   

                    <FormControlLabel value="keep" 
                                        control={<Radio classes={{
                                            root: classes.checkRoot,
                                            checked: classes.checked
                                        }} />} 
                                        label='Keep' />

                    <FormControlLabel value="cascade" 
                                        control={<Radio classes={{
                                            root: classes.checkRoot,
                                            checked: classes.checked
                                        }}/>} 
                                        label="Delete" />

                    
                </RadioGroup>
                </div>
            </div>
        )
    }

    styledQuestion = withStyles(styles)(this.subscopeComponent)

    confirmDelete = () => {
        this.props.toggleModal(
            {
                type: 'confirmation_modal', 
                payload: {
                    message: `You are about to delete this account.`,
                    forceYes: 'Delete',
                    forceNo: 'Cancel',
                    subscope: this.styledQuestion,
                    subscopeValue: 'keep',
                    handleConfirm: (scope) => this.deleteWallet(scope),
                }, 
                open: true
            }
        )
    }


    render() {
        const {data} = this.props
        const val = data 
        let scopeCoin
        try {
            scopeCoin = this.props.balance[this.props.data.id]['coin']
        } catch(e) {}
        

        return (((Math.abs(getValue(this.props.balance[this.props.data.id], 'balance')) > 0.000000001 && this.props.connected === true) ||  
                  (this.props.connected === false && (Math.abs(getValue(this.props.balance[this.props.data.id], 'balance')) > 0.000000001 || this.props.showHidden))
                  
                  )) &&
        (

            <div 
                style={{ 
                    height: 60,
                    paddingTop: 8, 
                    paddingBottom: 8,  
                    display: 'flex', 
                    alignItems: 'center', 
                    paddingLeft: 6, 
                    paddingRight: 6,
                    width: '100%',
                    borderBottom: '1px solid #CCC'
                    }} 
                    className="basier-p3 light-hover"
                >
              
                    <div style={{width: '25%', display: 'flex', alignItems: 'center'}} className="truncate">
                        <div>
                            {val.coin_image !== "" && val.coin_image !== null ? 
                            <span><img style={{height: 25, width: 25, marginRight: 12}} src={val.coin_image} alt=""/></span>
                            :
                            <span style={{height: 25, width: 25, marginRight: 12, display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 20 }}>
                                {currencyDict[val.coin_ticker] === undefined ? null : currencyDict[val.coin_ticker]['symbol'].length > 1 ? null : currencyDict[val.coin_ticker]['symbol'] }
                            </span>
                            }
                        </div>
                        <div style={{width: '100%'}} className="truncate">
                            <div className="basiser-p3 truncate" style={{color: 'black'}}>
                                {val.coin_ticker}
                            </div>
                            <div className="basier-p4-caps truncate" style={{color: '#6A7E93'}}>
                                {
                                val.coin_type === 'fiat' ? 
                                val.coin_ticker 
                                :
                                val.coin_name}
                            </div>
                        </div>
                    </div>


                {this.props.connected === true ? 

                    <div style={{width: '35%', display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>
                        <div className="basier-p3" style={{color: 'black'}}>
                            {formatNum(parseFloat(getNumber(this.props.reported, scopeCoin, 'amount')))}
                        </div>
                        {/* <div className="basier-p4-caps" style={{color: '#6A7E93'}}>
                        {formatNum(parseFloat(getNumber(this.props.connectedData, this.props.data.coin, 'free')))}
                        </div> */}
                    </div>
                
                :

                    <div style={{width: '35%', display: 'flex', alignItems: 'center',}} className='basier-p4'>
                        
                    {this.state.walletEdit ?
                
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <input autoFocus={true} autoComplete="false" className="light-input-back basier-p4" style={{flex: 1, minWidth: 250, maxWidth: 500, height: 30, zIndex: 10}} value={this.state.newAddress || ""} onChange={(e) => this.editAddress(e)} onKeyDown={(e) => this.onKeyDown(e)}/>
                            <div style={{width: '30%', display: 'flex', alignItems: 'center'}}>
                                <div style={{marginLeft: 6, marginRight: 6}} onClick={() => this.commitEditAddress('ok')}>
                                    <img src={okGreen} style={{height: 24, width: 24}} alt="ok" />
                                </div>
                                <div style={{marginRight: 6}} onClick={() => this.commitEditAddress('cancel')}>
                                    <img src={cancelRed} style={{height: 24, width: 24}} alt="no" />
                                </div>
                            </  div>
                        </div>

                        :

                        this.state.loading ? 

                            <div style={{height: 20,flex: 1, minWidth: 250}} className="pulse-skeleton"></div>

                        : 

                        
                        val.address !== null && val.address !== undefined && val.address !== '' ? 
                            
                            <div className="truncate" style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start', minWidth: 250, flex: 1}}>
                                <div className="basier-p4-caps truncate" style={{marginLeft: 12,}}>
                                    {val.address}
                                </div>
                                <div className="basier-p4 opacity-hover" style={{color: '#0078DB', cursor: 'pointer', width: 'fit-content', marginLeft: 6}} onClick={() => this.showAddressEdit(val.address)}>
                                edit
                                </div>
                            </div>
                        
                        :

                        <div className="basier-p4-caps opacity-hover" style={{color: '#0078DB', cursor: 'pointer', width: 'fit-content'}} onClick={() => this.showAddressEdit(val.address)}>+ add address</div>
                    }

                    </div>
                }

                <div style={{flex: 1, minWidth: '30%', textAlign: 'right'}}>
                    {this.props.balance === undefined || this.props.loading ? 
                    <div style={{width: '100%'}} className="pulse-skeleton" />
                    :
                    <div>
                        <div className="basier-p3" style={{color: 'black'}}>
                            {formatNum(getValue(this.props.balance[this.props.data.id], 'balance'))}
                        </div>
                        <div className="basier-p4-caps" style={{color: '#6A7E93'}}>
                            {this.props.currency_symbol}{formatNum(getValue(this.props.balance[this.props.data.id], 'balance') * getValue(this.props.balance[this.props.data.id], 'price'))}
                        </div>
                    </div>
                    }
                </div>
                
                
                <div style={{width: 50, textAlign: 'right'}}>
                {this.props.connected? null :
                    <img onClick={() => this.confirmDelete()} src={bin} style={{height: 25, width: 25, cursor: 'pointer'}} alt="del" />
                }
                </div>
                
            </div>

        )
    }

}

const mapStateToProps = state => {
    return {
        user_id: state.auth.user_id, 
        token: state.auth.token,
        walletsData: state.appwide.wallets, 
        currency_symbol: state.auth.currency.symbol
    };
};

const mapDispatchToProps = dispatch => {
    return {
        updateSingleWallet: (payload) => 
            dispatch(appwideActions.updateSingleWallet(payload)),
        toggleModal: (data) => 
            dispatch(actions.toggleModal(data)), 
        getWallets: (user) => dispatch(appwideActions.getWallets(user)), 
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(WalletLine)