import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";
import { StateType } from "rmc-tabs/lib/Tabs.base";


const initialState = {
    portfolioTab: 0, 
    snack: {
        show: false, 
        message: "", 
        origin: "", 
        variant: "",
    }, 
    overviewUpdated: null,
    assetModal: {
        open: false,
    },
    transactionAddModal: {
        open: false,
    }, 
    transactionAddData: {
        number: 0, 
        data: {},
    },
    modal: {
        type: null, 
        open: false, 
        payload: null, 
        //onClose: null, 
    },
    dateTransaction: null,
    feeCheck: false,
    exchangeCheck: false,
    transfersCheck: false, 
    transferAddData: {
        number: 0, 
        data: {},
    }, 
    walletTransaction: {},
    originSelected: null,
    mobileWalletType: 'exchange',
    priceType: 'unit',
    pin: true, 
    transactionFilter: {
        base_id: 'all', 
        quote_id: "all",
        date: 'all',
        user_id: "all", 
        transaction_description: 'all', 
        sort: 'desc',
    }, 
    positionFilter: {
        base_id: 'all', 
        exchange_id: 'all', 
        position_id: "all",
        date: 'all',
        user_id: "all",
        sort: 'desc', 
    },
    tasks: [],
    };



const updateTasks = (state, action) => {
    return updateObject(state, {tasks: [...state.tasks, ...action.data]})
}

    
const changeMobileWallet = (state, action) => {
    return updateObject(state, {mobileWalletType: action.data})
}

const setTransactionFilter = (state, action) => {
    return updateObject(state, {transactionFilter: action.data})
}

const setPositionsFilter = (state, action) => {
    return updateObject(state, {positionFilter: action.data})
}

const setModal = (state, action) => {
    return updateObject(state, {modal: {...state.modal, type: action.data.type, open: action.data.open, payload: action.data.payload }})
}

const setInteraction = (state, action) => {
    return updateObject(state, {[action.data.label]: action.data.value})
}

const showSnack = (state, action) => {
    return updateObject(state, {snack: {
        ...state.snack, 
        show: true, 
        message: action.data.message, 
        origin: action.data.origin,
        variant: action.data.variant,
    }})
}


const hideSnackState = (state, action) => {
    return updateObject(state, {snack: {
        ...state.snack, 
        show: false, 
    }})
}

const setUpdateTime = (state, action) => {
    return updateObject(state, {
        [action.data.scope]: action.data.payload
    })
}

const setTrTime = (state, action) => {

    return updateObject(state, {
        dateTransaction: action.data,
    })

}

const updatePfTab = (state, action) => {
    return updateObject(state, {portfolioTab: action.data})
}

const updateAssetModal = (state, action) => {
    return updateObject(state, {assetModal: {
                                ...state.assetModal, 
                                ...action.data}})
}

const updateTransactionAddModal = (state, action) => {
    return updateObject(state, {transactionAddModal: {
        ...state.transactionAddModal, 
        ...action.data}})
}

const addTransactionData = (state, action) => {
    return updateObject(state, {transactionAddData: {
                                        ...state.transactionAddData,
                                        data:   {...state.transactionAddData.data,
                                        [action.data.key]: {} }}})
}

const updateTransactionData = (state, action) => {
    return updateObject(state, {transactionAddData: {
                                        ...state.transactionAddData, 
                                        data: {...state.transactionAddData.data, 
                                                [action.data.key]: {...state.transactionAddData.data[action.data.key], 
                                                [action.data.label]: action.data.value }
                                        }
                                         }})
}

const removeTransactionData = (state, action) => {

    const idToRemove = action.data
    let newState = {...state.transactionAddData.data}
    delete newState[idToRemove]

    return updateObject(state, {transactionAddData: {
                                        ...state.transactionAddData, 
                                        data: newState
                                        }})
}



const addTransferData = (state, action) => {
    return updateObject(state, {transferAddData: {
                                        ...state.transferAddData,
                                        data:   {...state.transferAddData.data,
                                        [action.data.key]: {} }}})
}

const updateTransferData = (state, action) => {
    return updateObject(state, {transferAddData: {
                                        ...state.transferAddData, 
                                        data: {...state.transferAddData.data, 
                                                [action.data.key]: {...state.transferAddData.data[action.data.key], 
                                                [action.data.label]: action.data.value }
                                        }
                                         }})
}

const updateWalletData = (state, action) => {
    return updateObject(state, {walletTransaction: {
                                        ...state.walletTransaction, 
                                        [action.data.coin]: {...state.walletTransaction[action.data.coin], 
                                        [action.data.label]: action.data.value }
                                         }})
}

const removeWalletData = (state, action) => {
    return updateObject(state, {walletTransaction: {} })
}

const removeTransferData = (state, action) => {

    const idToRemove = action.data
    let newState = {...state.transferAddData.data}
    delete newState[idToRemove]

    return updateObject(state, {transferAddData: {
                                        ...state.transferAddData, 
                                        data: newState
                                        }})
}



const reducer = (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.UPDATE_PF_TAB:
                return updatePfTab(state, action);
        case actionTypes.CHANGE_MOBILE_WALLET: 
                return changeMobileWallet(state, action);
        case actionTypes.SNACKBAR_SHOW: 
                return showSnack(state, action);
        case actionTypes.SNACKBAR_HIDE: 
                return hideSnackState(state, action);
        case actionTypes.SET_UPDATE_TIME: 
                return setUpdateTime(state, action);
        case actionTypes.TOGGLE_ASSET_MODAL: 
                return updateAssetModal(state, action);
        case actionTypes.SET_MODAL: 
                return setModal(state, action);
        case actionTypes.TOGGLE_ADD_TRANSACTION_MODAL: 
                return updateTransactionAddModal(state, action);
        case actionTypes.ADD_TRANSACTION_DATA: 
                return addTransactionData(state, action);
        case actionTypes.UPDATE_TRANSACTION_DATA: 
                return updateTransactionData(state, action);
        case actionTypes.REMOVE_TRANSACTION_DATA: 
                return removeTransactionData(state, action);
        case actionTypes.ADD_TRANSFER_DATA: 
                return addTransferData(state, action);
        case actionTypes.UPDATE_TRANSFER_DATA: 
                return updateTransferData(state, action);
        case actionTypes.REMOVE_TRANSFER_DATA: 
                return removeTransferData(state, action);
        case actionTypes.UPDATE_WALLET_DATA: 
                return updateWalletData(state, action);
        case actionTypes.REMOVE_WALLET_DATA: 
                return removeWalletData(state, action);
        case actionTypes.SET_TR_TIME: 
                return setTrTime(state, action);
        case actionTypes.SET_INTERACTION: 
                return setInteraction(state, action);
        case actionTypes.SET_TR_FILTER: 
                return setTransactionFilter(state, action);
        case actionTypes.SET_POS_FILTER: 
                return setPositionsFilter(state, action);
        case actionTypes.APPWIDE_TASKS: 
                return updateTasks(state, action);
        default:
            return state;

    }
}
    
export default reducer;
