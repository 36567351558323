import React from 'react';
import { connect } from 'react-redux';
import * as actions from "../../store/actions/data";
import { loadData } from '../../helpers/helpers';
import { backendUrl } from '../../variables'
import LargeGraph from '../AppWide/FlexibleLargeGraph'

const downArrow = 
    (
    <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.3333 8.35083L6.68421 13.9999L1 8.35083" stroke="#F9716B" stroke-width="2" stroke-miterlimit="10"/>
    <path d="M6.68457 14V0" stroke="#F9716B" stroke-width="2" stroke-miterlimit="10"/>
    </svg>
    )

    const upArrow = 
    (
    <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.999933 7.50329L6.64905 1.85418L12.3333 7.5033" stroke="#4CDAC1" stroke-width="2" stroke-miterlimit="10"/>
    <path d="M6.64868 1.85414L6.64868 15.8541" stroke="#4CDAC1" stroke-width="2" stroke-miterlimit="10"/>
    </svg>
    )
    

class CoinOverview extends React.PureComponent {

    constructor(props) {

        super(props);
        this.coin_id = this.props.match.params.coin_id

        this.state = {

            history_data: null,
            coin_data: null, 
            coin_id: this.props.match.params.coin_id 
            
        }
    }

    
    componentDidMount() {

        this.props.setCurrentView(96);
        this.props.setMenuPortfolio(null);
        
        let headers = { "content-type": "application/json" };
        headers["Authorization"] = `Token ${this.props.token}`;

        const data_fetch = loadData(`${backendUrl}/v1/coin_details/?u=${this.props.user_id}&coin_id=${this.state.coin_id}`, headers)
        data_fetch
            .then(json => {

                this.setState(state => ({...state, coin_data: json['data']}))

                
            })
        
        const price_fetch = loadData(`${backendUrl}/v1/coin_history/?coin=${this.state.coin_id}&period=max`, headers)
        price_fetch
            .then(json => {

                this.setState(state => ({...state, history_data: json}))
                
            })



    }

    componentDidUpdate() {


        const newCoin = this.props.match.params.coin_id

        if (newCoin !== this.state.coin_id) { 

            this.setState(state => ({...state, coin_id: newCoin }));

            let headers = { "content-type": "application/json" };
            headers["Authorization"] = `Token ${this.props.token}`;

            const data_fetch = loadData(`${backendUrl}/v1/coin_details/?u=${this.props.user_id}&coin_id=${newCoin}`, headers)
            data_fetch
                .then(json => {

                    this.setState(state => ({...state, coin_data: json['data']}))

                    
                })
            
            const price_fetch = loadData(`${backendUrl}/v1/coin_history/?coin=${newCoin}&period=max`, headers)
            price_fetch
                .then(json => {

                    this.setState(state => ({...state, history_data: json}))
                    
                })

        }
    }


    render () {

        let change, price;
        try {
            change  = `${this.state.coin_data.change.toLocaleString('en', {minimumFractionDigits:2, maximumFractionDigits: 2}) }%`
        } catch(e) {}
        try {
            price  = `$ ${
                this.state.coin_data.price > 1 ? 
                    this.state.coin_data.price.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2})
                    :
                    this.state.coin_data.price.toLocaleString('en', { minimumFractionDigits: 6, maximumFractionDigits: 6})
                }`
        } catch(e) {}

        return (
            this.state.coin_data !== null && this.state.coin_data !== undefined ? 

            <div style={{padding: 48}}>

                <div style={{paddingLeft: 24, paddingRight: 24,}}>

                    <div style={{display: 'flex', alignItems: 'center', marginBottom: 18}}>
                        <img src={this.state.coin_data.image_large} alt="" style={{height: 40, width: 40, marginRight: 12}}></img>
                        <div style={{display: 'flex', alignItems: 'baseline'}}>
                            <div className="basier" style={{margin: 0, fontWeight: 900, color: 'black', fontSize: 34}}>{this.state.coin_data.display_name}</div>
                            <div className="basier" style={{fontSize: 22, marginBottom: 0, marginLeft: 8, textTransform: 'uppercase', color: '#6A7E93'}}>({this.state.coin_data.display_symbol})</div>
                        </div>
                    </div>

                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <div style={{marginRight: 12, display: 'flex', flexDirection: 'column', alignContent: 'center', justifyContent: 'center', textAlign: 'center',}}>
                            <div style={{marginBottom: 2}}>
                            {this.state.coin_data.change > 0 ? 
                            
                            upArrow

                            :

                            downArrow
                            
                            }
                            </div>
                            <div
                               className={`basier-p2 ${this.state.coin_data.change > 0 ? 'growth' : 'decline'}`}
                                >
                                {change}
                            </div>
                        </div>
                        <h2>
                            {price}
                        </h2>
                    </div>

                </div>
                
                {this.state.history_data !== null && this.state.history_data !== undefined ? 
                <div>
                    <LargeGraph coinGraph={true} start_date={this.state.history_data.start_date} data={this.state.history_data.data} />
                </div>
                :

                null
                }

                <div
                style={{
                    boxShadow: '0px 10px 10px rgba(225, 229, 233, 0.3)',
                    borderRadius: 14, 
                    position: 'relative',
                    width: '100%',
                    padding: 24,
                }}>
                    <div style={{marginBottom: 12}}>
                        <div className="basier" style={{fontSize: 18, textTransform: 'uppercase'}}>Details</div>
                    </div>

                    <div style={{display: 'flex', alignItems: 'baseline', flexWrap: 'wrap'}}>

                        <div style={{marginRight: 48, marginBottom: 12}}>
                            <div className="basier-p4-caps">Website</div>
                            <div>
                                <a href={this.state.coin_data.website} target="_blank" rel="noopener noreferrer">{this.state.coin_data.website}</a>
                            </div>
                        </div>

                        {this.state.coin_data.technical_doc !== null ? 

                            <div style={{marginRight: 48, marginBottom: 12}}>
                                <div className="basier-p4-caps">White paper</div>
                                <div>
                                    <a href={this.state.coin_data.technical_doc} target="_blank" rel="noopener noreferrer">See Whitepaper</a>
                                </div>
                            </div>
                            :
                            null

                        }

                        {this.state.coin_data.twitter !== null ? 

                            <div style={{marginRight: 48, marginBottom: 12}}>
                                <div className="basier-p4-caps">Twitter</div>
                                <div>
                                    <a href={`https://twitter.com/${this.state.coin_data.twitter}`} target="_blank" rel="noopener noreferrer">Go to Twitter</a>
                                </div>
                            </div>
                            :
                            null

                        }

                        {this.state.coin_data.explorer !== null ? 

                            <div style={{marginRight: 48, marginBottom: 12}}>
                                <div className="basier-p4-caps">Explorers</div>
                                <div style={{display: 'flex'}}>
                                {this.state.coin_data.explorer.slice(0,3).map((val, key) => {
                                    return(
                                        <div key={key} style={{marginRight: 6}}>
                                        <a href={val} target="_blank" rel="noopener noreferrer">{val}</a>; 
                                        </div>
                                    )
                                })}
                                </div>
                                <div>
                                   
                                </div>
                            </div>
                            :
                            null

                        }


                    </div>

                    <div style={{marginTop: 0}}>
                        {this.state.coin_data.description !== null ? 

                            <div style={{marginRight: 48}}>
                                <div className="basier-p4-caps" style={{marginBottom: 6}}>Description</div>
                                <div className="basier-p4">
                                    {this.state.coin_data.description}
                                </div>
                            </div>
                            :
                            null

                        }

                    </div>

                </div>
                
            </div>

            :

            null
        )
    }


}

const mapStateToProps = state => {
    return {
        token: state.auth.token, 
        user_id: state.auth.user_id,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setMenuPortfolio: (path) => 
        dispatch(actions.setMenuPortfolio(path)), 
        setCurrentView: (view) => 
            dispatch(actions.setCurrentView(view)),
        };
};


export default connect(mapStateToProps, mapDispatchToProps)(CoinOverview)
