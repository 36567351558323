import React from 'react';
import { connect } from 'react-redux';
import PositionLine from './PositionLine'
import currencyDict from '../../../helpers/currency.json'
import listIcon from '../../../Media/listIcon.svg'
import contentLoader from '../../../Media/contentLoader.svg'


const getCurrency = (ticker) => {
  let returnData = ticker;
  try {
      returnData = currencyDict[ticker]['symbol']
  } catch(e) {}

  return returnData
} 

class AccountSection extends React.PureComponent {

  state = {
    balances: null,
    loading: true,
  }
    

  
  render() {
    return(
      !this.props.balances ? 
      <div style={{width: '100%', dispay: 'flex', justifyContent: 'center', marginTop: 48, height: 300, textAlign: 'center'}}>
        <img src={contentLoader} style={{height: 50, width: 50}} alt="" />
      </div>
      :
      <div>
        {
        !this.props.balances.balances ? null :
          this.props.balances.balances.length === 0 ? 
            <div>
              <div style={{height: 200, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 12}}>
                  <div style={{height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', padding: 48, flexDirection: 'column'}}>
                      <div>
                          <img src={listIcon} alt="" style={{height: 80}} />
                      </div>
                      <div className="basier-p2" style={{marginTop: 24}}>
                          <div>You do not have any positions in this portfolio yet.</div>
                          <div>Once you add positions you can see them here.</div>
                      </div>
                  </div>
              </div>
            </div>
          :
            this.props.balances.balances.map((item, index) => {

              let open, close, amount, pnl, pnlpct, inversePnL, pnlquotepct, border, unsupported, pnlBaseCurrency, pnlQuoteCurrency, render;
              try {
                open = parseFloat(item.open_price)
                close = parseFloat(item.current_close)
                amount = parseFloat(item.amount)
                pnl = (close - open) * amount
                pnlpct = pnl / parseFloat(item.margin) * 100
                
                inversePnL = ( (1/open ) - (1/(close)) ) * (amount * open)
                pnlquotepct = ( inversePnL / (parseFloat(item.margin) / open) ) * 100
                border = false
                unsupported = item['base_ticker'] === 0 ? true : false
                pnlBaseCurrency = unsupported ? null : ['usd'].includes(item['base_ticker'].toLowerCase()) ? '$' : ['btc', 'xbt'].includes(item['base_ticker'].toLowerCase()) ? '₿' : getCurrency(item['base_ticker'])
                pnlQuoteCurrency = unsupported ? null : ['usd'].includes(item['quote_ticker'].toLowerCase()) ? '$' : ['btc', 'xbt'].includes(item['quote_ticker'].toLowerCase()) ? '₿' : getCurrency(item['quote_ticker'])
                render = true
              } catch(e) {
                render = false;
              }

              return (
                render ? 
                <div key={index}>
                  <div style={{width: '100%'}}>
                  <PositionLine menuItems={null} border={border} key={index} index={index} item={item} pnl={pnl} inversePnL={inversePnL} currency_symbol={pnlBaseCurrency} quote_currency={pnlQuoteCurrency} pnlpct={pnlpct} pnlquotepct={pnlquotepct} unsupported={unsupported} />
                  </div>
                </div> : null

              )

            })
        }
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
      currency: state.auth.currency.id,
      currency_symbol: state.auth.currency.symbol,
      balances: state.data.accountPositions,
  };
};


export default connect(
  mapStateToProps,
  null
)(AccountSection);