import React from 'react';
import { connect } from 'react-redux';
import * as actions from "../../../../../store/actions/data";
import * as interActions from "../../../../../store/actions/interactions";
import SmallTransactionOverview from './SmallTransactionOverview'
import SmallTransactionEditDetail from './SmallTransactionEditDetail'
import TransactionEdit from './TransactionAddComponents/TransactionEdit'
import PositionEdit from './TransactionAddComponents/PositionEdit'
import ApiConnect from './TransactionAddComponents/SmallAPIConnect'
import wizardIcon from '../../../../../Media/wizard-blue.svg'
import quickIcon from '../../../../../Media/quickadd-blue.svg'
import positionIcon from '../../../../../Media/positionIcon.svg'
import BackIcon from "../../../../../Media/CloseIcon.svg";

class TransactionWrapper extends React.PureComponent {

    state = {
        transactionDetail: null,
        add: false,
        type: 'welcome'
    }


    addNew = () => {
        this.setState(state => ({...state, add: true}))
    }

    cancelAdd = () => {
        this.setState(state => ({...state, add: false})) 
    }

    selectTransaction = (val) => {

        this.setState(state => ({...state, transactionDetail: val}))

    }


    componentDidMount() {

        this.props.setCurrentView(51)
        
       //this.props.setCurrentView(5)
       //this.props.componentDataFetch('get', 5, this.props.currency_id, null, this.props.portfolioId, null, null, this.props.inBitcoin)

    } 
    
    getOverallData = () => {
        this.props.componentDataFetch('get', 5, this.props.currency_id, null, this.props.portfolioId, null, null, this.props.inBitcoin)
    }

    toggleTxModal = () => {

        //this.props.toggleTransactionAddModal({open: true});

    }

    render() {
         
        return (

            <div style={{top: 0, zIndex: 999999, width: '100%', position: 'absolute', height: '100%'}}>

                <div style={{backgroundColor: '#F8F9FA', zIndex: 9999999, height: 75, width: '100%', display:'flex', alignItems: 'center', justifyContent: 'space-between', position: 'fixed', top: 0}} id="responsive-dialog-title">
                    
                    <div onClick={this.props.cancelAdd} style={{width: '25%'}}>
                        <img style={{paddingTop: 24}} src={BackIcon} alt=""/>
                    </div>

                    <div className="basier-p4-caps" style={{color: 'black', width: '50%', textAlign: 'center'}}>
                        Transaction Entry
                    </div>

                    <div style={{width: '25%'}}>
                        
                    </div>

                </div>

               {this.state.type === 'welcome' ? 

                <div style={{marginTop: 80, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: 12}}>
                    
                    {
                        ['transaction', 'position', 'connection'].map((val, key) => {

                            const iconNeeded = () => {
                                switch(val) {
                                    case 'transaction': {
                                        return wizardIcon
                                    }
                                    case 'position': {
                                        return positionIcon
                                    }
                                    case 'connection': {
                                        return quickIcon
                                    }
                                    default: 
                                        return null;
                                }
                            }

                            const mainTitle = () => {
                                switch(val) {
                                    case 'transaction': {
                                        return <div className="basier" style={{fontSize: 18, textTransform: 'uppercase', color: 'black'}}>Cryptocurrency</div>
                                    }
                                    case 'position': {
                                        return <div className="basier" style={{fontSize: 18, textTransform: 'uppercase', color: 'black'}}>Derivatives</div>
                                    }
                                    case 'connection': {
                                        return <div className="basier" style={{fontSize: 18, textTransform: 'uppercase', color: 'black'}}>API Connection</div> 
                                    }
                                    default: 
                                        return null;
                                }
                            }

                            const subTitle = () => {
                                switch(val) {
                                    case 'transaction': {
                                        return <div style={{marginTop: 12}} className="basier-p4">Enter transactions on spot or OTC cryptocurrencies.</div>
                                    }
                                    case 'position': {
                                        return <div style={{marginTop: 12}} className="basier-p4">Enter a position taken in a derivatives contract.</div>
                                    }
                                    case 'connection': {
                                        return <div style={{marginTop: 12}} className="basier-p4">Connect your wallets / exchanges for automatic imports.</div>
                                    }
                                    default: 
                                        return null;
                                }
                            }

                            return (

                                    <div 
                                    key={key}
                                    onClick={() => this.setState(state => ({...state, type: val, welcome: false}))}
                                    className="multiple-tr"
                                    style={{width: '100%', height: 100, borderRadius: 20, 
                                            boxShadow: '0px 10px 10px rgba(225, 229, 233, 0.6)', 
                                            marginTop: 24,
                                            display: 'flex', alignItems: 'flex-start', justifyContent: 'center'}}>

                                    <div style={{width: '100%', display: 'flex', flexDirection: 'column'}}>

                                        <div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-start', padding: 12}}>
                                            <div style={{width: 80}}>
                                                <img src={iconNeeded()} style={{height: 60, width: 60, marginRight: 24}} alt=""/>
                                            </div>
                                            <div className="basier-p3" style={{display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column'}}>
                                                {mainTitle()}
                                                {subTitle()}
                                            </div>
                                        </div>
                                    
                                    </div>
                                    

                                </div>


                            )

                        })
                    }



                </div>

                : 

                this.state.type === 'transaction' ? 

                <TransactionEdit refreshAddData={this.props.refreshAddData} new={true} portfolioId={this.props.portfolioId} cancelAdd={this.props.cancelAdd} passedData={undefined} />

                : 

                this.state.type === 'position' ? 

                <PositionEdit refreshAddData={this.props.refreshAddData} new={true} portfolioId={this.props.portfolioId} cancelAdd={this.props.cancelAdd} passedData={undefined} />

                :

                this.state.type === 'connection' ? 

                <ApiConnect  portfolioId={this.props.portfolioId} cancelAdd={this.props.cancelAdd} />

                :

                null
                
                }
               
            </div>

        )
    }
  
};

const mapStateToProps = state => {
    return {
        inBitcoin: state.data.bitcoinPf, 
        currency_id: state.auth.currency.id
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setCurrentView: (view) => 
            dispatch(actions.setCurrentView(view)),
        componentDataFetch: (scope, view, currency, user, portfolio, globalGraphData, portfolioGraphData, inBitcoin) =>
            dispatch(actions.componentDataFetch(scope, view, currency, user, portfolio, globalGraphData, portfolioGraphData, inBitcoin)), 
        toggleTransactionAddModal: (data) => 
            dispatch(interActions.toggleTransactionAddModal(data)),
        
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TransactionWrapper);