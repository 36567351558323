import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import * as actions from "../../../store/actions/interactions";
import { connect } from 'react-redux';
import worker from "../../../workers/assetWorker";
import WebWorker from "../../../workers/WebWorker";
import AssetModalContent from './AssetModalContent'
import { backendUrl } from '../../../variables'

class AssetModal extends React.Component {

  constructor(props) {
    
    super(props);

    this.state = {
        open: false,
        coin_in_scope: null, 
        graph_data: [], 
        graph_start: null,
    }
    

  };

  handleClose = () => {
    this.props.toggleAssetModal({open: false, coin: null});
  };

  componentDidMount() {

    this.worker = new WebWorker(worker);

    this.worker.addEventListener('message', event => {
        const coin_in_scope = event.data 

        this.setState(state => ({...state, 
                                coin_in_scope: coin_in_scope,
                                }));

    });

    this.worker.postMessage([this.props.portfoliosData[this.props.portfolioId].balances, this.props.modalPref.coin])

  }


  render() {

    const { fullScreen } = this.props;
    const { coin_in_scope } = this.state;

    return (
    
      this.state.coin_in_scope === null ? 

      null 
      
      :

      <div>
        <Dialog
          fullScreen={fullScreen}
          open={this.props.modalPref.open}
          onClose={this.handleClose}
          aria-labelledby="responsive-dialog-title"
          classes={{ paper: 'modal-root' }}
        >
         
            <AssetModalContent coin_in_scope={coin_in_scope} graphData={this.state.graph_data} startDate={this.state.graph_start} />

        </Dialog>
      </div>
    );
  }
}

AssetModal.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
};


const mapStateToProps = state => {
    return {

        modalPref: state.interactions.assetModal,
        portfoliosData: state.data.portfoliosData,
        
    };
};

const mapDispatchToProps = dispatch => {
    return {
        toggleAssetModal: (currency, scope) => 
            dispatch(actions.toggleAssetModal(currency, scope)), 
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withMobileDialog()(AssetModal));