import React from 'react';
import { connect } from 'react-redux';
import {loadData} from '../../helpers/helpers'
import { backendUrl } from '../../variables'
import {renderAuditType} from '../../helpers/helpers'
import { normalizeTickInterval } from 'highcharts';

const getter = (dict, key) => {
    try{
        return dict[key]
    }
    catch(e) {
        return null
    }
}
class GlobalAuditWrapper extends React.PureComponent {

    state = {
        auditData: null
    }

    renderContent = (new_data, old_data, data) => {

        let value = null;
        if (data.action === 'delete') {
            value = old_data 
        } else {
            value = new_data
        }

        if (data !== null && data !== undefined && value !== null && value !== undefined) {
            switch(data.scope) {

                case 'portfolio': {
                    return value.name;
                    break;
                }
                case 'transaction': {

                    let base = '';
                    try {base = value.base_currency.symbol !== undefined ? value.base_currency.symbol : 
                    '' } catch(e) {}

                    let amount = '';
                    try {amount = value.amount !== undefined ? value.amount : '' } catch(e) {}

                    let quote = '' 
                    try {quote = value.quote_currency.symbol !== undefined ? value.quote_currency.symbol : '' } catch(e) {}

                    let type = '' 
                    try {type = value.transaction_type !== undefined ? value.transaction_type : '' } catch(e) {}
                    
                    const caller = `${type} ${amount} ${base} ${quote !== '' ? `/ ${quote}` : ''}`                
                    return caller

                }
                case 'upload': {
                    return getter(data.portfolio, 'name')

                }
                case 'exchange': {
                    if(value.custom_exchange_check === true && value.custom_exchange !== null) { 
                        return `${value.name} - ${value.custom_exchange}`
                    } else if (value.custom_exchange_check === false && value.exchange !== null) {
                        return `${value.name} - ${value.exchange.name}`
                    } else {
                        return `${value.name}`
                    }

                }
                case 'wallet': {

                    let coin_symbol = undefined;
                    try {coin_symbol = value.coin.symbol} catch(e) {}

                    if (value.wallet_type === 'storage' ) { 

                        if(coin_symbol !== undefined) { 
                            return `${value.name} - ${coin_symbol}`
                        } else {
                            return `${value.name}`
                        }
                    } else {

                        if(coin_symbol !== undefined && value.exchange.name !== undefined) { 
                            return `${value.exchange.name} - ${coin_symbol}`
                        } else {
                            return `${value.name}`
                        }
                    }

                }
                default: 
                    return null


            }
        }

    }

    componentDidMount() {

        let headers = { "content-type": "application/json" };
        headers["Authorization"] = `Token ${this.props.token}`;

        let urlCall = null;

        if (this.props.scope === 'global') {
            urlCall = `${backendUrl}/v1/audit_trail/?u=${this.props.user_id}`
        } else if (this.props.scope === 'portfolio') {
            urlCall = `${backendUrl}/v1/audit_trail/?p=${this.props.portfolioId}&u=${this.props.user_id}`
        }

        const data_fetch = loadData(urlCall, headers)
        data_fetch
            .then(json => {

                this.setState(state => ({...state, auditData: json['data']}))

            })
            .catch(e => {
                console.log(e)
            })



    }

    render () {

        return (

            this.state.auditData !== null && this.state !== undefined ?
                <div style={{width: '100%'}}>

                   <div style={{marginTop: 12, marginLeft: 12, marginBottom: 24}}>
                        {
                            this.props.scope === 'global' ?
                            <React.Fragment>
                                <h3 style={{margin: 0}}>
                                    Audit Trail
                                </h3>
                                <div className="basier-p4-caps">
                                    {
                                    this.props.scope === 'global' ?
                                    `${this.props.first_name} ${this.props.last_name}`
                                    :
                                    this.props.scope === 'portfolio' ?
                                    `${this.props.data.portfolio_name}`
                                    :
                                    null
                                    }
                                </div>
                            </React.Fragment>
                            :
                            <h3>
                                {`${this.props.data.portfolio_name}`}
                            </h3>
                        }
                    </div>


                    {
                        this.state.auditData.map((val, key) => {

                            const language = navigator.language || 'en'
                            const options = {year: 'numeric', month: 'numeric', day: 'numeric' };
                            const parseDate = new Date(val.created_at);
                            const showDate = parseDate.toLocaleDateString(language,options)

                            return (

                                <div key={val.id} className="exchange-flat-list-item" style={{paddingRight: 24, paddingLeft: 24, minHeight: 65 }}>

                                    <div>

                                        <div style={{display: 'flex', alignItems: 'baseline'}}>
                                            <div 
                                                className="basier-p4-caps" 
                                                style={{
                                                    fontWeight: 900,
                                                    color: val.action === 'create' ? 'green' : val.action === 'edit' ? 'orange' : 'red', 
                                                }}>
                                            {renderAuditType(val.scope, val.action)}
                                            </div>
                                            <div style={{marginLeft: 6, marginRight: 6}}>-</div>
                                            <div className="basier-p4-caps">
                                                {showDate}
                                            </div>
                                        </div>

                                        <div style={{display: 'flex', alignItems: 'baseline', marginTop: 0}}>
                                            <div className="basier-p4-caps" style={{fontWeight: 900}}>
                                            {this.renderContent(val.new_data, val.old_data, val)}
                                            </div>
                                            <div style={{marginLeft: 6, marginRight: 6}}>-</div>
                                            <div className="basier-p4-caps">
                                                {
                                                
                                                val.action !== 'delete'?
                                                
                                                    val.portfolio !== null && val.portfolio !== undefined ? 
                                                    
                                                    this.props.scope === 'global' ?
                                                    val.portfolio.name 
                                                    :
                                                    val.new_data !== null && val.new_data !== undefined && val.new_data.user_transaction !== null && val.new_data.user_transaction !== undefined ? 
                                                    `${val.new_data.user_transaction.first_name} ${val.new_data.user_transaction.last_name !== undefined  ? val.new_data.user_transaction.last_name.slice(0,1) : ''}`
                                                    :
                                                    null

                                                    :

                                                    null
                                                :

                                                    val.portfolio !== null && val.portfolio !== undefined ? 
                                                        
                                                    this.props.scope === 'global' ?
                                                    val.portfolio.name 
                                                    :
                                                    val.old_data !== null && val.old_data !== undefined && val.old_data.user_transaction !== null && val.old_data.user_transaction !== undefined ? 
                                                    `${val.old_data.user_transaction.first_name !== undefined ? val.old_data.user_transaction.first_name : ''} ${val.old_data.user_transaction.last_name !== undefined ? val.old_data.user_transaction.last_name.slice(0,1) : ''}`
                                                    :
                                                    null

                                                    :

                                                    null

                                            
                                                }
                                            </div>
                                        </div>
                                        
                                    </div>

                                    <div>
                                        
                                    </div>


                                </div>
                            
                            )

                        })
                    }

                </div>
            :
            null
            
        )
    }


}

const mapStateToProps = state => {
    return {
        user_id: state.auth.user_id, 
        token: state.auth.token,
        first_name: state.auth.user_first_name, 
        last_name: state.auth.user_last_name, 
    };
};

const mapDispatchToProps = dispatch => {
    return {
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(GlobalAuditWrapper)
