import React, { Component } from "react";

const next_steps = {
  binance: {
    message:
      "Binance's API does not provide BNB small balance swaps older than 12 months. If you have made any BNB-convert transactions, you may need to add them manually or through CSV import.",
  },
  upbit_indonesia: {
    message:
      "Upbit Indonesia's API does not include any trades data on delisted coins (e.g. KMD, PI...). Deposits and Withdraws are however included. If you had traded any delisted coins, Please input any older trades manually or through CSV import.",
  },
  bitvavo: {
    message:
      "Bitvavo's API does not provide information staking/rewards income yet. Please input any staking/rewards transactions manually or through CSV import.",
  },
};

export default class NextStepsApi extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  goToTransactions = () => {
    this.props.goToEntry();
  }

  render() {
    const { account_id } = this.props;
    return Object.keys(next_steps).includes(account_id) ? (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: 24,
          paddingLeft: 24,
          paddingRight: 24,
          textAlign: "center",
        }}
      >
        <div
          className="basier"
          style={{ color: "red", fontSize: 36, fontWeight: "bold" }}
        >
          Required Action
        </div>
        <div style={{ color: "black", marginTop: 12 }} className="basier-p3">
          {next_steps[account_id].message}
        </div>
        <div
          className="button-next-blue"
          onClick={() => this.goToTransactions()}
          style={{
            width: 200,
            height: 40,
            borderRadius: 35,
            marginLeft: 12,
            marginRight: 12,
            marginTop: 24,
            cursor: "pointer",
          }}
        >
          Enter Transactions
        </div>
      </div>
    ) : (
      null
    );
  }
}
