import React from "react";
import { connect } from "react-redux";


const UpdateNotify = (props) => {

  let active = 0;
  try {
    active = props.tasks.filter(v => v.type == "connection" && v.status == 'pending').length
  } catch(e) {
    active = 0
  }
  return (
    active > 0 && 
    <div className="basier-p3" style={{flex: 1, border: '2px solid #C174FD', minHeight: 40, marginLeft: 48, marginRight: 48, padding: '12px 24px', borderRadius: 10, backgroundColor: "#C174FD1A"}}>
        <div>Your API connections are updating at the moment. While they update, there may be some discrepancies in results.</div>
    </div>
  )

}

const mapStateToProps = state => {
  return {
    tasks: state.interactions.tasks, 
  };
};

export default connect(
  mapStateToProps,
  null
)(UpdateNotify);