import React from 'react';
import CoinSearch from '../../../../../AppWide/CoinSearch';
import ToOriginList from './ToOriginList'
import ExchangeQuestion from './ExchangeQuestion'
import * as actions from "../../../../../../store/actions/interactions";
import { connect } from 'react-redux';
import WalletQuestion from './WalletQuestion'
import UserList from '../UserList'
import DetailWalletList from '../QuestionBlocks/DetailWalletList'

class SelectSource extends React.PureComponent {


    state = {
        originSelected: null, 
    }

    componentDidMount() {
        
        if (this.props.scopedPortfolios !== null && this.props.scopedPortfolios !== undefined) {
            this.setState(state => ({...state, scopeUser: this.props.user_id}))
        }

    }


    changeOrigin = (origin) => {

        this.props.updateTransaction({key: this.props.transactionId, label: "from_wallet", value: origin})

    }

    handleDestinationSameChange = (val) => {

        this.setState(state => ({...state, userSend: val.value}))
        
    }

    render() {

        return (

            <div>
                
                
                <div style={{

                display: 'flex', 
                height: 60,//height: `${this.props.transaction['data'][this.props.transactionId]['quote_currency'] !== null && this.props.transaction['data'][this.props.transactionId]['quote_currency']  !== undefined ? '60px' : '300px'}`, 
                alignItems: 'baseline',
                transition: 'height 0.3s'
                }}>

                    <div className='basier-p3' style={{marginTop: 12, paddingLeft: 0, width: 500, marginBottom: 6, zIndex: 7000, marginRight: 24, fontWeight:  900}}>From which wallet did you send the coins?</div>
                    <DetailWalletList wallets={this.props.scopedWallets} scopedUser={this.props.user_id} base_currency={this.props.selectedBase['value']} handleChange={this.changeOrigin} />
                    
                </div>

               

            </div>

        )
    }

}


const mapStateToProps = state => {
    return {
  
        modalPref: state.interactions.transactionAddModal,
        transaction: state.interactions.transactionAddData,
        tr_date: state.interactions.dateTransaction,
        exchangeCheck: state.interactions.exchangeCheck, 
        originSelected: state.interactions.originSelected,
        exchanges: state.appwide.exchanges,
        wallets: state.appwide.wallets,
        currentPortfolio: state.data.menu_selected, 
        scopedPortfolios: state.appwide.all_portfolios,
        scopedWallets: state.appwide.flat_wallets
    };
  };
  
  const mapDispatchToProps = dispatch => {
    return {
        addTransaction: (data) =>
            dispatch(actions.addTransaction(data)),
        updateTransaction: (data) =>
            dispatch(actions.updateTransaction(data)), 
        setTrTime: (data) => 
            dispatch(actions.setTrTime(data)), 
        setInteraction: (data) => 
            dispatch(actions.setInteraction(data)), 
    };
  };
  
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(SelectSource);
