import React from 'react';
import Highcharts from 'highcharts/highstock'
import Exporter from 'highcharts/modules/exporting'
import HighchartsReact from 'highcharts-react-official'
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import * as actions from "../../../../../store/actions/auth";
import loadingSpinner from '../../../../../Media/loadingSpinner.gif'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
/* import HighchartsReact from 'highcharts-react-official'*/
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {nFormatter} from '../../../../../helpers/helpers'
Exporter(Highcharts)

/* (function(H) {
    H.Pointer.prototype.reset = function() {
      return undefined;
    };
  
    H.Point.prototype.highlight = function(event) {
      event = this.series.chart.pointer.normalize(event);
      this.onMouseOver(); // Show the hover marker
      //this.series.chart.tooltip.refresh(this); // Show the tooltip
      this.series.chart.xAxis[0].drawCrosshair(event, this); // Show the crosshair
    };
  
    H.syncExtremes = function(e) {
      var thisChart = this.chart;
  
      if (e.trigger !== "syncExtremes") {
        // Prevent feedback loop
        Highcharts.each(Highcharts.charts, function(chart) {
          if (chart && chart !== thisChart) {
            if (chart.xAxis[0].setExtremes) {
              // It is null while updating
              chart.xAxis[0].setExtremes(e.min, e.max, undefined, false, {
                trigger: "syncExtremes"
              });
            }
          }
        });
      }
    };
  })(Highcharts); */

const styles = {
    root: {
      color: '#6A7E93',
      '&$checked': {
        color: '#0078DB',
      },
    },
    checked: {
        color: '#0078DB'
    },
  };


class BalanceGraph extends React.PureComponent {

    constructor(props) {

        super(props);
        this.state = {
            dateSelected: null,
            chart: null,
        }

    }
    

    render () {

    const component = this
    const { classes } = this.props;

    const options = {
        chart: {
            type: 'spline', 
            backgroundColor: 'white',  
            animation: true,
           
        },
        credits: false,
        title: false,
        xAxis: {
            className: 'basier-p4-caps',    
            minorGridLineWidth: 0,
            gridLineWidth: 0,
            type: 'datetime',
            minPadding:0,
            maxPadding:0,
            tickLength: 0,
            tickColor: 'red',
            labels: {
                padding: 30,
                style: {
                    fontSize: '12px',
                },
            },  

        },
        yAxis: [{
            title: {
              text: 'Bitcoin Price (USD)'
              
            },
            className: 'basier-p4 graph-labels-x',
            lineWidth: 0,
          }],
        
        plotOptions:{
            series:{
                allowPointSelect: true, 
                point:{
                animation: false, 
                marker: {
                    enabledThreshold: 3
                },
            }
        }
    },

        series: [

            {
                name: 'BTC 30-day volatility',
                data: this.props.data['30_d_vol'],
                pointStart: Date.UTC(this.props.data.start_year_30, this.props.data.start_month_30 -1 , this.props.data.start_day_30),
                pointIntervalUnit: 'day',
                showInLegend: true, 
                lineWidth: 3,
                marker: {
                    lineWidth: 1,
                    }
            },

            {
                name: 'BTC 60-day volatility',
                data: this.props.data['60_d_vol'],
                pointStart: Date.UTC(this.props.data.start_year_60, this.props.data.start_month_60 -1 , this.props.data.start_day_60),
                pointIntervalUnit: 'day',
                showInLegend: true, 
                lineWidth: 3,
                visible: false,
                marker: {
                    lineWidth: 1,
                    }
            },

            {
                name: 'BTC 90-day volatility',
                data: this.props.data['90_d_vol'],
                pointStart: Date.UTC(this.props.data.start_year_90, this.props.data.start_month_90 -1 , this.props.data.start_day_90),
                pointIntervalUnit: 'day',
                showInLegend: true, 
                lineWidth: 3,
                visible: false,
                marker: {
                    lineWidth: 1,
                    }
            },



        ],
        legend: {
            enabled: true,
        },
        navigator: {
            enabled: false
        },
        scrollbar: {
            enabled: false
        },
        rangeSelector: {
            enabled: true, 
            buttonPosition: {
                align: 'left', 
            }, 
            inputEnabled: false, 
            buttonSpacing: 12, 
            buttonTheme: { // styles for the buttons
                fill: 'none',
                stroke: 'none',
                'stroke-width': 0,
                r: 8,
                style: {
                    color: '#6A7E93',
                    fontSize: 16, 
                },
                states: {
                    hover: {
                        fill: 'none',
                        style: {
                            color: '#0078DB'
                        },
                    },
                    select: {
                        fill: 'none',
                        style: {
                            color: '#0078DB',
                            fontWeight: 'bold',
                        }
                    }, 
                    // disabled: { ... }
                }
            },
            labelStyle: {
                color: 'white',
                display: 'none',
            },
            scrollbar: {
                enabled: false,
            },
        },
      
        exporting: {
            buttons: {
                contextButton: {
                    menuItems: ["viewFullscreen"]
                }
            }
        },
        tooltip: {
            //followPointer: false,
            floating: false,
            padding:12,
            useHTML: true,
            shared: true,
            split: false,
            shadow: false,
            borderWidth: 0,
            backgroundColor: 'rgba(255,255,255,0.8)',
            formatter: function () {
                let bitcoin = null;
                
                const data = this.points
                bitcoin = data.slice(this.points.length - 1, )[0]
                
                return (


                    `

                    <div>
                        <div class="tooltip-date-corr basier">${Highcharts.dateFormat('%e %b %Y',new Date(this.x))}</div>
                        <div class="corr-divider divider-padding"></div>
                        ${data.slice(0, data.length).map((value, index) => {
                            return `<div key=${index} class="basier" style="text-transform: uppercase; display: flex; font-size: 14px; padding-bottom: 14px; align-items: center;"> <span style="font-weight: 900; color: black">${value.series.name}</span> &nbsp; ${(value.y * 100).toLocaleString('en', {minimumFractionDigits: 2, maximumFractionDigits: 2})}%</div>`
                        }).join('')}
                    </div>

                    `
                   
                )
    
    
                },
    
            },

           

        
        
    }    

        return (

            <div>
                    <Grid container 
                        spacing={0}>

                        <div id="correl_container" style={{width: '100%', marginLeft: 0, position: 'relative'}}>
                        { ( this.props.loadedGraph === true) ?  <div className="loader-graph"><img style={{height: 64, with: 64}} src={loadingSpinner} alt="Loading..."/></div> : null }
                            

                                <HighchartsReact
                                    highcharts={Highcharts}
                                    constructorType={'stockChart'}
                                    containerProps={{ style: { height: "600px", width: '100%' } }}
                                    options={options}
                                    oneToOne={true}
                                    style={{height: 600}}
                                />


                        </div>

                    </Grid>

                </div>
            
            
        )
    }


        
};

BalanceGraph.propTypes = {
    classes: PropTypes.object.isRequired,
  };
  

const mapStateToProps = state => {
    return {
        currency_symbol: state.auth.currency.symbol,
        currency_name: state.auth.currency.id,
        currentView: state.data.currentView,
        inBitcoin: state.data.bitcoinPf

    };
};

const mapDispatchToProps = dispatch => {
    return {
        setBtcSymbol: (s) =>
            dispatch(actions.setBtcSymbol(s)), 
        
    };
  };

export default connect(
    mapStateToProps,
    mapDispatchToProps
)( withStyles(styles)(BalanceGraph));


