import React from 'react';
import seeMore from '../../../../../Media/seeMore.svg'
import warning from '../../../../../Media/warning.svg'
import currencyDict from '../../../.././../helpers/currency.json'
import Tooltip from '../../../../AppWide/Tooltip';
import MenuPopper from '../../../../AppWide/MenuPopper';

const assetDetail = (props) => 

{

    return (
    
    <div container style={{ 
        height: 70,
        paddingLeft: 12,
        paddingTop: 8, 
        paddingBottom: 8, 
        paddingRight: 12, 
        width: '100%',
        display: 'flex', 
        borderBottom: props.border ? '1px solid #CCC' : 'none',
        alignItems: 'center',
        }} 
        index={props.index}
        className="basier-p3 light-hover">
                
                
                <div item style={{width: '19%', display: 'flex', alignItems: 'center'}} >
                
                {props.item.base_img !== "" ? 
                <span><img style={{height: 25, width: 25, marginRight: 12}} src={props.item.base_img} alt=""/></span>
                :
                <span style={{height: 25, width: 25, marginRight: 12, display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 20 }}>
                </span>
                }
                
                <span>
                    <div style={{marginTop:2, marginBottom: 2}}>{
                    props.unsupported ? props.item.import_name :
                    `${`${props.item.base_ticker}/${props.item.quote_ticker}`}`}</div>
                    {/* <div className="basier-p4-caps white-hover" style={{color: '#6A7E93'}}>{props.item.contract_name}</div> */}
                    <div className="basier-p4-caps white-hover" style={{color: '#6A7E93'}}>{props.unsupported ? null : props.item.exchange_name}</div>
                </span>
                
                </div> 

                <div style={{width: '12%', textAlign: 'left' }} >
                
                    <div style={{
                        width: 'auto'}}>
                        <span style={{textTransform: 'uppercase', color: props.item.position_side === 'long' ? '#4CDAC1' : '#F9716B', fontWeight: 900}} className="basier-p3">{props.item.position_side}</span>
                    </div>

                
                </div> 


                <div  className="truncate" item style={{width: '19%', textAlign: 'right'}} >
                    <div className="truncate" style={{width: 'auto', paddingTop: 4, marginBottom: 2, display: 'flex', justifyContent: 'flex-end', alignItems: 'center', position: 'relative'}}>
                        <div>
                            <div className="truncate basier-p3" style={{color: 'rgba(0, 0, 0, 0.65)', fontWeight: 400}}>
                                {props.currency_symbol}&nbsp;
                                {props.item.amount.toLocaleString('en', {maximumFractionDigits: 6, minimumFractionDigits: 6})}
                                </div>
                            <div className="truncate basier-p4-caps" style={{color: 'rgba(0, 0, 0, 0.65)', fontWeight: 400}}>
                                {props.quote_currency}&nbsp;
                                { 
                                    props.item.quote_type === 'fiat' || props.item.quote_ticker === 'USDT' ? 
                                    (parseFloat(props.item.amount) * parseFloat(props.item.open_price)).toLocaleString('en', {maximumFractionDigits: 2, minimumFractionDigits: 2})
                                
                                    :

                                    (parseFloat(props.item.amount) * parseFloat(props.item.open_price)).toLocaleString('en', {maximumFractionDigits: 6, minimumFractionDigits: 6})
                                }
                            </div>
                        </div>
                        

                    </div>
                </div> 

                <div  className="truncate" item style={{width: '21%', textAlign: 'right'}} >
                    <div className="truncate" style={{width: 'auto', paddingTop: 4, marginBottom: 2, display: 'flex', justifyContent: 'flex-end', alignItems: 'center', position: 'relative'}}>
                        {
                        props.unsupported ? 
                        <div style={{textStyle: 'italic'}} className="basier-p4"><div>Sorry we do not track this coin yet. </div><div>
                        We are adding support soon</div>
                        </div>
                        :
                        props.item.is_open ? 
                        <div>
                            
                            <div className="truncate basier-p3" style={{color: 'rgba(0, 0, 0, 0.65)', fontWeight: 400}}>
                                {props.quote_currency}&nbsp;
                                {props.item.quote_type === 'fiat'|| props.item.quote_ticker === 'USDT' ? 
                                    props.item.current_close.toLocaleString('en', {maximumFractionDigits: 2, minimumFractionDigits: 2})
                                :

                                    props.item.current_close.toLocaleString('en', {maximumFractionDigits: 6, minimumFractionDigits: 6})
                                
                                }
                                </div>
                            <div className="truncate basier-p4-caps" style={{color: 'rgba(0, 0, 0, 0.65)', fontWeight: 400}}>
                                {props.quote_currency}&nbsp;
                                {props.item.quote_type === 'fiat' || props.item.quote_ticker === 'USDT' ? 
                                    props.item.open_price.toLocaleString('en', {maximumFractionDigits: 2, minimumFractionDigits: 2})
                                :

                                    props.item.open_price.toLocaleString('en', {maximumFractionDigits: 6, minimumFractionDigits: 6})
                                
                                }
                            </div>
                        </div>
                        :
                        null}
                        

                    </div>
                </div> 

                <div item style={{width: '21%', textAlign: 'right' }} >
                    
                    {props.item.is_open ? 
                    <div style={{paddingTop: 4, 
                        marginBottom: 2,}}>
                        <div className="truncate basier-p3" style={{color: props.pnl < 0 ? '#F9716B' : '#4CDAC1', fontWeight: 900}}>
                            {props.quote_currency}&nbsp;
                            {props.item.quote_type === 'fiat' || props.item.quote_ticker === 'USDT' ? 
                                
                                (props.pnl).toLocaleString('en', {minimumFractionDigits: 2, maximumFractionDigits: 2})
                            
                            :

                                (props.pnl).toLocaleString('en', {minimumFractionDigits: 6, maximumFractionDigits: 6})
                            
                            }&nbsp;
                        </div>
                        <div className="truncate basier-p4-caps" style={{color: props.pnl < 0 ? '#F9716B' : '#4CDAC1', fontWeight: 400}}>
                            {props.currency_symbol}&nbsp;
                            {(props.inversePnL).toLocaleString('en', {minimumFractionDigits: 6, maximumFractionDigits: 6})}&nbsp;
                        </div>
                        
                    </div>
                    :


                    <div style={{paddingTop: 4, 
                        marginBottom: 2,}}>
                        <div className="truncate basier-p3" style={{color: props.pnl < 0 ? '#F9716B' : '#4CDAC1', fontWeight: 900}}>
                            {props.quote_currency}&nbsp;
                            {props.item.quote_type === 'fiat' || props.item.quote_ticker === 'USDT' ? 
                                
                                parseFloat(props.item.realized_profit).toLocaleString('en', {minimumFractionDigits: 2, maximumFractionDigits: 2})
                            
                            :

                                parseFloat(props.item.realized_profit).toLocaleString('en', {minimumFractionDigits: 6, maximumFractionDigits: 6})
                            
                            }&nbsp;
                        </div>
                    </div>


                    }
                    
                </div>

                <div item style={{width: '8%', display: 'flex', justifyContent: 'flex-end' }} >
                
                    <div className="basier-p4-caps" style={{
                        borderRadius: 35, 
                        height: 27, 
                        textAlign: 'right'
                        }}>
                        
                        <MenuPopper menuItems={props.menuItems} payload={props.item}/>
                        
                        </div>

                </div> 
            </div>

    )

}

export default assetDetail;


