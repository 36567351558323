import React from 'react';
import { connect } from 'react-redux';
import BackIcon from "../../../../../Media/BackIcon.svg";
import Plus from '../../../../../Media/Plus.svg';
import CollabEdit from '../../../../MobileOnly/CollabDrawer';
import * as interActions from "../../../../../store/actions/interactions";
import * as actions from "../../../../../store/actions/data";
import SmallCollabList from './SmallCollabList';
import axios from 'axios';
import { backendUrl } from '../../../../../variables';

class SmallGeneral extends React.PureComponent {

    state = {
        editColab: false,
        data: null, 
        drawerData: null,
    }

    componentDidMount() {
        const data = this.props.portfoliosData[this.props.portfolioId]
        if (data !== undefined) {
            this.setState(state => ({...state, data: data}))
        }
        document.body.ontouchmove = (e) => { 
            return true;
        }
    }
    
    componentWillUnmount() {
        document.body.ontouchmove = (e) => { 
            return true;
        }
        this.props.setInteraction({label: 'pin', value: true});
    }

    getData = () => {
        this.props.componentDataFetch('get', 291, this.props.currency, this.props.user_id, this.props.portfolioId, {}, {}, this.props.inBitcoin)
        this.props.componentDataFetch('get', 6, this.props.currency, this.props.user_id, this.props.portfolioId, {}, {}, this.props.inBitcoin)
    }

    componentDidUpdate(prevProps) {
        if (this.props.portfoliosData !== prevProps.portfoliosData) {
            document.body.ontouchmove = (e) => { 
                return true;
            }

            const data = this.props.portfoliosData[this.props.portfolioId]
            if (data !== undefined) {
                this.setState(state => ({...state, data: data}))    
            }
        }

    }

    addCollab = () => {
        this.props.toggleModal({type: 'add_collab', open: true, payload: {data: {
            portfolio_limit: this.state.data['user_limit'] || '10',
            users: this.state.data['users'],
            org: this.state.data['organization'],
            portfolioId:this.props.portfolioId,
        },
        getData: this.getData,
    }})
    }

    deleteUser = (id) => {

        const sendData = {
            id: id, 
            scope: 'simple_delete',
            user_id: this.props.user_id,
        }

        let headers = { "content-type": "application/json" };
        if (this.props.token !== null && this.props.token !== undefined) {

            headers['Authorization'] = `Token ${this.props.token}`

        }

        const url_call = `${backendUrl}/v1/portfolio/users/`
        
        axios.post(url_call, sendData, {headers: headers})
            .then(json => {
                
                let newUsers = this.state.data['users'].filter(v => v.id !== id)
                
                const newData = {
                    ...this.state.data, 
                    users: newUsers,
                }

                
                this.setState(state => ({...state, data: newData}))
                this.getData()
                this.props.showSnack({message: 'User deleted successfully', variant: "success"})
                this.closeDrawer()
            })
            .catch(() => {
                this.props.showSnack({message: 'Error in deleting user from portfolio', variant: "error"})
            })



}

    editName = () => {
        this.setState(state => ({...state, nameEdit: true})) 
    }

    openDrawer = (val) => {
        this.setState(state => ({...state, drawerData: val, editColab: true}));
    }

    closeDrawer = () => {
        this.setState(state => ({...state, drawerData: null, editColab: false}));  
    }

    editHandler = () => {
        this.setState(state => ({...state, edit: true}))     
    }

    goBackAction = () => {
        this.props.goBack()
    }
    
    updateState = (newData) => {

        this.setState(state => ({...state, data: newData}))
        this.getData()

    }

    confirmDelete = (val) => {

        if (val !== undefined && val.transaction_number > 0) {
        this.props.toggleModal({
            type: 'delete_collab', 
            open: true, 
            payload: {
                handleDelete: this.deleteUser,  
                updateState: this.updateState,
                data: {
                    id: val.id, 
                    users: this.state.data['users'], 
                    userName: `${val.first_name} ${val.last_name}`,
                    numberTx: val.transaction_number,
                    oldUser: val.user_id,
                }
            }
        })
        } else {
            if (window.confirm(`Are you sure you want to remove ${val.first_name} ${val.last_name} from the portfolio. ${val.first_name} ${val.last_name} had no transactions so it will be a simple removal.`)) {
                this.deleteUser(val.id)
            }
        }
    }

    render () {
        

        return (
            this.state.data === null ? 
            null :

                <React.Fragment>
                    
                            
                    <div style={{position: 'absolute', top: 0, zIndex: 1300, width: '100%'}}>

                        <div style={{backgroundColor: '#F8F9FA', zIndex: 1300, height: 75, width: '100%', display:'flex', alignItems: 'center', position: 'fixed', justifyContent: 'space-between'}} id="responsive-dialog-title">

                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <div onClick={() => this.goBackAction()}>
                                        <img style={{paddingTop: 24}} src={BackIcon} alt=""/>
                                    </div>
                                </div>

                                <div className="basier-p2" style={{color: 'black', fontWeight: 900, textTransform: 'uppercase'}}>Users</div>

                                <div>
                                    <div className="basier"  style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end', fontSize: '1.2rem'}}>
                                        
                                        <React.Fragment>
                                            <div className="modalActionButton touchableOpacity"  onClick={() => this.addCollab()} style={{marginRight: 24, color: '#0078DB'}}>Add</div>
                                        </React.Fragment>
                                    

                                    </div> 
                                </div>

                        </div>

                        <div style={{position: 'fixed', top: 75, height: 200, width: '100%', zIndex: 1200, backgroundColor: 'white'}} />

                            <div style={{position: 'absolute', top: 75, zIndex: 1200, backgroundColor: 'white', minHeight: 100, width: '100%', overflowScrolling: "touch", WebkitOverflowScrolling: "touch", }}>

                                <div style={{
                                    overflowScrolling: "touch",
                                    WebkitOverflowScrolling: "touch",
                                    }}>

                                    <div style={{padding: 12}} className="basier-p4-caps">
                                        Existing users
                                    </div>
                                        
                                        
                                        <SmallCollabList data={this.state.data} openDrawer={(v) => this.openDrawer(v)} user_id={this.props.user_id} />
                                    
                                
                                </div>
                            </div>

                    </div>
                    {this.state.editColab &&
                    <CollabEdit updateState={(d) => this.updateState(d)} token={this.props.token} user_id={this.props.user_id} data={this.state.drawerData} allData={this.state.data} openModal={this.state.editColab} closeModal={this.closeDrawer} confirmDelete={(val) => this.confirmDelete(val)} handleSelect={(res) => this.handleUser(res)}/>
                    }
                </React.Fragment>
            
        )
    }


}

const mapStateToProps = state => {
    return {
        user_id: state.auth.user_id, 
        token: state.auth.token,
        currency: state.auth.currency.id,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        showSnack: (data) => dispatch(interActions.showSnack(data)), 
        setInteraction: (data) => 
            dispatch(interActions.setInteraction(data)), 
        toggleModal: (data) => dispatch(interActions.toggleModal(data)), 
        componentDataFetch: (scope, view, currency, user, portfolio, globalGraphData, portfolioGraphData, inBitcoin) =>
            dispatch(actions.componentDataFetch(scope, view, currency, user, portfolio, globalGraphData, portfolioGraphData, inBitcoin)), 
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(SmallGeneral)



