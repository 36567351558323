import React from 'react'; 
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import ProfitTable from './ProfitList';
import SmallAssetTable from '../Overview/Small/SmallAssetTable';
import Hidden from '@material-ui/core/Hidden';
import AssetModal from '../../PortfolioComponents/AssetModal';
import Plus from '../../../../Media/Plus.svg'
import * as actions from '../../../../store/actions/interactions'
import SmallProfitList from './Small/SmallProfitTable'
import profitExtractworker from "../../../../workers/profitExtractworker";
import WebWorker from "../../../../workers/WebWorker";
import Tooltip from '../../../AppWide/Tooltip'
import questionMark from '../../../../Media/questionMark.svg'

const toggleModal = (props) => {
    props.toggleModal({type: 'add_transaction', open: true, payload: {} })
}

class AssetList extends React.PureComponent {

    state = {
        openData: undefined,
        closedData: undefined,
    }

    componentDidMount() {

        if (this.props.portfoliosData[this.props.portfolioId] !== undefined) {
            this.worker = new WebWorker(profitExtractworker);
            
            this.worker.addEventListener('message', event => {
                const data = event.data

                this.setState(state => ({...state,
                                        openData: data[0], 
                                        closedData: data[1], 
                                        }))

            });

            this.worker.postMessage([this.props.portfoliosData[this.props.portfolioId]['balances'], this.props.portfoliosData[this.props.portfolioId]['positions'], this.props.portfoliosData[this.props.portfolioId]['total_value']])

            }
        }

    componentDidUpdate(prevProps) {

        if (this.props.portfoliosData[this.props.portfolioId] !== undefined && this.props.portfoliosData[this.props.portfolioId]['total_value'] !== prevProps.portfoliosData[this.props.portfolioId]['total_value']) {
        this.worker = new WebWorker(profitExtractworker);

        this.worker.addEventListener('message', event => {
            const data = event.data

            this.setState(state => ({...state,
                                    openData: data[0], 
                                    closedData: data[1], 
                                    }))

        });

        this.worker.postMessage([this.props.portfoliosData[this.props.portfolioId]['balances'], this.props.portfoliosData[this.props.portfolioId]['positions'], this.props.portfoliosData[this.props.portfolioId]['total_value']])

        }
    }

    render() {

    return (

        <div>
        <Hidden smDown>

            <div>

                <div className='title-asset-sticky' style={{width: '100%'}}>
                    <div  style={{paddingLeft: 0, paddingTop: 25, display: 'flex', alignItems: 'center'}}>
                        <h3 style={{marginBottom: 6}}>Open Investments</h3>
                        {this.props.roles[this.props.portfolioId] === 'admin' || this.props.roles[this.props.portfolioId] === 'manager' ? 
                        <span onClick={() => toggleModal(this.props)} className="basier-p4-caps" style={{userSelect: 'none', color: 'black', marginLeft: 24, cursor: 'pointer'}}>
                            <span><img src={Plus} alt="" style={{marginRight: 12}}/></span>
                            <span>Add a Transaction</span>
                        </span> 
                        :
                        null }
                    </div>
                    
                    <div style={{display: 'flex', alignItems: 'flex-end', backgroundColor: 'white', 'boxShadow': 'none', height: 30, paddingBottom: 12, marginTop: 30, marginBottom: 0, borderBottom: '1px solid #E1E5E9'}}>
                
                        <div
                            style={{paddingLeft: 24, paddingRight: 24, color: '#6A7E93', display: 'flex', alignItems: 'baseline', width: '100%'}}
                            className="basier-p4-caps"
                        > 
                
                        <div style={{width: '22%' }}>

                            <div className="basier-p3" style={{textTransform: 'uppercase'}}>
                                Asset
                            </div>

                            <div className="basier-p4-caps">
                                
                            </div>

                        </div> 

                        <div style={{width: '15%', textAlign: 'right'}}>
                            <div className="basier-p3" style={{textTransform: 'uppercase'}}>
                                <span style={{marginRight: 6}}>
                                    <Tooltip title="Amount of new money that has been invested in this asset. Includes re-invested profits from other assets, but does not include re-invested profits from the same asset." placement="right">
                                        <img style={{height: 20, width: 20}} src={questionMark} alt=""/>
                                    </Tooltip>
                                </span>

                                <span>Investment</span>
                                
                            </div>

                            <div className="basier-p4-caps">
                                Weight
                            </div>    
                        </div> 

                        <div style={{width: '15%', textAlign: 'right'}}>
                            <div className="basier-p3" style={{textTransform: 'uppercase'}}>
                                Open QTY
                            </div>

                            <div className="basier-p4-caps">
                                Cost basis
                            </div>    
                        </div> 

                        <div style={{width: '15%', textAlign: 'right' }}>

                            <div className="basier-p3" style={{textTransform: 'uppercase'}}>
                                Open P&L
                            </div>

                            <div className="basier-p4-caps">
                                % Investment
                            </div>    

                        </div>

                        <div style={{width: '15%', textAlign: 'right'}}>

                            <div className="basier-p3" style={{textTransform: 'uppercase'}}>
                                Realized P&L
                            </div>

                            <div className="basier-p4-caps">
                                % Investment
                            </div>    

                        </div> 

                        <div style={{width: '15%', textAlign: 'right'}}>

                            <div className="basier-p3" style={{textTransform: 'uppercase'}}>
                                Total P&L
                            </div>

                            <div className="basier-p4-caps">
                                % Investment
                            </div> 

                        </div> 

                        <div style={{width: 50, textAlign: 'right'}} />

                        </div>
                    </div>

                </div>

                <div style={{width: '100%'}}>
                    <div style={{marginLeft: 12, marginRight: 12, marginTop: 0}}>
                        
                        {this.state.openData !== undefined ?
                        <ProfitTable data={this.state.openData} investment={this.props.portfoliosData[this.props.portfolioId].deposit_check} />
                        : 
                        null 
                        }
                    
                    </div>
                </div>

                <div className='title-asset-sticky' style={{width: '100%'}}>
                    <div  style={{paddingLeft: 0, paddingTop: 25, display: 'flex', alignItems: 'center'}}>
                        <h3 style={{marginBottom: 6}}>Closed & Near-closed Investments</h3>
                    </div>
                    
                    <div style={{display: 'flex', alignItems: 'flex-end', backgroundColor: 'white', 'boxShadow': 'none', height: 30, paddingBottom: 12, marginTop: 30, marginBottom: 0, borderBottom: '1px solid #E1E5E9'}}>
                
                        <div
                            style={{paddingLeft: 24, paddingRight: 24, color: '#6A7E93', display: 'flex', alignItems: 'baseline', width: '100%'}}
                            className="basier-p4-caps"
                        > 
                
                    <div style={{width: '22%' }}>

                        <div className="basier-p3" style={{textTransform: 'uppercase'}}>
                            Asset
                        </div>

                        <div className="basier-p4-caps">
                            
                        </div>

                        </div> 

                        <div style={{width: '15%', textAlign: 'right'}}>
                        <div className="basier-p3" style={{textTransform: 'uppercase'}}>
                            <span style={{marginRight: 6}}>
                                <Tooltip title="Amount of new money that has been invested in this asset. Includes re-invested profits from other assets, but does not include re-invested profits from the same asset." placement="right">
                                    <img style={{height: 20, width: 20}} src={questionMark} alt=""/>
                                </Tooltip>
                            </span>

                            <span>Investment</span>
                            
                        </div>

                        <div className="basier-p4-caps">
                            Weight
                        </div>    
                        </div> 

                        <div style={{width: '15%', textAlign: 'right'}}>
                        <div className="basier-p3" style={{textTransform: 'uppercase'}}>
                            Open QTY
                        </div>

                        <div className="basier-p4-caps">
                            Cost basis
                        </div>    
                        </div> 

                        <div style={{width: '15%', textAlign: 'right' }}>

                        <div className="basier-p3" style={{textTransform: 'uppercase'}}>
                            Open P&L
                        </div>

                        <div className="basier-p4-caps">
                            % Investment
                        </div>    

                        </div>

                        <div style={{width: '15%', textAlign: 'right'}}>

                        <div className="basier-p3" style={{textTransform: 'uppercase'}}>
                            Realized P&L
                        </div>

                        <div className="basier-p4-caps">
                            % Investment
                        </div>    

                        </div> 

                        <div style={{width: '15%', textAlign: 'right'}}>

                        <div className="basier-p3" style={{textTransform: 'uppercase'}}>
                            Total P&L
                        </div>

                        <div className="basier-p4-caps">
                            % Investment
                        </div> 

                        </div> 

                        <div style={{width: 50, textAlign: 'right'}} />


                            </div>
                        </div>

                    </div>

                    <div style={{width: '100%'}}>
                        <div style={{marginLeft: 12, marginRight: 12, marginTop: 12}}>
                            
                            {this.state.closedData !== undefined ?
                            <ProfitTable data={this.state.closedData} />
                            : 
                            null 
                            }
                        
                        </div>
                    </div>



                </div>
            
            </Hidden>

            <Hidden mdUp>

            <div
            style={{
                boxShadow: '0px 10px 10px rgba(225, 229, 233, 0.3)',
                borderRadius: 14, 
                position: 'relative',
                width: '100%',
                paddingBottom: 24,
            }}>

              <div className='title-asset-sticky-mobile' style={{width: '100%'}}>
                <div  style={{paddingLeft: 12, paddingTop: 25}}>
                    <h3>Profit details</h3>
                </div>
                
                <div style={{display: 'flex', alignItems: 'flex-end', backgroundColor: 'white', 'boxShadow': 'none', height: 30, paddingBottom: 12, marginTop: 30, borderBottom: '1px solid #E1E5E9'}}>
               
                <Grid
                    container
                    direction="row"
                    alignItems="baseline"
                    style={{color: '#6A7E93'}}
                    className="basier-p4-caps"
                > 
        
                <Grid item style={{width: '30%', paddingLeft: 12}}>Asset</Grid> 
                <Grid item style={{width: '65%', textAlign: 'right', paddingRight: 12}}>Profit</Grid> 
                <Grid item style={{width: '5%', textAlign: 'centre', paddingRight: 12}}></Grid> 


              </Grid>
                </div>

            </div>

            <Grid item style={{width: '100%'}}>
                <div style={{marginLeft: 0, marginRight: 0, marginTop: 0}}>

                {this.props.portfoliosData[this.props.portfolioId] !== undefined ?

                    <SmallProfitList items={this.props.portfoliosData[this.props.portfolioId]} />
                    : 
                    null 

                }

                </div>
            </Grid>

            </div>

            </Hidden>
            
            </div>

            
        
        

    )
    }

}

const mapStateToProps = state => {
    return {
        currency_symbol: state.auth.currency.symbol,
        portfoliosData: state.data.portfoliosData,
        roles: state.auth.roles,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        toggleModal: (data) => 
            dispatch(actions.toggleModal(data)), 
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AssetList);

