import React from 'react';
import { connect } from 'react-redux';

class NewAccount extends React.PureComponent {

    state = {
        name: null
    }

    
    handleChange = (e) => {
        e.persist()
        this.props.handleNewAccount(e.target.value)
    }

    componentDidMount() {
        let newName = this.props.name 
        try { newName = `${this.props.name} - ${this.props.user_first.slice(0,1)}${this.props.user_last.slice(0,1)}` } catch(e) {}
        if (this.props.type === 'exchange') {
            try {newName = `${this.props.name.replace(/[()]/g, "")} ${this.props.user_first.slice(0,1)}${this.props.user_last.slice(0,1)}`} catch(e) {}
        } else {
            try {newName = `${this.props.name.replace(/[()]/g, "")} Wallet - ${this.props.user_first.slice(0,1)}${this.props.user_last.slice(0,1)}`} catch(e) {}
        }
        this.props.handleNewAccount(newName)
    }

    componentDidUpdate(prevProps) {

        if (prevProps.name !== this.props.name && this.props.name !== undefined && this.props.name !== null && this.props.name !== '') {
            let newName = this.props.name 
            try { newName = `${this.props.name} - ${this.props.user_first.slice(0,1)}${this.props.user_last.slice(0,1)}` } catch(e) {}
            if (this.props.type === 'exchange') {
                try {newName = `${this.props.name.replace(/[()]/g, "")} ${this.props.user_first.slice(0,1)}${this.props.user_last.slice(0,1)}`} catch(e) {}
            } else {
                try {newName = `${this.props.name.replace(/[()]/g, "")} Wallet - ${this.props.user_first.slice(0,1)}${this.props.user_last.slice(0,1)}`} catch(e) {}
            }
            this.props.handleNewAccount(newName)
        }
    }

    componentWillUnmount() {
        this.props.handleNewAccount(undefined)
    }

    render () {

        return (

            <input 
                type="text" 
                onChange={(e) => this.handleChange(e)} 
                onBlur={this.onBlur}
                ref={(ip)=> this.myInput = ip}  
                value={this.props.accountName || ''}
                className={`light-input basier-p3`} 
                onKeyDown={this.onKeyDown}
                style={{width: this.props.width !== undefined ? this.props.width : 250}}
                //onKeyDown={(e) => this.onKeyDownInput(e, this.props.label, this.priceTypeRef, this.props.transaction['data'][this.props.transactionId]['transaction_description'])} value={this.props.transaction['data'][this.props.transactionId][this.props.label] || ""} 
            />
            
        )
    }


}

const mapStateToProps = state => {
    return {
        user_first: state.auth.user_first_name, 
        user_last: state.auth.user_last_name, 
    };
};

const mapDispatchToProps = dispatch => {
    return {
    };
};


export default connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true})(NewAccount)
