
export default () => {
	self.addEventListener('message', e => { // eslint-disable-line no-restricted-globals
		if (!e) return;

		//console.log(e)
        let micro_coins = []
        let stable_coins = []
        let fiat_coins = []
		let low_coins = []
		let mid_coins = []
        let high_coins = []
        let fiat_total = 0
		let micro_total = 0
		let stable_total = 0
		let low_total = 0
		let mid_total = 0
		let high_total = 0
		let bitcoin_total = 0
        let fees = 0
		const data = e.data

		data.map((balance, index) => {
            
            fees = fees + (balance.fees * -1 * balance.current_price)
            switch (true) {

                case (balance.coin === 'bitcoin'): {
                    bitcoin_total = balance.total_value; 
                    break;
                }
                case ( (balance.coin === 'usd-coin') || (balance.coin === 'tether') ): {
                    stable_coins.push(balance);
                    stable_total = stable_total + balance.total_value;
                    break;
                }
                case (balance.asset_type === 'fiat'): {
                    fiat_coins.push(balance);
                    fiat_total = fiat_total + balance.total_value;
                    break; 
                }
                case ( balance.market_cap_usd >= 500000000 ): {
                    high_coins.push(balance);
                    high_total = high_total + balance.total_value;
                    break;
                }
                case ( balance.market_cap_usd < 500000000 && balance.market_cap_usd >= 50000000):  {
                    mid_coins.push(balance);
                    mid_total = mid_total + balance.total_value;
                    break;                    
                }
                case ( balance.market_cap_usd < 50000000 && balance.market_cap_usd >= 10000000):  {
                    low_coins.push(balance);
                    low_total = low_total + balance.total_value;
                    break;
                }
                case ( balance.market_cap_usd < 10000000):  {
                    micro_coins.push(balance);
                    micro_total = micro_total + balance.total_value;
                    break;
                }

                default: {
                    return [];
                }

            }

        })
        

        const labels_graph_test = ['Bitcoin', 'High Cap', 'Mid Cap', 'Low Cap', 'Micro Cap', "Stable Coin", "Fiat"]
        const labels_values_test = [bitcoin_total, high_total, mid_total, low_total, micro_total, stable_total, fiat_total]

        let graphData = [];
        //let graph_labels = [];

        labels_values_test.map((val, index) => {
            if (val > 0) {
                graphData.push([labels_graph_test[index], val])
            } else {
                graphData.push([labels_graph_test[index], 0])
            }
        })
        

        const return_value =  {
                            graphData: graphData, 
                            bitcoin_total: bitcoin_total, 
                            micro_coins: micro_coins,
                            low_coins: low_coins,
                            mid_coins: mid_coins,
                            high_coins: high_coins,
                            fiat_coins: fiat_coins,
                            fees: fees
                            }
		

		postMessage(return_value);

	})
}
