import React from 'react';

const ExportLine = React.memo((props) => (<EntryLine {...props} />) )

const EntryLine = (props) => (

    <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', height: props.height !== undefined ? props.height : 95, border: '0px solid black', width: '100%', marginTop: 4}}>
        {props.children}
    </div>

)

export default ExportLine