import React from 'react';
import { connect } from 'react-redux';
import currencyDict from '../../../../../helpers/currency.json'

class ExchangeBalance extends React.PureComponent {

    
    renderApproxZero = (bal) => {
        return (

        <div style={{display: 'flex', alignItems: 'center'}}>
            <div style={{marginRight: 6}}>&lt; 1$</div>
        </div>

        )
    }

    render () {

        return (

            this.props.balances !== undefined && this.props.balances[this.props.user_id] !== undefined ?

            <div>

                { Object.keys(this.props.balances[this.props.user_id]['storage']).length > 0 ? 
                    
                  Object.keys(this.props.balances[this.props.user_id]['storage']).map((val, key) => {

                    return (
                        <div key={key} style={{width: '100%', marginBottom: 12,}} >
                            <div 
                                onClick={() => {console.log('item')}}
                                style={{
                                    paddingRight: 24, paddingLeft: 12,
                                    paddingTop: 12, paddingBottom: 12, backgroundColor: 'rgba(243, 245, 246, 0.5)', 
                                    display: 'flex', alignItems: 'center'
                                }}>

                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    {this.props.balances[this.props.user_id]['storage'][val]['details']['image'] !== undefined && 
                                    this.props.balances[this.props.user_id]['storage'][val]['details']['image'] !== null ? 
                                    <div style={{marginRight: 12}}><img src={this.props.balances[this.props.user_id]['storage'][val]['details']['image']} style={{height: 24, width: 24}} alt="storage"/></div>
                                    :
                                    null 
                                    }
                                    <div style={{color: 'black', fontWeight: 900}}>
                                        {this.props.balances[this.props.user_id]['storage'][val]['details']['name']}
                                    </div> 

                                </div>
                            </div>

                            <div>

                                {
                                this.props.balances[this.props.user_id]['storage'][val]['wallets'].map((val, key) => {

                                    return (
                                        <div key={key}>
                                            
                                            <div className="exchange-flat-list-item" style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                                <div   style={{display: 'flex', flexDirection: 'column', minHeight: 60, paddingTop: 12, paddingBottom: 12, justifyContent: 'center', alignItems: 'flex-start', paddingLeft: 12,}}>
                                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                                        <div className="basier-p3" style={{color: 'black', fontWeight: 900, marginRight: 12, textTransform: 'uppercase'}}>
                                                            {val.wallet_name}
                                                        </div>
                                                    </div>
                                                    <div className="basier-p3" style={{marginTop: 6}}>
                                                        {
                                                                val.value < 1 && val.value > -1 ? 
                                                                this.renderApproxZero(val.balance.toLocaleString('en', {maximumFractionDigits: 8, minimumFractionDigits: 8}))
                                                            :
                                                                val.balance >= 1 ?
                                                                val.balance.toLocaleString('en', {maximumFractionDigits: 2, minimumFractionDigits: 2})
                                                            :   
                                                                val.balance <= -1 ?
                                                                val.balance.toLocaleString('en', {maximumFractionDigits: 2, minimumFractionDigits: 2})
                                                            :
                                                                val.balance.toLocaleString('en', {maximumFractionDigits: 8, minimumFractionDigits: 8})
                                                        }
                                                    </div>
                                                    <div className="basier-p4" style={{marginTop: 6, fontStyle: 'italic', paddingLeft: 0}}>
                                                        {val.wallet_address}
                                                    </div>
                                                </div>

                                                <div style={{marginRight: 12}}>
                                                    <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M0.999999 0.856573L7 6.85413L1 12.8541" stroke="#000000" stroke-miterlimit="10"/>
                                                    </svg>
                                                </div>

                                            </div>
                                            

                                        </div>
                                    )

                                })
                            }
                                
                            </div>

                        </div>
                    )
                })
            
                :
                
                <div className="basier-p3" style={{marginTop: 24, fontStyle: 'italic', paddingBottom: 24, width: '100%', justifyContent: 'center', textAlign: 'center'}}>
                    No storage wallets with positive balances.
                </div>
                
                }

                
            </div>

            :
            null
        )
    }


}

const mapStateToProps = state => {
    return {
        balances: state.data.wallet_balance
    };
};

const mapDispatchToProps = dispatch => {
    return {
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(ExchangeBalance)
