
export default () => {
	self.addEventListener('message', e => { // eslint-disable-line no-restricted-globals
		
		if (!e) return;

    const balances = e.data
    let balanceDict = {}
    let total_value = 0;
    const coinData = balances.coin_data
    let multiplier = parseFloat(balances.multiplier)
    if (isNaN(multiplier)) {
      multiplier = 1
    }


    let connectedExchangeBalances = []; 
    try {
      Object.keys(balances.connected.e).map(function(e) {
        connectedExchangeBalances = connectedExchangeBalances.concat(balances.connected.e[e])
      })
    } catch(e) {
      connectedExchangeBalances = []
    }
    let connectedStroageBalances = []; 
    try {
      Object.keys(balances.connected.s).map(function(e) {
        connectedStroageBalances = connectedStroageBalances.concat(balances.connected.s[e])
      })
    } catch(e) {
      connectedStroageBalances = []
    }

    let totalConnected = connectedExchangeBalances.concat(connectedStroageBalances) //connectedExchangeBalances.concat(connectedStroageBalances)
    let remainingBalance = balances.remaining

    let allBalances = totalConnected.concat(remainingBalance) //.concat(remainingBalance)

    for (const key in allBalances) {
      
      const line = allBalances[key]
      if (line === undefined || line['coin'] == null) {
        continue
      }
      const amount = parseFloat(line['amount'])
      let price, change;
      try {
        price = parseFloat(coinData[line['coin']]['price']) * multiplier
        if (isNaN(price)) {
          price = 0
        }
      } catch(e) {}
      try {
        change = parseFloat(coinData[line['coin']]['change'])
        if (isNaN(change)) {
          change = 0
        }
      } catch(e) {}
      const value = (amount * price)
      let coinDataDict;
      try {
        coinDataDict = coinData[line['coin']];
        if (coinDataDict === undefined) {
          coinDataDict = {};
        }
      } catch(e) {
        coinDataDict = {};
      }

      const selector = line['coin'] !== null ? line['coin'] : line['asset']
      if (selector) {
        if (balanceDict[selector] === undefined) {
          balanceDict[selector] = {
            coin: line['coin'],
            asset: line['asset'],
            name: coinDataDict['name'], 
            symbol: coinDataDict['symbol'], 
            image: coinDataDict['image'], 
            asset_type: coinDataDict['asset_type'], 
            amount: 0, 
            price: price, 
            change: change,
            value: 0
          }
        }
        balanceDict[selector]['amount'] += amount
        balanceDict[selector]['value'] += value
        total_value += value
      }
    }

    let balanceReturn =  Object.keys(balanceDict).map(function(e) {
      return balanceDict[e]
    })
    balanceReturn = balanceReturn.sort(function compare(a, b) {
			return b.value - a.value;
		})

    //console.log('THIS', balanceDict)
		postMessage([balanceReturn, total_value]);

	})
}
