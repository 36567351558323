import React from 'react';
import { connect } from 'react-redux';
import SFBody from './SFBody'
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

class SFWrapper extends React.PureComponent {

    state = {
        open: false,
        selected: {}, 
        selectCandidates: {},
    }

    componentDidMount() {
        if (this.props.filters !== 'all' && this.props.values !== null && this.props.values !== undefined) {
            let newSelect = {}
            this.props.filters.map(v => {
                if (this.props.values.includes(v.toLowerCase())) {
                    newSelect[v] = true
                }
            })

            this.setState(state => ({...state, selected: newSelect}))
        }
        
    }

    componentDidUpdate(prevProps) {
        if (this.props.filters !== 'all' && this.props.values !== null && this.props.values !== undefined && this.props.values !== prevProps.values) {
            let newSelect = {}
            this.props.filters.map(v => {

                if (this.props.values.filter(x => (x[0] !== null ? x[0].toString().toLowerCase() : null) === (v !== null ? v.toString().toLowerCase() : null)).length > 0) {
                    newSelect[v] = true
                }
            })

            this.setState(state => ({...state, selected: newSelect}))
        }
        
    }

    clearAll = () => {
        this.setState(state => ({...state, selected: {}, selectCandidates: {}}))
        this.props.filterData('all')
        this.props.resetPage()
        //RELOAD TABLE
    }  

    submitFilter = () => {

        if (Object.keys({...this.state.selected, ...this.state.selectCandidates}).length > 0) {
            this.toClose(true)
            //RELOAD TABLE
            this.props.filterData(Object.keys({...this.state.selected, ...this.state.selectCandidates}))
            this.props.resetPage()
        } else {
            this.toClose()
        }
    }

    selectAll = () => {
        let newChecked = {...this.state.selected}
        this.props.values.map(v => {
            newChecked[v[0]] = true
        })

        this.props.filterData(Object.keys(newChecked))
        this.props.resetPage()
        this.setState(state => ({...state, selected: newChecked}))
    }
    

    selectRow = (id) => {

        let verifyChecked = {...this.state.selectCandidates, ...this.state.selected}
        let newChecked = {...this.state.selected}
        if (verifyChecked[id] === true) {

            try {
                delete newChecked[id]
            } catch(e) {}

        } else {

            newChecked[id] = true

       }
       if (Object.keys(newChecked).length > 0) {
        this.props.filterData(Object.keys(newChecked))
        this.props.resetPage()
       } else {
        this.props.filterData('all')
        this.props.resetPage()
       }
       this.setState(state => ({...state, selected: newChecked}))
    }

    toggleMenu = () => {
        this.setState(state => ({...state, open: !state.open}))
    }
    

    toClose = (commit) => {
        this.setState(state => ({...state, open: false }))  
    }

    handleClose = () => {
    
        setTimeout(() => { this.toClose() }, 10)
        
      }

    render () {

        const defaultStyle={
            height: 32, 
            width: 120,
            cursor: 'pointer',
            display: 'flex', 
            alignItems: 'flex-end',
            paddingBottom: 4, 
            paddingLeft: 3,
            justifyContent: 'space-between',
            backgroundColor: this.state.open ? this.props.background !== undefined ? this.props.background : '#E1E5E9' : this.props.openBackground !== undefined ? this.props.openBackground : 'transparent',
            borderBottom: '1px solid rgba(0,0,0, 0.4)',
        }

        return (
            <React.Fragment>
                <div className="border-bottom-hover padding-hover basier-p3" onClick={this.toggleMenu} style={{...defaultStyle, ...this.props.style}}>
                        <div className="noselect truncate">
                            {Object.keys(this.state.selected).length > 0 && Object.keys(this.state.selected).length !== this.props.values.length ?
                                <span style={{color: '#0078DB'}}>{`${Object.keys({...this.state.selected, ...this.state.selectCandidates}).length} selected`}</span>
                                :
                                <span>All selected</span>
                            }
                            
                        </div>
                        <svg style={{fill: 'currentColor', display: 'inline-block', transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',  userSelect: 'none', color: 'rgba(0, 0, 0, 0.54)', width: '1em', height: '1em', fontSize: 24}} focusable="false" viewBox="0 0 24 24" aria-hidden="true" role="presentation"><path d="M7 10l5 5 5-5z"></path></svg>
                </div>

                {this.state.open &&
                 <ClickAwayListener onClickAway={() => this.handleClose()}>
                 <SFBody values={this.props.values} selectAll={this.selectAll} selected={{...this.state.selected, ...this.state.selectCandidates}} clearAll={this.clearAll} selectRow={(id) => this.selectRow(id)} submitFilter={this.submitFilter} close={this.toClose} rightOpen={this.props.rightOpen} />
                 </ClickAwayListener>
                 }


            </React.Fragment>
            
        )
    }


}

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = dispatch => {
    return {
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(SFWrapper)
