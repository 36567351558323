import React from 'react';
import { connect } from 'react-redux';
import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import { backendUrl } from '../../variables'
import 'filepond/dist/filepond.min.css';
import loadingSpinner from '../../Media/loadingSpinner.gif'
import PendingUpload from '../Modals/ModalViews/ModalComps/PendingUpload'
import ErrorUpload from '../Modals/ModalViews/ModalComps/ErrorUpload'
import okIcon from '../../Media/okIcon.svg'
import gearIcon from '../../Media/gears.svg'
import axios from 'axios'; 
import Tooltip from '../AppWide/Tooltip'
import questionMark from '../../Media/questionMark.svg'
import * as appwideActions from "../../store/actions/appwide";
import * as dataActions from "../../store/actions/data";
import * as interActions from "../../store/actions/interactions";
import SingleSelect from '../AppWide/SingleSelect';
import fieldValidator from '../../helpers/validators';

//registerPlugin(FilePondPluginFileValidateType);

class UploadView extends React.PureComponent {

    state = {
        // Set initial files, type 'local' means this is a file
        // that has already been uploaded to the server (see docs)
        files: [], 
        progress: {},
        scope: 'instructions',
        portfolio_id: null,
        pending: false, 
        pendingData: null,
        error: false, 
        task: false,
        errorData: {
            data: {}
        }, 
        success: false,
        uploadId: null,
        uploading: false,
    }

    updateState = (newData) => {

        this.setState(state => ({...state, ...newData}))


    }

    checkUploadError = () => {

      if (this.state.portfolio_id === null || this.state.portfolio_id === undefined) {
        this.props.setImportError('Please select a portfolio to import to')
        return false
      }

      let name_val = true;
      let lowercaseName = this.props.accountName
      try { lowercaseName = this.props.accountName.toLowerCase().trim() } catch(e) {}
      name_val = fieldValidator(this.props.accountName, ['isNotEmpty'])
      name_val = name_val && !(this.props.existingNames.includes(lowercaseName))

      if (!name_val) {
        this.props.setImportError('Account names must be unique. Please select a different account name.')
        return false
      } else {
        return true
      }

    }

    selectPort = (val) => {
      if (val !== null && val !== undefined) {
        this.setState(state => ({...state, portfolio_id: val.value}))
      } else {
        this.setState(state => ({...state, portfolio_id: null}))
      }
    }

    downloadTemplate = (scope) => {

        let headers = { "content-type": "application/json" };
        if (this.props.token !== null && this.props.token !== undefined) {

            headers['Authorization'] = `Token ${this.props.token}`

        }

        const url_call = `${backendUrl}/v1/transaction_template/?u=${this.props.user_id}&scope=${scope}`

        axios.get(url_call, {headers: headers})
            .then(json => {
               
            })
            .catch( e => {

            })

    }

    componentDidMount() {
        
        if (this.props.importTarget !== null) {
            this.setState(state => ({...state, portfolio_id: this.props.importTarget}))
          } else if (this.props.scopePortfolios.length > 0) {
            this.setState(state => ({...state, portfolio_id: this.props.scopePortfolios[0].value}))
          }

    }
    handleInit(file) {
    }

    addTask = (data) => {
        const currentTasks = this.props.tasks
        const newTasks = [data, ...currentTasks]

        this.props.setInteraction({label: "tasks", value: newTasks})
    }

    render () {

        let token = this.props.token;
        let auth_header = `Token ${token}`

        let importName;
        try {
          importName = this.props.scopePortfolios.filter(v => v.value == this.props.importTarget)[0].label
        } catch(e) {}

        return (
            <div>

              {this.state.scope === 'instructions' ?
                
                <div style={{width: '100%', height: '100%'}}>
                    
                    {this.props.importTarget === null ? 
                    <div>
                    <div style={{display: 'flex', marginBottom: 12, alignItems: 'center'}}>
                        <div style={{width: 24, height: 24, display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: 100, backgroundColor: '#0078DB', color: 'white', fontWeight: 900, marginRight: 12}} className="basier-p2">
                            1
                        </div>
                        <div className="basier-p3" style={{textTransform: 'uppercase', color: 'black', fontWeight: 900}}>
                            Select Import Target
                        </div>
                    </div>

                     <div style={{marginBottom: 12}}>
                        <div>
                          <div className="basier-p4-caps" style={{fontWeight: 900, color: 'black', marginBottom: 4 }}>
                            Import To Portfolio: 
                          </div>
                          <SingleSelect selected={this.state.portfolio_id} data={this.props.scopePortfolios} handleSelect={this.selectPort} />
                        </div>
                    </div>
                    </div>
                    :
                    <div style={{marginLeft: 0, marginBottom: 24, }} className="basier-p3">Transaction will be imported to: <span style={{textTransform: 'uppercase', fontWeight: 'bold', color: 'black'}}>{importName}</span></div>
                    }

                    <div style={{display: 'flex', marginBottom: 12, alignItems: 'center'}}>
                        <div style={{width: 24, height: 24, display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: 100, backgroundColor: '#0078DB', color: 'white', fontWeight: 900, marginRight: 12}} className="basier-p2">
                            {this.props.importTarget === null ? '2' : '1'}
                        </div>
                        <div className="basier-p3" style={{textTransform: 'uppercase', color: 'black', fontWeight: 900}}>
                            Fill in Wisly's CSV Import format
                        </div>
                    </div>

                    <div style={{marginBottom: 12}}>
                        <div>
                            <span>
                                Download the Wisly template <a href={`${backendUrl}/v1/transaction_template/?u=${this.props.user_id}&scope=simple`}><span className="opacity-hover" style={{color: '#0078DB', cursor: 'pointer'}}>here</span></a><span> (example lines included)</span>
                            </span>
                            
                        </div>  
                        <div style={{marginBottom: 12}}>
                          Use your account's transaction history to fill in the template. Make sure you use the correct coin tickers based on our <a href={`https://wisly-resources.s3-eu-west-1.amazonaws.com/public/coin_export_wisly.csv`}><span className="opacity-hover" style={{marginLeft: 0, color: '#0078DB', cursor: 'pointer'}}>Coin Reference File</span></a>
                        
                        </div>
                    </div>
                     

                    <div style={{display: 'flex', marginBottom: 12, alignItems: 'center'}}>
                        <div style={{width: 24, height: 24, display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: 100, backgroundColor: '#0078DB', color: 'white', fontWeight: 900, marginRight: 12}} className="basier-p2">
                            {this.props.importTarget === null ? '3' : '2'}
                        </div>
                        <div className="basier-p3" style={{textTransform: 'uppercase', color: 'black', fontWeight: 900}}>
                            Upload file
                        </div>
                    </div>

                </div>

                :

                this.state.uploading ?
                <div style={{width: '100%', paddingLeft: 6, height: '100%'}}>
                    <div style={{backgroundColor: 'rgba(243, 245, 246, 0.5)', borderRadius: 20, width: '100%', height: 300, paddingTop: 18, paddingBottom:18, paddingLeft: 12, overflow: 'scroll'}}>

                        { 

                            this.state.scope === 'upload' ?
                                
                                <div>
                                    {!this.state.pending && !this.state.error && !this.state.success && !this.state.task ? 
                                    <div style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                                        <div><img src={loadingSpinner} style={{height: 40, width: 40}} alt=""/></div>
                                        <div>Upload in progress</div>
                                    </div>
                                    :
                                    null
                                    }
                                    <div>

                                    {this.state.success ? 

                                        <div style={{width: '100%', marginTop: 72, display: 'flex', justifyContent: 'center', textAlign: 'center', alignItems: 'center', flexDirection: 'column'}}>
                                            <img src={okIcon} alt="" />
                                            <div style={{marginTop: 12}} className="basier-p3">Upload successful</div>
                                        </div>
                                    :

                                    null
                                    }

                                    {this.state.task ? 

                                        <div style={{width: '100%', marginTop: 72, display: 'flex', justifyContent: 'center', textAlign: 'center', alignItems: 'center', flexDirection: 'column'}}>
                                            <img src={gearIcon} alt="" />
                                            <div style={{marginTop: 12}} className="basier-p3">You upload file contained more than 5,000 lines. We have queued your upload request and will process it in the background. You can continue using Wisly, and we will notify you when your upload is complete.</div>
                                        </div>
                                    :

                                    null
                                    }

                                    {this.state.pending ? 

                                        <PendingUpload tasks={this.props.tasks} addTask={this.addTask} data={this.state.pendingData['data']} uploadId={this.state.uploadId} updateState={this.updateState} />
                                    :

                                    null
                                    }
                                    {this.state.error ? 

                                        <ErrorUpload data={this.state.errorData['data']} uploadId={this.state.uploadId} />
                                    :

                                    null
                                    }
                                    </div>  
                                </div> 
                            
                            :

                            null

                        }
                    </div>
                </div>

                :

                null

              }

              <div style={{width: '100%', height: 150, marginTop: 24}}>

                        <FilePond ref={ref => this.pond = ref}
                        files={this.state.files}
                        allowMultiple={false}
                        maxFiles={1}
                        //allowFileTypeValidation={true}
                        //acceptedFileTypes={["text/csv", "text/plain", "text/*"]}
                        server={
                            {
                                url: `${backendUrl}/v1`,
                                process: {
                                    url: `/account_upload/`, 
                                    method: 'POST',
                                    headers: {
                                        "Authorization": auth_header,
                                        "x-portfolio": this.state.portfolio_id,
                                        "x-user": this.props.user_id,
                                        "x-sid": this.props.sourceId,
                                        "x-st": this.props.sourceType,
                                        "x-an": this.props.accountName,
                                        "x-custa": false,
                                    },
                                    onload: res => {

                                        res = JSON.parse(res)

                                        if (res['status'] === 'pending') {
                                            this.setState(state => ({...state, pending: true, error: false, success: false, task: false, pendingData: res, uploadId: res['upload_id']}))
                                        } else if (res['status'] === 'task') {
                                            
                                            const data = res['data'][0]
                                            
                                            const language = navigator.language || 'en'
                                            const options = {year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit' };
                                            const parseDate = new Date();
                                            const showDate = parseDate.toLocaleDateString(language,options)

                                            const newTaskData = {description: `CSV Import`, sub: `Created ${showDate}`, status: 'pending', type: 'import', task_id: data['task_id']}
                                            this.addTask(newTaskData)
                                            this.setState(state => ({...state, pending: false, success: false, error: false, task: true}))
                                        
                                        }  else if (res['status'] === 'OK') {
                                            const request_data = {
                                                period: 'max',
                                                start: null,
                                                end: null,
                                              }

                                            this.props.componentDataFetch('update', this.props.currentView, this.props.currency_id, null, this.props.currentPortfolio, null, request_data, this.props.inBitcoin);
                                            this.props.getWallets(this.props.user_id);
                                            this.props.getUserLimits(this.props.user_id)
                                            this.setState(state => ({...state, pending: false, success: true, error: false}))
                                            
                                        } 

                                    },
                                    onerror: res => {

                                        res = JSON.parse(res)

                                        if (res['status'] === 'error') {
                                            this.setState(state => ({...state, error: true, pending: false, success: false, errorData: res, uploadId: res['upload_id']}))
                                        } 
                                    },
                                
                                
                            }
                        }}
                        oninitfile={(file) => {
                        }}
                        onaddfile={(error, file) => {
                        }}
                        onprocessfilestart={(file) => {
                          const success = this.checkUploadError()
                          if (success === false) {
                            file.abortProcessing()
                          }
                          else {
                            this.setState(state => ({...state, scope: 'upload', error: false, success: false, pending: false, uploading: true}))
                          }
                        }}
                        oninit={(file) => this.handleInit()}
                        onactivatefile={(file) => {
                        }}
                        onupdatefiles={files => {
                          // console.log('update')
                          // const success = this.checkUploadError()
                          // if (success === false) {
                          //   if (files.length == 1) {
                          //     files[0].abortProcessing()
                          //     files[0].abortLoad()
                          //     console.log('4')
                          //     return
                          //   }
                          // } else {
                          //   this.setState(state => ({...state, scope: 'upload', error: false, success: false, pending: false, uploading: true}))
                          // }
                        }}
                        >
                    </FilePond>
                    
                    </div>
              

            </div>
        )
    }


}

const mapStateToProps = state => {
    return {
        token: state.auth.token,
        user_id: state.auth.user_id,
        currentPortfolio: state.data.menu_selected, 
        inBitcoin: state.data.bitcoinPf, 
        currentView: state.data.currentView, 
        currency_id: state.auth.currency.id, 
        tasks: state.interactions.tasks, 
        existingNames: state.appwide.accountNames
    };
};

const mapDispatchToProps = dispatch => {
    return {
        componentDataFetch: (scope, view, currency, user, portfolio, globalGraphData, portfolioGraphData, inBitcoin) =>
        dispatch(dataActions.componentDataFetch(scope, view, currency, user, portfolio, globalGraphData, portfolioGraphData, inBitcoin)),
        getUserLimits: (user) => dispatch(appwideActions.getUserLimits(user)), 
        getWallets: (user) => dispatch(appwideActions.getWallets(user)), 
        setInteraction: (data) => 
            dispatch(interActions.setInteraction(data)),

    };
};


export default connect(mapStateToProps, mapDispatchToProps)(UploadView)
