import React from "react";
import restore from "../../../Media/restore.svg";

function DeletedLine(props) {
  //const [success, setSuccess] = React.useState(null)
  //const [loading, setLoading] = React.useState(false)

  const restoreAction = () => {
    props.restoreLine();
  }

  return (
    <div style={{ flex: 1, }}>
      <div className="basier-p4-caps" style={{ color: "#F9716B" }}>
        Deleted
      </div>
      <div className="opacity-hover" onClick={restoreAction} style={{display: "flex", alignItems: 'center', marginTop: 6, cursor: "pointer" }}>
        <img
          src={restore}
          alt=""
          style={{ height: 20}}
        />
        <div style={{marginLeft: 6, color: '#4CDAC1'}} className="basier-p4-caps">Restore</div>
      </div>
    </div>
  );
}

export default DeletedLine;
