import React from "react";
import { FixedSizeList as List } from "react-window";
import CloseIcon from '../../Media/CloseIcon.svg'
import Grid from '@material-ui/core/Grid';
import AddIcon from '../../Media/seeMore.svg'
import CancelIcon from '../../Media/errorIcon.svg'

const noAddErc20 = ['bitcoin', 'bitcoin-cash', 'neo', 'ripple', 'ethereum', 'monero']
let options = [];

class CoinFlatList extends React.PureComponent {

    

    constructor(props) {

      super(props)
      this.inputRef = React.createRef();

      this.state = {
        initialItems: [], 
        items: [],
        addNew: false,
        newWalletName: null, 
        newWalletAddress: null,
      }

    }

    handleNewWallet = (e, type) => {
        e.persist();
        this.setState(state => ({...state, [type]: e.target.value}))
    }

    escapeRegexCharacters = (str) => {
        return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
      }

      getListSize = () => {
          return this.state.items.length
      }
      
      getSuggestions = (event) => {
        const value = event.target.value

        const escapedValue = this.escapeRegexCharacters(value.trim());

        if (escapedValue === '') {
           
          this.setState(state => ({...state, items: this.state.initialItems}))
          return [];
        }
      
        const regex = new RegExp('\\b' + escapedValue, 'i');
        
        const filteredList = this.state.initialItems.filter(coin => regex.test(this.getSuggestionValue(coin)));
        this.setState(state => ({...state, items: filteredList})) 

      }

      getSuggestionValue  = (suggestion) => {
        return `${suggestion.name}`;
      }

      selectCoin = (val) => {
        this.props.handleSelect(val)
      }

      getRef = async (ref) => {
        return ref;
    }
    
      componentDidMount() {
    
        if ( this.props.wallets[this.props.user['value']]['storage'][this.props.base_currency['id']] !== undefined &&  this.props.wallets[this.props.user['value']]['storage'][this.props.base_currency['id']] !== null) {

                options = this.props.wallets[this.props.user['value']]['storage'][this.props.base_currency['id']]

            }
        else {
            options = [];
        }
    
        if ( this.props.base_currency['id'] !== 'ethereum' && !noAddErc20.includes(this.props.base_currency['id']) && this.props.wallets[this.props.user['value']]['storage']['ethereum'] !== undefined ) {
            
            this.props.wallets[this.props.user['value']]['storage']['ethereum'].map( (wallet) => {
                
                options.push(wallet)
          
              })
            
    
        }


        this.setState(state => ({...state, initialItems: options, items: options}))

      }

    componentWillUnmount() {
        document.body.ontouchmove = (e) => { 
            return true;
        }
      }

    componentWillUpdate() {
        document.body.ontouchmove = (e) => { 
            return true;
        }
    }

    openNew = () => {
        this.setState(state => ({...state, addNew: true}))
    }

    closeNew = () => {
        this.setState(state => ({...state, addNew: false}))
    }

      clearInput = () => {
          document.getElementById('coin-search').value = '';
          this.setState(state => ( {...state, items: this.state.initialItems}))
      }

      addNewWallet = () => {

        this.props.newWallet(this.state.newWalletName, this.state.newWalletAddress)
        this.props.closeAction()

      }

      Row = ({ data, index, style }) => {
        // Data passed to List as "itemData" is available as props.data
        const item = data[index]
        return (
            <div style={{...style, 
                        paddingLeft: 24, 
                        paddingRight: 24, 
                        }} value={item.id}
                        className="coin-flat-list-item basier-p2"
                        onClick={() => this.selectCoin(item)}>
            <span>
                <span style={{textTransform: 'capitalize'}}>
                    {item.name}
                </span>
                <span style={{marginLeft: 6}}>
                    { item.wallet_coin === 'ethereum' && this.props.base_currency['id'] !== 'ethereum' ? '(ETH / ERC20)' : null }
                </span>
            </span>
            <span>></span>
            </div>
        );
    };

      render() {
        
        const heightVitrual = window.screen.height - 75; 
        const widthVirtual = window.innerWidth;
        const itemHeight = 65

        return (
            <div className="filter-list" style={{height: '100%', width: '100%', position: 'fixed', top: 0}}>

            <div style={{backgroundColor: '#F8F9FA', boxShadow: 'none', height: 75, position: 'fixed', width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
        
                <div style={{width: '33%'}} >
                  <img style={{marginLeft: '12px', paddingTop: '12px'}} src={CloseIcon} alt="" onClick={this.props.closeAction}/> 
                </div>
                
                <div style={{marginRight: 24, width: '33%',}} >
                      {!this.state.addNew ? 

                            <div className="touchableOpacity basier"  onClick={this.openNew} style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end', fontSize: '1.2rem'}}>

                                 <div className="modalActionButton" style={{marginRight: 12, color: '#0078DB'}}>New</div>
                               
                            </div> 

                        : 

                          null
                      }

                </div>

            </div>

              {this.state.items !== undefined ?
                <div style={{marginTop: 75}}>

                    {this.state.addNew ? 
                    
                    <div style={{width: '100%', padding: 24, display: 'flex', flexDirection: 'column'}}>
                        <h3 className="baser-p2" style={{marginBottom: 12}}>
                            New {this.props.base_currency['symbol']} wallet
                        </h3>
                        <div style={{display: 'flex', flexDirection: 'column', width: '100%', marginBottom: 12}}>
                            <div className="basier-p4-caps" style={{width: '100%', marginBottom: 6}}>
                                Name
                            </div>
                            <input value={this.state.newWalletName} onChange={(e) => this.handleNewWallet(e, 'newWalletName')} style={{width: '100%', textAlign: 'left',  height: 50, border: '1px solid #E1E5E9', paddingLeft: 12}} type="text" className="no-outline" />
                            
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
                            <div className="basier-p4-caps" style={{width: '100%', marginBottom: 6}}>
                                Address
                            </div>
                            <input value={this.state.newWalletAddress} placeholder="Optional" onChange={(e) => this.handleNewWallet(e, 'newWalletAddress')} style={{width: '100%', textAlign: 'left',  height: 50, border: '1px solid #E1E5E9', paddingLeft: 12}} type="text" className="no-outline" />
                            
                        </div>

                        <div style={{marginTop: 12, display: 'flex', width: '100%', alignItems: 'space-around'}}>

                            <div className='basier' onClick={this.closeNew} 
                            style={{height: 40, borderRadius: 35, marginLeft: 12, marginRight: 12, cursor: 'pointer', color: 'white', backgroundColor: '#F9716B', width: 150, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            Cancel
                            </div>

                            <div className='button-next-blue' onClick={() => this.addNewWallet()} 
                                style={{height: 40, borderRadius: 35, marginLeft: 12, marginRight: 12, cursor: 'pointer'}}>
                                Submit
                            </div>

                        </div>

                    </div>

                    
                
                    :
                    
                    <List
                    height={heightVitrual}
                    itemCount={this.state.items.length}
                    itemData={this.state.items}
                    itemSize={itemHeight}
                    width={widthVirtual}
                    >
                    {this.Row}
                    </List>
                    
                    }
                
                </div>

              :

              <div>No wallets yet. Add a new one</div> 

              }
              
          </div>
        )
      }

}

export default CoinFlatList