import React from 'react';
import ShortTransactionEntry from '../../Portfolio/PortfolioComponents/Transactions/NewTransaction/ShortForm/ShortTransactionEntry';

class NewTransactionEntryOverview extends React.PureComponent {

  render() {
    return (
      <div style={{width: '100%', paddingLeft: 48, paddingRight: 48}}>
        <ShortTransactionEntry />
      </div>
    )
  }

}

export default NewTransactionEntryOverview