import React from 'react';
import { connect } from 'react-redux';
import DateTimePicker from 'react-datetime';
import 'react-datetime/css/react-datetime.css'
import FiatLarge from '../../../../AppWide/FiatLarge';
import {loadData} from '../../../../../helpers/helpers'
import { backendUrl } from '../../../../../variables'
import warningIcon from '../../../../../Media/warning.svg'
import currencyDict from '../../../../../helpers/currency.json'
import Tooltip from '../../../../AppWide/Tooltip';
import Export from '../../../../../Media/Export.svg'
import Oops from '../../../../../Media/oops.svg'
import { saveAs } from 'file-saver';
import selectBackground from '../../../../../Media/selectBackground.svg';
import loadingSpinner from '../../../../../Media/contentLoader.svg';
import * as interActions from "../../../../../store/actions/interactions";
import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from '@material-ui/core/styles';
import CorrelMatrix from './Visualizations/CorrelMatrix'

const ColorLinearProgress = withStyles({
    root: {
        height: 3
    },
    colorPrimary: {
      backgroundColor: 'rgba(0, 120, 219, 0.2)',
    },
    barColorPrimary: {
      backgroundColor: '#0078DB',
    },
  })(LinearProgress);

var moment = require('moment');
require('moment/locale/en-gb');

class BalanceDate extends React.PureComponent {

    state = {

        currency: 'USD', 
        serverDate: null,
        validation: {
            date: true,
            currency: true,
        }, 
        futurDate: false,
        currenciesData: null, 
        analysisData: undefined,
        loading: false,
        downloading: false,

    }


    componentDidMount() {

    }
    
    componentDidUpdate(prevProps) {
       

    }
    
    
   

    generateReport = (csv) => {

        
        const dataSend = { 
            portfolio: this.props.portfolioId
        }

        let headers = { "content-type": "application/json" };
        headers["Authorization"] = `Token ${this.props.token}`;

        if (csv === '1') {
            this.setState(state => ({...state, downloading: true}))
            headers["content-type"] = 'text/csv'
            fetch(`${backendUrl}/v1/analyses/?p=${dataSend.portfolio}&u=${this.props.user_id}&scope=correlation&csv=${csv}/`, {headers: headers})
            .then(response => response.blob())
            .then(blob => {
                if (blob.type === 'text/html') {
                    this.setState(state => ({...state, downloading: false}))
                    this.props.showSnack({message: 'Error in downloading file', variant: "error"})
                } else {
                saveAs(blob, `export_${this.state.serverDate}_${this.props.portfolioId}.csv`)
                this.setState(state => ({...state, downloading: false}))
                }
                })
            .catch(() => {
                this.setState(state => ({...state, downloading: false}))
                this.props.showSnack({message: 'Error in downloading file', variant: "error"})
            })


        } else {
            this.setState(state => ({...state, loading: true}))
            const data_fetch = loadData(`${backendUrl}/v1/analyses/?p=${dataSend.portfolio}&u=${this.props.user_id}&scope=correlation`, headers)
            data_fetch
                .then(json => {

                this.setState(state => ({...state, analysisData: json['data'], loading: false}))
                    
                })
                .catch(e => {

                    const errorDate = {
                        balances: null
                    }

                    this.setState(state => ({...state, analysisData: errorDate, loading: false}))

                })
        }
        

    }

    render () {

        return (

            <div style={{width: '100%', height: 'inherit', display: 'flex', alignItems: 'center'}}>

                <div style={{height: '90%', width: 200, borderRight: '1px solid #E1E5E9', paddingTop: 12, paddingBottom: 12, paddingLeft: 24, paddingRight: 24, display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>

                    <div>
                    <h4>Parameters</h4>

                    </div>

                    <div>
                        <div className='button-next-blue' onClick={() => this.generateReport()} 
                            style={{height: 40, borderRadius: 35, marginTop: 0, cursor: 'pointer'}}>
                            Generate Report
                        </div>
                    </div>


                </div>

                <div style={{height: '95%', padding: 24, width: '100%', display: 'flex', flexDirection: 'column'}}>
                    
                    <h4>Output</h4>
                    
                    {   
                        this.state.loading ? 
                        <div style={{width: '100%', height: 'inherit', flexDirection: 'column', display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center'}}>
                            <img style={{height: 120, with: 120}} src={loadingSpinner} alt="Loading..."/>
                            <div className="basier-p2" style={{marginTop: 6}}>Loading data</div>
                        </div>
                        :
                        this.state.analysisData == undefined ? 

                        <div style={{width: '100%', display: 'flex', justifyContent: 'center', textAlign: 'center', flexDirection: 'column'}}>
                            <h3>Please select parameters and generate analysis</h3>
                            <img src={selectBackground} alt="" style={{height: 400}} />
                        </div>

                        : 

                        this.state.analysisData == null ? 

                        <div style={{width: '100%', display: 'flex', justifyContent: 'center', textAlign: 'center', flexDirection: 'column'}}>
                            <img style={{marginTop: 24, height: 150}} src={Oops} alt="" />
                            <h3>Unavailable data</h3>
                            <div className="basier-p2">
                                This data is currently unavailable. This might be a temporary issue as we update our data. Please try again in a few minutes.
                            </div>
                        </div>

                        :
                    
                            <div style={{width: '100%', display: 'flex', flexDirection: 'column', flex: 2}}>

                               <CorrelMatrix data={this.state.analysisData} />

                            </div>


                        }
                    
                </div>

            </div>
        )
    }


}

const mapStateToProps = state => {
    return {
        initialFiat: state.data.fiatList,
        user_id: state.auth.user_id, 
        token: state.auth.token,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        showSnack: (data) => dispatch(interActions.showSnack(data)), 
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(BalanceDate)
