import React from 'react';
import { connect } from 'react-redux';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { CSVLink, CSVDownload } from "react-csv";
import { backendUrl } from '../../../../variables';
import * as interActions from "../../../../store/actions/interactions";

const timeoutLength = 100
/* const headers = [
    { label: 'User', key: 'user' },
    { label: 'Date', key: 'date' },
    { label: 'Type', key: 'type' },
    { label: 'Base', key: 'base_coin' },
    { label: 'Amount', key: 'amount' },
    { label: 'Price', key: 'unit_price' },
    { label: 'Quote', key: 'quote_coin' },
    { label: 'Fee', key: 'fee' },
    { label: 'Fee_Currency', key: 'fee_curr' },
    { label: 'Comments', key: 'comments' },
    { label: 'Account', key: 'account' },
    { label: 'Destination_User', key: 'dest_user' },
    { label: 'Destination_Account', key: 'dest_account' },
  ]; */

class SelectAction extends React.PureComponent {

    state = {
        open: false, 
        onMenu: false, 
        exportData: 'no data', 
    }


    saveStreamCSV(filename, text) {
        this.setState({downloadingCSV: false})
        if(window.navigator.msSaveBlob) {
            // IE 10 and later, and Edge.
            var blobObject = new Blob([text], {type: 'text/csv'});
            window.navigator.msSaveBlob(blobObject, filename);
        } else {
            // Everthing else (except old IE).
            // Create a dummy anchor (with a download attribute) to click.
            var anchor = document.createElement('a');
            anchor.download = filename;
            if(window.URL.createObjectURL) {
                // Everything else new.
                var blobObject = new Blob([text], {type: 'text/csv'});
                anchor.href = window.URL.createObjectURL(blobObject);
            } else {
                // Fallback for older browsers (limited to 2MB on post-2010 Chrome).
                // Load up the data into the URI for "download."
                anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(text);
            }
            // Now, click it.
            if (document.createEvent) {
                var event = document.createEvent('MouseEvents');
                event.initEvent('click', true, true);
                anchor.dispatchEvent(event);
            }
            else {
                anchor.click();
            }
        }
    }
    updateState = (data, done) => {
         this.setState({
           exportData: data
         },() => { done() })
       }


    handleHoverOn = () => {
        this.setState(state => ({ open: true })); 
      };
      
      emptyMenu = () => {
            this.props.setMenuPortfolio(null)
            this.props.setMenuPath(null)
            
        }
    
      goToLink = (destination) => {
    
        this.emptyMenu()
        this.props.history.push(`/${destination}`)
        this.handleClose()
        
      }
    
      handleHoverOff = () => {
        setTimeout(() => {
            this.setState(state => ({ open: false }));
           }, timeoutLength);
      };
    
      handleHoverMenuOn = () => {
        this.setState(state => ({ onMenu: true }));   
      }
    
      handleHoverMenuOff = () => {
        setTimeout(() => {
            this.setState(state => ({ onMenu: false }));
           }, timeoutLength);
      }
    
      handleToggle = () => {
        this.setState(state => ({ open: !state.open }));
      };
    
      handleClose = event => {
        this.setState({ open: false });
      };
    


    render () {

        const open = this.state.open || this.state.onMenu
        return (
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%'}}>
                <div >
                {this.props.selected !== null && this.props.selected !== undefined ? 

                    <div className="basier-p4-caps">
                        {Object.values(this.props.selected).filter(x => x === true).length} Selected
                    </div>
                
                : 

                null}
                </div>
            </div>
        )
    }


}

const mapStateToProps = state => {
    return {
        token: state.auth.token, 
        user_id: state.auth.user_id,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        toggleModal: (data) => dispatch(interActions.toggleModal(data)),
        showSnack: (data) => dispatch(interActions.showSnack(data)),
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(SelectAction)
