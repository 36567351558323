import React from 'react';
import {connect} from 'react-redux';

class ListHeaders extends React.PureComponent {

    render() {

        return (

            this.props.scope === 0 ?

                <div
                    style={{
                        display: 'flex',
                        alignItems: 'baseline',
                        paddingLeft: 12,
                        paddingRight: 12,
                        color: '#6A7E93',
                        width: '100%'
                    }}
                >

                    <div className="basier-p3" style={{width: '28%', textTransform: 'uppercase'}}>Asset</div>
                    <div className="basier-p3"
                         style={{width: '13%', textAlign: 'left', textTransform: 'uppercase'}}>Proportion
                    </div>
                    <div style={{width: '16%', textAlign: 'right', textTransform: 'uppercase'}}>
                        <div className="basier-p3">
                            Balance
                        </div>

                    </div>
                    <div style={{width: '16%', textAlign: 'right'}}>

                        <div className="basier-p3" style={{textTransform: 'uppercase'}}>
                            Value
                        </div>
                        <div className="basier-p4" style={{fontStyle: 'italic'}}>

                        </div>

                    </div>
                    <div style={{width: '16%', textAlign: 'right'}}>

                        <div className="basier-p3" style={{textTransform: 'uppercase'}}>
                            Price
                        </div>
                        <div className="basier-p4-caps">
                            24h %
                        </div>

                    </div>
                    <div style={{width: '10%', textAlign: 'right', paddingRight: 12}}>

                        <div className="basier-p3">
                            ROI
                        </div>
                        <div className="basier-p4-caps">
                            On balance
                        </div>

                    </div>

                </div>


                :
                this.props.scope === 1 ?

                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'baseline',
                            paddingLeft: 12,
                            paddingRight: 12,
                            color: '#6A7E93',
                            width: '100%'
                        }}
                    >

                        <div style={{width: '19%'}}>
                            <div className="basier-p3" style={{textTransform: 'uppercase'}}>
                                Contract
                            </div>
                        </div>


                        <div style={{width: '12%', textAlign: 'left'}}>
                            <div className="basier-p3" style={{textTransform: 'uppercase'}}>
                                Type
                            </div>
                        </div>


                        <div style={{width: '19%', textAlign: 'right'}}>
                            <div className="basier-p3" style={{textTransform: 'uppercase'}}>
                                Size (Base)
                            </div>

                            <div className="basier-p4-caps">
                                Size (Quote)
                            </div>
                        </div>


                        <div style={{width: '21%', textAlign: 'right'}}>

                            <div className="basier-p3" style={{textTransform: 'uppercase'}}>
                                Last Price
                            </div>

                            <div className="basier-p4-caps">
                                Open Price
                            </div>

                        </div>


                        <div style={{width: '21%', textAlign: 'right'}}>

                            <div className="basier-p3" style={{textTransform: 'uppercase'}}>
                                P&L (Quote)
                            </div>
                            <div className="basier-p4-caps">
                                Inerse P&L (base)
                            </div>

                        </div>


                        <div style={{width: '8%', textAlign: 'right', paddingRight: 12}}>

                            <div className="basier-p4-caps">

                            </div>

                        </div>

                    </div>

                    :
                    null


        )
    }


}

const mapStateToProps = state => {
    return {};
};

const mapDispatchToProps = dispatch => {
    return {};
};


export default connect(mapStateToProps, mapDispatchToProps)(ListHeaders)
