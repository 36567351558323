import React from 'react';
import { formattedNumber } from '../../../helpers/helpers';
import Moment from 'moment'
import ChevronRight from '@material-ui/icons/ChevronRight';
import { connect } from 'react-redux';
import WislyTooltip from '../../AppWide/Tooltip';
import currencyDict from "../../../helpers/currency.json";
import { Link } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom'

const CoinIcon = (props) => {
  return (
    <WislyTooltip title={`${props.coinData.symbol}: ${formattedNumber(props.data.amount)}`}>
      {props.coinData.image !== "" &&
          props.coinData.image !== null ? (
            <span>
              <img
                style={{ height: 25, width: 25, marginRight: 3, marginLeft: 3 }}
                src={props.coinData.image}
                alt=""
              />
            </span>
          ) : (
            <span
              style={{
                height: 25,
                width: 25,
                marginRight: 3,
                marginLeft: 3,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: 14,
                border: '1px solid gray',
                borderRadius: 13,
              }}
            >
              {currencyDict[props.coinData.symbol] === undefined
                ? null
                : currencyDict[props.coinData.symbol]["symbol"].length > 1
                ? null
                : currencyDict[props.coinData.symbol]["symbol"]}
            </span>
          )}

    </WislyTooltip>
  )
}

const CoinCluster = (props) => {
  return (

    <div style={{zIndex: 500 - props.ix}}>
      {props.coinData.image !== "" &&
          props.coinData.image !== null ? (
            <span style={{backgroundColor: 'white', width: 25, height: 25, borderRadius: 13, marginRight: -12}}>
              <img
                style={{ height: 25, width: 25 }}
                src={props.coinData.image}
                alt=""
              />
            </span>
          ) : (
            <span
              style={{
                height: 25,
                width: 25,
                marginRight: -12,
                backgroundColor: 'white',
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: 14,
                border: '1px solid gray',
                borderRadius: 13,
              }}
            >
              {currencyDict[props.coinData.symbol] === undefined
                ? null
                : currencyDict[props.coinData.symbol]["symbol"].length > 1
                ? null
                : currencyDict[props.coinData.symbol]["symbol"]}
            </span>
          )
      }
      </div>
  )
}

class AccountLine extends React.PureComponent {

  state = {
    item: [],
    loading: true,
    balances: []
  }
  
  componentDidMount() {
    this.extractBalance()
  }
  componentDidUpdate(prevProps) {
    if (prevProps.balances !== this.props.balances && this.props.balances !== undefined) {
      this.extractBalance()
    }
  }

  extractBalance() {

    if (this.props.balances !== undefined && this.props.balances.connected !== undefined) {

      let base;
      let selector = this.props.data.type === "exchange" ? 'e' : 's'
      if (this.props.data.connected === true) {
        try {
          base = this.props.balances.connected[selector][this.props.data.id]
        }
        catch(e) {
          base = []
        }
      } else {
        if (this.props.data.type === 'exchange') {
          base = this.props.balances.remaining.filter(v => v.simple_wallet__exchange_id == this.props.data.id)
        } else if (this.props.data.type === 'storage') {
          base = this.props.balances.remaining.filter(v => v.simple_wallet == this.props.data.id)
        }
      }
      this.setState(state => ({...state, balances: base}))
    } 
  }

  renderCoinsLine = (number, coinData) => {
    return(
      this.state.balances === undefined ? null :
      this.state.balances.filter(v => v.coin !== null).slice(0, number).map((v, k) => {
        let singleCoinData = {} 
        try {
          singleCoinData = coinData[v['coin']]
          if (singleCoinData === undefined) {
            singleCoinData = {}
          }
        } catch(e) {}
        return(
          <CoinIcon data={v} key={k} coinData={singleCoinData} ix={k} />
        )
      })
    )
  }
 
  render() {

    const {data} = this.props;
    let coinData = {};
    try {
      coinData = this.props.balances.coin_data;
      if (coinData === undefined) {
        coinData = {}
      }
    } catch(e) {}

    let totalNumber, remainingNumber;
    try {
      totalNumber = this.state.balances.length
      remainingNumber = this.state.balances.length - 3
    } catch(e) {}
  
    return(

      <Link component={RouterLink} to={`/wallets/${data.id}-${data.type}-${data.source_id}`}>
        <div style={{height: 60, display: 'flex', alignItems: 'center', justifyContent: 'space-between', borderBottom: '1px solid #E1E5E94D', cursor: 'pointer'}} className="light-hover">
          <div className="truncate" style={{width: 'calc(100% - 210px)'}}>
            <div className="truncate" style={{color: 'black', textTransform: 'uppercase', fontWeight: 'bold', width: '100%'}}>
              {data.name}
            </div>
            <div style={{color: '#6A7E93', display: 'flex', alignItems: 'center'}} className="basier-p4">
              <div style={{color: data.connected ? '#4CDAC1' : '#6A7E93', fontWeight: 900}}>
              {data.connected ? 'Connected' : 'Manual'}
              </div>
              {data.connected && <div style={{marginLeft: 6, marginRight: 6}}>-</div>}
              {data.connected && <div>Last updated: {Moment(data.last_updated).format("YYYY-MM-DD | HH:mm")} </div> }
            </div>
          </div>
          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start', width: 200}}>
            {
            totalNumber > 5 ? 
            
              <div style={{display: 'flex', alignItems: 'center'}}> 
              {this.renderCoinsLine(3, coinData)}
              <WislyTooltip title={`${remainingNumber} other coins`}>
              <div style={{display: 'flex', alignItems: 'center'}}> 
              { 
                this.state.balances.filter(v => v.coin !== null).slice(3, 8).map((v, k) => {
                  let singleCoinData = {} 
                  try {
                    singleCoinData = coinData[v['coin']]
                    if (singleCoinData === undefined) {
                      singleCoinData = {}
                    }
                  } catch(e) {}
                  return(
                    <CoinCluster data={v} key={k} coinData={singleCoinData} ix={k} />
                  )
                })
              }
              </div></WislyTooltip>
              </div>
            :
              this.renderCoinsLine(5, coinData) 
            }
        </div>
        </div>
      </Link>

    )
  }
}

const mapStateToProps = state => {
  return {
      balances: state.data.connectedBalances,
  };
};


export default connect(
  mapStateToProps,
  null
)(AccountLine);
