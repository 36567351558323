import React from 'react';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import axios from 'axios';
import { backendUrl } from '../../variables';
import * as actions from "../../store/actions/auth";
import * as interActions from "../../store/actions/interactions";
import * as dataActions from "../../store/actions/data";
import {updateLocal} from '../../helpers/localHelpers'
import CurrencySelect from './CurrencyPref'
import currencyData from '../../helpers/currency.json'
import Hidden from '@material-ui/core/Hidden';
import CoinSelect from '../MobileOnly/MobileCoinSelect'

const styles = theme => ({
    root: {
        '& label.Mui-focused': {
          color: 'green',
        },
        '& .MuiInput-underline:after': {
          borderBottomColor: 'green',
        },
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: 'red',
          },
          '&:hover fieldset': {
            borderColor: 'yellow',
          },
          '&.Mui-focused fieldset': {
            borderColor: 'green',
          },
        },
      },

      inputSmall: {
        width: 200,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            marginTop: 12,
        },
        [theme.breakpoints.up('md')]: {
            width: 200,
            marginRight: 12
        }
    },
    inputLarge: {
        width: 412,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            marginTop: 12,
        },
        [theme.breakpoints.up('md')]: {
            width: 412,
            marginRight: 12
        }
    },
    inputsWrapper: {
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    }, 
    buttonWidth: {
        [theme.breakpoints.down('sm')]: {
            width: 130,
        },
    }, 
    buttonWrapper: {
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            marginRight: 0
        },
        [theme.breakpoints.up('md')]: {
            paddingRight: 24
        } 
    }, 
    paddingSmall: {
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 12, paddingRight: 12
        },  
    }
  });

class ProfileContainer extends React.PureComponent {
    
    state = {
        edit: false,
        changePass: false,
        first_name: this.props.first_name,
        last_name: this.props.last_name, 
        email: this.props.email,
        oldPass: null, 
        newPass: null,
        nameLoad: false, 
        errorPass: null,
        emailChangeMessage: false, 
        settingsEdit: false,
        currencySelect: this.props.currency, 
        settingsLoad: false,
        modalType: null, 
        openModal: false,
    }

    componentDidMount() {
        this.props.setCurrentView(999);
    }

    handleModalOpen = (e, type) => {

        this.setState(state => ({...state, modalType: type}))
        this.setState({ openModal: true });
      };
    

    handleModalClose = () => {

        this.setState({ openModal: false });

      };

    changePrefs = () => {
        const new_currency = this.state.currencySelect != this.props.currency ? this.state.currencySelect : undefined

        const postData = {
            currency: new_currency, 
            user_id: this.props.user_id
        }

        let proceed = false
        if (new_currency) {
            proceed = true
        } 

        if (proceed) {
            this.setState(state => ({...state, settingsLoad: true}))
            let headers = { "content-type": "application/json" };
                if (this.props.token !== null && this.props.token !== undefined) {

                    headers['Authorization'] = `Token ${this.props.token}`

                }

                const url_call = `${backendUrl}/v1/user_preference/`

                axios.post(url_call, postData, {headers: headers})
                    .then(json => {

                        const returnData = json['data']['data']
                        let newData = {}
                        if ('currency' in returnData) {

                            let currency_symbol = null 
                            const u_preferred_currency = returnData['currency']['id']
                            try { currency_symbol = currencyData[u_preferred_currency]["symbol"]} catch (e) { currency_symbol = u_preferred_currency}

                            newData = {
                                ...newData,
                                currency: {
                                    id: u_preferred_currency, 
                                    symbol: currency_symbol, 
                                },
                                user_preferences: returnData
                            }

                        }
                        this.props.updateUser(newData)
                        updateLocal(newData)
                        this.setState(state => ({...state, settingsLoad: false, settingsEdit: false}))
                        this.props.showSnack({message: 'Preferences updated succesfully', variant: "success"})

                    })
                    .catch( e => {

                        this.props.showSnack({message: 'Error in updating preferences. Please try again in a few minutes', variant: "error"})

                    

                    })
            }



    }

    changeCurrency = (val) => {
        this.setState(state => ({...state, currencySelect: val}))
    }

    changeMobileCurrency = (val) => {
        this.setState(state => ({...state, currencySelect: val}))

        const new_currency = val != this.props.currency ? val : undefined

        const postData = {
            currency: new_currency, 
            user_id: this.props.user_id
        }

        let proceed = false
        if (new_currency) {
            proceed = true
        } 

        if (proceed) {

            let headers = { "content-type": "application/json" };
                if (this.props.token !== null && this.props.token !== undefined) {

                    headers['Authorization'] = `Token ${this.props.token}`

                }

                const url_call = `${backendUrl}/v1/user_preference/`

                axios.post(url_call, postData, {headers: headers})
                    .then(json => {

                        const returnData = json['data']['data']
                        let newData = {}
                        if ('currency' in returnData) {

                            let currency_symbol = null 
                            const u_preferred_currency = returnData['currency']['id']
                            try { currency_symbol = currencyData[u_preferred_currency]["symbol"]} catch (e) { currency_symbol = u_preferred_currency}

                            newData = {
                                ...newData,
                                currency: {
                                    id: u_preferred_currency, 
                                    symbol: currency_symbol, 
                                },
                                user_preferences: returnData
                            }

                        }
                        this.props.updateUser(newData)
                        updateLocal(newData)
                        this.setState(state => ({...state, settingsLoad: false, settingsEdit: false}))
                        this.props.showSnack({message: 'Preferences updated succesfully', variant: "success"})

                    })
                    .catch( e => {

                        this.props.showSnack({message: 'Error in updating preferences. Please try again in a few minutes', variant: "error"})

                    

                    })
            }

    }

    

    changeValue = (label, e) => {
        e.persist();
        this.setState(state => ({...state, [label]: e.target.value}))
    }

    toggleEdit = () => {
        this.setState(state => ({...state, edit: !state.edit}))
    }

    togglePass = () => {
        this.setState(state => ({...state, changePass: !state.changePass, oldPass: null, newPass: null}))
    }

    togglePrefs = () => {
        this.setState(state => ({...state, settingsEdit: !state.settingsEdit}))
    }

    updateProfile = () => {
        const new_first_name = this.state.first_name != this.props.first_name ? this.state.first_name : undefined
        const new_last_name = this.state.last_name != this.props.last_name ? this.state.last_name : undefined
        const new_email = this.state.email != this.props.email ? this.state.email : undefined
        const postData = {
            first_name: new_first_name, 
            last_name: new_last_name, 
            email: new_email, 
            user_id: this.props.user_id,
        }
        const showEmailMess = new_email !== undefined ? true : false

        let proceed = false
        if (new_first_name || new_last_name || new_email) {
            proceed = true
        } 

        if (proceed) {
            this.setState(state => ({...state, nameLoad: true}))
            let headers = { "content-type": "application/json" };
                if (this.props.token !== null && this.props.token !== undefined) {

                    headers['Authorization'] = `Token ${this.props.token}`

                }

                const url_call = `${backendUrl}/api/users/update/`

                axios.post(url_call, postData, {headers: headers})
                    .then(json => {

                        const newData = {
                            user_first_name: this.state.first_name, 
                            user_last_name: this.state.last_name, 
                        }

                        this.props.updateUser(newData)
                        updateLocal(newData)
                        this.setState(state => ({...state, nameLoad: false, edit: false, emailChangeMessage: showEmailMess}))
                        this.props.showSnack({message: 'Profile updated succesfully', variant: "success"})

                    })
                    .catch( e => {

                        this.props.showSnack({message: 'Error in profile update. Please try again in a few minutes', variant: "error"})

                    

                    })
            }

    }

    changePass = () => {

        if (this.state.oldPass && this.state.newPass) {

        const postData = {
            old_password: this.state.oldPass, 
            new_password1: this.state.newPass, 
            new_password2: this.state.newPass,
        }
        let headers = { "content-type": "application/json" };
                if (this.props.token !== null && this.props.token !== undefined) {

                    headers['Authorization'] = `Token ${this.props.token}`

                }

                const url_call = `${backendUrl}/rest-auth/password/change/`

                axios.post(url_call, postData, {headers: headers})
                    .then(json => {

                        this.setState(state => ({...state, passLoad: false, changePass: false, errorPass: null}))
                        this.props.showSnack({message: 'Password succesfully changed', variant: "success"})

                    })
                    .catch( e => {

                        //console.log(e.response)
                        const response = e.response['data']
                        if ('old_password' in response) {
                            this.setState(state => ({...state, errorPass: 'Incorrect Old Password'}))
                        } else {
                            this.setState(state => ({...state, errorPass: 'Error while changing password. Please try again in a few minutes, or contact support.'}))
                        }

                    })
        }
    }
   
    render () {

        const { classes } = this.props;

        return (



                <div style={{marginTop: 0, minHeight: 120, maxWidth: '100vw'}}>


                    <div className={`basier-p4-caps ${classes.paddingSmall}`}>
                        Profile
                    </div>
                    <div className={classes.paddingSmall} >
                        <div className="wrap-small" style={{display: 'flex', alignItems: 'flex-start', marginTop: 12, marginBottom: 12, justifyContent: 'space-between', minHeight: 120,}}>
                            {
                            this.state.nameLoad ? 

                            <div style={{marginTop: 24, height: 83, width: '100%', display: 'flex', alignItems: 'center'}}>
                                <div className="pulse-skeleton" style={{width: 75, height: 75, borderRadius: 100, }}></div>

                                <div style={{marginLeft: 24, display: 'flex', flexDirection: 'column'}}>
                                    <div className="pulse-skeleton" style={{width: 200, height: 30, borderRadius: 10, marginRight: 0}}></div>
                                    <div className="pulse-skeleton" style={{width: 200, height: 18, marginTop: 12, borderRadius: 10, marginRight: 0}}></div>
                                </div>
                            </div>

                            :
                            this.state.edit ? 
                            <div className={classes.inputsWrapper}>

                                <div style={{display: 'flex', alignItems: 'center', marginTop: 12, marginBottom: 12, flexWrap: 'wrap'}}>

                                <div className={classes.inputSmall}>
                                <TextField
                                        className="custom-input"
                                        value={this.state.first_name}
                                        onChange={(val) => this.changeValue('first_name', val)}
                                        label="First Name"
                                        type="text"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        variant="outlined"
                                        />
                                </div>

                                <div className={classes.inputSmall}>
                                <TextField
                                        className="custom-input"
                                        value={this.state.last_name}
                                        onChange={(val) => this.changeValue('last_name', val)}
                                        label="Last Name"
                                        type="text"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        variant="outlined"
                                        />
                                </div>

                                </div>
                                <div className={classes.inputLarge}>
                                <TextField
                                    className="custom-input custom-input-email"
                                    value={this.state.email}
                                    onChange={(val) => this.changeValue('email', val)}
                                    label="E-Mail"
                                    type="email"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    variant="outlined"
                                    />
                                </div>

                            </div>
                            :
                            <div>
                            <div style={{display: 'flex', alignItems: 'center', marginTop: 24, marginBottom: 24}}>
                                <img 
                                    style={{height: 60, width: 60}}
                                    className="no-select"
                                    src={`https://ui-avatars.com/api/?rounded=true&size=128&background=6A7E93&color=ffffff&name=${this.props.first_name}+${this.props.last_name}`} 
                                    alt="" />
                                    <div style={{marginLeft: 12}}>
                                        <Hidden smDown>
                                        <h2 style={{margin: 0}}>
                                            <span style={{textTransform: 'capitalize'}}>{`${this.props.first_name} `}</span>
                                            <span style={{textTransform: 'capitalize'}}>{`${this.props.last_name}`}</span>
                                        </h2>
                                        </Hidden>
                                        <Hidden mdUp>
                                            <h4 style={{margin: 0}}>
                                                <span style={{textTransform: 'capitalize'}}>{`${this.props.first_name} `}</span>
                                                <span style={{textTransform: 'capitalize'}}>{`${this.props.last_name}`}</span>
                                            </h4>
                                        </Hidden>
                                        <Hidden smDown>
                                            <div className="basier-p2" style={{marginTop: 4}}>
                                                {this.props.email}
                                            </div>
                                        </Hidden>

                                        <Hidden mdUp>
                                            <div className="basier-p3 truncate" style={{marginTop: 4}}>
                                                {this.props.email}
                                            </div>
                                        </Hidden>
                                        
                                    </div>
                                </div>
                                {this.state.emailChangeMessage ? 

                                        <div className="basier-p4" style={{marginTop: 2, color: 'blue'}}>A confirmation email has been sent to {this.state.email}. Please click the activation link to confirm the change to your e-mail.</div>
                                        
                                        :
                                        
                                        null
                                        
                                }
                                </div>
                                }
                                <div className={classes.buttonWrapper}>
                                    {
                                    
                                    this.state.nameLoad ? 
                                    
                                    null
                                    
                                    :
                                    
                                    this.state.edit ? 

                                    <div className="small-center" style={{display: 'flex', alignItems: 'center', flexWrap: 'wrap',}}>

                                        <button className={`${classes.buttonWidth} button-blue opacity-hover`} style={{height: 40, borderRadius: 35, cursor: 'pointer', marginRight: 12, marginTop: 12}} onClick={() => this.updateProfile()}>
                                        Save
                                        </button>

                                        <button className={`${classes.buttonWidth} button-blue opacity-hover`} style={{height: 40, borderRadius: 35, cursor: 'pointer', backgroundColor: '#F9716B', marginTop: 12, marginRight: 12,}} onClick={() => this.toggleEdit()}>
                                            Cancel
                                        </button>

                                    </div>

                                    :

                                    <button className={`${classes.buttonWidth} button-blue opacity-hover`} style={{height: 40, borderRadius: 35, marginTop: 12,  cursor: 'pointer', marginRight: 12,}} onClick={() => this.toggleEdit()}>
                                        Edit Profile
                                    </button>
                                    }   
                                </div>
                        </div>
                        <Divider />
                        
                        <div className="basier-p4-caps" style={{marginTop: 24}}>
                            Password
                        </div>

                        <>
                        <div className="wrap-small" style={{display: 'flex', alignItems: 'center', marginTop: 12, marginBottom: 12, justifyContent: 'space-between', minHeight: 78}}>

                            {this.state.changePass ? 

                            <div className={classes.inputsWrapper}>

                                <div className="wrap-small" style={{display: 'flex', alignItems: 'center', marginTop: 12}}>

                                <div className={classes.inputSmall}>

                                    <TextField
                                        className="custom-input"
                                        autoComplete='old-password'
                                        autoFocus={true}
                                        value={this.state.oldPass}
                                        onChange={(val) => this.changeValue('oldPass', val)}
                                        label="Old Password"
                                        type="password"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        variant="outlined"
                                        />
                                </div>

                                <Hidden mdUp>
                                    <br/>
                                </Hidden>

                                <div className={classes.inputSmall}>
                                    <TextField
                                        className="custom-input"
                                        autoComplete='new-password'
                                        value={this.state.newPass}
                                        onChange={(val) => this.changeValue('newPass', val)}
                                        label="New Password"
                                        type="password"
                                        InputLabelProps={{
                                            shrink: true,       
                                        }}
                                        variant="outlined"
                                        />
                                </div>
                                
                                </div>

                                <div className="basier-p4" style={{color: 'red', marginTop: 6}}>{this.state.errorPass}</div>

                            </div>

                            :
                                <span>
                                ********
                                </span>
                            }   

                                <div className={classes.buttonWrapper}>

                                {this.state.changePass ? 
                                

                                <div className="small-center" style={{display: 'flex', alignItems: 'center', flexWrap: 'wrap', marginLeft: 12,}}>

                                    <button className={`${classes.buttonWidth} button-blue opacity-hover`} style={{height: 40, borderRadius: 35, cursor: 'pointer', marginRight: 12, marginTop: 12}} onClick={() => this.changePass()}>
                                        Save
                                    </button>

                                    <button className={`${classes.buttonWidth} button-blue opacity-hover`} style={{height: 40, borderRadius: 35, cursor: 'pointer', backgroundColor: '#F9716B', marginTop: 12, marginRight: 12,}} onClick={() => this.togglePass()}>
                                        Cancel
                                    </button>

                                </div>
                                :

                                <button className={`${classes.buttonWidth} button-blue opacity-hover`} style={{height: 40, borderRadius: 35, cursor: 'pointer', marginRight: 12, marginTop: 12, }} onClick={() => this.togglePass()}>
                                    Change
                                </button>
                                
                                }
                                </div>
                        
                            </div>

                        </>
                        
                        
                        <Divider />
                    
                    </div>

                    <div>

                    <div className={`basier-p4-caps ${classes.paddingSmall}`} style={{marginTop: 24}}>
                        Preferences
                    </div>
                    
                    <Hidden smDown>
                        <div className="wrap-small" style={{display: 'flex', justifyContent: 'space-between'}}>
                            <div style={{display: 'flex', alignItems: 'center', marginTop: 12}}>
                                <div className="basier-p3" style={{color: 'black', marginRight: 12, fontWeight: 900}}>Display currency: </div>
                                {this.state.settingsEdit ? 
                                <CurrencySelect handleChange={this.changeCurrency} />
                                :
                                this.props.currency
                                }
                            </div>

                            <div className={classes.buttonWrapper} style={{display: 'flex',}}>

                                {this.state.settingsEdit ? 
                                
                                    <div style={{display: 'flex', alignItems: 'center', flexWrap: 'wrap', marginLeft: 12, justifyContent: 'flex-end'}}>

                                        <button className={`${classes.buttonWidth} button-blue opacity-hover`} style={{height: 40, borderRadius: 35, cursor: 'pointer', marginRight: 12, marginTop: 12}} onClick={() => this.changePrefs()}>
                                            Save
                                        </button>

                                        <button className={`${classes.buttonWidth} button-blue opacity-hover`} style={{height: 40, borderRadius: 35, cursor: 'pointer', backgroundColor: '#F9716B', marginTop: 12, marginRight: 12,}} onClick={() => this.togglePrefs()}>
                                            Cancel
                                        </button>

                                    </div>
                                    :

                                    <button className={`${classes.buttonWidth} button-blue opacity-hover`} style={{height: 40, borderRadius: 35, cursor: 'pointer', marginRight: 12, marginTop: 12, }} onClick={() => this.togglePrefs()}>
                                        Edit
                                    </button>
                                
                                }

                            </div>
                        </div>
                    </Hidden>

                    <Hidden mdUp>
                        
                    <div 
                        style={{
                            height: 60,
                            display: 'flex', 
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            paddingLeft: 24,
                            marginTop: 12,
                            borderBottom: '1px solid #E1E5E9',
                            borderTop: '1px solid #E1E5E9',
                            width: '100%',
                            textTransform: 'uppercase',
                            fontWeight: 900
                        }}
                        onClick={(e, coin) => this.handleModalOpen(e, 'currency_set')}
                        className="basier-p3">

                                
                                <div style={{color: 'black', fontWeight: 900}}>
                                    Display currency
                                </div>

                                <div style={{marginRight: 24, display: 'flex', alignItems: 'center'}}>

                                    <div style={{marginRight: 24, color: '#6A7E93'}}>
                                        {
                                            this.props.currency !== undefined && this.props.currency !== null ? 
                                            
                                            <div style={{display: 'flex', alignItems: 'center'}}>
                                                <div>{this.state.currencySelect}</div>
                                            </div>
                                            
                                            :
                                            <div>USD</div>
                                        }
                                    </div>

                                    <div>
                                        >
                                    </div>
                                </div>

                                 

                             </div>
                        
                    </Hidden>


                    </div>
                    <CoinSelect openModal={this.state.openModal} closeModal={this.handleModalClose} type={this.state.modalType} handleSelect={this.changeMobileCurrency}/>
                    
                </div>

                       
        )
    }


}

const mapStateToProps = state => {
    return {

        first_name: state.auth.user_first_name,
        last_name: state.auth.user_last_name,
        email: state.auth.email,
        token: state.auth.token,
        user_id: state.auth.user_id, 
        currency: state.auth.currency.id,

    };
};

const mapDispatchToProps = dispatch => {
    return {
        updateUser: (data) => dispatch(actions.updateUser(data)), 
        showSnack: (data) => dispatch(interActions.showSnack(data)), 
        updateCurrency: (data) => 
          dispatch(actions.setViewCurrency(data)),
        setCurrentView: (view) => 
            dispatch(dataActions.setCurrentView(view)),
        
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ProfileContainer));
