import React from 'react'
import { connect } from 'react-redux';
import { Link } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom'
import TouchCarousel from 'react-touch-carousel'
import '../../../../Overview/OverviewComponents/cards.css'
import NonPassiveTouchTarget from '../../../../Overview/OverviewComponents/NonPassiveTouchTarget'
import currency_dict from '../../../../../helpers/currency.json';

const symbolGet = (dict, key) => {
    
    if (dict[key] !== undefined) {
        return dict[key]["symbol"]
    } else {
        return key
    }
}

function clamp (n, min, max) {
    if (n < min) {
      return min
    }
    if (n > max) {
      return max
    }
    return n
  }

class CollabSwipe extends React.Component {

  state = {

    activeItemIndex: 0, 
    summaryData: [], 
    numdigits: 0,
    dataList: null,
    expand: false,

  }


  componentDidMount() {

    window.addEventListener('touchstart', this.touchStart);
    window.addEventListener('touchmove', this.preventTouch, {passive: false});
    if (this.props.deposits !== null && this.props.deposits !== undefined ) {
      const sortedData = this.props.deposits.user_list.filter(v => v === this.props.firstScopeUser).concat(this.props.deposits.user_list.filter(v => v !== this.props.firstScopeUser))
      this.setState(state => ({...state, dataList: sortedData}))
    }

    }

    componentDidUpdate(prevProps) {

      if (this.props.deposits !== null && this.props.deposits !== undefined && prevProps.deposits !== this.props.deposits) {
        const sortedData = this.props.deposits.user_list.filter(v => v === this.props.firstScopeUser).concat(this.props.deposits.user_list.filter(v => v !== this.props.firstScopeUser))
        this.setState(state => ({...state, dataList: sortedData}))
      }
      
    }

    componentWillUnmount() {
        window.removeEventListener('touchstart', this.touchStart);
        window.removeEventListener('touchmove', this.preventTouch, {passive: false});
    }

    touchStart(e) {
        this.firstClientX = e.touches[0].clientX;
        this.firstClientY = e.touches[0].clientY;
    }

    preventTouch(e) {
        const minValue = 15; // threshold

        this.clientX = e.touches[0].clientX - this.firstClientX;
        this.clientY = e.touches[0].clientY - this.firstClientY;

        // Vertical scrolling does not work when you start swiping horizontally.
        if(Math.abs(this.clientX) > minValue){ 
            e.preventDefault();
            e.returnValue = false;
            return false;
        }
    }

    dragEnd = (index, modIndex, cursor, carouselState) => {
        this.props.setScopeUser(this.state.dataList[modIndex])
    }

    dragStop = () => {
      this.setState(state => ({...state, expand: false}))
    }
    
    render() {

      const enableLoop = false
      
      const cardSize = window.innerWidth
      const cardPadCount = 0
      const carouselWidth = clamp(window.innerWidth, 0, 960)

      const CarouselContainer = (props) => {
        const {cursor, carouselState: {active, dragging}, ...rest} = props
        let current = -Math.round(cursor) % this.state.dataList.length
        while (current < 0) {
          current += this.state.dataList.length
        }
        // Put current card at center
        const translateX = (cursor - cardPadCount) * cardSize + (carouselWidth - cardSize) / 2
        return (
          <NonPassiveTouchTarget className="carousel-container-collab">
            <NonPassiveTouchTarget
              className={this.state.expand ? 'carousel-track-collab-fit' : 'carousel-track-collab'}
              style={{transform: `translate3d(${translateX}px, 0, 0)`}}
              {...rest}
            />
      
            <div className='carousel-pagination-wrapper-collab'>
              <ol className='carousel-pagination-summary-collab'>
                {this.state.dataList.map((_, index) => (
                  <li
                    key={index}
                    className={current === index ? 'current' : ''}
                  />
                ))}
              </ol>
            </div>
          </NonPassiveTouchTarget>
        )
      }
      

      const renderCard = (index, modIndex) => {
        const item = this.state.dataList[modIndex]
        if (this.props.deposits.user_deposits[item] !== undefined) {
          return (
              <div
              //onClick={(e) => this.props.setScopeUser(e, item.user_id)}
              className="carousel-collab-inner"
              style={{ 
                  minWidth: window.innerWidth * 0.9,
                  height: this.state.expand ? 'fit-content' : 100,
                  padding: 12,
                  width: '100%',
                  boxShadow: '0px 10px 10px rgba(225, 229, 233, 0.3)', 
                  border: '1px solid rgba(243, 245, 246, 0.5)',
                      }}>
                  
                  <div style={{
                      display: 'flex', 
                      alignItems: 'center',
                  }}>
                      <span>
                          <img 
                          style={{height: 35, width: 35, border: '2px solid white', borderRadius: '50%'}}
                          src={`https://ui-avatars.com/api/?rounded=true&size=128&background=6A7E93&color=ffffff&name=${this.props.deposits.user_deposits[item]['name']}`} 
                          alt=""/>
                      </span>
                      <span className="basier-p3" style={{marginLeft: 10, color: 'black',}}>
                          {this.props.deposits.user_deposits[item]['name']}
                      </span>
                  </div>
      
                  <div style={{
                      display: 'flex', 
                      alignItems: 'flex-start',
                      justifyContent: 'flex-start',
                      marginTop: 6,
                      width: '100%'
                  }}>
                      <div style={{marginLeft: 6, width: '100%'}}>
                          <div className="basier-p4-caps">
                              Investment
                          </div>

                              <div style={{display: 'flex', width: '100%', alignItems: 'baseline', justifyContent: 'space-between'}}>
                                <div>
                                  { 
                                      this.state.expand ? 
                                      Object.keys(this.props.deposits.user_deposits[item]['deposits']).map((key, index) => {
                                            
                                            return (
                                               
                                                <div key={index} style={{display: 'flex', width: '100%', alignItems: 'baseline', justifyContent: 'space-between'}}>
                                                    <div className="basier-p3" style={{marginBottom:0, paddingBottom: 0}}>
                                                        <span>{symbolGet(currency_dict, key)} </span> 
                                                        <span>{this.props.deposits.user_deposits[item]['deposits'][key].toLocaleString('en', {minimumFractionDigits:2, maximumFractionDigits: 2})}</span> 
                                                    </div>
                                                </div>
                                            )
                                      })
                                      :
                                      Object.keys(this.props.deposits.user_deposits[item]['deposits']).map((key, index) => {
                                            
                                        return (
                                            index === 0 ? 
                                            <div key={index} style={{display: 'flex', width: '100%', alignItems: 'baseline', justifyContent: 'space-between'}}>
                                                <div className="basier-p3" style={{marginBottom:0, paddingBottom: 0}}>
                                                    <span>{symbolGet(currency_dict, key)} </span> 
                                                    <span>{this.props.deposits.user_deposits[item]['deposits'][key].toLocaleString('en', {minimumFractionDigits:2, maximumFractionDigits: 2})}</span> 
                                                </div>
                                            </div>
                                            :
                                            null
                                        )
                                  })
                                  
                                  }
                                  </div>
                                  {this.state.expand ? 
                              
                                  <div onClick={() => this.setState(state => ({...state, expand: !state.expand }))}className="basier-p4 touchableOpacity" style={{color: '#0078DB', paddingRight: 6}}>See less</div>

                                  :

                                  Object.keys(this.props.deposits.user_deposits[item]['deposits']).length > 1 ? 
                                  <div onClick={() => this.setState(state => ({...state, expand: !state.expand }))}className="basier-p4 touchableOpacity" style={{color: '#0078DB', paddingRight: 6}}>See {Object.keys(this.props.deposits.user_deposits[item]['deposits']).length - 1} more</div>
                                  :
                                  null 
                                  }
                              </div>
                              
      
                      </div>    
                  
                  </div>
      
                  
      
              </div>
          )
        }
      }


    return (

        <div style={{marginTop: 0,}}>

          {
            this.state.dataList !== undefined && this.state.dataList !== null ?

              <TouchCarousel
              component={CarouselContainer}
              cardSize={cardSize}
              cardCount={this.state.dataList.length}
              cardPadCount={cardPadCount}
              onRest={this.dragEnd}
              loop={false}
              autoplay={false}
              renderCard={renderCard}
              onDragEnd={this.dragStop}
              />


            :

            null
            
          }

          </div>

    )

    }
    
}

const mapStateToProps = state => {
    return {
      currency_symbol: state.auth.currency.symbol,
      currency_name: state.auth.currency.id,
      inBitcoin: state.data.bitcoinPf
    };
};

export default connect(
    mapStateToProps,
    null,
)(CollabSwipe);
