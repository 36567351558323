import React from "react";
import listIcon from "../../../../../../Media/listIcon.svg";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import NFTViewer from "./NFTViewer";
import NFTTableLine from "./NFTTableLine";

import worker from "../../../../../../workers/nftWorker";
import WebWorker from "../../../../../../workers/WebWorker";

class NFTTable extends React.Component {
  state = { data: null };

  render() {
    const { data } = this.props;
    return (
      <div>
        {!data ? null :
        data.map((v, k) => {
          console.log(v)
          return (
            <NFTTableLine 
              currency_symbol={this.props.currency_symbol} 
              inBitcoin={this.props.inBitcoin} 
              key={k} 
              data={v} 
              token={this.props.token} 
              priceCache={this.props.priceCache}
              fetchCache={this.props.fetchCache}
              setPriceCache={this.props.setPriceCache}
              getExternalPrice={this.props.getExternalPrice}
              prices={this.props.prices}
            />
          )
        })}
      </div>
    );
  }
}



export default NFTTable;
