import React, {Component} from 'react'
import NonPassiveTouchTarget from '../../../../Overview/OverviewComponents/NonPassiveTouchTarget'
import TouchCarousel from 'react-touch-carousel'
import { connect } from 'react-redux';


function clamp (n, min, max) {
  if (n < min) {
    return min
  }
  if (n > max) {
    return max
  }
  return n
}

const cardSize = 200
const cardPadCount = 2
const carouselWidth = clamp(window.innerWidth, 0, 960)


class ProfitSummary extends Component {
  constructor (props) {
    super(props)
    this.state = {
      playing: 0, 
      numdigits: 0,
    }
    this.dataList = ['total_active_profit', 'total_realized_profit']
  }

  componentDidMount() {

    if (this.props.inBitcoin === 'bitcoin') {
      this.setState(state => ({...state, numdigits: 2}))
    } else {
      this.setState(state => ({...state, numdigits: 0}))
    }

  }

  renderCard = (index, modIndex, cursor) => {
    const item = this.dataList[modIndex]
    const playing = this.state.playing === modIndex
    return (
      <div
        key={index}
        className='carousel-card-summ'
      >
        <div className='carousel-card-inner-summ'>
          <div
            className='carousel-title-summ' 
          >
            <div style={{marginRight: 12, width: 200, height: 150}}>

                <div>

                  <div style={{display: 'flex', justifyContent: 'center'}}>
                    
                    <p className="basier-p2" style={{marginBottom: 12, color: 'black'}}>
                      {item === 'total_active_profit' ? 'Unrealized Profit' : 'Realized Profit'}
                    </p>

                  </div>

                  <div style={{display: 'flex', justifyContent: 'center'}}>

                        <h4 style={{fontSize: 24}}>
                          {this.props.currency_symbol}&nbsp;
                          {this.props.data[this.props.portfolioId][item].toLocaleString('en', {minimumFractionDigits:this.state.numdigits, maximumFractionDigits: this.state.numdigits})}
                        </h4>

                    </div>

                </div>

            </div>
           
          </div>
        </div>
      </div>
    )
  }

  render () {

    const CarouselContainer = (props) => {
      
      const {cursor, carouselState: {dragging, springing}, ...rest} = props
      let current = -Math.round(cursor) % this.dataList.length
        while (current < 0) {
          current += this.dataList.length
        }
      // Put current card at center
      const translateX = (cursor - cardPadCount) * cardSize + (carouselWidth - cardSize) / 2
      return (
        <div>
          <NonPassiveTouchTarget className='carousel-container-summ'>
            <NonPassiveTouchTarget
              className='carousel-track-summ'
              style={{transform: `translate3d(${translateX}px, 0, 0)`}}
              {...rest}
            />
    
                <div className='carousel-pagination-wrapper-summary'>
                  <ol className='carousel-pagination-summary'>
                    {['total_active_profit', 'total_realized_profit'].map((_, index) => (
                      <li
                        key={index}
                        className={current === index ? 'current' : ''}
                      />
                    ))}
                  </ol>
                </div>
                
          </NonPassiveTouchTarget>
          <div className='cd-bar' />
        </div>
      )
    }


    return (
      <React.StrictMode>
        <TouchCarousel
          component={CarouselContainer}
          cardSize={cardSize}
          cardCount={this.dataList.length}
          cardPadCount={cardPadCount}
          renderCard={this.renderCard}
        />
      </React.StrictMode>
    )
  }
}


const mapStateToProps = state => {
  return {
    currency_symbol: state.auth.currency.symbol,
    currency_name: state.auth.currency.id,
    inBitcoin: state.data.bitcoinPf
  };
};

export default connect(
  mapStateToProps,
  null,
)(ProfitSummary);