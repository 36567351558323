import React from 'react';
import Logo from './Media/Logo.svg'
import googlePlay from './Media/googlePlay.svg'
import appStore from './Media/appStore.svg'
export default class AppMobile extends React.PureComponent {

  render() {
    return (
      <div style={{width: '100wv', height: '100vh', backgroundColor: 'white', padding: 24, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        <img src={Logo} alt="Logo" style={{height: 50}} />
        <div className="poppins" style={{fontSize: '2em', textAlign: 'center', marginTop: 24, color: 'black'}}>
          Discover Wisly
        </div>
        <div className="basier-p4-caps" style={{color: '#0078DB', marginTop: 6}}>On smaller screens</div>
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', margin: 6}}>
          <a href="https://apps.apple.com/us/app/wisly/id1534345820">
            <img src={appStore} alt="app store" style={{height: 50, margin: 6}} />
          </a>
          <a href="https://play.google.com/store/apps/details?id=com.wisly">
            <img src={googlePlay} alt="app store" style={{height: 50, margin: 6}} />
          </a>
        </div>
      </div>
    )
  }
}