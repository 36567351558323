
export default () => {
	self.addEventListener('message', e => { // eslint-disable-line no-restricted-globals
		if (!e) return;
    
    const prices = e['data'][0]
    const scopeData = e['data'][1]
    const scope = e['data'][2]
    const allPorts = e['data'][3]
    const currencyScope = e['data'][4]
    const subs = e['data'][5]
    
    if (scope === 'portfolio' || scope === 'global') {
        let balances = scopeData['balances']
        let total_value = 0
        let total_profit = 0
        let total_active = 0
        let divider = prices[currencyScope]['price']
        balances.map((val) => {

            const new_price = (scope === 'portfolio' ? prices[val['coin']]['price'] : prices[val['base_currency_id']]['price']) / divider
            const change = scope === 'portfolio' ? prices[val['coin']]['change'] : prices[val['base_currency_id']]['change']

            let new_value = new_price * Math.max(val['balance'], 0)
            if (scope === 'portfolio') {
                val['current_price']  = new_price
                val['change_24h'] = change
                val['total_value'] = new_value
                val['active_profit'] = ( (new_price - val['purchase_price']) * Math.max(val['balance'],0) ) + val['fees_remaining']
                val['total_profit'] = val['active_profit'] + val['realized_profit']
                total_profit += val['total_profit']
                total_active +=  val['active_profit']
            } else {
                val['price']  = new_price
                val['value'] = new_value
                val['change'] = change
            }

            total_value += new_value
            
        })
        total_profit += scopeData['open_position_profits']
        total_value += scopeData['open_position_profits']


        if (scope === 'portfolio') {

            scopeData['balances'] = balances
            scopeData['total_value'] = total_value
            scopeData['net_profit'] = total_profit
            scopeData['total_active_profit'] = total_active
            scopeData['percent_change'] = (total_profit/scopeData['deposit_check'])
            scopeData['total_roi_usd'] = total_value/scopeData['deposit_check']

        } else if (scope === 'global') {
            
            scopeData['total'] = total_value
            scopeData['balances'] = balances
            let ind_total_value = 0
            let ind_total_active = 0
            let ind_position_profit = 0
            let ind_total_profit = 0
            let ind_balances = null
            Object.keys(allPorts).map(k => {

                ind_total_value = 0
                ind_total_active = 0
                ind_total_profit = 0
                ind_position_profit = 0
                let scopeInd = allPorts[k]
                ind_balances = scopeInd['balances']
                
                ind_balances.map((val) => {

                    const new_ind_price = prices[val['coin']]['price'] / divider
                    const change_ind = prices[val['coin']]['change'] 
            
                    let new_ind_value = new_ind_price * Math.max(val['balance'], 0)

                    val['current_price']  = new_ind_price
                    val['change_24h'] = change_ind
                    val['total_value'] = new_ind_value
                    val['active_profit'] = ( (new_ind_price - val['purchase_price']) * Math.max(val['balance'],0) ) + val['fees_remaining']
                    val['total_profit'] = val['active_profit'] + val['realized_profit']
                    ind_total_profit += val['total_profit']
                    ind_total_active +=  val['active_profit']
                    ind_position_profit +=  val['open_position_profits']
                    ind_total_value += new_ind_value

                })

                scopeInd['balances'] = ind_balances
                scopeInd['total_value'] = ind_total_value
                scopeInd['net_profit'] = ind_total_profit
                scopeInd['total_active_profit'] = ind_total_active
                scopeInd['percent_change'] = (ind_total_profit/scopeInd['deposit_check'])
                scopeInd['total_roi_usd'] = ind_total_value/scopeInd['deposit_check']
                
                scopeInd['total_value'] += ind_position_profit
                scopeInd['net_profit'] += ind_position_profit
                
            })
            

        }
    } 

    if (scope === 'global' || scope === 'subs') {
        subs.map(val => {
            const sub_price = prices[val['coin_id']]['price']
            const sub_change = prices[val['coin_id']]['change']

            val['price'] = sub_price
            val['change'] = sub_change
        })
    }

    postMessage([scopeData, scope, allPorts, subs]);

})
}
