import React from 'react';
import { connect } from 'react-redux';
import TableRow from './TransactionTableRow';


class TableBody extends React.PureComponent {

    render () {

        return (

            <div className="basier-p4-caps" style={{display: 'flex', width: '100%', alignItems: 'center'}}>

                <div style={{minWidth: 50, maxWidth: 50, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <input
                        type="checkbox"
                        className="regular-checkbox"
                        onChange={() => this.props.selectAll()}
                        checked={this.props.allSelected}
                        //defaultChecked={this.props.allSelected}
                        />
                </div>

                <div style={{minWidth: 150, maxWidth: 200, flex: 1, paddingLeft: 12}}>
                    <div>Type</div>
                </div>

                <div style={{minWidth: 150, flex: 1}}>
                    {this.props.scope === 'transaction' ? 'Base Coin' : 'Contract'}
                </div>

                {this.props.account === true && this.props.scope === 'position' ? null : 
                <div style={{minWidth: 150, flex: 1}}>
                    {this.props.scope === 'transaction' ? 'Quote Coin' : 'Type / Exchange'}
                </div>}

                <div style={{minWidth: 120, maxWidth: 200, flex: 1}}>
                    Amount / Price
                </div>

                <div style={{minWidth: 120, maxWidth: 200, flex: 1}}>
                    Date
                </div>

                {this.props.account === true ? null :
                <div style={{minWidth: 120, maxWidth: 120, flex: 1}}>
                    Action
                </div>}


                
            </div>
            
        )
    }


}

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = dispatch => {
    return {
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(TableBody)
