import React from 'react'; 
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import Tooltip from '../../../AppWide/Tooltip'
import questionMark from '../../../../Media/questionMark.svg'


const Summary = props => {

    const downArrow = 
    (
    <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.3333 8.35083L6.68421 13.9999L1 8.35083" stroke="#F9716B" stroke-width="2" stroke-miterlimit="10"/>
    <path d="M6.68457 14V0" stroke="#F9716B" stroke-width="2" stroke-miterlimit="10"/>
    </svg>
    )

    const upArrow = 
    (
    <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.999933 7.50329L6.64905 1.85418L12.3333 7.5033" stroke="#4CDAC1" stroke-width="2" stroke-miterlimit="10"/>
    <path d="M6.64868 1.85414L6.64868 15.8541" stroke="#4CDAC1" stroke-width="2" stroke-miterlimit="10"/>
    </svg>
    )
    

    return (

        props.data[props.portfolioId] !== undefined ? 

        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
            <div>

                <div style={{justifySelf: "flex-start"}}>
                    
                    <div className="basier-p4-caps" style={{color: '#6A7E93', display: 'inline-block'}}>Total Profit to date</div>
                    <h2 style={{marginBottom: 0, marginTop:0, color: 'black'}}>
                        {props.currency_symbol}&thinsp;
                        {props.data[props.portfolioId].net_profit.toLocaleString('en', {minimumFractionDigits:2, maximumFractionDigits: 2})}
                    </h2>
                    <p className={`basier-p2 ${props.data[props.portfolioId].net_profit > 0 ? 'growth' : 'decline'}`} style={{marginBottom: 6, marginTop:12}}>
                        { ((props.data[props.portfolioId].percent_change) * 100).toLocaleString('en', {minimumFractionDigits:2, maximumFractionDigits: 2}) }% in {props.inBitcoin === 'bitcoin' ? 'BTC' : props.currency_name}
                    </p>

                </div>

                <div style={{justifySelf: "flex-start", marginTop: 24}}>
                
                    <div className="basier-p4-caps" style={{color: '#6A7E93', display: 'flex', alignItems: 'center'}}>
                        <div>Capital Gains</div>
                        <div style={{marginLeft: 2}}>
                            <Tooltip title="These represent the gross capital gains, not taking fees into account." placement="right">
                                <img style={{height: 15, width: 15}} src={questionMark} alt=""/>
                            </Tooltip>
                        </div>
                    </div>
                    <h2 style={{marginBottom: 0, marginTop:0, color: 'black'}}>
                        {props.currency_symbol}&thinsp;
                        {props.data[props.portfolioId].capital_gains.toLocaleString('en', {minimumFractionDigits:2, maximumFractionDigits: 2})}
                    </h2>

                </div>

                <div style={{justifySelf: "flex-start", marginTop: 36}}>
                
                    <div className="basier-p4-caps" style={{color: '#6A7E93', display: 'flex', alignItems: 'center'}}>
                        <div>Income Only</div>
                        <div style={{marginLeft: 2}}>
                            <Tooltip title="Includes transactions tagged as Income." placement="right">
                                <img style={{height: 15, width: 15}} src={questionMark} alt=""/>
                            </Tooltip>
                        </div>
                    </div>
                    <h2 style={{marginBottom: 0, marginTop:0, color: 'black'}}>
                        {props.currency_symbol}&thinsp;
                        {props.data[props.portfolioId].only_income.toLocaleString('en', {minimumFractionDigits:2, maximumFractionDigits: 2})}
                    </h2>

                </div>
            
            </div>

            <div>

                <div style={{justifySelf: "flex-start"}}>
                    <div className="basier-p4-caps" style={{color: '#6A7E93', display: 'inline-block'}}>Unrealized profit to date</div>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                
                    <span style={{display: 'inline-block'}}>
                        <h2 style={{marginBottom: 0, marginTop:0, color: 'black'}}>
                            <span>{props.currency_symbol}&thinsp;</span>
                            <span>{props.data[props.portfolioId].total_active_profit.toLocaleString('en', {minimumFractionDigits:2, maximumFractionDigits: 2})}</span>
                        </h2>
                        <p className={`basier-p2 ${props.data[props.portfolioId].net_profit > 0 ? 'growth' : 'decline'}`} style={{marginBottom: 6, marginTop:12, color: 'white'}}>
                            -
                        </p>
                    </span>
                    </div>
                </div>

                <div style={{justifySelf: "flex-start", marginTop: 24}}>
                    <div className="basier-p4-caps" style={{color: '#6A7E93', display: 'flex', alignItems: 'center'}}>
                        <div>All Income Received</div>
                        <div style={{marginLeft: 2}}>
                            <Tooltip title="Sum of Income, Dividend and Rewards received." placement="right">
                                <img style={{height: 15, width: 15}} src={questionMark} alt=""/>
                            </Tooltip>
                        </div>
                    </div>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                
                    <span style={{display: 'inline-block'}}>
                        <h2 style={{marginBottom: 0, marginTop:0, color: 'black'}}>
                            <span>{props.currency_symbol}&thinsp;</span>
                            <span>{props.data[props.portfolioId].income_profit.toLocaleString('en', {minimumFractionDigits:2, maximumFractionDigits: 2})}</span>
                        </h2>
                    </span>
                    </div>
                </div>

                <div style={{justifySelf: "flex-start", marginTop: 36}}>
                
                    <div className="basier-p4-caps" style={{color: '#6A7E93', display: 'flex', alignItems: 'center'}}>
                        <div>Dividends / Interest</div>
                        <div style={{marginLeft: 2}}>
                            <Tooltip title="Includes transactions tagged as Dividend/Interest Received." placement="right">
                                <img style={{height: 15, width: 15}} src={questionMark} alt=""/>
                            </Tooltip>
                        </div>
                    </div>
                    <h2 style={{marginBottom: 0, marginTop:0, color: 'black'}}>
                        {props.currency_symbol}&thinsp;
                        {props.data[props.portfolioId].dividend_profit.toLocaleString('en', {minimumFractionDigits:2, maximumFractionDigits: 2})}
                    </h2>

                </div>
            
            </div>

            <div>

                <div style={{justifySelf: "flex-start"}}>
                    <div className="basier-p4-caps" style={{color: '#6A7E93', display: 'inline-block'}}>Realized profit to date</div>
                    <h2 style={{marginBottom: 0, marginTop:0, color: 'black'}}>
                        <span>{props.currency_symbol}&thinsp;</span>
                        <span>{props.data[props.portfolioId].total_realized_profit.toLocaleString('en', {minimumFractionDigits:2, maximumFractionDigits: 2})}</span>
                    </h2>
                    <p className={`basier-p2 ${props.data[props.portfolioId].net_profit > 0 ? 'growth' : 'decline'}`} style={{marginBottom: 6, marginTop:12, color: 'white'}}>
                        -
                    </p>
                </div>

                <div style={{justifySelf: "flex-start", marginTop: 24}}>
                    <div className="basier-p4-caps" style={{color: '#6A7E93', display: 'flex', alignItems: 'center'}}>
                        <div>Paid Fees</div>
                        <div style={{marginLeft: 2}}>
                            <Tooltip title="All fees that have been realized (accounted at time of sale)." placement="right">
                                <img style={{height: 15, width: 15}} src={questionMark} alt=""/>
                            </Tooltip>
                        </div>
                    </div>
                    <h2 style={{marginBottom: 0, marginTop:0, color: 'black'}}>
                        <span>{props.currency_symbol}&thinsp;</span>
                        <span>{props.data[props.portfolioId].realized_fees.toLocaleString('en', {minimumFractionDigits:2, maximumFractionDigits: 2})}</span>
                    </h2>
                </div>

                <div style={{justifySelf: "flex-start", marginTop: 36}}>
                
                    <div className="basier-p4-caps" style={{color: '#6A7E93', display: 'flex', alignItems: 'center'}}>
                        <div>Mining / Rewards</div>
                        <div style={{marginLeft: 2}}>
                            <Tooltip title="Includes transactions tagged as Mining/Rewards." placement="right">
                                <img style={{height: 15, width: 15}} src={questionMark} alt=""/>
                            </Tooltip>
                        </div>
                    </div>
                    <h2 style={{marginBottom: 0, marginTop:0, color: 'black'}}>
                        {props.currency_symbol}&thinsp;
                        {props.data[props.portfolioId].reward_profit.toLocaleString('en', {minimumFractionDigits:2, maximumFractionDigits: 2})}
                    </h2>

                </div>
                
            </div> 

        </div>

        : 

            <div style={{height: 112}}></div> 
        

    )
}


const mapStateToProps = state => {
    return {
        currency_symbol: state.auth.currency.symbol,
        currency_name: state.auth.currency.id,
        inBitcoin: state.data.bitcoinPf, 
    };
};

export default connect(
    mapStateToProps,
    null
)(Summary);