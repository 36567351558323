import React from 'react';
import { connect } from 'react-redux';
import quickIcon from '../../../Media/quickadd-blue.svg'
import manualIcon from '../../../Media/keyboardIcon.svg'
import BaseModalTop from '../../Portfolio/PortfolioComponents/Transactions/BaseModalTop';
import ConnectAccount from '../ModalViews/ConnectAccount';
import AddWallet from '../ModalViews/AddWallet'
import * as actions from "../../../store/actions/interactions";
import Overview from '../../AccountCreation/AccountCreationOverview'

class NewAccount extends React.PureComponent {

    state = {
        welcome: true,
        scope: 'welcome',
        subType: 'exchange'
    }

    handleClose = () => {

        this.props.toggleModal({type: null, open: false, payload: null})
       
      }

    changeScope = (scope) => {
        this.setState(state => ({...state, scope: scope, welcome: false}))
    }

    backToWelcome = () => {
        this.setState(state => ({...state, scope: 'welcome', welcome: true}))
    }

    render () {

        return (
            
            <div>
            
            <BaseModalTop forceSingle={true} title="New account" handleClose={this.handleClose} onClick={() => this.backToWelcome()} welcome={this.state.welcome} entryType={this.state.type_selected} backIcon={true} />

            <Overview origin="wallet" importTo={null} />
        </div>
        )
    }


}

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = dispatch => {
    return {
        toggleModal: (data) => 
            dispatch(actions.toggleModal(data)), 
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(NewAccount)
