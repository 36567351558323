import React from "react";
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import * as actions from "../../../../../store/actions/data";
import StorageGraph from './StorageGraph'
import CapGraph from './CapGraph'

class StatsTable extends React.PureComponent {

    state = {
        graphData: null,
        micro_coins: null,
        low_coins: null,
        mid_coins: null,
        high_coins: null,
        fiat_coins: null,
    }
    componentDidMount() {
        
       this.props.getBalanceRisk(this.props.portfolioId, 'storage')
       this.props.getBalanceRisk(this.props.portfolioId, 'user')

    }

    componentDidUpdate(prevProps) {
        
        if (prevProps.portfolioId !== this.props.portfolioId) {
        this.props.getBalanceRisk(this.props.portfolioId, 'storage')
        this.props.getBalanceRisk(this.props.portfolioId, 'user')

        }

    }

    render() {


        return (

            <Grid 
                container
                justify='space-between'
                spacing={40}
                
                >

                <Grid item lg={6} md={6} sm={6} xl={6} xs={6}
                    style={{
                       // marginRight: 12,
                }}> 

                        <div className="basier" style={{textTransform: 'uppercase', paddingLeft: 12, borderBottom: '1px solid #E1E5E9', fontSize: 16, color: '#6A7E93'}}>
                            Personal Exposure
                        </div>

                    {this.props.userRisk !== null &&  this.props.userRisk !== undefined ? 
                    <div style={{width: '45%', marginTop: 48, marginLeft: 12, height: 400, marginBottom: 48}}>
                        <StorageGraph scope="user" values={this.props.userRisk} />
                    </div>
                    : null}     

                </Grid>
                
                <Grid item lg={6} md={6} sm={6} xl={6} xs={6}
                style={{
                       // marginLeft: 12,
                    }} >

                        <div className="basier" style={{textTransform: 'uppercase', paddingLeft: 12, borderBottom: '1px solid #E1E5E9', fontSize: 16, color: '#6A7E93'}}>
                            Storage Exposure
                        </div>

                    {this.props.balanceRisk !== null &&  this.props.balanceRisk !== undefined ? 
                    <div style={{width: '45%', marginTop: 48, marginLeft: 12, height: 400, marginBottom: 48}}>
                        <StorageGraph scope="storage" values={this.props.balanceRisk} />
                    </div>
                    : <div>Hi</div>}     

                </Grid>

            </Grid>
          
    )
    

    }
}

const mapStateToProps = state => {
    return {
        currency_symbol: state.auth.currency.symbol,
        balanceRisk: state.data.balanceRisk, 
        userRisk: state.data.userRisk, 
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getBalanceRisk: (port, scope) => 
            dispatch(actions.getBalanceRisk(port, scope)), 
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(StatsTable);