import React from 'react';
import { connect } from 'react-redux';
import 'react-table/react-table.css'
import '../TransactionStyle.css'
import TransactionOverview from './LargeTransactionOverview';
import SmallTransactionWrapper from '../Small/SmallTransactionWrapper';
import Hidden from '@material-ui/core/Hidden';
import {withRouter} from 'react-router-dom'; /* MAY NEED TO REMOVE */

class TransactionWrapper extends React.PureComponent {

    state = {
        selectAll: false, 
        checked: {},
    }

    render() {
        return (

           <div>

                <React.Fragment>
                    
                    <Hidden smDown>

                        <TransactionOverview 
                            resetPage={this.props.resetPage}
                            scope={this.props.scope} 
                            movePage={this.props.movePage}
                            getData={(page, skp, filters) => this.props.getData(page, skp, filters)} 
                            getOverallData={this.props.getOverallData}
                            portfolioId={this.props.portfolioId}
                            page={this.props.page}
                            account={this.props.account}
                            ascope={this.props.ascope}
                        />
                    
                    </Hidden>

                    <Hidden mdUp>

                        <SmallTransactionWrapper 
                            refreshAddData={this.props.refreshAddData}
                            portfoliosData={this.props.portfoliosData}
                            resetPage={this.props.resetPage}
                            scope={this.props.scope} 
                            movePage={this.props.movePage}
                            getData={(page, skp, filters) => this.props.getData(page, skp, filters)} 
                            getOverallData={this.props.getOverallData}
                            portfolioId={this.props.portfolioId}
                            page={this.props.page}
                        />


                    </Hidden>
                </React.Fragment>
                

           </div>

        )
    }
  
};

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = dispatch => {
    return {

    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(TransactionWrapper));