import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import BaseModalTop from '../../Portfolio/PortfolioComponents/Transactions/BaseModalTop';
import * as actions from "../../../store/actions/interactions";
import DateTimePicker from 'react-datetime';
import PriceDenom from '../../AppWide/InputExtraSelect';
import axios from 'axios';
import { backendUrl } from '../../../variables';
import * as dataActions from "../../../store/actions/data";
import loadingSpinner from '../../../Media/loadingSpinner.gif'
import fieldValidator from '../../../helpers/validators' 

var moment = require('moment');
require('moment/locale/en-gb');

const renderText = (type) => {
    switch(type) {
        case 'reduce': 
            return 'Reduce'
        case 'add': 
            return 'Add to'
        case 'close': 
            return 'Close'
        default: 
            return null
    }
}

class ResponsiveDialog extends React.Component {

    constructor(props) {
        super(props)
        this.priceTypeRef = React.createRef()
        this.state = {
            data: {
                user_id: this.props.user_id
            },
            scope: 'add',
            type: [],
            loading: false,
            error: false,
            amountOptions: this.props.currencies,
            selectedExchange: null, 
            scopedContracts: null,
            selectedContract: null,
            extraData: {
                date: null,
                amount_coin: 'base',
                pnl_coin: 'target',
            }, 
            quoteTicker: null,
            
            validations: {
                amount: true, 
                price: true, 
                date: true,
            }
        }
    }   

    componentDidMount() {
        const now = new Date()
        const moment_date = moment(now);
        const stateDate = moment_date.local().format("DD/MM/YYYY HH:mm")
        const reduxDate = moment_date.utc().format();
        this.setState(state => ({...state, date: stateDate}))
        this.setDataField('date', reduxDate)
    }

    handleClose = () => {

        this.props.toggleModal({type: null, open: false, payload: null})
       
    }

    validatorUpdate = (field, data) => {
        this.setState(state => ({...state, validation: {...state.validation, [field]: data}}))
    }

    setDataField = (field, data) => {
        this.setState(state => ({...state, extraData: {...state.extraData, [field]: data}}))
    }

    fillToday = () => {

        const now = new Date()
        const moment_date = moment(now);
        const reduxDate = moment_date.utc().format();
        const stateDate = moment_date.local().format("DD/MM/YYYY HH:mm")
        
        this.setState(state => ({...state, date: stateDate}))
        this.setDataField('date', reduxDate)

        this.validatorUpdate('date', true)         

    }

    sumbit = () => {

        let price_val = true;
        let amount_val = true;
        let date_val = true; 
       
        if (this.props.type !== 'close') {
            amount_val = fieldValidator(this.state.extraData.size, ['isNotEmpty'])
            this.setState(state => ({
            ...state, 
            validations: {
                ...state.validations,
                amount: amount_val}
            })
            )
        }

        price_val = fieldValidator(this.state.extraData.price, ['isNotEmpty'])
        this.setState(state => ({
        ...state, 
        validations: {
            ...state.validations,
            price: price_val}
        })
        )

        date_val = fieldValidator(this.state.extraData.date, ['isNotEmpty'])
        this.setState(state => ({
        ...state, 
        validations: {
            ...state.validations,
            date: date_val}
        })
        )

        if (price_val && amount_val && date_val) {

            this.setState(state => ({...state, loading: true}))
            const data = {
                user_id: this.props.user_id, 
                portfolio: this.props.portfolioId, 
                scope: this.props.type, 
                amount: Math.abs(this.state.extraData.size), 
                amount_coin: this.state.extraData.amount_coin, 
                price: Math.abs(this.state.extraData.price),
                pnl: this.state.extraData.pnl,
                pnl_currency: this.state.extraData.pnl_coin,
                date: this.state.extraData.date,
            }


            let  url_call = `${backendUrl}/v1/position/${this.props.positionId}/`

            let headers = { "content-type": "application/json" };
                if (this.props.token !== null && this.props.token !== undefined) {

                    headers['Authorization'] = `Token ${this.props.token}`

                }

                axios({method: 'post', url: url_call, data: data, headers: headers})
                    .then(json => {
                        
                        this.props.componentDataFetch('update', this.props.currentView, this.props.currency, this.props.user_id, this.props.portfolioId, {},{},this.props.inBitcoin, this.props.token)

                        setTimeout(() => {
                            this.handleClose()
                        }, 200);

                    })
                    .catch(e => {
                        this.setState(state => ({...state, loading: false, error: true}))
                    })

            }
        }


    handleChange = (date) => {

        const testStateDate = moment( date, "DD/MM/YYYY HH:mm", true).local()

        if (testStateDate.isValid()) {

            const stateDate = testStateDate.format("DD/MM/YYYY HH:mm")
            this.setState(state => ({...state, date: stateDate}))
            let dateRedux = moment(date);
            if (dateRedux.isValid()) {       
                this.setDataField('date', dateRedux.utc().format())    
                const now = new Date()
                const redDe = Date(dateRedux)
                
                if (date > now) {
                    this.validatorUpdate('date', false)
                    this.setState(state => ({...state, futurDate: true}))    
                } else {
                    this.setState(state => ({...state, futurDate: false})) 
                    this.validatorUpdate('date', true)    
                }
                
            } else {
                
                this.setState(state => ({...state, date: date}))
                this.validatorUpdate('date', false)    
            }

        } else {

            this.setState(state => ({...state, date: date}))
            this.validatorUpdate('date', false)

        }
    }


    
    render() {

    return (

      <div>
        
        <BaseModalTop forceSingle={true} title={`${renderText(this.props.type)} Position`} handleClose={this.handleClose} onClick={null} welcome={null} entryType={null} />

        {this.state.loading && <div style={{zIndex: 900000, height: 375, width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'absolute', backgroundColor: 'white', opacity: 0.8}}>
            <img src={loadingSpinner} alt="" style={{height: 70, width: 70}}  />
        </div>}
        <div style={{padding: 48}}>

            <div style={{height: 375}}>
                <div style={{marginTop: 0, marginBottom: 24, display: 'flex', alignItems: 'center'}}>
                    <div className="basier-p2" style={{marginRight: 12, width: 130, color: 'black', fontWeight: 900}}>Position</div>
                    <div style={{width: 250, display: 'flex', alignItems: 'baseline', marginLeft: 12}} className="basier-p3">
                        {this.props.positionName} | {this.props.positionExchange}
                    </div>
                </div>

                <div style={{marginTop: 0, marginBottom: 24, display: 'flex', alignItems: 'center'}}>
                    
                        <div className="basier-p2" style={{marginRight: 12, width: 130, color: 'black', fontWeight: 900}}>Date</div>
                        <div style={{width: 250, display: 'flex', alignItems: 'baseline', marginLeft: 12}} className="root_datepicker ">
                            <DateTimePicker 
                                onChange={(date) => this.handleChange(date)} 
                                value={this.state.date} 
                                locale="en-gb"
                                closeOnSelect={true}
                                strictParsing={true}
                                inputProps={{className: `select_datepicker basier-p3 ${!this.state.validations.date ? "error-input" : null }`, placeholder: 'DD/MM/YYYY', onBlur: this.handleDateBlur, ref: this.dateRef}}
                            />
                            <div className="basier-p4-caps link-hover"  style={{marginLeft: 12, width: 60,}} onClick={this.fillToday}>Now</div>
                        </div>

                    </div>

                    {['reduce', 'add'].includes(this.props.type) && 

                        <div style={{marginBottom: 24, display: 'flex', alignItems: 'center'}}>
                        <div className="basier-p2" style={{marginRight: 12, width: 130, color: 'black', fontWeight: 900}}>Amount</div>
                        <input onChange={(e) => this.setDataField('size', e.target.value)} value={this.state.extraData.size || ""} type="text" className={`light-input ${!this.state.validations.amount ? "error-input" : null }`} style={{marginLeft: 12, width: 130, borderRadius: '5px 0px 0px 5px'}} />
                        <PriceDenom width={100} innerRef={this.priceTypeRef} handleSelect={(val) => this.setDataField('amount_coin', val)}  selection={this.state.extraData.amount_coin} options={this.state.amountOptions} float='right' />

                        {!this.state.validations.price  &&
                        <div className="basier-p4" style={{fontStyle: 'italic', color: 'red', marginLeft: 40}}>
                            Amount cannot be empty and must be a number (without commas).
                        </div>}

                        </div>
                    
                    }

                    <div style={{marginBottom: 24, display: 'flex', alignItems: 'center'}}>
                        <div className="basier-p2" style={{marginRight: 12, width: 130, color: 'black', fontWeight: 900}}>{this.props.type === 'close' && 'Close'} Price</div>
                        <input onChange={(e) => this.setDataField('price', e.target.value)} value={this.state.extraData.price || ""} type="text" className={`light-input ${!this.state.validations.price ? "error-input" : null }`} style={{marginLeft: 12, width: 230, paddingRight: 75, textAlign: 'right'}} />
                        <span style={{position: 'relative', right: 65, zIndex: 9001}}>{this.props.priceQuote}</span>
                        {!this.state.validations.price  &&
                        <div className="basier-p4" style={{fontStyle: 'italic', color: 'red'}}>
                            Price cannot be empty and must be a number (without commas).
                        </div>}
                    </div>

                    {['reduce', 'close'].includes(this.props.type) && 
                        
                        <div>
                            <div style={{marginBottom: 6, display: 'flex', alignItems: 'center'}}>
                            <div className="basier-p2" style={{marginRight: 12, width: 130, color: 'black', fontWeight: 900}}>Realized P&L</div>
                            <input onChange={(e) => this.setDataField('pnl', e.target.value)} value={this.state.extraData.pnl || ""} type="text" className="light-input" style={{marginLeft: 12, width: 130, borderRadius: '5px 0px 0px 5px'}} />
                            <PriceDenom width={100} innerRef={this.priceTypeRef} handleSelect={(val) => this.setDataField('pnl_coin', val)}  selection={this.state.extraData.pnl_coin} options={this.props.currencies} float='right' />

                            </div>
                            <div style={{color: '#0078DB', fontStyle: 'italic', marginBottom: 24}} className="basier-p4">
                            We recommend you enter the actual realized P&L on the position as it will include all transaction costs and fees. If you do not enter the P&L we will calculate a gross profit or loss based on your entry and exit prices, but it will likely overstate your performance. 
                            </div>
                        </div>
                    }
                
                
                <div style={{marginTop: 24}}>
                    {this.state.error && <div style={{color: 'red'}} className="basier-p4">An error occured. Please check your entries and try again</div>}
                </div>

        </div>

        <div style={{display: 'flex', alignItems: 'center', marginTop: 24, justifyContent: 'center', width: '100%', marginRight: 12}}>
                <div className="basier-p3 opacity-hover" style={{fontWeight: 900, height: 40, backgroundColor: '#F9716B', width: 150, color: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 20, marginRight: 12, cursor: 'pointer'}} onClick={this.state.loading ? null : () => this.handleClose()}>
                    Cancel
                </div>

                <div className="basier-p3 opacity-hover" style={{fontWeight: 900, height: 40, backgroundColor: '#0078DB', width: 150, color: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 20, marginRight: 12, cursor: 'pointer'}} onClick={this.state.loading ? null : () => this.sumbit(this.state.type[0])}>
                    {this.state.scope === 'edit' ? 'Update' : 'Submit'}
                </div>
            </div>   

        </div>
            
        </div>

    )
  }
}

ResponsiveDialog.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
};


const mapStateToProps = state => {
    return {
        user_id: state.auth.user_id, 
        token: state.auth.token, 
        currentView: state.data.currentView,
        currency: state.auth.currency.id,
        graphData: state.data.globalBalance,
        inBitcoin: state.data.bitcoinPf, 
        all_coins: state.appwide.all_coins, 
    };
};

const mapDispatchToProps = dispatch => {
    return {
        toggleModal: (data) => 
            dispatch(actions.toggleModal(data)), 
        componentDataFetch: (scope, view, currency, user, portfolio, globalGraphData, portfolioGraphData, inBitcoin, tokenProvided) =>
            dispatch(dataActions.componentDataFetch(scope, view, currency, user, portfolio, globalGraphData, portfolioGraphData, inBitcoin, tokenProvided)), 
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ResponsiveDialog);
