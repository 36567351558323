import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Slide from '@material-ui/core/Slide';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import SwipeFlat from './MobileSwipeFlat'

function Transition(props) {
    return <Slide direction="left" {...props} />;
  }
  
  const drawerWidth = '100%';
  const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);
  
  
  const styles = theme => ({
    list: {
      width: 250,
    },
    fullList: {
      width: 'auto',
    },
    drawer: {
      [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    root: {
      backgroundColor: "#fff",
      zIndex: 9000000,
      display: 'block',
        "&:hover": {
            //you want this to be the same as the backgroundColor above
            backgroundColor: "#fff"
        }, 
      },
    drawerPaper: {
      width: drawerWidth,
      backgroundColor: '#fff',
      "&:hover": {
        //you want this to be the same as the backgroundColor above
        backgroundColor: "#fff"
    }, 
    },
  
  });
  

class FullScreenDialog extends React.Component {

  openModal = this.props.openModal

  componentDidMount() {
    document.body.ontouchmove = (e) => { 
      e.preventDefault(); 
    }
  }

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <SwipeableDrawer
          anchor="right"
          open={this.props.openModal}
          onClose={this.props.closeModal}
          onOpen={this.props.openModal}
          disableBackdropTransition={!iOS} 
          disableDiscovery={true}
          disableSwipeToOpen={true}
          hysteresis={0.05}
          classes={{
            paper: classes.drawerPaper,
            root: classes.root
          }}
        >
          <div
            tabIndex={0}
            role="button"
          >
            <div>
                <SwipeFlat data={this.props.data} handleSelect={(val) => this.props.handleSelect(val)} closeAction={this.props.closeModal} />
            </div>
          </div>
        </SwipeableDrawer>
      </div>
    );
  }
}

FullScreenDialog.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FullScreenDialog);