import React from 'react';
import { connect } from 'react-redux';
import DateSelector from './DateSelector';
import * as appwideActions from "../../../../../../store/actions/appwide";
import EntryLine from './EntryLine';
import CoinSelector from './CoinSelector';
import ExchangeSelector from './ExchangeSelector';
import InputQuestion from './InputQuestion';
import SourceOfFunds from './SourceOfFunds';
import TransactionTypeSelect from './TransactionTypeSelect';
import ExchangeAccountQuestion from './ExchangeAccountQuestion';
import WalletQuestion from './WalletQuestion';
import UserSelect from './UserSelect';
import fieldValidator from '../../../../../../helpers/validators' 
import { backendUrl } from '../../../../../../variables';
import {loadData} from '../../../../../../helpers/helpers';
//import warningIcon from '../../../../../../Media/warning.svg';
//import Switch from '../../../../../Assets/Switch';
import SubmitForm from './SubmitForm';
import DestinationType from './DestinationType';
import DetailWalletList from '../QuestionBlocks/DetailWalletList';
import loadingSpinner from '../../../../../../Media/loadingSpinner.gif'
import okIcon from '../../../../../../Media/okIcon.svg'
import errorIcon from '../../../../../../Media/errorIcon.svg'
import _ from "lodash"
import axios from 'axios'
import currencyDict from '../../../../../../helpers/currency.json'

const getValue = (dict, value) => {

    let ret = null; 
    try { ret = dict[value]} catch(e) {}
    return ret

}

const getNumber = (dict, value) => {

    let ret = 0; 
    try { ret = dict[value]} catch(e) {}
    if (ret === null || ret === undefined || ret === '') {
        ret = 0
    }
    return ret

}

const formatNumber = (num) => {
    let ret = null; 
    const digits = 6
    try {
        ret = num.toLocaleString('en', {minimumFractionDigits: digits, maximumFractionDigits: digits})
    } catch(e) {}
    return ret
}



class ShortTransactionEntry extends React.PureComponent {

    constructor(props) {
        super(props);

        this.baseRef = React.createRef();
        this.amountRef = React.createRef();
        this.feeCurrencyRef = React.createRef();
        this.firstTypeRef = React.createRef();
        this.transitionBackToType = React.createRef();
        this.priceRef = React.createRef();
        this.priceTypeRef = React.createRef();
        this.feeRef = React.createRef();
        this.userSelectRef = React.createRef();
        this.exchangeRef = React.createRef();
        this.ExchangeAccountRef = React.createRef();
        this.backFromSubmit = React.createRef();
        this.firstSubmitRef = React.createRef();
        this.originRef = React.createRef();
        this.txHashRef = React.createRef();
        this.walletRef = React.createRef();
        this.miningCostRef = React.createRef();
        this.flatWalletRef = React.createRef();
        this.destinationUser = React.createRef();

        this.initialState = {
            review: false,
            activeStep: 0,
            addCost: false,
            storageBuy: false,
            submitStep: false,
            transaction_id: null,
            selected_base: null,
            selected_quote: null,
            selected_exchange: null,
            loading: false, 
            completed: false,
            error: false,
            tr_date: null,
            feeInclude: true,
            priceDisplay: null,
            portfolioCurrency: null, 
            priceType: 'unit',
            origin: 'exchange',
            coinPrices: {},
            coinBalances: {},
            deduct: true,
            invalidBalance: false,
            validations: {
                base_currency: true, 
                description: true, 
                quote_currency: true, 
                mining_currency: true, 
                amount: true, 
                unit_price: true, 
                date: true, 
                user_transaction: true, 
                fee_currency: true, 
                fee: true,
                storageName: true,
                accountName: true, 
                tid: true,
                mining_cost: true,
            },
            transactionData: {
                user_transaction: this.props.user_id,
            },
        }

        if (props.scope === 'new') {
            this.state = {...this.initialState}
        } else {

            let exchange = undefined;
            let selectedBase = null;
            let selectedQuote = null;
            let baseC = this.props.existingData.base_currency;
            let quoteC = this.props.existingData.quote_currency;
            let feeC = this.props.existingData.fee_currency;
            let account = null; 
            let storage = this.props.existingData.storage;

            try {baseC = {...this.props.existingData.base_currency, value: this.props.existingData.base_currency.id}} catch(e) {} 
            try {quoteC = {...this.props.existingData.quote_currency, value: this.props.existingData.quote_currency.id}} catch(e) {} 
            try {feeC = {...this.props.existingData.fee_currency, value: this.props.existingData.fee_currency.id}} catch(e) {} 

            try {
                selectedBase = {
                    coinName: this.props.existingData.base_currency.name, 
                    coinTicker: this.props.existingData.base_currency.symbol,
                    coinType:  this.props.existingData.base_currency.asset_type,
                    image: this.props.existingData.base_currency.image_large,
                    value: this.props.existingData.base_currency.id
                }
            } catch(e) {

            }

            try {
                selectedQuote = {
                    coinName: this.props.existingData.quote_currency.name, 
                    coinTicker: this.props.existingData.quote_currency.symbol,
                    coinType:  this.props.existingData.quote_currency.asset_type,
                    image: this.props.existingData.quote_currency.image_thumb,
                    value: this.props.existingData.quote_currency.id
                }
            } catch(e) {

            }


            try { 
                if (this.props.existingData.account_data.custom_exchange_check === true) {
                    exchange = {
                        custom: true,
                        value: this.props.existingData.account_data.custom_exchange.trim().toLowerCase(), 
                        image: null, 
                        name: this.props.existingData.account.custom_exchange
                    } 
                } else {
                    exchange = {
                        custom: this.props.existingData.account.exchange.custom, 
                        value: this.props.existingData.account.exchange.id, 
                        image: this.props.existingData.account.exchange.image, 
                        name: this.props.existingData.account.exchange.name
                    } 
                }
            } 
            catch(e) {}

            try { account = this.props.existingData.account.id } catch(e) {}
            try { storage = storage.id } catch(e) {}
            let dest_type = storage !== null && storage !== undefined ? 'wallet' : 'exchange'

            const newTxData = {
                ...this.initialState.transactionData, 
                ...this.props.existingData,
                user_transaction: this.props.existingData.user_transaction.id,
                exchange: exchange, 
                account: account, 
                storage: storage,
                date: this.props.existingData.reduxDate,
                base_currency: baseC, 
                quote_currency: quoteC, 
                fee_currency: feeC, 
            }

            this.initialState = {
                ...this.initialState, 
                selected_base: selectedBase, 
                selected_quote: selectedQuote,
                origin: dest_type,
                tr_date: this.props.existingData.date,
                transactionData: newTxData, 
            }

            this.state = {...this.initialState}
            this.oldData = _.cloneDeep(this.initialState)
        }

    }
      
    componentDidMount() {
        
        this.props.getFlatWallets(this.props.user_id)
        const portfolioId = this.props.overridePortId !== undefined ? this.props.overridePortId : this.props.currentPortfolio
        this.setState(state => ({...state, portfolioId: portfolioId}))
        let portfolioData = undefined;
        let portCurrency = 'USD'
        try {portfolioData = this.props.list_portfolios.filter(v => v.id == portfolioId)[0]} catch(e) {}
        if (portfolioData !== undefined) {
            try { portCurrency = portfolioData['currency'] } catch(e) {}
        }
        this.setState(state => ({...state, portfolioCurrency: portCurrency}))
    }


    resetState = () => {
        this.setState(this.initialState)
    }
    
    toggleStorageBuy = () => {
        this.setState(state => ({...state, storageBuy: !state.storageBuy}))
    }
 
    updateTransaction = (label, data) => {
          this.setState(state => ({...state, transactionData: {...state.transactionData, [label]: data}}));
    }

    updateTransfer = (label, data) => {
        if (label === "user_transaction") {
            //update flat wallet list
        }
        this.updateTransaction(label, data)
    }

    updateInputTransaction = (label, data) => {
        const old = this.state.transactionData[label]
        this.updateTransaction(label, data)

        if (label === 'amount' || label === 'unit_price' || label === 'fee') {
            if (old !== data) {
                this.initiateBalanceCheck(label, data)
            }     
        } 
    }

    setTrTime = (data) => {
          this.setState(state => ({...state, tr_date: data}))
    }
    
      
    removeWallets = () => {

    }

    updateState = (label, value) => {
          this.setState(state => ({...state, [label]: value }))
    }

    getRef = async (ref) => {
        return ref;
    }

    getAmountRef = (ref) => {
        const ref_needed = this.getRef(this.amountRef)
        
        ref_needed
        .then(res => {

            try {res.current.focus({preventScroll: true})} catch(e) {}
            
            })

    }


    handleClose = () => {
        this.props.handleClose()
    }

    getTargetRef = (ref) => {

        const ref_needed = this.getRef(ref)
        
        ref_needed
        .then(res => {

            try {res.current.focus({preventScroll: true})} catch(e) {}
            
        })

    }

    getTargetInnerRef = (ref) => {

        const ref_needed = this.getRef(ref)
        
        ref_needed
        .then(res => {

            try {res.current.myInput.focus({preventScroll: true})} catch(e) {}
            
        })

    }

    focusBase = () => {

        const ref_needed = this.getRef(this.baseRef)
        
        ref_needed
        .then(res => {

            try {res.current.myInput.focus({preventScroll: true})} catch(e) {}
            
            })
    }

    switchSource = () => {

        this.setState(state => ({...state, deduct: !state.deduct}))

    }

    getFirstTypeRef = () => {

        const ref_needed = this.getRef(this.firstTypeRef)
        
        ref_needed
        .then(res => {

            try {res.current.focus({preventScroll: true})} catch(e) {}
            
            })

        this.setState(state => ({...state, activeType: true}))

    }

    toggleActivate = () => {
        this.setState(state => ({...state, activeType: false}))
    }
      

    validatorUpdate = (label, validState) => {

        this.setState(state => ({
          ...state, 
          validations: {
            ...state.validations,
            [label]: validState}
          })
        )

    }

    backToAmount = (e) => {
          
        e.persist();
        e.preventDefault();
        
        
        const isShift = !!e.shiftKey;
        if (e.keyCode === 13 || e.keyCode === 9 || e.key === "Enter" || e.key === "Tab") {

            if (isShift && e.key === "Tab") {

                
                this.getAmountRef()

            } else {
                // go to the quote / other next input 
            }
        }
    }

    goToSubmit = () => {
        this.getTargetRef(this.firstSubmitRef)
        this.setState(state => ({...state, submitStep: true}))
    }

    handleDisplayPrice = (label, value) => {
        
        if (label === 'amount') {

            const transaction = this.state.transactionData.transaction_description
            if (transaction !== null && transaction !== undefined) {

                if (this.baseBalance.includes(transaction)) {

                    const valpass = parseFloat(value)
                    const value_check = isNaN(valpass) ? null : valpass
                    if (this.state.transactionData.base_currency !== null && this.state.transactionData.base_currency !== undefined ) {
                        this.balanceCheck(parseFloat(value_check), this.state.coinBalances[this.state.transactionData.base_currency.value], this.state.transactionData.unit_price, this.state.feeInclude, this.state.transactionData.fee)
                    }


                } else {

                    const valpass = parseFloat(value)
                    const value_check = isNaN(valpass) ? null : valpass
                    if (this.state.transactionData.quote_currency !== null && this.state.transactionData.quote_currency !== undefined ) {
                        this.balanceCheck(parseFloat(value_check), this.state.coinBalances[this.state.transactionData.quote_currency.value], this.state.transactionData.unit_price, this.state.feeInclude, this.state.transactionData.fee)
                    }
                    

                }

            }
            

            let priceSend = null;
            if (this.state.transactionData['unit_price'] !== null && this.state.transactionData['unit_price'] !== undefined) {
                
                if (this.state.priceType === 'unit') {
                    priceSend = this.state.transactionData['unit_price'] * parseFloat(value)
                    } else {
                    priceSend = this.state.transactionData['unit_price'] / parseFloat(value)
                    }
                }

            this.updateState('priceDisplay', priceSend)

        } else if (label === 'unit_price') {

            const transaction = this.state.transactionData.transaction_description
            if (transaction !== null && transaction !== undefined) {

                if (this.baseBalance.includes(transaction)) {

                    const valpass = parseFloat(this.state.transactionData.amount)
                    const value_check = isNaN(valpass) ? null : valpass
                    if (this.state.transactionData.base_currency !== null && this.state.transactionData.base_currency !== undefined ) {
                        this.balanceCheck(parseFloat(value_check), this.state.coinBalances[this.state.transactionData.base_currency.value], this.state.transactionData.unit_price, this.state.feeInclude, this.state.transactionData.fee)
                    }


                } else {

                    const valpass = parseFloat(this.state.transactionData.amount)
                    const value_check = isNaN(valpass) ? null : valpass
                    if (this.state.transactionData.quote_currency !== null && this.state.transactionData.quote_currency !== undefined ) {
                        this.balanceCheck(parseFloat(value_check), this.state.coinBalances[this.state.transactionData.quote_currency.value], value, this.state.feeInclude, this.state.transactionData.fee)
                    }
                    

                }

            }
            

            let priceSend = null;
            if (this.state.priceType === 'unit') {
                priceSend = parseFloat(value) * this.state.transactionData['amount']
            } else {
                priceSend = parseFloat(value) / this.state.transactionData['amount']
            }

            this.updateState('priceDisplay', priceSend)

        }
    }

    handlePostTypePrice = (e) => {


        this.getFirstTypeRef()
        
    }

    getPriceRef = (e) => {

        
        const ref_needed = this.getRef(this.priceRef)
        
        ref_needed
        .then(res => {

            try {res.current.focus({preventScroll: true})} catch(e) {}
            
            })


    }

    updateCoinTransaction = (label ,data) => {
        this.updateTransaction(label ,data)

        if (data !== null && data !== undefined) {
            let get_coins = false
            let coins = ''
            if (label === 'base_currency') {
                this.getCoinBalance(data.value, this.state.transactionData.date, false)
                let existingbase = undefined;
                try { existingbase = this.state.transactionData.base_currency.value } catch(e) {}
                if (data.value !== existingbase) {
                    this.setState(state => ({...state, priceAtDate: null}))
                    get_coins = true; 
                    coins = `${data.value},${this.state.portfolioCurrency}`
                }
                
            } else if (label === 'quote_currency') {

                if (data.value !== this.state.portfolioCurrency) {

                    let existingquote = undefined;
                    try { existingquote = this.state.transactionData.quote_currency.value } catch(e) {}
                    if (data.value !== existingquote) {
                        this.setState(state => ({...state, priceAtDate: null}))
                        get_coins = true; 
                        coins = `${data.value}`
                    }
                } else if (label === 'quote_currency' && data.value === this.state.portfolioCurrency) {
                    this.updateCoinPrice(this.state.transactionData.transaction_description, data.value)
                }

                if (['buy', 'mining'].includes(this.state.transactionData.transaction_description)) {
                    if (this.state.coinBalances[data.value] === undefined) {
                        this.getCoinBalance(data.value, this.state.transactionData.date, true)
                    } else {
                        this.balanceCheck(this.state.transactionData.amount, this.state.coinBalances[data.value], this.state.transactionData.unit_price, this.state.feeInclude, this.state.transactionData.fee)
                    }
                } else {
                    this.getCoinBalance(data.value, this.state.transactionData.date, false)
                }

            }

            if (get_coins) {
                this.getCoinPrice(coins, label === "quote_currency")
            }
        
        }
    }


    getCoinPrice = (coins, update, date) => {


        let urlCall = `${backendUrl}/v1/asset/price/?u=${this.props.user_id}&coins=${coins}&date=${this.state.transactionData.date}`
        
        if (date !== undefined) {
            urlCall = `${backendUrl}/v1/asset/price/?u=${this.props.user_id}&coins=${coins}&date=${date}`
        }

        let headers = { "content-type": "application/json" };
        if (this.props.token !== null && this.props.token !== undefined) {

            headers['Authorization'] = `Token ${this.props.token}`

        }

        const data_fetch = loadData(urlCall, headers)
        data_fetch
            .then(json => {

                const coins = json['data'];
                const newCoins = {
                    ...this.state.coinPrices,
                    ...coins
                }
                this.setState(state => ({...state, coinPrices: newCoins}))
                if (update === true) {
                    this.updateCoinPrice(this.state.transactionData.transaction_description)
                }

            })
            .catch(e => {
                console.log('error with price', e)
            })

    }

    getCoinBalance = (coins, date, update) => {

        let urlCall = `${backendUrl}/v1/portfolio/balances/${this.state.portfolioId}/?u=${this.props.user_id}&coin=${coins}&date=${this.state.transactionData.date}`
        
        if (date !== undefined) {
            urlCall = `${backendUrl}/v1/portfolio/balances/${this.state.portfolioId}/?u=${this.props.user_id}&coin=${coins}&date=${date}`
        }

        let headers = { "content-type": "application/json" };
        if (this.props.token !== null && this.props.token !== undefined) {

            headers['Authorization'] = `Token ${this.props.token}`

        }

        const data_fetch = loadData(urlCall, headers)
        data_fetch
            .then(json => {

                const data = json['data'];
                const newCoins = {
                    ...this.state.coinBalances,
                    ...data
                }
                this.setState(state => ({...state, coinBalances: newCoins}))
                if (update === true) {
                    this.balanceCheck(this.state.transactionData.amount, data.balance, this.state.transactionData.unit_price, this.state.feeInclude, this.state.transactionData.fee)
                }

            })
            .catch(e => {
                console.log('error with price', e)
            })

    }

    initiateBalanceCheck = (type, field) => {
        let scopeCoin =  null;
        if (this.quoteBalance.includes(this.state.transactionData.transaction_description)) {
            scopeCoin = getValue(this.state.transactionData.quote_currency, 'value')
        } else if (this.baseBalance.includes(this.state.transactionData.transaction_description)) {
            scopeCoin = getValue(this.state.transactionData.base_currency, 'value')
        }


        if (scopeCoin !== null && field !== null && field !== undefined && field !== '') {

            if (type === 'amount') {
                this.balanceCheck(field, this.state.coinBalances[scopeCoin], this.state.transactionData.unit_price, this.state.feeInclude, this.state.transactionData.fee, this.state.priceType)
            } else if (type === 'unit_price') {
                this.balanceCheck(this.state.transactionData.amount, this.state.coinBalances[scopeCoin], field, this.state.feeInclude, this.state.transactionData.fee, this.state.priceType)
            } else if (type === 'fee') {
                this.balanceCheck(this.state.transactionData.amount, this.state.coinBalances[scopeCoin], this.state.transactionData.unit_price, this.state.feeInclude, field, this.state.priceType)
            } else if (type === 'forceFee') {
                this.balanceCheck(this.state.transactionData.amount, this.state.coinBalances[scopeCoin], this.state.transactionData.unit_price, field, this.state.transactionData.fee, this.state.priceType)
            } else if (type === 'priceType') {

                this.balanceCheck(this.state.transactionData.amount, this.state.coinBalances[scopeCoin], this.state.transactionData.unit_price, this.state.feeInclude, this.state.transactionData.fee, field)
            }

            

        } else {
            this.setState(state => ({...state, invalidBalance: false})) 
        }

        
    }

    toggleForceFee = () => {
        const newToggle = !this.state.feeInclude
        this.initiateBalanceCheck('forceFee', newToggle)
        this.setState(state => ({...state, feeInclude: newToggle}))
    }

    balanceCheck = (amount, balance, changedPrice, forceFeeInclude, forcefee, forcePriceType) => {
        if (forceFeeInclude === undefined) {
            forceFeeInclude = this.state.feeInclude 
        }

        if (forcePriceType === undefined) {
            forcePriceType = this.state.priceType
        }

        if (this.baseBalance.includes(this.state.transactionData.transaction_description) && this.state.transactionData.transaction_description !== undefined && this.state.transactionData.transaction_description !== null ) {
            if (amount === null || amount === undefined) {
                this.setState(state => ({...state, invalidBalance: false}))
                return 
            }

            if ( (balance !== null && balance !== undefined)) {

                let feeUse = 0;
                if (forceFeeInclude) {
                    if (forcefee === undefined) {
                        if (this.state.transactionData.fee !== undefined && !isNaN(parseFloat(this.state.transactionData.fee))) {
                            feeUse = Math.abs((parseFloat(this.state.transactionData.fee)))
                        } else {
                            feeUse = 0
                        }
                    } else {
                        if (forcefee !== undefined && !isNaN(parseFloat(forcefee))) {
                            feeUse = Math.abs((parseFloat(forcefee)))
                        } else {
                            feeUse = 0
                        }
                    }
                }

                if (forceFeeInclude && feeUse !== null && forcefee !== undefined && forcefee !== '' ) {
                    amount = parseFloat(amount) + feeUse
                }

                if ( Math.abs(parseFloat(amount) / parseFloat(balance)) > 1 ) {
                    this.setState(state => ({...state, invalidBalance: true}))
                } else {
                    this.setState(state => ({...state, invalidBalance: false}))
                }
            }
        }

        else if (this.quoteBalance.includes(this.state.transactionData.transaction_description)) {

            let unit_price = this.state.transactionData.unit_price
            if (changedPrice !== undefined) {
                unit_price = changedPrice
            }

            if (unit_price !== null && unit_price !== undefined && unit_price !== '') {

                if (forcePriceType === 'unit') { 

                    if (amount === null || amount === undefined) {
                        this.setState(state => ({...state, invalidBalance: false}))
                        return 
                    }
                    if (balance !== null && balance !== undefined) {

                        if ( Math.abs(( parseFloat(amount) * parseFloat(unit_price) ) / parseFloat(balance))  > 1 )  {
                            this.setState(state => ({...state, invalidBalance: true}))
                        } else {
                            this.setState(state => ({...state, invalidBalance: false}))
                        }
                    }


                } else {

                    if (amount === null || amount === undefined) {
                        this.setState(state => ({...state, invalidBalance: false}))
                        return 
                    }
        
                    if ( (balance !== null && balance !== undefined) ) {
                        if ( Math.abs(parseFloat(unit_price) / parseFloat(balance)) > 1 ) {
                            this.setState(state => ({...state, invalidBalance: true}))
                        } else {
                            this.setState(state => ({...state, invalidBalance: false}))
                        }
                    }

                }

            }


        }

    }

    roundPrice = (price) => {
        let updatePrice = price
                
        if (price > 1) {
            updatePrice = Math.round(price * 100) / 100
        } else {
            updatePrice = Math.round(price * 1000000) / 1000000
        }

        return updatePrice
    }

    updateCoinPrice = (val, forceQuote) => {
        
        if (val === undefined) {
            this.setState(state => ({...state, priceAtDate: null}))
            return
        }
        let updatePrice = null;

        if (this.pricePortfolioQuote.includes(val)) {
            let price = undefined; 
            try { price = this.state.coinPrices[this.state.transactionData.base_currency.value] / this.state.coinPrices[this.state.portfolioCurrency] } catch(e) {}
            if (price !== undefined) {
                updatePrice = this.roundPrice(price)
                this.setState(state => ({...state, priceAtDate: updatePrice, transactionData: {...state.transactionData, unit_price: updatePrice} }))    
            } else {
                this.setState(state => ({...state, priceAtDate: null })) 
            }
            
        } else if (this.quoteAndPrice.includes(val) && ((this.state.transactionData.quote_currency !== undefined && this.state.transactionData.quote_currency !== null) || forceQuote !== undefined) ) {
            let price = undefined; 
            const quote_getter = forceQuote !== undefined ? forceQuote : this.state.transactionData.quote_currency.value
            try { price = this.state.coinPrices[this.state.transactionData.base_currency.value] / this.state.coinPrices[quote_getter] } catch(e) { console.log('err', e)}
            if (price !== undefined) {
                updatePrice = this.roundPrice(price)
                this.setState(state => ({...state, priceAtDate: updatePrice }))  
            } else {
                this.setState(state => ({...state, priceAtDate: null }))  
            }
        }

        return updatePrice

    }

    datePriceUpdate = (date) => {
        const base = this.state.transactionData.base_currency
        const quote = this.state.transactionData.quote_currency
        const update = ( (base !== undefined && base !== null) && ( ( (quote !== undefined && quote !== null) && (this.quoteAndPrice.includes(this.state.transactionData.transaction_description)) ) || (this.pricePortfolioQuote.includes(this.state.transactionData.transaction_description)) ) )

        this.setState(state => ({...state, coinPrices: {}, priceAtDate: null}))
        const scope = [base, quote, {value: this.state.portfolioCurrency}]
        let fetch = []
        scope.map(v => {
            if (v !== undefined && v !== null && !fetch.includes(v)) {
                fetch.push(v.value)
            }
        })

        if (fetch.length > 0) {
            const string = fetch.join(',')
            this.getCoinPrice(string, update, date)
        }

        this.setState(state => ({...state, coinBalances: {}}))
        const balanceCoins = [base, quote]
        let fetchBal = []
        balanceCoins.map(v => {
            if (v !== undefined && v !== null && !fetchBal.includes(v)) {
                fetchBal.push(v.value)
            }
        })

        if (fetchBal.length > 0) {
            const string = fetchBal.join(',')
            this.getCoinBalance(string, date, update)
        }



        

    }

    handleBackFromSubmit = (e) => {
        e.preventDefault();
        
        const isShift = !!e.shiftKey
        if (isShift && (e.key === "Tab" || e.keyCode === 9)) {
            this.getTargetRef(this.backFromSubmit)
        }

    }

    insertPrice = (e, price) => {

        let insert = price
        if (price === undefined) {
            insert = this.state.priceAtDate
        }
        this.setState(state => ({...state, priceType: 'unit', transactionData: {...state.transactionData, unit_price: insert}}))
    }

    postTypeSelect = (val, clear) => {

        
        if (clear) {

            this.updateTransaction('fee', undefined)
            this.updateTransaction('fee_currency', undefined)
            this.updateTransaction('exchange', undefined)
            this.updateTransaction('user_transaction', this.props.user_id)
            this.updateTransaction('priceType', 'unit')
            this.updateTransaction('account', null)
            this.updateTransaction('account_name', undefined)
            this.updateTransaction('quote_currency', undefined)
            this.updateTransaction('fee_currency', undefined)
            this.updateTransaction('storage', undefined)
            this.updateTransaction('storage_name', undefined)
            this.updateTransaction('mining_currency', undefined)
            this.updateTransaction('tid', undefined)
            this.updateTransaction('from_wallet', undefined)
            this.updateTransaction('user_wallet_transfer', undefined)
            

            this.updateState('origin', 'exchange')
            this.updateState('deduct', true)
            this.updateState('feeInclude', true)
            this.updateState('priceDisplay', null)
            this.updateState('selected_exchange', null)
            this.updateState('selected_quote', null)
            this.updateState('addCost', null)

        }

        if (this.quoteAndPrice.includes(val)) {
            if (clear) {
                this.updateCoinPrice(val)
                this.insertPrice(null, null)
            }
        } else if (this.pricePortfolioQuote.includes(val)) {
            const price = this.updateCoinPrice(val)
            if (price !== null && price !== undefined) {
                //this.insertPrice(null, price)
            }
        }

        if (this.baseBalance.includes(val) && this.state.transactionData.base_currency !== undefined) {
            if (this.state.coinBalances[this.state.transactionData.base_currency.value] === undefined) {
                this.getCoinBalance(this.state.transactionData.base_currency.value, this.state.transactionData.date, true)
            }
        } else if (this.quoteBalance.includes(val) && this.state.transactionData.quote_currency !== undefined) {
            if (this.state.coinBalances[this.state.transactionData.quote_currency.value] === undefined) {
                this.getCoinBalance(this.state.transactionData.quote_currency.value, this.state.transactionData.date, true)
            }
        }
    }   


    handlePriceType = (val) => {
        this.setState(state => ({...state, priceType: val}));
        let priceSend = null;
        if(this.state.transactionData['unit_price'] !== null && this.state.transactionData['unit_price'] !== undefined && this.state.transactionData['amount'] !== null && this.state.transactionData['amount'] !== undefined && this.state.transactionData['amount'] !== 0 ) {
            if (val === 'unit') {
                priceSend = this.state.transactionData['unit_price'] * this.state.transactionData['amount'];
            } else {
                priceSend = this.state.transactionData['unit_price'] / this.state.transactionData['amount'];
            }
        }
        this.setState(state => ({...state, priceDisplay: priceSend}));
        this.initiateBalanceCheck('priceType', val)
    }

    toggleReview = () => {
        this.setState(state => ({...state, review: !state.review}))
    }

    getTransitionBackToType = () => {

        const ref_needed = this.getRef(this.transitionBackToType)

        ref_needed
        .then(res => {
            
            if (this.quoteAndPrice.includes(this.state.transactionData.transaction_description)) {
                try {res.current.myInput.focus({preventScroll: true})} catch(e) {}
            } else {
                try {res.current.focus({preventScroll: true})} catch(e) {}
            }
            
                /* try {res.current.myInput.focus({preventScroll: true})} catch(e) {} */
            
            })


    }

    handleNumberValidation = (field, number) => {

        let amount_val = true; 
        amount_val = fieldValidator(number, ['isNotEmpty', 'isPositive'])
        this.setState(state => ({
          ...state, 
          validations: {
            ...state.validations,
            [field]: amount_val}
          })
        
          )

        return amount_val

    }

    submitNewTransaction = () => {



    }

    updateAction = (data) => {
    
        //console.log(this.state)
        this.setState(state => ({...state, loading: true}))
        let headers = { "content-type": "application/json" };
        if (this.props.token !== null && this.props.token !== undefined) {
    
            headers['Authorization'] = `Token ${this.props.token}`
    
        }
    
        const url_call = `${backendUrl}/v1/transaction_detail/${this.props.editTransactionId}`
        
        axios.patch(url_call, data, {headers: headers})
            .then(json => {
                
                this.setState(state => ({...state, loading: false, completed: true}))
                
                this.props.setDetailTr(json['data']['data'])
                this.props.getEditData(json['data']['data'])
                this.props.getOverallEditData()
                this.props.getWallets(this.props.user_id)
                this.props.getUserLimits(this.props.user_id)
    
    
                setTimeout(() => {
    
                  this.handleClose()
      
                }, 300)
    
            })
            .catch( e => {
    
              this.setState(state => ({...state, loading: false, error: true}))
    
            })
    }

    quoteBalance = ['buy', 'ico']
    baseBalance = ['withdraw', 'sell', 'fee', 'lost', 'donation', 'purchase']
    quoteAndPrice = ['buy', 'sell', 'ico']
    pricePortfolioQuote = ['deposit', 'withdraw', 'dividend', 'income', 'gift', 'airdrop', 'fork', 'purchase', 'donation']
    feeOnly = ['mining']
    transferOnly = ['transfer']

    render () {

        let destinationAccount, destinationExchange, destinationWallet, destinationType;
        if (this.state.transactionData.transaction_description == "transfer") {
            try {
                destinationType = this.state.transactionData.destination_account ? "exchange" : this.state.transactionData.destination_storage ? "storage" : null;
            } catch(e) {}

            if (destinationType === "exchange") {
                destinationAccount = this.state.transactionData.destination_account.id
                try {
                    destinationExchange = this.state.transactionData.destination_account.custom_exchange_check ? this.state.transactionData.destination_account.custom_exchange : this.state.transactionData.destination_account.exchange.id
                } catch(e) {

                }
            } else if (destinationType === "storage") {
                destinationWallet = this.state.transactionData.destination_storage
            }
        }

        return (
              
              this.state.completed ? 

              <div style={{ marginTop: 140}}>

                <div style={{textAlign: 'center', marginBottom: 24}}>
                  <img style={{ height: 90, width: 90}} src={okIcon} alt=""/>
                </div>
                
                <div className="basier" style={{textAlign: 'center', fontSize: 14}}>Succesfully {this.props.scope === 'new' ? 'added' : 'edited'} transaction</div>

                {this.props.scope === 'new' ?
                <div style={{display: 'flex', width: '100%', justifyContent: 'center', marginTop: 12}}>

                    <div
                    onClick={this.handleClose}
                    className='button-next'
                    style={{height: 40, borderRadius: 35, marginLeft: 12, marginRight: 12, cursor: 'pointer' }}
                    >
                        Close
                    </div>

                    <div
                    onClick={this.resetState}
                    className='button-next-blue'
                    style={{height: 40, borderRadius: 35, marginLeft: 12, marginRight: 12, cursor: 'pointer' }}
                    >
                        Add another
                    </div> 
                
              </div>
              :
              null}

              </div>
            
              : 
              
              this.state.error  ? 
                
              <div style={{ marginTop: 140, textAlign: 'center'}}>

                <div style={{textAlign: 'center', marginBottom: 24}}>
                  <img style={{ height: 90, width: 90}} src={errorIcon} alt=""/>
                </div>
                
                <div className="basier" style={{textAlign: 'center', fontSize: 14, marginBottom: 24}}>Error {this.props.scope === 'new' ? 'adding' : 'editing' } transaction. Please retry</div>

                {/* <div className='button-next-blue' onClick={this.backToEdit} style={{height: 40, borderRadius: 35, marginLeft: 'auto', marginRight: 'auto', cursor: 'pointer'}}>
                  Back
                </div> */}

              </div>


            :

            this.state.loading ? 

                <div style={{ marginTop: 140}}>

                  <div style={{textAlign: 'center', marginBottom: 24}}>
                    <img style={{ height: 90, width: 90}} src={loadingSpinner} alt=""/>
                  </div>
                  
                  <div className="basier" style={{textAlign: 'center', fontSize: 14}}>{this.props.scope === 'new' ? 'Creating' : 'Editing'} transaction.</div>

                </div>
              
              : 
            
            <React.Fragment>
            {this.state.review === true && 
            <div style={{position: 'absolute', top: 90, width: '100%', height: 'calc(100% - 90px)', zIndex: 99999999, backgroundColor: 'white', padding: 48}}>
                
                <h3>Transaction Entry Review</h3>

                <div className="basier-p4-caps" style={{display: 'flex', alignItems: 'baseline', width: '100%', marginTop: 48, borderBottom: '1px solid #6A7E93', color: '#6A7E93', paddingBottom: 6}}>
                    <div style={{width: '25%', textAlign: 'left'}}>Asset</div>
                    <div style={{width: '25%', textAlign: 'right'}}>Before transaction</div>
                    <div style={{width: '25%', textAlign: 'right'}}>Change</div>
                    <div style={{width: '25%', textAlign: 'right'}}>After transaction</div>
                </div>


                {this.quoteBalance.includes(this.state.transactionData.transaction_description) && this.state.deduct === false && 
                
                <div className="basier-p4-caps" style={{display: 'flex', alignItems: 'center', width: '100%', marginTop: 0, height: 60, borderBottom: '1px solid #E1E5E9'}}>
                    
                    <div className="truncate" style={{width: '25%', textAlign: 'left', display: 'flex', alignItems: 'center'}}>
                        {this.state.selected_quote.image !== "" && this.state.selected_quote.image !== null ? 
                            <div><img style={{height: 25, width: 25, marginRight: 12}} src={this.state.selected_quote.image} alt=""/></div>
                            :
                            <div style={{height: 25, width: 25, marginRight: 12, display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 20 }}>
                                {currencyDict[this.state.selected_quote.coinTicker] === undefined ? null : currencyDict[this.state.selected_quote.coinTicker]['symbol'].length > 1 ? null : currencyDict[this.state.selected_quote.coinTicker]['symbol'] }
                            </div>
                        }
                        <div className="truncate" style={{width: '100%'}}>
                            <div className="truncate basier-p3" style={{marginTop:2, marginBottom: 2, color: 'black'}}>{this.state.selected_quote.coinTicker}</div>
                            <div className="basier-p4-caps white-hover truncate" style={{color: '#6A7E93'}}>{this.state.selected_quote.coinName}</div>
                        </div>
                        
                    </div>
                    
                    <div style={{width: '25%', textAlign: 'right'}}>

                        {formatNumber(getNumber(this.state.coinBalances, this.state.selected_quote.value))}

                    </div>

                    <div style={{width: '25%', textAlign: 'right'}}>
                        <span style={{color: 'green'}}>
                            <span>+</span>
                            <span>
                            {
                            formatNumber((this.state.priceType === 'unit' ? 
                            
                                    this.state.transactionData.amount * this.state.transactionData.unit_price
    
                                : 
                                
                                    this.state.transactionData.unit_price
                                
                                ) + (

                                        (getValue(this.state.transactionData.fee_currency, 'value') === getValue(this.state.transactionData.quote_currency, 'value') ? 

                                        parseFloat(getNumber(this.state.transactionData, 'fee')) : 0

                                        )

                                    )
                                ) 
                            
                            
                            }
                            </span>
                        </span>
                        
                    </div>
                    <div style={{width: '25%', textAlign: 'right'}}>
                        {
                            formatNumber(getNumber(this.state.coinBalances, this.state.selected_quote.value) + 
                            
                                ((this.state.priceType === 'unit' ? 
                            
                                    this.state.transactionData.amount * this.state.transactionData.unit_price
    
                                : 
                                
                                    this.state.transactionData.unit_price
                                
                                ) + (

                                    (getValue(this.state.transactionData.fee_currency, 'value') === getValue(this.state.transactionData.quote_currency, 'value') ? 

                                    parseFloat(getNumber(this.state.transactionData, 'fee')) : 0

                                    ))
                                ) 

                            )
                        }
                    </div>
                </div>
                
                }

                <div className="basier-p4-caps" style={{display: 'flex', alignItems: 'center', width: '100%', marginTop: 0, height: 60, borderBottom: '1px solid #E1E5E9'}}>
                    <div className="truncate" style={{width: '25%', textAlign: 'left', display: 'flex', alignItems: 'center'}}>
                        {this.state.selected_base.image !== "" && this.state.selected_base.image !== null ? 
                            <div><img style={{height: 25, width: 25, marginRight: 12}} src={this.state.selected_base.image} alt=""/></div>
                            :
                            <div style={{height: 25, width: 25, marginRight: 12, display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 20 }}>
                                {currencyDict[this.state.selected_base.coinTicker] === undefined ? null : currencyDict[this.state.selected_base.coinTicker]['symbol'].length > 1 ? null : currencyDict[this.state.selected_base.coinTicker]['symbol'] }
                            </div>
                        }
                        <div className="truncate" style={{width: '100%'}}>
                            <div className="truncate basier-p3" style={{marginTop:2, marginBottom: 2, color: 'black'}}>{this.state.selected_base.coinTicker}</div>
                            <div className="basier-p4-caps white-hover truncate" style={{color: '#6A7E93'}}>{this.state.selected_base.coinName}</div>
                        </div>
                        
                    </div>

                    <div style={{width: '25%', textAlign: 'right'}}>
                        {formatNumber(getNumber(this.state.coinBalances, this.state.selected_base.value))}
                    </div>

                    <div style={{width: '25%', textAlign: 'right'}}>
                        <span style={{color: (this.state.transactionData.transaction_description === 'transfer' || this.baseBalance.includes(this.state.transactionData.transaction_description)) ? 'red' :

                            (this.state.transactionData.amount - ( ((getValue(this.state.transactionData.fee_currency, 'value') === getValue(this.state.transactionData.base_currency, 'value') && getValue(this.state.transactionData.base_currency, 'value') !== null)  || this.pricePortfolioQuote.includes(this.state.transactionData.transaction_description)) ? parseFloat(getNumber(this.state.transactionData, 'fee')) : 0)
                            ) > 0 ?

                             'green' 

                             :

                             'red'
                             
                            }}>
                            {(this.state.transactionData.transaction_description === 'transfer' || this.baseBalance.includes(this.state.transactionData.transaction_description)) ? null
                            : 
                            (
                                (this.state.transactionData.amount
                                    -
                                    (((getValue(this.state.transactionData.fee_currency, 'value') === getValue(this.state.transactionData.base_currency, 'value') && getValue(this.state.transactionData.base_currency, 'value') !== null)  || this.pricePortfolioQuote.includes(this.state.transactionData.transaction_description)) ? parseFloat(getNumber(this.state.transactionData, 'fee')) : 0)
                            ) > 0) ? 
                            <span>+</span> : null}

                            <span>{
                                this.state.transactionData.transaction_description === 'transfer' ? 
                                formatNumber(parseFloat(getNumber(this.state.transactionData, 'fee')))
                                :
                                formatNumber(
                                    (
                                        ((this.baseBalance.includes(this.state.transactionData.transaction_description) ? -1 : 1) * this.state.transactionData.amount) - ( (this.state.feeInclude === false ) ? 0 : ((getValue(this.state.transactionData.fee_currency, 'value') === getValue(this.state.transactionData.base_currency, 'value') && getValue(this.state.transactionData.base_currency, 'value') !== null)  || this.pricePortfolioQuote.includes(this.state.transactionData.transaction_description)) ? parseFloat(getNumber(this.state.transactionData, 'fee')) : 0 ))
                                )}
                            </span>
                        </span>
                    </div>
                    <div style={{width: '25%', textAlign: 'right'}}>
                        {   
                            this.state.transactionData.transaction_description === 'transfer' ? 

                            formatNumber(getNumber(this.state.coinBalances, this.state.selected_base.value) - ( (this.state.feeInclude === false ) ? 0 : parseFloat(getNumber(this.state.transactionData, 'fee')) ))

                            :

                            formatNumber(
                                (getNumber(this.state.coinBalances, this.state.selected_base.value) + ( (this.baseBalance.includes(this.state.transactionData.transaction_description) ? -1 : 1) * this.state.transactionData.amount ))  
                                - 
                                ( (this.state.feeInclude === false ) ? 0 : ((getValue(this.state.transactionData.fee_currency, 'value') === getValue(this.state.transactionData.base_currency, 'value') && getValue(this.state.transactionData.base_currency, 'value') !== null)  || this.pricePortfolioQuote.includes(this.state.transactionData.transaction_description)) ? parseFloat(getNumber(this.state.transactionData, 'fee')) : 0)
                            )
                        }
                    </div>
                </div>

                {this.quoteAndPrice.includes(this.state.transactionData.transaction_description) && 
                
                
                <div className="basier-p4-caps" style={{display: 'flex', alignItems: 'center', width: '100%', marginTop: 0, height: 60, borderBottom: '1px solid #E1E5E9'}}>
                    
                    <div className="truncate" style={{width: '25%', textAlign: 'left', display: 'flex', alignItems: 'center'}}>
                        {this.state.selected_quote.image !== "" && this.state.selected_quote.image !== null ? 
                            <div><img style={{height: 25, width: 25, marginRight: 12}} src={this.state.selected_quote.image} alt=""/></div>
                            :
                            <div style={{height: 25, width: 25, marginRight: 12, display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 20 }}>
                                {currencyDict[this.state.selected_quote.coinTicker] === undefined ? null : currencyDict[this.state.selected_quote.coinTicker]['symbol'].length > 1 ? null : currencyDict[this.state.selected_quote.coinTicker]['symbol'] }
                            </div>
                        }
                        <div className="truncate" style={{width: '100%'}}>
                            <div className="truncate basier-p3" style={{marginTop:2, marginBottom: 2, color: 'black'}}>{this.state.selected_quote.coinTicker}</div>
                            <div className="basier-p4-caps white-hover truncate" style={{color: '#6A7E93'}}>{this.state.selected_quote.coinName}</div>
                        </div>
                        
                    </div>
                    
                    <div style={{width: '25%', textAlign: 'right'}}>

                        {formatNumber(
                            getNumber(this.state.coinBalances, this.state.selected_quote.value)
                            + ( (this.state.transactionData.transaction_description === 'buy' || this.state.transactionData.transaction_description === 'ico') && this.state.deduct === false ? 
                            
                                ((this.state.priceType === 'unit' ? 
                            
                                    (this.state.transactionData.amount * this.state.transactionData.unit_price) 
    
                                : 
                                
                                    this.state.transactionData.unit_price
                                
                                ) + (

                                    (getValue(this.state.transactionData.fee_currency, 'value') === getValue(this.state.transactionData.quote_currency, 'value') ? 

                                    parseFloat(getNumber(this.state.transactionData, 'fee')) : 0

                                    ))
                                )

                            : 0)
                        )}

                    </div>

                    <div style={{width: '25%', textAlign: 'right'}}>
                        <span style={{color: this.state.transactionData.transaction_description === 'sell' ? 'green' : 'red'}}>
                            {this.state.transactionData.transaction_description === 'sell' && <span>+</span>}
                            <span>
                            {formatNumber(
                                (((this.baseBalance.includes(this.state.transactionData.transaction_description) ? 1 : -1) * (this.state.priceType === 'unit' ? 
                                
                                
                                    (this.state.transactionData.amount * this.state.transactionData.unit_price)
                                

                                    :      
                                
                                    this.state.transactionData.unit_price))
                                    - (

                                        (getValue(this.state.transactionData.fee_currency, 'value') === getValue(this.state.transactionData.quote_currency, 'value') ? 
    
                                        parseFloat(getNumber(this.state.transactionData, 'fee')) : 0
    
                                        ))
                                )
                                    
                                
                            )
                            }
                            </span>
                        </span>
                    </div>
                    <div style={{width: '25%', textAlign: 'right'}}>
                        {
                            formatNumber(
                                
                                (
                                    getNumber(this.state.coinBalances, this.state.selected_quote.value)
                                    + ((this.state.transactionData.transaction_description === 'buy' || this.state.transactionData.transaction_description === 'ico') && this.state.deduct === false ? 
                                    
                                        ((this.state.priceType === 'unit' ? 
                                    
                                            this.state.transactionData.amount * this.state.transactionData.unit_price
            
                                        : 
                                        
                                            this.state.transactionData.unit_price
                                        
                                        ) + (getValue(this.state.transactionData.fee_currency, 'value') === getValue(this.state.transactionData.quote_currency, 'value') ? 

                                        parseFloat(getNumber(this.state.transactionData, 'fee')) : 0

                                        ))

                                    : 0)
                                ) + ( (this.baseBalance.includes(this.state.transactionData.transaction_description) ? 1 : -1) * (
                                    (this.state.priceType === 'unit' ? 
                                    
                                            this.state.transactionData.amount * this.state.transactionData.unit_price
            
                                        : 
                                        
                                            this.state.transactionData.unit_price
                                        
                                        )
                                ) - ((getValue(this.state.transactionData.fee_currency, 'value') === getValue(this.state.transactionData.quote_currency, 'value') ? 

                                parseFloat(getNumber(this.state.transactionData, 'fee')) : 0

                                )) )
                            )
                        }
                    </div>
                </div>
                

                
                }
                
                <div style={{marginTop: 48, width: '100%', display: 'flex', justifyContent: 'center'}}>
                    <SubmitForm review={true} scope={this.props.scope} initialState={this.oldData} transaction={this.state.transactionData} passState={this.state} goToSubmit={this.state.submitStep} handleSubmit={this.submitNewTransaction} handleCancel={() => {}} updateState={this.updateState} handleBack={['buy', 'sell', 'ico'].includes(this.state.transactionData.transaction_description) ? () => this.getTargetInnerRef(this.backFromSubmit) : () => this.getTargetRef(this.backFromSubmit)} validatorUpdate={this.validatorUpdate} accountNames={this.props.accountNames} portfolioId={this.state.portfolioId} user_id={this.props.user_id} overridePort={this.props.overridePort} refresher={this.props.refresher} editServer={this.updateAction} toggleReview={this.toggleReview} />
                </div>

            </div>
            }

            <div style={{padding: '12px 48px'}}>    

                <EntryLine height={90}>

                    <div style={{flex: 1, display: 'flex', justifyContent: 'flex-start'}}>
                        <DateSelector focusBase={this.focusBase} validatorUpdate={this.validatorUpdate} validation={this.state.validations} updateTransaction={this.updateTransaction} transaction={this.state.transactionData} setTrTime={this.setTrTime} tr_date={this.state.tr_date} nextRef={this.baseRef} postSelect={this.datePriceUpdate} />
                    </div>

                    <div style={{flex: 1, display: 'flex', justifyContent: 'center'}}>
                        <CoinSelector autoFocus={this.props.scope === "new" ? true : false} inputBaseRef={this.baseRef} coins={this.props.all_coins} label="base_currency" updateState={this.updateState} updateTransaction={this.updateCoinTransaction} transaction={this.state.transactionData} getNextRef={this.getAmountRef} validations={this.state.validations} />
                    </div>

                    <div style={{flex: 1, display: 'flex', justifyContent: 'flex-end'}}>
                        <InputQuestion 
                            validatorMethod={(n) => this.handleNumberValidation('amount', n)}
                            innerRef={this.amountRef} updateState={this.updateState} label="amount" validation={this.state.validations} updateTransaction={this.updateInputTransaction} transaction={this.state.transactionData} getNextRef={this.getFirstTypeRef} getPreviousRef={this.focusBase} validateNumber={true} handlePostSelect={this.handleDisplayPrice} labelName="Amount" subText="Use dot for decimal; no thousand separator." errorText="Use dot for decimal; no thousand separator." />
                    </div>


                </EntryLine>

                <EntryLine height={100}>
                    <div style={{width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                        {this.state.transactionData.base_currency !== undefined && this.state.transactionData.base_currency !== null ? 
                        <TransactionTypeSelect updateState={this.updateState} updateTransaction={this.updateTransaction} transaction={this.state.transactionData} activeType={this.state.activeType} toggleActivate={this.toggleActivate} goToRef={this.getTransitionBackToType} handlePostSelect={this.postTypeSelect} />
                        :
                        null}
                    </div>
                </EntryLine>

                <input style={{backgroundColor: 'white', border: 'none', height: 0, width: 0, opacity: 0, visibility: 'none', position: 'absolute'}} type="text" ref={this.firstTypeRef} onKeyDown={this.backToAmount} />
                <input style={{backgroundColor: 'white', border: 'none', height: 0, width: 0, opacity: 0, visibility: 'none', position: 'absolute'}} type="text" ref={this.firstSubmitRef} />

                {this.state.transactionData.transaction_description !== null && this.state.transactionData.transaction_description !== undefined && this.state.transactionData.base_currency !== null && this.state.transactionData.base_currency !== undefined ?

                    this.quoteAndPrice.includes(this.state.transactionData.transaction_description) ? 

                    <EntryLine>
                    
                        <div style={{flex: 1, display: 'flex', justifyContent: 'left'}}>
                            <CoinSelector 
                                autoFocus={false} 
                                inputBaseRef={this.transitionBackToType} 
                                validations={this.state.validations}
                                coins={this.props.all_coins} 
                                label="quote_currency" 
                                updateState={this.updateState} 
                                updateTransaction={this.updateCoinTransaction} 
                                transaction={this.state.transactionData} 
                                getNextRef={this.getPriceRef} 
                                backToPrevious={this.handlePostTypePrice} 
                            />
                        </div>

                        <div style={{flex: 1, display: 'flex', justifyContent: 'center'}}>
                            <InputQuestion 
                                validatorMethod={(n) => this.handleNumberValidation('unit_price', n)}
                                innerRef={this.priceRef} 
                                updateState={this.updateState} 
                             
                                label="unit_price" 
                                validation={this.state.validations} 
                                updateTransaction={this.updateInputTransaction} 
                                transaction={this.state.transactionData} 
                                getNextRef={() => this.getTargetRef(this.feeRef)} 
                                getPreviousRef={this.getTransitionBackToType} 
                                validateNumber={true} handlePostSelect={this.handleDisplayPrice} 
                                labelName="Price" 
                                labelPreCTA='Avg. Unit Price:'
                                labelCTA={this.state.priceAtDate}
                                labelCTAAction={this.insertPrice}
                                typeSelector="right"
                                extraSelection={this.state.priceType}
                                extraOptions={[{value: 'unit', label: "per Unit"}, {value: 'total', label: "in Total"}]}
                                handleExtraSelect={this.handlePriceType}
                                extraRef={this.priceTypeRef}
                                subText="Use dot for decimal; no thousand separator." 
                                errorText="Use dot for decimal; no thousand separator."
                                
                            />
                        </div>
                        
                        <div style={{flex: 1, display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-end', height: '100%', textAlign: 'left'}}>
                            
                            <div>
                                <SourceOfFunds 
                                    transaction={this.state.transactionData}
                                    priceType={this.state.priceType} 
                                    priceDisplay={this.state.priceDisplay}
                                    switchSource={this.switchSource} 
                                    deduct={this.state.deduct} 
                                    quoteBalance={this.quoteBalance}
                                    base={this.state.selected_base}
                                    quote={this.state.selected_quote}
                                    invalidBalance={this.state.invalidBalance}
                                    balances={this.state.coinBalances}
                                    />
                            </div>
                            
                        </div>

                    </EntryLine>

                    : 

                    this.pricePortfolioQuote.includes(this.state.transactionData.transaction_description) ? 

                    <EntryLine>
                        <div style={{flex: 1, display: 'flex', justifyContent: 'flex-start'}}>
                            <InputQuestion 
                                validatorMethod={(n) => this.handleNumberValidation('unit_price', n)}
                                innerRef={this.priceRef} 
                                updateState={this.updateState} 
                             
                                label="unit_price" 
                                validation={this.state.validations} 
                                updateTransaction={this.updateInputTransaction} 
                                transaction={this.state.transactionData} 
                                getNextRef={this.getFirstTypeRef} 
                                getPreviousRef={this.handlePostTypePrice} 
                                validateNumber={true} handlePostSelect={this.handleDisplayPrice} 
                                labelName="Unit Price" 
                                append={this.state.portfolioCurrency}
                                subText="Denominated in portfolio's native currency."
                                errorText="Use dot for decimal; no thousand separator."
                            />
                        </div>

                        <div style={{flex: 1, display: 'flex', justifyContent: 'center'}}>
                            <InputQuestion 
                                validatorMethod={(n) => this.handleNumberValidation('fee', n)}
                                innerRef={this.transitionBackToType} 
                                updateState={this.updateState} 
                             
                                label="fee" 
                                validation={this.state.validations} 
                                updateTransaction={this.updateInputTransaction} 
                                transaction={this.state.transactionData} 
                                getNextRef={() => this.getTargetInnerRef(this.userSelectRef)} 
                                getPreviousRef={() => this.getTargetRef(this.priceRef)} 
                                validateNumber={false} handlePostSelect={this.handleDisplayPrice} 
                                labelName="Fee" 
                                append={getValue(this.state.selected_base, 'coinTicker')}
                                subText="Denominated in base currency."
                                errorText="Use dot for decimal; no thousand separator."
                            />
                        </div>
                        <div style={{flex: 1, display: 'flex', justifyContent: 'flex-end'}}>

                            <SourceOfFunds 
                                transaction={this.state.transactionData} 
                                switchSource={this.switchSource} 
                                deduct={this.state.deduct} 
                                quoteBalance={this.quoteBalance}
                                base={this.state.selected_base}
                                quote={this.state.selected_quote}
                                invalidBalance={this.state.invalidBalance}
                                balances={this.state.coinBalances}
                                toggleFeeInclude={this.toggleForceFee}
                                feeInclude={this.state.feeInclude}
                                feeQuestion={true}
                                />



                        </div>

                    </EntryLine>

                    : 

                    this.feeOnly.includes(this.state.transactionData.transaction_description) ? 

                    <EntryLine>
                        
                        <div style={{flex: 1, display: 'flex', justifyContent: 'flex-start'}}>
                        <InputQuestion 
                            validatorMethod={(n) => this.handleNumberValidation('mining_cost', n)}
                            innerRef={this.transitionBackToType} updateState={this.updateState} label="mining_cost" validation={this.state.validations} updateTransaction={this.updateInputTransaction} transaction={this.state.transactionData} getNextRef={() => this.getTargetInnerRef(this.miningCostRef)} getPreviousRef={this.handlePostTypePrice} validateNumber={false} handlePostSelect={this.handleDisplayPrice} labelName="Total Cost" subText="Use dot for decimal; no thousand separator." errorText="Use dot for decimal; no thousand separator." placeholder="Optional" />
                        </div>
                        
                        <div style={{flex: 1, display: 'flex', justifyContent: 'center'}}>
                        <CoinSelector 
                                autoFocus={false} 
                                inputBaseRef={this.miningCostRef} 
                                validations={this.state.validations}
                                coins={this.props.all_coins} 
                                label="quote_currency" 
                                overrideLabel="Cost"
                                updateState={this.updateState} 
                                updateTransaction={this.updateCoinTransaction} 
                                transaction={this.state.transactionData} 
                                getNextRef={() => this.getTargetInnerRef(this.userSelectRef)} 
                                backToPrevious={() => this.getTargetRef(this.transitionBackToType)} 
                            />
                        </div>

                        <div style={{flex: 1, display: 'flex', justifyContent: 'flex-end'}}>

                            <SourceOfFunds 
                                transaction={this.state.transactionData} 
                                switchSource={this.switchSource} 
                                deduct={this.state.deduct} 
                                quoteBalance={this.quoteBalance}
                                base={this.state.selected_base}
                                quote={this.state.selected_quote}
                                invalidBalance={this.state.invalidBalance}
                                balances={this.state.coinBalances}
                                addCost={this.state.addCost}
                                toggleAddCost={() => this.setState(state => ({...state, addCost: !state.addCost}))}
                                miningQuestion={true}
                            />

                        </div>
                        

                    </EntryLine>

                    : 

                    this.transferOnly.includes(this.state.transactionData.transaction_description) ? 

                    <EntryLine>

                        <div style={{flex: 1, display: 'flex', justifyContent: 'flex-start'}}>
                            <InputQuestion 
                                    validatorMethod={(n) => this.handleNumberValidation('fee', n)}
                                    innerRef={this.transitionBackToType} 
                                    updateState={this.updateState} 
                                 
                                    label="fee" 
                                    validation={this.state.validations} 
                                    updateTransaction={this.updateInputTransaction} 
                                    transaction={this.state.transactionData} 
                                    getNextRef={() => this.getTargetInnerRef(this.userSelectRef)} 
                                    getPreviousRef={() => this.getTargetRef(this.priceRef)} 
                                    validateNumber={false} handlePostSelect={this.handleDisplayPrice} 
                                    labelName="Fee" 
                                    append={getValue(this.state.selected_base, 'coinTicker')}
                                    subText="Denominated in base currency."
                                    errorText="Use dot for decimal; no thousand separator."
                                />
                        </div>

                        <div style={{flex: 1, display: 'flex', justifyContent: 'center'}}>

                            <UserSelect transaction={this.state.transactionData} portfolioId={this.state.portfolioId} updateTransaction={this.updateTransfer} scope='source' innerRef={this.userSelectRef} getNextRef={() => this.getTargetInnerRef(this.flatWalletRef)} backToPrevious={() => this.getTargetRef(this.transitionBackToType)} forceLabel="Sending User" user_id={this.state.transactionData.user_transaction} label="user_transaction" />

                        </div>

                        <div style={{flex: 1, display: 'flex', justifyContent: 'flex-end'}}>
                            {this.props.scopedWallets !== undefined && this.props.scopedWallets !== null ? 
                            <div>
                                <div className="basier-p4-caps" style={{marginBottom: 2, color: 'black', fontWeight: 900, display: 'flex', alignItems: 'flex-start'}}>
                                    Sending Wallet
                                </div>
                                <DetailWalletList ref={this.flatWalletRef} wallets={this.props.scopedWallets} scopedUser={this.state.transactionData.user_transaction || this.props.user_id} base_currency={this.state.selected_base['value']} handleChange={(value) => this.updateTransaction('from_wallet', value)} height={40} goToNext={() => this.getTargetInnerRef(this.destinationUser)} backToPrevious={() => this.getTargetInnerRef(this.userSelectRef)} propagateKey={true} selection={this.props.scope === 'update' ? this.state.transactionData.from_wallet : undefined} />
                            </div>
                            : 
                            null}
                        </div>


                    </EntryLine>
                    
                    :

                    null 

                    :

                    null
                    
                    }
                
                <EntryLine>
                    {this.state.transactionData.transaction_description !== null && this.state.transactionData.transaction_description !== undefined && this.state.transactionData.base_currency !== null && this.state.transactionData.base_currency !== undefined ?

                        this.quoteAndPrice.includes(this.state.transactionData.transaction_description) ?

                        <React.Fragment>
                            
                            <div style={{flex: 1, display: 'flex', justifyContent: 'flex-start'}}>
                            <InputQuestion 
                                validatorMethod={(n) => this.handleNumberValidation('fee', n)}
                                innerRef={this.feeRef} updateState={this.updateState} label="fee" validation={this.state.validations} updateTransaction={this.updateInputTransaction} transaction={this.state.transactionData} getNextRef={() => this.getTargetInnerRef(this.feeCurrencyRef)} getPreviousRef={() => this.getTargetRef(this.priceTypeRef)} validateNumber={false} handlePostSelect={this.handleDisplayPrice} labelName="Fee" subText="Use dot for decimal; no thousand separator." errorText="Use dot for decimal; no thousand separator." />
                            </div>

                            <div style={{flex: 1, display: 'flex', justifyContent: 'center'}}>
                                <CoinSelector 
                                    autoFocus={false} 
                                    inputBaseRef={this.feeCurrencyRef} 
                                    coins={this.props.all_coins} 
                                    validations={this.state.validations}
                                    label="fee_currency" 
                                    updateState={this.updateState} 
                                    updateTransaction={this.updateCoinTransaction} 
                                    transaction={this.state.transactionData} 
                                    getNextRef={() => this.getTargetInnerRef(this.userSelectRef)} 
                                    backToPrevious={() => this.getTargetRef(this.feeRef)} 
                                />
                            </div>

                            <div style={{flex: 1, display: 'flex', justifyContent: 'flex-end'}}>
                                <UserSelect transaction={this.state.transactionData} portfolioId={this.state.portfolioId} updateTransaction={this.updateTransaction} scope='source' innerRef={this.userSelectRef} getNextRef={() => this.getTargetInnerRef(this.exchangeRef)} backToPrevious={() => this.getTargetInnerRef(this.feeCurrencyRef)} user_id={this.props.user_id} label="user_transaction" />
                            </div>
                            
                        

                        </React.Fragment>

                        :

                        this.transferOnly.includes(this.state.transactionData.transaction_description) ? 

                        <React.Fragment>

                            <div style={{flex: 1, display: 'flex', justifyContent: 'flex-start'}}>
                                <UserSelect transaction={this.state.transactionData} portfolioId={this.state.portfolioId} updateTransaction={this.updateTransaction} innerRef={this.destinationUser} getNextRef={() => this.getTargetRef(this.originRef)} backToPrevious={() => this.getTargetInnerRef(this.flatWalletRef)} forceLabel="Receiving User" user_id={this.props.user_id} label="user_wallet_transfer" test="1"/>
                            </div>

                            <div style={{flex: 1, display: 'flex', justifyContent: 'center'}}>
                                <DestinationType origin={this.state.origin} updateState={this.updateState} updateTransaction={this.updateTransaction} innerRef={this.originRef} />
                            </div>

                            <div style={{flex: 1, display: 'flex', justifyContent: 'flex-end'}}>

                                {this.state.origin === 'exchange' ? 

                                <ExchangeSelector 
                                    removeWallets={this.removeWallets}
                                    exchanges={this.props.exchanges}
                                    autoFocus={false} 
                                    inputBaseRef={this.exchangeRef} 
                                    coins={this.props.all_coins} 
                                    label="exchange" 
                                    updateState={this.updateState} 
                                    updateTransaction={this.updateCoinTransaction} 
                                    transaction={this.state.transactionData} 
                                    getNextRef={() => this.getTargetInnerRef(this.ExchangeAccountRef)} 
                                    backToPrevious={() => this.getTargetRef(this.originRef)} 
                                />
                                
                                : 
                                
                                this.state.origin === 'wallet' ? 
                            
                                    <WalletQuestion 
                                        accountNames={this.props.accountNames}
                                        innerRef={this.walletRef}
                                        wallets={this.props.wallets} 
                                        user_id={this.state.transactionData.transaction_description === 'transfer' ? (this.state.transactionData.user_wallet_transfer || this.props.user_id) : this.state.transactionData.user_transaction} 
                                        transaction={this.state.transactionData}
                                        updateTransaction={this.updateTransaction}
                                        getNextRef={() => this.getTargetRef(this.backFromSubmit)} 
                                        coin={this.state.selected_base}
                                        backToPrevious={() => this.getTargetRef(this.originRef)} 
                                    />

                                : 


                                null }
                                
                            </div>

                        </React.Fragment>

                        :

                        <React.Fragment>
                            <div style={{flex: 1, display: 'flex', justifyContent: 'flex-start'}}>
                                <UserSelect transaction={this.state.transactionData} portfolioId={this.state.portfolioId} updateTransaction={this.updateTransaction} scope='source' innerRef={this.state.transactionData.transaction_description === 'lost' ? this.transitionBackToType : this.userSelectRef} backToPrevious={this.state.transactionData.transaction_description === 'lost' ? () => this.handlePostTypePrice() : this.state.transactionData.transaction_description === 'mining' ? () => this.getTargetInnerRef(this.miningCostRef) : () => this.getTargetRef(this.transitionBackToType)} getNextRef={() => this.getTargetRef(this.originRef)} user_id={this.props.user_id} label="user_transaction" />
                            </div>

                            <div style={{flex: 1, display: 'flex', justifyContent: 'center'}}>
                                 <DestinationType updateTransaction={this.updateTransaction} origin={this.state.origin} updateState={this.updateState} innerRef={this.originRef} />
                            </div>

                            <div style={{flex: 1, display: 'flex', justifyContent: 'flex-end'}}>
                            {this.state.origin === 'exchange' ? 

                                <ExchangeSelector 
                                    removeWallets={this.removeWallets}
                                    exchanges={this.props.exchanges}
                                    autoFocus={false} 
                                    inputBaseRef={this.exchangeRef} 
                                    coins={this.props.all_coins} 
                                    label="exchange" 
                                    updateState={this.updateState} 
                                    updateTransaction={this.updateCoinTransaction} 
                                    transaction={this.state.transactionData} 
                                    getNextRef={() => this.getTargetInnerRef(this.ExchangeAccountRef)} 
                                    backToPrevious={() => this.getTargetRef(this.originRef)} 
                                />
                            
                            : 
                            
                            this.state.origin === 'wallet' ? 
                        
                                <WalletQuestion 
                                    accountNames={this.props.accountNames}
                                    innerRef={this.walletRef}
                                    wallets={this.props.wallets} 
                                    user_id={this.state.transactionData.user_transaction} 
                                    transaction={this.state.transactionData}
                                    updateTransaction={this.updateTransaction}
                                    getNextRef={() => this.getTargetRef(this.backFromSubmit)} 
                                    coin={this.state.selected_base}
                                    backToPrevious={() => this.getTargetRef(this.originRef)} 
                                />

                            : 


                            null }
                            </div>


                        </React.Fragment>


                    :

                    null

                    }


                </EntryLine>


                <EntryLine>
                    {this.state.transactionData.transaction_description !== null && this.state.transactionData.transaction_description !== undefined && this.state.transactionData.base_currency !== null && this.state.transactionData.base_currency !== undefined ?

                        this.quoteAndPrice.includes(this.state.transactionData.transaction_description) ?

                        <React.Fragment>
                            
                            <div style={{flex: 1, display: 'flex', alignItems: 'flex-start', flexDirection: 'column'}}>
                                {this.state.storageBuy ? 
                                
                                <WalletQuestion 
                                    accountNames={this.props.accountNames}
                                    innerRef={this.walletRef}
                                    wallets={this.props.wallets} 
                                    user_id={this.state.transactionData.user_transaction} 
                                    transaction={this.state.transactionData}
                                    updateTransaction={this.updateTransaction}
                                    getNextRef={() => this.getTargetRef(this.backFromSubmit)} 
                                    coin={this.state.selected_base}
                                    backToPrevious={() => this.getTargetRef(this.originRef)} 
                                /> 
                                :
                                <ExchangeSelector 
                                    removeWallets={this.removeWallets}
                                    exchanges={this.props.exchanges}
                                    autoFocus={false} 
                                    inputBaseRef={this.exchangeRef} 
                                 
                                    coins={this.props.all_coins} 
                                    label="exchange" 
                                    updateState={this.updateState} 
                                    updateTransaction={this.updateCoinTransaction} 
                                    transaction={this.state.transactionData} 
                                    getNextRef={() => this.getTargetInnerRef(this.backFromSubmit)} 
                                    backToPrevious={() => this.getTargetInnerRef(this.userSelectRef)} 
                                />
                                }
                                <div className="basier-p4 opacity-hover" style={{marginTop: 2, fontStyle: 'italic', color: '#e0a03d', cursor: 'pointer'}} onClick={this.toggleStorageBuy}>
                                    {this.state.storageBuy ? "Bought on an Exchange ?" : "Bought on a DEX / using wallet?"}
                                </div>
                                
                            </div>

                            <div style={{flex: 1, display: 'flex', justifyContent: 'center'}}>
                                {this.state.storageBuy ? 
                                null :
                                <ExchangeAccountQuestion 
                                    accountNames={this.props.accountNames}
                                    innerRef={this.backFromSubmit}
                                    wallets={this.props.wallets} 
                                    user_id={this.state.transactionData.user_transaction} 
                                    transaction={this.state.transactionData}
                                    updateTransaction={this.updateTransaction}
                                    getNextRef={() => this.goToSubmit()} 
                                    backToPrevious={() => this.getTargetInnerRef(this.exchangeRef)} 
                                />
                                }
                            </div>                        

                        </React.Fragment>

                        :

                        <React.Fragment>

                            
                                {this.state.origin === 'exchange' ? 
                                <React.Fragment>
                                    <div style={{flex: 1, display: 'flex', justifyContent: 'flex-start'}}>
                                        <ExchangeAccountQuestion 
                                            accountNames={this.props.accountNames}
                                            innerRef={this.ExchangeAccountRef}
                                            wallets={this.props.wallets} 
                                            user_id={this.state.transactionData.transaction_description === 'transfer' ? ((this.props.scope === 'update' ? getValue(this.state.transactionData.user_wallet_transfer, 'id', null) : this.state.transactionData.user_wallet_transfer) || this.props.user_id) : this.state.transactionData.user_transaction} 
                                            transaction={this.state.transactionData}
                                            updateTransaction={this.updateTransaction}
                                            getNextRef={() => this.getTargetRef(this.backFromSubmit)} 
                                            backToPrevious={() => this.getTargetInnerRef(this.exchangeRef)} 
                                        />
                                    </div>
                                    
                                    <div style={{flex: 1, display: 'flex', justifyContent: 'center'}}>

                                        <InputQuestion 
                                            validatorMethod={(n) => {}}
                                            innerRef={this.backFromSubmit} 
                                            updateState={this.updateState} 
                                            label="tid" 
                                            validation={this.state.validations} 
                                            updateTransaction={this.updateInputTransaction} 
                                            transaction={this.state.transactionData} 
                                            getNextRef={() => this.goToSubmit()} 
                                            getPreviousRef={() => this.getTargetInnerRef(this.ExchangeAccountRef)} 
                                            validateNumber={false} 
                                            handlePostSelect={null} 
                                            labelName="Tx Hash" 
                                            subText="" 
                                            errorText="" 
                                        />

                                    </div>

                                </React.Fragment>
                                : 
                                
                                this.state.origin === 'wallet' ? 
                                <div style={{flex: 2, display: 'flex', justifyContent: 'flex-start'}}>
                                    <InputQuestion 
                                        validatorMethod={(n) => {}}
                                        fullWidth={true}
                                        innerRef={this.backFromSubmit} 
                                        updateState={this.updateState} 
                                        label="tid" 
                                        validation={this.state.validations} 
                                        updateTransaction={this.updateInputTransaction} 
                                        transaction={this.state.transactionData} 
                                        getNextRef={() => this.goToSubmit()} 
                                        getPreviousRef={() => this.getTargetInnerRef(this.walletRef)} 
                                        validateNumber={false} 
                                        handlePostSelect={null} 
                                        labelName="Tx Hash" 
                                        subText="" 
                                        errorText="" 
                                    />
                                </div>

                                : 
                                null}

                        </React.Fragment>


                    :

                    null

                    }

                    {this.state.transactionData.transaction_description !== null && this.state.transactionData.transaction_description !== undefined && this.state.transactionData.base_currency !== null && this.state.transactionData.base_currency !== undefined ?
                    <div style={{flex: 1, display: 'flex', justifyContent: 'flex-end', height: '100%', alignItems: 'center'}}>
                        <SubmitForm scope={this.props.scope} initialState={this.oldData} transaction={this.state.transactionData} passState={this.state} goToSubmit={this.state.submitStep} handleSubmit={this.submitNewTransaction} handleCancel={() => {}} updateState={this.updateState} handleBack={['buy', 'sell', 'ico'].includes(this.state.transactionData.transaction_description) ? () => this.getTargetInnerRef(this.backFromSubmit) : () => this.getTargetRef(this.backFromSubmit)} validatorUpdate={this.validatorUpdate} accountNames={this.props.accountNames} portfolioId={this.state.portfolioId} user_id={this.props.user_id} overridePort={this.props.overridePort} refresher={this.props.refresher} editServer={this.updateAction} toggleReview={this.toggleReview} />
                    </div>
                    :
                    null}


                </EntryLine>



            </div>
            </React.Fragment>
            
        )
    }


}

const mapStateToProps = state => {
    return {
        currentPortfolio: state.data.menu_selected, 
        all_coins: state.appwide.all_coins, 
        exchanges: state.appwide.exchanges,
        list_portfolios: state.auth.list_portfolios, 
        user_id: state.auth.user_id,
        token: state.auth.token,
        wallets: state.appwide.wallets,
        scopedWallets: state.appwide.flat_wallets,
        accountNames: state.appwide.accountNames
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getFlatWallets: (user) => dispatch(appwideActions.getFlatWalletList(user)), 
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(ShortTransactionEntry)
