import React from "react";
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import * as actions from '../../../../../store/actions/interactions'
import { withRouter } from "react-router-dom";
import currencyDict from '../../../../../helpers/currency.json'

class AssetTable extends React.PureComponent {

    state = {

        small: false, 
        exited: false
    }

    handleSmallToggle = () => {
        if (this.state.small === false) {
            this.setState(state => ({...state, small: true}));
        } else {
            this.setState(state => ({...state, small: false}));
        }
    }

    handleExitedToggle = () => {
        if (this.state.exited === false) {
            this.setState(state => ({...state, exited: true}));
        } else {
            this.setState(state => ({...state, exited: false}));
        }
    }


    render() {

        return (
            
            
            this.props.items.balances.map((item, index) => { 
                
    
                return (
    
                    <Grid container style={{ 
                        height: 85,
                        display: 'flex', 
                        alignItems: 'center',
                        paddingLeft: 6,
                        borderBottom: '1px solid #E1E5E9',
                        width: '100%',
                        }} 
    
                        value={item.coin}
                        key={index}
                        //onClick={this.handleDetail}
                        index={index}
                        className="asset-line basier-p3">
            
                <Grid item style={{width: '30%' }} >
                    <div style={{display: 'flex', alignItems: 'center'}}>
                    {item.image !== "" && item.image !== null  ? 
                    <span><img style={{height: 25, width: 25, marginRight: 12}} src={item.image} alt=""/></span>
                    :
                    <span style={{height: 25, width: 25, marginRight: 12, display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: `${ currencyDict[item.coin_symbol] === undefined ? '16px' : currencyDict[item.coin_symbol]['symbol'].length > 1 ? '16px' : '22px'}` }}>
                        {currencyDict[item.coin_symbol] === undefined ? item.symbol : currencyDict[item.coin_symbol]['symbol'].length > 1 ? currencyDict[item.coin_symbol]['symbol'] : currencyDict[item.coin_symbol]['symbol'] }
                    </span>
                    }
                        <span className="basier-p2" style={{fontWeight: 'bold'}}>{item.coin_ticker}</span>
                    </div>
                    <div style={{marginTop: 6}} className="basier-p4">Purchased @</div> 
                    <div className="basier-p4">{this.props.currency_symbol}{item.purchase_price > 1 ? item.purchase_price.toLocaleString('en', {maximumFractionDigits: 2, minimumFractionDigits: 2}) : item.purchase_price.toLocaleString('en', {maximumFractionDigits: 6, minimumFractionDigits: 6}) }</div>
                </Grid> 
    

    
                <Grid item style={{width: '65%', textAlign: 'right'}} >
                
                    <div style={{
                        textAlign: 'right',
                        marginRight: 8, 
                        fontWeight: 'bold',
                        color: `${item.total_profit >= 0 ? '#4CDAC1' : '#F9716B'}`
                        }}
                        className="basier-p2">

                            {this.props.currency_symbol} {item.total_profit.toLocaleString('en', {maximumFractionDigits: 2, minimumFractionDigits: 2})}
                            
                    </div>
                    {item.realized_profit !== undefined && item.active_profit !== undefined ? 
                    <div style={{width: '100%', marginRight: 8}}>
                        <div style={{display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'flex-end', marginTop: 12}}>
                            <div className="basier-p4-caps">R:</div>
                            <div className="basier-p4-caps" style={{marginLeft: 12, justifySelf: 'flex-end', marginRight: 8}}>{this.props.currency_symbol} {item.realized_profit.toLocaleString('en', {maximumFractionDigits: 2, minimumFractionDigits: 2})}</div>
                        </div>
                        <div style={{display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'flex-end', marginTop: 4}}>
                            <div className="basier-p4-caps">U:</div>
                            <div className="basier-p4-caps" style={{marginLeft: 12, justifySelf: 'flex-end', marginRight: 8}}>
                                {this.props.currency_symbol} {item.active_profit.toLocaleString('en', {maximumFractionDigits: 2, minimumFractionDigits: 2})}
                            </div>
                        </div>
                        
                    </div>
                    :
                    null
                    }
    
                </Grid> 
    
                <Grid item style={{width: '5%', textAlign: 'center'}} >
                
                
    
                </Grid> 
    
    
            </Grid>
            )
        })


        )
    }
}

const mapStateToProps = state => {
    return {
        currency_symbol: state.auth.currency.symbol,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        toggleAssetModal: (currency, scope) => 
            dispatch(actions.toggleAssetModal(currency, scope)), 
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(AssetTable));