import React, {Component} from 'react';
import LpQuoteBox from "./LpQuoteBox";

class LpQuoteSummary extends Component {
    render() {
        const {data} = this.props;
        return (
            <div style={{display: 'flex', alignItems: 'flex-start', flex: 1, height: 132, marginLeft: 24}}>
                {Object.keys(data.translator).map((v, k) => {
                    return <LpQuoteBox key={k} coin={v} data={data} />
                })}
            </div>
        );
    }
}

export default LpQuoteSummary;