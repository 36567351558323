import React, {Component} from 'react';

class LpTokenBalance extends Component {
    render() {
        const {data} = this.props;
        return (
            <div style={{height: 60, width: '100%', flex: 1, marginTop: 6}}>
                <div className="basier-p4" style={{color: '#6A7E93', textTransform: 'uppercase'}}>
                    LP Token Amount
                </div>
                <div style={{marginTop: 2, display: 'flex', alignItems: 'center'}}>
                    <div className="poppins" style={{color: 'black',}}>
                        {data.initial_lp_balance.toLocaleString('en', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 8
                        })}
                    </div>
                    {this.props.needsEstimate &&
                    <div style={{
                        border: '1px solid #4CDAC1',
                        backgroundColor: '#4CDAC14D',
                        fontWeight: 'bold',
                        padding: '2px 4px',
                        borderRadius: 5,
                        marginLeft: 6
                    }}
                         className="basier-p4">
                        staked
                    </div>
                    }
                </div>
                {data.initial_lp_balance !== data.current_lp_balance &&
                <div className={'basier-p4'} style={{color: '#6A7E93', fontStyle: 'italic', marginTop: 2}}>Current
                    Balance: {data.current_lp_balance.toLocaleString('en', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 8
                    })}</div>
                }
            </div>
        );
    }

}

export default LpTokenBalance;