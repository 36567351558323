import React from "react";
import { connect } from "react-redux";
import BaseModalTop from "../../Portfolio/PortfolioComponents/Transactions/BaseModalTop";
import * as actions from "../../../store/actions/interactions";
import AuditCompare from "./ModalComps/AuditCompare";
import fieldValidator from "../../../helpers/validators";

class ExportModal extends React.PureComponent {
  state = {};

  handleClose = () => {
    this.props.toggleModal({ type: null, open: false, payload: null });
  };

  submit = () => {
    this.props.submit();
    this.handleClose();
  }

  render() {
    return (
      <div>
        <BaseModalTop
          forceSingle={true}
          title="Export"
          handleClose={this.handleClose}
          onClick={this.backToWelcome}
          welcome={this.state.welcome}
          entryType={this.state.type_selected}
        />

        <div
          style={{alignItems: "center", justifyContent: "center", textAlign: 'center', marginTop: 24, padding: 24}}
        >
          <div style={{marginBottom: 24}}>
          <h4>Request Data Export</h4>
          </div>
          <p className="basier-p3">
            You are about to export your full {this.props.scope}. Once
            confirmed you can find your export in the export tab on this page
          </p>
          <p className="basier-p3">
            It may take some time for your export to be ready so please check back if it isn't right away.
          </p>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: 24,
              justifyContent: "center",
              width: "100%",
              marginRight: 12,
            }}
          >
            <div
              className="basier-p3 opacity-hover"
              style={{
                fontWeight: 900,
                height: 40,
                backgroundColor: "#F9716B",
                width: 150,
                color: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 20,
                marginRight: 12,
                cursor: "pointer",
              }}
              onClick={() => this.handleClose()}
            >
              Cancel
            </div>

            <div
              className="basier-p3 opacity-hover"
              style={{
                fontWeight: 900,
                height: 40,
                backgroundColor: "#0078DB",
                width: 150,
                color: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 20,
                marginRight: 12,
                cursor: "pointer",
              }}
              onClick={this.submit}
            >
              Submit
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user_id: state.auth.user_id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleModal: (data) => dispatch(actions.toggleModal(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExportModal);
