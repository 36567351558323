import React from "react";
import { connect } from "react-redux";
import * as actions from "../../../store/actions/data";
import * as interActions from "../../../store/actions/interactions";
import * as appwideActions from "../../../store/actions/appwide";
import "../../Portfolio/PortfolioComponents/Transactions/TransactionStyle.css";
import {
  loadData,
  siwtchTransactionType,
  dateParser,
  formattedNumber,
} from "../../../helpers/helpers";
import BaseModalTop from "../../Portfolio/PortfolioComponents/Transactions/BaseModalTop";
import { withRouter } from "react-router-dom";
import axios from "axios";
import { backendUrl } from "../../../variables";
import Tooltip from "../../AppWide/Tooltip";
import TypeSelector from "../../AppWide/TableTypeSelector";
import arrowRound from "../../../Media/arrowRound.svg";

class MatchedTransfer extends React.PureComponent {
  state = {
    transactionId: this.props.transactionId,
    date: null,
    data: null,
    editConnected: false,
    connectedEdit: {
      unit_price: null,
    },
  };

  renderSelector = (data) => {

    return (
      <div style={{ width: "100%", padding: 12 }}>
        <div
          style={{
            fontWeight: 900,
            width: 100,
            height: 26,
            padding: "0px 12px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: 20,
            textTransform: "uppercase",
            color: "white",
            backgroundColor: siwtchTransactionType(
              data.transaction_description
            )[1],
          }}
          className="basier-p4-caps"
        >
          {siwtchTransactionType(data.transaction_description)[0]}
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: 6,
            paddingLeft: 4,
          }}
        >
          <div className="basier-p4" style={{ paddingLeft: 0 }}>
            {dateParser(data.date)}
          </div>
        </div>
        <div className="basier-p3" style={{ marginTop: 12 }}>
          <span style={{ fontWeight: 900, color: "black" }}>Amount: </span>
          <span style={{ fontWeight: 400 }}>
            {formattedNumber(data.amount, 8)}
          </span>
        </div>
        <div className="basier-p3" style={{ marginTop: 6 }}>
          <span style={{ fontWeight: 900, color: "black" }}>
            {data.transaction_description === "deposit"
              ? "Sent to: "
              : "Sent from: "}
          </span>

          {data.storage !== null ? (
            <span style={{ fontWeight: 400 }}>{data.storage.name}</span>
          ) : data.account !== null ? (
            <span style={{ fontWeight: 400 }}>{data.account.name}</span>
          ) : (
            "Not specified"
          )}
        </div>
      </div>
    );

  }

  renderTransaction = (data) => {
    return (
      <div style={{ width: "100%", padding: 12 }}>
        <div
          style={{
            fontWeight: 900,
            width: 100,
            height: 30,
            padding: "0px 12px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: 20,
            textTransform: "uppercase",
            color: "white",
            backgroundColor: siwtchTransactionType(
              data.transaction_description
            )[1],
          }}
          className="basier-p4-caps"
        >
          {siwtchTransactionType(data.transaction_description)[0]}
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: 6,
            paddingLeft: 4,
          }}
        >
          <div className="basier-p4" style={{ paddingLeft: 0 }}>
            {dateParser(data.date)}
          </div>
        </div>
        <div className="basier-p3" style={{ marginTop: 12 }}>
          <span style={{ fontWeight: 900, color: "black" }}>Amount: </span>
          <span style={{ fontWeight: 400 }}>
            {formattedNumber(data.amount, 8)}
          </span>
        </div>
        <div className="basier-p3" style={{ marginTop: 6 }}>
          <span style={{ fontWeight: 900, color: "black" }}>
            {data.transaction_description === "deposit"
              ? "Sent to: "
              : "Sent from: "}
          </span>

          {data.storage !== null ? (
            <span style={{ fontWeight: 400 }}>{data.storage.name}</span>
          ) : data.account !== null ? (
            <span style={{ fontWeight: 400 }}>{data.account.name}</span>
          ) : (
            "Not specified"
          )}
        </div>
      </div>
    );
  };

  render() {
    return this.props.passedData !== null &&
      this.props.passedData !== undefined ? (
      <div>
        <div style={{ padding: "24px 48px", width: "100%" }}>
          <div
            style={{
              width: "100%",
              paddingBottom: 0,
              marginBottom: 24,
              borderBottom: "none",
            }}
          >
            We have found{" "}
            <span style={{ color: "#0078DB", fontWeight: "bold" }}>
              {this.props.passedData.candidates.length} transactions
            </span>{" "}
            that can be matched with your selection. Please select the correct
            match in the column on the right.
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "space-between",
              height: 334,
            }}
          >
            <div style={{flex: 1, marginRight: 6}}>
              <div
                className="basier-p4-caps"
                style={{ marginBottom: 6, paddingLeft: 6 }}
              >
                Match Details
              </div>

              <div
                style={{
                  width: "100%",
                  height: 312,
                  boxShadow: "0px 10px 10px rgba(225, 229, 233, 0.3)",
                  borderRadius: 20,
                }}
              >
                {this.renderTransaction(this.props.passedData["current"])}
              </div>
            </div>

            <div style={{flex: 1, marginLeft: 6,}}>
              <div
                className="basier-p4-caps"
                style={{ marginBottom: 6, paddingLeft: 6 }}
              >
                Match Candidates
              </div>

              <div
                style={{
                  width: "100%",
                  height: 312,
                  overflowY: 'scroll',
                  borderRadius: 20,
                }}
              >
                {this.props.passedData.candidates.map((v, k) => {
                    return (
                    <div 
                      key={k} 
                      onClick={() => this.props.selectCandidate(v.id)}
                      style={{margin: 4,  border: this.props.selected === v.id ? '2px solid #0078DB' : '1px solid rgba(243,245,246, 1)', borderRadius: 10, boxShadow: "0px 10px 10px rgba(225, 229, 233, 0.3)", cursoir: 'pointer'}} className="hover-border-select">
                    {this.renderSelector(v)}
                    </div>
                    )
                })}
              </div>
            </div>

            {/* <div style={{width: '50%'}}>
                            {this.renderTransaction(this.props.passedData['deposit'])}
                        </div> */}
          </div>

          {/* <div className="basier-p4-caps" style={{marginBottom: 6, marginTop: 24, paddingLeft: 6}}>Transfer Details</div> */}

          {/* <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', boxShadow: '0px 10px 10px rgba(225, 229, 233, 0.3)', minHeight: 75, borderRadius: 20}}>

                        <div style={{width: '100%', padding: 12}}>
                            <div className="basier-p3" style={{marginTop: 12,}}>
                                <span style={{fontWeight: 900, color: 'black'}}>Coin: </span>
                                <span style={{fontWeight: 400}}>{this.props.passedData['deposit']['base_currency']['name']}</span>
                            </div>

                            <div className="basier-p3" style={{marginTop: 12,}}>
                                <span style={{fontWeight: 900, color: 'black'}}>Fee: </span>
                                <span style={{fontWeight: 400}}>{formattedNumber(this.props.passedData['withdraw']['fee'], 8)} {this.props.passedData['deposit']['base_currency']['symbol']}</span>
                            </div>
                            
                            {this.props.simple !== true && 
                            <div style={{marginTop: 12,}}>
                                <span className="basier-p3"  style={{fontWeight: 900, color: 'black'}}>Tx Hash: </span>
                                <span className="basier-p4"  style={{fontWeight: 400}}>{this.props.passedData['deposit']['tid']}</span>
                            </div>}

                        </div>

                    </div> */}
        </div>
      </div>
    ) : null;
  }
}

const mapStateToProps = (state) => {
  return {
    inBitcoin: state.data.bitcoinPf,
    token: state.auth.token,
    user_id: state.auth.user_id,
    roles: state.auth.roles,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCurrentView: (view) => dispatch(actions.setCurrentView(view)),
    componentDataFetch: (
      scope,
      view,
      currency,
      user,
      portfolio,
      globalGraphData,
      portfolioGraphData,
      inBitcoin
    ) =>
      dispatch(
        actions.componentDataFetch(
          scope,
          view,
          currency,
          user,
          portfolio,
          globalGraphData,
          portfolioGraphData,
          inBitcoin
        )
      ),
    toggleModal: (data) => dispatch(interActions.toggleModal(data)),
    getFlatWallets: (user_id) =>
      dispatch(appwideActions.getFlatWalletList(user_id)),
    getAllPortfolioList: (user_id) =>
      dispatch(appwideActions.getAllPortfolioList(user_id)),
    getUserLimits: (user) => dispatch(appwideActions.getUserLimits(user)),
    getImports: (data) => dispatch(actions.getImports(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MatchedTransfer));
