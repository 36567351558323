import React, { Component } from 'react';

import { Creatable }  from 'react-select';
import { connect } from 'react-redux';
import { stat } from 'fs';
import {loadData} from '../../helpers/helpers'
import { backendUrl } from '../../variables'

const orderOptions = values => {
    return values.filter(v => v.isFixed).concat(values.filter(v =>  (!v.isFixed && v.isExisting && v.isScope) )).concat(values.filter(v =>  (!v.isFixed && v.isExisting && !v.isScope) )).concat(values.filter(v =>  (!v.isFixed && !v.isExisting) ));
  };
  

const styles = {
    control: styles => ({ ...styles, backgroundColor: 'transparent', height: 'fit-content', fontSize: 16, color: 'black', dispay:'inline-block'}),
    multiValue: (base, state) => {
      return state.data.isFixed ? { ...base, backgroundColor: '#0078DB' } : base;
    },
    multiValueLabel: (base, state) => {
      return state.data.isFixed
        ? { ...base, fontWeight: 'bold', color: 'white', paddingRight: 6 }
        : state.data.isScope ? { ...base, backgroundColor: '#4CDAC1', fontWeight: 'bold', color: 'white', paddingRight: 6 }
        : ( !state.data.isScope && state.data.isExisting) ? { ...base, backgroundColor: '#edb142', fontWeight: 'bold', color: 'white', paddingRight: 6 }
        :
        base;
    },
    
    multiValueRemove: (base, state) => {
      return state.data.isFixed ? { ...base, display: 'none' } : 
      state.data.isScope ? { ...base, backgroundColor: '#4CDAC1', color: 'white'} :
      ( !state.data.isScope && state.data.isExisting) ? { ...base, backgroundColor: '#edb142', fontWeight: 'bold', color: 'white', paddingRight: 6 }
      :
      base;
    },
};

  
const createNewOption = (label) => {
    
    return (

        {
            label,
            value: -1,
        }

    )


}

const createOption = (label, value) => {
    
    return (

        {
            label,
            value: value,
            isExisting: true,
            isScope: true,
        }

    )


}



class MenuSelector extends React.Component {

  state = {
    isLoading: false,
    options: [],
    value: orderOptions([{ value: this.props.user_id, label: `${this.props.user_first_name} ${this.props.user_last_name}`, isFixed: true }]),
  };

  componentDidMount() {

    let defaultOptions = [];
    if (this.props.orgId === -1 || this.props.orgId === null || this.props.orgId === undefined ) {
        defaultOptions = [];
    } else {
        this.props.orgs[this.props.orgId]['users'].map(val => {
            let temp = createOption(val['label'], val['value'])
            defaultOptions.push(temp)
        })
    }

    this.setState(state => ({...state, options: defaultOptions}))

  }

  componentDidUpdate(prevProps) {

      if (prevProps.orgId !== this.props.orgId) {


        let defaultOptions = [];
        if (this.props.orgId === -1 || this.props.orgId === null || this.props.orgId === undefined ) {
            defaultOptions = [];
        } else {
            this.props.orgs[this.props.orgId]['users'].map(val => {
                let temp = createOption(val['label'], val['value'])
                defaultOptions.push(temp)
            })
        }
    
        this.setState(state => ({...state, options: defaultOptions}))

      }
  }

  handleChange = (newValue) => {

    let newVals = [...this.state.value, newValue]
    this.setState(state => ({...state, value: newVals,}));
    this.props.handleAdd(newVals)

  };

  handleCreate = (inputValue) => {

    const checkOpts = inputValue.split(" ")
    this.setState({ isLoading: true });
    for (let obj of checkOpts) {


        let headers = { "content-type": "application/json" };
        headers["Authorization"] = `Token ${this.props.token}`;

        const data_fetch = loadData(`${backendUrl}/v1/check_user/?email=${obj}`, headers)
        data_fetch
            .then(json => {

                let newOption = null;
                let proceed = true;
                if (json['exist'] === true) {

                    const idCheck = json['data']['value']
                    const selected = this.state.value.filter(v => v.value === idCheck)

                    if (selected.length === 0) {
                        const existing = this.state.options.filter(v => v.value === idCheck)
                        if (existing.length > 0) {
                            newOption = existing[0];
                        } else {
                            newOption = json['data']
                        }
                    } else {
                        proceed = false
                        newOption = null; 
                    }
                    
                } else {

                    newOption = createNewOption(obj)

                }

                if (proceed) {
                    let newVals = [...this.state.value, newOption]
                    newVals = orderOptions(newVals)
                    this.setState(state => ({...state,
                        isLoading: false,
                        value: newVals,
                    }));
                    this.props.handleAdd(newVals)
                } else {
                    this.setState(state => ({...state,
                        isLoading: false}))
                }
                
            })
    }

  };


  onChange = (value, { action, removedValue }) => {

    switch (action) {
      case 'remove-value':
        this.setState(state => ({...state,
            value: value,
            }));
        this.props.handleAdd(value)          
          return;
      case 'pop-value':
        if (removedValue.isFixed) {
          return;
        }
        return;
      case 'clear': {
        value = this.state.value.filter(v => v.isFixed);
        this.setState({value: value});
        this.props.handleAdd(value)
        return 1;
      }
      default: {
        value = orderOptions(value)
        this.setState(state => ({...state,
            value: value,
          }));
        this.props.handleAdd(value)
        return
      }

    }

   

    }


  render() {
    const { isLoading, options, value } = this.state;
    return (
      <Creatable
        isMulti
        isClearable={value.some(v => !v.isFixed)}
        isDisabled={isLoading}
        isLoading={isLoading}
        onChange={this.onChange}
        onCreateOption={this.handleCreate}
        options={options}
        value={value}
        styles={styles}
      />
    );
  }
}

const mapStateToProps = state => {
    return {
      user_id: state.auth.user_id, 
      user_first_name: state.auth.user_first_name, 
      user_last_name: state.auth.user_last_name, 
      token: state.auth.token,
      orgs: state.appwide.orgs
    };
  };
  
  const mapDispatchToProps = dispatch => {
    return {

    };
  };
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(MenuSelector);
  