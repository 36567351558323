import React from 'react';
import { connect } from 'react-redux';
import AccountTable from './AccountTable'
import AccountDetail from './DetailComponents/AccountDetail'
import BriefImage from '../../../Media/GroupPort.svg';
import { withRouter} from 'react-router-dom';
import Plus from '../../../Media/Plus.svg'


class MainWalletSection extends React.PureComponent {

    state = {
        scope: 'all', 
        selected: null, 
    }

    componentDidMount() {
        if (this.props.givenRoute !== undefined && this.props.data !== null && this.props.data !== undefined) {
            this.setSelected()
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.givenRoute !== undefined && this.props.data !== null && this.props.data !== undefined && this.props.data !== prevProps.data) {
            this.setSelected()
        }
    }

    setSelected = () => {
        const base = this.props.data[this.props.user_id]
        let fil, stateData;
        let split = this.props.givenRoute.split('-')
        if (split[1] === 'exchange') {
            
            let exchange;
            try {
                exchange = base['exchange'][split[2]][split[0]]
            } catch(e) {}

            if (exchange !== undefined) {

                    const val = exchange
    
                    stateData = {
                        name: val.name.toUpperCase(), 
                        source_name: val.exchange_name, 
                        image: val.exchange_image, 
                        user: val.user_id, 
                        address: null, 
                        connected: val.connected,
                        target_id: val.target_id,
                        sub_name: null,
                        link: val.url,
                        id: val.id,
                        source_id: val.exchange_id,
                        custom_exchange_check: false,
                        date: val.created_at,
                        last_update: val.last_update_check,
                        wallets: val.wallets,
                        type: 'exchange'
                    }
    
            } else {
                let custom;
                try {
                    custom = base['custom'][split[2]][split[0]]
                } catch(e) {}
                if (custom !== undefined) {

                    const val = custom 
                    stateData = {
                        name: val.name.toUpperCase(), 
                        source_name: val.custom_exchange, 
                        image: null, 
                        address: null, 
                        user: val.user_id, 
                        connected: false,
                        target_id: val.target_id,
                        sub_name: null,
                        link: null,
                        id: val.id,
                        source_id: val.custom_exchange,
                        custom_exchange_check: true, 
                        date: val.created_at,
                        last_update: val.last_update_check,
                        wallets: val.wallets,
                        type: 'exchange'
                    }

                }

            }

        } else if (split[1] === 'storage') {
            let storage = [];
            try {
                storage = base['storage'][split[2]]
                if (storage === undefined) {
                    storage = []
                }
            } catch(e) {
                storage = []
            }
            fil = storage.filter(v => v.id == split[0])
            if (fil.length > 0) {

                const val = fil[0]

                stateData = {

                    name: val.name.toUpperCase(), 
                    source_name: val.coin_name, 
                    user: val.user_id, 
                    image: val.coin_image, 
                    address: val.address, 
                    connected: val.connector !== null,
                    connector: val.connector,
                    sub_name: val.ticker,
                    target_id: val.target_id,
                    link: val.explorer,
                    id: val.id,
                    source_id: val.wallet_coin,
                    date: val.created_at,
                    last_update: val.last_update_check,
                    wallets: val.wallets,
                    type: 'storage'
                    
                 }

            }

        }

        
        if (stateData !== undefined) {
            this.setState(state => ({...state, selected: stateData}))
        }

        window.history.pushState("", "", '/wallets');
        //this.props.history.push('/wallets')
    }

    changeScope = (new_scope) => {
        this.setState(state => ({...state, scope: new_scope}))
    }

    selectAccount = (data) => {

        this.setState(state => ({...state, selected: data}))

    }

    

    render () {

        return (

            this.props.data !== null && this.props.data !== undefined ? 

                <div style={{display: 'flex', alignItems: 'flex-start'}}>

                    <div style={{width: '25%', minWidth: 300, maxWidth: 400, height: 1250, borderRight: '1px solid #CCC', padding: '12px 24px'}}>

                        <div className="basier-p3" style={{display: 'flex', justifyContent: 'flex-start', width: '100%', textTransform: 'uppercase', height: 25}}>
                            <div
                                className="opacity-hover"
                                onClick={() => this.changeScope('all')}
                                style={{color: this.state.scope === 'all' ? '#0078DB' : '#6A7E93', cursor: 'pointer'}}
                                >
                                all
                            </div>
                            <div style={{marginRight: 12, marginLeft: 12}}>|</div>
                            <div
                                className="opacity-hover"
                                onClick={() => this.changeScope('exchange')}
                                style={{color: this.state.scope === 'exchange' ? '#0078DB' : '#6A7E93', cursor: 'pointer'}}
                                >
                                exchange
                            </div>
                            <div style={{marginRight: 12, marginLeft: 12}}>|</div>
                            <div
                                className="opacity-hover"
                                onClick={() => this.changeScope('storage')}
                                style={{color: this.state.scope === 'storage' ? '#0078DB' : '#6A7E93', cursor: 'pointer'}}
                                >
                                storage
                            </div>
                        </div>

                        <div style={{height: 575}}>
                            <AccountTable data={this.props.data} scope={this.state.scope} selectAccount={this.selectAccount} selected={this.state.selected} />
                        </div>
                    </div>
                    
                    <div style={{flex: 1}}>
                        {this.state.selected !== null && this.state.selected !== undefined ? 

                            <AccountDetail data={this.state.selected} scope={this.state.selected.type} />
                            
                        : 
                        
                        <div style={{width: '100%', display: 'flex', alignItems: 'center', flexDirection: 'column', marginTop: 48}}>

                            <img src={BriefImage} alt="select" style={{height: 300}} />
                            <div className="basier-p3" style={{marginTop: 12}}>Select an account or wallet to see its details.</div>

                        </div>

                        }
                    </div>
                    
                </div>
            
            :

            null
            
        )
    }


}

const mapStateToProps = state => {
    return {
        user_id: state.auth.user_id
    };
};

const mapDispatchToProps = dispatch => {
    return {
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MainWalletSection))
