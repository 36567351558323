import React, { Component } from "react";
import LpQuoteSummary from "./LPQuoteSummary";
import LPPoolToken from "./LPPoolToken";
import LpTokenBalance from "./LPTokenBalance";
import Moment from "moment";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import LPProfitSection from "./LPProfitSection";

function renderDex(dex) {
  switch (dex) {
    case "uniswap": {
      return { name: "uniswap", color: "#FE1C87" };
    }
    default: {
      return { name: dex, color: "#6A7E93" };
    }
  }
}

class OverallLPBox extends Component {
  render() {
    return this.props.pools.map((poolData, k) => {
      if (poolData.initial_lp_balance == 0 || Math.round(poolData.initial_value * 1e4) / 1e4 === 0 || Math.round(poolData.initial_lp_balance * 1e16) / 1e16 === 0) {
        return;
      }
      console.log('render', poolData)
      const needsEstimate =
        Math.round(
          (parseFloat(poolData.initial_lp_balance) /
            parseFloat(poolData.current_lp_balance)) *
            1e8
        ) /
          1e8 <
        0.98;
      return (
        <div key={k} style={styles.overallBox}>
          <LPProfitSection poolData={poolData} />
          <div style={styles.bodyDivider}>
            <div style={{ flex: 2 }}>
              <LPPoolToken data={poolData} needsEstimate={needsEstimate} />
              <LpTokenBalance data={poolData} needsEstimate={needsEstimate} />
            </div>
            <div style={{ flex: 3 }}>
              <LpQuoteSummary data={poolData} needsEstimate={needsEstimate} />
            </div>
          </div>
          <div style={styles.bottomContainer}>
            {needsEstimate && (
              <div style={styles.bottom} className="basier-p4">
                This Liquidity Pool Token has been withdrawn, most likely to be
                staked. The balances presented here are an estimation that
                represent your share in the pool had you not staked your LP
                token.
              </div>
            )}
          </div>
        </div>
      );
    });
  }
}

const styles = {
  overallBox: {
    minHeight: 150,
    width: "100%",
    backgroundColor: "white",
    borderRadius: 20,
    marginTop: 12,
    marginBottom: 12,
    display: "flex",
    flexDirection: "column",
    boxShadow: "0px 10px 10px rgba(225, 229, 233, 0.3)",
    border: "1px solid #E1E5E9",
    padding: "12px 24px",
    paddingBottom: 24,
  },
  top: {
    height: 60,
    width: "100%",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    marginBottom: 12,
  },
  bottom: {
    padding: "6px 12px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    border: "1px solid #e0a03d",
    borderRadius: 10,
    backgroundColor: "#e0a03d1A",
    marginTop: 12,
    height: 40,
  },
  bottomContainer: {},
  titleText: {
    fontWeight: "bold",
    textTransform: "uppercase",
  },
  bodyDivider: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
};
export default OverallLPBox;
