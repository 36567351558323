import * as actionTypes from "./actionTypes";
import { loadData, api_timeout_caller } from '../../helpers/helpers';
import * as interActions from './interactions'
import { backendUrl } from '../../variables'

export const updateGlobals = (data) => {
    return {
        type: actionTypes.UPDATE_GLOBALS, 
        data
    };
};

export const saveTv = (data) => {
    return {
        type: actionTypes.SAVE_TV, 
        data
    };
};

/////////////////
/// ALL DATA /// 
///////////////
export const componentDataFetch = (scope, view, currency, user, portfolio, globalGraphData, portfolioGraphData, inBitcoin, tokenProvided) => {    
        switch(true) {

            case (view === 1): {
                return dispatch => {
                    //dispatch(getGraphData(currency, user, globalGraphData, inBitcoin, tokenProvided))
                    dispatch(getConnectedBalances(user, currency, inBitcoin))
                    dispatch(getAccountPositions(user, currency, inBitcoin))
                    dispatch(getTransferBalances(user))
                    dispatch(getAllBalances(currency, user, inBitcoin, tokenProvided))
                    dispatch(getSingleBalances(currency, user, inBitcoin, tokenProvided))
                    //dispatch(getTotalValue(currency, user, portfolio, inBitcoin, 'total', tokenProvided))
                    dispatch(getSubsData(user, tokenProvided))
                }
            }
            case (view === 2  && (scope === 'get')): {
                return dispatch => {
                    //dispatch(getPortfolioData(currency, user, inBitcoin))
                    dispatch(getSinglePortfolioData(currency, portfolio, inBitcoin))
                    dispatch(getRoiEvol(user, portfolio, currency, inBitcoin))
                    dispatch(getWalletBalance(portfolio));
                    //dispatch(getTotalValue(currency, user, portfolio, inBitcoin, 'portfolio')) 
                    dispatch(getPortfolioGraphData(currency, portfolio, portfolioGraphData, inBitcoin))   
                    dispatch(getHoldings(user, portfolio))           
                }
            }
            case (view === 2  && (scope === 'update')): { //overview
                return dispatch => {
                    //dispatch(getPortfolioData(currency, user, inBitcoin))
                    dispatch(getSinglePortfolioData(currency, portfolio, inBitcoin))
                    //dispatch(getWalletBalance(portfolio));
                    //dispatch(getTotalValue(currency, user, portfolio, inBitcoin, 'portfolio'))
                    dispatch(getPortfolioGraphData(currency, portfolio, portfolioGraphData, inBitcoin)) 
                    //dispatch(getRoiEvol(user, portfolio))
                    dispatch(getHoldings(user, portfolio))

                }
            }
            
           case (view === 4) && (scope === 'get'): { //profit
                return dispatch => {
                }
            }
            case (view === 4) && (scope === 'update'): {
                return dispatch => {
                    dispatch(getRoiEvol(user, portfolio, currency, inBitcoin))
                    dispatch(getSinglePortfolioData(currency, portfolio, inBitcoin))
                    //dispatch(getHoldings(user, portfolio))
                }   
            }
            case (view === 5) && (scope === 'get'): { //transaction
                return dispatch => {
                    //dispatch(getSinglePortfolioData(currency, portfolio, inBitcoin))
                    //dispatch(getTotalValue(currency, user, portfolio, inBitcoin, 'portfolio'))
                    //dispatch(getTransaction(portfolio))
                }
            }
            case (view === 5) && (scope === 'update'): {
                return dispatch => {
                    //dispatch(getSinglePortfolioData(currency, portfolio, inBitcoin))
                    //dispatch(getTransaction(portfolio))
                    //dispatch(getRoiEvol(user, portfolio))
                    //dispatch(getWalletBalance(portfolio));
                    //dispatch(getHoldings(user, portfolio))
                }
            }
            case (view === 51) && (scope === 'get'): {
                return dispatch => {
                    //dispatch(getSinglePortfolioData(currency, portfolio, inBitcoin))
                    //dispatch(getTotalValue(currency, user, portfolio, inBitcoin, 'portfolio'))
                    //dispatch(getTransaction(portfolio))
                }
            }
            case (view === 51) && (scope === 'update'): {
                return dispatch => {
                    //dispatch(getSinglePortfolioData(currency, portfolio, inBitcoin))
                    //dispatch(getTransaction(portfolio))
                    dispatch(getHoldings(user, portfolio))

                }
            }
            case (view === 6) && (scope === 'get'): { //collab + balances
                return dispatch => {
                    dispatch(getDeposits(portfolio)); 
                    dispatch(getWalletBalance(portfolio));
                }
            }
            case (view === 6) && (scope === 'update'): {
                return dispatch => {
                    //dispatch(getSinglePortfolioData(currency, portfolio, inBitcoin))
                    dispatch(getDeposits(portfolio));
                    dispatch(getWalletBalance(portfolio));
                    dispatch(getSinglePortfolioData(currency, portfolio, inBitcoin))
                }
            }
            case (view === 95): {
                return dispatch => {
                    dispatch(getSubsData(user))
                    //dispatch(getSinglePortfolioData(currency, portfolio, inBitcoin))
                    //dispatch(getDeposits(portfolio))
                }
            }
            case (view === 291): {
                return dispatch => {
                    //dispatch(getSubsData(user))
                    //dispatch(getSinglePortfolioData(currency, portfolio, inBitcoin))
                    //dispatch(getDeposits(portfolio))
                }
            }
            default: 
            return dispatch => {
                //dispatch(getSinglePortfolioData(currency, portfolio, inBitcoin))
                //dispatch(getDeposits(portfolio))
            }
        }
    
};




// Fetch data for any component 

// Fetch data from APIs


export const updateGraphScope = (data, scope, portfolio) => {

    if (scope === "portfolio") {
        return {
            type: actionTypes.PF_GRAPH_SCOPE, 
            data
        } 
    } else {
        return {
            type: actionTypes.GLOBAL_GRAPH_SCOPE, 
            data
        }
    }
        
}

export const setGraphScope = (data, scope, portfolio) => {

    return dispatch => {
        dispatch(updateGraphScope(data, scope, portfolio))
    }
    
}


export const setPfScope = (data) => {
    return {
        type: actionTypes.SET_PF_SCOPE, 
        data
    };
};

export const overwritePorts = (data) => {
    return {
        type: actionTypes.OVERWRITE_PORTS, 
        data
    };
};

/////////////////
///// SUBS ///// 
///////////////
export const updateHolds = (data) => {
    return {
        type: actionTypes.PORT_HOLDINGS_SUCCESS, 
        data
    };
};

export const getHoldings = (user, portfolioId) => {

    let headers = { "content-type": "application/json" };
    if (localStorage.hasOwnProperty("user")) {
        let user = JSON.parse(localStorage.getItem("user"));
        let token = user.rahat_t
        headers["Authorization"] = `Token ${token}`;
    } 
    return dispatch => {

        const data_fetch = loadData(`${backendUrl}/v1/portfolio/balances/${portfolioId}/?u=${user}&simple_balance=1`, headers)
        data_fetch
            .then(json => 
                
                {   
                    dispatch(updateHolds(json['data']));
                }
                
            )
            .catch(err => {
                
            })
    };


}

/////////////////
///// CONNECTED BALANCES ///// 
///////////////
export const updateConnectedBalances = (data) => {
    return {
        type: actionTypes.ACCOUNT_CONNECTED_BALANCES, 
        data
    };
};

export const getConnectedBalances = (user, currency, inBitcoin) => {

    let headers = { "content-type": "application/json" };
    if (localStorage.hasOwnProperty("user")) {
        let user = JSON.parse(localStorage.getItem("user"));
        let token = user.rahat_t
        headers["Authorization"] = `Token ${token}`;
    } 
    const currency_call = inBitcoin === 'bitcoin' ? 'bitcoin' : currency
    return dispatch => {

        const data_fetch = loadData(`${backendUrl}/v1/global/connected_balances/?u=${user}&c=${currency_call}`, headers)
        data_fetch
            .then(json => 
                
                {   
                    dispatch(updateConnectedBalances(json['data']));
                }
                
            )
            .catch(err => {
                
            })
    };


}


/////////////////
///// Account POS ///// 
///////////////
export const updateAccountPositions = (data) => {
    return {
        type: actionTypes.ACCOUNT_POSITION_BALANCES, 
        data
    };
};

export const getAccountPositions = (user, currency, inBitcoin) => {

    let headers = { "content-type": "application/json" };
    if (localStorage.hasOwnProperty("user")) {
        let user = JSON.parse(localStorage.getItem("user"));
        let token = user.rahat_t
        headers["Authorization"] = `Token ${token}`;
    } 
    const currency_call = inBitcoin === 'bitcoin' ? 'bitcoin' : currency
    return dispatch => {

        const data_fetch = loadData(`${backendUrl}/v1/global/positions/?u=${user}&c=${currency_call}`, headers)
        data_fetch
            .then(json => 
                
                {   
                    dispatch(updateAccountPositions(json['data']));
                }
                
            )
            .catch(err => {
                
            })
    };


}

export const updateTransferBalances = (data) => {
    return {
        type: actionTypes.ACCOUNT_TRANSFER_BALANCES, 
        data
    };
};

export const getTransferBalances = (user) => {

    let headers = { "content-type": "application/json" };
    if (localStorage.hasOwnProperty("user")) {
        let user = JSON.parse(localStorage.getItem("user"));
        let token = user.rahat_t
        headers["Authorization"] = `Token ${token}`;
    } 
    return dispatch => {

        const data_fetch = loadData(`${backendUrl}/v1/global/transfer_balances/?u=${user}`, headers)
        data_fetch
            .then(json => 
                
                {   
                    dispatch(updateTransferBalances(json['data']));
                }
                
            )
            .catch(err => {
                
            })
    };


}


/////////////////
///// SUBS ///// 
///////////////
export const updateImports = (data) => {
    return {
        type: actionTypes.ACC_TR_IMPORT_SUCCESS, 
        data
    };
};

const flattenFilters = (data) => {
    return {
        type: actionTypes.FLATTEN_FILTERS_IMPORT,
        data
    }
}

export const getImports = (user, tokenProvided, type, page, skip_filter, filters, accountId, accountType) => {
    
    let headers = { "content-type": "application/json" };
    if (tokenProvided !== undefined) {
        headers["Authorization"] = `Token ${tokenProvided}`;
    }
    else if (localStorage.hasOwnProperty("user")) {
        let user = JSON.parse(localStorage.getItem("user"));
        let token = user.rahat_t
        headers["Authorization"] = `Token ${token}`;
    } 

    if (type === undefined) {
        type = 'spot'
    }

    if (skip_filter === undefined) {
        skip_filter = '0'
    }

    if (accountId === undefined) {
        accountId = 'all'
    }

    let additionalFilterString = ''
    if (filters !== null && filters !== undefined) {
        additionalFilterString = serialize(filters)
    }

    if (page === undefined) {
        page = 0
    }

    return dispatch => {
        dispatch( {type: actionTypes.ACC_TR_IMPORT_REQUEST} )
        const data_fetch = loadData(`${backendUrl}/v1/connectors/transactions/?u=${user}&type=${type}&account=${accountId}&page=${page}&skip_filter=${skip_filter}&ascope=${accountType}&${additionalFilterString}`, headers)
        data_fetch
            .then(json => 
                
                {   
                    
                    let data = json['data']
                    /* if (type === 'position') {
                        data = {
                            ...json['data'], 
                            positions: json.data.transactions
                        }
                    } */
                    if (data.filters !== null) {
                        dispatch(flattenFilters(json['data'].filters))
                        delete data['filters']
                    }
                    dispatch(updateImports({data: data, skip_filter: skip_filter}));
                }
                
            )
            .catch(err => {
                console.log('error import', err)
            })
    };


}

/////////////////
///// SUBS ///// 
///////////////
export const updateSubs = (data) => {
    return {
        type: actionTypes.GET_SUBS, 
        data
    };
};

const getSubsData = (user, tokenProvided) => {

    let headers = { "content-type": "application/json" };
    if (tokenProvided !== undefined) {
        headers["Authorization"] = `Token ${tokenProvided}`;
    }
    else if (localStorage.hasOwnProperty("user")) {
        let user = JSON.parse(localStorage.getItem("user"));
        let token = user.rahat_t
        headers["Authorization"] = `Token ${token}`;
    } 
    return dispatch => {

        const data_fetch = loadData(`${backendUrl}/v1/coin_subs/?u=${user}`, headers)
        data_fetch
            .then(json => 
                
                {   
                    dispatch(updateSubs(json['data']));
                }
                
            )
            .catch(err => {
                
            })
    };


}


/////////////////
////BAL RISK /// 
///////////////
const updateBalanceRisk = (data) => {
    return {
        type: actionTypes.BALANCE_RISK_SUCCESS, 
        data
    };
};

const updateUserRisk = (data) => {
    return {
        type: actionTypes.USER_RISK_SUCCESS, 
        data
    };
};

export const getBalanceRisk = (portfolio, scope) => {

    let headers = { "content-type": "application/json" };
    if (localStorage.hasOwnProperty("user")) {
        let user = JSON.parse(localStorage.getItem("user"));
        let token = user.rahat_t
        headers["Authorization"] = `Token ${token}`;
    }
    return dispatch => {

        const data_fetch = loadData(`${backendUrl}/v1/balance_analysis/?p=${portfolio}&scope=${scope}`, headers)
        data_fetch
            .then(json => 
                
                {      
                    if (scope === 'storage') {
                        dispatch(updateBalanceRisk(json['data']));
                    } else if (scope === 'user') {
                        dispatch(updateUserRisk(json['data']));
                    }
                }
                
            )
            .catch(err => {
                
            })
    };


}

const updateRoiEvol = (data) => {
    return {
        type: actionTypes.ROI_EVOL_SUCCESS, 
        data
    };
};

const getRoiEvol = (user, portfolio, currency, inBitcoin) => {

    let headers = { "content-type": "application/json" };
    if (localStorage.hasOwnProperty("user")) {
        let user = JSON.parse(localStorage.getItem("user"));
        let token = user.rahat_t
        headers["Authorization"] = `Token ${token}`;
    }
    const currency_call = inBitcoin === 'bitcoin' ? 'bitcoin' : currency
    return dispatch => {

        const data_fetch = loadData(`${backendUrl}/v1/roi_evol/?u=${user}&p=${portfolio}&c=${currency_call}`, headers)
        data_fetch
            .then(json => 
                
                {   
                    dispatch(updateRoiEvol(json['data']));
                }
                
            )
            .catch(err => {
                
            })
    };


}


/////////////////
/// OW GRAPH /// 
///////////////

// Update Global (Overivew) Graph 
export const updateGraph = (data) => {
    return {
        type: actionTypes.UPDATE_GRAPH_SUCCESS, 
        data
    };
};

export const callUserList = (portfolio) => {
    let headers = { "content-type": "application/json" };
    if (localStorage.hasOwnProperty("user")) {
        let user = JSON.parse(localStorage.getItem("user"));
        let token = user.rahat_t
        headers["Authorization"] = `Token ${token}`;
    }
    return dispatch => {

        const data_fetch = loadData(`${backendUrl}/v1/users_portfolio/?p=${portfolio}`, headers)
        data_fetch
            .then(json => 
                
                {   
                    dispatch(updateUserList(json['data']));
                }
                
            )
            .catch(err => {
                
            })
    };


}

const updateUserList = (data) => {
    return {
        type: actionTypes.UPDATE_SCOPED_USER, 
        data
    };
}

const getGraphData = (currency, user, globalData, inBitcoin, tokenProvided) => {

    let headers = { "content-type": "application/json" };
    if (tokenProvided !== undefined) {
        headers["Authorization"] = `Token ${tokenProvided}`;
    }
    else if (localStorage.hasOwnProperty("user")) {
        let user = JSON.parse(localStorage.getItem("user"));
        let token = user.rahat_t
        headers["Authorization"] = `Token ${token}`;
    } 
    
    return dispatch => {
        dispatch( {type: actionTypes.UPDATE_GRAPH_REQUEST} )
        const data_fetch = loadData(`${backendUrl}/v1/total_value/?u=${user}&c=${currency}&period=${globalData.period}&start=${globalData.start}&end=${globalData.end}&scope=${inBitcoin}`, headers)
        data_fetch
            .then(json => {
                dispatch(updateGraph(json['data']));
            })
            .catch(err => {
                //const err_message = err.message || "Problem fetching data. Please try refreshing the page"
                //dispatch({type: actionTypes.UPDATE_GRAPH_ERROR})
                //dispatch(interActions.showSnack({message: err_message, variant: "error"}))
            })
    };
};

// Change the time of the overview Graph (e.g. 1m, 3m, etc.)
export const setGlobalTime = (data) => {
    return {
        type: actionTypes.UPDATE_GLOBAL_TIME, 
        data
    };
}

/////////////////
/// PF GRAPH /// 
///////////////

// Update data of graph on portfolio pages 
export const updatePortfolioGraph = (data) => {
    return {
        type: actionTypes.UPDATE_PORTFOLIO_GRAPH_SUCCESS, 
        data
    };
};

const getPortfolioGraphData = (currency, portfolio, globalData, inBitcoin) => {
    let headers = { "content-type": "application/json" };
    if (localStorage.hasOwnProperty("user")) {
        let user = JSON.parse(localStorage.getItem("user"));
        let token = user.rahat_t
        headers["Authorization"] = `Token ${token}`;
    }
    return dispatch => {
        dispatch( {type: actionTypes.UPDATE_PORTFOLIO_GRAPH_REQUEST} )
        const data_fetch = loadData(`${backendUrl}/v1/portfolio-values/?p=${portfolio}&c=${currency}&period=${globalData.period}&start=${globalData.start}&end=${globalData.end}&scope=${inBitcoin}`, headers)
        data_fetch
            .then(json => 
                
                {   
                    dispatch(updatePortfolioGraph(json['data']));
                }
                
            )
            .catch(err => {
                //const err_message = err.message || "Problem fetching data. Please try refreshing the page"
                //dispatch({type: actionTypes.UPDATE_PORTFOLIO_GRAPH_ERROR})
                //dispatch(interActions.showSnack({message: err_message, variant: "error"}))
            })
    };
};

// Change the time of the overview Graph (e.g. 1m, 3m, etc.)
export const setPortfolioTime = (data) => {
    return {
        type: actionTypes.UPDATE_PORTFOLIO_TIME, 
        data
    };
}


/////////////////
// ALL PF DATA / 
///////////////

// Get data of portfolios (profit and such)
export const updatePortfolio = (data) => {
    return {
        type: actionTypes.UPDATE_ALL_PORTFOLIOS_SUCCESS, 
        data
    };
};


/////////////////
/// 1 PF DATA //
///////////////

// Get data of portfolios (profit and such)
export const updateSinglePortfolio = (data) => {
    return {
        type: actionTypes.UPDATE_PORTFOLIO_SUCCESS, 
        data, 
    };
};


const getSinglePortfolioData = (currency, portfolio, inBitcoin) => {
    let headers = { "content-type": "application/json" };
    if (localStorage.hasOwnProperty("user")) {
        let user = JSON.parse(localStorage.getItem("user"));
        let token = user.rahat_t
        headers["Authorization"] = `Token ${token}`;
    }

    const currency_call = inBitcoin === 'bitcoin' ? 'bitcoin' : currency  

    return dispatch => {
        dispatch( {type: actionTypes.UPDATE_PORTFOLIO_REQUEST} )
        const data_fetch = loadData(`${backendUrl}/v1/assets/?p=${portfolio}&c=${currency_call}`, headers)
        data_fetch
            .then(json => 
                
                { 
                    dispatch(updateSinglePortfolio({data: json['data'], portfolio: portfolio}));
                }
                
            )
            .catch(err => {
                console.log(err)
                console.log(err.message)
            })
    };
};


/////////////////
/// OW ASSETS // 
///////////////

export const updateTotalValue = (data) => {
    return {
        type: actionTypes.UPDATE_TOTAL_VALUE_SUCCESS, 
        data
    }
}

export const updateTotalPortfolioValue = (data) => {
    return {
        type: actionTypes.UPDATE_TOTAL_PORTFOLIO_VALUE_SUCCESS, 
        data
    }
}

const getTotalValue = (currency, user, portfolio, inBitcoin, scope, tokenProvided) => {
    let headers = { "content-type": "application/json" };
    if (tokenProvided !== undefined) {
        headers["Authorization"] = `Token ${tokenProvided}`;
    }
    else if (localStorage.hasOwnProperty("user")) {
        let user = JSON.parse(localStorage.getItem("user"));
        let token = user.rahat_t
        headers["Authorization"] = `Token ${token}`;
    } 

    return dispatch => {

        dispatch(updateTotalValue([]))
    };
}

// Update global assets list (Overview page )
export const updateTotalBalances = (data) => {
    return {
        type: actionTypes.UPDATE_ALL_BALANCES_SUCCESS, 
        data
    }
}

const getAllBalances = (currency, user, inBitcoin, tokenProvided) => {
    let headers = { "content-type": "application/json" };
    if (tokenProvided !== undefined) {
        headers["Authorization"] = `Token ${tokenProvided}`;
    }
    else if (localStorage.hasOwnProperty("user")) {
        let user = JSON.parse(localStorage.getItem("user"));
        let token = user.rahat_t
        headers["Authorization"] = `Token ${token}`;
    } 

    const currency_call = (inBitcoin === 'bitcoin' ? 'bitcoin' : currency)


    return dispatch => {
        dispatch( {type: actionTypes.UPDATE_ALL_BALANCES_REQUEST})
        const data_fetch = loadData(`${backendUrl}/v1/global/balances/?u=${user}&c=${currency_call}`, headers)
        data_fetch
        .then(json => 
                
                { 
                    dispatch(updateTotalBalances(json['data']));
                }
                
            )
            .catch(res => console.log('error'))
    };
}


// Update global assets list (Overview page )
export const updatePortfolioBalances = (data) => {
    return {
        type: actionTypes.UPDATE_SINGLE_BALANCES_SUCCESS, 
        data
    }
}

const getSingleBalances = (currency, user, inBitcoin, tokenProvided) => {
    let headers = { "content-type": "application/json" };
    if (tokenProvided !== undefined) {
        headers["Authorization"] = `Token ${tokenProvided}`;
    }
    else if (localStorage.hasOwnProperty("user")) {
        let user = JSON.parse(localStorage.getItem("user"));
        let token = user.rahat_t
        headers["Authorization"] = `Token ${token}`;
    } 

    const currency_call = (inBitcoin === 'bitcoin' ? 'bitcoin' : currency)


    return dispatch => {
        dispatch( {type: actionTypes.UPDATE_SINGLE_BALANCES_REQUEST})
        const data_fetch = loadData(`${backendUrl}/v1/global/portfolios/balances/?u=${user}&c=${currency_call}`, headers)
        data_fetch
        .then(json => 
                
                { 
                    dispatch(updatePortfolioBalances(json['data']));
                }
                
            )
            .catch(res => console.log('error'))
    };
}

/////////////////
/// FIAT LIST // 
///////////////

// set list of supported fiats
export const updateCurrency = (data) => {
    return {
        type: actionTypes.UPDATE_FIAT, 
        data
    }
}

export const filterFiatList = (data) => {
    return {
        type: actionTypes.FILTER_FIAT, 
        data
    }
}

export const setFiatList = () => {
    let headers = { "content-type": "application/json" };
    if (localStorage.hasOwnProperty("user")) {
        let user = JSON.parse(localStorage.getItem("user"));
        let token = user.rahat_t
        headers["Authorization"] = `Token ${token}`   
    }
    return dispatch => {
        const data_fetch = loadData(`${backendUrl}/v1/cryptos/?type=fiat`, headers)
        data_fetch
            .then(json => 
                
                { 
                    dispatch(updateCurrency(json['data']));
                }
                
            )
    };
}


/////////////////
/// PORT LIST // 
///////////////

// set list of supported fiats
export const updatePortfoliosList = (data) => {
    return {
        type: actionTypes.UPDATE_PF_LIST, 
        data
    }
}

export const fetchPortfoliosList = (user) => {

    let headers = { "content-type": "application/json" };
    if (localStorage.hasOwnProperty("user")) {
        let user = JSON.parse(localStorage.getItem("user"));
        let token = user.rahat_t
        headers["Authorization"] = `Token ${token}`;
    }

    return dispatch => {

        const data_fetch = loadData(`${backendUrl}/v1/portfolio_users/?u=${user}`, headers)
        data_fetch
            .then(json => 
                
                {   
                    dispatch(updatePortfoliosList(json['data']));
                }
                
            )
            .catch(err => {
                const err_message = err.message || "Problem fetching data. Please try refreshing the page"
                //dispatch(interActions.showSnack({message: err_message, variant: "error"}))
            })
    };
}

/////////////////
/// TRANSAC //// 
///////////////

const serialize = function(obj) {
    var str = [];
    for (var p in obj) {
      if (p === 'date' && obj.hasOwnProperty(p) ) {
        if (obj[p] !== 'all') {
            for (var j in obj[p]) {
                let val = obj[p][j]
                try { val = obj[p][j].join(',') } catch(e) {}
                str.push(encodeURIComponent(j) + "=" + encodeURIComponent(val.toISOString()))
            }
        }
      }
      else if (obj.hasOwnProperty(p)) {
        let val = obj[p]
        try { val = obj[p].join(',') } catch(e) {}
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(val))
      }
    }
    return str.join("&");
  }

const updateTransaction = (data) => {
    return {
        type: actionTypes.GET_TRANSACTION_SUCCESS, 
        data
    };
};

export const getTransaction = (user_id, portfolio, page, skip_filters, filters, account, ascope) => {

    let headers = { "content-type": "application/json" };
    if (localStorage.hasOwnProperty("user")) {
        let user = JSON.parse(localStorage.getItem("user"));
        let token = user.rahat_t
        headers["Authorization"] = `Token ${token}`;
    }
    if (skip_filters === undefined) {
        skip_filters = true
    }

    let additionalFilterString = ''
    if (filters !== null && filters !== undefined) {
        additionalFilterString = serialize(filters)
    }
    return dispatch => {
        dispatch( {type: actionTypes.GET_TRANSACTION_REQUEST} )
        let url = `${backendUrl}/v1/transactions/?u=${user_id}&p=${portfolio}&page=${page}&skip_filter=${skip_filters}&${additionalFilterString}`
        if (account === true) {
            url = `${backendUrl}/v1/transactions/?u=${user_id}&a=${portfolio}&ascope=${ascope}&page=${page}&skip_filter=${skip_filters}&${additionalFilterString}`
        }
        const data_fetch = loadData(url, headers)
        data_fetch
            .then(json => {
                dispatch(updateTransaction(json['data']));
            })
            .catch(err => {
                const err_message = err.message || "Problem fetching data. Please try refreshing the page"
                dispatch({type: actionTypes.GET_TRANSACTION_ERROR})
                //dispatch(interActions.showSnack({message: err_message, variant: "error"}))
            })
    };
};


/////////////////
/// TRANSAC //// 
///////////////

const updatePositionEntries = (data) => {
    return {
        type: actionTypes.GET_POS_TRANSACTION_SUCCESS, 
        data
    };
};

export const getPositionEntries = (user_id, portfolio, page, skip_filters, filters, account, ascope) => {

    let headers = { "content-type": "application/json" };
    if (localStorage.hasOwnProperty("user")) {
        let user = JSON.parse(localStorage.getItem("user"));
        let token = user.rahat_t
        headers["Authorization"] = `Token ${token}`;
    }
    if (skip_filters === undefined) {
        skip_filters = true
    }

    let additionalFilterString = ''
    if (filters !== null && filters !== undefined) {
        additionalFilterString = serialize(filters)
    }
    return dispatch => {
        dispatch( {type: actionTypes.GET_POS_TRANSACTION_REQUEST} )
        let url = `${backendUrl}/v1/positions/entries/?u=${user_id}&p=${portfolio}&page=${page}&skip_filter=${skip_filters}&${additionalFilterString}`
        if (account === true) {
            url = `${backendUrl}/v1/positions/entries/?u=${user_id}&a=${portfolio}&ascope=${ascope}&page=${page}&skip_filter=${skip_filters}&${additionalFilterString}`
        }
        const data_fetch = loadData(url, headers)
        data_fetch
            .then(json => {
                dispatch(updatePositionEntries(json['data']));
            })
            .catch(err => {
                const err_message = err.message || "Problem fetching data. Please try refreshing the page"
                dispatch({type: actionTypes.GET_POS_TRANSACTION_ERROR})
                //dispatch(interActions.showSnack({message: err_message, variant: "error"}))
            })
    };
};

/////////////////
/// DEPOSIT //// 
///////////////

const updateDeposits = (data) => {
    return {
        type: actionTypes.GET_DEPOSITS_SUCCESS, 
        data
    };
};

const getDeposits = (portfolio) => {

    let headers = { "content-type": "application/json" };
    if (localStorage.hasOwnProperty("user")) {
        let user = JSON.parse(localStorage.getItem("user"));
        let token = user.rahat_t
        headers["Authorization"] = `Token ${token}`;
    }
    return dispatch => {
        dispatch( {type: actionTypes.GET_DEPOSITS_REQUEST} )
        const data_fetch = loadData(`${backendUrl}/v1/deposits/?p=${portfolio}`, headers)
        data_fetch
            .then(json => {
                dispatch(updateDeposits(json['data']));
            })
            .catch(err => {
                const err_message = err.message || "Problem fetching data. Please try refreshing the page"
                dispatch({type: actionTypes.GET_DEPOSITS_ERROR})
                //dispatch(interActions.showSnack({message: err_message, variant: "error"}))
            })
    };
};

/////////////////
// WALLET BAL // 
///////////////

const updateWalletBalance = (data) => {
    return {
        type: actionTypes.GET_WALLET_BALANCE_SUCCESS, 
        data
    };
};

export const getWalletBalance = (portfolio) => {

    let headers = { "content-type": "application/json" };
    if (localStorage.hasOwnProperty("user")) {
        let user = JSON.parse(localStorage.getItem("user"));
        let token = user.rahat_t
        headers["Authorization"] = `Token ${token}`;
    }
    return dispatch => {
        dispatch( {type: actionTypes.GET_WALLET_BALANCE_REQUEST} )
        const data_fetch = loadData(`${backendUrl}/v1/wallet_balance/?p=${portfolio}`, headers)
        data_fetch
            .then(json => {
                dispatch(updateWalletBalance(json['data']));
            })
            .catch(err => {
                const err_message = err.message || "Problem fetching data. Please try refreshing the page"
                dispatch({type: actionTypes.GET_WALLET_BALANCE_ERROR})
                //dispatch(interActions.showSnack({message: err_message, variant: "error"}))
            })
    };
};

/////////////////
/// MENU /////// 
///////////////

// Set the current Path (for calls) and menu Portfolio 
export const setMenuPortfolio = data => {
    return {
        type: actionTypes.UPDATE_MENU, 
        data
    };
}
export const setMenuPath = data => {
    return {
        type: actionTypes.UPDATE_MENU_PATH, 
        data
    };
}

export const setCurrentView = data => {
    return {
        type: actionTypes.SET_CURRENT_VIEW, 
        data
    };
}