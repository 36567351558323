import React from 'react';
import worker from "../../../../workers/profitWorker";
import WebWorker from "../../../../workers/WebWorker";
import { connect } from 'react-redux';
import * as actions from "../../../../store/actions/data";
import ProfitSummary from './Small/SmallSummaryProfit';
import SummaryProfit from './SummaryProfit'
import StraightSummary from './Small/StraightSummary'
import ProfitList from './OverviewProfitList';
import Hidden from '@material-ui/core/Hidden';
import ROIGraph from '../../../AppWide/RoiGraph'
import ProfitGraphSmall from './Small/SmallProfitGraph';
import profitIcon from '../../../../Media/profitIcon.svg';
import Waterfall from '../../../AppWide/Waterfall';

class PortfolioProfit extends React.Component {
    
    state = {
        data: undefined, 
        scope: 'evol',
    }
    
    switchScope = (val) => {
        this.setState(state => ({...state, scope: val}))
    }

    componentDidMount() {

        this.props.setCurrentView(4)
        

            this.worker = new WebWorker(worker);

            this.worker.addEventListener('message', event => {
                const data = event.data 
                

                this.setState(state => ({...state, 
                                        data: data, 
                                        loading: false
                                        }))
            

            });

        if (this.props.portfoliosData[this.props.portfolioId] !== null && this.props.portfoliosData[this.props.portfolioId] !== undefined ) {
            this.setState(state => ({...state, loading: true}))
            this.worker.postMessage([this.props.portfoliosData[this.props.portfolioId].balances, this.props.portfoliosData[this.props.portfolioId].net_profit, this.props.portfoliosData[this.props.portfolioId].positions])
        }

    }

    componentDidUpdate(prevProps) {

        if ( (this.props.portfoliosData[this.props.portfolioId] !== null && this.props.portfoliosData[this.props.portfolioId] !== undefined ) || (this.props.currency !== prevProps.currency) ) {
            
            const new_val = this.props.portfoliosData[this.props.portfolioId].total_value
            let previous_val = undefined;
            try { previous_val = prevProps.portfoliosData[this.props.portfolioId].total_value } catch(e) {}
            if (new_val !== undefined && previous_val !== new_val ) {
                this.setState(state => ({...state, loading: true}))                
                this.worker.postMessage([this.props.portfoliosData[this.props.portfolioId].balances, this.props.portfoliosData[this.props.portfolioId].net_profit, this.props.portfoliosData[this.props.portfolioId].positions])
            }
        }
        

    }
    
    
    render() {

        return (
            this.props.portfoliosData[this.props.portfolioId] !== undefined ?
            
                this.props.portfoliosData[this.props.portfolioId].balances.length > 0 ? 
                    <div>
                    <Hidden smDown>
                    <div style={{marginTop: 0, marginRight: 24, marginLeft: 48, height: 450}}>
                        
                        <div style={{marginBottom: 12, paddingLeft: 12}}> 

                            <div style={{display: 'flex', alignItems: 'center'}}>

                                <div className="blue-hover basier-p2"
                                    onClick={() => this.switchScope('evol')}
                                    style={{marginRight: 12,
                                        color: this.state.scope === 'evol' ? "#0078DB" : "#6A7E93" ,
                                        fontWeight: this.state.scope === 'evol' ? 900 : 400, 
                                        cursor: 'pointer'
                                    }}>
                                        Profit % evolution
                                </div>

                                
                                <div className="basier-p2" style={{marginRight: 6}}>|</div>

                                <div className="blue-hover basier-p2" 
                                    onClick={() => this.switchScope('bars')}
                                    style={{marginRight: 12,
                                        color: this.state.scope === 'bars' ? "#0078DB" : "#6A7E93" ,
                                        fontWeight: this.state.scope === 'bars' ? 900 : 400,
                                        cursor: 'pointer'

                                    }}>
                                        Profit Waterfall
                                </div>


                            </div>

                        </div>

                        {this.state.scope === 'bars' ?
                        
                        <div>

                            <div style={{paddingLeft: 12, marginBottom: 12, fontStyle: 'italic', height: 20, display: 'flex', alignItems: 'center'}} className="basier-p4"></div>

                            {/* <ProfitGraph realized={this.state.realized_profit} 
                                    unrealized={this.state.unrealized_profit} 
                                    coins={this.state.coins}
                                    total_profit={this.state.total_profit} /> */}
                            {this.state.data !== undefined ?
                                this.state.loading ? 
                                    
                                <div style={{backgroundColor: 'white', height: 350, width: '100%'}}>

                                        <div style={{marginLeft: 12, height: 83, width: '100%', display: 'flex', alignItems: 'center'}}>
                                            <div className="pulse-skeleton" style={{width: 75, height: 75, borderRadius: 100, }}></div>

                                                <div style={{marginLeft: 24, display: 'flex', flexDirection: 'column'}}>
                                                    <div className="pulse-skeleton" style={{width: 200, height: 30, borderRadius: 10, marginRight: 0}}></div>
                                                    <div className="pulse-skeleton" style={{width: 200, height: 18, marginTop: 12, borderRadius: 10, marginRight: 0}}></div>
                                                </div>
                                            </div>

                                            <div style={{marginLeft: 0, height: 334}}>
                                                <div style={{marginTop: 12}}></div>
                                                <div style={{width: '100%', height: 322}} className="pulse-skeleton"></div>
                                            </div>

                                    </div>


                                    :
                                    <Waterfall data={this.state.data} currency={this.props.currency} />
                                :
                                
                                    <div style={{backgroundColor: 'white', height: 350, width: '100%'}}>

                                        <div style={{marginLeft: 12, height: 83, width: '100%', display: 'flex', alignItems: 'center'}}>
                                            <div className="pulse-skeleton" style={{width: 75, height: 75, borderRadius: 100, }}></div>

                                                <div style={{marginLeft: 24, display: 'flex', flexDirection: 'column'}}>
                                                    <div className="pulse-skeleton" style={{width: 200, height: 30, borderRadius: 10, marginRight: 0}}></div>
                                                    <div className="pulse-skeleton" style={{width: 200, height: 18, marginTop: 12, borderRadius: 10, marginRight: 0}}></div>
                                                </div>
                                            </div>

                                            <div style={{marginLeft: 0, height: 334}}>
                                                <div style={{marginTop: 12}}></div>
                                                <div style={{width: '100%', height: 322}} className="pulse-skeleton"></div>
                                            </div>

                                        </div>



                                }

                        </div>
                        
                        :

                        this.state.scope === 'evol' ?

                            this.props.roiData[this.props.portfolioId] !== undefined && this.props.portfoliosData !== undefined && this.props.portfoliosData[this.props.portfolioId] !== undefined && this.props.roiData[this.props.portfolioId] !== null
                            && this.props.roiData[this.props.portfolioId].values['values'] !== null && this.props.roiData[this.props.portfolioId].values['values'] !== undefined 

                            ? 
                                
                                <div>
                                    <ROIGraph netProfit={this.props.portfoliosData[this.props.portfolioId].net_profit} netChange={this.props.portfoliosData[this.props.portfolioId].percent_change} data={this.props.roiData} portfolioId={this.props.portfolioId} />
                                </div>
                                :

                                <div style={{backgroundColor: 'white', height: 350, width: '100%'}}>

                                    <div style={{marginLeft: 12, height: 83, width: '100%', display: 'flex', alignItems: 'center'}}>
                                        <div className="pulse-skeleton" style={{width: 75, height: 75, borderRadius: 100, }}></div>

                                            <div style={{marginLeft: 24, display: 'flex', flexDirection: 'column'}}>
                                                <div className="pulse-skeleton" style={{width: 200, height: 30, borderRadius: 10, marginRight: 0}}></div>
                                                <div className="pulse-skeleton" style={{width: 200, height: 18, marginTop: 12, borderRadius: 10, marginRight: 0}}></div>
                                            </div>
                                        </div>

                                        <div style={{marginLeft: 0, height: 334}}>
                                            <div style={{marginTop: 12}}></div>
                                            <div style={{width: '100%', height: 322}} className="pulse-skeleton"></div>
                                        </div>

                                </div>

                        :

                        null}


                    </div>
                    </Hidden>

                    <Hidden mdUp>
                    {this.props.roiData[this.props.portfolioId] !== undefined && this.props.roiData[this.props.portfolioId] !== null
                     && this.props.roiData[this.props.portfolioId].values['values'] !== null && this.props.roiData[this.props.portfolioId].values['values'] !== undefined 

                        ?
                         
                        <ProfitGraphSmall data={this.props.roiData[this.props.portfolioId]['values']} portfolioId={this.props.portfolioId} start_date={this.props.roiData[this.props.portfolioId]['start_date']} totalData={this.props.portfoliosData} />
                        :
                        <div style={{minHeight: 200, width: '100%'}} />
                    }
                    </Hidden>

                    <Hidden mdUp>
                    <div style={{marginTop: 24}}>
                        {this.props.portfoliosData[this.props.portfolioId] !== undefined ? 
                            window.innerWidth < 400 ? 
                            <ProfitSummary data={this.props.portfoliosData} portfolioId={this.props.portfolioId} />
                            :
                            <StraightSummary data={this.props.portfoliosData} portfolioId={this.props.portfolioId} />
                        :
                        null
                        }
                    </div>
                    </Hidden>

                    <Hidden smDown>
                        <div style={{paddingLeft: 48, paddingRight: 48,}}>
                        <SummaryProfit data={this.props.portfoliosData} portfolioId={this.props.portfolioId} />
                        </div>
                    </Hidden>

                    <div className="asset-wrapper-responsive" >


                        <ProfitList portfolioId={this.props.portfolioId} />


                    </div>

                    </div>
                :

                    <React.Fragment>
                        <Hidden smDown>
                            <div style={{height: 530, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 24}}>
                                <div style={{border: '1px solid #CCCCCC', borderRadius: 20, height: '100%', width: '100%', backgroundColor: 'rgba(255, 255, 255, 0.5)', display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', padding: 48, flexDirection: 'column', boxShadow: '0px 10px 10px rgba(225, 229, 233, 0.3)',}}>
                                    <div>
                                        <img src={profitIcon} alt="" style={{height: 80}} />
                                    </div>
                                    <div className="basier-p2" style={{marginTop: 24}}>
                                        <div>You do not have any transactions in your portfolios yet.</div>
                                        <div>Once you add transactions you can see your portfolio's profit details here.</div>
                                    </div>
                                </div>
                            </div>
                        </Hidden>

                        <Hidden mdUp>
                            <div style={{height: 300, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 12}}>
                                <div style={{border: '1px solid #CCCCCC', borderRadius: 20, height: '100%', width: '100%', backgroundColor: 'rgba(255, 255, 255, 0.5)', display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', padding: 24, flexDirection: 'column', boxShadow: '0px 10px 10px rgba(225, 229, 233, 0.3)',}}>
                                    <div>
                                        <img src={profitIcon} alt="" style={{height: 80}} />
                                    </div>
                                    <div className="basier-p2" style={{marginTop: 24}}>
                                        <div>You do not have any transactions in your portfolios yet. Once you add transactions you can see your portfolio's profit details here.</div>
                                    </div>
                                </div>
                            </div>
                        </Hidden>
                    </React.Fragment>

            :

            null
        )

    }
  
}

const mapStateToProps = state => {
    return {
        roiData: state.data.roiEvol, 
        currency: state.auth.currency.id,
        inBitcoin: state.data.bitcoinPf
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setCurrentView: (view) => 
            dispatch(actions.setCurrentView(view)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PortfolioProfit);
