import React, { Component } from 'react';

import { Creatable }  from 'react-select';
import { connect } from 'react-redux';
import { stat } from 'fs';
import {loadData} from '../../helpers/helpers'
import { backendUrl } from '../../variables'

const orderOptions = values => {
    return values
  };
  

const styles = {
    control: styles => ({ ...styles, backgroundColor: 'transparent', height: 'fit-content', fontSize: 16, color: 'black', dispay:'inline-block'}),
    multiValue: (base, state) => {
      return state.data.isFixed ? { ...base, backgroundColor: '#0078DB' } : base;
    },

    multiValueRemove: (base, state) => {
      return state.data.isFixed ? { ...base, display: 'none' } : 
      state.data.isScope ? { ...base, backgroundColor: '#4CDAC1', color: 'white'} :
      ( !state.data.isScope && state.data.isExisting) ? { ...base, backgroundColor: '#edb142', fontWeight: 'bold', color: 'white', paddingRight: 6 }
      :
      base;
    },
};

  
const createNewOption = (label) => {
    
    return (

        {
            label,
            value: -1,
        }

    )


}

const createOption = (label, value, plan) => {
    
    return (

        {
            label,
            value: value,
            isExisting: true,
            isScope: true,
            plan: plan, 
        }

    )


}



class MenuSelector extends React.Component {

  state = {
    isLoading: false,
    options: [],
    value: null,
  };

  componentDidMount() {
    

    let existingUser = [];

        this.props.users.map(val => {
            existingUser.push(val['user_id'])
        })
    

    let defaultOptions = [];
    if (this.props.orgId === -1 || this.props.orgId === null || this.props.orgId === undefined ) {
        defaultOptions = [];
    } else {
        this.props.orgs[this.props.orgId]['users'].map(val => {

            if (!existingUser.includes(val['value'])) {
                let temp = createOption(val['label'], val['value'], val['plan'])
                defaultOptions.push(temp)
            }

        })
    }

    this.setState(state => ({...state, options: defaultOptions}))

  }

  componentDidUpdate(prevProps) {
    
      if (prevProps.orgId !== this.props.orgId) {

        let existingUser = [];

        this.props.users.map(val => {
            existingUser.push(val['user_id'])
        })

        let defaultOptions = [];
        if (this.props.orgId === -1 || this.props.orgId === null || this.props.orgId === undefined ) {
            defaultOptions = [];
        } else {
            this.props.orgs[this.props.orgId]['users'].map(val => {
                if (!existingUser.includes(val['value'])) {
                    let temp = createOption(val['label'], val['value'], val['plan'])
                    defaultOptions.push(temp)
                }
            })
        }
    
        this.setState(state => ({...state, options: defaultOptions}))

      }
  }

  handleChange = (newValue) => {

    this.setState(state => ({...state, value: newValue,}));
    //this.props.handleAdd(newValue)

  };

  handleCreate = (inputValue) => {

    const checkOpts = inputValue.split(" ")
    this.setState({ isLoading: true });
    for (let obj of checkOpts) {


        let headers = { "content-type": "application/json" };
        headers["Authorization"] = `Token ${this.props.token}`;

        const data_fetch = loadData(`${backendUrl}/v1/check_user/?email=${obj}`, headers)
        data_fetch
            .then(json => {

                let newOption = null;
                let proceed = true;
                if (json['exist'] === true) {

                    newOption = json['data']
                
                } else {

                    newOption = createNewOption(obj)

                }

                if (proceed) {
                    //let newVals = [...this.state.value, newOption]
                    this.setState(state => ({...state,
                        isLoading: false,
                        value: newOption,
                    }));
                    this.props.handleAdd(newOption)
                } else {
                    this.setState(state => ({...state,
                        isLoading: false}))
                }
                
            })
    }

  };

  onFocus = () => {
    this.setState({value: null});
    this.props.handleAdd(null)
    this.props.handleDelete()
  }

  onChange = (value, { action, removedValue }) => {

    switch (action) {
      case 'remove-value':
        this.setState({value: null});
        this.props.handleDelete()      
        return;
      case 'pop-value':
        return;
      case 'clear': {
        this.setState({value: null});
        this.props.handleAdd(null)
        this.props.handleDelete()
        return 1;
      }
      default: {
        value = orderOptions(value)
        this.setState(state => ({...state,
            value: value,
          }));
        this.props.handleAdd(value)
        return
      }

    }

   

    }


  render() {
    const { isLoading, options, value } = this.state;
    return (
      <Creatable
        isClearable
        onMenuOpen={this.onFocus}
        isDisabled={isLoading}
        isLoading={isLoading}
        onChange={this.onChange}
        onCreateOption={this.handleCreate}
        options={options}
        value={value}
        styles={styles}
      />
    );
  }
}

const mapStateToProps = state => {
    return {
      user_id: state.auth.user_id, 
      user_first_name: state.auth.user_first_name, 
      user_last_name: state.auth.user_last_name, 
      token: state.auth.token,
      orgs: state.appwide.orgs
    };
  };
  
  const mapDispatchToProps = dispatch => {
    return {
      
    };
  };
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(MenuSelector);
  