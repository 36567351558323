import React from 'react';
import Highcharts from 'highcharts/highstock'
import HeatMap from 'highcharts/modules/heatmap'
import HighchartsReact from 'highcharts-react-official'
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';

/* import HighchartsReact from 'highcharts-react-official'*/
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
/* (function(H) {
    H.Pointer.prototype.reset = function() {
      return undefined;
    };
  
    H.Point.prototype.highlight = function(event) {
      event = this.series.chart.pointer.normalize(event);
      this.onMouseOver(); // Show the hover marker
      //this.series.chart.tooltip.refresh(this); // Show the tooltip
      this.series.chart.xAxis[0].drawCrosshair(event, this); // Show the crosshair
    };
  
    H.syncExtremes = function(e) {
      var thisChart = this.chart;
  
      if (e.trigger !== "syncExtremes") {
        // Prevent feedback loop
        Highcharts.each(Highcharts.charts, function(chart) {
          if (chart && chart !== thisChart) {
            if (chart.xAxis[0].setExtremes) {
              // It is null while updating
              chart.xAxis[0].setExtremes(e.min, e.max, undefined, false, {
                trigger: "syncExtremes"
              });
            }
          }
        });
      }
    };
  })(Highcharts); */

const styles = {
    root: {
      color: '#6A7E93',
      '&$checked': {
        color: '#0078DB',
      },
    },
    checked: {
        color: '#0078DB'
    },
  };

class BalanceGraph extends React.PureComponent {

    render () {

        const data = this.props.data 
        const var_limit = data.expected_return - data.var_abs
    
        const options = {
            chart: {
              
                //height: 600,
            },
            title: {
                text: 'Monte Carlo Portfolio Returns Simulation', 
            },
            credits: false,
            xAxis: [{
                title: false,
                alignTicks: false,
                visible: false,
            }, {
                title: { text: 'Simulated Portfolio value' },
                alignTicks: false,
                opposite: false,
                min: data.pf_simus_sorted.bins.slice(0,1)[0],
                max: data.pf_simus_sorted.bins.slice(-1)[0],
                isDirt: true,
                plotLines: 
                [{
                    color: '#F9716B', // Color value
                    dashStyle: 'solid',
                    value: var_limit,
                    width: 4, 
                    zIndex: 999,
                    label: {text: `<b>${data.var_limit || '5'}% VaR:<b/> <br>${var_limit.toLocaleString('en', {maximumFractionDigits: 2, minimumFractionDigits: 2})}`, 
                    rotation: 0,
                    x: 10,
                    y: 40,
                    },
                  }]
        
            }],
        
            yAxis: [{
                title: false
            }, {
                title: false,
                opposite: true
            }],
        
            series: [{
                name: 'Histogram',
                type: 'column',
                xAxis: 0,
                data: data.pf_simus_sorted.values,
                yAxis: 1,
                //binWidth: data.expected_return / 10,
                baseSeries: 's1',
                zIndex: -1, 
                color: 'rgba(0, 120, 219, 0.4)',
        
            }, {
                name: false,
                type: 'scatter',
                data: data.pf_simus_sorted.bins,
                id: 's1',
                yAxis: 0, 
                xAxis: 1,
                visible: false,
        
            }], 
        
            legend:false,
            exporting: {
                enabled: false
            },
            tooltip: {
              enabled: false,
            },
            plotOptions: {
              column: {
                  pointPadding: 0,
                  borderWidth: 0.2,
                  groupPadding: 0,
                  shadow: false
              }
            }
            
        
        
        }


        return (

            <div style={{width: '100%', height: 650}}>


                        <div style={{marginLeft: 0, padding: 24, display: 'flex', width: '100%', height: '100%', alignItems: 'flex-start'}}>

                        <div style={{height: '100%', width: 200, borderRight: '1px solid #E1E5E9', paddingTop: 12, paddingBottom: 12, paddingLeft: 0, paddingRight: 12, marginRight: 12, display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>

                          <div>

                            <h4>Parameters</h4>

                            <div className="basier-p4-caps" style={{marginTop: 24, marginBottom: 4}}>
                                Simulation method
                            </div>
                            <div className="basier-p3" style={{color: 'black'}}>Monte Carlo</div>

                            

                            <div className="basier-p4-caps" style={{marginTop: 24, marginBottom: 4}}>
                                Number of runs
                            </div>
                            <div className="basier-p3" style={{color: 'black'}}>{this.props.data.runs.toLocaleString('en', {minimumFractionDigits: 0, maximumFractionDigits: 0})}</div>

                            <div className="basier-p4-caps" style={{marginTop: 24, marginBottom: 4}}>
                                Method used
                            </div>
                            <div className="basier-p3" style={{color: 'black'}}>Geometric Brownian Motion</div>

                            <div className="basier-p4-caps" style={{marginTop: 24, marginBottom: 4}}>
                                Additional steps
                            </div>
                            <div className="basier-p3" style={{color: 'black'}}>Probabilistic jumps (fixed threshold)</div>

                            <div className="basier-p4-caps" style={{marginTop: 24, marginBottom: 4}}>
                                Price Sampling period
                            </div>
                            <div className="basier-p3" style={{color: 'black'}}>Last {this.props.data.period} months</div>

                            <div className="basier-p4-caps" style={{marginTop: 24, marginBottom: 4}}>
                                Simulation length
                            </div>
                            <div className="basier-p3" style={{color: 'black'}}>{this.props.data.days} days</div>

                          </div>

                        </div>

                        <div style={{width: '100%', flex: 1, display: 'flex'}}>
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={options}
                                oneToOne={true}
                                style={{height: 600, width: '100%', flex: 1}}
                                containerProps={{ style: { height: 600, width: "100%", flex: 1, } }}
                            />
                        </div>

                        </div>


                </div>
            
            
        )
    }


        
};

BalanceGraph.propTypes = {
    classes: PropTypes.object.isRequired,
  };
  

const mapStateToProps = state => {
    return {
        currency_symbol: state.auth.currency.symbol,
        currency_name: state.auth.currency.id,
        currentView: state.data.currentView,
        inBitcoin: state.data.bitcoinPf

    };
};

const mapDispatchToProps = dispatch => {
    return {
        
        
    };
  };

export default connect(
    mapStateToProps,
    mapDispatchToProps
)( withStyles(styles)(BalanceGraph));


