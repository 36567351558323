import React from "react";
import { connect } from "react-redux";
import UploadTable from "./UploadTable";
import { loadData } from "../../../../../helpers/helpers";
import * as interActions from "../../../../../store/actions/interactions";
import * as appwideActions from "../../../../../store/actions/appwide";
import { backendUrl } from "../../../../../variables";
import loadingSpinner from "../../../../../Media/contentLoader.svg";

import axios from "axios";
import ErrorLine from "./ErrorLine";

class ErrorsComponent extends React.PureComponent {
  state = {
    data: undefined,
  };

  componentDidMount() {
    const portData = this.props.portfoliosData;
    if (portData[this.props.portfolioId] !== undefined && portData[this.props.portfolioId] !== null) {
      this.getData();
    }
  }

  compondentDidUpdate(prevProps) {
    const portData = this.props.portfoliosData;
    if (
      portData !== undefined &&
      portData !== null &&
      prevProps.portfoliosData !== portData
    ) {
      this.getData();
    }
  }
  getData = () => {
    const portData = this.props.portfoliosData[this.props.portfolioId];
    const errors = portData.corrections
    const errorkeys = Object.keys(errors)
    let fixkeys = [];
    Object.values(errors).map(function(v) {
      if (v != 'ADD') {
        const correctKeys = v['fix_ids'].filter(v => v !== 'ADD')
        if (correctKeys.length > 0) {
          fixkeys.push(...correctKeys)  
        }
      }
    })

    let headers = { "content-type": "application/json" };
    headers["Authorization"] = `Token ${this.props.token}`;
    
    const url = `${backendUrl}/v1/portfolio/${this.props.portfolioId}/errors/`
    const submitData = {
      user_id: this.props.user_id,
      ids: [...fixkeys, ...errorkeys]
    }
    axios.post(url, submitData, {headers: headers})
    .then(json => {
      this.setState(state => ({...state, data: json['data']['data'], errors: Object.keys(errors), errorData: errors}))
    })

  };


  renderErrors = () => {

  }

  render() {

    const loading = (
      <div style={{width: '100%', marginTop: 24, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        <img src={loadingSpinner} alt="loader" style={{height: 80, width: 80}} />
        <div style={{color: '#6A7E93', marginTop: 12,}} className="basier-p4-caps">Loading portfolio errors</div>
      </div>
    )

    return (
      this.props.portfoliosData[this.props.portfolioId] !== undefined && this.props.portfoliosData[this.props.portfolioId] !== null ? 
      this.state.data === undefined ?
        <div>
          {loading}
        </div>
        :
        <div style={{height: '100%', paddingRight: 12}}>
          <ErrorLine data={this.state.data} errors={this.state.errors} errorData={this.state.errorData} />
        </div>
      :
      <div>
        {loading}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    user_id: state.auth.user_id,
    portfoliosData: state.data.portfoliosData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showSnack: (data) => dispatch(interActions.showSnack(data)),
    getUserLimits: (user) => dispatch(appwideActions.getUserLimits(user)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ErrorsComponent);
