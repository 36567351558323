import React from 'react';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import * as actions from "../../../../store/actions/data";
import AnalysisTable from './AnalysisTable';
import AnalysisSwitch from './AnalysisSwitch';
import './Analysis.css'
import { backendUrl } from '../../../../variables';
import BackIcon from '../../../../Media/BackIcon.svg'
class AnalysisOverview extends React.PureComponent {

    state = {
        data: null, 
        shown_details: null,
    }

    handleSelect = (id, type) => {

        this.setState(state => ({shown_details: {id: id, type: type}}))

    }

    handleClear = () => {

        this.setState(state => ({...state, shown_details: null}))

    }

    analysisMapping = {
        'correlation_pf': "Historical BTC correlations by market cap", 
        "corr_matrix": "Correlation matrix (12 months)", 
        "market_cap_prop": "Market Capitalization evolutions (%)", 
        "bull_bear": "BTC Bull & Bear Market analysis", 
        "hist_vol": "BTC historical volatility",
        "paraboles": "Paraboles",
    }

    componentDidMount() {
        //this.props.setCurrentView(6)
        //this.props.componentDataFetch('get', 6, null, null, this.props.portfolioId, null, null, this.props.inBitcoin)

        const prod_url = `${backendUrl}/v1/analysis/?p=${this.props.currentPf}`
        //const preprod_url = `http://localhost:8000/v1/analysis/?p=${this.props.currentPf}`

        fetch(prod_url)
            .then(res => res.json() )
            .then(json => {
                this.setState({data: json['data'] })
            })
    }
    
    render() {

        return (
           
            <div style={{margin: 62}}>
                
                

                {this.state.shown_details ? 

                    <div>
                        <div onClick={this.handleClear} className="basier-p4-caps link-hover" style={{marginBottom: 12}}>Back to all Analyses</div>
                        <h3>{this.analysisMapping[this.state.shown_details.type]}</h3>
                        <AnalysisSwitch analysis={this.state.shown_details} />
                    </div>

                    : 

                    this.state.data ? 

                        <div>
                            <h3>Analysis</h3>
                            <AnalysisTable onClick={this.handleSelect} onExit={this.handleClear} data={this.state.data} mapping={this.analysisMapping} />
                        </div>
                    
                    : 
                        null

                } 




               {/*  {this.state.data ? 
                <CorrelationLineGraph data={this.state.data}/>
                : 
                null 
                } */}
                

            </div>
            
        )


    }   
};
  

const mapStateToProps = state => {
    return {
        currency_symbol: state.auth.currency.symbol,
        currency_name: state.auth.currency.id,
        currentPf: state.data.menu_selected,
        deposits: state.data.deposits, 
        inBitcoin: state.data.bitcoinPf

    };
};

const mapDispatchToProps = dispatch => {
    return {
        setCurrentView: (view) => 
            dispatch(actions.setCurrentView(view)),
        componentDataFetch: (scope, view, currency, user, portfolio, globalGraphData, portfolioGraphData, inBitcoin) =>
            dispatch(actions.componentDataFetch(scope, view, currency, user, portfolio, globalGraphData, portfolioGraphData, inBitcoin)), 
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AnalysisOverview);


