import React from 'react';
import { connect } from 'react-redux';
import AuditTable from './GlobalAuditTable'
import {loadData} from '../../helpers/helpers'
import { backendUrl } from '../../variables'

class GlobalAuditWrapper extends React.PureComponent {

    state = {
        auditData: null
    }

    componentDidMount() {

        let headers = { "content-type": "application/json" };
        headers["Authorization"] = `Token ${this.props.token}`;

        const data_fetch = loadData(`${backendUrl}/v1/audit_trail/?u=${this.props.user_id}`, headers)
        data_fetch
            .then(json => {

                this.setState(state => ({...state, auditData: json['data']}))

            })
            .catch(e => {
                console.log(e)
            })



    }

    render () {

        return (

            this.state.auditData !== null && this.state !== undefined ?
                <div style={{width: '100%'}}>
                    <AuditTable data={this.state.auditData} auditScope="global" />
                </div>
            :
            null
            
        )
    }


}

const mapStateToProps = state => {
    return {
        user_id: state.auth.user_id, 
        token: state.auth.token,
    };
};

const mapDispatchToProps = dispatch => {
    return {
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(GlobalAuditWrapper)
