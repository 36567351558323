import React from 'react';
import PropTypes from 'prop-types';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import * as actions from "../../../store/actions/interactions";
import * as appwideActions from "../../../store/actions/appwide";
import { connect } from 'react-redux';
import BaseModalTop from '../../Portfolio/PortfolioComponents/Transactions/BaseModalTop'
import { backendUrl } from '../../../variables';
import {loadData} from '../../../helpers/helpers'
import NewTransactionProcess from '../../Portfolio/PortfolioComponents/Transactions/NewTransaction/ShortForm/ShortTransactionEntry'


var moment = require('moment');
require('moment/locale/en-gb');

class EditTransaction extends React.Component {

  constructor(props) {
    
    super(props);

    this.focusOut = React.createRef();
    this.baseCurencyRef = React.createRef();
    this.quoteCurencyRef = React.createRef();
    this.feeCurrencyRef = React.createRef();
    this.firstFocusExchange = React.createRef();
    

    this.state = {
    }

  };

  componentDidMount() {

    if (this.props.data !== null) {

        this.updateState(this.props.data)

    } else {
      this.getData()

    }

  }

  updateState = (data) => {

    const now = new Date(data.date)
    const moment_date = moment(now);
    const stateDate = moment_date.local().format("DD/MM/YYYY HH:mm")

    let quote_currency = data.quote_currency
    if (data.transaction_description !== 'buy' && data.transaction_description !== 'ico' &&data.transaction_description !== 'sell') {
      quote_currency = null
    }

    this.setState(state => ({...state, 

        loading: false, 
        error: false, 
        success: false,
        formFields: {
  
          ...data, 
          date: stateDate, 
          reduxDate: data.date,
          quote_currency: quote_currency,
          account_data: data.account,
          base_id: data.base_currency.id
  
        },
        editFields: {
          
        }
  

    }))


  }

  handleClose = () => {
    this.props.toggleModal({type: null, open: false, payload: null})
  }

  getData = () => {
    let headers = { "content-type": "application/json" };
    if (this.props.token !== null && this.props.token !== undefined) {

        headers['Authorization'] = `Token ${this.props.token}`

    }

    const url_call = `${backendUrl}/v1/transaction_detail/${this.props.transactionId}`

    const transactionDetail = loadData(url_call, headers)
    transactionDetail
        .then(json => {

            const data = json['data']
            this.updateState(data)
            
        })

}


  
  render() {

    
    return (

      this.state.formFields !== null && this.state.formFields !== undefined ?

      <div>
        
          <BaseModalTop forceSingle={true} title="Edit transaction" handleClose={this.handleClose} onClick={this.backToWelcome} welcome={this.state.welcome} entryType={this.state.type_selected} />

          <NewTransactionProcess 
            scope="update" 
            existingData={this.state.formFields}
            setDetailTr={this.props.setDetailTr}
            getEditData={this.props.getData}  
            getOverallEditData={this.props.getOverallData}
            getWallets={this.props.getWallets}
            getUserLimits={this.props.getUserLimits}
            editTransactionId={this.props.transactionId}
            handleClose={this.handleClose}
          />


      </div>

    : 

    null
    )
  }
}

EditTransaction.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
};


const mapStateToProps = state => {
    return {

        modalPref: state.interactions.transactionAddModal,
        portfoliosData: state.data.portfoliosData,
        transaction: state.interactions.transactionAddData,
        all_coins: state.appwide.all_coins, 
        exchangeCheck: state.interactions.exchangeCheck, 
        exchanges: state.appwide.exchanges, 
        wallets: state.appwide.wallets, 
        user_id: state.auth.user_id,
        user_first: state.auth.user_first_name,
        user_last: state.auth.user_last_name, 
        token: state.auth.token
        
    };
};

const mapDispatchToProps = dispatch => {
    return {
       toggleModal: (data) => 
            dispatch(actions.toggleModal(data)), 
        removeTransaction: (data) =>
            dispatch(actions.removeTransaction(data)), 
        setTrTime: (data) => 
            dispatch(actions.setTrTime(data)), 
        setInteraction: (data) => 
            dispatch(actions.setInteraction(data)), 
        getWallets: (user) => dispatch(appwideActions.getWallets(user)), 
        getUserLimits: (user) => dispatch(appwideActions.getUserLimits(user)), 
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withMobileDialog()(EditTransaction));
