import React from 'react';
import { connect } from 'react-redux';
import { backendUrl, cbKey } from '../../variables'
import 'filepond/dist/filepond.min.css';
import * as appwideActions from "../../store/actions/appwide";
import * as interActions from "../../store/actions/interactions";
import * as dataActions from "../../store/actions/data";
import whiteLoader from '../../Media/whiteloader.gif'
import coinbaseWhite from '../../Media/coinbaseWhite.png'
import { withStyles } from '@material-ui/core/styles';
import axios from 'axios';
import fieldValidator from '../../helpers/validators';
import SingleSelect from '../AppWide/SingleSelect';


const styles = theme => ({
    container: {
        width: '100%', 
        padding: '24px 48px', 
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            padding: 12, 
        },
    },
    image: {
        display: 'block', 
        [theme.breakpoints.down('sm')]: {
            display: 'none', 
        },
    },
    ImageText: {
        [theme.breakpoints.down('sm')]: {
            padding: 12 
        },
    },
    subContainer: {
        padding: 12,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            height: '100%',
        },
        [theme.breakpoints.up('md')]: {
            width: '48%',
            height: '100%',
        }
    },
    secondSub: {
        [theme.breakpoints.up('md')]: {
            height: 340,
        }
    }, 
    secondQuestions: {

        [theme.breakpoints.down('sm')]: {
            padding: 0
        },
        [theme.breakpoints.up('md')]: {
            marginTop: 0
        }

    }, 
    input: {
        
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 24, width: '100%', marginTop: 12, 
        },
        [theme.breakpoints.up('md')]: {
            paddingLeft: 24, width: 324, marginTop: 12, 
        }
    },
    fiatContainer: {
        
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        },
        [theme.breakpoints.up('md')]: {
            width: 324
        }
    }, 
    root: {
        color: '#6A7E93',
        '&$checked': {
          color: '#0078DB',
        },
      },
      checked: {
          color: '#0078DB'
      },
    });


const depositTypes = [
    {value: "deposit", label: "Deposit"},
    {value: "mining", label: "Mining/Rewards"},
    {value: "dividend", label: "Dividend"},
    {value: "income", label: "Income"},
    {value: "gift", label: "Gift"},
    {value: "airdrop", label: "Airdrop"},
]
class ConnectAccount extends React.PureComponent {

    state = {
        activeStep: 0,
        data: {
            scope: 'all',
            deposit_type: 'deposit',
        }, 
        selected: null,
        loading: false, 
        error: null,
        validation: {
            name: true,
            select: true,
        },
        connection_type: 'exchange', 
        exchange: [], 
        storage: [],
        scopeData: undefined, 
      };


    componentDidMount() {
        
        if (this.props.importTarget !== null) {
            this.setState(state => ({...state, portfolio_id: this.props.importTarget}))
            this.props.setPortfolio(this.props.importTarget)
          } else if (this.props.scopePortfolios.length > 0) {
            this.setState(state => ({...state, portfolio_id: this.props.scopePortfolios[0].value}))
            this.props.setPortfolio(this.props.scopePortfolios[0].value)
          }

    }

    updateState = (label, value) => {

        if (label === 'connection_type') {
            this.setState(state => ({...state, scopeData: undefined}))
        }
        this.setState(state => ({...state, [label]: value}))
    }

    updateData = (label, value) => {
        this.setState(state => ({...state, data: {...state.data, [label]: value}}))
    }

    updateDepositType = (d) => {
        this.updateData("deposit_type", d.value)
    }

    updatePort = (data) => {
        this.setState(state => ({...state, ...data}))
        
    }

    submitServer = () => {
      this.props.updateState({loading: true, success: false})
      let port; 
      try {
        port = this.state.portfolio_id
      } catch(e) {}
      let submitData = {
        user_id: this.props.user_id, 
        ...this.state.data,
        ...this.props.data, 
        portfolio: port,
        scope: 'all',
      }
      submitData['exchange_id'] = submitData['exchange']

      let url;
      if (this.props.connection_type === 'exchange') {
        url = `${backendUrl}/v1/connectors/setup/`
      } else if (this.props.connection_type === 'storage') {
        url = `${backendUrl}/v1/wallets/setup/`
      }

        let name_val = true;
        
        let lowercaseName = this.props.data.name
        try { lowercaseName = this.props.data.name.toLowerCase().trim() } catch(e) {}
        name_val = fieldValidator(this.props.data.name, ['isNotEmpty'])
        name_val = name_val && !(this.props.existingNames.includes(lowercaseName))

      
        if (name_val) {
            this.setState(state => ({...state, loading: true}))

            let headers = { 
                "content-type": "application/json",     
            };
            headers["Authorization"] = `Token ${this.props.token}`;

            axios
            .post(url,
                submitData
            , { headers: headers } )
            .then(res => { 

                this.props.updateState({loading: false, success: true, portfolio: port})
                const accountId = res['data']['data']['id']
                const currentTasks = this.props.tasks
                let newTasks = [...currentTasks]
                if (this.props.connection_type=== 'exchange') {
                    newTasks = [{description: `Exchange Connection`, sub: `${this.props.data.exchange_name}`, status: 'pending', type: 'connection', task_id: accountId}, ...currentTasks]
                } else if (this.props.connection_type === 'storage') {
                    newTasks = [{description: `Wallet Connection`, sub: `${this.props.data.coin_name}`, status: 'pending', type: 'connection', task_id: accountId}, ...currentTasks]
                }
                
                this.props.setInteraction({label: "tasks", value: newTasks})
                this.props.getWallets(this.props.user_id, this.props.token);
                this.props.getUserLimits(this.props.user_id)
                window.setTimeout(() => {
                  this.handleClose();
                }, 500)
                

            })
            .catch(err => {
                this.props.updateState({loading: false, success: false})
                let error = "Unable to connect at this time, please try again."
                
                //console.log(err)

                try {
                    error = err.response['data']['data']
                } catch(e) {}

                this.setState(state => ({...state, error: error, loading: false }))

            })
        }
    }

    handleInit() {
    }

    handleClose = () => {

        //this.props.toggleModal({type: null, open: false, payload: null})
       
      }

    handleBack = () => {
        this.setState(state => ({
        activeStep: state.activeStep - 1,
        }));
    };

    getSteps() {
        return ['Select Account', 'Enter details'];
    }

    getStepContent(stepIndex) {
        switch (stepIndex) {
        case 0:
            return this.step1();
        case 1:
            return this.step2();
        default:
            return 'Unknown stepIndex';
        }
    }

    inputData = (e, label) => {
        e.persist();
        this.setState(state => ({...state, data: {...state.data, [label]: e.target.value}}))
    }

    selectExchange = (object, connection_type) => {
        if (connection_type === 'exchange') {
            this.setState(state => ({...state, selected: object, connection_type: connection_type, data: {...state.data, exchange_id: object.exchange_id}, activeStep: 1}))
        } else {
            this.setState(state => ({...state, selected: object, connection_type: connection_type, data: {...state.data, coin_id: object.coin_id}, activeStep: 1}))
        }
    }

    changeType = e => {
        e.persist();
        this.setState(state => ({...state, data: {...state.data, scope: e.target.value}}))
    }

    goToCoinbase = () => {

        this.setState(state => ({...state, loading: true}))
        const url = `${backendUrl}/api/oauth/coinbase/setup/`
        let headers = { 
            "content-type": "application/json",     
        };
        headers["Authorization"] = `Token ${this.props.token}`;

        let submitData = {
            ...this.state.data, 
            user_id: this.props.user_id, 
            name: this.props.data.name,

        }

        if ( submitData.scope === 'all' ) {
            submitData['portfolio'] = this.state.portfolio_id
        }

        axios.post(
            url,
            submitData,
            {headers: headers}
        ).then(
            res => {

                const data = res.data.data
                const url = `https://www.coinbase.com/oauth/authorize?response_type=code&client_id=${cbKey}&redirect_uri=${backendUrl}/api/oauth/coinbase/success/&state=${data['account_id']}&account=all&scope=wallet:accounts:read,wallet:buys:read,wallet:deposits:read,wallet:sells:read,wallet:transactions:read,wallet:trades:read,wallet:withdrawals:read,wallet:addresses:read,wallet:payment-methods:read`
                //console.log(url)
                window.location.replace(url)
            }
        )

       

    }

    selectPort = (val) => {
      let pv = null;
      try {
          pv = val.value
      } catch(e) {}
      this.setState(state => ({...state, portfolio_id: pv}))
      this.props.setPortfolio(pv)
    }


    render () {
        const { classes } = this.props;

        let importName;
        try {
          importName = this.props.scopePortfolios.filter(v => v.value == this.props.importTarget)[0].label
        } catch(e) {}

        return (
            
            <div style={{width: '100%'}}>
              <div style={{width: '100%'}}>

          
              <div>

                  <div>

                    {this.props.importTarget === null ? 
                        <div>
                        <div style={{display: 'flex', marginBottom: 12, alignItems: 'center'}}>
                            <div style={{width: 24, height: 24, display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: 100, backgroundColor: '#0078DB', color: 'white', fontWeight: 900, marginRight: 12}} className="basier-p2">
                                1
                            </div>
                            <div className="basier-p3" style={{textTransform: 'uppercase', color: 'black', fontWeight: 900}}>
                                Select Import Target
                            </div>
                        </div>

                        <div style={{marginBottom: 12}}>
                            <div>
                            <div className="basier-p4-caps" style={{fontWeight: 900, color: 'black', marginBottom: 4 }}>
                                Import To Portfolio: 
                            </div>
                            <SingleSelect selected={this.state.portfolio_id} data={this.props.scopePortfolios} handleSelect={this.selectPort} />
                            </div>
                        </div>
                        </div>
                        :
                        <div style={{marginLeft: 0, marginBottom: 24, }} className="basier-p3">Transaction will be imported to: <span style={{textTransform: 'uppercase', fontWeight: 'bold', color: 'black'}}>{importName}</span></div>
                        }

                        { 
                        this.props.selectedExchange === 'coinbase' ? 
                            
                        null

                        :
                        
                        this.props.connection_type === 'exchange' && this.props.connectorData !== undefined && this.props.connectorData.fields !== undefined && this.props.connectorData.fields[0].map( (val, key) => {

                            return (
                                <div  key={key} style={{marginBottom: 24}}>
                                    <div className="basier-p4-caps" style={{color: 'black', textTransform: 'uppercase', fontWeight: 900, marginBottom: 12, width: '100%'}}>
                                        {val[1]}
                                    </div>
                                    <input onChange={(e) => this.updateData(val[0], e.target.value)} type="text" className={this.state.error ? 'light-input error-input' : 'light-input'} style={{width: '100%'}} value={this.state.data[val[0]] || ""} />
                                </div>
                                )
                            })
                        }

                        {this.props.connection_type === 'storage' && 
                            <div style={{marginBottom: 24}}>
                                <div className="basier-p4-caps" style={{color: 'black', textTransform: 'uppercase', fontWeight: 900, marginBottom: 12, width: '100%'}}>
                                        Address
                                </div>
                                <input onChange={(e) => this.updateData('address', e.target.value)} type="text" className={this.state.error ? 'light-input error-input' : 'light-input'} style={{width: '100%'}} value={this.state.data.address || ""} />

                                <div className="basier-p4-caps" style={{color: 'black', textTransform: 'uppercase', fontWeight: 900, marginBottom: 12, marginTop: 12, width: '100%'}}>
                                        Mark deposits as
                                </div>
                                <SingleSelect selected={this.state.data.deposit_type} data={depositTypes} handleSelect={this.updateDepositType} />
                                
                            </div>    
                        }
                        {this.props.selectedExchange !== undefined && this.props.selectedExchange !== undefined && this.props.selectedExchange == 'coinbase' && 
                        
                        <div style={{marginTop: 24}}>
                            <span style={{textAlign: 'justify'}}>
                                <span>We will now redirect you to Coinbase so that you can authorize Wisly. We will only request <span style={{fontWeight: 'bold'}}>READ ONLY ACCESS</span>. We will not be able to trade or move your funds. Once the process if complete, you will be redirected to Wisly and we will start importing your transactions.</span>
                            </span>

                            <div style={{marginTop: 24}}>
                                <div className='button-blue opacity-hover no-select' style={{width: '100%', height: 50, cursor: 'pointer'}} onClick={this.state.loading ? null : this.goToCoinbase}>
                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                        {this.state.loading && <img src={whiteLoader} alt="" style={{height: 20, width: 20, marginRight: 12}}/>}
                                        <div>Continue to</div>
                                        <img src={coinbaseWhite} style={{height: 22, marginLeft: 4}} alt="Coinbase" />
                                    </div>
                                </div>
                            </div>
                        </div>
                
                        }
                    
                    </div>

                </div>
            
            </div>
            
            <div className="basier-p4" style={{paddingLeft: 6, color: '#F9716B', fontWeight: 900, marginTop: 0, width: '100%', display: 'flex', justifyContent: 'center', textAlign: 'center'}}>{this.state.error}</div>
            
            <div style={{height: 100}}>
                    
                    <div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>

                        {/* <div className='basier' onClick={this.handleClose} 
                            style={{height: 40, borderRadius: 35, marginRight: 12, marginTop: 24, cursor: 'pointer', color: 'white', backgroundColor: '#F9716B', width: 150, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            Cancel
                        </div> */}
                        
                        {this.props.selectedExchange === 'coinbase' ? null : 
                        <div className='button-blue opacity-hover' onClick={this.state.loading ? null : () => this.submitServer()} style={{height: 50, marginTop: 0, cursor: 'pointer', width: '100%'}}>
                            {this.state.loading ? 

                                <span>
                                    <img src={whiteLoader} alt="" style={{height: 20, width: 20, marginRight: 12}}/>
                                    <span>Connecting</span>
                                </span>
                            
                            :

                                <span>Connect API</span>
                            
                            }
                        </div>
                        }

                    </div> 

            </div>


            </div>


        )
    }


}

const mapStateToProps = state => {
    return {
        token: state.auth.token,
        user_id: state.auth.user_id,
        inBitcoin: state.data.bitcoinPf, 
        currency_id: state.auth.currency.id, 
        currentPortfolio: state.data.menu_selected, 
        tasks: state.interactions.tasks,
        currentView: state.data.currentView, 
        graphSettings: state.data.globalBalance,
        //portfolioList: state.auth.list_portfolios, 
        existingNames: state.appwide.accountNames
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getImports: (u) => dispatch(dataActions.getImports(u)), 
        getWallets: (user, t) => dispatch(appwideActions.getWallets(user, t)), 
        getUserLimits: (user) => dispatch(appwideActions.getUserLimits(user)), 
        setInteraction: (data) => dispatch(interActions.setInteraction(data)), 
        toggleModal: (data) => 
            dispatch(interActions.toggleModal(data)), 
        componentDataFetch: (scope, view, currency, user, portfolio, globalGraphData, portfolioGraphData, inBitcoin) =>
            dispatch(dataActions.componentDataFetch(scope, view, currency, user, portfolio, globalGraphData, portfolioGraphData, inBitcoin)), 
        
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ConnectAccount))
