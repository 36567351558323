import React from "react";
import Select, { components } from "react-select";
import { connect } from "react-redux";
import loadingImg from "../../Media/contentLoader.svg";
import okIcon from "../../Media/okIcon.svg";

class MenuSelector extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedOption: null,
      menuIsOpen: false,
      editPf: false,
      force: false,
      existingValue: null,
      loading: false,
    };
  }

  colourStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "white",
      width: '100%',
      minHeight: 28,
      height: 25,
      fontSize: 13,
      fontFamily: "Basier Circle",
      textTransform: "uppercase",
      border: "0px",
      color: "black",
      cursor: "text",
      dispay: "flex",
      alignItems: "center",
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        fontFamily: "Basier Circle",
        backgroundColor: isDisabled
          ? null
          : isSelected
          ? "white"
          : isFocused
          ? "#F3F5F6"
          : null,
        color: isDisabled
          ? "#F3F5F6"
          : isSelected
          ? "hsl(207, 100%, 43%)"
          : "black",
        opacity: isDisabled ? "1" : isSelected ? "1" : "0.4",
      };
    },
    menu: (styles) => ({ ...styles, fontSize: 12, width: '100%' }),
    valueContainer: (styles) => ({ ...styles, padding: "0x 0px" }),
    placeholder: (styles) => ({
      ...styles,
      textTransform: 'capitalize',
      color: "hsl(3, 92%, 50%)",
      value: "Select Portfolio",
    }),
    indicatorSeparator: (styles) => ({ ...styles, display: "none" }),
    dropdownIndicator: (styles) => ({
      ...styles,
      color:
        this.state.existingValue !== null &&
        this.state.existingValue !== undefined
          ? "hsl(0,0%,80%)"
          : "hsl(3, 92%, 70%)",
      "&:hover": {
        color:
          this.state.existingValue !== null &&
          this.state.existingValue !== undefined
            ? "hsl(0,0%,50%)"
            : "hsl(3, 92%, 50%)",
        opacity: 0.8,
      },
    }),
  };

  editPf = () => {
    this.setState((state) => ({ ...state, editPf: true }));
  };

  stopEditPf = () => {
    if (this.state.force === false) {
      this.setState((state) => ({ ...state, editPf: false }));
    }
  };

  componentDidMount() {
    let options = [];
    this.props.data.map((portfolio) => {
      let values = { value: portfolio["id"], label: portfolio["name"] };
      options.push(values);
    });

    this.setState((state) => ({ ...state, options: options }));

    try {
      if (this.props.value.portfolio.id === null) {
        return;
      } 
    } catch(e) {}

    if (this.props.value !== undefined && this.props.value !== null) {
      if (this.props.value.portfolio.id !== null) {
        const first = {
          value: this.props.value.portfolio.id,
          label: this.props.value.portfolio.name,
        };
        this.setState((state) => ({
          ...state,
          selectedOption: first,
          existingValue: this.props.value.portfolio.name,
        }));
      }
    }
  }

  componentDidUpdate(prevProps) {

    if (this.props.portCheck !== prevProps.portCheck) {
      if (this.props.portCheck === null || this.props.portCheck === undefined) {
        this.setState(state => ({...state, selectedOption: null, existingValue: null}))
      }
    }
  }

  handleMenuOpen = () => {
    this.setState((state) => ({ ...state, force: true }));
  };

  handleMenuClose = () => {
    this.setState((state) => ({ ...state, editPf: false, force: false }));
  };

  toggleMenuIsOpen = () => {
    this.setState((state) => ({ menuIsOpen: false }));
  };

  handleChange = (selectedOption) => {
    if (
      this.state.selectedOption === null ||
      selectedOption.value !== this.state.selectedOption.value
    ) {
      this.setState((state) => ({
        ...state,
        selectedOption: selectedOption,
        existingValue: selectedOption.label,
      }));
      this.props.updateServer({ portfolio: selectedOption.value });
    }
  };

  render() {
    const { selectedOption } = this.state;

    return this.state.existingValue !== null &&
      this.state.editPf === false &&
      this.props.forceSelect !== true ? (
      <div
        className="basier"
        style={{
          paddingLeft: 2,
          paddingTop: 1,
          color: "#0078DB",
          fontSize: 13,
          textTransform: "uppercase",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: 8,
        }}
        onMouseEnter={this.props.loading !== true ? () => this.editPf() : null}
      >
        <div>{this.state.existingValue}</div>
        {this.props.loading && (
          <img src={loadingImg} alt="" style={{ height: 30 }} />
        )}
        {this.props.valid && (
          <img
            className="dissapear"
            src={okIcon}
            alt=""
            style={{ height: 18, marginRight: 6 }}
          />
        )}
      </div>
    ) : (
      <div onMouseLeave={this.stopEditPf}>
        <Select
          value={selectedOption}
          onChange={this.handleChange}
          options={this.state.options}
          isSearchable={true}
          styles={this.colourStyles}
          maxMenuHeight={80}
          minMenuHeight={80}
          onMenuOpen={() => this.handleMenuOpen()}
          onMenuClose={() => this.handleMenuClose()}
          components={{}}
          classNamePrefix="react-select-smallPort"
          placeholder="Select portfolio"
          theme={(theme) => ({
            ...theme,
            borderRadius: 0,
            colors: {
              ...theme.colors,
              primary25: "#F3F5F6",
              primary: "#F3F5F6",
            },
          })}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuSelector);
