import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    zIndex: 9000,
  },
  tabsRoot: {
    minWidth: '100%',
    paddingLeft: 12,
    paddingRight: 12,
    flexGrow: 1,
    backgroundColor: '#F8F9FA',
    overflow: 'hidden', 
    paddingTop:10,
    height: 60,
  },
  tabsIndicator: {
    backgroundColor: '#0078DB',
    top: 48,
  },
  tabRoot: {
    color: '#6A7E93',
    textTransform: 'initial',
    minWidth: '72',
    fontSize: '1rem',
    fontWeight: 400,
    marginRight: theme.spacing.unit * 4,
    fontFamily: [
      'Basier Circle',
    ].join(','),
    '&$tabSelected': {
      color: 'black',
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:focus': {
      color: 'black',
    },
  },
  tabCointainer: {
      paddingBottom: 0,
  },
  tabScroller: {
    paddingBottom: 20,
  },
  tabSelected: {},
  typography: {
    padding: theme.spacing.unit * 3,
  },
});

class CustomizedTabs extends React.Component {
  
  render() {
    const { classes, handleChange } = this.props;

    return (
      <div className={`${classes.root} tab-position`}>

        <Tabs
          value={this.props.value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator, scroller: classes.tabScroller}}
          >
          <Tab
              disableRipple
              classes={{ root: classes.tabRoot, selected: classes.tabSelected, labelContainer: classes.tabCointainer  }}
              label="Overview"
              value={0}
          />
          <Tab
              disableRipple
              classes={{ root: classes.tabRoot, selected: classes.tabSelected, labelContainer: classes.tabCointainer }}
              label="Profits"
              value={1}
          />
          <Tab
              disableRipple
              classes={{ root: classes.tabRoot, selected: classes.tabSelected, labelContainer: classes.tabCointainer }}
              label="Transaction"
              value={2}
          />
          <Tab
              disableRipple
              classes={{ root: classes.tabRoot, selected: classes.tabSelected, labelContainer: classes.tabCointainer }}
              label={this.props.plan.plan_id === 'pro' || this.props.plan.plan_id === 'enterprise' || this.props.plan.plan_id === 'unlimited'  ? 'Collaborators' : 'Balances'}  
              value={5}
          />
          <Tab
              disableRipple
              classes={{ root: classes.tabRoot, selected: classes.tabSelected, labelContainer: classes.tabCointainer }}
              label="Settings"
              value={6}
          />
          </Tabs> 

      </div>
    );
  }
}

CustomizedTabs.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CustomizedTabs);