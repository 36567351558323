
import { connect } from "react-redux";
import * as actions from "../../store/actions/auth";
import * as interActions from "../../store/actions/interactions";
import React from "react";
import TextField from '@material-ui/core/TextField';
import whiteLoader from '../../Media/whiteloader.gif'
import axios from 'axios';
import {backendUrl} from '../../variables';
import {withRouter} from 'react-router-dom'; /* MAY NEED TO REMOVE */

import {
    Form
} from 'antd';

class NormalLoginForm extends React.Component {

    state = {
        loadingReset: false,
        resetValid: false, 
        resetError: false,
    }

    componentDidMount() {
        //this.props.logout();
        //this.props.history.push('/')
    }

    handleForgotSend = (email) => {

        this.setState(state => ({...state, loadingReset: true}))
        const urlCall = `${backendUrl}/rest-auth/password/reset/`

        axios.post(
            urlCall, 
            {email: email}
        ).then( () => {
            //this.props.toggleForgot();
            this.setState(state => ({...state, loadingReset: false, resetValid: true, resetError: false}))
        }
        ).catch( () => this.setState(state => ({...state, loadingReset: false, resetValid: false, resetError: true})) )

    }

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.props.onAuth(values.email, values.password, values.remember);
                this.props.history.push("/");
            }
        });
    };

    handleFogot = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.handleForgotSend(values.forgotEmail);
                this.state.forgotSubmit()
                //this.props.history.push("/");
            }
        });
    };

    render() {

        const { getFieldDecorator } = this.props.form;

        return (

            this.props.forgot ? 

                this.state.resetValid ? 

                <div>Password reset email sent successfully. Please check your email for further steps on how to reset your email.</div>

                :
                <React.Fragment>
                <div style={{marginBottom: 12}}>
                    If you have forgotten your password, we will send you a link to reset your password. 
                </div>
                <form onSubmit={this.handleFogot}>

                    {getFieldDecorator('forgotEmail', {
                        rules: [{ required: true, message: 'Please input your email!' }],
                    })(
                        <TextField
                        error={this.props.error}
                        id="outlined-email-input"
                        label="Email"
                        style={{ marginLeft: 5,
                                marginRight: 5,
                                width: '100%',
                                borderRadius: 7, 
                                backgroundColor: 'white',
                                }}
                        type="email"
                        name="email"
                        ref={this.email}
                        autoComplete="email"
                        margin="normal"
                        variant="outlined"
                        onChange={this.handleEmail}
                        onFocus={this.focusInput}
                    />
                    )}
                        <div
                            style={{display: 'flex', 
                                    justifyContent: 'space-between', 
                                    alignItems: 'center',
                                    width: '100%', 
                                    marginTop: 20,
                                    marginLeft: 10, 
                                    marginRight: 15,}}>

                        <div className="basier-p4-caps" style={{color: '#6A7E93', cursor: 'pointer'}} onClick={this.props.toggleForgot}>Back to Login</div>
                        {this.state.loadingReset ?
                        <div className="basier-p2"
                        style={{backgroundColor: '#0078DB', 
                                borderRadius: 20, 
                                color: 'white', 
                                border: 'none',
                                boxShadow: 'none',
                                height: 40, 
                                width: 87,
                                display: 'flex', 
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginRight: 10,
                                cursor: 'pointer',
                                outline: 'none'
                                
                                }}>
                            <img src={whiteLoader} alt="Loading" style={{height: 20, width: 20}}/>
                        </div>
                        :
                            <input type="submit" value="Submit" className="basier-p2"
                                style={{
                                    backgroundColor: '#0078DB', 
                                    borderRadius: 20, 
                                    color: 'white', 
                                    marginTop: 24,
                                    border: 'none',
                                    boxShadow: 'none',
                                    height: 40, 
                                    width: 87,
                                    display: 'flex', 
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    marginRight: 10,
                                    cursor: 'pointer',
                                    outline: 'none'
                                    
                            }}/>
                        }
                        </div>

                </form>
                {this.state.resetError && 
                <div style={{color: 'red', marginLeft: 12}} className="basier-p4">Problem with password reset. Please try again.</div>
                }
            </React.Fragment>

           


            :


            <form onSubmit={this.handleSubmit}>

                {getFieldDecorator('email', {
                    rules: [{ required: true, message: 'Please input your email!' }],
                })(
                    <TextField
                    error={this.props.error}
                    id="outlined-email-input"
                    label="Email"
                    style={{ marginLeft: 0,
                            marginRight: 0,
                            width: '100%',
                            borderRadius: 7, 
                            backgroundColor: 'white',
                            }}
                    type="email"
                    name="email"
                    ref={this.email}
                    autoComplete="email"
                    margin="normal"
                    variant="outlined"
                    onChange={this.handleEmail}
                    onFocus={this.focusInput}
                />
                )}

                {getFieldDecorator('password', {
                    rules: [{ required: true, message: 'Please input your Password!' }],
                })(
                    <TextField
                            error={this.props.error}
                            id="outlined-password-input"
                            label="Password"
                            style={{ marginLeft: 0,
                                marginRight: 0,
                                width: '100%',
                                borderRadius: 7, 
                                backgroundColor: 'white',
                            }}
                            type="password"
                            ref={this.pw}
                            autoComplete="current-password"
                            margin="normal"
                            variant="outlined"
                            onChange={this.handlePW}
                            onFocus={this.focusInput}
                            value

                        />
                )}

                <div
                    style={{display: 'flex', 
                            justifyContent: 'space-between', 
                            alignItems: 'center',
                            width: '100%', 
                            marginTop: 20,
                            marginLeft: 10, 
                            marginRight: 15,}}>
                        
                        {this.props.register !== true && 
                        <div className="basier-p4-caps" style={{color: '#6A7E93', cursor: 'pointer'}} onClick={this.props.toggleForgot}>Forgot password?</div>}
                        {!this.props.loading ? 
                        <input type="submit" value="Log in" className="basier-p2"
                            style={{backgroundColor: '#0078DB', 
                                    borderRadius: 20, 
                                    color: 'white', 
                                    border: 'none',
                                    boxShadow: 'none',
                                    height: 40, 
                                    width: 160,
                                    display: 'flex', 
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    marginRight: 10,
                                    cursor: 'pointer',
                                    outline: 'none'
                                    
                                    }}/>
                        :

                        <div className="basier-p2"
                        style={{backgroundColor: '#0078DB', 
                                borderRadius: 20, 
                                color: 'white', 
                                border: 'none',
                                boxShadow: 'none',
                                height: 40, 
                                width: 160,
                                display: 'flex', 
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginRight: 10,
                                cursor: 'pointer',
                                outline: 'none'
                                
                                }}>
                        <img src={whiteLoader} alt="Loading" style={{height: 20, width: 20}}/>
                        </div>

                        }
                            
                </div>
                {this.props.error && 
                    <div style={{color: 'red', marginLeft: 12}} className="basier-p4">Login error. Please check email and password.</div>
                }

            </form>
        );
    }
}

const WrappedNormalLoginForm = Form.create()(NormalLoginForm);


const mapStateToProps = state => {
    return {
        loading: state.auth.loading,
        error: state.auth.error
    };
};

const mapDispatchToProps = dispatch => {
    return {
        logout: () => dispatch(actions.logout()),
        onAuth: (email, password, remember) =>
            dispatch(actions.authLogin(email, password, remember)),
        showSnack: (data) => dispatch(interActions.showSnack(data)), 
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(WrappedNormalLoginForm));