import React, {Component} from 'react';
import {connect} from 'react-redux';
import OverallLPBox from "./OverallLPBox";
import listIcon from "../../../../../../Media/listIcon.svg";
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

class DefiOverview extends Component {
    render() {
        const liquidity_profit = this.props.portfoliosData?.[this.props.portfolioId]?.liquidity_profit || 0;
        return (
            <div>
                {this.props.portfoliosData?.[this.props.portfolioId]?.liquidity_pools &&
                this.props.portfoliosData[this.props.portfolioId].liquidity_pools.length > 0 ?
                    <div>
                        <div style={{
                            height: 40,
                            borderRadius: 5,
                            padding: 12,
                            backgroundColor: 'rgba(243,245,246, 1)',
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}>
                            <div className={"poppins"} style={{fontSize: 18, color: 'black'}}>Total Defi Unrealized
                                P&L
                            </div>
                            <div className={"poppins"}
                                 style={{
                                     fontSize: 18,
                                     display: 'flex',
                                     alignItems: 'center',
                                     color: liquidity_profit > 0 ? '#4CDAC1' : liquidity_profit < 0 ? '#F9716B' : '#6A7E93',
                                 }}
                            >
                                {liquidity_profit > 0 ?
                                    <ArrowDropUpIcon style={{fontSize: 30}}/> : liquidity_profit < 0 ?
                                        <ArrowDropDownIcon style={{fontSize: 30}}/> : null}
                                {this.props.symbol}{Math.abs(liquidity_profit).toLocaleString('en', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                            })}</div>
                        </div>
                        <OverallLPBox pools={this.props.portfoliosData[this.props.portfolioId].liquidity_pools}
                                      symbol={this.props.symbol}/>
                    </div>
                    :
                    <div style={{
                        height: 200,
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: 12
                    }}>
                        <div style={{
                            height: '100%',
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            textAlign: 'center',
                            padding: 48,
                            flexDirection: 'column'
                        }}>
                            <div>
                                <img src={listIcon} alt="" style={{height: 80}}/>
                            </div>
                            <div className="basier-p2" style={{marginTop: 24}}>
                                <div>You do not have any Liquidity Pool/Defi transactions in your portfolios yet.</div>
                                <div>Once you add these types of transactions you will see your data here.</div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        );
    }

}

function mapStateToProps(state) {
    return {
    symbol: state.auth.currency.symbol
};
}

function mapDispatchToProps(dispatch)
{
    return {};
}

export default connect(
mapStateToProps,
)(DefiOverview);