import React from 'react';
import { connect } from 'react-redux';
import {siwtchTransactionType} from '../../../../../../helpers/helpers';
import CoinList from '../../../../../MobileOnly/MobileCurrency'
import TypeList from '../../../../../MobileOnly/MobileType'
import * as actions from "../../../../../../store/actions/interactions";
import * as dataActions from "../../../../../../store/actions/data";
import Switch from '../../../../../Assets/Switch';
import UserList from '../../../../../MobileOnly/UserDrawer';
import currencyDict from '../../../../../../helpers/currency.json'
import PriceType from '../../NewTransaction/QuestionBlocks/SelectPriceType';

var moment = require('moment');
require('moment/locale/en-gb');

function getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
  
        // Windows Phone must come first because its UA also contains "Android"
      if (/windows phone/i.test(userAgent)) {
          return "Windows Phone";
      }
  
      if (/android/i.test(userAgent)) {
          return "Android";
      }
  
      // iOS detection from: http://stackoverflow.com/a/9039885/177710
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
          return "iOS";
      }
  
      return "unknown";
  }

class assetModalContent extends React.PureComponent {

    

    constructor(props) {

        super(props)
        

          this.state = {

            
          }
  
      }
    
    ChangePriceType = (val) => {
    this.props.setInteraction({label: 'priceType', value: val})
    let priceSend = null;

    if(this.props.passState['transactionData']['unit_price'] !== null && this.props.passState['transactionData']['unit_price'] !== undefined && this.props.passState['transactionData']['amount'] !== null && this.props.passState['transactionData']['amount'] !== undefined && this.props.passState['transactionData']['amount'] !== 0 ) {

        if (val === 'unit') {
        
        priceSend = this.props.passState['transactionData']['unit_price'] * this.props.passState['transactionData']['amount']

        } else {
            
        
        priceSend = this.props.passState['transactionData']['unit_price'] / this.props.passState['transactionData']['amount']

        }
    }

    this.props.updatePriceType(priceSend)
    }
    
    render () {

        return (    

                    <div style={{minHeight: 300}}>

                        <div style={{marginTop: 12, display: 'flex', alignItems: 'center', justifyContent: 'space-between',  flexWrap: 'wrap', marginLeft: 12}}>
                                    
                            <div 
                                className={`touchableOpacity basier-p4-caps transaction-bubble ${ this.props.passState.type === 'long' ? `transaction-color-${`buy`}` : 'color-grey' }`}
                                onClick={() => this.props.selectType('long')}
                                style={{
                                    marginBottom: 12, 
                                    fontSize: 20,
                                    fontWeight: 900, 
                                    padding: 20, 
                                    marginRight: 12,
                                    marginLeft: 0,
                                    minWidth: 80, 
                                    flexGrow: 1,
                                    height: 50, 
                                    borderRadius: 10, 
                                    
                                    }}>

                                    Long
                            </div>

                            <div 
                                className={`touchableOpacity basier-p4-caps transaction-bubble ${ this.props.passState.type === 'short' ? `transaction-color-${`sell`}` : 'color-grey' }`}
                                onClick={() => this.props.selectType('short')}                                    
                                style={{
                                    marginBottom: 12, 
                                    fontSize: 20,
                                    fontWeight: 900, 
                                    padding: 20, 
                                    marginRight: 12,
                                    marginLeft: 0,
                                    minWidth: 80, 
                                    flexGrow: 1,
                                    height: 50, 
                                    borderRadius: 10
                                    
                                    }}>

                                    Short
                            </div>

                            <div 
                                className={`touchableOpacity basier-p4-caps transaction-bubble ${ this.props.passState.type === 'pnl' ? `transaction-color-${`withdraw`}` : 'color-grey' }`}
                                onClick={() => this.props.selectType('pnl')}                                    
                                style={{
                                    marginBottom: 12, 
                                    fontSize: 20,
                                    fontWeight: 900, 
                                    padding: 20, 
                                    marginRight: 12,
                                    marginLeft: 0,
                                    minWidth: 80, 
                                    flexGrow: 1,
                                    height: 50, 
                                    borderRadius: 10
                                    
                                    }}>

                                    P&L
                            </div>
                        
                        </div>
                        
                        {this.props.passState.type !== null && this.props.passState.type !== undefined && 
                        

                        this.props.passState.type === 'pnl' ? 
                        
                        <div style={{marginTop: 0}}>
                            
                            <div className="basier-p4-caps" style={{color: 'black', fontWeight: 900, paddingLeft: 12, marginTop: 12, marginBottom: 12}}>
                                P&L Currency
                            </div>

                             <div 
                                style={{
                                 height: 60,
                                 display: 'flex', 
                                 alignItems: 'center',
                                 justifyContent: 'space-between',
                                 paddingLeft: 24,
                                 borderBottom: '1px solid #E1E5E9',
                                 borderTop: '1px solid #E1E5E9',
                                 width: '100%',
                                 textTransform: 'uppercase',
                                 fontWeight: 900
                             }}
                                onClick={() => this.props.coinModalOpen()}
                                className="basier-p3">

                                <div style={{marginRight: 24, display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%'}}>

                                    <div className="truncate" style={{marginRight: 24, color: '#6A7E93', flex: 1}}>
                                        
                                        {
                                            this.props.passState.extraData.pnlCoin !== undefined && this.props.passState.extraData.pnlCoin !== null ? 
                                            <div>

                                                <div style={{display: 'flex', alignItems: 'center'}}>
                                                
                                                        
                                                {
                                                    
                                                    this.props.passState.extraData.pnlCoin['image_thumb'] !== "" && this.props.passState.extraData.pnlCoin['image_thumb'] !== null  ? 
                                                        <span><img style={{height: 25, width: 25, marginRight: 12}} src={this.props.passState.extraData.pnlCoin['image_thumb']} alt=""/></span>
                                                        :
                                                        <span style={{ height: 25, width: 25, marginRight: 12, display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: `${ currencyDict[this.props.passState.extraData.pnlCoin['symbol']] === undefined ? '16px' : currencyDict[this.props.passState.extraData.pnlCoin['symbol']]['symbol'].length > 1 ? '16px' : '22px'}` }}>
                                                            {currencyDict[this.props.passState.extraData.pnlCoin['symbol']] === undefined ? this.props.passState.extraData.pnlCoin['symbol'] : currencyDict[this.props.passState.extraData.pnlCoin['symbol']]['symbol'].length > 1 ? currencyDict[this.props.passState.extraData.pnlCoin['symbol']]['symbol'] : currencyDict[this.props.passState.extraData.pnlCoin['symbol']]['symbol'] }
                                                        </span>

                                                }

                                                    <div>{this.props.passState.extraData.pnlCoin['symbol']}</div>


                                                </div>
                                            </div>
                                            
                                            :
                                            <div style={{marginRight: 12}}>select...</div>
                                        }
                                    </div>
                                    
                                    <div style={{display: 'flex', alignItems: 'center', width: 15}}>
                                        <div>
                                            >
                                        </div>
                                    </div>
                                    
                                </div>

                             </div>
                            
                            {this.props.passState.extraData.pnlCoin !== null &&
                            
                            <React.Fragment>
                                <div className="basier-p4-caps" style={{color: 'black', fontWeight: 900, paddingLeft: 12, marginTop: 12, marginBottom: 6}}>
                                    Amount (Negative for loss)
                                </div>
                                
                                <div style={{display: 'flex', height: 50, alignItems: 'center', paddingRight: 12, paddingLeft: 12}} className="basier-p3">
                                    <input onFocus={this.onFocus} value={this.props.passState.extraData.size || ""} onChange={(e) => this.props.handleInput(e, 'size')} style={{width: '100%', textAlign: 'right', height: 50, border: '1px solid #E1E5E9', paddingRight: 12, zIndex: 9001, backgroundColor: 'transparent'}} type="text" className={`no-outline ${this.props.passState.validations.amount ? '' : 'error-input'}`} />
                                </div>
                                {!this.props.passState.validations.amount  && <div className="basier-p4" style={{color: 'red', marginTop: 4, marginLeft: 12}}>P&NL amount must use numbers only, and dot for decimals.</div>  }
                            </React.Fragment>
                            
                            }

                        </div>

                        :

                        this.props.passState.type !== null && this.props.passState.type !== undefined && 

                        <div style={{marginTop: 0}}>
                            
                            <div className="basier-p4-caps" style={{color: 'black', fontWeight: 900, paddingLeft: 12, marginTop: 12, marginBottom: 12}}>
                                Exchange
                            </div>

                             <div 
                                style={{
                                 height: 60,
                                 display: 'flex', 
                                 alignItems: 'center',
                                 justifyContent: 'space-between',
                                 paddingLeft: 24,
                                 borderBottom: '1px solid #E1E5E9',
                                 borderTop: '1px solid #E1E5E9',
                                 width: '100%',
                                 textTransform: 'uppercase',
                                 fontWeight: 900
                             }}
                                onClick={() => this.props.exchangeModalOpen()}
                                className="basier-p3">

                                <div style={{marginRight: 24, display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%'}}>

                                    <div className="truncate" style={{marginRight: 24, color: '#6A7E93', flex: 1}}>
                                        
                                        {
                                            this.props.passState.selectedExchange !== undefined && this.props.passState.selectedExchange !== null ? 
                                            <div>

                                                <div style={{display: 'flex', alignItems: 'center'}}>
                                                
                                                        
                                                    <div>
                                                        <img style={{height: 30, width: 30, marginRight: 12, }} src={this.props.passState.selectedExchange['image']} alt=""/>
                                                    </div>

                                                
                                                    <div style={{color: 'black'}} className="basier-p3 truncate">{this.props.passState.selectedExchange['label']}</div>
                                                </div>
                                            </div>
                                            
                                            :
                                            <div style={{marginRight: 12}}>select...</div>
                                        }
                                    </div>
                                    
                                    <div style={{display: 'flex', alignItems: 'center', width: 15}}>
                                        <div>
                                            >
                                        </div>
                                    </div>
                                    
                                </div>

                             </div>

                        </div>
                        }
                        
                        {
                        this.props.passState.type !== 'pnl' &&

                        this.props.passState.selectedExchange !== undefined && this.props.passState.selectedExchange !== null ? 

                        <div style={{marginTop: 0}}>
                            
                            <div className="basier-p4-caps" style={{color: 'black', fontWeight: 900, paddingLeft: 12, marginTop: 12, marginBottom: 12}}>
                                Contract
                            </div>

                             <div 
                                style={{
                                 height: 60,
                                 display: 'flex', 
                                 alignItems: 'center',
                                 justifyContent: 'space-between',
                                 paddingLeft: 24,
                                 borderBottom: '1px solid #E1E5E9',
                                 borderTop: '1px solid #E1E5E9',
                                 width: '100%',
                                 textTransform: 'uppercase',
                                 fontWeight: 900
                             }}
                                onClick={() => this.props.contractModalOpen()}
                                className="basier-p3">

                                <div style={{marginRight: 24, display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%'}}>

                                    <div className="truncate" style={{marginRight: 24, color: '#6A7E93', flex: 1}}>
                                        
                                        {
                                            this.props.passState.selectedContract !== undefined && this.props.passState.selectedContract !== null ? 
                                            <div>

                                                <div style={{display: 'flex', alignItems: 'center'}}>
                                                
                                                        
                                                    <div>
                                                        <img style={{height: 30, width: 30, marginRight: 12, }} src={this.props.passState.selectedContract['image']} alt=""/>
                                                    </div>

                                                
                                                    <div style={{color: 'black'}} className="basier-p3 truncate">{this.props.passState.selectedContract['label']}</div>
                                                </div>
                                            </div>
                                            
                                            :
                                            <div style={{marginRight: 12}}>select...</div>
                                        }
                                    </div>
                                    
                                    <div style={{display: 'flex', alignItems: 'center', width: 15}}>
                                        <div>
                                            >
                                        </div>
                                    </div>
                                    
                                </div>

                             </div>

                        </div>
                        :
                        null
                        }

                    </div>
        )

    }
   
}

const mapStateToProps = state => {
    return {

        currency_symbol: state.auth.currency.symbol,
        coins: state.appwide.all_coins,
        transaction: state.interactions.transactionAddData,
        portfolio_users: state.data.scopedUsers, 
        user_id: state.auth.user_id, 
        user_first_name: state.auth.user_first_name,
        user_last_name: state.auth.user_last_name,
        priceType: state.interactions.priceType
        
    };
};

const mapDispatchToProps = dispatch => {
    return {
        addTransaction: (data) =>
            dispatch(actions.addTransaction(data)),
        updateTransaction: (data) =>
            dispatch(actions.updateTransaction(data)),
        removeTransaction: (data) =>
            dispatch(actions.removeTransaction(data)), 
        callUserList: (portfolio) =>
          dispatch(dataActions.callUserList(portfolio)), 
        setInteraction: (data) =>
            dispatch(actions.setInteraction(data)), 
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(assetModalContent);
