import React from 'react';
import PropTypes from 'prop-types';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import * as actions from "../../../store/actions/interactions";
import * as appwideActions from "../../../store/actions/appwide";
import * as dataActions from "../../../store/actions/data";
import { connect } from 'react-redux';
import { backendUrl } from '../../../variables';
import axios from 'axios';

class ResponsiveDialog extends React.Component {

handleClose = () => {

    this.props.toggleModal({type: null, open: false, payload: null})
    
}

deleteData = () => {

    let headers = { "content-type": "application/json" };
    headers['x-user'] = this.props.user_id
    if (this.props.token !== null && this.props.token !== undefined) {

        headers['Authorization'] = `Token ${this.props.token}`

    }

    const url_call = `${backendUrl}/v1/transaction_detail/${this.props.transactionId}`

    axios.delete(url_call, {headers: headers})
        .then(json => {

            
            this.props.getOverallData()
            this.props.getUserLimits(this.props.user_id)
            this.handleClose()

            //console.log(json['data'])

        })



}


handleDelete = () => {

    this.props.deleteTransaction()
    this.handleClose()

}

  
  render() {

    
    return (

      <div style={{height: 200, witdth: 500, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
        
            <div className="basier-p2">Are you sure you want to delete this transaction?</div>

            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 24}}>


            <div 
                className='basier' 
                onClick={this.deleteData} 
                style={{height: 40, borderRadius: 35, marginLeft: 12, marginRight: 12, marginTop: 24, cursor: 'pointer', color: 'white', backgroundColor: '#4CDAC1', width: 150, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                Yes
            </div>

            <div 
                className='basier' onClick={this.handleClose} 
                style={{height: 40, borderRadius: 35, marginLeft: 12, marginRight: 12, marginTop: 24, cursor: 'pointer', color: 'white', backgroundColor: '#F9716B', width: 150, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                No
            </div>

            </div>

      </div>

    )
  }
}

ResponsiveDialog.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
};


const mapStateToProps = state => {
    return {

        modalPref: state.interactions.transactionAddModal,
        portfoliosData: state.data.portfoliosData,
        transaction: state.interactions.transactionAddData,
        all_coins: state.appwide.all_coins, 
        exchangeCheck: state.interactions.exchangeCheck, 
        exchanges: state.appwide.exchanges, 
        wallets: state.appwide.wallets, 
        user_id: state.auth.user_id,
        user_first: state.auth.user_first_name,
        user_last: state.auth.user_last_name,
        token: state.auth.token,
        
    };
};

const mapDispatchToProps = dispatch => {
    return {
       toggleModal: (data) => 
            dispatch(actions.toggleModal(data)), 
        removeTransaction: (data) =>
            dispatch(actions.removeTransaction(data)), 
        setTrTime: (data) => 
            dispatch(actions.setTrTime(data)), 
        setInteraction: (data) => 
            dispatch(actions.setInteraction(data)), 
        setCurrentView: (view) => 
            dispatch(dataActions.setCurrentView(view)),
        componentDataFetch: (scope, view, currency, user, portfolio, globalGraphData, portfolioGraphData, inBitcoin) =>
            dispatch(dataActions.componentDataFetch(scope, view, currency, user, portfolio, globalGraphData, portfolioGraphData, inBitcoin)), 
        getFlatWallets: (user_id) => 
            dispatch(appwideActions.getFlatWalletList(user_id)), 
        getAllPortfolioList: (user_id) => 
            dispatch(appwideActions.getAllPortfolioList(user_id)), 
        getUserLimits: (user) => dispatch(appwideActions.getUserLimits(user)), 
        
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withMobileDialog()(ResponsiveDialog));
