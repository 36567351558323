import React from 'react';
import currencyDict from '../../../../helpers/currency.json'
import okGreen from '../../../../Media/okGreen.svg'
import cancelRed from '../../../../Media/cancelRed.svg'
//import refreshSmall from "../../../../Media/refreshSmall.svg";
import { backendUrl } from '../../../../variables'
import { connect } from 'react-redux';
import * as appwideActions from "../../../../store/actions/appwide";
import * as actions from "../../../../store/actions/interactions";
import axios from 'axios';
import bin from '../../../../Media/bin.svg'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/core/styles';


const styles = theme => ({
    container: {
        width: '100%', 
        padding: '24px 48px', 
        display: 'flex',
        flexDirection: 'column'
    },
    managementBox: {
        width: '100%', 
        height: 70, 
        backgroundColor: '#F8F9FA', 
        display: 'flex', 
        justifyContent: 'space-between', 
        padding: '12px 24px',
    }, 
    transactionBox: {
        height: 380,
        display: 'flex', 
        flexDirection: 'row',
    },
    checkRoot: {
        color: '#6A7E93',
        '&$checked': {
          color: '#0078DB',
        },
      },
      checked: {
          color: '#0078DB'
      },
    });


const getValue = (dict, val) => {
    let ret = undefined
    try {ret = dict[val]} catch(e) {}
    return ret
}

const getNumber = (dict, sub, val) => {
    let ret = 0.0
    try {ret = dict[sub][val]} catch(e) {}
    return ret
}

const formatNum = (num) => {
    const digits = Math.abs(num) > 1 || num === 0 ? 2 : 6
    let form = null;
    try {
        form = num.toLocaleString('en', {minimumFractionDigits: digits, maximumFractionDigits: digits})
    } catch(e) {

    }
    return form
}

class WalletLine extends React.PureComponent {

    state = {
        walletEdit: false,
        newAddress: null,
    }

    subscopeComponent = (props) => {
        const {classes} = props;
        return (
            <div>
                <div className="basier-p3" style={{color: '#0078DB'}}>What should we do with transactions linked to this account</div>
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <RadioGroup
                        name="deleteScope"
                        value={props.subValue}
                        style={{display: 'flex', flexDirection: 'row'}}
                        onChange={(e) => props.onSelect(e.target.value)}
                        //style={{display: 'inline-block'}}

                    >   

                    <FormControlLabel value="keep" 
                                        control={<Radio classes={{
                                            root: classes.checkRoot,
                                            checked: classes.checked
                                        }} />} 
                                        label='Keep' />

                    <FormControlLabel value="cascade" 
                                        control={<Radio classes={{
                                            root: classes.checkRoot,
                                            checked: classes.checked
                                        }}/>} 
                                        label="Delete" />

                    
                </RadioGroup>
                </div>
            </div>
        )
    }

    styledQuestion = withStyles(styles)(this.subscopeComponent)

    confirmDelete = () => {
        this.props.toggleModal(
            {
                type: 'confirmation_modal', 
                payload: {
                    message: `You are about to delete this account.`,
                    forceYes: 'Delete',
                    forceNo: 'Cancel',
                    subscope: this.styledQuestion,
                    subscopeValue: 'keep',
                    handleConfirm: (scope) => this.deleteWallet(scope),
                }, 
                open: true
            }
        )
    }


    render() {
        const {data, balance, lineData} = this.props

        let calculatedData = null
        try {
          calculatedData = lineData[data.coin]
        } catch(e) {} 

        let scopeBalance = {}
        try {
          scopeBalance = balance[calculatedData.id]
        } catch(e) {}
        
        let val = calculatedData !== null ? calculatedData : undefined 

        if (val === undefined) {

          const scopeCoin = this.props.all_coins.filter(v => v.id == data.coin)
          if (scopeCoin.length > 0) {
            val = {coin_image: scopeCoin[0].image_thumb, coin_name: scopeCoin[0].display_name, coin_ticker: scopeCoin[0].symbol }
          }

        }
        

        return (

            <div 
                style={{ 
                    height: 60,
                    paddingTop: 8, 
                    paddingBottom: 8,  
                    display: 'flex', 
                    alignItems: 'center', 
                    paddingLeft: 6, 
                    paddingRight: 6,
                    width: '100%',
                    borderBottom: '1px solid #CCC'
                    }} 
                    className="basier-p3 light-hover"
                >
                
                {val == undefined ?
                    <div style={{width: '25%', display: 'flex', alignItems: 'center'}} className="truncate">
                        <div>
                          <div>{data.asset}</div>
                          <div style={{color: '#F9716B'}} className="basier-p4-caps">UNSUPPORTED</div>
                        </div>  
                    </div>
                :
                    <div style={{width: '25%', display: 'flex', alignItems: 'center'}} className="truncate">
                        <div>
                            {val.coin_image !== "" && val.coin_image !== null ? 
                            <span><img style={{height: 25, width: 25, marginRight: 12}} src={val.coin_image} alt=""/></span>
                            :
                            <span style={{height: 25, width: 25, marginRight: 12, display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 20 }}>
                                {currencyDict[val.coin_ticker] === undefined ? null : currencyDict[val.coin_ticker]['symbol'].length > 1 ? null : currencyDict[val.coin_ticker]['symbol'] }
                            </span>
                            }
                        </div>
                        <div style={{width: '100%'}} className="truncate">
                            <div className="basiser-p3 truncate" style={{color: 'black'}}>
                                {val.coin_ticker}
                            </div>
                            <div className="basier-p4-caps truncate" style={{color: '#6A7E93'}}>
                                {
                                val.coin_type === 'fiat' ? 
                                val.coin_ticker 
                                :
                                val.coin_name}
                            </div>
                        </div>
                    </div>
                }


                <div style={{width: '35%', display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>
                    <div className="basier-p3" style={{color: 'black'}}>
                        {formatNum(parseFloat(getValue(data, 'amount')))}
                    </div>
                    <div className="basier-p4-caps" style={{color: '#6A7E93'}}>
                    {formatNum(parseFloat(getValue(data, 'free')))}
                    </div>
                </div>
                
                <div style={{flex: 1, minWidth: '30%', textAlign: 'right'}}>
                    {this.props.balance === undefined || this.props.loading ? 
                    <div style={{width: '100%'}} className="pulse-skeleton" />
                    :
                    getValue(scopeBalance, 'balance') === undefined ? 
                    null :
                    <div>
                        <div className="basier-p3" style={{color: 'black'}}>
                            {formatNum(getValue(scopeBalance, 'balance'))}
                        </div>
                        <div className="basier-p4-caps" style={{color: '#6A7E93'}}>
                            {this.props.currency_symbol}{formatNum(getValue(scopeBalance, 'balance') * getValue(scopeBalance, 'price'))}
                        </div>
                    </div>
                    }
                </div>

                <div style={{width: 50, textAlign: 'right'}}>

                    <img onClick={() => this.confirmDelete()} src={bin} style={{height: 25, width: 25, cursor: 'pointer'}} alt="del" />

                </div>
                
            </div>

        )
    }

}

const mapStateToProps = state => {
    return {
        user_id: state.auth.user_id, 
        token: state.auth.token,
        walletsData: state.appwide.wallets, 
        currency_symbol: state.auth.currency.symbol,
        all_coins: state.appwide.all_coins

    };
};

const mapDispatchToProps = dispatch => {
    return {
        updateSingleWallet: (payload) => 
            dispatch(appwideActions.updateSingleWallet(payload)),
        toggleModal: (data) => 
            dispatch(actions.toggleModal(data)), 
        getWallets: (user) => dispatch(appwideActions.getWallets(user)), 
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(WalletLine)