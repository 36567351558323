import React from 'react';
import Select, { components }  from 'react-select';
import { connect } from 'react-redux';
import * as actions from "../../../store/actions/data";
import * as auth_actions from "../../../store/actions/auth";




const colourStyles = {
  control: styles => ({ ...styles, backgroundColor: 'transparent', width: '90px', border: '0px', color: '#6A7E93', cursor: 'text', dispay:'inline-block'}),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {

    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected ? 'white' : isFocused ? '#F3F5F6' : null,
      color: isDisabled
        ? '#6A7E93'
        : isSelected ? '#6A7E93' : '#6A7E93',
      opacity: isDisabled
        ? '1'
        : isSelected ? '1' : '0.4',
    };
  },

};

const DropdownIndicator = (
  props: ElementConfig<typeof components.DropdownIndicator>
) => {
  return (
    <components.DropdownIndicator {...props}>
      <div style={{marginLeft: '12px'}}>
        <svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M14.9136 1L7.95679 8L1 1" stroke="#6A7E93" strokeWidth="1.5" strokeMiterlimit="10"/>
        </svg>
      </div>
    </components.DropdownIndicator>
  );
};


class MenuSelector extends React.Component {

  state = {
    selectedOption: {value: this.props.currency_used, label: this.props.currency_used}
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currency_used !== this.props.currency_used) {
      this.setState(state => ({...state, selectedOption: {value: this.props.currency_used, label: this.props.currency_used} }))
    }
  }

  handleChange = (selectedOption) => {

    if ((selectedOption.value !== this.state.selectedOption.value) || (this.props.inBitcoin === 'bitcoin')) {

      this.setState({ selectedOption });
      const new_currency = selectedOption.value
      const data = {"preferred_currency": new_currency}
      
      this.props.updateCurrency(data)

      const graphDataRequest = {
        period: this.props.graphData.period,
        start: this.props.graphData.start,
        end: this.props.graphData.end, 
      }

      const portfolioGraphRequest = {
        period: this.props.portfolioGraphData.period,
        start: this.props.portfolioGraphData.start,
        end: this.props.portfolioGraphData.end, 
      }

      this.props.setPfScope('fiat')
      this.props.componentDataFetch('update', this.props.currentView, new_currency, this.props.user, this.props.currentPortfolio, {}, {}, 'fiat')
      this.props.setGraphScope("fiat", "global");
      this.props.setGraphScope("fiat", "portfolio");
    }
    
  }

  render() {
    const { selectedOption } = this.state;

    return (
      <Select
        value={selectedOption}
        onChange={this.handleChange}
        options={this.props.fiatList}
        isSearchable={true}
        styles={colourStyles}
        maxMenuHeight={210}
        minMenuHeight={50}
        components={{ DropdownIndicator }}
        classNamePrefix="react-select-currency"
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
          ...theme.colors,
            primary25: '#6A7E93',
            primary: '#6A7E93',
          },
        })}
      />
    );
  }
}

const mapStateToProps = state => {
  return {

      fiatList: state.data.fiatList,
      currency_used: state.auth.currency.id, 
      graphData: state.data.globalBalance,
      portfolioGraphData: state.data.portfolioBalance,
      user: state.auth.user_id,
      portfolio: state.auth.default_portfolio_id,
      currentPortfolio: state.data.menu_selected, 
      currentView: state.data.currentView, 
      inBitcoin: state.data.bitcoinPf

  };
};

const mapDispatchToProps = dispatch => {
  return {
      updateCurrency: (data) => 
          dispatch(auth_actions.setViewCurrency(data)),
      componentDataFetch: (scope, view, currency, user, portfolio, globalGraphData, portfolioGraphData) =>
            dispatch(actions.componentDataFetch(scope, view, currency, user, portfolio, globalGraphData, portfolioGraphData)), 
      setGraphScope: (currency, scope) => 
            dispatch(actions.setGraphScope(currency, scope)), 
      setPfScope: (currency, scope) => 
            dispatch(actions.setPfScope(currency, scope)), 
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MenuSelector);
