import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";
import currencyData from "../../helpers/currency.json";
import { siwtchTransactionType } from "../../helpers/helpers";

const initialState = {
  currentView: null,
  portfolioList: [],
  menuList: {},
  bitcoinPf: "fiat",
  totalGlobalValue: undefined,
  totalPortValue: {},
  globalBalance: {
    period: "30d",
    start: null,
    end: null,
    inBitcoin: "fiat",
    values: [],
    start_date: null,
    current_value: null,
    last_updated: null,
  },
  singleBalances: null,
  portfolioBalance: {
    period: "30d",
    start: null,
    end: null,
    inBitcoin: "fiat",
    data: {},
  },
  portfolioHoldings: {},
  portfoliosData: {},
  roiEvol: {},
  portfoliosHist: null,
  currency_used: {
    id: null,
    symbol: null,
  },
  transactions: null,
  deposits: null,
  menu_selected: null,
  selectedMenuOption: null,
  globalAssetList: {},
  fiatList: [],
  filteredFiat: [],
  scopedUsers: [],
  subscriptions: [],
  wallet_balance: undefined,
  balanceRisk: undefined,
  userRisk: undefined,
  importedTransactions: undefined,
  importedPositions: undefined,
  position_entries: undefined,
  tvData: {},
  connectedBalances: undefined,
  transferBalances: undefined,
  accountPositions: undefined,
};


const updateAccountPos = (state, action) => {
  return updateObject(state, {
    accountPositions: action.data,
  });
};

const updateConnectedBalances = (state, action) => {
  return updateObject(state, {
    connectedBalances: action.data,
  });
};

const updateTransferBalances = (state, action) => {
  return updateObject(state, {
    transferBalances: action.data,
  });
};

const updateSingleBalances = (state, action) => {
  return updateObject(state, {
    singleBalances: action.data,
  });
};

const updateTvData = (state, action) => {
  return {...state, tvData: {...state.tvData, [action.data.label]: {...state.tvData[action.data.label], [action.data.data.currency]: action.data.data}}}
};

const updateImportTr = (state, action) => {
  const data = { ...action.data.data };
  if (action.data.skip_filter === 1 || action.data.skip_filter === '1') {
    try {
      delete data["filters"];
    } catch (e) {}
  }
  return {
    ...state,
    importedTransactions: { ...state.importedTransactions, ...data },
  };
};

const resetTxImport = (state, action) => {
  return {
    ...state,
    importedTransactions: { ...state.importedTransactions, transactions: [] },
  };
};

const updatePortfolioTotal = (state, action) => {
  return updateObject(state, {
    totalPortValue: {
      ...state.totalPortValue,
      [action.data.portfolio_id]: action.data.data,
    },
  });
};

const updateGlobalTotal = (state, action) => {

  return updateObject(state, {
    globalBalance: {
      ...state.globalBalance,
      today_value: action.data,
    },
  });
};

const setSubs = (state, action) => {
  return updateObject(state, { subscriptions: action.data });
};

const setBalanceRisk = (state, action) => {
  return updateObject(state, { balanceRisk: action.data });
};

const setUserRisk = (state, action) => {
  return updateObject(state, { userRisk: action.data });
};

const setPfScope = (state, action) => {
  return updateObject(state, { bitcoinPf: action.data });
};

const setPortfolioHist = (state, action) => {
  return updateObject(state, { portfoliosHist: action.data });
};

const getGlobalData = (state, action) => {
  return updateObject(state, {
    globalBalance: {
      ...state.globalBalance,
      ...action.data,
    },
  });
};

const setCurrentView = (state, action) => {
  return updateObject(state, { currentView: action.data });
};

const updateMenuSelected = (state, action) => {
  return updateObject(state, { menu_selected: action.data });
};

const updatescopedUsers = (state, action) => {
  return updateObject(state, { scopedUsers: action.data });
};

const updateMenuPath = (state, action) => {
  return updateObject(state, { selectedMenuOption: action.data });
};

const getGraphData = (state, action) => {
  return updateObject(state, {
    globalBalance: {
      ...state.globalBalance,
      ...action.data,
      loading: false,
    },
  });
};

const getAllPortfoliosData = (state, actions) => {
  return updateObject(state, {
    portfoliosData: {
      ...state.portfoliosData,
      ...actions.data,
    },
  });
};

const getPortfolioHoldings = (state, actions) => {
  return updateObject(state, {
    portfolioHoldings: {
      ...state.portfolioHoldings,
      [actions.data.portfolio]: { ...actions.data.balances },
    },
  });
};

const getSinglePortfoliosData = (state, actions) => {
  return updateObject(state, {
    portfoliosData: {
      ...state.portfoliosData,
      [actions.data.portfolio]: { ...actions.data.data },
    },
  });
};

const overWritePorts = (state, actions) => {
  return updateObject(state, {
    portfoliosData: actions.data,
  });
};

const getSingleRoiEvol = (state, actions) => {
  return updateObject(state, {
    roiEvol: {
      ...state.roiEvol,
      [actions.data.portfolio]: { ...actions.data },
    },
  });
};

const getAllBalancesData = (state, actions) => {
  return updateObject(state, {
    globalAssetList: {
      ...state.globalAssetList,
      ...actions.data,
    },
  });
};

const setFiatList = (state, action) => {
  return updateObject(state, {
    fiatList: action.data,
    filteredFiat: action.data,
  });
};

const filterFiatList = (state, action) => {
  return updateObject(state, {
    filteredFiat: action.data,
  });
};

const setGlobalTime = (state, action) => {
  return updateObject(state, {
    globalBalance: {
      ...state.globalBalance,
      period: action.data,
    },
  });
};

const setPortfolioTime = (state, action) => {
  return updateObject(state, {
    portfolioBalance: {
      ...state.portfolioBalance,
      period: action.data,
    },
  });
};

const updatePortfolioValues = (state, action) => {
  return updateObject(state, {
    portfolioBalance: {
      ...state.portfolioBalance,
      data: {
        ...state.portfolioBalance.data,
        [action.data.portfolio_id]: {
          ...state.portfolioBalance.data[action.data.portfolio_id],
          ...action.data,
        },
      },
    },
  });
};

const setPortfolioList = (state, action) => {
  return updateObject(state, {
    portfolioList: action.data,
  });
};

const setGlobaltoBtc = (state, action) => {
  return updateObject(state, {
    globalBalance: {
      ...state.globalBalance,
      inBitcoin: action.data,
    },
  });
};

const setPFtoBtc = (state, action) => {
  return updateObject(state, {
    portfolioBalance: {
      ...state.portfolioBalance,
      inBitcoin: action.data,
    },
  });
};

const updatePositionTransactions = (state, action) => {
  return updateObject(state, {
    position_entries: { ...action.data },
  });
};

const updateTransactions = (state, action) => {
  return updateObject(state, {
    transactions: { ...action.data },
  });
};

const updateDeposits = (state, action) => {
  return updateObject(state, {
    deposits: { ...action.data },
  });
};

const updateWalletBalance = (state, action) => {
  return updateObject(state, {
    wallet_balance: { ...action.data },
  });
};

const flattenImportFilters = (state, action) => {

  let accounts = [];
  let descriptions = [];
  const givenExchanges = action.data.exchanges;
  const givenWallets = action.data.wallets;
  const allDesc = action.data.transaction_description;

  for (let i = 0; i < givenExchanges.length; i++) {
    accounts.push([
      `${givenExchanges[i][0]}-exchange`,
      givenExchanges[i][1],
    ]);
  }
  for (let j = 0; j < givenWallets.length; j++) {
    accounts.push([
      `${givenWallets[j][0]}-wallet`,
      givenWallets[j][1],
    ]);
  }
  for (let w = 0; w < allDesc.length; w++) {
    descriptions.push([allDesc[w], siwtchTransactionType(allDesc[w])[0]]);
  }
  descriptions = descriptions.sort(function (a, b) {
    if (a[1] < b[1]) {
      return -1;
    }
    if (a[1] > b[1]) {
      return 1;
    }
    return 0;
  });

  const putData = {
    base_id: action.data.base_id,
    scope: action.data.types,
    source_id: accounts,
    transaction_description: descriptions,
  };

  return {
    ...state,
    importedTransactions: { ...state.importedTransactions, filters: putData },
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_GLOBALS:
      return getGlobalData(state, action);
    case actionTypes.UPDATE_GRAPH_SUCCESS:
      return getGraphData(state, action);
    case actionTypes.UPDATE_ALL_PORTFOLIOS_SUCCESS:
      return getAllPortfoliosData(state, action);
    case actionTypes.UPDATE_PORTFOLIO_SUCCESS:
      return getSinglePortfoliosData(state, action);
    case actionTypes.UPDATE_FIAT:
      return setFiatList(state, action);
    case actionTypes.SET_PF_SCOPE:
      return setPfScope(state, action);
    case actionTypes.FILTER_FIAT:
      return filterFiatList(state, action);
    case actionTypes.UPDATE_GLOBAL_TIME:
      return setGlobalTime(state, action);
    case actionTypes.UPDATE_ALL_BALANCES_SUCCESS:
      return getAllBalancesData(state, action);
    case actionTypes.UPDATE_MENU:
      return updateMenuSelected(state, action);
    case actionTypes.UPDATE_MENU_PATH:
      return updateMenuPath(state, action);
    case actionTypes.UPDATE_PORTFOLIO_GRAPH_SUCCESS:
      return updatePortfolioValues(state, action);
    case actionTypes.UPDATE_PORTFOLIO_TIME:
      return setPortfolioTime(state, action);
    case actionTypes.SET_CURRENT_VIEW:
      return setCurrentView(state, action);
    case actionTypes.UPDATE_PF_LIST:
      return setPortfolioList(state, action);
    case actionTypes.GLOBAL_GRAPH_SCOPE:
      return setGlobaltoBtc(state, action);
    case actionTypes.PF_GRAPH_SCOPE:
      return setPFtoBtc(state, action);
    case actionTypes.UPDATE_PORTFOLIO_HIST_SUCCESS:
      return setPortfolioHist(state, action);
    case actionTypes.GET_TRANSACTION_SUCCESS:
      return updateTransactions(state, action);
    case actionTypes.GET_POS_TRANSACTION_SUCCESS:
      return updatePositionTransactions(state, action);
    case actionTypes.UPDATE_SCOPED_USER:
      return updatescopedUsers(state, action);
    case actionTypes.GET_DEPOSITS_SUCCESS:
      return updateDeposits(state, action);
    case actionTypes.GET_WALLET_BALANCE_SUCCESS:
      return updateWalletBalance(state, action);
    case actionTypes.GET_SUBS:
      return setSubs(state, action);
    case actionTypes.ROI_EVOL_SUCCESS:
      return getSingleRoiEvol(state, action);
    case actionTypes.BALANCE_RISK_SUCCESS:
      return setBalanceRisk(state, action);
    case actionTypes.USER_RISK_SUCCESS:
      return setUserRisk(state, action);
    case actionTypes.UPDATE_SINGLE_BALANCES_SUCCESS:
      return updateSingleBalances(state, action);
    case actionTypes.UPDATE_TOTAL_VALUE_SUCCESS:
      return updateGlobalTotal(state, action);
    case actionTypes.UPDATE_TOTAL_PORTFOLIO_VALUE_SUCCESS:
      return updatePortfolioTotal(state, action);
    case actionTypes.OVERWRITE_PORTS:
      return overWritePorts(state, action);
    case actionTypes.PORT_HOLDINGS_SUCCESS:
      return getPortfolioHoldings(state, action);
    case actionTypes.ACC_TR_IMPORT_SUCCESS:
      return updateImportTr(state, action);
    case actionTypes.FLATTEN_FILTERS_IMPORT:
      return flattenImportFilters(state, action);
    case actionTypes.ACC_TR_IMPORT_REQUEST:
      return resetTxImport(state, action);
    case actionTypes.SAVE_TV: 
      return updateTvData(state, action)
    case actionTypes.ACCOUNT_CONNECTED_BALANCES: 
      return updateConnectedBalances(state, action)
    case actionTypes.ACCOUNT_TRANSFER_BALANCES: 
      return updateTransferBalances(state, action)
    case actionTypes.ACCOUNT_POSITION_BALANCES: 
      return updateAccountPos(state, action)
    default:
      return state;
  }
};

export default reducer;
