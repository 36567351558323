import React from "react";
import { connect } from "react-redux";
import ReactTable from "react-table";
import "react-table/react-table.css";
import matchSorter from "match-sorter";
import { getValue } from "../../../../../helpers/helpers";

class TransactionOverview extends React.PureComponent {
  state = {
    date: null,
  };
  componentDidMount() {
    //this.props.setCurrentView(5)
    //this.props.componentDataFetch('get', 5, null, null, this.props.portfolioId, null, null, this.props.inBitcoin)
  }

  toggleTxModal = (data) => {
    this.props.toggleModal({
      type: "audit_modal",
      open: true,
      payload: { data: data },
    });
  };

  confirmDelete = (id) => {
    if (
      window.confirm(
        "Are you sure you wantto delete this upload and the transactions linked to it?"
      )
    ) {
      this.props.deleteUpolad(id);
    }
  };

  renderContent = (new_data, old_data, data) => {
    let value = null;
    if (data.action === "delete") {
      value = old_data;
    } else {
      value = new_data;
    }

    if (
      data !== null &&
      data !== undefined &&
      value !== null &&
      value !== undefined
    ) {
      switch (data.scope) {
        case "portfolio": {
          return value.name;
        }
        case "transaction": {
          let base = "";
          try {
            base = value.base_currency.symbol;
          } catch (e) {}

          let amount = "";
          try {
            amount = value.amount;
          } catch (e) {}

          let quote = "";
          try {
            quote = value.quote_currency.symbol;
          } catch (e) {}

          let type = "";
          try {
            type = value.transaction_type;
          } catch (e) {}

          const caller = `${type} ${amount} ${base} ${
            quote !== "" ? `/ ${quote}` : ""
          }`;
          return caller;
        }
        case "upload": {
          return data.portfolio.name;
        }
        case "exchange": {
          if (
            value.custom_exchange_check === true &&
            value.custom_exchange !== null
          ) {
            return `${value.name} - ${value.custom_exchange}`;
          } else if (
            value.custom_exchange_check === false &&
            value.exchange !== null
          ) {
            return `${value.name} - ${value.exchange.name}`;
          } else {
            return `${value.name}`;
          }
        }
        case "wallet": {
          let coin_symbol = undefined;
          try {
            coin_symbol = value.coin.symbol;
          } catch (e) {}

          if (value.wallet_type === "storage") {
            if (coin_symbol !== undefined) {
              return `${value.name} - ${coin_symbol}`;
            } else {
              return `${value.name}`;
            }
          } else {
            if (
              coin_symbol !== undefined &&
              value.exchange.name !== undefined
            ) {
              return `${value.exchange.name} - ${coin_symbol}`;
            } else {
              return `${value.name}`;
            }
          }
        }
        default:
          return null;
      }
    }
  };

  render() {
    const columns = [
      {
        id: "created_at",
        accessor: "created_at",
        Header: () => (
          <div className="basier-p4-caps" style={{ paddingLeft: 12 }}>
            Date
          </div>
        ),
        maxWidth: 120,
        minWidth: 110,
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["created_at"] }),
        Filter: ({ filter, onChange }) => (
          <input
            placeholder="DDDD-MM-YY"
            className="basier-p4"
            onChange={(event) => onChange(event.target.value)}
            style={{ width: "100%" }}
            value={filter ? filter.value : null}
          />
        ),
        filterAll: true,
        Cell: (row) => {
          const language = navigator.language || "en";
          const options = { year: "numeric", month: "numeric", day: "numeric" };
          const parseDate = new Date(row.value);
          const showDate = parseDate.toLocaleDateString(language, options);

          return (
            <div>
              <div className="basier-p4" style={{ paddingLeft: 12 }}>
                {showDate}
              </div>
            </div>
          );
        },
      },
      {
        accessor: "id",
        Header: () => (
          <div className="basier-p4-caps" style={{ paddingLeft: 12 }}>
            Uploading User
          </div>
        ),
        minWidth: 210,
        Cell: (row) => {
          return (
            <div>
              <div className="basier-p4-caps" style={{ paddingLeft: 12 }}>
                {row.original.first_name !== null
                  ? `${row.original.first_name} ${row.original.last_name}`
                  : "Deleted User"}
              </div>
            </div>
          );
        },
      },
      {
        accessor: "id",
        Header: () => (
          <div className="basier-p4-caps" style={{ textAlign: "center" }}>
            Action
          </div>
        ),
        maxWidth: 90,
        Cell: (row) => {
          return row.original.scope !== "upload"
            ? getValue (this.props.roles, [this.props.portfolioId]) ===
                "admin" && (
                <div onClick={() => this.confirmDelete(row.value)}>
                  <div
                    className="basier-p4-caps opacity-hover"
                    style={{
                      textAlign: "center",
                      cursor: "pointer",
                      color: "#0078DB",
                      fontWeight: 900,
                    }}
                  >
                    Delete
                  </div>
                </div>
              )
            : null;
        },
      },
    ];

    return (
      <div style={{ width: "100%", marginLeft: "auto", marginRight: "auto" }}>
        <ReactTable
          defaultPageSize={5}
          filterable
          resizable={false}
          defaultFilterMethod={(filter, row) =>
            String(row[filter.id]) === filter.value
          }
          showPageSizeOptions={false}
          data={this.props.data}
          columns={columns}
          getProps={(state, rowInfo, column) => {
            return {
              style: {
                border: "0px",
              },
            };
          }}
          getTrGroupProps={(state, rowInfo, column) => {
            return {
              style: {
                border: "0px",
              },
            };
          }}
          getTheadFilterProps={(state, rowInfo, column) => {
            return {
              style: {
                marginTop: 6,
                border: "none",
              },
            };
          }}
          getPaginationProps={(state, rowInfo, column) => {
            return {
              style: {
                boxShadow: "none",
                border: "none",
              },
            };
          }}
          getTheadProps={(state, rowInfo, column) => {
            return {
              style: {
                boxShadow: "none",
              },
            };
          }}
          getTrProps={(state, rowInfo, column) => {
            return {
              style: {
                display: "flex",
                alignItems: "center",
                marginBottom: 4,
                marginTop: 4,
                boxShadow:
                  rowInfo === undefined
                    ? "none"
                    : "0px 10px 10px rgba(225, 229, 233, 0.3)",
                borderRadius: rowInfo === undefined ? 0 : 35,
                height: 56,
                border: "0px",
                background:
                  rowInfo === undefined
                    ? "white"
                    : rowInfo.row.action === "create"
                    ? "linear-gradient(180deg, #FFFFFF 75%, rgba(255, 255, 255, 0) 175.3%), #4CDAC1"
                    : rowInfo.row.action === "edit"
                    ? "linear-gradient(180deg, #FFFFFF 75%, rgba(255, 255, 255, 0) 175.3%), #ffc163"
                    : rowInfo.row.action === "delete"
                    ? "linear-gradient(180deg, #FFFFFF 75%, rgba(255, 255, 255, 0) 175.3%), #F9716B"
                    : "white",
              },
            };
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    //transactions: state.data.transactions,
    inBitcoin: state.data.bitcoinPf,
    roles: state.auth.roles,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TransactionOverview);
