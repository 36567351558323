import React from 'react';
import { connect } from 'react-redux';
import BaseModalTop from '../../Portfolio/PortfolioComponents/Transactions/BaseModalTop';
import * as actions from "../../../store/actions/interactions";
import {loadData} from '../../../helpers/helpers';
import { backendUrl } from '../../../variables';

const typeToNum = (val) => {
    
    switch(true) {
        case (val.initial === true): {
            return 0
        }
        case (val.close === true): {
            return 2
        }
        case (val.reduce === true): {
            return 1
        }
        case (val.increase === true): {
            return 1
        }
        default: 
            return 3; 
    }

}

const renderType = (val) => {

    switch(true) {
        case (val.initial === true): {
            return ['Open', '#4CDAC1']
        }
        case (val.close === true): {
            return ['Close', '#F9716B']
        }
        case (val.reduce === true): {
            return ['Reduction', '#F9716B']
        }
        case (val.increase === true): {
            return ['Increase', '#4CDAC1']
        }
        default: 
            return null; 
    }

}

class PositionEntries extends React.PureComponent {


    state = {
        data: undefined, 
        main: undefined
    }

    handleClose = () => {

        this.props.toggleModal({type: null, open: false, payload: null})
       
      }
    
    getData = () => {

        let headers = { "content-type": "application/json" };
        headers["Authorization"] = `Token ${this.props.token}`;

        const data_fetch = loadData(`${backendUrl}/v1/connectors/positions/${this.props.data.id}/`, headers)
        data_fetch
            .then(json => {

                const initial = json['data'].filter(v => v.initial === true)
                const others = json['data'].sort((a, b) => {
                    var dateA = new Date(a.date);
                    var dateB = new Date(b.date);
                    return dateA - dateB;
                }).sort((a, b) => {
                    var typeSwitchA = typeToNum(a)
                    var typeSwitchB = typeToNum(b)
                    return typeSwitchA - typeSwitchB;
                })

                this.setState({data: others, main: initial[0]})

            })
        
    }
    componentDidMount() {
        
        this.getData()
        
    }
    
    render () {

        return (

            <div>

                <BaseModalTop forceSingle={true} title="Position entries" handleClose={this.handleClose} onClick={this.backToWelcome} welcome={this.state.welcome} entryType={this.state.type_selected} />

                {this.state.data !== undefined &&  
                
                    <div style={{padding: 24}}>
                        
                        <div style={{
                                width: '100%', 
                                height: 70, 
                                backgroundColor: '#F8F9FA', 
                                display: 'flex', 
                                justifyContent: 'space-between', 
                                alignItems: 'center',
                                padding: '12px 24px',
                            }}>
                            
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <img src={this.state.main.base_img} alt="" style={{height: 40, width: 40}} />
                                <div style={{marginLeft: 12}}>
                                    <div style={{textTransform: 'uppercase'}} className="basier-p2">{this.state.main.text_name}</div>
                                    <div style={{textTransform: 'uppercase'}} className="basier-p4-caps">{this.state.main.exchange_name}</div>
                                </div>
                            </div>

                            <h3 style={{textAlign: 'right', textTransform: 'uppercase', marginBottom: 0, fontWeight: 900}}>
                                {this.state.main.transaction_description}
                            </h3>

                        </div>

                        <div>
                            <h4 style={{marginTop: 24}}>Related Entries</h4>
                        </div>

                        <div className="basier-p4-caps" style={{display: 'flex', alignItems: 'center', width: '100%', paddingLeft: 12, paddingRight: 12, marginTop: 24}} >
                            <div style={{width: '15%'}}>Type</div>
                            <div style={{width: '25%'}}>Date</div>
                            <div style={{width: '30%'}}>Amount</div>
                            <div style={{width: '30%'}}>Price</div>
                        </div> 

                        <div style={{paddingLeft: 12, paddingRight: 12, marginTop: 24, height: 300, overflowY: 'scroll'}}>

                            {
                            this.state.data.map((val, key) => {

                                const language = navigator.language || 'en'
                                const options = {year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit' };
                                const parseDate = new Date(val.date);
                                const showDate = parseDate.toLocaleDateString(language,options)

                                return (

                                    <div key={key}>

                                        <div style={{display: 'flex', alignItems: 'center', width: '100%', height: 40, borderBottom: key !== val.length -1 ? '1px solid #CCC' : null}}>

                                            <div style={{width: '15%'}}>
                                                <div className="basier-p4-caps" style={{color: renderType(val)[1], fontWeight: 900, textTransform: 'uppercase' }}>
                                                    {renderType(val)[0]}
                                                </div>
                                            </div>

                                            <div style={{width: '25%'}}>
                                                <div className="basier-p4-caps">
                                                    {showDate}
                                                </div>
                                            </div>

                                            <div style={{width: '30%'}} className="basier-p4">
                                                {val.amount.toLocaleString('en', {minimumFractionDigits: 8, maximumFractionDigits: 8})} &nbsp;
                                                {val.base_ticker}
                                            </div>

                                            <div style={{width: '30%'}} className="basier-p4">
                                                {
                                                    val.quote_type === 'fiat' || val.quote_currency_id === 'tether' ? 
                                                        val.unit_price.toLocaleString('en', {minimumFractionDigits: 2, maximumFractionDigits: 2})
                                                    :
                                                        val.unit_price.toLocaleString('en', {minimumFractionDigits: 8, maximumFractionDigits: 8})
                                                } &nbsp;
                                                 {val.quote_ticker}
                                            </div>

                                        </div>


                                    </div>

                                )

                            })
                            }

                        </div>
                    
                    </div>
                
                }
                
            </div>
            
        )
    }


}

const mapStateToProps = state => {
    return {
        user_id: state.auth.user_id,
        token: state.auth.token,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        toggleModal: (data) => 
            dispatch(actions.toggleModal(data)), 
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(PositionEntries)
