
export default () => {
	self.addEventListener('message', e => { // eslint-disable-line no-restricted-globals
		if (!e) return;

        let openBalances = []
        let openPositions = []
		let closedPositions = []
        let closedBalances = []
        
		const balances = e.data[0]
        const positions = e.data[1]
        const total_value = e.data[2]

        openPositions = positions.filter(v => v.is_open === true)
        closedPositions = positions.filter(v => v.is_open !== true)
		openBalances = balances.filter(v => v.total_value > 0.5 && v.total_value > 0.00000005*total_value && v.IsCustom === false)
        closedBalances = balances.filter(v => !(v.total_value > 0.5 && v.total_value > 0.00000005*total_value) && v.IsCustom === false)

       
        let cleanOpenPositions = []
        openPositions.map(v => {
            let temp = {
                image: v.base_img, 
                scope: 'position',
                image_large: v.base_img, 
                type: v.position_side,
                quote_ticker: v.quote_ticker,
                coin_symbol: v.contract_name,
                short_name: v.exchange_name,
                total_profit: v.total_pnl, 
                balance: v.amount, 
                purchase_price: v.open_price, 
                active_profit: v.active_profit, 
                realized_profit: v.realized_pnl, 
                current_price: 0,
            }
            cleanOpenPositions.push(temp)
        })

        let cleanClosedPositions = []
        closedPositions.map(v => {
            let temp = {
                image: v.base_img, 
                scope: 'position',
                image_large: v.base_img, 
                type: v.position_side,
                quote_ticker: v.quote_ticker,
                coin_symbol: v.contract_name,
                short_name: v.exchange_name,
                total_profit: v.total_pnl, 
                balance: v.amount, 
                purchase_price: v.open_price, 
                active_profit: v.active_profit, 
                realized_profit: v.realized_pnl, 
                current_price: 0,
            }
            cleanClosedPositions.push(temp)
        })

        let allOpen = openBalances.concat(cleanOpenPositions)
        let allClosed = closedBalances.concat(cleanClosedPositions)
        
        allOpen = allOpen.sort(function compare(a, b) {
            var profit1 = a.total_profit;
            var profit2 = b.total_profit;
            return profit2 - profit1;
        });

        allClosed = allClosed.sort(function compare(a, b) {
            var profit1 = a.total_profit;
            var profit2 = b.total_profit;
            return profit2 - profit1;
        });

		postMessage([allOpen, allClosed]);

	})
}
