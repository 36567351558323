import React from 'react';
import { connect } from 'react-redux';
import trIcon from '../../Media/trIcon.svg'
import okGreen from '../../Media/okGreen.svg'
import cancelRed from '../../Media/cancelRed.svg'
import loader from '../../Media/contentLoader.svg'
import restore from '../../Media/restore.svg';
import PortfolioSelect from '../AppWide/TablePortfolioSelector';
import TransactionTable from '../AppWide/ImportedPositionTable'
import * as actions from "../../store/actions/data";
import * as interActions from "../../store/actions/interactions";
import _pickBy from 'lodash/pickBy';
import _keys from 'lodash/keys';
import { backendUrl } from '../../variables';
import axios from 'axios';

const labelScope = (val) => {
    switch (val) {

        case 'all': 
            return 'All'
        case 'pending': 
            return 'To review'
        case 'deleted': 
            return 'Deleted'
        case 'reconciled': 
            return 'Reconciled'
        case 'unsupported': 
            return 'Unsupported'
        default: 
            return null
    }
}


class PositionReconcile extends React.PureComponent {

    state = {
        selectAll: false, 
        checked: {},
        dataScope: undefined, 
        editAssign: false, 
        assignLoad: false,
        data: {
            portfolio: null
        },
    }

    componentDidMount() {


        if (this.props.transactions !== undefined) {

            let scopeTx = this.props.transactions.filter(v => (v.include === true  && ((v.include === true && v.portfolio === null) || v.reviewed === false)))
            if (this.props.account !== null && this.props.account !== undefined) {
                if (this.props.type == 'exchange') {
                scopeTx = scopeTx.filter(v => v.related_account_id == this.props.account)
                } else {
                scopeTx = scopeTx.filter(v => v.related_wallet_id == this.props.account)                    
                }
            }


            const initialState = scopeTx.length > 0 ? 'pending' : 'all'
            this.setScope(initialState)
        }

    }

    setScope = (scope) => {
        this.setState(state => ({...state, dataScope: scope}))
    }

    componentDidUpdate (prevProps) {

        if (prevProps.transactions === undefined && this.props.transactions !== undefined && this.props.transactions !== prevProps.transactions) {
            let scopeTx = this.props.transactions.filter(v => (v.include === true  && ((v.include === true && v.portfolio === null) || v.reviewed === false)))
            if (this.props.account !== null && this.props.account !== undefined) {
                if (this.props.type == 'exchange') {
                scopeTx = scopeTx.filter(v => v.related_account_id == this.props.account)
                } else {
                scopeTx = scopeTx.filter(v => v.related_wallet_id == this.props.account)                    
                }
            }


            const initialState = scopeTx.length > 0 ? 'pending' : 'all'
            this.setState(state => ({...state, dataScope: initialState}))
        }

    }

    renderTable = (dataScope) => {
        
        let base = this.props.transactions
        
        if (this.props.account !== null && this.props.account !== undefined) {
            if (this.props.type == 'exchange') {
            base = base.filter(v => v.related_account_id == this.props.account)
            } else {
            base = base.filter(v => v.related_wallet_id == this.props.account)                    
            }
        }

        let data = base.filter(v => (v.include === true  && ((v.include === true && v.portfolio === null) || v.reviewed === false)))

        if (dataScope === 'reconciled') {
            data = base.filter(v => (v.related_transaction !== null && v.include === true && v.reviewed === true && (v.include === true && v.portfolio !== null)))
        } else if (dataScope === 'pending') {
            data = base.filter(v => (v.include === true  && ((v.include === true && v.portfolio === null) || v.reviewed === false)))
        } else if (dataScope === 'deleted') {
            data = base.filter(v => (v.include === false && v.base_currency !== null))
        } else if (dataScope === 'unsupported') {
            data = base.filter(v => (v.include === false && v.base_currency === null))
        } else if (dataScope === 'all') {
            data = base
        }
        
        return (
            <TransactionTable editPf={this.state.editPf} data={data} selectAll={this.state.selectAll} checked={this.state.checked} selectSingle={(id,scope) => this.selectSingle(id, scope)} handleSelectAll={(scope) => this.selectAll(scope)} global={true} />
        )
    }

    selectAll = (scope) => {

        let newChecked = {...this.state.checked}
        if (this.state.selectAll === false) {
            scope.map(val => {
                if (this.state.checked[val.id] !== true) {
                    newChecked[val.id] = true
                }
            })
            
            this.setState(state => ({...state, selectAll: true, checked: newChecked }))

        } else {
            this.setState(state => ({...state, selectAll: false, checked: {} }))
        }

       

    }

    selectSingle = (id, scope) => {
       
        let newChecked = {...this.state.checked}
        if (this.state.checked[id] === true) {
 
             delete newChecked[id]
 
        } else {
 
             newChecked[id] = true
 
        }
 
        this.setState(state => ({...state, checked: newChecked}))
 
        if (Object.keys(newChecked).length === scope.length) {
            if (JSON.stringify(Object.keys(newChecked).sort()) == JSON.stringify(scope.map(e => `${e.id}`).sort())) {
             this.setState(state => ({...state, selectAll: true }))
         } else {
             this.setState(state => ({...state, selectAll: false }))
         }
         } else {
             this.setState(state => ({...state, selectAll: false }))
         }
 
     }
     assignAll = () => {
        if (this.state.editAssign === true) {
            this.setState(state => ({...state, editAssign: false, data: {...state.data, scope: null, portfolio: null }}))
        }
        else {
            this.setState(state => ({...state, editAssign: true, data: {...state.data, scope: 'create' }}))
        }

    }

    updatePortfolioBulk = (val) => {
        this.setState(state => ({...state, data: {...state.data, ...val}}))
    }
    
     actionAll = (type) => {

        const scoped = _keys(_pickBy(this.state.checked))

        let data = {
            ...this.state.data, 
            ids: scoped, 
            user_id: this.props.user_id, 
            type_tx: 'position'
        }

        if (type !== 'assign') {
            data['scope'] = type
        } else {
            this.setState(state => ({...state, assignLoad: true}))
        }
        


        let headers = { "content-type": "application/json" };
        if (this.props.token !== null && this.props.token !== undefined) {

            headers['Authorization'] = `Token ${this.props.token}`

        }

        let url_call = `${backendUrl}/v1/account/bulk/`

        axios.post(
            url_call, 
            data, 
            {headers: headers}
        )
        .then(
            json => {
                this.props.getImports(this.props.user_id)
                this.setState(state => ({...state, selectAll: false, checked: {}, editAssign: false, assignLoad: false }))
                let message = 'Change successful'
                try {
                    const response = json
                    message = response['data']['data']
                } catch(e) {}
                this.props.showSnack({message: message, variant: "success"})

            }
        )
        .catch(
            err => {
                let message = 'An error occured. Please try again.'
                try {
                    const response = err.response
                    message = response['data']['data']
                } catch(e) {}
                this.props.showSnack({message: message, variant: "error"})
            }
        )
        
        


    }

    render () {

        return (
            <div>
                
                <div style={{marginBottom: 0, width: '100%', display: 'flex', alignItems: 'flex-start'}}>
                    <div style={{display: 'flex', alignItems: 'baseline', marginTop: 12, paddingLeft: 12}}>
                    {
                        this.props.transactions !== undefined && this.props.transactions.length > 0 ? 
                            ['all', 'pending', 'reconciled', 'deleted', 'unsupported'].map((val, key) => {
                            return (
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <div onClick={() => this.setScope(val)} key={key} className="blue-hover basier-p4-caps" style={{
                                        color: this.state.dataScope === val ? '#0078DB' : '#6A7E93',
                                        fontWeight: this.state.dataScope === val ? 900 : 400,
                                        cursor: 'pointer'
                                    }}>
                                        {
                                            labelScope(val)
                                        }
                                    </div>
                                    {key === 4 ? null : <div style={{marginLeft: 12, marginRight: 12}}>|</div>}
                                </div>
                                

                            )
                        })
                            
                    :
                    null }
                    </div>
                </div>
                
                {this.props.transactions !== undefined && 
                this.props.transactions.length > 0  ?
                <div>
                    
                    
                        {this.props.transactions !== undefined && this.props.transactions.length > 0 && 
                            <div className="basier-p4" style={{paddingLeft: 12, paddingRight: 12, marginBottom: 12, height: 50, display: 'flex', alignItems: 'center', justifyContent: 'flex-start', width: '100%'}}>
                                
                                <div style={{marginRight: 12, display: 'flex'}}>
                                    { (Object.keys(this.state.checked).length > 0) && ( this.state.dataScope !== 'deleted' && (this.props.transactions.filter(v => (v.include === false)).length !== this.props.transactions.length ) ) ? 

                                        `${Object.keys(this.state.checked).length} transactions selected`

                                    : 
                                        this.state.dataScope !== 'deleted' && (this.props.transactions.filter(v => (v.include === false)).length !== this.props.transactions.length ) ?
                                    '0 transactions selected' : null }
                                </div>

                                { (this.state.dataScope === 'deleted' || (this.state.dataScope === 'all' && this.props.transactions.filter(v => (v.include === false)).length === this.props.transactions.length ) )&& 
                                <div style={{display: 'flex', width: '100%', justifyContent: 'flex-end'}}>
                                    <div onClick={() => this.actionAll('restore')} className="opacity-hover" style={{marginLeft: 12, marginRight: 35, color: '#4CDAC1', cursor: 'pointer', display: 'flex', alignItems: 'center'}}>
                                        
                                        <div style={{marginRight: 12}}>Restore All</div>
                                        <img src={restore} alt="" className="opacity-hover" style={{height: 20, cursor: 'pointer'}} />

                                    </div>
                                </div>
                                } 
                                
                                        
                                { (Object.keys(this.state.checked).length > 0) && this.state.dataScope !== 'deleted' && ( this.state.dataScope !== 'deleted' && (this.props.transactions.filter(v => (v.include === false)).length !== this.props.transactions.length ) ) ?

                                    this.state.editAssign ? 

                                    <React.Fragment>
                                        <div className="opacity-hover" style={{marginLeft: 0, marginRight: 24, color: 'black'}}>
                                                Assign all to:
                                        </div>
                                        <div style={{width: 180}}>
                                        <PortfolioSelect updateServer={(data) => this.updatePortfolioBulk(data)} value={null} data={this.props.portfolios} forceSelect={true} />
                                        </div>
                                        
                                        {this.state.assignLoad ? 
                                            <div style={{marginLeft: 6, marginRight: 6}}>
                                                <img src={loader} style={{height: 24, width: 24}} alt="ok" />
                                            </div>
                                        :
                                            <React.Fragment>
                                                <div className="opacity-hover" style={{marginLeft: 6, marginRight: 6, cursor: 'pointer'}} onClick={() => this.actionAll('assign')}>
                                                    <img src={okGreen} style={{height: 24, width: 24}} alt="ok" />
                                                </div>
                                                <div className="opacity-hover" style={{marginRight: 12, cursor: 'pointer'}} onClick={() => this.assignAll()}>
                                                    <img src={cancelRed} style={{height: 24, width: 24}} alt="no" />
                                                </div>
                                            </React.Fragment>
                                        
                                        }
                                    </React.Fragment>

                                    :

                                    <React.Fragment>

                                        <div onClick={() => this.actionAll('validate')} className="opacity-hover" style={{marginLeft: 12, marginRight: 12, color: '#0078DB', cursor: 'pointer'}}>
                                            Validate all
                                        </div>

                                        <div>
                                            |
                                        </div>

                                        <div onClick={() => this.actionAll('delete')} className="opacity-hover" style={{marginLeft: 12, marginRight: 12, color: '#0078DB', cursor: 'pointer'}}>
                                            Delete All
                                        </div>

                                        <div>
                                            |
                                        </div>

                                        <div onClick={() => this.assignAll()} className="opacity-hover" style={{marginLeft: 12, marginRight: 12, color: '#0078DB', cursor: 'pointer'}}>
                                            Assign all to portfolio
                                        </div>

                                        {/* <div>
                                            |
                                        </div>

                                        <div onClick={() => this.actionAll('clear')} className="opacity-hover" style={{marginLeft: 12, marginRight: 12, color: '#0078DB', cursor: 'pointer'}}>
                                            Clear assigned portfolio
                                        </div> */}
                                    </React.Fragment>

                                    : 

                                    null
                                }

                            
                            
                            </div>}

                        {
                            this.props.transactions !== undefined && 
                            <div>
                                {this.renderTable(this.state.dataScope)}
                            </div>
                        }
                    </div>
                

                : 

                <div style={{height: 382, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 12}}>
                    <div style={{border: '1px solid #CCCCCC', borderRadius: 20, height: '100%', width: '100%', backgroundColor: 'rgba(255, 255, 255, 0.5)', display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', padding: 48, flexDirection: 'column', boxShadow: '0px 10px 10px rgba(225, 229, 233, 0.3)',}}>
                        <div>
                            <img src={trIcon} alt="" style={{height: 80}} />
                        </div>
                        <div className="basier-p2" style={{marginTop: 24}}>
                            <div>You do not have any imported positions yet. Once you connect accounts which can import long or short positions on derivatives, you will see them here.</div>
                        </div>
                    </div>
                </div>

                }

            </div>
        )
    }


}

const mapStateToProps = state => {
    return {
        portfolios: state.auth.list_portfolios,
        user_id: state.auth.user_id, 
        token: state.auth.token,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setCurrentView: (view) => 
            dispatch(actions.setCurrentView(view)),
        getImports: (data) => dispatch(actions.getImports(data)), 
        showSnack: (data) => dispatch(interActions.showSnack(data)), 
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(PositionReconcile)
