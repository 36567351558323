import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import BaseModalTop from '../../Portfolio/PortfolioComponents/Transactions/BaseModalTop';
import AddPosition from './AddPosition'
import * as actions from "../../../store/actions/interactions";



class ResponsiveDialog extends React.Component {


    handleClose = () => {

        this.props.toggleModal({type: null, open: false, payload: null})
       
    }
    
    render() {

    return (

      <div>
        
          <BaseModalTop forceSingle={true} title="Edit Position" handleClose={this.handleClose} onClick={null} welcome={null} entryType={null} />


          <AddPosition existingData={this.props.existingData} />

        </div>

    )
  }
}

ResponsiveDialog.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
};


const mapStateToProps = state => {
    return {

    };
};

const mapDispatchToProps = dispatch => {
    return {
        toggleModal: (data) => 
            dispatch(actions.toggleModal(data)), 
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ResponsiveDialog);
