import React from 'react';
import { connect } from 'react-redux';
import currencyDict from '../../../../helpers/currency.json';
import {siwtchTransactionType, loadData, dateParser, formattedNumber} from '../../../../helpers/helpers'
import Tooltip from '../../../AppWide/Tooltip';
import questionMark from '../../../../Media/questionMark.svg'
import nftIcon from '../../../../Media/nftIcon.svg'
import loader from '../../../../Media/contentLoader.svg'
import eyeIcon from '../../../../Media/eyeIcon.svg'
import MenuPopper from '../../../AppWide/MenuPopper';
import * as actions from "../../../../store/actions/interactions";
import * as appwideActions from "../../../../store/actions/appwide";
import { backendUrl } from '../../../../variables'
import axios from 'axios';

const allTxTypes = ['deposit', 'mining', 'fork', 'airdrop', 'dividend', 'income', 'gift', 'withdraw', 'purchase', 'donation', 'lost', 'fee'];
const transferTxs = ['deposit', 'withdraw', 'transfer']

const getOptions = (type) => {
    if (['deposit', 'mining', 'fork', 'airdrop', 'dividend', 'income', 'gift'].includes(type)) {
        return ['deposit', 'mining', 'fork', 'airdrop', 'dividend', 'income', 'gift'].sort()
    } else if (['withdraw', 'purchase', 'donation', 'lost', 'fee'].includes(type)) {
        return ['withdraw', 'purchase', 'donation', 'lost', 'fee'].sort()
    } else {
        return ['deposit', 'mining', 'fork', 'airdrop', 'dividend', 'income', 'gift', 'withdraw', 'purchase', 'donation', 'lost', 'fee']
    }
}

class TransactionTableRow extends React.PureComponent {
    
    state = {
        loading: false,
    }

    updateId = () => {

    }

    convertLine = (type) => {
    
        //console.log(this.state)
        this.setState(state => ({...state, loading: true}))
        let headers = { "content-type": "application/json" };
        if (this.props.token !== null && this.props.token !== undefined) {
    
            headers['Authorization'] = `Token ${this.props.token}`
    
        }
    
        const url_call = `${backendUrl}/v1/transaction_detail/${this.props.data.id}`
    
        const sendData = {
          transaction_description: type,
          user_id: this.props.user_id
        }
        axios.patch(url_call, sendData, {headers: headers})
            .then(json => {
                
                this.setState(state => ({...state, loading: false, success: true}))
                this.props.getOverallData()
                this.props.getWallets(this.props.user_id)
                this.props.getUserLimits(this.props.user_id)

            })
            .catch( e => {
    
              this.setState(state => ({...state, loading: false, error: true}))
    
            })
      }

      markAsTransfer = () => {
    
        //console.log(this.state)
        this.setState(state => ({...state, loading: true}))
        let headers = { "content-type": "application/json" };
        if (this.props.token !== null && this.props.token !== undefined) {
    
            headers['Authorization'] = `Token ${this.props.token}`
    
        }
    
        const url_call = `${backendUrl}/v1/transaction/${this.props.data.id}/mark_transfer/`
    
        const sendData = {
          user_id: this.props.user_id
        }
        axios.post(url_call, sendData, {headers: headers})
            .then(json => {
                
                this.setState(state => ({...state, loading: false, success: true}))
                this.props.getOverallData()
            })
            .catch( e => {
              this.setState(state => ({...state, loading: false, error: true}))
            })
      }


    getData = (transaction_id) => {
        let headers = { "content-type": "application/json" };
        if (this.props.token !== null && this.props.token !== undefined) {

            headers['Authorization'] = `Token ${this.props.token}`

        }

        const url_call = `${backendUrl}/v1/transaction_detail/${transaction_id}`

        const transactionDetail = loadData(url_call, headers)
        transactionDetail
            .then(json => {

                this.props.getFlatWallets(this.props.user_id)
                //this.props.getAllPortfolioList(this.props.user_id)

                this.setState(state => ({...state, transctionData: json['data'], connectedEdit: {...this.state.connectedEdit, unit_price: json['data']['unit_price'], transaction_description: json['data']['transaction_description']}}))

            })

    }

    viewEdit = (payload) => {

        this.props.toggleModal(
            {
                type: 'edit_transaction', 
                payload: {
                    data: null, 
                    transactionId: this.props.data.id, 
                    getData: this.getData, 
                    setDetailTr: this.updateId,
                    getOverallData: this.props.getOverallData
                }, 
                open: true
            }
        )
    }

    findTransferMatch = (data) => {
        this.props.toggleModal(
            {
                type: 'match_transfer', 
                payload: {
                    data: data, 
                    transactionId: this.props.data.id, 
                    getData: this.getData, 
                    setDetailTr: this.updateId,
                    getOverallData: this.props.getOverallData
                }, 
                open: true
            }
        )
    }
    

    delete = () => {

        this.props.toggleModal(
            {
                type: 'delete_transaction', 
                payload: {
                    transactionId: this.props.data.id, 
                    getOverallData: this.props.getOverallData
                }, 
                open: true
            }
        )


    }

    unmatch = () => {

        const url_call = `${backendUrl}/v1/transaction/${this.props.data.id}/match/?u=${this.props.user_id}`

        let headers = { "content-type": "application/json" };
        if (this.props.token !== null && this.props.token !== undefined) {

            headers['Authorization'] = `Token ${this.props.token}`

        }

        axios({method: 'delete', url: url_call, headers: headers})
        .then(json => {
        this.props.getOverallData()
        })

    }

    render () {

        const {data} = this.props
        const language = navigator.language || 'en'
        const optionsDate = {year: 'numeric', month: 'numeric', day: 'numeric' };
        const optionsTime = {hour: '2-digit', minute: '2-digit'};
        const parseDate = new Date(data.date);
        const showDate = parseDate.toLocaleDateString(language,optionsDate)
        const showTime = parseDate.toLocaleTimeString(language, optionsTime)

        let menuItems = []
        try {
            switch(true) {
                case ['liquidity_in', 'liquidity_out', 'pnl_profit', 'pnl_loss'].includes(data.transaction_description):
                    menuItems = [['Delete', this.delete]]
                    break;
                case data.transfer_match !== null: {
                    menuItems = [['Unmatch', () => this.unmatch()], ['Delete', this.delete]]
                break;
                }
                case data.transfer_match === null && data.is_transfer === false && transferTxs.includes(data.transaction_description): {
                    menuItems = [['Mark As Transfer', () => this.markAsTransfer()], ['Match Transfer', () => this.findTransferMatch(data)], ['Convert to', (t) => this.convertLine(t), getOptions(data.transaction_description)], ['Edit', () => this.viewEdit(data)], ['Delete', this.delete]]; 
                    break; 
                }
                case data.transfer_match === null && data.is_transfer === false && allTxTypes.includes(data.transaction_description): {
                    menuItems = [['Convert to', (t) => this.convertLine(t), getOptions(data.transaction_description)], ['Edit', () => this.viewEdit(data)], ['Delete', this.delete]]; 
                    break; 
                }
                case data.transfer_match === null && data.is_transfer === true && allTxTypes.includes(data.transaction_description): {
                    menuItems = [['UnMark Transfer', () => this.markAsTransfer()], ['Edit', () => this.viewEdit(data)], ['Delete', this.delete]]; 
                    break;
                }
                default: {
                    menuItems = [['Edit', () => this.viewEdit(data)], ['Delete', this.delete]]; 
                    break;
                }
            }
        } catch(e) {

        }

        return (
            
            
            <div className="basier" style={{display: 'flex', width: '100%', alignItems: 'center', height: 80, borderBottom: '1px solid #CCC'}}>
                
                
                <div style={{minWidth: 50, maxWidth: 50, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>

                        <Tooltip enterDelay={1000} interactive disableFocusListener={data.transfer_match === null} disableHoverListener={data.transfer_match === null} disableTouchListener={data.transfer_match === null} title="You cannot select or perform bulk actions on matched transfers. Please unmatch them first." aria-label="read">
                        <span style={{height: 24, padding: 4}}> 
                        <input
                            type="checkbox"
                            className={data.transfer_match !== null ? 'disabled-checkbox' : "regular-checkbox"}
                            style={{backgroundColor: 'red'}}
                            onChange={() => this.props.selectSingle(data.id || data.transfer_match)}
                            checked={this.props.selected[data.id]}
                            disabled={data.transfer_match !== null}
                            />
                        </span>
                        </Tooltip>

                    </div>
                    

                <div style={{minWidth: 150, maxWidth: 200, flex: 1}}>

                    {this.state.loading && <div style={{height: 70, minWidth: 150, maxWidth: 200, flex: 1, backgroundColor: 'rgba(255,255,255,1)', position: 'absolute', display: 'flex', alignItems: 'center', justifyContent: 'center'}}><img src={loader} style={{height: 50}} alt="loading" /></div>}
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                            
                            <div style={{display: 'flex', flexDirection: 'column', width: 150}}>
                                {
                                data.transfer_match !== null && data.transfer_match_deleted !== true && data.is_transfer === false && 
                                <div style={{display: 'flex', alignSelf: 'flex-start', marginLeft: 24, alignItems: 'center', justifyContent: 'center'}}>
                                <div style={{fontWeight: 900, color: '#6A7E93'}} className="basier-p4-caps">Internal</div>
                                    <Tooltip title="Internal deposit / withdrawals happened between your accounts and will not be part of Profit & Loss calculations." placement="right" arrow>
                                    <img style={{height: 12, width: 12, marginLeft: 2,}} src={questionMark} alt=""/>
                                    </Tooltip>
                                </div>
                                
                                }
                                <div style={{textTransform: 'uppercase', fontWeight: 900, marginRight: 12, maxWidth: 200}} className={`basier-p4 transaction-bubble transaction-color-${data.is_transfer === true || data.transfer_match !== null ? 'transfer' : data.transaction_description}`}>
                                    {data.is_nft ? (["buy", "deposit"].includes(data.transaction_description) ? "NFT IN" : "NFT OUT") : siwtchTransactionType(data.is_transfer === true ? 'transfer' : data.transaction_description)[0]}
                                </div>  
                            </div>
        
                        {this.props.account === true ? 

                        <div className="basier-p4" style={{paddingLeft: 12, marginTop: 6}}>
                            on {data.portfolio_name}
                        </div>

                        :

                        <div className="basier-p4" style={{paddingLeft: 12, marginTop: 6}}>
                            by {data.user_first} {data.user_last}
                        </div>

                        }
                    </div>

                </div>

                <div style={{minWidth: 150, flex: 1, paddingLeft: 6, paddingRight: 6, }}>

                    <div style={{display: 'flex'}}>
                        <div>

                            {
                                data.is_nft && ["buy", "sell"].includes(data.transaction_description) ? 
                                <img style={{height: 40, width: 40, marginRight: 12}} src={nftIcon} alt=""/>
                                :
                                data.base_img !== "" && data.base_img !== null  ? 
                                <span><img style={{height: 40, width: 40, marginRight: 12}} src={data.base_img} alt=""/></span>
                                :
                                data.transaction_description === 'liquidity_in' || data.transaction_description === "liquidity_out" ? 
                                <span style={{height: 40, width: 40, border: '2px solid black', color: 'black', borderRadius: '100%', marginRight: 12, display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 16 }}>
                                    LP
                                </span>
                                :
                                <span style={{height: 40, width: 40, border: '2px solid black', color: 'black', borderRadius: '100%', marginRight: 12, display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: `${ currencyDict[data.base_ticker] === undefined ? '16px' : currencyDict[data.base_ticker]['symbol'].length > 1 ? '16px' : '22px'}` }}>
                                    {currencyDict[data.base_ticker] === undefined ? data.base_ticker : currencyDict[data.base_ticker]['symbol'].length > 1 ? currencyDict[data.base_ticker]['symbol'] : currencyDict[data.base_ticker]['symbol'] }
                                </span>


                            }


                        </div>
                        <div className="truncate">
                            <div className='basier-p3' style={{fontWeight: 400, color: 'black'}}>{data.custom_base && !data.base_id ? data.custom_base : data.base_ticker}</div>
                            <div className="basier-p4-caps truncate">{data.custom_base && !data.base_id  ? data.address_hash : data.base}</div>
                        </div>
                    </div>
                    
                </div>

                <div style={{minWidth: 150, flex: 1, paddingLeft: 6, paddingRight: 6, }}>
                    {
                    data.transaction_description === 'liquidity_in' || data.transaction_description === "liquidity_out" ? 
                    
                    <div>
                        {Object.values(data.multiple_quotes).map((v, k) => {
                            return (
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <div className="basier-p3" style={{color: 'black', fontWeight: 'bold'}}>{v.symbol}</div>
                                    <div style={{marginLeft: 4, marginRight: 4}} className="basier-p3">|</div>
                                    <div className="basier-p3" style={{color: '#6A7E93'}}>{formattedNumber(v.amount)}</div>
                                </div>
                            )
                        })}
                    </div>
                    :
                    
                    data.quote !== null && !['deposit', 'withdraw', 'transfer'].includes(data.transaction_type) && 
                    <div style={{display: 'flex', width: 'inherit'}}>    
                        <div style={{}}>

                            {
                                data.quote_img !== "" && data.quote_img !== null  ? 
                                <span><img style={{height: 40, width: 40, marginRight: 12}} src={data.quote_img} alt=""/></span>
                                :
                                <span style={{height: 40, width: 40, border: '2px solid black', color: 'black', borderRadius: '100%', marginRight: 12, display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: `${ currencyDict[data.quote_ticker] === undefined ? '16px' : currencyDict[data.quote_ticker]['symbol'].length > 1 ? '16px' : '22px'}` }}>
                                    {currencyDict[data.quote_ticker] === undefined ? data.quote_ticker : currencyDict[data.quote_ticker]['symbol'].length > 1 ? currencyDict[data.quote_ticker]['symbol'] : currencyDict[data.quote_ticker]['symbol'] }
                                </span>


                            }


                        </div>
                        <div style={{flex: 1}} className="truncate">
                            <div className='basier-p3' style={{fontWeight: 400, color: 'black'}}>{data.quote_ticker}</div>
                            <div className="basier-p4-caps truncate">{data.quote}</div>
                        </div>
                    </div>
                    }

                </div>

                <div style={{minWidth: 120, maxWidth: 200, flex: 1, textAlign: 'left', paddingLeft: 6, paddingRight: 6, }}>
                    <div className="basier-p3" style={{color: 'black'}}>{
                        data.amount === undefined ? null :
                        data.base_type == 'fiat' ? 
                        data.amount.toLocaleString('en', {minimumFractionDigits: 2, maximumFractionDigits: 2})
                        :
                        data.amount.toLocaleString('en', {minimumFractionDigits: 8, maximumFractionDigits: 8})}</div>

                    {
                    data.transaction_description === 'liquidity_in' || data.transaction_description === "liquidity_out" ? 
                    null :
                    data.quote !== null && 
                    <div className="basier-p4">@ {
                        data.unit_price === null || data.unit_price === undefined ? 
                        null :
                        data.quote_type == 'fiat' ? 
                        data.unit_price.toLocaleString('en', {minimumFractionDigits: 2, maximumFractionDigits: 2})
                        :
                        data.unit_price.toLocaleString('en', {minimumFractionDigits: 8, maximumFractionDigits: 8})
                        
                    }</div>
                    }


                </div>

                <div style={{minWidth: 120, maxWidth: 200, flex: 1, paddingLeft: 4, paddingRight: 4,}}>
                    <div className="basier-p3" style={{color: 'black'}}>{showDate}</div>
                    <div className="basier-p4">{showTime}</div>
                </div>
                
                {this.props.account === true ? null : 
                <div className="basier-p4-caps" style={{minWidth: 120, maxWidth: 120, flex: 1, display: 'flex', alignItems: 'center'}}>
                    <img onClick={() => this.props.selectTransaction(data)} className="opacity-hover" src={eyeIcon} style={{height: 20, width: 20, cursor: 'pointer', marginRight: 24}} alt="" />
                    <MenuPopper menuItems={menuItems} currentDescription={data.transaction_description} />
                </div>}


                
            </div>


        )
    }


}

const mapStateToProps = state => {
    return {
        token: state.auth.token, 
        user_id: state.auth.user_id,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        toggleModal: (data) => 
            dispatch(actions.toggleModal(data)), 
        getWallets: (user) => dispatch(appwideActions.getWallets(user)), 
        getUserLimits: (user) => dispatch(appwideActions.getUserLimits(user)), 
        getFlatWallets: (user) => dispatch(appwideActions.getFlatWalletList(user)), 
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(TransactionTableRow)
