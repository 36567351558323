import React from "react";
import { FixedSizeList as List } from "react-window";
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';


class AnalysisTable extends React.PureComponent {


    state = {

        activeSuggestion: null,
    
      }
    

    Row = ({ data, index, style }) => {
        // Data passed to List as "itemData" is available as props.data
        
        const item = data[index]

        const name = this.props.mapping[item['name']]

        return (
            <Grid container style={{...style, 
                        height: 60,
                        paddingLeft: 24,
                        marginTop: 8, 
                        marginBottom: 20, 
                        paddingRight: 24, 
                        boxShadow: '0px 10px 10px rgba(225, 229, 233, 0.3)',
                        borderRadius: 15, 
                        width: '100%',

                        }} 
                        value={item.coin}
                        onClick={() => this.props.onClick(item['id'], item['name'])}
                        index={index}
                        className="analysis-line basier-p3">
            
                <Grid item style={{width: '50%' }} >

                <span>{name}</span>
                
                </Grid> 

                <Grid item style={{width: '25%', textAlign: 'left'}} >
                    {item['updated_at']}
                </Grid> 

                <div className="line-after" style={{left: '45%'}} /> 
                <Grid item style={{width: '25%', textAlign: 'left' }} >{item.completed ? "Completed" : "Calculating"}</Grid> 
                    
                </Grid>
                
        );
    };

      render() {
        
        const heightVitrual = 600; 
        const widthVirtual = '100%';
        const itemHeight = 70

        return (
            <div className="filter-list">

            <div style={{display: 'flex', alignItems: 'flex-end', backgroundColor: 'white', 'boxShadow': 'none', height: 30, paddingBottom: 12, marginBottom: 12, borderBottom: '1px solid #E1E5E9'}}>
                <Grid
                    container
                    direction="row"
                    alignItems="baseline"
                    style={{paddingLeft: 24, paddingRight: 24, color: '#6A7E93'}}
                    className="basier-p4-caps"
                > 
        
                <Grid item style={{width: '50%' }}>Type</Grid> 
                <Grid item style={{width: '25%', textAlign: 'left'}}>Last Updated</Grid> 
                <Grid item style={{width: '25%', textAlign: 'left'}}>Status</Grid> 


              </Grid>
            </div>
            
                <List
                    children={this.Row}
                    itemData={this.props.data}
                    height={heightVitrual}
                    itemCount={this.props.data.length}
                    itemSize={itemHeight}
                    width={widthVirtual}
                    >
                </List>
              
          </div>
        )
      }

}

const mapStateToProps = state => {
    return {
        currency_symbol: state.auth.currency.symbol,
    };
};

export default connect(
    mapStateToProps,
    null
)(AnalysisTable);