import React, {Component} from 'react'
import NonPassiveTouchTarget from '../../../../Overview/OverviewComponents/NonPassiveTouchTarget'
import TouchCarousel from 'react-touch-carousel'
import { connect } from 'react-redux';


function clamp (n, min, max) {
  if (n < min) {
    return min
  }
  if (n > max) {
    return max
  }
  return n
}


const downArrow = 
    (
    <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.3333 8.35083L6.68421 13.9999L1 8.35083" stroke="#F9716B" stroke-width="2" stroke-miterlimit="10"/>
    <path d="M6.68457 14V0" stroke="#F9716B" stroke-width="2" stroke-miterlimit="10"/>
    </svg>
    )

const upArrow = 
(
<svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.999933 7.50329L6.64905 1.85418L12.3333 7.5033" stroke="#4CDAC1" stroke-width="2" stroke-miterlimit="10"/>
<path d="M6.64868 1.85414L6.64868 15.8541" stroke="#4CDAC1" stroke-width="2" stroke-miterlimit="10"/>
</svg>
)

const styles = {
    root: {
      padding: '0 36px',
    },
    slideContainer: {
      padding: '0 12px',
    },
    slide: {
    },
    slide1: {
      backgroundColor: '#FEA900',
    },
    slide2: {
      backgroundColor: '#B3DC4A',
    },
    slide3: {
      backgroundColor: '#6AC0FF',
    },
  };
  
const cardSize = 200
const cardPadCount = 3
const carouselWidth = clamp(window.innerWidth, 0, 960)


class ProfitSummary extends Component {
  constructor (props) {
    super(props)
    this.state = {
      playing: 0, 
      numdigits: 0,
      portData: undefined, 
    }
    this.dataList = ['profit', 'investment', 'transactions']
  }

  componentDidMount() {

    window.addEventListener('touchstart', this.touchStart);
    window.addEventListener('touchmove', this.preventTouch, {passive: false});

    this.setState(state => ({...state, portData: this.props.data[this.props.portfolioId]}))

      if (this.props.inBitcoin === 'bitcoin') {
        this.setState(state => ({...state, numdigits: 2}))
      } else {
        this.setState(state => ({...state, numdigits: 0}))
      }
    }

    componentDidUpdate(prevProps) {
      if ( (prevProps.data !== this.props.data) || (prevProps.portfolioId !== this.props.portfolioId) ){
        this.setState(state => ({...state, portData: this.props.data[this.props.portfolioId]}))
      }
      if ((this.props.inBitcoin !== prevProps.inBitcoin)) {
        if (this.props.inBitcoin === 'bitcoin') {
            this.setState(state => ({...state, numdigits: 2}))
          } else {
            this.setState(state => ({...state, numdigits: 0}))
          }

      }
    }

  investmentRender = () => { return (
    <div>
      <div style={{display: 'flex', justifyContent: 'center'}}>
        
        <p className="basier-p2" style={{marginBottom: 12, color: 'black'}}>Investment</p>

      </div>

      <div style={{display: 'flex', justifyContent: 'center'}}>

          <h4 style={{fontSize: 24}}>
            {this.props.currency_symbol}&nbsp;
            {this.state.portData.deposit_check.toLocaleString('en', {minimumFractionDigits:this.state.numdigits, maximumFractionDigits: this.state.numdigits})}
          </h4>

      </div>

      <div style={{display: 'flex', justifyContent: 'center'}}>
        
      <p className="basier-p2" style={{marginBottom: 6, marginTop:6, color: '#6A7E93'}}>{this.props.currency_name}</p>

      </div>

    </div>
  )}


  profitRender = () =>  { return (
    <div>

      <div style={{display: 'flex', justifyContent: 'center'}}>
        
        <p className="basier-p2" style={{marginBottom: 12, color: 'black'}}>Total Profit</p>

      </div>

      <div style={{display: 'flex', justifyContent: 'center'}}>

            <h4 style={{fontSize: 24}}>
              {this.props.currency_symbol}&nbsp;
              {this.state.portData.net_profit.toLocaleString('en', {minimumFractionDigits:this.state.numdigits, maximumFractionDigits: this.state.numdigits})}
            </h4>

        </div>

      <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
      
      <div>
            {
                  this.state.portData.net_profit >= 0 ? 
                  <div className="arrow-container-large">{upArrow}</div>
                  : 
                  <div className="arrow-container-large">{downArrow}</div>

              }
            </div>
            <p className={`basier-p2 ${this.state.portData.net_profit > 0 ? 'growth' : 'decline'}`} style={{marginBottom: 6, marginTop:6}}>
                  { ((this.state.portData.percent_change) * 100).toLocaleString('en', {minimumFractionDigits:2, maximumFractionDigits: 2}) }%
            </p>

      </div>

    </div>
  )}

  transactionRender = () => { return (
    <div>

      <div style={{display: 'flex', justifyContent: 'center'}}>
        
      <p className="basier-p2" style={{marginBottom: 6, color: 'black'}}>Transactions</p>

      </div>

      <div style={{display: 'flex', justifyContent: 'center'}}>

            <h4 style={{fontSize: 24}}>
              {this.state.portData.number_transactions}
            </h4>

        </div>

      <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
      
        <p className="basier-p2" style={{marginBottom: 6, marginTop:6, color: '#6A7E93'}}>{`${this.state.portData.recent_transactions} in the last month`}</p>

      </div>

    </div>
  )}

  dataRender = (item) => {
    switch (item) {
      case 'investment': {
        return this.investmentRender(); 
        break;
      }
      case 'profit': {
        return this.profitRender(); 
        break;
      }
      case 'transactions': {
        return this.transactionRender(); 
        break;
      }
      default:
        return null; 

    }
  }


  renderCard = (index, modIndex, cursor) => {
    const item = this.dataList[modIndex]
    const playing = this.state.playing === modIndex
    return (
      <div
        key={index}
        className='carousel-card-summ'
      >
        <div className='carousel-card-inner-summ'>
          <div
            className='carousel-title-summ' 
          >
            <div style={{marginRight: 12, width: 200, height: 150}}>

              {this.dataRender(item)}

            </div>
           
          </div>
        </div>
      </div>
    )
  }

  render () {

    const CarouselContainer = (props) => {
      
      const {cursor, carouselState: {dragging, springing}, ...rest} = props
      let current = -Math.round(cursor) % this.dataList.length
        while (current < 0) {
          current += this.dataList.length
        }
      // Put current card at center
      const translateX = (cursor - cardPadCount) * cardSize + (carouselWidth - cardSize) / 2
      return (
        <div>
          <NonPassiveTouchTarget className='carousel-container-summ'>
            <NonPassiveTouchTarget
              className='carousel-track-summ'
              style={{transform: `translate3d(${translateX}px, 0, 0)`}}
              {...rest}
            />
    
                <div className='carousel-pagination-wrapper-summary'>
                  <ol className='carousel-pagination-summary'>
                    {['profit', 'investment', 'transactions'].map((_, index) => (
                      <li
                        key={index}
                        className={current === index ? 'current' : ''}
                      />
                    ))}
                  </ol>
                </div>
                
          </NonPassiveTouchTarget>
          <div className='cd-bar' />
        </div>
      )
    }


    return (
      <React.StrictMode>
        <div style={{marginTop: 24,}}>

          {
            this.state.portData !== undefined ?

              !this.props.loading ? 
              <TouchCarousel
                component={CarouselContainer}
                cardSize={cardSize}
                cardCount={3}
                cardPadCount={3}
                renderCard={this.renderCard}
              />

              :

              <div style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                <div style={{width: '80%', height: 60}} className="pulse-skeleton"></div>
                <div style={{width: '80%', height: 40, marginTop: 10}} className="pulse-skeleton"></div>
                <div style={{width: '80%', height: 40, marginTop: 10}} className="pulse-skeleton"></div>
              </div>

            :

            null
            
          }

          </div>
        
      </React.StrictMode>
    )
  }
}


const mapStateToProps = state => {
  return {
    currency_symbol: state.auth.currency.symbol,
    currency_name: state.auth.currency.id,
    inBitcoin: state.data.bitcoinPf
  };
};

export default connect(
  mapStateToProps,
  null,
)(ProfitSummary);


