import React from 'react';
import { connect } from 'react-redux';
import TableRow from './TableRow';
import worker from "../../../workers/walletFlattenWorker";
import WebWorker from "../../../workers/WebWorker";


const getValue = (dict, val) => {
    let ret = null
    try {ret = dict[val]} catch(e) {}
    return ret
}

class AccountTable extends React.PureComponent {

    state = {

        exchange: null, 
        storage: null,
        item: null,
        filteredItem: null,
        input_value: null,

    }

    componentDidMount() {

        this.worker = new WebWorker(worker);

        this.worker.addEventListener('message', event => {
            const exchange = event.data[0]
            const storage = event.data[1]
            
            if (exchange.length > 0 || storage.length > 0) {
                const selected = this.props.selected !== null && this.props.selected !== undefined && (getValue(this.props.selected, 'type') == this.props.scope || this.props.scope === 'all')
                let newSelect = this.props.selected
                if (selected) {
                    const scope = this.props.selected.type === 'exchange' ? exchange : storage
                    const newScope = scope.filter(v => v.id === this.props.selected.id)
                    if (newScope.length === 1) {
                        newSelect = newScope[0]
                    }
                }
                const itemPut = this.props.scope  === 'all' ? [...exchange, ...storage] : this.props.scope === 'exchange' ? exchange : storage
                const dataSelect = selected ? newSelect : itemPut !== null ? itemPut[0] : null
                this.setState(state => ({...state, 
                                        exchange: exchange, 
                                        storage: storage, 
                                        item: itemPut,
                                        filteredItem: itemPut,
                                        }))
                
                this.props.selectAccount(dataSelect)
            } else {
                this.setState(state => ({...state, item: [], exchange: [], storage: [], filteredItem: []}))
            }

        });

        if (this.props.data[this.props.user_id] !== undefined) {
            this.worker.postMessage(this.props.data[this.props.user_id])
        }
        
    }

    componentDidUpdate(prevProps) {

        if (this.props.data !== prevProps.data && this.props.data[this.props.user_id] !== undefined) {
            this.worker.postMessage(this.props.data[this.props.user_id])
        }

        if (this.props.scope !== prevProps.scope && this.props.scope !== undefined) {
            const selected = this.props.selected !== null && this.props.selected !== undefined && ( getValue(this.props.selected, 'type') == this.props.scope  || this.props.scope === 'all' )
            const itemPut = this.props.scope  === 'all' ? [...this.state.exchange, ...this.state.storage] : this.props.scope === 'exchange' ? this.state.exchange : this.state.storage
            const dataSelect = selected ? this.props.selected : itemPut !== null ? itemPut[0] : null


            this.setState(state => ({...state, item: itemPut, filteredItem: itemPut, input_value: null}))
            this.props.selectAccount(dataSelect)
        }


    }

    escapeRegexCharacters = (str) => {
        return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    }

    filterData = (e) => {

        e.persist(); 
        const input = e.target.value 

        const escapedValue = this.escapeRegexCharacters(input.trim());
        const regex = new RegExp('\\b' + escapedValue, 'i');
        const filteredList = this.state.item.filter(v => regex.test(v.name) || regex.test(v.source_name) || regex.test(v.source_name.replace(/[()]/g, "")) || regex.test(v.name.replace(/[()]/g, ""))  );

        this.setState(state => ({...state, filteredItem: filteredList, input_value: input}))

    }

    render () {

        return (
            <div style={{minHeight: 575, height: 1150, maxHeight: 1150,}}>
                
                <div style={{marginTop: 12, marginBottom: 12, width: '100%', height: 30}}>
                    <input value={this.state.input_value || "" } onChange={this.filterData} className="light-input-back basier-p4" style={{height: 30, width: '100%'}} type="text" placeholder={this.props.scope === 'exchange' ? `Filter: type exchange or account name` : `Filter: type coin or account name` }/>
                </div>


                
                
                {
                
                this.state.filteredItem !== null && this.state.item !== null ? 

                    this.state.item.length === 0 && this.props.scope === 'all' ? 

                        <div className="basier-p4"> You do not have any wallets yet. Add wallets to see them here.</div>

                    :

                    this.state.exchange.length === 0 && this.props.scope === 'exchange' ? 

                        <div className="basier-p4"> You do not have any exchange wallets yet. Add wallets to see them here.</div>
                    : 

                    this.state.exchange.length === 0 && this.props.scope === 'storage' ? 

                        <div className="basier-p4"> You do not have any storage wallets yet. Add wallets to see them here.</div>
                    :

                    <div className="hover-on-scroll" style={{ height: '100%',}}>
                    <div>
                    {this.state.filteredItem.map((v2, k2) => {
                        return (
                            <TableRow key={k2} data={v2} type={v2.type} selected={this.props.selected} selectAccount={this.props.selectAccount} />
                        )
                        })
                    }

                    </div>
                    </div>

                :
                null

                }
        

        </div>
        
        )
    }


}

const mapStateToProps = state => {
    return {
        user_id: state.auth.user_id
    };
};

const mapDispatchToProps = dispatch => {
    return {
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(AccountTable)
