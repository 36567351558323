import React from 'react';
import { connect } from 'react-redux';
import BackIcon from "../../../../Media/BackIcon.svg";
import ExchangeList from '../../../MobileOnly/ExchangeDrawer'
import fieldValidator from '../../../../helpers/validators'
import { backendUrl } from '../../../../variables';
import axios from 'axios';
import * as appwideActions from "../../../../store/actions/appwide";
import * as dataActions from "../../../../store/actions/data";
import loadingSpinner from '../../../../Media/contentLoader.svg'
import successIcon from '../../../../Media/okIcon.svg'
import errorIcon from '../../../../Media/errorIcon.svg'

class AddExchangeMobile extends React.PureComponent {

    state = {
        data: {
            exchange: null, 
            name: null,
            user_id: this.props.user_id,
            custom_exchange: null,
        },
        exchangeError: false, 
        exchangeModal: false,
        loading: false,
        success: false,
        error: false,
    }

    componentDidMount() {
        this.props.setCurrentView(971)
    }
    
    exchangeModalClose = () => { //

        this.setState(state => ({...state, exchangeModal: false}))


    };

    exchangeModalOpen = () => { //

        this.setState(state => ({...state, exchangeModal: true}))

    };

    handleExchangeSelect = (val) => {

        if (val.custom === true) {
            this.setState(state => ({...state, data: {...state.data, custom_exchange: val, exchange: null}}))
        } else {
        this.setState(state => ({...state, data: {...state.data, exchange: val, custom_exchange: null}}))
        }
        this.exchangeModalClose()

    }

    handleInput = (e, label) => {
        e.persist();
        this.setState(state => ({...state, data: {...state.data, [label]: e.target.value}}))
    }


    updateServer = () => {
        
        
        const exchange_val = fieldValidator(this.state.data['exchange'], ['isNotEmpty'])
        const custom_val = fieldValidator(this.state.data['custom_exchange'], ['isNotEmpty'])
        this.setState(state => ({...state, exchangeError: !exchange_val}))

        if (exchange_val || custom_val) {
            
            this.setState(state => ({...state, loading: true}))
            let data = {}
            if (this.state.data.custom_exchange === null) {
                data = {
                    ...this.state.data, 
                    exchange: this.state.data.exchange.id
                }
            } else if (this.state.data.custom_exchange !== null) {
                data = {
                    ...this.state.data, 
                    exchange: null,
                    custom_exchange: this.state.data.custom_exchange,
                }
            }

            this.setState(state => ({...state, eAloading: true}))
            let headers = { "content-type": "application/json" };
            if (this.props.token !== null && this.props.token !== undefined) {

                headers['Authorization'] = `Token ${this.props.token}`

            }

            const url_call = `${backendUrl}/v1/exchange_accounts/`

            axios.post(url_call, data, {headers: headers})
                .then(json => {

                   this.props.getWallets(this.props.user_id)
                   this.setState(state => ({...state, loading: false, success: true}))
                   
                   setTimeout(() => {

                    this.props.history.goBack()
        
                  }, 600)
                    
                })
                .catch( e => {

                this.setState(state => ({...state, loading: false, error: true}))

                })

        }
        
    }

    render () {

        return (

            this.state.loading ?

            <div style={{width: '100%', marginTop: 60, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                <img src={loadingSpinner} alt="" style={{height: 80, width: 80, marginBottom: 12}} />
                <div className="basier-p3">Creating Account...</div>
            </div>

            :

            this.state.success ? 

            <div style={{width: '100%', marginTop: 60, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                <img src={successIcon} alt="" style={{height: 50, width: 50, marginBottom: 12}} />
                <div className="basier-p3">Successfully created account!</div>
            </div>

            :

            this.state.error ? 

            <div style={{width: '100%', marginTop: 60, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                <img src={errorIcon} alt="" style={{height: 50, width: 50, marginBottom: 12}} />
                <div className="basier-p3">Oops! An error occured, please try again.</div>

                <div style={{width: '100%', display: 'flex', justifyContent: 'center', marginTop: 24}} >
                        <div
                            onClick={() => this.props.history.goBack()}
                            className='button-next'
                            style={{height: 40, borderRadius: 35, marginLeft: 12, marginRight: 12, cursor: 'pointer', backgroundColor: '#F9716B', color: 'white'}}
                            >
                            Cancel
                        </div>

                        <div
                            onClick={this.updateServer}
                            className='button-next-blue'
                            style={{height: 40, borderRadius: 35, marginLeft: 12, marginRight: 12, cursor: 'pointer' }}
                            >
                            Retry
                        </div> 
                    </div>

            </div>


            :

            <div style={{position: 'absolute', top: 0, zIndex: 999999, width: '100%'}}>

                <div style={{backgroundColor: '#F8F9FA', zIndex: 9999999, height: 75, width: '100%', display:'flex', alignItems: 'center', position: 'fixed', justifyContent: 'space-between'}} id="responsive-dialog-title">
                    
                        <div onClick={() => this.props.history.goBack()}>
                            <img style={{paddingTop: 24}} src={BackIcon} alt=""/>
                        </div>
                        <div className="basier" style={{fontSize: '1.2rem', marginLeft: -62}}>New Account</div> 
                        <div></div>

                    </div>

                <div style={{position: 'absolute', top: 75, zIndex: 120000, backgroundColor: 'white', minHeight: 100, width: '100%'}}>
                    <div>

                        <div style={{marginTop: 0, display: 'flex', alignItems: 'center',}}>

                            <div 
                                style={{
                                height: 60,
                                display: 'flex', 
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                paddingLeft: 24,
                                borderBottom: '1px solid #E1E5E9',
                                borderTop: '1px solid #E1E5E9',
                                width: '100%',
                                textTransform: 'uppercase',
                                fontWeight: 900
                            }}
                                onClick={() => this.exchangeModalOpen()}
                                className="basier-p3">

                                
                                <div style={{color: this.state.exchangeError ? 'red' : 'black', fontWeight: 900}}>
                                    Exchange
                                </div>

                                <div style={{marginRight: 24, display: 'flex', alignItems: 'center'}}>

                                    <div style={{marginRight: 24, color: '#6A7E93'}}>
                                        {
                                            (this.state.data.exchange !== undefined && this.state.data.exchange !== null) || (this.state.data.custom_exchange !== undefined && this.state.data.custom_exchange !== null) ? 
                                            
                                            <div style={{display: 'flex', alignItems: 'center'}}>
                                                {(this.state.data.exchange !== undefined && this.state.data.exchange !== null) &&
                                                <img src={this.state.data.exchange['image_thumb']} style={{height: 24, width: 24, marginRight: 12}} alt="" />}
                                                <div style={{textTransform: 'capitalize'}}>
                                                    {
                                                    this.state.data.exchange !== null ? 
                                                    this.state.data.exchange['name'] 
                                                    :
                                                    this.state.data.custom_exchange!== null ? 
                                                    this.state.data.custom_exchange['name']
                                                    :
                                                    null
                                                    }
                                                    </div>
                                            </div>
                                            
                                            :
                                            <div style={{color: this.state.exchangeError ? 'red' : 'black'}}>select...</div>
                                        }
                                    </div>

                                    <div>
                                        >
                                    </div>
                                </div>

                                

                            </div>

                        </div>

                        <div style={{marginTop: 24, paddingLeft: 12, paddingRight: 12}}>
                            <div className="basier-p4-caps">Account name (optional)</div>
                            <div style={{marginTop: 6}} className="basier-p2">
                                <input value={this.state.data.name} onChange={(e) => this.handleInput(e, 'name')} style={{width: '100%', textAlign: 'left',  height: 50, border: '1px solid #E1E5E9', paddingLeft: 12}} className="no-outline" />
                            </div>
                        </div>

                        <div style={{marginTop: 24, width: '100%', display: 'flex', justifyContent: 'center'}} >
                            
                            <div className='button-next-blue' onClick={this.updateServer} style={{height: 40, borderRadius: 35, marginLeft: 12, marginRight: 12, cursor: 'pointer'}}>
                                Submit
                            </div>

                        </div>

                    </div>
                </div>
                <ExchangeList exchanges={this.props.exchanges} openModal={this.state.exchangeModal} closeModal={this.exchangeModalClose} handleSelect={(res) => this.handleExchangeSelect(res)}/>
            </div>
            
        )
    }


}

const mapStateToProps = state => {
    return {
        exchanges: state.appwide.exchanges, 
        user_id: state.auth.user_id, 
        token: state.auth.token,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getWallets: (user) => dispatch(appwideActions.getWallets(user)), 
        setCurrentView: (v) => dispatch(dataActions.setCurrentView(v))
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(AddExchangeMobile)
