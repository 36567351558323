import React from 'react';
import { connect } from 'react-redux';
import * as actions from "../../../store/actions/data";
import * as interActions from "../../../store/actions/interactions";
import * as appwideActions from "../../../store/actions/appwide";
import '../../Portfolio/PortfolioComponents/Transactions/TransactionStyle.css'
import {loadData, siwtchTransactionType, dateParser, formattedNumber} from '../../../helpers/helpers'
import BaseModalTop from '../../Portfolio/PortfolioComponents/Transactions/BaseModalTop';
import { withRouter } from "react-router-dom";
import axios from 'axios';
import { backendUrl } from '../../../variables'
import Tooltip from '../../AppWide/Tooltip';
import TypeSelector from '../../AppWide/TableTypeSelector';
import arrowRound from '../../../Media/arrowRound.svg';

class MatchedTransfer extends React.PureComponent {

    state = {

        transactionId: this.props.transactionId,
        date: null,
        data: null,
        editConnected: false,
        connectedEdit: {
            unit_price: null
        }
    }


    renderTransaction = (data) => {
        
        return (
            <div style={{width: '100%', padding: 12}}>
                
                <div style={{
                    fontWeight: 900, 
                    width: 100,
                    height: 30, 
                    padding: '0px 12px', 
                    display: 'flex', 
                    alignItems: 'center', 
                    justifyContent: 'center', 
                    borderRadius: 20, 
                    textTransform: 'uppercase', 
                    color: 'white', 
                    backgroundColor: siwtchTransactionType(data.transaction_description)[1]
                    }} 
                    className="basier-p4-caps">
                        {siwtchTransactionType(data.transaction_description)[0]}
                </div>
                <div style={{display: 'flex', alignItems: 'center', marginTop: 6, paddingLeft: 4, }}>
                    <div className="basier-p4" style={{paddingLeft: 0}}>
                        { dateParser(data.date) }
                    </div >
                </div>
                <div className="basier-p3" style={{marginTop: 12,}}>
                    <span style={{fontWeight: 900, color: 'black'}}>Amount: </span>
                    <span style={{fontWeight: 400}}>{formattedNumber(data.amount, 8)}</span>

                </div>
                <div className="basier-p3" style={{marginTop: 6, }}>
                        
                        <span style={{fontWeight: 900, color: 'black'}}>{
                            data.transaction_description === 'deposit' ? 'Sent to: ' : 'Sent from: '
                        }</span>

                        {
                            data.storage !== null ? 

                                <span style={{fontWeight: 400}}>{data.storage.name}</span>


                            : 

                            data.account !== null ? 

                                <span style={{fontWeight: 400}}>{data.account.name}</span>

                            : 
                            
                            'Not specified'
                        }
                        
                    </div>
                    
                    <div className="basier-p3" style={{marginTop: 6, }}>
                        
                        <span style={{fontWeight: 900, color: 'black'}}>Address: </span>

                        {
                            data.connection !== null ? 

                                data.transaction_type === 'deposit' ? 
                                    <span className="basier-p4 truncate" style={{fontWeight: 400}}>{this.props.passedData['withdraw']['address_hash']}</span>
                                    
                                :   
                                    <span className="basier-p4 truncate" style={{fontWeight: 400}}>{this.props.passedData['deposit']['address_hash']}</span>

                            :

                                data.storage !== null ? 

                                    <span className="basier-p4 truncate" style={{fontWeight: 400}}>{data.storage.address}</span>


                                : 

                                data.account !== null ? 

                                    <span className="basier-p4 truncate" style={{fontWeight: 400}}>{data.account.address}</span>

                                : 
                                
                                'Not specified'
                            


                        }
                        
                    </div>

            </div>
        )

    }

    render() {

        return (

            this.props.passedData !== null && this.props.passedData !== undefined ? 
            <div>
                <div style={{padding: '24px 48px', width: '100%'}}>

                    {this.props.simple !== true && 
                    <div className="basier-p4" style={{width: '100%', fontStyle: 'italic', textAlign: 'justify', marginBottom: 24}}>
                        Matched transfers are created from a deposit and a withdrawal within your portfolio, when these two transactions occur between your (or your collaborators) accounts. This allows us to only take fees into consideration, and not calculate withdrawal / disposal realized profit.
                    </div>}
                    
                    {this.props.simple !== true ?     
                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', paddingBottom: 0, marginBottom: 24, borderBottom: 'none'}}>
                      
                        
                        <div style={{
                            fontWeight: 900, 
                            width: 210,
                            height: 40, 
                            padding: '0px 12px', 
                            display: 'flex', 
                            alignItems: 'center', 
                            justifyContent: 'center', 
                            borderRadius: 20, 
                            textTransform: 'uppercase', 
                            color: 'white', 
                            backgroundColor: siwtchTransactionType('transfer')[1]
                            }} 
                            className="basier-p3">
                                Matched Transfer
                        </div>

                        <div className="basier-p3 opacity-hover" style={{textTransform: 'uppercase', color: this.props.passedData.deposit.connection !== null ? '#E1E5E9' : '#0078DB', cursor: this.props.passedData.deposit.connection !== null ? 'not-allowed' : 'pointer', }}>
                            Unmatch
                        </div>
                    </div>
                    :
                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', paddingBottom: 0, marginBottom: 24, borderBottom: 'none'}}>
                      We have found a transaction match within your portfolio. Please review the details below and confirm whether you want to match these transactions together.
                    </div>
                    }
                    
                    <div className="basier-p4-caps" style={{marginBottom: 6, paddingLeft: 6}}>Match Details</div>

                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', boxShadow: '0px 10px 10px rgba(225, 229, 233, 0.3)', minHeight: 75, borderRadius: 20}}>
                            
                        <div style={{width: '50%'}}>
                            {this.renderTransaction(this.props.passedData['withdraw'])}
                        </div>

                        <div style={{width: '50%'}}>
                            {this.renderTransaction(this.props.passedData['deposit'])}
                        </div>

                    </div>

                    <div className="basier-p4-caps" style={{marginBottom: 6, marginTop: 24, paddingLeft: 6}}>Transfer Details</div>
                    
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', boxShadow: '0px 10px 10px rgba(225, 229, 233, 0.3)', minHeight: 75, borderRadius: 20}}>

                        <div style={{width: '100%', padding: 12}}>
                            <div className="basier-p3" style={{marginTop: 12,}}>
                                <span style={{fontWeight: 900, color: 'black'}}>Coin: </span>
                                <span style={{fontWeight: 400}}>{this.props.passedData['deposit']['base_currency']['name']}</span>
                            </div>

                            <div className="basier-p3" style={{marginTop: 12,}}>
                                <span style={{fontWeight: 900, color: 'black'}}>Fee: </span>
                                <span style={{fontWeight: 400}}>{formattedNumber(this.props.passedData['withdraw']['fee'], 8)} {this.props.passedData['deposit']['base_currency']['symbol']}</span>
                            </div>
                            
                            {this.props.simple !== true && 
                            <div style={{marginTop: 12,}}>
                                <span className="basier-p3"  style={{fontWeight: 900, color: 'black'}}>Tx Hash: </span>
                                <span className="basier-p4"  style={{fontWeight: 400}}>{this.props.passedData['deposit']['tid']}</span>
                            </div>}

                        </div>

                    </div>

                        
                </div>

            </div>
           :

           null

        )
    }
  
};

const mapStateToProps = state => {
    return {
        inBitcoin: state.data.bitcoinPf, 
        token: state.auth.token,
        user_id: state.auth.user_id,
        roles: state.auth.roles,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setCurrentView: (view) => 
            dispatch(actions.setCurrentView(view)),
        componentDataFetch: (scope, view, currency, user, portfolio, globalGraphData, portfolioGraphData, inBitcoin) =>
            dispatch(actions.componentDataFetch(scope, view, currency, user, portfolio, globalGraphData, portfolioGraphData, inBitcoin)), 
        toggleModal: (data) => 
            dispatch(interActions.toggleModal(data)), 
        getFlatWallets: (user_id) => 
            dispatch(appwideActions.getFlatWalletList(user_id)), 
        getAllPortfolioList: (user_id) => 
            dispatch(appwideActions.getAllPortfolioList(user_id)), 
        getUserLimits: (user) => dispatch(appwideActions.getUserLimits(user)), 
        getImports: (data) => dispatch(actions.getImports(data)), 
        
        
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(MatchedTransfer));