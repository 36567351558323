import React from 'react';
import CoinSearch from '../../../../../AppWide/CoinSearch';
import ExchangeWallets from './ExchangeWallets'
import WalletCreation from './WalletCreation'
import * as actions from "../../../../../../store/actions/interactions";
import { connect } from 'react-redux';


class ExchangeQuestion extends React.PureComponent {


    constructor(props) {

        super(props)
        this.exchangeFocusRef = React.createRef();

        this.state = {
            existing_wallet: null, 
            single_account: null, 
            multiple_account: null,
            
        }

    }


    componentDidMount() {

        
        if (this.props.transaction['data'][this.props.transactionId]['exchange'] === undefined) {

            this.props.updateTransaction({key: this.props.transactionId, label: "exchange", value: null})
            this.props.updateTransaction({key: this.props.transactionId, label: "account", value: null})
            this.props.updateTransaction({key: this.props.transactionId, label: "account_name", value: null})
            this.props.updateTransaction({key: this.props.transactionId, label: "wallets", value: {} })

        }

    }

    

    handleSelect = (value) => {

        this.props.selectExchange(value)
        const scope_exchange = value.value
        let scope_coin = null 
        try {scope_coin = this.props.transaction['data'][this.props.transactionId]['base_currency']['value']} catch(e) {}


        let exchange_accounts = null
        try { exchange_accounts = this.props.wallets[this.props.user_id]['exchange'][scope_exchange] } catch (e) {}

        if ( exchange_accounts === null ||  exchange_accounts === undefined ) {
            //this.setState(state => ({existing_wallet: false}))
        } else if (exchange_accounts.length === 1) {
            //this.setState(state => ({existing_wallet: true, single_account: true, multiple_account: false}))
        } else if (exchange_accounts.length > 1) {
            //this.setState(state => ({existing_wallet: true, multiple_account: true, single_account: false}))
        }

    }

    render() {

        const scope_coin = this.props.transaction['data'][this.props.transactionId]['base_currency']['name']
        let scope_quote = null 
        try {scope_quote = this.props.transaction['data'][this.props.transactionId]['quote_currency']['name']} catch(e) {}

        const base_coin = this.props.transaction['data'][this.props.transactionId]['base_currency']['value']

        let quote_coin = null 
        try {quote_coin = this.props.transaction['data'][this.props.transactionId]['quote_currency']['value']} catch(e) {}

        return (

        <div>

            <div style={{

            display: 'flex', 
            height: 60,//height: `${this.props.transaction['data'][this.props.transactionId]['quote_currency'] !== null && this.props.transaction['data'][this.props.transactionId]['quote_currency']  !== undefined ? '60px' : '300px'}`, 
            alignItems: 'baseline',
            transition: 'height 0.3s'
            }}>
            
                <div className='basier-p3' style={{paddingLeft: `${this.props.firstFocus ? '0px' : '24px'}`, marginTop: 12, width: 500, marginBottom: 6, marginRight: 24, fontWeight:  `${this.props.firstFocus ? 900 : 400}`}}>{this.props.question}</div>

                    <CoinSearch 
                        data_filter={this.props.exchanges}
                        dataBind={this.props.transaction['data'][this.props.transactionId]['exchange']}
                        ref={this.exchangeFocusRef}
                        width={250}
                        autoFocus={true}
                        keepInput={true}
                        overrideClass={true} 
                        //focusIn={() => this.props.getExchangeAddress()}
                        type="exchange"
                        allowOther={true}
                        clearRedux={['exchange', 'account', 'account_name']}
                        clearWallets={true}
                        removeWallets={this.props.removeWallets}
                        updaterMethod={(key, value) => this.props.updateTransaction({key: this.props.transactionId, label: key, value: value})}
                        handleSelect={(value) => this.handleSelect(value)}
                        masterClass="transaction-coin-search"/>

                    
                    



            </div>

            {
                this.props.transaction['data'][this.props.transactionId]['exchange'] !== null && this.props.transaction['data'][this.props.transactionId]['exchange'] !== undefined ?

                <div>



                {

                    this.props.transaction['data'][this.props.transactionId]['exchange'] !== null && this.props.transaction['data'][this.props.transactionId]['exchange'] !== undefined ? 

                        (this.props.wallets[this.props.user_id]['exchange'][this.props.transaction['data'][this.props.transactionId]['exchange']['value']] !== null &&
                        this.props.wallets[this.props.user_id]['exchange'][this.props.transaction['data'][this.props.transactionId]['exchange']['value']] !== undefined) ?
                        <div>
                            <div style={{

                                display: 'flex', 
                                height: 60,//height: `${this.props.transaction['data'][this.props.transactionId]['quote_currency'] !== null && this.props.transaction['data'][this.props.transactionId]['quote_currency']  !== undefined ? '60px' : '300px'}`, 
                                alignItems: 'baseline',
                                transition: 'height 0.3s'
                                }}>

                                <div className='basier-p3' style={{paddingLeft: 24, marginTop: 12, width: 500, marginBottom: 6, marginRight: 24, fontWeight:  400}}>
                                    Select your {this.props.transaction['data'][this.props.transactionId]['exchange']['name']} account - or type the name of a new one.
                                </div>

                                <ExchangeWallets 
                                    accounts={this.props.wallets[this.props.user_id]['exchange']}
                                    exchange={this.props.transaction['data'][this.props.transactionId]['exchange']}
                                    updateTransaction={this.props.updateTransaction}
                                    transactionId={this.props.transactionId}
                                />

                            </div>
                            {!this.props.validation.accountName && 

                            <div style={{paddingLeft: 24, color: 'red', fontStyle: 'italic', marginTop: 6}} className="basier-p4">
                                 You already have an account with that name. Please select a different name.
                            </div>   
                                
                            }
                        </div>

                            : 

                            this.props.noAdd ? 

                                null 

                            :
                        
                                <div>

                                    <div className="basier" style={{fontSize: 14, fontStyle: 'italic', color: 'black', marginBottom: 12, marginTop: 12}}>
                                        You do not have a registered {this.props.transaction['data'][this.props.transactionId]['exchange']['name']} account with us yet. <span style={{color: '#0078DB', fontWeight: 900}}>No worries, we will create one for you!</span> 
                                    </div>

                                    <div className="basier" style={{color: 'black', fontSize: 14, fontStyle: 'italic'}}>

                                    <span style={{color: '#0078DB', fontWeight: 900}}>We will also create</span><span> the corresponding</span>
                                        <span style={{color: '#0078DB', fontWeight: 900}}> {this.props.transaction['data'][this.props.transactionId]['base_currency']['name']}</span> 
                            
                            { 
                                            this.props.transaction['data'][this.props.transactionId]['quote_currency'] !== null && this.props.transaction['data'][this.props.transactionId]['quote_currency'] !== undefined ?
                                            
                                            <span>
                                            <span> and </span>
                                            <span style={{color: '#0078DB', fontWeight: 900}}> {this.props.transaction['data'][this.props.transactionId]['quote_currency']['name']} wallets.</span>
                                            <span> You can edit the wallet details below or leave as is and simply click next! </span>
                                            </span>

                                            : 

                                            <span> wallet </span>
                            }
                                    
                                    </div>

                                    <div style={{marginTop: 12}}>
                                        <WalletCreation 
                                            exchange={this.props.transaction['data'][this.props.transactionId]['exchange']} 
                                            coin={this.props.transaction['data'][this.props.transactionId]['base_currency']['name']}
                                            coin_id={this.props.transaction['data'][this.props.transactionId]['base_currency']['value']}
                                            coin_ticker={this.props.selectedBase['coinTicker']}
                                            user_first={this.props.user_first}
                                            user_last={this.props.user_last}
                                            transactionId={this.props.transactionId}
                                            simple={true}/>
                                    </div>

                            { 
                                        this.props.transaction['data'][this.props.transactionId]['quote_currency'] !== null && this.props.transaction['data'][this.props.transactionId]['quote_currency'] !== undefined ?
                                            

                                    <div style={{marginTop: 12}}>
                                        <WalletCreation 
                                            exchange={this.props.transaction['data'][this.props.transactionId]['exchange']} 
                                            coin={this.props.transaction['data'][this.props.transactionId]['quote_currency']['name']}
                                            coin_id={this.props.transaction['data'][this.props.transactionId]['quote_currency']['value']}
                                            coin_ticker={this.props.selectedQuote['coinTicker']}
                                            user_first={this.props.user_first}
                                            user_last={this.props.user_last}
                                            transactionId={this.props.transactionId}
                                            simple={true}/>
                                    </div>

                                    :

                                    null

                            }
                                

                                </div>

                    : 

                    null 

                }

                </div>

                :

                null 

                }

                    

                {
                this.props.transaction['data'][this.props.transactionId]['account'] !== -1 && 
                this.props.transaction['data'][this.props.transactionId]['account'] !== null && 
                this.props.transaction['data'][this.props.transactionId]['account'] !== undefined &&
                this.props.transaction['data'][this.props.transactionId]['exchange'] !== null &&
                this.props.transaction['data'][this.props.transactionId]['exchange'] !== undefined &&
                this.props.wallets[this.props.user_id]['exchange'][this.props.transaction['data'][this.props.transactionId]['exchange']['value']][this.props.transaction['data'][this.props.transactionId]['account']]['connected'] === false && 

                <div>

                    {
                    !this.props.noAdd && 
                    this.props.transaction['data'][this.props.transactionId]['account'] !== -1 && 
                    this.props.transaction['data'][this.props.transactionId]['account'] !== null && 
                    this.props.transaction['data'][this.props.transactionId]['account'] !== undefined &&
                    this.props.transaction['data'][this.props.transactionId]['exchange'] !== null &&
                    this.props.transaction['data'][this.props.transactionId]['exchange'] !== undefined ?
                    

                            this.props.wallets[this.props.user_id]['exchange'][this.props.transaction['data'][this.props.transactionId]['exchange']['value']][this.props.transaction['data'][this.props.transactionId]['account']]['wallets'][base_coin] !== null 
                            &&
                            this.props.wallets[this.props.user_id]['exchange'][this.props.transaction['data'][this.props.transactionId]['exchange']['value']][this.props.transaction['data'][this.props.transactionId]['account']]['wallets'][base_coin] !== undefined 
                            &&
                            (this.props.wallets[this.props.user_id]['exchange'][this.props.transaction['data'][this.props.transactionId]['exchange']['value']][this.props.transaction['data'][this.props.transactionId]['account']]['wallets'][quote_coin] !== null || quote_coin === null)
                            &&
                            (this.props.wallets[this.props.user_id]['exchange'][this.props.transaction['data'][this.props.transactionId]['exchange']['value']][this.props.transaction['data'][this.props.transactionId]['account']]['wallets'][quote_coin] !== undefined || quote_coin === null)
                            
                            ?  
    
                            null
    
                            : 


                            <div className="basier" style={{color: 'black', fontSize: 14, fontStyle: 'italic'}}>

                                <span>You do not have a</span>

                                    {
                                        this.props.wallets[this.props.user_id]['exchange'][this.props.transaction['data'][this.props.transactionId]['exchange']['value']][this.props.transaction['data'][this.props.transactionId]['account']]['wallets'][base_coin] === null || 
                                        this.props.wallets[this.props.user_id]['exchange'][this.props.transaction['data'][this.props.transactionId]['exchange']['value']][this.props.transaction['data'][this.props.transactionId]['account']]['wallets'][base_coin] === undefined ? 

                                        <span>
                                        <span style={{color: '#0078DB', fontWeight: 900}}> {this.props.transaction['data'][this.props.transactionId]['base_currency']['name']}</span> 
                                        

                                        {
                                        (this.props.wallets[this.props.user_id]['exchange'][this.props.transaction['data'][this.props.transactionId]['exchange']['value']][this.props.transaction['data'][this.props.transactionId]['account']]['wallets'][quote_coin] === null && quote_coin !== null) || 
                                        (this.props.wallets[this.props.user_id]['exchange'][this.props.transaction['data'][this.props.transactionId]['exchange']['value']][this.props.transaction['data'][this.props.transactionId]['account']]['wallets'][quote_coin] === undefined && quote_coin !== null) ?
                                        
                                            <span> or a </span>

                                            :

                                            null
                                        }

                                        </span>

                                        : 

                                        null
                                    }

                                    {
                                        (this.props.wallets[this.props.user_id]['exchange'][this.props.transaction['data'][this.props.transactionId]['exchange']['value']][this.props.transaction['data'][this.props.transactionId]['account']]['wallets'][quote_coin] === null && quote_coin !== null) || 
                                        (this.props.wallets[this.props.user_id]['exchange'][this.props.transaction['data'][this.props.transactionId]['exchange']['value']][this.props.transaction['data'][this.props.transactionId]['account']]['wallets'][quote_coin] === undefined && quote_coin !== null) ?

                                        <span style={{color: '#0078DB', fontWeight: 900}}> {this.props.transaction['data'][this.props.transactionId]['quote_currency']['name']} </span>

                                        :

                                        null
                                    }

                                    <span> wallet registered with us yet. </span><span style={{color: '#0078DB', fontWeight: 900}}>No worries, we will create one for you!</span> <span>You can edit the details below or simply click next!</span> 

                            </div>
                                   
                    
                    :

                    null

                    }

                    {
                    !this.props.noAdd && 
                    this.props.transaction['data'][this.props.transactionId]['account'] !== -1 && 
                    this.props.transaction['data'][this.props.transactionId]['account'] !== null && 
                    this.props.transaction['data'][this.props.transactionId]['account'] !== undefined &&
                    this.props.transaction['data'][this.props.transactionId]['exchange'] !== null &&
                    this.props.transaction['data'][this.props.transactionId]['exchange'] !== undefined ?


                        this.props.wallets[this.props.user_id]['exchange'][this.props.transaction['data'][this.props.transactionId]['exchange']['value']][this.props.transaction['data'][this.props.transactionId]['account']]['wallets'][base_coin] === null || 
                        this.props.wallets[this.props.user_id]['exchange'][this.props.transaction['data'][this.props.transactionId]['exchange']['value']][this.props.transaction['data'][this.props.transactionId]['account']]['wallets'][base_coin] === undefined ? 
                    

                        <div style={{marginTop: 12}}>
                        <WalletCreation 
                                exchange={this.props.transaction['data'][this.props.transactionId]['exchange']} 
                                coin={this.props.transaction['data'][this.props.transactionId]['base_currency']['name']}
                                coin_id={this.props.transaction['data'][this.props.transactionId]['base_currency']['value']}
                                coin_ticker={this.props.selectedBase['coinTicker']}
                                user_first={this.props.user_first}
                                user_last={this.props.user_last}
                                transactionId={this.props.transactionId}
                                simple={true}/>
                        </div>

                                    
                        :

                        null

                    :

                    null
                        
                    }

                    {
                    !this.props.noAdd && 
                    this.props.transaction['data'][this.props.transactionId]['account'] !== -1 && 
                    this.props.transaction['data'][this.props.transactionId]['account'] !== null && 
                    this.props.transaction['data'][this.props.transactionId]['account'] !== undefined &&
                    this.props.transaction['data'][this.props.transactionId]['exchange'] !== null &&
                    this.props.transaction['data'][this.props.transactionId]['exchange'] !== undefined ?


                        (this.props.wallets[this.props.user_id]['exchange'][this.props.transaction['data'][this.props.transactionId]['exchange']['value']][this.props.transaction['data'][this.props.transactionId]['account']]['wallets'][quote_coin] === null && quote_coin !== null) || 
                        (this.props.wallets[this.props.user_id]['exchange'][this.props.transaction['data'][this.props.transactionId]['exchange']['value']][this.props.transaction['data'][this.props.transactionId]['account']]['wallets'][quote_coin] === undefined && quote_coin !== null) ? 
                            
                        

                        <div style={{marginTop: 12}}>
                        <WalletCreation 
                                exchange={this.props.transaction['data'][this.props.transactionId]['exchange']} 
                                coin={this.props.transaction['data'][this.props.transactionId]['quote_currency']['name']}
                                coin_ticker={this.props.selectedQuote['coinTicker']}
                                coin_id={this.props.transaction['data'][this.props.transactionId]['quote_currency']['value']}
                                user_first={this.props.user_first}
                                user_last={this.props.user_last}
                                transactionId={this.props.transactionId}
                                simple={true}/>
                        </div>

                                    
                        :

                        null

                    :

                    null
                        
                    }


                    {

                    (this.props.transaction['data'][this.props.transactionId]['account'] === -1 && 
                    this.props.transaction['data'][this.props.transactionId]['account_name'] !== null && 
                    this.props.transaction['data'][this.props.transactionId]['account_name'] !== undefined && 
                    this.props.transaction['data'][this.props.transactionId]['exchange'] !== null &&
                    this.props.transaction['data'][this.props.transactionId]['exchange'] !== undefined) ?
                        
                    <div>
                        {!this.props.noAdd?
                            <div className="basier" style={{color: 'black', fontSize: 14, fontStyle: 'italic'}}>
                            <span>We will create the</span> <span style={{color: '#0078DB', fontWeight: 900}}>new wallet {this.props.transaction['data'][this.props.transactionId]['account_name']}. </span>
                                <span style={{color: '#0078DB', fontWeight: 900}}>We will also add to it</span><span> the corresponding</span>
                                    <span style={{color: '#0078DB', fontWeight: 900}}> {this.props.transaction['data'][this.props.transactionId]['base_currency']['name']}</span> 
                        
                                    {    
                                        this.props.transaction['data'][this.props.transactionId]['quote_currency'] !== null && this.props.transaction['data'][this.props.transactionId]['quote_currency'] !== undefined ?
                                        
                                        <span>
                                        <span> and </span>
                                        <span style={{color: '#0078DB', fontWeight: 900}}> {this.props.transaction['data'][this.props.transactionId]['quote_currency']['name']} wallets.</span>
                                        <span> You can edit the wallet details below or leave as is and simply click next! </span>
                                        </span>

                                        : 

                                        <span> wallet </span>
                                    }
                                
                            </div>
                        :
                        null
                        }

                        <div style={{marginTop: 12}}>
                                <WalletCreation 
                                    noAdd={this.props.noAdd}
                                    exchange={this.props.transaction['data'][this.props.transactionId]['exchange']} 
                                    coin={this.props.transaction['data'][this.props.transactionId]['base_currency']['name']}
                                    coin_ticker={this.props.selectedBase['coinTicker']}
                                    coin_id={this.props.transaction['data'][this.props.transactionId]['base_currency']['value']}
                                    user_first={this.props.user_first}
                                    user_last={this.props.user_last}
                                    type='base'
                                    transactionId={this.props.transactionId}
                                    simple={true}/>
                            </div>

                    { 
                                this.props.transaction['data'][this.props.transactionId]['quote_currency'] !== null && this.props.transaction['data'][this.props.transactionId]['quote_currency'] !== undefined ?
                                    

                            <div style={{marginTop: 12}}>
                                <WalletCreation 
                                    exchange={this.props.transaction['data'][this.props.transactionId]['exchange']} 
                                    coin={this.props.transaction['data'][this.props.transactionId]['quote_currency']['name']}
                                    coin_id={this.props.transaction['data'][this.props.transactionId]['quote_currency']['value']}
                                    coin_ticker={this.props.selectedQuote['coinTicker']}
                                    user_first={this.props.user_first}
                                    user_last={this.props.user_last}
                                    type='quote'
                                    transactionId={this.props.transactionId}                                    
                                    simple={true}/>
                            </div>

                            :

                            null

                    }
                           

                    </div>

                    :

                    null

                    }

                </div> 
                }     

            </div>

        )
    }

}

const mapStateToProps = state => {
    return {
        
    };
  };
  
  const mapDispatchToProps = dispatch => {
    return {
        updateTransaction: (data) =>
            dispatch(actions.updateTransaction(data)), 
        removeWallets: () =>
            dispatch(actions.removeWallets()), 
    };
  };
  
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(ExchangeQuestion);