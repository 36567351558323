import React from 'react';
import { connect } from 'react-redux';
import BackIcon from "../../../../../../Media/CloseIcon.svg";
import whiteLoader from '../../../../../../Media/whiteloader.gif';
import okIcon from '../../../../../../Media/okIcon.svg';
import MobileSwipeSelect from '../../../../../MobileOnly/MobileSwipeSelect'
import fieldValidator from '../../../../../../helpers/validators' 
import { backendUrl } from '../../../../../../variables'
import axios from 'axios';
import * as appwideActions from "../../../../../../store/actions/appwide";
import * as interActions from "../../../../../../store/actions/interactions";
import * as dataActions from "../../../../../../store/actions/data";
import {specificMessageRenderer} from '../../../../../../helpers/helpers';

class SmallApiConnect extends React.PureComponent {

    state = {
        loading: false, 
        error: null, 
        success: false, 
        type: 'exchange', 
        exchanges: [],
        wallets: [], 
        selectorOpen: false,
        selected: undefined,
        data: {
            scope: 'all'
        }, 
        validations: {
            public: true, 
            address: true, 
            private: true, 
            passphrase: true, 
            name: true,
            name_message: null,
        }
    }
    
    componentDidMount() {

        let normalizedExchange = []
        let normalizedWallet = []
        this.props.connectors.exchange.map(v => {
            if (v.ready === true) {
                normalizedExchange.push({value: v['exchange_id'], label: v['exchange_name'], image: v['image'], fields: v['fields']})
            }
        })

        this.props.connectors.storage.map(v => {
            if (v.ready === true) {
                normalizedWallet.push({value: v['coin_id'], label: v['coin_name'], image: v['image'], fields: v['fields']})
            }
        })
        
        this.setState(state => ({...state, exchanges: normalizedExchange, wallets: normalizedWallet}))

    }

    openSelector = () => {
        this.changeState('selectorOpen', true)
    }

    changeScope = (val) => {
        this.setState(state => ({...state, data: {...state.data, scope: val} }))
    }
    
    closeSelector = () => {
        this.changeState('selectorOpen', false)
    }

    handleSelectorSelect = (val) => {
        this.changeState('selected', val)
        this.closeSelector()
    }

    changeState = (label, value) => {
        this.setState(state => ({...state, [label]: value}))
    }

    changeType = (val) => {
        this.setState(state => ({...state, type: val, selected: undefined, data: {scope: 'all'}}))
    }

    addToData = (label, e) => {
        e.persist();
        this.setState(state => ({...state, data: {...state.data, [label]: e.target.value} }))
    }

    submit = () => {

        let valid = true
        this.state.selected.fields[0].map(v => {

            let tempCheck = true;
            tempCheck = fieldValidator(this.state.data[v[0]], ['isNotEmpty'])
            this.setState(state => ({
            ...state, 
            validations: {
                ...state.validations,
                [v[0]]: tempCheck}
            })
            )

            valid = valid && tempCheck
    

        })

        let name_val = true;
        let select_val = true;
        
        let lowercaseName = this.state.data.name
        try { lowercaseName = this.state.data.name.toLowerCase().trim() } catch(e) {}
        name_val = fieldValidator(this.state.data.name, ['isNotEmpty'])
        name_val = name_val && !(this.props.existingNames.includes(lowercaseName))
        const name_message = (this.props.existingNames.includes(lowercaseName)) && this.state.data.name !== '' && this.state.data.name !== undefined && this.state.data.name !== null ? 'You already have an account with this name. Please choose a different name' : null
            this.setState(state => ({
            ...state, 
            validations: {
                ...state.validations,
                name: name_val, 
                name_message: name_message}
            })
        )

        select_val = this.state.selected !== undefined && this.state.selected !== null
        this.setState(state => ({
            ...state, 
            validations: {
                ...state.validations,
                select: select_val, }
            })
        )

        if (valid && name_val && select_val) {

            this.setState(state => ({...state, loading: true}))

            let submitData = {
                ...this.state.data, 
                user_id: this.props.user_id, 

            }

            if ( submitData.scope === 'all' ) {
                submitData['portfolio'] = this.props.portfolioId
            }

            let headers = { 
                "content-type": "application/json",     
            };
            headers["Authorization"] = `Token ${this.props.token}`;

            
            let url = null 
            if (this.state.type === 'exchange') {
                url = `${backendUrl}/v1/connectors/setup/`
                submitData['exchange_id'] = this.state.selected.value
            } else if (this.state.type === 'wallet') {
                url = `${backendUrl}/v1/wallets/setup/`
                submitData['coin_id'] = this.state.selected.value
            }
            axios
            .post(url,
                submitData
            , { headers: headers } )
            .then(res => { 
                const accountId = res['data']['data']['id']
                const currentTasks = this.props.tasks
                let newTasks = [...currentTasks]
                if (this.state.type=== 'exchange') {
                    newTasks = [{description: `Exchange Connection`, sub: `${this.state.selected.exchange_name}`, status: 'pending', type: 'connection', task_id: accountId}, ...currentTasks]
                } else if (this.state.type === 'wallet') {
                    newTasks = [{description: `Wallet Connection`, sub: `${this.state.selected.coin_name}`, status: 'pending', type: 'connection', task_id: accountId}, ...currentTasks]
                }
                
                this.props.setInteraction({label: "tasks", value: newTasks})

                this.setState(state => ({...state, error: null, loading: false, success: true }))

                //this.connectForUpdate();
                this.props.getWallets(this.props.user_id, this.props.token);
                this.props.getUserLimits(this.props.user_id)
                //this.props.cancelAdd()


            })
            .catch(err => {
                let error = "Unable to connect at this time, please try again."
                console.log(err)
                try {
                    error = err.response['data']['data']
                } catch(e) {}

                this.setState(state => ({...state, error: error, loading: false }))

            })


        }


    }

    render () {

        return (

                this.state.success ? 
                    

                    <div>

                        <div style={{backgroundColor: '#F8F9FA', zIndex: 9999999, height: 75, width: '100%', display:'flex', alignItems: 'center', justifyContent: 'space-between', position: 'fixed', top: 0}} id="responsive-dialog-title">

                        </div>

                        <div style={{marginTop: 75, marginBottom: 12, backgroundColor: 'white', paddingTop: 0, width: '100%', display: 'flex', justifyContent: 'center'}}>
                                
                            <img style={{ height: 90, width: 90, marginTop: 90}} src={okIcon} alt=""/>
                            

                        </div>

                        
                            <React.Fragment>
                                <div className="basier" style={{textAlign: 'center', fontSize: 14}}>Succesfully added API connection. We are importing your transactions in the background and will notify you when it is done.</div>

                                <div style={{width: '100%', display: 'flex', justifyContent: 'center', marginTop: 24}} >
                                    <div
                                        disabled={this.state.step === 0}
                                        onClick={() => this.props.cancelAdd()}
                                        className='button-next'
                                        style={{height: 40, borderRadius: 35, marginLeft: 12, marginRight: 12, cursor: 'pointer' }}
                                        >
                                        Close
                                    </div>

                                    
                                </div>
                            </React.Fragment>

                        

                    </div>


                : 

                <div style={{display: 'flex', flexDirection: 'column', width: '100%'}}>

                    <div style={{backgroundColor: '#F8F9FA', zIndex: 9999999, height: 75, width: '100%', display:'flex', alignItems: 'center', justifyContent: 'space-between', position: 'fixed', top: 0}} id="responsive-dialog-title">

                        <div onClick={this.props.cancelAdd} style={{width: '25%'}}>
                            <img style={{paddingTop: 24}} src={BackIcon} alt=""/>
                        </div>

                        <div className="basier-p4-caps" style={{color: 'black', width: '50%', textAlign: 'center'}}>
                            API Connection
                        </div>

                        <div style={{width: '25%'}}>
                        </div>

                    </div>

                    <div style={{paddingTop: 90, flex: 1, width: '100%', backgroundColor: 'white', zIndex: 1500}}>
                        <div style={{backgroundColor: 'white'}}>
                            <div style={{ height: '100%', overflow: 'scroll', display: 'flex', alignItems: 'center', justifyContent: 'space-between',  flexWrap: 'wrap', marginLeft: 12}}>
                                            
                                    <div 
                                        className='basier-p4-caps touchableOpacity'
                                        onClick={() => this.changeType('exchange')}
                                        style={{
                                            marginBottom: 12, 
                                            fontSize: 20,
                                            fontWeight: 900, 
                                            padding: 20, 
                                            marginRight: 12,
                                            marginLeft: 0,
                                            minWidth: 80, 
                                            flex: 1,
                                            height: 40, 
                                            borderRadius: 10, 
                                            display: 'flex', 
                                            alignItems: 'center', 
                                            justifyContent: 'center', 
                                            backgroundColor: this.state.type === 'exchange' ? '#0078DB' : '#E1E5E9',
                                            color: 'white', 
                                            cursor: 'pointer',
                                            
                                            }}>

                                            Exchange
                                    </div>

                                    <div 
                                        className='basier-p4-caps touchableOpacity'
                                        onClick={() => this.changeType('wallet')}                                    
                                        style={{
                                            marginBottom: 12, 
                                            fontSize: 20,
                                            fontWeight: 900, 
                                            padding: 20, 
                                            marginRight: 12,
                                            marginLeft: 0,
                                            minWidth: 80, 
                                            flex: 1,
                                            height: 40, 
                                            borderRadius: 10,
                                            display: 'flex', 
                                            alignItems: 'center', 
                                            justifyContent: 'center', 
                                            backgroundColor: this.state.type === 'wallet' ? '#0078DB' : '#E1E5E9',
                                            color: 'white', 
                                            cursor: 'pointer',
                                            }}>

                                            Wallet
                                    </div>

                                </div>


                                <div style={{marginTop: 0}}>
                                    
                                    <div className="basier-p4-caps" style={{color: 'black', fontWeight: 900, paddingLeft: 12, marginTop: 12, marginBottom: 12}}>
                                        {this.state.type === 'exchange' ? 'Select Exchange' : 'Select Coin'}
                                    </div>

                                    <div 
                                        style={{
                                        height: 60,
                                        display: 'flex', 
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        paddingLeft: 24,
                                        borderBottom: '1px solid #E1E5E9',
                                        borderTop: '1px solid #E1E5E9',
                                        width: '100%',
                                        textTransform: 'uppercase',
                                        fontWeight: 900
                                    }}
                                        onClick={() => this.openSelector()}
                                        className="basier-p3">

                                        <div style={{marginRight: 24, display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%'}}>

                                            <div className="truncate" style={{marginRight: 24, color: '#6A7E93', flex: 1}}>
                                                
                                                {
                                                    this.state.selected !== undefined && this.state.selected !== null ? 
                                                    <div>

                                                        <div style={{display: 'flex', alignItems: 'center'}}>
                                                        
                                                                
                                                            <div>
                                                                <img style={{height: 30, width: 30, marginRight: 12, }} src={this.state.selected['image']} alt=""/>
                                                            </div>

                                                        
                                                            <div style={{color: 'black'}} className="basier-p3 truncate">{this.state.selected['label']}</div>
                                                        </div>
                                                    </div>
                                                    
                                                    :
                                                    <div style={{marginRight: 12}}>select...</div>
                                                }
                                            </div>
                                            
                                            <div style={{display: 'flex', alignItems: 'center', width: 15}}>
                                                <div>
                                                    >
                                                </div>
                                            </div>
                                            
                                        </div>

                                    </div>

                                </div>
                            
                                {this.state.selected !== undefined && 

                                <div style={{marginTop: 0}}>

                                    <div style={{marginTop: 12, display: 'flex', alignItems: 'center', justifyContent: 'space-between',  flexWrap: 'wrap', marginLeft: 12}}>
                                            
                                        <div 
                                            className='basier-p4 touchableOpacity'
                                            onClick={() => this.changeScope('all')}
                                            style={{
                                                marginBottom: 12, 
                                                fontSize: 20,
                                                fontWeight: 900, 
                                                padding: 6, 
                                                marginRight: 12,
                                                marginLeft: 0,
                                                minWidth: 80, 
                                                flex: 1,
                                                height: 40, 
                                                borderRadius: 10, 
                                                display: 'flex', 
                                                alignItems: 'center', 
                                                justifyContent: 'center', 
                                                backgroundColor: this.state.data.scope === 'all' ? '#0078DB' : '#E1E5E9',
                                                color: 'white', 
                                                cursor: 'pointer',
                                                textAlign: 'center',
                                                
                                                }}>

                                                Import to current portfolio
                                        </div>

                                        <div 
                                            className='basier-p4 touchableOpacity'
                                            onClick={() => this.changeScope('select')}                                    
                                            style={{
                                                marginBottom: 12, 
                                                fontSize: 20,
                                                fontWeight: 900, 
                                                padding: 6, 
                                                marginRight: 12,
                                                marginLeft: 0,
                                                minWidth: 80, 
                                                flex: 1,
                                                height: 40, 
                                                borderRadius: 10,
                                                display: 'flex', 
                                                alignItems: 'center', 
                                                justifyContent: 'center', 
                                                backgroundColor: this.state.data.scope === 'select' ? '#0078DB' : '#E1E5E9',
                                                color: 'white', 
                                                cursor: 'pointer',
                                                textAlign: 'center',
                                                }}>

                                                Select portfolio after import
                                        </div>

                                    </div>
                                    



                                    <div className="basier-p4-caps" style={{color: 'black', fontWeight: 900, paddingLeft: 12, marginTop: 12, marginBottom: 6}}>
                                        Account Name
                                    </div>
                                    
                                    <div style={{display: 'flex', height: 50, alignItems: 'center', paddingRight: 12, paddingLeft: 12}} className="basier-p3">
                                        <input autocomplete="off" autocorrect="off" spellcheck="false" onFocus={this.onFocus} value={this.state.data['name'] || ""} onChange={(e) => this.addToData('name', e)} style={{width: '100%', textAlign: 'left', height: 50, border: '1px solid #E1E5E9', paddingRight: 12, paddingLeft: 12, zIndex: 9001, backgroundColor: 'white'}} type="text" className={`no-outline ${this.state.validations['name'] ? '' : 'error-input'}`} />
                                    </div>
                                    <div className="basier-p4" style={{width: '100%', paddingLeft: 12, color: '#F9716B', marginTop: 2, fontWeight: 400}}>{!this.state.validations.name && this.state.validations.name_message}</div>
                                </div>}

                                <div style={{marginTop: 12}}>
                                    {this.state.selected !== undefined && 

                                        this.state.selected.fields[0].map((v, k) => {

                                            return(
                                                <div style={{marginTop: 12}} key={k}>
                                                    <div className="basier-p4-caps" style={{color: 'black', fontWeight: 900, paddingLeft: 12, marginTop: 12, marginBottom: 6}}>
                                                        {v[1]}
                                                    </div>
                                                    
                                                    <div style={{display: 'flex', height: 50, alignItems: 'center', paddingRight: 12, paddingLeft: 12}} className="basier-p3">
                                                        <input autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false" onFocus={this.onFocus} value={this.state.data[v[0]] || ""} onChange={(e) => this.addToData(v[0], e)} style={{width: '100%', textAlign: 'left', height: 50, border: '1px solid #E1E5E9', paddingRight: 12, paddingLeft: 12, zIndex: 9001, backgroundColor: 'white'}} type="text" className={`no-outline ${this.state.validations[v[0]] ? '' : 'error-input'}`} />
                                                    </div>
                                                </div>
                                            )

                                        })
                                        
                                    }
                                    
                                </div>
                                
                                {this.state.selected !== undefined &&
                                <div className="basier-p4" style={{marginTop: 24, paddingLeft: 12, paddingRight: 12, color: '#0078DB' }}>
                                    { specificMessageRenderer(this.state.selected.value) }
                                </div>
                                }

                                {this.state.error !== null && 
                                    <div className="basier-p4" style={{paddingLeft: 12, paddingRight: 12, color: '#F9716B', fontWeight: 900, width: '100%', display: 'flex', justifyContent: 'center', textAlign: 'center', marginTop: 24}}>{this.state.error}</div>
                                }

                                <div style={{width: '100%', display: 'flex', justifyContent: 'center', marginTop: 24, marginBottom: 72}}>
                                    {this.state.selected !== undefined && 
                                    <div className='button-blue touchableOpacity' onClick={this.state.loading ? null : () => this.submit()} style={{marginBottom: 72, height: 40, borderRadius: 35, marginLeft: 12, marginRight: 12, cursor: 'pointer'}}>
                                        {this.state.loading ? 

                                            <span>
                                                <img src={whiteLoader} alt="" style={{height: 20, width: 20, marginRight: 12}}/>
                                                <span>Connecting</span>
                                            </span>
                                        
                                        :

                                            <span>Submit</span>
                                        
                                        }
                                    </div>
                                    }
                                </div>

                                {this.state.type !== undefined && 
                                    <MobileSwipeSelect data={this.state.type === 'exchange' ? this.state.exchanges : this.state.wallets } openModal={this.state.selectorOpen} closeModal={this.closeSelector} handleSelect={(res) => this.handleSelectorSelect(res)}/>
                                }
                        </div>
                    </div>

                </div>
            
        )
    }


}

const mapStateToProps = state => {
    return {
        token: state.auth.token,
        user_id: state.auth.user_id,
        connectors: state.appwide.connectors,
        inBitcoin: state.data.bitcoinPf, 
        currency_id: state.auth.currency.id, 
        currentPortfolio: state.data.menu_selected, 
        tasks: state.interactions.tasks,
        currentView: state.data.currentView, 
        graphSettings: state.data.globalBalance,
        portfolioList: state.auth.list_portfolios, 
        existingNames: state.appwide.accountNames
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getImports: (u) => dispatch(dataActions.getImports(u)), 
        getWallets: (user, t) => dispatch(appwideActions.getWallets(user, t)), 
        getUserLimits: (user) => dispatch(appwideActions.getUserLimits(user)), 
        setInteraction: (data) => dispatch(interActions.setInteraction(data)), 
        toggleModal: (data) => 
            dispatch(interActions.toggleModal(data)), 
        componentDataFetch: (scope, view, currency, user, portfolio, globalGraphData, portfolioGraphData, inBitcoin) =>
            dispatch(dataActions.componentDataFetch(scope, view, currency, user, portfolio, globalGraphData, portfolioGraphData, inBitcoin)), 
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(SmallApiConnect)
