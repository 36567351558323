import React from 'react';
import Select, { components }  from 'react-select';
import OverviewIcon from '../../../Media/Icon-W-Blue.svg'
import { connect } from 'react-redux';
import { withRouter} from 'react-router-dom';
import * as actions from "../../../store/actions/data";
import * as interactionActions from "../../../store/actions/interactions";
import plusButton from '../../../Media/Plus.svg'
import portSelect from '../../../Media/PortSelect.svg'

const optionDisplay = (value) => {
    return (
      <div style={{'textAlign': 'left'}}>
         <span className="icon-container-menu">
              <img src={portSelect} alt=""/>
          </span>
          <span>{value}</span>
      </div>
     
    )
}

const overviewDiv = (
  <div style={{'textAlign': 'left'}}>
         <span className="icon-container-menu">
              <img src={OverviewIcon} alt=""/>
          </span>
          <span>Overview</span>
  </div>
)



let options = [];

const colourStyles = {
  control: styles => ({ ...styles, backgroundColor: 'white', dispay:'inline-block' }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {

    return {
      ...styles,
      borderRadius: data.value === 'adder' ? '0px 0px 24px 24px' : null,
      backgroundColor: isDisabled
        ? null
        : isSelected ? 'white' : isFocused ? '#F3F5F6' : data.value === 'adder' ? 'rgba(243, 245, 246, 0.7) ' : null,
      color: isDisabled
        ? 'black'
        : isSelected ? 'black' : 'black',
      opacity: isDisabled
        ? '1'
        : isSelected ? '1' : data.value === 'adder' ? '1' : '0.4',
    };
  },

};

const DropdownIndicator = (
  props: ElementConfig<typeof components.DropdownIndicator>
) => {
  return (
    <components.DropdownIndicator {...props}>
      <div style={{marginRight: '12px'}}>
        <svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M14.9136 1L7.95679 8L1 1" stroke="black" strokeWidth="1.5" strokeMiterlimit="10"/>
        </svg>
      </div>
    </components.DropdownIndicator>
  );
};

class MenuSelector extends React.Component {

  state = {
    selectedOption: {},
    options: []
  }

  styles = {
    'dropdown': {
        'borderRadius': 7,
        'backgroundColor': '#FFFFFF',
        'height': 49, 
        'width': 232,
        'display': 'inline-block',
        'textAlign': 'left',
        'lineHeight': '50px'

    },
  }

  componentDidMount() {

    options = [{id: "/", value: "/", label: overviewDiv}]

    this.props.portfolios.map( (portfolio) => {
      let values = {id: portfolio['id'], value: `/portfolio/${portfolio['id']}`, label: optionDisplay(portfolio['name'])};
      options.push(values)

    })

    const adder = {id: "noAction", value: 'adder', label: this.adderDisplay}
    options.push(adder);

    this.setState(state => ({...state, options: options}))

    const currentIndex = options.findIndex(p => p.id == this.props.selected_menu)
    this.props.setMenuPath(options[currentIndex]);

  }

  componentDidUpdate(prevProps, prevState) {
    
    const currentIndex = this.state.options.findIndex(p => p.id == this.props.selected_menu)
    
    if (currentIndex !== -1) {
      this.props.setMenuPath(this.state.options[currentIndex]);
      if (prevProps.menu_selected !== this.props.menu_selected) {
        this.setState(state => ({...state, selectedOption: currentIndex}))
      }
    } else {
      this.props.setMenuPath(null);
    }
  
    if (prevProps.portfolios !== this.props.portfolios) {

      let options = [{id: "/", value: "/", label: overviewDiv}]

      this.props.portfolios.map( (portfolio) => {
        let values = {id: portfolio['id'], value: `/portfolio/${portfolio['id']}`, label: optionDisplay(portfolio['name'])};
        options.push(values)

      })
      const adder = {id: "noAction", value: 'adder', label: this.adderDisplay}
      options.push(adder);
      this.setState(state => ({...state, options: options}))

      const currentIndex = options.findIndex(p => p.id == this.props.selected_menu)
      
      if (currentIndex !== -1) {
        this.props.setMenuPath(options[currentIndex]);
        if (prevProps.menu_selected !== this.props.menu_selected) {
          this.setState(state => ({...state, selectedOption: currentIndex}))
        }
      } else {
        this.props.setMenuPath(null);
      }
      
    }

  }

    


    

  openModal = () => {

      this.props.toggleModal({type: 'new_portfolio', open: true, payload: {} })

  }

  adderDisplay = (
      <div style={{'textAlign': 'left'}} onClick={this.openModal}>
        <span className="" style={{marginRight: 12}}>
            <img src={plusButton} alt=""/>
        </span>
        <span className="basier-p4-caps">add a new portfolio</span>
      </div>
    )

  handleChange = (selectedOption) => {

    if (selectedOption['id']!== "noAction") {

      this.props.setMenuPortfolio(selectedOption['id'])
      this.props.setMenuPath(selectedOption);
      this.props.history.push(selectedOption['value'])

      if (selectedOption['id']!== "/") {
          
          const new_portfolio = selectedOption['id']

          const graphDataRequest = {
            period: this.props.graphData.period,
            start: this.props.graphData.start,
            end: this.props.graphData.end, 
          }

          const portfolioGraphRequest = {
            period: this.props.portfolioGraphData.period,
            start: this.props.portfolioGraphData.start,
            end: this.props.portfolioGraphData.end, 
          }

          this.props.componentDataFetch('get', 2, this.props.currency_used, this.props.user, new_portfolio, graphDataRequest, portfolioGraphRequest, this.props.inBitcoin)

        }
    }

  }

  render() {

    return (
      <Select
        value={this.props.selectedOption}
        onChange={this.handleChange}
        options={this.state.options}
        isSearchable={false}
        styles={colourStyles}
        className='react-select-container'
        classNamePrefix="react-select"
        components={{ DropdownIndicator }}
        theme={(theme) => ({
          ...theme,
          borderRadius: '0px 0px 24px 24px',
          paddingBottom: 0,
          marginBottom:0,
          colors: {
          ...theme.colors,
            primary25: '#F3F5F6',
            primary: '#F3F5F6',
          },
        })}
      />
    );
  }
}


const mapStateToProps = state => {
  return {
      portfolios: state.auth.list_portfolios,
      selected_menu: state.data.menu_selected, 
      selectedOption: state.data.selectedMenuOption, 
      currency_used: state.auth.currency.id, 
      graphData: state.data.globalBalance,
      portfolioGraphData: state.data.portfolioBalance,
      user: state.auth.user_id,
      portfolio: state.auth.default_portfolio_id,
      currentView: state.data.currentView, 
      inBitcoin: state.data.bitcoinPf
  };
};

const mapDispatchToProps = dispatch => {
  return {
      setMenuPortfolio: (portfolio) =>
          dispatch(actions.setMenuPortfolio(portfolio)), 
      setMenuPath: (path) =>
          dispatch(actions.setMenuPath(path)),
      componentDataFetch: (scope, view, currency, user, portfolio, globalGraphData, portfolioGraphData, inBitcoin) =>
          dispatch(actions.componentDataFetch(scope, view, currency, user, portfolio, globalGraphData, portfolioGraphData, inBitcoin)), 
      toggleModal: (data) => 
            dispatch(interactionActions.toggleModal(data)), 
      
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MenuSelector));
