import React from 'react';
import { connect } from 'react-redux';
import Plus from '../../../../Media/Plus.svg'
import ReportTable from './ReportComponents/ReportTable'
import ReportPresentation from './ReportComponents/ReportPresentation'
import AnalysisCard from './ReportComponents/AnalysisList'
import BackIcon from '../../../../Media/BackIcon.svg'

class AnalysisOverview extends React.PureComponent {

    state = {
        filter: null, 
        analysisList: null,
        filterAnalysis: null, 
        new: false
    }

    toggleNew = () => {
        this.setState(state => ({...state, new: !state.new}))
    }

    initAnalysisList = () => {

        const analysisList = [
            {id: 'balanceDate', name: 'Balance at Date', description: 'Generate your portfolio value and balances at any date, in any currency. Exportable into CSV.'}, 
            {id: 'correlationMatrix', name: 'Correlation Matrix', description: 'Analyse how different cryptocurrencies and correlated with an easy to read matrix.'}, 
            //{id: 'periodSummary', name: 'Period Review', description: 'Generate a PDF report on your P&L, transactions and activity between two dates. Useful for year-end reporting.'}, 
        ]

        this.setState(state => ({...state, analysisList: analysisList, filterAnalysis: analysisList}))

    }

    componentDidMount = () => {
        this.initAnalysisList()
    }

    selectReport = (id) => {
        this.props.handleSelect(id)
    }

    changeFilter = (e) => {
        e.persist();
        const newVal = e.target.value
        this.setState(state => ({...state, filter: newVal}))

        if (newVal !== '' && newVal !== undefined && newVal !== null) {
            const newList = this.state.analysisList.filter(v => v.name.toLowerCase().includes(newVal.toLowerCase()))
            this.setState(state => ({...state, filterAnalysis: newList}))
        } else {
            this.setState(state => ({...state, filterAnalysis: this.state.analysisList}))
        }

    }

    renderReport = (data) => {

        return (
            <div 
                onClick={() => this.selectReport(data.id)}
                className="hvr-grow-shadow"
                style={{
                    width: 300, 
                    height: 200,
                    boxShadow: '0px 10px 10px rgba(225, 229, 233, 0.3)',
                    marginLeft: 12, marginRight: 12, 
                    border: '1px solid #E1E5E9', 
                    borderRadius: 10, 
                    padding: 24, 
                    cursor: 'pointer',
                }}>

                <h5 style={{margin: 0}}>
                    {data.name}
                </h5>
                <div className="basier-p3" style={{marginTop: 12}}>
                    {data.description}
                </div>

            </div>
        )

    }

    render () {

        
        return (

            <div>

                <div style={{display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between', width: '100%'}}>

                    <div style={{flex: 1, width: '50%', marginRight: 12}}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <h3 style={{margin: 0}}>Reports</h3>
                            <span onClick={() => this.toggleNew()} className="basier-p4-caps" style={{userSelect: 'none', color: 'black', marginLeft: 24, cursor: 'pointer'}}>
                                <span><img src={this.state.new ? BackIcon : Plus} alt="" style={{marginRight: 12, height: 24}}/></span>
                                <span>{this.state.new ?  'Back': 'Generate new'}</span>
                            </span> 
                        </div>

                        <div style={{marginTop: 24, paddingRight: 48, paddingLeft: 0}}>
                            {this.props.reports !== null && this.props.reports !== undefined ? 
                            this.props.reports.length > 0 ? 
                                this.state.new === false ?
                                    <ReportTable handleReportSwitch={this.props.handleReportSwitch} renderAnalysisName={this.props.reportRender} data={this.props.reports} />
                                :
                                    <ReportPresentation renderType={this.props.renderType} reportRenderer={this.props.reportRender} noTitle={true} />    
                            :
                            <ReportPresentation renderType={this.props.renderType} reportRenderer={this.props.reportRender} />
                            :
                            null}
                            
                        </div>
                        
                    </div>

                    <div style={{flex: 1, width: '50%', marginLeft: 24}}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <h3 style={{margin: 0}}>Analysis</h3> 
                        </div>

                        <div style={{marginTop: 24}}>

                            <AnalysisCard renderType={this.props.renderType} analysisRender={this.props.analysisRender} />
                            
                        </div>
                        
                    </div>

                </div>
                
                
                



            </div>
           
        )
    }


}

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = dispatch => {
    return {
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(AnalysisOverview)
