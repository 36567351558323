import React from 'react';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import * as actions from "../../../../../store/actions/auth";
//import loadingSpinner from '../../Media/loadingSpinner.gif'
/* import HighchartsReact from 'highcharts-react-official'*/
/* import HighchartsReact from 'highcharts-react-official'*/
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
//import currencyData from '../../helpers/currency.json'


const styles = {
    root: {
      color: '#6A7E93',
      '&$checked': {
        color: '#0078DB',
      },
    },
    checked: {
        color: '#0078DB'
    },
  };

const BalanceGraph = (props) => {


    const { classes } = props;

    const options = {
        chart: {
            type: 'pie',
        },

        credits: false, 

        title: false,
        xAxis: {
            categories: false, 
            minorGridLineWidth: 0, 
            gridLineWidth: 0,
        },
        yAxis: {
            title: {
                text: false,
            }, 
            minorGridLineWidth: 0, 
            gridLineWidth: 0,
        },
        series: [{
            name: '',
            data: props.values,
            innerSize: '50%',
            showInLegend: true, 
            label: false, 
            dataLabels: false,
            minSize: 300,
            size: 300,
            colors: ['#0078DB', '#4ca0e5', '#A2DEFD', '#b2d6f4', '#CCEDFE', '#6A7E93','#E1E5E9'],
        }], 

        legend: {
            floating: false,
            align: 'right',
            verticalAlign: 'top',
            layout: 'vertical',
            symbolPadding: 0,
            y: 20,
            symbolWidth: 0.1,
            symbolHeight: 0.1,
            symbolRadius: 20,
            useHTML: true,
            labelFormatter: function() {
                
                let range = null;

                switch(this.name) {
                    case 'Bitcoin': {
                        range = ""
                        break;
                    }
                    case 'High Cap': {
                        range = "| $500M+"
                        break;
                    }
                    case 'Mid Cap': {
                        range = " | $50M - $500M"
                        break;
                    }
                    case 'Low Cap': {
                        range = " | $10M - $50M"
                        break;
                    }
                    case 'Micro Cap': {
                        range = " | $10M-"
                        break;
                    }
                    case 'Stable Coin': {
                        range = "";
                        break;
                    }
                    case 'Fiat': {
                        range = "";
                        break;
                    }
                    default: {

                    }
                    
                }
                return (
                    `
                        <div class="basier" style="text-transform: uppercase; display: flex; align-items: center;">
                            <div style="display: 'inline-block'; width: 20px; height: 20px; margin-left: 12px; margin-right: 12px; background-color: ${this.color};">
                        </div> 
                        <span style="font-weight: 900;">${this.percentage.toLocaleString('en', {minimumFractionDigits: 2, maximumFractionDigits: 2})}%</span> <span style="font-weight: 200;">&nbsp;${this.name} ${range}</span> 
                        `
                    )
          
            },
            itemStyle: {
              fontSize: '14px', 
              paddingBottom: '28px',
            }
          },

        tooltip: {
        useHTML: true,
        formatter: function () {
            return '<b>' + this.point.name +
                '</b> : ' + parseFloat(this.y.toFixed(2)).toLocaleString('en');
                }
        }

    }


        return (

            <div>
                
                <Grid container 
                    spacing={0}>

                    <div style={{width: '100%', marginLeft: 0, height: 250, paddingRight: 0, position: 'relative'}}>
                        
                    
                        <HighchartsReact
                            highcharts={Highcharts}
                            containerProps={{ style: { height: "400px", width: "600px"} }}
                            options={options}
                            oneToOne={true}
                        />

                    </div>
                    

                </Grid>

                </div>
            
            
        )


        
};


BalanceGraph.propTypes = {
    classes: PropTypes.object.isRequired,
  };
  

const mapStateToProps = state => {
    return {
        currency_symbol: state.auth.currency.symbol,
        currency_name: state.auth.currency.id,
        currentView: state.data.currentView,
        inBitcoin: state.data.bitcoinPf

    };
};
const mapDispatchToProps = dispatch => {
    return {
        setBtcSymbol: (s) =>
            dispatch(actions.setBtcSymbol(s)), 
        
    };
  };

export default connect(
    mapStateToProps,
    mapDispatchToProps
)( withStyles(styles)(BalanceGraph));

