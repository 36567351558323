import React from 'react';
import Select, { components }  from 'react-select';
import { connect } from 'react-redux';
import * as actions from '../../../../../store/actions/data';
import * as interactionActions from '../../../../../store/actions/interactions';

const ValueContainer = ({ children, ...props }) => {
  return (
      <components.ValueContainer {...props}>
        <div className="basier-p3">
          {children}
        </div>
      </components.ValueContainer>
  )
};

class MenuSelector extends React.Component {

  state = {
    selectedOption: this.props.initator ? this.props.initator : {value: this.props.user_id, label: `${this.props.first_name} ${this.props.last_name}`},
    users: undefined
  }

  colourStyles = {
    control: styles => ({ ...styles, backgroundColor: 'transparent', width: this.props.width === undefined ? '250px' : this.props.width, height: this.props.height !== undefined ? this.props.height : 42, fontSize: 16, color: 'black', cursor: 'text', dispay:'inline-block'}),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
  
      return {
        ...styles,
        backgroundColor: isDisabled
          ? null
          : isSelected ? 'white' : isFocused ? '#F3F5F6' : null,
        color: isDisabled
          ? '#F3F5F6'
          : isSelected ? 'black' : 'black',
        opacity: isDisabled
          ? '1'
          : isSelected ? '1' : '0.4',
      };
    },
  
  };

  componentDidMount() {


    if (this.props.prov === true) {

      this.setState(state => ({...state, users: this.props.userProvided})) 

    } else {

      if (this.props.portfolioId !== undefined) {
        this.props.callUserList(this.props.portfolioId)
      }
      this.props.handleChange(this.props.user_id)

      if (this.props.userPass !== null && this.props.userPass !== undefined && this.props.users !== undefined && this.props.users !== null) {
        const selected = this.props.users.filter(v => v.value === this.props.userPass)
        if (selected.length > 0) {
          this.handleChange(selected[0])
        }

      }

    }

  }

  componentDidUpdate(prevProps) {

    if (this.props.userProvided !== null && this.props.userProvided !== undefined && this.props.userProvided !== prevProps.userProvided) {

      this.setState(state => ({...state, users: this.props.userProvided})) 
    }

    if (this.props.userPass !== null && this.props.userPass !== undefined && this.props.users !== undefined && this.props.users !== null && this.props.userPass !== prevProps.userPass && this.props.users !== prevProps.users) {

      const selected = this.props.users.filter(v => v.value === this.props.userPass)

      if (selected.length > 0) {
        this.handleChange(selected[0])
      }

    }

    if (prevProps.portfolioId !== this.props.portfolioId && this.props.portfolioId !== undefined) {
        this.props.callUserList(this.props.portfolioId)
    }


  }


  handleChange = (selectedOption) => {
    
    if (selectedOption === null) {
      this.setState({ selectedOption });
      if (this.props.propagate) {
        this.props.handleChange(selectedOption)
      }

    }
    else if (selectedOption !== this.state.selectedOption) {
      this.setState({ selectedOption });

      this.props.handleChange(selectedOption.value)



    }
    
  }

  onFocus = () => {

    if (this.props.propagate === true) {
      this.handleChange(null)
    }
    

  }

  getFocusedOption() {
    let selectedData = undefined;
    try { selectedData = this.myInput.select.state.focusedOption } catch(e) {}

    return selectedData
   }

  onKeyDown = (e) => {

    if (this.props.propagateKey === true) {

      if (e.key === 'Tab' || e.key === 'Enter' || e.keyCode === 13 || e.keyCode === 9) {
      e.preventDefault();
      const isShift = !!e.shiftKey
      if (isShift) {
        this.props.backToPrevious()
      } else {
        this.props.goToNext()
        const data = this.getFocusedOption()
        if (data !== undefined) {
          this.handleChange(data)
        }
        
      }
    }
  }
}
  

  render() {
    const { selectedOption } = this.state;

    return (
      <Select
        value={selectedOption}
        onChange={this.handleChange}
        options={this.state.users || this.props.users}
        isSearchable={true}
        styles={this.colourStyles}
        maxMenuHeight={210}
        minMenuHeight={50}
        ref={(ip)=> this.myInput = ip}
        onFocus={this.onFocus}
        onKeyDown={this.onKeyDown}
        components={{ ValueContainer  }}
        onMenuOpen={this.onFocus}
        classNamePrefix="react-select-user"
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
          ...theme.colors,
            primary25: '#F3F5F6',
            primary: '#F3F5F6',
          },
        })}
      />
    );
  }
}

const mapStateToProps = state => {
  return {

      users: state.data.scopedUsers,
      currentPortfolio: state.data.menu_selected, 
      user_id: state.auth.user_id,
      first_name: state.auth.user_first_name,
      last_name: state.auth.user_last_name,

  };
};

const mapDispatchToProps = dispatch => {
  return {
    callUserList: (portfolio) =>
          dispatch(actions.callUserList(portfolio)), 
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null, {forwardRef: true}
)(MenuSelector);
