import React from 'react';
import { connect } from 'react-redux';
import Selectlist from './Selectlist';

class DestinationTypeQuestion extends React.PureComponent {

    changeOrigin = (v) => {
        this.props.updateState(this.props.label, v)
        this.props.postSelect()
    }

    render () {

        return (

            <div>
                <div className="basier-p4-caps" style={{marginBottom: 2, color: 'black', fontWeight: 900, display: 'flex', alignItems: 'center'}}>
                    {this.props.title}
                </div>

                <Selectlist selection={this.props.selected} changeOrigin={(v) => this.changeOrigin(v)} height={40} innerRef={this.props.innerRef} options={this.props.options} />

            </div>
            
        )
    }


}

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = dispatch => {
    return {
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(DestinationTypeQuestion)
