import React from "react";
import { connect } from "react-redux";
import currencyDict from "../../../helpers/currency.json";
import {
  siwtchTransactionType,
  loadData,
  dateParser,
} from "../../../helpers/helpers";
import Tooltip from "../../AppWide/Tooltip";
import questionMark from "../../../Media/questionMark.svg";
import loader from "../../../Media/contentLoader.svg";
import eyeIcon from "../../../Media/eyeIcon.svg";
import MenuPopper from "../../AppWide/MenuPopper";
import * as actions from "../../../store/actions/data";
import * as interActions from "../../../store/actions/interactions";
import { backendUrl } from "../../../variables";
import axios from "axios";
import ImportPf from "./ImportPf";
import DeletedLine from "./Deleted";
import binSvg from "../../../Media/bin.svg";
import checkmark from "../../../Media/hollowCheck.svg";

const allTxTypes = [
  "deposit",
  "mining",
  "fork",
  "airdrop",
  "dividend",
  "income",
  "gift",
  "withdraw",
  "purchase",
  "donation",
  "lost",
  "fee",
];

const getOptions = (type) => {
  if (
    [
      "deposit",
      "mining",
      "fork",
      "airdrop",
      "dividend",
      "income",
      "gift",
    ].includes(type)
  ) {
    return [
      "deposit",
      "mining",
      "fork",
      "airdrop",
      "dividend",
      "income",
      "gift",
    ].sort();
  } else if (
    ["withdraw", "purchase", "donation", "lost", "fee"].includes(type)
  ) {
    return ["withdraw", "purchase", "donation", "lost", "fee"].sort();
  } else {
    return [
      "deposit",
      "mining",
      "fork",
      "airdrop",
      "dividend",
      "income",
      "gift",
      "withdraw",
      "purchase",
      "donation",
      "lost",
      "fee",
    ];
  }
};

class TransactionTableRow extends React.Component {
  state = {
    loading: false,
    update: false,
  };

  toggleModalTransaction = (data) => {
    //props.toggleTransactionAddModal({open: true});
    this.props.toggleModal({
      type: "view_related_positions",
      open: true,
      payload: {
        data: data,
      },
    });
  };

  updateServer = (method, lineId, data, scopeUpdate, entry_type) =>
    new Promise((resolve, reject) => {
      let headers = { "content-type": "application/json" };
      if (this.props.token !== null && this.props.token !== undefined) {
        headers["Authorization"] = `Token ${this.props.token}`;
      }

      let url_call = `${backendUrl}/v1/connectors/positions/${lineId}/`;
      let sendData = undefined;
      if (method === "patch" || method === "post") {
        sendData = {
          ...data,
          user_id: this.props.user_id,
        };
      } else {
        url_call = `${backendUrl}/v1/connectors/positions/${lineId}/?u=${this.props.user_id}`;
      }

      axios({ method: method, url: url_call, data: sendData, headers: headers })
        .then((json) => {
          let newImportedTr = [];
          if (method === "patch" || method === "post") {
            if (scopeUpdate === "clear") {
              this.props.transactions.transactions.map((v) => {
                if (v.id == lineId) {
                  v.related_transaction = null;
                  v.portfolio = null;
                  v.portfolio_name = null;
                  newImportedTr.push(v);
                } else {
                  newImportedTr.push(v);
                }
              });
              this.props.updateImports({
                data: {
                  transactions: newImportedTr,
                },
              });
              this.setState((state) => ({ ...state, update: !state.update }));
              resolve(true);
              return;
            } else if (scopeUpdate === "include") {
              this.props.transactions.transactions.map((v) => {
                if (v.id == lineId) {
                  v.include = true;
                  newImportedTr.push(v);
                } else {
                  newImportedTr.push(v);
                }
              });
              this.props.updateImports({
                data: {
                  transactions: newImportedTr,
                },
              });
              resolve(true);
              return;
            } else if (scopeUpdate === "validate") {
              this.props.transactions.transactions.map((v) => {
                if (v.id == lineId) {
                  v.reviewed = true;
                  newImportedTr.push(v);
                } else {
                  newImportedTr.push(v);
                }
              });
              this.props.updateImports({
                data: {
                  transactions: newImportedTr,
                  tx_review: Math.max(0, this.props.transactions.tx_review - 1),
                },
              });
              resolve(true);
              return;
            } else if (scopeUpdate === "portfolio") {
              this.props.transactions.transactions.map((v) => {
                if (v.id == lineId) {
                  try {
                    newImportedTr.push(json["data"]["data"][0]);
                  } catch (e) {
                    newImportedTr.push(v);
                  }
                } else {
                  newImportedTr.push(v);
                }
              });
              this.props.updateImports({
                data: {
                  transactions: newImportedTr,
                },
              });
              resolve(true);
              return;
            }
          } else if (method === "delete") {
            this.props.transactions.transactions.map((v) => {
              if (v.id == lineId) {
                v.include = false;
                v.related_transaction = null;
                v.portfolio = null;
                v.portfolio_name = null;
                newImportedTr.push(v);
              } else {
                newImportedTr.push(v);
              }
            });

            this.props.updateImports({
              data: {
                transactions: newImportedTr,
              },
            });
            resolve(true);
          }

          //this.props.getImports(this.props.user_id);
        })
        .catch((e) => {
          console.log(e);
          reject(false);
        });
    });

  restoreLine = () => {
    this.setState((state) => ({ ...state, loading: true }));
    this.updateServer(
      "patch",
      this.props.data.id,
      { restore: true },
      "include",
      this.props.data.entry_type
    )
      .then((res) => {
        this.setState((state) => ({
          ...state,
          update: !state.update,
          loading: false,
        }));
      })
      .catch((e) => console.log(e));
  };

  validateLine = () => {
    this.updateServer(
      "patch",
      this.props.data.id,
      { validate: true },
      "validate",
      this.props.data.entry_type
    )
      .then((res) => {
        this.setState((state) => ({
          ...state,
          update: !state.update,
        }));
      })
      .catch((e) => console.log(e));
  };

  deleteLine = () => {
    this.setState((state) => ({ ...state, loading: true }));
    this.updateServer(
      "delete",
      this.props.data.id,
      this.props.data,
      null,
      null,
      this.props.data.entry_type
    ).then((res) => {
      this.setState((state) => ({
        ...state,
        update: !state.update,
        loading: false,
      }));
    });
  };

  render() {
    const { data } = this.props;
    const language = navigator.language || "en";
    const optionsDate = { year: "numeric", month: "numeric", day: "numeric" };
    const optionsTime = { hour: "2-digit", minute: "2-digit" };
    const parseDate = new Date(data.date);
    const showDate = parseDate.toLocaleDateString(language, optionsDate);
    const showTime = parseDate.toLocaleTimeString(language, optionsTime);

    let menuItems = [];
    try {
      switch (true) {
        case data.transfer_match !== null: {
          menuItems = [
            ["Unmatch", this.unmatch],
            ["Delete", this.deleteItem],
          ];
          break;
        }
        case data.transfer_match === null &&
          allTxTypes.includes(data.transaction_description): {
          menuItems = [
            [
              "Convert to",
              (t) => this.convertLine(t),
              getOptions(data.transaction_description),
            ],
            ["Edit", () => this.viewEdit(data)],
            ["Delete", this.delete],
          ];
          break;
        }
        default: {
          menuItems = [
            ["Edit", () => this.viewEdit(data)],
            ["Delete", this.delete],
          ];
          break;
        }
      }
    } catch (e) {}

    const isSelected =
      this.props.fullSelect === true
        ? this.props.excluded[data.id] === true
          ? false
          : true
        : this.props.selected[data.id] === true
        ? true
        : false;
    return (
      <div style={{ borderBottom: "1px solid #CCC", height: 80 }}>
        <div
          className="basier"
          style={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            height: 80,
            opacity: this.state.loading ? 0.2 : 1,
          }}
        >
          <div
            style={{
              minWidth: 50,
              maxWidth: 50,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <span style={{ height: 24, padding: 4 }}>
              <input
                type="checkbox"
                className={
                  data.include ? "regular-checkbox" : "disabled-checkbox"
                }
                onChange={() => this.props.selectSingle(data)}
                checked={isSelected}
                disabled={!data.include}
              />
            </span>
          </div>

          <div
            style={{ width: 250, opacity: data.include ? 1 : 0.2 }}
            className="truncate"
          >
            {this.state.loading && (
              <div
                style={{
                  height: 50,
                  width: 250,
                  flex: 1,
                  backgroundColor: "rgba(255,255,255,1)",
                  position: "absolute",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img src={loader} style={{ height: 50 }} alt="loading" />
              </div>
            )}

            <div style={{ display: "flex", flexDirection: "column" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "fit-content",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  {data.exchange_image !== "" &&
                  data.exchange_image !== null ? (
                    <span>
                      <img
                        style={{ height: 24, width: 24, marginRight: 12 }}
                        src={data.exchange_image}
                        alt=""
                      />
                    </span>
                  ) : (
                    <div style={{ height: 24, width: 24 }} />
                  )}
                  <div style={{ width: 250 - 36 }}>
                    <div
                      className="basier-p3 truncate"
                      style={{ fontWeight: 400, color: "black" }}
                    >
                      {data.account_name}
                    </div>
                    <div className="basier-p4-caps">
                      {showDate} | {showTime}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              minWidth: 200,
              flex: 1,
              paddingLeft: 6,
              paddingRight: 6,
              opacity: data.include ? 1 : 0.2,
            }}
          >
            <div
              className="basier-p3"
              style={{
                textTransform: "uppercase",
                color: siwtchTransactionType(data.transaction_description)[1],
                fontWeight: 900,
                marginBottom: 6,
              }}
            >
              {siwtchTransactionType(data.transaction_description)[0]}
            </div>
            <div style={{ display: "flex" }}>
              <div>
                {data.base_img !== "" && data.base_img !== null ? (
                  <span>
                    <img
                      style={{ height: 24, width: 24, marginRight: 12 }}
                      src={data.base_img}
                      alt=""
                    />
                  </span>
                ) : (
                  <span
                    style={{
                      height: 40,
                      width: 40,
                      border: "2px solid black",
                      color: "black",
                      borderRadius: "100%",
                      marginRight: 12,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      fontSize: `${
                        currencyDict[data.base_ticker] === undefined
                          ? "16px"
                          : currencyDict[data.base_ticker]["symbol"].length > 1
                          ? "16px"
                          : "22px"
                      }`,
                    }}
                  >
                    {currencyDict[data.base_ticker] === undefined
                      ? data.base_ticker
                      : currencyDict[data.base_ticker]["symbol"].length > 1
                      ? currencyDict[data.base_ticker]["symbol"]
                      : currencyDict[data.base_ticker]["symbol"]}
                  </span>
                )}
              </div>
              <div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div
                    className="basier-p3"
                    style={{ fontWeight: 400, color: "black" }}
                  >
                    {data.base_ticker}
                  </div>
                  <div style={{ marginLeft: 6, marginRight: 6 }}>/</div>
                  <div
                    className="basier-p3"
                    style={{ fontWeight: 400, color: "black" }}
                  >
                    {data.quote_ticker}
                  </div>
                </div>
                <div
                  className="basier-p4-caps"
                  style={{ fontWeight: 400, color: "#6A7E93" }}
                >
                  {data.text_name}
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              flex: 1,
              textAlign: "left",
              paddingLeft: 6,
              paddingRight: 6,
              minWidth: 120,
              opacity: data.include ? 1 : 0.2,
            }}
          >
            <div
              style={{ textTransform: "uppercase", color: "black" }}
              className="basier-p3"
            >
              {data.positionNumber} entries
            </div>
            <div
              onClick={() => this.toggleModalTransaction(data)}
              style={{
                textTransform: "uppercase",
                color: "#0078DB",
                cursor: "pointer",
              }}
              className="basier-p4-caps opacity-hover"
            >
              <div>View entries</div>
            </div>
          </div>

          <div
            style={{
              minWidth: 250,
              maxWidth: 250,
              flex: 1,
              paddingLeft: 4,
              paddingRight: 4,
            }}
          >
            <div>
              {data.include === false ? (
                <DeletedLine restoreLine={this.restoreLine} data={data} />
              ) : (
                <ImportPf
                  data={data}
                  updateServer={this.updateServer}
                  update={this.state.update}
                />
              )}
            </div>
          </div>

          <div
            className="basier-p4-caps"
            style={{
              minWidth: 150,
              maxWidth: 150,
              flex: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              paddingRight: 24,
            }}
          >
            {data.include === true && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                }}
              >
                {!data.reviewed && (
                  <div
                    className="opacity-hover"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => this.validateLine()}
                  >
                    <img
                      src={checkmark}
                      alt=""
                      style={{ width: 25, paddingLeft: 0 }}
                    />
                    <div style={{ color: "#4CDAC1" }}>Approve</div>
                  </div>
                )}
                <div
                  className="opacity-hover"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => this.deleteLine()}
                >
                  <img
                    src={binSvg}
                    alt=""
                    style={{ width: 25, paddingLeft: 0 }}
                  />
                  <div>Delete</div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    portfolios: state.auth.list_portfolios,
    user_id: state.auth.user_id,
    token: state.auth.token,
    transactions: state.data.importedTransactions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateImports: (data) => dispatch(actions.updateImports(data)),
    getImports: (user) => dispatch(actions.getImports(user)),
    toggleModal: (data) => dispatch(interActions.toggleModal(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TransactionTableRow);
