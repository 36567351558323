import React from 'react';
import { connect } from 'react-redux';
import CoinSelector from '../../../AppWide/CoinSelector';

var _ = require('lodash/array');

class PendingCoins extends React.PureComponent {


    state = {
        new_coins: {},
        currentPage: 0,
        coinPerPage: 5, 
        maxPage: null,
        options: null
    }

    updateServer = () => {
        this.props.updateServer('check_coins', this.state.new_coins)
    }

    nextPage = () => {
        const calc = this.state.currentPage + 1
        const new_index = Math.min(this.state.maxPage - 1, calc)
        this.setState(state => ({...state, currentPage: new_index}))

    }

    previousPage = () => {
        const calc = this.state.currentPage - 1
        const new_index = Math.max(0, calc)
        this.setState(state => ({...state, currentPage: new_index}))

    }

    componentDidMount() {
        const chunks = _.chunk(this.props.data['data']['scope'], this.state.coinPerPage)
        const max_page = Math.ceil(this.props.data['data']['scope'].length / this.state.coinPerPage)
        this.setState(state => ({...state, options: chunks, maxPage: max_page}))
    }

    componentDidUpdate(prevProps) {
        if (prevProps.data.options !== this.props.option) {
            const chunks = _.chunk(this.props.data['data']['scope'], this.state.coinPerPage)
            const max_page = Math.ceil(this.props.data['data']['scope'].length / this.state.coinPerPage)

            this.setState(state => ({...state, options: chunks, maxPage: max_page}))
        }
    } 
 
    handleSelect = (old, new_ticker) => {

        this.setState(state => ({...state, new_coins: {...state.new_coins, [old]: new_ticker}}))

    }

    handleSubmit = () => {
        this.updateServer()
    }


    render () {

        return (
            
            <div>
                <div style={{width: '100%', textAlign: 'center', }}>
                    <h5 style={{color: '#C174FD', fontWeight: 900}}>
                        Action required
                    </h5>
                
                    {this.props.data['type'] === 'confirm_coins' ? 

                        <div className="basier-p4">
                            Some entries in your file have tickers that can refer to multiple cryptocurrencies. You can confirm or select the correct coin below, or use <a href="https://wisly-resources.s3-eu-west-1.amazonaws.com/public/coin_export_wisly.csv">this file</a> to change the tickers on a line by line basis.
                        </div>
                    
                    :
                    
                    null // OTHER TYPES HERE
                    
                    }
                </div>

                <div style={{marginTop: 8, marginBottom: 0, paddingRight: 12, paddingLeft: 12, marginRight: 12, height: 250 }}>
                    

                    {
                        this.props.data['data']['scope'] !== null && this.props.data['data']['scope'] !== undefined && this.state.options !== null && this.state.options !== undefined ?

                        this.state.options[this.state.currentPage].map ((val, key) => {
                            return (
                            <div key={key} style={{marginTop: 12, display: 'flex', alignItems: 'center'}}>
                                <div style={{marginRight: 6, width: 40}}>{val[0]['symbol']}</div>
                                <div>
                                    <CoinSelector 
                                        selected={val[0]}
                                        options={val[1]} 
                                        handleSelect={(new_ticker) => this.handleSelect(val[0]['symbol'], new_ticker)}
                                        /> 
                                </div>
                            </div>
                            )

                        })  

                        :

                        null

                    }


                </div>

                <div style={{width: '100%', marginTop: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', height: 50}}>
                    <div className={`basier-p4-caps ${this.state.currentPage === 0 ? null : 'blue-hover'}`} onClick={this.previousPage} style={{marginRight: 16, cursor: `${this.state.currentPage === 0 ? 'not-allowed' : 'pointer'}`}}> 
                        &lt; Previous 
                    </div>

                    <div style={{color: 'black'}} className="basier-p4-caps">Page {this.state.currentPage + 1} of {this.state.maxPage}</div>

                    <div className={`basier-p4-caps ${this.state.currentPage === this.state.maxPage - 1 ? null : 'blue-hover'}`} onClick={this.nextPage} style={{marginLeft: 16, cursor: `${this.state.currentPage === this.state.maxPage - 1 ? 'not-allowed' : 'pointer'}`}}> 
                        Next &gt; 
                    </div>
                </div>

                <div style={{width: '100%', marginTop: 12, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>

                    <div className='basier' 
                        style={{height: 35, borderRadius: 35, marginLeft: 12, marginRight: 12, marginTop: 0, cursor: 'pointer', color: 'white', backgroundColor: '#F9716B', width: 150, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        Cancel
                    </div>

                    <div className='button-next-blue' onClick={() => this.handleSubmit()} 
                        style={{height: 35, borderRadius: 35, marginLeft: 12, marginRight: 12, marginTop: 0, cursor: 'pointer'}}>
                        Submit
                    </div>

                </div> 

            </div>

        )
    }


}

const mapStateToProps = state => {
    return {
        token: state.auth.token,
        user_id: state.auth.user_id,
        currentPortfolio: state.data.menu_selected, 
        inBitcoin: state.data.bitcoinPf, 
        currentView: state.data.currentView, 
        currency_id: state.auth.currency.id, 
    };
};

const mapDispatchToProps = dispatch => {
    return {
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(PendingCoins)
