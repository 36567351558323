import React from "react";
import Select, { components } from "react-select";
import { noAddErc20 } from "../../../../../../helpers/noERC20";

const Option = (props) => {
  const { data } = props;

  return (
    <components.Option {...props}>
      <div
        {...props}
        style={{
          textAlign: "left",
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            fontSize: 12,
            width: "100%",
          }}
          className="basier"
        >
          {data.wallet_type === "exchange" ? (
            <div style={{ width: "50%", color: "black", fontWeight: 900 }}>
              {data.exchange !== null
                ? data.exchange.name.length > 15
                  ? `${data.exchange.name.slice(0, 15)}...`
                  : data.exchange.name
                : "Deleted account"}
            </div>
          ) : (
            <div style={{ color: "black", fontWeight: 900 }}>{data.name}</div>
          )}

          {data.wallet_type === "exchange" ? (
            <div
              style={{ textAlign: "right", width: "50%", fontStyle: "italic" }}
            >
              {data.exchange !== null
                ? data.exchange.custom === true
                  ? data.exchange.custom_exchange !== null &&
                    data.exchange.custom_exchange !== undefined
                    ? data.exchange.custom_exchange.length > 12
                      ? `${data.exchange.custom_exchange.slice(0, 12)}...`
                      : data.exchange.custom_exchange
                    : null
                  : data.exchange.exchange !== null &&
                    data.exchange.exchange !== undefined
                  ? data.exchange.exchange.name.length > 12
                    ? `${data.exchange.exchange.name.slice(0, 12)}...`
                    : data.exchange.exchange.name
                  : null
                : null}
            </div>
          ) : null}

          <div></div>
        </div>
        <div
          style={{ fontSize: 10, textTransform: "uppercase" }}
          className="basier"
        >
          {data.address !== null && data.address !== undefined
            ? data.address.length > 35
              ? `${data.address.slice(0, 35)}...`
              : data.address
            : null}
        </div>
      </div>
    </components.Option>
  );
};

const groupStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
};
const groupBadgeStyles = {
  backgroundColor: "#EBECF0",
  borderRadius: "2em",
  color: "#172B4D",
  display: "inline-block",
  fontSize: 12,
  fontWeight: "normal",
  lineHeight: "1",
  minWidth: 1,
  padding: "0.16666666666667em 0.5em",
  textAlign: "center",
};

const formatGroupLabel = (data) => (
  <div style={groupStyles}>
    <span
      className="basier"
      style={{ color: "black", fontWeight: 900, fontSize: 10 }}
    >
      {data.label}
    </span>
    <span style={groupBadgeStyles}>{data.options.length}</span>
  </div>
);

let groupedOptions = [];

class MenuSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: null,
    };
  }

  colourStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "transparent",
      width: "250px",
      height: this.props.height !== undefined ? this.props.height : 42,
      fontSize: 16,
      border: "0px",
      color: "black",
      cursor: "text",
      dispay: "inline-block",
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? null
          : isSelected
          ? "white"
          : isFocused
          ? "#F3F5F6"
          : null,
        color: isDisabled ? "#F3F5F6" : isSelected ? "black" : "black",
        opacity: isDisabled ? "1" : isSelected ? "1" : "0.7",
      };
    },
  };

  hydrateWallets() {
    groupedOptions = [];
    let displayErc20 = [];
    let accounts =
      this.props.wallets[this.props.scopedUser][this.props.base_currency];

    const needErc20 = !noAddErc20.includes(this.props.base_currency);
    const accountsErc20 =
      this.props.wallets[this.props.scopedUser]["ethereum"]["storage"];
    if (accountsErc20 !== undefined) {
      const jsonVer = JSON.stringify(accountsErc20);
      displayErc20 = JSON.parse(jsonVer);
    }

    displayErc20 = displayErc20.map((val) => {
      val.name = `${val.name} (ERC20)`;
      return val;
    });

    if (
      needErc20 &&
      accounts !== undefined &&
      accounts !== null &&
      noAddErc20 !== null &&
      noAddErc20 !== undefined
    ) {
      if (accounts.storage !== undefined && accounts.storage !== null) {
        accounts = {
          ...accounts,
          storage: [...accounts.storage, ...displayErc20],
        };
      } else {
        accounts = { ...accounts, storage: displayErc20 };
      }
    } else if (needErc20 && noAddErc20 !== null && noAddErc20 !== undefined) {
      accounts = { storage: displayErc20 };
    }

    let selectedWallet = null;
    if (accounts !== undefined && accounts !== null) {
      const selectWallets = accounts;
      Object.keys(selectWallets).map((key) => {
        let temp_values = [];
        selectWallets[key].map((wallet) => {
          let indiv_values = wallet;
          if (
            indiv_values.wallet_type === "exchange" &&
            ((indiv_values.exchange !== null && indiv_values.custom !== true) ||
              (indiv_values.custom === true &&
                indiv_values.custom_exchange !== null))
          ) {
            let label =
              indiv_values.custom === true
                ? indiv_values.custom_exchange
                : indiv_values.exchange.name;
            let tempvals = { label: label, ...indiv_values };
            if (wallet["id"] == this.props.selection) {
              selectedWallet = tempvals;
            }
            temp_values.push(tempvals);
          } else if (
            indiv_values.wallet_type === "storage" &&
            indiv_values.name !== null
          ) {
            let tempvals = { label: indiv_values.name, ...indiv_values };
            if (wallet["id"] == this.props.selection) {
              selectedWallet = tempvals;
            }
            temp_values.push(tempvals);
          }
        });

        let values = { label: key, options: temp_values };
        groupedOptions.push(values);

        //let values = {id: JSON.stringify(selectWallets['id']), value: wallet['name'], label: optionDisplay(wallet)};

        //options.push(values)
      });
    }

    /* if (this.props.noAdd === true) {
      const adder = {id: "noAction", value: 'adder', label: adderDisplay}
      options.push(adder);
    } */

    this.setState((state) => ({
      ...state,
      options: groupedOptions,
      selectedOption: selectedWallet,
    }));
  }

  componentDidMount() {
    this.hydrateWallets();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.scopedUser !== this.props.scopedUser) {
      this.hydrateWallets();   
    }
  }

  filterOptions = (candidate, input) => {
    if (input) {
      let exchange_name = null;
      try {
        exchange_name = candidate.data.exchange.name.toLowerCase();
      } catch (e) {}

      return (
        (candidate.data.name &&
          candidate.data.name.toLowerCase().includes(input.toLowerCase())) ||
        (candidate.data.address &&
          candidate.data.address.toLowerCase().includes(input.toLowerCase())) ||
        (exchange_name &&
          exchange_name.toLowerCase().includes(input.toLowerCase()))
      );
    }
    return true;
  };

  handleChange = (selectedOption) => {
    if (selectedOption.value !== "adder") {
      this.setState((state) => ({ ...state, selectedOption: selectedOption }));
      this.props.handleChange(selectedOption.id);
      this.props.goToNext();
      //this.props.updateTransaction({key: this.props.transactionId, label: "storage", value: selectedOption.id})
    } else if (selectedOption.value === "adder") {
      //this.props.setNewWallet(false)
    }
  };

  onFocus = () => {};

  getFocusedOption() {
    let selectedData = undefined;
    try {
      selectedData = this.thisInput.select.state.focusedOption;
    } catch (e) {}
    return selectedData;
  }

  onKeyDown = (e) => {
    if (this.props.propagateKey === true) {
      if (
        e.key === "Tab" ||
        e.key === "Enter" ||
        e.keyCode === 13 ||
        e.keyCode === 9
      ) {
        e.preventDefault();
        const isShift = !!e.shiftKey;
        if (isShift) {
          this.props.backToPrevious();
        } else {
          this.props.goToNext();
          const data = this.getFocusedOption();
          this.handleChange(data);
        }
      }
    }
  };

  render() {
    const { selectedOption } = this.state;

    return (
      <Select
        value={selectedOption}
        onChange={this.handleChange}
        options={this.state.options}
        formatGroupLabel={formatGroupLabel}
        onFocus={this.onFocus}
        onMenuOpen={this.onFocus}
        isSearchable={true}
        ref={(ip) => (this.myInput = ip)}
        styles={this.colourStyles}
        filterOption={this.filterOptions}
        maxMenuHeight={210}
        minMenuHeight={50}
        components={{ Option }}
        alwaysOpen
        classNamePrefix="react-select-user"
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary25: "#F3F5F6",
            primary: "#F3F5F6",
          },
        })}
      />
    );
  }
}

export default MenuSelector;
