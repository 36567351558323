import React from 'react';
import { connect } from 'react-redux';
import TransactionTable from '../TransactionTable';
import * as interActions from "../../../../../store/actions/interactions";
import TransactionFilter from '../TransactionFilter';
import SelectActions from '../SelectActions';
import { withRouter} from 'react-router-dom';
import PositionFilter from '../PositionFilter';
import { siwtchTransactionType } from '../../../../../helpers/helpers';
import contentLoader from '../../../../../Media/contentLoader.svg';


class TransactionOverview extends React.PureComponent {

    state = {
        data: undefined, 
        filterValues: {}, 
        selectAll: false, 
        checked: {},
    }

    componentDidMount() {
 
        const data = this.props.scope === 'transaction' ? this.props.transactions : this.props.positions
        this.setState(state => ({...state, data: data}))

        if (data !== undefined && data !== null && data['filters'] !== undefined && data['filters'] !== null  ) { 
            

            let filterTypes = [];
            let filterExchanges = undefined;
            let filterQuotes = undefined;
            let filterPositions = undefined;

            if (this.props.scope === 'transaction') {

                filterQuotes = data['filters']['quote_id']

                data['filters']['types'].map(v => {
                    filterTypes.push([v, siwtchTransactionType(v)[0]])
                })

               
            } else {
                filterExchanges = data['filters']['exchange']
                filterPositions = data['filters']['positions']
            }

            this.setState(state => ({
                ...state, 
                filterValues: {
                    ...state.filterValues, 
                    base_id: data['filters']['base_id'],
                    quote_id: filterQuotes,
                    user_id: data['filters']['user_id'],
                    transaction_description: filterTypes,
                    position_id: filterExchanges,
                    exchange_id: filterPositions,
                }

            })
            )
        }

    }

    // WHY FILTER NO WORK

    componentDidUpdate(prevProps) {

        if ( (prevProps.transactions !== this.props.transactions) || (prevProps.positions !== this.props.positions) || (this.props.scope !== prevProps.scope) ) {
            const data = this.props.scope === 'transaction' ? this.props.transactions : this.props.positions
            this.setState(state => ({...state, data: data}))

            if (data !== undefined && data !== null && data['filters'] !== undefined && data['filters'] !== null ) {    

                let filterTypes = [];
                let filterExchanges = undefined;
                let filterQuotes = undefined;
                let filterPositions = undefined;

                if (this.props.scope === 'transaction') {

                    data['filters']['types'].map(v => {
                        filterTypes.push([v, siwtchTransactionType(v)[0]])
                    })

                    filterQuotes = data['filters']['quote_id']
                
                } else {
                    filterExchanges = data['filters']['exchange']
                    filterPositions = data['filters']['positions']
                }

                this.setState(state => ({
                    ...state, 
                    filterValues: {
                        ...state.filterValues, 
                        base_id: data['filters']['base_id'],
                        quote_id: filterQuotes,
                        user_id: data['filters']['user_id'],
                        transaction_description: filterTypes,
                        position_id: filterPositions,
                        exchange_id: filterExchanges,
                    }

                })
                )
            }

        }
        
    }

    selectTransaction = (val) => {

        if (val.matched !== true) {
            this.props.toggleModal({type: 'transaction_detail', open: true, payload: {transactionId: val.id, portfolioId: this.props.portfolioId, getOverallData: this.getOverallData }})
        } else {
            this.props.toggleModal({type: 'matched_detail', open: true, payload: {transactionId: val.transfer_match, portfolioId: this.props.portfolioId, getOverallData: this.getOverallData }})
        }
        //this.setState(state => ({...state, transactionDetail: val}))

    }

    selectAll = () => {

        let newChecked = {...this.state.checked}
        
        if (this.state.selectAll === false) {
            this.state.data['transactions'].filter(x => (x.transfer_match === null || x.transfer_match === undefined)).map(val => {
                if (this.state.checked[val.id] !== true) {
                    newChecked[val.id] = true
                }
            })
            
            this.setState(state => ({...state, selectAll: true, checked: newChecked }))

        } else {
            this.setState(state => ({...state, selectAll: false, checked: {} }))
        }

    }

    selectSingle = (id) => {
        let newChecked = {...this.state.checked}
        if (this.state.checked[id] === true) {
            
            newChecked[id] = false
            delete newChecked[id]
            
        } else {    
            
            newChecked[id] = true
            
        }

        this.setState(state => ({...state, checked: newChecked}))
        
        if (Object.keys(newChecked).length == this.state.data['transactions'].filter(x => (x.transfer_match === null || x.transfer_match === undefined) ).length) {

            if (JSON.stringify(Object.keys(newChecked).sort()) == JSON.stringify(this.state.data['transactions'].filter(x => (x.transfer_match === null || x.transfer_match === undefined)).map(e => `${e.id}`).sort())) {
             this.setState(state => ({...state, selectAll: true }))
            } else {
             this.setState(state => ({...state, selectAll: false }))
         }
        } else {
            this.setState(state => ({...state, selectAll: false }))
        }

    }
   
    render () {

        return (

            this.state.data !== null && this.state.data !== undefined ? 
            <div style={{width: '100%', marginTop: 24}}>

                {this.props.scope === 'transaction' ? 
                    <TransactionFilter resetPage={this.props.resetPage} filters={this.props.transactionFilter} filterValues={this.state.filterValues} sort={this.returnSorted} getData={(page, skp, filter) => this.props.getData(page, skp, filter)}/>
                :
                    <PositionFilter account={this.props.account} resetPage={this.props.resetPage} filters={this.props.positionFilter} filterValues={this.state.filterValues} sort={this.returnSorted} getData={(page, skp, filter) => this.props.getData(page, skp, filter)}/>
                }

                {this.props.account === true ? null : 
                <div style={{height: 50, paddingLeft: 12, display: 'flex', alignItems: 'center', marginBottom: 12}} className="basier-p4-caps">
                    <SelectActions selected={this.state.checked} allData={this.state.data} filteredData={this.state.data} portfolioId={this.props.portfolioId} />
                </div>
                }

                <div style={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    
                    <TransactionTable scope={this.props.scope} getTransactions={(page) => this.props.movePage(page)} total={this.state.data['total']} page={this.props.page} getOverallData={this.props.getOverallData} data={this.state.data['transactions']} number_rows={10} allSelected={this.state.selectAll} selectAll={this.selectAll} selectSingle={this.selectSingle} selected={this.state.checked} selectTransaction={this.selectTransaction} account={this.props.account} />
                
                </div>
                
            </div>
            :
            <div style={{minHeight: 800, display: 'flex', justifyContent: 'center', marginTop: 24}}>
                <img src={contentLoader} alt=""  style={{height: 100, width: 100}} />
            </div>
            
        )
    }


}

const mapStateToProps = state => {
    return {
        transactions: state.data.transactions,
        positions:  state.data.position_entries,
        transactionFilter: state.interactions.transactionFilter, 
        positionFilter: state.interactions.positionFilter, 

    };
};

const mapDispatchToProps = dispatch => {
    return {
        showSnack: (data) => dispatch(interActions.showSnack(data)), 
        toggleModal: (data) => dispatch(interActions.toggleModal(data)), 
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TransactionOverview));
