import React from 'react';
import { connect } from 'react-redux';
import listIcon from '../../../../../Media/listIcon.svg'
import SmallAssetTable from '../Small/SmallAssetTable';
import SmallPositionTable from '../Small/SmallPositionTable';

class SmallAssetTableOverview extends React.PureComponent {

    state = {
        view: 'asset', 
    }

    switchType = (val) => {
        this.setState({view: val})
    }

    render () {

        return (

            <div
                style={{
                    boxShadow: '0px 10px 10px rgba(225, 229, 233, 0.3)',
                    borderRadius: 14, 
                    position: 'relative',
                    width: '100%',
                    paddingBottom: 24,
                }}>



                <div className='title-asset-sticky-mobile' style={{width: '100%'}}>
                    <div  style={{paddingLeft: 6, paddingRight: 6, paddingTop: 25, display: 'flex', alignItems: 'center'}}>

                    {['asset', 'position'].map((val, key) => {

                        return (
                            
                            <div key={key} onClick={() => this.switchType(val)} 
                                style={{
                                    width: '50%', height: 30, display: 'flex', 
                                    alignItems: 'center', justifyContent: 'center', 
                                    backgroundColor: `${this.state.view === val ? '#0078DB' : '#E1E5E9' }`, 
                                    color: `${this.state.view === val ? 'white' : 'black'}`, 
                                    fontWeight: `${this.state.view === val ? 900 : 400 }`, 
                                    textTransform: 'uppercase', marginRight: 6, marginLeft: 6, borderRadius: 10}} 
                                    className="basier-p4-caps touchableOpacity"
                            >
                                {`${val} List`}
                            </div>

                        )

                    })} 

                    </div>
                
                </div>

                <div item style={{width: '100%'}}>
                    <div style={{marginLeft: 0, marginRight: 0, marginTop: 0}}>

                    {this.props.portfoliosData[this.props.portfolioId] !== undefined ?

                        this.state.view === 'asset' ? 
                        
                            this.props.portfoliosData[this.props.portfolioId].balances.length > 0  ?

                                <div>
                                    <div style={{display: 'flex', alignItems: 'flex-end', width: '100%', backgroundColor: 'white', 'boxShadow': 'none', height: 30, paddingBottom: 12, marginTop: 30, borderBottom: '1px solid #E1E5E9'}}>
                                        <div
                                                style={{display: 'flex', width: '100%', alignItems: 'baseline', color: '#6A7E93'}}
                                                className="basier-p4-caps"
                                            > 
                                    
                                            <div item style={{width: '30%', paddingLeft: 12}}>Asset</div> 
                                            <div item style={{width: '40%', textAlign: 'right'}}>Value</div> 
                                            <div item style={{width: '25%', textAlign: 'right', paddingRight: 12}}>ROI</div> 
                                            <div item style={{width: '5%', textAlign: 'centre', paddingRight: 12}}></div> 
                                        </div>
                                    </div>


                                    <SmallAssetTable active={this.props.active} small={this.props.small} exited = {this.props.exited} items={this.props.portfoliosData[this.props.portfolioId]} portfolioId={this.props.portfolioId} portfoliosData={this.props.portfoliosData} />
                                
                                </div>

                            :

                                <div style={{height: 382, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 12}}>
                                    <div style={{height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', padding: 48, flexDirection: 'column',}}>
                                        <div>
                                            <img src={listIcon} alt="" style={{height: 80}} />
                                        </div>
                                        <div className="basier-p2" style={{marginTop: 24}}>
                                            <div>You do not have any transactions in your portfolios.</div>
                                            <div>Once you add transactions you can see your balances here.</div>
                                        </div>
                                    </div>
                                </div>
                        
                        :

                        this.state.view === 'position' ?

                            this.props.portfoliosData[this.props.portfolioId].positions.filter(v => v.is_open === true).length > 0  ?

                                <div style={{marginTop: 12}}>

                                    <SmallPositionTable open={this.props.portfoliosData[this.props.portfolioId].positions.filter(v => v.is_open === true)} close={this.props.portfoliosData[this.props.portfolioId].positions.filter(v => v.is_open === false)} portfolioId={this.props.portfolioId} portfoliosData={this.props.portfoliosData} />
                                
                                </div>
                            
                            :

                                <div style={{height: 382, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 12}}>
                                    <div style={{height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', padding: 48, flexDirection: 'column',}}>
                                        <div>
                                            <img src={listIcon} alt="" style={{height: 80}} />
                                        </div>
                                        <div className="basier-p2" style={{marginTop: 24}}>
                                            <div>You do not have any open positions in your portfolios.</div>
                                            <div>Once you add positions you can see your balances here.</div>
                                        </div>
                                    </div>
                                </div>

                        : 

                        null 

                        :

                        null

                    }


                    </div>
                </div>

            </div>
            
        )
    }


}

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = dispatch => {
    return {
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(SmallAssetTableOverview)
