import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import PersonIcon from '@material-ui/icons/Person';
import AddIcon from '@material-ui/icons/Add';
import Typography from '@material-ui/core/Typography';
import { blue } from '@material-ui/core/colors';
import { connect } from 'react-redux';
import Port1 from '../../../../../Media/Portfolio-1.svg'
import Port2 from '../../../../../Media/Portfolio-2.svg'
import * as actions from "../../../../../store/actions/data";
import { withRouter } from 'react-router-dom';


function SimpleDialog(props) {
  const { onClose, selectedValue, open } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };

  const handlePortfolioChoose = (data) => {
    onClose()
    props.history.push(`/portfolio/${data}/overview`)
    props.setMenuPortfolio(data);
    props.componentDataFetch('get', 2, props.currency_used, props.user, data, {}, {}, props.inBitcoin)
}

  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle><h4>Select Portfolio</h4></DialogTitle>
      <List>
        {props.portfolios.map((v, k) => (
          <ListItem key={k} button onClick={() => handlePortfolioChoose(v['id'])}>
            <div style={{marginRight: 12}}>
              <img style={{marginRight: 12, height: 20, width: 20}} src={(k === 0 || !!(k && !(k%2))) ? Port1 : Port2 } alt="" />
            </div>
            <div className="basier-p3">
              <ListItemText primary={v['name']} />
            </div>
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};

function SimpleDialogDemo(props) {

  return (
    <div>
      <SimpleDialog open={props.open} onClose={props.onClose} portfolios={props.list_portfolio} history={props.history} setMenuPortfolio={props.setMenuPortfolio} componentDataFetch={props.componentDataFetch} user={props.user} currency_used={props.currency_used} inBitcoin={props.inBitcoin}  />
    </div>
  );
}

const mapStateToProps = state => {
  return {
    list_portfolio: state.auth.list_portfolios,
    currency_used: state.auth.currency.id, 
    user: state.auth.user_id,
    inBitcoin: state.data.bitcoinPf

  };
};

const mapDispatchToProps = dispatch => {
  return {
    setMenuPortfolio: (path) =>
      dispatch(actions.setMenuPortfolio(path)),
    componentDataFetch: (scope, view, currency, user, portfolio, globalGraphData, portfolioGraphData, inBitcoin) =>
      dispatch(actions.componentDataFetch(scope, view, currency, user, portfolio, globalGraphData, portfolioGraphData, inBitcoin)), 

  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SimpleDialogDemo));
