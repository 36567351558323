import React from "react";
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import * as actions from "../../../../../store/actions/interactions";
import * as dataActions from "../../../../../store/actions/data";
import PositionDetail from './positionDetail'
import AssetExpansion from './AssetExpansion'
import Divider from '@material-ui/core/Divider';
import currencyDict from '../../../../../helpers/currency.json'
import axios from 'axios';
import { backendUrl } from '../../../../../variables';
import listIcon from '../../../../../Media/listIcon.svg'

const getCurrency = (ticker) => {
    let returnData = ticker;
    try {
        returnData = currencyDict[ticker]['symbol']
    } catch(e) {}

    return returnData
} 

const getTypeData = (val) => {

    switch(val) {
        case 'long': {
            return ['long', 'Long']
        }
        case 'short': {
            return ['short', 'Short']
        }
        case 'pnl': {
            return ['pnl', 'P&L Entry']
        }
        case 'close': {
            return  ['close', 'Close']
        }
        default: {
            return null
        }
    }


}
class PositionTable extends React.PureComponent {
    
    closePosition = () => {

    }

    editPosition = (val) => {
        const type = getTypeData(val['position_side'])
        const payloadData = {
            extraData: {
                size: val['amount'], 
                price: val['open_price'], 
                amount_coin: 'base',
            },  
                scope: 'edit',
                existingId: val['position_id'],
                selectedExchange: val['exchange_id'], 
                selectedContract: val['symbol_id'], 
                type: type, 
                quoteTicker: val['quote_ticker'],
                amountOptions: [{label: val['base_ticker'], value: 'base'}, {label: val['quote_ticker'], value: 'target'}]
            }

        this.props.toggleModal({type: 'add_position', open: true, payload: {existingData: payloadData} })

    }

    delete = (val) => {

        this.props.toggleModal({type: 'confirmation_modal', open: true, payload: {
            message: "Are you sure you want to delete this position?",
            handleConfirm: () => this.deletePosition(val)
        } })

    }

    quickClose = (val) => {
        this.props.toggleModal({type: 'quick_position_close', open: true, payload: {
            positionId: val.id,
            currencies: [{label: val['base_ticker'], value: 'base'}, {label: val['quote_ticker'], value: 'target'}], 
            portfolioId: this.props.portfolioId
        } })
    }

    movePosition = (val, type) => {
        this.props.toggleModal({type: 'move_position', open: true, payload: {
            type: type,
            positionName: val['contract_name'], 
            positionExchange: val['exchange_name'],
            positionSize: val['amount'], 
            quoteSize: parseFloat(val['amount']) * parseFloat(val['open_price']),
            positionId: val.position_id,
            priceQuote:  val['quote_ticker'],
            currencies: [{label: val['base_ticker'], value: 'base'}, {label: val['quote_ticker'], value: 'target'}], 
            portfolioId: this.props.portfolioId
        } })
    }

    deletePosition = (val) => {

        const url_call = `${backendUrl}/v1/position/${val.id}/?u=${this.props.user_id}&p=${this.props.portfolioId}`

        let headers = { "content-type": "application/json" };
        if (this.props.token !== null && this.props.token !== undefined) {

            headers['Authorization'] = `Token ${this.props.token}`

        }

        axios.delete(url_call, {headers: headers}).then(
            res => {
                this.props.componentDataFetch('update', this.props.currentView, this.props.currency, this.props.user_id, this.props.portfolioId, {},{},this.props.inBitcoin, this.props.token)
            }
        )

    }

    render() {
    
        const menuItems = [['Close', (val) => this.movePosition(val, 'close')], ['Add', (val) => this.movePosition(val, 'add')], ['Reduce', (val) => this.movePosition(val, 'reduce')]]; 
        const closeMenuItems = [['Delete', (val) => this.delete(val)]]; 
        
        return (

        

        <div>
           {   
                this.props.open.length == 0 ? 

                    <div style={{height: 200, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 12}}>
                        <div style={{height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', padding: 48, flexDirection: 'column'}}>
                            <div>
                                <img src={listIcon} alt="" style={{height: 80}} />
                            </div>
                            <div className="basier-p2" style={{marginTop: 24}}>
                                <div>You do not have any open position in this portfolio yet.</div>
                                <div>Once you add positions you can see them here.</div>
                            </div>
                        </div>
                    </div>

               :

               this.props.open.map((item, index) => { 

                const open = parseFloat(item.open_price)
                const close = parseFloat(item.current_close)
                const amount = parseFloat(item.amount)
                const pnl = (close - open) * amount
                const pnlpct = pnl / parseFloat(item.margin) * 100
                
                const inversePnL = ( (1/open ) - (1/(close)) ) * (amount * open)
                const pnlquotepct = ( inversePnL / (parseFloat(item.margin) / open) ) * 100
                const border = index !== this.props.open.length - 1
                const unsupported = item['base_ticker'] === 0 ? true : false
                const pnlBaseCurrency = unsupported ? null : ['usd'].includes(item['base_ticker'].toLowerCase()) ? '$' : ['btc', 'xbt'].includes(item['base_ticker'].toLowerCase()) ? '₿' : getCurrency(item['base_ticker'])
                const pnlQuoteCurrency = unsupported ? null : ['usd'].includes(item['quote_ticker'].toLowerCase()) ? '$' : ['btc', 'xbt'].includes(item['quote_ticker'].toLowerCase()) ? '₿' : getCurrency(item['quote_ticker'])

            return (

                <PositionDetail menuItems={menuItems} border={border} key={index} index={index} item={item} pnl={pnl} inversePnL={inversePnL} currency_symbol={pnlBaseCurrency} quote_currency={pnlQuoteCurrency} pnlpct={pnlpct} pnlquotepct={pnlquotepct} unsupported={unsupported} />
            
            )
    

            })
        }
        
        {/* {this.props.closed.length > 0 &&
        <div style={{marginTop: 24}}>
            <AssetExpansion title="Closed positions">

                {
                    this.props.closed.map((item, index) => { 

                        const mult = item.position_side === 'short' ? -1 : 1
                        const open = parseFloat(item.open_price)
                        const close = parseFloat(item.current_close)
                        const amount = parseFloat(item.amount)
                        const pnl = (close - open) * amount
                        const pnlpct = pnl / parseFloat(item.margin) * 100
                        const unsupported = item['base_ticker'] === 0 ? true : false
                        
                        const inversePnL = ( (1/open ) - (1/(close)) ) * (amount * open) * mult
                        const pnlquotepct = ( inversePnL / (parseFloat(item.margin) / open) ) * 100
                        const border = index !== this.props.open.length - 1
        
                        const pnlBaseCurrency = unsupported ? null : ['usd'].includes(item['base_ticker'].toLowerCase()) ? '$' : ['btc', 'xbt'].includes(item['base_ticker'].toLowerCase()) ? '₿' : getCurrency(item['base_ticker'])

        
                    return (
        
                        <PositionDetail menuItems={closeMenuItems} border={border} key={index} index={index} item={item} pnl={pnl} inversePnL={inversePnL} currency_symbol={pnlBaseCurrency} quote_currency={null} pnlpct={pnlpct} pnlquotepct={pnlquotepct} unsupported={unsupported} />
                    
                    )


                    })
                }

            </AssetExpansion>
        </div>
        } */}
        

    </div>

        )
    }
}

const mapStateToProps = state => {
    return {
        currency_symbol: state.auth.currency.symbol,
        history: state.data.portfoliosHist, 
        token: state.auth.token, 
        user_id: state.auth.user_id,
        currentView: state.data.currentView,
        currency: state.auth.currency.id,
        graphData: state.data.globalBalance,
        inBitcoin: state.data.bitcoinPf, 
    };
};

const mapDispatchToProps = dispatch => {
    return {
        toggleModal: (data) => 
            dispatch(actions.toggleModal(data)), 
        componentDataFetch: (scope, view, currency, user, portfolio, globalGraphData, portfolioGraphData, inBitcoin, tokenProvided) =>
            dispatch(dataActions.componentDataFetch(scope, view, currency, user, portfolio, globalGraphData, portfolioGraphData, inBitcoin, tokenProvided)), 
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PositionTable);