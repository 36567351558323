import React from 'react';
import PropTypes from 'prop-types';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import * as actions from "../../../store/actions/interactions";
import * as appwideActions from "../../../store/actions/appwide";
import * as authActions from "../../../store/actions/auth";
import * as dataActions from "../../../store/actions/data";
import { connect } from 'react-redux';
import BaseModalTop from '../../Portfolio/PortfolioComponents/Transactions/BaseModalTop';
import {updatePortfolios} from '../../../helpers/portfolioHelpers';
import axios from 'axios';

import { backendUrl } from '../../../variables';
import Oops from '../../../Media/oops.svg';
import { withRouter} from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';


const styles = theme => ({
    container: {
        width: '100%', 
        padding: 48, 
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            padding: 12, 
        },
    },
    image: {
        display: 'block', 
        [theme.breakpoints.down('sm')]: {
            display: 'none', 
        },
    },
    ImageText: {
        [theme.breakpoints.down('sm')]: {
            padding: 12 
        },
    },
    subContainer: {
        padding: 12,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            height: '100%',
        },
        [theme.breakpoints.up('md')]: {
            width: '48%',
            height: '100%',
        }
    },
    secondSub: {
        [theme.breakpoints.up('md')]: {
            height: 350,
        }
    }, 
    secondQuestions: {

        [theme.breakpoints.down('sm')]: {
            padding: 0
        },
        [theme.breakpoints.up('md')]: {
            marginTop: 0
        }

    }, 
    input: {
        
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 24, width: '100%', marginTop: 12, 
        },
        [theme.breakpoints.up('md')]: {
            paddingLeft: 24, width: 324, marginTop: 12, 
        }
    },
    fiatContainer: {
        
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        },
        [theme.breakpoints.up('md')]: {
            width: 324
        }
    }
    });

class ResponsiveDialog extends React.Component {

    state = {
        loading: false,
        success: false, 
        confirmEmail: null,
        validation: {
            email: true, 
        }, 
        errorMessage: null
    }

    handleClose = () => {

        this.props.toggleModal({type: null, open: false, payload: null})
       
      }

    deletePort = () => {

        let headers = { "content-type": "application/json" };
        if (this.props.token !== null && this.props.token !== undefined) {

            headers['Authorization'] = `Token ${this.props.token}`

        }

        const url_call = `${backendUrl}/v1/portfolio/${this.props.data.portfolioId}/?u=${this.props.user_id}&email=${this.state.confirmEmail}`

        axios.delete(url_call, {headers: headers})
            .then(json => {
                
                this.props.addPort(-1)
                this.handleClose()
                this.props.history.push('/')
                this.props.showSnack({message: 'Portfolio deleted successfully', variant: "success"})
                
                const newPortData = this.props.list_portfolios.filter(v => v.id != this.props.data.portfolioId)
                const success = updatePortfolios(newPortData)
                if (success) {
                    this.props.setPorts(newPortData)
                }
                const newTotalPorts = {}
                Object.keys(this.props.portfoliosData).map(val => {
                    if (val != this.props.data.portfolioId) {
                        newTotalPorts[val] = this.props.portfoliosData[val]
                    }
                })
                this.props.overwritePorts(newTotalPorts)
                

            })
            .catch( e => {

                console.log(e)
                let message = 'Error deleting portfolio'
                try { message = e.response['data']['data']} catch(e) {}

                this.setState(state => ({...state, errorMessage: message}))
                this.props.showSnack({message: 'Error in deleting portfolio. Please try again later.', variant: "error"})

                
            })

    }

    setConfirmEmail = (e) => {
        e.persist();
        this.setState(state => ({...state, confirmEmail: e.target.value}))
    }

    render() {

    const { classes } = this.props;

    return (

        <div style={{width: '100%', top: 0}}>

        <BaseModalTop forceSingle={true} title="Cancel plan" handleClose={this.handleClose} onClick={this.backToWelcome} welcome={this.state.welcome} entryType={this.state.type_selected} />
        
        <div style={{width: '100%', backgroundColor: 'white', opacity: 1, display: 'flex', flexDirection: 'column', paddingTop: 0, paddingLeft: 24, paddingRight: 24, alignItems: 'center', justifyContent: 'center', textAlign: 'center', position: 'relative',  zIndex: 13000}}>
            
        
            <img src={Oops} alt="" style={{height: 100, marginTop: 12}} />
            <div style={{marginTop: 24, fontWeight: 900, color: '#0078DB'}} className="basier-p2">
                You are about to delete a portfolio.
            </div>
            <div style={{marginTop: 12, paddingLeft: 24, paddingRight: 24}} className="basier-p3">
                Deleting the portfolio will delete all transactions and transfers that are linked to it. You currently have <span style={{fontWeight: 900, color: '#0078DB'}}>{this.props.data.numberTransactions} transactions</span> in this portfolio.
            </div>
        
            <div style={{marginTop: 12, marginBottom:12}}>
                To confirm you want to delete this portfolio, please type the email address linked to your account in the textbox below and click Delete Portfolio. 
            </div>
        
            <div>
                <input type='email' className="light-input basier-p4" value={this.state.confirmEmail} onChange={this.setConfirmEmail} />
            </div>
            {this.state.errorMessage !== null && 
            (
                <div className="basier-p4" style={{marginTop: 12, color: 'red'}}>
                    {this.state.errorMessage}
                </div>

            )}
        
        <button className='button-blue opacity-hover' style={{height: 40, backgroundColor: '#F9716B', borderRadius: 35, marginTop: 24, marginBottom: 48, cursor: 'pointer'}} onClick={() => this.deletePort(null, false, true, true)}>Delete Portfolio</button>
        
        </div>
        
        
        </div>
        

    )
  }
}

ResponsiveDialog.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
};


const mapStateToProps = state => {
    return {

        token: state.auth.token,
        user_id: state.auth.user_id,
        list_portfolios: state.auth.list_portfolios,
        portfoliosData: state.data.portfoliosData,
        
    };
};

const mapDispatchToProps = dispatch => {
    return {
        addPort: (v) => dispatch(appwideActions.addPort(v)), 
        toggleModal: (data) => 
            dispatch(actions.toggleModal(data)), 
        showSnack: (data) => dispatch(actions.showSnack(data)), 
        setPorts: (ports) => dispatch(authActions.setPorts(ports)),
        overwritePorts: (data) => dispatch(dataActions.overwritePorts(data))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withMobileDialog()(withRouter(withStyles(styles)(ResponsiveDialog))));



