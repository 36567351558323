import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import * as actions from "../../../../../store/actions/interactions";
import * as appwideActions from "../../../../../store/actions/appwide";
import * as dataActions from "../../../../../store/actions/data";
import { connect } from 'react-redux';
import TransactionTypeQuote from './TransactionStep1'
import TransactionStep2 from './TransactionStep2'
import TransactionStep3 from './TransactionStep3'
import TransactionStep4 from './TransactionStep4'
import axios from 'axios';
import loadingSpinner from '../../../../../Media/loadingSpinner.gif'
import okIcon from '../../../../../Media/okIcon.svg'
import errorIcon from '../../../../../Media/errorIcon.svg'
import {postData} from '../../../../../helpers/helpers'
import fieldValidator from '../../../../../helpers/validators' 
import { backendUrl } from '../../../../../variables'

const styles = theme => ({
  root: {
    width: '100%',
    height: '100%',
  },
  backButton: {
    marginRight: 24,
  },
  instructions: {
    width: '100%',
    display: 'flex', 
    justifyContent: 'center'
  },
});


class HorizontalLabelPositionBelowStepper extends React.Component {
  state = {
    activeStep: 0,
    transaction_id: null,
    selected_base: null,
    selected_quote: null,
    selected_exchange: null,
    loading: false, 
    completed: false,
    error: false,
    validations: {
        base_currency: true, 
        description: true, 
        quote_currency: true, 
        amount: true, 
        unit_price: true, 
        date: true, 
        user_transaction: true, 
        fee_currency: true, 
        fee: true,
        storageName: true,
        accountName: true, 
    },
  };


  componentDidMount() {

    const last_index = this.props.transaction.number
    const next_index = last_index + 1
    this.props.addTransaction({key: next_index})
    this.setState(state => ({...state, transaction_id: next_index}))

  }

  handleNext = () => {

    let base_currency_val = null;
    let amount_val = null;
    let transaction_type_val = null;
    let quote_currency_val = null;
    let unit_price_val = null;
    let date_val = null; 
    let user_transaction_val = null; 
    let fee_currency_val = null; 
    let fee_val = null; 



    let valid = false
    if ( this.state.activeStep === 0 ) {

        base_currency_val = fieldValidator(this.props.transaction['data'][this.state.transaction_id]['base_currency'], ['isNotEmpty'])
        this.setState(state => ({
          ...state, 
          validations: {
            ...state.validations,
            base_currency: base_currency_val}
          })
        )

        amount_val = fieldValidator(this.props.transaction['data'][this.state.transaction_id]['amount'], ['isNotEmpty', 'isPositive'])
        this.setState(state => ({
          ...state, 
          validations: {
            ...state.validations,
            amount: amount_val}
          })
        )
        
        transaction_type_val = fieldValidator(this.props.transaction['data'][this.state.transaction_id]['transaction_description'], ['isNotEmpty'])
        this.setState(state => ({
          ...state, 
          validations: {
            ...state.validations,
            description: transaction_type_val}
          })
        )
        

        if (this.props.transaction['data'][this.state.transaction_id]['transaction_description'] === 'buy' || this.props.transaction['data'][this.state.transaction_id]['transaction_description'] === 'ico' ||this.props.transaction['data'][this.state.transaction_id]['transaction_description'] === 'sell') {
          quote_currency_val = fieldValidator(this.props.transaction['data'][this.state.transaction_id]['quote_currency'], ['isNotEmpty'])
          this.setState(state => ({
            ...state, 
            validations: {
              ...state.validations,
              quote_currency: quote_currency_val}
            })
          )
          unit_price_val = fieldValidator(this.props.transaction['data'][this.state.transaction_id]['unit_price'], ['isNotEmpty', 'isPositive'])
          this.setState(state => ({
            ...state, 
            validations: {
              ...state.validations,
              unit_price: unit_price_val}
            })
          )
        } else {
          quote_currency_val = true
          unit_price_val = true
        }
        valid = base_currency_val && transaction_type_val && amount_val && unit_price_val && quote_currency_val

    } 
    else if ( this.state.activeStep === 1 )
    {
        
      date_val = ( fieldValidator(this.props.transaction['data'][this.state.transaction_id]['date'], ['isNotEmpty']) && this.state.validations.date)
      this.setState(state => ({
        ...state, 
        validations: {
          ...state.validations,
          date: date_val}
        })
      )

      user_transaction_val = fieldValidator(this.props.transaction['data'][this.state.transaction_id]['user_transaction'], ['isNotEmpty'])
      this.setState(state => ({
        ...state, 
        validations: {
          ...state.validations,
          user_transaction: user_transaction_val}
        })
      )
      
      if (this.props.feeCheck === true) {
        fee_currency_val = fieldValidator(this.props.transaction['data'][this.state.transaction_id]['fee_currency'], ['isNotEmpty'])
        this.setState(state => ({
          ...state, 
          validations: {
            ...state.validations,
            fee_currency: fee_currency_val}
          })
        )


        fee_val = fieldValidator(this.props.transaction['data'][this.state.transaction_id]['fee'], ['isNotEmpty', 'isPositive'])
        this.setState(state => ({
          ...state, 
          validations: {
            ...state.validations,
            fee: fee_val}
          })
        )
      }
      else {
        fee_currency_val = true
        fee_val = true
      }

      valid = date_val && user_transaction_val && fee_currency_val && fee_val
    }  else if ( this.state.activeStep === 2 ) {

      let unique_name = true
      let account_name_check = true
      if (this.props.transaction['data'][this.state.transaction_id]['storage_name'] !== null && this.props.transaction['data'][this.state.transaction_id]['storage_name'] !== undefined && this.props.transaction['data'][this.state.transaction_id]['storage'] == -1) {

        unique_name = fieldValidator(this.props.transaction['data'][this.state.transaction_id]['storage_name'], ['isNotIncluded'], this.props.accountNames)
        this.setState(state => ({
          ...state, 
          validations: {
            ...state.validations,
            storageName: unique_name}
          })
        )

      }

      if (this.props.transaction['data'][this.state.transaction_id]['account_name'] !== null && this.props.transaction['data'][this.state.transaction_id]['account_name'] !== undefined && this.props.transaction['data'][this.state.transaction_id]['account'] == -1) {

        account_name_check = fieldValidator(this.props.transaction['data'][this.state.transaction_id]['account_name'], ['isNotIncluded'], this.props.accountNames)
        this.setState(state => ({
          ...state, 
          validations: {
            ...state.validations,
            accountName: account_name_check}
          })
        )

      }

      valid = unique_name && account_name_check

    }
    else {
      valid = true
    }
    
    if (valid) {
      this.setState(state => ({
        activeStep: state.activeStep + 1,
      }));
    } 


  };

  handleBack = () => {
    this.setState(state => ({
      activeStep: state.activeStep - 1,
    }));
  };

  handleReset = () => {

    this.props.removeTransaction(this.state.transaction_id);
    this.props.removeWallets();
    this.props.setTrTime(null);
    this.props.setInteraction({label: 'feeCheck', value: false});
    this.setState(state => ({...state, loading: false, error: false, completed: false}));
    this.props.addTransaction({key: this.state.transaction_id});
   
    this.setState({
      activeStep: 0,
    });
  };

  handleSubmit = (activeStep, steps) => {

    this.setState(state => ({...state, loading: true}));

    try {
      if (activeStep !== steps.length) {
        this.handleNext();
      }
    } catch(e) {}

    let form_data = {...this.props.transaction['data'][this.state.transaction_id]}
    try { form_data['base_currency'] = this.props.transaction['data'][this.state.transaction_id]['base_currency'].value } catch(e) {}
    try { form_data['quote_currency'] = this.props.transaction['data'][this.state.transaction_id]['quote_currency'].value } catch(e) {}
    try { form_data['fee_currency'] = this.props.transaction['data'][this.state.transaction_id]['fee_currency'].value } catch(e) {}

    if (form_data['exchange'] !== null && form_data['exchange'] !== undefined) {

      if (this.props.transaction['data'][this.state.transaction_id]['exchange'].custom === true) {

        form_data['custom_exchange'] = {id: this.props.transaction['data'][this.state.transaction_id]['exchange'].value, name:this.props.transaction['data'][this.state.transaction_id]['exchange'].name}
        form_data['exchange'] = null

      } else {

        try { form_data['exchange'] = this.props.transaction['data'][this.state.transaction_id]['exchange'].value } catch(e) {}
        form_data['custom_exchange'] = null

      }

    }
    
    if (this.props.overridePort === true) {
      form_data['portfolio'] = this.props.overridePortId
    } else {
      form_data['portfolio'] = this.props.currentPortfolio
    }
    
    form_data['wallets'] = this.props.walletsData
    form_data['user_id'] = this.props.user_id

    if (this.props.priceType === 'total') {
      form_data['unit_price'] = form_data['unit_price'] / form_data['amount']
    }
    
    let headers = { 
      "content-type": "application/json",     
    };
    headers["Authorization"] = `Token ${this.props.token}`;

    axios
      .post(`${backendUrl}/v1/transactions/`,
        form_data
      , { headers: headers } )
      .then(res => {

          this.props.addTr()
          if (res.data['status'] === 'OK') {


            this.setState(state => ({...state, completed: true, loading: false}))


            const request_data = {
              period: this.props.portfolioBalance.period,
              start: this.props.portfolioBalance.start,
              end: this.props.portfolioBalance.end
            }

            if (this.props.refresher !== undefined){
              this.props.refresher()
            }
            if (this.props.overridePort === true) {

              this.props.componentDataFetch('update', 1, this.props.currency_id, this.props.user_id, this.props.currentPortfolio, this.props.graphSettings, null, this.props.inBitcoin);
              this.props.getWallets(this.props.user_id);
              this.props.getUserLimits(this.props.user_id)

              this.props.getWalletBalance(this.props.currentPortfolio)
              this.props.getHoldings(this.props.user_id, this.props.currentPortfolio)

            } else {

              this.props.componentDataFetch('update', this.props.currentView, this.props.currency_id, this.props.user_id, this.props.currentPortfolio, null, request_data, this.props.inBitcoin);
              this.props.getWallets(this.props.user_id);
              this.props.getUserLimits(this.props.user_id)

              this.props.getWalletBalance(this.props.currentPortfolio)
              this.props.getHoldings(this.props.user_id, this.props.currentPortfolio)

            }
            

          } else {


            this.setState(state => ({...state, error: true, loading: false})) 

          }


      })
      .catch(err => {


          this.setState(state => ({...state, error: true, loading: false})) 

      }) 


  }

    getSteps() {
        return ['Transaction', 'Date & User', 'Exchange & Transfers', 'Confirm transaction'];
    }
    
    validatorUpdate = (label, validState) => {

        this.setState(state => ({
          ...state, 
          validations: {
            ...state.validations,
            [label]: validState}
          })
        )

    }

    selectBaseCoin = (data) => {
        

        this.props.updateTransaction({key: this.state.transaction_id, label: "transaction_description", value: null})
        this.props.updateTransaction({key: this.state.transaction_id, label: "amount", value: null})
        this.props.updateTransaction({key: this.state.transaction_id, label: "unit_price", value: null})
      this.props.updateTransaction({key: this.state.transaction_id, label: "fund_source", value: null})
        this.props.updateTransaction({key: this.state.transaction_id, label: "base_currency", value: { value: data.value, name: data.coinName, image: data.image, type: data.coinType } })
        this.setState(state => ({...state, selected_base: data}))

        //this.handleNext()

    }

    selectQuoteCoin = (data) => {

      this.props.updateTransaction({key: this.state.transaction_id, label: "amount", value: null})
      this.props.updateTransaction({key: this.state.transaction_id, label: "unit_price", value: null})
      this.props.updateTransaction({key: this.state.transaction_id, label: "fund_source", value: null})
      this.props.updateTransaction({key: this.state.transaction_id, label: "quote_currency", value: { value: data.value, name: data.coinName, image: data.image, type: data.coinType } })
      this.setState(state => ({...state, selected_quote: data}))


      //this.handleNext()
    }

    selectTransactionType  = (val) => {

      if (val === "transfer") {
        this.props.getFlatWallets(this.props.user_id)
        this.props.getScopePortfolios(this.props.user_id)
      }

      this.props.updateTransaction({key: this.state.transaction_id, label: "amount", value: null})
      this.props.updateTransaction({key: this.state.transaction_id, label: "unit_price", value: null})
      this.props.updateTransaction({key: this.state.transaction_id, label: "fund_source", value: null})
      this.props.updateTransaction({key: this.state.transaction_id, label: "transaction_description", value: val})
      this.props.updateTransaction({key: this.state.transaction_id, label: "exchange", value: null})
      this.props.updateTransaction({key: this.state.transaction_id, label: "exchange_address", value: null})
      this.props.updateTransaction({key: this.state.transaction_id, label: "account", value: null})
      this.props.updateTransaction({key: this.state.transaction_id, label: "storage", value: null})

    }

    selectDate  = (val) => {
      this.props.updateTransaction({key: this.state.transaction_id, label: "date", value: val})
    }

    selectFundSource = (val) => {
      this.props.updateTransaction({key: this.state.transaction_id, label: "fund_source", value: val})
    }
    
    selectExchange  = (val) => {
      this.props.updateTransaction({key: this.state.transaction_id, label: "exchange", value: val})
      this.setState(state => ({...state, selected_exchange: val}))

    }

    step1 = () => {
      return (
        
        <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
          <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
            
            <TransactionTypeQuote 
                validation={this.state.validations}
                handleNext={this.handleNext} 
                transactionId={this.state.transaction_id} 
                selectType={this.selectTransactionType}
                selectBaseCoin={this.selectBaseCoin}
                selectQuoteCoin={this.selectQuoteCoin}
                selectFundSource={this.selectFundSource}
                selectedBase={this.state.selected_base}
                overridePort={this.props.overridePort}
                overridePortId={this.props.overridePortId}
                currentPortfolio={this.props.currentPortfolio}
                selectedQuote={this.state.selected_quote}/>


          </div>
        </div>
      )
    }

    step2 = () => {
       
        return (
            <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
              <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>

                <TransactionStep2 
                 validatorUpdate ={(label, val) => this.validatorUpdate(label, val)}
                 validation={this.state.validations}
                 handleNext={this.handleNext} 
                 transactionId={this.state.transaction_id} 
                 selectDate={this.selectDate}
                 selectBaseCoin={this.selectBaseCoin}
                 selectQuoteCoin={this.selectQuoteCoin}
                 selectedBase={this.state.selected_base}
                 selectedQuote={this.state.selected_quote} />

                {/* <TransactionRadios assetType={this.state.selected_base.coinType} transactionId={this.state.transaction_id} selectType={this.selectTransactionType} /> */}
              </div>
            </div>
        )
    }

    step3 = () => {
       
      return (
          <div style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center'}}>
            <div style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center'}}>

              <TransactionStep3 
               handleNext={this.handleNext} 
               transactionId={this.state.transaction_id} 
               selectDate={this.selectDate}
               selectExchange={this.selectExchange}
               selectedBase={this.state.selected_base}
               selectedQuote={this.state.selected_quote}
               selectedExchange={this.selected_exchange}
               validation={this.state.validations}
               />

              {/* <TransactionRadios assetType={this.state.selected_base.coinType} transactionId={this.state.transaction_id} selectType={this.selectTransactionType} /> */}
            </div>
          </div>
      )
  }

    step4 = () => {
       
      return (
          <div style={{width: '100%', height: '100%'}}>
            <div style={{width: '100%', height: '100%'}}>

              <TransactionStep4 
               handleNext={this.handleNext} 
               transactionId={this.state.transaction_id} 
               selectDate={this.selectDate}
               selectBaseCoin={this.selectBaseCoin}
               selectQuoteCoin={this.selectQuoteCoin}
               selectedBase={this.state.selected_base}
               selectedQuote={this.state.selected_quote} />

              {/* <TransactionRadios assetType={this.state.selected_base.coinType} transactionId={this.state.transaction_id} selectType={this.selectTransactionType} /> */}
            </div>
          </div>
      )
  }
    
    
    getStepContent(stepIndex) {
        switch (stepIndex) {
        case 0:
            return this.step1();
        case 1:
            return this.step2();
        case 2:
            return this.step3();
        case 3:
            return this.step4();
        default:
            return 'Unknown stepIndex';
        }
    }

  render() {
    const { classes } = this.props;
    const steps = this.getSteps();
    const { activeStep } = this.state;



    return (

      <div className={classes.root}>
        <div style={{height: '10%'}}>
        <Stepper activeStep={activeStep}>
          {steps.map(label => (
            <Step key={label} >
              <StepLabel StepIconProps={{ classes: {active: 'step-icon-active', completed: 'step-icon-completed'}}} >{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        </div>
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'flex-start', height: '78%'}}>
          {this.state.activeStep === steps.length ? (
            <div>


              {this.state.loading ? 

                <div style={{ marginTop: 140}}>

                  <div style={{textAlign: 'center', marginBottom: 24}}>
                    <img style={{ height: 90, width: 90}} src={loadingSpinner} alt=""/>
                  </div>
                  
                  <div className="basier" style={{textAlign: 'center', fontSize: 14}}>Creating transaction.</div>

                </div>
              
              : 
              
              this.state.completed ? 

              <div style={{ marginTop: 140}}>

                <div style={{textAlign: 'center', marginBottom: 24}}>
                  <img style={{ height: 90, width: 90}} src={okIcon} alt=""/>
                </div>
                
                <div className="basier" style={{textAlign: 'center', fontSize: 14}}>Succesfully added transaction</div>

              </div>
            
              : 
              
              this.state.error  ? 
                
              <div style={{ marginTop: 140, textAlign: 'center'}}>

                <div style={{textAlign: 'center', marginBottom: 24}}>
                  <img style={{ height: 90, width: 90}} src={errorIcon} alt=""/>
                </div>
                
                <div className="basier" style={{textAlign: 'center', fontSize: 14, marginBottom: 24}}>Error adding transaction. Please retry</div>

                <div className='button-next-blue' onClick={this.handleSubmit} style={{height: 40, borderRadius: 35, marginLeft: 'auto', marginRight: 'auto', cursor: 'pointer'}}>
                  Retry
                </div>

              </div>
            
              : 
              
              null 
              
              }


            </div>
          ) : (
            <div style={{width: '85%', display: 'flex', justifyContent: 'center'}}>
              <div className={classes.instructions}>{this.getStepContent(activeStep)}</div>
              
            </div>
          )}
          </div>
            
          <div  style={{display: 'flex', justifyContent: 'center', height: '15%'}}>

            {activeStep === 0 ? null : 

              this.state.completed ? 
              <div style={{display: 'flex'}}>

                <div
                disabled={activeStep === 0}
                onClick={this.props.onClose}
                className='button-next'
                style={{height: 40, borderRadius: 35, marginLeft: 12, marginRight: 12, cursor: 'pointer' }}
                >
                  Close
                </div>

                <div
                disabled={activeStep === 0}
                onClick={this.handleReset}
                className='button-next-blue'
                style={{height: 40, borderRadius: 35, marginLeft: 12, marginRight: 12, cursor: 'pointer' }}
                >
                  Add another
                </div> 
                
              </div>
              :
              <div
              disabled={activeStep === 0}
              onClick={this.handleBack}
              className='button-next'
              style={{height: 40, borderRadius: 35, marginLeft: 12, marginRight: 12, cursor: 'pointer' }}
              >
                Back
              </div>
            }

            { 
              this.state.transaction_id !== null ?

              (this.props.transaction['data'][this.state.transaction_id]['amount'] !== null && this.props.transaction['data'][this.state.transaction_id]['transaction_description'] !== 'buy' && this.props.transaction['data'][this.state.transaction_id]['transaction_description'] !== 'ico' && this.props.transaction['data'][this.state.transaction_id]['transaction_description'] !== 'sell' && this.props.transaction['data'][this.state.transaction_id]['amount'] !== undefined && activeStep === 0) ||
              (this.props.transaction['data'][this.state.transaction_id]['unit_price'] !== null && (this.props.transaction['data'][this.state.transaction_id]['transaction_description'] === 'buy' || this.props.transaction['data'][this.state.transaction_id]['transaction_description'] === 'ico' || this.props.transaction['data'][this.state.transaction_id]['transaction_description'] === 'sell') && this.props.transaction['data'][this.state.transaction_id]['unit_price'] !== undefined && activeStep === 0) ||
              (this.props.transaction['data'][this.state.transaction_id]['date'] !== null && this.props.transaction['data'][this.state.transaction_id]['date'] !== undefined && activeStep === 1) ||
              (activeStep === 1) ||
              (activeStep === 2) 
              

              ?

            
                  <div className='button-next-blue' onClick={this.handleNext} style={{height: 40, borderRadius: 35, marginLeft: 12, marginRight: 12, cursor: 'pointer'}}>
                  Next
                  </div>
              
              :

              null 

              :

              null 

            }

            {
              activeStep === 3? 

              <div className='button-next-blue' onClick={() => this.handleSubmit(activeStep, steps)} style={{height: 40, borderRadius: 35, marginLeft: 12, marginRight: 12, cursor: 'pointer'}}>
                  Submit
              </div>

                  : null
            }



           
               

            </div>
         
        </div>
    );
  }
}

HorizontalLabelPositionBelowStepper.propTypes = {
  classes: PropTypes.object,
};


const mapStateToProps = state => {
    return {
        token: state.auth.token,
        user_id: state.auth.user_id,
        modalPref: state.interactions.transactionAddModal,
        transaction: state.interactions.transactionAddData,
        currentPortfolio: state.data.menu_selected, 
        currentView: state.data.currentView, 
        currency_id: state.auth.currency.id, 
        portfolioBalance: state.data.portfolioBalance, 
        inBitcoin: state.data.bitcoinPf, 
        walletsData: state.interactions.walletTransaction,
        graphSettings: state.data.globalBalance,
        feeCheck: state.interactions.feeCheck, 
        priceType: state.interactions.priceType, 
        accountNames: state.appwide.accountNames
    };
};

const mapDispatchToProps = dispatch => {
    return {
        addTransaction: (data) =>
            dispatch(actions.addTransaction(data)),
        updateTransaction: (data) =>
            dispatch(actions.updateTransaction(data)),
        removeTransaction: (data) =>
            dispatch(actions.removeTransaction(data)), 
        removeWallets: (data) =>
            dispatch(actions.removeWallets(data)),
        setTrTime: (data) => 
            dispatch(actions.setTrTime(data)), 
        setInteraction: (data) => 
            dispatch(actions.setInteraction(data)), 
        getWallets: (user) => dispatch(appwideActions.getWallets(user)), 
        addTr: () => dispatch(appwideActions.addTr()), 
        getScopePortfolios: (user) => dispatch(appwideActions.getAllPortfolioList(user)), 
        getFlatWallets: (user) => dispatch(appwideActions.getFlatWalletList(user)), 
        componentDataFetch: (scope, view, currency, user, portfolio, globalGraphData, portfolioGraphData, inBitcoin) =>
            dispatch(dataActions.componentDataFetch(scope, view, currency, user, portfolio, globalGraphData, portfolioGraphData, inBitcoin)), 
        getUserLimits: (user) => dispatch(appwideActions.getUserLimits(user)), 
        getWalletBalance: (user) => dispatch(dataActions.getWalletBalance(user)), 
        getHoldings: (user, portfolio) => dispatch(dataActions.getHoldings(user, portfolio)), 
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(HorizontalLabelPositionBelowStepper));