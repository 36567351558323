import React from "react";
import { connect } from "react-redux";
import * as actions from "../../store/actions/data";
import * as interactionActions from "../../store/actions/interactions";
import * as appwideActions from "../../store/actions/appwide";
import PortfolioTabs from "./PortfolioComponents/PortfolioTabs";
import Settings from "./PortfolioComponents/Settings/SettingsOverview";
import PortfolioOverview from "./PortfolioComponents/Overview/PortfolioOverview";
import PortfolioProfit from "./PortfolioComponents/Profit/PortfolioProfit";
import TransactionOverview from "./PortfolioComponents/Transactions/NewStructure/NTransactionPicker";
import Hidden from "@material-ui/core/Hidden";
import { withRouter } from "react-router-dom";
import "./Portfolio.css";
import Upload from "../../Other";
import CollaboratorOverview from "./PortfolioComponents/Collaborators/CollaboratorWrapper";
import AnalysisOverview from "./PortfolioComponents/Analysis/AnalysisOverview";
import TransactionWrapper from "./PortfolioComponents/Transactions/NewStructure/NTransactionPicker";
import noAccess from "../../Media/noAccess.svg";
import Warning from "../../Media/warning.svg";
import ReportOverview from "./PortfolioComponents/Reports/ReportOverview";
import blurredOverview from "../../Media/blurredOverview.jpg";
import { getValue } from "../../helpers/helpers";
import NewTransactionEntryOverview from "../AppWide/NewTransaction/NewTransactionEntryOverview";

function TabContainer(props) {
  let limited, accountNeeded;
  try {
    limited =
      props.limits.transactions > props.limits.transaction_limit &&
      props.limits.transaction_limit != -1 &&
      props.subscription.plan.plan_id === "free";
    accountNeeded =
      props.limits.transactions > 5000
        ? "Enterprise"
        : props.limits.transactions > 2000
        ? "Pro"
        : "Hobbyist";
  } catch (e) {
    limited = undefined;
  }

  return props.limited === true && props.trial === false ? (
    <div>
      {limited === undefined ? null : limited === true ? (
        <div>
          <img src={blurredOverview} alt="account" style={{ width: "100vw" }} />
          <div
            style={{
              position: "absolute",
              width: "100vw",
              height: "100%",
              top: 100,
              left: 0,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                backgroundColor: "white",
                border: "1px solid #F8F9FA",
                boxShadow: "0px 10px 10px rgba(225, 229, 233, 0.3)",
                padding: 24,
              }}
            >
              <div className="poppins" style={{ fontSize: 36, color: "black" }}>
                Account Upgrade Required
              </div>
              <div className="basier-p3" style={{ color: "black" }}>
                You have exceeded the limits of your Basic account. Please
                upgrade to a higher tier to continue using Wisly.
              </div>
              <div className="basier-p3" style={{ color: "black" }}>
                Based on your current usage, a
                {accountNeeded === "Enterprise" ? "n" : null}{" "}
                <span style={{ fontWeight: "bold", color: "#0078DB" }}>
                  {accountNeeded}
                </span>{" "}
                account will be neeed.
              </div>
              <div
                className="button-next-blue"
                onClick={props.goToSettings}
                style={{
                  width: 200,
                  height: 40,
                  borderRadius: 35,
                  marginLeft: 12,
                  marginRight: 12,
                  marginTop: 24,
                  cursor: "pointer",
                }}
              >
                Go To Settings
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>{props.children}</div>
      )}
    </div>
  ) : (
    <div>{props.children}</div>
  );
}

const switchTabs = (tab) => {
  switch (tab) {
    case "overview":
      return 0;
    case "profit":
      return 1;
    case "transactions":
      return 2;
    case "report":
      return 3;
    case "analysis":
      return 4;
    case "balances":
      return 5;
    case "collaborators":
      return 5;
    case "settings":
      return 6;
    default:
      return 0;
  }
};

const tabToView = (tab) => {
  switch (tab) {
    case 0:
      return 2;
    case 1:
      return 4;
    case 2:
      return 5;
    case 3:
      return null;
    case 5:
      return null;
    case 6:
      return null;
    default:
      return null;
  }
};

const switchInts = (tab) => {
  switch (tab) {
    case 0:
      return "overview";
    case 1:
      return "profit";
    case 2:
      return "transactions";
    case 3:
      return "report";
    case 5:
      return "balances";
    case 6:
      return "settings";
    default:
      return "overview";
  }
};

class PortfolioContainer extends React.Component {
  constructor(props) {
    super(props);
    this.portfolio_id = this.props.match.params.portfolio;
    this.section = undefined;
    this.subsection = undefined;
    try {
      this.section = this.props.match.params.section;
    } catch (e) {}
    if (this.section === undefined) {
      this.section = "overview";
      this.props.history.push(`/portfolio/${this.portfolio_id}/overview`);
      this.props.setCurrentView(2);
    }

    try {
      this.subsection = this.props.match.params.subsection;
    } catch (e) {}
    this.props.setPfTab(switchTabs(this.section));
    this.state = {
      allowed: null,
      settingSubsection: null,
      isTransactionEntry: false,
    };
  }

  redirect = () => {
    this.props.showSnack({
      message: "You do not have access to this portfolio.",
      variant: "error",
    });
    this.props.history.push("/");
  };

  toggleTransactionEntry = () => {
    this.setState(state => ({...state, isTransactionEntry: !state.isTransactionEntry}))
  }

  getNFTforAddresses() {
    const portfolioAddresses = getValue(this.props.addresses, [this.props.match.params.portfolio])
    const thisPort = this.props.list_portfolios.filter(v => v.id == this.props.match.params.portfolio)
    let lastTime;
    if (thisPort.length > 0) {
      lastTime = getValue(thisPort[0], ['last_updated'], null)
    }

    const eth = getValue(portfolioAddresses, ['ethereum'], [])
    if (eth && eth.length > 0) {
      this.props.getNFTCollection("ethereum", eth, lastTime)
    }
  }

  componentDidMount() {

    if (this.props.addresses) {
      this.getNFTforAddresses()
    }


    this.props.setInteraction({ label: "pin", value: true });

    const myPorts = this.props.list_portfolios.filter(
      (v) => v.id == this.props.match.params.portfolio
    );
    const isAllowed = myPorts.length > 0;
    this.setState({ allowed: isAllowed });

    const request_data = {
      period: this.props.portfoliosBalance.period,
      start: this.props.portfoliosBalance.start,
      end: this.props.portfoliosBalance.end,
      scope: this.props.portfoliosBalance.inBitcoin,
    };
    if (isAllowed) {
      this.props.setMenuPortfolio(this.portfolio_id);
      this.props.componentDataFetch(
        "get",
        2,
        this.props.currency,
        this.props.user,
        this.portfolio_id,
        null,
        request_data,
        this.props.inBitcoin
      );
    }
  }

  componentDidUpdate(prevProps) {

    if (this.props.addresses && prevProps.addresses !== this.props.addresses) {
      this.getNFTforAddresses() 
    }

    if (this.props.match.params.portfolio !== this.portfolio_id) {
      this.props.setPfTab(0);

      if (this.props.addresses) {
        this.getNFTforAddresses()
      }

      let isAllowed = false;
      if (
        this.props.list_portfolios !== undefined &&
        prevProps.list_portfolios !== this.props.list_portfolios
      ) {
        const myPorts = this.props.list_portfolios.filter(
          (v) => v.id == this.props.match.params.portfolio
        );
        isAllowed = myPorts.length > 0;
        this.setState({ allowed: isAllowed });
      }
      this.portfolio_id = this.props.match.params.portfolio;

      if (isAllowed) {
        this.props.componentDataFetch(
          "get",
          2,
          this.props.currency,
          this.props.user,
          this.props.match.params.portfolio,
          {},
          {},
          this.props.inBitcoin
        );
      }
    }
  }

  goToWallets = () => {
    this.props.history.push("/wallets");
  };

  goToErrors = () => {
    this.setState((state) => ({ ...state, subsection: "errors" }));
    this.handleTabChange(null, 6);
  };

  handleTabChange = (event, val, subview) => {
    let limited;
    try {
      limited =
        this.props.limits.transactions > this.props.limits.transaction_limit &&
        this.props.limits.transaction_limit != -1;
    } catch (e) {
      limited = undefined;
    }

    let url;
    if (subview !== undefined) {
      url = `/portfolio/${this.portfolio_id}/${switchInts(val)}/${subview}`;
    } else {
      url = `/portfolio/${this.portfolio_id}/${switchInts(val)}`;
    }
    this.props.history.push(url);
    this.props.setPfTab(val);
    this.setState(state => ({...state, isTransactionEntry: false}))
    const view = tabToView(val);

    if (limited !== true) {
      if (view !== null) {
        this.props.componentDataFetch(
          "update",
          view,
          this.props.currency,
          this.props.user,
          this.portfolio_id,
          {},
          {},
          this.props.inBitcoin
        );
      }
    }
  };

  goToSettings = () => {
    this.props.history.push("/settings/account");
  };

  render() {
    let active = null;
    try {
      active = this.props.tasks.filter(
        (v) => v.type == "connection" && v.status == "pending"
      ).length;
    } catch (e) {
      active = null;
    }

    return this.state.allowed !== null ? (
      this.state.allowed === true ? (
        <div style={{ minWidth: "100%" }}>
          {this.props.subscription !== undefined &&
          this.props.list_portfolios.filter(
            (v) => v.id == this.props.match.params.portfolio
          ).length > 0 ? (
            <PortfolioTabs
              handleChange={this.handleTabChange}
              value={this.props.tab}
              plan={this.props.subscription.plan}
              portfolioType={
                this.props.list_portfolios.filter(
                  (v) => v.id == this.props.match.params.portfolio
                )[0]["portfolio_ownership"]
              }
            />
          ) : null}

          <div className="portfolio-content">
            <div style={{ marginBottom: 24 }}>
              {active !== null &&
              active == 0 &&
              this.props.portfoliosData[this.portfolio_id] !== undefined
                ? this.props.portfoliosData[this.portfolio_id][
                    "material_errors"
                  ] > 0 &&
                  this.props.portfoliosData[this.portfolio_id][
                    "corrections"
                  ] !== null &&
                  Object.keys(
                    this.props.portfoliosData[this.portfolio_id]["corrections"]
                  ).length ===
                    0(
                      <div
                        className="basier-p3"
                        style={{
                          flex: 1,
                          border: "2px solid #e0a03d",
                          minHeight: 40,
                          marginLeft: 48,
                          marginRight: 48,
                          padding: "12px 24px",
                          borderRadius: 10,
                          backgroundColor: "#ffc1631A",
                        }}
                      >
                        <div>
                          There are missing or incorrectly entered transactions
                          in your portfolio, which are resulting in wrong or
                          negative balances. If you are using APIs, please check
                          your accounts by going to{" "}
                          <span
                            className="opacity-hover"
                            style={{
                              color: "#0078DB",
                              cursor: "pointer",
                              textDecoration: "underline",
                            }}
                            onClick={this.goToWallets}
                          >
                            Wallets
                          </span>{" "}
                          and checking your accounts. Some APIs do not import
                          all transactions, specifically those where crypto is
                          bought directly with credit cards.
                        </div>
                      </div>
                    )
                : null}

              {this.props.portfoliosData[this.portfolio_id] !== undefined ? (
                this.props.portfoliosData[this.portfolio_id]["limited"] &&
                this.props.trial === false ? (
                  <div
                    className="basier-p3"
                    style={{
                      flex: 1,
                      display: "flex",
                      alignItems: "center",
                      border: "2px solid #F9716B",
                      minHeight: 40,
                      marginLeft: 48,
                      marginRight: 48,
                      padding: "12px 24px",
                      borderRadius: 10,
                      backgroundColor: "#F9716B1A",
                    }}
                  >
                    <img
                      src={Warning}
                      alt=""
                      style={{ height: 30, width: 30, marginRight: 12 }}
                    />
                    <div style={{ color: "red", fontWeight: "bold" }}>
                      This portfolio may display incorrect data as its owner no
                      longer has an active Subscription allowing this number of
                      transactions. Please change the portfolio Owner to an
                      account with an active subscription or reactivate the
                      subscription of the current owner.
                    </div>
                  </div>
                ) : active !== null &&
                  active == 0 &&
                  this.props.portfoliosData[this.portfolio_id] !== undefined ? (
                  this.props.portfoliosData[this.portfolio_id][
                    "material_errors"
                  ] === 0 &&
                  this.props.portfoliosData[this.portfolio_id][
                    "corrections"
                  ] !== null &&
                  Object.keys(
                    this.props.portfoliosData[this.portfolio_id]["corrections"]
                  ).length > 0 && (
                    <div
                      className="basier-p3"
                      style={{
                        flex: 1,
                        border: "2px solid #F9716B",
                        minHeight: 40,
                        marginLeft: 48,
                        marginRight: 48,
                        padding: "12px 24px",
                        borderRadius: 10,
                        backgroundColor: "#F9716B1A",
                      }}
                    >
                      <div>
                        This portfolio contains some errors and innacuracies. We
                        have automatically made adjustments.{" "}
                        <span
                          onClick={this.goToErrors}
                          style={{
                            color: "#F9716B",
                            fontWeight: "bold",
                            cursor: "pointer",
                          }}
                          className="opacity-hover"
                        >
                          Please review the errors and adjustments by clicking
                          here.
                        </span>
                      </div>
                    </div>
                  )
                ) : null
              ) : null}
            </div>
            
            {!this.state.isTransactionEntry ?
            <div>
            {this.props.tab === 0 &&
              this.props.portfoliosData[this.portfolio_id] !== undefined && (
                <div>
                  <TabContainer
                    limited={true}
                    limits={this.props.limits}
                    goToSettings={this.goToSettings}
                    subscription={this.props.subscription}
                    trial={this.props.trial}
                  >
                    <PortfolioOverview portfolioId={this.portfolio_id} toggleTransactionEntry={this.toggleTransactionEntry} />
                  </TabContainer>
                </div>
              )}

            {this.props.tab === 1 && (
              <TabContainer
                limited={true}
                limits={this.props.limits}
                goToSettings={this.goToSettings}
                subscription={this.props.subscription}
              >
                <PortfolioProfit
                  portfolioId={this.portfolio_id}
                  portfoliosData={this.props.portfoliosData}
                  toggleTransactionEntry={this.toggleTransactionEntry}
                />
              </TabContainer>
            )}
            {this.props.tab === 2 && (
              <TabContainer>
                <TransactionWrapper
                  portfoliosData={this.props.portfoliosData}
                  portfolioId={this.portfolio_id}
                  toggleTransactionEntry={this.toggleTransactionEntry}
                />
              </TabContainer>
            )}
            {this.props.tab === 3 && (
              <TabContainer
                limited={true}
                limits={this.props.limits}
                goToSettings={this.goToSettings}
                subscription={this.props.subscription}
              >
                <ReportOverview portfolioId={this.portfolio_id} />
              </TabContainer>
            )}
            {this.props.tab === 5 && (
              <TabContainer
                limited={true}
                limits={this.props.limits}
                goToSettings={this.goToSettings}
                subscription={this.props.subscription}
              >
                <div>
                  <CollaboratorOverview
                    portfolioId={this.portfolio_id}
                    portfoliosData={this.props.portfoliosData}
                  />
                </div>
              </TabContainer>
            )}

            {this.props.tab === 6 && (
              <TabContainer>
                <Settings
                  selectedSetting={this.state.subsection}
                  portfolioId={this.portfolio_id}
                  portfoliosData={this.props.portfoliosData}
                />
              </TabContainer>
            )}
          </div>
          : 
            <NewTransactionEntryOverview />
          }
          </div>
        </div>
        
      ) : (
        <div
          style={{
            padding: 48,
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start",
            flexWrap: "wrap",
          }}
        >
          {this.redirect()}
          <img src={noAccess} alt="no access" style={{ width: 500 }} />
          <div style={{ width: 200, paddingTop: 60 }}>
            <h1>Sorry!</h1>
            <div className="basier-p2">
              You do not seem to have access to this portfolio. Please contact
              the portfolio's owner to request access.
            </div>
          </div>
        </div>
      )
    ) : null;
  }
}

const mapStateToProps = (state) => {
  return {
    portfoliosBalance: state.data.portfolioBalance,
    tab: state.interactions.portfolioTab,
    portfoliosData: state.data.portfoliosData,
    currency: state.auth.currency.id,
    globalAssetList: state.data.globalAssetList,
    user: state.auth.user_id,
    list_portfolios: state.auth.list_portfolios,
    inBitcoin: state.data.bitcoinPf,
    subscription: state.auth.subscription,
    limits: state.appwide.limits,
    trial: state.auth.trial,
    tasks: state.interactions.tasks,
    addresses: state.appwide.addresses,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setPfTab: (tab) => dispatch(interactionActions.setPfTab(tab)),
    setMenuPortfolio: (portfolio) =>
      dispatch(actions.setMenuPortfolio(portfolio)),
    setCurrentView: (view) => dispatch(actions.setCurrentView(view)),
    componentDataFetch: (
      scope,
      view,
      currency,
      user,
      portfolio,
      globalGraphData,
      portfolioGraphData,
      inBitcoin
    ) =>
      dispatch(
        actions.componentDataFetch(
          scope,
          view,
          currency,
          user,
          portfolio,
          globalGraphData,
          portfolioGraphData,
          inBitcoin
        )
      ),
    setInteraction: (data) => dispatch(interactionActions.setInteraction(data)),
    showSnack: (data) => dispatch(interactionActions.showSnack(data)),
    getNFTCollection: (network, address, lastTime) => dispatch(appwideActions.getNFTCollection(network, address, lastTime))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PortfolioContainer));
