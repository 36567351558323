import React from "react";
import { connect } from 'react-redux';
import currencyDict from '../../../../helpers/currency.json'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExtraProfit from "./ExtraProfit";

class ProfitLine extends React.PureComponent {

 
    state ={
        rotate: 0,
        height: 0,
    }

    expandData = () => {
        this.setState(state => ({...state, rotate: state.rotate === 0 ? 180 : 0, height: state.height === 0 ? 66 : 0 }))
    }

    render() {

        const {index, item} = this.props; 

        return (
          <div style={{borderBottom: '1px solid #CCC'}} className="light-hover">
            <div style={{ 
                height: 60,
                paddingTop: 8, 
                paddingBottom: 8,  
                display: 'flex', 
                alignItems: 'center', 
                paddingLeft: 6, 
                paddingRight: 6,
                width: '100%',
                }} 
                index={index}
                key={index}
                className="basier-p3">
          
                <div style={{flex: 1, maxWidth: '22%' }} >

                <div style={{display: 'flex', alignItems: 'center'}}>

                    {item.image !== "" && item.image !== null  ? 
                      <span><img style={{height: 25, width: 25, marginRight: 12}} src={item.image} alt=""/></span>
                      :
                      <span style={{height: 25, width: 25, marginRight: 12, display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: `${ currencyDict[item.coin_symbol] === undefined ? '16px' : currencyDict[item.coin_symbol]['symbol'].length > 1 ? '16px' : '22px'}` }}>
                          {currencyDict[item.coin_symbol] === undefined ? item.symbol : currencyDict[item.coin_symbol]['symbol'].length > 1 ? currencyDict[item.coin_symbol]['symbol'] : currencyDict[item.coin_symbol]['symbol'] }
                      </span>
                    }
                
                    <div className="truncate">
                        <div style={{marginTop:2, marginBottom: 2}}>{item.coin_symbol}</div>
                        <div className="basier-p4-caps truncate" style={{color: '#6A7E93'}}>{item.short_name}</div>    
                    </div>
                </div>
                
                </div> 

                <div style={{width: '15%', textAlign: 'right' }} >
                    {
                    
                    item.asset_type === 'fiat' || item.scope === 'position' ? 

                    <div>-</div> :

                    <div>
                        <div>
                            
                            {this.props.currency_symbol}{
                            
                            Math.abs(item.net_investment) > 1 || item.net_investment === 0? 
                            item.net_investment.toLocaleString('en', {minimumFractionDigits: 2, maximumFractionDigits: 2})
                            :
                            item.net_investment.toLocaleString('en', {minimumFractionDigits: 8, maximumFractionDigits: 8})
                        }</div> 

                        <div className="basier-p4-caps" style={{color: '#6A7E93'}}>
                            {this.props.investment !== undefined && this.props.investment !== 0 ? 
                                <span>
                                    <span>
                                {
                                (item.net_investment/this.props.investment * 100).toLocaleString('en', {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                                    </span>
                                    <span>%</span> 
                                </span>

                            :
                            '-'}
                        </div>
                    </div>

                    }

                </div> 



                <div style={{width: '15%', textAlign: 'right', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end'}} >
                    
                    <div>{
                        Math.abs(item.balance) > 1 || item.balance === 0? 
                        item.balance.toLocaleString('en', {minimumFractionDigits: 2, maximumFractionDigits: 2})
                        :
                        item.balance.toLocaleString('en', {minimumFractionDigits: 8, maximumFractionDigits: 8})
                    }</div> 

                    <div className="basier-p4-caps" style={{color: '#6A7E93'}}>
                        {item.scope === 'position' ? 
                            item.quote_ticker
                        : 
                            this.props.currency_symbol
                        }&nbsp;
                        {item.purchase_price > 1 || item.purchase_price === 0 ? 
                            item.purchase_price.toLocaleString('en', {maximumFractionDigits: 2, minimumFractionDigits: 2})
                            : 
                            item.purchase_price.toLocaleString('en', {maximumFractionDigits: 6, minimumFractionDigits: 6}) }
                    </div>

                </div> 
                
                <div style={{width: '15%', textAlign: 'right'}}>
                    <div  style={{textAlign: 'right', color: item.active_profit === 0 ? 'black' :  item.active_profit > 0 ? '#4CDAC1' : '#F9716B', fontWeight: item.active_profit !== 0 ? 900 : 400   }} >

                    {item.scope === 'position' ? 
                            this.props.currency_symbol
                        : 
                            this.props.currency_symbol
                        }&nbsp;
                    {Math.abs(item.active_profit) > 1 || item.active_profit === 0 ? 
                        item.active_profit.toLocaleString('en', {maximumFractionDigits: 2, minimumFractionDigits: 2})
                        : 
                        item.active_profit.toLocaleString('en', {maximumFractionDigits: 6, minimumFractionDigits: 6}) }

                        
                    </div>

                    <div className="basier-p4-caps" style={{color: '#6A7E93'}}>
                        {item.net_investment !== undefined && item.net_investment !== 0  && item.asset_type !== 'fiat'? 
                            <span>
                                <span>
                            {
                            (item.active_profit/item.net_investment * 100).toLocaleString('en', {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                                </span>
                                <span>%</span> 
                            </span>

                        :
                        '-'}
                    </div>
                </div>



                <div style={{width: '15%', textAlign: 'right'}}>
                    <div  style={{textAlign: 'right', color: item.realized_profit === 0 ? 'black' :  item.realized_profit > 0 ? '#4CDAC1' : '#F9716B', fontWeight: item.realized_profit !== 0 ? 900 : 400   }} >

                    {item.scope === 'position' ? 
                            this.props.currency_symbol
                        : 
                            this.props.currency_symbol
                        }&nbsp;
                    {Math.abs(item.realized_profit) > 1 || item.realized_profit === 0 ? 
                        item.realized_profit.toLocaleString('en', {maximumFractionDigits: 2, minimumFractionDigits: 2})
                        : 
                        item.realized_profit.toLocaleString('en', {maximumFractionDigits: 6, minimumFractionDigits: 6}) }

                        
                    </div>

                    <div className="basier-p4-caps" style={{color: '#6A7E93'}}>
                        {item.net_investment !== undefined && item.net_investment !== 0  && item.asset_type !== 'fiat'? 
                            <span>
                                <span>
                            {
                            (item.realized_profit/item.net_investment * 100).toLocaleString('en', {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                                </span>
                                <span>%</span> 
                            </span>

                        :
                        '-'}
                    </div>
                </div>

                <div style={{width: '15%', textAlign: 'right'}}>
                    <div  style={{textAlign: 'right', color: item.total_profit === 0 ? 'black' :  item.total_profit > 0 ? '#4CDAC1' : '#F9716B', fontWeight: item.total_profit !== 0 ? 900 : 400   }} >

                    {item.scope === 'position' ? 
                            this.props.currency_symbol
                        : 
                            this.props.currency_symbol
                        }&nbsp;
                    {Math.abs(item.total_profit) > 1 || item.total_profit === 0 ? 
                        item.total_profit.toLocaleString('en', {maximumFractionDigits: 2, minimumFractionDigits: 2})
                        : 
                        item.total_profit.toLocaleString('en', {maximumFractionDigits: 6, minimumFractionDigits: 6}) }

                        
                    </div>

                    <div className="basier-p4-caps" style={{color: '#6A7E93'}}>
                        {item.net_investment !== undefined && item.net_investment !== 0  && item.asset_type !== 'fiat'? 
                            <span>
                                <span>
                            {
                            (item.total_profit/item.net_investment * 100).toLocaleString('en', {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                                </span>
                                <span>%</span> 
                            </span>

                        :
                        '-'}
                    </div>
                </div>

                <div style={{width: 50, textAlign: 'right'}}>
                    {item.scope !== 'position' && 
                        <ExpandMoreIcon onClick={this.expandData} className="opacity-hover noselect" style={{color: '#6A7E93', transform: `rotate(${this.state.rotate}deg)`, cursor: 'pointer', transition: 'transform 120ms ease-in-out', willChange: 'transform' }} />
                    }
                </div>

            </div>

            <div style={{height: this.state.height, transition: 'height 30ms ease-in-out', willChange: 'height'}}>
              {this.state.height === 66 && item.scope !== 'position' && 
              <ExtraProfit item={item} />}
            </div>
          </div>


        )
    }
}

const mapStateToProps = state => {
    return {
        currency_symbol: state.auth.currency.symbol,
    };
};


export default connect(
    mapStateToProps,
    null
)(ProfitLine);