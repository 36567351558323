import React from 'react';
import { connect } from 'react-redux';
import DateTimePicker from 'react-datetime';
import 'react-datetime/css/react-datetime.css'
import FiatLarge from '../../../../AppWide/FiatLarge';
import Oops from '../../../../../Media/oops.svg'
import okGreen from '../../../../../Media/okGreen.svg'
import { saveAs } from 'file-saver';
import selectBackground from '../../../../../Media/selectBackground.svg';
import loadingSpinner from '../../../../../Media/contentLoader.svg';
import * as interActions from "../../../../../store/actions/interactions";
import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from '@material-ui/core/styles';

const ColorLinearProgress = withStyles({
    root: {
        height: 3
    },
    colorPrimary: {
      backgroundColor: 'rgba(0, 120, 219, 0.2)',
    },
    barColorPrimary: {
      backgroundColor: '#0078DB',
    },
  })(LinearProgress);

var moment = require('moment');
require('moment/locale/en-gb');

class PortfolioReport extends React.PureComponent {

    state = {

        simulations: 1000,
        threshold: 5,
        price_months: 12,
        simu_days: 30, 
        loading: false,
        completed: false,

    }

    updateSimuData = (label, val) => {

        this.setState(state => ({...state, [label]: val}))
    }

    componentDidMount() {

        let totalFiat = null
        if (this.props.initialFiat !== undefined && this.props.initialFiat !== null && this.props.initialFiat !== []) {
            totalFiat = [{value: 'bitcoin', label: 'BTC', full_name: 'Bitcoin'}, ...this.props.initialFiat]
        } else {
            totalFiat = null
        }
        
        const now = new Date()
        let oneYearFromNow = new Date()
        oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() - 1);
        const moment_date_now = moment(now);
        const moment_date_before = moment(oneYearFromNow);
        const serverStart = moment_date_now.local().format("YYYY-MM-DD")
        const serverEnd = moment_date_before.local().format("YYYY-MM-DD")
        this.setState(state => ({...state, end: serverStart, currenciesData: totalFiat}))
        this.setState(state => ({...state, start: serverEnd, currenciesData: totalFiat}))

    }
    
    componentDidUpdate(prevProps) {
        if (prevProps.initialFiat !== this.props.initialFiat) {
            let totalFiat = null
            if (this.props.initialFiat !== undefined && this.props.initialFiat !== null && this.props.initialFiat !== []) {
                totalFiat = [{value: 'BTC', label: 'BTC', full_name: 'Bitcoin'}, ...this.props.initialFiat]
            } else {
                totalFiat = null
            }
            this.setState(state => ({...state, currenciesData: totalFiat}))
        }

    }
    handleChange = (date, scope) => {
        //console.log(date)
        const testStateDate = moment( date, "YYYY-MM-DD", true).local()

        if (testStateDate.isValid()) {
            let dateRedux = moment(date);
            const serverDate = dateRedux.local().format("YYYY-MM-DD")
            this.setState(state => ({...state, [scope]: serverDate}))
            if (dateRedux.isValid()) {     
                this.setState(state => ({...state, validation: {...state.validation, date: true}}))         
                const serverDate = dateRedux.local().format("YYYY-MM-DD")
                this.setState(state => ({...state, [scope]: serverDate}))
                const now = new Date()
                if (date > now) {
                    this.setState(state => ({...state, futurDate: true}))    
                } else {
                    this.setState(state => ({...state, futurDate: false}))   
                }
                
            } else {

                this.setState(state => ({...state, validation: {...state.validation, date: false}}))    
            }

        } else {

            this.setState(state => ({...state, validation: {...state.validation, date: false}}))   

        }
    }


    
    updateData = (val) => {

        this.setState(state => ({...state, currency: val}))

    }

    generateReport = () => {

        
        const data = {
            number_sim: this.state.simulations, 
            sim_days: this.state.simu_days, 
            var_ths: this.state.threshold, 
            price_months: this.state.price_months,
            portfolio: this.props.portfolioId, 
            user: this.props.user_id, 
            scope: 'valueAtRisk'
        }

        this.props.requestAnalysis(data)
        .then(
            json => {
                this.setState(state => ({...state, error: false, completed: true}))
            }
        )
        .catch(
            err => {
                this.setState(state => ({...state, error: true, completed: true}))
            }
        )

    }

    render () {

        return (

            <div style={{width: '100%', height: 'inherit', display: 'flex', alignItems: 'center'}}>

                <div style={{height: '90%', width: 300, borderRight: '1px solid #E1E5E9', paddingTop: 12, paddingBottom: 12, paddingLeft: 24, paddingRight: 24, display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>

                    <div>
                    <h4>Parameters</h4>

                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        <div style={{marginTop: 24, marginBottom: 4}} className="basier-p4-caps"># simulations (max 10,000)</div>
                        <input value={this.state.simulations || ""} onChange={(e) => this.updateSimuData('simulations', e.target.value)} className="light-input" style={{width: 250}} />

                        <div style={{marginTop: 24, marginBottom: 4}} className="basier-p4-caps">VaR Threshold (%)</div>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <input value={this.state.threshold || ""} onChange={(e) => this.updateSimuData('threshold', e.target.value)}  className="light-input" style={{width: 224, textAlign: 'left', paddingRight: 6}} /><span style={{marginLeft: 6, width: 20}}>%</span>
                        </div>

                        <div style={{marginTop: 24, marginBottom: 4}} className="basier-p4-caps">Sample prices from last</div>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                        <input value={this.state.price_months || ""} onChange={(e) => this.updateSimuData('price_months', e.target.value)} className="light-input" style={{width: 175, textAlign: 'left', paddingRight: 6}} /><span style={{marginLeft: 6, width: 75}}>Months</span>
                        </div>

                        <div style={{marginTop: 24, marginBottom: 4}} className="basier-p4-caps">Simulation duration</div>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                        <input value={this.state.simu_days || ""} onChange={(e) => this.updateSimuData('simu_days', e.target.value)} className="light-input" style={{width: 175, textAlign: 'left', paddingRight: 6}} /><span style={{marginLeft: 6, width: 75}}>Days</span>
                        </div>

                    </div>

                    </div>
                    
                    {this.state.completed ? 
                    
                    <div>
                        <div className='button-next-blue' onClick={() => this.props.backAction()} 
                            style={{height: 40, borderRadius: 35, marginTop: 0, cursor: 'pointer'}}>
                            Back
                        </div>
                    </div>

                    :

                    <div>
                        <div className='button-next-blue' onClick={() => this.generateReport()} 
                            style={{height: 40, borderRadius: 35, marginTop: 0, cursor: 'pointer'}}>
                            Run Simulation
                        </div>
                    </div>
                    }


                </div>

                <div style={{height: '95%', padding: 24, width: '100%', display: 'flex', flexDirection: 'column'}}>
                    
                    
                    
                    {   
                        this.state.loading ? 
                        <div style={{width: '100%', height: 'inherit', flexDirection: 'column', display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center'}}>
                            <img style={{height: 120, with: 120}} src={loadingSpinner} alt="Loading..."/>
                            <div className="basier-p2" style={{marginTop: 6}}>Loading data</div>
                        </div>
                        :
                        this.state.completed === false ? 

                        <div style={{width: '100%', display: 'flex', justifyContent: 'center', textAlign: 'center', flexDirection: 'column'}}>
                            <h3>Please select parameters and run the simulation.</h3>
                            <img src={selectBackground} alt="" style={{height: 400}} />

                            <div className="basier-p2" style={{marginTop: 24, color: 'red'}}>
                                Warning: You can only run one MonteCarlo Analysis per hour, with a maximum of 4 per day.
                            </div>
                        </div>

                        : 

                        this.state.error ? 

                        <div style={{width: '100%', display: 'flex', justifyContent: 'center', textAlign: 'center', flexDirection: 'column'}}>
                            <img style={{marginTop: 24, height: 150}} src={Oops} alt="" />
                            <h3>Unavailable data</h3>
                            <div className="basier-p2">
                                This data is currently unavailable. This might be a temporary issue as we update our data. Please try again in a few minutes.
                            </div>
                        </div>

                        :   

                            <div style={{width: '100%', display: 'flex', justifyContent: 'center', textAlign: 'center', flexDirection: 'column'}}>
                                <img style={{marginTop: 24, height: 150}} src={okGreen} alt="" />
                                <h3>Simulation in progress!</h3>
                                <div className="basier-p2">
                                    Your simulation has been queued and will be ready soon. The estimated time of completion for this particular simulation will be at least of <span style={{color: '#0078DB'}}>{((Math.min(10000, this.state.simulations)/1000) * 30 / 60).toLocaleString('en', {minimumFractionDigits: 2, maximumFractionDigits: 2})} minutes.</span>
                                </div>
                                <div className="basier-p2" style={{marginTop: 24, color: '#F9716B'}}>
                                    Warning: You can only run one MonteCarlo Analysis per hour, with a maximum of 4 per day.
                                </div>
                            </div>
                    
                        }
                    
                </div>

            </div>
        )
    }


}

const mapStateToProps = state => {
    return {
        initialFiat: state.data.fiatList,
        user_id: state.auth.user_id, 
        token: state.auth.token,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        showSnack: (data) => dispatch(interActions.showSnack(data)), 
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(PortfolioReport)
