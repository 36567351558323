import polly from "polly-js";
import axios from "axios";
import visa from "../Media/visa.png";
import master from "../Media/master.png";
import diners from "../Media/diners.png";
import discover from "../Media/discover.png";
import amex from "../Media/amex.png";
import jcb from "../Media/jcb.png";
import React from "react";

export const delay = (ms) => new Promise((r) => setTimeout(r, ms));

export const getValue = (object, keys, defaultValue) => {
  let ret;
  let i = 0;
  const len = keys.length;
  try {
    while (i < len) {
      ret = object[keys[i]];
      object = ret;
      i++;
    }
  } catch (e) {}
  if (ret === undefined && defaultValue !== undefined) {
    ret = defaultValue;
  }
  return ret;
};

export const reverseFormatNumber = (val, locale) => {
  var group = new Intl.NumberFormat(locale).format(1111).replace(/1/g, "");
  var decimal = new Intl.NumberFormat(locale).format(1.1).replace(/1/g, "");
  var reversedVal = val.replace(new RegExp("\\" + group, "g"), "");
  reversedVal = reversedVal.replace(new RegExp("\\" + decimal, "g"), ".");
  return Number.isNaN(reversedVal) ? 0 : reversedVal;
};

export const beautifyNumber = (number, digits) => {
  const formattedNumber = number.toLocaleString("en", {
    maximumFractionDigits: digits,
    minimumFractionDigits: digits,
  });
  const split = formattedNumber.split(".");
  const body = split[0];
  const decimal = split[1];
  let color1 = "black";
  let color2 = "rgba(0,0,0,0.55)";
  let size1 = "1em";
  let size2 = "0.9em";
  let decimalColor = color2;
  let decimalSize = size1;
  if (number < 1) {
    color2 = "black";
    color1 = "rgba(0,0,0,0.55)";
    decimalColor = color1;
    size1 = "0.9em";
    size2 = "1em";
    decimalSize = size2;
  }

  if (digits === undefined) {
    digits = number > 1 ? 2 : 8;
  }

  return (
    <span>
      <span style={{ fontSize: size1, color: color1 }}>{body}</span>
      <span style={{ color: decimalColor, fontSize: decimalSize }}>.</span>
      <span style={{ fontSize: size2, fontWeight: 400, color: color2 }}>
        {decimal}
      </span>
    </span>
  );
};

export const formattedNumber = (number, digits) => {
  if (digits === undefined) {
    digits = Math.abs(number) > 1 ? 2 : number !== 0 ? 6 : 2;
  }
  if (number !== null && number !== undefined) {
    return number.toLocaleString("en", {
      maximumFractionDigits: digits,
      minimumFractionDigits: digits,
    });
  }
  return null;
};

export const getItem = (dict, key) => {
  if (dict[key] !== undefined) {
    return dict[key];
  } else {
    return null;
  }
};

export const isObjectEqual = (obj1, obj2) => {
  // are the references the same?
  if (obj1 === obj2) {
    return true;
  }

  // does it contain objects with the same keys?
  const item1Keys = Object.keys(obj1).sort();
  const item2Keys = Object.keys(obj2).sort();

  if (!isArrayEqual(item1Keys, item2Keys)) {
    return false;
  }

  // does every object in props have the same reference?
  return item2Keys.every((key) => {
    const value = obj1[key];
    const nextValue = obj2[key];

    if (value === nextValue) {
      return true;
    }

    // special case for arrays - check one level deep
    return (
      Array.isArray(value) &&
      Array.isArray(nextValue) &&
      isArrayEqual(value, nextValue)
    );
  });
};

const isArrayEqual = (array1 = [], array2 = []) => {
  if (array1 === array2) {
    return true;
  }

  // check one level deep
  return (
    array1.length === array2.length &&
    array1.every((item, index) => item === array2[index])
  );
};

export function nFormatter(num, digits) {
  var si = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: " Mn" },
    { value: 1e9, symbol: " Bn" },
    { value: 1e12, symbol: " Tn" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];
  var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var i;
  for (i = si.length - 1; i > 0; i--) {
    if (Math.abs(num) >= si[i].value) {
      break;
    }
  }
  return (
    (Math.abs(num) / si[i].value).toFixed(digits).replace(rx, "$1") +
    si[i].symbol
  );
}

export const loadData = (url, headers) => {
  return polly()
    .waitAndRetry([100, 200, 400, 1000])
    .executeForPromise(async () => {
      const rsp = await fetch(url, { headers: headers });
      if (rsp.ok) {
        return rsp.json();
      }
      return Promise.reject(rsp);
    })
    .catch(e => console.log(''))
};

export const slowLoadData = (url, headers) => {
  return polly()
    .waitAndRetry([1000, 2000, 4000, 7000])
    .executeForPromise(async () => {
      const rsp = await fetch(url, { headers: headers });
      if (rsp.ok) {
        return rsp.json();
      }
      return Promise.reject(rsp);
    })
    .catch(e => console.log('err', e))
};

export const postData = (url, data, headers) => {
  return polly()
    .waitAndRetry([100, 200, 400, 1000])
    .executeForPromise(async () => {
      const rsp = await fetch(url, {
        method: "POST",
        mode: "cors",
        credentials: "include",
        cache: "no-store",
        body: JSON.stringify(data),
        headers: headers,
      });

      if (rsp.ok) {
        return rsp.json();
      }

      return Promise.reject(rsp);
    });
};

export const api_timeout_caller = (last_call) => {
  if (last_call === null || last_call === undefined) {
    return true;
  }

  const compareDate = Date.parse(last_call);
  const date = new Date();
  const compare = (date - compareDate) / 1000;

  if (compare > 60) {
    return true;
  } else {
    return false;
  }
};

export const capitalize = (s) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

function checkValue(str, max) {
  if (str.charAt(0) !== "0" || str === "00") {
    var num = parseInt(str);
    if (isNaN(num) || num <= 0 || num > max) num = 1;
    str =
      num > parseInt(max.toString().charAt(0)) && num.toString().length == 1
        ? "0" + num
        : num.toString();
  }
  return str;
}

//On input
export const parseDate = (e) => {
  this.type = "text";
  var input = this.value;
  if (/\D\/$/.test(input)) input = input.substr(0, input.length - 3);
  var values = input.split("/").map(function (v) {
    return v.replace(/\D/g, "");
  });
  if (values[0]) values[0] = checkValue(values[0], 12);
  if (values[1]) values[1] = checkValue(values[1], 31);
  var output = values.map(function (v, i) {
    return v.length === 2 && i < 2 ? v + " / " : v;
  });
  this.value = output.join("").substr(0, 14);
};

//onBlur
export const onBlurInput = (e) => {
  this.type = "text";
  var input = this.value;
  var values = input.split("/").map(function (v, i) {
    return v.replace(/\D/g, "");
  });
  var output = "";

  if (values.length === 3) {
    var year =
      values[2].length !== 4 ? parseInt(values[2]) + 2000 : parseInt(values[2]);
    var month = parseInt(values[0]) - 1;
    var day = parseInt(values[1]);
    var d = new Date(year, month, day);
    if (!isNaN(d)) {
      document.getElementById("result").innerText = d.toString();
      var dates = [d.getMonth() + 1, d.getDate(), d.getFullYear()];
      output = dates
        .map(function (v) {
          v = v.toString();
          return v.length === 1 ? "0" + v : v;
        })
        .join(" / ");
    }
  }
  this.value = output;
};

export const dateParser = (date) => {
  const language = navigator.language || "en";
  const options = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };
  const parseDate = new Date(date);
  const showDate = parseDate.toLocaleDateString(language, options);

  return showDate;
};

export const siwtchTransactionType = (type) => {
  switch (type) {
    case "deposit": {
      return ["Deposit", "#C174FD"];
    }
    case "withdraw": {
      return ["Withdraw", "#0078DB"];
    }
    case "buy": {
      return ["Buy", "#4CDAC1"];
    }
    case "long": {
      return ["Long", "#4CDAC1"];
    }
    case "ico": {
      return ["ICO", "#4CDAC1"];
    }
    case "sell": {
      return ["Sell", "#F9716B"];
    }
    case "short": {
      return ["Short", "#F9716B"];
    }
    case "transfer": {
      return ["Transfer", "#6A7E93"];
    }
    case "mining": {
      return ["Mining / Rewards", "#C174FD"];
    }
    case "fee": {
      return ["Fee", "#F9716B"];
    }
    case "dividend": {
      return ["Dividend / Interest", "#C174FD"];
    }
    case "income": {
      return ["Income", "#C174FD"];
    }
    case "gift": {
      return ["Gift", "#C174FD"];
    }
    case "airdrop": {
      return ["Airdrop", "#C174FD"];
    }
    case "fork": {
      return ["Fork", "#C174FD"];
    }
    case "purchase": {
      return ["Purchase", "#0078DB"];
    }
    case "donation": {
      return ["Donation", "#0078DB"];
    }
    case "lost": {
      return ["Loss", "#F9716B"];
    }
    case "pnl_loss": {
      return ["P&L Loss", "#F9716B"];
    }
    case "pnl_profit": {
      return ["P&L Profit", "#4CDAC1"];
    }
    case "borrow": {
      return ["Borrow", "black"];
    }
    case "repay": {
      return ["Loan Repay", "black"];
    }
    case "interest_payment": {
      return ["Interest Paid", "#F9716B"];
    }
    case "margin_buy": {
      return ["Margin Buy", "#4CDAC1"];
    }
    case "margin_sell": {
      return ["Margin Sell", "#F9716B"];
    }
    case 'liquidity_in': {
      return ['Liquidity In', "#C174FD"];
    }
    case 'liquidity_out': {
      return ['Liquidity Out', "#0078DB"];
    }

    default: {
      return [null, null];
    }
  }
};

export const renderAuditType = (scope, type) => {
  let type_caller = null;
  switch (type) {
    case "create": {
      type_caller = "New";
      break;
    }
    case "edit": {
      type_caller = "Edited";
      break;
    }
    case "delete": {
      type_caller = "Deleted";
      break;
    }
    default:
      type_caller = null;
  }

  let scope_caller = null;
  switch (scope) {
    case "portfolio": {
      scope_caller = "portfolio";
      break;
    }
    case "transaction": {
      scope_caller = "transaction";
      break;
    }
    case "upload": {
      scope_caller = "upload";
      break;
    }
    case "wallet": {
      scope_caller = "wallet";
      break;
    }
    case "exchange": {
      scope_caller = "account";
      break;
    }
    case "collaborator": {
      scope_caller = "collaborator";
      break;
    }
    case "permission": {
      scope_caller = "collaborator";
      break;
    }
    default:
      scope_caller = null;
  }

  const default_caller = `${type_caller} ${scope_caller}`;

  switch (true) {
    case scope === "create" &&
      (type === "collaborator" || type === "permission"): {
      return "Invited collaborator";
    }
    case scope === "edit" &&
      (type === "collaborator" || type === "permission"): {
      return "Edited collaborator";
    }
    case scope === "delete" &&
      (type === "collaborator" || type === "permission"): {
      return "Deleted collaborator";
    }
    default:
      return default_caller;
  }
};

export const handleNotifClick = (notif, this2) => {
  switch (true) {
    case notif.action === "portfolio_request": {
      this2.toggleReadUnread(null, notif, true);
      this2.props.history.push("/invitations");
      this2.props.handleClose();
      break;
    }
    default: {
      this2.toggleReadUnread(null, notif, true);
      this2.props.handleClose();
    }
  }
};

export const paymentLogos = (id) => {
  switch (id) {
    case "visa": {
      return <img src={visa} alt="" style={{ width: 30, marginRight: 12 }} />;
    }
    case "mastercard": {
      return <img src={master} alt="" style={{ width: 30, marginRight: 12 }} />;
    }
    case "american express": {
      return <img src={amex} alt="" style={{ width: 30, marginRight: 12 }} />;
    }
    case "diners club": {
      return <img src={diners} alt="" style={{ width: 30, marginRight: 12 }} />;
    }
    case "discover": {
      return (
        <img src={discover} alt="" style={{ width: 30, marginRight: 12 }} />
      );
    }
    case "jcb": {
      return <img src={jcb} alt="" style={{ width: 30, marginRight: 12 }} />;
    }
    default: {
      return <img src={visa} alt="" style={{ width: 30, marginRight: 12 }} />;
    }
  }
};

export const specificMessageRenderer = (exchangeId) => {
  switch (exchangeId) {
    case "binance": {
      return "WARNING: Binance API does not support credit card transactions. Please add those transactions manually or through import. This API connection will take some time to initialize (up to 30 minutes). We will proceed with it in the background.";
    }
    case "binance_futures": {
      return "WARNING: This will only connect your Binance Futures account - not your Binance account. We recommend you connect your Binance account which will automatically connect Binance Futures. Only use this option if you are trying not to connect Binance. You will need to manually enter and deposits into Binance Futures this way.";
    }
    case "bittrex": {
      return "Bittrex limits the historical import of withdraws and trades to the last 30 days. Please use manual entries or CSV import after adding this account to complete your historical transactions for this account.";
    }
    case "bibox": {
      return "Bibox limits the historical import of transactions to the last 3 months. Please use manual entries or CSV import after adding this account to complete your historical transactions for this account. The API imports only support Spot trading at the moment.";
    }
    case "huobi": {
      return "Due to Huobi's limitations, we can only import new transactions to you account. To import past transactions in a complete and error-free way, please contact Huobi support and request your transaction history. The API imports only support Spot trading at the moment.";
    }
    case "kucoin": {
      return "Kucoin's API only returns transactions made on or after 18th February 2019. Please use manual entries or CSV import after adding this account to complete your historical transactions for this account. The API imports only support Spot trading at the moment.";
    }
    default:
      return null;
  }
};
