import React from "react";
import { connect } from "react-redux";
import WalletBalanceLine from "./BalanceLine";
import contentLoader from "../../../../Media/contentLoader.svg";
import * as interActions from "../../../../store/actions/interactions";
import * as appwideActions from "../../../../store/actions/appwide";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Hidden from "@material-ui/core/Hidden";
import Divider from "@material-ui/core/Divider";
import { backendUrl, cbKey } from "../../../../variables";
import axios from "axios";
import fieldValidator from "../../../../helpers/validators";
import SingleSelect from "../../../AppWide/SingleSelect";
import whiteLoader from "../../../../Media/whiteloader.gif";
import coinbaseWhite from "../../../../Media/coinbaseWhite.png";


const depositTypes = [
  {value: "deposit", label: "Deposit"},
  {value: "mining", label: "Mining/Rewards"},
  {value: "dividend", label: "Dividend"},
  {value: "income", label: "Income"},
  {value: "gift", label: "Gift"},
  {value: "airdrop", label: "Airdrop"},
]

const styles = (theme) => ({
  root: {
    "& label.Mui-focused": {
      color: "green",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "green",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "red",
      },
      "&:hover fieldset": {
        borderColor: "yellow",
      },
      "&.Mui-focused fieldset": {
        borderColor: "green",
      },
    },
  },

  inputSmall: {
    width: 200,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginTop: 12,
    },
    [theme.breakpoints.up("md")]: {
      width: 200,
      marginRight: 12,
    },
  },
  inputLarge: {
    width: 412,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginTop: 12,
    },
    [theme.breakpoints.up("md")]: {
      width: 412,
      marginRight: 12,
    },
  },
  inputsWrapper: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.up("md")]: {
      width: 500,
    },
  },
  buttonWidth: {
    [theme.breakpoints.down("sm")]: {
      width: 130,
    },
  },
  buttonWrapper: {
    [theme.breakpoints.down("sm")]: {
      //width: '100%',
      marginRight: 0,
    },
    [theme.breakpoints.up("md")]: {
      paddingRight: 24,
    },
  },
  paddingSmall: {
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 12,
      paddingRight: 12,
    },
  },
});

class SettingComponent extends React.PureComponent {
  state = {
    edit: false,
    changePass: false,
    name: this.props.data.name,
    address: this.props.data.address,
    oldPass: null,
    newPass: null,
    nameLoad: false,
    errorPass: null,
    passLoad: false,
    emailChangeMessage: false,
    settingsEdit: false,
    currencySelect: this.props.currency,
    settingsLoad: false,
    modalType: null,
    openModal: false,
    editData: {},
    toggleConenct: false,
    deposit_type: this.props.data.deposit_type || "deposit"
  };

  componentDidMount() {
    const scope_pf = [];
    this.props.portfolios.map((v) => {
      scope_pf.push({
        value: v.id,
        label: v.name,
      });
    });
    this.setState((state) => ({ ...state, scope_pf: scope_pf }));

    if (this.props.data.connected === true) {
      let import_target = null;
      try {
        const target_port = this.props.portfolios.filter(
          (v) => v.id == this.props.data.target_id
        )[0];
        import_target = {
          value: target_port.id,
          label: target_port.name,
        };
        this.setState((state) => ({
          ...state,
          import_target: import_target,
          import_to_id: target_port.id,
        }));
      } catch (e) {
        this.setState((state) => ({
          ...state,
          import_target: null,
          import_to_id: null,
        }));
      }
    } else {
      this.setState((state) => ({
        ...state,
        import_target: null,
        import_to_id: null,
      }));
    }
  }

  toggleConenctSetup = () => {
    this.setState((state) => ({
      ...state,
      toggleConenct: !state.toggleConenct,
    }));
  };
  changeValue = (label, e) => {
    e.persist();

    if (label === "address") {
      this.setState((state) => ({
        ...state,
        [label]: e.target.value,
        editData: {
          ...state.editData,
          [label]: e.target.value,
          public: e.target.value,
        },
      }));
    } else {
      this.setState((state) => ({
        ...state,
        [label]: e.target.value,
        editData: { ...state.editData, [label]: e.target.value },
      }));
    }
  };

  toggleEdit = () => {
    this.setState((state) => ({ ...state, edit: !state.edit }));
  };

  togglePass = () => {
    this.setState((state) => ({
      ...state,
      changePass: !state.changePass,
      oldPass: null,
      newPass: null,
    }));
  };

  togglePrefs = () => {
    this.setState((state) => ({ ...state, settingsEdit: !state.settingsEdit }));
  };

  editName = (url) => {
    let editData = {
      ...this.state.editData,
      user: this.props.user_id,
      id: this.props.data.id,
    };

    const exchange_val = fieldValidator(this.state.editData["name"], [
      "isNotEmpty",
    ]);
    this.setState((state) => ({ ...state, editNameError: !exchange_val }));

    if (exchange_val) {
      this.setState((state) => ({ ...state, nameLoad: true }));

      let headers = { "content-type": "application/json" };
      if (this.props.token !== null && this.props.token !== undefined) {
        headers["Authorization"] = `Token ${this.props.token}`;
      }

      const url_call = url;

      axios
        .patch(url_call, editData, { headers: headers })
        .then((json) => {
          this.setState((state) => ({
            ...state,
            nameLoad: false,
            error: false,
            edit: false,
          }));
          this.props.getWallets(this.props.user_id);
          this.props.showSnack({
            message: "Profile updated succesfully",
            variant: "success",
          });
        })
        .catch((e) => {
          this.setState((state) => ({
            ...state,
            nameLoad: false,
            error: true,
          }));
          this.props.showSnack({
            message:
              "Error in profile update. Please try again in a few minutes",
            variant: "error",
          });
        });
    }
  };
  updateProfile = () => {
    if (this.props.type === "exchange") {
      this.editName(`${backendUrl}/v1/exchange_accounts/`);
    } else if (this.props.type === "storage") {
      this.editName(`${backendUrl}/v1/wallets/`);
    }
  };

  changePass = () => {
    this.setState((state) => ({ ...state, passLoad: true, errorPass: null }));
    const postData = {
      new_details: {
        public: this.state.editData.public,
        private: this.state.editData.private,
        passphrase: this.state.editData.passphrase,
      },
      user: this.props.user_id,
      scope: this.props.type,
    };
    let headers = { "content-type": "application/json" };
    if (this.props.token !== null && this.props.token !== undefined) {
      headers["Authorization"] = `Token ${this.props.token}`;
    }

    const url_call = `${backendUrl}/v1/connectors/change/${this.props.data.id}/`;

    axios
      .patch(url_call, postData, { headers: headers })
      .then((json) => {
        if (this.props.type === "storage") {
          this.props.getWallets(this.props.user_id);
        }
        setTimeout(() => {
          this.setState((state) => ({
            ...state,
            passLoad: false,
            changePass: false,
            errorPass: null,
          }));
          this.props.showSnack({
            message: "Connection credentials changed succesfully",
            variant: "success",
          });
        }, 1000);
      })
      .catch((e) => {
        if (
          e.response.data["data"] === undefined ||
          e.response.data["data"] === null
        ) {
          this.setState((state) => ({
            ...state,
            passLoad: false,
            errorPass:
              "Error while changing connection credentials. Please try again in a few minutes, or contact support.",
          }));
        } else {
          this.setState((state) => ({
            ...state,
            passLoad: false,
            errorPass: e.response.data["data"],
          }));
        }
      });
  };

  restore = () => {
    const confirmationMessage =
      "Are you sure you want to restore this account. This will delete all imports and transactions and re-import them. Any changes you have made will be lost.";
    if (this.props.client === "mobile") {
      if (window.confirm(confirmationMessage)) {
        this.refreshAccount();
      }
      this.props.handleBack();
    } else {
      this.props.toggleModal({
        type: "confirmation_modal",
        payload: {
          message: confirmationMessage,
          handleConfirm: this.refreshAccount,
        },
        open: true,
      });
    }
  };

  refreshAccount = () => {
    this.setState((state) => ({ ...state, refreshLoad: true }));
    let headers = { "content-type": "application/json" };
    if (this.props.token !== null && this.props.token !== undefined) {
      headers["Authorization"] = `Token ${this.props.token}`;
    }
    let url_call = `${backendUrl}/v1/account/${this.props.data.id}/refresh/`;

    const data = {
      scope: this.props.type,
      user_id: this.props.user_id,
      force: true,
    };
    axios.post(url_call, data, { headers: headers }).then((json) => {
      //this.props.getWallets(this.props.user_id)

      const currentTasks = this.props.tasks;
      let newTasks = [...currentTasks];
      newTasks = [
        {
          description: `Restoring Account`,
          sub: `${this.props.data.source_name}`,
          status: "pending",
          type: "connection",
          task_id: this.props.data.id,
        },
        ...currentTasks,
      ];

      this.props.setInteraction({ label: "tasks", value: newTasks });
      this.setState((state) => ({ ...state, refreshLoad: false }));
    });
  };

  goToCoinbase = () => {
    this.setState((state) => ({ ...state, loading: true }));
    const url = `${backendUrl}/api/oauth/coinbase/setup/?existing=${this.props.data.id}&update=1`;
    let headers = {
      "content-type": "application/json",
    };
    headers["Authorization"] = `Token ${this.props.token}`;

    let submitData = {
      ...this.state.data,
      user_id: this.props.user_id,
    };

    if (submitData.scope === "all") {
      submitData["portfolio"] = this.state.portfolio_id;
    }

    axios.post(url, submitData, { headers: headers }).then((res) => {
      const data = res.data.data;
      const url = `https://www.coinbase.com/oauth/authorize?response_type=code&client_id=${cbKey}&redirect_uri=${backendUrl}/api/oauth/coinbase/success/&state=${data["account_id"]}&account=all&scope=wallet:accounts:read,wallet:buys:read,wallet:deposits:read,wallet:sells:read,wallet:transactions:read,wallet:trades:read,wallet:withdrawals:read,wallet:addresses:read,wallet:payment-methods:read`;
      //console.log(url)
      window.location.replace(url);
    });
  };

  selectPort = (val) => {
    if (val !== null && val !== undefined) {
      this.setState((state) => ({ ...state, import_target_send: val.value }));
    } else {
      this.setState((state) => ({ ...state, import_target_send: null }));
    }
  };

  changeImportTarget = () => {
    this.setState((state) => ({ ...state, refreshTarget: true }));
    let headers = { "content-type": "application/json" };
    if (this.props.token !== null && this.props.token !== undefined) {
      headers["Authorization"] = `Token ${this.props.token}`;
    }
    const url_call = `${backendUrl}/v1/connectors/change/${this.props.data.id}/`;

    const data = {
      portfolio_id: this.state.import_target_send,
      user_id: this.props.user_id,
      action: "import_target",
      scope: this.props.type,
    };

    axios
      .patch(url_call, data, { headers: headers })
      .then((json) => {
        this.props.getWallets(this.props.user_id);
        this.props.showSnack({
          message: "Import target changed succesfully!",
          variant: "success",
        });
        if (this.state.import_target_send !== null) {
          try {
            const portTarget = this.props.portfolios.filter(
              (v) => v.id === this.state.import_target_send
            )[0];
            const import_target = {
              value: portTarget.id,
              label: portTarget.name,
            };
            this.setState((state) => ({
              ...state,
              refreshTarget: false,
              import_target: import_target,
              import_to_id: this.state.import_target_send,
            }));
          } catch (e) {}
        } else {
          this.setState((state) => ({
            ...state,
            refreshTarget: false,
            import_target: null,
            import_to_id: null,
          }));
        }
      })
      .catch((e) => {
        this.props.showSnack({
          message: "An error occured while changing the import target.",
          variant: "error",
        });
      });
  };

  updateDepositType = (select) => {
    this.setState(state => ({...state, deposit_type: select.value}))
    let url;
    if (this.props.type === "exchange") {
      url = `${backendUrl}/v1/exchange_accounts/`;
    } else if (this.props.type === "storage") {
      url = `${backendUrl}/v1/wallets/`;
    }
    this.setState((state) => ({ ...state, depositLoad: true, errorDeposit: null }));
    let editData = {
      user: this.props.user_id,
      id: this.props.data.id,
      deposit_type: select.value,
    };

    let headers = { "content-type": "application/json" };
    if (this.props.token !== null && this.props.token !== undefined) {
      headers["Authorization"] = `Token ${this.props.token}`;
    }

    const url_call = url;

    axios
      .patch(url_call, editData, { headers: headers })
      .then((json) => {
        this.setState((state) => ({
          ...state,
          depositLoad: false,
          errorDeposit: false,
        }));
        this.props.getWallets(this.props.user_id);
        this.props.showSnack({
          message: "Deposit selection successfully updated",
          variant: "success",
        });
      })
      .catch((e) => {
        this.setState((state) => ({
          ...state,
          depositLoad: false,
          errorDeposit: true,
        }));
        this.props.showSnack({
          message:
            "An error occured. Please try again later",
          variant: "error",
        });
      });
  }

  setUpConnect = () => {
    this.setState((state) => ({ ...state, passLoad: true, errorPass: null }));
    let headers = {
      "content-type": "application/json",
    };
    headers["Authorization"] = `Token ${this.props.token}`;

    let submitData = {
      ...this.state.editData,
      existing_id: this.props.data.id,
      user_id: this.props.user_id,
    };
    if (
      this.state.import_target_send !== null &&
      this.state.import_target_send !== undefined
    ) {
      submitData["scope"] = "all";
      submitData["portfolio"] = this.state.import_target_send;
    } else {
      submitData["scope"] = "choose";
      submitData["portfolio"] = null;
    }
    let url = null;
    if (this.props.type === "exchange") {
      url = `${backendUrl}/v1/connectors/setup/`;
      submitData["exchange_id"] = this.props.data.source_id;
    } else if (this.props.type === "storage") {
      url = `${backendUrl}/v1/wallets/setup/`;
      submitData["coin_id"] = this.props.data.source_id;
    }
    axios
      .post(url, submitData, { headers: headers })
      .then((res) => {
        const accountId = res["data"]["data"]["id"];
        const currentTasks = this.props.tasks;
        let newTasks = [...currentTasks];
        if (this.state.connection_type === "exchange") {
          newTasks = [
            {
              description: `Exchange Connection`,
              sub: `${this.state.scopeData[0].exchange_name}`,
              status: "pending",
              type: "connection",
              task_id: accountId,
            },
            ...currentTasks,
          ];
        } else if (this.state.connection_type === "storage") {
          newTasks = [
            {
              description: `Wallet Connection`,
              sub: `${this.state.scopeData[0].coin_name}`,
              status: "pending",
              type: "connection",
              task_id: accountId,
            },
            ...currentTasks,
          ];
        }

        this.props.setInteraction({ label: "tasks", value: newTasks });

        //this.connectForUpdate();
        this.props.getWallets(this.props.user_id, this.props.token);
        this.props.getUserLimits(this.props.user_id);
        this.setState((state) => ({
          ...state,
          passLoad: false,
          errorPass: null,
          toggleConenct: false,
        }));
      })
      .catch((err) => {
        let error = "Unable to connect at this time, please try again.";

        console.log(err);

        try {
          error = err.response["data"]["data"];
        } catch (e) {}

        this.setState((state) => ({
          ...state,
          passLoad: false,
          errorPass: error,
        }));
      });
  };

  render() {
    const { classes } = this.props;

    return (
      <div style={{ marginTop: 48 }}>
        <div style={{ marginBottom: 48 }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <h4 style={{ margin: 0 }}>Account Settings</h4>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "baseline",
            paddingLeft: 12,
            paddingRight: 12,
            color: "#6A7E93",
            width: "100%",
          }}
        >
          <div
            style={{
              marginTop: 0,
              minHeight: this.props.type === "storage" ? 120 : 90,
              width: "100%",
            }}
          >
            <div className={`basier-p4-caps ${classes.paddingSmall}`}>
              General
            </div>
            <div className={classes.paddingSmall}>
              <div
                className="wrap-small"
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  marginTop: 12,
                  marginBottom: 12,
                  justifyContent: "space-between",
                  minHeight: this.props.type === "storage" ? 120 : 90,
                }}
              >
                {this.state.nameLoad ? (
                  <div
                    style={{
                      marginTop: 24,
                      height: 83,
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div
                      className="pulse-skeleton"
                      style={{ width: 75, height: 75, borderRadius: 100 }}
                    ></div>

                    <div
                      style={{
                        marginLeft: 24,
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <div
                        className="pulse-skeleton"
                        style={{
                          width: 200,
                          height: 30,
                          borderRadius: 10,
                          marginRight: 0,
                        }}
                      ></div>
                      <div
                        className="pulse-skeleton"
                        style={{
                          width: 200,
                          height: 18,
                          marginTop: 12,
                          borderRadius: 10,
                          marginRight: 0,
                        }}
                      ></div>
                    </div>
                  </div>
                ) : this.state.edit ? (
                  <div className={classes.inputsWrapper}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: 12,
                        marginBottom: 12,
                        flexWrap: "wrap",
                      }}
                    >
                      <div className={classes.inputLarge}>
                        <TextField
                          className="custom-input custom-input-email"
                          value={this.state.name}
                          onChange={(val) => this.changeValue("name", val)}
                          label="Wallet Name"
                          type="name"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="outlined"
                        />
                      </div>
                    </div>
                    {this.props.type === "storage" && (
                      <div className={classes.inputLarge}>
                        <TextField
                          className="custom-input custom-input-email"
                          value={this.state.address}
                          onChange={(val) => this.changeValue("address", val)}
                          label="Wallet Address"
                          type="address"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="outlined"
                        />
                      </div>
                    )}
                  </div>
                ) : (
                  <div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: 24,
                        marginBottom: 24,
                      }}
                    >
                      <div style={{ marginLeft: 12 }}>
                        <Hidden smDown>
                          <h3 style={{ margin: 0 }}>
                            <span style={{ textTransform: "capitalize" }}>
                              {this.props.data.name}
                            </span>
                          </h3>
                        </Hidden>
                        <Hidden mdUp>
                          <h4 style={{ margin: 0 }}>
                            <span style={{ textTransform: "capitalize" }}>
                              {this.props.data.name}
                            </span>
                          </h4>
                        </Hidden>
                        {this.props.type === "storage" &&
                        this.props.data.connected === false ? (
                          <React.Fragment>
                            <Hidden smDown>
                              <div
                                className="basier-p2"
                                style={{ marginTop: 4 }}
                              >
                                {this.props.data.address}
                              </div>
                            </Hidden>

                            <Hidden mdUp>
                              <div
                                className="basier-p3 truncate"
                                style={{ marginTop: 4 }}
                              >
                                {this.props.data.address}
                              </div>
                            </Hidden>
                          </React.Fragment>
                        ) : null}
                      </div>
                    </div>
                    {this.state.emailChangeMessage ? (
                      <div
                        className="basier-p4"
                        style={{ marginTop: 2, color: "blue" }}
                      >
                        A confirmation email has been sent to {this.state.email}
                        . Please click the activation link to confirm the change
                        to your e-mail.
                      </div>
                    ) : null}
                  </div>
                )}
                <div className={classes.buttonWrapper}>
                  {this.state.nameLoad ? null : this.state.edit ? (
                    <div
                      className="small-center"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap",
                      }}
                    >
                      <button
                        className={`${classes.buttonWidth} button-blue opacity-hover`}
                        style={{
                          height: 40,
                          borderRadius: 35,
                          cursor: "pointer",
                          marginRight: 12,
                          marginTop: 12,
                        }}
                        onClick={() => this.updateProfile()}
                      >
                        Save
                      </button>

                      <button
                        className={`${classes.buttonWidth} button-blue opacity-hover`}
                        style={{
                          height: 40,
                          borderRadius: 35,
                          cursor: "pointer",
                          backgroundColor: "#F9716B",
                          marginTop: 12,
                          marginRight: 12,
                        }}
                        onClick={() => this.toggleEdit()}
                      >
                        Cancel
                      </button>
                    </div>
                  ) : (
                    <button
                      className={`${classes.buttonWidth} button-blue opacity-hover`}
                      style={{
                        height: 40,
                        borderRadius: 35,
                        marginTop: 12,
                        cursor: "pointer",
                        marginRight: 12,
                      }}
                      onClick={() => this.toggleEdit()}
                    >
                      Edit Details
                    </button>
                  )}
                </div>
              </div>
              <Divider />

              {this.props.data.connected === true && (
                <React.Fragment>
                  <div className="basier-p4-caps" style={{ marginTop: 24 }}>
                    Restore
                  </div>

                  <>
                    <div
                      className="wrap-small"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: 12,
                        marginBottom: 12,
                        justifyContent: "space-between",
                        minHeight: 78,
                      }}
                    >
                      <span style={{ paddingRight: 24 }} className="basier-p3">
                        If there is an issue with your account connections or if
                        there is a problem with the imported transactions, you
                        can restore your wallet here. Restoring will delete all
                        related transactions and import them all again to the
                        pre-configured portfolio. Any modifications you did to
                        the transactions will be lost.
                      </span>

                      <div className={classes.buttonWrapper}>
                        <button
                          className={`${classes.buttonWidth} button-blue opacity-hover`}
                          style={{
                            height: 40,
                            borderRadius: 35,
                            cursor: "pointer",
                            marginRight: 12,
                            marginTop: 12,
                            backgroundColor: "#F9716B",
                          }}
                          onClick={
                            this.state.refreshLoad === true
                              ? null
                              : () => this.restore()
                          }
                        >
                          Restore Account
                        </button>
                      </div>
                    </div>
                  </>

                  <Divider />
                </React.Fragment>
              )}

              {this.props.data.connected === false &&
              this.props.connectorData[this.props.type] !== undefined &&
              (this.props.connectorData[this.props.type].filter(
                (v) => v.exchange_id === this.props.data.source_id
              ).length > 0 ||
                this.props.connectorData[this.props.type].filter(
                  (v) => v.coin_id === this.props.data.source_id
                ).length > 0) ? (
                <React.Fragment>
                  <div className="basier-p4-caps" style={{ marginTop: 24 }}>
                    Connect API
                  </div>

                  <>
                    <div
                      className="wrap-small"
                      style={{
                        display: "flex",
                        alignItems: "baseline",
                        marginTop: 12,
                        marginBottom: 12,
                        justifyContent: "space-between",
                        minHeight: 78,
                      }}
                    >
                      {this.state.toggleConenct ? (
                        <div className={classes.inputsWrapper}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "flex-start",
                              marginTop: 12,
                              marginBottom: 12,
                              flexWrap: "wrap",
                              flexDirection: "column",
                            }}
                          >
                            {this.state.passLoad && (
                              <div
                                style={{
                                  marginTop: 0,
                                  marginLeft: 0,
                                  position: "absolute",
                                  height:
                                    this.props.type === "storage"
                                      ? 165
                                      : 100 +
                                        65 *
                                          this.props.connectorData[
                                            this.props.type
                                          ].filter(
                                            (v) =>
                                              v.exchange_id ==
                                              this.props.data.source_id
                                          )[0]["fields"][0].length,
                                  backgroundColor: "white",
                                  opacity: 0.8,
                                  width: 550,
                                  zIndex: 999999,
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <img
                                  src={contentLoader}
                                  style={{
                                    width: 60,
                                    height: 60,
                                    marginLeft: 48,
                                  }}
                                  alt=""
                                />
                                <div
                                  className="basier-p3"
                                  style={{ color: "black" }}
                                >
                                  Validating connection
                                </div>
                              </div>
                            )}

                            {this.props.type === "exchange" ? (
                              this.props.connectorData[this.props.type]
                                .filter(
                                  (v) =>
                                    v.exchange_id == this.props.data.source_id
                                )[0]
                                ["fields"][0].map((v, k) => {
                                  return (
                                    <div style={{ marginTop: 12 }}>
                                      <div className={classes.inputLarge}>
                                        <TextField
                                          className="custom-input custom-input-email"
                                          value={this.state[v[0]]}
                                          onChange={(val) =>
                                            this.changeValue(v[0], val)
                                          }
                                          label={v[1]}
                                          type="text"
                                          InputLabelProps={{
                                            shrink: true,
                                          }}
                                          variant="outlined"
                                        />
                                      </div>
                                    </div>
                                  );
                                })
                            ) : (
                              <div style={{ marginTop: 6 }}>
                                <div className={classes.inputLarge}>
                                  <TextField
                                    className="custom-input custom-input-email"
                                    value={this.state.address}
                                    onChange={(val) =>
                                      this.changeValue("address", val)
                                    }
                                    label="Blockchain Address"
                                    type="text"
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    variant="outlined"
                                  />
                                </div>
                              </div>
                            )}
                          </div>

                          <div>
                            <div className="basier-p4-caps">Import To</div>
                            <div style={{ width: 300 }}>
                              <SingleSelect
                                selected={this.state.import_to_id}
                                data={this.state.scope_pf}
                                handleSelect={this.selectPort}
                              />
                            </div>
                            <div className="basier-p4" style={{ marginTop: 6 }}>
                              <div>
                                Select a portfolio to automatically add imported
                                transactions.
                              </div>
                              <div>
                                Leave blank to select manually how to allocate
                                each imported transaction to portfolios
                              </div>
                            </div>
                          </div>

                          {this.state.errorPass !== null && (
                            <div
                              className="basier-p4"
                              style={{ color: "red", marginTop: 12 }}
                            >
                              {this.state.errorPass}
                            </div>
                          )}
                        </div>
                      ) : (
                        <div
                          className="wrap-small"
                          style={{
                            display: "flex",
                            alignItems: "baseline",
                            marginTop: 12,
                            marginBottom: 12,
                            justifyContent: "space-between",
                            minHeight: 78,
                            width: "100%",
                          }}
                        >
                          <span
                            style={{ paddingRight: 24 }}
                            className="basier-p3"
                          >
                            Automate your workflow by connecting this account
                            through API. Click here to find out how.
                          </span>
                        </div>
                      )}

                      <div className={classes.buttonWrapper}>
                        {this.state.toggleConenct ? (
                          <div
                            className="small-center"
                            style={{
                              display: "flex",
                              alignItems: "baseline",
                              flexWrap: "wrap",
                              marginLeft: 12,
                            }}
                          >
                            <button
                              className={`${classes.buttonWidth} button-blue opacity-hover`}
                              style={{
                                height: 40,
                                borderRadius: 35,
                                cursor: "pointer",
                                marginRight: 12,
                                marginTop: 12,
                              }}
                              onClick={() => this.setUpConnect()}
                            >
                              Save
                            </button>

                            <button
                              className={`${classes.buttonWidth} button-blue opacity-hover`}
                              style={{
                                height: 40,
                                borderRadius: 35,
                                cursor: "pointer",
                                backgroundColor: "#F9716B",
                                marginTop: 12,
                                marginRight: 12,
                              }}
                              onClick={() => this.toggleConenctSetup()}
                            >
                              Cancel
                            </button>
                          </div>
                        ) : this.props.data.source_id === "coinbase" ? (
                          <div
                            className="button-blue opacity-hover no-select"
                            style={{ height: 40, cursor: "pointer" }}
                            onClick={
                              this.state.loading ? null : this.goToCoinbase
                            }
                          >
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              {this.state.loading ? (
                                <img
                                  src={whiteLoader}
                                  alt=""
                                  style={{
                                    height: 20,
                                    width: 20,
                                    marginRight: 12,
                                  }}
                                />
                              ) : (
                                <>
                                  <div>Continue to</div>
                                  <img
                                    src={coinbaseWhite}
                                    style={{ height: 18, marginLeft: 4 }}
                                    alt="Coinbase"
                                  />
                                </>
                              )}
                            </div>
                          </div>
                        ) : (
                          <button
                            className={`${classes.buttonWidth} button-blue opacity-hover`}
                            style={{
                              height: 40,
                              borderRadius: 35,
                              cursor: "pointer",
                              marginRight: 12,
                              marginTop: 12,
                            }}
                            onClick={() => this.toggleConenctSetup()}
                          >
                            Set up connection
                          </button>
                        )}
                      </div>
                    </div>
                  </>
                </React.Fragment>
              ) : null}
              {this.props.data.connected === true &&
                this.props.data.source_id !== "coinbase" && (
                  <React.Fragment>
                    <div className="basier-p4-caps" style={{ marginTop: 24 }}>
                      API Details
                    </div>

                    <>
                      <div
                        className="wrap-small"
                        style={{
                          display: "flex",
                          alignItems: "baseline",
                          marginTop: 12,
                          marginBottom: 12,
                          justifyContent: "space-between",
                          minHeight: 78,
                        }}
                      >
                        {this.state.changePass ? (
                          <div className={classes.inputsWrapper}>
                            <div>
                              {this.state.passLoad && (
                                <div
                                  style={{
                                    marginTop: -10,
                                    position: "absolute",
                                    height:
                                      this.props.type === "storage"
                                        ? 55
                                        : 55 *
                                          this.props.connectorData[
                                            this.props.type
                                          ].filter(
                                            (v) =>
                                              v.exchange_id ==
                                              this.props.data.source_id
                                          )[0]["fields"][0].length,
                                    backgroundColor: "white",
                                    opacity: 0.8,
                                    width: 550,
                                    zIndex: 999999,
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <img
                                    src={contentLoader}
                                    style={{
                                      width: 60,
                                      height: 60,
                                      marginLeft: 48,
                                    }}
                                    alt=""
                                  />
                                  <div
                                    className="basier-p3"
                                    style={{ color: "black" }}
                                  >
                                    Validating connection
                                  </div>
                                </div>
                              )}

                              {this.props.type === "exchange" ? (
                                this.props.connectorData[this.props.type]
                                  .filter(
                                    (v) =>
                                      v.exchange_id == this.props.data.source_id
                                  )[0]
                                  ["fields"][0].map((v, k) => {
                                    return (
                                      <div style={{ marginTop: 12 }}>
                                        <div className={classes.inputLarge}>
                                          <TextField
                                            className="custom-input custom-input-email"
                                            value={this.state[v[0]]}
                                            onChange={(val) =>
                                              this.changeValue(v[0], val)
                                            }
                                            label={v[1]}
                                            type="text"
                                            InputLabelProps={{
                                              shrink: true,
                                            }}
                                            variant="outlined"
                                          />
                                        </div>
                                      </div>
                                    );
                                  })
                              ) : (
                                <div style={{ marginTop: 12 }}>
                                  <div className={classes.inputLarge}>
                                    <TextField
                                      className="custom-input custom-input-email"
                                      value={this.state.address}
                                      onChange={(val) =>
                                        this.changeValue("address", val)
                                      }
                                      label="Blockchain Address"
                                      type="text"
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      variant="outlined"
                                    />
                                  </div>
                                </div>
                              )}
                            </div>

                            {this.state.errorPass !== null && (
                              <div
                                className="basier-p4"
                                style={{ color: "red", marginTop: 12 }}
                              >
                                {this.state.errorPass}
                              </div>
                            )}
                          </div>
                        ) : (
                          <div>
                            {this.props.type === "exchange" ? (
                              this.props.connectorData[this.props.type]
                                .filter(
                                  (v) =>
                                    v.exchange_id == this.props.data.source_id
                                )[0]
                                ["fields"][0].map((v, k) => {
                                  return (
                                    <div
                                      key={k}
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <div
                                        style={{
                                          fontWeight: 900,
                                          color: "black",
                                        }}
                                        className="basier-p3"
                                      >
                                        {v[1]}:
                                      </div>
                                      <div
                                        className="basier-p3"
                                        style={{ marginLeft: 12 }}
                                      >
                                        ••••••••
                                      </div>
                                    </div>
                                  );
                                })
                            ) : (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  style={{ fontWeight: 900, color: "black" }}
                                  className="basier-p3"
                                >
                                  Address:
                                </div>
                                <div
                                  className="basier-p3"
                                  style={{ marginLeft: 12 }}
                                >
                                  {this.props.data.address}
                                </div>
                              </div>
                            )}
                          </div>
                        )}

                        <div className={classes.buttonWrapper}>
                          {this.state.changePass ? (
                            <div
                              className="small-center"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                flexWrap: "wrap",
                                marginLeft: 12,
                              }}
                            >
                              <button
                                className={`${classes.buttonWidth} button-blue opacity-hover`}
                                style={{
                                  height: 40,
                                  borderRadius: 35,
                                  cursor: "pointer",
                                  marginRight: 12,
                                  marginTop: 12,
                                }}
                                onClick={() => this.changePass()}
                              >
                                Save
                              </button>

                              <button
                                className={`${classes.buttonWidth} button-blue opacity-hover`}
                                style={{
                                  height: 40,
                                  borderRadius: 35,
                                  cursor: "pointer",
                                  backgroundColor: "#F9716B",
                                  marginTop: 12,
                                  marginRight: 12,
                                }}
                                onClick={() => this.togglePass()}
                              >
                                Cancel
                              </button>
                            </div>
                          ) : (
                            <button
                              className={`${classes.buttonWidth} button-blue opacity-hover`}
                              style={{
                                height: 40,
                                borderRadius: 35,
                                cursor: "pointer",
                                marginRight: 12,
                                marginTop: 12,
                              }}
                              onClick={() => this.togglePass()}
                            >
                              Update
                            </button>
                          )}
                        </div>
                      </div>
                    </>
                    <Divider />
                  </React.Fragment>
                )}

              {this.props.data.source_id === "coinbase" && (
                <React.Fragment>
                  <div className="basier-p4-caps" style={{ marginTop: 24 }}>
                    API Details
                  </div>
                  <div
                    className="wrap-small"
                    style={{
                      display: "flex",
                      alignItems: "baseline",
                      marginTop: 12,
                      marginBottom: 12,
                      justifyContent: "space-between",
                      minHeight: 78,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          fontWeight: 900,
                          color: "black",
                        }}
                        className="basier-p3"
                      >
                        Connection:
                      </div>
                      {this.props.data.key_error ? (
                        <div
                          style={{
                            padding: "4px 4px",
                            borderRadius: 5,
                            color: "white",
                            backgroundColor: "#F9716B",
                            marginLeft: 6,
                          }}
                          className="basier-p4-caps"
                        >
                          Needs Reconnecting
                        </div>
                      ) : (
                        <div
                          style={{
                            padding: "4px 4px",
                            borderRadius: 5,
                            color: "white",
                            backgroundColor: "#4CDAC1",
                            marginLeft: 6,
                          }}
                          className="basier-p4-caps"
                        >
                          Connected through Coinbase
                        </div>
                      )}
                    </div>
                    <div className={classes.buttonWrapper}>
                    <button
                      className={`${classes.buttonWidth} button-blue opacity-hover`}
                      style={{
                        height: 40,
                        borderRadius: 35,
                        cursor: "pointer",
                        marginRight: 12,
                        marginTop: 12,
                      }}
                      onClick={() => this.goToCoinbase()}
                    >
                      Renew Connection
                    </button>
                    </div>
                  </div>
                  <Divider />
                </React.Fragment>
              )}
            </div>

            {this.props.data.connected === true && (
              <div>
                <div
                  className={`basier-p4-caps ${classes.paddingSmall}`}
                  style={{ marginTop: 24 }}
                >
                  Import Target
                </div>

                <Hidden smDown>
                  <div
                    className="wrap-small"
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: 12,
                      }}
                    >
                      <div
                        className="basier-p3"
                        style={{
                          color: "black",
                          marginRight: 12,
                          fontWeight: 900,
                        }}
                      >
                        Import transactions to:{" "}
                      </div>
                      {this.state.settingsEdit ? (
                        <div style={{ width: 300 }}>
                          <SingleSelect
                            selected={this.state.import_to_id}
                            data={this.state.scope_pf}
                            handleSelect={this.selectPort}
                          />
                        </div>
                      ) : this.state.import_target !== null &&
                        this.state.import_target !== undefined ? (
                        this.state.import_target.label
                      ) : (
                        <div style={{ fontStyle: "italic" }}>
                          No portfolio selected
                        </div>
                      )}
                    </div>

                    <div
                      className={classes.buttonWrapper}
                      style={{ display: "flex" }}
                    >
                      {this.state.settingsEdit ? (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            flexWrap: "wrap",
                            marginLeft: 12,
                            justifyContent: "flex-end",
                          }}
                        >
                          <button
                            className={`${classes.buttonWidth} button-blue opacity-hover`}
                            style={{
                              height: 40,
                              borderRadius: 35,
                              cursor: "pointer",
                              marginRight: 12,
                              marginTop: 12,
                            }}
                            onClick={() => this.changeImportTarget()}
                          >
                            Save
                          </button>

                          <button
                            className={`${classes.buttonWidth} button-blue opacity-hover`}
                            style={{
                              height: 40,
                              borderRadius: 35,
                              cursor: "pointer",
                              backgroundColor: "#F9716B",
                              marginTop: 12,
                              marginRight: 12,
                            }}
                            onClick={() => this.togglePrefs()}
                          >
                            Cancel
                          </button>
                        </div>
                      ) : (
                        <button
                          className={`${classes.buttonWidth} button-blue opacity-hover`}
                          style={{
                            height: 40,
                            borderRadius: 35,
                            cursor: "pointer",
                            marginRight: 12,
                            marginTop: 12,
                          }}
                          onClick={() => this.togglePrefs()}
                        >
                          Edit
                        </button>
                      )}
                    </div>
                  </div>
                </Hidden>
              </div>
            )}

            {this.props.data.connected === true && this.props.data.type === "storage" && (
              <div>
                <div
                  className={`basier-p4-caps ${classes.paddingSmall}`}
                  style={{ marginTop: 24 }}
                >
                  Mark Deposits as
                </div>
                <div style={{width: 300}}>
                  <SingleSelect selected={this.state.deposit_type} data={depositTypes} handleSelect={this.updateDepositType} />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currency: state.auth.currency.id,
    connectorData: state.appwide.connectors,
    user_id: state.auth.user_id,
    token: state.auth.token,
    tasks: state.interactions.tasks,
    portfolios: state.auth.list_portfolios,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleModal: (data) => dispatch(interActions.toggleModal(data)),
    getWallets: (user) => dispatch(appwideActions.getWallets(user)),
    showSnack: (data) => dispatch(interActions.showSnack(data)),
    setInteraction: (data) => dispatch(interActions.setInteraction(data)),
    getUserLimits: (user) => dispatch(appwideActions.getUserLimits(user)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(SettingComponent));
