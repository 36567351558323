
import { connect } from "react-redux";
import * as actions from "../../store/actions/auth";
import * as interActions from "../../store/actions/interactions";
import React from "react";
import TextField from '@material-ui/core/TextField';
import whiteLoader from '../../Media/whiteloader.gif'
import axios from 'axios';
import {backendUrl} from '../../variables';
import {withRouter} from 'react-router-dom'; /* MAY NEED TO REMOVE */


import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';



import {
    Form
} from 'antd';

class NormalLoginForm extends React.Component {

    state = {
        loadingReset: false,
        loading: false,
        resetValid: false, 
        resetError: false,
        error: 'An error occured, please set a more secure password.',
        showPassword: false,
        processMessage: "Processing"
    }
    
    handleClickShowPassword = () => {
        this.setState(state => ({...state, showPassword: !state.showPassword}))
    }
    componentDidUpdate(prevProps) {

        if (this.props.error !== prevProps.error) {
            let error = "An error occured, please set a more secure password."
            try { 
                error = this.props.error.response.data.message
            } catch(e) {}
            this.setState(state => ({...state, error: error}))
        }

    }

    handleSubmit = e => {
        e.preventDefault();
        if (!this.state.loading && !this.props.loading) {
            this.setState(state => ({...state, loading: true}))
            this.props.form.validateFields((err, values) => {
                if (!err) {

                    this.props.onRegister(values.email, values.password, values.first_name, values.last_name, 'usd');
                    //this.props.history.push("/");
                    window.setTimeout(() => this.setState(state => ({...state, loading: false})), 500)
                    window.setTimeout(() => this.setState(state => ({...state, processMessage: 'Setting up'})), 1500)

                }
            });
        }   
    };

    handleMouseDown = (event) => {
        event.preventDefault();
      };

    render() {

        const { getFieldDecorator } = this.props.form;

        return (

            <form onSubmit={this.handleSubmit}>

                {getFieldDecorator('email', {
                    rules: [{ required: true, message: 'Please input your email!' }],
                })(
                    <TextField
                    error={this.props.error}
                    id="outlined-email-input"
                    label="Email"
                    style={{ marginLeft: 0,
                            marginRight: 0,
                            width: '100%',
                            borderRadius: 7, 
                            backgroundColor: 'white',
                            }}
                    inputProps={{
                    'data-lpignore':"true",
                    }}
                    type="email"
                    name="email"
                    ref={this.email}
                    autoComplete="email"
                    margin="normal"
                    variant="outlined"
                    onChange={this.handleEmail}
                    onFocus={this.focusInput}
                />
                )}

                <div style={{display: 'flex', width: '100%'}}>
                {getFieldDecorator('first_name', {
                    rules: [{ required: true, message: 'Please input your first name!' }],
                })(
                    <TextField
                    error={this.props.error}
                    id="outlined-email-input"
                    label="First Name"
                    inputProps={{
                        'data-lpignore':"true",
                        }}
                    style={{ marginLeft: 0,
                            marginRight: 5,
                            flex: 1,
                            borderRadius: 7, 
                            backgroundColor: 'white',
                            }}
                    type="text"
                    name="text"
                    ref={this.first_name}
                    autoComplete="off"
                    margin="normal"
                    variant="outlined"
                    //onChange={this.handleEmail}
                    onFocus={this.focusInput}
                />
                )}

                {getFieldDecorator('last_name', {
                    rules: [{ required: true, message: 'Please input your last name!' }],
                })(
                    <TextField
                    error={this.props.error}
                    id="outlined-email-input"
                    label="Last Name"
                    inputProps={{
                        'data-lpignore':"true",
                        }}
                    style={{ marginLeft: 5,
                            marginRight: 0,
                            flex: 1,
                            borderRadius: 7, 
                            backgroundColor: 'white',
                            }}
                    type="text"
                    name=""
                    ref={this.last_name}
                    autoComplete="off"
                    margin="normal"
                    variant="outlined"
                    //onChange={this.handleEmail}
                    onFocus={this.focusInput}
                />
                )}
                </div>

                {getFieldDecorator('password', {
                    rules: [{ required: true, message: 'Please input your Password!' }],
                })(
                    <TextField
                            error={this.props.error}
                            id="outlined-password-input"
                            label="Password"
                            style={{ marginLeft: 0,
                                marginRight: 0,
                                width: '100%',
                                borderRadius: 7, 
                                backgroundColor: 'white',
                            }}
                            type={!this.state.showPassword ? "password" : "text"}
                            ref={this.pw}
                            autoComplete="new-password"
                            margin="normal"
                            
                            variant="outlined"
                            onChange={this.handlePW}
                            onFocus={this.focusInput}
                            inputProps={{
                                'data-lpignore':"true",
                                autocomplete:"new-password"
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                      <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={this.handleClickShowPassword}
                                        onMouseDown={this.handleMouseDown}
                                      >
                                        {!this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                                      </IconButton>
                                    </InputAdornment>
                                )
                            }}

                        />
                )}

                <div
                    style={{display: 'flex', 
                            justifyContent: 'space-between', 
                            alignItems: 'center',
                            width: '100%', 
                            marginTop: 20,
                            marginLeft: 10, 
                            marginRight: 15,}}>
                        
                        <div className="basier-p4-caps" style={{color: '#6A7E93', cursor: 'pointer'}}></div>
                        {this.props.loading || this.state.loading ? 

                        <div className="basier-p2"
                        style={{backgroundColor: '#0078DB', 
                                borderRadius: 20, 
                                color: 'white', 
                                border: 'none',
                                boxShadow: 'none',
                                height: 40, 
                                width: 160,
                                display: 'flex', 
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginRight: 10,
                                cursor: 'pointer',
                                outline: 'none'
                                
                                }}>
                        <img src={whiteLoader} alt="Loading" style={{height: 20, width: 20, marginRight: 6}}/>
                        <div className="basier-p2">{
                        this.state.processMessage}</div>
                        </div>

                        :
                        
                        <button  type="submit" className="basier-p2" style={{backgroundColor: '#0078DB', 
                        borderRadius: 20, 
                        color: 'white', 
                        border: 'none',
                        boxShadow: 'none',
                        height: 40, 
                        width: 160,
                        display: 'flex', 
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginRight: 10,
                        cursor: 'pointer',
                        outline: 'none'
                        
                        }}>
                            Sign up
                        </button>

                        }
                            
                </div>
                {this.props.error && 
                    <div style={{color: 'red', marginLeft: 12, marginTop: 12}} className="basier-p4">
                        {this.state.error}
                    </div>
                }

            </form>
        );
    }
}

const WrappedNormalLoginForm = Form.create()(NormalLoginForm);


const mapStateToProps = state => {
    return {
        loading: state.auth.loading,
        error: state.auth.register_error
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onRegister: (email, password, first_name, last_name, currency) =>
            dispatch(actions.authRegister(email, password, first_name, last_name, currency)),
        showSnack: (data) => dispatch(interActions.showSnack(data)), 
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(WrappedNormalLoginForm));