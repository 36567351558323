import React from 'react';
import { connect } from 'react-redux';

class XXXXX extends React.PureComponent {

    render () {

        return (
            <div style={{marginBottom: 12, padding: 24, backgroundColor: '#F8F9FA', height: '100%'}}>
                <div style={{width: '100%', display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between'}}>
                </div>
            </div>

        )
    }


}

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = dispatch => {
    return {
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(XXXXX)
