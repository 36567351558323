import React from 'react';
import { connect } from 'react-redux';
import AssetLine from './AssetLine'
import listIcon from '../../../Media/listIcon.svg'
import contentLoader from '../../../Media/contentLoader.svg'

class AccountSection extends React.PureComponent {

  state = {
    balances: null,
    loading: true,
  }
    

  
  render() {
    return(
      !this.props.balances ? 
      <div style={{width: '100%', dispay: 'flex', justifyContent: 'center', marginTop: 48, height: 300, textAlign: 'center'}}>
        <img src={contentLoader} style={{height: 50, width: 50}} alt="" />
      </div>
      :
      <div>
        {
        this.props.balances.length === 0 ? 
        <div>
          <div style={{height: 400, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'flex-start', marginTop: 12}}>
              <div style={{borderRadius: 20, height: '100%', width: '100%', backgroundColor: 'rgba(255, 255, 255, 0.5)', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', textAlign: 'center', padding: 48, flexDirection: 'column',}}>
                  <div>
                      <img src={listIcon} alt="" style={{height: 80}} />
                  </div>
                  <div className="basier-p2" style={{marginTop: 24}}>
                      <div>You do not have any transactions in your portfolios yet.</div>
                      <div>Once you add transactions you can see your top holdings here.</div>
                      <div style={{marginTop: 12, color: "#e0a03d", fontStyle: 'italic'}}>Note: You will not see assets of your audited portfolios and wallets here.</div>
                  </div>
              </div>
          </div>
        </div>
        :
        this.props.balances.map((v, k) => {

          let check; 
          let errorDelta = false;
          try {
            check = this.props.transferBalances[v['coin']]
          } catch(e) {}
          if (check === undefined) {
            errorDelta = true
          } else {
            if (check === 0 && parseFloat(v['amount']) > 0) {
              errorDelta = true
            } else {
                const delta = Math.abs(check/parseFloat(v['amount']) - 1) 
              if (delta > 0.01) {
                errorDelta = true
              }
            }
          }
          return (

            <div key={k}>
              <div style={{width: '100%'}}>
              <AssetLine item={v} currency_symbol={this.props.currency_symbol} value={this.props.value} errorDelta={errorDelta} />
              </div>
            </div>

          )

        })}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
      data: state.appwide.wallets,
      currency: state.auth.currency.id,
      inBictoin: state.data.bitcoinPf === 'bitcoin',
      bitcoinPf: state.data.bitcoinPf,
      currency_symbol: state.auth.currency.symbol,
      transferBalances: state.data.transferBalances
  };
};


export default connect(
  mapStateToProps,
  null
)(AccountSection);