import React from "react";
import PropTypes from "prop-types";
import Dialog from "@material-ui/core/Dialog";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import * as actions from "../../store/actions/interactions";
import { connect } from "react-redux";
import TransactionAdd from "../Portfolio/PortfolioComponents/Transactions/NewTransaction/BaseModal";
import EditTransaction from "./ModalViews/EditTransaction";
import DeleteTransaction from "./ModalViews/DeleteTransaction";
import NewPortfolio from "./ModalViews/NewPortfolio";
import ConfirmationModal from "./ModalViews/CofirmationModal";
import ConvertPort from "./ModalViews/ConvertToMulti";
import AddWallet from "./ModalViews/AddWallet";
import AuditBase from "./ModalViews/AuditBase";
import ChangePlan from "./ModalViews/ChangePlan";
import DeleteCollab from "./ModalViews/DeleteCollab";
import AddCollaborator from "./ModalViews/AddCollaborator";
import DeletePort from "./ModalViews/DeletePortfolio";
import WalletDetail from "./ModalViews/WalletDetail";
import ViewConnected from "./ModalViews/ViewConnected";
import TransactionDetail from "./ModalViews/TransactionModalDetail";
import MatchedDetail from "./ModalViews/MatchedDetail";
import AddPosition from "./ModalViews/EditPosition";
import MovePosition from "./ModalViews/MovePosition";
import QuickClosePosition from "./ModalViews/QuickClosePosition";
import PositionEntries from "./ModalViews/PositionEntries";
import NewAccount from "./ModalViews/NewAccount";
import ExportModal from "./ModalViews/ExportModal";
import AccountCSV from "./ModalViews/AccountCSV";
import MatchTransfer from "./ModalViews/MatchTransfer";
import ImportAddCoin from "./ModalViews/ImportAddCoin";

class ResponsiveDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      type: null,
    };
  }

  handleClose = () => {
    if (this.props.modalPref.type === "add_transaction") {
      this.props.setTrTime(null);
      this.props.setInteraction({ label: "feeCheck", value: false });
      this.props.setInteraction({
        label: "transactionAddData",
        value: { number: 0, data: {} },
      });
    }

    this.props.toggleModal({
      open: false,
      type: null,
      payload: null,
      onClose: null,
    });
  };

  componentDidMount() {}

  modalClass = (type) => {
    switch (type) {
      case "delete_transaction": {
        return "modal-root-small";
      }

      case "quick_position_close": {
        return "modal-root-small";
      }

      case "confirmation_modal": {
        return "modal-root-small";
      }

      case "add_single_wallet": {
        return "modal-root";
      }
      case null: {
        return "";
      }

      case "add_transaction": {
        return "modal-root-unhidden";
      }

      case "edit_transaction": {
        return "modal-root-unhidden";
      }
      case "match_transfer": {
        return "modal-root-unhidden";
      }

      default: {
        return "modal-root";
      }
    }
  };

  renderModal = (type) => {
    switch (type) {
      case "add_transaction": {
        return (
          <TransactionAdd
            overrideType={this.props.modalPref.payload.overrideType}
            overridePort={this.props.modalPref.payload.overridePort}
            overridePortId={this.props.modalPref.payload.overridePortId}
            refresher={this.props.modalPref.payload.refresher}
          />
        );
      }

      case "delete_transaction": {
        return (
          <DeleteTransaction
            transactionId={this.props.modalPref.payload.transactionId}
            getOverallData={this.props.modalPref.payload.getOverallData}
          />
        );
      }

      case "edit_transaction": {
        return (
          <EditTransaction
            data={this.props.modalPref.payload.data}
            transactionId={this.props.modalPref.payload.transactionId}
            getData={this.props.modalPref.payload.getData}
            setDetailTr={this.props.modalPref.payload.setDetailTr}
            getOverallData={this.props.modalPref.payload.getOverallData}
          />
        );
      }

      case "match_transfer": {
        return (
          <MatchTransfer
            data={this.props.modalPref.payload.data}
            transactionId={this.props.modalPref.payload.transactionId}
            getData={this.props.modalPref.payload.getData}
            setDetailTr={this.props.modalPref.payload.setDetailTr}
            getOverallData={this.props.modalPref.payload.getOverallData}
          />
        );
      }

      case "new_portfolio": {
        return (
          <NewPortfolio
            data={this.props.modalPref.payload.data}
            getOverallData={this.props.modalPref.payload.getOverallData}
          />
        );
      }

      case "add_position": {
        return (
          <AddPosition
            existingData={this.props.modalPref.payload.existingData}
          />
        );
      }

      case "view_related_positions": {
        return <PositionEntries data={this.props.modalPref.payload.data} />;
      }

      case "export_modal": {
        return (
          <ExportModal
            scope={this.props.modalPref.payload.scope}
            submit={this.props.modalPref.payload.submit}
          />
        );
      }

      case "confirmation_modal": {
        return (
          <ConfirmationModal
            message={this.props.modalPref.payload.message}
            subscope={this.props.modalPref.payload.subscope}
            subscopeValue={this.props.modalPref.payload.subscopeValue}
            forceYes={this.props.modalPref.payload.forceYes}
            forceNo={this.props.modalPref.payload.forceNo}
            handleConfirm={this.props.modalPref.payload.handleConfirm}
          />
        );
      }

      case "quick_position_close": {
        return (
          <QuickClosePosition
            positionId={this.props.modalPref.payload.positionId}
            currencies={this.props.modalPref.payload.currencies}
            portfolioId={this.props.modalPref.payload.portfolioId}
          />
        );
      }

      case "move_position": {
        return (
          <MovePosition
            type={this.props.modalPref.payload.type}
            positionName={this.props.modalPref.payload.positionName}
            positionExchange={this.props.modalPref.payload.positionExchange}
            priceQuote={this.props.modalPref.payload.priceQuote}
            positionSize={this.props.modalPref.payload.positionSize}
            quoteSize={this.props.modalPref.payload.quoteSize}
            positionId={this.props.modalPref.payload.positionId}
            currencies={this.props.modalPref.payload.currencies}
            portfolioId={this.props.modalPref.payload.portfolioId}
          />
        );
      }

      case "convert_portfolio": {
        return (
          <ConvertPort
            data={this.props.modalPref.payload.data}
            getOverallData={this.props.modalPref.payload.getOverallData}
          />
        );
      }

      case "new_wallet": {
        return (
          <NewAccount
            data={this.props.modalPref.payload.data}
            scope={this.props.modalPref.payload.scope}
            context={this.props.modalPref.payload.context}
          />
        );
      }

      case "account_csv": {
        return (
          <AccountCSV
            data={this.props.modalPref.payload.data}
            scope={this.props.modalPref.payload.scope}
            context={this.props.modalPref.payload.context}
          />
        );
      }

      case "add_single_wallet": {
        return (
          <AddWallet
            data={this.props.modalPref.payload.data}
            scope={this.props.modalPref.payload.scope}
            contextData={this.props.modalPref.payload.context}
          />
        );
      }

      case "delete_portfolio": {
        return <DeletePort data={this.props.modalPref.payload.data} />;
      }

      case "audit_modal": {
        return (
          <AuditBase
            data={this.props.modalPref.payload.data}
            scope={this.props.modalPref.payload.data.scope}
          />
        );
      }
      case "change_plan": {
        return (
          <ChangePlan
            data={this.props.modalPref.payload.data}
            scope={this.props.modalPref.payload.scope}
          />
        );
      }
      case "delete_collab": {
        return (
          <DeleteCollab
            data={this.props.modalPref.payload.data}
            handleDelete={this.props.modalPref.payload.handleDelete}
            updateState={this.props.modalPref.payload.updateState}
          />
        );
      }

      case "add_collab": {
        return (
          <AddCollaborator
            data={this.props.modalPref.payload.data}
            getData={this.props.modalPref.payload.getData}
          />
        );
      }

      case "wallet_detail": {
        return (
          <WalletDetail
            scope={this.props.modalPref.payload.scope}
            data={this.props.modalPref.payload.context.data}
            type={this.props.modalPref.payload.context.type}
            getData={this.props.modalPref.payload.getData}
          />
        );
      }

      case "connected_detail": {
        return (
          <ViewConnected
            accountId={this.props.modalPref.payload.scope}
            data={this.props.modalPref.payload.context.data}
            scope={this.props.modalPref.payload.context.scope}
            //getData={this.props.modalPref.payload.getData}
          />
        );
      }

      case "transaction_detail": {
        return (
          <TransactionDetail
            getOverallData={this.props.modalPref.payload.getOverallData}
            transactionId={this.props.modalPref.payload.transactionId}
            portfolioId={this.props.modalPref.payload.portfolioId}
            selectTransaction={this.props.modalPref.payload.selectTransaction}
          />
        );
      }

      case "matched_detail": {
        return (
          <MatchedDetail
            getOverallData={this.props.modalPref.payload.getOverallData}
            transactionId={this.props.modalPref.payload.transactionId}
            data={this.props.modalPref.payload.data}
            portfolioId={this.props.modalPref.payload.portfolioId}
            selectTransaction={this.props.modalPref.payload.selectTransaction}
          />
        );
      }

      case "importAddCoin": {
        return (
          <ImportAddCoin
            data={this.props.modalPref.payload.data}
            getData={this.props.modalPref.payload.getData}
          />
        );
      }

      default: {
        return null;
      }
    }
  };

  render() {
    const { fullScreen } = this.props;

    return (
      <div style={{ zIndex: 9999999 }}>
        <Dialog
          fullScreen={fullScreen}
          open={this.props.modalPref.open}
          disableEscapeKeyDown={true}
          onClose={this.handleClose}
          aria-labelledby="responsive-dialog-title"
          classes={{
            paper: `${this.modalClass(this.props.modalPref.type)}`,
            root: "mobile-on-top",
          }}
        >
          <div>{this.renderModal(this.props.modalPref.type)}</div>
        </Dialog>
      </div>
    );
  }
}

ResponsiveDialog.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  return {
    modalPref: state.interactions.modal,
    portfoliosData: state.data.portfoliosData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleModal: (data) => dispatch(actions.toggleModal(data)),
    removeTransaction: (data) => dispatch(actions.removeTransaction(data)),
    setTrTime: (data) => dispatch(actions.setTrTime(data)),
    setInteraction: (data) => dispatch(actions.setInteraction(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withMobileDialog()(ResponsiveDialog));
