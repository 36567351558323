import React from 'react';
import { connect } from 'react-redux';
import BackIcon from "../../../../../Media/BackIcon.svg";
import portSelect from '../../../../../Media/PortSelect.svg'
import Divider from '@material-ui/core/Divider';
import UserList from '../../../../MobileOnly/UserDrawer';
import axios from 'axios';
import { backendUrl } from '../../../../../variables';
import {updatePortfolios} from '../../../../../helpers/portfolioHelpers';
import * as authActions from "../../../../../store/actions/auth";
import * as interActions from "../../../../../store/actions/interactions";
import * as dataActions from "../../../../../store/actions/data";

class SmallGeneral extends React.PureComponent {

    state = {
        edit: false,
        data: null, 
        newName: null, 
        currentOwner: null,
        newOwner: null,
        userModal: false,

    }

    componentDidMount() {
        const data = this.props.portfoliosData[this.props.portfolioId]
        if (data !== undefined) {
            

            let usersScope = [];
            let inScope = data.users.filter(v => ( (v.plan_type === 'pro' || v.plan_type === 'unlimited') && v.active == 1))

            inScope.map(val => {

                let temp_list = {label: `${val.first_name} ${val.last_name}`, value: val.user_id}
                usersScope.push(temp_list)

            })

            let currentOwner = {value: data.owner, label: data.owner_name}

            this.setState(state => ({...state, data: data, usersScope: usersScope, currentOwner: currentOwner}))
    
            
        }

    }

    componentDidUpdate(prevProps) {
        if (this.props.portfoliosData !== prevProps.portfoliosData) {
            const data = this.props.portfoliosData[this.props.portfolioId]
            if (data !== undefined) {

                let usersScope = [];
                let inScope = data.users.filter(v => ( (v.plan_type === 'pro' || v.plan_type === 'unlimited') && v.active == 1))

                inScope.map(val => {

                    let temp_list = {label: `${val.first_name} ${val.last_name}`, value: val.user_id}
                    usersScope.push(temp_list)

                })

                let currentOwner = {value: data.owner, label: data.owner_name }

                this.setState(state => ({...state, data: data, usersScope: usersScope, currentOwner: currentOwner}))
                 
            }
        }
    }

    submitEditServer = () => {

        let name = false;
        let owner = false;

        if (this.state.data['portfolio_name'] !== this.state.newName && (this.state.newName !== null && this.state.newName !== undefined && this.state.newName !== '')) {
            name = true
        } 

        if (this.state.data['owner'] !== this.state.newOwner && (this.state.newOwner !== null && this.state.newOwner !== undefined && this.state.newOwner !== '')) {
            owner = true
        } 

        let sendData = {
            user_id: this.props.user_id,
        }

        if (name === true) {
            sendData['name'] = this.state.newName
        }

        if (owner === true) {
            sendData['owner'] = this.state.newOwner['value']
        }

        let headers = { "content-type": "application/json" };
            if (this.props.token !== null && this.props.token !== undefined) {
                headers['Authorization'] = `Token ${this.props.token}`
            }

            const url_call = `${backendUrl}/v1/portfolio/${this.props.portfolioId}/`
            
            axios.patch(url_call, sendData, {headers: headers})
                .then(json => {

                    if (name === true) {

                        this.setState(state => ({...state, data: {...state.data, portfolio_name: this.state.newName}}))
                        const oldPorts = this.props.list_portfolios
                        let newPorts = []

                        oldPorts.map(val => {
                            if (val.id == this.props.portfolioId) {
                                val.name = this.state.newName
                            }
                            newPorts.push(val)
                        })
                        const success = updatePortfolios(newPorts)
                        if (success) {
                            this.props.setPorts(newPorts)
                            this.props.updateSinglePortfolio({portfolio: this.props.portfolioId, data: {...this.state.data, portfolio_name: this.state.newName} })
                        }
                        this.props.fetchUserPreferences({user_id: this.props.user_id, rahat_t: this.props.token});
                        this.props.showSnack({message: 'Portfolio name changed successfully', variant: "success"})
                    }

                     if (owner === true) {
                        const newOwnerData = this.state.usersScope.filter(v => v.value == this.state.newOwner['value'])
                        const newOwnerName = newOwnerData[0]['label']
                        const newOwnerId = newOwnerData[0]['value']
                        this.setState(state => ({...state, currentOwner: newOwnerData[0], data: {...state.data, owner: newOwnerId, owner_name: newOwnerName}}))
                        this.props.updateSinglePortfolio({portfolio: this.props.portfolioId, data: {...this.state.data, owner_name: this.state.newOwner['label']} })
                        this.props.showSnack({message: 'Portfolio owner changed successfully', variant: "success"})
                    }


                })
                .catch((e) => {
                    console.log(e)
                    this.props.showSnack({message: 'Error in updating portfolio', variant: "error"})
                })
        
        
        this.editHandler()

    }

    onChangeName = (e) => {
        e.persist();
        this.setState(state => ({...state, newName: e.target.value}))    
    }

    editHandler = () => {
        this.setState(state => ({...state, edit: !state.edit}))     
    }

    goBackAction = () => {
        this.props.goBack()
    }

    userModalOpen = () => {
        this.setState(state => ({...state, userModal: true}))
    }

    userModalClose = () => {
        this.setState(state => ({...state, userModal: false}))
    }

    handleUser = (v) => {
        this.setState(state => ({...state, newOwner: v}))
        this.userModalClose()
    }

    deletePort = () => {
        this.props.toggleModal({open: true, type: 'delete_portfolio', payload: {data: {portfolioId: this.props.portfolioId, numberTransactions: this.state.data['number_transactions']}}, onClose: null});
    }

    convertToMulti = () => {
        this.props.toggleModal({open: true, type: 'convert_portfolio', payload: {data: {portfolioId: this.props.portfolioId}}, onClose: null});
    }
    componentWillUnmount() {
        document.body.ontouchmove = (e) => { 
            return true;
        }
        this.props.setInteraction({label: 'pin', value: true});
    }

    render () {
        

        return (
            this.state.data === null ? 
            null :

                <React.Fragment>
                    
                            
                    <div style={{position: 'absolute', top: 0, zIndex: 1300, width: '100%'}}>

                        <div style={{backgroundColor: '#F8F9FA', zIndex: 1300, height: 75, width: '100%', display:'flex', alignItems: 'center', position: 'fixed', justifyContent: 'space-between'}} id="responsive-dialog-title">

                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <div onClick={() => this.goBackAction()}>
                                        <img style={{paddingTop: 24}} src={BackIcon} alt=""/>
                                    </div>
                                </div>

                                <div className="basier setting-tansitions" style={{color: 'black', fontWeight: 900, textTransform: 'uppercase', fontSize: this.state.edit ? 12: 16, position: 'relative', left: this.state.edit ? -20: 0}}>General</div>

                                <div>
                                    <div className="basier"  style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end', fontSize: '1.2rem'}}>
                                        
                                        {this.state.edit ? 
                                        
                                        <React.Fragment>
                                            <div className="modalActionButton touchableOpacity"  onClick={this.editHandler} style={{marginRight: 24, color: '#F9716B'}}>Cancel</div>
                                            <div className="modalActionButton touchableOpacity"  onClick={this.submitEditServer} style={{marginRight: 24, color: '#0078DB'}}>Save</div>
                                        </React.Fragment>

                                        :
                                        
                                        <React.Fragment>
                                            <div className="modalActionButton touchableOpacity"  onClick={this.editHandler} style={{marginRight: 24, color: '#0078DB'}}>Edit</div>
                                        </React.Fragment>
                                    
                                        }
                                    </div> 
                                </div>

                        </div>
                        
                        <div style={{position: 'fixed', top: 75, height: 200, width: '100%', zIndex: 1200, backgroundColor: 'white'}} />



                            <div style={{position: 'absolute', top: 75, zIndex: 1250, backgroundColor: 'white', minHeight: 100, width: '100%'}}>
                                
                                <div style={{}}>
                                    <div style={{padding: 24}}>
                                        <div style={{marginBottom: 6}} className="basier-p4-caps">
                                            Portfolio Name
                                        </div>
                                        <div style={{display: 'flex', alignItems: 'center'}}>
                                            <img 
                                                style={{height: 50, width: 50, marginRight: 12}}
                                                className="no-select"
                                                src={portSelect} 
                                                alt="" />
                                            {this.state.edit ? 

                                            <div>
                                                <input value={this.state.newName} autoComplete="false" className="light-input basier-p2" style={{width: 200}} onChange={(e) => this.onChangeName(e)} />
                                            </div>

                                            :
                                            <div>
                                                <h4 style={{margin: 0}}>
                                                    <span>{this.state.data['portfolio_name']}</span>
                                                </h4>
                                                <div className="basier-p4-caps" style={{marginTop: 4}}>
                                                    {this.state.data['portfolio_type'] === 'individual' ? 'Solo Portfolio' : 'Multi-User Portfolio'}
                                                </div>
                                            </div>
                                            }
                                        </div>
                                    </div>
                                    {!this.state.edit &&
                                    <Divider />}
                                    
                                    <div style={{marginTop: 24}}>
                                        <div style={{marginBottom: 24}}>                                            
                                                {this.state.edit ? 

                                                    <div style={{display: 'flex', alignItems: 'center',}}>

                                                        <div 
                                                                style={{
                                                                height: 60,
                                                                display: 'flex', 
                                                                alignItems: 'center',
                                                                justifyContent: 'space-between',
                                                                paddingLeft: 24,
                                                                borderBottom: '1px solid #E1E5E9',
                                                                borderTop: '1px solid #E1E5E9',
                                                                width: '100%',
                                                                textTransform: 'uppercase',
                                                                fontWeight: 900
                                                            }}
                                                                onClick={() => this.userModalOpen()}
                                                                className="basier-p3">

                                                                
                                                                <div style={{color: 'black', fontWeight: 900}}>
                                                                    Owner
                                                                </div>

                                                                <div style={{marginRight: 24, display: 'flex', alignItems: 'center'}}>

                                                                    <div style={{marginRight: 24, color: '#6A7E93'}}>
                                                                        {
                                                                            this.state.newOwner !== undefined && this.state.newOwner !== null ? 
                                                                            
                                                                            <div style={{display: 'flex', alignItems: 'center'}}>
                                                                                <div>
                                                                                    {this.state.newOwner.label}
                                                                                </div>
                                                                            </div>
                                                                            
                                                                            :
                                                                            <div>select...</div>
                                                                        }
                                                                    </div>

                                                                    <div>
                                                                        >
                                                                    </div>
                                                                </div>

                                                                

                                                        </div>

                                                    </div> 

                                                :
                                                <div style={{paddingLeft: 24, paddingRight: 24}}>
                                                    <div style={{marginBottom: 6}} className="basier-p4-caps">
                                                        Owner
                                                </div>
                                                    <h4>
                                                        {this.state.data['owner_name']}
                                                    
                                                    </h4>
                                                </div>
                                                }
                                        </div>
                                        {!this.state.edit &&
                                        <Divider />}
                                    </div>

                                    <div style={{marginTop: 24}}>
                                        <div style={{marginBottom: 24}}>

                                            <div style={{marginBottom: 6, paddingLeft: 24}} className="basier-p4-caps">
                                                Actions
                                            </div>

                                            <div style={{display: 'flex', alignItems: 'center', marginLeft: 12}}>
                        
                                                {this.props.sub !== undefined && this.state.data['portfolio_type'] === 'individual' && ( this.props.sub['plan']['plan_id'] === 'unlimited' || ( this.props.sub['plan']['plan_id'] === 'pro' && this.props.sub['subscription_status'] == 1 && new Date() < new Date(this.props.sub['end'] ) ) ) ? 
                                                <button className={`button-blue opacity-hover`} style={{height: 40, borderRadius: 35, marginTop: 12, width: 180,  cursor: 'pointer', marginRight: 12,}} onClick={() => this.convertToMulti()}>
                                                    Make Multi-User
                                                </button>
                                                :
                                                null}

                                                <button className={`button opacity-hover`} style={{backgroundColor: '#F9716B', color: 'white', height: 40, borderRadius: 35, marginTop: 12, width: 150,  cursor: 'pointer', marginRight: 12, border: 'none'}} onClick={() => this.deletePort()}>
                                                    Delete Portfolio
                                                </button>

                                            </div>

                                        </div>
                                        <Divider />
                                    </div>

                                </div>

                                

                                


                            </div>

                    </div>

                     <UserList users={this.state.usersScope} openModal={this.state.userModal} closeModal={this.userModalClose} handleSelect={(res) => this.handleUser(res)}/>

                </React.Fragment>
            
        )
    }


}

const mapStateToProps = state => {
    return {
        user_id: state.auth.user_id, 
        token: state.auth.token,
        sub: state.auth.subscription,
        list_portfolios: state.auth.list_portfolios
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setInteraction: (data) => 
            dispatch(interActions.setInteraction(data)), 
        setPorts: (ports) => dispatch(authActions.setPorts(ports)),
        showSnack: (data) => dispatch(interActions.showSnack(data)), 
        toggleModal: (data) => dispatch(interActions.toggleModal(data)), 
        fetchUserPreferences: (user) => dispatch(authActions.fetchUserPreferences(user)), 
        updateSinglePortfolio: (user) => dispatch(dataActions.updateSinglePortfolio(user)), 
        

    };
};


export default connect(mapStateToProps, mapDispatchToProps)(SmallGeneral)



