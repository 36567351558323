
/* AUTH */
export const AUTH_START = "AUTH_START";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_CHECK = "AUTH_CHECK";
export const AUTH_FAIL = "AUTH_FAIL";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const USER_PREF = "USER_PREF";
export const UPDATE_PORTS = "UPDATE_PORTS";
export const UPDATE_PREFS = "UPDATE_PREFS";
export const UPDATE_USER = "UPDATE_USER";
export const LOAD_APP = "LOAD_APP";
export const UPDATE_ROLES = "UPDATE_ROLES";
export const FLATTEN_FILTERS_IMPORT = "FLATTEN_FILTERS_IMPORT";
export const SAVE_TV = "SAVE_TV";

/* APP WIDE ACTIONS */

export const APPWIDE_ALL_COINS = "APPWIDE_ALL_COINS";
export const APPWIDE_CRYPTO = "APPWIDE_CRYPTO";
export const APPWIDE_EXCHANGES = "APPWIDE_EXCHANGES";
export const APPWIDE_WALLETS = "APPWIDE_WALLETS";
export const APPWIDE_SINGLE_WALLET = "APPWIDE_SINGLE_WALLET";
export const APPWIDE_FLAT_WALLETS = "APPWIDE_FLAT_WALLETS";
export const APPWIDE_ALL_PORTS = "APPWIDE_ALL_PORTS";
export const APPWIDE_ORG_LIST = "APPWIDE_ORG_LIST";
export const APPWIDE_NOTIFS = "APPWIDE_NOTIFS";
export const APPWIDE_PLAN = "APPWIDE_PLAN";
export const APPWIDE_PAY = "APPWIDE_PAY";
export const APPWIDE_LIMITS = "APPWIDE_LIMITS";
export const APPWIDE_ADD_PORT = "APPWIDE_ADD_PORT";
export const APPWIDE_ADD_TR = "APPWIDE_ADD_TR";
export const APPWIDE_TASKS = "APPWIDE_TASKS";
export const APPWIDE_ACCOUNT_NAMES = "APPWIDE_ACCOUNT_NAMES";
export const APPWIDE_CONTRACTS = "APPWIDE_CONTRACTS";
export const APPWIDE_ADDRESSES = "APPWIDE_ADDRESSES";
export const APPWIDE_NFT_COLLECTION = "APPWIDE_NFT_COLLECTION";
export const APPWIDE_NFT_STAT = "APPWIDE_NFT_STAT";
export const APPWIDE_NFT_PIECE = "APPWIDE_NFT_PIECE";

export const APPWIDE_INVITES_REQUEST = "APPWIDE_INVITES_REQUEST";
export const APPWIDE_INVITES_SUCCESS = "APPWIDE_INVITES_SUCCESS";
export const APPWIDE_INVITES_ERROR = "APPWIDE_INVITES_ERROR";
export const APPWIDE_CONNECTORS = "APPWIDE_CONNECTORS";

export const APPWIDE_SUB = "APPWIDE_SUB";
export const ROI_EVOL_SUCCESS = "ROI_EVOL_SUCCESS";
export const USER_RISK_SUCCESS = "USER_RISK_SUCCESS";
export const BALANCE_RISK_SUCCESS = "BALANCE_RISK_SUCCESS";

/* GLOBAL UPDATES */
export const UPDATE_GLOBALS = "UPDATE_GLOBALS";
export const OVERWRITE_PORTS = "OVERWRITE_PORTS";

/* Static state changes */ 
export const UPDATE_FIAT = "UPDATE_FIAT";
export const SET_VIEW_FIAT = "SET_VIEW_FIAT";
export const UPDATE_MENU = "UPDATE_MENU";
export const UPDATE_MENU_PATH = "UPDATE_MENU_PATH";
export const UPDATE_PF_TAB = "UPDATE_PF_TAB";
export const FILTER_FIAT = "FILTER_FIAT";
export const UPDATE_PF_LIST = "UPDATE_PF_LIST";
export const BTC_SYMBOL = "BTC_SYMBOL";

// API Call management 
export const SET_UPDATE_TIME = "SET_UPDATE_TIME";
export const SET_CURRENT_VIEW = "SET_CURRENT_VIEW";

/* Error handling */
export const SNACKBAR_SHOW = "SNACKBAR_SHOW";
export const SNACKBAR_HIDE = "SNACKBAR_HIDE";

// Modal interactions 

export const TOGGLE_ASSET_MODAL = "TOGGLE_ASSET_MODAL";
export const TOGGLE_ADD_TRANSACTION_MODAL = "TOGGLE_ADD_TRANSACTION_MODAL";
export const ADD_TRANSACTION_DATA = "ADD_TRANSACTION_DATA";
export const UPDATE_TRANSACTION_DATA = "UPDATE_TRANSACTION_DATA";
export const REMOVE_TRANSACTION_DATA = "REMOVE_TRANSACTION_DATA";
export const SET_TR_TIME = "SET_TR_TIME";
export const SET_INTERACTION = "SET_INTERACTION";
export const SET_MODAL = "SET_MODAL";

export const ADD_TRANSFER_DATA = "ADD_TRANSFER_DATA";
export const UPDATE_TRANSFER_DATA = "UPDATE_TRANSFER_DATA";
export const REMOVE_TRANSFER_DATA = "REMOVE_TRANSFER_DATA";

export const UPDATE_WALLET_DATA = "UPDATE_WALLET_DATA";
export const REMOVE_WALLET_DATA = "REMOVE_WALLET_DATA";

export const CHANGE_MOBILE_WALLET = "CHANGE_MOBILE_WALLET";
export const SET_TR_FILTER = "SET_TR_FILTER";
export const SET_POS_FILTER = "SET_POS_FILTER";




/* Overview Graph */
export const UPDATE_GRAPH_REQUEST = "UPDATE_GRAPH_REQUEST";
export const UPDATE_GRAPH_SUCCESS = "UPDATE_GRAPH_SUCCESS";
export const UPDATE_GRAPH_ERROR = "UPDATE_GRAPH_ERROR";
export const UPDATE_GLOBAL_TIME = "UPDATE_GLOBAL_TIME";
export const GLOBAL_GRAPH_SCOPE = "GLOBAL_GRAPH_SCOPE";


/* Portfolio Graph */
export const UPDATE_PORTFOLIO_GRAPH_REQUEST = "UPDATE_PORTFOLIO_GRAPH_REQUEST";
export const UPDATE_PORTFOLIO_GRAPH_SUCCESS = "UPDATE_PORTFOLIO_GRAPH_SUCCESS";
export const UPDATE_PORTFOLIO_GRAPH_ERROR = "UPDATE_PORTFOLIO_GRAPH_ERROR";
export const UPDATE_PORTFOLIO_TIME = "UPDATE_PORTFOLIO_TIME";
export const PF_GRAPH_SCOPE = "PF_GRAPH_SCOPE";


/* Get all Portfolios data */
export const UPDATE_ALL_PORTFOLIOS_REQUEST = "UPDATE_ALL_PORTFOLIOS_REQUEST";
export const UPDATE_ALL_PORTFOLIOS_SUCCESS = "UPDATE_ALL_PORTFOLIOS_SUCCESS";
export const UPDATE_ALL_PORTFOLIOS_ERROR = "UPDATE_ALL_PORTFOLIOS_ERROR";
export const SET_PF_SCOPE = "SET_PF_SCOPE";
export const PORT_HOLDINGS_SUCCESS = "PORT_HOLDINGS_SUCCESS";

/* Get ONE Portfolios data */
export const UPDATE_PORTFOLIO_REQUEST = "UPDATE_PORTFOLIO_REQUEST";
export const UPDATE_PORTFOLIO_SUCCESS = "UPDATE_PORTFOLIO_SUCCESS";
export const UPDATE_PORTFOLIO_ERROR = "UPDATE_PORTFOLIO_ERROR";

export const UPDATE_PORTFOLIO_HIST_REQUEST = "UPDATE_PORTFOLIO_HIST_REQUEST";
export const UPDATE_PORTFOLIO_HIST_SUCCESS = "UPDATE_PORTFOLIO_HIST_SUCCESS";
export const UPDATE_PORTFOLIO_HIST_ERROR = "UPDATE_PORTFOLIO_HIST_ERROR";

/* Get all balances for page 1 */
export const UPDATE_ALL_BALANCES_REQUEST = "UPDATE_ALL_BALANCES_REQUEST";
export const UPDATE_ALL_BALANCES_SUCCESS = "UPDATE_ALL_BALANCES_SUCCESS";
export const UPDATE_ALL_BALANCES_ERROR = "UPDATE_ALL_BALANCES_ERROR";


/* Get portfolio balances for page 1 */
export const UPDATE_SINGLE_BALANCES_REQUEST = "UPDATE_SINGLE_BALANCES_REQUEST";
export const UPDATE_SINGLE_BALANCES_SUCCESS = "UPDATE_SINGLE_BALANCES_SUCCESS";
export const UPDATE_SINGLE_BALANCES_ERROR = "UPDATE_SINGLE_BALANCES_ERROR";


/* Get all imports (connected accounts) */
export const ACC_TR_IMPORT_REQUEST = "ACC_TR_IMPORT_REQUEST";
export const ACC_TR_IMPORT_SUCCESS = "ACC_TR_IMPORT_SUCCESS";
export const ACC_TR_IMPORT_ERROR = "ACC_TR_IMPORT_ERROR";

/* Get all Transactions */
export const GET_TRANSACTION_REQUEST = "GET_TRANSACTION_REQUEST";
export const GET_TRANSACTION_SUCCESS = "GET_TRANSACTION_SUCCESS";
export const GET_TRANSACTION_ERROR = "GET_TRANSACTION_ERROR";

/* Get all Positions */
export const GET_POS_TRANSACTION_REQUEST = "GET_POS_TRANSACTION_REQUEST";
export const GET_POS_TRANSACTION_SUCCESS = "GET_POS_TRANSACTION_SUCCESS";
export const GET_POS_TRANSACTION_ERROR = "GET_POS_TRANSACTION_ERROR";


/* Get all Transactions */
export const GET_DEPOSITS_REQUEST = "GET_DEPOSITS_REQUEST";
export const GET_DEPOSITS_SUCCESS = "GET_DEPOSITS_SUCCESS";
export const GET_DEPOSITS_ERROR = "GET_DEPOSITS_ERROR";

/* Get all Transactions */
export const GET_WALLET_BALANCE_REQUEST = "GET_WALLET_BALANCE_REQUEST";
export const GET_WALLET_BALANCE_SUCCESS = "GET_WALLET_BALANCE_SUCCESS";
export const GET_WALLET_BALANCE_ERROR = "GET_WALLET_BALANCE_ERROR";

/* Portfolio and Global Totals */
export const UPDATE_TOTAL_PORTFOLIO_VALUE_SUCCESS = "UPDATE_TOTAL_PORTFOLIO_VALUE_SUCCESS";
export const UPDATE_TOTAL_VALUE_SUCCESS = "UPDATE_TOTAL_VALUE_SUCCESS";



export const UPDATE_SCOPED_USER = "UPDATE_SCOPED_USER";
export const GET_SUBS = "GET_SUBS";


export const ACCOUNT_CONNECTED_BALANCES = "ACCOUNT_CONNECTED_BALANCES";
export const ACCOUNT_TRANSFER_BALANCES = "ACCOUNT_TRANSFER_BALANCES";
export const ACCOUNT_POSITION_BALANCES = "ACCOUNT_POSITION_BALANCES";
export const INIT_PROFITS = "INIT_PROFITS";

