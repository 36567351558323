import React from 'react';
import { connect } from 'react-redux';
import DateTimePicker from 'react-datetime';
import 'react-datetime/css/react-datetime.css'
import FiatLarge from '../../../../AppWide/FiatLarge';
import Oops from '../../../../../Media/oops.svg'
import okGreen from '../../../../../Media/okGreen.svg'
import { saveAs } from 'file-saver';
import selectBackground from '../../../../../Media/selectBackground.svg';
import loadingSpinner from '../../../../../Media/contentLoader.svg';
import * as interActions from "../../../../../store/actions/interactions";
import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from '@material-ui/core/styles';

const ColorLinearProgress = withStyles({
    root: {
        height: 3
    },
    colorPrimary: {
      backgroundColor: 'rgba(0, 120, 219, 0.2)',
    },
    barColorPrimary: {
      backgroundColor: '#0078DB',
    },
  })(LinearProgress);

var moment = require('moment');
require('moment/locale/en-gb');

class PortfolioReport extends React.PureComponent {

    state = {

        currency: 'USD', 
        serverDate: null,
        validation: {
            date: true,
            currency: true,
        }, 
        futurDate: false,
        currenciesData: null, 
        balanceData: null,
        loading: false,
        downloading: false,
        completed: false,

    }


    componentDidMount() {

        let totalFiat = null
        if (this.props.initialFiat !== undefined && this.props.initialFiat !== null && this.props.initialFiat !== []) {
            totalFiat = [{value: 'bitcoin', label: 'BTC', full_name: 'Bitcoin'}, ...this.props.initialFiat]
        } else {
            totalFiat = null
        }
        
        const now = new Date()
        let oneYearFromNow = new Date()
        oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() - 1);
        const moment_date_now = moment(now);
        const moment_date_before = moment(oneYearFromNow);
        const serverStart = moment_date_now.local().format("YYYY-MM-DD")
        const serverEnd = moment_date_before.local().format("YYYY-MM-DD")
        this.setState(state => ({...state, end: serverStart, currenciesData: totalFiat}))
        this.setState(state => ({...state, start: serverEnd, currenciesData: totalFiat}))

    }
    
    componentDidUpdate(prevProps) {
        if (prevProps.initialFiat !== this.props.initialFiat) {
            let totalFiat = null
            if (this.props.initialFiat !== undefined && this.props.initialFiat !== null && this.props.initialFiat !== []) {
                totalFiat = [{value: 'BTC', label: 'BTC', full_name: 'Bitcoin'}, ...this.props.initialFiat]
            } else {
                totalFiat = null
            }
            this.setState(state => ({...state, currenciesData: totalFiat}))
        }

    }
    handleChange = (date, scope) => {
        //console.log(date)
        const testStateDate = moment( date, "YYYY-MM-DD", true).local()

        if (testStateDate.isValid()) {
            let dateRedux = moment(date);
            const serverDate = dateRedux.local().format("YYYY-MM-DD")
            this.setState(state => ({...state, [scope]: serverDate}))
            if (dateRedux.isValid()) {     
                this.setState(state => ({...state, validation: {...state.validation, date: true}}))         
                const serverDate = dateRedux.local().format("YYYY-MM-DD")
                this.setState(state => ({...state, [scope]: serverDate}))
                const now = new Date()
                if (date > now) {
                    this.setState(state => ({...state, futurDate: true}))    
                } else {
                    this.setState(state => ({...state, futurDate: false}))   
                }
                
            } else {

                this.setState(state => ({...state, validation: {...state.validation, date: false}}))    
            }

        } else {

            this.setState(state => ({...state, validation: {...state.validation, date: false}}))   

        }
    }


    
    updateData = (val) => {

        this.setState(state => ({...state, currency: val}))

    }

    generateReport = () => {

        
        const data = {
            start: this.state.start, 
            end: this.state.end, 
            currency: this.state.currency, 
            portfolio: this.props.portfolioId, 
            user: this.props.user_id, 
            scope: 'portfolioReport'
        }

        this.props.requestAnalysis(data)
        .then(
            json => {
                this.setState(state => ({...state, error: false, completed: true}))
            }
        )
        .catch(
            err => {
                this.setState(state => ({...state, error: true, completed: true}))
            }
        )

    }

    render () {

        return (

            <div style={{width: '100%', height: 'inherit', display: 'flex', alignItems: 'center'}}>

                <div style={{height: '90%', width: 200, borderRight: '1px solid #E1E5E9', paddingTop: 12, paddingBottom: 12, paddingLeft: 24, paddingRight: 24, display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>

                    <div>
                    <h4>Parameters</h4>


                    <div style={{marginTop: 24}} className="basier-p4-caps">Currency</div>
                    <div style={{marginTop: 12}}>
                        {this.state.currenciesData !== null &&
                        <FiatLarge data={this.state.currenciesData} valid={this.state.validation.currency} handleChange={(val) => this.updateData(val)} />}
                    </div>


                    <div style={{marginTop: 24}} className="basier-p4-caps">Start Date</div>
                    <div style={{paddingRight: 24, width: 175, marginTop: 12}}>
                        <DateTimePicker 
                                onChange={(date) => this.handleChange(date, 'start')} 
                                value={this.state.start} 
                                locale="en-gb"
                                closeOnSelect={true}
                                timeFormat={false}
                                strictParsing={true}
                                inputProps={{className: `select_datepicker-small basier-p3 ${!this.state.validation.date ? "error-input" : null }`, placeholder: 'YYYY-MM-DD'}}
                            />
                    </div>

                    <div style={{marginTop: 24}} className="basier-p4-caps">End Date</div>
                    <div style={{paddingRight: 24, width: 175, marginTop: 12}}>
                        <DateTimePicker 
                                onChange={(date) => this.handleChange(date, 'end')} 
                                value={this.state.end} 
                                locale="en-gb"
                                closeOnSelect={true}
                                timeFormat={false}
                                strictParsing={true}
                                inputProps={{className: `select_datepicker-small basier-p3 ${!this.state.validation.date ? "error-input" : null }`, placeholder: 'YYYY-MM-DD'}}
                            />
                    </div>

                    </div>
                    
                    {this.state.completed ? 
                    
                    <div>
                        <div className='button-next-blue' onClick={() => this.props.backAction()} 
                            style={{height: 40, borderRadius: 35, marginTop: 0, cursor: 'pointer'}}>
                            Back
                        </div>
                    </div>

                    :

                    <div>
                        <div className='button-next-blue' onClick={() => this.generateReport()} 
                            style={{height: 40, borderRadius: 35, marginTop: 0, cursor: 'pointer'}}>
                            Generate Report
                        </div>
                    </div>
                    }


                </div>

                <div style={{height: '95%', padding: 24, width: '100%', display: 'flex', flexDirection: 'column'}}>
                    
                    
                    
                    {   
                        this.state.loading ? 
                        <div style={{width: '100%', height: 'inherit', flexDirection: 'column', display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center'}}>
                            <img style={{height: 120, with: 120}} src={loadingSpinner} alt="Loading..."/>
                            <div className="basier-p2" style={{marginTop: 6}}>Loading data</div>
                        </div>
                        :
                        this.state.completed === false ? 

                        <div style={{width: '100%', display: 'flex', justifyContent: 'center', textAlign: 'center', flexDirection: 'column'}}>
                            <h3>Please select parameters and generate the report.</h3>
                            <img src={selectBackground} alt="" style={{height: 400}} />
                        </div>

                        : 

                        this.state.error ? 

                        <div style={{width: '100%', display: 'flex', justifyContent: 'center', textAlign: 'center', flexDirection: 'column'}}>
                            <img style={{marginTop: 24, height: 150}} src={Oops} alt="" />
                            <h3>Unavailable data</h3>
                            <div className="basier-p2">
                                This data is currently unavailable. This might be a temporary issue as we update our data. Please try again in a few minutes.
                            </div>
                        </div>

                        :   

                            <div style={{width: '100%', display: 'flex', justifyContent: 'center', textAlign: 'center', flexDirection: 'column'}}>
                                <img style={{marginTop: 24, height: 150}} src={okGreen} alt="" />
                                <h3>Report requested!</h3>
                                <div className="basier-p2">
                                    Your request for a portfolio report has been saved. We will generate your report in the background. It will be available within the next 5 minutes. 
                                </div>
                            </div>
                    
                        }
                    
                </div>

            </div>
        )
    }


}

const mapStateToProps = state => {
    return {
        initialFiat: state.data.fiatList,
        user_id: state.auth.user_id, 
        token: state.auth.token,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        showSnack: (data) => dispatch(interActions.showSnack(data)), 
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(PortfolioReport)
