import React from "react";
import { connect } from "react-redux";
import Divider from "@material-ui/core/Divider";
import bin from "../../../../../Media/bin.svg";
import crown from "../../../../../Media/crown.svg";
import RoleList from "./RoleList";
import axios from "axios";
import { backendUrl } from "../../../../../variables";
import okGreen from "../../../../../Media/okGreen.svg";
import cancelRed from "../../../../../Media/cancelRed.svg";
import * as interActions from "../../../../../store/actions/interactions";
import { getValue } from "../../../../../helpers/helpers";

const generateUserRoles = (role, owner) => {
  switch (true) {
    case owner:
      return [{ value: "admin", label: "Admin" }];
    case role === "pro" || role === "unlimited":
      return [
        { value: "admin", label: "Admin" },
        { value: "manager", label: "Manager" },
        { value: "observer", label: "Observer" },
      ];
    default:
      return [
        { value: "manager", label: "Manager" },
        { value: "observer", label: "Observer" },
      ];
  }
};

class Collabs extends React.PureComponent {
  state = {
    scope: "members",
    roleEdit: {},
    newRole: {},
  };

  componentDidMount() {
    if (
      this.props.portfoliosData !== undefined &&
      this.props.portfoliosData[this.props.portfolioId] !== undefined
    ) {
      this.setState((state) => ({
        ...state,
        data: this.props.portfoliosData[this.props.portfolioId],
      }));
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.portfoliosData !== undefined &&
      this.props.portfoliosData[this.props.portfolioId] !== undefined &&
      this.props.portfoliosData !== prevProps.portfoliosData
    ) {
      this.setState((state) => ({
        ...state,
        data: this.props.portfoliosData[this.props.portfolioId],
      }));
    }
  }

  updateState = (data) => {
    this.setState((state) => ({ ...state, data: data }));
  };

  changeScope = (scope) => {
    this.setState((state) => ({ ...state, scope: scope }));
  };

  submitServerChange = (id, oldRole) => {
    if (oldRole !== this.state.newRole[id]) {
      const sendData = {
        id: id,
        role: this.state.newRole[id],
        user_id: this.props.user_id,
      };

      let headers = { "content-type": "application/json" };
      if (this.props.token !== null && this.props.token !== undefined) {
        headers["Authorization"] = `Token ${this.props.token}`;
      }

      const url_call = `${backendUrl}/v1/portfolio/users/`;

      axios.patch(url_call, sendData, { headers: headers }).then((json) => {
        let newUsers = [];
        this.state.data["users"].map((val) => {
          if (val["id"] == id) {
            val["role"] = this.state.newRole[id];
          }
          newUsers.push(val);
        });
        const newData = {
          ...this.state.data,
          users: newUsers,
        };
        this.setState((state) => ({
          ...state,
          data: newData,
          roleEdit: { ...state.roleEdit, [id]: false },
        }));
      });
    }
  };

  confirmDelete = (val) => {
    if (val.transactions > 0) {
      this.props.toggleModal({
        type: "delete_collab",
        open: true,
        payload: {
          handleDelete: this.deleteUser,
          updateState: this.updateState,
          data: {
            id: val.id,
            users: this.state.data["users"],
            userName: `${val.first_name} ${val.last_name}`,
            numberTx: val.transactions,
            oldUser: val.user_id,
          },
        },
      });
    } else {
      if (
        window.confirm(
          `Are you sure you want to remove ${val.first_name} ${val.last_name} from the portfolio. ${val.first_name} ${val.last_name} had no transactions so it will be a simple removal.`
        )
      ) {
        this.deleteUser(val.id);
      }
    }
  };

  deleteUser = (id) => {
    const sendData = {
      id: id,
      scope: "simple_delete",
      user_id: this.props.user_id,
    };

    let headers = { "content-type": "application/json" };
    if (this.props.token !== null && this.props.token !== undefined) {
      headers["Authorization"] = `Token ${this.props.token}`;
    }

    const url_call = `${backendUrl}/v1/portfolio/users/`;

    axios
      .post(url_call, sendData, { headers: headers })
      .then((json) => {
        let newUsers = this.state.data["users"].filter((v) => v.id !== id);

        const newData = {
          ...this.state.data,
          users: newUsers,
        };

        this.props.showSnack({
          message: "User deleted successfully",
          variant: "success",
        });
        this.setState((state) => ({ ...state, data: newData }));
      })
      .catch(() => {
        this.props.showSnack({
          message: "Error in deleting user from portfolio",
          variant: "error",
        });
      });
  };

  changeRole = (id, val) => {
    this.setState((state) => ({
      ...state,
      newRole: { ...state.newRole, [id]: val },
    }));
  };

  toggleEditRole = (id) => {
    if (this.state.roleEdit[id] === true) {
      this.setState((state) => ({
        ...state,
        roleEdit: { ...state.roleEdit, [id]: false },
      }));
    } else {
      this.setState((state) => ({
        ...state,
        roleEdit: { ...state.roleEdit, [id]: true },
      }));
    }
  };

  renderUser = (type, val, key) => {
    return (
      <div key={key}>
        <div style={{ display: "flex", alignItems: "center", height: 50 }}>
          <div
            className="basier-p3"
            style={{ width: "30%", minWidth: 200, paddingLeft: 6 }}
          >
            <div style={{ display: "flex", alignItems: "flex-end" }}>
              {this.state.data["owner"] == val.user_id ? (
                <div style={{ marginRight: 6 }}>
                  <img src={crown} alt="" style={{ height: 18 }} />
                </div>
              ) : null}

              <div
                style={{ fontWeight: "bold" }}
              >{`${val.first_name} ${val.last_name}`}</div>
            </div>
            <div
              className="basier-p4"
              style={{
                marginTop: 2,
                fontStyle: "italic",
                textTransform: "capitalize",
              }}
            >
              {`${val.plan_type == null ? "Free" : val.plan_type} Plan`}
            </div>
          </div>

          <div
            className="basier-p3"
            style={{ width: "35%", minWidth: 250, textTransform: "lowercase" }}
          >
            {val.email}
          </div>

          <div
            className="basier-p3"
            style={{ width: "25%", minWidth: 200, textTransform: "uppercase" }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              {this.state.roleEdit[val.id] === true ? (
                <div style={{ width: 120 }}>
                  <RoleList
                    roleList={generateUserRoles(
                      val.plan_type,
                      val.user_id == this.state.data["owner"]
                    )}
                    selected={val.role}
                    changeType={(r) => this.changeRole(val.id, r)}
                  />
                </div>
              ) : (
                <div>
                  {val.user_id == this.state.data["owner"] ? (
                    <span>Owner</span>
                  ) : (
                    <span>{val.role}</span>
                  )}
                </div>
              )}
              {this.state.roleEdit[val.id] === true ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: 12,
                  }}
                >
                  <div
                    style={{ marginLeft: 6, marginRight: 6 }}
                    onClick={() => this.submitServerChange(val.id, val.role)}
                  >
                    <img
                      className="opacity-hover"
                      src={okGreen}
                      style={{ height: 36, width: 36, cursor: "pointer" }}
                      alt="ok"
                    />
                  </div>
                  <div
                    style={{ marginRight: 6 }}
                    onClick={() => this.toggleEditRole(val.id)}
                  >
                    <img
                      className="opacity-hover"
                      src={cancelRed}
                      style={{ height: 36, width: 36, cursor: "pointer" }}
                      alt="no"
                    />
                  </div>
                </div>
              ) : val.user_id == this.state.data["owner"] ? null : (
                getValue(this.props.roles, [this.props.portfolioId]) ===
                  "admin" && (
                  <span
                    onClick={() => this.toggleEditRole(val.id)}
                    className="basier-p4-caps opacity-hover"
                    style={{
                      marginLeft: 6,
                      cursor: "pointer",
                      color: "#0078DB",
                    }}
                  >
                    - Edit
                  </span>
                )
              )}
            </div>
          </div>

          {getValue(this.props.roles, [this.props.portfolioId]) === "admin" && (
            <div
              className="basier-p3"
              style={{
                width: "10%",
                minWidth: 50,
                textTransform: "uppercase",
                textAlign: "right",
                paddingRight: 6,
              }}
            >
              {this.props.user_id != val.user_id &&
              val.user_id !=
                this.props.portfoliosData[this.props.portfolioId]["owner"] ? (
                <img
                  onClick={() => this.confirmDelete(val)}
                  src={bin}
                  alt="delete"
                  style={{ height: 30, cursor: "pointer" }}
                />
              ) : null}
            </div>
          )}
        </div>
        <Divider />
      </div>
    );
  };
  render() {
    return (
      <div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            className="basier-p4-caps blue-hover"
            style={{
              cursor: "pointer",
              color: this.state.scope === "members" ? "#0078DB" : "#6A7E93",
              fontWeight: this.state.scope === "members" ? 900 : 400,
            }}
            onClick={() => this.changeScope("members")}
          >
            Collaborators
          </div>

          <div className="basier-p2" style={{ marginLeft: 6, marginRight: 6 }}>
            |
          </div>

          <div
            className="basier-p4-caps blue-hover"
            style={{
              cursor: "pointer",
              color: this.state.scope === "observer" ? "#0078DB" : "#6A7E93",
              fontWeight: this.state.scope === "observer" ? 900 : 400,
            }}
            onClick={() => this.changeScope("observer")}
          >
            Observers
          </div>
        </div>

        {this.state.data !== undefined ? (
          <div style={{ marginTop: 12 }}>
            {this.state.scope === "members"
              ? this.state.data["users"]
                  .filter((v) => v.role == "admin" || v.role == "manager")
                  .map((val, key) => {
                    return this.renderUser("member", val, key);
                  })
              : this.state.scope === "observer"
              ? this.state.data["users"]
                  .filter((v) => v.role == "observer")
                  .map((val, key) => {
                    return this.renderUser("observer", val, key);
                  })
              : null}
          </div>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user_id: state.auth.user_id,
    token: state.auth.token,
    roles: state.auth.roles,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleModal: (data) => dispatch(interActions.toggleModal(data)),
    showSnack: (data) => dispatch(interActions.showSnack(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Collabs);
