import React from "react";
import { connect } from 'react-redux';
import Tooltip from '../../../AppWide/Tooltip'
import questionMark from '../../../../Media/questionMark.svg'

class ExtraProfit extends React.PureComponent {

 
    state ={
        opacity: 0,
        show: false,
    }

    componentDidMount() {
      setTimeout(() => {
        this.setState({opacity: 1, show: true})
      }, 150)
    }

    componentWillUnmount() {
      setTimeout(() => {
        this.setState({opacity: 0, show: false})
      }, 150)
    }

    expandData = () => {
        this.setState(state => ({...state, rotate: state.rotate === 0 ? 180 : 0 }))
    }

    render() {

        const {item} = this.props;

        return (
                !this.state.show ? null :
                <div style={{marginTop: 24, opacity: this.state.opacity, transition: 'opacity 250ms ease-in-out'}}>

                  <div>

                  <div
                            style={{paddingLeft: 0, paddingRight: 0, color: '#6A7E93', display: 'flex', alignItems: 'baseline', width: '100%'}}
                            className="basier-p4-caps"
                        > 
                
                        <div style={{width: '22%' }}>

                            <div className="basier-p3" style={{textTransform: 'uppercase'}} />

                            <div className="basier-p4-caps">
                                
                            </div>

                        </div> 

                        <div style={{width: '15%', textAlign: 'right'}}>
                            <div className="basier-p3" style={{textTransform: 'uppercase'}}>
                                <span style={{marginRight: 6}}>
                                    <Tooltip title="These represent the gross capital gains, not taking fees into account." placement="right">
                                        <img style={{height: 20, width: 20}} src={questionMark} alt=""/>
                                    </Tooltip>
                                </span>

                                <span>Capital Gains</span>
                                
                            </div>
                        </div> 

                        <div style={{width: '15%', textAlign: 'right'}}>
                            <div className="basier-p3" style={{textTransform: 'uppercase'}}>
                                Paid Fees
                            </div>
                        </div> 

                        <div style={{width: '15%', textAlign: 'right' }}>

                            <div className="basier-p3" style={{textTransform: 'uppercase'}}>
                                Income
                            </div>
                        </div>

                        <div style={{width: '15%', textAlign: 'right'}}>

                            <div className="basier-p3" style={{textTransform: 'uppercase'}}>
                                Dividends
                            </div>
                        </div> 

                        <div style={{width: '15%', textAlign: 'right'}}>

                            <div className="basier-p3" style={{textTransform: 'uppercase'}}>
                                Rewards
                            </div>
                        </div> 

                        <div style={{width: 50, textAlign: 'right'}} />

                    </div>


                    <div style={{ 
                      height: 30,
                      paddingTop: 8, 
                      paddingBottom: 8,  
                      display: 'flex', 
                      alignItems: 'center', 
                      paddingLeft: 6, 
                      paddingRight: 6,
                      width: '100%',
                      }} 
                      className="basier-p3">
                
                      <div style={{flex: 1, maxWidth: '22%' }} >
                        <div style={{display: 'flex', alignItems: 'center'}} />
                      </div> 

                      <div style={{width: '15%', textAlign: 'right' }} >
                          {
                          
                          item.asset_type === 'fiat' || item.scope === 'position' ? 

                          <div>-</div> :

                          <div  style={{textAlign: 'right', color: item.capital_gains === 0 ? 'black' :  item.capital_gains > 0 ? '#4CDAC1' : '#F9716B', fontWeight: item.capital_gains !== 0 ? 900 : 400   }} >

                            {item.scope === 'position' ? 
                                    this.props.currency_symbol
                                : 
                                    this.props.currency_symbol
                                }&nbsp;
                            {Math.abs(item.capital_gains) > 1 || item.capital_gains === 0 ? 
                                item.capital_gains.toLocaleString('en', {maximumFractionDigits: 2, minimumFractionDigits: 2})
                                : 
                                item.capital_gains.toLocaleString('en', {maximumFractionDigits: 6, minimumFractionDigits: 6}) }

                                
                          </div>

                          }

                      </div> 



                      <div style={{width: '15%', textAlign: 'right', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end'}} >
                          
                        <div  style={{textAlign: 'right', color: item.realized_fee === 0 ? 'black' :  '#F9716B', fontWeight: item.realized_fee !== 0 ? 900 : 400   }} >

                          {item.scope === 'position' ? 
                                  this.props.currency_symbol
                              : 
                                  this.props.currency_symbol
                              }&nbsp;
                          {Math.abs(item.realized_fee) > 1 || item.realized_fee === 0 ? 
                              item.realized_fee.toLocaleString('en', {maximumFractionDigits: 2, minimumFractionDigits: 2})
                              : 
                              item.realized_fee.toLocaleString('en', {maximumFractionDigits: 6, minimumFractionDigits: 6}) }

                              
                          </div>

                      </div> 
                      
                      <div style={{width: '15%', textAlign: 'right'}}>
                          <div  style={{textAlign: 'right', color: item.only_income === 0 ? 'black' :  item.only_income > 0 ? '#4CDAC1' : '#F9716B', fontWeight: item.only_income !== 0 ? 900 : 400   }} >

                          {item.scope === 'position' ? 
                                  this.props.currency_symbol
                              : 
                                  this.props.currency_symbol
                              }&nbsp;
                          {Math.abs(item.only_income) > 1 || item.only_income === 0 ? 
                              item.only_income.toLocaleString('en', {maximumFractionDigits: 2, minimumFractionDigits: 2})
                              : 
                              item.only_income.toLocaleString('en', {maximumFractionDigits: 6, minimumFractionDigits: 6}) }

                              
                          </div>
                      </div>



                      <div style={{width: '15%', textAlign: 'right'}}>
                          <div  style={{textAlign: 'right', color: item.dividend_profit === 0 ? 'black' :  item.dividend_profit > 0 ? '#4CDAC1' : '#F9716B', fontWeight: item.dividend_profit !== 0 ? 900 : 400   }} >

                          {item.scope === 'position' ? 
                                  this.props.currency_symbol
                              : 
                                  this.props.currency_symbol
                              }&nbsp;
                          {Math.abs(item.dividend_profit) > 1 || item.dividend_profit === 0 ? 
                              item.dividend_profit.toLocaleString('en', {maximumFractionDigits: 2, minimumFractionDigits: 2})
                              : 
                              item.dividend_profit.toLocaleString('en', {maximumFractionDigits: 6, minimumFractionDigits: 6}) }

                              
                          </div>
                      </div>

                      <div style={{width: '15%', textAlign: 'right'}}>
                          <div  style={{textAlign: 'right', color: item.mining_profit === 0 ? 'black' :  item.mining_profit > 0 ? '#4CDAC1' : '#F9716B', fontWeight: item.mining_profit !== 0 ? 900 : 400   }} >

                          {item.scope === 'position' ? 
                                  this.props.currency_symbol
                              : 
                                  this.props.currency_symbol
                              }&nbsp;
                          {Math.abs(item.mining_profit) > 1 || item.mining_profit === 0 ? 
                              item.mining_profit.toLocaleString('en', {maximumFractionDigits: 2, minimumFractionDigits: 2})
                              : 
                              item.mining_profit.toLocaleString('en', {maximumFractionDigits: 6, minimumFractionDigits: 6}) }

                              
                          </div>
                      </div>

                      <div style={{width: 50, textAlign: 'right'}} />

                  </div>
                </div>


                </div>

        )
    }
}

const mapStateToProps = state => {
    return {
        currency_symbol: state.auth.currency.symbol,
    };
};


export default connect(
    mapStateToProps,
    null
)(ExtraProfit);