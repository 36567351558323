import React from 'react'
import PortfolioCard from './PortfolioCard'
import PortfolioAdd from './PortfolioAdd'
import { connect } from 'react-redux';
import {createLoadingSelector} from '../../../store/reducers/selectors'
import { Link } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom'
import Hidden from '@material-ui/core/Hidden';
import SwipeableViews from 'react-swipeable-views';
import TouchCarousel from 'react-touch-carousel'
import './cards.css'

import ItemsCarousel from 'react-items-carousel';
import NonPassiveTouchTarget from './NonPassiveTouchTarget'


const styles = {
    root: {
      padding: '0 36px',
    },
    slideContainer: {
      padding: '0 12px',
    },
    slide: {
    },
    slide1: {
      backgroundColor: '#FEA900',
    },
    slide2: {
      backgroundColor: '#B3DC4A',
    },
    slide3: {
      backgroundColor: '#6AC0FF',
    },
  };

  function clamp (n, min, max) {
    if (n < min) {
      return min
    }
    if (n > max) {
      return max
    }
    return n
  }

  
let options = [];

class PortfolioList extends React.Component {

  state = {

    activeItemIndex: 0, 
    portfoliosList: [],

  }


  componentDidMount() {

    window.addEventListener('touchstart', this.touchStart);
    window.addEventListener('touchmove', this.preventTouch, {passive: false});
    options = [];
    for (let [key, value] of Object.entries(this.props.portData)) {
      options.push([key, value])
    }

    this.setState(state => ({...state, portfoliosList: options}))

    }

    componentDidUpdate(prevProps) {

      if (this.props.portData !== prevProps.portData) {
        options = []
        for (let [key, value] of Object.entries(this.props.portData)) {
          options.push([key, value])
        }
    
        this.setState(state => ({...state, portfoliosList: options}))    

      }
    
    }

    componentWillUnmount() {
        window.removeEventListener('touchstart', this.touchStart);
        window.removeEventListener('touchmove', this.preventTouch, {passive: false});
    }

    touchStart(e) {
        this.firstClientX = e.touches[0].clientX;
        this.firstClientY = e.touches[0].clientY;
    }

    preventTouch(e) {
        const minValue = 15; // threshold

        this.clientX = e.touches[0].clientX - this.firstClientX;
        this.clientY = e.touches[0].clientY - this.firstClientY;

        // Vertical scrolling does not work when you start swiping horizontally.
        if(Math.abs(this.clientX) > minValue){ 
            e.preventDefault();
            e.returnValue = false;
            return false;
        }
    }
    
    render() {

      const query = window.location.search.slice(1)
      const enableLoop = /\bloop\b/.test(query)
      
      const cardSize = 280 + 25
      const cardPadCount = enableLoop ? 3 : 0
      const carouselWidth = clamp(window.innerWidth, 0, 960)

      const CarouselContainer = (props) => {
        const {cursor, carouselState: {active, dragging}, ...rest} = props
        let current = -Math.round(cursor) % this.state.portfoliosList.length
        while (current < 0) {
          current += this.state.portfoliosList.length
        }
        // Put current card at center
        const translateX = (cursor - cardPadCount) * cardSize + (carouselWidth - cardSize) / 2
        return (
          <NonPassiveTouchTarget className="carousel-container">
            <NonPassiveTouchTarget
              className='carousel-track'
              style={{transform: `translate3d(${translateX}px, 0, 0)`}}
              {...rest}
            />
      
            <div className='carousel-pagination-wrapper'>
              <ol className='carousel-pagination'>
                {this.state.portfoliosList.map((_, index) => (
                  <li
                    key={index}
                    className={current === index ? 'current' : ''}
                  />
                ))}
              </ol>
            </div>
          </NonPassiveTouchTarget>
        )
      }
      

      const renderCard = (index, modIndex) => {
        const item = this.state.portfoliosList[modIndex]
        return (
          <div className='carousel-card-inner'>
            <Link component={RouterLink} to={`/portfolio/${item[0]}`}>
              <PortfolioCard data={item[1]} />
            </Link>
          </div>
        )
      }


    return (

        <div style={{marginTop: 24,}}>

        { this.state.portfoliosList !== [] ?   
          <TouchCarousel
          component={CarouselContainer}
          cardSize={cardSize}
          cardCount={this.state.portfoliosList.length}
          cardPadCount={cardPadCount}
          loop={false}
          autoplay={false}
          renderCard={renderCard}
        />
        :
        null
        }

          </div>

    )

    }
    
}


const graphPortfolioSelector = createLoadingSelector(['UPDATE_ALL_PORTFOLIOS']);

const mapStateToProps = state => {
    return {
        portData: state.data.portfoliosData,
        loading_port: graphPortfolioSelector(state),
    };
};

export default connect(
    mapStateToProps,
    null,
)(PortfolioList);
