import Axios from "axios";
import React from "react";
import { connect } from "react-redux";
import { backendUrl } from "../../../variables";
import FilterComponent from "./FilterComponent";
import SelectActions from "./SelectActions";
import TableBody from "./TableBody";
import * as actions from "../../../store/actions/data";
import * as interActions from "../../../store/actions/interactions";
import _pickBy from "lodash/pickBy";
import _keys from "lodash/keys";

class TableWrapper extends React.PureComponent {
  state = {
    filterValues: {},
    selectAll: false,
    fullSelect: false,
    excluded: {},
    checked: {},
    data: {
      portfolio: null,
    },
  };

  componentDidUpdate(prevProps) {
    if (prevProps.scope !== this.props.scope) {
      this.setState((state) => ({
        ...state,
        filterValues: {},
        selectAll: false,
        fullSelect: false,
        excluded: {},
        checked: {},
        data: {
          portfolio: null,
        },
      }));
    }
  }

  bulkAction = (type) =>
    new Promise((resolve, reject) => {
      let scoped;
      if (this.state.fullSelect === true) {
        scoped = _keys(_pickBy(this.state.excluded));
      } else {
        scoped = _keys(_pickBy(this.state.checked));
      }

      let data = {
        ...this.state.data,
        ids: scoped,
        user_id: this.props.user_id,
        all_selected: this.state.fullSelect,
        entry_type: this.props.scope === "transaction" ? "spot" : "position",
        filters: this.props.filters,
      };

      if (type !== "assign") {
        data["scope"] = type;
      }

      if (this.props.accountScope) {
        data['account_id'] = this.props.account
        data['account_type'] = this.props.accountType
      }

      let headers = { "content-type": "application/json" };
      if (this.props.token !== null && this.props.token !== undefined) {
        headers["Authorization"] = `Token ${this.props.token}`;
      }

      let url_call = `${backendUrl}/v1/account/bulk/`;

      Axios.post(url_call, data, { headers: headers })
        .then((json) => {
          this.props.getData();
          this.setState((state) => ({
            ...state,
            selectAll: false,
            fullSelect: false,
            checked: {},
            excluded: {},
            editAssign: false,
            assignLoad: false,
          }));
          let message = "Change successful";
          try {
            const response = json;
            message = response["data"]["data"];
          } catch (e) {}
          this.props.showSnack({ message: message, variant: "success" });
          resolve(true);
        })
        .catch((err) => {
          let message = "An error occured. Please try again.";
          try {
            const response = err.response;
            message = response["data"]["data"];
          } catch (e) {}
          this.props.showSnack({ message: message, variant: "error" });
          reject(false);
        });
    });

  selectAll = () => {
    if (this.state.fullSelect === true) {
      if (
        Object.values(this.state.excluded).filter((v) => v === true).length > 0
      ) {
        this.setState((state) => ({ ...state, excluded: {}, selectAll: true }));
      } else {
        this.setState((state) => ({
          ...state,
          excluded: {},
          selectAll: false,
          fullSelect: false,
          checked: {},
        }));
      }
      return;
    }
    let newChecked = { ...this.state.checked };

    if (this.state.selectAll === false) {
      this.props.transactions.transactions.map((val) => {
        if (this.state.checked[val.id] !== true && val.include === true) {
          newChecked[val.id] = true;
        }
      });

      this.setState((state) => ({
        ...state,
        selectAll: true,
        checked: newChecked,
      }));
    } else {
      this.setState((state) => ({
        ...state,
        selectAll: false,
        fullSelect: false,
        checked: {},
        excluded: {},
      }));
    }
  };

  selectSingle = (data) => {
    if (data.include === false) {
      return;
    }
    const id = data.id;
    if (this.state.fullSelect === true) {
      let newChecked = { ...this.state.excluded };
      if (this.state.excluded[id] === true) {
        newChecked[id] = false;
        delete newChecked[id];
      } else {
        newChecked[id] = true;
      }

      this.setState((state) => ({ ...state, excluded: newChecked }));

      if (Object.values(newChecked).filter((x) => x === true).length > 0) {
        this.setState((state) => ({ ...state, selectAll: false }));
      } else {
        this.setState((state) => ({ ...state, selectAll: true }));
      }
    } else {
      let newChecked = { ...this.state.checked };
      if (this.state.checked[id] === true) {
        newChecked[id] = false;
        delete newChecked[id];
      } else {
        newChecked[id] = true;
      }
      this.setState((state) => ({ ...state, checked: newChecked }));

      if (
        Object.keys(newChecked).length ==
        this.props.transactions.transactions.filter((v) => v.include === true)
          .length
      ) {
        if (
          JSON.stringify(Object.keys(newChecked).sort()) ==
          JSON.stringify(
            this.props.transactions.transactions
              .filter((v) => v.include === true)
              .map((e) => `${e.id}`)
              .sort()
          )
        ) {
          this.setState((state) => ({ ...state, selectAll: true }));
        } else {
          this.setState((state) => ({ ...state, selectAll: false }));
        }
      } else {
        this.setState((state) => ({ ...state, selectAll: false }));
      }
    }
  };

  toggleFull = () => {
    const initialExcluded = this.props.transactions.transactions
      .filter((v) => v.include === false)
      .map((v) => ({ [v.id]: true }));
    this.setState((state) => ({
      ...state,
      fullSelect: !state.fullSelect,
      excluded: initialExcluded,
    }));
  };

  clearSelectAll = () => {
    if (this.state.fullSelect === false) {
      this.setState((state) => ({
        ...state,
        selectAll: false,
      }));
    }
  };

  setAssign = (scope, portfolio) => {
    if (scope !== undefined) {
      this.setState((state) => ({
        ...state,
        data: { ...state.data, scope: scope, portfolio: portfolio },
      }));
    } else {
      this.setState((state) => ({
        ...state,
        data: { ...state.data, portfolio: portfolio },
      }));
    }
  };

  render() {
    return (
      <div>
        <div style={{ marginTop: 24 }}>
          <FilterComponent
            setFilter={this.props.setFilter}
            resetPage={this.props.resetPage}
            filters={this.props.filters}
            filterValues={this.state.filterValues}
            getData={(page, skp, filter) =>
              this.props.getData(page, skp, filter)
            }
          />
        </div>

        <div
          style={{
            height: 50,
            paddingLeft: 12,
            display: "flex",
            alignItems: "center",
            marginBottom: 12,
          }}
          className="basier-p4-caps"
        >
          <SelectActions
            selected={this.state.checked}
            allData={this.state.data}
            filteredData={this.state.data}
            portfolioId={this.props.portfolioId}
            transactions={this.props.transactions}
            selectAll={this.state.selectAll}
            fullSelect={this.state.fullSelect}
            scopeView={this.props.filters.scope}
            toggleFull={this.toggleFull}
            excluded={this.state.excluded}
            actionAll={this.bulkAction}
            setAssign={this.setAssign}
          />
        </div>

        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <TableBody
            getData={(page, skp, filter) =>
              this.props.getData(page, skp, filter)}
            clearSelectAll={this.clearSelectAll}
            scope={this.props.scope}
            getTransactions={(page) => this.props.movePage(page)}
            total={this.props.transactions.total}
            page={this.props.page}
            //getOverallData={this.props.getOverallData}
            data={this.props.transactions.transactions}
            number_rows={10}
            allSelected={this.state.selectAll}
            selectAll={this.selectAll}
            selectSingle={this.selectSingle}
            selected={this.state.checked}
            //selectTransaction={this.selectTransaction}
            movePage={this.props.movePage}
            excluded={this.state.excluded}
            fullSelect={this.state.fullSelect}
            //account={this.props.account}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    portfolios: state.auth.list_portfolios,
    user_id: state.auth.user_id,
    token: state.auth.token,
    transactions: state.data.importedTransactions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showSnack: (data) => dispatch(interActions.showSnack(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TableWrapper);
