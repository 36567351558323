import React from "react";
import PortfolioSelect from '../../AppWide/TablePortfolioSelector';
import { connect } from "react-redux";

function ImportPf({data, portfolios, updateServer, update}) {
  const [success, setSuccess] = React.useState(null)
  const [loading, setLoading] = React.useState(false)

  const localUpdateServer = (d) => {
    setLoading(true)
    updateServer('post', data.id, d, 'portfolio', data.entry_type).then(res => setLoading(false)).catch(e => setLoading(false))
  }

  const clearPf = () => {
    updateServer('patch', data.id, {clear: true}, 'clear', data.entry_type).then(res => {setLoading(false)}).catch(e => {setLoading(false)})
  }

  return(
    <div style={{flex: 1, alignItems: 'center'}}>
      <div style={{display: 'flex', alignItems: 'center'}}>
      {loading ?
      <div className="basier-p4-caps" style={{color: '#6A7E93'}}>Assigning</div> :
      <div className="basier-p4-caps" style={{color: '#6A7E93'}}>{data.portfolio !== null ? 'Assigned' : 'Not Assigned'}</div>}
      {/* {data.portfolio !== null && <div style={{color: '#F9716B', cursor: 'pointer'}} className="basier-p4-caps opacity-hover" onClick={clearPf}><span style={{color: '#6A7E93', marginLeft: 6, marginRight: 6}}>|</span><span>clear</span></div>} */}
      </div>
      <PortfolioSelect 
        update={update}
        valid={success} loading={loading} updateServer={(d) => localUpdateServer(d)} value={{portfolio: {id: data.portfolio, name: data.portfolio_name}}} data={portfolios} check={data} portCheck={data.portfolio}
      />
    </div>
  )

}


const mapStateToProps = state => {
  return {
      portfolios: state.auth.list_portfolios,
  };
};

const mapDispatchToProps = dispatch => {
  return {
      //updateImports: (data) => dispatch(actions.updateImports(data)), 
      //getImports: (user) => dispatch(actions.getImports(user)), 
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ImportPf);