import React from "react";
import { connect } from "react-redux";
import BaseModalTop from "../../Portfolio/PortfolioComponents/Transactions/BaseModalTop";
import currencyDict from "../../../helpers/currency.json";
import axios from "axios";
import { backendUrl, strPub } from "../../../variables";
import * as appwideActions from "../../../store/actions/appwide";
import * as authActions from "../../../store/actions/auth";
import * as actions from "../../../store/actions/interactions";
import { paymentLogos } from "../../../helpers/helpers";
import PaymentComponent from "../../AppWide/Payments/PaymentWrapper";
import loadingSpinner from "../../../Media/loadingSpinner.gif";
import okIcon from "../../../Media/okIcon.svg";
import noChange from "../../../Media/NoChange.svg";
import Oops from "../../../Media/oops.svg";
import { withStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import { ElementsConsumer } from "@stripe/react-stripe-js";
import {
  Elements,
} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

const stripePromise = loadStripe(strPub);

const displayDate = (dateSend) => {
  let date = new Date(dateSend);
  const language = navigator.language || "en";
  const options = { year: "numeric", month: "numeric", day: "numeric" };
  return date.toLocaleDateString(language, options);
};

const getValue = (dict, val) => {
  let ret;
  try {
    ret = dict[val];
  } catch (e) {}
  return ret;
};

const styles = (theme) => ({
  container: {
    width: "100%",
    padding: 48,
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      padding: 12,
    },
  },
  subContainer: {
    width: "49%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.up("md")]: {
      width: "49%",
    },
  },
  secondContainer: {
    [theme.breakpoints.down("sm")]: {
      paddingTop: 24,
    },
    [theme.breakpoints.up("md")]: {
      paddingLeft: 24,
    },
  },
  planContainer: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
    [theme.breakpoints.up("md")]: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
  },
  PlanSelector: {
    [theme.breakpoints.down("sm")]: {
      height: 75,
    },
    [theme.breakpoints.up("md")]: {
      height: 150,
    },
  },
  checkBoxRoot: {
    padding: 0,
  },
});

class ChangePlan extends React.PureComponent {
  state = {
    data: {},
  };

  componentDidMount() {
    if (
      this.props.data.currentWsPlanId === "free" ||
      this.props.data.currentWsPlanId === "unlimited"
    ) {
      this.setState({
        confirmEmail: null,
        loading: false,
        success: false,
        fetching: false,
        selectedPlan: [],
        period: null,
        data: {
          user_id: this.props.user_id,
          customer_id: this.props.data.customer_id,
          date_movement: null,
          change_type: this.props.scope,
          current_plan: this.props.data.currentWsPlanId,
          current_sub: null,
        },
        immediateChange: true,
        change: false,
        allowed: false,
        changePrice: null,
        changeMethod: false,
        paymentError: null,
        loadingMessage: null,
        checkedConsent: false,
      });
    } else {
      this.setState({
        confirmEmail: null,
        loading: false,
        success: false,
        fetching: false,
        selectedPlan: this.props.data.currentPlan,
        period: this.props.data.currentPlanData[0]["plan"]["interval"],
        data: {
          user_id: this.props.user_id,
          customer_id: this.props.data.customer_id,
          date_movement: this.props.data.currentPlanData[0][
            "current_period_end"
          ],
          change_type: this.props.scope,
          current_plan: this.props.data.currentWsPlanId,
          current_sub: this.props.data.currentPlanData[0]["id"],
        },
        immediateChange: true,
        change: false,
        allowed: false,
        changePrice: null,
        changeMethod: false,
        paymentError: null,
        loadingMessage: null,
        checkedConsent: false,
      });
    }

    this.props.getPay(this.props.user_id, this.props.data.customer_id);
  }

  handleCheck = () => {
    this.setState((state) => ({
      ...state,
      checkedConsent: !state.checkedConsent,
    }));
  };

  estimateChange = (plan_id) => {
    if (this.props.data.currentWsPlanId === "free") {
      this.setState((state) => ({
        ...state,
        fetching: false,
        changePrice: 0, // this.state.targetData.amount,
      }));
      return;
    }
    this.setState((state) => ({ ...state, fetching: true }));
    const sendData = {
      user_id: this.props.user_id,
      customer_id: this.state.data.customer_id,
      subscription_id: this.state.data.current_sub,
      plan_id: plan_id,
    };
    let headers = { "content-type": "application/json" };
    if (this.props.token !== null && this.props.token !== undefined) {
      headers["Authorization"] = `Token ${this.props.token}`;
    }

    const url_call = `${backendUrl}/v1/change_est/`;

    axios
      .post(url_call, sendData, { headers: headers })
      .then((json) => {
        this.setState((state) => ({
          ...state,
          fetching: false,
          changePrice: json["data"]["data"],
        }));
      })
      .catch((e) => {});
  };

  checkAllowChange = (currentPlan, newPlan) => {




    if (
      (currentPlan === null || currentPlan === undefined) &&
      this.props.data.currentWsPlanId === "free"
    ) {
      return [true, "i"];
    }

    const currentType = currentPlan["nickname"].toLowerCase().slice(0, 3)
    const newType = newPlan["nickname"].toLowerCase().slice(0, 3)
    const interval = currentPlan["interval"]
    const newInterval = newPlan["interval"]

    if (interval === 'year') {

        switch (true) {
            case newInterval === 'month': {
                return [true, "p"];
            }
            case newInterval === 'year' && currentType === 'ent' && (newType === 'hob' || newType === 'pro'): {
                return [false, "d"];
            } 
            case newInterval === 'year' && currentType === 'pro' && (newType === 'hob'): {
                return [false, "d"];
            } 
            case newInterval === 'year' && currentType === 'hob' && (newType === 'pro' || newType === 'ent'): {
                return [true, "i"];
            } 
            default: {
                return [true, 'i']
            }
        }

    } else if (interval === 'month') {

        switch (true) {
            case newInterval === 'year': {
                return [true, "i"];
            }
            case newInterval === 'month' && currentType === 'ent' && (newType === 'hob' || newType === 'pro'): {
                return [true, "d"];
            } 
            case newInterval === 'month' && currentType === 'pro' && (newType === 'hob'): {
                return [true, "d"];
            } 
            case newInterval === 'month' && currentType === 'hob' && (newType === 'pro' || newType === 'ent'): {
                return [true, "i"];
            } 
            default: {
                return [true, 'i']
            }
        }

    }
    return [false, 'd']
  };

  changeMethod = () => {
    this.setState((state) => ({ ...state, changeMethod: true }));
  };

  changePlan = (targetPlan, currentId, targetId, scope, planData) => {
    const change = this.props.data.currentPlan !== targetId;

    const allowChange = this.checkAllowChange(
      getValue(this.props.data.currentPlanData[0], "plan"),
      planData
    );
    this.setState((state) => ({
      ...state,
      selectedPlan: targetId,
      change: change,
      allowed: allowChange[0],
      changeType: allowChange[1],
      targetData: planData,
      data: {
        ...state.data,
        target_plan: targetPlan.plan_id,
        current_plan_id: currentId,
        target_plan_id: targetId,
      },
    }));

    if (change === true && allowChange[0] === true) {
      this.estimateChange(targetId);
    } else {
      this.setState((state) => ({ ...state, immediateChange: false }));
    }
  };

  setError = (errorMessage) => {
    this.setState((state) => ({
      ...state,
      paymentError: errorMessage,
      loading: false,
      loadingMessage: null,
    }));
  };

  setLoading = (val, message) => {
    this.setState((state) => ({
      ...state,
      loading: val,
      loadingMessage: message,
      errorMessage: null,
      success: false,
    }));
  };

  setValid = (val, message) => {
    this.setState((state) => ({
      ...state,
      success: true,
      loading: false,
      errorMessage: null,
      loadingMessage: null,
    }));
  };

  handleClose = () => {
    this.props.toggleModal({ type: null, open: false, payload: null });
  };


  handleSuccess = () => {
    this.setValid();
    this.props.getPlans(this.props.user_id);
    this.props.fetchUserPreferences({
      user_id: this.props.user_id,
      rahat_t: this.props.token,
    });
  }
  submitToServer = (payment, overPay, skipConsent, skipPlanComp) => new Promise((resolve, reject) => {
    let sendData = { ...this.state.data };

    if (skipPlanComp === true) {
      this.setLoading(true, "Requesting cancellation");
      const freePlan = this.props.data.monthlyPlans["data"].filter(
        (v) => v.amount == 0
      )[0];

      sendData = {
        ...sendData,
        target_plan: "free",
        current_plan_id: this.props.data.currentPlan,
        target_plan_id: null,
        scope: 'cancel',
      };
    }

    if (this.state.checkedConsent || skipConsent) {
      this.setLoading(true, "Updating plan");
      let paymentData = null;
      let oldPayment = null;
      if (payment !== null && payment !== undefined && overPay === true) {
        paymentData = payment;
        try {
          oldPayment = this.props.payment["methods"][0]["id"];
        } catch (e) {
          oldPayment = null;
        }
      }

      if (
        this.state.data.target_plan_id !== this.state.data.current_plan_id ||
        skipPlanComp === true
      ) {
        sendData = {
          ...sendData,
          change_timing: this.state.changeType,
          payment_id: paymentData,
          old_payment: oldPayment,
          email_check: this.state.confirmEmail,
        };

        let headers = { "content-type": "application/json" };
        if (this.props.token !== null && this.props.token !== undefined) {
          headers["Authorization"] = `Token ${this.props.token}`;
        }

        const url_call = `${backendUrl}/v1/sub/modify/`;

        axios
          .post(url_call, sendData, { headers: headers })
          .then((json) => {
            if (skipPlanComp === true) {
              this.handleSuccess();
              this.handleClose();
            } else {
            resolve({subscription: json.data.data, priceId: sendData.targetId})
            }
          })
          .catch((e) => {
            reject(e)
            let errorMessage = "An unknown error occured, please try again.";
            try {
              errorMessage = e.response.data.message;
            } catch (e) {}
            this.setError(errorMessage);
          });
      }
    } else {
      this.setError(
        "Please agree with the Terms and conditions and the payment consent."
      );
    }
  });

  handleSubmit = (ev) => {
    ev.preventDefault();
    const cardElement = this.props.elements.getElement("card");

    this.props.stripe
      .createPaymentMethod({
        type: "card",
        card: cardElement,
        billing_details: { email: "alexandre.mehrdad@gmail.com" },
      })
      .then(({ paymentMethod }) => {
      });
  };

  renderPlans = (scope, classes) => {
    let list = this.props.data.wsPlans.filter(
      (v) => v.plan_id !== "free" && v.plan_id !== "unlimited"
    );
    return (
      <div style={{ display: "flex" }}>
        {list.map((val, key) => {
          let planScope = null;

          if (scope === "monthly") {
            planScope = this.props.data.monthlyPlans["data"].filter(
              (v) =>
                v.product == val.stripe_id &&
                v.currency === this.props.data.currency
            );
          } else {
            planScope = this.props.data.yearlyPlans["data"].filter(
              (v) =>
                v.product == val.stripe_id &&
                v.currency === this.props.data.currency
            );
          }

          return (
            planScope.length > 0 && (
              <div
                key={key}
                onClick={() =>
                  this.changePlan(
                    val,
                    this.props.data.currentPlan,
                    planScope[0].id,
                    scope,
                    planScope[0]
                  )
                }
                className={`watch-list-container ${
                  planScope[0].id == this.state.selectedPlan
                    ? ""
                    : "light-blue-hover"
                } ${classes.PlanSelector}`}
                style={{
                  border: "1px solid #0078DB",
                  padding: 12,
                  cursor: "pointer",
                  width: "50%",
                  margin: 8,
                  borderRadius: 15,
                  display: "flex",
                  alignItems: "flex-start",
                  backgroundColor:
                    planScope[0].id == this.state.selectedPlan
                      ? "#0078DB"
                      : "white",
                  color:
                    planScope[0].id == this.state.selectedPlan
                      ? "white"
                      : "black",
                }}
              >
                {planScope[0].id === this.props.data.currentPlan ? (
                  <div
                    style={{
                      position: "absolute",
                      height: 18,
                      paddingLeft: 6,
                      paddingRight: 6,
                      marginTop: -20,
                      backgroundColor: "white",
                      color: "black",
                      textAlign: "center",
                      border: "1px solid #0078DB",
                    }}
                    className="basier-p4"
                  >
                    Current Plan
                  </div>
                ) : null}

                <div className={classes.planContainer}>
                  <div
                    className="basier-p2"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      textTransform: "uppercase",
                    }}
                  >
                    <div style={{ fontWeight: 900 }}>{val.plan_name}</div>
                  </div>
                  <div
                    className="basier"
                    style={{ alignSelf: "center", fontSize: 16 }}
                  >
                    {planScope[0]["amount"] > 0
                      ? `${(planScope[0]["amount"] / 100).toLocaleString("en", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}${
                          currencyDict[planScope[0]["currency"].toUpperCase()][
                            "symbol"
                          ]
                        }/${planScope[0]["interval"] === "month" ? "mo" : "yr"}`
                      : "Free"}
                  </div>
                </div>
              </div>
            )
          );
        })}
      </div>
    );
  };

  updatePeriod = (val) => {
    this.setState((state) => ({ ...state, period: val }));
  };

  cancelExistingChange = (consent) => {
    this.setLoading(
      true,
      "Requesting cancelation of all changes. Please wait."
    );
    const sendData = {
      user_id: this.props.user_id,
      check_needed: consent,
      email_check: this.state.confirmEmail,
    };

    let headers = { "content-type": "application/json" };
    if (this.props.token !== null && this.props.token !== undefined) {
      headers["Authorization"] = `Token ${this.props.token}`;
    }

    const url_call = `${backendUrl}/v1/cancel_move/`;

    axios
      .post(url_call, sendData, { headers: headers })
      .then((json) => {
        this.setValid();
        this.props.getPlans(this.props.user_id);
        this.props.fetchUserPreferences({
          user_id: this.props.user_id,
          rahat_t: this.props.token,
        });
        this.handleClose();
      })
      .catch((e) => {
        this.setError(e.response.data.message);
      });
  };

  setConfirmEmail = (e) => {
    e.persist();
    this.setState((state) => ({ ...state, confirmEmail: e.target.value }));
  };

  submitPlanChange = () => {
    this.submitToServer(null, false, false)
    .then((result) => {
      return {
        // Use the Stripe 'object' property on the
        // returned result to understand what object is returned.
        subscription: result.subscription,
        paymentMethodId: this.props.payment.methods[0].id,
        priceId: result.priceId,
      };
    })
    .then(this.handlePaymentThatRequiresCustomerAction)
  }

  handlePaymentThatRequiresCustomerAction = ({
    subscription,
    invoice,
    paymentMethodId,
    priceId,
    isRetry
  }) => {
    if (subscription && (subscription.status === "active" || subscription.status === "trialing")) {
      // Subscription is active, no customer actions required.
      this.handleSuccess();
      this.handleClose();
      return { subscription, priceId, paymentMethodId };
    }

    // If it's a first payment attempt, the payment intent is on the subscription latest invoice.
    // If it's a retry, the payment intent will be on the invoice itself.
    let paymentIntent = subscription.latest_invoice.payment_intent;

    if (
      paymentIntent.status === "requires_action" ||
      (isRetry === true && paymentIntent.status === "requires_payment_method")
    ) {
      return this.props.stripe
        .confirmCardPayment(paymentIntent.client_secret, {
          payment_method: paymentMethodId,
        })
        .then((result) => {
          if (result.error) {
            // Start code flow to handle updating the payment details.
            // Display error message in your UI.
            // The card was declined (i.e. insufficient funds, card has expired, etc).
            throw result.error;
          } else {
            if (result.paymentIntent.status === "succeeded") {
              // Show a success message to your customer.
              // There's a risk of the customer closing the window before the callback.
              // We recommend setting up webhook endpoints later in this guide.
              this.handleSuccess();
              this.handleClose();
              return { subscription, priceId, paymentMethodId };
            }
          }
        })
        .catch((error) => {
          //this.handleClose();
          return;
        });
    } else {
      // No customer action needed.
      this.handleSuccess();
      this.handleClose();
      return { subscription, priceId, paymentMethodId };
    }
  }

  render() {
    const { classes } = this.props;
    return this.props.scope === "cancel" ? (
      this.state.loading ? (
        <div
          style={{
            width: "100%",
            zIndex: 90000,
            height: 530,
            backgroundColor: "white",
            opacity: 0.9,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            position: "absolute",
            top: 90,
            zIndex: 13000,
          }}
        >
          <img style={{ height: 64, with: 64 }} src={loadingSpinner} alt="" />
          <div className="basier-p3" style={{ marginTop: 12 }}>
            {this.state.loadingMessage}
          </div>
        </div>
      ) : (
        <div style={{ width: "100%", top: 0 }}>
          <BaseModalTop
            forceSingle={true}
            title="Cancel plan"
            handleClose={this.handleClose}
            onClick={this.backToWelcome}
            welcome={this.state.welcome}
            entryType={this.state.type_selected}
          />

          <div
            style={{
              width: "100%",
              backgroundColor: "white",
              opacity: 1,
              display: "flex",
              flexDirection: "column",
              paddingTop: 0,
              paddingLeft: 24,
              paddingRight: 24,
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              position: "relative",
              zIndex: 13000,
            }}
          >
            <img src={Oops} alt="" style={{ height: 100, marginTop: 12 }} />
            <div
              style={{ marginTop: 24, fontWeight: 900, color: "#0078DB" }}
              className="basier-p2"
            >
              You are about to cancel your current Wisly plan. If you wish to
              proceed, this change will take effect on the following date:{" "}
              {displayDate(this.props.data.currentPlanEnd)}
            </div>
            <div
              style={{ marginTop: 12, paddingLeft: 24, paddingRight: 24 }}
              className="basier-p3"
            >
              Note than when you cancel a plan, you revert back to the Free Tier
              at Wisly. You will still be able to use our features but the
              limitations of a Free Tier will apply. You will not be able to
              access any Transaction above the threshold (either the investment
              threshold or transaction number threshold), and all your
              Multi-User portfolios will be converted to Solo (transactions of
              collaborators will be lost). Any report or analysis you had
              generated will still be available but you cannot generate new
              ones.
            </div>

            {this.props.data.changing === true ? (
              <div
                style={{
                  marginTop: 12,
                  paddingLeft: 24,
                  paddingRight: 24,
                  color: "#0078DB",
                }}
                className="basier-p3"
              >
                We noticed you had previously requested a change on your
                account. We will cancel this change request before cancelling
                your plan
              </div>
            ) : null}

            <div style={{ marginTop: 12, marginBottom: 12 }}>
              To confirm your plan cancelation, please type the email address
              linked to your account in the textbox below and click Cancel.
            </div>

            <div>
              <input
                type="email"
                className="light-input basier-p4"
                value={this.state.confirmEmail}
                onChange={this.setConfirmEmail}
              />
            </div>

            <button
              className="button-blue opacity-hover"
              style={{
                height: 40,
                backgroundColor: "#F9716B",
                borderRadius: 35,
                marginTop: 24,
                marginBottom: 48,
                cursor: "pointer",
              }}
              onClick={() => this.submitToServer(null, false, true, true)}
            >
              Cancel Plan
            </button>

            {this.state.paymentError !== null ? (
              <div style={{ padding: 12, color: "red" }} className="basier-p4">
                {this.state.paymentError}
              </div>
            ) : null}
          </div>
        </div>
      )
    ) : this.props.scope === "revert_cancel" ? (
      this.state.loading ? (
        <div
          style={{
            width: "100%",
            zIndex: 90000,
            height: 530,
            backgroundColor: "white",
            opacity: 0.9,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            position: "absolute",
            top: 90,
            zIndex: 13000,
          }}
        >
          <img style={{ height: 64, with: 64 }} src={loadingSpinner} alt="" />
          <div className="basier-p3" style={{ marginTop: 12 }}>
            {this.state.loadingMessage}
          </div>
        </div>
      ) : (
        <div style={{ width: "100%", top: 0 }}>
          <BaseModalTop
            forceSingle={true}
            title="Undo cancel"
            handleClose={this.handleClose}
            onClick={this.backToWelcome}
            welcome={this.state.welcome}
            entryType={this.state.type_selected}
          />

          <div
            style={{
              width: "100%",
              backgroundColor: "white",
              opacity: 1,
              display: "flex",
              flexDirection: "column",
              paddingTop: 0,
              paddingLeft: 24,
              paddingRight: 24,
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              zIndex: 13000,
            }}
          >
            <img src={Oops} alt="" style={{ height: 100, marginTop: 12 }} />
            <div
              style={{ marginTop: 24, fontWeight: 900, color: "#0078DB" }}
              className="basier-p2"
            >
              You are about to undo the cancelation request for your current
              Wisly plan. If you wish to proceed, we will simply keep your
              current plan as is and you will be billed for it as per your
              current billing agreement.
            </div>

            <div style={{ marginTop: 12, marginBottom: 12 }}>
              To confirm your plan cancelation, please type the email address
              linked to your account in the textbox below and click Submit. By
              entering the email and clicking Submit, you agree to keep your
              plan active and be charged for any upcoming invoice from Wisly.
            </div>

            <div>
              <input
                type="email"
                className="light-input basier-p4"
                value={this.state.confirmEmail}
                onChange={this.setConfirmEmail}
              />
            </div>

            <button
              className="button-blue opacity-hover"
              style={{
                height: 40,
                backgroundColor: "#0078DB",
                borderRadius: 35,
                marginTop: 24,
                cursor: "pointer",
              }}
              onClick={() => this.cancelExistingChange(true)}
            >
              Submit
            </button>

            {this.state.paymentError !== null ? (
              <div style={{ padding: 12, color: "red" }} className="basier-p4">
                {this.state.paymentError}
              </div>
            ) : null}
          </div>
        </div>
      )
    ) : this.props.data.changing === true ? (
      this.state.loading ? (
        <div
          style={{
            width: "100%",
            zIndex: 90000,
            height: 530,
            backgroundColor: "white",
            opacity: 0.9,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            position: "absolute",
            top: 90,
            zIndex: 13000,
          }}
        >
          <img style={{ height: 64, with: 64 }} src={loadingSpinner} alt="" />
          <div className="basier-p3" style={{ marginTop: 12 }}>
            {this.state.loadingMessage}
          </div>
        </div>
      ) : (
        <div>
          <BaseModalTop
            forceSingle={true}
            title=""
            handleClose={this.handleClose}
            onClick={this.backToWelcome}
            welcome={this.state.welcome}
            entryType={this.state.type_selected}
          />
          <div
            style={{
              width: "100%",
              backgroundColor: "white",
              opacity: 1,
              display: "flex",
              flexDirection: "column",
              paddingTop: 0,
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              zIndex: 13000,
            }}
          >
            <img src={Oops} alt="" style={{ height: 100, marginTop: 12 }} />
            <div
              style={{ marginTop: 24, fontWeight: 900, color: "#0078DB" }}
              className="basier-p2"
            >
              A subscription change is already planned for the following date:{" "}
              {displayDate(this.props.data.currentPlanEnd)}
            </div>
            <div
              style={{ marginTop: 12, paddingLeft: 24, paddingRight: 24 }}
              className="basier-p3"
            >
              If you wish to cancel the planned change and proceed to another
              account modification, please click below to cancel the upcoming
              change. You will then be able to modify your plan again.
            </div>

            <button
              className="button-blue opacity-hover"
              style={{
                height: 40,
                borderRadius: 35,
                marginTop: 24,
                cursor: "pointer",
              }}
              onClick={(p, o) => this.cancelExistingChange(false)}
            >
              Cancel change
            </button>

            {this.state.paymentError !== null ? (
              <div style={{ padding: 12, color: "red" }} className="basier-p4">
                {this.state.paymentError}
              </div>
            ) : null}
          </div>
        </div>
      )
    ) : this.state.success ? (
      <div
        style={{
          width: "100%",
          height: 530,
          backgroundColor: "white",
          opacity: 1,
          display: "flex",
          flexDirection: "column",
          paddingTop: 100,
          alignItems: "center",
          position: "absolute",
          top: 90,
          zIndex: 13000,
        }}
      >
        <img style={{ height: 64, with: 64 }} src={okIcon} alt="OK!" />
        <div style={{ marginTop: 24 }} className="basier-p2">
          Request successful! Thank you
        </div>
      </div>
    ) : (
      <div>
        <BaseModalTop
          forceSingle={true}
          title="Change plan"
          handleClose={this.handleClose}
          onClick={this.backToWelcome}
          welcome={this.state.welcome}
          entryType={this.state.type_selected}
        />

        {this.state.loading ? (
          <div
            style={{
              width: "100%",
              zIndex: 90000,
              height: 530,
              backgroundColor: "white",
              opacity: 0.9,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              position: "absolute",
              top: 90,
              zIndex: 13000,
            }}
          >
            <img style={{ height: 64, with: 64 }} src={loadingSpinner} alt="" />
            <div className="basier-p3" style={{ marginTop: 12 }}>
              {this.state.loadingMessage}
            </div>
          </div>
        ) : null}

        <div className={classes.container}>
          <div className={classes.subContainer}>
            <div className="basier-p4-caps" style={{ marginBottom: 12 }}>
              Monthly plans
            </div>

            {this.renderPlans("monthly", classes)}

            <div
              className="basier-p4-caps"
              style={{ marginBottom: 12, marginTop: 12 }}
            >
              Yearly plans
            </div>

            {this.renderPlans("yearly", classes)}
          </div>

          <div className={`${classes.subContainer} ${classes.secondContainer}`}>
            {this.state.change === true ? (
              this.state.allowed === true ? (
                this.state.changePrice === null ||
                this.state.fetching === true ? (
                  <div
                    style={{
                      marginTop: 120,
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center",
                    }}
                  >
                    <img
                      style={{ height: 64, with: 64 }}
                      src={loadingSpinner}
                      alt=""
                    />
                    <div className="basier-p3">Loading Changes</div>
                  </div>
                ) : (
                  <React.Fragment>
                    <div
                      className="basier-p4-caps"
                      style={{ marginBottom: 24 }}
                    >
                      Plan change summary
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "baseline",
                        width: "100%",
                        marginTop: 12,
                      }}
                    >
                      <div
                        className="basier-p3"
                        style={{
                          minWidth: 125,
                          textTransform: "uppercase",
                          color: "black",
                        }}
                      >
                        New plan cost
                      </div>
                      <div className="dot"></div>
                      <div>
                        {this.state.targetData["amount"] > 0
                          ? `${(
                              this.state.targetData["amount"] / 100
                            ).toLocaleString("en", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}${
                              currencyDict[
                                this.state.targetData["currency"].toUpperCase()
                              ]["symbol"]
                            }`
                          : "Free"}
                      </div>
                    </div>

                    {this.props.data.currentWsPlanId !== "free" ? (
                      <React.Fragment>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "baseline",
                            width: "100%",
                            marginTop: 12,
                          }}
                        >
                          <div
                            className="basier-p3"
                            style={{
                              minWidth: 135,
                              textTransform: "uppercase",
                              color: "black",
                            }}
                          >
                            Account credit
                          </div>
                          <div className="dot"></div>
                          <div>
                            {`${(
                              this.state.changePrice -
                              this.state.targetData["amount"] / 100 +
                              this.props.data.customerBalance
                            ).toLocaleString("en", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}${
                              currencyDict[
                                this.state.targetData["currency"].toUpperCase()
                              ]["symbol"]
                            }`}
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "baseline",
                            width: "100%",
                            marginTop: 12,
                          }}
                        >
                          <div
                            className="basier-p3"
                            style={{
                              minWidth: 70,
                              textTransform: "uppercase",
                              color: "black",
                            }}
                          >
                            Pay now
                          </div>
                          <div className="dot"></div>
                          <div>
                            {`${Math.max(
                              this.state.changePrice +
                                this.props.data.customerBalance,
                              0
                            ).toLocaleString("en", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}${
                              currencyDict[
                                this.state.targetData["currency"].toUpperCase()
                              ]["symbol"]
                            }`}
                          </div>
                        </div>
                      </React.Fragment>
                    ) : null}

                    <div
                      style={{
                        color: "#0078DB",
                        paddingLeft: 0,
                        fontStyle: "italic",
                        marginTop: 12,
                      }}
                      className="basier-p4"
                    >
                      {this.state.changeType === "d"
                        ? "We will downgrade your plan at the end of your current billing period. You will be charged the new amount then (unless you have account credit)"
                        : this.state.changeType === "p"
                        ? "Any future payments will also be deducted by any credit you may have. If your credit is fully used, regular monthly payments will resume."
                        : null}
                    </div>

                    <div
                      className="basier-p4-caps"
                      style={{ marginBottom: 12, marginTop: 12 }}
                    >
                      Payment method
                    </div>

                    <div>
                      {this.props.payment.number >= 1 &&
                      this.state.changeMethod === false ? (
                        <div>
                          <div
                            style={{ display: "flex", alignItems: "baseline" }}
                          >
                            <div style={{ paddingLeft: 12 }}>
                              {paymentLogos(
                                this.props.payment["methods"][0]["brand"]
                              )}
                            </div>
                            <div
                              className="basier-p3"
                              style={{
                                color: "black",
                                textTransform: "uppercase",
                              }}
                            >
                              {`Card ending in ${
                                this.props.payment["methods"][0]["last4"]
                              } expiry ${String(
                                this.props.payment["methods"][0]["month"]
                              ).padStart(2, 0)}/${
                                this.props.payment["methods"][0]["year"]
                              }`}
                            </div>
                          </div>

                          <div
                            onClick={this.changeMethod}
                            style={{
                              marginTop: 12,
                              paddingLeft: 12,
                              cursor: "pointer",
                              color: "#0078DB",
                            }}
                            className="basier-p4-caps opacity-hover"
                          >
                            Change payment method
                          </div>
                        </div>
                      ) : null}
                    </div>

                    <div>
                      {this.state.changeMethod === true ||
                      this.props.payment.number === 0 ? (
                        <PaymentComponent
                          customer_id={this.props.plan.customer_id}
                          submit={(p) => this.submitToServer(p, true, true)}
                          fullName={`${this.props.user_first_name} ${this.props.user_last_name}`}
                          user_id={this.props.user_id}
                          token={this.props.token}
                          currency={this.props.data.currency}
                          setError={(m) => this.setError(m)}
                          setLoading={(l, m) => this.setLoading(l, m)}
                          handleClose={this.handleClose}
                          handleSuccess={this.handleSuccess}
                          planId={this.state.data.targetId}
                        />
                      ) : null}
                    </div>

                    {this.props.payment.number >= 1 &&
                    this.state.changeMethod === false ? (
                      <div>
                        <div
                          style={{
                            marginTop: 24,
                            paddingLeft: 12,
                            display: "flex",
                            alignItems: "flex-start",
                          }}
                        >
                          <Checkbox
                            onClick={this.handleCheck}
                            checked={this.state.checkedConsent}
                            color="primary"
                            className={classes.checkBoxRoot}
                          />
                          <div
                            className="basier-p4"
                            style={{ paddingLeft: 12 }}
                          >
                            I agree with the{" "}
                            <a href="https://wisly.io/terms-conditions">
                              Terms and Conditions
                            </a>{" "}
                            of Wisly UK Limited, and to being charged for the
                            amount of the subscription that I have selected and
                            at the frequency that I have selected.
                          </div>
                        </div>
                        <button
                          className="button-blue opacity-hover"
                          style={{
                            height: 40,
                            borderRadius: 35,
                            marginTop: 24,
                            cursor: "pointer",
                          }}
                          onClick={(p, o) =>
                            this.submitPlanChange()
                          }
                        >
                          Submit
                        </button>
                      </div>
                    ) : null}
                    {this.state.paymentError !== null ? (
                      <div
                        style={{ padding: 12, color: "red" }}
                        className="basier-p4"
                      >
                        {this.state.paymentError}
                      </div>
                    ) : null}
                  </React.Fragment>
                )
              ) : (
                <div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    <img
                      src={Oops}
                      alt=""
                      style={{ height: 50, marginTop: 12 }}
                    />
                    <h3
                      className="basier-p2"
                      style={{ margin: 0, color: "black" }}
                    >
                      Annual plan downgrade
                    </h3>
                    <p className="basier-p3" style={{ marginTop: 12 }}>
                      You have selected to downgrade your annual plan. This plan
                      is set to end on{" "}
                      {displayDate(this.props.data.currentPlanEnd)}. Any changes
                      you make now will only take effect at renewal then.
                    </p>
                    <p className="basier-p3">
                      If you wish to discuss options to change your plan before,
                      you can contact us.
                    </p>
                  </div>

                  <div
                    className="basier-p4-caps"
                    style={{ marginBottom: 12, marginTop: 12 }}
                  >
                    Payment method
                  </div>

                  <div>
                    {this.props.payment.number >= 1 &&
                    this.state.changeMethod === false ? (
                      <div>
                        <div
                          style={{ display: "flex", alignItems: "baseline" }}
                        >
                          <div style={{ paddingLeft: 12 }}>
                            {paymentLogos(
                              this.props.payment["methods"][0]["brand"]
                            )}
                          </div>
                          <div
                            className="basier-p3"
                            style={{
                              color: "black",
                              textTransform: "uppercase",
                            }}
                          >
                            {`Card ending in ${
                              this.props.payment["methods"][0]["last4"]
                            } expiry ${String(
                              this.props.payment["methods"][0]["month"]
                            ).padStart(2, 0)}/${
                              this.props.payment["methods"][0]["year"]
                            }`}
                          </div>
                        </div>

                        <div
                          onClick={this.changeMethod}
                          style={{
                            marginTop: 12,
                            paddingLeft: 12,
                            cursor: "pointer",
                            color: "#0078DB",
                          }}
                          className="basier-p4-caps opacity-hover"
                        >
                          Change payment method
                        </div>
                      </div>
                    ) : null}
                  </div>

                  <div>
                    {this.state.changeMethod === true ||
                    this.props.payment.number === 0 ? (
                      <PaymentComponent
                      customer_id={this.props.plan.customer_id}
                        submit={(p) => this.submitToServer(p, true, true)}
                        fullName={`${this.props.user_first_name} ${this.props.user_last_name}`}
                        user_id={this.props.user_id}
                        token={this.props.token}
                        currency={
                          "usd"
                        }
                        setError={(m) => this.setError(m)}
                        setLoading={(l, m) => this.setLoading(l, m)}
                        handleClose={this.handleClose}
                        handleSuccess={this.handleSuccess}
                        planId={this.state.data.targetId}
                      />
                    ) : null}
                  </div>

                  {this.props.payment.number >= 1 &&
                  this.state.changeMethod === false ? (
                    <div>
                      <div
                        style={{
                          marginTop: 12,
                          paddingLeft: 12,
                          display: "flex",
                          alignItems: "flex-start",
                        }}
                      >
                        <Checkbox
                          onChange={this.handleCheck}
                          checked={this.state.checkedConsent}
                          color="primary"
                          className={classes.checkBoxRoot}
                        />
                        <div className="basier-p4" style={{ paddingLeft: 12 }}>
                          I agree with the{" "}
                          <a href="https://wisly.io/terms-conditions">
                            Terms and Conditions
                          </a>{" "}
                          of Wisly UK Limited, and to being charged for the
                          amount of the subscription that I have selected and at
                          the frequency that I have selected.
                        </div>
                      </div>
                      <button
                        className="button-blue opacity-hover"
                        style={{
                          height: 40,
                          borderRadius: 35,
                          marginTop: 12,
                          cursor: "pointer",
                        }}
                        onClick={(p, o) =>
                          this.submitToServer(null, false, false)
                        }
                      >
                        Submit
                      </button>
                    </div>
                  ) : null}
                  {this.state.paymentError !== null ? (
                    <div
                      style={{ padding: 12, color: "red" }}
                      className="basier-p4"
                    >
                      {this.state.paymentError}
                    </div>
                  ) : null}
                </div>
              )
            ) : this.props.data.active == 0 ? (
              <div>
                <div
                  className="basier-p3"
                  style={{ marginBottom: 24, marginTop: 36 }}
                >
                  There seems to have been an issue with your payment. Please
                  update your payment details below.
                </div>
                <PaymentComponent
                  customer_id={this.props.plan.customer_id}
                  submit={(p) => this.submitToServer(p, true, true)}
                  fullName={`${this.props.user_first_name} ${this.props.user_last_name}`}
                  user_id={this.props.user_id}
                  token={this.props.token}
                  currency={
                    "usd"
                  }
                  setError={(m) => this.setError(m)}
                  setLoading={(l, m) => this.setLoading(l, m)}
                  handleClose={this.handleClose}
                  handleSuccess={this.handleSuccess}
                  planId={this.state.data.targetId}
                />
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  width: "100%",
                  textAlign: "center",
                }}
              >
                <h3 style={{ margin: 0 }}>
                  Please select a different plan to change your subscription
                </h3>
                <img
                  src={noChange}
                  alt=""
                  style={{ height: 300, marginTop: 12 }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user_id: state.auth.user_id,
    token: state.auth.token,
    payment: state.appwide.payMethods,
    user_first_name: state.auth.user_first_name,
    user_last_name: state.auth.user_last_name,
    subscription: state.auth.subscription,
    plan: state.appwide.plan,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPlans: (user) => dispatch(appwideActions.getPlans(user)),
    getPay: (user, cust_id) => dispatch(appwideActions.getPay(user, cust_id)),
    fetchUserPreferences: (user) =>
      dispatch(authActions.fetchUserPreferences(user)),
    toggleModal: (data) => dispatch(actions.toggleModal(data)),
  };
};

const SplitForm = (props) => (
  <Elements locale='en' stripe={stripePromise}>
  <ElementsConsumer>
    {({ stripe }) => (
      <ChangePlan {...props} stripe={stripe} />
    )}
  </ElementsConsumer>
  </Elements>
);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(SplitForm));
