import React from 'react';
import { connect } from 'react-redux';
import TopSection from './TopSection';
import TransactionPicker from '../../../Portfolio/PortfolioComponents/Transactions/NewStructure/NTransactionPicker';
import WalletBalance from './WalletBalance'
import SettingComponent from './SettingComponent'
import { backendUrl } from '../../../../variables'
import {loadData} from '../../../../helpers/helpers';
import ImportOverview from '../../../Reconcile/ReconcileOverview'
import loadingSpinner from "../../../../Media/contentLoader.svg";


const MessageContent = (props) => {
    return (
        <div className="basier-p3" style={{flex: 1, border: '2px solid #e0a03d', minHeight: 40, marginTop: 12, padding: '12px 24px', borderRadius: 10, backgroundColor: "#ffc1631A"}}>
            {props.children}      
        </div>
    )
}

class AccountDetail extends React.PureComponent {

    state = {
        section: this.props.scope === 'exchange' ? 'wallets' : 'transactions', 
        balance: undefined,
        reported: {},
        loading: false,
        reportLoading: false
    }

    componentDidUpdate(prevProps) {
        if (this.props.data !== prevProps.data) {
            this.setState({section: this.props.scope === 'exchange' ? 'wallets' : 'transactions'})
        }
    }

    selectSection = (v) => {
        this.setState(state => ({...state, section: v}))
    }

    getData = () => {
        
        this.setState(state => ({...state, loading: true}))
        this.setState(state => ({...state, reportLoading: true}))
        let headers = { "content-type": "application/json" };
        if (this.props.token !== null && this.props.token !== undefined) {

            headers['Authorization'] = `Token ${this.props.token}`

        }

        if (this.props.data.connected == true) {
            const data_fetch = fetch(`${backendUrl}/v1/wallets/connected/${this.props.data.id}/balances/?u=${this.props.user_id}&type=${this.props.scope}`, {headers: headers})
            data_fetch
                .then(res => res.json())
                .then(json => 
                    {      
                        this.setState(state => ({...state, reported: json['data'], reportLoading: false}))
                    }
                )
                .catch(e => {
                    this.setState(state => ({...state, reportLoading: false}))
                })
        } else {
            this.setState(state => ({...state, reportLoading: false}))
        }

        const data_fetch = loadData(`${backendUrl}/v1/wallets/${this.props.data.id}/values/?u=${this.props.user_id}&type=${this.props.scope}&curr=${this.props.currency}`, headers)
        data_fetch
            .then(json => 
                {
                    this.setState(state => ({...state, balance: json['data'], loading: false}))
                }
            )
            .catch(e => {
                this.setState(state => ({...state, loading: false}))
            })
        
        
        
        

    }

    

    renderMessage = (text) => {
        return (
        <MessageContent>
            <b>Note: </b><span>{text}</span>
        </MessageContent>
        )

    }

    showWarning = (data) => {
        if (data.connected === false) {
            return null
        }
        const scope = ['binance', 'bittrex']
        if (!scope.includes(data.source_id)) {
            return null
        }

        switch (data.source_id) {
            case 'binance': {
                return this.renderMessage("Binance API Imports do not support credit card and liquidity pool transactions. Please input these trades manually, otherwise the calculated balances and profits will be incorrect.")
            }
            default: {
                return null
            }
        }
    }

    switchAccounts = () => {
        this.setState(state => ({...state, balance: undefined, reported: {}, loading: true, reportLoading: true}))
    }


    render () {
        
        const { data } = this.props
        
        let beginning = this.props.scope === 'exchange' ? ['wallets', 'transactions'] : ['wallets', 'transactions']
        let end = ['settings']

        let categories = data.connected === true ? [...beginning, 'imports', ...end] : [...beginning, ...end]

        return (
            data !== null && data !== undefined &&

            <div style={{paddingLeft: 24, paddingTop: 12, width: '100%'}}>
                <div style={{width: '100%'}}>
                    <TopSection getData={this.getData} data={data} scope={this.props.scope} walletsData={this.props.walletsData[this.props.user_id]['storage']} />
                </div>

                <div>{this.showWarning(data)}</div>

                <div style={{marginTop: 24, marginBottom: 24}}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                    {
                        categories.map((v, k) => {
                            return (
                                <React.Fragment key={k} className="basier-p4-caps">
                                    <div 
                                        onClick={() => this.selectSection(v)}
                                        style={{
                                            textTransform: 'uppercase', 
                                            cursor: 'pointer', 
                                            color: v === this.state.section ? '#0078DB' : '#6A7E93'
                                        }}
                                    >
                                        {v}
                                    </div>
                                    {k === categories.length - 1 ? null : <div style={{marginLeft: 12, marginRight: 12}}>|</div>}
                                </React.Fragment>
                                
                            )
                        })
                    }
                    </div>
                </div>

                {this.state.section === 'wallets' &&
                    <div style={{width: '100%', flex: 1}}>
                    <WalletBalance accountData={this.props.data} getData={this.getData} account={true} data={this.props.data.wallets} accountId={this.props.data.id} switchAccounts={this.switchAccounts} exchange={this.props.data.source_id} balance={this.state.balance} reported={this.state.reported} loading={this.state.loading} /> 
                    </div>
                }

                {this.state.section === 'transactions' && 

                    <div style={{marginTop: 48}}>
                    <div style={{marginBottom: 24}}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <h4 style={{margin: 0}}>
                                Account Transactions
                            </h4>
                        </div>
                    </div>

                    <TransactionPicker account={true} portfolioId={this.props.data.id} ascope={this.props.scope} /> 
                    </div>
                }

                {this.state.section === 'imports' && <div style={{marginTop: 48}}><ImportOverview account={this.props.data.id} givenScope={true} title="Account Imports" type={this.props.scope} accountScope={true} /></div> }

                {this.state.section === 'settings' && <SettingComponent account={this.props.data.id}  data={this.props.data} type={this.props.scope} /> }

            </div>
            
        )
    }


}

const mapStateToProps = state => {
    return {
        walletsData: state.appwide.wallets,
        user_id: state.auth.user_id, 
        token: state.auth.token,
        currency: state.auth.currency.id,
    };
};

const mapDispatchToProps = dispatch => {
    return {
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(AccountDetail)
