import React from 'react';
import { connect } from 'react-redux';
import AuditTable from '../../../../Settings/GlobalAuditTable';
import {loadData} from '../../../../../helpers/helpers'
import { backendUrl } from '../../../../../variables'

class AuditWrapper extends React.PureComponent {

    state = {
        auditData: undefined
    }

    componentDidMount() {

        let headers = { "content-type": "application/json" };
        headers["Authorization"] = `Token ${this.props.token}`;

        const data_fetch = loadData(`${backendUrl}/v1/audit_trail/?p=${this.props.portfolioId}&u=${this.props.user_id}`, headers)
        data_fetch
            .then(json => {

                this.setState(state => ({...state, auditData: json['data']}))

            })
            .catch(e => {
                console.log(e)
            })



    }

    componentDidUpdate(prevProps) {

        if (prevProps.portfolioId !== this.props.portfolioId) {
            let headers = { "content-type": "application/json" };
            headers["Authorization"] = `Token ${this.props.token}`;

            const data_fetch = loadData(`${backendUrl}/v1/audit_trail/?p=${this.props.portfolioId}&u=${this.props.user_id}`, headers)
            data_fetch
                .then(json => {

                    this.setState(state => ({...state, auditData: json['data']}))

                })
                .catch(e => {
                    console.log(e)
                })
        }


    }

    render () {

        return (
            this.state.auditData !== undefined ? 
            <div>
                <AuditTable data={this.state.auditData} auditScope="portfolios" />
            </div>
            :
            null
            
        )
    }


}

const mapStateToProps = state => {
    return {
        token: state.auth.token,
        user_id: state.auth.user_id,
    };
};

const mapDispatchToProps = dispatch => {
    return {
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(AuditWrapper)
