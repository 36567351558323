import React from "react";
import { connect } from "react-redux";
import * as actions from "../../../store/actions/interactions";
import * as dataActions from "../../../store/actions/data";
import * as appwideActions from "../../../store/actions/appwide";
import longIcon from "../../../Media/LONG.svg";
import shortIcon from "../../../Media/SHORT.svg";
import closeIcon from "../../../Media/CLOSE.svg";
import plIcon from "../../../Media/PNL.svg";
import loadingSpinner from '../../../Media/loadingSpinner.gif'
import okIcon from '../../../Media/okIcon.svg'
import errorIcon from '../../../Media/errorIcon.svg'
import SingleSelect from "../../AppWide/SingleSelect";
import PriceDenom from "../../AppWide/InputExtraSelect";
import DateTimePicker from "react-datetime";
import axios from "axios";
import { backendUrl } from "../../../variables";
import CoinSearch from "../../AppWide/CoinSearch";
import fieldValidator from "../../../helpers/validators";
import UserList from "../../Portfolio/PortfolioComponents/Transactions/NewTransaction/UserList";
import ExchangeWallets from "../../Portfolio/PortfolioComponents/Transactions/NewTransaction/QuestionBlocks/ExchangeWallets";
import NewAccount from "../../Portfolio/PortfolioComponents/Transactions/NewTransaction/ShortForm/NewAccount";

import EntryLine from '../../Portfolio/PortfolioComponents/Transactions/NewTransaction/ShortForm/EntryLine';
import DateSelector from '../../Portfolio/PortfolioComponents/Transactions/NewTransaction/ShortForm/DateSelector';
import DestinationType from "../../Portfolio/PortfolioComponents/Transactions/NewTransaction/ShortForm/DestinationType";
import WalletQuestion from "../../Portfolio/PortfolioComponents/Transactions/NewTransaction/ShortForm/WalletQuestion";
import ExchangeSelector from "../../Portfolio/PortfolioComponents/Transactions/NewTransaction/ShortForm/ExchangeSelector";
import UserSelect from "../../Portfolio/PortfolioComponents/Transactions/NewTransaction/ShortForm/UserSelect";
import InputQuestion from "../../Portfolio/PortfolioComponents/Transactions/NewTransaction/ShortForm/InputQuestion";
import Select from "../../Portfolio/PortfolioComponents/Transactions/NewTransaction/ShortForm/CustomSelect/CustomSelect";
import CoinSelector from "../../Portfolio/PortfolioComponents/Transactions/NewTransaction/ShortForm/CoinSelector";

const typeOptions = [{label: "Liquidity In", value:"liquidity_in"}, {label: "Liquidity Out", value:"liquidity_out"}]
var moment = require("moment");
require("moment/locale/en-gb");

const formatNumber = (num) => {
  let ret = null; 
  const digits = 6
  try {
      ret = num.toLocaleString('en', {minimumFractionDigits: digits, maximumFractionDigits: digits})
  } catch(e) {}
  return ret
}


class DefiView extends React.PureComponent {
  constructor(props) {
    super(props);
    this.networkRef = React.createRef();
    this.originRef = React.createRef();
    this.priceTypeRef = React.createRef();
    this.amountRef = React.createRef();
    this.walletRef = React.createRef();
    this.userSelectRef = React.createRef();
    this.quoteCoinRef = React.createRef();
    this.quoteAmountRef = React.createRef();
    this.hashRef = React.createRef();
    this.typeSelectRef = React.createRef();
    this.lpSymbolRef = React.createRef();
    this.lpAmountRef = React.createRef();
    this.state = {
      completed: false, 
      loading: false, 
      error: false,
      origin: "wallet",
      tr_date: null,
      transactionData: {
        user_transaction: this.props.user_id,
        transaction_description: "liquidity_in",
        network: null,
      },
      quotes: {},
      newQuoteEntry: {},
      quote_validations: {
        quote_currency: true,
        amount: true,
      },
      validations: {
        date: true,
        hash: true,
        custom_base: true,
        amount: true,
        network: true,
        quotes: true,
      }
    };
  }

  handleCancel = () => {
    this.props.toggleModal({type: null, open: false, payload: {} })
  }

  validateData = () => {

    let valid = true; 
    let network_val = true;
    let amount_val = true;
    let base_val = true; 
    let quotes_val = true;
    let hash_val = true;
   
    network_val = fieldValidator(this.state.transactionData.network, ['isNotEmpty'])
    valid = valid && network_val
    this.validatorUpdate('network', network_val)
      
    amount_val = fieldValidator(this.state.transactionData.amount, ['isNotEmpty', 'isNumeric'])
    valid = valid && amount_val
    this.validatorUpdate('amount', amount_val)

    base_val = fieldValidator(this.state.transactionData.custom_base, ['isNotEmpty'])
    valid = valid && base_val
    this.validatorUpdate('custom_base', base_val)

    hash_val = fieldValidator(this.state.transactionData.hash, ['isNotEmpty'])
    valid = valid && hash_val
    this.validatorUpdate('hash', hash_val)

    quotes_val = Object.keys(this.state.quotes).length > 0
    valid = valid && quotes_val
    this.validatorUpdate('quotes', quotes_val)

    return valid
  }

  prepareData = () => {

    const success = this.validateData();

    if (!success) {
      return {data: null, success: false}
    }

    const data = {
      ...this.state.transactionData,
      quotes: this.state.quotes,
      network: this.state.transactionData.network.value,
      portfolio_id: this.props.portfolioId,
      user_id: this.props.user_id,
    }

    return {data, success: true}

  }

  submitData = () => {
    const {data, success} = this.prepareData()
    if (!success) {
      return
    }

    this.updateState('loading', true)
    let headers = { 
      "content-type": "application/json",     
    };
    headers["Authorization"] = `Token ${this.props.token}`;

    axios
  .post(`${backendUrl}/v1/transactions/new_lp`,
      data
  , { headers: headers } )
  .then(res => {
    this.updateState('completed', true)
    if (this.props.refresher !== undefined){
      this.props.refresher()
    }
    if (this.props.overridePort === true) {
        this.props.componentDataFetch('update', 1, this.props.currency_id, this.props.user_id, this.props.portfolioId, {}, {}, this.props.inBitcoin);
    } else {
        this.props.componentDataFetch('update', this.props.currentView, this.props.currency_id, this.props.user_id, this.props.portfolioId, {}, {}, this.props.inBitcoin);
    }
      this.props.getWallets(this.props.user_id);
      this.props.getUserLimits(this.props.user_id)
      //this.props.getExchanges(this.props.user_id);
      this.props.getWalletBalance(this.props.portfolioId)
      this.props.getHoldings(this.props.user_id, this.props.portfolioId)

      setTimeout(() => {
        this.props.toggleModal({type: null, open: false, payload: {} })
      }, 800)
  }).catch(e => {
    this.updateState('error', true)
  })

  }

  getRef = async (ref) => {
    return ref;
  }
  getTargetRef = (ref) => {

    const ref_needed = this.getRef(ref)
    
    ref_needed
    .then(res => {

        try {res.current.focus({preventScroll: true})} catch(e) {}
        
    })

}

getTargetInnerRef = (ref) => {

  const ref_needed = this.getRef(ref)
  
  ref_needed
  .then(res => {

      try {res.current.myInput.focus({preventScroll: true})} catch(e) {}
      
  })

}
  updateState = (label, value) => {
    this.setState(state => ({...state, [label]: value }))
  }
  updateTransaction = (label, data) => {
    this.setState(state => ({...state, transactionData: {...state.transactionData, [label]: data}}));
  }
  updateCoinTransaction = (label, data) => {
    this.updateTransaction(label, data)
  }


  updateQuoteEntry = (label, data) => {
    this.setState(state => ({...state, newQuoteEntry: {...state.newQuoteEntry, [label]: data}}));
  }

  addQuote = () => {
    let valid = true; 
    let network_val = true;
    let amount_val = true;

    network_val = fieldValidator(this.state.newQuoteEntry.quote_currency, ['isNotEmpty'])
    valid = valid && network_val
    this.quoteValidatorUpdate('quote_currency', network_val)
      
    amount_val = fieldValidator(this.state.newQuoteEntry.amount, ['isNotEmpty', 'isNumeric'])
    valid = valid && amount_val
    this.quoteValidatorUpdate('amount', amount_val)

    if (!valid) {
      return
    }

    const newData = {[this.state.newQuoteEntry.quote_currency.value]: {amount: this.state.newQuoteEntry.amount, name: this.state.selected_quote.coinName}}
    this.setState(state => ({...state, selected_quote: null, quotes: {...state.quotes, ...newData}, newQuoteEntry: {}, quote_validations: {quote_currency: true, amount: true}}))
  }

  cleanQuoteEntry = () => {
    this.setState(state => ({...state, newQuoteEntry: {}, quote_validations: {quote_currency: true, amount: true}, selected_quote: null}))
  }

  setTrTime = (data) => {
    this.setState(state => ({...state, tr_date: data}))
  }

  removeQuote = (key) => {
    const newQuote = this.state.quotes
    delete newQuote[key]
    this.setState(state => ({...state, selected_quote: null, quotes: {...newQuote}, newQuoteEntry: {}, quote_validations: {quote_currency: true, amount: true}}))
  }

  validatorUpdate = (label, validState) => {

    this.setState(state => ({
      ...state, 
      validations: {
        ...state.validations,
        [label]: validState}
      })
    )

  }

  quoteValidatorUpdate = (label, validState) => {

    this.setState(state => ({
      ...state, 
      quote_validations: {
        ...state.quote_validations,
        [label]: validState}
      })
    )

  }

  handleNumberValidation = (field, number) => {

    let amount_val = true; 
    amount_val = fieldValidator(number, ['isNotEmpty', 'isPositive'])
    this.setState(state => ({
      ...state, 
      validations: {
        ...state.validations,
        [field]: amount_val}
      })
    
      )

    return amount_val

}


  postDateSelect = () => {

  }

  focusPostDate = () => {

    const ref_needed = this.getRef(this.networkRef)
    
    ref_needed
    .then(res => {

        try {res.current.myInput.focus({preventScroll: true})} catch(e) {}
        
        })
  }
  getWalletRef = (e) => {

        
    const ref_needed = this.getRef(this.walletRef)
    
    ref_needed
    .then(res => {

        try {res.current.myInput.focus({preventScroll: true})} catch(e) {}
        
        })


  }

  getNetworkRef = () => {

    const ref_needed = this.getRef(this.networkRef)
    
    ref_needed
    .then(res => {

        try {res.current.myInput.focus({preventScroll: true})} catch(e) {}
        
        })
}

  getCoinRef = () => {

    const ref_needed = this.getRef(this.quoteCoinRef)

    ref_needed
    .then(res => {
        
       try {res.current.focus({preventScroll: true})} catch(e) {}
        
    })

  }


  render() {
    return (
      this.state.completed ? 

        <div style={{ marginTop: 140}}>

              <div style={{textAlign: 'center', marginBottom: 24}}>
                <img style={{ height: 90, width: 90}} src={okIcon} alt=""/>
              </div>
              
              <div className="basier" style={{textAlign: 'center', fontSize: 14}}>Succesfully {this.props.scope === 'new' ? 'added' : 'edited'} transaction</div>

        </div>

      :  

      this.state.error ?

      <div style={{ marginTop: 140, textAlign: 'center'}}>

                <div style={{textAlign: 'center', marginBottom: 24}}>
                  <img style={{ height: 90, width: 90}} src={errorIcon} alt=""/>
                </div>
                
                <div className="basier" style={{textAlign: 'center', fontSize: 14, marginBottom: 24}}>Error {this.props.scope === 'new' ? 'adding' : 'editing' } transaction. Please retry</div>

                {/* <div className='button-next-blue' onClick={this.backToEdit} style={{height: 40, borderRadius: 35, marginLeft: 'auto', marginRight: 'auto', cursor: 'pointer'}}>
                  Back
                </div> */}

              </div>

      :
      
      this.state.loading ? 

        <div style={{ marginTop: 140}}>

          <div style={{textAlign: 'center', marginBottom: 24}}>
            <img style={{ height: 90, width: 90}} src={loadingSpinner} alt=""/>
          </div>
          
          <div className="basier" style={{textAlign: 'center', fontSize: 14}}>{this.props.scope === 'new' ? 'Creating' : 'Editing'} transaction.</div>

        </div>

      :

      <div
        style={{
          padding: 24,
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
        }}
      >
        <EntryLine height={70}>
          <div
            style={{ flex: 1, display: "flex", justifyContent: "flex-start" }}
          >
            <DateSelector
              focusBase={this.focusPostDate}
              validatorUpdate={this.validatorUpdate}
              validation={this.state.validations}
              updateTransaction={this.updateTransaction}
              transaction={this.state.transactionData}
              setTrTime={this.setTrTime}
              tr_date={this.state.tr_date}
              nextRef={this.networkRef}
              postSelect={this.postDateSelect}
            />
          </div>

          <div style={{flex: 1, display: 'flex', justifyContent: 'flex-start'}}>
            <CoinSelector 
                autoFocus={true} 
                inputBaseRef={this.networkRef} 
                validations={this.state.validations}
                coins={this.props.all_coins} 
                label="network" 
                updateState={this.updateState} 
                updateTransaction={this.updateTransaction} 
                transaction={this.state.transactionData} 
                getNextRef={this.getWalletRef} 
                backToPrevious={() => {}} 
            />
          </div>

          <div style={{flex: 1, display: 'flex', justifyContent: 'flex-end'}}>
            <WalletQuestion
                  allCoins={true}
                  accountNames={this.props.accountNames}
                  innerRef={this.walletRef}
                  wallets={this.props.wallets} 
                  user_id={this.state.transactionData.user_transaction} 
                  transaction={this.state.transactionData}
                  updateTransaction={this.updateTransaction}
                  getNextRef={() => this.getTargetInnerRef(this.userSelectRef)} 
                  coin={this.state.network}
                  backToPrevious={this.getNetworkRef} 
            /> 
          </div>
        </EntryLine>

        <EntryLine height={70}>
          <div style={{flex: 1, display: 'flex', justifyContent: 'flex-start'}}>
          <UserSelect transaction={this.state.transactionData} portfolioId={this.props.portfolioId} updateTransaction={this.updateTransaction} scope='source' innerRef={this.userSelectRef} getNextRef={ () => this.getTargetRef(this.typeSelectRef) } backToPrevious={() => this.getTargetInnerRef(this.walletRef)} forceLabel="User" user_id={this.state.transactionData.user_transaction} label="user_transaction" />
          </div>

          <div style={{flex: 1, display: 'flex', justifyContent: 'flex-start'}}>

          <Select 
              options={typeOptions}
              selected={typeOptions.filter(v => v.value === this.state.transactionData.transaction_description)[0]}
              title="Type"
              label="transaction_description"
              postSelect={() => {}}
              updateState={this.updateTransaction}
              innerRef={this.typeSelectRef}
          />

          </div>
          
          <div style={{flex: 1, display: 'flex', justifyContent: 'flex-end'}}>
            <InputQuestion
              validatorMethod={(n) => this.handleNumberValidation('custom_base', n)}
              innerRef={this.lpSymbolRef} 
              updateState={this.updateState} 
              label="custom_base" 
              validation={this.state.validations} 
              updateTransaction={this.updateTransaction} 
              transaction={this.state.transactionData} 
              getNextRef={() => this.getTargetRef(this.lpAmountRef)} 
              getPreviousRef={() => this.getTargetRef(this.typeSelectRef)} 
              validateNumber={false} 
              handlePostSelect={this.handleDisplayPrice} 
              labelName="LP Token Symbol" 
              subText="" errorText="Cannot be empty." />
          </div>
          

        </EntryLine>

        <EntryLine height={70}>

          <div style={{flex: 1, display: 'flex', justifyContent: 'flex-start'}}>

          <InputQuestion
            validatorMethod={(n) => this.handleNumberValidation('amount', n)}
            innerRef={this.lpAmountRef} 
            updateState={this.updateState} 
            label="amount" 
            validation={this.state.validations} 
            updateTransaction={this.updateTransaction} 
            transaction={this.state.transactionData} 
            getNextRef={() => this.getTargetRef(this.hashRef)} 
            getPreviousRef={() => this.getTargetRef(this.lpSymbolRef)} 
            validateNumber={false} 
            handlePostSelect={() => {}} 
            labelName="LP Token Amount" 
            subText="" errorText="Cannot be empty." />


          </div>

          <div style={{flex: 2, display: 'flex', justifyContent: 'flex-start'}}>

          <InputQuestion
            fullWidth={true}
            validatorMethod={(n) => {}}
            innerRef={this.hashRef} 
            updateState={this.updateState} 
            label="hash" 
            validation={this.state.validations} 
            updateTransaction={this.updateTransaction} 
            transaction={this.state.transactionData} 
            getNextRef={() => this.getTargetInnerRef(this.quoteCoinRef)} 
            getPreviousRef={() => this.getTargetRef(this.lpAmountRef)} 
            validateNumber={false} 
            handlePostSelect={() => {}} 
            labelName="LP Token Contract Address" 
            subText="" errorText="Cannot be empty." />

          </div>

        </EntryLine>

        <div style={{width: '100%', backgroundColor: '#F8F9FA', padding: 12, marginBottom: 12}}>
          <div className="basier-p4-caps" style={{fontWeight: 'bold', marginBottom: 6}}>Enter Liquidity Input</div>
          <EntryLine height={70}>
            <div style={{flex: 1, display: 'flex', justifyContent: 'flex-start'}}>
            <CoinSelector 
                autoFocus={false} 
                inputBaseRef={this.quoteCoinRef} 
                validations={this.state.quote_validations}
                coins={this.props.all_coins} 
                label="quote_currency" 
                updateState={this.updateState} 
                updateTransaction={this.updateQuoteEntry} 
                transaction={this.state.newQuoteEntry} 
                getNextRef={() => this.getTargetRef(this.quoteAmountRef)} 
                backToPrevious={this.handlePostTypePrice} 
            />
            </div>

            <div style={{flex: 1, display: 'flex', justifyContent: 'flex-start'}}>

              <InputQuestion
              validatorMethod={(n) => this.handleNumberValidation('amount', n)}
              innerRef={this.quoteAmountRef} 
              updateState={this.updateState} 
              label="amount" 
              validation={this.state.quote_validations} 
              updateTransaction={this.updateQuoteEntry} 
              transaction={this.state.newQuoteEntry} 
              getNextRef={() => {}} 
              getPreviousRef={() => this.getTargetInnerRef(this.quoteCoinRef)} 
              validateNumber={false} 
              handlePostSelect={() => {}} 
              labelName="LP Token Amount" 
              subText="" errorText="Cannot be empty." />

            </div>

            <div style={{height: '100%', flex: 1, display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>

              <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%'}}>
                <div onClick={this.addQuote} className="basier-p4-caps opacity-hover" style={{height: 42, cursor: 'pointer', flex: 1, marginLeft: 12, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#0078DBD9', padding: 4, borderRadius: 10, color: 'white', fontWeight: 'bold'}}>Add quote</div>
                <div onClick={this.cleanQuoteEntry} className="basier-p4-caps opacity-hover" style={{height: 42, cursor: 'pointer', flex: 1, marginLeft: 12, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#F9716BD9', padding: 4, borderRadius: 10, color: 'white', fontWeight: 'bold'}}>Clear</div>
              </div>

            </div>

          </EntryLine>

          <div className="basier-p4-caps" style={{fontWeight: 'bold', marginBottom: 6}}>Liquidity inputs</div>
          <div style={{height: 80, width: '100%', overflow: 'scroll'}}>
            {Object.values(this.state.quotes).length === 0 ? 
            
            <div style={{color: !this.state.validations.quotes ? 'red' : undefined, fontWeight: !this.state.validations.quotes ? 'bold' : 'normal'}}> 
              No quotes added. Please add at least one quote for a liquidity pool transaction
            </div> 
            : 
            <ul>
            
            {Object.keys(this.state.quotes).map((k) => {
              const v = this.state.quotes[k];
              return (
                <li key={k}>
                  <span>
                 {this.state.transactionData.transaction_description === "liquidity_in" ? 'Added' : 'Removed'} {v.amount} {v.name} {this.state.transactionData.transaction_description === "liquidity_in" ? 'to' : 'from'} the pool
                 </span>
                 <span> - </span>
                 <span onClick={() => this.removeQuote(k)} className="opacity-hover" style={{color: "red", cursor: 'pointer'}}>
                   Remove
                 </span>
                </li>
              )
            })}
            </ul>

          }
          </div>
        </div>

        <div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>

            <div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            
              <div className={`basier-p4-caps opacity-hover ${this.state.active === true  && this.state.ix === 0 ? 'grow-shaddow' : ''}`} onClick={this.submitData} style={{height: 35, borderRadius: 35, marginLeft: 3, marginRight: 3, marginTop: 0, cursor: 'pointer', color: 'white', backgroundColor: '#0078DB', width: 80, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                Submit
              </div>
              <div className={`basier-p4-caps opacity-hover ${this.state.active === true  && this.state.ix === 2 ? 'grow-shaddow' : '' }`} onClick={() => this.handleCancel()} style={{height: 35, borderRadius: 35, marginLeft: 3, marginRight: 0, marginTop: 0, cursor: 'pointer', color: 'white', backgroundColor: '#F9716B', width: 80, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                Cancel
              </div>

            </div>

         </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user_id: state.auth.user_id,
    contracts: state.appwide.contracts,
    token: state.auth.token,
    portfolioId: state.data.menu_selected,
    currentView: state.data.currentView,
    currency: state.auth.currency.id,
    graphData: state.data.globalBalance,
    inBitcoin: state.data.bitcoinPf,
    all_coins: state.appwide.all_coins,
    wallets: state.appwide.wallets,
    accountNames: state.appwide.accountNames,
    exchanges: state.appwide.exchanges,
    currency_id: state.auth.currency.id, 
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleModal: (data) => dispatch(actions.toggleModal(data)),
    getUserLimits: (user) => dispatch(appwideActions.getUserLimits(user)), 
    getWallets: (user) => dispatch(appwideActions.getWallets(user)), 
    getWalletBalance: (user) => dispatch(dataActions.getWalletBalance(user)), 
    getHoldings: (user, portfolio) => dispatch(dataActions.getHoldings(user, portfolio)),
    getExchanges: (user) => dispatch(appwideActions.getExchanges(user)),  
    componentDataFetch: (
      scope,
      view,
      currency,
      user,
      portfolio,
      globalGraphData,
      portfolioGraphData,
      inBitcoin,
      tokenProvided
    ) =>
      dispatch(
        dataActions.componentDataFetch(
          scope,
          view,
          currency,
          user,
          portfolio,
          globalGraphData,
          portfolioGraphData,
          inBitcoin,
          tokenProvided
        )
      ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DefiView);
