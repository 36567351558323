import React from 'react';
import Logo from '../../../Media/Logo.svg'
import { connect } from 'react-redux';

class Footer extends React.PureComponent {

    state = {
        bk: 'white'
    }

    render () {

        return (

            <div style={{paddingTop: 24, width: '100%', display: 'flex', flexDirection: 'column', paddingBottom:48, backgroundColor: `${this.props.currentView === 1 || this.props.currentView === 99 ? '#F8F9FA' : 'white'}`}}>

                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <div style={{width: '40%'}} className="gray-divider"></div>
                    <div style={{marginLeft: 36, marginRight: 36}}><img src={Logo} alt="logo" /></div>    
                    <div style={{width: '40%'}}  className="gray-divider"></div>
                </div>

                <div className="basier-p4" style={{width: '100%', textAlign: 'center', marginTop: 24}}>
                    2019-{ new Date().getFullYear() }, All rights reserved © Wisly
                </div>

            </div>
            
        )
    }


}

const mapStateToProps = state => {
    return {
        currentView: state.data.currentView
    };
};

export default connect(
    mapStateToProps,
    null
)(Footer);


