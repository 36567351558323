import React from 'react';
import Select, { components }  from 'react-select';


const ValueContainer = ({ children, ...props }) => {
  return (
      <components.ValueContainer {...props}>
        <div className="basier-p3">
          {children}
        </div>
      </components.ValueContainer>
  )
};



class MenuSelector extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      selectedOption: null
    }
  }
  
  colourStyles = {
    control: styles => ({ ...styles, backgroundColor: 'transparent', width: '250px', height: this.props.height !== undefined ? this.props.height : 42,  fontSize: 16, border: '0px', color: 'black', cursor: 'text', dispay:'inline-block'}),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
  
      return {
        ...styles,
        backgroundColor: isDisabled
          ? null
          : isSelected ? 'white' : isFocused ? '#F3F5F6' : null,
        color: isDisabled
          ? '#F3F5F6'
          : isSelected ? 'black' : 'black',
        opacity: isDisabled
          ? '1'
          : isSelected ? '1' : '0.4',
      };
    },
  
  }

  componentDidMount() {
    this.setState(state => ({...state, selectedOption: {value: this.props.selection.value, label: this.props.selection.label} })) 
  }

  componentDidUpdate(prevProps) {

    if (prevProps.selection !== this.props.selection) {
      this.setState(state => ({...state, selectedOption: {value: this.props.selection.value, label: this.props.selection.label} })) 
    }

  }


  handleChange = (selectedOption) => {

    if (selectedOption.value !== this.state.selectedOption.value) {

      this.setState({ selectedOption });
      this.props.changeOrigin(selectedOption.value)


    }
    
  }

  render() {
    const { selectedOption } = this.state;

    return (
      <Select
        value={selectedOption}
        onChange={this.handleChange}
        options={this.props.options}
        isSearchable={true}
        styles={this.colourStyles}
        maxMenuHeight={210}
        minMenuHeight={50}
        ref={this.props.innerRef}
        components={{ ValueContainer }}
        classNamePrefix="react-select-user"
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
          ...theme.colors,
            primary25: '#F3F5F6',
            primary: '#F3F5F6',
          },
        })}
      />
    );
  }
}

export default MenuSelector