import React from "react";
import { Route, Switch } from "react-router-dom";
import Hoc from "./hoc/hoc";

import Login from "./containers/Login/Login";
import Register from "./containers/Login/Register";
import Start from "./containers/Login/Start";
import Reset from "./containers/Login/Reset";

const BaseRouter = () => (
  <Hoc>
    <Switch>
    <Route exact path="/reset/:uid/:token/" component={Reset} />
    <Route exact path="/reset/" component={Reset} />
    <Route exact path="/" component={Login} />
    <Route exact path="/register" component={Register} />
    <Route exact path="/login/" component={Login} />
    <Route exact path="/start/" component={Start} />
    <Route path="*" component={Login} />
    </Switch>
  </Hoc>
);

export default BaseRouter;