import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
 
const styles = theme => ({
    root: {
        height: 10,
        width: '100%',
        borderRadius: 5,
        backgroundColor: 'rgba(0, 120, 219, 0.1)',
        },

    bar: {
     borderRadius: 0,
     backgroundColor: 'rgba(0, 120, 219, 1)',
    },
   
    })


const CustomizedProgressBars = (props) => {


const { classes } = props;
const value = props.limit !== -1 ? (props.value / props.limit) * 100 : 100

  return (
    <div>
     
      <LinearProgress
        classes={{
            root: classes.root,
            bar: classes.bar,
        }}
        variant="determinate"
        color="primary"
        value={value}
      />
     
    </div>
  );
}

export default withStyles(styles)(CustomizedProgressBars);