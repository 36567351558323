import React from 'react';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import * as actions from "../../store/actions/data";
import loadingSpinner from '../../Media/loadingSpinner.gif'
import {createLoadingSelector} from '../../store/reducers/selectors'
/* import HighchartsReact from 'highcharts-react-official'*/
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
/* import HighchartsReact from 'highcharts-react-official'*/
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';


const styles = {
    root: {
      color: '#6A7E93',
      '&$checked': {
        color: '#0078DB',
      },
    },
    checked: {
        color: '#0078DB'
    },
  };

const BalanceGraph = (props) => {

    let data_render = props.history_data
    let received_date = new Date(props.start_date)
    let data_date = Date.UTC(received_date.getFullYear(), received_date.getMonth(), received_date.getDate() )

    
    const symbol_to_use = props.currencyScope=== 'bitcoin' ? "₿" : props.currency_symbol
    const { classes } = props;


    const arrowDownSvg = 
        `<svg width="24" height="24" viewBox="12 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d)">
        <rect x="10" y="0.00415039" width="24" height="24" rx="5" fill="white"/>
        </g>
        <path d="M26.2664 13.0848L21.7471 17.6041L17.1997 13.0848" stroke="#F9716B" stroke-width="2" stroke-miterlimit="10"/>
        <path d="M21.7473 17.6042V6.40417" stroke="#F9716B" stroke-width="2" stroke-miterlimit="10"/>
        <defs>
        <filter id="filter0_d" x="0" y="0.00415039" width="44" height="44" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
        <feOffset dy="10"/>
        <feGaussianBlur stdDeviation="5"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0.882353 0 0 0 0 0.898039 0 0 0 0 0.913725 0 0 0 0.3 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
        </filter>
        </defs>
        </svg>`

    const arrowUp = 
    `<svg width="24" height="24" viewBox="12 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d)">
    <rect x="10" width="24" height="24" rx="5" fill="white"/>
    </g>
    <path d="M18 10.3684L22.4861 5.88234L27 10.3684" stroke="#4CDAC1" stroke-width="2" stroke-miterlimit="10"/>
    <path d="M22.4854 5.88235L22.4854 17" stroke="#4CDAC1" stroke-width="2" stroke-miterlimit="10"/>
    <defs>
    <filter id="filter0_d" x="0" y="0" width="44" height="44" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
    <feOffset dy="10"/>
    <feGaussianBlur stdDeviation="5"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0.882353 0 0 0 0 0.898039 0 0 0 0 0.913725 0 0 0 0.3 0"/>
    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
    </filter>
    </defs>
    </svg>`
    
    const options = {
        chart: {
            type: 'area', 
            backgroundColor: 'white',  
            animation: true,
            height: 200,
        },
        credits: false,
        title: false,
        xAxis: {
            className: 'basier-p4-caps',    
            minorGridLineWidth: 0,
            gridLineWidth: 0,
            type: 'datetime',
            minPadding:0,
            maxPadding:0,
            tickLength: 0,
            tickColor: 'red',
            labels: {
                padding: 30,
                style: {
                    fontSize: '12px',
                },
            }

        },
        yAxis: {
            className: 'basier-p4 graph-labels-x',
            title: {
                text: false,
            },
            align: 'right',
            minorGridLineWidth: 1,
            gridLineWidth: 1,
            gridLineColor: '#E1E5E9',
            opposite: true,
            labels: {
                style: {
                    fontSize: '12px',
                    textAlign: 'right',
                    position: 'relative',
                    display: 'inline-block'
                },
                reserveSpace: false,
            }
            
        },
        series: [{
            name: 'Portfolio value in USD',
            data: data_render,
            pointStart: data_date,
            pointInterval: 24 * 3600 * 1000,
            color: '#0078DB',
            lineWidth: 1.5,
            marker: {
                fillColor: "#fff", 
                lineColor: "#0078DB", 
                lineWidth: 2,
            }
        }],
        legend: {
            enabled: false
        },
        navigator: {
            enabled: false
        },
        scrollbar: {
            enabled: false
        },
        
        plotOptions: {
            series: {
                animation: false, 
                marker: {
                    enabled: false
                },
            },
            area: {
                fillColor: {
                    linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1},
                    stops: [
                        [0, "rgba(0, 120, 219, 0.22)"],
                        [1, "rgba(0, 120, 219, 0)"]
                    ]
                },
            },
        },
        responsive: {
            rules: [{
                condition: {
                    minWidth: 400
                },
                chartOptions: {
                    chart: {
                        height: 200
                    },
                    subtitle: {
                        text: null
                    },
                    navigator: {
                        enabled: false
                    }
                }
            }]
        },
        tooltip: {
            backgroundColor: 'white',
            borderWidth: 0,
            borderRadius: 14,
            padding:6,
            split: false,
            shadow: true,
            style: {
                boxShadow: '0px 10px 10px rgba(225, 229, 233, 0.3)',
            },
            useHTML: true,
            outside: true,
        
            formatter: function () {
                const changePrevious = this.y / this.series.yData[this.point.index - 1];
                let changeVue = null;
    
                if (changePrevious < 1) {
                    changeVue = arrowDownSvg
                } else if (changePrevious > 1) {
                    changeVue = arrowUp
                } else { 
                    changeVue = arrowUp
                }

                const digits = this.y > 1 ? 2 : 6;
    
                return `<div style="z-index:900000"><div class="small-tooltip"><span class="small-direction-tooltip">${changeVue} </span><span class="basier-p2">${symbol_to_use} ${parseFloat(this.y).toLocaleString('en', {minimumFractionDigits:digits, maximumFractionDigits: digits})} </span></div><div class="basier-p4-caps">${Highcharts.dateFormat('%e/%b/%Y',new Date(this.x))} 23:59 UTC</div></div>`
                    }
            }
            
    }   

        return (

            <div>

                <HighchartsReact
                    highcharts={Highcharts}
                    options={options}
                    oneToOne={true}
                />

            </div>
                        
        )


        
};


BalanceGraph.propTypes = {
    classes: PropTypes.object.isRequired,
  };
  

const mapStateToProps = state => {
    return {
        currency_symbol: state.auth.currency.symbol,
        currency_name: state.auth.currency.id,

    };
};

export default connect(
    mapStateToProps,
    null
)( withStyles(styles)(BalanceGraph));

