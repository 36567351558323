import React from 'react';
import { connect } from 'react-redux';
import Large from './Large/CollaboratorOverview'
import Small from './Small/CollaboratorOverview'
import Hidden from '@material-ui/core/Hidden';

class CollaboratorWrapper extends React.PureComponent {

    render () {

        return (

            <React.Fragment>
                
                <Hidden smDown>
                    <Large portfolioId={this.props.portfolioId} portfoliosData={this.props.portfoliosData} />
                </Hidden>

                <Hidden mdUp>
                    <Small portfolioId={this.props.portfolioId} portfoliosData={this.props.portfoliosData} />
                </Hidden>


            </React.Fragment>
            
        )
    }


}

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = dispatch => {
    return {
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(CollaboratorWrapper)
