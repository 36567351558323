import React from 'react';
import { connect } from 'react-redux';

class AssetHeaders extends React.PureComponent {

    render () {

        return (
            this.props.scope === "spot" ? 
            <div
                style={{display: 'flex', alignItems: 'baseline', paddingLeft: 12, paddingRight: 12, color: '#6A7E93', width: '100%'}}
            > 
        
                <div className="basier-p4-caps"  style={{width: '28%', textTransform: 'uppercase'}}>Asset</div> 
                <div className="basier-p4-caps"  style={{width: '13%', textAlign: 'left', textTransform: 'uppercase'}}>Proportion</div> 
                <div  style={{width: '16%', textAlign: 'right', textTransform: 'uppercase'}}>
                    <div className="basier-p4-caps">
                    Balance
                    </div>
                    
                </div> 
                <div  style={{width: '16%', textAlign: 'right'}}>

                    <div className="basier-p4-caps" style={{textTransform: 'uppercase'}}>
                    Value
                    </div>
                    <div className="basier-p4-caps" style={{fontStyle: 'italic'}}>
                    
                    </div>

                </div> 
                <div  style={{width: '16%', textAlign: 'right' }}>
                
                    <div className="basier-p4-caps" style={{textTransform: 'uppercase'}}>
                        Price
                    </div>
                    
                </div> 
                <div  style={{width: '10%', textAlign: 'right', paddingRight: 12}}>
                
                    <div className="basier-p4-caps">
                        24h Change
                    </div>
                
                </div> 

            </div>   
            :
            <div
            style={{display: 'flex', alignItems: 'baseline', paddingLeft: 12, paddingRight: 12, color: '#6A7E93', width: '100%'}}
        > 
    
            <div style={{width: '19%' }}>
                <div className="basier-p4-caps"  style={{textTransform: 'uppercase'}}>
                    Contract
                </div>
            </div> 



            <div style={{width: '12%', textAlign: 'left'}}>
                <div className="basier-p4-caps"  style={{textTransform: 'uppercase'}}>
                    Type
                </div>
            </div> 




            <div style={{width: '19%', textAlign: 'right'}}>
                <div className="basier-p4-caps" style={{textTransform: 'uppercase'}}>
                    Size (Base)
                </div>

                <div className="basier-p4-caps">
                    Size (Quote)
                </div>
            </div> 



            <div  style={{width: '21%', textAlign: 'right'}}>
                
                <div className="basier-p4-caps" style={{textTransform: 'uppercase'}}>
                    Last Price
                </div>

                <div className="basier-p4-caps">
                    Open Price
                </div>

            </div> 



            <div  style={{width: '29%', textAlign: 'right'}}>

                <div className="basier-p4-caps" style={{textTransform: 'uppercase'}}>
                    P&L (Quote)
                </div>
                <div className="basier-p4-caps">
                    Inerse P&L (base)
                </div>

            </div> 

        </div>
        )
    }


}

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = dispatch => {
    return {
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(AssetHeaders)
