import React from 'react';
import * as actions from "../../../../../../store/actions/interactions";
import { connect } from 'react-redux';



class ExchangeQuestion extends React.PureComponent {


    state = {
        
        wallet_name: null, 
        wallet_address: null, 
    
    }

    componentDidMount() {

        if (this.props.exchange !== null && this.props.coin_ticker !== null && this.props.user_first !== null && this.props.user_last !== null
            &&
            this.props.exchange !== undefined && this.props.coin_ticker !== undefined && this.props.user_first !== undefined && this.props.user_last !== undefined
            ) {
                const wallet_name = `${this.props.exchange.name} - ${this.props.coin_ticker}`
                
                if (this.props.walletTransaction[this.props.coin_id] === undefined) {

                    this.props.updateWallets({coin: this.props.coin_id, label: "name", value: wallet_name })
                    this.props.updateWallets({coin: this.props.coin_id, label: "address", value: null })
                    this.props.updateWallets({coin: this.props.coin_id, label: "coin", value: this.props.coin_id })
                    this.props.updateWallets({coin: this.props.coin_id, label: "user", value: this.props.transaction['data'][this.props.transactionId]['user_transaction'] })
                    
                    }
            
            }


    }

    updateInfo = (type, v) => {

        this.props.updateWallets({coin: this.props.coin_id, label: type, value: v })

    }

    render() {

        return (

            this.props.noAdd ?
            null
            :
            <div>
                
                {this.props.exchange !== null ? 

                    !this.props.simple ? 

                        <div className="basier-p4" style={{fontStyle: 'italic', color: 'black', marginBottom: 6}}>
                            You do not have a registered {this.props.exchange.name} - {this.props.coin} wallet with us yet. No worries, we will create one for you! If you want to customize your wallet now you can do it below, otherwise you can leave the fields blank and do it later.
                        </div>
                    
                    : 
                    
                    null 

            
                : 
                    null
                }

                <div style={{

                            display: 'flex', 
                            flexDirection: 'column',
                            height: `${this.props.simple ? '80px' : '150px'}`,//height: `${this.props.transaction['data'][this.props.transactionId]['quote_currency'] !== null && this.props.transaction['data'][this.props.transactionId]['quote_currency']  !== undefined ? '60px' : '300px'}`, 
                            alignItems: 'baseline',
                            transition: 'height 0.3s',
                            width: '100%', 
                            border: '1px solid rgba(204, 237, 254, 0.7)', 
                            backgroundColor: 'rgba(204, 237, 254, 0.1)', 
                            borderRadius: 25,
                            padding: `${this.props.simple ? '12px 24px' : '24px'}`
                            }}>

                        { 

                        this.props.walletTransaction[this.props.coin_id] !== undefined ?  

                        <div>
                            <div style={{color: 'black', fontWeight: 700}} className="basier-p3">New {this.props.exchange.name} - {this.props.coin} Wallet details</div>
                            
                            <div style={{display: 'flex', marginTop: `${this.props.simple ? '6px' : '24px'}` }}>

                            <div style={{marginTop: 12, marginBottom: 6, width: 42, zIndex: 7000, marginRight: 8, fontWeight: 'bold', fontSize: 12}}>Name : </div>
                            <input style={{width: 140, backgroundColor: 'transparent', fontSize: 12,  marginRight: 24, height: 18, marginTop: 13}} onChange={(e) => this.updateInfo('name', e.target.value) }  className="transaction-coin-search-edit" onKeyDown={(e) => console.log(e)} value={this.props.walletTransaction[this.props.coin_id]['name']} />
                            <div style={{marginTop: 12, marginBottom: 6, width: 125, zIndex: 7000, marginRight: 12, fontWeight: 'bold', fontSize: 12}}>Blockchain address : </div>
                            <input style={{width: 370, backgroundColor: 'transparent',  fontSize: 12, height: 18, marginTop: 13}} onChange={(e) => this.updateInfo('address', e.target.value) }  className="transaction-coin-search-edit" placeholder="optional (and only if it isn't a floating address)" onKeyDown={(e) => console.log(e)} value={this.props.walletTransaction[this.props.coin_id]['address']} />

                            </div>
                        </div>

                        :

                        null 

                        }
                       
                </div>

            </div>
            



        )
    }

}

const mapStateToProps = state => {
    return {
        transaction: state.interactions.transactionAddData,
        walletTransaction: state.interactions.walletTransaction,
    };
  };
  
  const mapDispatchToProps = dispatch => {
    return {
        updateWallets: (data) =>
            dispatch(actions.updateWallets(data)), 
    };
  };
  
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(ExchangeQuestion);