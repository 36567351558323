import React from "react";
import PropTypes from "prop-types";
import Dialog from "@material-ui/core/Dialog";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import * as actions from "../../../../../store/actions/interactions";
import { connect } from "react-redux";
import TransactionModalSingle from "./SignleAddContent";
import ShortForm from "./ShortForm/ShortTransactionEntry";
import UploadView from "../../../../Modals/ModalViews/UploadView";
import PositionEntry from "../../../../Modals/ModalViews/AddPosition";
import "./TransactionAddStyle.css";
import wizardIcon from "../../../../../Media/bitcoin.svg";
import liquidityIcon from "../../../../../Media/liquidity.svg";
import DefiIcon from "../../../../../Media/defi.svg";
import nftIcon from "../../../../../Media/nft.svg";
import quickIcon from "../../../../../Media/quickadd-blue.svg";
import uploadIcon from "../../../../../Media/upload-blue.svg";
import positionIcon from "../../../../../Media/positionIcon.svg";
import BaseModalTop from "../BaseModalTop";
import Oops from "../../../../../Media/oops.svg";
import { withRouter } from "react-router-dom";
//import ConnectAccount from '../../../../Modals/ModalViews/ModalComps/ConnectAccounts'
import ConnectAccount from "../../../../Modals/ModalViews/ConnectAccount";
import AccountCreationOverview from "../../../../AccountCreation/AccountCreationOverview";
import DefiView from "../../../../Modals/ModalViews/DefiView";

const getValue = (dictVal, key) => {
  try {
    return dictVal[key];
  } catch (e) {
    return undefined;
  }
};

const OptionButton = ({ selectOption, title, subtitle, icon, selector, buttonText, inactive }) => {
  return (
    <div>
    <div
      onClick={inactive ? null : () => selectOption(selector)}
      className="multiple-tr"
      style={{
        opacity: inactive ? 0.4 : 1,
        width: 200,
        borderRadius: 20,
        border: '1px solid #F8F9FA',
        boxShadow: "0px 10px 10px rgba(225, 229, 233, 0.6)",
        marginRight: 24,
        marginLeft: 24,
        padding: '24px 12px',
        display: "flex",
        alignItems: "flex-start",
        flexDirection: 'column',
        justifyContent: "flex-start",
      }}
    >
      <div style={{ width: "100%", display: "flex", alignItems: "flex-start" }}>
        <img
          src={icon}
          style={{ height: 24, width: 24, textAlign: "center", marginRight: 12 }}
          alt=""
        />
        <div>
          <div
            className="basier"
            style={{
              fontSize: 16,
              textTransform: "uppercase",
              color: "black",
            }}
          >
            {title}
          </div>

          <div
            className="basier-p4"
            style={{
              width: "100%",
              textAlign: "left",
            }}
          >
          </div>
        </div>
      </div>
      <div style={{marginTop: 12, fontStyle: 'italic', fontSize: 14, paddingLeft: 36}}>
        {subtitle}
      </div>
      {/* <div style={{alignSelf: 'center'}}>
        <div style={{
          width: 160,
          height: 40,
          padding: "12px 6px",
          border: '1px solid #0078DBCC',
          display: 'flex', 
          alignItems: 'center', 
          justifyContent: 'center',
          borderRadius: 24,
          color: "#0078DBCC"
        }}>Select</div>
      </div> */}
    </div>
    {inactive && 
      <div className="basier-p4" style={{width: '100%', display: 'flex', justifyContent: 'center', textAlign: 'center', color: '#e0a03d', marginTop: -20}}>
        Coming soon
      </div>
    }
    </div>
  );
};
class ResponsiveDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      coin_in_scope: null,
      graph_data: [],
      graph_start: null,
      welcome: this.props.overrideType ? false : true,
      type_selected: this.props.overrideType,
    };
  }

  handleClose = () => {
    this.setState((state) => ({
      ...state,
      welcome: true,
      type_selected: null,
    }));
    this.props.toggleModal({ type: null, open: false, payload: null });
  };

  componentDidMount() {
    if (this.props.overrideType) {
      this.setState((state) => ({
        ...state,
        type_selected: this.props.overrideType,
        welcome: false,
      }));
    }
  }

  backToWelcome = () => {
    this.setState((state) => ({
      ...state,
      welcome: true,
      type_selected: null,
    }));
  };

  rendererSelector = (type) => {
    if (type === "wizard") {
      return (
        <ShortForm
          scope="new"
          handleClose={this.handleClose}
          overridePort={this.props.overridePort}
          overridePortId={this.props.overridePortId}
          refresher={this.props.refresher}
        />
      );
    } else if (type === "quick") {
      let port = this.props.currentPortfolio;
      if (this.props.overridePort) {
        port = this.props.overridePortId;
      }
      return (
        <AccountCreationOverview
          origin="transaction"
          importTo={port}
          goToEntry={this.goToEntry}
        />
      );
      //return <ConnectAccount overridePort={this.props.overridePort} overridePortId={this.props.overridePortId} handleClose={this.handleClose}/>
    } else if (type === "upload") {
      return (
        <UploadView
          overridePort={this.props.overridePort}
          overridePortId={this.props.overridePortId}
        />
      );
    } else if (type === "position") {
      return <PositionEntry existingData={null} />;
    } else if (type === "defi") {
      return <DefiView existingData={null} scope="new" refresher={this.props.refresher} />;
    }
     else {
      return null;
    }
  };

  goToEntry = () => {
    this.setState((state) => ({
      ...state,
      type_selected: "wizard",
      welcome: false,
    }));
  };

  goToAccountSettings = () => {
    this.props.history.push("/settings");
    this.handleClose();
  };

  selectOption = (type) => {
    this.setState((state) => ({
      ...state,
      type_selected: type,
      welcome: false,
    }))
  }

  render() {
    const currentLimits = this.props.limits;
    const txLimit = currentLimits.transactions;
    const investLimit = currentLimits.investment;
    return this.props.roles[this.props.currentPortfolio] === "admin" ||
      this.props.roles[this.props.currentPortfolio] === "manager" ||
      this.props.roles[this.props.overridePortId] === "admin" ||
      this.props.roles[this.props.overridePortId] === "manager" ? (
      <div>
        <BaseModalTop
          handleClose={this.handleClose}
          onClick={this.backToWelcome}
          welcome={this.state.welcome}
          entryType={this.state.type_selected}
        />

        {this.props.portfoliosData[this.props.currentPortfolio] !== undefined &&
        this.props.portfoliosData[this.props.currentPortfolio]["limited"] ===
          true &&
        this.props.trial === false ? (
          <div
            style={{
              paddingLeft: 48,
              paddingRight: 48,
              height: 520,
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              flexDirection: "column",
              textAlign: "center",
            }}
          >
            <img src={Oops} alt="" style={{ height: 100, marginTop: 120 }} />
            <h3 style={{ marginTop: 12, fontWeight: 900, color: "#0078DB" }}>
              Cannot add transaction
            </h3>
            <div
              style={{ marginTop: 24, fontWeight: 400 }}
              className="basier-p3"
            >
              This portfolio is currently in Limited Mode as its owner does not
              have an adequate Subscription. If you are a portfolio Admin, you
              can change the owner to another user who has an adequate
              Subscription, or contact the owner so that they reactivate their
              subscription.
            </div>
          </div>
        ) : currentLimits !== undefined && currentLimits !== null ? (
          (this.props.trial === false &&
            currentLimits.transaction_limit != -1 &&
            txLimit > currentLimits.transaction_limit) ||
          (currentLimits.invest_limit != -1 &&
            investLimit > currentLimits.invest_limit &&
            getValue(
              Object.values(this.props.transaction.data)[0],
              "amount"
            ) === undefined) ? (
            <div
              style={{
                paddingLeft: 48,
                paddingRight: 48,
                height: 520,
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                flexDirection: "column",
                textAlign: "center",
              }}
            >
              <img src={Oops} alt="" style={{ height: 100, marginTop: 120 }} />
              <h3 style={{ marginTop: 12, fontWeight: 900, color: "#0078DB" }}>
                Transaction Limit Reached
              </h3>
              <div
                style={{ marginTop: 24, fontWeight: 400 }}
                className="basier-p3"
              >
                It looks like you have reached the limit of number of
                transactions permitted with your current plan. If you wish to
                add more transactions, you can upgrade your plan by going to
                your{" "}
                <span
                  onClick={this.goToAccountSettings}
                  className="opacity-hover"
                  style={{ cursor: "pointer", color: "#0078DB" }}
                >
                  Account Settings
                </span>
              </div>
            </div>
          ) : this.state.welcome ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 48,
                  width: 750,
                }}
              >
                <OptionButton 
                  selectOption={this.selectOption} 
                  icon={wizardIcon} 
                  subtitle="Spot movements in crypto and fiat." 
                  title="Spot Crypto" 
                  selector="wizard" 
                />

                <OptionButton 
                  selectOption={this.selectOption} 
                  icon={positionIcon} 
                  subtitle="Position taken in a derivatives contract." 
                  title="Derivatives" 
                  selector="position" 
                />

                <OptionButton 
                  selectOption={this.selectOption} 
                  icon={uploadIcon} 
                  subtitle="Upload multiple transactions at once." 
                  title="CSV Import" 
                  selector="upload" 
                />

              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 48,
                  width: 750,
                }}
              >

                <OptionButton 
                  selectOption={this.selectOption} 
                  icon={liquidityIcon} 
                  subtitle="Liquidity In / Out transactions." 
                  title="Liquidity Pool" 
                  selector="defi" 
                />

                <OptionButton 
                  selectOption={this.selectOption} 
                  icon={DefiIcon} 
                  subtitle="Other DeFi transactions." 
                  title="DeFi" 
                  selector="other_defi"
                  inactive={true} 
                />

                <OptionButton 
                  selectOption={this.selectOption} 
                  icon={nftIcon} 
                  subtitle="NFT minting, buying and selling." 
                  title="NFTs" 
                  selector="nft" 
                  inactive={true} 
                />

              </div>

              <div>
                <div>
                  <div
                    //onClick={() => this.setState(state => ({...state, type_selected: 'quick', welcome: false}))}
                    className="multiple-tr"
                    onClick={() =>
                      this.setState((state) => ({
                        ...state,
                        type_selected: "quick",
                        welcome: false,
                      }))
                    }
                    style={{
                      width: 702,
                      height: 110,
                      borderRadius: 20,
                      boxShadow: "0px 10px 10px rgba(225, 229, 233, 0.6)",
                      marginTop: 24,
                      padding: 12,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                    }}
                  >
                    <div style={{ textAlign: "center", marginRight: 12 }}>
                      <img
                        src={quickIcon}
                        style={{ height: 80, width: 80, textAlign: "center" }}
                        alt=""
                      />
                    </div>

                    <div>
                      <div
                        className="basier"
                        style={{
                          fontSize: 18,
                          textTransform: "uppercase",
                          color: "black",
                        }}
                      >
                        Connect/Import from new Account
                      </div>
                      <div className="basier-p4" style={{ textAlign: "left" }}>
                        Connect your cryptocurrency wallets and exchanges for
                        automatic imports or import CSV to a new account.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div style={{ height: 520 }}>
              {this.rendererSelector(this.state.type_selected)}
            </div>
          )
        ) : null}
      </div>
    ) : (
      <div>
        <BaseModalTop
          handleClose={this.handleClose}
          onClick={this.backToWelcome}
          welcome={this.state.welcome}
          entryType={this.state.type_selected}
        />
        <div
          style={{
            paddingLeft: 48,
            paddingRight: 48,
            height: 520,
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            flexDirection: "column",
            textAlign: "center",
          }}
        >
          <img src={Oops} alt="" style={{ height: 100, marginTop: 120 }} />
          <h3 style={{ marginTop: 12, fontWeight: 900, color: "#0078DB" }}>
            Insufficient Permissions
          </h3>
          <div style={{ marginTop: 24, fontWeight: 400 }} className="basier-p3">
            You do not have the required permissions to add transactions to this
            portfolio. Please contact one of the portfolio administratiors.
          </div>
        </div>
      </div>
    );
  }
}

ResponsiveDialog.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  return {
    modalPref: state.interactions.transactionAddModal,
    portfoliosData: state.data.portfoliosData,
    transaction: state.interactions.transactionAddData,
    subscription: state.auth.subscription,
    limits: state.appwide.limits,
    roles: state.auth.roles,
    currentPortfolio: state.data.menu_selected,
    trial: state.auth.trial,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleModal: (data) => dispatch(actions.toggleModal(data)),
    removeTransaction: (data) => dispatch(actions.removeTransaction(data)),
    setTrTime: (data) => dispatch(actions.setTrTime(data)),
    setInteraction: (data) => dispatch(actions.setInteraction(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withMobileDialog()(withRouter(ResponsiveDialog)));
