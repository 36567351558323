
export default () => {
	self.addEventListener('message', e => { // eslint-disable-line no-restricted-globals
		if (!e) return;

		let active = []
		let small = []
		let exited = []

		const data = e.data[0]
        const total_value = e.data[1]

		data.map((balance, index) => {

			let isExit = balance.total_value < 0.00000005*total_value && balance.total_value < 0.5
            if (balance.total_value >= total_value * 0.005) {

                active.push(balance)

            } 
            else if (balance.total_value < total_value * 0.005 && !isExit) {
                
                small.push(balance)


            }

            else if (isExit) {

                exited.push(balance)

            }
			

		})
		
		postMessage([active, small, exited]);

	})
}
