import React, { Component } from "react";
import LpQuoteSummary from "./LPQuoteSummary";
import LPPoolToken from "./LPPoolToken";
import LpTokenBalance from "./LPTokenBalance";
import Moment from "moment";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import LPProfitLine from "./profitLine";

function renderDex(dex) {
  switch (dex) {
    case "uniswap": {
      return { name: "uniswap", color: "#FE1C87" };
    }
    default: {
      return { name: dex, color: "#6A7E93" };
    }
  }
}

class LPProfitSection extends Component {
  render() {
    const { poolData } = this.props;
    const lines = [];
    let poolProfit = 0;
    Object.keys(poolData.translator).map((v) => {
      const intitialBalance = poolData.initial_token_balance[v]
      const newPrice = poolData.current_prices[v]
      const initialPrice = poolData.initial_prices[v]
      const ticker = poolData.translator[v].symbol
      if (poolData.connected) {
        const currentBalance = poolData.connected_token_balance[v]
        poolProfit += (newPrice * (currentBalance - intitialBalance))
      }
      lines.push({label: `incl. ${ticker} capital gain/loss`, value: (intitialBalance * (newPrice - initialPrice)) })
    })
    lines.push({label: "incl. liqudity pool profit", value: poolProfit })
    return (
      <div>
        <div style={styles.top} className="basier-p3">
          <div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ ...styles.titleText, color: "black" }}>
                Liquidity Pool
              </div>
              {poolData.dex && (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div
                    style={{
                      ...styles.titleText,
                      marginLeft: 4,
                      marginRight: 4,
                      color: "#6A7E93",
                    }}
                  >
                    |
                  </div>
                  <div
                    style={{
                      ...styles.titleText,
                      color: renderDex(poolData.dex).color,
                    }}
                  >
                    {renderDex(poolData.dex).name}
                  </div>
                </div>
              )}
            </div>
            <div className={"basier-p4"} style={{ color: "#6A7E93" }}>
              Opened on {Moment(poolData.date).format("YYYY-MM-DD | HH:mm")}
            </div>
          </div>
          <div
            style={{
              paddingRight: 12,
              display: "flex",
              flex: 3,
              flexDirection: "column",
              alignItems: "flex-end",
              textAlign: "right",
            }}
          >
            <div className={"basier-p4-caps"} style={{ color: "#6A7E93" }}>
              Current Value
            </div>
            <div
              className={"poppins"}
              style={{
                color: "black",
                fontSize: 18,
              }}
            >
              {this.props.symbol}
              {poolData.current_value.toLocaleString("en", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </div>
          </div>
        </div>
        <div style={{paddingRight: 12, marginTop: 2, marginBottom: 12}}>
          <LPProfitLine label="OVERALL PROFIT" value={poolData.current_value - poolData.initial_value} size="main" />
          {lines.map((v, k) => {
            return (
              <LPProfitLine
                key={k}
                label={v.label}
                value={v.value}
              />
            );
          })}
        </div>
      </div>
    );
  }
}

const styles = {
  overallBox: {
    minHeight: 60,
    width: "100%",
    backgroundColor: "white",
    borderRadius: 20,
    marginTop: 12,
    marginBottom: 12,
    display: "flex",
    flexDirection: "column",
    boxShadow: "0px 10px 10px rgba(225, 229, 233, 0.3)",
    border: "1px solid #E1E5E9",
    padding: "12px 24px",
    paddingBottom: 24,
  },
  top: {
    width: "100%",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  bottom: {
    padding: "6px 12px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    border: "1px solid #e0a03d",
    borderRadius: 10,
    backgroundColor: "#e0a03d1A",
    marginTop: 12,
    height: 40,
  },
  bottomContainer: {},
  titleText: {
    fontWeight: "bold",
    textTransform: "uppercase",
  },
  bodyDivider: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
};
export default LPProfitSection;
