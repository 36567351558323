import React from "react";
import { connect } from "react-redux";
import ArrowLeft from "../../Media/ArrowLeftBox.svg";
import quickIcon from "../../Media/quickadd-blue.svg";
import uploadIcon from "../../Media/upload-blue.svg";
import manualWallet from "../../Media/manualWallet.svg";
import BackIcon from "../../Media/BackIcon.svg";

import APIConnection from "./APIConnection";
import UploadFile from "./UploadFile";


const Bubble = (props) => {
  return (
    <div
      onClick={props.onClick}
      className="no-select"
      style={{
        width: "100%",
        height: 100,
        marginBottom: 12,
        borderRadius: 20,
        border: "1px solid #F8F9FA",
        boxShadow: "0px 10px 10px rgba(225, 229, 233, 0.3)",
        padding: 24,
        display: 'flex', 
        alignItems: 'center',
        cursor: 'pointer',
      }}
    >
      {props.children}
    </div>
  );
};

class AccountRightSection extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  selectType = (type) => {
    this.props.changeScope(type)
  }

  componentDidMount() {

    const scope_pf = []
    this.props.portfolios.map(v => {
        scope_pf.push({
            value: v.id, 
            label: v.name,
        })
    })

    const exchangeData = this.props.connectors['exchange'].filter(v => v.ready === true)
    const storageData = this.props.connectors['storage'].filter(v => v.ready === true)

    let exchangeNorm = []
    let storageNorm = []

    exchangeData.map(v => {
      const tempData = {
        label: v['exchange_name'],
        value: v['exchange_id'],
        image: v['image'],
        fields: v['fields'],
      }
      exchangeNorm.push(tempData)
    })

    storageData.map(v => {
      const tempData = {
        label: v['coin_name'],
        value: v['coin_id'],
        image: v['image'],
        fields: v['fields'],
      }
      storageNorm.push(tempData)
    })

    this.setState(state => ({...state, exchangeConns: exchangeNorm, storageConns: storageNorm, portfolios: scope_pf}))        


  }

  componentDidUpdate(prevProps) {
    if ((prevProps.data.exchange !== this.props.data.exchange) || (this.props.type !== prevProps.type) || (prevProps.data.coin_id !== this.props.data.coin_id) || (prevProps.data.custom_exchange !== this.props.data.custom_exchange)) {
      if (this.props.data.exchange || this.props.data.coin_id || this.props.data.custom_exchange) {
        this.props.changeScope("select")
      } else {
        this.props.changeScope(null)
      }
    } 
  }

  focusIn = () => {};

  render() {
    const { type } = this.props;
    let connector = undefined;
    let source;
    try {
      if (type === "exchange") {
      source = this.props.data.exchange
      connector = this.state.exchangeConns.filter(
        (v) => v.value === this.props.data.exchange
      )[0];
      } else {
        source = this.props.data.coin_id
        connector = this.state.storageConns.filter(
          (v) => v.value === this.props.data.coin_id
        )[0];
      }
    } catch (e) {}

    return this.props.data.type === undefined ? (
      <div
        style={{
          width: "100%",
          height: 300,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src={ArrowLeft}
            style={{ height: 80, width: 80, marginBottom: 24 }}
            alt=""
          />
          <div
            className="basier"
            style={{ fontWeight: "bold", fontSize: 18, textAlign: "center" }}
          >
            Select {type === "exchange" ? "Exchange" : "Coin"}{" "}
          </div>
          <div className="basier-p2" style={{ textAlign: "center" }}>
            Please select an {type === "exchange" ? "Exchange" : "Coin"} on the
            left first, then select an entry method here.
          </div>
        </div>
      </div>
    ) : this.props.scope === "select" ? (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          flex: 1,
          width: "100%",
          paddingLeft: 0,
        }}
      >
        <div
          className="basier-p4-caps"
          style={{
            color: "black",
            textTransform: "uppercase",
            fontWeight: 900,
            marginBottom: 12,
          }}
        >
          Select Import Method
        </div>
        {connector !== undefined && (
          <Bubble onClick={() => this.selectType('api')}>
            <div style={{ textAlign: "center", marginRight: 12 }}>
              <img
                src={quickIcon}
                style={{ height: 40, width: 40, textAlign: "center" }}
                alt=""
              />
            </div>

            <div>
              <div
                className="basier"
                style={{
                  fontSize: 18,
                  textTransform: "uppercase",
                  color: "black",
                }}
              >
                API Connection
              </div>
              <div className="basier-p4" style={{ textAlign: "left" }}>
                Automatically import transactions
              </div>
            </div>
          </Bubble>
        )}
        {!this.props.data.custom_exchange &&
        <Bubble onClick={() => this.selectType('import')}>
          <div style={{ textAlign: "center", marginRight: 12 }}>
              <img
                src={uploadIcon}
                style={{ height: 40, width: 40, textAlign: "center" }}
                alt=""
              />
            </div>

            <div>
              <div
                className="basier"
                style={{
                  fontSize: 18,
                  textTransform: "uppercase",
                  color: "black",
                }}
              >
                CSV Import
              </div>
              <div className="basier-p4" style={{ textAlign: "left" }}>
                Import transactions in bulk
              </div>
            </div>
        </Bubble>}

        {this.props.origin === "wallet" && (
          <Bubble onClick={this.props.manualCreate}>
            <div style={{ textAlign: "center", marginRight: 12 }}>
              <img
                src={manualWallet}
                style={{ height: 40, width: 40, textAlign: "center" }}
                alt=""
              />
            </div>

            <div>
              <div
                className="basier"
                style={{
                  fontSize: 18,
                  textTransform: "uppercase",
                  color: "black",
                }}
              >
                Manual Account
              </div>
              <div className="basier-p4" style={{ textAlign: "left" }}>
                Create account without connecting or importing transactions
              </div>
            </div>
          </Bubble>
        )}
      </div>
    ) :
    this.props.scope === "import" ?

    

    <div>
        <div className="no-select" style={{display: 'flex', alignItems: 'center', cursor: 'pointer'}} onClick={() => this.selectType("select")}>
          <img src={BackIcon} style={{height: 30, width: 30, marginRight: 0, marginLeft: -4, marginBottom: 4}} alt="" />
          <div
            className="basier-p4-caps"
            style={{
              textTransform: "uppercase",
              marginBottom: 12,
            }}
          > 
            Back To Import Method
          </div>
        </div>
        <UploadFile setImportError={this.props.setImportError} importTarget={this.props.importTo} scopePortfolios={this.state.portfolios} selectedExchange={this.props.data.exchange} sourceId={source} sourceType={type} accountName={this.props.data.name} updateState={this.props.updateState}  />
        
      </div> 

    :

    this.props.scope === "api" ?

      <div>
        <div className="no-select" style={{display: 'flex', alignItems: 'center', cursor: 'pointer', height: 20}} onClick={() => this.selectType("select")}>
          <img src={BackIcon} style={{height: 30, width: 30, marginRight: 0, marginLeft: -4, marginBottom: 4}} alt="" />
          <div
            className="basier-p4-caps"
            style={{
              textTransform: "uppercase",
              marginBottom: 12,
            }}
          > 
            Back To Import Method
          </div>
        </div>
        <APIConnection importTarget={this.props.importTo} scopePortfolios={this.state.portfolios} connectorData={connector} selectedExchange={this.props.data.exchange} connection_type={this.props.type} data={this.props.data} updateState={this.props.updateState} setPortfolio={this.props.setPortfolio} />
      </div>


    :

    null
  }
}

const mapStateToProps = (state) => {
  return {
    exchanges: state.appwide.exchanges,
    user_id: state.auth.user_id,
    connectors: state.appwide.connectors,
    portfolios: state.auth.list_portfolios
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountRightSection);
