import React from "react";
import { connect } from "react-redux";
import * as actions from "../../store/actions/data";
import * as interActions from "../../store/actions/interactions";
import { withStyles } from "@material-ui/core/styles";
import { fade } from "@material-ui/core/styles/colorManipulator";
import ReactTable from "react-table";
import "react-table/react-table.css";
import matchSorter from "match-sorter";
import { DateFormatInput } from "material-ui-next-pickers";
import { renderAuditType } from "../../helpers/helpers";
import Export from "../../Media/exportCSV.svg";
import { backendUrl } from "../../variables";
import ExportTable from "../AppWide/ExportTable";

class TransactionOverview extends React.PureComponent {
  state = {
    date: null,
    scope: "audit",
  };
  componentDidMount() {
    //this.props.setCurrentView(5)
    //this.props.componentDataFetch('get', 5, null, null, this.props.portfolioId, null, null, this.props.inBitcoin)
  }

  switchScope = (scope) => {
    this.setState((state) => ({ ...state, scope: scope }));
  };

  export = () => {
    this.props.toggleModal({
      type: "export_modal",
      open: true,
      payload: { scope: "audit trail data", submit: this.commitExport },
    });
  };
  commitExport = () => {
    let url;
    if (this.props.auditScope === "global") {
      url = `${backendUrl}/v1/audit_trail/?u=${this.props.user_id}&export=1`;
    } else {
      url = `${backendUrl}/v1/audit_trail/?p=${this.props.portfolioId}&u=${this.props.user_id}&export=1`;
    }

    let headers = { "content-type": "application/json" };
    headers["Authorization"] = `Token ${this.props.token}`;
    fetch(url, { headers: headers }).then((res) => {
      this.props.showSnack({ message: "Export requested", variant: "success" });
      window.setTimeout(() => {
        this.setState((state) => ({ ...state, scope: "export" }));
      }, 500)
      
    });
  };

  toggleTxModal = (data) => {
    this.props.toggleModal({
      type: "audit_modal",
      open: true,
      payload: { data: data },
    });
  };

  renderContent = (new_data, old_data, data) => {
    let value = null;
    if (data.action === "delete") {
      value = old_data;
    } else {
      value = new_data;
    }

    if (
      data !== null &&
      data !== undefined &&
      value !== null &&
      value !== undefined
    ) {
      switch (data.scope) {
        case "portfolio": {
          return value.name;
        }
        case "transaction": {
          let base = "";
          try {
            base = value.base_currency.symbol;
          } catch (e) {}

          let amount = "";
          try {
            amount = value.amount;
          } catch (e) {}

          let quote = "";
          try {
            quote = value.quote_currency.symbol;
          } catch (e) {}

          let type = "";
          try {
            type = value.transaction_type;
          } catch (e) {}

          const caller = `${type} ${amount} ${base} ${
            quote !== "" ? `/ ${quote}` : ""
          }`;
          return caller;
        }
        case "upload": {
          return data.portfolio.name;
        }
        case "exchange": {
          if (
            value.custom_exchange_check === true &&
            value.custom_exchange !== null
          ) {
            return `${value.name} - ${value.custom_exchange}`;
          } else if (
            value.custom_exchange_check === false &&
            value.exchange !== null
          ) {
            return `${value.name} - ${value.exchange.name}`;
          } else {
            return `${value.name}`;
          }
        }
        case "wallet": {
          let coin_symbol = undefined;
          try {
            coin_symbol = value.coin.symbol;
          } catch (e) {}

          if (value.wallet_type === "storage") {
            if (coin_symbol !== undefined) {
              return `${value.name} - ${coin_symbol}`;
            } else {
              return `${value.name}`;
            }
          } else {
            if (
              coin_symbol !== undefined &&
              value.exchange.name !== undefined
            ) {
              return `${value.exchange.name} - ${coin_symbol}`;
            } else {
              return `${value.name}`;
            }
          }
        }
        default:
          return null;
      }
    }
  };

  render() {
    let firstColumn = [];

    if (this.props.auditScope === "global") {
      firstColumn = [
        {
          accessor: "portfolio",
          Header: () => (
            <div
              className="basier-p4-caps"
              style={{ textAlign: "left", paddingLeft: 12 }}
            >
              Portfolio
            </div>
          ),
          minWidth: 120,
          filterMethod: (filter, rows) =>
            matchSorter(rows, filter.value, { keys: ["content_object"] }),
          filterAll: true,
          Filter: ({ filter, onChange }) => (
            <input
              placeholder="Filter portfolio"
              className="basier-p4"
              onChange={(event) => onChange(event.target.value)}
              style={{ width: "100%", textAlign: "left", paddingLeft: 12 }}
              value={filter ? filter.value : null}
            />
          ),
          Cell: (row) => {
            return (
              <div>
                <div className="basier-p4-caps" style={{ paddingLeft: 12 }}>
                  {row.value !== null && row.value !== undefined
                    ? row.value
                    : null}
                </div>
              </div>
            );
          },
        },
      ];
    } else {
      firstColumn = [
        {
          accessor: "first_name",
          Header: () => (
            <div
              className="basier-p4-caps"
              style={{ textAlign: "left", paddingLeft: 12 }}
            >
              User
            </div>
          ),
          minWidth: 120,
          filterMethod: (filter, rows) =>
            matchSorter(rows, filter.value, { keys: ["content_object"] }),
          filterAll: true,
          Filter: ({ filter, onChange }) => (
            <input
              placeholder="Filter portfolio"
              className="basier-p4"
              onChange={(event) => onChange(event.target.value)}
              style={{ width: "100%", textAlign: "left", paddingLeft: 12 }}
              value={filter ? filter.value : null}
            />
          ),
          Cell: (row) => {
            return (
              <div>
                <div className="basier-p4-caps" style={{ paddingLeft: 12 }}>
                  {`${row.original.first_name}  ${row.original.last_name}`}
                </div>
              </div>
            );
          },
        },
      ];
    }

    let otherColumns = [
      {
        id: "created_at",
        accessor: "created_at",
        Header: () => (
          <div className="basier-p4-caps" style={{ paddingLeft: 12 }}>
            Date
          </div>
        ),
        maxWidth: 120,
        minWidth: 110,
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["created_at"] }),
        Filter: ({ filter, onChange }) => (
          <input
            placeholder="DDDD-MM-YY"
            className="basier-p4"
            onChange={(event) => onChange(event.target.value)}
            style={{ width: "100%" }}
            value={filter ? filter.value : null}
          />
        ),
        filterAll: true,
        Cell: (row) => {
          const language = navigator.language || "en";
          const options = { year: "numeric", month: "numeric", day: "numeric" };
          const parseDate = new Date(row.value);
          const showDate = parseDate.toLocaleDateString(language, options);

          return (
            <div>
              <div className="basier-p4" style={{ paddingLeft: 12 }}>
                {showDate}
              </div>
            </div>
          );
        },
      },
      {
        accessor: "action",
        Header: () => (
          <div className="basier-p4-caps" style={{ paddingLeft: 12 }}>
            Action
          </div>
        ),
        maxWidth: 300,
        minWidth: 210,
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["action"] }),
        filterAll: true,
        Filter: ({ filter, onChange }) => (
          <input
            placeholder="Filter action"
            className="basier-p4"
            onChange={(event) => onChange(event.target.value)}
            style={{ width: "100%" }}
            value={filter ? filter.value : null}
          />
        ),
        Cell: (row) => {
          return (
            <div>
              <div className="basier-p4-caps" style={{ paddingLeft: 12 }}>
                {renderAuditType(row.original.scope, row.value)}
              </div>
            </div>
          );
        },
      },
      {
        accessor: "new_data",
        Header: () => (
          <div
            className="basier-p4-caps"
            style={{ textAlign: "left", paddingLeft: 12 }}
          >
            Scope
          </div>
        ),
        minWidth: 140,
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["content_object"] }),
        filterAll: true,
        Filter: ({ filter, onChange }) => (
          <input
            placeholder="Filter amount"
            className="basier-p4"
            onChange={(event) => onChange(event.target.value)}
            style={{ width: "100%", textAlign: "left", paddingLeft: 12 }}
            value={filter ? filter.value : null}
          />
        ),
        Cell: (row) => {
          return (
            <div>
              <div
                className="basier-p4"
                style={{
                  textAlign: "left",
                  paddingLeft: 12,
                  textTransform: "capitalize",
                }}
              >
                {this.renderContent(
                  row.value,
                  row.original.old_data,
                  row.original
                )}
              </div>
            </div>
          );
        },
      },
      {
        accessor: "id",
        Header: () => (
          <div className="basier-p4-caps" style={{ textAlign: "center" }}>
            Action
          </div>
        ),
        maxWidth: 90,
        Cell: (row) => {
          return row.original.scope !== "upload" ? (
            <div onClick={() => this.toggleTxModal(row.original)}>
              <div
                className="basier-p4-caps opacity-hover"
                style={{
                  textAlign: "center",
                  cursor: "pointer",
                  color: "#0078DB",
                  fontWeight: 900,
                }}
              >
                View
              </div>
            </div>
          ) : null;
        },
      },
    ];

    const columns = [...firstColumn, ...otherColumns];

    return (
      <div style={{ width: "100%", marginLeft: "auto", marginRight: "auto" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          {["audit", "export"].map((v, k) => {
            return (
              <div
                className="basier-p4-caps"
                key={k}
                style={{ display: "flex", alignItems: "center" }}
              >
                <div
                  style={{
                    color: this.state.scope === v ? "#0078DB" : "#6A7E93",
                    fontWeight: this.state.scope === v ? "bold" : "normal",
                    cursor: "pointer",
                  }}
                  className="opacity-hover"
                  onClick={() => this.switchScope(v)}
                >
                  {v === "audit" ? "Audit Trail" : "Exports"}
                </div>
                {k !== 1 && (
                  <div style={{ marginLeft: 6, marginRight: 6 }}> | </div>
                )}
              </div>
            );
          })}
        </div>
        <div
          style={{
            marginBottom: 12,
            marginRight: 12,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div style={{ marginTop: 6 }}>
            <h5 style={{ marginBottom: 0 }}>
              {this.state.scope === "audit"
                ? "First 100 Records"
                : "Full Exports"}
            </h5>
            {this.state.scope === "audit" && (
              <div className="basier-p4-caps" style={{ color: "#6A7E93" }}>
                Export to see full audit trail
              </div>
            )}
          </div>
          {this.state.scope === "audit" && (
          <div
            onClick={this.export}
            style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
            className="opacity-hover"
          >
            <div>
              <img
                src={Export}
                alt="export"
                style={{ height: 24, width: 24 }}
              />
            </div>
            
              <div className="basier-p4-caps" style={{ marginLeft: 12 }}>
                Export audit trail
              </div>
          </div>
          )}
        </div>

        {this.state.scope === "export" ? (
          <ExportTable
            scope="audit"
            portfolioId={
              this.props.auditScope === "global"
                ? undefined
                : this.props.portfolioId
            }
          />
        ) : (
          <ReactTable
            defaultPageSize={5}
            filterable
            resizable={false}
            defaultFilterMethod={(filter, row) =>
              String(row[filter.id]) === filter.value
            }
            showPageSizeOptions={false}
            data={this.props.data}
            columns={columns}
            getProps={(state, rowInfo, column) => {
              return {
                style: {
                  border: "0px",
                },
              };
            }}
            getTrGroupProps={(state, rowInfo, column) => {
              return {
                style: {
                  border: "0px",
                },
              };
            }}
            getTheadFilterProps={(state, rowInfo, column) => {
              return {
                style: {
                  marginTop: 6,
                  border: "none",
                },
              };
            }}
            getPaginationProps={(state, rowInfo, column) => {
              return {
                style: {
                  boxShadow: "none",
                  border: "none",
                },
              };
            }}
            getTheadProps={(state, rowInfo, column) => {
              return {
                style: {
                  boxShadow: "none",
                },
              };
            }}
            getTrProps={(state, rowInfo, column) => {
              return {
                style: {
                  display: "flex",
                  alignItems: "center",
                  marginBottom: 4,
                  marginTop: 4,
                  boxShadow:
                    rowInfo === undefined
                      ? "none"
                      : "0px 10px 10px rgba(225, 229, 233, 0.3)",
                  borderRadius: rowInfo === undefined ? 0 : 35,
                  height: 56,
                  border: "0px",
                  background:
                    rowInfo === undefined
                      ? "white"
                      : rowInfo.row.action === "create"
                      ? "linear-gradient(180deg, #FFFFFF 75%, rgba(255, 255, 255, 0) 175.3%), #4CDAC1"
                      : rowInfo.row.action === "edit"
                      ? "linear-gradient(180deg, #FFFFFF 75%, rgba(255, 255, 255, 0) 175.3%), #ffc163"
                      : rowInfo.row.action === "delete"
                      ? "linear-gradient(180deg, #FFFFFF 75%, rgba(255, 255, 255, 0) 175.3%), #F9716B"
                      : "white",
                },
              };
            }}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    inBitcoin: state.data.bitcoinPf,
    token: state.auth.token,
    user_id: state.auth.user_id,
    portfolioId: state.data.menu_selected,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCurrentView: (view) => dispatch(actions.setCurrentView(view)),
    componentDataFetch: (
      scope,
      view,
      currency,
      user,
      portfolio,
      globalGraphData,
      portfolioGraphData,
      inBitcoin
    ) =>
      dispatch(
        actions.componentDataFetch(
          scope,
          view,
          currency,
          user,
          portfolio,
          globalGraphData,
          portfolioGraphData,
          inBitcoin
        )
      ),
    toggleModal: (data) => dispatch(interActions.toggleModal(data)),
    showSnack: (data) => dispatch(interActions.showSnack(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TransactionOverview);
