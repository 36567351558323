import React from "react";
import nftIcon from "../../../../../../Media/nftIcon.svg";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import Axios from "axios";
import { backendUrl } from "../../../../../../variables";
import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from '@material-ui/core/styles';

const getValue = (object, keys, defaultValue) => {
  let ret;
  let i = 0;
  const len = keys.length;
  try {
    while (i < len) {
      ret = object[keys[i]];
      object = ret;
      i++;
    }
  } catch (e) {}
  if (ret === undefined && defaultValue !== undefined) {
    ret = defaultValue;
  }
  return ret;
};

const ColorLinearProgress = withStyles({
  root: {
      height: 6,
      borderRadius: 5
  },
  colorPrimary: {
    backgroundColor: '#E1E5E9',
  },
  barColorPrimary: {
    backgroundColor: '#6A7E93',
  },
})(LinearProgress);

class NFTTableLine extends React.Component {
  state = {
    show: false, //this.props.nftPrices[this.props.data.id] ? true : false,
    current: this.props.data.purchase,// this.props.nftPrices[this.props.data.id] ? this.props.nftPrices[this.props.data.id] : this.props.data.purchase,
    profit: 0, //this.props.nftPrices[this.props.data.id] ? this.props.this.props.nftPrices[this.props.data.id] - this.props.data.purchase : 0,
    mount: true,
  };

  mount = true

  componentWillUnmount() {
    this.mount = false
  }

  checkPrices = () => {
    // if (this.props.data.link_price.length === 0) return;
    // const currencyPrice = this.props.data.quote_price;
    // let count = 0;
    // let sum = 0;
    // this.props.data.link_price.map((link) => {
    //   const cache = this.props.priceCache;
    //   const existing = getValue(cache, [this.props.data.id, link.id], null)
    //   if (existing) {
    //     sum += existing * currencyPrice
    //     count++
    //   }
    // })
    // if (count === this.props.data.link_price.length) {
    //   this.setState(state => ({...state, current: sum, profit: sum - this.props.data.purchase, show: true}))
    // }

  }
  componentDidMount() {
    //this.checkPrices();

    const num = this.props.data.tokens.length
    const prices = this.props.prices
    if (prices) {
      let thisPrice = getValue(prices, [this.props.data.network, 'prices', this.props.data.id], null)
      
      const adjustedPrice = thisPrice ? thisPrice * this.props.data.quote_price : this.props.data.purchase / num
      const value = thisPrice ? (num * adjustedPrice) : this.props.data.purchase
      const profit =  value - this.props.data.purchase

      this.setState(state => ({...state, current: value, profit: profit, show: true }))
    }


    // if (this.props.data.link_price.length > 0) {      
    //   const contractId = this.props.data.id;
    //   this.props.getExternalPrice(contractId, this.props.data.link_price)
    // }
  }

  componentDidUpdate(prevProps) {
    // if (this.props.data.link_price.length !== 0) {
    //   const newData = getValue(this.props.priceCache, [this.props.data.id])
    //   const oldData = getValue(prevProps.priceCache, [prevProps.data.id])
    //   if (newData !== oldData) {
    //     this.checkPrices();
    //   }
    // }
  }

  render() {
    const { data, currency_symbol } = this.props;
    const {show, current, profit} = this.state;

    return (
      <div style={styles.overallBox}>
        <div
          style={{
            width: 200,
            height: "100%",
            display: "flex",
            alignItems: "flex-start",
          }}
        >
          <div style={{ height: "100%", maxWidth: 74, minWidth: 74 }}>
            {data.image ? (
              <img src={data.image} style={{ height: "100%", width: '100%', objectFit: "contain", backgroundPosition: 'center' }} alt="" />
            ) : (
              <div
                style={{
                  height: 74,
                  width: 74,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  src={nftIcon}
                  style={{ height: 40, width: 40 }}
                  alt="nft"
                />
              </div>
            )}
          </div>
          <div style={{ marginLeft: 12, width: 126 }}>
            <div
              className="basier-p4-caps"
              style={{ color: "#6A7E93", marginBottom: 6 }}
            >
              Collection
            </div>
            <div
              className="basier-p3 truncate"
              style={{ fontWeight: "bold", color: "black" }}
            >
              {data.name}
            </div>
            <div className="basier-p4 truncate" style={{ color: "#6A7E93" }}>
              {data.address}
            </div>
          </div>
        </div>
        <div
          style={{
            flex: 1,
            paddingLeft: 24,
            height: "100%",
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "space-betwen",
          }}
        >
          <div
            style={{
              width: 100,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div
              className="basier-p4-caps"
              style={{ color: "#6A7E93", marginBottom: 6, textAlign: "center" }}
            >
              Qty
            </div>
            <div style={{ textAlign: "center" }}>{data.tokens.length}</div>
          </div>

          <div
            style={{
              minWidth: 150,
              flex: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
            }}
          >
            <div
              className="basier-p4-caps"
              style={{ color: "#6A7E93", marginBottom: 6, textAlign: "right" }}
            >
              Total Cost
            </div>
            <div style={{ textAlign: "right" }}>
              {currency_symbol}
              {data.purchase.toLocaleString("en", {
                minimumFractionDigits: 2,
                maximumFractionDigits: data.purchase < 1 ? 6 : 2,
              })}
            </div>
          </div>

          <div
            style={{
              minWidth: 150,
              flex: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
            }}
          >
            <div
              className="basier-p4-caps"
              style={{ color: "#6A7E93", marginBottom: 6, textAlign: "right" }}
            >
              Current Value
            </div>
            {show ? 
              <div style={{ textAlign: "right" }}>
                {currency_symbol}
                {current.toLocaleString("en", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: current < 1 ? 6 : 2,
                })}
              </div>
            :
            <div style={{width: 100}}>
              <ColorLinearProgress />
            </div>
            }
          </div>

          <div
            style={{
              minWidth: 150,
              flex: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
            }}
          >
            <div
              className="basier-p4-caps"
              style={{ color: "#6A7E93", marginBottom: 6, textAlign: "right" }}
            >
              Profit
            </div>
            {show ?
              <div
                style={{
                  textAlign: "right",
                  fontWeight: "bold",
                  color:
                    profit > 0
                      ? "#4CDAC1"
                      : profit < 0
                      ? "#F9716B"
                      : "#6A7E93",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {profit > 0 ? (
                  <ArrowDropUpIcon style={{ fontSize: 20 }} />
                ) : profit < 0 ? (
                  <ArrowDropDownIcon style={{ fontSize: 20 }} />
                ) : null}
                {currency_symbol}
                {Math.abs(profit).toLocaleString("en", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: Math.abs(profit) < 1 ? 6 : 2,
                })}
              </div>
            :
            <div style={{width: 100}}>
              <ColorLinearProgress />
            </div>
            }
          </div>
        </div>
      </div>
    );
  }
}

const styles = {
  overallBox: {
    height: 100,
    width: "100%",
    backgroundColor: "white",
    borderRadius: 20,
    marginTop: 10,
    marginBottom: 10,
    marginLeft: 4,
    marginRight: 4,
    display: "flex",
    flexDirection: "row",
    boxShadow: "0px 10px 10px rgba(225, 229, 233, 0.3)",
    border: "1px solid #E1E5E9",
    padding: "12px 24px",
  },
  top: {
    height: 60,
    width: "100%",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    marginBottom: 12,
  },
  bottom: {
    padding: "6px 12px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    border: "1px solid #e0a03d",
    borderRadius: 10,
    backgroundColor: "#e0a03d1A",
    marginTop: 12,
    height: 40,
  },
  bottomContainer: {},
  titleText: {
    fontWeight: "bold",
    textTransform: "uppercase",
  },
  bodyDivider: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
};

export default NFTTableLine;
