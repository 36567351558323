import React from 'react';
import { connect } from 'react-redux';
import { backendUrl, cbKey } from '../../../variables'
import 'filepond/dist/filepond.min.css';
import * as appwideActions from "../../../store/actions/appwide";
import * as interActions from "../../../store/actions/interactions";
import * as dataActions from "../../../store/actions/data";
import SingleSelectImage from '../../AppWide/SingleSelectImage';
import whiteLoader from '../../../Media/whiteloader.gif'
import okIcon from '../../../Media/okIcon.svg'
import errorIcon from '../../../Media/errorIcon.svg'
import coinbaseWhite from '../../../Media/coinbaseWhite.png'
import { withStyles } from '@material-ui/core/styles';
import axios from 'axios';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import fieldValidator from '../../../helpers/validators';
import {specificMessageRenderer} from '../../../helpers/helpers';
import PortfolioSelect from '../../AppWide/TablePortfolioSelector';


function getCBValue(source, index, value) {
    let ret;
    try {
        ret = source[index][value]
    } catch(e) {}
    return ret
}

function getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
  
        // Windows Phone must come first because its UA also contains "Android"
      if (/windows phone/i.test(userAgent)) {
          return true;
      }
  
      if (/android/i.test(userAgent)) {
          return true;
      }
  
      // iOS detection from: http://stackoverflow.com/a/9039885/177710
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
          return true;
      }
  
      return false;
  }

const styles = theme => ({
    container: {
        width: '100%', 
        padding: '24px 48px', 
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            padding: 12, 
        },
    },
    image: {
        display: 'block', 
        [theme.breakpoints.down('sm')]: {
            display: 'none', 
        },
    },
    ImageText: {
        [theme.breakpoints.down('sm')]: {
            padding: 12 
        },
    },
    subContainer: {
        padding: 12,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            height: '100%',
        },
        [theme.breakpoints.up('md')]: {
            width: '48%',
            height: '100%',
        }
    },
    secondSub: {
        [theme.breakpoints.up('md')]: {
            height: 340,
        }
    }, 
    secondQuestions: {

        [theme.breakpoints.down('sm')]: {
            padding: 0
        },
        [theme.breakpoints.up('md')]: {
            marginTop: 0
        }

    }, 
    input: {
        
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 24, width: '100%', marginTop: 12, 
        },
        [theme.breakpoints.up('md')]: {
            paddingLeft: 24, width: 324, marginTop: 12, 
        }
    },
    fiatContainer: {
        
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        },
        [theme.breakpoints.up('md')]: {
            width: 324
        }
    }, 
    root: {
        color: '#6A7E93',
        '&$checked': {
          color: '#0078DB',
        },
      },
      checked: {
          color: '#0078DB'
      },
    });


class ConnectAccount extends React.PureComponent {

    state = {
        activeStep: 0,
        data: {
            scope: this.props.noPort === true ? 'choose' : 'all'
        }, 
        selected: null,
        loading: false, 
        error: null,
        validation: {
            name: true,
            select: true,
        },
        connection_type: 'exchange', 
        exchange: [], 
        storage: [],
        scopeData: undefined, 
      };


    componentDidMount() {

        const exchangeData = this.props.connectors['exchange'].filter(v => v.ready === true)
        const storageData = this.props.connectors['storage'].filter(v => v.ready === true)

        let exchangeNorm = []
        let storageNorm = []

        exchangeData.map(v => {
          const tempData = {
            label: v['exchange_name'],
            value: v['exchange_id'],
            image: v['image'],
          }
          exchangeNorm.push(tempData)
        })

        storageData.map(v => {
          const tempData = {
            label: v['coin_name'],
            value: v['coin_id'],
            image: v['image'],
          }
          storageNorm.push(tempData)
        })

        this.setState(state => ({...state, exchanges: exchangeNorm, storage: storageNorm}))        
    

    }

    updateState = (label, value) => {

        if (label === 'connection_type') {
            this.setState(state => ({...state, scopeData: undefined}))
        }
        this.setState(state => ({...state, [label]: value}))
    }

    updateData = (label, value) => {
        this.setState(state => ({...state, data: {...state.data, [label]: value}}))
    }

    updatePort = (data) => {
        this.setState(state => ({...state, ...data}))
    }

    submitServer = () => {


        let name_val = true;
        let select_val = true;
        
        let lowercaseName = this.state.data.name
        try { lowercaseName = this.state.data.name.toLowerCase().trim() } catch(e) {}
        name_val = fieldValidator(this.state.data.name, ['isNotEmpty'])
        name_val = name_val && !(this.props.existingNames.includes(lowercaseName))
        const name_message = (this.props.existingNames.includes(lowercaseName)) && this.state.data.name !== '' && this.state.data.name !== undefined && this.state.data.name !== null ? 'You already have an account with this name. Please choose a different name' : null
            this.setState(state => ({
            ...state, 
            validation: {
                ...state.validation,
                name: name_val, 
                name_message: name_message}
            })
        )

        select_val = this.state.scopeData !== undefined && this.state.selected !== null
        this.setState(state => ({
            ...state, 
            validation: {
                ...state.validation,
                select: select_val, }
            })
        )
        
        if (select_val && name_val) {
            this.setState(state => ({...state, loading: true}))

            let submitData = {
                ...this.state.data, 
                user_id: this.props.user_id, 

            }

            if ( submitData.scope === 'all' ) {
                submitData['portfolio'] = this.props.noPort === true ? this.state.portfolio : this.props.overridePort === true ? this.props.overridePortId : this.props.currentPortfolio
            }

            let headers = { 
                "content-type": "application/json",     
            };
            headers["Authorization"] = `Token ${this.props.token}`;

            
            let url = null 
            if (this.state.connection_type === 'exchange') {
                url = `${backendUrl}/v1/connectors/setup/`
                submitData['exchange_id'] = this.state.selected
            } else if (this.state.connection_type === 'storage') {
                url = `${backendUrl}/v1/wallets/setup/`
                submitData['coin_id'] = this.state.selected
            }
            axios
            .post(url,
                submitData
            , { headers: headers } )
            .then(res => { 
                const accountId = res['data']['data']['id']
                const currentTasks = this.props.tasks
                let newTasks = [...currentTasks]
                if (this.state.connection_type=== 'exchange') {
                    newTasks = [{description: `Exchange Connection`, sub: `${this.state.scopeData[0].exchange_name}`, status: 'pending', type: 'connection', task_id: accountId}, ...currentTasks]
                } else if (this.state.connection_type === 'storage') {
                    newTasks = [{description: `Wallet Connection`, sub: `${this.state.scopeData[0].coin_name}`, status: 'pending', type: 'connection', task_id: accountId}, ...currentTasks]
                }
                
                this.props.setInteraction({label: "tasks", value: newTasks})


                //this.connectForUpdate();
                this.props.getWallets(this.props.user_id, this.props.token);
                this.props.getUserLimits(this.props.user_id)
                this.handleClose();

            })
            .catch(err => {
                let error = "Unable to connect at this time, please try again."
                
                //console.log(err)

                try {
                    error = err.response['data']['data']
                } catch(e) {}

                this.setState(state => ({...state, error: error, loading: false }))

            })
        }
    }

    handleInit() {
    }

    handleClose = () => {

        this.props.toggleModal({type: null, open: false, payload: null})
       
      }

    connectForUpdate = () => {

        const prefix = process.env.REACT_APP_HOST_ENV === 'dev' ? 'ws' : process.env.REACT_APP_HOST_ENV === 'mobile' ? 'ws' : 'wss'

        const url = process.env.REACT_APP_HOST_ENV === 'dev' ? backendUrl.slice(6,) : process.env.REACT_APP_HOST_ENV === 'dev' ? backendUrl.slice(6,) : backendUrl.slice(7,)
        
        const allUrl = `${prefix}://${url}/ws/tasks/${this.props.user_id}?t=${this.props.token}`
        const ws = new WebSocket(allUrl);

        ws.onopen = function () {
        };

        ws.onmessage = (evt) => {
            
            const message = JSON.parse(evt.data)['message']
            const tasks = this.props.tasks
            
            const newTasks = []

            tasks.map(val => {
                if (val.id == message.id && val.type == message.type) {
                    val.status = message.status
                    newTasks.push(val)
                } else {
                    newTasks.push(val)
                }

            })
            this.props.setInteraction({label: "tasks", value: newTasks})
            ws.close()

            const request_data = {
                period: null,
                start: null,
                end: null
              }

            if (this.props.overridePort === true) {

                this.props.componentDataFetch('update', 1, this.props.currency_id, this.props.user_id, this.props.currentPortfolio, this.props.graphSettings, null, this.props.inBitcoin);
              } else {

                this.props.componentDataFetch('update', this.props.currentView, this.props.currency_id, this.props.user_id, this.props.currentPortfolio, null, request_data, this.props.inBitcoin);
                
            }

            this.props.getImports(this.props.user_id)

        };

        ws.onclose = function () {
            // websocket is closed.
        };
    
    }

    handleChange = (type, value) => {

        this.setState(state => ({...state, selected: value, validation: {...state.validation, select: true}}))
        if (type === 'exchange' && value !== null) {
            const scopeData = this.props.connectors['exchange'].filter(v => v.exchange_id === value)
            this.setState(state => ({...state, scopeData: scopeData}))
        } else if (type === 'storage' && value !== null) {
            const scopeWallet = this.props.connectors['storage'].filter(v => v.coin_id === value)
            this.setState(state => ({...state, scopeData: scopeWallet}))
        }
        else {
            this.setState(state => ({...state, scopeData: undefined}))
        }

    }

    handleBack = () => {
        this.setState(state => ({
        activeStep: state.activeStep - 1,
        }));
    };

    getSteps() {
        return ['Select Account', 'Enter details'];
    }

    getStepContent(stepIndex) {
        switch (stepIndex) {
        case 0:
            return this.step1();
        case 1:
            return this.step2();
        default:
            return 'Unknown stepIndex';
        }
    }

    inputData = (e, label) => {
        e.persist();
        this.setState(state => ({...state, data: {...state.data, [label]: e.target.value}}))
    }

    selectExchange = (object, connection_type) => {
        if (connection_type === 'exchange') {
            this.setState(state => ({...state, selected: object, connection_type: connection_type, data: {...state.data, exchange_id: object.exchange_id}, activeStep: 1}))
        } else {
            this.setState(state => ({...state, selected: object, connection_type: connection_type, data: {...state.data, coin_id: object.coin_id}, activeStep: 1}))
        }
    }

    changeType = e => {
        e.persist();
        this.setState(state => ({...state, data: {...state.data, scope: e.target.value}}))
    }

    goToCoinbase = () => {

        this.setState(state => ({...state, loading: true}))
        const url = `${backendUrl}/api/oauth/coinbase/setup/`
        let headers = { 
            "content-type": "application/json",     
        };
        headers["Authorization"] = `Token ${this.props.token}`;

        let submitData = {
            ...this.state.data, 
            user_id: this.props.user_id, 

        }

        if ( submitData.scope === 'all' ) {
            submitData['portfolio'] = this.props.noPort === true ? this.state.portfolio : this.props.overridePort === true ? this.props.overridePortId : this.props.currentPortfolio
        }

        axios.post(
            url,
            submitData,
            {headers: headers}
        ).then(
            res => {

                const data = res.data.data
                const url = `https://www.coinbase.com/oauth/authorize?response_type=code&client_id=${cbKey}&redirect_uri=${backendUrl}/api/oauth/coinbase/success/&state=${data['account_id']}&account=all&scope=wallet:accounts:read,wallet:buys:read,wallet:deposits:read,wallet:sells:read,wallet:transactions:read,wallet:trades:read,wallet:withdrawals:read,wallet:addresses:read,wallet:payment-methods:read`
                //console.log(url)
                window.location.replace(url)
            }
        )

       

    }


    render () {
        const { classes } = this.props;
        const portId = this.props.overridePort === true ? this.props.overridePortId : this.props.currentPortfolio
        const portName = this.props.portfolioList.filter(v => v.id == portId)

        let portMessage = null;
        if (this.props.noPort === true) {
            portMessage = `Import all transactions to:`
        }
        else if (portName.length > 0) {
            portMessage = `Import all past and future transactions automatically to ${portName[0].name} (current portfolio)`
        } else {
            portMessage = `Import all transactions from this account to a single portfolio.`
        }

        return (
            
            <div>

            <div className={classes.container}>

            
            <React.Fragment>
                <div className={classes.subContainer}>

                    <div>
                        <div className="basier-p4-caps" style={{color: 'black', textTransform: 'uppercase', fontWeight: 900}}>
                            Connection type
                        </div>
                        <div style={{display: 'flex', alignItems: 'center'}}>

                        <div className={`${this.state['connection_type'] === 'exchange' ? 'toggle-button-active' : 'toggle-button'}`} onClick={() => this.updateState('connection_type', 'exchange')} 
                          style={{height: 40, width: 150, borderRadius: 35, marginRight: 12, marginTop: 12, cursor: 'pointer'}}>
                                Exchange
                        </div>

                        <div className={`${this.state['connection_type'] === 'storage' ? 'toggle-button-active' : 'toggle-button'}`} onClick={() => this.updateState('connection_type', 'storage')} 
                          style={{height: 40, width: 150, borderRadius: 35, marginRight: 12, marginTop: 12, cursor: 'pointer'}}>
                                Storage / Wallet
                        </div>

                        </div>
                    </div>

                    
                    <div>

                        <div>
                            <div style={{marginTop: 20,}}>
                                <div className="basier-p4-caps" style={{color: 'black', textTransform: 'uppercase', fontWeight: 900, marginBottom: 4, width: 320}}>
                                    Select {this.state.connection_type}
                                </div>
                                <div className={classes.fiatContainer}>
                                    <SingleSelectImage clearer={this.state.connection_type} options={this.state.connection_type === 'exchange' ? this.state.exchanges : this.state.connection_type === 'storage' ? this.state.storage : []} handleChange={(val) => this.handleChange(this.state.connection_type, val)} />
                                    {!this.state.validation.select && <div className="basier-p4" style={{width: 320, paddingLeft: 6, color: '#F9716B', marginTop: 2, fontWeight: 900}}>Cannot be empty</div>}
                                </div>
                            </div>
                        </div>

                        <div style={{marginTop: 24,}}>
                            <div className="basier-p4-caps" style={{color: 'black', textTransform: 'uppercase', fontWeight: 900, marginBottom: 4}}>
                                Account Name
                            </div>
                            <div>
                                <input style={{marginTop: 0}} onChange={(e) => this.updateData('name', e.target.value)} className={`light-input ${!this.state.validation.name ? 'error-input' : null} ${classes.input}`} value={this.state.data.name} 
                                />
                                <div className="basier-p4" style={{width: 320, paddingLeft: 6, color: '#F9716B', marginTop: 2, fontWeight: 900}}>{this.state.validation.name_message}</div>
                            </div>
                        </div>

                        <div style={{color: '#F9716B', marginTop: 24, fontStyle: 'italic', width: 320}} className="basier-p4">
                        {
                            specificMessageRenderer(this.state.selected)
                        }
                        </div>
                    </div>

                   
                </div>

                <div className={classes.subContainer}>

                    <div className={classes.secondSub}>

                        { 
                        (this.state.scopeData === undefined || this.state.scopeData[0] === undefined) ? null :
                        this.state.scopeData[0].exchange_id === 'coinbase' ? 
                            
                        null

                        :
                        
                        this.state.connection_type === 'exchange' && this.state.scopeData[0].fields[0].map( (val, key) => {

                            return (
                                <div  key={key} style={{marginBottom: 24}}>
                                    <div className="basier-p4-caps" style={{color: 'black', textTransform: 'uppercase', fontWeight: 900, marginBottom: 4, width: '100%'}}>
                                        {val[1]}
                                    </div>
                                    <input onChange={(e) => this.updateData(val[0], e.target.value)} type="text" className={this.state.error ? 'light-input error-input' : 'light-input'} style={{width: '100%'}} />
                                </div>
                                )
                            })
                        }

                        {this.state.connection_type === 'storage' && this.state.selected !== null && this.state.scopeData !== undefined && 
                            <div>
                                <div className="basier-p4-caps" style={{color: 'black', textTransform: 'uppercase', fontWeight: 900, marginBottom: 4, width: '100%'}}>
                                        Address
                                    </div>
                                <input onChange={(e) => this.updateData('address', e.target.value)} type="text" className={this.state.error ? 'light-input error-input' : 'light-input'} style={{width: '100%'}} />
                            </div>    
                        }

                        {this.state.selected !== null && this.state.scopeData !== undefined && 
                        <div style={{marginTop: 24}}> 

                                {this.props.noPort === true ? 

                                <RadioGroup
                                name="btcScope"
                                value={this.state.data.scope}
                                onChange={(e) => this.updateData('scope', e.target.value)}
                                //style={{display: 'inline-block'}}

                                >  
                                    <FormControlLabel value="choose" 
                                                    control={<Radio classes={{
                                                        root: classes.root,
                                                        checked: classes.checked
                                                    }}/>} 
                                                    label="Import transactions but let me select how to allocate transactions to portfolios." />

                                    
                                    <div style={{display: 'flex', alignItems: 'center'}}>

                                        <FormControlLabel value="all" 
                                            control={<Radio classes={{
                                                root: classes.root,
                                                checked: classes.checked
                                            }} />} 
                                        label={
                                            portMessage} />
                                        
                                        {this.state.data.scope === 'all' ?
                                        <div style={{width: 180}}>
                                        <PortfolioSelect valid={true} loading={false} updateServer={(data) => this.updatePort(data)} value={null} data={this.props.portfolioList} /> 
                                        </div>
                                        :
                                        null}
                                        

                                    </div>

                                    
                                </RadioGroup>
                                
                               

                                :
                                <RadioGroup
                                name="btcScope"
                                value={this.state.data.scope}
                                onChange={(e) => this.updateData('scope', e.target.value)}
                                //style={{display: 'inline-block'}}

                                >  

                                    <FormControlLabel value="all" 
                                            control={<Radio classes={{
                                                root: classes.root,
                                                checked: classes.checked
                                            }} />} 
                                        label={portMessage} />

                                    <FormControlLabel value="choose" 
                                                        control={<Radio classes={{
                                                            root: classes.root,
                                                            checked: classes.checked
                                                        }}/>} 
                                                        label="Import transactions but let me select how to allocate transactions to portfolios." />

                                    

                                </RadioGroup>
                                }

                            
                            
                            {this.props.scope === 'choose' &&
                                <div className="basier-p4" style={{fontStyle: 'italic', color: '#0078DB'}}>Once we have imported all your transactions you will be able to manually match transactions to portfolios</div>
                            }

                        </div>
                        }
                        {this.state.scopeData !== undefined && this.state.scopeData[0] !== undefined && this.state.scopeData[0].exchange_id == 'coinbase' && 
                        
                        <div style={{marginTop: 24}}>
                            <span style={{textAlign: 'justify'}}>
                                <span>We will now redirect you to Coinbase so that you can authorize Wisly. We will only request <span style={{fontWeight: 'bold'}}>READ ONLY ACCESS</span>. We will not be able to trade or move your funds. Once the process if complete, you will be redirected to Wisly and we will start importing your transactions.</span>
                            </span>

                            <div style={{marginTop: 24}}>
                                <div className='button-blue opacity-hover' style={{width: '100%', height: 50}} onClick={this.state.loading ? null : this.goToCoinbase}>
                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                        {this.state.loading && <img src={whiteLoader} alt="" style={{height: 20, width: 20, marginRight: 12}}/>}
                                        <div>Continue to</div>
                                        <img src={coinbaseWhite} style={{height: 22, marginLeft: 4}} alt="Coinbase" />
                                    </div>
                                </div>
                            </div>
                        </div>
                
                        }
                    
                    </div>

                </div>

            </React.Fragment>

            
            </div>
            
            <div className="basier-p4" style={{paddingLeft: 6, color: '#F9716B', fontWeight: 900, marginTop: 0, width: '100%', display: 'flex', justifyContent: 'center', textAlign: 'center'}}>{this.state.error}</div>
            
            <div style={{height: 100}}>
                    
                    <div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>

                        <div className='basier' onClick={this.handleClose} 
                            style={{height: 40, borderRadius: 35, marginRight: 12, marginTop: 24, cursor: 'pointer', color: 'white', backgroundColor: '#F9716B', width: 150, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            Cancel
                        </div>
                        
                        {getCBValue(this.state.scopeData, 0, 'exchange_id') == 'coinbase' ? null : 
                        <div className='button-blue opacity-hover' onClick={this.state.loading ? null : () => this.submitServer()} style={{height: 40, borderRadius: 35, marginLeft: 12, marginRight: 12, marginTop: 24, cursor: 'pointer'}}>
                            {this.state.loading ? 

                                <span>
                                    <img src={whiteLoader} alt="" style={{height: 20, width: 20, marginRight: 12}}/>
                                    <span>Connecting</span>
                                </span>
                            
                            :

                                <span>Submit</span>
                            
                            }
                        </div>
                        }

                    </div> 

            </div>


            </div>


        )
    }


}

const mapStateToProps = state => {
    return {
        token: state.auth.token,
        user_id: state.auth.user_id,
        connectors: state.appwide.connectors,
        inBitcoin: state.data.bitcoinPf, 
        currency_id: state.auth.currency.id, 
        currentPortfolio: state.data.menu_selected, 
        tasks: state.interactions.tasks,
        currentView: state.data.currentView, 
        graphSettings: state.data.globalBalance,
        portfolioList: state.auth.list_portfolios, 
        existingNames: state.appwide.accountNames
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getImports: (u) => dispatch(dataActions.getImports(u)), 
        getWallets: (user, t) => dispatch(appwideActions.getWallets(user, t)), 
        getUserLimits: (user) => dispatch(appwideActions.getUserLimits(user)), 
        setInteraction: (data) => dispatch(interActions.setInteraction(data)), 
        toggleModal: (data) => 
            dispatch(interActions.toggleModal(data)), 
        componentDataFetch: (scope, view, currency, user, portfolio, globalGraphData, portfolioGraphData, inBitcoin) =>
            dispatch(dataActions.componentDataFetch(scope, view, currency, user, portfolio, globalGraphData, portfolioGraphData, inBitcoin)), 
        
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ConnectAccount))
