import React from 'react';
import { withRouter } from 'react-router';

class RegisterGo extends React.PureComponent {

  constructor(props) {
    super(props);
    props.history.push('/')
  }

  render() {
    return(<div />)
  }
}

export default withRouter(RegisterGo)