import React from 'react';
import {withRouter} from 'react-router-dom'; /* MAY NEED TO REMOVE */
import { connect } from 'react-redux';
import Snackbar from './containers/AppWide/Snackbars'


class ErrorHoc extends React.Component {

    classes = {
      error: {
        backgroundColor: 'red'
      },
      info: {
        backgroundColor: 'gray'
      },
      message: {
        display: 'flex',
        alignItems: 'center',
      },
    }
  
    render() {
      return (
        <React.Fragment>
          {this.props.children}

          <div style={{position: 'absolute', left: 9, zIndex: 99999999
                    }}>
            <Snackbar show={this.props.snack.show} variant={this.props.snack.variant}>
                {this.props.snack.message}
            </Snackbar>

          </div>

        </React.Fragment>
      )
       
    }
  }
  


const mapStateToProps = state => {
  return {
      snack: state.interactions.snack,
  };
};

export default withRouter(connect(
  mapStateToProps,
  null
)(ErrorHoc));
