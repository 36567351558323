import React from 'react';
import Highcharts from 'highcharts/highstock'
import HeatMap from 'highcharts/modules/heatmap'
import HighchartsReact from 'highcharts-react-official'
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import * as actions from "../../../../../store/actions/auth";
import loadingSpinner from '../../../../../Media/loadingSpinner.gif'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
/* import HighchartsReact from 'highcharts-react-official'*/
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

HeatMap(Highcharts)
/* (function(H) {
    H.Pointer.prototype.reset = function() {
      return undefined;
    };
  
    H.Point.prototype.highlight = function(event) {
      event = this.series.chart.pointer.normalize(event);
      this.onMouseOver(); // Show the hover marker
      //this.series.chart.tooltip.refresh(this); // Show the tooltip
      this.series.chart.xAxis[0].drawCrosshair(event, this); // Show the crosshair
    };
  
    H.syncExtremes = function(e) {
      var thisChart = this.chart;
  
      if (e.trigger !== "syncExtremes") {
        // Prevent feedback loop
        Highcharts.each(Highcharts.charts, function(chart) {
          if (chart && chart !== thisChart) {
            if (chart.xAxis[0].setExtremes) {
              // It is null while updating
              chart.xAxis[0].setExtremes(e.min, e.max, undefined, false, {
                trigger: "syncExtremes"
              });
            }
          }
        });
      }
    };
  })(Highcharts); */

const styles = {
    root: {
      color: '#6A7E93',
      '&$checked': {
        color: '#0078DB',
      },
    },
    checked: {
        color: '#0078DB'
    },
  };

class BalanceGraph extends React.PureComponent {


    render () {
    
    const { classes } = this.props;

    const options = {

        chart: {
          type: 'heatmap',
          marginTop: 40,
          marginBottom: 80,
          height: 700,
          width: 700, 

        },
        credits: false, 
      
        title: false,
      
        xAxis: {
          categories: this.props.data.x_axis, 
          scrollbar: {
            enabled: true
          }, 
          startOnTick: false,
          endOnTick: false,
          min: 0, 
          max: 10, 
          opposite: true,
          tickLength: 0,
        },
      
        yAxis: {
          categories: this.props.data.y_axis,
          title: null,
          reversed: true, 
          min: 0, 
          max: 10, 
          scrollbar: {
            enabled: true
          },
          startOnTick: false,
          endOnTick: false,
        },
      
        colorAxis: {
          min: -1,
          max: 1,
          minColor: '#F9716B',
          maxColor: '#A2DEFD'
        },
      
        legend: {
          align: 'right',
          layout: 'vertical',
          margin: 0,
          verticalAlign: 'top',
          y: 25,
          symbolHeight: 280
        },
      
        tooltip: {
          formatter: function () {
            return '<b>' + this.series.xAxis.categories[this.point.x] + '/' + this.series.yAxis.categories[this.point.y] + '</b>';
          }
        },
      
        series: [{
          name: 'Pearson correlations',
          borderWidth: 1,
          data: this.props.data.values,
          dataLabels: {
            enabled: true,
            color: 'black',
            style: {
                textOutline: 'none',
            }
            
          }
        }], 

        plotOptions: {
            series: {
              dataLabels: {
                overflow: 'none',
                crop: true,
                enabled: true,
                style: {
                  fontWeight: 'normal'
                }
              }
            }
          },
      
      }  


        return (

            <div>
                    <Grid container 
                        spacing={0}>

                        <div id="correl_container" style={{width: '100%', marginLeft: -10, paddingRight: 36, position: 'relative'}}>
                        { ( this.props.loadedGraph === true) ?  <div className="loader-graph"><img style={{height: 64, with: 64}} src={loadingSpinner} alt="Loading..."/></div> : null }
                            
                        

                            <HighchartsReact
                                highcharts={Highcharts}
                                options={options}
                                oneToOne={true}
                            />

                           {/*  <HighchartsReact
                                highcharts={Highcharts}
                                constructorType={'stockChart'}
                                options={optionsBtc}
                                oneToOne={true}
                            /> */}

                        </div>

                    </Grid>

                </div>
            
            
        )
    }


        
};

BalanceGraph.propTypes = {
    classes: PropTypes.object.isRequired,
  };
  

const mapStateToProps = state => {
    return {
        currency_symbol: state.auth.currency.symbol,
        currency_name: state.auth.currency.id,
        currentView: state.data.currentView,
        inBitcoin: state.data.bitcoinPf

    };
};

const mapDispatchToProps = dispatch => {
    return {
        setBtcSymbol: (s) =>
            dispatch(actions.setBtcSymbol(s)), 
        
    };
  };

export default connect(
    mapStateToProps,
    mapDispatchToProps
)( withStyles(styles)(BalanceGraph));


