import React from "react";
import { connect } from 'react-redux';
import * as actions from "../../store/actions/data";
import * as interActions from "../../store/actions/interactions";
import axios from 'axios';
import { backendUrl } from '../../variables'
import Hidden from '@material-ui/core/Hidden';
import EditWatchListC from './TestDD'
import LargeWatchList from './LargeWatchlist'
import { DndProvider } from 'react-dnd'
import Backend from 'react-dnd-html5-backend'
import TouchBackend from 'react-dnd-touch-backend'
import Plus from '../../Media/Plus.svg'
import Move from '../../Media/move.svg'
import CoinSearch from '../AppWide/CoinSearch';
import SmallWatchList from './SmallWatchList';
import CoinList from '../MobileOnly/MobileCurrency'

// Import FilePond styles

class WalletOverview extends React.Component {

    state = {
        // Set initial files, type 'local' means this is a file
        // that has already been uploaded to the server (see docs)
        data: undefined, 
        edit: false,
        add: false,
        loading: false,
        coinModal: false,
    }

    handleInit() {
    }

    handleSocket(data) {

        

    }

    coinModalOpen = () => {
        this.setState(state => ({...state, coinModal: true}))
    }

    coinModalClose = () => {
        this.setState(state => ({...state, coinModal: false}))
    }

    editWatchList = (data) => new Promise ((resolve, reject) => {


        
    })
    

    openModal = (type, scope, add) => {

        this.props.toggleModal({type: type, open: true, payload: {scope: scope, context: add, data: null}})

    }

    showAdd = () => {

        this.setState(state => ({...state, add: true}))

    }

    closeAdd = () => {

        this.setState(state => ({...state, add: false}))

    }

    handleSelectCoin = (val) => {
        this.addCoin({value: val.id});
        this.coinModalClose()
    }

    addCoin = (coin) => {

        this.setState(state => ({...state, loading: true}))
        let payloadData = {
            user_id: this.props.user_id, 
            coin_id: coin.value
        }
        let headers = { "content-type": "application/json" };
            if (this.props.token !== null && this.props.token !== undefined) {

                headers['Authorization'] = `Token ${this.props.token}`

            }

        const url_call = `${backendUrl}/v1/coin_subs/`

        axios.post(url_call, payloadData, {headers: headers})
            .then(json => {
                this.setState(state => ({...state, data: json['data']['data'], loading: false}))
            })
            .catch( e => {

            })

      }

      addNewModal = (sub) => {

      }

      deleteRecord = (sub) => {

            let payloadData = {
                user_id: this.props.user_id, 
                id: sub
            }
            let headers = { "content-type": "application/json" };
                if (this.props.token !== null && this.props.token !== undefined) {

                    headers['Authorization'] = `Token ${this.props.token}`

                }

            const url_call = `${backendUrl}/v1/coin_subs/`

            axios.delete(url_call, {data : payloadData, headers: headers})
                .then(json => {
                    let newData = this.state.data.filter(v => v.id !== sub)
                    this.setState(state => ({...state, data: newData}))
                })
                .catch( e => {

                })
                

      }


    componentDidMount() {

        this.coinRef = React.createRef();

        this.props.setCurrentView(95);
        this.props.setMenuPortfolio(null);
        this.props.componentDataFetch('get', 95, null, this.props.user_id, null, null, null, null)
        this.setState(state => ({...state, data: this.props.subs}))
        
    }

    updateSubs = (list) => {
        this.setState(state => ({...state, data: list}))
    }

    componentDidUpdate(prevProps) {

        if (this.props.subs !== undefined && prevProps.subs !== this.props.subs) {
            this.setState(state => ({...state, data: this.props.subs}))
        }
       
    }

    toggleEdit = () => {
        this.setState(state => ({...state, edit: !state.edit}))
    }

    render() {

        return (

            this.state.data !== undefined && this.state.data !== null  ? 

            <div>

                <Hidden smDown>

                        <div style={{padding: 48}}>
                            <div style={{marginBottom: 40, width: '100%', display: 'flex', alignItems: 'flex-start'}}>
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <h3 style={{marginBottom: 6}}>Watchlist</h3>

                                    <span onClick={() => this.toggleEdit()} className="basier-p4-caps opacity-hover" style={{userSelect: 'none', color: 'black', marginLeft: 24, cursor: 'pointer'}}>
                                        <span><img src={Move} alt="" style={{marginRight: 12}}/></span>
                                        <span>{this.state.edit ? 'Done' : 'Edit order'}</span>
                                    </span> 

                                    <span onClick={() => this.showAdd()} className="basier-p4-caps opacity-hover" style={{userSelect: 'none', color: 'black', marginLeft: 24, cursor: 'pointer'}}>
                                        <span><img src={Plus} alt="" style={{marginRight: 12}}/></span>
                                        <span>Add coin</span>
                                    </span> 
                                    
                                    {
                                        this.state.add ? 
                                        <>
                                        <span className="basier-p4-caps" style={{userSelect: 'none', color: 'black', marginLeft: 24,}}>
                                            
                                            <CoinSearch 
                                                data_filter={this.props.all_coins}
                                                autoFocus={true}
                                                width={250}
                                                keepInput={false}
                                                overrideClass={true} 
                                                //updaterMethod={(key, value) => this.props.addCoin({key: this.props.transactionId, label: key, value: value})}
                                                handleSelect={(value) => this.addCoin(value)}
                                                masterClass="transaction-coin-search"/>
                                            

                                        </span> 

                                        <span onClick={() => this.closeAdd()} className="basier-p4-caps opacity-hover" style={{userSelect: 'none', color: 'black', marginLeft: 12, cursor: 'pointer'}}>
                                        <span>Close</span>

                                        </span> 
                                        </>

                                        :

                                        null
                                    }
                                </div>
                                <div style={{justifySelf: 'flex-end', marginLeft: 'auto', marginRight: 48}}>
                                </div>
                            </div>

                                {!this.state.edit ? 

                                <LargeWatchList loading={this.state.loading} subs={this.state.data} deleteRecord={(s) => this.deleteRecord(s)} />
                                
                                :

                                <DndProvider backend={Backend}>
                                    <EditWatchListC updateSubs={this.updateSubs} subs={this.state.data} token={this.props.token} user_id={this.props.user_id} scope="desktop" />
                                </DndProvider>
                                }
                     </div>

                </Hidden>

                <Hidden mdUp>
                    <div style={{marginBottom: 80}}>
                        <div style={{padding: 12}}>
                            <div style={{marginBottom: 24, width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <h3 style={{marginBottom: 6}}>Watchlist</h3>
                                </div>
                            </div>
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-around', marginBottom: 24}}>
                                <div  style={{paddingRight: 24, display: 'flex', alignItems: 'center',}} onClick={this.coinModalOpen}>
                                    <img src={Plus} style={{height: 30, width: 30}} alt=""/>
                                    <div className="basier" style={{fontSize: 16, textTransform: 'uppercase', marginLeft: 12}}>Add coin</div>
                                </div>

                                <div  style={{paddingRight: 24, display: 'flex', alignItems: 'center',}} onClick={() => this.toggleEdit()}>
                                    <img src={Move} style={{height: 30, width: 30}} alt=""/>
                                    <div className="basier" style={{fontSize: 16, textTransform: 'uppercase', marginLeft: 12}}>{!this.state.edit ? 'Edit' : 'Save'}</div>
                                </div>

                            </div>
                        </div>
                        {!this.state.edit ? 
                            <SmallWatchList subs={this.state.data} />
                            :
                            <DndProvider backend={TouchBackend}>
                                    <EditWatchListC deleteRecord={this.deleteRecord} updateSubs={this.updateSubs} subs={this.state.data} token={this.props.token} user_id={this.props.user_id} scope="mobile" />
                            </DndProvider>
                        }
                    </div>
                    <CoinList coins={this.props.all_coins} openModal={this.state.coinModal} closeModal={this.coinModalClose} handleSelect={(res) => this.handleSelectCoin(res)}/>
                </Hidden>

            </div>

            :

            null

        )
    }
}

const mapStateToProps = state => {
    return {
        subs: state.data.subscriptions, 
        user_id: state.auth.user_id, 
        token: state.auth.token,
        all_coins: state.appwide.crypto, 
    };
};

const mapDispatchToProps = dispatch => {
    return {
        componentDataFetch: (scope, view, currency, user, portfolio, globalGraphData, portfolioGraphData, inBitcoin) =>
            dispatch(actions.componentDataFetch(scope, view, currency, user, portfolio, globalGraphData, portfolioGraphData, inBitcoin)), 
        setMenuPortfolio: (path) => 
            dispatch(actions.setMenuPortfolio(path)), 
        setCurrentView: (view) => 
            dispatch(actions.setCurrentView(view)),
        toggleModal: (data) => 
            dispatch(interActions.toggleModal(data)), 
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(WalletOverview)
