import React from "react";
import currency_dict from "../../../../../helpers/currency.json";
import { getValue } from "../../../../../helpers/helpers";
import Tooltip from '../../../../AppWide/Tooltip';
import help from '../../../../../Media/help.svg';

const symbolGet = (dict, key) => {
  if (dict[key] !== undefined) {
    return dict[key]["symbol"];
  } else {
    return key;
  }
};

const collabCard = (props) => {
  let thisPort = []
  try {
    thisPort = props.list_portfolios.filter(v => v.id == props.portfolioId)
  } catch(e) {}
  let curr = "";
  if (thisPort && thisPort.length > 0) {
    curr = thisPort[0].currency
  }
  if (!curr) {
    curr = ""
  }
  return (
    <div
      onClick={(e) => props.setScopeUser(e, props.user_id)}
      className="hover-border-select"
      style={{
        boxShadow: "0px 10px 10px rgba(225, 229, 233, 0.3)",
        border: `${
          props.selectedUser === props.user_id ? "2px solid #0078DB" : "none"
        }`,
        borderRadius: 20,
        minWidth: 310,
        marginRight: 20,
        marginLeft: 20,
        paddingTop: 28,
        paddingBottom: 28,
        paddingLeft: 12,
        paddingRight: 12,
        marginBottom: 24,
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <span>
          <img
            style={{
              height: 35,
              width: 35,
              border: "2px solid white",
              borderRadius: "50%",
            }}
            src={`https://ui-avatars.com/api/?rounded=true&size=128&background=6A7E93&color=fffff&name=${props.user}`}
            alt=""
          />
        </span>
        <span className="basier-p2" style={{ marginLeft: 10, color: "black" }}>
          {props.user}
        </span>
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
          marginTop: 40,
          paddingRight: 6,
        }}
      >
        <div style={{ marginLeft: 6 }}>
          <div className="basier-p4-caps">Deposits</div>

          <div>
            {Object.keys(props.deposit["deposits"]).map(function (
              key,
              index
            ) {
              if (!key || key === "null") {
                  return
              }
              const income = getValue(props.deposit['income'], [key], 0)
              const deposit = getValue(props.deposit['deposits'], [key], 0)
              const net = deposit - income

              const incomeVal = getValue(props.deposit['income_value'], [key], 0)
              const depositVal = getValue(props.deposit['deposit_value'], [key], 0)
              const netVal = depositVal - incomeVal;

              if (net === 0) {
                return;
              }
              return (
                <div key={index}>
                  <div
                    className="basier-p3"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: 0,
                      paddingBottom: 0,
                      color: "black",
                      fontWeight: "bold",
                    }}
                  >
                    <span>
                      {props.deposit["images"][key] ? (
                        <img
                          src={props.deposit["images"][key]}
                          style={{ height: 12, width: 12, marginRight: 4, }}
                          alt=""
                        />
                      ) : (
                        symbolGet(currency_dict, key)
                      )}{" "}
                    </span>
                    <span>
                      {net.toLocaleString("en", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </span>
                    <Tooltip title={`${curr} ${netVal.toLocaleString('en', {minimumFractionDigits: 2, maximumFractionDigits: 2})}`}>
                      <div><img src={help} style={{height: 12, width: 12, marginLeft: 3}} alt="" /></div>
                    </Tooltip>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        {/* <div style={{ marginLeft: 64 }}>
          <div className="basier-p4-caps">All Income</div>

          <div>
            {Object.keys(props.deposit["income"]).map(function (key, index) {
              return (
                <div key={index}>
                  <div
                    className="basier-p3"
                    style={{
                      marginBottom: 0,
                      paddingBottom: 0,
                      color: "black",
                      fontWeight: "bold",
                    }}
                  >
                    <span>
                      {props.deposit["images"][key] ? (
                        <img
                          src={props.deposit["images"][key]}
                          style={{ height: 12, width: 12 }}
                          alt=""
                        />
                      ) : (
                        symbolGet(currency_dict, key)
                      )}{" "}
                    </span>
                    <span>
                      {props.deposit["income"][key].toLocaleString("en", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
        </div> */}

        <div style={{}}>
          <div className="basier-p4-caps">Transactions</div>

          <div>
            <div
              className="basier-p3"
              style={{
                marginBottom: 0,
                paddingBottom: 0,
                color: "black",
                fontWeight: "bold",
              }}
            >
              {props.transactions}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default collabCard;
