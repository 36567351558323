
export default () => {
	self.addEventListener('message', e => { // eslint-disable-line no-restricted-globals
		if (!e) return;

		let coin_in_scope = []

		const data = e.data[0]
        const coin_name = e.data[1]

		data.map((balance, index) => {

            if (balance.coin === coin_name) {
                coin_in_scope = balance 
            }
			

		})
		
		postMessage(coin_in_scope);

	})
}
