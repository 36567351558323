import React from "react";
import Selectable, { Creatable, components } from "react-select";
import { connect } from "react-redux";
import plusButton from "../../../../../../Media/Plus.svg";
import * as actions from "../../../../../../store/actions/interactions";
import { noAddErc20 } from "../../../../../../helpers/noERC20";

const optionDisplay = (account) => {
  return <div style={{ textAlign: "left" }}>{account.name}</div>;
};

const getData = (dict, val) => {
  let ret = undefined;
  try {
    ret = dict[val];
  } catch (e) {}
  return ret;
};

const ValueContainer = ({ children, ...props }) => {
  return (
    <components.ValueContainer {...props}>
      <div className="basier-p3">{children}</div>
    </components.ValueContainer>
  );
};

const menuHeaderStyle = {
  padding: "8px 12px",
  background: "white",
  color: "black",
  fontWeight: 900,
};

const MenuList = (props) => {
  return (
    <components.MenuList {...props}>
      <div className="basier-p4-caps" style={menuHeaderStyle}>
        Storage wallets
      </div>
      {props.children}
    </components.MenuList>
  );
};

let options = [];

class MenuSelector extends React.Component {
  state = {
    selectedOption: null,
  };

  hydrateWallets() {
    let accounts = null;
    let displayErc20 = [];
    const allAccounts = Object.values(this.props.accounts);
    if (Array.isArray(allAccounts) || this.props.allCoins === true) {
      accounts = allAccounts.flat();
    } else {
      accounts = this.props.accounts[getData(this.props.coin, "value")];
      const needErc20 = !noAddErc20.includes(getData(this.props.coin, "value"));
      const accountsErc20 = this.props.accounts["ethereum"];
      if (accountsErc20 !== undefined) {
        const jsonVer = JSON.stringify(accountsErc20);
        displayErc20 = JSON.parse(jsonVer);
      }

      displayErc20 = displayErc20.map((val) => {
        val.name = `${val.name} (ERC20)`;
        return val;
      });

      if (
        needErc20 &&
        accounts !== undefined &&
        accounts !== null &&
        noAddErc20 !== null &&
        noAddErc20 !== undefined
      ) {
        accounts = [...accounts, ...displayErc20];
      } else if (needErc20 && noAddErc20 !== null && noAddErc20 !== undefined) {
        accounts = displayErc20;
      }
    }

    options = [];

    if (accounts !== undefined) {
      accounts.map((value) => {
        let values = {
          id: value["id"],
          value: value["name"],
          label: optionDisplay(value),
        };
        options.push(values);

        if (this.props.wallet !== null && this.props.wallet !== undefined) {
          if (value["id"] == this.props.wallet) {
            this.setState((state) => ({ ...state, selectedOption: values }));
            this.props.updateTransaction({
              key: this.props.transactionId,
              label: "wallet",
              value: values.id,
            });
            this.props.updateTransaction("storage", values.id);
            this.props.updateTransaction("storage_name", null);
          }
        } else {
          if (accounts.length === 1) {
            this.setState((state) => ({ ...state, selectedOption: values }));
            this.props.updateTransaction({
              key: this.props.transactionId,
              label: "wallet",
              value: values.id,
            });
            this.props.updateTransaction("storage", values.id);
            this.props.updateTransaction("storage_name", null);
          }
        }
      });
    }

    this.setState((state) => ({ ...state, options: options }));
  }

  componentDidMount() {
    this.hydrateWallets();
  }

  colourStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "transparent",
      width: "250px",
      height: this.props.height !== undefined ? this.props.height : 42,
      fontSize: 16,
      border: "0px",
      color: "black",
      cursor: "text",
      dispay: "inline-block",
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? null
          : isSelected
          ? "white"
          : isFocused
          ? "#F3F5F6"
          : null,
        color: isDisabled ? "#F3F5F6" : isSelected ? "black" : "black",
        opacity: isDisabled ? "1" : isSelected ? "1" : "0.7",
      };
    },
  };

  componentDidUpdate(prevProps) {
    if (prevProps.coin !== this.props.coin) {
      this.hydrateWallets();
    }

    if (prevProps.accounts !== this.props.accounts) {
      this.hydrateWallets();
    }
  }

  handleClear = () => {
    this.setState((state) => ({ ...state, selectedOption: null }));
    this.props.updateTransaction("storage", -1);
    this.props.updateTransaction("storage_name", null);
    //this.props.removeWallets()
  };
  handleChange = (selectedOption) => {
    if (selectedOption.hasOwnProperty("__isNew__")) {
      if (selectedOption["__isNew__"] === true) {
        this.setState((state) => ({
          ...state,
          selectedOption: selectedOption,
        }));
        this.props.updateTransaction("storage", -1);
        this.props.updateTransaction("storage_name", selectedOption.value);
      }
    } else {
      this.setState((state) => ({ ...state, selectedOption: selectedOption }));
      this.props.updateTransaction("storage", selectedOption.id);
      this.props.updateTransaction("storage_name", null);
    }
  };

  getFocusedOption() {
    let selectedData = undefined;
    try {
      selectedData = this.myInput.select.select.state.focusedOption;
    } catch (e) {}
    return selectedData;
  }

  onKeyDown = (e) => {
    if (this.props.propagateKey === true) {
      if (
        e.key === "Tab" ||
        e.key === "Enter" ||
        e.keyCode === 13 ||
        e.keyCode === 9
      ) {
        e.preventDefault();

        const isShift = !!e.shiftKey;
        if (isShift) {
          this.props.backToPrevious();
        } else {
          const data = this.getFocusedOption();
          if (data !== undefined) {
            this.handleChange(data);
          }
          this.props.getNextRef();
        }
      }
    }
  };

  render() {
    const { selectedOption } = this.state;

    return (
      <div>
        {this.props.allCoins ? (
          <Selectable
            value={selectedOption}
            onChange={this.handleChange}
            onMenuOpen={() => this.handleClear()}
            options={this.state.options}
            isSearchable={true}
            styles={this.colourStyles}
            ref={(ip) => (this.myInput = ip)}
            //innerRef={this.props.innerRef}
            onKeyDown={this.onKeyDown}
            maxMenuHeight={210}
            minMenuHeight={50}
            components={{ ValueContainer, MenuList }}
            classNamePrefix="react-select-user"
            placeholder="Select or type new..."
            theme={(theme) => ({
              ...theme,
              borderRadius: 0,
              colors: {
                ...theme.colors,
                primary25: "#F3F5F6",
                primary: "#F3F5F6",
              },
            })}
          />
        ) : (
          <Creatable
            value={selectedOption}
            onChange={this.handleChange}
            onMenuOpen={() => this.handleClear()}
            options={this.state.options}
            isSearchable={true}
            styles={this.colourStyles}
            ref={(ip) => (this.myInput = ip)}
            //innerRef={this.props.innerRef}
            onKeyDown={this.onKeyDown}
            maxMenuHeight={210}
            minMenuHeight={50}
            components={{ ValueContainer, MenuList }}
            classNamePrefix="react-select-user"
            placeholder="Select or type new..."
            theme={(theme) => ({
              ...theme,
              borderRadius: 0,
              colors: {
                ...theme.colors,
                primary25: "#F3F5F6",
                primary: "#F3F5F6",
              },
            })}
          />
        )}
      </div>
    );
  }
}

export default MenuSelector;
