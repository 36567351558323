import React from "react";
import { FixedSizeList as List } from "react-window";
import Plus from '../../../../../Media/Plus.svg'
import contentLoader from '../../../../../Media/contentLoader.svg'
import trIcon from '../../../../../Media/trIcon.svg'
import {siwtchTransactionType, formattedNumber} from '../../../../../helpers/helpers'
import SmallTransactionEditDetail from './SmallTransactionEditDetail'
import { withRouter } from "react-router-dom";
//import queryString from 'query-string';
import { connect } from 'react-redux';
import SmallTableBody from './SmallTransactionTable';
import SmallPositionList from './SmallPositionList'

const transactionFilters = {
  base_id: 'all', 
  quote_id: "all",
  date: 'all',
  user_id: "all", 
  transaction_description: 'all', 
}

const positionFilters = {
  base_id: 'all', 
  exchange_id: 'all', 
  position_id: "all",
  date: 'all',
  user_id: "all", 
}

class SmallTransactionOverview extends React.PureComponent {

      state = {
        page: 0,
        total: 0,
        selectAll: false, 
        checked: {},
        data: null,
        filteredData: null, 
        sort: 'desc',
        filters: this.props.scope === 'transaction' ? transactionFilters : positionFilters,
        filterValues: {}
    }

    state = {
        data: undefined, 
        filterValues: {}, 
        selectAll: false, 
        checked: {},
    }

    componentDidMount() {
        this.props.setCurrentView(5)

        const data = this.props.scope === 'transaction' ? this.props.transactions : this.props.positions
        this.setState(state => ({...state, data: data}))

        if (data !== undefined && data !== null && data['filters'] !== undefined && data['filters'] !== null  ) { 
            
            let filterTypes = [];
            let filterExchanges = undefined;
            let filterQuotes = undefined;
            let filterPositions = undefined;

            if (this.props.scope === 'transaction') {

                filterQuotes = data['filters']['quote_id']

                data['filters']['types'].map(v => {
                    filterTypes.push([v, siwtchTransactionType(v)[0]])
                })

               
            } else {
                filterExchanges = data['filters']['exchange']
                filterPositions = data['filters']['positions']
            }

            this.setState(state => ({
                ...state, 
                filterValues: {
                    ...state.filterValues, 
                    base_id: data['filters']['base_id'],
                    quote_id: filterQuotes,
                    user_id: data['filters']['user_id'],
                    transaction_description: filterTypes,
                    position_id: filterExchanges,
                    exchange_id: filterPositions,
                }

            })
            )
        }

    }

    refreshAddData = () => {
        
    }

    componentDidUpdate(prevProps) {

        if ( (prevProps.transactions !== this.props.transactions) || (prevProps.positions !== this.props.positions) || (this.props.scope !== prevProps.scope) ) {

            const data = this.props.scope === 'transaction' ? this.props.transactions : this.props.positions
            this.setState(state => ({...state, data: data}))

            if (data !== undefined && data !== null && data['filters'] !== undefined && data['filters'] !== null ) {    

                let filterTypes = [];
                let filterExchanges = undefined;
                let filterQuotes = undefined;
                let filterPositions = undefined;

                if (this.props.scope === 'transaction') {

                    data['filters']['types'].map(v => {
                        filterTypes.push([v, siwtchTransactionType(v)[0]])
                    })

                    filterQuotes = data['filters']['quote_id']
                
                } else {
                    filterExchanges = data['filters']['exchange']
                    filterPositions = data['filters']['positions']
                }

                this.setState(state => ({
                    ...state, 
                    filterValues: {
                        ...state.filterValues, 
                        base_id: data['filters']['base_id'],
                        quote_id: filterQuotes,
                        user_id: data['filters']['user_id'],
                        transaction_description: filterTypes,
                        position_id: filterPositions,
                        exchange_id: filterExchanges,
                    }

                })
                )
            }

        }
        
    }

    componentWillUnmount() {
        document.body.ontouchmove = (e) => { 
            return true;
        }
      }



      seeDetail = (itemInfo) => {

          /* this.props.history.push({pathname: '/transaction_detail', 
                                   state: { item: itemInfo, portfolioId: this.props.portfolioId}}
                                  ) */
                                  
          this.setState(state => ( {...state, detail: true, selectedItem:itemInfo}))

      }

      deleteDeltail = () => {
        
        this.setState(state => ( {...state, detail: false, selectedItem:null}))
        this.props.setCurrentView(5)

      }

      render() {
        
        return (

          this.state.data !== null && this.state.data !== undefined ?

            this.state.detail ? 

            <div style={{height: '100%'}}>
              
              <SmallTransactionEditDetail scope={this.props.scope} item={this.state.selectedItem} goBack={this.deleteDeltail} edit={false} getData={() => this.props.getTransaction()} />

            </div>

            :

            <div style={{height: '100%'}}>

                <div style={{height: 120, display: 'flex', alignItems: 'center', justifyContent: 'space-between', zIndex: 900}}>
                    <div style={{paddingLeft: 24}}>
                        {this.props.scope === 'transaction' ? 

                            this.props.transactions !== undefined && this.props.transactions !== null &&

                                this.props.transactions.total !== null && this.props.transactions.total !== undefined && 

                                <div>
                                    <h5>{formattedNumber(this.props.transactions.total, 0)}</h5>
                                    <div className="basier-p4-caps">Transactions</div>
                                </div>

                        :

                            this.props.positions !== undefined && this.props.positions !== null &&

                                this.props.positions.total !== null && this.props.positions.total !== undefined && 

                                <div>
                                    <h5>{formattedNumber(this.props.positions.total, 0)}</h5>
                                    <div className="basier-p4-caps">Positions</div>
                                </div>
                        
                        
                        }
                    </div>

                    <div  style={{paddingRight: 24, display: 'flex', alignItems: 'center'}} onClick={this.props.addNew}>
                      <img src={Plus} style={{height: 50, width: 50}} alt=""/>
                      <div className="basier" style={{fontSize: 16, textTransform: 'uppercase', marginLeft: 12}}>Add</div>
                    </div>
                </div>

                <div>
                  
                  <div>
                    
                    {
                    
                    this.props.scope === 'transaction' ? 

                        this.props.transactions !== undefined && this.props.transactions !== null ? 

                            this.props.transactions.total === 0 ? 
                        
                            <div style={{height: 280, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 12}}>
                                <div style={{border: '1px solid #CCCCCC', borderRadius: 20, height: '100%', width: '100%', backgroundColor: 'rgba(255, 255, 255, 0.5)', display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', padding: 24, flexDirection: 'column', boxShadow: '0px 10px 10px rgba(225, 229, 233, 0.3)',}}>
                                    <div>
                                        <img src={trIcon} alt="" style={{height: 80}} />
                                    </div>
                                    <div className="basier-p2" style={{marginTop: 24}}>
                                        <div>You do not have any transactions in your portfolios. Once you add transactions you will see them here.</div>
                                    </div>
                                </div>
                            </div>

                            :

                            <SmallTableBody 
                                scope={this.props.scope} getTransactions={(page) => this.props.movePage(page)} total={this.state.data['total']} page={this.props.page} getOverallData={this.props.getOverallData} data={this.state.data['transactions']} number_rows={10} allSelected={this.state.selectAll} selectAll={this.selectAll} selectSingle={this.selectSingle} selected={this.state.checked} selectTransaction={this.selectTransaction} movePage={this.props.movePage}
                                seeDetail={this.seeDetail}
                            />

                        :

                        <div style={{marginTop: 48, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}> 
                            <div><img src={contentLoader} alt="" style={{height: 120, width: 120}} /></div>
                            <div>Loading</div>
                        </div>

                    : 
                        this.props.positions !== undefined && this.props.positions !== null ? 

                            this.props.positions.total === 0 ? 
                        
                                <div style={{height: 280, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 12}}>
                                    <div style={{border: '1px solid #CCCCCC', borderRadius: 20, height: '100%', width: '100%', backgroundColor: 'rgba(255, 255, 255, 0.5)', display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', padding: 24, flexDirection: 'column', boxShadow: '0px 10px 10px rgba(225, 229, 233, 0.3)',}}>
                                        <div>
                                            <img src={trIcon} alt="" style={{height: 80}} />
                                        </div>
                                        <div className="basier-p2" style={{marginTop: 24}}>
                                            <div>You do not have any position in your portfolios. Once you add positions, you will see them here.</div>
                                        </div>
                                    </div>
                                </div>

                            :

                                <SmallPositionList 
                                    scope={this.props.scope} getTransactions={(page) => this.props.movePage(page)} total={this.state.data['total']} page={this.props.page} getOverallData={this.props.getOverallData} data={this.state.data['transactions']} number_rows={10} allSelected={this.state.selectAll} selectAll={this.selectAll} selectSingle={this.selectSingle} selected={this.state.checked} selectTransaction={this.selectTransaction} movePage={this.props.movePage}
                                    seeDetail={this.seeDetail}
                                />
                            
                        :
                        
                        <div style={{marginTop: 48, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}> 
                            <div><img src={contentLoader} alt="" style={{height: 120, width: 120}} /></div>
                            <div>Loading</div>
                        </div>

                    }
                
                  </div>

                    </div>
            </div>

          :

          <div style={{marginTop: 48, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}> 
                <div><img src={contentLoader} alt="" style={{height: 120, width: 120}} /></div>
                <div>Loading</div>
            </div>

        )
      }

}

const mapStateToProps = state => {
  return {
        transactions: state.data.transactions,
        positions:  state.data.position_entries,
        transactionFilter: state.interactions.transactionFilter, 
        positionFilter: state.interactions.positionFilter, 
        isLoading: state.loading.GET_TRANSACTION

  };
};

const mapDispatchToProps = dispatch => {
};

export default connect(mapStateToProps, null)(withRouter(SmallTransactionOverview));
