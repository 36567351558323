import React from 'react';
import Highcharts from 'highcharts/highstock'
import Exporter from 'highcharts/modules/exporting'
import HighchartsReact from 'highcharts-react-official'
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import * as actions from "../../../../../store/actions/auth";
import contentLoader from '../../../../../Media/contentLoader.svg'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
/* import HighchartsReact from 'highcharts-react-official'*/
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {nFormatter} from '../../../../../helpers/helpers'
import ParabolesDetail from './ParabolesDetail'
import CoinSearch from '../../../../AppWide/CoinSearch';

/* (function(H) {
    H.Pointer.prototype.reset = function() {
      return undefined;
    };
  
    H.Point.prototype.highlight = function(event) {
      event = this.series.chart.pointer.normalize(event);
      this.onMouseOver(); // Show the hover marker
      //this.series.chart.tooltip.refresh(this); // Show the tooltip
      this.series.chart.xAxis[0].drawCrosshair(event, this); // Show the crosshair
    };
  
    H.syncExtremes = function(e) {
      var thisChart = this.chart;
  
      if (e.trigger !== "syncExtremes") {
        // Prevent feedback loop
        Highcharts.each(Highcharts.charts, function(chart) {
          if (chart && chart !== thisChart) {
            if (chart.xAxis[0].setExtremes) {
              // It is null while updating
              chart.xAxis[0].setExtremes(e.min, e.max, undefined, false, {
                trigger: "syncExtremes"
              });
            }
          }
        });
      }
    };
  })(Highcharts); */

const styles = {
    root: {
      color: '#6A7E93',
      '&$checked': {
        color: '#0078DB',
      },
    },
    checked: {
        color: '#0078DB'
    },
  };


class BalanceGraph extends React.PureComponent {

    constructor(props) {

        super(props);
        this.state = {
            dateSelected: null,
            chart: null,
            data: null,
        }

    }

    addCoin = (value) => {
        this.setState(state => ({...state, selectedCoin: value}))
    }
    
    generateReport = () => {
        if (this.state.selectedCoin !== null && this.state.selectedCoin !== undefined) 
        {
            this.setState(state => ({...state, loading: true}))
            this.props.fetchAnalysis('paraboles', this.state.selectedCoin.value)
            .then(data => {
                this.setState(state => ({...state, data: data, loading: false}))
            })
            .catch(err => {
                this.setState(state => ({...state, error: true, loading: false}))
            })
        }
    }

    render () {

    
        return (
            <div style={{width: '100%', padding: 24}}>
                
                <div style={{display: 'flex', height: 120, width: '100%', alignItems: 'center'}}>
                    <div className="basier-p3">Select coin:</div>
                    <div style={{marginLeft: 12}}>
                        <CoinSearch 
                            data_filter={this.props.all_coins}
                            autoFocus={true}
                            width={250}
                            keepInput={true}
                            overrideClass={true} 
                            //updaterMethod={(key, value) => this.props.addCoin({key: this.props.transactionId, label: key, value: value})}
                            handleSelect={(value) => this.addCoin(value)}
                            masterClass="transaction-coin-search"/>
                    </div>
                    <div style={{marginLeft: 12, height: 40, cursor: 'pointer'}} className="button-blue opacity-hover" onClick={this.generateReport}>Generate</div>
                </div>

                {this.state.loading ?
                <div style={{display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
                    <img src={contentLoader} alt="" style={{height: 40}} />
                    <div className="basier-p2">Loading</div>
                </div>
                :
                this.state.data !== null ?
                <ParabolesDetail data={this.state.data} coinName={this.state.selectedCoin.coinName} />
                :
                null
                }

            </div>
            
        )
    }


        
};

BalanceGraph.propTypes = {
    classes: PropTypes.object.isRequired,
  };
  

const mapStateToProps = state => {
    return {
        currency_symbol: state.auth.currency.symbol,
        currency_name: state.auth.currency.id,
        currentView: state.data.currentView,
        inBitcoin: state.data.bitcoinPf, 
        all_coins: state.appwide.crypto,

    };
};

const mapDispatchToProps = dispatch => {
    return {
        setBtcSymbol: (s) =>
            dispatch(actions.setBtcSymbol(s)), 
        
    };
  };

export default connect(
    mapStateToProps,
    mapDispatchToProps
)( withStyles(styles)(BalanceGraph));


