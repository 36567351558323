import React from "react";
import { connect } from 'react-redux';
import * as actions from "../../store/actions/data";
import Hidden from '@material-ui/core/Hidden';
import LargeSettings from './LargeSettings'
import SmallSettings from './SmallSettings'

class Settings extends React.Component {



    constructor(props) {
        super(props);
        this.state = {
            currentView: this.props.match.params.setting 
        }
        
    }
    
    handleInit() {
    }

    handleSocket(data) {


    }

    componentDidMount() {

        this.props.setCurrentView(99);
        this.props.setMenuPortfolio(null);
    }

    componentDidUpdate(prevProps) {
        if (this.props.match.params.setting !== prevProps.match.params.setting) {
            this.setState({currentView: this.props.match.params.setting})
        }
    }

    render() {

        return (

            <div>

                <Hidden smDown>
                    <LargeSettings viewOverRide={this.state.currentView} />
                </Hidden>

                <Hidden mdUp>
                    <SmallSettings viewOverRide={this.state.currentView} />
                </Hidden>

            </div>
            

        )
    }
}

const mapStateToProps = state => {
    return {
        token: state.auth.token,
        portfolio_id: state.auth.default_portfolio_id,
        user_id: state.auth.user_id,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setMenuPortfolio: (path) => 
            dispatch(actions.setMenuPortfolio(path)), 
        setCurrentView: (view) => 
            dispatch(actions.setCurrentView(view)),
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(Settings)
