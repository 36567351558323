import React from 'react'; 
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import warningIcon from '../../../Media/warning.svg'
import Tooltip from '../../AppWide/Tooltip';

const Summary = props => {

    const downArrow = 
    (
    <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.3333 8.35083L6.68421 13.9999L1 8.35083" stroke="#F9716B" stroke-width="2" stroke-miterlimit="10"/>
    <path d="M6.68457 14V0" stroke="#F9716B" stroke-width="2" stroke-miterlimit="10"/>
    </svg>
    )

    const upArrow = 
    (
    <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.999933 7.50329L6.64905 1.85418L12.3333 7.5033" stroke="#4CDAC1" stroke-width="2" stroke-miterlimit="10"/>
    <path d="M6.64868 1.85414L6.64868 15.8541" stroke="#4CDAC1" stroke-width="2" stroke-miterlimit="10"/>
    </svg>
    )
    

    return (

        props.data[props.portfolioId] !== undefined ? 

            <Grid container 
            spacing={0}
            justify="space-between"
            alignItems="center">

            <Grid item>
                <p className="basier-p2" style={{marginBottom: 6, color: 'black'}}>Investment</p>
                <div style={{display: 'flex', alignItems: 'baseline'}}>
                <h2 style={{marginBottom: 0, marginTop:0, color: 'black'}}>
                    {props.currency_symbol}&thinsp;
                    {Math.max(props.data[props.portfolioId].deposit_check, 0).toLocaleString('en', {minimumFractionDigits:2, maximumFractionDigits: 2})}
                </h2>
                {props.data[props.portfolioId].deposit_check <= 0 &&
                <div style={{marginLeft: 6}}>(Fully recouped)</div>}
                </div>
                <p className="basier-p2" style={{marginBottom: 6, marginTop:12, color: '#6A7E93'}}>Total in {props.inBitcoin === 'bitcoin' ? 'BTC' : props.currency_name}</p>
            </Grid>

            <Grid item>
                <p className="basier-p2" style={{marginBottom: 6, color: 'black'}}>Profit to date</p>
                <div style={{display: 'flex', alignItems: 'center'}}>

                {
                    props.data[props.portfolioId].net_profit >= 0 ? 
                    <div className="arrow-container-large">{upArrow}</div>
                    : 
                    <div className="arrow-container-large">{downArrow}</div>

                }

            
                <span style={{display: 'inline-block'}}>
                    <h2 style={{marginBottom: 0, marginTop:0, color: 'black'}}>
                        <span>{props.currency_symbol}&thinsp;</span>
                        <span>{props.data[props.portfolioId].net_profit.toLocaleString('en', {minimumFractionDigits:2, maximumFractionDigits: 2})}</span>
                    </h2>
                </span>
                </div>
                <div style={{display: 'flex', alignItems: 'center'}}>
                {props.data[props.portfolioId].percent_change === 'TBD' ? 
                <React.Fragment>
                    <div className={`basier-p2`} style={{marginBottom: 6, marginTop:12}}>
                        N/A
                    </div>
                    <Tooltip title="Unable to calculate a meaningful profit percentage, as there are too many errors in transaction entries. Please go to your portfolio settings to correct the errors." placement="right">
                        <img src={warningIcon} alt="" style={{height: 20, marginLeft: 12}} />
                    </Tooltip>
                </React.Fragment>
                :
                <React.Fragment>
                    <div className={`basier-p2 ${props.data[props.portfolioId].net_profit >= 0 ? 'growth' : 'decline'}`} style={{marginBottom: 6, marginTop:12}}>
                        {
                        props.data[props.portfolioId].deposit_check <= 0 ? "-" : 
                        
                        `${((props.data[props.portfolioId].percent_change) * 100).toLocaleString('en', {minimumFractionDigits:2, maximumFractionDigits: 2}) }%`}
                    </div>
                    {props.data[props.portfolioId].material_errors > 0 ?
                    <Tooltip title="There are some errors in your transaction entries that may result in incorrect profit and investment calculations. Please review errors in your portfolio settings." placement="right">
                        <img src={warningIcon} alt="" style={{height: 20, marginLeft: 12}} />
                    </Tooltip>
                    :
                    null}
                </React.Fragment>
                }
                </div>
            </Grid>



            <Grid item>
                <p className="basier-p2" style={{marginBottom: 6, color: 'black'}}>Number of transactions</p>
                <h2 style={{marginBottom: 0, marginTop:0, color: 'black'}}>
                    {props.data[props.portfolioId].number_transactions}
                </h2>
                <p className="basier-p2" style={{marginBottom: 6, marginTop:12, color: '#6A7E93'}}>{`${props.data[props.portfolioId].recent_transactions} in the last month`}</p>
            </Grid>
                
            </Grid>

        : 

            <div style={{height: 112}}></div> 
        

    )
}


const mapStateToProps = state => {
    return {
        currency_symbol: state.auth.currency.symbol,
        currency_name: state.auth.currency.id,
        inBitcoin: state.data.bitcoinPf, 
    };
};

export default connect(
    mapStateToProps,
    null
)(Summary);