import React from 'react';
import { connect } from 'react-redux';
import Moment from 'moment'
import { formattedNumber } from '../../../helpers/helpers';
import ChevronRight from '@material-ui/icons/ChevronRight';
import contentLoader from "../../../Media/contentLoader.svg";
import { Hidden, Link } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom'

const PortfolioLine = (props) => {
  return (
    <Link component={RouterLink} to={`/portfolio/${props.id}`}>
    <div style={{height: 60, display: 'flex', alignItems: 'center', justifyContent: 'space-between', borderBottom: '1px solid #E1E5E94D', cursor: 'pointer'}} className="light-hover">
      {props.children}
    </div>
    </Link>
  )
}
class PortfolioSection extends React.PureComponent {
    
  componentDidMount() {}

  render () {
    const {portfolios} = this.props;
    return(
      portfolios &&
      <div>
        {portfolios.map((v, k) => {
          let valueDict;
          try {
            valueDict = this.props.singleBalances[v['id']]
          } catch(e) {}
          let change;
          if (valueDict !== undefined) {
            try {

              change = parseFloat(valueDict.change)
              if (isNaN(change)) {
                change = 0
              }

            } catch(e) {}
          }

          return (

            <PortfolioLine key={k} id={v.id}>
              <div>
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <div style={{color: 'black', textTransform: 'uppercase', fontWeight: 'bold'}}>
                    {v.name}
                  </div>
                  {this.props.roles[v['id']] === "observer" && 
                    <div className="basier-p5" style={{color: "#6A7E93", marginLeft: 12, padding: '2px 6px', backgroundColor: "#CCEDFE", display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: 5}}>
                      auditor
                    </div>
                  }
                </div>
                <Hidden smDown>
                  <div style={{color: '#6A7E93'}} className="basier-p4">
                    Last updated: {Moment(v.last_updated).format("YYYY-MM-DD | HH:mm")}
                  </div>
                </Hidden>
              </div>
              <div style={{display: 'flex', alignItems: 'center'}}>
                {this.props.singleBalances === null ? <img src={contentLoader} alt="" style={{height: 40}} /> :
                <div style={{display: 'flex', alignItems: 'flex-end', flexDirection: 'column'}}>
                  <div>{this.props.currency_symbol}{valueDict === undefined ? formattedNumber(0) : formattedNumber(valueDict.total_value + valueDict.position)}</div>
                  <Hidden smDown>
                    <div style={{color: '#6A7E93'}} className="basier-p4">{valueDict === undefined ? 
                      '-' 
                    : 
                      <span style={{color: "#6A7E93"}}><span style={{color: change > 0 ? '#4CDAC1' : change < 0 ? '#F9716B' : '#6A7E93', fontWeight: 'bold'}}>{formattedNumber(change * 100, 2)}%</span> since yesterday</span>
                    }
                    </div>
                  </Hidden>
                </div>}
                <div style={{width: 36, display: 'flex', justifyContent: 'center'}}><ChevronRight /></div>
              </div>
            </PortfolioLine>
          )
        })}
      </div>
    )
  }
}


const mapStateToProps = state => {
  return {
      portfolios: state.auth.list_portfolios,
      singleBalances: state.data.singleBalances,
      nBictoin: state.data.bitcoinPf === 'bitcoin',
      bitcoinPf: state.data.bitcoinPf,
      currency_symbol: state.auth.currency.symbol,
      roles: state.auth.roles,
  };
};


export default connect(
  mapStateToProps,
  null
)(PortfolioSection);