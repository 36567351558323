import React from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import matchSorter from 'match-sorter';
import binSvg from '../../Media/bin.svg';
import restore from '../../Media/restore.svg';
import checkmark from '../../Media/hollowCheck.svg';
import * as actions from "../../store/actions/data";
import currencyDict from '../../helpers/currency.json';
import {siwtchTransactionType} from '../../helpers/helpers'
import PortfolioSelect from '../AppWide/TablePortfolioSelector';
import TypeSelector from '../AppWide/TableTypeSelector';
import axios from 'axios'; 
import { backendUrl } from '../../variables';


const get_transfer_match = (data) => {

    let match_id = null
    let del = false
    try {

        match_id = data['matched']
        del = data['deleted']


    } catch(e) {match_id = null}

    const matched = match_id === null ? false : del === false ? true : false
    return matched
}



class TransactionOverview extends React.PureComponent {

    state = {
        date: null,
        editPf: {}, 
        typeLoading: {}, 
        typeSuccess: {},
        portfolioLoading: {}, 
        portfolioSuccess: {},

    }
    componentDidMount() {
        //this.props.setCurrentView(5)
        //this.props.componentDataFetch('get', 5, null, null, this.props.portfolioId, null, null, this.props.inBitcoin)

    } 

    handleSingleCheckboxChange = () => {

    }

    handlePosition = () => {

    }

    updateServer = (method, lineId, data, scopeUpdate, entry_type) => {
        
        if (entry_type === 'position') {
            return this.handlePosition(method, lineId, data, scopeUpdate)
        }

        let headers = { "content-type": "application/json" };
        if (this.props.token !== null && this.props.token !== undefined) {

            headers['Authorization'] = `Token ${this.props.token}`

        }

        let url_call = `${backendUrl}/v1/connectors/transactions/${lineId}/`
        let sendData = undefined;
        if (method === 'patch' || method === 'post') {

            sendData = {
                ...data, 
                user_id: this.props.user_id
            }

        } else {
            url_call = `${backendUrl}/v1/connectors/transactions/${lineId}/?u=${this.props.user_id}`
        }
        
        if (scopeUpdate === 'transaction_description') {
            this.setState(state => ({...state, typeLoading: {...state.typeLoading, [lineId]: true}, typeSuccess: {...state.typeSuccess, [lineId]: false} }))
        } else if (scopeUpdate === 'portfolio') {
            this.setState(state => ({...state, portfolioLoading: {...state.portfolioLoading, [lineId]: true}, portfolioSuccess: {...state.portfolioSuccess, [lineId]: false} }))
        }

        axios({method: method, url: url_call, data: sendData, headers: headers})
            .then(json => {  
                
                let newImportedTr = []
                let oldPos = this.props.transactions.positions
                if ((method === 'patch' || method === 'post') && (scopeUpdate !== 'include')) {
                    this.props.transactions.transactions.map(v => {
                        if (v.id == lineId) {
                            newImportedTr.push(json['data']['data'])
                        } else {
                            newImportedTr.push(v)
                        }
                    })
                    this.props.updateImports({
                        transactions: newImportedTr, 
                        positions: oldPos
                    })
                   
                    if (scopeUpdate === 'transaction_description') {
                        this.setState(state => ({...state, typeLoading: {...state.typeLoading, [lineId]: false}, typeSuccess: {...state.typeSuccess, [lineId]: true} }))
                        setTimeout(() => {
                            this.setState(state => ({...state, typeSuccess: {...state.typeSuccess, [lineId]: false} }))
                        },1100)
                    } else if (scopeUpdate === 'portfolio') {
                        this.setState(state => ({...state, portfolioLoading: {...state.portfolioLoading, [lineId]: false}, portfolioSuccess: {...state.portfolioSuccess, [lineId]: true} }))
                        setTimeout(() => {
                            this.setState(state => ({...state, portfolioSuccess: {...state.portfolioSuccess, [lineId]: false} }))
                        },1100)

                    } 


                } else if ((method === 'patch' || method === 'post') && scopeUpdate === 'include') {

                    this.props.transactions.transactions.map(v => {
                        if (v.id == lineId) {
                            v.include = true
                            newImportedTr.push(v)
                        } else {
                            newImportedTr.push(v)
                        }
                    })
                    this.props.updateImports({
                        transactions: newImportedTr, 
                        positions: oldPos
                    })

                } else if (method === 'delete') {

                    this.props.transactions.transactions.map(v => {
                        if (v.id == lineId) {
                            v.include = false
                            v.related_transaction = null
                            newImportedTr.push(v)
                        } else {
                            newImportedTr.push(v)
                        }
                    })
                    
                    this.props.updateImports({
                        transactions: newImportedTr, 
                        positions: oldPos
                    })
                }

                this.props.getImports(this.props.user_id)
            
            })
            .catch(e => {
        })


    }

    handlePfEdit = (id) => {
        this.setState(state => ({...state, editPf: {...state.editPf, [id]: {edit: true, force: false}}}))
    }

    handlePfStopEdit = (id) => {
        if (this.state.editPf[id]['force'] === false) {
            this.setState(state => ({...state, editPf: {...state.editPf, [id]: {edit: false, force: false}}}))
        }
    }

    forceOpen = (id) => {
        this.setState(state => ({...state, editPf: {...state.editPf, [id]: {...state.editPf[id], force: true}}}))
    }

    stopForceOpen = (id) => {
        this.setState(state => ({...state, editPf: {...state.editPf, [id]: {...state.editPf[id], force: false}}}))
    }

    handleDelete = (id) => {
    }
 
    render() {

        const checkBox = [
            {
                Header: (
                    <input
                    type="checkbox"
                    onChange={() => this.props.handleSelectAll(this.props.data.filter(v => v.include === true))}
                    checked={this.props.selectAll}
                    />
                ),
                minWidth: 25,
                maxWidth: 25,
                Cell: row => (
                    <input
                    type="checkbox"
                    disabled={!row.original.include}
                    defaultChecked={this.props.checked[row.original.id]}
                    checked={this.props.checked[row.original.id]}
                    onChange={() => this.props.selectSingle(row.original.id, this.props.data.filter(v => v.include === true))}
                    />
                ),
                sortable: false,
                filterable: false
            },
        ]

        const firstColumns = [{ 
            Header: () =>  (
                <div className='basier-p4-caps' style={{paddingLeft: 12, fontWeight: 900, color: 'black'}}>Account</div>
            ),
            minWidth: 75,
            Cell: row => {
                return (
                <div>
                    <div className="basier-p4" style={{paddingLeft: 12, color: row.original.include ? 'rgba(0, 0, 0, 0.65)' : 'rgba(0, 0, 0, 0.25)'}}>
                    {row.original.related_type === 'exchange' ? row.original.account_name : row.original.wallet_name}
                    </div>  
                </div>
            )
            }
        },]

        const lightColumns=[
            { 
                id: 'date',
                accessor: 'date', 
                Header: () =>  (
                    <div className='basier-p4-caps' style={{paddingLeft: 12, fontWeight: 900, color: 'black'}}>Date</div>
                ),
                minWidth: 75,
                Cell: row => {
                    const language = navigator.language || 'en'
                    const options = {year: 'numeric', month: 'numeric', day: 'numeric' };
                    const parseDate = new Date(row.value);
                    const showDate = parseDate.toLocaleDateString(language,options)

                    return (
                    <div>
                        <div className="basier-p4" style={{paddingLeft: 12, color: row.original.include ? 'rgba(0, 0, 0, 0.65)' : 'rgba(0, 0, 0, 0.25)'}}>
                        {showDate}
                        </div>  
                    </div>
                )
                }
            },
            {   
                id: 'transaction_description',
                accessor: 'transaction_description', 
                Header: () =>  (
                    <div className='basier-p4-caps' style={{paddingLeft: 12, fontWeight: 900, color: 'black'}}>Type</div>
                ),
                minWidth: 75,
                Cell: row => {
                    
                    const matched = get_transfer_match(row.original)
                    return (
                        <div>
                            <div className="basier-p4" style={{paddingLeft: 12, color: row.original.include ? 'rgba(0, 0, 0, 0.65)' : 'rgba(0, 0, 0, 0.25)'}}>
                                {   
                                    (row.value !== 'buy' && row.value !== 'sell' && row.value !== 'short' && row.value !== 'long' ) && (!matched) ? 
                                        row.original.include ?
                                            row.original.base_currency !== null ? 
                                            <TypeSelector valid={this.state.typeSuccess[row.original.id]} loading={this.state.typeLoading[row.original.id]} updateServer={(data) => this.updateServer('patch', row.original.id, data, 'transaction_description', row.original.entry_type)} value={row.value} coinType={row.original.base_type}/>
                                            :
                                            <span style={{textTransform: 'uppercase'}}>
                                                {siwtchTransactionType(row.value)[0]}
                                            </span>
                                        :
                                            <span style={{textTransform: 'capitalize'}}>{siwtchTransactionType(row.value)[0]}</span>
                                    : 
                                        <span>
                                            {matched ?
                                                <span style={{color: '#4CDAC1', fontWeight: 'bold'}}>
                                                    Internal Transfer
                                                </span>
                                            
                                            :

                                                <span style={{textTransform: 'uppercase'}}>
                                                    {siwtchTransactionType(row.value)[0]}
                                                </span>
                                            
                                            }
                                        </span>
                                }
                            </div>  
                        </div>
                    )
                }
            },
            {   
                id: 'coins',
                Header: () =>  (
                    <div className='basier-p4-caps' style={{paddingLeft: 12, fontWeight: 900, color: 'black'}}>Coin</div>
                ),
                minWidth: 75,
                Cell: row => {
                    
                    
                    return (
                        <div>
                            <div style={{paddingLeft: 12, color: row.original.include ? 'rgba(0, 0, 0, 0.65)' : 'rgba(0, 0, 0, 0.25)'}}>
                                
                                {row.original.base_ticker !== null ? 
                                
                                    <span className="basier-p4-caps">{row.original.base_ticker}{row.original.quote_ticker !== null ? `/${row.original.quote_ticker}` : null}</span>
                                
                                    :

                                    <div>
                                        <div className="basier-p4" style={{color: 'red'}}>Unsupported coin</div>
                                        <div className="basier-p4-caps" style={{marginTop: 0}}>{row.original.text_name}</div>
                                    </div> 
                                
                                }

                            </div>  
                        </div>
                    )
                }
            },
            {   
                id: 'amount',
                accessor: 'amount', 
                Header: () =>  (
                    <div className='basier-p4-caps' style={{paddingLeft: 12, fontWeight: 900, color: 'black'}}>Amount</div>
                ),
                minWidth: 75,
                Cell: row => {
                    
                    
                    return (
                        <div>
                            <div className="basier-p4-caps" style={{paddingLeft: 12, color: row.original.include ? 'rgba(0, 0, 0, 0.65)' : 'rgba(0, 0, 0, 0.25)'}}>
                                {row.value}
                            </div>  
                        </div>
                    )
                }
            },
            {   
                id: 'unit_price',
                accessor: 'unit_price', 
                Header: () =>  (
                    <div className='basier-p4-caps' style={{paddingLeft: 12, fontWeight: 900, color: 'black'}}>Unit Price</div>
                ),
                minWidth: 75,
                Cell: row => {
                    return (
                        <div>
                            <div className="basier-p4-caps" style={{paddingLeft: 12, color: row.original.include ? 'rgba(0, 0, 0, 0.65)' : 'rgba(0, 0, 0, 0.25)'}}>
                                {
                                row.value !== null && row.value !== undefined?
                                    row.original.quote_type === 'fiat' ? 

                                    row.value.toLocaleString('en', {minimumFractionDigits: 2, maximumFractionDigits: 2})
                                
                                    : 

                                    row.value.toLocaleString('en', {minimumFractionDigits: 8, maximumFractionDigits: 8})
                                :
                                null
                                
                                }
                            </div>  
                        </div>
                    )
                }
            },
            {   id: 'portfolio',
                accessor: 'portfolio', 
                Header: () =>  (
                    <div className='basier-p4-caps' style={{textAlign: 'left', paddingLeft: 12, fontWeight: 900, color: 'black'}}>Portfolio</div>
                ),
                minWidth: 150,
                maxWidth: 150,
                Cell: row => {
                    return (
                        row.original.include ?
                        <div className='basier-p4' style={{paddingLeft: 12, width: 180}}>
                            <PortfolioSelect valid={this.state.portfolioSuccess[row.original.id]} loading={this.state.portfolioLoading[row.original.id]} updateServer={(data) => this.updateServer('post', row.original.id, data, 'portfolio', row.original.entry_type)} value={{portfolio: {id: row.original.portfolio, name: row.original.portfolio_name}}} data={this.props.portfolios} />    
                        </div>
                        :
                        null
                    
                    )
                }
            },
            {  
                id: 'include',
                accessor: 'include', 
                Header: () =>  (
                    <div className='basier-p4-caps' style={{textAlign: 'left', paddingLeft: 12}}></div>
                ),
                minWidth: 30,
                maxWidth: 30,
                Cell: row => {
                    return (

                        <div className="basier-p4-caps opacity-hover" style={{display: 'inline-flex', justifyContent: 'center', alignItems: 'center'}}>
                            {
                            row.original.base_currency === null ? 

                            null :
                            
                            row.value === true ? 
                                <img src={binSvg} className="opacity-hover"  alt="" style={{width: 30, cursor: 'pointer', paddingLeft: 0}} onClick={(data, callback) => this.updateServer('delete', row.original.id, data, callback, null, row.original.entry_type)}/>
                            :
                                <div onClick={() => this.updateServer('patch', row.original.id, {'restore': true}, 'include', row.original.entry_type)} value={row.value} data={this.props.portfolios}>
                                    <div style={{width: 30, display: 'flex', justifyContent: 'center'}}>
                                        <img src={restore} alt="" className="opacity-hover" style={{height: 20, cursor: 'pointer'}} />
                                    </div>
                                </div>
                            }
                        </div>
                    
                    )
                }
            }
            ]

            const okButton = [{  
                id: 'reviewed',
                accessor: 'reviewed', 
                Header: () =>  (
                    <div className='basier-p4-caps' style={{textAlign: 'left', paddingLeft: 12}}></div>
                ),
                minWidth: 40,
                maxWidth: 40,
                Cell: row => {
                    return (

                        <div className="basier-p4-caps opacity-hover" style={{textAlign: 'left', paddingLeft: 12, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            {row.value === true || row.original.base_currency === null ? 
                                null
                            :
                                <div style={{width: 40, display: 'flex', alignItems: 'center'}}>
                                    <div onClick={() => this.updateServer('patch', row.original.id, {reviewed: true}, 'reviewed', row.original.entry_type)} style={{width: 30, height: 30, backgroundColor: 'rgba(76, 218, 193, 1)', color: 'white', fontWeight: 900, display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: 10, cursor: 'pointer'}}>
                                        OK
                                    </div>
                                </div>
                            }
                        </div>
                    
                    )
                }
            }]

        
        const allColumns = this.props.global ? [...checkBox, ...firstColumns, ...lightColumns, ...okButton] : [...checkBox, ...lightColumns]

        return (

            <div style={{width: '100%', marginLeft: 'auto', marginRight: 'auto'}}>

                <ReactTable
                    
                    defaultPageSize={ this.props.global ? 12 : 5 }
                    //filterable
                    resizable={false}
                    defaultFilterMethod={(filter, row) =>
                    String(row[filter.id]) === filter.value}
                    showPageSizeOptions={false}
                    data={this.props.data}
                    columns={allColumns}
                    getProps={(state, rowInfo, column) => {
                        return {
                          style: {
                            border: '0px',
                          }
                        };
                      }}
                      getTrGroupProps={(state, rowInfo, column) => {
                        return {
                          style: {
                            border: '0px',
                          }
                        };
                    }}
                    getTheadFilterProps={(state, rowInfo, column) => {
                        return {
                          style: {
                            marginTop: 6,
                            border: 'none'
                          }
                        };
                      }}
                    getPaginationProps={(state, rowInfo, column) => {
                        return {
                          style: {
                           boxShadow: 'none',
                           border: 'none',
                           marginTop: 12,
                          }
                        };
                      }}
                    getTheadProps={(state, rowInfo, column) => {
                        return {
                          style: {
                            boxShadow: 'none', 
                          }
                        };
                      }}
                    getTdProps={(state, rowInfo, column) => {
                        return {
                          style: {
                            overflow: 'visible',
                            paddingLeft: 0, 
                            paddingRight: 0,
                          }
                        };
                      }}
                    getTheadThProps={(state, rowInfo, column) => {
                    return {
                        style: {
                            paddingLeft: 0, 
                            paddingRight: 0,
                        }
                    };
                    }}
                    getTbodyProps={(state, rowInfo, column) => {
                    return {
                        style: {
                            overflow: 'visible',   
                        
                        }
                    };
                    }}
                    getTableProps={(state, rowInfo, column) => {
                        return {
                            style: {
                            overflow: 'visible',   
                            }
                        };
                        }}
                    getTrProps={(state, rowInfo, column) => {
                        return {
                          style: {
                            display: 'flex', 
                            alignItems: 'center',
                            paddingBottom: 4,
                            paddingTop: 4,
                            height: 40,
                            
                            borderBottom: rowInfo !== undefined ? rowInfo.index !== this.props.data.length -1 ?  '1px solid #CCC' : null : null,
                            background: 'white'
                          }
                        };
                      }}
                />
            
            </div>

        )
    }
  
};

const mapStateToProps = state => {
    return {
        portfolios: state.auth.list_portfolios,
        user_id: state.auth.user_id, 
        token: state.auth.token,
        transactions: state.data.importedTransactions,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        updateImports: (data) => dispatch(actions.updateImports(data)), 
        getImports: (user) => dispatch(actions.getImports(user)), 
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TransactionOverview);