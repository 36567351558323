import React from 'react';
import { connect } from 'react-redux';

class Header extends React.PureComponent {

    render () {

        return (

            <div className="basier-p4-caps" style={{display: 'flex', width: '100%', alignItems: 'center'}}>

                <div style={{minWidth: 50, maxWidth: 50, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <input
                        type="checkbox"
                        className="regular-checkbox"
                        onChange={() => this.props.selectAll()}
                        checked={this.props.allSelected}
                        //defaultChecked={this.props.allSelected}
                        />
                </div>

                <div style={{width: 250, maxWidth: 250, minWidth: 250}}>
                    <div>Account / Date</div>
                </div>

                <div style={{minWidth: 200, flex: 1}}>
                    {this.props.scope === 'transaction' ? 'Transaction' : 'Side / Contract'}
                </div>

                <div style={{minWidth: 120, flex: 1}}>
                    {this.props.scope === 'transaction' ? 'Amount / Price' : 'Position Entries'}
                </div>

                <div style={{minWidth: 250, maxWidth: 250, flex: 1}}>
                    Import Status
                </div>

                {this.props.account === true ? null :
                <div style={{minWidth: 150, maxWidth: 150, flex: 1, justifySelf: 'flex-end', textAlign: 'right', paddingRight: 24}}>
                    Action
                </div>}


                
            </div>
            
        )
    }


}

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = dispatch => {
    return {
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(Header)
