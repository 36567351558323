import React, {Component} from 'react';
import Tooltip from "../../../../../AppWide/Tooltip";
import HelpIcon from '@material-ui/icons/Help';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

function formatNumber(data) {
    return data.toLocaleString('en', {minimumFractionDigits: 2, maximumFractionDigits: 6})
}

class LpQuoteBox extends Component {
    render() {
        const {coin} = this.props;
        const translator = this.props.data?.translator?.[coin]
        const initial_balance = this.props.data?.initial_token_balance?.[coin]
        const connected = this.props.data?.connected
        const expected_balance = this.props.data?.expected_token_balance?.[coin]
        const connected_balance = this.props.data?.connected_token_balance?.[coin]
        //const needsEstimate = Math.round((parseFloat(this.props.data?.initial_lp_balance) / parseFloat(this.props.data?.current_lp_balance)) * 1e8) / 1e8 !== 1
        const {needsEstimate} = this.props;
        const helperText = needsEstimate ?
            "Your liquidity token having been withdrawn, this is an estimate of what your liquidity position will be if you unstaked your LP Token."
            :
            "This represents your current balance, which may have changed since your initial liquidity deposits, due to the mechanics of the liquidity pool and AMM."
        return (
            <div style={{
                height: 132,
                marginLeft: 6,
                marginRight: 6,
                backgroundColor: '#F8F9FA',
                flex: 1,
                padding: 12,
                borderRadius: 5,
                border: '1px solid #E1E5E9'
            }}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <div>
                        {translator.image ?
                            <img src={translator.image} alt="" style={{height: 24, width: 24}}/>
                            :
                            <div style={{
                                height: 24,
                                width: 24,
                                borderRadius: '100%',
                                backgroundColor: '#F8F9FA',
                                border: '1px solid #6A7E93'
                            }}>
                                <div className="basier-p4" style={{color: 'black'}}>
                                    {translator.symbol.symbol.slice(0, 1)}
                                </div>
                            </div>
                        }
                    </div>
                    <div style={{color: 'black', fontWeight: 'bold', marginLeft: 12}} className="basier-p2">
                        {translator.symbol}
                    </div>
                </div>
                <div
                    style={{display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', marginTop: 12}}>
                    <div className="basier-p4" style={{
                        color: '#6A7E93',
                        marginTop: 6,
                    }}>
                        <div style={{textTransform: 'uppercase'}}>{expected_balance ? 'Initial' : 'Current'}</div>
                        <div className="poppins" style={styles.smallNumber}>
                            {formatNumber(initial_balance)}
                        </div>
                        <div className={"basier-p4"} style={{fontStyle: 'italic'}}>Based on deposits</div>
                    </div>
                    {expected_balance &&
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-end', textAlign: 'right'}}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <div className="basier-p4" style={{
                                color: '#6A7E93',
                                marginRight: 2,
                                textTransform: 'uppercase'
                            }}>{needsEstimate ? 'Estimated' : 'Current'}
                            </div>
                            {connected && <Tooltip
                                title={helperText}
                                placement="right">
                                <HelpIcon style={{fontSize: 14, color: '#6A7E93'}}/>
                            </Tooltip>}
                        </div>
                        <div className="poppins" style={styles.smallNumber}>{formatNumber(expected_balance)}</div>
                        <div className="basier-p4" style={{
                            textAlign: 'right',
                            display: 'flex',
                            alignItems: 'center',
                            fontWeight: 'bold',
                            color: expected_balance > initial_balance ? '#4CDAC1' : expected_balance < initial_balance ? '#F9716B' : '#6A7E93'
                        }}>
                            {expected_balance > initial_balance ? <ArrowDropUpIcon style={{fontSize: 14}}/> :
                                expected_balance < initial_balance ?
                                    <ArrowDropDownIcon style={{fontSize: 18}}/> : null}
                            <div>
                                {formatNumber(Math.abs(expected_balance - initial_balance).toLocaleString('en', {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 6
                                }))}
                            </div>
                        </div>
                    </div>
                    }
                </div>

            </div>
        );
    }

}

const styles = {
    smallNumber: {
        fontSize: 14,
        color: 'black'
    }
}

export default LpQuoteBox;