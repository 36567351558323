import React from "react";
import "./Login.css";
import VectorBG from "../../Media/LoginVectorFinal.svg";
import VectorSmall from "../../Media/MobileBk.svg";
import CenterVector from "../../Media/LoginCenterVector.svg";
import LoginImage from "../../Media/LoginIllu.svg";
import background from "../../Media/signupBk.jpg";
import whiteLogo from "../../Media/whiteLogo.svg";
import screens from "../../Media/screens.png";
import headerLanding from "../../Media/headerLanding.svg";

import Graph from "../../Media/colorGraph.svg";
import Globe from "../../Media/colorGlobe.svg";
import Secure from "../../Media/colorSecure.svg";
import Flex from "../../Media/colorFlex.svg";

import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import RegisterForm from "./RegisterForm";
import Logo from "../../Media/Logo.svg";
import { withStyles } from "@material-ui/core/styles";
import { Hidden } from "@material-ui/core";

const styles = (theme) => ({
  container: {
    flex: 1,
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  formContainer: {
    width: 500,
    marginLeft: "auto",
    marginRight: "auto",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      paddingLeft: 12,
      paddingRight: 12,
    },
  },
  adjustSize: {
    width: "40rem",
    [theme.breakpoints.down("md")]: {
      width: "70%",
      paddingLeft: "25%",
    },
  },
});

class loginLand extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.email = React.createRef();
    this.pw = React.createRef();
    this.state = {
      forgot: false,
    };
  }

  toggleForgot = () => {
    this.setState((state) => ({ ...state, forgot: !state.forgot }));
  };

  handleSubmit(event) {
    event.preventDefault();
  }

  componentDidMount() {
    document.body.style.backgroundColor = "white";
  }

  componentWillUnmount() {
    window.scrollTo(0, 0);
    document.body.style.backgroundColor = null;
  }

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <div
          style={{
            position: "absolute",
            top: "3rem",
            right: "7.5rem",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <img src={screens} alt="" className={classes.adjustSize} />
        </div>
        <div
          style={{
            display: "flex",
            height: "100vh",
            width: "100%",
            backgroundColor: '#0078DB',
          }}
        >
          <div
            style={{
              flex: 1,
              width: "100%",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                height: "15rem",
                minHeight: "6.25rem",
                width: "100%",
                backgroundColor: "#0078DB",
                backgroundImage: `url(${headerLanding})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                display: "flex",
              }}
            >
              <div style={{ paddingTop: 48, paddingLeft: 48 }}>
                <div style={{ marginTop: 0 }}>
                  <div
                    className="poppins"
                    style={{ fontSize: '2.25rem', color: "white" }}
                  >
                    Filter through the Noise,
                  </div>
                  <div
                    className="poppins"
                    style={{ fontSize: '2.25rem', color: "white" }}
                  >
                    Distill Insights.
                  </div>
                  <div className="basier" style={{ color: "white", fontSize: '1rem' }}>
                    Track and analyze your crypto investments, <b>Wisly</b>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                flex: 1,
                minHeight: "31.25rem",
                width: "100%",
                backgroundColor: "white",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div style={{ width: '40%', paddingLeft: '4rem', marginTop: '4rem', justifyContent: 'center'}}>
                <RegisterForm />
              </div>

              <div
                style={{
                  height: "17rem",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  paddingLeft: "3rem",
                  paddingRight: "3rem",
                  paddingTop: "3rem",
                  paddingBottom: '3rem'
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                    flexDirection: "column",
                    flex: 0.25,
                  }}
                >
                  <img src={Graph} style={{  height: '5rem'  }} alt="" />
                  <div style={{ marginLeft: '0.75rem', marginRight: '0.75rem' }}>
                    <div
                      className="poppins"
                      style={{ fontSize: '1rem', color: "black", marginTop: '1rem' }}
                    >
                      Advanced
                    </div>
                    <div
                      className="poppins"
                      style={{ fontSize: '1rem', color: "black" }}
                    >
                      Cryptocurrency Analytics
                    </div>
                    <div className="basier" style={{ color: "black", marginTop: '1rem', fontSize: '0.94rem' }}>
                      Access detailed and actionable insight on your investments and portfolios.
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                    flexDirection: "column",
                    flex: 0.25,
                  }}
                >
                  <img src={Globe} style={{ height: '5rem' }} alt="" />
                  <div style={{ marginLeft: '0.75rem', marginRight: '0.75rem' }}>
                    <div
                      className="poppins"
                      style={{ fontSize: '1rem', color: "black",  marginTop: '1rem', }}
                    >
                      All-Around
                    </div>
                    <div
                      className="poppins"
                      style={{ fontSize: '1rem', color: "black", }}
                    >
                      Crypto Market Coverage
                    </div>
                    <div className="basier" style={{ color: "black",  marginTop: '1rem', fontSize: '0.94rem'}}>
                      Track both your spot and futures investments from a single dashboard.
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                    flexDirection: "column",
                    flex: 0.25,
                  }}
                >
                  <img src={Flex} style={{  height: '5rem'  }} alt="" />
                  <div style={{ marginLeft: '0.75rem', marginRight: '0.75rem' }}>
                    <div
                      className="poppins"
                      style={{ fontSize: '1rem', color: "black", marginTop: '1rem' }}
                    >
                      Flexibility
                    </div>
                    <div
                      className="poppins"
                      style={{ fontSize: '1rem', color: "black", }}
                    >
                      When You're On The Move
                    </div>
                    <div className="basier" style={{ color: "black",marginTop: '1rem', fontSize: '0.94rem'}}>
                      Explore our full-fledged desktop Web-App or use our sleek mobile app.
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                    flexDirection: "column",
                    flex: 0.25,
                  }}
                >
                  <img src={Secure} style={{  height: '5rem'  }} alt="" />
                  <div style={{ marginLeft: '0.75rem', marginRight: '0.75rem' }}>
                    <div
                      className="poppins"
                      style={{ fontSize: '1rem', color: "black",marginTop: '1rem'  }}
                    >
                      Secure
                    </div>
                    <div
                      className="poppins"
                      style={{ fontSize: '1rem', color: "black" }}
                    >
                      Crypto Portfolio
                    </div>
                    <div className="basier" style={{ color: "black",marginTop: '1rem', fontSize: '0.94rem' }}>
                      Your sensitive information is encrypted and we value your privacy.
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div
              style={{ height: "6.25rem", minHeight: "3.15rem", width: "100%", backgroundColor: '#0078DB', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
                <img src={whiteLogo} style={{ width: '10rem' }} alt="" />
            </div>
          </div>
          {/* <div className={classes.container}>
            <div className={classes.formContainer}>
              <div
                style={{
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div className="login-title">
                  <h1
                    className="poppins"
                    style={{ fontSize: 40, lineHeight: "42px" }}
                  >
                    {this.state.forgot
                      ? "Forgot Password"
                      : this.props.register === true
                      ? "Sign up"
                      : "Log in"}
                  </h1>
                </div>
                {this.props.register ? (
                  <RegisterForm />
                ) : (
                  <LoginForm
                    toggleForgot={this.toggleForgot}
                    forgot={this.state.forgot}
                    register={this.props.register}
                  />
                )}

                {!this.state.forgot && (
                  <div
                    style={{
                      marginTop: 24,
                      width: "100%",
                      height: 60,
                      backgroundColor: "#F3F5F6",
                      borderRadius: 0,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      padding: 24,
                    }}
                  >
                    <div>
                      {this.props.register
                        ? "Already have an account?"
                        : "Need an account?"}
                    </div>
                    <div
                      onClick={this.props.toggleRegister}
                      className="text-link"
                    >
                      {this.props.register ? "Log in" : "Sign up"}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div> */}
        </div>

        {/* <div
          className="total-login-container"
          style={{
            display: "flex",
            position: "relative",
            width: "100%",
            overflowY: "hidden",
            height: "1200px",
          }}
        >
          <div className="vector-bg-large" style={{ zIndex: 10 }}>
            <img className="vector-svg" src={VectorBG} alt="" />
          </div>
          <div className="vector-bg-small" style={{ zIndex: 10 }}>
            <img className="small-svg" src={VectorSmall} alt="" />
          </div>

          <div className="centered-container">
            <div>
              <a href="https://wisly.io">
                <div
                  style={{
                    width: "100%",
                    height: 100,
                    display: "flex",
                    alignItems: "center",
                    marginLeft: 24,
                    cursor: "pointer",
                  }}
                >
                  <div style={{ marginRight: 12 }}>
                    <svg
                      width="12"
                      height="21"
                      viewBox="0 0 8 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7 13L1 7.00244L7 1.00244"
                        stroke="#0078DB"
                        stroke-miterlimit="10"
                      />
                    </svg>
                  </div>
                  <img src={Logo} alt="" />
                </div>
              </a>
              <div style={{ display: "flex" }}>
                <div className="image-login">
                  <img className="" src={LoginImage} alt="" />
                </div>

                <div className="form-overall-container">
                  <div className="form-container">
                    <div style={{ width: "100%" }}>
                      <div className="login-title">
                        <h1 style={{ fontSize: 40, lineHeight: "42px" }}>
                          {this.state.forgot
                            ? "Forgot Password"
                            : this.props.register === true
                            ? "Sign up"
                            : "Log in"}
                        </h1>
                      </div>

                      {this.props.register ? (
                        <RegisterForm />
                      ) : (
                        <LoginForm
                          toggleForgot={this.toggleForgot}
                          forgot={this.state.forgot}
                          register={this.props.register}
                        />
                      )}

                      {!this.state.forgot && (
                        <div
                          style={{
                            marginTop: 24,
                            width: "100%",
                            height: 70,
                            backgroundColor: "#F3F5F6",
                            borderRadius: 20,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            padding: 24,
                          }}
                        >
                          <div>
                            {this.props.register
                              ? "Already have an account?"
                              : "Need an account?"}
                          </div>
                          <div
                            onClick={this.props.toggleRegister}
                            className="text-link"
                          >
                            {this.props.register ? "Log in" : "Sign up"}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              style={{
                marginTop: 0,
                zIndex: 900,
                display: "block",
                position: "relative",
              }}
            >
              <footer style={{ zIndex: 900 }}>
                <div
                  style={{
                    maxWidth: 1280,
                    margin: "0 auto",
                    padding: "0 15px",
                  }}
                  className="basier"
                >
                  <div
                    className="footer-logo"
                    style={{
                      textAlign: "center",
                      position: "relative",
                      marginBottom: 20,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div className="line-logo" />
                    <figure
                      style={{
                        display: "inline-block",
                        position: "relative",
                        zIndex: 9,
                        padding: "0 40px",
                      }}
                    >
                      <img
                        src="//wisly.hestawork.com/media/_sitelogosize/wisly_logo.svg"
                        alt="footer-logo"
                      />
                    </figure>
                    <div className="line-logo" />
                  </div>

                  <div
                    style={{
                      display: "flex",
                      marginBottom: 50,
                      justifyContent: "space-between",
                      flexWrap: "wrap",
                    }}
                  >
                    <div class="footer-menu">
                      <h6>Communication</h6>
                      <ul className="login-ul">
                        <li
                          className="opacity-hover"
                          style={{ cursor: "pointer" }}
                        >
                          <a href="https://t.me/wislyio">Telegram</a>
                        </li>
                      </ul>
                    </div>
                    <div class="footer-menu">
                      <h6>Read Us</h6>
                      <ul style={{ zIndex: 900 }} className="login-ul">
                        <li
                          className="opacity-hover"
                          style={{ cursor: "pointer", zIndex: 900 }}
                        >
                          <a href="https://medium.com/@wisly">Medium</a>
                        </li>
                        <li
                          className="opacity-hover"
                          style={{ cursor: "pointer", zIndex: 900 }}
                        >
                          <a href="https://wisly.io/blog">Blog</a>
                        </li>
                      </ul>
                    </div>
                    <div class="footer-menu">
                      <h6>Features</h6>
                      <div class="menu footer-menu">
                        <ul className="login-ul">
                          <li
                            className="opacity-hover"
                            style={{ cursor: "pointer" }}
                            id="menu-item-33"
                          >
                            <span class="">
                              <a
                                class=""
                                href="https://wisly.io/#smart-features"
                              >
                                Smart Features
                              </a>
                            </span>
                          </li>
                          <li
                            className="opacity-hover"
                            style={{ cursor: "pointer" }}
                            id="menu-item-34"
                          >
                            <a
                              class=""
                              href="https://wisly.io/features/collaboration"
                            >
                              Collaboration
                            </a>
                          </li>
                          <li
                            className="opacity-hover"
                            style={{ cursor: "pointer" }}
                            id="menu-item-35"
                          >
                            <a
                              class=""
                              href="https://wisly.io/features/reports"
                            >
                              Reports
                            </a>
                          </li>
                          <li
                            className="opacity-hover"
                            style={{ cursor: "pointer" }}
                            id="menu-item-36"
                          >
                            <a class="" href="https://wisly.io/features/profit">
                              Profits
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="footer-menu">
                      <h6>Links</h6>
                      <div class="menu footer-menu">
                        <ul className="login-ul">
                          <li
                            className="opacity-hover"
                            style={{ cursor: "pointer" }}
                            id="menu-item-37"
                          >
                            <a class="" href="https://wisly.io/disclaimer">
                              About Us
                            </a>
                          </li>
                          <li
                            className="opacity-hover"
                            style={{ cursor: "pointer" }}
                            id="menu-item-38"
                          >
                            <a class="" href="https://wisly.io/privacy-policy">
                              Privacy Policy
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="footer-menu address-menu">
                      <h6>Based In South Bank, London UK</h6>
                      <div class="menu contact-us"></div>
                    </div>
                  </div>
                  <div class="copyright">
                    <p>2020, All rights reserved © Wisly</p>
                  </div>
                </div>
              </footer>
            </div>
          </div>
        </div> */}
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(loginLand);
