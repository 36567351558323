import React from "react";
import { connect } from "react-redux";
import listIcon from "../../../../../../Media/listIcon.svg";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import NFTViewer from "./NFTViewer";
import NFTTable from "./NFTTable";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { withStyles } from "@material-ui/core/styles";

import worker from "../../../../../../workers/nftWorker";
import WebWorker from "../../../../../../workers/WebWorker";
import Axios from "axios";
import { backendUrl } from "../../../../../../variables";
import { getValue } from "../../../../../../helpers/helpers";
import { getLocalNFTCache, setLocalNFTCache } from "../../../../../../helpers/localHelpers";
import CustomProgress from "../../../../../AppWide/Components/CustomProgress";

const styles = {
  root: {
    color: "#6A7E93",
    "&$checked": {
      color: "#0078DB",
    },
  },
  checked: {
    color: "#0078DB",
  },
  buttonRoot: {
    //width: "100%",
  },
};

class NFTOverview extends React.PureComponent {
  state = {
    data: null,
    table_data: null,
    view: "table",
    priceCache: {},
    cacheMutex: false,
    progress: 0,
  };

  getExternalPrice = (contract, links) => {

    const { priceCache } = this.state;
    const { token } = this.props;
    if (!Array.isArray(links)) return;

    let headers = { "content-type": "application/json" };
    headers["Authorization"] = `Token ${token}`;

    const len = links.length;
    let i = 0;

    while (i < len) {
      const link = links[i];
      const tokenId = link.id;
      if (
        getValue(priceCache, [contract, tokenId], undefined) !== undefined
      ) {
        i++;
        continue;
      }

      this.setPriceCache(contract, tokenId, null);
      const url = link.url;
      const data = {
        url: url,
      };
      const data_fetch = Axios.post(`${backendUrl}/v1/itrnl/sopsfgo/`, data, {
        headers,
      });
      data_fetch
        .then((json) => {
          const price = parseFloat(json.data);
          if (!isNaN(price)) {
            if (price > 0) {
              this.setPriceCache(contract, tokenId, price)
            }
          }
        })
        .catch((err) => {
        });
      i++;
    }
  };

  setPriceCache = (contract, token, val) => {
    this.setState((state) => ({
      ...state,
      priceCache: {...state.priceCache, [contract]: {...state.priceCache[contract], [token]: val}},
      cacheMutex: false,
    }));
  };

  fetchCache = () => {
    return this.state.priceCache;
    // if (!this.state.cacheMutex) {
    //   //this.setState((state) => ({ ...state, cacheMutex: true }));
    //   return this.state.priceCache;
    // }
    // const interval = setInterval(() => {
    //   if (!this.state.cacheMutex) {
    //     this.setState((state) => ({ ...state, cacheMutex: true }));
    //     clearInterval(interval);
    //     return this.state.priceCache;
    //   }
    // }, 300);
  };

  handleNftData = () => {
    const nftKeys = Object.values(
      this.props.portfoliosData?.[this.props.portfolioId].nfts
    );
    const tokenKeys = Object.values(nftKeys);
    const data = tokenKeys.map((v) => Object.values(v)).flat();
    this.setState({ data: data });
  };

  runWorker = (nfts, nftCollections) => {
    this.worker.postMessage([nfts, nftCollections]);
  };

  setLocalCache = () => {
    setLocalNFTCache(this.state.priceCache)
  }

  componentDidMount() {

    const thisNfts = getValue(this.props.portfoliosData, [this.props.portfolioId, 'nfts'], null)

    // const progress = setInterval(() => {

    //   this.setState(state => ({...state, progress: state.progress + 1}))

    //   if (this.state.progress >= 100) {
    //     clearInterval(progress)
    //   }

    // }, 300)

    const deviceCache = getLocalNFTCache();
    if (deviceCache) {
      this.setState(state => ({...state, priceCache: deviceCache.payload}))
    }
    
    
    this.worker = new WebWorker(worker);

    this.worker.addEventListener("message", (event) => {
      const parsedBalances = event.data;
      this.setState((state) => ({ ...state, table_data: parsedBalances}));
    });

    if (thisNfts && this.props.nftCollection) {
      this.runWorker(thisNfts, this.props.nftCollection)
    }

  }

  changeView = (e) => {
    e.persist();
    const val = e.target.value;
    this.setState((state) => ({ ...state, view: val }));
  };

  componentDidUpdate(prevProps) {

    const currencychange = this.props.currency !== prevProps.currency;
    const btcChange = this.props.inBitcoin !== prevProps.inBitcoin;
    let portfoliochange = false;
    if (prevProps.portfoliosData && this.props.portfoliosData) {
      portfoliochange =
        this.props.portfoliosData[this.props.portfolioId] !==
        prevProps.portfoliosData[this.props.portfolioId];
    }
    const collectionChange = this.props.nftCollection !== prevProps.nftCollection;
    if (currencychange || portfoliochange || btcChange || collectionChange) {
      const thisNfts = getValue(this.props.portfoliosData, [this.props.portfolioId, 'nfts'], null)
      if (thisNfts && this.props.nftCollection) {
        this.runWorker(thisNfts, this.props.nftCollection)
      }
      //this.handleNftData();
    }
  }

  cleanup = () => {

  }
  componentWillUnmount() {
    this.setLocalCache()
    window.removeEventListener('beforeunload', this.cleanup);
  }

  render() {
    const { table_data, view } = this.state;

    console.log('table data is', table_data)
    const { classes } = this.props;
    return (
      <div>
        {!table_data ? 
        <div>

            <CustomProgress
                value={this.state.progress}
                limit={100}
                color="#0000FF"
              />
              <div 
                className="basier-p4-caps"
                style={{
                display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', color: "#6A7E93", marginTop: 12,
              }}>
                Loading NFT data (1/10)
              </div>

        </div>
        : table_data.length > 0 ? (
          <div>
            <div
              style={{ marginLeft: 12, fontStyle: "italic", color: "#e0a03d" }}
              className="basier-p3"
            >
              Note: Given the illiquid nature of NFTs, Wisly includes your NFTs
              in this portfolio at cost of purchase. Any Profit / Loss on NFTs
              will be accounted for only when the NFT is sold or marked as a
              loss. The data presented below is derived from floor prices and is
              for information purposes only. The profit/loss calculated below is
              not reflected in the overall portfolio value and P&L.
            </div>
            {/* <div
              style={{
                height: 40,
                borderRadius: 5,
                padding: 12,
                backgroundColor: "rgba(243,245,246, 1)",
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div
                className={"poppins"}
                style={{ fontSize: 18, color: "black" }}
              >
                Total NFT Value
              </div>
              <div
                className={"poppins"}
                style={{
                  fontSize: 18,
                  display: "flex",
                  alignItems: "center",
                  color:
                    nftProfit > 0
                      ? "#4CDAC1"
                      : nftProfit < 0
                      ? "#F9716B"
                      : "#6A7E93",
                }}
              >
                {nftProfit > 0 ? (
                  <ArrowDropUpIcon style={{ fontSize: 30 }} />
                ) : nftProfit < 0 ? (
                  <ArrowDropDownIcon style={{ fontSize: 30 }} />
                ) : null}
                {this.props.symbol}
                {Math.abs(nftProfit).toLocaleString("en", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </div>
            </div> */}
            <div
              style={{ display: "flex", alignItems: "center", marginLeft: 12 }}
            >
              <div className="basier-p2" style={{ marginRight: 12 }}>
                View NFTs as:{" "}
              </div>
              <RadioGroup
                name="nftView"
                value={this.state.view}
                onChange={this.changeView}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <FormControlLabel
                  value="table"
                  control={
                    <Radio
                      classes={{
                        root: classes.root,
                        checked: classes.checked,
                      }}
                    />
                  }
                  label="Collection"
                />
                <FormControlLabel
                  value="individual"
                  control={
                    <Radio
                      classes={{
                        root: classes.root,
                        checked: classes.checked,
                      }}
                    />
                  }
                  label="Individual Items"
                />
              </RadioGroup>
            </div>
            <div>
              {view === "table" ? (
                <NFTTable
                  getExternalPrice={this.getExternalPrice}
                  priceCache={this.state.priceCache}
                  fetchCache={this.fetchCache}
                  setPriceCache={this.setPriceCache}
                  currency_symbol={this.props.currency_symbol}
                  inBitcoin={this.props.inBitcoin}
                  data={this.state.table_data}
                  token={this.props.token}
                  prices={this.props.nftPrices}
                />
              ) : (
                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-evenly",
                      flex: 1,
                      flexWrap: "wrap",
                      alignItems: "flex-start",
                    }}
                  >
                    {this.state.table_data.map((v) => {
                      return v.tokens.map((v1, k) => {
                        console.log('v1', v1)
                        return (
                          <div>
                            <NFTViewer
                              priceCache={this.state.priceCache}
                              fetchCache={this.fetchCache}
                              setPriceCache={this.setPriceCache}
                              key={k}
                              data={v1}
                              currency_symbol={this.props.currency_symbol}
                              inBitcoin={this.props.inBitcoin}
                              token={this.props.token}
                              prices={this.props.nftPrices}
                            />
                          </div>
                        );
                      });
                    })}
                  </div>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div
            style={{
              height: 200,
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 12,
            }}
          >
            <div
              style={{
                height: "100%",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                padding: 48,
                flexDirection: "column",
              }}
            >
              <div>
                <img src={listIcon} alt="" style={{ height: 80 }} />
              </div>
              <div className="basier-p2" style={{ marginTop: 24 }}>
                <div>
                  You do not have any NFT transactions in your portfolios yet.
                </div>
                <div>
                  Once you add these types of transactions you will see your
                  data here.
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    portfoliosData: state.data.portfoliosData,
    currency_symbol: state.auth.currency.symbol,
    inBitcoin: state.data.bitcoinPf === "bitcoin",
    currency: state.auth.currency.id,
    token: state.auth.token,
    nftCollection: state.appwide.nftCollection,
    nftPrices: state.appwide.nftPrices,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(NFTOverview)
);
