import React from "react";
import "./Login.css";
import VectorBG from "../../Media/LoginVectorFinal.svg";
import VectorSmall from "../../Media/MobileBk.svg";
import CenterVector from "../../Media/LoginCenterVector.svg";
import LoginImage from "../../Media/LoginIllu.svg";
import background from "../../Media/signupBk.jpg";
import whiteLogo from "../../Media/whiteLogo.svg";

import Graph from "../../Media/graph1.svg";
import Globe from "../../Media/globe1.svg";
import Secure from "../../Media/secure1.svg";
import Flex from "../../Media/flex1.svg";

import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import LoginForm from "./LoginForm";
import RegisterForm from "./RegisterForm";
import Logo from "../../Media/Logo.svg";
import { withStyles } from "@material-ui/core/styles";
import { Hidden } from "@material-ui/core";
import { withRouter } from "react-router";

const styles = (theme) => ({
  container: {
    flex: 1,
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  formContainer: {
    width: 500,
    marginLeft: "auto",
    marginRight: "auto",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      paddingLeft: 12,
      paddingRight: 12,
    },
  },
});

class loginLand extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.email = React.createRef();
    this.pw = React.createRef();
    this.state = {
      forgot: false,
    };
  }

  toggleForgot = () => {
    this.setState((state) => ({ ...state, forgot: !state.forgot }));
  };

  handleSubmit(event) {
    event.preventDefault();
  }

  componentDidMount() {
    document.body.style.backgroundColor = "white";
  }

  componentWillUnmount() {
    window.scrollTo(0, 0);
    document.body.style.backgroundColor = null;
  }

  goRegister = () => {
    this.props.history.push('register')
  }

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <div
          style={{
            display: "flex",
            height: "100vh",
            width: "100%",
          }}
        >
          <Hidden smDown>
            <div
              style={{
                flex: 1,
                width: "50%",
                backgroundImage: `url(${background})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: 'cover'
              }}
            >
              <div style={{paddingTop: 48, paddingLeft: 48 }}>
                <img src={whiteLogo} style={{ width: 150 }} alt="" />
                <div style={{marginTop: 60}}>
                    <div className="poppins" style={{fontSize: 36, color: 'white'}}>
                        Filter through the Noise,
                    </div>
                    <div className="poppins" style={{fontSize: 36, color: 'white'}}>
                        Distill Insights.
                    </div>
                    <div className="basier-p1" style={{color: 'white'}}>
                        Track and analyze your crypto investments, <b>Wisly</b>
                    </div>
                </div>
              </div>

              <div style={{marginTop: 60, marginLeft: 48}}>
                <div style={{display: 'flex', alignItems: 'center', marginTop: 48}}>
                  <img src={Graph} style={{height: 50}} alt="" />
                  <div style={{marginLeft: 24}}>
                    <div className="poppins" style={{fontSize: 20, color: 'white'}}>Advanced Analytics</div>
                    <div className="basier-p3" style={{color: 'white'}}>
                          Profit breakdown, cost basis, and more.
                    </div>
                  </div>
                </div>

                <div style={{display: 'flex', alignItems: 'center', marginTop: 48}}>
                  <img src={Globe} style={{height: 50}} alt="" />
                  <div style={{marginLeft: 24}}>
                    <div className="poppins" style={{fontSize: 20, color: 'white'}}>Full Coverage</div>
                    <div className="basier-p3" style={{color: 'white'}}>
                          Largest coverage of crypto and derivatives, updated daily.
                    </div>
                  </div>
                </div>

                <div style={{display: 'flex', alignItems: 'center', marginTop: 48}}>
                  <img src={Secure} style={{height: 50}} alt="" />
                  <div style={{marginLeft: 24}}>
                    <div className="poppins" style={{fontSize: 20, color: 'white'}}>Secure and Private</div>
                    <div className="basier-p3" style={{color: 'white'}}>
                          Your data is encrypted and kept private.
                    </div>
                  </div>
                </div>

                <div style={{display: 'flex', alignItems: 'center', marginTop: 48}}>
                  <img src={Flex} style={{height: 50}} alt="" />
                  <div style={{marginLeft: 24}}>
                    <div className="poppins" style={{fontSize: 20, color: 'white'}}>Flexibility, On The Go.</div>
                    <div className="basier-p3" style={{color: 'white'}}>
                          Use the Web App or Native iOS/Android app.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Hidden>
          <div className={classes.container}>
            <div className={classes.formContainer}>
              <div
                style={{
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div className="login-title">
                  <h1
                    className="poppins"
                    style={{ fontSize: 40, lineHeight: "42px" }}
                  >
                    {this.state.forgot
                      ? "Forgot Password"
                      : this.props.register === true
                      ? "Sign up"
                      : "Log in"}
                  </h1>
                </div>
                {this.props.register ? (
                  <RegisterForm />
                ) : (
                  <LoginForm
                    toggleForgot={this.toggleForgot}
                    forgot={this.state.forgot}
                    register={this.props.register}
                  />
                )}

                {!this.state.forgot && (
                  <div
                    style={{
                      marginTop: 24,
                      width: "100%",
                      height: 60,
                      backgroundColor: "#F3F5F6",
                      borderRadius: 0,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      padding: 24,
                    }}
                  >
                    <div>
                      {this.props.register
                        ? "Already have an account?"
                        : "Need an account?"}
                    </div>
                    <div
                      onClick={this.goRegister}
                      className="text-link"
                    >
                      {this.props.register ? "Log in" : "Sign up"}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(withRouter(loginLand));
