import React from 'react';
import { connect } from 'react-redux';
import listIcon from '../../../../../Media/listIcon.svg'
import AssetTable from './AssetTableStraight';

class LargeAssetTable extends React.PureComponent {

    render () {

        return (
            <div>
                
                <div style={{width: '100%'}}>

                    <div style={{marginLeft: 12, marginRight: 12, marginTop: 12}}>
                        
                        {this.props.portfoliosData[this.props.portfolioId] !== undefined ?

                            this.props.portfoliosData[this.props.portfolioId].balances.filter(v => v.is_nft === false).length > 0 ? 
                            
                                <AssetTable portfolioId={this.props.portfolioId} active={this.props.active} small={this.props.small} exited = {this.props.exited} items={this.props.portfoliosData[this.props.portfolioId]}  />
                            
                            :

                            <div style={{height: 200, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 12}}>
                                <div style={{height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', padding: 48, flexDirection: 'column'}}>
                                    <div>
                                        <img src={listIcon} alt="" style={{height: 80}} />
                                    </div>
                                    <div className="basier-p2" style={{marginTop: 24}}>
                                        <div>You do not have any transactions in your portfolios yet.</div>
                                        <div>Once you add transactions you can see your balances here.</div>
                                    </div>
                                </div>
                            </div>

                        : 
                        null 
                        }
                    
                    </div>

                </div>
            </div>
        )
    }


}

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = dispatch => {
    return {
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(LargeAssetTable)
