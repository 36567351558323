import React from 'react';
import { connect } from 'react-redux';
import worker from "../../../workers/walletFlattenWorker";
import WebWorker from "../../../workers/WebWorker";
import AccountLine from './AccountLine';
import manualWallet from '../../../Media/manualWallet.svg'

class AccountSection extends React.PureComponent {

  state = {
    item: undefined,
    loading: true,
  }
    
  componentDidMount() {

    this.worker = new WebWorker(worker);

    this.worker.addEventListener('message', event => {
        const exchange = event.data[0]
        const storage = event.data[1]
        const item = [...exchange, ...storage] 

        this.setState(state => ({...state, 
          item: item, 
          }))
    });

    if (this.props.data[this.props.user_id] !== undefined) {
        this.worker.postMessage(this.props.data[this.props.user_id])
    }
    
}

componentDidUpdate(prevProps) {

    if (this.props.data !== prevProps.data && this.props.data[this.props.user_id] !== undefined) {
        this.worker.postMessage(this.props.data[this.props.user_id])
    }
}

  render () {
    const {item} = this.state;
    return(
      item === undefined ? 
      <div>Load</div>
      :
      item.length === 0 ? 
      <div style={{width: '100%', height: '100%', display: 'flex', alignItems: 'center', flexDirection: 'column',justifyContent: 'center', textAlign: 'center'}}>
        <img src={manualWallet} style={{height: 50, marginBottom: 24}} alt="" />
        <div className="basier-p3">
          You do not have any accounts yet. Create or connect an account now.
        </div>
        <div
          className="basier-p3"
          style={{
            height: 50,
            width: 280,
            fontSize: 18,
            borderRadius: 35,
            marginTop: 24,
            marginLeft: 12,
            marginRight: 12,
            cursor: "pointer",
            backgroundColor: "#0078DB",
            color: 'white',
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          onClick={() => this.props.openModal('new_wallet')}
        >
          Create first Account
          </div>
      </div>
      :
      <div>
        {item.map((v, k) => {
          return (
            <AccountLine key={k} data={v} />
          )
        })}
      </div>
    )
  }
}


const mapStateToProps = state => {
  return {
      data: state.appwide.wallets,
      user_id: state.auth.user_id,
  };
};


export default connect(
  mapStateToProps,
  null
)(AccountSection);