import React from 'react';
import { connect } from 'react-redux';
import BackIcon from "../../../../Media/BackIcon.svg";
import axios from 'axios';
import * as appwideActions from "../../../../store/actions/appwide";
import { backendUrl } from '../../../../variables'
import fieldValidator from '../../../../helpers/validators'
import loadingSpinner from '../../../../Media/loadingSpinner.gif'
import successIcon from '../../../../Media/okIcon.svg'
import errorIcon from '../../../../Media/errorIcon.svg'
import * as dataActions from "../../../../store/actions/data";
import SettingComponent from '../../../Wallets/Large/DetailComponents/SettingComponent'


class assetModalContent extends React.PureComponent {

    state = {
        open: false,
        coin_in_scope: null, 
        graph_data: [], 
        graph_start: null,
        edit: false,
        wallet_id: this.props.location.state.wallet_id,
        exchange_id: this.props.location.state.exchange_id,
        edit_id: this.props.location.state.data.id,
        data: this.props.location.state.data,
        source: this.props.location.state.source,
        editData: {},
        editNameError: false,
        loading: false, 
        error: false, 
        success: false, 
    }

    normalizeData = (val) => {
        return {
            name: val.name.toUpperCase(), 
            source_name: val.coin_name, 
            user: val.user_id, 
            image: val.coin_image, 
            address: val.address, 
            connected: val.connector !== null,
            connector: val.connector,
            sub_name: val.ticker,
            target_id: val.target_id,
            link: val.explorer,
            id: val.id,
            source_id: val.wallet_coin,
            date: val.created_at,
            wallets: [],
            type: 'storage'
        }
    }


    componentDidMount() {
        
        this.props.setCurrentView(972)
        if (this.props.wallet !== null && this.props.wallet !== undefined ) {
            let newData = null
            let stateData = null 
            try { newData = this.props.wallets[this.props.user_id]['storage'][this.props.location.state.data.source_id].filter(v => v.id === this.props.location.state.data.id )[0] } catch(e) {}
            if (newData !== null && newData !== undefined) {
                stateData = this.normalizeData(newData)
            }
            
            this.setState(state => ({...state, data: stateData}))
        }

    }

    componentDidUpdate(prevProps) {

        if (prevProps.wallets !== this.props.wallets && this.props.wallets !== undefined && this.props.wallets !== null ) {

            let newData = null
            let stateData = null 
            try { newData = this.props.wallets[this.props.user_id]['storage'][this.props.location.state.data.source_id].filter(v => v.id === this.props.location.state.data.id )[0] } catch(e) {}
            if (newData !== null && newData !== undefined) {
                stateData = this.normalizeData(newData)
            }
            this.setState(state => ({...state, data: stateData}))

        }

    }
    

    getData = (transaction_id) => {
    
    }

    editHandler = () => {
        this.setState(state => ({...state, edit: true}))
    }

    deleteHandler = () => {
        if (window.confirm("Are you sure you want to delete this wallet")) {
            this.deleteTransaction()
          } 
    }

    deleteTransaction = () => {

        let headers = { "content-type": "application/json" };
        if (this.props.token !== null && this.props.token !== undefined) {

            headers['Authorization'] = `Token ${this.props.token}`

        }

        const url_call = `${backendUrl}/v1/wallets/${this.props.location.state.wallet_id}/`

        axios.delete(url_call, {headers: headers})
            .then(json => {

                // load new wallet list
                this.props.getWallets(this.props.user_id)
                this.props.history.goBack()
                //console.log(json['data'])

            })


    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        this.props.history.push('/wallets')
        return
    }

    handleEditData = (e, label) => {
        e.persist();
        this.setState(state => ({...state, editData: {...state.editData, [label]: e.target.value}}))
    }

    cancelEdit = () => {
        this.setState(state => ({...state, edit: false, success: false, error: false, loading: false, editData: {}}))
    }
    
    backHandler = () => {
        this.props.backAction()
    }

    updateEditData = () => {

        if (Object.keys(this.state.editData).length > 0) {

            let wal_id = null;
            if (this.state.source !== null && this.state.source !== undefined) {
                wal_id = this.state.edit_id
            } else {
                wal_id = this.state.wallet_id
            }
            let editData = {
                ...this.state.editData, 
                user: this.props.user_id, 
                id: wal_id
            }
            
            const exchange_val = true

            if (exchange_val) {
                
                this.setState(state => ({...state, loading: true}))

                let headers = { "content-type": "application/json" };
                if (this.props.token !== null && this.props.token !== undefined) {

                    headers['Authorization'] = `Token ${this.props.token}`

                }

                const url_call = `${backendUrl}/v1/wallets/`

                axios.patch(url_call, editData, {headers: headers})
                    .then(json => {

                    this.props.getWallets(this.props.user_id)
                    this.setState(state => ({...state, loading: false, success: true}))
                        
                    setTimeout(() => {

                        this.cancelEdit()
                        
                    }, 600)
                        
                    })
                    .catch( e => {

                    this.setState(state => ({...state, loading: false, error: true}))

                    })
            
            }
        } else {
            this.cancelEdit()
        }

        
    
    }
    
    render () {
         
        const { item } = this.state;

        return ( 
            
            this.state.data !== null &&
            
            <div style={{position: 'absolute', top: 0, zIndex: 999999, width: '100%'}}>

                <div style={{backgroundColor: '#F8F9FA', zIndex: 9999999, height: 75, width: '100%', display:'flex', alignItems: 'center', position: 'fixed', justifyContent: 'space-between'}} id="responsive-dialog-title">
                    
                        <div onClick={() => this.props.history.goBack()}>
                            <img style={{paddingTop: 24}} src={BackIcon} alt=""/>
                        </div>

                        <div>
                            <div className="touchableOpacity basier"  style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end', fontSize: '1.2rem'}}>

                            {this.state.edit ? 
                                
                                <React.Fragment>
                                    <div className="modalActionButton touchableOpacity"  onClick={this.updateEditData} style={{marginRight: 24, color: '#0078DB'}}>Save</div>
                                </React.Fragment>

                                :

                                    <React.Fragment>
                                    
                                    <div className="modalActionButton"  onClick={this.deleteHandler} style={{marginRight: 48, color: '#F9716B'}}>Delete</div>

                                    {/* <div className="modalActionButton"  onClick={this.editHandler} style={{marginRight: 24, color: '#0078DB'}}>Edit</div> */}

                                 </React.Fragment>
                            }
                            </div> 
                        </div>

                    </div>

                    <div>
                        <SettingComponent account={this.state.data.id} data={this.state.data} type='storage' client='mobile' handleBack={this.goBackAction} />
                    </div>



            </div>
        )

    }
   
}

const mapStateToProps = state => {
    return {

        currency_symbol: state.auth.currency.symbol,
        currency_id: state.auth.currency.id,
        inBitcoin: state.data.bitcoinPf, 
        user_id: state.auth.user_id, 
        token: state.auth.token,
        wallets: state.appwide.wallets

        
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getWallets: (user) => dispatch(appwideActions.getWallets(user)), 
        setCurrentView: () => dispatch(dataActions.setCurrentView())
    };
};


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(assetModalContent);
