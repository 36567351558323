import React from 'react';
import * as actions from "../../../../../store/actions/interactions";
import { connect } from 'react-redux';
import CoinSearch from '../../../../AppWide/CoinSearch';
import DateTimePicker from 'react-datetime';
import 'react-datetime/css/react-datetime.css'
import UserList from './UserList'
import Switch from '@material-ui/core/Switch';
var moment = require('moment');
require('moment/locale/en-gb');

class TransactionStep2 extends React.PureComponent {

    constructor(props) {

        super(props)
        this.firstFocusFees = React.createRef();
        this.secondFocusFees = React.createRef();
        this.hiddenRef = React.createRef();
        this.dateRef = React.createRef();

        this.state = {
            done: false, 
            selected_quote: null, 
            futurDate: false,
        }

        if (this.props.transaction['data'][this.props.transactionId]['date'] !== null && this.props.transaction['data'][this.props.transactionId]['date'] !== undefined) {
            

            const existing_date = this.props.transaction['data'][this.props.transactionId]['date']
            const stateDate = moment(existing_date).local().format("DD/MM/YYYY HH:mm")
            this.props.setTrTime(stateDate) 
                


        } else {

            const now = new Date()
            const moment_date = moment(now);
            const stateDate = moment_date.local().format("DD/MM/YYYY HH:mm")
            const reduxDate = moment_date.utc().format();
            this.props.setTrTime(stateDate) 
            this.props.selectDate(reduxDate)


        }
        

    }
    

    /* selectQuoteCoin = (data) => {
        this.props.updateTransaction({key: this.props.transactionId, label: "quote_currency", value: data.value})
        this.setState(state => ({...state, selected_quote: data}))
        //this.props.handleNext()
    } */

    componentDidMount() {

       

    }

    getRef = async (ref) => {
        return ref;
    }

    handleChange = (date) => {

        const testStateDate = moment( date, "DD/MM/YYYY HH:mm", true).local()

        if (testStateDate.isValid()) {

            const stateDate = testStateDate.format("DD/MM/YYYY HH:mm")
            this.props.setTrTime(stateDate)  
            let dateRedux = moment(date);
            if (dateRedux.isValid()) {           
                this.props.selectDate(dateRedux.utc().format())
                const now = new Date()
                const redDe = Date(dateRedux)
                
                if (date > now) {
                    this.props.validatorUpdate('date', false)
                    this.setState(state => ({...state, futurDate: true}))    
                } else {
                    this.setState(state => ({...state, futurDate: false})) 
                    this.props.validatorUpdate('date', true)    
                }
                
            } else {
                
                this.props.setTrTime(date)
                this.props.validatorUpdate('date', false)    
            }

        } else {

            this.props.setTrTime(date)
            this.props.validatorUpdate('date', false)
        }
    }

    handleDateBlur = (dateSend) => {

    }
 



    selectFeeCoin = (data) => {

        this.props.updateTransaction({key: this.props.transactionId, label: "fee_currency", value: { value: data.value, name: data.coinName, image: data.image } })


    }

    changeFees = () => {

        if (this.props.feeCheck === true) {

            this.props.setInteraction({label: 'feeCheck', value: false})
            this.props.updateTransaction({key: this.props.transactionId, label: "fee", value: null})
            this.props.updateTransaction({key: this.props.transactionId, label: "fee_currency", value: null})

        } else {

            this.props.setInteraction({label: 'feeCheck', value: true})
            this.getRef(this.firstFocusFees)
            .then(res => {
                res.current.myInput.focus();
            })

        }
        
    }

    getFeesAmountRef = (ref) => {

        const ref_needed = this.getRef(this.secondFocusFees)
        
        ref_needed
        .then(res => {

            try {res.current.focus({preventScroll: true})} catch(e) {}
            
            })

    }

    getDateRef = (ref) => {

        const ref_needed = this.getRef(this.dateRef)
        
        ref_needed
        .then(res => {

            try {res.current.focus({preventScroll: true})} catch(e) {}
            
            })

    }

    onKeyDownNext = (e, key, focus) => {

        if (e.keyCode === 13 || e.keyCode === 9) {

            e.preventDefault();
            this.props.updateTransaction({key: this.props.transactionId, label: key, value: e.target.value})
            this.props.handleNext()
            
        }
        
    }

    keyPressSwitch = (e) => {
        e.preventDefault();
        if (e.keyCode === 9) {
            this.props.handleNext()
        } else if (e.keyCode === 13 || e.keyCode === 33) {
            this.changeFees()
        }
    }

    fillToday = () => {

        const now = new Date()
        const moment_date = moment(now);
        const reduxDate = moment_date.utc().format();
        const stateDate = moment_date.local().format("DD/MM/YYYY HH:mm")
        
        this.props.setTrTime(stateDate)  
        this.props.updateTransaction({key: this.props.transactionId, label: 'date', value: reduxDate})
        this.props.validatorUpdate('date', true)         

    }

    handleModalKeyDown = (e) => {
        if (e.keyCode === 13) {

            this.props.handleNext()

        } else if (e.keyCode === 9) {
            e.preventDefault();
            this.getDateRef();

        }
    }

    handleUserChange = (selectedOption) => {
        this.props.updateTransaction({key: this.props.transactionId, label: "user_transaction", value: selectedOption})
    }

    render() {

        return (

            <div style={{marginTop: 24}}>
                
                <div style={{

                                display: 'flex', 
                                height: 60,//height: `${this.props.transaction['data'][this.props.transactionId]['quote_currency'] !== null && this.props.transaction['data'][this.props.transactionId]['quote_currency']  !== undefined ? '60px' : '300px'}`, 
                                alignItems: 'baseline',
                                transition: 'height 0.3s'
                                }}>
                                    
                            <div style={{display: 'flex', flexDirection: 'column'}}>
                                <div className='basier-p3' style={{marginTop: 12, marginBottom: 12, width: 500, zIndex: 7000, marginRight: 24, fontWeight: 'bold'}}>What was the transaction {this.props.selectedBase.coinName} {this.props.transaction['data'][this.props.transactionId]['transaction_description']}  date? </div>
                                {!this.props.validation.date && 
                                    <div className="basier-p4" style={{marginTop: -8, fontStyle: 'italic', color: 'red'}}>
                                        {this.state.futurDate ? "The transaction date cannot be in the future" : "Invalid date format. Please use DD/MM/YYYY HH:mm"}
                                    </div>
                                }
                            </div>
                            
                            <div style={{width: 250, display: 'flex', alignItems: 'baseline'}} className="root_datepicker ">
                            <DateTimePicker 
                                onChange={(date) => this.handleChange(date)} 
                                value={this.props.tr_date} 
                                locale="en-gb"
                                closeOnSelect={true}
                                strictParsing={true}
                                inputProps={{className: `select_datepicker basier-p3 ${!this.props.validation.date ? "error-input" : null }`, placeholder: 'DD/MM/YYYY', onBlur: this.handleDateBlur, ref: this.dateRef}}
                            />
                            <div className="basier-p4-caps link-hover"  style={{marginLeft: 24, width: 60,}} onClick={this.fillToday}>Now</div>
                            </div>

                        </div>

                
                <div style={{

                                display: 'flex', 
                                height: 60,//height: `${this.props.transaction['data'][this.props.transactionId]['quote_currency'] !== null && this.props.transaction['data'][this.props.transactionId]['quote_currency']  !== undefined ? '60px' : '300px'}`, 
                                alignItems: 'baseline',
                                transition: 'height 0.3s'
                                }}>
                            <div className='basier-p3' style={{marginTop: 12, marginBottom: 12, width: 500, zIndex: 7000, marginRight: 24, fontWeight: 'bold'}}>Who executed the transaction?</div>
                            
                            <UserList transactionId={this.props.transactionId} portfolioId={this.props.currentPortfolio} handleChange={(o) => this.handleUserChange(o)} prov={false} userPass={this.props.transaction['data'][this.props.transactionId]['user_transaction']} />

                </div>


                <div style={{

                    display: 'flex', 
                    height: 60,//height: `${this.props.transaction['data'][this.props.transactionId]['quote_currency'] !== null && this.props.transaction['data'][this.props.transactionId]['quote_currency']  !== undefined ? '60px' : '300px'}`, 
                    alignItems: 'baseline',
                    transition: 'height 0.3s'
                    }}>
                <div className='basier-p3' style={{marginTop: 12, marginBottom: 12, width: 500, zIndex: 7000, marginRight: 24, fontWeight: 'bold'}}>
                    Were there any fees related to the transaction?
                </div>
                
                
                <Switch
                    checked={this.props.feeCheck}
                    onChange={this.changeFees}
                    value="checkedB"
                    color="primary"
                    onKeyDown={(e) => this.keyPressSwitch(e)}
                />
                <input type="text" style={{width: 0, height:0, opacity: 0,}} onKeyDown={this.handleModalKeyDown} autoFocus={true} />

                </div>

               
                {this.props.feeCheck ? 
                

                
                
                <div>

                    <div style={{

                        display: 'flex', 
                        height: 60,//height: `${this.props.transaction['data'][this.props.transactionId]['quote_currency'] !== null && this.props.transaction['data'][this.props.transactionId]['quote_currency']  !== undefined ? '60px' : '300px'}`, 
                        alignItems: 'baseline',
                        transition: 'height 0.3s'
                        }}>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                    <div className='basier-p3' style={{marginTop: 12, marginBottom: 12, width: 500, zIndex: 7000, marginRight: 24, fontWeight: 'bold'}}>In what coin or currency did you pay fees?</div>
                    {!this.props.validation.fee_currency && 
                        <div className="basier-p4" style={{marginTop: -8, fontStyle: 'italic', color: 'red'}}>
                            Fee currency cannot be empty
                        </div>
                    }
                    </div>
                    <CoinSearch 

                                    data_filter={this.props.all_coins} 
                                    dataBind={this.props.transaction['data'][this.props.transactionId]['fee_currency']}
                                    ref={this.firstFocusFees}
                                    width={250}
                                    keepInput={true}
                                    overrideClass={true} 
                                    focusIn={() => this.getFeesAmountRef()}
                                    clearRedux='fee_currency'
                                    updaterMethod={(key, value) => this.props.updateTransaction({key: this.props.transactionId, label: key, value: value})}
                                    handleSelect={(value) => this.selectFeeCoin(value)}
                                    masterClass={`transaction-coin-search ${!this.props.validation.fee_currency === true ? 'error-input' : null}`}/>


                    </div> 

                    <div style={{

                        display: 'flex', 
                        height: 60,//height: `${this.props.transaction['data'][this.props.transactionId]['quote_currency'] !== null && this.props.transaction['data'][this.props.transactionId]['quote_currency']  !== undefined ? '60px' : '300px'}`, 
                        alignItems: 'baseline',
                        transition: 'height 0.3s'
                        }}>
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            <div className='basier-p3' style={{marginTop: 12, marginBottom: 12, width: 500, zIndex: 7000, marginRight: 24, fontWeight: 'bold'}}>How much fees did you pay?</div>
                            {!this.props.validation.fee && 
                            <div className="basier-p4" style={{marginTop: -8, fontStyle: 'italic', color: 'red'}}>
                                Fee must be a positive number and cannot be empty
                            </div>
                            }
                        </div>
                            <input onChange={(e) => this.props.updateTransaction({key: this.props.transactionId, label: 'fee', value: e.target.value})} ref={this.secondFocusFees}   className={`transaction-coin-search ${!this.props.validation.fee === true ? 'error-input' : null}`} onKeyDown={(e) => this.onKeyDownNext(e, 'fee')} value={this.props.transaction['data'][this.props.transactionId]['fee']} />
                    </div>

                </div>

                :  
                
                null
                
                }
                

            </div>  


        )
    }
}


const mapStateToProps = state => {
    return {
  
        modalPref: state.interactions.transactionAddModal,
        transaction: state.interactions.transactionAddData,
        tr_date: state.interactions.dateTransaction,
        feeCheck: state.interactions.feeCheck, 
        all_coins: state.appwide.all_coins,
        currentPortfolio: state.data.menu_selected, 
        
    };
  };
  
  const mapDispatchToProps = dispatch => {
    return {
        addTransaction: (data) =>
            dispatch(actions.addTransaction(data)),
        updateTransaction: (data) =>
            dispatch(actions.updateTransaction(data)), 
        setTrTime: (data) => 
            dispatch(actions.setTrTime(data)), 
        setInteraction: (data) => 
            dispatch(actions.setInteraction(data)), 
    };
  };
  
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(TransactionStep2);