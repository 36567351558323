import React from 'react';
import AccountSection from './AccountSection';
import AssetContainer from './AssetContainer';
import PortfolioSection from './PortfolioSection';
import TopSection from './TopSection';
import worker from "../../../workers/overviewWorker";
import WebWorker from "../../../workers/WebWorker";
import { connect } from 'react-redux';
import AssetHeaders from './AssetHeaders';
import PositionContainer from './PositionContainer';
import * as interActions from "../../../store/actions/interactions";
import Plus from '../../../Media/Plus.svg'
import { Hidden } from '@material-ui/core';


const ScrollBox = (props) => (
  <div style={{flex: 1, border: '1px solid #E1E5E94D', borderRadius: 5, height: props.height, marginRight: 24, backgroundColor: 'white', boxShadow: '0px 3px 3px rgba(225, 229, 233, 0.3)'}}>
    {props.children}
  </div>
)


const Box = (props) => (
  <div style={{flex: 1, border: '1px solid #E1E5E94D', borderRadius: 5, minHeight: props.height, marginRight: 24, backgroundColor: 'white', boxShadow: '0px 3px 3px rgba(225, 229, 233, 0.3)'}}>
    {props.children}
  </div>
)

class OverviewGlobalView extends React.PureComponent {
  
  state = {
    balances: undefined, 
    totalValue: null,
    assetType: 'spot'
  }

  changeScope = (scope) => {
    this.setState(state => ({...state, assetType: scope}))
  }
  componentDidMount() {

    this.worker = new WebWorker(worker);

    this.worker.addEventListener('message', event => {
        const balances = event.data[0]
        const totalValue = event.data[1]

        this.setState(state => ({...state, 
          balances: balances, 
          totalValue:totalValue
          }))
    });

    if (this.props.balances !== undefined && this.props.balances.connected !== undefined ) {
        this.worker.postMessage(this.props.balances)
    }
    
}

componentDidUpdate(prevProps) {

    if (this.props.balances !== undefined && this.props.balances.connected !== undefined) {
      if ((prevProps.balances !== this.props.balances) || (prevProps.balances.multiplier !== this.props.balances.multiplier)) {
        this.worker.postMessage(this.props.balances)
      }
    }
    
}

componentWillUnmount() {
  this.worker.terminate()
}

openModal = (type, scope, add,) => {

  this.props.toggleModal({type: type, open: true, payload: {scope: scope, context: add, data: null}})

}

  render () {
    return (
      <div style={{paddingTop: 24, paddingLeft: 48, paddingRight: 48, backgroundColor: '#F8F9FA'}}>

        <TopSection totalValue={this.state.totalValue} />
        
        <div style={{display: 'flex', alignItems: 'flex-start', paddingTop: 24,}}>
          <ScrollBox height={350}>
            <div style={{height: 50, borderBottom: '1px solid #E1E5E94D', display: 'flex', alignItems: 'center', paddingLeft: 12}}>
              <div className="basier-p4-caps" style={{color: '#6A7E93'}}>Portfolios</div>
              <div onClick={() => this.openModal('new_portfolio')} className="basier-p4 opacity-hover" style={{userSelect: 'none', color: 'black', marginLeft: 18, cursor: 'pointer', display: 'flex', alignItems: 'center'}}>
                  <div><img src={Plus} alt="" style={{marginRight: 12, height: 20, width: 20}}/></div>
                  <div style={{paddingTop: 0}}>Add</div>
              </div> 
            </div>
            <div className="hover-on-scroll" style={{paddingLeft: 12, paddingRight: 12, paddingBottom: 12, overflow: 'scroll', height: 300}}>
              <PortfolioSection />
            </div>
          </ScrollBox>
          <Hidden smDown>
          <ScrollBox height={350}>
            <div style={{height: 50, borderBottom: '1px solid #E1E5E94D', display: 'flex', alignItems: 'center', paddingLeft: 12}}>
              <div className="basier-p4-caps" style={{color: '#6A7E93'}}>Accounts</div>
              <div onClick={() => this.openModal('new_wallet')} className="basier-p4 opacity-hover" style={{userSelect: 'none', color: 'black', marginLeft: 18, cursor: 'pointer', display: 'flex', alignItems: 'center'}}>
                  <div><img src={Plus} alt="" style={{marginRight: 12, height: 20, width: 20}}/></div>
                  <div style={{paddingTop: 0}}>Add</div>
              </div> 
            </div>
            <div className="hover-on-scroll" style={{paddingLeft: 12, paddingRight: 12, paddingBottom: 12, overflow: 'scroll', height: 300}}>
              <AccountSection openModal={this.openModal} />
            </div>
          </ScrollBox>
          </Hidden>
        </div>
        <Hidden smDown>
          <div style={{marginTop: 24, paddingBottom: 24}}>
            <Box>
              <div style={{width: '100%', padding: 12, display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <h3 style={{margin: 0}}>Holdings</h3>
                <div style={{display: 'flex', alignItems: 'center'}} className="basier-p4-caps no-select">
                  <div style={{
                    color: this.state.assetType === 'spot' ? '#0078DB' : '#6A7E93',
                    fontWeight: this.state.assetType === 'spot' ? 900 : 400, 
                    cursor: 'pointer'
                  }}
                  onClick={() => this.changeScope('spot')}
                  >Spot Asset List</div>
                  <div style={{marginLeft: 6, marginRight: 6}}>|</div>
                  <div style={{
                    color: this.state.assetType === 'position' ? '#0078DB' : '#6A7E93',
                    fontWeight: this.state.assetType === 'position' ? 900 : 400, 
                    cursor: 'pointer'
                  }}
                  onClick={() => this.changeScope('position')}
                  >Derivative Position List</div>

                </div>
              </div>
              <div style={{height: 50, borderBottom: '1px solid #E1E5E94D', display: 'flex', alignItems: 'center', paddingLeft: 12}}>
                <AssetHeaders scope={this.state.assetType} />
              </div>
              {this.state.assetType === 'spot' &&
              <AssetContainer balances={this.state.balances} value={this.state.totalValue} />}
              {this.state.assetType === 'position' &&
              <PositionContainer />}
            </Box>
          </div>
        </Hidden>


      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
      data: state.appwide.wallets,
      balances: state.data.connectedBalances,
  };
};

const mapDispatchToProps = dispatch => {
  return {
      toggleModal: (data) => 
          dispatch(interActions.toggleModal(data)), 
  };
};



export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OverviewGlobalView);