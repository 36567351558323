import React from 'react';
import { relative } from 'path';
import Grid from '@material-ui/core/Grid';
import FlexibleLargeGraph from '../AppWide/FlexibleLargeGraph';
import SmallGraph from '../AppWide/FlexibleSmallGraph';
import { connect } from 'react-redux';
import * as actions from "../../store/actions/data";
import * as interactionActions from "../../store/actions/interactions";
import * as authActions from "../../store/actions/auth";
import PortfolioList from './OverviewComponents/PortfolioLists'
import LargePortfolioList from './OverviewComponents/LargePortfolioList'
import PortfolioTable from './OverviewComponents/PortfolioTable'
import {createLoadingSelector} from '../../store/reducers/selectors'
import OverviewGlobalView from './OverviewComponents/OverviewGlobalView'
import './Overview.css'
import Hidden from '@material-ui/core/Hidden';
import Plus from '../../Media/Plus.svg'
import FirstStepUp from './OverviewComponents/NoPortfolios'
import chartIcon from '../../Media/chartIcon.svg'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import currencyData from '../../helpers/currency.json';
import { withStyles } from '@material-ui/core/styles';
import {formattedNumber} from '../../helpers/helpers'

const formatData = (num) => {
    let ret = null; 
    try {ret = num.toLocaleString('en', {maximumFractionDigits: 2, minimumFractionDigits: 2})} catch(e) { ret = num }
    return ret 
}

const styles = {
    root: {
      color: '#6A7E93',
      '&$checked': {
        color: '#0078DB',
      },
    },
    checked: {
        color: '#0078DB'
    },
  };
class OverviewContainer extends React.PureComponent {
    
    componentDidMount() {

        this.props.setCurrentView(1);
        this.props.setMenuPortfolio('/');
        if (this.props.token !== undefined) {
            this.props.componentDataFetch('get', 1, this.props.currency, this.props.user, null, {}, {}, this.props.inBitcoin, this.props.token)
        } else {
        }
        
    }

    componentDidUpdate(prevProps) {
        if (this.props.token !== undefined && prevProps.token !== this.props.token) {
            this.props.componentDataFetch('get', 1, this.props.currency, this.props.user, null, {}, null, this.props.inBitcoin, this.props.token)
        }
    }

    changeTimePeriod = (newPeriod) => {
        this.setState(state => ({...state, timeperiod: newPeriod}))
    }

    togglePfModal = () => {

        this.props.toggleModal({type: 'new_portfolio', open: true, payload: {} })

    }

    handleBTCChange = e => {

            
        const new_scope = e.target.value;
        //console.log(`new scope is ${new_scope}`)
        const globalData = {
        }
        
        this.props.setPfScope(new_scope)
        this.props.componentDataFetch('update', this.props.currentView, this.props.currency, this.props.user, this.props.portfolioId, globalData, globalData, e.target.value, this.props.token)
        if (new_scope === 'bitcoin') {
            this.props.setBtcSymbol('₿')
        } else {
            let currency_symbol = null;
            try { currency_symbol = currencyData[this.props.currency_name]["symbol"]} catch (e) { currency_symbol = this.props.currency_name}
            this.props.setBtcSymbol(currency_symbol)
        }
        
    }

    render() {

        const { classes } = this.props;
        const symbol_to_use = this.props.coinGraph === true ? "$" : this.props.currencyScope === 'bitcoin' ? "₿" : this.props.currency_symbol     

        return (

            this.props.list_portfolio.length > 0 ?
            <OverviewGlobalView />
            :
            <FirstStepUp newPortModal={this.togglePfModal} />

            
        )
    }

}

const graphLoadingSelector = createLoadingSelector(['UPDATE_GRAPH']);
const graphPortfolioSelector = createLoadingSelector(['UPDATE_ALL_PORTFOLIOS']);

const mapStateToProps = state => {
    return {
        graphData: state.data.globalBalance,
        currency: state.auth.currency.id,
        globalAssetList: state.data.globalAssetList,
        user: state.auth.user_id, 
        list_portfolio: state.auth.list_portfolios,
        loading_graph: graphLoadingSelector(state),
        loading_port: graphPortfolioSelector(state),
        timePeriod: state.data.globalBalance.period,
        loadedGraph: graphLoadingSelector(state),
        last_updated: state.interactions.overviewUpdated,
        inBitcoin: state.data.bitcoinPf,
        token: state.auth.token,
        currentView: state.data.currentView,
        currency_name: state.auth.currency.id,
        currency_symbol: state.auth.currency.symbol,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        componentDataFetch: (scope, view, currency, user, portfolio, globalGraphData, portfolioGraphData, inBitcoin, token) =>
            dispatch(actions.componentDataFetch(scope, view, currency, user, portfolio, globalGraphData, portfolioGraphData, inBitcoin, token)), 
        setMenuPortfolio: (path) => 
            dispatch(actions.setMenuPortfolio(path)), 
        updateTime: (time) => 
            dispatch(actions.setGlobalTime(time)), 
        updateRequestTime: (time) => 
            dispatch(interactionActions.updateRequestTime(time)), 
        setCurrentView: (view) => 
            dispatch(actions.setCurrentView(view)),
        setGraphScope: (currency, scope) => 
            dispatch(actions.setGraphScope(currency, scope)), 
        setPfScope: (currency) => 
            dispatch(actions.setPfScope(currency)), 
        toggleModal: (data) => 
            dispatch(interactionActions.toggleModal(data)), 
        setBtcSymbol: (s) =>
            dispatch(authActions.setBtcSymbol(s)), 
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(OverviewContainer));
