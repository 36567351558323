import React from 'react';
import Tooltip from '../../../../../AppWide/Tooltip';
import { connect } from 'react-redux';
//import questionMark from '../../../../../Media/questionMark.svg'
import help from '../../../../../../Media/help.svg'
import { ClickAwayListener } from '@material-ui/core';

class TransactionTypeSelect extends React.Component {

    state = {
        labels: {
            'fiat': [], 
            'crypto': []
        },
    }

    componentDidMount() {
        this.setState(state => ({...state, activate: false, activeLabel: 0}))

        if (this.props.limits !== undefined ) {
            this.initLabels()
        }
    }

    componentWillUnmount() {
        this.props.updateTransaction('transaction_description', null)
        document.removeEventListener("keydown", this.handleMoveKeys);
    }

    componentDidUpdate(prevProps) {
        if (this.props.limits !== prevProps.limits && this.props.limits !== undefined ) {
            this.initLabels()
        }
        if (this.props.activeType == true && this.props.activeType !== prevProps.activeType) {
            this.setState(state => ({...state, activate: true, activeLabel: this.state.activeLabel}))
            let val = {}; 
            try {val = this.state.labels[this.props.transaction.base_currency.type].filter(v => v.ix == this.state.activeLabel)[0]} catch(e) {}
            const old = this.props.transaction.transaction_description
            const update = ( (old !== val.value) && !(['buy', 'sell', 'ico'].includes(old) && ['buy', 'sell', 'ico'].includes(val.value)) )
            this.props.updateTransaction('transaction_description', val.value)
            this.props.handlePostSelect(val.value, update)

            this.props.toggleActivate()
            document.addEventListener("keydown", this.handleMoveKeys);
        }

    }

    moveTicker = (number) => {
        const max_number = this.props.transaction.base_currency.type === 'crypto' ? 15 : 3
        const min_number = 0

        const currentIndex = this.state.activeLabel
        let nextIndex = currentIndex + number
        if (nextIndex > max_number) {
            nextIndex = max_number
        } else if (nextIndex < min_number) {
            nextIndex = min_number
        }
        this.setState(state => ({...state, activeLabel: nextIndex}))
        const val = this.state.labels[this.props.transaction.base_currency.type].filter(v => v.ix == nextIndex)[0]
        const old = this.props.transaction.transaction_description
        const update = ( (old !== val.value) && !(['buy', 'sell', 'ico'].includes(old) && ['buy', 'sell', 'ico'].includes(val.value)) )
        this.props.updateTransaction('transaction_description', val.value)
        this.props.handlePostSelect(val.value, update)

    }

    handleMoveKeys = (e) => {
        //e.persist();

        if ([37, 38, 39, 40, 13, 9].includes(e.keyCode)  || ['Down', 'ArrowDown', 'Up', 'ArrowUp', 'Left', 'ArrowLeft', 'Right', 'ArrowRight', 'Enter', 'Tab'].includes(e.key)) {

            switch (true) {
                case (e.key === "Down" || e.keyCode === 40) : // IE/Edge specific value
                case (e.key === "ArrowDown" || e.keyCode === 40):
                  if (this.props.transaction.base_currency.type === 'crypto' && this.state.activeLabel <= 7) {
                    this.moveTicker(8)
                  }
                  break;
                case (e.key === "Up" || e.keyCode === 38) : // IE/Edge specific value
                case (e.key === "ArrowUp" || e.keyCode === 38):
                    if (this.props.transaction.base_currency.type === 'crypto' && this.state.activeLabel > 7) {
                        this.moveTicker(-8)
                      }
                    break;
                case (e.key === "Left" || e.keyCode === 37) : // IE/Edge specific value
                case (e.key === "ArrowLeft" || e.keyCode === 37):
                    this.moveTicker(-1)
                    break;
                case (e.key === "Right" || e.keyCode === 39) : // IE/Edge specific value
                case (e.key === "ArrowRight" || e.keyCode === 39):
                    this.moveTicker(1)
                    break;
                case (e.key === "Enter" || e.keyCode === 13 || e.key === "Tab" || e.keyCode === 9 ): {
                  const isShift = !!e.shiftKey;
                  this.moveOn(isShift)
                  break;
                }
                default:
                  break; // Quit when this doesn't handle the key event.
              }


        }


    }

    moveOn = (isShift) => {
        
        this.setState(state => ({...state, activate: false}))
        document.removeEventListener("keydown", this.handleMoveKeys);
        
        if (!isShift) {

            this.props.goToRef();
        } else {

        }
    }

    selectClick = (val) => {
        this.setState(state => ({...state, activate: false, activeLabel: val.ix}))
        const old = this.props.transaction.transaction_description
        const update = ( (old !== val.value) && !(['buy', 'sell', 'ico'].includes(old) && ['buy', 'sell', 'ico'].includes(val.value)) )
        this.props.updateTransaction('transaction_description', val.value)
        this.props.handlePostSelect(val.value, update)
        this.moveOn()
    }

    disableKeyboard = () => {
        this.setState(state => ({...state, activate: false}))
        document.removeEventListener("keydown", this.handleMoveKeys);
    }


    initLabels = () => {

        const transaction_types = {

            "fiat": [
                {ix: 0, value: 'deposit', label: 'Deposit', helper: 'Any fiat money you send into your portfolio, usually through a cryptocurrency excahnge.', enabled: ((this.props.limits.investment < this.props.limits.investment_limit) || (this.props.limits.investment_limit == -1 )) },
                {ix: 1, value: 'withdraw', label: 'Withdraw', helper: 'Any fiat money you take out of your portfolio.', enabled: true},
                {ix: 2, value: 'fee', label: 'Fee', helper: 'Use this to enter any fees you may have paid. This could be for example if you forgot to add deposit fees or if your bank took transfer fees.', enabled: true},
                {ix: 3, value: 'lost', label: 'Loss', helper: 'Use this if you lost fiat or got hacked. This will decrease your crypto balance and show as a net loss.', enabled: true},
            ], 
            "crypto" : [
                {ix: 0, value: 'deposit', label: 'Deposit', helper: 'Any fiat money you send into your portfolio, usually through a cryptocurrency excahnge.', enabled: ((this.props.limits.investment < this.props.limits.investment_limit) || (this.props.limits.investment_limit == -1 )) },
                {ix: 1, value: 'withdraw', label: 'Withdraw', helper: 'Any fiat money you take out of your portfolio.', enabled: true},
                {ix: 2, value: 'buy', label: 'Buy', helper: 'Use this for buying crypto. This will increase the bought crypto balance.', enabled: true},
                {ix: 3, value: 'sell', label: 'Sell', helper: 'Use this for selling crypto. This will decrease the sold crypto balance.', enabled: true},
                {ix: 4, value: 'transfer', label: 'Transfer', helper: 'Use this for transfer of crypto - usually to and from wallets or between exchanges. Overall balance will not change, but any transfer fees will be taken into account.', enabled: true},
                {ix: 5, value: 'ico', label: 'ICO', helper: 'Use this when you bought the token in an ICO.', enabled: true},
                {ix: 6, value: 'fee', label: 'Fee', helper: 'Use this to enter any fees you may have paid. This could be for example for mining, or if you forgot to add trading fees or transfer fees at any point.', enabled: true},
                {ix: 7, value: 'mining', label: 'Mining/ Rewards', helper: 'Use this to enter any crypto rewards gained through mining or staking.', enabled: true},
                {ix: 8, value: 'dividend', label: 'Dividend/ Interest', helper: 'Use this to enter any crypto gained through dividend schemes.', enabled: ((this.props.limits.investment < this.props.limits.investment_limit) || (this.props.limits.investment_limit == -1 ))},
                {ix: 9, value: 'income', label: 'Income', helper: 'Use this to enter any crypto received as income.', enabled: ((this.props.limits.investment < this.props.limits.investment_limit) || (this.props.limits.investment_limit == -1 ))},
                {ix: 10, value: 'gift', label: 'Gift', helper: 'Use this if you received crypto received as a gift. This will increase your balance.', enabled: ((this.props.limits.investment < this.props.limits.investment_limit) || (this.props.limits.investment_limit == -1 ))},
                {ix: 11, value: 'airdrop', label: 'Airdrop', helper: 'Use this to enter any crypto received as airdrops.', enabled: true},
                {ix: 12, value: 'fork', label: 'Fork', helper: 'Use this to enter any crypto received through a fork.', enabled: true},
                {ix: 13, value: 'purchase', label: 'Purchase', helper: 'Use this for Transaction where you used crypto to buy goods/services. This will decrease your balance.', enabled: true},
                {ix: 14, value: 'donation', label: 'Donation', helper: 'Use this if you donated crypto to a third party. This will decrease your balance.', enabled: true},
                {ix: 15, value: 'lost', label: 'Loss', helper: 'Use this if you lost crypto or got hacked. This will decrease your crypto balance and show as a net loss.', enabled: true},
            ]

        }

        this.setState({labels: transaction_types});

    }
    
    buttonRender = (val, key) => {
        return (
            <div
                className={`basier-p4-caps opacity-hover ${ (this.props.transaction.transaction_description == val.value) ? `transaction-color-${val.value}` : 'color-inactive'} ${ this.state.activate === true && (this.state.activeLabel == val.ix) ? 'grow-shaddow' : ''}`}
                key={val.value}
                onClick={() => this.selectClick(val)}
                style={{
                    flex: 1,
                    padding: 12, 
                    height: 37, 
                    fontWeight: 900,
                    color: this.props.transaction.transaction_description == val.value ? 'white' : 'black',
                    marginLeft: key === 0 ? 0 : 7, marginRight: key == 6 || key == this.state.labels['crypto'].length - 9 ? 0 : 6,
                    borderRadius: 10,
                    display: 'flex', 
                    alignItems: 'center', 
                    justifyContent: 'center',
                    cursor: val.enabled === true ? 'pointer' : 'not-allowed'
                }}>
                <div>
                {val.label}
                </div>
                {['ico', 'mining', 'purchase', 'donation', 'gift'].includes(val.value) &&
                <Tooltip title={val.helper} placement="right">
                    <img src={help} style={{height: 18, width: 18, marginLeft: 6}} alt="" />
                </Tooltip>
                }
            </div>
        )
    }

  render() {

    return (
    
        <div style={{width: '100%'}}>   
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%'}}>
                {   
                    this.props.transaction.base_currency !== undefined && this.props.transaction.base_currency !== null ?
                        (this.props.transaction.base_currency.type === 'crypto' || this.props.transaction.base_currency.asset_type === 'crypto') ?
                        this.state.labels['crypto'].slice(0,8).map(
                            (val, key) => (
                            <ClickAwayListener key={key} onClickAway={this.disableKeyboard}>
                            {this.buttonRender(val, key) }
                            </ClickAwayListener>
                            )
                        )

                        :
                        
                        (this.props.transaction.base_currency.type === 'fiat' || this.props.transaction.base_currency.asset_type === 'fiat') ?

                        this.state.labels['fiat'].map(
                            (val, key) => (
                                this.buttonRender(val, key) 
                            )
                        )

                        :

                        null 
                    :

                    null
                    
                    }
                </div>

                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', marginTop: 14}}>

                    {   
                    this.props.transaction.base_currency !== undefined && this.props.transaction.base_currency !== null  ?
                    (this.props.transaction.base_currency.type === 'crypto' || this.props.transaction.base_currency.asset_type === 'crypto') ?
                        this.state.labels['crypto'].slice(8,).map(
                            (val, key) => (
                            this.buttonRender(val, key) 
                            )
                        )

                        :

                        null 
                    :

                    null
                    
                    }

                </div>

        </div>

    )
  }
}


const mapStateToProps = state => {
  return {

      limits: state.appwide.limits,
      
  };
};

const mapDispatchToProps = dispatch => {
  return {
      
  };
};


export default connect(
  mapStateToProps,
  mapDispatchToProps)(TransactionTypeSelect);
