import React from 'react';
import { connect } from 'react-redux';
import GlobalAuditWrapper from './GlobalAuditWrapper'
import Account from './AccountSmall';
import { withRouter } from "react-router-dom";
import SmallAudit from '../MobileOnly/MobileAuditTrail'
import Profile from './Profile';
import Billing from './Billing';

class SmallSettings extends React.PureComponent {
    
    constructor(props) {
        super(props); 
        if( this.props.viewOverRide === undefined) {
           this.props.history.push('settings/overview')
        }
  
     }
        state = {
           selectedIndex: this.props.viewOverRide !== undefined ? this.props.viewOverRide : 'overview',
        }
    
      componentDidUpdate(prevProps) {
          if (this.props.viewOverRide !== prevProps.viewOverRide) {
              this.setState({selectedIndex: this.props.viewOverRide})
          }
      }

      changeMenu = (val) => {
         this.setState(state => ({...state, selectedIndex: val}))
         this.props.history.push(val)
      }

      render () {

        return (

            <div>

            {this.state.selectedIndex === 'audit' && <SmallAudit scope="global" />}
            {this.state.selectedIndex === 'account' && <Account />}
            {this.state.selectedIndex === 'profile' && <div style={{marginTop: 24, marginBottom: 48}}><Profile /></div>}
            {this.state.selectedIndex === 'billing' && <div style={{padding: 12}}><Billing /></div>}
            {this.state.selectedIndex === 'overview' &&
            <div style={{top: '130px', width: '100%', backgroundColor: '#F8F9FA', padding: 48, paddingBottom: 12}}>
               
               <h3>Settings</h3>


                <div style={{marginTop: 0}}>

                    {[
                        ['account','Account'],
                        ['profile','Profile'],
                        ['billing','Billing']
                    ].map((val, key) => {
                        
                        return (

                            <div 
                                key={key}
                                style={{ 
                                height: 60,
                                paddingLeft: 24, 
                                paddingRight: 24, 
                                width: '100%',
                                }} value={val}
                                className="coin-flat-list-item basier-p2"
                                onClick={() => this.changeMenu(val[0])}>
                        
                        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%',}}>
                            <div>
                                <div style={{color: 'black', textTransform: 'capitalize'}} className="basier-p2">{val[1]}</div>
                            </div>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0.999999 0.856573L7 6.85413L1 12.8541" stroke="#000000" stroke-miterlimit="10"/>
                                </svg>
                            </div>
    
                        </div>
    
                    
                    </div>

                        )


                    })}
                    


            </div>
        </div>
        }
        </div>


                
               
               
        )
    }


}

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = dispatch => {
    return {
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SmallSettings));