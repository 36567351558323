import { Component } from 'react';
import { jsx } from '@emotion/core';
import Button from '@atlaskit/button';
import React from 'react';

import Select from 'react-select';
import { defaultTheme } from 'react-select';

const stateOptions = [{label: "ALA", value: 'al'}, {label: "DFG", value: 'alfd'}, {label: "FFF", value: 'afdl'}]

const { colors } = defaultTheme;

const selectStyles = {
    container: styles => ({...styles, backgroundColor: 'transparent', width: 252, border: '1px solid #E1E5E9', marginTop: 0}),
    menuList: styles => ({...styles, backgroundColor: 'white', width: 252, marginTop: 0}),
    menu: styles => ({...styles, backgroundColor: 'blue', width: 252, border: '1px solid #E1E5E9', margin: 0, boxShadow: 'none', borderRadius: 0}),
    control: styles => ({ ...styles, backgroundColor: 'white', width: 232, margin: 10, fontSize: 14, color: 'black', dispay:'inline-block'}),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        padding: 8,
        width: 252,
        fontFamily: 'Basier Circle',
        height: 30,
        cursor: 'default',
        backgroundColor: 'white',
        color: isDisabled
          ? '#F3F5F6'
          : isSelected ? 'black' : 'black',
        opacity: isDisabled
          ? '1'
          : isSelected ? '1' : '0.7',
      };
    },
  
  };

class PopoutExample extends Component {
  state = { isOpen: false, value: undefined };
  toggleOpen = () => {
    this.setState(state => ({ isOpen: !state.isOpen }));
  };
  onSelectChange = value => {
    this.toggleOpen();
    this.setState({ value });
  };
  render() {
    const { isOpen, value } = this.state;
    return (
      <Dropdown
        isOpen={isOpen}
        onClose={this.toggleOpen}
        target={
          <Button
            iconAfter={<ChevronDown />}
            onClick={this.toggleOpen}
            isSelected={isOpen}
          >
            {value ? `State: ${value.label}` : 'Select a State'}
          </Button>
        }
      >
        <Select
          autoFocus
          backspaceRemovesValue={false}
          components={{ DropdownIndicator, IndicatorSeparator: null }}
          controlShouldRenderValue={false}
          hideSelectedOptions={false}
          isClearable={false}
          menuIsOpen
          isMulti
          onChange={this.onSelectChange}
          options={stateOptions}
          placeholder="Search..."
          styles={selectStyles}
          tabSelectsValue={false}
          value={value}
        />
      </Dropdown>
    );
  }
}

// styled components

const Menu = props => {

  return (
    <div
      style={{
        backgroundColor: 'white',
        borderRadius: 0,
        boxShadow: 'none',
        marginTop: 8,
        position: 'absolute',
        zIndex: 2,
      }}
      {...props}
    />
  );
};
const Blanket = props => (
  <div
    style={{
      bottom: 0,
      left: 0,
      top: 0,
      right: 0,
      position: 'fixed',
      zIndex: 1,
    }}
    {...props}
  />
);
const Dropdown = ({ children, isOpen, target, onClose }) => (
  <div style={{ position: 'relative' }}>
    {target}
    {isOpen ? <Menu>{children}</Menu> : null}
    {isOpen ? <Blanket onClick={onClose} /> : null}
  </div>
);
const Svg = p => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    focusable="false"
    role="presentation"
    {...p}
  />
);
const DropdownIndicator = () => (
  <div css={{ color: colors.neutral20, height: 24, width: 32 }}>
    <Svg>
      <path
        d="M16.436 15.085l3.94 4.01a1 1 0 0 1-1.425 1.402l-3.938-4.006a7.5 7.5 0 1 1 1.423-1.406zM10.5 16a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </Svg>
  </div>
);
const ChevronDown = () => (
  <Svg style={{ marginRight: -6 }}>
    <path
      d="M8.292 10.293a1.009 1.009 0 0 0 0 1.419l2.939 2.965c.218.215.5.322.779.322s.556-.107.769-.322l2.93-2.955a1.01 1.01 0 0 0 0-1.419.987.987 0 0 0-1.406 0l-2.298 2.317-2.307-2.327a.99.99 0 0 0-1.406 0z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </Svg>
);

export default PopoutExample