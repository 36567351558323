import React from 'react';
import { connect } from 'react-redux';
import * as actions from "../../../../../../store/actions/interactions";
import * as dataActions from "../../../../../../store/actions/data";
import Switch from '../../../../../Assets/Switch';
import UserList from '../../../../../MobileOnly/UserDrawer';

import currencyDict from '../../../../../../helpers/currency.json';

var moment = require('moment');
require('moment/locale/en-gb');

function getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
  
        // Windows Phone must come first because its UA also contains "Android"
      if (/windows phone/i.test(userAgent)) {
          return "Windows Phone";
      }
  
      if (/android/i.test(userAgent)) {
          return "Android";
      }
  
      // iOS detection from: http://stackoverflow.com/a/9039885/177710
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
          return "iOS";
      }
  
      return "unknown";
  }

class assetModalContent extends React.PureComponent {

    

    constructor(props) {

        super(props)
        

          this.state = {
            
          }
  
      }

    
    render () {

        return (    

                    <div>

                        <div>

                                <div style={{display: 'flex', alignItems: 'center', paddingLeft: 12, paddingRight: 12}} className="basier-p2">
                                                <div style={{position:'absolute', left: 24}}></div>

                                                {this.props.passState.os === "Android" ? 

                                                    <div style={{display: 'flex', height: 50, alignItems: 'center', marginTop: 0, width: '100%',}} className="basier-p2">
                                                        <div style={{position:'absolute', left: 24}}>Date</div>
                                                        <input value={this.props.passState.stateDate || ""} onChange={(e) => this.props.handleDate(e)} style={{width: '100%', textAlign: 'right', display: 'flex', justifyContent: 'flex-end',  height: 50, backgroundColor: 'white', border: 'none',borderTop: '1px solid #E1E5E9', paddingRight: 0}} type="datetime-local" className="no-outline" />
                                                    </div>

                                                :

                                                <div style={{display: 'flex', width: '100%', justifyContent: 'space-between'}}>

                                                    <div style={{width: '49%'}}>
                                                        <div className="basier-p4-caps" style={{width: '100%', marginBottom: 6}}>
                                                            Date
                                                        </div>
                                                        <input value={this.props.passState.stateDate || ""} onChange={(e) => this.props.handleDate(e)} style={{textAlign: 'right', width: '100%',  height: 50, border: '1px solid #E1E5E9', paddingRight: 12}} type="date" className="no-outline" />
                                                    </div>

                                                    <div style={{width: '49%'}}>
                                                        <div className="basier-p4-caps" style={{width: '100%', marginBottom: 6}}>
                                                            Time
                                                        </div>
                                                        <input value={this.props.passState.stateTime || ""} onChange={(e) => this.props.handleTime(e)} style={{width: '100%', textAlign: 'right',  height: 50, border: '1px solid #E1E5E9', paddingRight: 12}} type="time" className="no-outline" />
                                                    </div>

                                                </div>

                                                }
                                       
                                    </div>

                                {

                                this.props.passState.extraData['user_transaction'] !== null && this.props.passState.extraData['user_transaction'] !== undefined ?

                                <div style={{marginTop: 12, display: 'flex', alignItems: 'center',}}>

                                    <div 
                                            style={{
                                            height: 60,
                                            display: 'flex', 
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                            paddingLeft: 24,
                                            borderBottom: '1px solid #E1E5E9',
                                            borderTop: '1px solid #E1E5E9',
                                            width: '100%',
                                            textTransform: 'uppercase',
                                            fontWeight: 900
                                        }}
                                            onClick={() => this.props.userModalOpen()}
                                            className="basier-p3">
                                    
                                            
                                            <div style={{color: 'black', fontWeight: 900}}>
                                                User
                                            </div>
        
                                            <div style={{marginRight: 24, display: 'flex', alignItems: 'center'}}>
        
                                                <div style={{marginRight: 24, color: '#6A7E93'}}>
                                                    {
                                                        this.props.passState.extraData['user_transaction'] !== undefined && this.props.passState.extraData['user_transaction'] !== null ? 
                                                        
                                                        <div style={{display: 'flex', alignItems: 'center'}}>
                                                            <div>
                                                                {this.props.passState.extraData['user_transaction']['label']}
                                                            </div>
                                                        </div>
                                                        
                                                        :
                                                        <div>select...</div>
                                                    }
                                                </div>
        
                                                <div>
                                                    >
                                                </div>
                                            </div>
        
                                            
        
                                    </div>
    
                                </div> 

                                :

                                    null 
                            }
                        </div>

                    </div>
                            
        )

    }
   
}

const mapStateToProps = state => {
    return {

        currency_symbol: state.auth.currency.symbol,
        coins: state.appwide.all_coins,
        transaction: state.interactions.transactionAddData,
        portfolio_users: state.data.scopedUsers, 
        user_id: state.auth.user_id, 
        user_first_name: state.auth.user_first_name,
        user_last_name: state.auth.user_last_name,
        
    };
};

const mapDispatchToProps = dispatch => {
    return {
        addTransaction: (data) =>
            dispatch(actions.addTransaction(data)),
        updateTransaction: (data) =>
            dispatch(actions.updateTransaction(data)),
        removeTransaction: (data) =>
            dispatch(actions.removeTransaction(data)), 
        callUserList: (portfolio) =>
          dispatch(dataActions.callUserList(portfolio)), 
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(assetModalContent);
