
export default () => {
	self.addEventListener('message', e => { // eslint-disable-line no-restricted-globals
		
		if (!e) return;

		let storage = []
		let exchange = []

        const storage_data = e.data['storage']
        Object.values(storage_data).map(v => {
            Object.values(v).map(val => {
                storage.push({
                    name: val.name.toUpperCase(), 
                    source_name: val.coin_name, 
                    user: val.user_id, 
                    image: val.coin_image, 
                    address: val.address, 
                    connected: val.connector !== null,
                    connector: val.connector,
                    sub_name: val.ticker,
                    target_id: val.target_id,
                    link: val.explorer,
                    id: val.id,
                    source_id: val.wallet_coin,
                    date: val.created_at,
                    deposit_type: val.deposit_type,
                    last_update: val.last_update_check,
                    wallets: val.wallets,
                    type: 'storage'
                })

            })
        })

        const exchange_data = e.data['exchange']
        const custom_data = e.data['custom']

        Object.values(exchange_data).map(v => {
            Object.values(v).map(val => {
                exchange.push({
                    name: val.name.toUpperCase(), 
                    source_name: val.exchange_name, 
                    image: val.exchange_image, 
                    user: val.user_id, 
                    address: null, 
                    connected: val.connected,
                    target_id: val.target_id,
                    sub_name: null,
                    link: val.url,
                    id: val.id,
                    source_id: val.exchange_id,
                    custom_exchange_check: false,
                    date: val.created_at,
                    last_update: val.last_update_check,
                    wallets: val.wallets,
                    key_error: val.key_error,
                    deposit_type: 'deposit',
                    type: 'exchange'
                })

            })
        })

        Object.values(custom_data).map(v => {
            Object.values(v).map(val => {
                exchange.push({
                    name: val.name.toUpperCase(), 
                    source_name: val.custom_exchange, 
                    image: null, 
                    address: null, 
                    user: val.user_id, 
                    connected: false,
                    target_id: val.target_id,
                    sub_name: null,
                    link: null,
                    id: val.id,
                    source_id: val.custom_exchange,
                    custom_exchange_check: true, 
                    date: val.created_at,
                    last_update: val.last_update_check,
                    wallets: val.wallets,
                    deposit_type: 'deposit',
                    type: 'exchange'
                })

            })
        })

        const exchange_ret = exchange.sort(function compare(a, b) {
            if(a.name < b.name) { return -1; }
            if(a.name > b.name) { return 1; }
            return 0;
            })
        
        const storage_ret = storage.sort(function compare(a, b) {
            if(a.name < b.name) { return -1; }
            if(a.name > b.name) { return 1; }
            return 0;
            })
		postMessage([exchange_ret, storage_ret]);

	})
}