import React from "react";
import { connect } from "react-redux";
import TransactionRow from "./TransactionRow";
import Oops from "../../../Media/oops.svg";
import PositionRow from "./PositionRow";
import { backendUrl } from "../../../variables";
import Axios from "axios";
import * as actions from "../../../store/actions/data";

class TableBody extends React.PureComponent {
  state = {
    update: false,
  };

  

  render() {
    return (
      <div>
        {this.props.data.length > 0 ? (
          this.props.data.map((v, key) => {
            return this.props.scope === "transaction" ? (
              <TransactionRow
                getData = {this.props.getData}
                update={this.state.update}
                key={key}
                selectTransaction={this.props.selectTransaction}
                data={v}
                selectAll={this.props.selectAll}
                selectSingle={this.props.selectSingle}
                selected={this.props.selected}
                allSelected={this.props.allSelected}
                account={this.props.account}
                fullSelect={this.props.fullSelect}
                excluded={this.props.excluded}
              />
            ) : (
              <PositionRow
                update={this.state.update}
                key={key}
                selectTransaction={this.props.selectTransaction}
                data={v}
                selectAll={this.props.selectAll}
                selectSingle={this.props.selectSingle}
                selected={this.props.selected}
                allSelected={this.props.allSelected}
                account={this.props.account}
                fullSelect={this.props.fullSelect}
                excluded={this.props.excluded}
              />
            );
          })
        ) : (
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "flex-start",
              marginTop: 12,
            }}
          >
            <img src={Oops} alt="" style={{ height: 100, marginTop: 120 }} />
            <div className="basier-p3">No records found</div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TableBody);
