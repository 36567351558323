import React from 'react';
import PropTypes from 'prop-types';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import * as actions from "../../../store/actions/interactions";
import * as appwideActions from "../../../store/actions/appwide";
import * as authActions from "../../../store/actions/auth";
import * as dataActions from "../../../store/actions/data";
import { connect } from 'react-redux';
import BaseModalTop from '../../Portfolio/PortfolioComponents/Transactions/BaseModalTop';
import DateTimePicker from 'react-datetime';
import TypeList from '../../Portfolio/PortfolioComponents/Transactions/NewTransaction/QuestionBlocks/TypeList';
import loadingSpinner from '../../../Media/loadingSpinner.gif';
import CoinSearch from '../../AppWide/CoinSearch';
import okIcon from '../../../Media/okIcon.svg';
import SoloPort from '../../../Media/SoloPort.svg';
import FiatLarge from '../../AppWide/FiatLarge';
import OrgLarge from '../../AppWide/OrgList';
import Collablist from '../../AppWide/CollabList';
import axios from 'axios';
import {updatePortfolios} from '../../../helpers/portfolioHelpers';
import fieldValidator from '../../../helpers/validators';
import { backendUrl } from '../../../variables';
import Oops from '../../../Media/oops.svg';
import { withRouter} from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';
import { Link as RouterLink } from 'react-router-dom'
import { Link } from '@material-ui/core';

function getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
  
        // Windows Phone must come first because its UA also contains "Android"
      if (/windows phone/i.test(userAgent)) {
          return true;
      }
  
      if (/android/i.test(userAgent)) {
          return true;
      }
  
      // iOS detection from: http://stackoverflow.com/a/9039885/177710
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
          return true;
      }
  
      return false;
  }

const styles = theme => ({
    container: {
        width: '100%', 
        padding: 48, 
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            padding: 12, 
        },
    },
    image: {
        display: 'block', 
        [theme.breakpoints.down('sm')]: {
            display: 'none', 
        },
    },
    ImageText: {
        [theme.breakpoints.down('sm')]: {
            padding: 12 
        },
    },
    subContainer: {
        padding: 12,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            height: '100%',
        },
        [theme.breakpoints.up('md')]: {
            width: '48%',
            height: '100%',
        }
    },
    secondSub: {
        [theme.breakpoints.up('md')]: {
            height: 350,
        }
    }, 
    secondQuestions: {

        [theme.breakpoints.down('sm')]: {
            padding: 0
        },
        [theme.breakpoints.up('md')]: {
            marginTop: 0
        }

    }, 
    input: {
        
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 24, width: '100%', marginTop: 12, 
        },
        [theme.breakpoints.up('md')]: {
            paddingLeft: 24, width: 324, marginTop: 12, 
        }
    },
    fiatContainer: {
        
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        },
        [theme.breakpoints.up('md')]: {
            width: 324
        }
    }
    });

class ResponsiveDialog extends React.Component {

    state = {
        loading: false,
        success: false, 
        data: {
            "requesting_user": this.props.user_id,
            "user": [{ value: this.props.user_id }], 
            "portfolio_ownership": 'individual',
        }, 
        validation: {
            name: true, 
            organization: true, 
            organization_name: true, 
            user: true, 
            currency: true
        }
    }

    updateData = (label, val) => {

        if (label === 'portfolio_ownership' && ( (this.props.plan.plan.plan_id === 'pro' || this.props.plan.plan.plan_id === 'enterprise' || this.props.plan.plan.plan_id === 'unlimited') && this.props.plan.subscription_status == 1)) {
            this.setState(state => ({...state, data: {...state.data, user: [{ value: this.props.user_id }], organization: null, organization_name: null, [label]: val}}))
        } else if (label !== 'portfolio_ownership' ) {
            this.setState(state => ({...state, data: {...state.data, [label]: val}}))
            }
        }

    handleClose = () => {

        this.props.toggleModal({type: null, open: false, payload: null})
       
      }
    
    goToAccountSettings = () => {
        this.props.history.push('/settings')
        this.handleClose()
    }

    goToPortfolio = (portId) => {
        this.props.setMenuPortfolio(portId)
        this.props.history.push(`/portfolio/${portId}`)
        this.props.componentDataFetch('get', 2, this.props.currency, this.props.user_id, portId, {}, {}, null)
        window.setTimeout(() => {
        this.handleClose()
        }, 200)
    }
    renderIndividual = (classes) => {
        return ( 
            <div style={{display: 'flex', flexDirection: 'column'}}>

                <div className={classes.image}>
                    <img src={SoloPort} alt="" />
                </div>
                <div style={{marginTop: 0}} className={`basier-p4 ${classes.ImageText}`}>
                    Individual portfolios are great for personal investments. You cannot add collaborators on invididual portfolios... but it is really easy to upgrade them to shared portfolio in the settings later on! 
                </div>

            </div>
        )
    }

    updateServer = () => {

        const name_val = fieldValidator(this.state.data['name'], ['isNotEmpty'])
        this.setState(state => ({...state, validation: {...state.validation, name: name_val}}))

        const users_val = fieldValidator(this.state.data['user'], ['isNotEmpty'])
        this.setState(state => ({...state, validation: {...state.validation, user: users_val}}))

        const currency_val = fieldValidator(this.state.data['currency'], ['isNotEmpty'])
        this.setState(state => ({...state, validation: {...state.validation, currency: currency_val}}))

        let org = true
        let org_name = true
        if (this.state.data['portfolio_ownership'] === 'organization') {
            org = fieldValidator(this.state.data['organization'], ['isNotEmpty'])
            this.setState(state => ({...state, validation: {...state.validation, organization: org}}))
        }

        let valid = name_val && users_val && org

        if (valid) {

        //console.log(this.state)
            this.setState(state => ({...state, loading: true}))
            let headers = { "content-type": "application/json" };
            if (this.props.token !== null && this.props.token !== undefined) {

                headers['Authorization'] = `Token ${this.props.token}`

            }

            const url_call = `${backendUrl}/v1/portfolio/`

            axios.post(url_call, this.state.data, {headers: headers})
                .then(json => {
                    
                
                    this.props.addPort(1)

                    if (this.props.currentView === 1) {

                        this.props.componentDataFetch('get', this.props.currentView, this.props.currency, this.props.user_id, null, {}, {}, null)
                        this.props.getOrgs(this.props.user_id)
                    }

                    const newId = json['data']['new']
                    const newRoles = {...this.props.roles, [newId]: "admin"}
                    this.props.updateRoles(newRoles)
                    this.props.getOrgs(this.props.user_id)

                    this.setState(state => ({...state, loading: false, success: true, newPortId: json['data']['new']}))
                    
                    const success = updatePortfolios(json['data']['data'])
                    if (success) {
                        this.props.setPorts(json['data']['data'])
                    }
                    
                    const toClose = getMobileOperatingSystem()
                    if (toClose){ 
                        this.handleClose()
                    }

                })
                .catch( e => {

                this.setState(state => ({...state, loading: false, error: true}))

                })
        
        }

    }

    addCollab = (collab) => {

        this.setState(state => ({...state, data: {...state.data, user: collab}}))

    }

    switchTransactions = (port) => {

        this.props.toggleModal({type: 'add_transaction', open: true, payload: {overridePort: true, overridePortId: port}})

    }

    render() {

    const { classes } = this.props;

    return (

      <div>
        
          <BaseModalTop forceSingle={true} title="New portfolio" handleClose={this.handleClose} onClick={this.backToWelcome} welcome={this.state.welcome} entryType={this.state.type_selected} />


          {

            this.state.loading ? 

          <div style={{width: '100%', height: 530, backgroundColor: 'white', opacity: 0.6, display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'absolute', top: 90, zIndex: 13000}}>
              <img style={{height: 64, with: 64}} src={loadingSpinner} alt="Loading..."/>
          </div>


          :

          this.state.success ? 

            <div style={{width: '100%', height: '100%', backgroundColor: 'white', opacity: 1, display: 'flex', flexDirection: 'column', paddingTop: 100, alignItems: 'center', position: 'absolute', top: 90, zIndex: 13000}}>
                <img style={{height: 64, with: 64}} src={okIcon} alt="OK!"/>
                <div style={{marginTop: 24}} className="basier-p2">
                  Portfolio Created!
                </div>
                <Hidden smDown>
                    <div className="basier-p3">
                        Your can now start adding transactions and tracking your assets!
                    </div>
                </Hidden>
                <div style={{marginTop: 24, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>

                <div className='basier' onClick={this.handleClose} 
                    style={{height: 40, borderRadius: 35, marginLeft: 12, marginRight: 12, marginTop: 24, cursor: 'pointer', color: 'white', backgroundColor: '#6A7E93', width: 200, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    Close
                </div>

                <Hidden smDown>


                        <div className='button-next-blue'
                            onClick={() => this.goToPortfolio(this.state.newPortId)}
                            style={{width: 200, height: 40, borderRadius: 35, marginLeft: 12, marginRight: 12, marginTop: 24, cursor: 'pointer'}}>
                            Go To Portfolio
                        </div>


                </Hidden>

                </div>
            </div>

          :

          null 

          }


          

            <div className={classes.container}>

            {
              this.props.limits !== null && this.props.limits !== undefined ? 
              
              this.props.limits.portfolio_limit === -1 || (this.props.limits.portfolio_limit > this.props.limits.portfolios) ? 

            <React.Fragment>
                <div className={classes.subContainer}>

                    <div>
                        <div className="basier-p4-caps" style={{color: 'black', textTransform: 'uppercase', fontWeight: 900}}>
                            Portfolio type
                        </div>
                        <div style={{display: 'flex', alignItems: 'center'}}>

                        <div className={`${this.state.data['portfolio_ownership'] === 'individual' ? 'toggle-button-active' : 'toggle-button'}`} onClick={() => this.updateData('portfolio_ownership', 'individual')} 
                          style={{height: 40, width: 150, borderRadius: 35, marginRight: 12, marginTop: 24, cursor: 'pointer'}}>
                                Individual
                            </div>

                        {( (this.props.plan.plan.plan_id === 'pro' || this.props.plan.plan.plan_id === 'unlimited' || this.props.plan.plan.plan_id === 'enterprise') && this.props.plan.subscription_status == 1) &&
                        <div className={`${this.state.data['portfolio_ownership'] === 'organization' ? 'toggle-button-active' : ( (this.props.plan.plan.plan_id === 'pro' || this.props.plan.plan.plan_id === 'unlimited') && this.props.plan.subscription_status == 1) ? 'toggle-button' : 'inactive-none'}`} onClick={() => this.updateData('portfolio_ownership', 'organization')} 
                          style={{height: 40, width: 150, borderRadius: 35, marginLeft: 12, marginTop: 24, cursor: ( (this.props.plan.plan.plan_id === 'pro' || this.props.plan.plan.plan_id === 'unlimited') && this.props.plan.subscription_status == 1) ? 'pointer' : 'not-allowed'}}>
                                Pool / Organization
                            </div>
                        }

                        </div>
                    </div>

                    { this.state.data['portfolio_ownership'] !== null && this.state.data['portfolio_ownership'] !== undefined ? 
                    <div>

                        <div style={{marginTop: 24,}}>
                            <div className="basier-p4-caps" style={{color: 'black', textTransform: 'uppercase', fontWeight: 900}}>
                                Portfolio name
                            </div>
                            <div>
                                <input onChange={(e) => this.updateData('name', e.target.value)} className={`light-input ${!this.state.validation.name ? 'error-input' : null} ${classes.input}`} value={this.state.data.name} 
                                />
                            </div>
                        </div>

                        <div>
                            <div style={{marginTop: 24,}}>
                                <div className="basier-p4-caps" style={{color: 'black', textTransform: 'uppercase', fontWeight: 900, marginBottom: 12}}>
                                    Portfolio currency
                                </div>
                                <div className={classes.fiatContainer}>
                                    <FiatLarge data={this.props.currencyFiatData} valid={this.state.validation.currency} handleChange={(val) => this.updateData('currency', val)} />
                                </div>
                            </div>
                        </div>

                    </div>

                    :
                    null
                    }
                
                </div>

                <div className={classes.subContainer}>

                    <div className={classes.secondSub}>
                        
                        {
                            this.state.data['portfolio_ownership'] === 'individual' && this.renderIndividual(classes)
                        }

                        {
                            this.state.data['portfolio_ownership'] === 'organization' ?

                            <div>
                                <div className={classes.secondQuestions}>
        
                                    <div className="basier-p4-caps" style={{paddingLeft: 0, color: 'black', textTransform: 'uppercase', fontWeight: 900}}>
                                        Organization
                                    </div>
                
                                    <div style={{marginTop: 22}}>
                                        <OrgLarge validOrg={this.state.validation.organization} validName={this.state.validation.organization_name} handleChange={(label, val) => this.updateData(label,val)} />
                                    </div>
            
                                </div>

                                

                                <div style={{display: 'flex', flexDirection: 'column'}}>

                                    <div>
                                        <div style={{marginTop: 24}}>
                                            <div className="basier-p4-caps" style={{paddingLeft: 0, color: 'black', textTransform: 'uppercase', fontWeight: 900, marginBottom: 12}}>
                                                Collaborators
                                            </div>
                                            <div>
                                                <Collablist orgId={this.state.data['organization']} handleAdd={(o) => this.addCollab(o)} />
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            
                            </div>

                            :

                        null
                        }
                    </div>

                    <div style={{height: 150}}>

                        <div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>

                            <div className='basier' onClick={this.handleClose} 
                                style={{height: 40, borderRadius: 35, marginRight: 12, marginTop: 24, cursor: 'pointer', color: 'white', backgroundColor: '#F9716B', width: 150, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                Cancel
                            </div>

                            <div className='button-next-blue' onClick={() => this.updateServer()} 
                                style={{height: 40, borderRadius: 35, marginLeft: 12,  marginTop: 24, cursor: 'pointer'}}>
                                Submit
                            </div>

                        </div> 

                    </div>

                </div>
            </React.Fragment>
                    
            :

            <div style={{paddingLeft: 48, paddingRight: 48, height: 520, width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column', textAlign: 'center'}}>
              <img src={Oops} alt="" style={{height: 100, marginTop: 60}} />
              <h3 style={{marginTop: 12, fontWeight: 900, color: '#0078DB'}}>
                  Portfolio Limit Reached
              </h3>
              <div style={{marginTop: 24, fontWeight: 400,}} className="basier-p3">
                  It looks like you have reached the limit of number of portfolios permitted with your current plan. If you wish to add more transactions, you can upgrade your plan by going to your <span onClick={this.goToAccountSettings}className="opacity-hover" style={{cursor: 'pointer', color: '#0078DB'}}>Account Settings</span>
              </div>
            </div>

            :

            null

            }

            </div>

            

        </div>

    )
  }
}

ResponsiveDialog.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
};


const mapStateToProps = state => {
    return {

        currencyFiatData: state.data.fiatList,
        modalPref: state.interactions.transactionAddModal,
        portfoliosData: state.data.portfoliosData,
        transaction: state.interactions.transactionAddData,
        all_coins: state.appwide.all_coins, 
        exchangeCheck: state.interactions.exchangeCheck, 
        exchanges: state.appwide.exchanges, 
        wallets: state.appwide.wallets, 
        user_id: state.auth.user_id,
        user_first: state.auth.user_first_name,
        user_last: state.auth.user_last_name,
        token: state.auth.token,
        currentView: state.data.currentView,
        currency: state.auth.currency.id,
        graphData: state.data.globalBalance,
        plan: state.auth.subscription,
        limits: state.appwide.limits,
        roles: state.auth.roles
        
    };
};

const mapDispatchToProps = dispatch => {
    return {
       toggleModal: (data) => 
            dispatch(actions.toggleModal(data)), 
        removeTransaction: (data) =>
            dispatch(actions.removeTransaction(data)), 
        setTrTime: (data) => 
            dispatch(actions.setTrTime(data)), 
        setInteraction: (data) => 
            dispatch(actions.setInteraction(data)), 
        getOrgs: (user) => dispatch(appwideActions.getOrgs(user)), 
        getWallets: (user) => dispatch(appwideActions.getWallets(user)), 
        addPort: (v) => dispatch(appwideActions.addPort(v)), 
        setPorts: (ports) => dispatch(authActions.setPorts(ports)),
        componentDataFetch: (scope, view, currency, user, portfolio, globalGraphData, portfolioGraphData, inBitcoin) =>
            dispatch(dataActions.componentDataFetch(scope, view, currency, user, portfolio, globalGraphData, portfolioGraphData, inBitcoin)), 
        updateRoles: (role) => dispatch(authActions.updateRoles(role)),
        setMenuPortfolio: (portfolio) =>
          dispatch(dataActions.setMenuPortfolio(portfolio)), 
        setMenuPath: (path) =>
          dispatch(dataActions.setMenuPath(path)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withMobileDialog()(withRouter(withStyles(styles)(ResponsiveDialog))));
