import React from "react";
import logo from "../../../Media/whiteLogo.svg";
import Hidden from "@material-ui/core/Hidden";

const noPortfolio = (props) => {
  return (
    <React.Fragment>
      <Hidden smDown>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start",
            marginLeft: 0,
            paddingTop: 90,
            paddingBottom: 90,
            backgroundColor: "#F8F9FA",
          }}
        >
          <div
            style={{
              height: 300,
              width: 250,
              backgroundColor: "#0078DB",
              boxShadow: "0px 10px 10px rgba(0, 0, 0, 0.3)",
              paddingLeft: 24,
            }}
          >
            <div
              style={{
                fontSize: 48,
                color: "black",
                display: "flex",
                justifyContent: "flex-start",
              }}
              className="poppins"
            >
              <img src={logo} style={{ height: 50, marginTop: 24 }} alt="" />
            </div>
            <p
              style={{ fontSize: 30, color: "white", marginTop: 24 }}
              className="poppins"
            >
              Welcome
            </p>
          </div>
          <div
            style={{
              height: 400,
              width: 600,
              backgroundColor: "#6A7E93",
              marginTop: 20,
              marginLeft: -50,
              boxShadow: "0px 10px 10px rgba(0, 0, 0, 0.3)",
              display: "flex",
              justifyContent: "center",
              padding: 30,
            }}
          >
            <div
              style={{
                
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  textAlign: "center",
                  fontSize: 30,
                  color: "white",
                  textTransform: "uppercase",
                }}
                className="poppins"
              >
                Get started
              </div>
              <div
                style={{ marginTop: 48, color: "white" }}
              >
                <div style={{ display: "flex", alignItems: "center" , marginBottom: 12}}>
                  <div
                    style={{
                      minWidth: 30,
                      minHeight: 30,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: 20,
                      backgroundColor: "white",
                      color: "#6A7E93",
                      fontWeight: 900,
                      marginRight: 12,
                    }}
                    className="basier-p3"
                  >
                    1
                  </div>
                  <div className="basier-p3" style={{color: 'white'}}>
                    Create your first portfolio.
                  </div>
                </div>
                <div style={{ display: "flex", alignItems: "center" , marginBottom: 12}}>
                  <div
                    style={{
                      minWidth: 30,
                      minHeight: 30,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: 20,
                      backgroundColor: "white",
                      color: "#6A7E93",
                      fontWeight: 900,
                      marginRight: 12,
                    }}
                    className="basier-p3"
                  >
                    2
                  </div>
                  <div className="basier-p3" style={{color: 'white'}}>
                    Import transactions by creating API Connected Accounts, or by importing CSVs
                  </div>
                </div>
                <div style={{ display: "flex", alignItems: "center" , marginBottom: 12}}>
                  <div
                    style={{
                      minWidth: 30,
                      minHeight: 30,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: 20,
                      backgroundColor: "white",
                      color: "#6A7E93",
                      fontWeight: 900,
                      marginRight: 12,
                    }}
                    className="basier-p3"
                  >
                    3
                  </div>
                  <div className="basier-p3" style={{color: 'white'}}>
                    Complete Portfolio by entering manual transactions if needed.
                  </div>
                </div>
              </div>
              <div
                style={{
                  height: 60,
                  width: 300,
                  fontSize: 18,
                  borderRadius: 35,
                  marginTop: 24,
                  marginLeft: 12,
                  marginRight: 12,
                  cursor: "pointer",
                  backgroundColor: "white",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onClick={props.newPortModal}
              >
                Create first portfolio
              </div>
            </div>
          </div>
        </div>
      </Hidden>

      <Hidden mdUp>
      <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start",
            flexDirection: 'column',
            marginLeft: 0,
            paddingBottom: 90,
            backgroundColor: "#F8F9FA",
            paddingLeft: 12, 
            paddingRight: 12,
          }}
        >
          <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
            <p
              style={{ fontSize: 30, color: "black", textAlign: 'center' }}
              className="poppins"
            >
              Welcome
            </p>
          </div>
          <div
            style={{
              height: 400,
              width: '100%',
              backgroundColor: "#6A7E93",
              marginTop: 20,
              marginLeft: 0,
              boxShadow: "0px 10px 10px rgba(0, 0, 0, 0.3)",
              display: "flex",
              justifyContent: "center",
              padding: 30,
            }}
          >
            <div
              style={{
                
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  textAlign: "center",
                  fontSize: 30,
                  color: "white",
                  textTransform: "uppercase",
                }}
                className="poppins"
              >
                Get started
              </div>
              <div
                style={{ marginTop: 48, color: "white" }}
              >
                <div style={{ display: "flex", alignItems: "center" , marginBottom: 12}}>
                  <div
                    style={{
                      minWidth: 30,
                      minHeight: 30,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: 20,
                      backgroundColor: "white",
                      color: "#6A7E93",
                      fontWeight: 900,
                      marginRight: 12,
                    }}
                    className="basier-p3"
                  >
                    1
                  </div>
                  <div className="basier-p3" style={{color: 'white'}}>
                    Create your first portfolio.
                  </div>
                </div>
                <div style={{ display: "flex", alignItems: "center" , marginBottom: 12}}>
                  <div
                    style={{
                      minWidth: 30,
                      minHeight: 30,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: 20,
                      backgroundColor: "white",
                      color: "#6A7E93",
                      fontWeight: 900,
                      marginRight: 12,
                    }}
                    className="basier-p3"
                  >
                    2
                  </div>
                  <div className="basier-p3" style={{color: 'white'}}>
                    Import transactions by creating API Connected Accounts, or by importing CSVs
                  </div>
                </div>
                <div style={{ display: "flex", alignItems: "center" , marginBottom: 12}}>
                  <div
                    style={{
                      minWidth: 30,
                      minHeight: 30,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: 20,
                      backgroundColor: "white",
                      color: "#6A7E93",
                      fontWeight: 900,
                      marginRight: 12,
                    }}
                    className="basier-p3"
                  >
                    3
                  </div>
                  <div className="basier-p3" style={{color: 'white'}}>
                    Complete Portfolio by entering manual transactions if needed.
                  </div>
                </div>
              </div>
              <div
                style={{
                  height: 60,
                  width: 300,
                  fontSize: 18,
                  borderRadius: 35,
                  marginTop: 24,
                  marginLeft: 12,
                  marginRight: 12,
                  cursor: "pointer",
                  backgroundColor: "white",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onClick={props.newPortModal}
              >
                Create first portfolio
              </div>
            </div>
          </div>
        </div>
      </Hidden>
    </React.Fragment>
  );
};

export default noPortfolio;
