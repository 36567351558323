import React from 'react';
import { connect } from 'react-redux';
import * as actions from "../../store/actions/interactions";
import * as appwideActions from "../../store/actions/appwide";
import questionMark from '../../Media/questionMark.svg'
import Tooltip from '../AppWide/Tooltip'
import ExchangeAdd from './ExchangeSection';
import WalletAdd from './WalletSection';
import RightSection from './RightSection'
import { specificMessageRenderer } from '../../helpers/helpers';
import { backendUrl } from '../../variables';
import axios from 'axios';
import fieldValidator from '../../helpers/validators';
import okIcon from '../../Media/okIcon.svg';
import loadingSpinner from '../../Media/loadingSpinner.gif';
import NextStepsApi from './NextStepsApi';

class NewAccount extends React.PureComponent {

    state = {
        welcome: true,
        scope: 'select',
        subType: 'exchange',
        eAloading: false,
        eAsuccess: false,
        eAerror: false,
        walletLoading: false, 
        walletSuccess: false, 
        walletError: false, 
        error: false, 
        data: {
            
        },
        validation: {
            exchange: true, 
            coin: true,
            exchange_account: true, 
            name: true,
            name_exists: true,
            isName: true,
        },
        importError: null,
        success: false,
        loading: false,
        portfolio: null,
    }

    setPortfolio = (port) => {
      this.setState(state => ({...state, porfolio:port}))
    }

    updateState = (extract) => {
      this.setState(state => ({...state, ...extract}))
    }

    manualCreate = () => {
      if (this.state.data.type === "storage") {
        this.updateWalletServer()
      } else if (this.state.data.type === "exchange") {
        this.updateServer()
      }
    }

    goToEntry = () => {
      if (this.props.goToEntry === undefined) {

        this.props.toggleModal({type: "add_transaction", payload: {overridePort: true, overridePortId: this.state.portfolio, overrideType: 'wizard'}, open: true})

      } else {
        this.props.goToEntry();
      }
    }

    updateServer = () => {
       
      let name_unique = true
      const exchange_val = fieldValidator(this.state.data['exchange'], ['isNotEmpty'])
      const cust_exchange_val = fieldValidator(this.state.data['custom_exchange'], ['isNotEmpty'])
      if (this.state.data['name'] !== null && this.state.data['name'] !== undefined) {
          name_unique = fieldValidator(this.state.data['name'], ['isNotIncluded'], this.props.existingNames)
      }
      
      this.setState(state => ({...state, validation: {...state.validation, exchange: (exchange_val || cust_exchange_val), name: name_unique, name_exists: name_unique}}))
      
      if ( (exchange_val || cust_exchange_val) && name_unique ) {
          
          this.setState(state => ({...state, loading: true}))
          let headers = { "content-type": "application/json" };
          if (this.props.token !== null && this.props.token !== undefined) {

              headers['Authorization'] = `Token ${this.props.token}`

          }

          const url_call = `${backendUrl}/v1/exchange_accounts/`

          axios.post(url_call, this.state.data, {headers: headers})
              .then(json => {

                this.props.getWallets(this.props.user_id)
                this.setState(state => ({...state, loading: false, success: true}))
                 
                 setTimeout(() => {

                  this.handleClose()
      
                }, 600)
                  
              })
              .catch( e => {

              this.setState(state => ({...state, loading: false, error: true}))

              })



      } else {
          //console.log('invalid form data')
      }
      

  }

  updateWalletServer = () => {


      let name_val = true
      let name_unique = true
      const coin_val = fieldValidator(this.state.data['coin_id'], ['isNotEmpty'])
      this.setState(state => ({...state, validation: {...state.validation, coin: coin_val}}))

      if ( this.state.data['wallet_type'] === "storage" ) {
          name_val = fieldValidator(this.state.data['name'], ['isNotEmpty'])
      }
      if (this.state.data['name'] !== null && this.state.data['name'] !== undefined) {
          name_unique = fieldValidator(this.state.data['name'], ['isNotIncluded'], this.props.existingNames)
      }
      
      
      this.setState(state => ({...state, validation: {...state.validation, coin: coin_val, name: name_unique, isName: name_val, name_exists: name_unique}}))
      
      const valid = coin_val && name_val && name_unique
      
      if (valid) {

          this.setState(state => ({...state, loading: true}))

          let headers = { "content-type": "application/json" };
          if (this.props.token !== null && this.props.token !== undefined) {

              headers['Authorization'] = `Token ${this.props.token}`

          }

          const url_call = `${backendUrl}/v1/wallets/`
          const data_send = {
              ...this.state.data, 
              user: this.props.user_id,
              wallet_type: "storage"
          }
          data_send['coin'] = data_send['coin_id']
          axios.post(url_call, data_send, {headers: headers})
              .then(json => {

                 this.props.getWallets(this.props.user_id)
                 this.setState(state => ({...state, loading: false, success: true}))
                 
                 setTimeout(() => {

                  this.handleClose()
      
                }, 600)
                  
              })
              .catch( e => {

              this.setState(state => ({...state, loading: false, error: true}))

              })


      } else {
          //console.log('not valid')
      }

  }

    handleClose = () => {

        this.props.toggleModal({type: null, open: false, payload: null})
       
      }

    changeScope = (scope) => {
        this.setState(state => ({...state, scope: scope}))
    }

    updateData = (label, data) => {

      let exists = false
      if (label === "name") {
        let lowercaseName = data
        try { lowercaseName = data.toLowerCase().trim() } catch(e) {}
        exists = this.props.existingNames.includes(lowercaseName)

      }
      this.setState(state => ({...state, data: {...state.data, [label]: data}, validation: {...state.validation, name_exists: !exists} }))

    }

    renderWarning = () => {

      let exchange_id = null; 
      try {
        exchange_id = this.state.data.exchange
      } catch(e) {}
      const mess = specificMessageRenderer(exchange_id)
      if (mess !== null) {
        return (

          <div className="basier-p4" style={{width: 324, border: '2px solid #e0a03d', maxHeight: 150, marginLeft: 12, marginRight: 12, marginTop: 24, padding: '6px 12px', borderRadius: 10, backgroundColor: "#ffc1631A"}}>
            {mess}
          </div>

        )
      }

    }

    setImportError = (mess) => {
      this.setState(state => ({...state, importError: mess}))
    }

    renderError = () => {
    
      return (
      this.state.importError === null ? null :
      <div className="basier-p4" style={{width: 324, border: '2px solid #F9716B', maxHeight: 150, marginLeft: 12, marginRight: 12, marginTop: 24, padding: '6px 12px', borderRadius: 10, backgroundColor: "#F9716B1A"}}>
        {this.state.importError}
      </div>
      )

    }

    setType = (type) => {
      this.setState(state => ({...state, subType: type, scope: 'select', data: {}}))
    }

    render () {

        return this.state.success ? (

              <div style={{width: '100%', height: 530, backgroundColor: 'white', opacity: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', position: 'absolute', top: 90, zIndex: 13000}}>
                  <img style={{height: 100, with: 100}} src={okIcon} alt="OK!"/>
                  <div style={{marginTop: 12, fontWeight: 'bold', color: 'black'}} className="basier-p2">
                    Account Created!
                  </div>
                  {(this.state.scope === "api") && 

                    <NextStepsApi account_id={this.state.data.exchange} goToEntry={this.goToEntry} portfolioId={this.props.importTo} />
                  
                  }
              </div>
          

        )
        :
         (
            <div>

          {(this.state.loading) && (
          <div style={{width: '100%', height: 530, backgroundColor: 'white', opacity: 0.9, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', position: 'absolute', top: 90, zIndex: 13000}}>
              <img style={{height: 64, with: 64}} src={loadingSpinner} alt="Loading..."/>
              <div style={{marginTop: 24}} className="basier-p2">
                  Creating Account
                </div>
          </div>
          )}

              <div style={{display: 'flex', alignItems: 'flex-start', padding: 48}}>
              <div style={{flex: 1}}>
              <div style={{paddingLeft: 12, paddingRight: 12, height: 80, display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}> 
                    <div className="basier-p4-caps" style={{color: 'black', textTransform: 'uppercase', fontWeight: 900}}>
                        Account type
                    </div>
                    <div style={{display: 'flex', alignItems: 'center'}}>

                        <div className={`${this.state.subType === 'exchange' ? 'toggle-button-active' : 'toggle-button'}`} onClick={() => this.setType('exchange')} 
                        style={{height: 40, width: 160, borderRadius: 35, marginRight: 4, cursor: 'pointer'}}>
                            Exchange
                        </div>

                        <div className={`${this.state.subType === 'storage' ? 'toggle-button-active' : 'toggle-button'}`} 
                        onClick={() => this.setType('storage')}
                        style={{height: 40, width: 160, borderRadius: 35, marginLeft: 4, cursor: 'pointer'}}>
                            Storage / Wallet
                        </div>
                    </div>
                </div>
                  <div>
                  {this.state.subType === "exchange" && 
                    <ExchangeAdd 
                        loading={this.state.eAloading}
                        changeScope={this.changeScope}
                        success={this.state.eAsuccess}
                        error={this.state.eAerror}
                        data={this.state.data}
                        handleClose={this.handleClose}
                        updateServer={() => this.updateServer()} 
                        updateData={(label, val) => this.updateData(label, val)} 
                        validation={this.state.validation} />
                  }
                  {this.state.subType === "storage" && 
                    <WalletAdd 
                        loading={this.state.eAloading}
                        success={this.state.eAsuccess}
                        error={this.state.eAerror}
                        data={this.state.data}
                        handleClose={this.handleClose}
                        updateServer={() => this.updateServer()} 
                        updateData={(label, val) => this.updateData(label, val)} 
                        validation={this.state.validation} />
                  }
                  </div>
                    {this.state.scope === "api" && this.renderWarning()}
                    {this.state.scope === "import" && this.renderError()}
                  </div>
                  <div style={{flex: 1, width: '100%'}}>
                    <RightSection setImportError={this.setImportError} origin={this.props.origin} type={this.state.subType} scope={this.state.scope} changeScope={this.changeScope} data={this.state.data} updateState={this.updateState} manualCreate={this.manualCreate} importTo={this.props.importTo} setPortfolio={this.setPortfolio} />
                  </div>
                </div>
            </div>

        )
    }


}

const mapStateToProps = state => {
    return {
      existingNames: state.appwide.accountNames,
      user_id: state.auth.user_id, 
      token: state.auth.token,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        toggleModal: (data) => 
            dispatch(actions.toggleModal(data)), 
        getWallets: (user, t) => dispatch(appwideActions.getWallets(user, t)), 
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(NewAccount)
