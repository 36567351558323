import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk'

import profitReducer from './store/reducers/profit'
import appwideReducer from './store/reducers/appwide'
import authReducer from './store/reducers/auth'
import dataReducer from './store/reducers/data'
import interactionReducer from './store/reducers/interactions'
import loadingReducer from './store/reducers/loading'

import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';

export default function configureStore(preloadedState) {
    
    const appReducer = combineReducers({
        profit: profitReducer,
        appwide: appwideReducer,
        auth: authReducer,
        data: dataReducer,
        loading: loadingReducer,
        interactions: interactionReducer,
    });
    
    
    const rootReducer = (state, action) => {
    
        if (action.type === 'AUTH_LOGOUT') {
            state = undefined
          }
    
        return appReducer(state, action)
      }
    
    
    const composerEnhancer = composeWithDevTools({
      name: `Redux`,
      realtime: true,
      trace: true,
      traceLimit: 25
    });
    
    let store = null;
    if (process.env.REACT_APP_HOST_ENV === 'dev' || process.env.REACT_APP_HOST_ENV === 'mobile' ) {
      //store = createStore(rootReducer, applyMiddleware(thunk));
      store = createStore(rootReducer, composerEnhancer(applyMiddleware(thunk)));
    } else {
      store = createStore(rootReducer, applyMiddleware(thunk));
    }

    return store
    

}