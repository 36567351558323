

export const updatePortfolios = (data) => {

    if (localStorage.hasOwnProperty("user")) {

        let user = JSON.parse(localStorage.getItem("user"));
        user['list_portfolios'] = data
        localStorage.setItem("user", JSON.stringify(user));

        return true
    }
    return false

}
