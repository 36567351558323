import React from "react";
import FlexibleLargeGraph from "../../../AppWide/FlexibleLargeGraph";
import SmallGraph from "../../../AppWide/FlexibleSmallGraph";
import {connect} from "react-redux";
import * as actions from "../../../../store/actions/data";
import * as authActions from "../../../../store/actions/auth";
import {createLoadingSelector} from "../../../../store/reducers/selectors";
import SummaryOverview from "../SummaryData";
import AssetList from "./Large/OverviewAssetList";
import Hidden from "@material-ui/core/Hidden";
import SmallSummary from "./Small/SmallSummary";
import StraightSummary from "./Small/StraightSummaryOverview";
import chartIcon from "../../../../Media/chartIcon.svg";
import rsiIcon from "../../../../Media/rsiIcon.svg";
import emaIcon from "../../../../Media/emaIcon.svg";
import taIcon from "../../../../Media/taIcon.svg";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import currencyData from "../../../../helpers/currency.json";
import {withStyles} from "@material-ui/core/styles";
import {formattedNumber, getValue} from "../../../../helpers/helpers";
import SmallPortSelect from "./Small/SmallPortfolioSelect";
import TVChart from './Large/TVChart'
import Button from "@material-ui/core/Button";
import {withRouter} from "react-router";

const formatData = (num) => {
    let ret = null;
    try {
        ret = num.toLocaleString("en", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
        });
    } catch (e) {
        ret = num;
    }
    return ret;
};

const styles = {
    root: {
        color: "#6A7E93",
        "&$checked": {
            color: "#0078DB",
        },
    },
    checked: {
        color: "#0078DB",
    },
    buttonRoot: {
        width: "100%",
    },
};

class PortfolioOverview extends React.PureComponent {
    state = {
        currentTab: 0,
        showPane: true,
        smallPortSelect: false,
        rsi: false,
        showEMA: false,
        ema: {
            200: true,
            100: true,
            50: true,
            21: true,
        },
        tradingView: false,
    };

    toggleTradingView = () => {
        this.setState(state => ({...state, tradingView: !state.tradingView}))
    }

    componentDidMount() {
        
        const request_data = {
            period: this.props.portfoliosBalance.period,
            start: this.props.portfoliosBalance.start,
            end: this.props.portfoliosBalance.end,
        };

        this.props.setCurrentView(2);

        if (false) {
            //have a last updated and only call if it makes sense. Potentially put it in the update scope of the component data fetch.
            this.props.componentDataFetch(
                "get",
                2,
                this.props.currency,
                this.props.user,
                this.props.portfolioId,
                null,
                request_data,
                this.props.inBitcoin
            );
        }
    }

    componentDidUpdate(prevProps) {

        if (prevProps.portfolioId !== this.props.portfolioId) {
            this.setState(state => ({...state, tradingView: false}))
        }

    }


    toggleIdicator = (type) => {
        this.setState((state) => ({...state, [type]: !state[type]}));
    };

    toggleEMA = () => {
        this.setState((state) => ({
            ...state,
            showEMA: !state.showEMA,
            ema: {200: true, 100: true, 50: true, 21: true},
        }));
    };
    indivEMA = (type) => {
        this.setState((state) => ({
            ...state,
            ema: {...state.ema, [type]: !state.ema[type]},
        }));
    };

    hidePane = () => {
        this.setState((state) => ({...state, showPane: !state.showPane}));
    };

    changeTab = (event, value) => {
        this.setState((state) => ({...state, currentTab: value}));
    };

    openPortSelect = () => {
        this.setState((state) => ({...state, smallPortSelect: true}));
    };

    closePortSelect = () => {
        this.setState((state) => ({...state, smallPortSelect: false}));
    };

    goToSettings = () => {
        this.props.history.push('/settings/account')
    }

    handleBTCChange = (e) => {
        const new_scope = e.target.value;
        //console.log(`new scope is ${new_scope}`)
        const globalData = {};

        this.props.setPfScope(new_scope);
        this.props.componentDataFetch(
            "update",
            this.props.currentView,
            this.props.currency,
            this.props.user,
            this.props.portfolioId,
            globalData,
            globalData,
            e.target.value
        );
        if (new_scope === "bitcoin") {
            this.props.setBtcSymbol("₿");
        } else {
            let currency_symbol = null;
            try {
                currency_symbol = currencyData[this.props.currency_name]["symbol"];
            } catch (e) {
                currency_symbol = this.props.currency_name;
            }
            this.props.setBtcSymbol(currency_symbol);
        }
    };

    render() {
        const {classes} = this.props;
        const symbol_to_use =
            this.props.coinGraph === true
                ? "$"
                : this.props.currencyScope === "bitcoin"
                ? "₿"
                : this.props.currency_symbol;
        let name = null;
        try {
            name = this.props.list_portfolio.filter(
                (v) => v.id == this.props.portfolioId
            )[0]["name"];
        } catch (e) {
        }


        return (
            <div style={{width: "100%"}}>
                <div style={{marginTop: 0}}>
                    <Hidden mdUp>
                        <React.Fragment>
                            <div
                                onClick={this.openPortSelect}
                                style={{
                                    paddingLeft: 18,
                                    paddingRight: 18,
                                    textTransform: "uppercase",
                                    marginBottom: 12,
                                }}
                                className="basier-p3"
                            >
                                <Button
                                    variant="outlined"
                                    classes={{root: classes.buttonRoot}}
                                >
                                    {name}
                                </Button>
                            </div>

                            <SmallPortSelect
                                open={this.state.smallPortSelect}
                                onClose={this.closePortSelect}
                            />
                        </React.Fragment>
                    </Hidden>

                    {this.props.portfoliosBalance.data[this.props.portfolioId] !==
                    undefined ? (
                        this.props.portfoliosBalance.data[this.props.portfolioId].values
                            .length > 0 ? (
                            <div>
                                <Hidden smDown>
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                            flex: 1,
                                        }}
                                    >
                                        <div
                                            style={{
                                                marginLeft: 48,
                                                display: "flex",
                                                alignItems: "center",
                                            }}
                                        >
                                            <div style={{display: "inline-block"}}>
                                                <div
                                                    className="basier-p4-caps"
                                                    style={{color: "#6A7E93", display: "inline-block", minWidth: 200}}
                                                >
                                                    Portfolio Value
                                                </div>
                                                <br/>
                                                <h2 style={{display: "inline-block"}}>
                                                    {symbol_to_use}
                                                    {formattedNumber(
                                                        this.props.portfoliosBalance.data[
                                                            this.props.portfolioId
                                                            ].today_value
                                                    )}
                                                </h2>
                                            </div>

                                            <div
                                                style={{
                                                    width: "100%",
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    alignItems: "center",
                                                    justifyContent: "space-between",
                                                }}
                                            >
                                                <div style={{flex: 1}}>
                                                    <div
                                                        style={{
                                                            marginLeft: 25,
                                                            width: 170,
                                                            height: 42,
                                                            paddingLeft: 10,
                                                            display: "inline-flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                        }}
                                                        className="white-button"
                                                    >
                                                        <div className="form-check form-check-inline sp-l-12">
                                                            <RadioGroup
                                                                name="btcScope"
                                                                value={this.props.inBitcoin}
                                                                onChange={this.handleBTCChange}
                                                                style={{display: "inline-block"}}
                                                            >
                                                                <FormControlLabel
                                                                    value="fiat"
                                                                    control={
                                                                        <Radio
                                                                            classes={{
                                                                                root: classes.root,
                                                                                checked: classes.checked,
                                                                            }}
                                                                        />
                                                                    }
                                                                    label={this.props.currency_name}
                                                                />
                                                                <FormControlLabel
                                                                    value="bitcoin"
                                                                    control={
                                                                        <Radio
                                                                            classes={{
                                                                                root: classes.root,
                                                                                checked: classes.checked,
                                                                            }}
                                                                        />
                                                                    }
                                                                    label="BTC"
                                                                />
                                                            </RadioGroup>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            style={{
                                                justifySelf: "flex-end",
                                                marginRight: 48,
                                                display: "flex",
                                                alignItems: "center",
                                            }}
                                        >
                                            {this.state.tradingView === false &&
                                            <>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        cursor: "pointer",
                                                    }}
                                                    className="opacity-hover no-select"
                                                    onClick={() => this.toggleIdicator("rsi")}
                                                >
                                                    <img
                                                        src={rsiIcon}
                                                        style={{height: 20, marginRight: 6}}
                                                    />
                                                    <div>RSI</div>
                                                </div>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        cursor: "pointer",
                                                        marginLeft: 24,
                                                    }}
                                                    className="opacity-hover no-select"
                                                    onClick={() => this.toggleEMA()}
                                                >
                                                    <img
                                                        src={emaIcon}
                                                        style={{height: 20, marginRight: 6}}
                                                    />
                                                    <div>EMA</div>
                                                </div>
                                            </>}
                                            <div
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    cursor: "pointer",
                                                    marginLeft: 24,
                                                }}
                                                className="opacity-hover no-select"
                                                onClick={() => this.toggleTradingView()}
                                            >
                                                <img
                                                    src={taIcon}
                                                    style={{height: 20, marginRight: 6}}
                                                />
                                                <div>{this.state.tradingView ? 'Simplified Chart' : 'Full TA'}</div>
                                            </div>
                                        </div>
                                    </div>
                                    {this.state.showEMA && (
                                        <div
                                            style={{
                                                height: 35,
                                                width: "100%",
                                                paddingLeft: 48,
                                                paddingRight: 48,
                                                paddingTop: 0,
                                                paddingBottom: 0,
                                                marginBottom: 12,
                                            }}
                                        >
                                            <div
                                                style={{
                                                    border: "1px solid gray",
                                                    width: "100%",
                                                    height: "100%",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "space-around",
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "space-around",
                                                        width: "100%",
                                                        cursor: "pointer",
                                                    }}
                                                    className="opacity-hover no-select"
                                                >
                                                    {["200", "100", "50", "21"].map((v, k) => {
                                                        return (
                                                            <div
                                                                key={v}
                                                                style={{
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                }}
                                                                onClick={() => this.indivEMA(v)}
                                                            >
                                                                <div style={{marginRight: 6}}>{v} EMA</div>
                                                                <div
                                                                    style={{
                                                                        height: 2,
                                                                        width: 100,
                                                                        backgroundColor:
                                                                            this.state.ema[v] === false
                                                                                ? "#E1E5E9"
                                                                                : v === "200"
                                                                                ? "red"
                                                                                : v === "100"
                                                                                    ? "black"
                                                                                    : v === "50"
                                                                                        ? "green"
                                                                                        : "orange",
                                                                    }}
                                                                />
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "flex-start",
                                            justifyContent: "flex-start",
                                        }}
                                    >

                                        <div style={{flex: 1, width: "100%"}}>
                                            {this.state.tradingView ?
                                                <TVChart/>
                                                :
                                                <FlexibleLargeGraph
                                                    large={this.state.showPane}
                                                    data={
                                                        this.props.portfoliosBalance.data[
                                                            this.props.portfolioId
                                                            ].values
                                                    }
                                                    start_date={
                                                        this.props.portfoliosBalance.data[
                                                            this.props.portfolioId
                                                            ].start_date
                                                    }
                                                    today_value={
                                                        this.props.portfoliosBalance.data[
                                                            this.props.portfolioId
                                                            ].today_value
                                                    }
                                                    currencyScope={this.props.portfoliosBalance.inBitcoin}
                                                    user={this.props.user}
                                                    currency={this.props.currency}
                                                    setNewScope={this.props.setGraphScope}
                                                    setPfScope={this.props.setPfScope}
                                                    updateTime={this.props.updateTime}
                                                    timePeriod={this.props.timePeriod}
                                                    loadedGraph={this.props.loadedGraph}
                                                    portfolio={this.props.portfolioId}
                                                    background="white"
                                                    showRSI={this.state.rsi}
                                                    showEMA={this.state.showEMA}
                                                    ema={this.state.ema}
                                                    showSMA={false}
                                                    scope="portfolio"
                                                />
                                            }
                                        </div>
                                    </div>
                                </Hidden>

                                <Hidden mdUp>
                                    <SmallGraph
                                        data={
                                            this.props.portfoliosBalance.data[this.props.portfolioId]
                                                .values
                                        }
                                        user={this.props.user}
                                        start_date={
                                            this.props.portfoliosBalance.data[this.props.portfolioId]
                                                .start_date
                                        }
                                        today_value={
                                            this.props.portfoliosBalance.data[this.props.portfolioId]
                                                .today_value
                                        }
                                        currencyScope={this.props.portfoliosBalance.inBitcoin}
                                        currency={this.props.currency}
                                        setPfScope={this.props.setPfScope}
                                        setNewScope={this.props.setGraphScope}
                                        updateGraph={(
                                            scope,
                                            view,
                                            currency,
                                            user,
                                            portfolio,
                                            globalGraphData,
                                            portfolioGraphData,
                                            inBitcoin
                                        ) =>
                                            this.props.componentDataFetch(
                                                scope,
                                                view,
                                                currency,
                                                user,
                                                portfolio,
                                                globalGraphData,
                                                portfolioGraphData,
                                                inBitcoin
                                            )
                                        }
                                        updateTime={this.props.updateTime}
                                        timePeriod={this.props.timePeriod}
                                        loadedGraph={this.props.loadedGraph}
                                        portfolio={this.props.portfolioId}
                                        background="white"
                                        scope="portfolio"
                                    />
                                </Hidden>
                            </div>
                        ) : (
                            <React.Fragment>
                                <Hidden smDown>
                                    <div
                                        style={{
                                            height: 530,
                                            width: "100%",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            padding: 24,
                                        }}
                                    >
                                        <div
                                            style={{
                                                border: "1px solid #CCCCCC",
                                                borderRadius: 20,
                                                height: "100%",
                                                width: "100%",
                                                backgroundColor: "rgba(255, 255, 255, 0.5)",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                textAlign: "center",
                                                padding: 48,
                                                flexDirection: "column",
                                                boxShadow: "0px 10px 10px rgba(225, 229, 233, 0.3)",
                                            }}
                                        >
                                            <div>
                                                <img src={chartIcon} alt="" style={{height: 80}}/>
                                            </div>
                                            <div className="basier-p2" style={{marginTop: 24}}>
                                                <div>
                                                    You do not have any transactions in your portfolios
                                                    yet.
                                                </div>
                                                <div>
                                                    Once you add transactions you can see your portfolio's
                                                    historical evolution here.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Hidden>

                                <Hidden mdUp>
                                    <div
                                        style={{
                                            height: 300,
                                            width: "100%",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            padding: 12,
                                        }}
                                    >
                                        <div
                                            style={{
                                                border: "1px solid #CCCCCC",
                                                borderRadius: 20,
                                                height: "100%",
                                                width: "100%",
                                                backgroundColor: "rgba(255, 255, 255, 0.5)",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                textAlign: "center",
                                                padding: 24,
                                                flexDirection: "column",
                                                boxShadow: "0px 10px 10px rgba(225, 229, 233, 0.3)",
                                            }}
                                        >
                                            <div>
                                                <img src={chartIcon} alt="" style={{height: 80}}/>
                                            </div>
                                            <div className="basier-p2" style={{marginTop: 24}}>
                                                <div>
                                                    You do not have any transactions in your portfolios
                                                    yet. Once you add transactions you can see your
                                                    portfolio's historical evolution here.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Hidden>
                            </React.Fragment>
                        )
                    ) : (
                        <div>
                            <Hidden smDown>
                                <div
                                    style={{
                                        backgroundColor: "white",
                                        height: 533,
                                        width: "100%",
                                    }}
                                >
                                    <div
                                        style={{
                                            marginLeft: 48,
                                            height: 83,
                                            width: 450,
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                    >
                                        <div
                                            className="pulse-skeleton"
                                            style={{width: 83, height: 83, borderRadius: 100}}
                                        ></div>

                                        <div
                                            style={{
                                                marginLeft: 24,
                                                display: "flex",
                                                flexDirection: "column",
                                            }}
                                        >
                                            <div
                                                className="pulse-skeleton"
                                                style={{
                                                    width: 325,
                                                    height: 45,
                                                    borderRadius: 10,
                                                    marginRight: 0,
                                                }}
                                            ></div>
                                            <div
                                                className="pulse-skeleton"
                                                style={{
                                                    width: 325,
                                                    height: 26,
                                                    marginTop: 12,
                                                    borderRadius: 10,
                                                    marginRight: 0,
                                                }}
                                            ></div>
                                        </div>
                                    </div>
                                    <div style={{marginLeft: 0, height: 450}}>
                                        <div style={{marginTop: 48}}></div>
                                        <div
                                            style={{width: "100%", height: 402}}
                                            className="pulse-skeleton"
                                        ></div>
                                    </div>
                                </div>
                            </Hidden>

                            <Hidden mdUp>
                                <div
                                    style={{
                                        backgroundColor: "white",
                                        height: 417,
                                        width: "100%",
                                    }}
                                >
                                    <div
                                        style={{
                                            marginLeft: 12,
                                            height: 83,
                                            width: "100%",
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                    >
                                        <div
                                            className="pulse-skeleton"
                                            style={{width: 75, height: 75, borderRadius: 100}}
                                        ></div>

                                        <div
                                            style={{
                                                marginLeft: 24,
                                                display: "flex",
                                                flexDirection: "column",
                                            }}
                                        >
                                            <div
                                                className="pulse-skeleton"
                                                style={{
                                                    width: 200,
                                                    height: 30,
                                                    borderRadius: 10,
                                                    marginRight: 0,
                                                }}
                                            ></div>
                                            <div
                                                className="pulse-skeleton"
                                                style={{
                                                    width: 200,
                                                    height: 18,
                                                    marginTop: 12,
                                                    borderRadius: 10,
                                                    marginRight: 0,
                                                }}
                                            ></div>
                                        </div>
                                    </div>

                                    <div style={{marginLeft: 0, height: 334}}>
                                        <div style={{marginTop: 12}}></div>
                                        <div
                                            style={{width: "100%", height: 322}}
                                            className="pulse-skeleton"
                                        ></div>
                                    </div>
                                </div>
                            </Hidden>
                        </div>
                    )}
                </div>

                <Hidden smDown>
                    <div
                        className="sticky-summary-header"
                        style={{paddingTop: 78, marginLeft: 48, marginRight: 48}}
                    >
                        <SummaryOverview
                            data={this.props.portfoliosData}
                            portfolioId={this.props.portfolioId}
                        />
                    </div>
                </Hidden>

                <Hidden mdUp>
                    <div style={{paddingTop: 12, marginLeft: 0, marginRight: 0}}>
                        {window.innerWidth < 560 ? (
                            <SmallSummary
                                loading={this.props.loadedPortData}
                                data={this.props.portfoliosData}
                                portfolioId={this.props.portfolioId}
                            />
                        ) : (
                            <StraightSummary
                                data={this.props.portfoliosData}
                                portfolioId={this.props.portfolioId}
                            />
                        )}
                    </div>
                </Hidden>

                <div className="asset-wrapper-responsive">
                    {this.props.portfoliosData === {} ? null : (
                        <AssetList
                            portfoliosData={this.props.portfoliosData}
                            portfolioId={this.props.portfolioId}
                            value={this.state.currentTab}
                            handleTabChange={this.changeTab}
                        />
                    )}
                </div>

                <div style={{marginTop: 78, marginLeft: 48, marginRight: 96}}></div>
            </div>
        );
    }
}

const graphLoadingSelector = createLoadingSelector(["UPDATE_PORTFOLIO_GRAPH"]);
const dataLoadingSelector = createLoadingSelector(["UPDATE_PORTFOLIO"]);

const mapStateToProps = (state) => {
    return {
        portfoliosBalance: state.data.portfolioBalance,
        portfoliosData: state.data.portfoliosData,
        currency: state.auth.currency.id,
        user: state.auth.user_id,
        timePeriod: state.data.portfolioBalance.period,
        loadedGraph: graphLoadingSelector(state),
        loadedPortData: dataLoadingSelector(state),
        inBitcoin: state.data.bitcoinPf,
        currency_name: state.auth.currency.id,
        currentView: state.data.currentView,
        currency_symbol: state.auth.currency.symbol,
        list_portfolio: state.auth.list_portfolios,
        limits: state.appwide.limits,
        
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setMenuPortfolio: (path) => dispatch(actions.setMenuPortfolio(path)),
        updateTime: (time, portfolio) =>
            dispatch(actions.setPortfolioTime(time, portfolio)),
        setGraphScope: (currency, scope) =>
            dispatch(actions.setGraphScope(currency, scope)),
        setPfScope: (currency) => dispatch(actions.setPfScope(currency)),
        setBtcSymbol: (s) => dispatch(authActions.setBtcSymbol(s)),
        setCurrentView: (view) => dispatch(actions.setCurrentView(view)),
        componentDataFetch: (
            scope,
            view,
            currency,
            user,
            portfolio,
            globalGraphData,
            portfolioGraphData,
            inBitcoin
        ) =>
            dispatch(
                actions.componentDataFetch(
                    scope,
                    view,
                    currency,
                    user,
                    portfolio,
                    globalGraphData,
                    portfolioGraphData,
                    inBitcoin
                )
            ),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(withRouter(PortfolioOverview)));
