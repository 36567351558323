/* eslint-disable no-loop-func */
import * as actionTypes from "./actionTypes";
import { loadData, api_timeout_caller, getValue, slowLoadData } from '../../helpers/helpers';
import * as interActions from './interactions';
import { backendUrl } from '../../variables';
import { setFiatList } from './data';
import {delay} from "../../helpers/helpers"
import axios from 'axios';

export const updatePay = (data) => {
    return {
        type: actionTypes.APPWIDE_PAY, 
        data
    };
};

export const addPort = (data) => {
    return {
        type: actionTypes.APPWIDE_ADD_PORT, 
        data
    };
};

export const addTr = (data) => {
    return {
        type: actionTypes.APPWIDE_ADD_TR, 
        data
    };
};


export const getPay = (user, custId) => {

    let headers = { "content-type": "application/json" };
    if (localStorage.hasOwnProperty("user")) {
        let user = JSON.parse(localStorage.getItem("user"));
        let token = user.rahat_t
        headers["Authorization"] = `Token ${token}`;
    }
    return dispatch => {

        const data_fetch = loadData(`${backendUrl}/v1/pm_mt/?u=${user}&customer_id=${custId}`, headers)
        data_fetch
            .then(json => 
                
                {   
                    dispatch(updatePay(json['data']));
                }
                
            )
            .catch(err => {
                console.log('error')
            })
    };


}

export const updateConnectors = (data) => {
    return {
        type: actionTypes.APPWIDE_CONNECTORS, 
        data
    };
};


export const getConnectors = (user, tokenProvided) => {

    let headers = { "content-type": "application/json" };
    if (tokenProvided !== undefined) {
        headers["Authorization"] = `Token ${tokenProvided}`;
    }
    else if (localStorage.hasOwnProperty("user")) {
        let user = JSON.parse(localStorage.getItem("user"));
        let token = user.rahat_t
        headers["Authorization"] = `Token ${token}`;
    } 
    return dispatch => {

        const data_fetch = loadData(`${backendUrl}/v1/connectors/list/?u=${user}`, headers)
        data_fetch
            .then(json => 
                
                {   
                    dispatch(updateConnectors(json['data']));
                }
                
            )
            .catch(err => {
                console.log('error')
            })
    };


}

export const updateAddresses = (data) => {
    return {
        type: actionTypes.APPWIDE_ADDRESSES, 
        data
    };
};

export const getAddresses = (user, tokenProvided) => {

    let headers = { "content-type": "application/json" };
    if (tokenProvided !== undefined) {
        headers["Authorization"] = `Token ${tokenProvided}`;
    }
    else if (localStorage.hasOwnProperty("user")) {
        let user = JSON.parse(localStorage.getItem("user"));
        let token = user.rahat_t
        headers["Authorization"] = `Token ${token}`;
    } 
    return dispatch => {

        const data_fetch = loadData(`${backendUrl}/v1/wallets/connected/addresses/?u=${user}`, headers)
        data_fetch
            .then(json => 
                
                {   
                    dispatch(updateAddresses(json['data']));
                    dispatch(getNFTCollection(json['data']))
                }
                
            )
            .catch(err => {
                console.log('error')
            })
    };


}

export const updateContracts = (data) => {
    return {
        type: actionTypes.APPWIDE_CONTRACTS, 
        data
    };
};


export const getContracts = (user, tokenProvided) => {

    let headers = { "content-type": "application/json" };
    if (tokenProvided !== undefined) {
        headers["Authorization"] = `Token ${tokenProvided}`;
    }
    else if (localStorage.hasOwnProperty("user")) {
        let user = JSON.parse(localStorage.getItem("user"));
        let token = user.rahat_t
        headers["Authorization"] = `Token ${token}`;
    } 
    return dispatch => {

        axios.get("https://wislydata.s3-eu-west-1.amazonaws.com/public/contract_list.json")
        .then(
            json => {
                dispatch(updateContracts(json['data']));
            }
        )
    };


}


export const updateUserLimit = (data) => {
    return {
        type: actionTypes.APPWIDE_LIMITS, 
        data
    };
};


export const getUserLimits = (user, tokenProvided) => {

    let headers = { "content-type": "application/json" };
    if (tokenProvided !== undefined) {
        headers["Authorization"] = `Token ${tokenProvided}`;
    }
    else if (localStorage.hasOwnProperty("user")) {
        let user = JSON.parse(localStorage.getItem("user"));
        let token = user.rahat_t
        headers["Authorization"] = `Token ${token}`;
    } 
    return dispatch => {

        const data_fetch = loadData(`${backendUrl}/v1/user_limits/?u=${user}`, headers)
        data_fetch
            .then(json => 
                
                {   
                    dispatch(updateUserLimit(json['data']));
                }
                
            )
            .catch(err => {
                console.log('error')
            })
    };


}


export const updateAllNames = (data) => {
    return {
        type: actionTypes.APPWIDE_ACCOUNT_NAMES, 
        data
    };
};


export const getAllNames = (user, tokenProvided) => {

    let headers = { "content-type": "application/json" };
    if (tokenProvided !== undefined) {
        headers["Authorization"] = `Token ${tokenProvided}`;
    }
    else if (localStorage.hasOwnProperty("user")) {
        let user = JSON.parse(localStorage.getItem("user"));
        let token = user.rahat_t
        headers["Authorization"] = `Token ${token}`;
    } 
    return dispatch => {

        const data_fetch = loadData(`${backendUrl}/v1/wallets/allnames/?u=${user}`, headers)
        data_fetch
            .then(json => 
                
                {   
                    dispatch(updateAllNames(json['data']));
                }
                
            )
            .catch(err => {
                console.log('error')
            })
    };


}


export const updatePlan = (data) => {
    return {
        type: actionTypes.APPWIDE_PLAN, 
        data
    };
};


export const getPlans = (user,tokenProvided) => {

    let headers = { "content-type": "application/json" };
    if (tokenProvided !== undefined) {
        headers["Authorization"] = `Token ${tokenProvided}`;
    }
    else if (localStorage.hasOwnProperty("user")) {
        let user = JSON.parse(localStorage.getItem("user"));
        let token = user.rahat_t
        headers["Authorization"] = `Token ${token}`;
    } 
    return dispatch => {

        const data_fetch = loadData(`${backendUrl}/v1/client_sub/?u=${user}`, headers)
        data_fetch
            .then(json => 
                
                {   
                    dispatch(updatePlan(json['data']));
                }
                
            )
            .catch(err => {
                console.log('error')
            })
    };


}


export const updateNotifs = (data) => {
    return {
        type: actionTypes.APPWIDE_NOTIFS, 
        data
    };
};


export const getNotifs = (user, tokenProvided) => {

    let headers = { "content-type": "application/json" };
    if (tokenProvided !== undefined) {
        headers["Authorization"] = `Token ${tokenProvided}`;
    }
    else if (localStorage.hasOwnProperty("user")) {
        let user = JSON.parse(localStorage.getItem("user"));
        let token = user.rahat_t
        headers["Authorization"] = `Token ${token}`;
    } 
    return dispatch => {

        const data_fetch = loadData(`${backendUrl}/v1/notifications/${user}/`, headers)
        data_fetch
            .then(json => 
                
                {   
                    dispatch(updateNotifs(json['data']));
                }
                
            )
            .catch(err => {
                console.log('error')
            })
    };


}

export const updateInvites = (data) => {
    return {
        type: actionTypes.APPWIDE_INVITES_SUCCESS, 
        data
    };
};


export const getInvites = (user, tokenProvided) => {

    let headers = { "content-type": "application/json" };
    if (tokenProvided !== undefined) {
        headers["Authorization"] = `Token ${tokenProvided}`;
    }
    else if (localStorage.hasOwnProperty("user")) {
        let user = JSON.parse(localStorage.getItem("user"));
        let token = user.rahat_t
        headers["Authorization"] = `Token ${token}`;
    } 
    return dispatch => {
        dispatch( {type: actionTypes.APPWIDE_INVITES_REQUEST} )
        const data_fetch = loadData(`${backendUrl}/v1/portfolio_requests/?id=${user}`, headers)
        data_fetch
            .then(json => 
                
                {   
                    dispatch(updateInvites(json['data']));
                }
                
            )
            .catch(err => {
                console.log('error')
            })
    };


}


const updateAllCoins = (data) => {
    return {
        type: actionTypes.APPWIDE_ALL_COINS, 
        data
    };
};


export const getAllCoins = (user, tokenProvided) => {

    /* let headers = { "content-type": "application/json" };
    if (tokenProvided !== undefined) {
        headers["Authorization"] = `Token ${tokenProvided}`;
    }
    else if (localStorage.hasOwnProperty("user")) {
        let user = JSON.parse(localStorage.getItem("user"));
        let token = user.rahat_t
        headers["Authorization"] = `Token ${token}`;
    }  */
    return dispatch => {

        axios.get("https://wislydata.s3-eu-west-1.amazonaws.com/public/coin_list.json")
        .then(
            json => {
                dispatch(updateAllCoins(json['data']));
                const crypto = json['data'].filter(v => v.asset_type === 'crypto')
                dispatch(updateCrypto(crypto))
                const fiat = json['data'].filter(v => v.asset_type === 'fiat')
                let goodFiat = []
                fiat.map(val => {
                    goodFiat.push({value: val.id, label: val.id, full_name: val.name})
                })
                dispatch(setFiatList(goodFiat))
            }
        )

        /* console.log('going to call')
        const data_fetch = loadData(`${backendUrl}/v1/cryptos/?type=all`, headers)
        data_fetch
            .then(json => 
                
                {   
                    dispatch(updateAllCoins(json['data']));
                    const crypto = json['data'].filter(v => v.asset_type === 'crypto')
                    dispatch(updateCrypto(crypto))
                    const fiat = json['data'].filter(v => v.asset_type === 'fiat')
                    let goodFiat = []
                    fiat.map(val => {
                        goodFiat.push({value: val.id, label: val.id, full_name: val.name})
                    })
                    dispatch(setFiatList(goodFiat))

                }
                
            )
            .catch(err => {
                console.log('coin error')
            }) */
    };


}

const updateAllPorts = (data) => {
    return {
        type: actionTypes.APPWIDE_ALL_PORTS, 
        data
    };
};

const updateFlatWallets = (data) => {
    return {
        type: actionTypes.APPWIDE_FLAT_WALLETS, 
        data
    };
};

export const getAllPortfolioList = (user_id) => {

    let headers = { "content-type": "application/json" };
    if (localStorage.hasOwnProperty("user")) {
        let user = JSON.parse(localStorage.getItem("user"));
        let token = user.rahat_t
        headers["Authorization"] = `Token ${token}`;
    }
    return dispatch => {

        const data_fetch = loadData(`${backendUrl}/v1/port_ownership/?u=${user_id}`, headers)
        data_fetch
            .then(json => 
                
                {   
                    dispatch(updateAllPorts(json['data']));
                }
                
            )
            .catch(err => {
                console.log('error')
            })
    };


}

export const getFlatWalletList = (user_id) => {

    let headers = { "content-type": "application/json" };
    if (localStorage.hasOwnProperty("user")) {
        let user = JSON.parse(localStorage.getItem("user"));
        let token = user.rahat_t
        headers["Authorization"] = `Token ${token}`;
    }
    return dispatch => {

        const data_fetch = loadData(`${backendUrl}/v1/wallet_ownership/?u=${user_id}`, headers)
        data_fetch
            .then(json => 
                
                {   
                    dispatch(updateFlatWallets(json['data']));
                }
                
            )
            .catch(err => {
                console.log('error')
            })
    };


}


const updateCrypto = (data) => {
    return {
        type: actionTypes.APPWIDE_CRYPTO, 
        data
    };
};


export const getCrypto = () => {

    let headers = { "content-type": "application/json" };
    if (localStorage.hasOwnProperty("user")) {
        let user = JSON.parse(localStorage.getItem("user"));
        let token = user.rahat_t
        headers["Authorization"] = `Token ${token}`;
    }
    return dispatch => {

        const data_fetch = loadData(`${backendUrl}/v1/cryptos/`, headers)
        data_fetch
            .then(json => 
                
                {   
                    dispatch(updateCrypto(json['data']));
                }
                
            )
            .catch(err => {
                console.log('error')
            })
    };


}

const updateExchanges = (data) => {
    return {
        type: actionTypes.APPWIDE_EXCHANGES, 
        data
    };
};


export const getExchanges = (user_id, tokenProvided) => {

    console.log('getting exchanges');
    let headers = { "content-type": "application/json" };
    if (tokenProvided !== undefined) {
        headers["Authorization"] = `Token ${tokenProvided}`;
    }
    else if (localStorage.hasOwnProperty("user")) {
        let user = JSON.parse(localStorage.getItem("user"));
        let token = user.rahat_t
        headers["Authorization"] = `Token ${token}`;
    } 
    return dispatch => {

        const data_fetch = loadData(`${backendUrl}/v1/exchanges/?u=${user_id}`, headers)
        data_fetch
            .then(json => 
                
                {   
                    dispatch(updateExchanges(json['data']));
                }
                
            )
            .catch(err => {
                console.log('error')
            })
    };


}


export const updateSingleWallet = (data) => {
    return {
        type: actionTypes.APPWIDE_SINGLE_WALLET, 
        data
    };
};


const updateWallets = (data) => {
    return {
        type: actionTypes.APPWIDE_WALLETS, 
        data
    };
};


export const getWallets = (user, tokenProvided) => {
    // console.log('getting wallets')

    // getExchanges(user, tokenProvided);
    // console.log("done?")

    let headers = { "content-type": "application/json" };
    if (tokenProvided !== undefined) {
        headers["Authorization"] = `Token ${tokenProvided}`;
    }
    else if (localStorage.hasOwnProperty("user")) {
        let user = JSON.parse(localStorage.getItem("user"));
        let token = user.rahat_t
        headers["Authorization"] = `Token ${token}`;
    } 

    return dispatch => {

        dispatch(getAllNames(user));
        dispatch(getAddresses(user, tokenProvided));
        const data_fetch = loadData(`${backendUrl}/v1/wallets/?u=${user}`, headers)
        data_fetch
            .then(json => 
                
                {   
                    dispatch(updateWallets(json['data']));
                }
                
            )
            .catch(err => {
                console.log('error')
            })
        
        const data_fetch2 = loadData(`${backendUrl}/v1/exchanges/?u=${user}`, headers)
        data_fetch2
            .then(json2 => 
                
                {   
                    dispatch(updateExchanges(json2['data']));
                }
                
            )
            .catch(err => {
                console.log('error')
            })
    };


}

const updateOrgs = (data) => {
    return {
        type: actionTypes.APPWIDE_ORG_LIST, 
        data
    };
};


export const getOrgs = (user, tokenProvided) => {

    let headers = { "content-type": "application/json" };
    if (tokenProvided !== undefined) {
        headers["Authorization"] = `Token ${tokenProvided}`;
    }
    else if (localStorage.hasOwnProperty("user")) {
        let user = JSON.parse(localStorage.getItem("user"));
        let token = user.rahat_t
        headers["Authorization"] = `Token ${token}`;
    } 
    return dispatch => {

        const data_fetch = loadData(`${backendUrl}/v1/organizations/?u=${user}`, headers)
        data_fetch
            .then(json => 
                
                {   
                    dispatch(updateOrgs(json['data']));
                }
                
            )
            .catch(err => {
                console.log('error')
            })
    };


}

const updateNFTCollections = (data) => {
    return {
        type: actionTypes.APPWIDE_NFT_COLLECTION, 
        data
    };
};


const updateNFTStats = (data) => {
    return {
        type: actionTypes.APPWIDE_NFT_STAT, 
        data
    };
};

const updateNFTPiece = (data) => {
    return {
        type: actionTypes.APPWIDE_NFT_PIECE, 
        data
    };
};

const getNftForNetwork = async (network, address)  => {

    switch(network) {
        case 'ethereum': {
            const returns = await Promise.all(address.map(v => getEthNFTs(v))) 
            if (Array.isArray(returns)) {
                return returns.flat()
            } else {
                return []
            }
        }
        default: return []
    }

}

const getEthNFTs = async (address) => {
    const masterData = []
    let continuer = true
    let offset = 0;
    const limit = 50;
    const doneIds = {}
    while (continuer) {
        const headers = {
            "X-API-KEY": "9327616a01c44d1fa27325b803389e50",
            "Content-Type": "application/json"
        }
        const data_fetch = await loadData(`https://api.opensea.io/api/v1/assets?owner=${address}&order_direction=desc&offset=${offset * limit}&limit=${limit}`, headers)
        const localData = getValue(data_fetch, ['assets'], [])
        const len = localData.length;
        let j = 0;

        while (j < len) {
            if (!doneIds[localData[j].id]) {
                masterData.push(localData[j])
            }
            doneIds[localData[j].id] = true
            j++
        }
        if (localData.length === 0) {
            continuer = false
        }
        offset++
    }  

    return {address: address, data: masterData};

}


export const getNFTCollection = (allAddresses) => {
    return async dispatch => {
        const supportedNetworks = ['ethereum']
        const networks = Object.keys(allAddresses);
        const len = networks.length;
        let i = 0;
        while (i < len) {
            const network = networks[i]
            const addresses = allAddresses[network]
            if (!supportedNetworks.includes(network)) {
                i++
                continue
            }
            // get user nfts for each address
        
            let thisCollection = {};
            let localAddressData = {};
            let collectionData = {};
            let collectionNames = {};
            let allCollections = {};
            let timeStamps = {}

            const lenAddresses = addresses.length;
            let addressIndex = 0;

            const fetchNew = [];
            const now = new Date()

            while (addressIndex < lenAddresses) {
                let getFromCache = false;
                const address = addresses[addressIndex];
                if (localStorage.hasOwnProperty("nftCollection")) {
                    let times = {}
                    if (localStorage.hasOwnProperty("nftTimes")) {
                        const timesRaw = localStorage.getItem("nftTimes")
                        times = JSON.parse(timesRaw)
                        timeStamps = times
                    }
                    const collections = localStorage.getItem("nftCollection")
                    try {
                        allCollections = JSON.parse(collections)
                        thisCollection = allCollections[network]
                    } catch(e) {}
                    
                    // check if the address nft collections are in cache
                    if (thisCollection) {
                        if (thisCollection[address]) {
                            const time = times[address]
                            if (time) {
                                const expirationTime = new Date(time)
                                const dif = Math.abs(expirationTime.getTime() - now.getTime()) / 1000
                                if (dif < 3600 * 4) {
                                    getFromCache = true;
                                    localAddressData = {...localAddressData, [address]: thisCollection[address]}
                                }
                            }
                        }
                        // invalidate cache every 4h or when portfolio is updated
                    }
                }

                if (!getFromCache) {
                    fetchNew.push(address)
                }
                addressIndex++
            }

            let masterData = []
            if (fetchNew.length > 0) {
                masterData = await getNftForNetwork(network, fetchNew);


                if (!masterData || !Array.isArray(masterData)) {
                    masterData = []
                }

                const assetLen = masterData.length;
                let i = 0; 
                const timeDone = {}
                while (i < assetLen) {
                    const address = masterData[i].address
                    const addressData = masterData[i].data
                    let j = 0;
                    const len = addressData.length;
                    while ( j < len) {
                        const line = addressData[j];
                        const thisCollectionName = getValue(line, ['collection', 'slug']);
                        let thisCollectionDesc = getValue(line, ['name'], "")
                        if (thisCollectionDesc) {
                            thisCollectionDesc = thisCollectionDesc.split("#")
                            if (thisCollectionDesc && thisCollectionDesc.length > 0) {
                                try {
                                    thisCollectionDesc = thisCollectionDesc[0].trim()
                                } catch(e) {
                                    thisCollectionDesc = ""
                                }
                            }
                        }
                        let isArtblock = false
                        try {
                            isArtblock = thisCollectionName.replaceAll("-", " ").indexOf("art blocks") !== -1
                        } catch(e) {}
                        
                        let link_price;
                        if (isArtblock) {
                            const traits = getValue(line, ['traits'], [])
                            console.log('traits are', traits)
                            let matchName;
                            const traitLen = traits.length
                            let i = 0 
                            while (i < traitLen) {
                                const trait = traits[i]
                                let lowerValue = trait.value
                                try {
                                    lowerValue = trait.value.toLowerCase()
                                } catch(e) {}

                                if (lowerValue === `all ${thisCollectionDesc.toLowerCase()}s`) {
                                    matchName = trait.value
                                }
                                i++
                            }

                            if (matchName) {
                                link_price = buildOpenSeaScraping(thisCollectionName, thisCollectionDesc, matchName)
                            }
                        }

                        const tokenId = getValue(line, ['token_id']);
                        const contractAddress = getValue(line, ['asset_contract', 'address']);
                        const assetData = {
                            collection: thisCollectionName,
                            image: getValue(line, ['image_url']),
                            name: getValue(line, ['name']),
                            link_price: link_price,
                            collectionName: getValue(line, ['collection', 'name']),
                            tokenId: tokenId,
                            contractAddress: contractAddress,
                        }
                        if (!collectionData[address]) {
                            collectionData[address] = {}
                        }
                        if (!collectionData[address][contractAddress]) {
                            collectionData[address][contractAddress] = {}
                        }
                        collectionData[address][contractAddress][tokenId] = assetData
                        if (!timeDone[address]) {
                            timeStamps = {...timeStamps, [address]: new Date().getTime()}
                            timeDone[address] = true
                        }
                        j++
                    }
                    // find a way to determine that a collection is artblock
                    i++
                }


                // const allNfts = Object.values(allData)
                // const allNftsLen = allNfts.length;
                // let nftIterator = 0
                // let flattenData = {}

                // while ( nftIterator < allNftsLen ) {
                //     const thisNft = allNfts[nftIterator];

                //     if (!flattenData[])



                //     nftIterator++
                // }

            }
            const allData = {...localAddressData, ...collectionData}

            localStorage.setItem("nftCollection", JSON.stringify({
                ...allCollections, 
                [network]: allData
            }))
            localStorage.setItem("nftTimes", JSON.stringify(timeStamps))

            const values = Object.values(allData);

            const valuesLen = values.length
            let x = 0;
            const flattenNftDict = {}

            while ( x < valuesLen) {

                const contractAddresses = Object.keys(values[x])
                const contractLen = contractAddresses.length;
                let i = 0 

                while (i < contractLen) {
                    const contract = contractAddresses[i]
                    if (!flattenNftDict[contract]) {
                        flattenNftDict[contract] = {}
                    }
                    const tokens = Object.keys(values[x][contract])
                    const tokenLen = tokens.length;
                    let j = 0;

                    while (j < tokenLen) {
                        const token = tokens[j]
                        const collection = getValue(values[x][contract][token], ['collection'], null)
                        const link_price = getValue(values[x][contract][token], ['link_price'], null)
                        if (collection && !link_price) {
                            collectionNames[collection] = true;
                        }
                        if (!flattenNftDict[contract][token]) {
                            flattenNftDict[contract][token] = values[x][contract][token]
                        }

                        j++
                    }
                    i++

                }
                x++
            }
            

            const networkData = {
                [network]: flattenNftDict
            }

            dispatch(getNFTCollectionPrices(network, Object.keys(collectionNames)))
            dispatch(updateNFTCollections(networkData))

            i++
        }
    }
}

const getEthPrices = async (slugs) => {
    
    let getSlugs = []
    let parsedPrices = {}
    let thisPrices = {}
    if (localStorage.hasOwnProperty("nftPrices")) {
        try {
            const allPrices = localStorage.getItem("nftPrices")
            parsedPrices = JSON.parse(allPrices)
        } catch(e) {
            try {
                localStorage.removeItem("nftPrices")
            } catch(e) {}
        }
    } else {
        getSlugs = slugs
    }
    if (parsedPrices["ethereum"]) {
        thisPrices = getValue(parsedPrices, ["ethereum", 'prices'], {})
        const thisDate = getValue(parsedPrices, ["ethereum", 'updatedAt'], 0)
        try {
            const expirationTime = new Date(thisDate)
            const now = new Date()
            const dif = Math.abs(expirationTime.getTime() - now.getTime()) / 1000
            if (dif <= 3600 * 4 ) {     
                slugs.forEach(e => {
                    if (getValue(parsedPrices, ["ethereum", 'prices'], {})[e] === undefined) {
                        getSlugs.push(e)
                    }
                })
            } else {
                getSlugs = slugs
            }
        } catch(e) {
            try {
                localStorage.removeItem("nftPrices")
            } catch(e) {}
            getSlugs = slugs
        }
    }

    const chunkedData = []
    let x,y, temporary, chunk = 5;
    for (x = 0,y = getSlugs.length; x < y; x += chunk) {
        temporary = getSlugs.slice(x, x + chunk);
        chunkedData.push(temporary)
        // do whatever
    }
    const len = chunkedData.length
    let i = 0
    let done = 0
    let masterData = []

    while (i < len) {
        const thisChunk = chunkedData[i];
        Promise.all(thisChunk.map(id => {
            return new Promise (function(resolve, reject){
                slowLoadData(`https://api.opensea.io/api/v1/collection/${id}/stats`).then(d => {
                    resolve({id: id, value: getValue(d, ['stats', 'floor_price'], 0)})
              }).catch(e => reject(e)) 
            })
          })).then(allData => {
            masterData.push(...allData)
            done++
          }).catch(e => {
            done++
          }).finally(async () => {
        })

        await delay(2000)
        i++
    }

    const timeout = 120
    let d = 0
    while (done < len) {

        d++
        await delay(1000)

        if (d >= timeout) {
            break
        }
    }

    const calledLen = masterData.length
    let v = 0
    while (v < calledLen) {
        const line = masterData[v]
        thisPrices[line.id] = line.value
        v++
    }


    const newLocalData = {
        ...parsedPrices, 
        "ethereum": {
            updatedAt: getValue(parsedPrices, ['ethereum', 'updatedAt'], new Date().getTime()),
            prices: thisPrices
        }
    }

    localStorage.setItem("nftPrices", JSON.stringify(newLocalData))
    return newLocalData
    

}

export const getPriceForNetwork = (network, slugs) => {
    return async dispatch => {
        switch (network) {
            case 'ethereum':
                const prices = await getEthPrices(slugs)
                dispatch(updateNFTStats(prices))
                break;
            default: 
                break;
        }
    }

}

export const getNFTCollectionPrices = (network, collections) => {

    return dispatch => {
        dispatch(getPriceForNetwork(network, collections))
    }

}

function buildOpenSeaScraping(id, name, filter) {
    const url = `http://opensea.io/collection/${id}/?search[sortAscending]=true&search[sortBy]=PRICE&search[stringTraits][0][name]=${name}&search[stringTraits][0][values][0]=${filter}&search[toggles][0]=BUY_NOW`
    return url
}