import React from "react";
import { connect } from "react-redux";
import ReactTable from "react-table";
import "react-table/react-table.css";
import matchSorter from "match-sorter";
import pdfIcon from "../../Media/pdfIcon.svg";
import currencyDict from "../../helpers/currency.json";
import { loadData } from "../../helpers/helpers";
import { backendUrl } from "../../variables";
import moment from 'moment';
class TransactionOverview extends React.PureComponent {
  state = {
    data: undefined,
  };

  getData = () => {
    let headers = { "content-type": "application/json" };
    headers["Authorization"] = `Token ${this.props.token}`;

    let url;

    if (this.props.scope === "transaction") {
      url = `${backendUrl}/v1/exports/?u=${this.props.user_id}&t=transaction`;
    } else if (this.props.scope === "audit") {
      url = `${backendUrl}/v1/exports/?u=${this.props.user_id}&t=audit`;
    }
    if (this.props.account) {
      url += `&a=${this.props.portfolioId}&ascope=${this.props.ascope}`;
    } else if (this.props.portfolioId) {
      url += `&p=${this.props.portfolioId}`;
    }
    const data_fetch = loadData(url, headers);
    data_fetch.then((json) => {
      this.setState((state) => ({ ...state, data: json["data"] }));
    });
  };

  componentDidMount() {
    this.getData();
    //this.props.setCurrentView(5)
    //this.props.componentDataFetch('get', 5, null, null, this.props.portfolioId, null, null, this.props.inBitcoin)
  }

  toggleTxModal = (data) => {
    this.props.toggleModal({
      type: "audit_modal",
      open: true,
      payload: { data: data },
    });
  };

  render() {
    const columns = [
      {
        id: "created_at",
        accessor: "created_at",
        Header: () => (
          <div className="basier-p4-caps" style={{ paddingLeft: 12 }}>
            Date
          </div>
        ),
        minWidth: 150,
        maxWidth: 200,
        Cell: (row) => {
          const language = navigator.language || "en";
          const options = { year: "numeric", month: "numeric", day: "numeric" };
          const parseDate = new Date(row.value);
          const showDate = parseDate.toLocaleDateString(language, options);

          return (
            <div>
              <div className="basier-p3" style={{ paddingLeft: 12, color: 'black' }}>
                {moment(row.value).format("YYYY-MM-DD")}
              </div>
              <div className="basier-p4-caps" style={{ paddingLeft: 12, color: '#6A7E93'}}>
                {moment(row.value).format("HH:mm")}
              </div>
            </div>
          );
        },
      },
      {
        accessor: "user",
        Header: () => (
          <div className="basier-p4-caps" style={{ paddingLeft: 12 }}>
            Requested by
          </div>
        ),
        minWidth: 150,
        Cell: (row) => {
          return (
            <div>
              <div className="basier-p3" style={{ paddingLeft: 12 }}>
                {row.value.first_name} {row.value.last_name}
              </div>
            </div>
          );
        },
      },
      {
        accessor: "file",
        Header: () => (
          <div
            className="basier-p4-caps"
            style={{ textAlign: "left", paddingLeft: 12 }}
          >
            File
          </div>
        ),
        minWidth: 150,
        maxWidth: 200,
        Cell: (row) => {
          return (
            <div
              className="basier-p4-caps opacity-hover"
              style={{
                textAlign: "left",
                paddingLeft: 12,
                display: "flex",
                alignItems: "center",
              }}
            >
              {row.original.completed === true ? (
                <a
                  href={`${row.value}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "black", display: 'flex', alignItems: 'center' }}
                >
                  <img
                    src={pdfIcon}
                    alt=""
                    style={{ width: 24, height: 24, marginRight: 6 }}
                  />
                  <div>Download</div>
                </a>
              ) : (
                <div className="basier-p4" style={{color: 'black'}}>Processing...</div>
              )}
            </div>
          );
        },
      },
    ];

    return (
      <div
        style={{
          width: "100%",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: 48,
        }}
      >
        {this.state.data !== undefined && (
          <ReactTable
            defaultPageSize={5}
            resizable={false}
            defaultFilterMethod={(filter, row) =>
              String(row[filter.id]) === filter.value
            }
            showPageSizeOptions={false}
            data={this.state.data}
            columns={columns}
            getProps={(state, rowInfo, column) => {
              return {
                style: {
                  border: "0px",
                },
              };
            }}
            getTrGroupProps={(state, rowInfo, column) => {
              return {
                style: {
                  border: "0px",
                },
              };
            }}
            getTheadFilterProps={(state, rowInfo, column) => {
              return {
                style: {
                  marginTop: 6,
                  border: "none",
                },
              };
            }}
            getPaginationProps={(state, rowInfo, column) => {
              return {
                style: {
                  boxShadow: "none",
                  border: "none",
                },
              };
            }}
            getTheadProps={(state, rowInfo, column) => {
              return {
                style: {
                  boxShadow: "none",
                },
              };
            }}
            getTrProps={(state, rowInfo, column) => {
              return {
                style: {
                  display: "flex",
                  alignItems: "center",
                  marginBottom: 4,
                  marginTop: 4,
                  borderBottom: rowInfo !== undefined ? '1px solid #CCCCCC' : null,
                  height: 56,
                  background: "white",
                },
              };
            }}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user_id: state.auth.user_id,
    token: state.auth.token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TransactionOverview);
