import React from 'react';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import * as actions from "../../../../store/actions/data";
import CorrelationLineGraph from './Visuals/CorrelationLineGraph'
import CorrelationTable from './Visuals/CorrelationTable'
import MarketCapProps from './Visuals/MarketCapProps'
import BullBear from './Visuals/BullBear'
import HistVol from './Visuals/HistVol'
import Paraboles from './Visuals/Paraboles'
import AnalysisTable from './AnalysisTable'
import { backendUrl } from '../../../../variables';


const showRenderer = (type, data) => {
    switch(type) {
        case 'correlation_pf': {
            return <CorrelationLineGraph data={data} />
        }
        case 'corr_matrix': {
            return <CorrelationTable data={data} />
        }
        case 'market_cap_prop': {
            return <MarketCapProps data={data} />
        }
        case 'bull_bear': {
            return <BullBear data={data} />
        }
        case 'hist_vol': {
            return <HistVol data={data} />
        }
        case 'paraboles': {
            return <Paraboles data={data} />
        }
        default: 
        return [];
    }
}

class AnalysisOverview extends React.PureComponent {

    state = {
        data: null, 
        error: false, 
        valid: false, 
    }

    handleSelect = (id, type) => {

        this.setState(state => ({shown_details: {id: id, type: type}}))

    }

    handleClear = () => {

        this.setState(state => ({...state, shown_details: null}))

    }

    

    componentDidMount() {
        //this.props.setCurrentView(6)
        //this.props.componentDataFetch('get', 6, null, null, this.props.portfolioId, null, null, this.props.inBitcoin)
        const prod_url = `${backendUrl}/v1/analysis-detail/?id=${this.props.analysis['id']}`
        const preprod_url = `http://localhost:8000/v1/analysis-detail/?id=${this.props.analysis['id']}`

        fetch(prod_url)
            .then(res => res.json() )
            .then(json => {
                this.setState(state => ({...state, data: json['data'] , valid: true, error: false, }))
            })
            .catch(err => {
                this.setState(state => ({...state, error: true, valid: false }))
            })
    }
    
    render() {

        return (
           
            <div style={{margin: 0}}>

                {this.state.data ? 


                    showRenderer(this.props.analysis['type'], this.state.data)

                    : 

                    <div class="basier-p2"> Loading... </div>


                } 




               {/*  {this.state.data ? 
                <CorrelationLineGraph data={this.state.data}/>
                : 
                null 
                } */}
                

            </div>
            
        )


    }   
};
  

const mapStateToProps = state => {
    return {
        currency_symbol: state.auth.currency.symbol,
        currency_name: state.auth.currency.id,
        currentPf: state.data.menu_selected,
        deposits: state.data.deposits, 
        inBitcoin: state.data.bitcoinPf

    };
};

const mapDispatchToProps = dispatch => {
    return {
        setCurrentView: (view) => 
            dispatch(actions.setCurrentView(view)),
        componentDataFetch: (scope, view, currency, user, portfolio, globalGraphData, portfolioGraphData, inBitcoin) =>
            dispatch(actions.componentDataFetch(scope, view, currency, user, portfolio, globalGraphData, portfolioGraphData, inBitcoin)), 
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AnalysisOverview);


