import React from 'react';
import {
    Elements,
} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import PaymentForm from './PaymentForm'
import {strPub} from '../../../variables'

const stripePromise = loadStripe(strPub);

const paymentWrapper = (props) => {

    return (

        <Elements locale='en' stripe={stripePromise}>
            <PaymentForm 
                user_id={props.user_id}
                token={props.token}
                currency={props.currency}
                customer_id={props.customer_id} 
                planId={props.planId} 
                fullName={props.fullName} 
                setError={(m) => props.setError(m)}
                setLoading={(l, m) => props.setLoading(l, m)}
                submit={(p) => props.submit(p)} 
                handleClose={props.handleClose}
                handleSuccess={props.handleSuccess}
                //setupOnly={props.setupOnly}
            />
        </Elements>

    )

}

export default paymentWrapper