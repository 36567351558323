import React from 'react';
import { connect } from 'react-redux';
import BackIcon from "../../../../Media/BackIcon.svg";
import CoinList from '../../../MobileOnly/MobileCurrency'

import fieldValidator from '../../../../helpers/validators'
import { backendUrl } from '../../../../variables';
import axios from 'axios';
import * as appwideActions from "../../../../store/actions/appwide";
import * as dataActions from "../../../../store/actions/data";
import loadingSpinner from '../../../../Media/loadingSpinner.gif'
import successIcon from '../../../../Media/okIcon.svg'
import errorIcon from '../../../../Media/errorIcon.svg'
import currencyDict from '../../../../helpers/currency.json'
import { stat } from 'fs';

class AddStorageMobile extends React.PureComponent {

    state = {
        data: {
            coin: null, 
            name: null,
            address: null,
            wallet_type: null,
            exchange: null,
            user: this.props.user_id,
        },
        nameError: false, 
        coinError: false, 
        coinModal: false,
        loading: false,
        success: false,
        error: false,
        existing: false,
        exchangeCheck: false,
        existingCoin: null,
    }

    componentDidMount() {
        this.props.setCurrentView(971)
        let wallet_type = null;
        let exchange = null;
        let exchangeCheck = null;

        try { wallet_type = this.props.location.state.wallet_type } catch(e) { wallet_type = 'storage' }
        try { exchange = this.props.location.state.exchange } catch(e) {}
        try { exchangeCheck = this.props.location.state.data.exchange.id } catch(e) {}

        this.setState(state => ({...state, exchangeCheck: exchangeCheck, data: {...state.data, wallet_type: wallet_type, exchange: exchange}}))

    }

    coinModalClose = () => { //

        this.setState(state => ({...state, coinModal: false}))


    };

    coinModalOpen = () => { //

        this.setState(state => ({...state, coinModal: true}))

    };

    handleCoinSelect = (val) => {

        this.checkAndUpdateCoins(val)
        this.coinModalClose()

    }

    handleInput = (e, label) => {
        e.persist();
        this.setState(state => ({...state, data: {...state.data, [label]: e.target.value}}))
    }

    updateData = (label, value) => {

        this.setState(state => ({...state, data: {...state.data, [label]: value}}))

    }
    
    checkAndUpdateCoins = (val) => {

        let existing = false
        if (this.state.data.wallet_type === 'exchange') {

            const user = this.props.wallets[this.props.user_id]
            if (user) {
                let exchange = undefined
                try { exchange = this.props.wallets[this.props.user_id]['exchange'][this.state.exchangeCheck] } catch(e) {}
                if (exchange) {
                    let account = undefined
                    try { account = this.props.wallets[this.props.user_id]['exchange'][this.state.exchangeCheck][this.state.data.exchange] } catch(e) {}
                    if (account) {
                        let wallet = undefined
                        try { wallet = this.props.wallets[this.props.user_id]['exchange'][this.state.exchangeCheck][this.state.data.exchange]['wallets'][val.id] } catch(e) {}
                        if (wallet) {
                            existing = true
                            this.setState(state => ({...state, existing: true}))
                        } else {
                            existing = false
                            this.setState(state => ({...state, existing: false}))
                        }
                    }
                }
            }



        }

        if (!existing) {
            this.updateData('coin', val)
        } else {
            this.setState(state => ({...state, existingCoin: val.symbol}))
        }

    }

    updateServer = () => {
        
        let coin_val = true
        coin_val = fieldValidator(this.state.data.coin, ['isNotEmpty'])
        this.setState(state => ({...state, coinError: !coin_val}))
        let wallet_name = true
        if (this.state.data.wallet_type === 'storage') {
        wallet_name = fieldValidator(this.state.data['name'], ['isNotEmpty'])
        this.setState(state => ({...state, nameError: !wallet_name}))
        }

        if (coin_val && wallet_name) {
            
            this.setState(state => ({...state, loading: true}))
            const data = {
                ...this.state.data, 
                coin: this.state.data.coin.id,
            }
            this.setState(state => ({...state, eAloading: true}))
            let headers = { "content-type": "application/json" };
            if (this.props.token !== null && this.props.token !== undefined) {

                headers['Authorization'] = `Token ${this.props.token}`

            }

            const url_call = `${backendUrl}/v1/wallets/`

            axios.post(url_call, data, {headers: headers})
                .then(json => {

                   this.props.getWallets(this.props.user_id)
                   this.setState(state => ({...state, loading: false, success: true}))
                   
                   setTimeout(() => {

                    this.props.history.goBack()
        
                  }, 600)
                    
                })
                .catch( e => {

                this.setState(state => ({...state, loading: false, error: true}))

                })

        }
        
    }

    render () {

        return (

            this.state.loading ?

            <div style={{width: '100%', marginTop: 60, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                <img src={loadingSpinner} alt="" style={{height: 50, width: 50, marginBottom: 12}} />
                <div className="basier-p3">Creating Account...</div>
            </div>

            :

            this.state.success ? 

            <div style={{width: '100%', marginTop: 60, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                <img src={successIcon} alt="" style={{height: 50, width: 50, marginBottom: 12}} />
                <div className="basier-p3">Successfully created account!</div>
            </div>

            :

            this.state.error ? 

            <div style={{width: '100%', marginTop: 60, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                <img src={errorIcon} alt="" style={{height: 50, width: 50, marginBottom: 12}} />
                <div className="basier-p3">Oops! An error occured, please try again.</div>

                <div style={{width: '100%', display: 'flex', justifyContent: 'center', marginTop: 24}} >
                        <div
                            onClick={() => this.props.history.goBack()}
                            className='button-next'
                            style={{height: 40, borderRadius: 35, marginLeft: 12, marginRight: 12, cursor: 'pointer', backgroundColor: '#F9716B', color: 'white'}}
                            >
                            Cancel
                        </div>

                        <div
                            onClick={this.updateServer}
                            className='button-next-blue'
                            style={{height: 40, borderRadius: 35, marginLeft: 12, marginRight: 12, cursor: 'pointer' }}
                            >
                            Retry
                        </div> 
                    </div>

            </div>


            :

            <div style={{position: 'absolute', top: 0, zIndex: 999999, width: '100%'}}>

                <div style={{backgroundColor: '#F8F9FA', zIndex: 9999999, height: 75, width: '100%', display:'flex', alignItems: 'center', position: 'fixed', justifyContent: 'space-between'}} id="responsive-dialog-title">
                    
                        <div onClick={() => this.props.history.goBack()}>
                            <img style={{paddingTop: 24}} src={BackIcon} alt=""/>
                        </div>
                        <div className="basier" style={{fontSize: '1.2rem', marginLeft: -62}}>New Wallet</div> 
                        <div></div>

                    </div>

                <div style={{position: 'absolute', top: 75, zIndex: 120000, backgroundColor: 'white', minHeight: 100, width: '100%'}}>
                    <div>

                    <div style={{marginTop: 0}}>

                        <div 
                        style={{
                            height: 60,
                            display: 'flex', 
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            paddingLeft: 24,
                            borderBottom: '1px solid #E1E5E9',
                            borderTop: '1px solid #E1E5E9',
                            width: '100%',
                            textTransform: 'uppercase',
                            fontWeight: 900
                        }}
                        onClick={() => this.coinModalOpen()}
                        className="basier-p3">

                        
                        <div style={{color: this.state.coinError ?  'red' : 'black', fontWeight: 900}}>
                            Coin
                        </div>

                        <div style={{marginRight: 24, display: 'flex', alignItems: 'center'}}>

                            <div style={{marginRight: 24, color: '#6A7E93'}}>
                                {
                                    this.state.data.coin !== undefined && this.state.data.coin !== null ? 
                                        
                                        <div style={{display: 'flex', alignItems: 'center'}}>
                                            
                                            {
                                                
                                                this.state.data.coin['image_thumb'] !== "" && this.state.data.coin['image_thumb'] !== null  ? 
                                                    <span><img style={{height: 25, width: 25, marginRight: 12}} src={this.state.data.coin['image_thumb']} alt=""/></span>
                                                    :
                                                    <span style={{height: 25, width: 25, marginRight: 12, display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: `${ currencyDict[this.state.data.coin['symbol']] === undefined ? '16px' : currencyDict[this.state.data.coin['symbol']]['symbol'].length > 1 ? '16px' : '22px'}` }}>
                                                        {currencyDict[this.state.data.coin['symbol']] === undefined ? this.state.data.coin['symbol'] : currencyDict[this.state.data.coin['symbol']]['symbol'].length > 1 ? currencyDict[this.state.data.coin['symbol']]['symbol'] : currencyDict[this.state.data.coin['symbol']]['symbol'] }
                                                    </span>

                                                }
                                            
                                            <div>{this.state.data.coin['symbol']}</div>
                                        </div>
                                        
                                        :
                                        <div style={{color: this.state.coinError ? 'red' : 'gray'}}>select...</div>
                                    }
                                </div>

                                <div>
                                    >
                                </div>
                            </div>

                        </div>
                        {this.state.existing ? 
                        <div className="basier-p4" style={{color: 'red', padding: 12}}>You already have {this.state.existingCoin} wallet for this account. You cannot create multiple. If you have multiple accounts in the same exchange please create a different account</div>
                        :
                        null
                        }

                    </div>

                        {this.state.data.wallet_type === 'storage' ?
                        <div style={{marginTop: 12, paddingLeft: 12, paddingRight: 12}}>
                            <div className="basier-p4-caps"><span>Wallet name</span>{this.state.nameError ? <span style={{marginLeft: 4, color: 'red'}}>Required</span> : null}</div>
                            <div style={{marginTop: 6}} className="basier-p2">
                                <input value={this.state.data.name || ""} onChange={(e) => this.handleInput(e, 'name')} style={{width: '100%', textAlign: 'left',  height: 50, border: this.state.nameError ? '2px solid red' : '1px solid #E1E5E9', paddingLeft: 12}} className="no-outline" />
                            </div>
                        </div>
                        :
                        null
                        }

                        <div style={{marginTop: 12, paddingLeft: 12, paddingRight: 12}}>
                            <div className="basier-p4-caps">Wallet address (optional)</div>
                            <div style={{marginTop: 6}} className="basier-p2">
                                <input value={this.state.data.address || ""} onChange={(e) => this.handleInput(e, 'address')} style={{width: '100%', textAlign: 'left',  height: 50, border: '1px solid #E1E5E9', paddingLeft: 12}} className="no-outline" />
                            </div>
                        </div>

                        <div style={{marginTop: 24, width: '100%', display: 'flex', justifyContent: 'center'}} >
                            
                            <div className='button-next-blue' onClick={this.updateServer} style={{height: 40, borderRadius: 35, marginLeft: 12, marginRight: 12, cursor: 'pointer'}}>
                                Submit
                            </div>

                        </div>

                    </div>
                </div>
                <CoinList coins={this.props.coins} openModal={this.state.coinModal} closeModal={this.coinModalClose} handleSelect={(res) => this.handleCoinSelect(res)}/>
            </div>
            
        )
    }


}

const mapStateToProps = state => {
    return {
        coins: state.appwide.crypto, 
        user_id: state.auth.user_id, 
        token: state.auth.token,
        wallets: state.appwide.wallets
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getWallets: (user) => dispatch(appwideActions.getWallets(user)), 
        setCurrentView: (v) => dispatch(dataActions.setCurrentView(v))
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(AddStorageMobile)
