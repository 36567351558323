import React from "react";
import './Login.css'
import ResetForm from './ResetForm'
import { withRouter} from 'react-router-dom'; /* MAY NEED TO REMOVE */
import { withStyles } from "@material-ui/core/styles";
import background from "../../Media/signupBk.jpg";
import whiteLogo from "../../Media/whiteLogo.svg";

import Graph from "../../Media/graph1.svg";
import Globe from "../../Media/globe1.svg";
import Secure from "../../Media/secure1.svg";
import Flex from "../../Media/flex1.svg";

import { Hidden } from "@material-ui/core";


const styles = (theme) => ({
    container: {
      flex: 1,
      width: "100%",
      height: "100%",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    formContainer: {
      width: 500,
      marginLeft: "auto",
      marginRight: "auto",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        paddingLeft: 12,
        paddingRight: 12,
      },
    },
  });

  
class ResetView extends React.Component {

    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.email = React.createRef();
        this.pw = React.createRef();
        this.state = {
            forgot: false,
            uid: null, 
            token: null,
            changeSuccess: false,
            invalid: false,
        }
        //console.log(this.props.pathname)
      }

      toggleForgot = () => {
        this.setState(state => ({...state, forgot: !state.forgot}))
        }

      handleSubmit(event) {

        event.preventDefault();
      }
      validateChange = () => {
          this.setState(state => ({...state, changeSuccess: true}))
      }
      componentDidMount() {
        document.body.style.backgroundColor = 'white'
        let uid, tok;

        //console.log(this.props.match)
        try {
            uid = this.props.match.params.uid
            tok = this.props.match.params.token
        } catch(e) {
            this.setState(state => ({...state, invalid: true}))
            //this.props.history.push('/reset')
        }
        this.props.history.push('/reset')

        if (uid === null || tok === null || uid === undefined || tok === undefined) {
            //this.props.history.push('/')
            this.setState(state => ({...state, invalid: true}))
        }

        this.setState(state => ({...state, uid: uid, token: tok}))
        //this.props.history.push('/')

      }

     componentWillUnmount() {
        window.scrollTo(0, 0);
        document.body.style.backgroundColor = null
     }

     
    render () {
    
        const { classes } = this.props;

    return (

        <React.Fragment>
        <div
          style={{
            display: "flex",
            height: "100vh",
            width: "100%",
          }}
        >
          <Hidden smDown>
            <div
              style={{
                flex: 1,
                width: "50%",
                backgroundImage: `url(${background})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: 'cover'
              }}
            >
              <div style={{paddingTop: 48, paddingLeft: 48 }}>
                <img src={whiteLogo} style={{ width: 150 }} alt="" />
                <div style={{marginTop: 60}}>
                    <div className="poppins" style={{fontSize: 36, color: 'white'}}>
                        Filter through the Noise,
                    </div>
                    <div className="poppins" style={{fontSize: 36, color: 'white'}}>
                        Distill Insights.
                    </div>
                    <div className="basier-p1" style={{color: 'white'}}>
                        Track and analyze your crypto investments, <b>Wisly</b>
                    </div>
                </div>
              </div>

              <div style={{marginTop: 60, marginLeft: 48}}>
                <div style={{display: 'flex', alignItems: 'center', marginTop: 48}}>
                  <img src={Graph} style={{height: 50}} alt="" />
                  <div style={{marginLeft: 24}}>
                    <div className="poppins" style={{fontSize: 20, color: 'white'}}>Advanced Analytics</div>
                    <div className="basier-p3" style={{color: 'white'}}>
                          Profit breakdown, cost basis, and more.
                    </div>
                  </div>
                </div>

                <div style={{display: 'flex', alignItems: 'center', marginTop: 48}}>
                  <img src={Globe} style={{height: 50}} alt="" />
                  <div style={{marginLeft: 24}}>
                    <div className="poppins" style={{fontSize: 20, color: 'white'}}>Full Coverage</div>
                    <div className="basier-p3" style={{color: 'white'}}>
                          Largest coverage of crypto and derivatives, updated daily.
                    </div>
                  </div>
                </div>

                <div style={{display: 'flex', alignItems: 'center', marginTop: 48}}>
                  <img src={Secure} style={{height: 50}} alt="" />
                  <div style={{marginLeft: 24}}>
                    <div className="poppins" style={{fontSize: 20, color: 'white'}}>Secure and Private</div>
                    <div className="basier-p3" style={{color: 'white'}}>
                          Your data is encrypted and kept private.
                    </div>
                  </div>
                </div>

                <div style={{display: 'flex', alignItems: 'center', marginTop: 48}}>
                  <img src={Flex} style={{height: 50}} alt="" />
                  <div style={{marginLeft: 24}}>
                    <div className="poppins" style={{fontSize: 20, color: 'white'}}>Flexibility, On The Go.</div>
                    <div className="basier-p3" style={{color: 'white'}}>
                          Use the Web App or Native iOS/Android app.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Hidden>
          <div className={classes.container}>
            <div className={classes.formContainer}>
              <div
                style={{
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div className="login-title">
                  <h1
                    className="poppins"
                    style={{ fontSize: 40, lineHeight: "42px" }}
                  >
                    Reset Password
                  </h1>
                </div>
                {this.state.invalid ? 
                <div>This reset link is invalid or expired. Please request a new password reset link.</div>
                :
                <ResetForm uid={this.state.uid} token={this.state.token} cancelReset={this.props.cancelReset} />
                }
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
        
    )
}
}

export default withStyles(styles)( withRouter(ResetView));