import React from 'react';
import { connect } from 'react-redux';
import currencyDict from '../../../../helpers/currency.json';
import {siwtchTransactionType, loadData, dateParser} from '../../../../helpers/helpers'
import Tooltip from '../../../AppWide/Tooltip';
import questionMark from '../../../../Media/questionMark.svg'
import loader from '../../../../Media/contentLoader.svg'
import eyeIcon from '../../../../Media/eyeIcon.svg'
import MenuPopper from '../../../AppWide/MenuPopper';
import * as actions from "../../../../store/actions/interactions";
import * as appwideActions from "../../../../store/actions/appwide";
import * as dataActions from "../../../../store/actions/data";

import { backendUrl } from '../../../../variables'
import axios from 'axios';


class TransactionTableRow extends React.PureComponent {
    
    state = {
        loading: false,
    }

    updateId = () => {

    }

    getData = (transaction_id) => {
        let headers = { "content-type": "application/json" };
        if (this.props.token !== null && this.props.token !== undefined) {

            headers['Authorization'] = `Token ${this.props.token}`

        }

        const url_call = `${backendUrl}/v1/transaction_detail/${transaction_id}`

        const transactionDetail = loadData(url_call, headers)
        transactionDetail
            .then(json => {

                //this.props.getAllPortfolioList(this.props.user_id)

                this.setState(state => ({...state, transctionData: json['data'], connectedEdit: {...this.state.connectedEdit, unit_price: json['data']['unit_price'], transaction_description: json['data']['transaction_description']}}))

            })

    }

    handleDelete = () => {

        //console.log(this.state)
        this.setState(state => ({...state, loading: true}))
        let headers = { "content-type": "application/json" };
        if (this.props.token !== null && this.props.token !== undefined) {
    
            headers['Authorization'] = `Token ${this.props.token}`
    
        }
    
        const url_call = `${backendUrl}/v1/position/${this.props.data.position_id}/entry/${this.props.data.id}/?u=${this.props.user_id}`
    
        axios.delete(url_call, {headers: headers})
            .then(json => {
                
                this.setState(state => ({...state, loading: false, success: true}))
                this.props.getOverallData()
                this.props.componentDataFetch('update', 2, this.props.currency_id, this.props.user_id, this.props.portfolioId, {}, {}, this.props.inBitcoin)
                this.props.getUserLimits(this.props.user_id)

            })
            .catch( e => {
    
              this.setState(state => ({...state, loading: false, error: true}))
    
            })

    }
    delete = () => {

        this.props.toggleModal(
            {
                type: 'confirmation_modal', 
                payload: {
                    message: "Are you sure you want to delete this position. Any related entries (position increases, reductions or realized P&L) will be deleted as well if applicable.", 
                    handleConfirm: this.handleDelete
                }, 
                open: true
            }
        )


    }

    render () {

        const {data} = this.props
        const language = navigator.language || 'en'
        const optionsDate = {year: 'numeric', month: 'numeric', day: 'numeric' };
        const optionsTime = {hour: '2-digit', minute: '2-digit'};
        const parseDate = new Date(data.date);
        const showDate = parseDate.toLocaleDateString(language,optionsDate)
        const showTime = parseDate.toLocaleTimeString(language, optionsTime)

        let menuItems = [['Delete', this.delete]]

        return (
            
            
            <div className="basier" style={{display: 'flex', width: '100%', alignItems: 'center', height: 80, borderBottom: '1px solid #CCC'}}>
                
                
                <div style={{minWidth: 50, maxWidth: 50, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>

                    <span style={{height: 24, padding: 4}}> 
                    <input
                        type="checkbox"
                        className="regular-checkbox"
                        style={{backgroundColor: 'red'}}
                        onChange={() => this.props.selectSingle(data.id)}
                        checked={this.props.selected[data.id]}
                        
                        />
                    </span>

                </div>
                    

                <div style={{minWidth: 150, maxWidth: 200, flex: 1}}>

                    {this.state.loading && <div style={{height: 50, minWidth: 150, maxWidth: 200, flex: 1, backgroundColor: 'rgba(255,255,255,1)', position: 'absolute', display: 'flex', alignItems: 'center', justifyContent: 'center'}}><img src={loader} style={{height: 50}} alt="loading" /></div>}
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                            
                            <div style={{display: 'flex', flexDirection: 'column', width: 'fit-content'}}>
                                 
                                <div style={{textTransform: 'uppercase', fontWeight: 900, marginRight: 12}} className={`basier-p3 transaction-bubble transaction-color-${data.side === 'long' ? 'buy' : 'sell'}`}>
                                    {data.side}
                                </div>  
                            </div>

                        {this.props.account === true ? 

                        <div className="basier-p4" style={{paddingLeft: 12, marginTop: 6}}>
                            on {data.portfolio_name}
                        </div>

                        :

                        <div className="basier-p4" style={{paddingLeft: 12, marginTop: 6}}>
                            by {data.user_first} {data.user_last}
                        </div>

                        }
                    </div>

                </div>

                <div style={{minWidth: 150, flex: 1, paddingLeft: 6, paddingRight: 6, }}>

                    <div style={{display: 'flex'}}>
                        <div>

                            {
                                data.base_img !== "" && data.base_img !== null  ? 
                                <span><img style={{height: 40, width: 40, marginRight: 12}} src={data.base_img} alt=""/></span>
                                :
                                <span style={{height: 40, width: 40, border: '2px solid black', color: 'black', borderRadius: '100%', marginRight: 12, display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: `${ currencyDict[data.base_ticker] === undefined ? '16px' : currencyDict[data.base_ticker]['symbol'].length > 1 ? '16px' : '22px'}` }}>
                                    {currencyDict[data.base_ticker] === undefined ? data.base_ticker : currencyDict[data.base_ticker]['symbol'].length > 1 ? currencyDict[data.base_ticker]['symbol'] : currencyDict[data.base_ticker]['symbol'] }
                                </span>


                            }


                        </div>
                        <div>
                            <div className='basier-p3' style={{fontWeight: 400, color: 'black'}}>
                                {data.base_ticker === null ? 
                                <div>{data.connection_name}</div> 
                                :
                                <div>{data.base_ticker}/{data.quote_ticker}</div>}
                            </div>
                            <div className="basier-p4-caps">{
                            data.base_ticker === null ? 
                            'Not supported' :
                            data.position_name}</div>
                        </div>
                    </div>
                    
                </div>
                
                { this.props.account ? null : 
                <div style={{minWidth: 150, flex: 1, paddingLeft: 6, paddingRight: 6, }}>

                    
                    <div>
                        {
                            data.initial ?
                            <div style={{fontWeight: 400}} className="basier-p4-caps">New {data.side}</div>
                            : 
                            data.close ? 
                                <div style={{fontWeight: 400}} className="basier-p4-caps">Closing Position</div>
                            :
                            data.increase ? 
                                <div style={{fontWeight: 400}} className="basier-p4-caps">Add to Position</div>
                            :
                            data.decrease ? 
                                <div style={{fontWeight: 400}} className="basier-p4-caps">Reducing Position</div>
                            :
                            null
                            
                        }
                    </div>
                    <div style={{display: 'flex', marginTop: 6}}>
                        <div>
                            <img style={{height: 20, width: 20, marginRight: 12}} src={data.base_ticker === null ? data.connection_image : data.exchange_img} alt=""/>
                        </div>
                        <div>
                            <div className='basier-p3' style={{fontWeight: 400, color: 'black'}}>{data.base_ticker === null ? data.connection_exchange : data.exchange_name}</div>
                        </div>
                    </div>
                    

                </div>
                }

                <div style={{minWidth: 120, maxWidth: 200, flex: 1, textAlign: 'left', paddingLeft: 6, paddingRight: 6, }}>
                    <div className="basier-p3" style={{color: 'black'}}>{
                        
                        data.amount.toLocaleString('en', {minimumFractionDigits: 8, maximumFractionDigits: 8})}
                    
                    </div>
 
                    <div className="basier-p4">@ {
                        data.price === null ? 
                        null :
                        data.quote_type == 'fiat' ? 
                        data.price.toLocaleString('en', {minimumFractionDigits: 2, maximumFractionDigits: 2})
                        :
                        data.price.toLocaleString('en', {minimumFractionDigits: 8, maximumFractionDigits: 8})
                        
                    }</div>


                </div>

                <div style={{minWidth: 120, maxWidth: 200, flex: 1, paddingLeft: 4, paddingRight: 4,}}>
                    <div className="basier-p3" style={{color: 'black'}}>{showDate}</div>
                    <div className="basier-p4">{showTime}</div>
                </div>
                
                {this.props.account === true ? null : 
                <div className="basier-p4-caps" style={{minWidth: 120, maxWidth: 120, flex: 1, display: 'flex', alignItems: 'center'}}>
                    <MenuPopper menuItems={menuItems} />
                </div>}


                
            </div>


        )
    }


}

const mapStateToProps = state => {
    return {
        token: state.auth.token, 
        user_id: state.auth.user_id,
        inBitcoin: state.data.bitcoinPf, 
        currency_id: state.auth.currency.id, 
        portfolioId: state.data.menu_selected,

    };
};

const mapDispatchToProps = dispatch => {
    return {
        toggleModal: (data) => 
            dispatch(actions.toggleModal(data)), 
        getWallets: (user) => dispatch(appwideActions.getWallets(user)), 
        getUserLimits: (user) => dispatch(appwideActions.getUserLimits(user)), 
        componentDataFetch: (scope, view, currency, user, portfolio, globalGraphData, portfolioGraphData, inBitcoin) =>
            dispatch(dataActions.componentDataFetch(scope, view, currency, user, portfolio, globalGraphData, portfolioGraphData, inBitcoin)), 
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(TransactionTableRow)
