import React from 'react';
import {withRouter, Redirect} from 'react-router-dom'; /* MAY NEED TO REMOVE */
import App from './App'
import Reset from './containers/Login/Reset'
import { BrowserRouter as Router } from "react-router-dom";
import { connect } from "react-redux";
import BaseRouter from "./routes";

class Intro extends React.PureComponent {

   
    componentDidMount() {

    }

    render () {

        return (
            <Redirect to={this.props.pathname}/>
        )
    }


}

export default withRouter(Intro)
