import React from 'react';
import { connect } from 'react-redux';
import { ClickAwayListener } from '@material-ui/core';
import fieldValidator from '../../../../../../helpers/validators' 
import { backendUrl } from '../../../../../../variables'
import axios from 'axios';
import * as appwideActions from "../../../../../../store/actions/appwide";
import * as dataActions from "../../../../../../store/actions/data";
import _ from "lodash"

const getValue = (dict, value) => {

    let ret = null; 
    try { ret = dict[value]} catch(e) {}
    return ret

}

class SubmitForm extends React.PureComponent {

    state = {
        active: false,
        ix: 0,
    }

    disableKeyboard = () => {
        this.setState(state => ({...state, active: false, ix: 0}))
        document.removeEventListener("keydown", this.handleMoveKeys);
    }    

    componentDidUpdate(prevProps) {
        if (this.props.goToSubmit === true && (this.props.goToSubmit !== prevProps.goToSubmit) ) {
            document.addEventListener("keydown", this.handleMoveKeys);
            this.setState(state => ({...state, active: true, ix: 0}))
            this.props.updateState('submitStep', false)
        }   
    }


    validateData = (data) => {
        let valid = true; 
        let base_currency_val = true;
        let amount_val = true;
        let transaction_type_val = true;
        let quote_currency_val = true;
        let unit_price_val = true;
        let date_val = true; 
        let user_transaction_val = true; 
        let fee_currency_val = true; 
        let fee_val = true; 

        base_currency_val = fieldValidator(data['base_currency'], ['isNotEmpty'])
            valid = valid && base_currency_val
            this.props.validatorUpdate('base_currency', base_currency_val)

        transaction_type_val = fieldValidator(data['transaction_description'], ['isNotEmpty'])
            valid = valid && transaction_type_val
            this.props.validatorUpdate('description', transaction_type_val)

        date_val = fieldValidator(data['date'], ['isNotEmpty'])
            valid = valid && date_val
            this.props.validatorUpdate('date', date_val)

        amount_val = fieldValidator(data['amount'], ['isNotEmpty', 'isPositive'])
            valid = valid && amount_val
            this.props.validatorUpdate('amount', amount_val)
        
        user_transaction_val = fieldValidator(data['user_transaction'], ['isNotEmpty', 'isPositive'])
            valid = valid && user_transaction_val
            this.props.validatorUpdate('user_transaction', user_transaction_val)

        if (['buy', 'ico', 'sell', 'miming'].includes(data.transaction_description)) {
            
            unit_price_val = fieldValidator(data['unit_price'], ['isNotEmpty', 'isPositive'])
                valid = valid && unit_price_val
                this.props.validatorUpdate('unit_price', unit_price_val)

            quote_currency_val = fieldValidator(data['quote_currency'], ['isNotEmpty'])
                valid = valid && quote_currency_val
                this.props.validatorUpdate('quote_currency', quote_currency_val)

        } else {
            this.props.validatorUpdate('unit_price', true)
            this.props.validatorUpdate('quote_currency', true)
        }

        if (data.fee !== null && data.fee !== undefined && data.fee !== '' && data.fee !== 0 && data.fee !== "0") {

            fee_val = fieldValidator(data['fee'], ['isNotEmpty', 'isPositive'])
                valid = valid && fee_val
                this.props.validatorUpdate('fee', fee_val)

            fee_currency_val = fieldValidator(data['fee_currency'], ['isNotEmpty'])
                valid = valid && fee_currency_val
                this.props.validatorUpdate('fee_currency', fee_currency_val)

        } else {
            this.props.validatorUpdate('fee_currency', true)
            this.props.validatorUpdate('fee', true)
        }


        if (
            data.storage_name !== undefined && 
            data.storage_name !== null && 
            data.storage_name !== '' && 

            data.account_name !== undefined && 
            data.account_name !== null && 
            data.account_name !== '' 
            ) {

                let unique_name = true
                let account_name_check = true

                if (data['storage_name'] !== null && data['storage_name'] !== undefined && data['storage'] == -1) {

                    unique_name = fieldValidator(data['storage_name'], ['isNotIncluded'], this.props.accountNames)
                    this.setState(state => ({
                    ...state, 
                    validations: {
                        ...state.validations,
                        storageName: unique_name}
                    })
                    )

                }

                if (data['account_name'] !== null && data['account_name'] !== undefined && data['account'] == -1) {

                    account_name_check = fieldValidator(data['account_name'], ['isNotIncluded'], this.props.accountNames)
                    this.setState(state => ({
                    ...state, 
                    validations: {
                        ...state.validations,
                        accountName: account_name_check}
                    })
                    )

                }

                valid = valid && unique_name && account_name_check
            
            }
        

        return valid
    
    }

    prepareData = (scopeData) => {
        let quote = null
        let feeCurr = null 
        let exchange = null 
        let custom_exchange = null 
        try { quote = scopeData.quote_currency.value } catch(e) {}
        try { feeCurr = scopeData.fee_currency.value } catch(e) {}

        if (['deposit', 'dividend', 'income', 'gift', 'airdrop', 'fork', 'withdraw', 'purchase', 'donation', 'transfer'].includes(scopeData.transaction_description)) {
            feeCurr = scopeData.base_currency.value
        }


        if (scopeData['exchange'] !== null && scopeData['exchange'] !== undefined) {

            if (getValue(scopeData['exchange'],'custom') === true) {
        
                custom_exchange = {id: scopeData['exchange'].value, name:scopeData['exchange'].name}
                exchange = null
        
            } else {
        
                try { exchange = scopeData['exchange'].value } catch(e) {}
                custom_exchange = null
        
            }
        
        } else {

            exchange = null;
            custom_exchange = null;

        }

        let unit = scopeData.unit_price 
        try {
            unit = unit.trim()
        } catch(e) {}
        
        if ( scopeData.transaction_description === 'mining' ) {
            if (
                scopeData.mining_cost !== undefined && 
                scopeData.mining_cost !== null && 
                scopeData.mining_cost !== '' && 
                isNaN(scopeData.mining_cost) === false &&
                scopeData.quote_currency !== undefined && 
                scopeData.quote_currency !== null && 
                scopeData.quote_currency !== ''
                ) 
                {
                    unit = parseFloat(scopeData.mining_cost) / parseFloat(scopeData.amount)
                }
        } else if (this.props.passState.priceType !== 'unit') {
            unit = parseFloat(scopeData.unit_price) / parseFloat(scopeData.amount)
        }

        let foundSource = 'deduct'
        if ((scopeData.transaction_description === 'buy' || scopeData.transaction_description === 'ico') && this.props.passState.deduct === false) {
            foundSource = 'add'
        } else if (scopeData.transaction_description === 'mining' && this.props.passState.addCost === true) {
            foundSource = 'add'
        }

        let amount = scopeData.amount
        if (['deposit', 'dividend', 'income', 'gift', 'airdrop', 'fork'].includes(scopeData.transaction_description)) {
            if (
                this.props.passState.feeInclude === false && 
                scopeData.fee !== null && 
                scopeData.fee !== undefined && 
                scopeData.fee !== '' && 
                isNaN(parseFloat(scopeData.fee)) === false 
                ) {

                amount = parseFloat(amount) + parseFloat(scopeData.fee)

            }
        } else if (['withdraw', 'purchase', 'donation'].includes(scopeData.transaction_description)) {

            if (this.props.passState.feeInclude === true && scopeData.fee !== null && scopeData.fee !== undefined && scopeData.fee !== '' && isNaN(parseFloat(scopeData.fee)) == false ) {

                amount = parseFloat(amount) + parseFloat(scopeData.fee)

            }

        }

        if (unit === "") {
            unit = null
        }
        let data = {
            transaction_description: scopeData.transaction_description,
            amount: amount,
            unit_price: unit, 
            fee: scopeData.fee,
            date: scopeData.date,
            tid: scopeData.tid, 
            user_transaction: scopeData.user_transaction,
            user_wallet_transfer: scopeData.user_wallet_transfer !== undefined ? scopeData.user_wallet_transfer : scopeData.user_transaction,
            portfolio: this.props.portfolioId, 
            base_currency: scopeData.base_currency.value,
            quote_currency: quote, 
            fee_currency: feeCurr, 
            destination_portfolio: this.props.portfolioId, 
            from_wallet: scopeData.from_wallet,
            storage: scopeData.storage,
            storage_name: scopeData.storage_name,
            custom_exchange: custom_exchange,
            exchange: exchange,
            account: exchange || custom_exchange ? scopeData.account : null,
            account_name: exchange || custom_exchange ? scopeData.account_name : null,
            fund_source: foundSource, 
            user_id: this.props.user_id,
        }

        return data
    
    }

    toggleReview = () => {

        let data = this.prepareData(this.props.transaction)
        const valid = this.validateData(data);
        

        if (valid) {
            this.props.toggleReview()
        }

    }

    submitData = () => {

        let data = this.prepareData(this.props.transaction)

        const valid = this.validateData(data);
        

        if (valid) {

            if (this.props.scope === 'new') {
                this.handleNew(data);
            }
            else if (this.props.scope === 'update') {
                this.handleUpdate(data);
            }
            
        } else {
        }
        /* if (type === 'new') {
            this.handleNew(data);
        } else if (type === 'update') {
            this.handleUpdate(data);
        } */

    }

    handleUpdate = (data) => {

        const initialData = this.props.initialState.transactionData
        const oldData = this.prepareData(initialData)
        let changed = {}

        // const diff =_.reduce(oldData, function(result, value, key) {
        //     return _.isEqual(value, data[key]) ?
        //         result : result.concat(key);
        // }, []);
        //console.log('diff', diff)

        for (let key in data) {

            if (data[key] !== oldData[key] && data[key] !== undefined) {
                changed[key] = data[key]
            }
          }

        if (Object.keys(changed).length > 0) {

            this.props.editServer(data)

        } 
    }


    handleNew = (data) => {

        const formData = {...data}

        this.props.updateState('loading', true)

        let headers = { 
            "content-type": "application/json",     
          };
          headers["Authorization"] = `Token ${this.props.token}`;

          axios
        .post(`${backendUrl}/v1/transactions/`,
            formData
        , { headers: headers } )
        .then(res => {

          if (res.data['status'] === 'OK') {


            this.props.updateState('completed', true)

            if (this.props.refresher !== undefined){
              this.props.refresher()
            }
            if (this.props.overridePort === true) {
                this.props.componentDataFetch('update', 1, this.props.currency_id, this.props.user_id, this.props.portfolioId, {}, {}, this.props.inBitcoin);
            } else {
                this.props.componentDataFetch('update', this.props.currentView, this.props.currency_id, this.props.user_id, this.props.portfolioId, {}, {}, this.props.inBitcoin);
            }
              this.props.getWallets(this.props.user_id);
              this.props.getUserLimits(this.props.user_id)
              //this.props.getExchanges(this.props.user_id);
              this.props.getWalletBalance(this.props.portfolioId)
              this.props.getHoldings(this.props.user_id, this.props.portfolioId)

          } else {

            this.props.updateState('error', true)

          }


      })
      .catch(err => {

          console.log(err.response)
          this.props.updateState('error', true)

      }) 


    }


    handleMoveKeys = (e) => {
        e.preventDefault();

        const isShift = !!e.shiftKey

        if (e.key === "Tab" || e.keyCode === 9) {
            if (isShift) {
                if (this.state.ix === 0) {
                    this.moveOn()
                } else {
                    this.setState(state => ({...state, ix: 0}))
                }
                
            } else {
                this.setState(state => ({...state, ix: 1}))
            }
        }
    
        else if (e.key === "Enter" || e.keyCode === 13 || e.key === "Space" || e.keyCode === 32) {
            if (this.state.ix === 0) {
                this.submitData()
                this.disableKeyboard()
            } else if (this.state.ix === 1) {
                this.toggleReview()
                this.disableKeyboard()
            } else if (this.state.ix === 2) {
                this.props.handleCancel()
                this.disableKeyboard()
            }
        }

        else if (e.key === "Left" || e.keyCode === 37 || e.key === "ArrowLeft") {

            if (this.state.ix === 1) {
                this.setState(state => ({...state, ix: 0}))
            } else if (this.state.ix === 2) {
                this.setState(state => ({...state, ix: 1}))
            }

        } else if ( e.key === "Right" || e.keyCode === 39 || e.key === "ArrowRight"  ) {
            if (this.state.ix === 0) {
                this.setState(state => ({...state, ix: 1}))
            } else if (this.state.ix === 1) {
                this.setState(state => ({...state, ix: 2}))
            }
        }

    }

    moveOn = () => {
        this.props.handleBack()
        this.setState(state => ({...state, active: false, ix: 0}))
        document.removeEventListener("keydown", this.handleMoveKeys);
    }

    render () {

        return (

            this.props.review ? 

            <div style={{display: 'flex', alignItems: 'center'}}>

                <ClickAwayListener key='review' onClickAway={this.disableKeyboard}>
                <div className={`basier-p4-caps opacity-hover ${this.state.active === true  && this.state.ix === 1 ? 'grow-shaddow' : ''}`} onClick={() => this.props.toggleReview()} style={{height: 35, borderRadius: 35, marginLeft: 6, marginRight: 6, marginTop: 0, cursor: 'pointer', color: 'white', backgroundColor: '#F9716B', width: 150, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    Back to Entry
                </div>
                </ClickAwayListener>

                <ClickAwayListener key='submit' onClickAway={this.disableKeyboard}>
                <div className={`basier-p4-caps opacity-hover ${this.state.active === true  && this.state.ix === 0 ? 'grow-shaddow' : ''}`} onClick={() => this.submitData()} style={{height: 35, borderRadius: 35, marginLeft: 6, marginRight: 6, marginTop: 0, cursor: 'pointer', color: 'white', backgroundColor: '#0078DB', width: 150, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    Submit
                </div>
                </ClickAwayListener>

            </div>

            :

            <div style={{display: 'flex', alignItems: 'center'}}>
            
            <ClickAwayListener key='submit' onClickAway={this.disableKeyboard}>
              <div className={`basier-p4-caps opacity-hover ${this.state.active === true  && this.state.ix === 0 ? 'grow-shaddow' : ''}`} onClick={() => this.submitData()} style={{height: 35, borderRadius: 35, marginLeft: 3, marginRight: 3, marginTop: 0, cursor: 'pointer', color: 'white', backgroundColor: '#0078DB', width: 80, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                OK
              </div>
            </ClickAwayListener>

            <ClickAwayListener key='review' onClickAway={this.disableKeyboard}>
              <div className={`basier-p4-caps opacity-hover ${this.state.active === true  && this.state.ix === 1 ? 'grow-shaddow' : ''}`} onClick={() => this.toggleReview()} style={{height: 35, borderRadius: 35, marginLeft: 3, marginRight: 3, marginTop: 0, cursor: 'pointer', color: 'white', backgroundColor: '#0078DB', width: 80, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                Review
              </div>
            </ClickAwayListener>

            <ClickAwayListener key='cancel' onClickAway={this.disableKeyboard}>
              <div className={`basier-p4-caps opacity-hover ${this.state.active === true  && this.state.ix === 2 ? 'grow-shaddow' : '' }`} onClick={() => this.props.handleCancel()} style={{height: 35, borderRadius: 35, marginLeft: 3, marginRight: 0, marginTop: 0, cursor: 'pointer', color: 'white', backgroundColor: '#F9716B', width: 80, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                Cancel
              </div>
            </ClickAwayListener>

            </div>
            
        )
    }


}

const mapStateToProps = state => {
    return {
        token: state.auth.token,
        currentView: state.data.currentView, 
        currency_id: state.auth.currency.id, 
        inBitcoin: state.data.bitcoinPf, 
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getWallets: (user) => dispatch(appwideActions.getWallets(user)), 
        getUserLimits: (user) => dispatch(appwideActions.getUserLimits(user)), 
        componentDataFetch: (scope, view, currency, user, portfolio, globalGraphData, portfolioGraphData, inBitcoin) =>
            dispatch(dataActions.componentDataFetch(scope, view, currency, user, portfolio, globalGraphData, portfolioGraphData, inBitcoin)), 
        getWalletBalance: (user) => dispatch(dataActions.getWalletBalance(user)), 
        getHoldings: (user, portfolio) => dispatch(dataActions.getHoldings(user, portfolio)),
        getExchanges: (user) => dispatch(appwideActions.getExchanges(user)),  
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(SubmitForm)
