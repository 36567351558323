
const fieldValidator = (value, rules, additional) => {

    let valid = true 
    for (let rule of rules) {


        switch(rule) {

            case 'isNumeric': 
                valid = valid && checkNumeric(value)
                break;
            case 'isNotEmpty': 
                valid = valid && isNotEmpty(value)
                break;
            case 'isAlpha': 
                valid = valid && checkAlpha(value)
                break;
            case 'isPositive': 
                valid = valid && checkPositive(value)
                break; 
            case 'isEmail': 
                valid = valid && isEmail(value)
                break; 
            case 'isNotIncluded': 
                valid = valid && isNotIncluded(value, additional)
                break; 
            default: 
                break;
        }

    }

    return valid
}

export default fieldValidator


const isEmail = (val) => {

    const regex = /(?:[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
    return regex.test(val)

}

const checkAlpha = (val) => {

    const clean_val = val.replace(/[\u2018\u2019]/g, "'").replace(/[\u201C\u201D]/g, '"');
    const regex = /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-‘]+$/
    return regex.test(clean_val)

}

const isNotIncluded = (val, source) => {
    const valcheck = val.toLowerCase().trim()
    return !source.includes(valcheck)
}

const checkNumeric = (val) => {

    return !isNaN(val)

}

const checkPositive = (val) => {

    if ( !isNaN(val) ) {
        if ( val > 0 ) {
            return true
        } else {
            return false
        }
    } else {
        return false
    }

}

const isNotEmpty = (value) => {

    if (value === undefined || value === null 
        || value === "" || value === []) {
        return false
    } else {
        return true
    }
    
}