import React from 'react';
import CoinSearch from '../../../../../AppWide/CoinSearch';
import * as actions from "../../../../../../store/actions/interactions";
import { connect } from 'react-redux';



class AddNewWallet extends React.PureComponent {


    state = {
        
        wallet_name: null, 
        wallet_address: null, 
    
    }

    componentDidMount() {

        this.props.updateTransaction({key: this.props.transactionId, label: "storage", value: -1})
        this.props.updateTransaction({key: this.props.transactionId, label: "storage_name", value: null})
        this.props.updateTransaction({key: this.props.transactionId, label: "storage_address", value: null})


    }

    updateInfo = (type, v) => {

        this.props.updateTransaction({key: this.props.transactionId, label: type, value: v})

    }

    render() {

        return (

            <div style={{

                display: 'flex', 
                //height: 170,//height: `${this.props.transaction['data'][this.props.transactionId]['quote_currency'] !== null && this.props.transaction['data'][this.props.transactionId]['quote_currency']  !== undefined ? '60px' : '300px'}`, 
                alignItems: 'baseline',
                transition: 'height 0.3s',
                width: '100%', 
                border: '1px solid rgba(204, 237, 254, 0.7)', 
                backgroundColor: 'rgba(204, 237, 254, 0.1)', 
                borderRadius: 25,
                padding: 24
            }}>
                       
                        <div>

                            {
                            
                            this.props.simple ? 

                            <div>

                                <div style={{color: 'black', fontWeight: 700}} className="basier-p3">New {this.props.coin} Storage Wallet details</div>
                                
                                <div style={{display: 'flex', marginTop: `${this.props.simple ? '6px' : '24px'}` }}>

                                <div style={{marginTop: 12, marginBottom: 6, width: 42, zIndex: 7000, marginRight: 8, fontWeight: 'bold', fontSize: 12}}>Name : </div>
                                <input style={{width: 140, backgroundColor: 'transparent', fontSize: 12,  marginRight: 24, height: 18, marginTop: 13}} onChange={(e) => this.updateInfo('storage_name', e.target.value) }  className="transaction-coin-search-edit" onKeyDown={(e) => console.log(e)} value={this.props.transaction['data'][this.props.transactionId]['storage_name']} />
                                
                                <div style={{marginTop: 12, marginBottom: 6, width: 125, zIndex: 7000, marginRight: 12, fontWeight: 'bold', fontSize: 12}}>Blockchain address : </div>
                                <input style={{width: 370, backgroundColor: 'transparent',  fontSize: 12, height: 18, marginTop: 13}} onChange={(e) => this.updateInfo('storage_address', e.target.value) }  className="transaction-coin-search-edit" placeholder="optional (and only if it isn't a floating address)" onKeyDown={(e) => console.log(e)} value={this.props.transaction['data'][this.props.transactionId]['storage_address']} />

                                </div>

                                {!this.props.validation.storageName && 
                                    <div style={{color: 'red', fontStyle: 'italic', marginTop: 6}} className="basier-p4">
                                        You already have an account with that name. Please select a different name.
                                    </div>
                                }

                            </div>

                            :

                            <div>
                                <div style={{color: 'black', fontWeight: 700}} className="basier-p3">New {this.props.coin} storage wallet details</div>
                                
                                <div style={{display: 'flex', marginTop: 12 }}>
                                    <div style={{marginTop: 12, marginBottom: 6, width: 170, zIndex: 7000, marginRight: 8, fontWeight: 'bold', fontSize: 14}}>Name : </div>
                                    <input style={{width: 400, backgroundColor: 'transparent', fontSize: 14,  marginRight: 24, height: 18, marginTop: 13}} onChange={(e) => this.updateInfo('storage_name', e.target.value) }  className="transaction-coin-search-edit" onKeyDown={(e) => console.log(e)} value={this.props.transaction['data'][this.props.transactionId]['storage_name']} />

                                </div>
                                {!this.props.validation.storageName && 
                                    <div style={{color: 'red', fontStyle: 'italic', marginTop: 6}} className="basier-p4">
                                        You already have an account with that name. Please select a different name.
                                    </div>
                                }   
                                <div style={{display: 'flex', marginTop: 12 }}>
                                    <div style={{marginTop: 12, marginBottom: 6, width: 170, zIndex: 7000, marginRight: 12, fontWeight: 'bold', fontSize: 14}}>Blockchain address : </div>
                                    <input style={{width: 400, backgroundColor: 'transparent',  fontSize: 14, height: 18, marginTop: 13}} onChange={(e) => this.updateInfo('storage_address', e.target.value) }  className="transaction-coin-search-edit" placeholder="optional" onKeyDown={(e) => console.log(e)} value={this.props.transaction['data'][this.props.transactionId]['storage_address']} />
                                </div>
                            </div>
                    }

                </div>
        
        </div>


        )
    }

}

const mapStateToProps = state => {
    return {
        transaction: state.interactions.transactionAddData,
        walletTransaction: state.interactions.walletTransaction,
    };
  };
  
  const mapDispatchToProps = dispatch => {
    return {

        updateTransaction: (data) =>
            dispatch(actions.updateTransaction(data)),
            
    };
  };
  
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(AddNewWallet);