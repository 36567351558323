import React from 'react';
import { connect } from 'react-redux';
import CoinSearch from '../AppWide/CoinSearch';
import loadingSpinner from '../../Media/loadingSpinner.gif'
import okIcon from '../../Media/okIcon.svg'
import errorIcon from '../../Media/errorIcon.svg'
import fieldValidator from '../../helpers/validators';

class WalletAdd extends React.PureComponent {

    constructor(props) {

        super(props);

        this.state = {
            nameExists: false
        }

        this.inputName = React.createRef();
    }

    componentDidMount() {
        this.props.updateData('user_id', this.props.user_id)
    }

    focusIn = () => {
        this.inputName.current.focus();
    }

    handleSelect = (value) => {

        let nameTest = `${value.coinTicker} Wallet ${this.props.first_name.slice(0,1).toUpperCase()}${this.props.last_name.slice(0,1).toUpperCase()}`
        let baseName = nameTest
        let iterator = 1

        let lowercaseName = nameTest
        try { lowercaseName = nameTest.toLowerCase().trim() } catch(e) {}
        let exists = this.props.existingNames.includes(lowercaseName)
        
        if (exists) {
          while (exists) {
            iterator++;
            nameTest = baseName + " " + iterator;
            lowercaseName = nameTest
            try { lowercaseName = nameTest.toLowerCase().trim() } catch(e) {}
            exists = this.props.existingNames.includes(lowercaseName)
          }
        }


        this.props.updateData('type', undefined)
        this.props.updateData('name', nameTest)

        if (value === undefined || value === null) {
            this.props.updateData('coin_id', null)
            this.props.updateData('coin_name', null)
            this.props.updateData('type', undefined)
            return
        } else {
            this.props.updateData('coin_id', value.value)
            this.props.updateData('coin_name', value.coinTicker)
            this.props.updateData('type', 'storage')

        }
        
    }
    
    render () {

        return (
            
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', flex:1, paddingLeft: 0}} >


                <div style={{display: 'flex', alignItems: 'flex-start',flex: 1, marginTop: 0, justifyContent: 'center'}}>

                <div style={{flex: 1}}>

                    <div style={{marginTop: 24}}>
                        <div style={{paddingLeft: 12, color: 'black', marginBottom: 12, display: 'flex', alignItems: 'center'}}>
                            <div className="basier-p4-caps" style={{fontWeight: 900, }}>Coin</div>
                            <div style={{color: 'red', marginLeft: 6, fontStyle: 'italic'}} className="basier-p4">
                            {
                                !this.props.validation.exchange ? 
                                
                                'Required'

                                :

                                null
                            }
                            </div>
                        </div>
                        <div style={{marginLeft: 12,}}>

                            <CoinSearch 
                                data_filter={this.props.all_crypto}
                                dataBind={this.props.exchangeName}
                                ref={this.props.passRef}
                                autoFocus={true}
                                width={324}
                                keepInput={true}
                                overrideClass={true} 
                                focusIn={this.focusIn}
                                type="coin"
                                allowOther={false}
                                clearRedux="coin"
                                clearWallets={false}
                                //removeWallets={this.props.removeWallets}
                                updaterMethod={(ref, value) => this.props.updateData(ref, value)}
                                handleSelect={(value) => this.handleSelect(value)}
                                masterClass={`${!this.props.validation.exchange ? "transaction-coin-search-large error-input" : "transaction-coin-search-large"}`}/>

                        </div>
                    </div>

                    <div style={{marginTop: 24,}}>
                        <div className="basier-p4-caps" style={{paddingLeft: 12, color: 'black', textTransform: 'uppercase', fontWeight: 900}}>
                            Account name
                        </div>
                        <div>
                            <input ref={this.inputName} onChange={(e) => this.props.updateData('name', e.target.value)} className={`light-input ${!(this.props.validation.name) || !(this.props.validation.name_exists) ? 'error-input' : ''}`} value={this.props.data.name} placeholder="Account name"
                            style={{marginLeft: 12, paddingLeft: 24, width: 324, marginTop: 12}} />
                        </div>
                        {!this.props.validation.name_exists  && 
                        <div style={{paddingLeft: 12, height: 36, color: 'red', fontStyle: 'italic', marginTop: 6, width: 324}} className="basier-p4">
                            You already have an account with that name. Please select a different name.
                        </div>
                        }
                        {!this.props.validation.name  && 
                        <div style={{paddingLeft: 12, height: 36, color: 'red', fontStyle: 'italic', marginTop: 6, width: 324}} className="basier-p4">
                            Please provide a name for your account.
                        </div>
                        }
                    </div>

                    {/* <div style={{width: 350, marginTop: 48, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>

                      <div className='basier' onClick={this.props.handleClose} 
                          style={{height: 40, borderRadius: 35, marginLeft: 12, marginRight: 12, marginTop: 24, cursor: 'pointer', color: 'white', backgroundColor: '#F9716B', width: 150, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                          Cancel
                      </div>

                      <div className='button-next-blue' onClick={() => this.props.updateServer()} 
                          style={{height: 40, borderRadius: 35, marginLeft: 12, marginRight: 12, marginTop: 24, cursor: 'pointer'}}>
                          Submit
                      </div>
                    </div>  */}
                

                </div>

            </div>
        </div>
            

        )
    }


}

const mapStateToProps = state => {
    return {
        all_crypto: state.appwide.crypto, 
        user_id: state.auth.user_id,
        existingNames: state.appwide.accountNames,
        first_name: state.auth.user_first_name,
        last_name: state.auth.user_last_name,
    };
};

const mapDispatchToProps = dispatch => {
    return {
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(WalletAdd)
