import React from 'react';
import { connect } from 'react-redux';

class LargeWalletOverview extends React.PureComponent {

    render () {

        return (
            
            <div></div>
        )
    }


}

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = dispatch => {
    return {
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(LargeWalletOverview)
