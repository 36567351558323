import React from 'react';
import CoinSearch from '../../../../../AppWide/CoinSearch';
import ToOriginList from './ToOriginList'
import ExchangeQuestion from './ExchangeQuestion'
import * as actions from "../../../../../../store/actions/interactions";
import { connect } from 'react-redux';
import WalletQuestion from './WalletQuestion'
import SelectSource from './SelectSource'
import UserList from '../UserList'
import DetailWalletList from '../QuestionBlocks/DetailWalletList'
import OriginList from './OriginList'

class TransferSecondBlock extends React.PureComponent {


    state = {
        originSelected: this.props.currentPortfolio, 
        userSend: this.props.user_id, 
        scopeUser: undefined, 
        toOriginType: 'exchange',
    }

    componentDidMount() {
        
        this.props.updateTransaction({key: this.props.transactionId, label: "user_wallet_transfer", value: this.props.user_id})

        if (this.props.scopedPortfolios !== null && this.props.scopedPortfolios !== undefined) {
            this.props.updateTransaction({key: this.props.transactionId, label: "destination_portfolio", value: this.props.currentPortfolio})
            this.setState(state => ({...state, scopeUser: this.props.scopedPortfolios[this.props.currentPortfolio]['user']}))
        }

    }


    changeOrigin = (origin) => {

        this.props.updateTransaction({key: this.props.transactionId, label: "to_wallet", value: origin})

    }

    changeToOrigin = (origin) => {

        this.setState(state => ({...state, toOriginType: origin}))
        this.props.updateTransaction({key: this.props.transactionId, label: "exchange", value: null})
        this.props.updateTransaction({key: this.props.transactionId, label: "exchange_address", value: null})
        this.props.updateTransaction({key: this.props.transactionId, label: "account", value: null})
        this.props.updateTransaction({key: this.props.transactionId, label: "storage", value: null})
        this.props.updateTransaction({key: this.props.transactionId, label: "storage_name", value: null})
        this.props.updateTransaction({key: this.props.transactionId, label: "account_name", value: null})

    }

    changePortOrigin = (origin) => {

        this.props.updateTransaction({key: this.props.transactionId, label: "exchange", value: null})
        this.props.updateTransaction({key: this.props.transactionId, label: "exchange_address", value: null})
        this.props.updateTransaction({key: this.props.transactionId, label: "account", value: null})
        this.props.updateTransaction({key: this.props.transactionId, label: "storage", value: null})
        this.props.updateTransaction({key: this.props.transactionId, label: "storage_name", value: null})
        this.props.updateTransaction({key: this.props.transactionId, label: "account_name", value: null})
        this.props.updateTransaction({key: this.props.transactionId, label: "destination_portfolio", value: null})
        this.props.updateTransaction({key: this.props.transactionId, label: "user_wallet_transfer", value: null})

        if (origin === null) {
           this.setState(state => ({...state, originSelected: origin, scopeUser: null}))  
        } else {
            const scopeUser = this.props.scopedPortfolios[origin]['user']
            this.setState(state => ({...state, originSelected: origin, scopeUser: scopeUser}))
            this.props.updateTransaction({key: this.props.transactionId, label: "user_wallet_transfer", value: this.props.user_id})
            this.props.updateTransaction({key: this.props.transactionId, label: "destination_portfolio", value: origin})

        }

    }

    handleDestinationSameChange = (val) => {

        if (val !== this.state.userSend) {

            this.props.updateTransaction({key: this.props.transactionId, label: "exchange", value: null})
            this.props.updateTransaction({key: this.props.transactionId, label: "exchange_address", value: null})
            this.props.updateTransaction({key: this.props.transactionId, label: "account", value: null})
            this.props.updateTransaction({key: this.props.transactionId, label: "storage", value: null})
            this.props.updateTransaction({key: this.props.transactionId, label: "storage_name", value: null})
            this.props.updateTransaction({key: this.props.transactionId, label: "account_name", value: null})
            this.props.updateTransaction({key: this.props.transactionId, label: "user_wallet_transfer", value: val})
            this.setState(state => ({...state, userSend: val}))
            this.changeOrigin(null)

        }
        

        
    }

    render() {

        
        return (

            <div>
                
                {(this.props.transaction['data'][this.props.transactionId]['from_wallet'] !== null && 
                 this.props.transaction['data'][this.props.transactionId]['from_wallet'] !== undefined) ? 
                <div style={{

                display: 'flex', 
                height: 60,//height: `${this.props.transaction['data'][this.props.transactionId]['quote_currency'] !== null && this.props.transaction['data'][this.props.transactionId]['quote_currency']  !== undefined ? '60px' : '300px'}`, 
                alignItems: 'baseline',
                transition: 'height 0.3s'

                }}>
                    

                    <div className='basier-p3' style={{marginTop: 12, paddingLeft: 0, width: 500, marginBottom: 6, zIndex: 7000, marginRight: 24, fontWeight:  900}}>Where did you send the coins to ?</div>
                    <ToOriginList changeOrigin={this.changePortOrigin} data={this.props.scopedPortfolios} currentPort={this.props.currentPortfolio} />
                    
                </div>

                : 

                null
                
                }

                <div>

                    {
                        this.state.originSelected !== null && this.state.originSelected !== undefined ? 

                        <div>

                            <div style={{

                                display: 'flex', 
                                height: 60,//height: `${this.props.transaction['data'][this.props.transactionId]['quote_currency'] !== null && this.props.transaction['data'][this.props.transactionId]['quote_currency']  !== undefined ? '60px' : '300px'}`, 
                                alignItems: 'baseline',
                                transition: 'height 0.3s'
                                }}>

                                <div className='basier-p3' style={{paddingLeft: 24, marginTop: 12, width: 500, marginBottom: 6, zIndex: 7000, marginRight: 24, fontWeight:  400, display: 'flex', alignItems: 'center'}}>
                                    <div>
                                        You transferred to
                                    </div>

                                        <div style={{marginLeft: 12, marginRight: 12}}>
                                            <UserList 
                                                transactionId={this.props.transactionId} 
                                                portfolioId={this.state.originSelected} 
                                                userProvided={this.state.scopeUser}
                                                prov={true}
                                                propagate={true}
                                                handleChange={
                                                    (o) => this.handleDestinationSameChange(o)
                                                } 
                                                />
                                        </div>

                                        <div style={{paddingLeft: 5}}>
                                            on 
                                        </div>

                                </div>

                                <div>

                                    <OriginList changeOrigin={this.changeToOrigin} selection={this.state.toOriginType} />

                                </div>

                            </div>

                            <div style={{marginTop: 12}}>

                            {
                            this.state.toOriginType === 'exchange'&& 
                            this.state.userSend !== null && this.state.userSend !== undefined &&
                            this.state.toOriginType !== null && this.state.toOriginType !== undefined ? 
                    

                                <ExchangeQuestion 
                                    noAdd={this.props.noAdd}
                                    exchanges = {this.props.exchanges}
                                    transaction = {this.props.transaction}
                                    transactionId = {this.props.transactionId}
                                    //firstFocusExchange = {this.firstFocusExchange}
                                    getExchangeAddress = {this.props.getExchangeAddress}
                                    updateTransaction = {this.props.updateTransaction}
                                    selectExchange = {this.props.selectExchange}
                                    onKeyDownNext = {this.props.onKeyDownNext}
                                    secondFocusExchange = {this.secondFocusExchange}
                                    selectedBase={this.props.selectedBase}
                                    selectedQuote={this.props.selectedQuote}
                                    question="Which exchange were coins sent to?"
                                    firstFocus={false}
                                    wallets={this.props.wallets}
                                    user_id={this.state.userSend}
                                    user_first=""
                                    user_last=""
                                />

                            : 

                            this.state.toOriginType === 'wallet' ? 

                                this.props.wallets !== null && this.props.wallets !== undefined ? 
                                    this.state.userSend !== null && this.state.userSend !== undefined ? 
                                        <WalletQuestion 
                                            simple={true}
                                            noAdd={false}
                                            allowAll={true}
                                            wallets = {this.props.wallets}
                                            user_id = {this.state.userSend}
                                            question="Which storage wallet?"
                                            transaction = {this.props.transaction}
                                            transactionId = {this.props.transactionId}
                                            updateTransaction = {this.props.updateTransaction}
                                            base_currency={this.props.transaction['data'][this.props.transactionId]['base_currency']}
                                            />
                                    :
                                    null
                                    :
                                
                                    null
                            

                            : 

                            null
                        }
                    
                        </div>

                    </div>


                        : 

                        null
                    }
                    

                </div>

            </div>

        )
    }

}


const mapStateToProps = state => {
    return {
  
        modalPref: state.interactions.transactionAddModal,
        transaction: state.interactions.transactionAddData,
        tr_date: state.interactions.dateTransaction,
        exchangeCheck: state.interactions.exchangeCheck, 
        originSelected: state.interactions.originSelected,
        exchanges: state.appwide.exchanges,
        wallets: state.appwide.wallets,
        user_id: state.auth.user_id,
        currentPortfolio: state.data.menu_selected, 
        scopedPortfolios: state.appwide.all_portfolios,
        scopedWallets: state.appwide.flat_wallets
    };
  };
  
  const mapDispatchToProps = dispatch => {
    return {
        addTransaction: (data) =>
            dispatch(actions.addTransaction(data)),
        updateTransaction: (data) =>
            dispatch(actions.updateTransaction(data)), 
        setTrTime: (data) => 
            dispatch(actions.setTrTime(data)), 
        setInteraction: (data) => 
            dispatch(actions.setInteraction(data)), 
    };
  };
  
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(TransferSecondBlock);
