

import React from 'react';
import { connect } from 'react-redux';
import DateTimePicker from 'react-datetime';
import * as actions from "../../../../../../store/actions/interactions";

var moment = require('moment');
require('moment/locale/en-gb');

class DateSelector extends React.Component {

    constructor(props) {

        super(props)
        this.firstFocusFees = React.createRef();
        this.secondFocusFees = React.createRef();
        this.hiddenRef = React.createRef();
        this.dateRef = React.createRef();

        this.state = {
            done: false, 
            selected_quote: null, 
            futurDate: false,
        }

        if (this.props.transaction['date'] !== null && this.props.transaction['date'] !== undefined) {
            

            const existing_date = this.props.transaction['date']
            const stateDate = moment(existing_date).local().format("DD/MM/YYYY HH:mm")
            this.props.setTrTime(stateDate) 
                


        } else {

            const now = new Date()
            const moment_date = moment(now);
            const stateDate = moment_date.local().format("DD/MM/YYYY HH:mm")
            const reduxDate = moment_date.utc().format();
            this.props.setTrTime(stateDate) 
            this.selectDate(reduxDate)


        }
    }

    shouldComponentUpdate (nextProps) {

        if (nextProps.validation.date !== this.props.validation.date) {
            return true
        } 
        if (
            ( this.props.transaction.date === nextProps.transaction.date ) ||
            ( this.props.tr_date === nextProps.tr_date )
        ) {
            return false
        }

        return true 
    }
        
    selectDate  = (val) => {
        this.props.updateTransaction('date', val)
    }

    onBlur = () => {

        if (!(this.props.transaction.base_currency !== undefined && this.props.transaction.base_currency !== null )) {
            this.props.focusBase()
        }

    }

    handleChange = (date) => {

        const testStateDate = moment( date, "DD/MM/YYYY HH:mm", true).local()

        if (testStateDate.isValid()) {

            const stateDate = testStateDate.format("DD/MM/YYYY HH:mm")
            this.props.setTrTime(stateDate)  
            let dateRedux = moment(date);
            if (dateRedux.isValid()) {           
                this.selectDate(dateRedux.utc().format())
                const now = new Date()
                const redDe = Date(dateRedux)
                
                if (date > now) {
                    this.props.validatorUpdate('date', false)
                    this.setState(state => ({...state, futurDate: true}))    
                } else {
                    this.setState(state => ({...state, futurDate: false})) 
                    this.props.validatorUpdate('date', true)    
                    this.props.postSelect(dateRedux.utc().format())
                }
                
            } else {
                
                this.props.setTrTime(date)
                this.props.validatorUpdate('date', false)    
            }

        } else {

            this.props.setTrTime(date)
            this.props.validatorUpdate('date', false)
        }
    }

    getDateRef = (ref) => {

        const ref_needed = this.getRef(this.dateRef)
        
        ref_needed
        .then(res => {

            try {res.current.focus({preventScroll: true})} catch(e) {}
            
            })

    }

    fillToday = () => {

        const now = new Date()
        const moment_date = moment(now);
        const reduxDate = moment_date.utc().format();
        const stateDate = moment_date.local().format("DD/MM/YYYY HH:mm")
        
        this.props.setTrTime(stateDate)  
        this.props.updateTransaction('date', reduxDate)
        this.props.validatorUpdate('date', true)      
        this.props.postSelect(reduxDate)   

    }

    render () {

        return (
            <div>
                <div className="basier-p4-caps" style={{marginBottom: 2, color: 'black', fontWeight: 900}}>Date</div>
                <div style={{width: 250, display: 'flex', alignItems: 'baseline'}} className="root_datepicker ">
                    <DateTimePicker 
                        onChange={(date) => this.handleChange(date)} 
                        onClick={() => console.log('on click date')}
                        value={this.props.tr_date} 
                        locale="en-gb"
                        closeOnSelect={true}
                        strictParsing={true}
                        inputProps={{className: `select_datepicker basier-p3 ${!this.props.validation.date ? "error-input" : null }`, placeholder: 'DD/MM/YYYY', onBlur: this.onBlur, onKeyDown: this.onKeyDown, ref: this.dateRef}}
                    />
                    <div className="basier-p4-caps link-hover"  style={{marginLeft: 24, width: 60,}} onClick={this.fillToday}>Now</div>
                </div>  
                {!this.props.validation.date && 
                    <div className="basier-p4" style={{marginTop: 2, fontStyle: 'italic', color: 'red'}}>
                        {this.state.futurDate ? "Date cannot be in the future" : " Invalid date. Use DD/MM/YYYY HH:mm"}
                    </div>
                }
            </div>
    
        )
    }


}

const mapStateToProps = state => {
    return {
        modalPref: state.interactions.transactionAddModal,
        feeCheck: state.interactions.feeCheck, 
        all_coins: state.appwide.all_coins,
        currentPortfolio: state.data.menu_selected, 
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setInteraction: (data) => 
            dispatch(actions.setInteraction(data)), 
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(DateSelector)
