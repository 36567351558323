import React from 'react';
import Grid from '@material-ui/core/Grid';
import currencyDict from '../../../.././../helpers/currency.json'

const SmallAssetDetail = props => {

    return (

        <div style={{
            height: 130,
            width: '100%',
            display: 'flex', 
            flexDirection: 'column',
            borderBottom: '1px solid #E1E5E9',
            justifyContent: 'center',
            paddingLeft: 12,
            }}>
            
            <div style={{width: '100%', marginBottom: 12}}>
                <div style={{display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between', marginTop: 12}}>
                    
                    <div  style={{display: 'flex', alignItems: 'flex-start'}}>
                        <img style={{height: 25, width: 25, marginRight: 12}} src={props.item.base_img} alt=""/>
                        <div>
                            <div className="basier-p3" style={{fontWeight: 'bold'}}>{props.item.contract_name}</div>
                            <div className="basier-p4" style={{fontWeight: 400}}>{props.item.exchange_name}</div> 
                        </div> 
                    </div>

                    <div style={{
                        width: 'auto', marginRight: 8}}>
                        <span style={{textTransform: 'uppercase', color: props.item.position_side === 'long' ? '#4CDAC1' : '#F9716B', fontWeight: 900}} className="basier-p4">{props.item.position_side}</span>
                    </div>

                
                </div>
            </div>

            <div style={{ 
                display: 'flex', 
                alignItems: 'flex-start',
                justifyContent: 'space-between',
                width: '100%',
                }} 

                value={props.item.coin}
                index={props.index}
                className="asset-line basier-p4">


                    <div item style={{textAlign: 'left' }} >
                        
                        <div className="basier-p4-caps" style={{marginBottom: 6}}>
                            Open
                        </div>

                        <div className="basier-p3" style={{fontWeight: 'bold'}}>
                                {props.currency_symbol}&nbsp;
                                {Math.abs(props.item.amount) > 1 ? 
                                    props.item.amount.toLocaleString('en', {maximumFractionDigits: 2, minimumFractionDigits: 2})
                                :
                                    props.item.amount.toLocaleString('en', {maximumFractionDigits: 6, minimumFractionDigits: 6})
                                }
                        </div>
                        
                        <div className="basier-p4" style={{fontSize: '0.9rem', marginTop: 2}}>
                            <span>
                            @ {props.quote_currency}&nbsp;
                            {props.item.open_price > 1 ? 
                                props.item.open_price.toLocaleString('en', {maximumFractionDigits: 2, minimumFractionDigits: 2})
                                : 
                                props.item.open_price.toLocaleString('en', {maximumFractionDigits: 6, minimumFractionDigits: 6}) }
                            </span>
                        </div>
                        
                    </div>

                    <div style={{textAlign: 'right'}} >

                        <div className="basier-p4-caps" style={{marginBottom: 6, marginRight: 8}}>
                            Profit
                        </div>
                    
                        <div style={{
                            borderRadius: 35, 
                            marginTop: 2,
                            textAlign: 'right',
                            marginRight: 8, 
                            color: props.item.active_profit > 0 ? '#4CDAC1' : '#F9716B',
                            fontWeight: 900,
                            }}
                            className="basier-p3"
                            >
                            
                            {props.quote_currency}&nbsp;
                            {
                                props.item.active_profit.toLocaleString('en', {maximumFractionDigits: 2, minimumFractionDigits: 2})

                            }

                        </div>

                        <div className="basier-p4" style={{fontSize: '0.9rem', marginTop: 0, marginRight: 8,}}>
                            <span>
                            @ {props.quote_currency}&nbsp;
                            {props.item.current_close > 1 ? 
                                props.item.current_close.toLocaleString('en', {maximumFractionDigits: 2, minimumFractionDigits: 2})
                                : 
                                props.item.current_close.toLocaleString('en', {maximumFractionDigits: 6, minimumFractionDigits: 6}) }
                            </span>
                        </div>

                    </div> 


            </div>
           
         </div>

    )
}

export default SmallAssetDetail