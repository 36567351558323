import React from 'react';
import Select, { components }  from 'react-select';
import { connect } from 'react-redux';
import loadingImg from '../../Media/contentLoader.svg'
import okIcon from '../../Media/okIcon.svg'


class MenuSelector extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      selectedOption: null, 
      menuIsOpen: false,
      editPf: false, 
      force: false, 
      existingValue: null, 
      loading: false,
    }
  }

  colourStyles =  {
    control: styles => ({ ...styles, backgroundColor: 'white',  width: this.props.largeSelect === true ? 250 : 110, minHeight: 28, height: this.props.largeSelect === true ? 50 : 25, fontSize: this.props.largeSelect === true ? 18 : 12, fontFamily: 'Basier Circle', border: this.props.largeSelect === true ? '1px solid #cccccc' : '0px', color: 'black', cursor: 'text', dispay:'flex', alignItems: 'center'}),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
  
      return {
        ...styles,
        backgroundColor: isDisabled
          ? null
          : isSelected ? 'white' : isFocused ? '#F3F5F6' : null,
        color: isDisabled
          ? '#F3F5F6'
          : isSelected ? 'hsl(207, 100%, 43%)' : 'black',
        opacity: isDisabled
          ? '1'
          : isSelected ? '1' : '0.4',
      };
    },
    menu: styles => ({...styles, fontSize: this.props.largeSelect === true ? 16 : 12, width: this.props.largeSelect === true ? 250 : 110}),  
    valueContainer: styles => ({...styles, padding: this.props.largeSelect === true ? '0px 12px' : '0x 0px'}),  
    placeholder: styles => ({...styles, color: 'hsl(207, 100%, 43%)', value: 'Select Type'}), 
    indicatorSeparator: styles => ({...styles, display: 'none'}),
    dropdownIndicator: styles => ({...styles, color: 'hsl(0,0%,80%)', '&:hover': {
        color: 'hsl(0,0%,50%)', 
        opacity: 0.8,
    }})
  };

  editPf = () => {
      this.setState(state => ({...state, editPf: true}))
  }
  
  stopEditPf = () => {
      if (this.state.force === false) {
        this.setState(state => ({...state, editPf: false}))
      }
  }

  componentDidMount() {

    const transaction_types = [
            {value: 'deposit', label: 'Deposit'},
            {value: 'withdraw', label: 'Withdraw'},
            {value: 'transfer', label: 'Transfer'},
            {value: 'mining', label: 'Rewards'},
            {value: 'fee', label: 'Fee'},
            {value: 'dividend', label: 'Dividend'},
            {value: 'income', label: 'Income'},
            {value: 'gift', label: 'Gift'},
            {value: 'airdrop', label: 'Airdrop'},
            {value: 'fork', label: 'Fork'},
            {value: 'purchase', label: 'Purchase'},
            {value: 'donation', label: 'Donation'},
            {value: 'lost', label: 'Loss'},
        ]
    
        this.setState(state => (({...state, selectedOption: transaction_types.filter(v => v.value === this.props.value)[0], options: transaction_types})))


  }

  handleMenuOpen = () => {

    this.setState(state => ({...state, force: true}))
    
  }

  handleMenuClose = () => {
    this.setState(state => ({...state, editPf: false, force: false}))
  }

  toggleMenuIsOpen = () => {
    this.setState(state => ({ menuIsOpen: false }));
  }

  submitServer = () => {
      

      setTimeout(
        () => this.setState(state => ({...state, loading: false, valid: true})), 
        1000
      )

  }

  handleComplete = () => {
    this.setState(state => ({...state, loading: false, valid: true}))
  }

  handleChange = (selectedOption) => {

    if ( (this.state.selectedOption === null) || (this.state.selectedOption === undefined) || (selectedOption.value !== this.state.selectedOption.value) ) {
      this.setState(state => ({...state, loading: true, valid: false}))
      this.setState(state => ({...state, selectedOption: selectedOption, existingValue: selectedOption.label }));
      this.props.updateServer({transaction_description: selectedOption.value})


    }
    
  }

  render() {
    const { selectedOption } = this.state;

    return (
      (this.state.selectedOption !== null) && this.state.editPf === false && this.props.largeSelect !== true ? 
      
      <div style={{paddingLeft: 2, paddingTop: 1, color: '#0078DB', display: 'flex', alignItems: 'center', justifyContent: 'space-between'}} onMouseEnter={this.props.loading !== true ? () => this.editPf() : null}>
          <div>
            {this.state.selectedOption !== undefined ? this.state.selectedOption.label : 'Select Type...'}
          </div>
          {this.props.loading === true && 
            <img src={loadingImg} alt="" style={{height: 30}} />
          }
          {
            this.props.valid === true &&
            <img className="dissapear" src={okIcon} alt="" style={{height: 18, marginRight: 6,}} />
          }
      </div>

      :
      <div onMouseLeave={this.stopEditPf}>
        <Select
            value={selectedOption}
            onChange={this.handleChange}
            options={this.state.options}
            isSearchable={true}
            styles={this.colourStyles}
            maxMenuHeight={this.props.largeSelect === true ? 200 : 80}
            minMenuHeight={80}
            onMenuOpen={() => this.handleMenuOpen()}
            onMenuClose={() => this.handleMenuClose()}
            components={{  }}
            classNamePrefix="react-select-smallPort"
            placeholder="Select Type"
            theme={(theme) => ({
            ...theme,
            borderRadius: 0,
            colors: {
            ...theme.colors,
                primary25: '#F3F5F6',
                primary: '#F3F5F6',
            },
            })}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {

  };
};

const mapDispatchToProps = dispatch => {
  return {
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MenuSelector);
