import React, { useState, useEffect } from 'react';
import Highcharts from 'highcharts';
import HighchartsMore from "highcharts/highcharts-more";
import HighchartsReact from 'highcharts-react-official'
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import * as actions from "../../store/actions/auth";
import loadingSpinner from '../../Media/contentLoader.svg'
import {createLoadingSelector} from '../../store/reducers/selectors'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
/* import HighchartsReact from 'highcharts-react-official'*/
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import currencyData from '../../helpers/currency.json'
import {nFormatter} from '../../helpers/helpers'


HighchartsMore(Highcharts);

const WaterFallComponent = React.memo((props) => (<WaterfallGraph data={props.data} currency={props.currency} inBitcoin={props.inBitcoin} />) )

const WaterfallGraph = (props) => {
    
    const options = {
        chart: {
            type: 'waterfall',
            height: 350,
        },
        credits: false,
        title: false,
        xAxis: {
            type: 'category'
        },
    
        yAxis: {
            title: {
                text: props.inBitcoin === 'bitcoin' ? 'BTC' : props.currency.toUpperCase()
            },
            plotLines: [{
                color: 'black',
                width: 1,
                value: 0,
            zIndex:2}]  
        },
    
        legend: {
            enabled: false
        },
    
        tooltip: {
            pointFormat: '<b>${point.y:,.2f}</b> USD'
        },
        plotOptions: {
            series: {
                borderWidth: 0,
            }
        },
        series: [{
            upColor: 'rgba(76, 218, 193, 0.4)',
            color: 'rgba(249, 113, 107, 0.4)',
            data: props.data,
            dataLabels: {
                enabled: true,
                crop: false,
                overflow: 'none',
                inside: false,
                formatter: function () {
                    return Math.abs(this.y) > 999 ? `${this.y < 0 ? '-' : ''}${nFormatter(this.y, 1)}` : Math.abs(this.y) > 100 ? this.y.toLocaleString('en', {minimumFractionDigits: 0, maximumFractionDigits: 0}) : this.y.toLocaleString('en', {minimumFractionDigits: 2, maximumFractionDigits: 2})
                },
                color: 'black',
                style: {
                    fontWeight: 'bold',
                    textOutline: false 
                }
            },
            pointPadding: 0
        }]
    }


        return (

            <div>
          
                    <Grid container 
                        spacing={0}>
                        
                        <div style={{width: '100%', marginLeft: -10, paddingRight: 0, position: 'relative', height: 350}}>
                      

                            <HighchartsReact
                                highcharts={Highcharts}
                                options={options}
                                oneToOne={true}
                            />


                        </div>

                    </Grid>

                </div>
            
            
        )


        
};

WaterfallGraph.propTypes = {
    classes: PropTypes.object.isRequired,
  };
  

const mapStateToProps = state => {
    return {
        inBitcoin: state.data.bitcoinPf, 
    };
};

const mapDispatchToProps = dispatch => {
    return {
                
    };
  };

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WaterFallComponent);


