import React from 'react';
import Grid from '@material-ui/core/Grid';
import currencyDict from '../../../.././../helpers/currency.json'

const SmallAssetDetail = props => {

    return (

        <div style={{
            height: props.item.oversold > 0 && props.errors !== true ? 144 : 80,
            width: '100%',
            display: 'flex', 
            flexDirection: 'column',
            borderBottom: '1px solid #E1E5E9',
            justifyContent: 'center',
            paddingLeft: 12,
            }}>

            <div style={{ 
                display: 'flex', 
                alignItems: 'center',
                width: '100%',
                }} 

                value={props.item.coin}
                onClick={props.handleDetail}
                index={props.index}
                className="asset-line basier-p3">

                    <div style={{width: '30%' }} >

                    <div style={{display: 'flex', alignItems: 'center'}}>
                        
                    {props.item.image_large !== "" && props.item.image_large !== null ? 
                        <span><img style={{height: 25, width: 25, marginRight: 12}} src={props.item.image_large} alt=""/></span>
                        :
                        <span style={{height: 25, width: 25, marginRight: 12, display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 20 }}>
                            {currencyDict[props.item.coin_symbol] === undefined ? null : currencyDict[props.item.coin_symbol]['symbol'].length > 1 ? null : currencyDict[props.item.coin_symbol]['symbol'] }
                        </span>
                        }

                        <span className="basier-p3" style={{fontWeight: 'bold'}}>{props.item.coin_ticker}</span>
                    </div>

                    <div className="basier-p3" style={{marginTop: 6, color: props.errors || props.item.oversold > 0 ? '#F9716B' : null }}>
                        {
                        props.errors ? 
                        
                        props.item.balance === 0 ?
                        
                            (-1 * props.item.oversold).toLocaleString('en', {maximumFractionDigits: 2, minimumFractionDigits: 2})

                        :

                        props.item.balance < -1 ? 
                            props.item.balance.toLocaleString('en', {maximumFractionDigits: 2, minimumFractionDigits: 2})
                        :
                            props.item.balance.toLocaleString('en', {maximumFractionDigits: 8, minimumFractionDigits: 8})
                        :
                        props.item.balance > 1 ? 
                            props.item.balance.toLocaleString('en', {maximumFractionDigits: 2, minimumFractionDigits: 2})
                        :
                            props.item.balance.toLocaleString('en', {maximumFractionDigits: 8, minimumFractionDigits: 8})
                        }
                    </div>
                    
                    </div> 


                    <div item style={{width: '40%', textAlign: 'right' }} >
                    
                    <div className="basier-p2" style={{fontWeight: 'bold'}}>
                        {props.currency_symbol} {props.item.total_value.toLocaleString('en', {maximumFractionDigits: 2, minimumFractionDigits: 2})}
                    </div>
                    
                    <div className="basier-p3" style={{fontSize: '0.9rem', marginTop: 6}}>
                        <span>
                        @ {props.currency_symbol}&nbsp;
                        {props.item.current_price > 1 ? 
                            props.item.current_price.toLocaleString('en', {maximumFractionDigits: 2, minimumFractionDigits: 2})
                            : 
                            props.item.current_price.toLocaleString('en', {maximumFractionDigits: 6, minimumFractionDigits: 6}) }
                        </span>
                    </div>
                        
                    </div>

                    <div style={{width: '25%', textAlign: 'right'}} >
                    
                        <div style={{
                            borderRadius: 35, 
                            height: 27, 
                            textAlign: 'right',
                            marginRight: 8
                            }}
                            className={`roi-container ${props.status === 'exit' ? 'neutral-bk' : props.roi  >= 1 ? 'green-bk' : props.roi < 1 ? 'red-bk' : 'neutral-bk' }`}>

                            {
                                props.status === 'exit' ?  

                                "-"
                                :

                                `${props.roi.toLocaleString('en', {maximumFractionDigits: 2, minimumFractionDigits: 2})}x`

                            }

                        </div>

                    </div> 

                    <div style={{width: '5%', textAlign: 'center'}} >
                        <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.999999 0.856573L7 6.85413L1 12.8541" stroke="#000000" stroke-miterlimit="10"/>
                        </svg>
                    </div> 


            </div>
            {props.item.oversold > 0 && props.errors !== true &&

                <div className="basier-p4" style={{marginTop: 12, paddingRight: 12, marginBottom: 6, fontStyle: 'italic', color: '#F9716B'}}>
                    Your transactions indicate you have sold or used {props.item.oversold > 1 ? props.item.oversold.toLocaleString('en', {minimumFractionDigits: 2, maximumFractionDigits: 2}) :  props.item.oversold.toLocaleString('en', {minimumFractionDigits: 2, maximumFractionDigits: 6})} more {props.item.coin_ticker} than you owned at the time of sale. We have made automatic adjustments but please review your entries' amounts and dates.
                </div>

            }
         </div>

    )
}

export default SmallAssetDetail