import React from 'react';
import { connect } from 'react-redux';
import * as actions from "../../../../../../store/actions/interactions";
import * as dataActions from "../../../../../../store/actions/data";
import Switch from '../../../../../Assets/Switch';
import UserList from '../../../../../MobileOnly/UserDrawer';
import PriceDenom from '../../../../../AppWide/InputExtraSelect';

import currencyDict from '../../../../../../helpers/currency.json';

var moment = require('moment');
require('moment/locale/en-gb');

function getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
  
        // Windows Phone must come first because its UA also contains "Android"
      if (/windows phone/i.test(userAgent)) {
          return "Windows Phone";
      }
  
      if (/android/i.test(userAgent)) {
          return "Android";
      }
  
      // iOS detection from: http://stackoverflow.com/a/9039885/177710
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
          return "iOS";
      }
  
      return "unknown";
  }

class assetModalContent extends React.PureComponent {

    

    constructor(props) {

        super(props)
        

          this.state = {
            
          }
  
      }

    
    render () {

        return (    

                    <div style={{paddingLeft: 12, paddingRight: 12}}>

                            <div className="basier-p4-caps" style={{color: 'black', fontWeight: 900, paddingLeft: 12, marginTop: 12, marginBottom: 6}}>
                                Amount
                            </div>

                            <div style={{display: 'flex', height: 50, alignItems: 'center', marginTop: 12}} className="basier-p3">
                                <input value={this.props.passState.extraData.size || ""} onChange={(e) => this.props.handleInput(e, 'size')} style={{width: '100%', textAlign: 'right',  height: 50, border: '1px solid #E1E5E9', paddingRight: 12, borderRight: 0, borderRadius: '5px 0px 0px 5px'}} type="number" pattern="[0-9]*" step="any" inputmode="decimal" className={`no-outline ${this.props.passState.validations.amount ? '' : 'error-input'}`} />

                                <PriceDenom width={120} options={this.props.passState.amountOptions} float='right' handleSelect={this.props.changePriceType} onKeyDown={null} selection={this.props.passState.extraData.amount_coin} scope="mobile" />
                            </div>
                        
                            <div className="basier-p4-caps" style={{color: 'black', fontWeight: 900, paddingLeft: 12, marginTop: 12, marginBottom: 6}}>
                                Unit Price
                            </div>
                            
                            <div style={{display: 'flex', height: 50, alignItems: 'center'}} className="basier-p3">
                                <input onFocus={this.onFocus} value={this.props.passState.extraData.price || ""} onChange={(e) => this.props.handleInput(e, 'price')} style={{width: '100%', textAlign: 'right', height: 50, border: '1px solid #E1E5E9', paddingRight: 75, zIndex: 9001, backgroundColor: 'transparent'}} type="number" pattern="[0-9]*" step="any" inputmode="decimal" className={`no-outline ${this.props.passState.validations.price ? '' : 'error-input'}`} />
                                <span style={{position: 'absolute', right: 36}}>{this.props.passState.quoteTicker}</span>
                            </div>
                           

                    </div>
                            
        )

    }
   
}

const mapStateToProps = state => {
    return {

        currency_symbol: state.auth.currency.symbol,
        coins: state.appwide.all_coins,
        transaction: state.interactions.transactionAddData,
        portfolio_users: state.data.scopedUsers, 
        user_id: state.auth.user_id, 
        user_first_name: state.auth.user_first_name,
        user_last_name: state.auth.user_last_name,
        
    };
};

const mapDispatchToProps = dispatch => {
    return {
        addTransaction: (data) =>
            dispatch(actions.addTransaction(data)),
        updateTransaction: (data) =>
            dispatch(actions.updateTransaction(data)),
        removeTransaction: (data) =>
            dispatch(actions.removeTransaction(data)), 
        callUserList: (portfolio) =>
          dispatch(dataActions.callUserList(portfolio)), 
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(assetModalContent);
