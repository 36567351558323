import React from 'react';
import { connect } from 'react-redux';
import * as actions from "../../../store/actions/interactions";
import * as dataActions from "../../../store/actions/data";
import longIcon from '../../../Media/LONG.svg';
import shortIcon from '../../../Media/SHORT.svg';
import closeIcon from '../../../Media/CLOSE.svg';
import plIcon from '../../../Media/PNL.svg';
import SingleSelect from '../../AppWide/SingleSelect';
import PriceDenom from '../../AppWide/InputExtraSelect';
import DateTimePicker from 'react-datetime';
import axios from 'axios';
import { backendUrl } from '../../../variables';
import CoinSearch from '../../AppWide/CoinSearch';
import fieldValidator from '../../../helpers/validators';
import UserList from '../../Portfolio/PortfolioComponents/Transactions/NewTransaction/UserList';
import ExchangeWallets from '../../Portfolio/PortfolioComponents/Transactions/NewTransaction/QuestionBlocks/ExchangeWallets';
import NewAccount from '../../Portfolio/PortfolioComponents/Transactions/NewTransaction/ShortForm/NewAccount';

var moment = require('moment');
require('moment/locale/en-gb');

const renderIcon = (type, invert) => {

    switch(type) {
        case 'long': {
            return <img src={longIcon} alt="" style={{width: 22, height: 22, filter: invert ? 'invert(1)' : null}} />
        }
        case 'short': {
            return <img src={shortIcon} alt="" style={{width: 22, height: 22, filter: invert ? 'invert(1)' : null}} />
        }
        case 'pnl': {
            return <img src={plIcon} alt="" style={{width: 22, height: 22, filter: invert ? 'invert(1)' : null}} />
        }
        case 'close': {
            return <img src={closeIcon} alt="" style={{width: 22, height: 22, filter: invert ? 'invert(1)' : null}} />
        }
        default: {
            return null
        }
    }

}

const renderText = (type) => {

    switch(type) {
        case 'long': {
            return <span>New long on specific contract.</span>
        }
        case 'short': {
            return <span>New long on specific contract.</span>
        }
        case 'pnl': {
            return <span>P&L entry without specifying contract.</span>
        }
        case 'close': {
            return <span>Close a currently open position on Wisly.</span>
        }
        default: {
            return null
        }
    }

}

class AddPosition extends React.PureComponent {

    constructor(props) {
        super(props)
        this.priceTypeRef = React.createRef()
        this.amountRef = React.createRef()
        this.state = {
            data: {
                user_id: this.props.user_id
            },
            scope: 'add',
            type: [],
            amountOptions: null,
            selectedExchange: null, 
            scopedContracts: null,
            selectedContract: null,
            extraData: {
                user: this.props.user_id,
                date: null,
                pnlCoin: null,
                amount_coin: 'base',
            }, 
            quoteTicker: null,
            validations: {
                exchange: true, 
                date: true,
                contract: true, 
                price: true, 
                amount: true, 
                amount_coin: true, 
                pnl: true, 
                number_message: null
            }
        }
    }   

    getRef = async (ref) => {
        return ref;
    }
    
    handleNewAccount = (name) => {
        this.setDataField("account", -1)
        this.setDataField("account_name", name)
    }

    getChildRef = (ref) => {

        const ref_needed = this.getRef(this.amountRef)
        
        ref_needed
        .then(res => {

            try {res.current.focus({preventScroll: true})} catch(e) {}
            
            })

    }

    handleUserChange = (v) => {
        this.setDataField('user', v)
    }

    handleClose = () => {

        this.props.toggleModal({type: null, open: false, payload: null})
       
      }

    selectType = (type) => {
        this.setState(state => ({...state, type: type}))
    }

    sumbit = () => {

        let contract_val = true;
        let amount_val = true;
        let date_val = true; 
        let price_val = true;  
        let exchange_val = true; 
        let pnl_val = true; 
        let amount_coin_val = true; 
        
        let number_message = this.state.validations.number_message
        amount_val = fieldValidator(this.state.extraData.size, ['isNotEmpty', 'isPositive'])
        if (amount_val === false && number_message === null) {
            number_message = "Please make sure to use only positive numbers and dots -> . <- for decimals. Do not use any spaces or commas to separate thousands."
        }
            this.setState(state => ({
            ...state, 
            validations: {
                ...state.validations,
                amount: amount_val, 
                number_message: number_message}
            })
        )

        date_val = fieldValidator(this.state.extraData.date, ['isNotEmpty'])
            this.setState(state => ({
            ...state, 
            validations: {
                ...state.validations,
                date: date_val}
            })
        )

        if (['short', 'long'].includes(this.state.type[0])) {
            
            let number_message = this.state.validations.number_message
            price_val = fieldValidator(this.state.extraData.price, ['isNotEmpty', 'isPositive'])
            if (price_val === false && number_message === null) {
                number_message = "Please make sure to use only positive numbers and dots -> . <- for decimals. Do not use any spaces or commas to separate thousands."
            }
            this.setState(state => ({
            ...state, 
            validations: {
                ...state.validations,
                price: price_val, 
                number_message: number_message}
            })
            )

            amount_coin_val = fieldValidator(this.state.extraData.amount_coin, ['isNotEmpty'])
            this.setState(state => ({
            ...state, 
            validations: {
                ...state.validations,
                amount_coin: amount_coin_val}
            })
            )

            contract_val = fieldValidator(this.state.selectedContract, ['isNotEmpty'])
            this.setState(state => ({
            ...state, 
            validations: {
                ...state.validations,
                contract: contract_val}
            })
            )


            exchange_val = fieldValidator(this.state.selectedExchange, ['isNotEmpty'])
            this.setState(state => ({
            ...state, 
            validations: {
                ...state.validations,
                exchange: exchange_val}
            })
            )

            
        } else if (this.state.type[0] === 'pnl') {

            pnl_val = fieldValidator(this.state.extraData.pnlCoin, ['isNotEmpty'])
            this.setState(state => ({
            ...state, 
            validations: {
                ...state.validations,
                pnl: pnl_val}
            })
            )

        }

        if (contract_val && amount_val && date_val && price_val && exchange_val && pnl_val && amount_coin_val) {



            let method = this.state.scope === 'edit' ? 'patch' : 'post'

            let data = {
                user_id: this.props.user_id, 
                portfolio: this.props.portfolioId, 
                contract: this.state.selectedContract, 
                exchange_id: this.state.selectedExchange,
                type: this.state.type[0],
                ...this.state.extraData
            }

            let url_call = `${backendUrl}/v1/positions/`

            if (method === 'patch') {
                url_call = `${backendUrl}/v1/position/${this.props.existingData['existingId']}/`
            }

            let headers = { "content-type": "application/json" };
                if (this.props.token !== null && this.props.token !== undefined) {

                    headers['Authorization'] = `Token ${this.props.token}`

                }

                

                axios({method: method, url: url_call, data: data, headers: headers})
                    .then(json => {
                        
                        this.props.componentDataFetch('update', this.props.currentView, this.props.currency, this.props.user_id, this.props.portfolioId, {},{},this.props.inBitcoin, this.props.token)

                        setTimeout(() => {
                            this.handleClose()
                        }, 200);

                    })
                    .catch(e => {console.log(e)})

        }
    }

    validatorUpdate = (field, data) => {
        this.setState(state => ({...state, validation: {...state.validation, [field]: data}}))
    }

    setDataField = (field, data) => {
        this.setState(state => ({...state, extraData: {...state.extraData, [field]: data}}))
    }
    componentDidMount() {
        if (this.props.existingData !== null && this.props.existingData !== undefined) {
            
            this.setState(state => ({...state, extraData: {...state.extraData, ...this.props.existingData['extraData']}, type: this.props.existingData['type'], selectedContract: this.props.existingData['selectedContract'], selectedExchange: this.props.existingData['selectedExchange'], amountOptions: this.props.existingData['amountOptions'], quoteTicker: this.props.existingData['quoteTicker'], scope: this.props.existingData['scope']}))

            let scopedContracts = []
            try {

                const rawContacts = this.props.contracts['contracts'].filter(v => v.exchange_id === this.props.existingData['selectedExchange'])
                rawContacts.map(v => {
                    scopedContracts.push({value: v['id'], label: v['symbol'], base: v['base'], target: v['target']})
                })

            } catch(e) {}
            this.setState(state => ({...state, scopedContracts: scopedContracts}))

            const existing_date = this.props.existingData['date']
            const stateDate = moment(existing_date).local().format("DD/MM/YYYY HH:mm")
            const reduxDate = moment(existing_date).utc().format();
            this.setState(state => ({...state, date: stateDate}))
            this.setDataField('date', reduxDate)


        } else {

            const now = new Date()
            const moment_date = moment(now);
            const stateDate = moment_date.local().format("DD/MM/YYYY HH:mm")
            const reduxDate = moment_date.utc().format();
            this.setState(state => ({...state, date: stateDate}))
            this.setDataField('date', reduxDate)


        }

    }

    fillToday = () => {

        const now = new Date()
        const moment_date = moment(now);
        const reduxDate = moment_date.utc().format();
        const stateDate = moment_date.local().format("DD/MM/YYYY HH:mm")
        
        this.setState(state => ({...state, date: stateDate}))
        this.setDataField('date', reduxDate)

        this.validatorUpdate('date', true)         

    }

    handleChange = (date) => {

        const testStateDate = moment( date, "DD/MM/YYYY HH:mm", true).local()

        if (testStateDate.isValid()) {

            const stateDate = testStateDate.format("DD/MM/YYYY HH:mm")
            this.setState(state => ({...state, date: stateDate}))
            let dateRedux = moment(date);
            if (dateRedux.isValid()) {       
                this.setDataField('date', dateRedux.utc().format())    
                const now = new Date()
                const redDe = Date(dateRedux)
                
                if (date > now) {
                    this.validatorUpdate('date', false)
                    this.setState(state => ({...state, futurDate: true}))    
                } else {
                    this.setState(state => ({...state, futurDate: false})) 
                    this.validatorUpdate('date', true)    
                }
                
            } else {
                
                this.setState(state => ({...state, date: date}))
                this.validatorUpdate('date', false)    
            }

        } else {

            this.setState(state => ({...state, date: date}))
            this.validatorUpdate('date', false)

        }
    }

    selectExchange = (exchange) => {

        let scopedContracts = []
        try {

            const rawContacts = this.props.contracts['contracts'].filter(v => v.exchange_id === exchange.value)
            rawContacts.map(v => {
                scopedContracts.push({value: v['id'], label: v['symbol'], base: v['base'], target: v['target']})
            })

        } catch(e) {}
        this.setState(state => ({...state, selectedExchange: exchange.value, scopedContracts: scopedContracts, extraData: {...state.extraData, price: null, size: null}, amountOptions: null}))
    }

    selectContract = (contract) => {

        this.setState(state => ({...state, selectedContract: contract.value, extraData: {...state.extraData, price: null, size: null}, amountOptions: [{label: contract['base'], value: 'base'}, {label: contract['target'], value: 'target'}], quoteTicker: contract['target'] }))
    }
   
    render () {

        return (

            <div style={{padding: 24, width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start'}}>
                
                <div style={{width: '100%', padding: '0px 12px', marginBottom: 12}}>
                        <div style={{display: 'flex', marginBottom: 0, marginTop: 12, alignItems: 'center'}}>
                            <div style={{width: 24, height: 24, display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: 100, backgroundColor: '#0078DB', color: 'white', fontWeight: 900, marginRight: 12}} className="basier-p2">
                                1
                            </div>
                            <div className="basier-p3" style={{textTransform: 'uppercase', color: 'black', fontWeight: 900}}>
                                Select the position type
                            </div>
                        </div>

                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                    {
                        [['long', 'Long'], ['short', 'Short'], ['pnl', 'P&L']].map((v, k) => {

                            const invert = this.state.type[0] === v[0]
                            return (
                                <div key={k} onClick={() => this.selectType(v)} className="hover-border-select noselect" style={{cursor: 'pointer', padding: 12, margin: '12px 12px', border: '1px solid #E1E5E9', borderRadius: 10, boxShadow: '0px 10px 10px rgba(225, 229, 233, 0.3)', height: 60, flex: 1, backgroundColor: this.state.type[0] === v[0] ? '#0078DB' : 'white', display: 'flex', alignItems: 'center', justifyContent: 'space-around'}}>
                                    <div style={{display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-start', height: 22}}>
                                        <div>{renderIcon(v[0], invert)}</div>
                                        <div style={{marginLeft: 12, textTransform: 'uppercase', color: invert ? 'white' : 'black', fontWeight: 900}} className="basier-p3">{v[1]}</div>
                                    </div>
                                    <div style={{textAlign: 'center', marginLeft: 12, fontStyle: 'italic', color: invert ? '#F8F9FA' : 'rgb(106, 126, 147)'}} className="basier-p4">
                                        {renderText(v[0])}
                                    </div>
                                </div>
                            )


                        })
                    
                    
                    }
                    </div>
                </div>
                
                {this.state.type.length > 0 && 
                <div style={{width: '100%', padding: '0px 12px'}}>

                    <div style={{display: 'flex', marginBottom: 12, marginTop: 0, alignItems: 'center'}}>
                        <div style={{width: 24, height: 24, display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: 100, backgroundColor: '#0078DB', color: 'white', fontWeight: 900, marginRight: 12}} className="basier-p2">
                            2
                        </div>
                        <div className="basier-p3" style={{textTransform: 'uppercase', color: 'black', fontWeight: 900}}>
                            Enter {this.state.type[1]} details
                        </div>
                    </div>

                    <div style={{paddingLeft: 12, paddingRight: 12}}>

                    <div style={{width: '100%', display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between', height: 245}}>
                            <div style={{width: '50%', paddingRight: 12}}>

                                <div style={{marginTop: 0, marginBottom: 24, display: 'flex', alignItems: 'center'}}>
                                    <div className="basier-p2" style={{marginRight: 12, width: 130, color: 'black', fontWeight: 900}}>Date</div>
                                    <div style={{width: 250, display: 'flex', alignItems: 'baseline', marginLeft: 12}} className="root_datepicker ">
                                        <DateTimePicker 
                                            onChange={(date) => this.handleChange(date)} 
                                            value={this.state.date} 
                                            locale="en-gb"
                                            closeOnSelect={true}
                                            strictParsing={true}
                                            inputProps={{className: `select_datepicker basier-p3 ${!this.state.validations.date ? "error-input" : null }`, placeholder: 'DD/MM/YYYY', onBlur: this.handleDateBlur, ref: this.dateRef}}
                                        />
                                        <div className="basier-p4-caps link-hover"  style={{marginLeft: 12, width: 60,}} onClick={this.fillToday}>Now</div>
                                    </div>
                                </div>

                                <div style={{marginTop: 12, display: 'flex', alignItems: 'center'}}>
                                    <div className="basier-p2" style={{marginRight: 12, width: 142, color: 'black', fontWeight: 900}}>Select User</div>
                                        <div style={{width: 230}}>

                                            <UserList backToPrevious={null} propagateKey={true} portfolioId={this.props.portfolioId} handleChange={(o) => this.handleUserChange(o)} prov={false} userPass={this.state.extraData.user} height={40}  goToNext={null} width={230} marginLeft={12} />
                                            
                                        </div>
                                </div>
                                    

                                {(this.state.type[0] === 'long' || this.state.type[0] === 'short') && (this.props.contracts !== null && this.props.contracts !== undefined) &&
                                <div>
                                    <div>
                                        <div style={{marginTop: 24, display: 'flex', alignItems: 'center'}}>
                                            <div className="basier-p2" style={{marginRight: 12, width: 142, color: 'black', fontWeight: 900}}>Select Exchange</div>
                                            <div style={{width: 230}}>
                                                <SingleSelect error={!this.state.validations.exchange} handleSelect={this.selectExchange} data={this.props.contracts['exchanges']} selected={this.state.selectedExchange} />
                                            </div>
                                        </div>

                                        {this.state.scopedContracts !== null && 
                                        <div>
                                            <div style={{marginTop: 24, display: 'flex', alignItems: 'center'}}>
                                                <div className="basier-p2" style={{marginRight: 12, width: 142, color: 'black', fontWeight: 900}}>
                                                    {
                                                        this.props.wallets[this.props.user_id]['exchange'][this.state.selectedExchange] === undefined ? 
                                                        <div>New Account</div>
                                                        :
                                                        <div>Account</div>
                                                    }
                                                </div>
                                                <div style={{width: 230}}>

                                                {(this.props.wallets[this.props.user_id]['exchange'][this.state.selectedExchange] === undefined) &&  (this.props.wallets[this.props.user_id]['custom'][this.state.selectedExchange] === undefined)  ? 
                                                    <div>
                                                        <NewAccount 
                                                            handleNewAccount={this.handleNewAccount} 
                                                            accountName={this.state.extraData.account_name}
                                                            name={this.state.selectedExchange !== null && this.state.selectedExchange !== undefined ? this.state.selectedExchange.replace("_", " ").toUpperCase() : ''}
                                                            ref={null}
                                                            type="exchange"
                                                            width={230}
                                                        />
                                                    </div>
                                                    :
                                                        <ExchangeWallets 
                                                            accounts={this.props.wallets[this.props.user_id]['exchange']}
                                                            exchange={this.state.selectedExchange}
                                                            updateTransaction={(data) => this.setDataField(data.label, data.value)}
                                                            selected={this.state.extraData.account}
                                                            ref={null}
                                                            getNextRef={() => {}}
                                                            backToPrevious={() => {}}
                                                            propagateKey={false}
                                                            straight={true}
                                                            width={230}
                                                        />
                                                }
                                                </div>
                                            </div>
                                        
                                        </div>
                                        }
                                    </div>
                                </div>
                                } 

                                {
                                    (this.state.type[0] === 'pnl') &&
                                    <div>
                                        <div>
                                            <div style={{marginTop: 12, display: 'flex', alignItems: 'center'}}>
                                                <div className="basier-p2" style={{marginRight: 12, width: 130, color: 'black', fontWeight: 900}}>P&L Coin</div>
                                                <div style={{width: 250, paddingLeft: 12, paddingRight: 12}}>

                                                    <CoinSearch 
                                                        data_filter={this.props.all_coins}
                                                        dataBind={this.state.extraData.pnlCoin}
                                                        //ref={this.inputBaseRef}
                                                        autoFocus={true}
                                                        width={250}
                                                        keepInput={true}
                                                        overrideClass={true} 
                                                        focusIn={() => this.getChildRef()}
                                                        clearRedux={null}
                                                        updaterMethod={(key, value) => null}
                                                        handleSelect={(value) => this.setDataField('pnlCoin', value)}
                                                        masterClass={`transaction-coin-search ${!this.state.validations.pnl ? "error-input" : null }`} />

                                                </div>
                                            </div>
    
                                            <div>
                                                <div style={{marginTop: 24, display: 'flex', alignItems: 'center'}}>
                                                    <div>
                                                    <div className="basier-p2" style={{marginRight: 12, width: 130, color: 'black', fontWeight: 900}}>
                                                        Net Profit / Loss
                                                    </div>
                                                    <div className="basier-p4" style={{fontStyle: 'italic'}}>Negative for loss</div>
                                                    </div>
                                                    <div style={{width: 250}}>
                                                        <input ref={this.amountRef} onChange={(e) => this.setDataField('size', e.target.value)} value={this.state.extraData.size || ""} type="text" className={`light-input ${!this.state.validations.amount ? "error-input" : null }`} style={{marginLeft: 12, width: 250, paddingLeft: 24,paddingRight: 24, textAlign: 'left'}} />
                                                    </div>
                                                </div>
                                            
                                            </div>
                                        </div>
                                    </div>

                                }
                            </div>

                            <div style={{width: '50%', paddingLeft: 12}}>
                                {this.state.scopedContracts !== null && 
                                        <div>
                                            <div style={{marginBottom: 0, display: 'flex', alignItems: 'center'}}>
                                                <div className="basier-p2" style={{marginRight: 12, width: 142, color: 'black', fontWeight: 900}}>Select Contract</div>
                                                <div style={{width: 230}}>
                                                    <SingleSelect error={!this.state.validations.contract} handleSelect={this.selectContract} data={this.state.scopedContracts} selected={this.state.selectedContract} />
                                                </div>
                                            </div>
                                        
                                        </div>
                                }
                                {(this.state.type[0] === 'long' || this.state.type[0] === 'short') && (this.props.contracts !== null && this.props.contracts !== undefined) &&
                                    this.state.scopedContracts !== null && 
                                    <div>
                                        
                                        <div style={{marginTop: 24, display: 'flex', alignItems: 'center'}}>
                                            <div className="basier-p2" style={{marginRight: 12, width: 130, color: 'black', fontWeight: 900}}>Position Size</div>
                                            <input onChange={(e) => this.setDataField('size', e.target.value)} value={this.state.extraData.size || ""} type="text" className={`light-input ${!this.state.validations.amount ? "error-input" : null }`} style={{marginLeft: 12, width: 130, borderRadius: '5px 0px 0px 5px'}} />
                                            <PriceDenom width={100} innerRef={this.priceTypeRef} handleSelect={(val) => this.setDataField('amount_coin', val)}  selection={this.state.extraData.amount_coin} options={this.state.amountOptions} float='right' error={!this.state.validations.amount_coin} />


                                        </div>
                                        
                                        <div style={{marginTop: 24, display: 'flex', alignItems: 'center'}}>
                                            <div className="basier-p2" style={{marginRight: 12, width: 130, color: 'black', fontWeight: 900}}>Opening Price</div>
                                            <input onChange={(e) => this.setDataField('price', e.target.value)} value={this.state.extraData.price || ""} type="text" className={`light-input ${!this.state.validations.price ? "error-input" : null }`} style={{marginLeft: 12, width: 230, paddingRight: 75, textAlign: 'right'}} />
                                            <span style={{position: 'relative', right: 65, zIndex: 9001}}>{this.state.quoteTicker}</span>
                                        </div>

                                        <div style={{marginTop: 24, display: 'flex', alignItems: 'center'}}>
                                        </div>

                                        

                                    </div>

                                        
                                }
                            </div>

                        </div>
                                
                        <div>
                            <div style={{color: 'red'}} className="basier-p4">{this.state.validations.number_message}</div>
                            { ( ( (this.state.type[0] === 'long' || this.state.type[0] === 'short') && (this.props.contracts !== null && this.props.contracts !== undefined)  && this.state.scopedContracts !== null  ) || (this.state.type[0] === 'pnl') ) &&
                           
                            <div style={{display: 'flex', alignItems: 'center', marginTop: 24, justifyContent: 'center', width: '100%', marginRight: 12}}>
                                <div className="basier-p3 opacity-hover" style={{fontWeight: 900, height: 40, backgroundColor: '#F9716B', width: 150, color: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 20, marginRight: 12, cursor: 'pointer'}} onClick={() => this.handleClose()}>
                                    Cancel
                                </div>

                                <div className="basier-p3 opacity-hover" style={{fontWeight: 900, height: 40, backgroundColor: '#0078DB', width: 150, color: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 20, marginRight: 12, cursor: 'pointer'}} onClick={() => this.sumbit(this.state.type[0])}>
                                    {this.state.scope === 'edit' ? 'Update' : 'Submit'}
                                </div>
                            </div>   
                            }
                        </div>

                        

                    </div>
                </div>

                }
                                                
            </div>
            
        )
    }


}

const mapStateToProps = state => {
    return {
        user_id: state.auth.user_id, 
        contracts: state.appwide.contracts, 
        token: state.auth.token, 
        portfolioId: state.data.menu_selected,
        currentView: state.data.currentView,
        currency: state.auth.currency.id,
        graphData: state.data.globalBalance,
        inBitcoin: state.data.bitcoinPf, 
        all_coins: state.appwide.all_coins, 
        wallets: state.appwide.wallets,
        accountNames: state.appwide.accountNames

    };
};

const mapDispatchToProps = dispatch => {
    return {
        toggleModal: (data) => 
            dispatch(actions.toggleModal(data)), 
        componentDataFetch: (scope, view, currency, user, portfolio, globalGraphData, portfolioGraphData, inBitcoin, tokenProvided) =>
            dispatch(dataActions.componentDataFetch(scope, view, currency, user, portfolio, globalGraphData, portfolioGraphData, inBitcoin, tokenProvided)), 
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(AddPosition)
