import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
 
const styles = theme => ({
    green: {
        height: 20,
        backgroundColor: 'rgba(76, 218, 193, 0.1)',
        width: 300,
        borderRadius: 20,
        },
    yellow: {
        height: 20,
        backgroundColor: 'rgba(249, 240, 125, 0.1)',
        width: 300,
        borderRadius: 20,
        },
    red: {
        height: 20,
        backgroundColor: 'rgba(249, 113, 107, 0.1)',
        width: 300,
        borderRadius: 20,
    },

    GreenBar: {
    backgroundColor: 'rgba(76, 218, 193, 1)',
    borderRadius: 0,
    },
    YellowBar: {
        backgroundColor: 'rgba(249, 240, 125, 1)',
        borderRadius: 0,
        },
    RedBar: {
    backgroundColor: 'rgba(249, 113, 107, 1)',
    borderRadius: 0,
    },
    })


const CustomizedProgressBars = (props) => {


const { classes } = props;
const value = props.limit !== -1 ? (props.value / props.limit) * 100 : 100
const color = props.limit == -1 ? 'green' : props.value / props.limit >= 1 ? 'red' : props.value / props.limit <= 0.67 ? 'green' : 'yellow'

  return (
    <div>
     
      <LinearProgress
        classes={{
            root: color === 'green' ? classes.green : color === 'yellow' ? classes.yellow : classes.red,
            bar: color === 'green' ? classes.GreenBar : color === 'yellow' ? classes.YellowBar : classes.RedBar,
        }}
        variant="determinate"
        color="secondary"
        value={value}
      />
     
    </div>
  );
}

export default withStyles(styles)(CustomizedProgressBars);