import React from 'react';
import Grid from '@material-ui/core/Grid';
import bell from '../../Media/bell.svg'

const SmallWatchList = props => {

    return (

        props.subs.map((val, key) => {
        
        return (
            <Grid container 
                key={key}
                style={{ 
                height: 65,
                display: 'flex', 
                alignItems: 'center',
                paddingLeft: 12,
                borderBottom: '1px solid #E1E5E9',
                width: '100%',
                }} 
                value={val.id}
                className="asset-line basier-p3">

                    <Grid item style={{width: '30%' }} >

                    <span><img style={{height: 30, width: 30, marginRight: 6}} src={val.image_thumb} alt=""/></span>
                    <span className="basier-p2" style={{fontWeight: 'bold'}}>{val.symbol}</span>

                    </Grid> 


                    <Grid item style={{width: '20%', textAlign: 'right', display: 'flex', alignItems: 'center', justifyContent: 'center'}} >
                        

                    </Grid> 


                    <Grid item style={{width: '50%', textAlign: 'right', paddingRight: 24 }} >
                    
                    <div className="basier-p2" style={{fontSize: '0.9rem', marginTop: 6}}>
                        <span>
                        {val.price > 1 ? 
                            val.price.toLocaleString('en', {maximumFractionDigits: 2, minimumFractionDigits: 2})
                            : 
                            val.price.toLocaleString('en', {maximumFractionDigits: 6, minimumFractionDigits: 6}) }
                        </span>
                        <span>
                            $
                        </span>
                    </div>
                    <div 
                        className="basier-p4"
                        style={{
                        fontWeight: 900,
                        color: val.change > 0 ? '#4CDAC1' : '#F9716B'}}>
                        {val.change !== null ? 
                        val.change > 0 ? 

                        `+${val.change.toLocaleString('en', {minimumFractionDigits:2, maximumFractionDigits: 2})}%`
                        : 

                        `${val.change.toLocaleString('en', {minimumFractionDigits:2, maximumFractionDigits: 2})}%`
                        :
                        null
                        }
                    </div>
                        
                    </Grid>

            </Grid>
         )
      })

    )
}

export default SmallWatchList