import React from 'react';
import { connect } from 'react-redux';
import CoinSearch from '../../../../../AppWide/CoinSearch';
import * as actions from "../../../../../../store/actions/interactions";
import Tooltip from '../../../../../AppWide/Tooltip';
import help from '../../../../../../Media/help.svg'

const getValue = (dict, value) => {

    let ret = null; 
    try { ret = dict[value]} catch(e) {}
    return ret

}

class CoinSelector extends React.Component {

    shouldComponentUpdate(nextProps) {

        if (this.props.transaction[this.props.label] === nextProps.transaction[this.props.label]) {
            return false
        }
        return true

    }

    handleSelect = (data) => {
        this.props.updateTransaction("exchange", data)
        this.props.updateTransaction("account", null)
    }
    
    updaterMethod = (label, value) => {
        this.props.updateTransaction(label, value)
        this.props.updateTransaction("account", null)

    }
    
    render () {
        let dataBind = this.props.transaction[this.props.label]
        try {

            if (!dataBind) {
                let accountData = this.props.transaction.account_data;
                if (accountData) {
                    if (accountData.custom_exchange_check && accountData.custom_exchange) {
                        dataBind = accountData.custom_exchange.toLowerCase()
                    }
                }   
            }
        } catch (e) {
            dataBind = null
        }


        return (

            <div>
                <div className="basier-p4-caps" style={{marginBottom: 2, color: 'black', fontWeight: 900, display: 'flex', alignItems: 'center'}}>
                    <div>Exchange</div>
                </div>

                <CoinSearch 
                    data_filter={this.props.exchanges}
                    dataBind={dataBind}
                    ref={this.props.inputBaseRef}
                    width={250}
                    type="exchange"
                    discardBlur={true}
                    keepInput={true}
                    backToPrevious={this.props.backToPrevious}
                    overrideClass={true} 
                    autoFocus={this.props.autoFocus || false}
                    focusIn={() => this.props.getNextRef()}
                    small={true}
                    clearRedux={['exchange', 'account', 'account_name']}
                    clearWallets={true}
                    removeWallets={this.props.removeWallets}
                    updaterMethod={(label, value) => this.updaterMethod(label, value)}
                    handleSelect={(value) => this.handleSelect(value)}
                    masterClass="transaction-coin-search basier-p3"/>

            </div>
            
        )
    }


}

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = dispatch => {
    return {
    };
};


export default connect(mapStateToProps, null)(CoinSelector)


