import React from 'react';
import PropTypes from 'prop-types';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { withStyles } from '@material-ui/core/styles';
import { withRouter} from 'react-router-dom';


const timeoutLength = 100;

const styles = theme => ({
  root: {
    display: 'flex',
  },
  paper: {
    marginRight: theme.spacing.unit * 2,
  },
});

class MenuListComposition extends React.Component {
  state = {
    open: false,
    onMenu: false,
  };

  handleHoverOn = () => {
    this.setState(state => ({ open: true })); 
  };
  
  emptyMenu = () => {
        this.props.setMenuPortfolio(null)
        this.props.setMenuPath(null)
        
    }

  goToLink = (destination) => {

    this.emptyMenu()
    this.props.history.push(`/${destination}`)
    this.handleClose()
    
  }

  handleHoverOff = () => {
    setTimeout(() => {
        this.setState(state => ({ open: false }));
       }, timeoutLength);
  };

  handleHoverMenuOn = () => {
    this.setState(state => ({ onMenu: true }));   
  }

  handleHoverMenuOff = () => {
    setTimeout(() => {
        this.setState(state => ({ onMenu: false }));
       }, timeoutLength);
  }

  handleToggle = () => {
    this.setState(state => ({ open: true }));
  };

  handleClose = event => {
    this.setState({ open: false });
  };


  render() {
    const { classes } = this.props;
    const open = this.state.open || this.state.onMenu;

    return (
      <div className={classes.root}>
        <div>
          <div
            buttonRef={node => {
              this.anchorEl = node;
            }}
            aria-owns={open ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            onClick={this.handleToggle}
            onMouseEnter={this.handleHoverOn}
            onMouseLeave={this.handleHoverOff}
            style={{cursor: 'pointer'}}
          >
            <span>See More</span><span style={{marginLeft: '12px'}}>

                <svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14.9136 1L7.95679 8L1 1" stroke="#6A7E93" stroke-width="1.5" stroke-miterlimit="10"/>
                </svg>

            </span>
          </div>
          <Popper open={open} anchorEl={this.anchorEl} transition disablePortal>
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                id="menu-list-grow"
                style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={this.handleClose}>
                    <MenuList
                       onMouseEnter={this.handleHoverMenuOn}
                       onMouseLeave={this.handleHoverMenuOff} 
                    >
                      <MenuItem onClick={() => this.goToLink('watchlist')}>
                        Watchlist
                      </MenuItem>
                      <MenuItem onClick={() => this.goToLink('wallets')}>
                        Wallet
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
      </div>
    );
  }
}

MenuListComposition.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(MenuListComposition));