import React from 'react';
import { connect } from 'react-redux';
import WalletList from '../QuestionBlocks/WalletList';
import NewAccount from './NewAccount';



class ExchangeAccountQuestion extends React.PureComponent {

    handleUpdate = (label, data) => {

        this.props.updateTransaction(label, data)

    }

    handleNewAccount = (name) => {
        this.props.updateTransaction("storage", -1)
        this.props.updateTransaction("storage_name", name)
    }

    componentDidUpdate(prevProps) {
        if (this.props.user_id !== prevProps.user_id) {
            this.props.updateTransaction("storage", null)
        }
    }

    render () {

        return (

            <div>
                <div className="basier-p4-caps" style={{marginBottom: 2, color: 'black', fontWeight: 900, display: 'flex', alignItems: 'center'}}>

                {
                Object.values(this.props.wallets[this.props.user_id]['storage']).length === 0 ? 
                <div>New Wallet</div>
                :
                this.props.allCoins === true ? 
                <div>Select Wallet</div>
                :
                <div>Select or Create Wallet</div>
                }

                </div>
                
                {
                Object.values(this.props.wallets[this.props.user_id]['storage']).length === 0 ? 
                this.props.allCoins === true ? 
                    <div>You need to create a wallet before proceeding</div> 
                :
                <div>
                    <NewAccount 
                        handleNewAccount={this.handleNewAccount} 
                        accountName={this.props.transaction.storage_name}
                        name={this.props.coin['coinTicker']}
                        innerRef={this.props.innerRef}
                        type="storage"
                    />
                </div>
                :
                <WalletList 
                    allCoins={this.props.allCoins}
                    accounts={this.props.wallets[this.props.user_id]['storage']}
                    wallets={this.props.wallets}
                    wallet={this.props.transaction.storage}
                    coin={this.props.coin}
                    allWallets={this.props.allWallets}
                    transaction={this.props.transaction}
                    updateTransaction={this.handleUpdate}
                    //innerRef={this.props.innerRef}
                    getNextRef={this.props.getNextRef}
                    backToPrevious={this.props.backToPrevious}
                    propagateKey={true}
                    ref={this.props.innerRef}
                    height={40}
                />
                }

                { 
                this.props.allCoins === true ? null :
                Object.values(this.props.wallets[this.props.user_id]['storage']).length === 0 ? 
                    <div className="basier-p4" style={{color: '#4CDAC1', fontStyle: 'italic'}}>We will create a new wallet with this name.</div>
                :
                this.props.transaction['account'] == -1 && this.props.transaction['account_name'] !== null && this.props.transaction['account_name'] !== undefined && this.props.transaction['account_name'] !== '' ? 

                    <div className="basier-p4" style={{color: '#4CDAC1', fontStyle: 'italic'}}>We will create a new wallet with this name.</div>
                    
                :

                this.props.transaction['account'] == -1 && (this.props.transaction['account_name'] === null || this.props.transaction['account_name'] === undefined || this.props.transaction['account_name'] === '') ? 
                    
                    <div className="basier-p4" style={{color: '#0078DB', fontStyle: 'italic'}}>Select wallet or type new name to create.</div>

                :

                    null

                }

            </div>

            
        )
    }


}




export default ExchangeAccountQuestion
