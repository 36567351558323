import React from 'react';
import CoinSearch from '../../../../../AppWide/CoinSearch';
import Tooltip from '../../../../../AppWide/Tooltip';
import help from '../../../../../../Media/help.svg'


const getValue = (dict, value) => {

    let ret = null; 
    try { ret = dict[value]} catch(e) {}
    return ret

}


class CoinSelector extends React.Component {

    shouldComponentUpdate(nextProps) {

        /* if ( (this.props.transaction[this.props.label] === nextProps.transaction[this.props.label]) && (this.props.validations[this.props.label] === nextProps.validations[this.props.label]) ) {
            return false
        } */
        return true

    }

    selectCoin = (data) => {
        
        if (this.props.label === 'base_currency') {

            const old = getValue(this.props.transaction.base_currency, 'value')

            if (data !== undefined && old !== getValue(data, 'value')) {

                this.props.updateTransaction("transaction_description", null)
                this.props.updateTransaction("amount", null)
                this.props.updateTransaction("unit_price", null)
                this.props.updateTransaction("fund_source", null)
                this.props.updateTransaction("quote_currency", undefined)
                this.props.updateTransaction("fee_currency", undefined)
                this.props.updateTransaction("fee", undefined)
                this.props.updateState('selected_quote', null)
                this.props.updateTransaction("base_currency", { value: data.value, name: data.coinName, image: data.image, type: data.coinType })
                this.props.updateState('selected_base', data)
            
            }

        } else if (this.props.label === 'quote_currency') {

            const old = getValue(this.props.transaction[this.props.label], 'value') 
            if (data !== undefined && old !== getValue(data, 'value')) {
                this.props.updateTransaction("quote_currency", { value: data.value, name: data.coinName, image: data.image, type: data.coinType })
                this.props.updateState('selected_quote', data)
                this.props.updateTransaction("unit_price", null)
            }

        } else {
            const old = getValue(this.props.transaction[this.props.label], 'value')
            if (data !== undefined && old !== getValue(data, 'value')) {
                this.props.updateTransaction(this.props.label, { value: data.value, name: data.coinName, image: data.image, type: data.coinType })
            }
        }


      }
    
    updaterMethod = (label, value) => {
        this.props.updateTransaction(this.props.label, value)
        this.props.updateState('priceAtDate', null)
    }
    
    render () {

        let renderTooltip = null; 
        if (this.props.label === 'base_currency') {
            renderTooltip =  "This is the currency you execute your transaction in, i.e. that you will receive when you buy or that you will reduce when you sell."
        } else if (this.props.label === 'quote_currency') {
            renderTooltip =  "This is the currency you execute your transaction against, i.e. that you will decrease when you buy or that you will receive when you sell."
        } else if (this.props.label === 'network') {
            renderTooltip =  "This is the native coin of the blockchain network (e.g. BNB for BSC)."
        }
        

        return (

            <div>
                <div className="basier-p4-caps" style={{marginBottom: 2, color: 'black', fontWeight: 900, display: 'flex', alignItems: 'center'}}>
                    <div>{this.props.overrideLabel !== undefined ? this.props.overrideLabel : this.props.label === 'base_currency' ? 'Base Currency' : this.props.label === 'quote_currency' ? 'Quote Currency' : this.props.label === 'fee_currency' ? 'Fee Currency' : 'network' ? "Network Coin" : null}</div>
                    {['base_currency', 'quote_currency', 'network'].includes(this.props.label) ? 
                    <Tooltip title={renderTooltip} placement="right" >
                        <img src={help} alt='' style={{height: 18, width: 18, marginLeft: 6}} />
                    </Tooltip> : null }
                </div>
                <CoinSearch 
                    discardBlur={true}
                    data_filter={this.props.coins}
                    dataBind={this.props.transaction[this.props.label]}
                    ref={this.props.inputBaseRef}
                    width={250}
                    keepInput={true}
                    backToPrevious={this.props.backToPrevious}
                    overrideClass={true} 
                    autoFocus={this.props.autoFocus || false}
                    focusIn={() => this.props.getNextRef()}
                    clearRedux={this.props.label}
                    small={true}
                    updaterMethod={(label, value) => this.updaterMethod(label, value)}
                    handleSelect={(value) => this.selectCoin(value)}
                    masterClass={`transaction-coin-search basier-p3 ${!(this.props.validations[this.props.label] === true) ? 'error-input' : ''}`}/>

            </div>
            
        )
    }


}

export default CoinSelector


