import React, { Component } from "react";
import { FixedSizeList as List } from "react-window";
import './SearchBar.css'
import currencyDict from '../../helpers/currency.json'

const get_value = (obj, key) => {
  
  if (typeof obj === 'string') {
    return obj
  }

  if (obj !== null && obj !== undefined) {
    return obj[key] 
  } else 
  {
    return null
  }

}
class Autocomplete extends Component {
  
  listRef = React.createRef();

  state = {

    suggestions: [],
    // The active selection's index
    activeSuggestion: 0,
    // The suggestions that match the user's input
    filteredSuggestions: [],
    // Whether or not the suggestion list is shown
    showSuggestions: false,
    // What the user has entered
    userInput: "", 
    data_bind: null,

  }

  // Fetch list of crypto --> in future version make this props from global state
  componentDidMount() {
    
    this.setState(state => ({...state, suggestions: this.props.data_filter, data_bind: this.props.dataBind})) 

  }

  componentDidUpdate(prevProps) {
  
    if (prevProps.data_filter !== this.props.data_filter) {

      this.setState(state => ({...state, suggestions: this.props.data_filter})) 

      if (this.state.userInput !== "") {
        this.onChange(this.state.userInput, true, this.props.data_filter)
      }
    }

    if (prevProps.dataBind !== this.props.dataBind) {
      console.log('old db', prevProps.dataBind, 'new db', this.props.dataBind)
      this.setState(state => ({...state, data_bind: this.props.dataBind})) 
      if (!this.props.dataBind) {
        this.setState(state => ({userInput: ""}))
      }
    }


  }


  selectCoin = (coin) => {

  }

    //Rendering for each row of the list 
    Row = ({ data, index, style }) => {
        // Data passed to List as "itemData" is available as props.data
        const item = data[index]

        const highlited = index === this.state.activeSuggestion ? true : false
        return (
            <div style={{...style, 
                        paddingLeft: 24, 
                        paddingRight: 24, 
                        display: 'flex', 
                        alignItems: 'center',
                        backgroundColor: highlited === true ? 'rgba(204, 237, 254, 0.4)' : 'white',
                        color: 'black',
                        height: this.props.small ? 35 : 50
                        }} 
                onMouseDown={e => this.handleClick(e)}
                value={item.id}
                custom={item.custom}
                coin_name={item.name}
                coin_type={item.asset_type}
                coin_ticker={item.asset_type}
                index={index}
                className={`autocomplete-item ${this.props.small ? 'basier-p5' : 'basier-p3'}`}
                onMouseEnter={e => this.hoverHandler(e)}
                >

              <span>
                
                { this.props.type === 'exchange' ?
                
                  <img src={item.image_thumb} style={{marginRight: 12, height:this.props.small ? 18 : 25, width: this.props.small ? 18 : 25}} alt=""/>

                  :

                  item.image_thumb !== "" && item.image_thumb !== null  ? 
                  <span><img style={{height: this.props.small ? 18 : 25, width: this.props.small ? 18 : 25, marginRight: 12}} src={item.image_thumb} alt=""/></span>
                  :
                  <span style={{height: this.props.small ? 18 : 25, width: this.props.small ? 18 : 25, marginRight: 12, display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: `${ currencyDict[item.symbol] === undefined ? '16px' : currencyDict[item.symbol]['symbol'].length > 1 ? '16px' : '22px'}` }}>
                      {currencyDict[item.symbol] === undefined ? item.symbol : currencyDict[item.symbol]['symbol'].length > 1 ? currencyDict[item.symbol]['symbol'] : currencyDict[item.symbol]['symbol'] }
                  </span>
                }
              </span>
            
              <span>{item.name}</span>

            </div>
        );
    };

    

  // Searching for suggestion 

  escapeRegexCharacters = (str) => {
    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  }

  getListSize = () => {
      return this.state.filteredSuggestions.length
  }
  

  getSuggestionValue  = (suggestionItem) => {
    return `${suggestionItem.name.replace(/[()]/g, "")}`;
  }

  // when the user types in the input 
  onChange = (event, straight, source) => {

    let value = null;
    if (straight) {
      value = event;
    } else {
      value = event.target.value;
    }
    this.setState(state => ({
      ...state, 
      userInput: value
    }))

    //const newVal = value.replace(/[()]/g, '')
    const escapedValue = this.escapeRegexCharacters(value.trim());

    // If there is nothing typed, hide the menu and reset user input and filtered state
    if (escapedValue === '') {
      this.setState(state => ({...state, showSuggestions: false, userInput: '', filteredSuggestions: this.state.suggestions}))
      this.props.handleSelect({ value: null, 
        name: null,
        custom: false,
        image: null})
      if (this.props.clearRedux !== undefined) {
        if (typeof this.props.clearRedux == 'string') {
          this.props.updaterMethod(this.props.clearRedux, null)
        } else if (typeof this.props.clearRedux == 'object') {
  
          for (let key in this.props.clearRedux) {
            this.props.updaterMethod(this.props.clearRedux[key], null)
          }
  
        }
      }
      return [];
    }
  
    const regex = new RegExp('\\b' + escapedValue, 'i');
    
    let notDl = false
    let filteredList = []
    if (this.state.suggestions === undefined || this.state.suggestions === null ) {
      if (straight && source !== undefined) {
        filteredList = source.filter(coin => regex.test(this.getSuggestionValue(coin)));
        notDl = true
      } else {
        filteredList = [];
        notDl = true
      }
    } else {
      filteredList = this.state.suggestions.filter(coin => regex.test(this.getSuggestionValue(coin)));
    }

    
    this.setState(state => ({
        ...state, 
        activeSuggestion: 0,
        filteredSuggestions: filteredList.length === 0 ? ( this.props.type === 'exchange' && state.userInput && state.userInput.length > 0 ? [{id: null, name:`Create ${state.userInput}`, image_thumb: null}] : notDl ? [{id: null, name:'Fetching coins...', image_thumb: null}] : [{id: null, name:'No result', image_thumb: null}] ) : filteredList,
        showSuggestions: true,
        userInput: value
    }))

  }

  // When one of the suggestions in hovered on
  hoverHandler = (e) => {
    const index = Number(e.currentTarget.getAttribute('index'))
    this.setState(state => ({...state, activeSuggestion: index}));
  }
        
  // Event fired when the user clicks on a suggestion
  handleClick = e => {

    // Update the user input and reset the rest of the state
    if (this.props.type === 'exchange' && e.currentTarget.innerText.slice(0,6) === 'Create') {

      this.setState({
        activeSuggestion: 0,
        filteredSuggestions: [],
        showSuggestions: false,
        userInput: ""
        //userInput: e.currentTarget.innerText.slice(7,)
      });
      let value_insert = null;
      let result_name = null;
     
      value_insert = e.currentTarget.innerText.slice(7,)
      result_name = e.currentTarget.innerText.slice(7,).toLowerCase().slice(0,1).toUpperCase() + e.currentTarget.innerText.slice(7,).toLowerCase().slice(1,)

      this.props.handleSelect({ value: value_insert, 
        name: result_name,
        custom: true,
        image: null})

    } else {
    

    const value = this.props.keepInput === true && e.currentTarget.innerText !== 'No result' ? e.currentTarget.innerText : ""

    this.setState({
      activeSuggestion: 0,
      filteredSuggestions: [],
      showSuggestions: false,
      //userInput: ""
      userInput: value
    });

    if (this.props.type === 'exchange') {

      let value_insert = null
      if (e.currentTarget.hasAttribute("value")) {
          value_insert = e.currentTarget.getAttribute('value')

          this.props.handleSelect({ value: value_insert, 
            name: e.currentTarget.getAttribute('coin_name'),
            custom: e.currentTarget.getAttribute('custom'),
            image: e.currentTarget.getAttribute('image_thumb')})
      }
    

     

    }
    else {

      let value_insert = null
      if (e.currentTarget.hasAttribute("value")) {
          value_insert = e.currentTarget.getAttribute('value')

          this.props.handleSelect({ value: value_insert, 
            coinName: e.currentTarget.getAttribute('coin_name'),
            coinTicker: e.currentTarget.getAttribute('coin_ticker'),
            image: e.currentTarget.getAttribute('image_thumb'),
            coinType: e.currentTarget.getAttribute('coin_type')})

      } 

      }
      

    if (this.props.focusIn !== undefined) 
        { 
          e.preventDefault()
          this.props.focusIn()
         }


  }

}

  // Event fired when the user presses a key down
  onKeyDown = e => {

    this.setState(state => ({
      ...state,
      data_bind: undefined
    }));

    const { activeSuggestion, filteredSuggestions } = this.state;

    if (e.keyCode === 27) {
      this.setState(state => ({
        ...state,
        activeSuggestion: null,
        showSuggestions: false,
        userInput: ""
      }));
      if (this.props.discardBlur !== true) {
        e.target.blur()
      }
      this.onChange("", true, undefined)
    }
    // User pressed the enter key, update the input and close the
    // suggestions

    if (e.keyCode === 8) {

      if (this.props.clearRedux !== undefined) {
        if (typeof this.props.clearRedux == 'string') {
          this.props.updaterMethod(this.props.clearRedux, null)
        } else if (typeof this.props.clearRedux == 'object') {
  
          for (let key in this.props.clearRedux) {
            this.props.updaterMethod(this.props.clearRedux[key], null)
          }
  
        }
      }

      if (this.props.clearWallets === true) {
        this.props.removeWallets()
      }
    }

    // user pressed enter or tab 
    if (e.keyCode === 13 || e.keyCode === 9) {

      if (this.state.userInput === ""  || this.state.userInput === null || this.state.userInput === undefined) {
        this.setState(state => ({
          ...state,
          activeSuggestion: null,
          showSuggestions: false,
          userInput: ""
        }));
        this.props.handleSelect(undefined)
        return
      }

      const isShift = !!e.shiftKey
      
      if (e.keyCode === 9 && isShift) {
        e.preventDefault();
        try {this.props.backToPrevious()} catch(e) {}
        return

      }

      if (this.props.focusIn !== undefined) 
        { 
          e.preventDefault()
          this.props.focusIn()
         }

      if (e.keyCode === 9) 
        { 
          e.preventDefault()
         }

         if (filteredSuggestions[0] !== undefined && filteredSuggestions[0] !== null) {
         
          if (this.props.type === 'exchange' && filteredSuggestions[0].name.slice(0,6) === 'Create') {
          
          const value = this.props.keepInput === true ? filteredSuggestions[0].name.slice(7,) : ""
          this.setState({
            activeSuggestion: 0,
            filteredSuggestions: [],
            showSuggestions: false,
            userInput: value
            //userInput: filteredSuggestions[0].name.slice(7,)
          });
          let value_insert = null;
          let result_name = null;
         
          value_insert = filteredSuggestions[0].name.slice(7,)
          result_name = filteredSuggestions[0].name.slice(7,).toLowerCase().slice(0,1).toUpperCase() + filteredSuggestions[0].name.slice(7,).toLowerCase().slice(1,)
          
          this.props.handleSelect({ value: value_insert, 
            name: result_name,
            custom: true,
            image: null})
    
        } else  {
        
          this.setState(state => ({
            ...state,
            activeSuggestion: 0,
            showSuggestions: false,
            //userInput: ""
            userInput: this.props.keepInput === true && filteredSuggestions[activeSuggestion] !== undefined && filteredSuggestions[activeSuggestion].id !== null ? filteredSuggestions[activeSuggestion].name : ""
          }));
        
          if (filteredSuggestions[activeSuggestion] !== undefined) {

            if (filteredSuggestions[activeSuggestion].id !== null && this.props.type === 'exchange') {

              this.props.handleSelect({ value: filteredSuggestions[activeSuggestion].id, 
                name: filteredSuggestions[activeSuggestion].name,
                custom: filteredSuggestions[activeSuggestion].custom,
                image: filteredSuggestions[activeSuggestion].image_thumb})
            
            }
            
            else if (filteredSuggestions[activeSuggestion].id !== null && this.props.type !== 'exchange') {

              this.props.handleSelect({ value: filteredSuggestions[activeSuggestion].id, 
                coinName: filteredSuggestions[activeSuggestion].name,
                coinTicker: filteredSuggestions[activeSuggestion].symbol,
                image: filteredSuggestions[activeSuggestion].image_thumb,
                coinType: filteredSuggestions[activeSuggestion].asset_type})

            }
            
            else if (filteredSuggestions[activeSuggestion].id === null) {

            }

        }

      }
    }
  }
    // User pressed the up arrow, decrement the index
    else if (e.keyCode === 38) {
      e.preventDefault();
      if (activeSuggestion === 0) {
        return;
      }
      const nextPosition = Math.max(0, activeSuggestion - 1)
      this.setState({ activeSuggestion: nextPosition });
      
      if (this.state.filteredSuggestions.length > 0 && this.state.showSuggestions === true) {
        this.listRef.current.scrollToItem(nextPosition, 'auto');
      }

    }
    // User pressed the down arrow, increment the index
    else if (e.keyCode === 40) {
      e.preventDefault();
      if (activeSuggestion - 1 === filteredSuggestions.length) {
        return;
      }
      const nextPosition = Math.min(filteredSuggestions.length -1, activeSuggestion + 1)
      this.setState({ activeSuggestion: nextPosition });

      if (this.state.filteredSuggestions.length > 0 && this.state.showSuggestions === true) {

        this.listRef.current.scrollToItem(nextPosition, 'auto');
      }
      
    }

  }

  focusLostHandler = () => {
    this.setState(state => ({...state, showSuggestions: false}))
  }

  focusInHandler = () => {
    
    if (!this.props.keepInput) {
        if (this.props.clearRedux !== undefined) {
          if (typeof this.props.clearRedux == 'string') {
            this.props.updaterMethod(this.props.clearRedux, null)
          } else if (typeof this.props.clearRedux == 'object') {

            for (let key in this.props.clearRedux) {
              this.props.updaterMethod(this.props.clearRedux[key], null)
            }

          }
        
        }

        if (this.props.clearWallets === true) {
          this.props.removeWallets()
        }
        
        if (!this.props.keepInput) {
          this.setState(state => ({...state, userInput: '', data_bind: null}))
        }
        

        if (this.state.userInput !== '') {
            this.setState(state => ({...state, showSuggestions: true}))
        } 

      } else {
        try { this.myInput.select() } catch(e) {}
      }
    } 

  render() {

    let width = 184;
    let height = this.props.small ? 160 : 200;
    const itemHeight = height/4

    if (this.props.overrideClass) {
        width = this.props.width;
    }
    else if (window.innerWidth <= 1065) {
        width = 184;
    } else if (window.innerWidth <= 1439) {
        width = 290;
    } else {
        width = 390;
    }


    const {
      onChange,
      onKeyDown,
      focusLostHandler, 
      focusInHandler, 
      state: {
        filteredSuggestions,
        showSuggestions,
        userInput
      }
    } = this;

    return (
        <div>
        <input
          autoFocus={this.props.autoFocus}
          type="text"
          ref={(ip)=> this.myInput = ip}
          onChange={onChange}
          onKeyDown={onKeyDown}
          className={this.props.overrideClass ? this.props.masterClass : 'input-class'}
          onBlur={focusLostHandler}
          onFocus={focusInHandler}
          value={get_value(this.state.data_bind, 'name') || userInput}
          placeholder={this.props.overrideClass ? "Search" : "Search Crypto"}
        />

        { showSuggestions === true ? 
            <div 
                style={{
                    backgroundColor: "white",
                    borderRadius: '0px 0px 21px 21px',
                    boxShadow: '0px 10px 10px rgba(225, 229, 233, 0.3)',
                    position: 'absolute',
                    marginTop: `${this.props.overrideClass ? '0px' : '-25px' }`,
                    paddingTop: `${this.props.overrideClass ? '0px' : '35px' }`,
                    zIndex: `${this.props.overrideClass ? 10000 : 8000 }`,
                }}>
                <List
                    height={height}
                    itemCount={filteredSuggestions.length}
                    itemData={filteredSuggestions}
                    itemSize={itemHeight}
                    width={width}
                    ref={this.listRef}
                    className="list-virtual"
                    style={{
                      marginTop: '0px',
                      //height: this.props.small ? 30 : 40,
                      paddingTop: this.props.small ? 30 : 40,
                      border: "none",
                      overflowY: "scroll",
                      zIndex: 3000,
                      borderRadius: '0px 0px 21px 21px',
                
                }}
                    >
                    {this.Row}
                </List>
            </div>

        : 

        null }
        </div>
    );
  }
}

export default Autocomplete;