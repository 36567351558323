import React from 'react';
import { connect } from 'react-redux';
import WalletBalanceLine from './BalanceLine'
import ConnectedWalletBalanceLine from './ConenctedBalanceLine'
import Plus from '../../../../Media/Plus.svg'
import * as interActions from "../../../../store/actions/interactions";


class WalletBalances extends React.PureComponent {
    
    state = {
        showHidden: false,
    }

    toggleHidden = () => {
        this.setState(state => ({...state, showHidden: !state.showHidden}))
    }

    componentDidMount() {
        this.props.getData()
    }

    componentDidUpdate(prevProps) {
        if (this.props.currency !== prevProps.currency || (this.props.accountId !== prevProps.accountId)) {
            this.props.switchAccounts()
            this.props.getData()
        }
    }

    render () {
        return (
            <div style={{marginTop: 48}}>
                <div style={{marginBottom: 12}}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <h4 style={{margin: 0}}>
                            Wallets & Balances
                        </h4>
                        <div onClick={() => this.props.toggleModal({type: 'add_single_wallet', open: true, payload: {scope: 'exchange_wallet', context: {account: this.props.accountId, exchange: this.props.exchange}}}) } className="basier-p4-caps opacity-hover" style={{userSelect: 'none', color: 'black', marginLeft: 18, cursor: 'pointer', display: 'flex', alignItems: 'center'}}>
                            <div><img src={Plus} alt="" style={{marginRight: 12, height: 24, width: 24}}/></div>
                            <div style={{paddingTop: 0}}>Add wallets</div>
                        </div> 
                    </div>
                </div>
                
                <div onClick={this.toggleHidden} className="opacity-hover basier-p4-caps" style={{width: '100%', display: 'flex', justifyContent: 'flex-start', paddingLeft: 12, marginBottom: 12, cursor: 'pointer', color: "#0078DB"}}>
                    {!this.state.showHidden ? 'Show' : 'Hide'} dust balances
                </div>

                <div>
                <div
                style={{display: 'flex', alignItems: 'baseline', paddingLeft: 12, paddingRight: 12, color: '#6A7E93', width: '100%'}}
                > 
            
                    <div className="basier-p3"  style={{width: '25%', textTransform: 'uppercase'}}>Wallet</div> 
                    {this.props.accountData.connected == true ?
                    <div  style={{flex: 1, minWidth: '30%', textAlign: 'right' }}>
                    
                        <div className="basier-p3" style={{textTransform: 'uppercase'}}>
                            reported Balance
                        </div>
                        <div className="basier-p4-caps">
                            reported Available
                        </div>
                
                    </div> 
                    :
                    <div className="basier-p3"  style={{width: '35%', textTransform: 'uppercase'}}>Address</div> 
                    }
                    
                    <div  style={{flex: 1, minWidth: '30%', textAlign: 'right' }}>
                    
                        <div className="basier-p3" style={{textTransform: 'uppercase'}}>
                            Imported Balance
                        </div>
                        <div className="basier-p4-caps">
                            Imported Value
                        </div>
                    
                    </div> 
                    <div className="basier-p3"  style={{width: 50, textTransform: 'uppercase'}}></div> 


                </div>


                {
                this.props.balance !== null && this.props.balance !== undefined ?
                this.props.accountData.connected === true ?
                    <div>
                    {
                        Object.values(this.props.data).map((val, key) => {
                            return (
                            <WalletBalanceLine showHidden={this.state.showHidden} key={key} data={val} reported={this.props.reported} accountId={this.props.accountId} exchange={this.props.exchange} balance={this.props.balance} report={this.props.report} loading={this.props.loading} connected={this.props.accountData.connected} />
                            // <ConnectedWalletBalanceLine key={key} data={val} accountId={this.props.accountId} exchange={this.props.exchange} balance={this.props.balance} lineData={this.props.data} loading={this.props.loading} connected={this.props.accountData.connected} />
                            )
                        })

                        
                    }
                    </div>
                    :
                    <div>
                        {
                            Object.values(this.props.data).map((val, key) => {
                                return (
                                    <WalletBalanceLine showHidden={this.state.showHidden} key={key} data={val} accountId={this.props.accountId} exchange={this.props.exchange} balance={this.props.balance} report={this.props.report} loading={this.props.loading} connected={this.props.accountData.connected} />
                                )
                            })
                        }
                    </div>
                    :
                    null

                }


                

            </div>
            </div>
            
        )
    }


}

const mapStateToProps = state => {
    return {
        currency: state.auth.currency.id
    };
};

const mapDispatchToProps = dispatch => {
    return {
        toggleModal: (data) => 
            dispatch(interActions.toggleModal(data)), 
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(WalletBalances)
