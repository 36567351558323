import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Slide from '@material-ui/core/Slide';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import FiatFlatList from './FiatFlatList'
import FiatPrefSelect from './FiatPrefSelect'
import PortfolioFlatList from './PortfolioFlatList'
import MenuMore from './MoreMenu'

function Transition(props) {
    return <Slide direction="left" {...props} />;
  }
  
  const drawerWidth = '100%';
  const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);
  
  
  const styles = theme => ({
    list: {
      width: 250,
    },
    fullList: {
      width: 'auto',
    },
    drawer: {
      [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    root: {
      backgroundColor: "#fff",
      zIndex: 1800,
        "&:hover": {
            //you want this to be the same as the backgroundColor above
            backgroundColor: "#fff"
        }, 
      },
    drawerPaper: {
      width: drawerWidth,
      backgroundColor: '#fff',
      "&:hover": {
        //you want this to be the same as the backgroundColor above
        backgroundColor: "#fff"
    }, 
    },
  
  });
  

class FullScreenDialog extends React.Component {

  openModal = this.props.openModal

  componentDidMount() {
    document.body.ontouchmove = (e) => { 
      e.preventDefault(); 
      return false
    }
  }

  

  render() {
    const { classes } = this.props;
    return (
      <div>
        <SwipeableDrawer
          anchor="right"
          open={this.props.openModal}
          onClose={this.props.closeModal}
          onOpen={this.props.openModal}
          disableBackdropTransition={!iOS} 
          disableDiscovery={iOS}
          disableSwipeToOpen={true}
          hysteresis={0.05}
          classes={{
            paper: classes.drawerPaper,
            root: classes.root
          }}
        >
          <div
            tabIndex={0}
            role="button"
          >
            <div>
                {this.props.type === 'currency' ? 
                
                  <FiatFlatList closeAction={this.props.closeModal} />

                : 

                this.props.type === 'currency_set' ? 
                
                  <FiatPrefSelect closeAction={this.props.closeModal} handleSelect={this.props.handleSelect} />

                : 

                this.props.type === 'portfolio' ? 
              
                  <PortfolioFlatList closeAction={this.props.closeModal} />

                :

                this.props.type === 'more' ? 

                  <MenuMore closeAction={this.props.closeModal} />

                :

                null

                }
                 
                
            </div>
          </div>
        </SwipeableDrawer>
      </div>
    );
  }
}

FullScreenDialog.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FullScreenDialog);