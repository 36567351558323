import React from "react";
import { connect } from "react-redux";
import * as actions from "../../store/actions/data";
import * as interActions from "../../store/actions/interactions";
import TransactionReconcile from "./TransactionReconcile";
import PositionReconcile from "./PositionReconcile";
import TableWrapper from "./TableComponents/TableWrapper";

const initialFilters = {
  base_id: "all",
  sort: "desc",
  source_id: "all",
  scope: "all",
  start: "all",
  end: "all",
  transaction_description: 'all',
};
class ReconcileOverview extends React.PureComponent {
  state = {
    page: 0,
    scope: "transaction",
    filterValues: {},
    filters: initialFilters,
  };

  componentDidMount() {
    if (!this.props.givenScope === true) {
      this.props.setCurrentView(92);
      this.props.setMenuPortfolio(null);
      this.getData(0, "0", initialFilters, "spot");
    } else {
      this.props.setCurrentView(992);
      this.props.setMenuPortfolio(null);
      this.getData(0, "0", initialFilters, "spot");
    }
  }

  resetPage = () => {
    this.setState((state) => ({ ...state, page: 0 }));
  };

  movePage = (page) => {
    this.setState((state) => ({ ...state, page: page }));
    this.getData(page);
  };

  setFilter = (filter) => {
    this.setState(state => ({...state, filters: filter}))
  }

  resetFilter = () => {
    this.getData(
      0,
      "0",
      initialFilters,
      this.state.scope === "transaction" ? "spot" : "position"
    );
  };

  getData = (page, skp, filters, type) => {
    let toSkip = skp;
    let newFilters = filters;
    let getType = type;
    let getPage = page;
    let account;
    let accountType;
    if (this.props.accountScope === true) {
      account = this.props.account
      accountType = this.props.type
    } else {
      account = 'all'
      accountType = null
    }

    if (type === undefined) {
      getType = this.state.scope === "transaction" ? "spot" : "position";
    }

    if (page === undefined) {
      getPage = this.state.page;
    }

    if (skp === undefined) {
      toSkip = "1";
    }

    if (newFilters === undefined) {
      newFilters = this.state.filters;
    }
    this.props.getImports([
      this.props.user_id,
      undefined,
      getType,
      getPage,
      toSkip,
      newFilters,
      account,
      accountType,
    ]);
  };

  setScope = (scope) => {
    this.setState((state) => ({
      ...state,
      scope: scope,
      page: 0,
      filters: initialFilters,
    }));
    const getType = scope === "transaction" ? "spot" : "position";
    this.getData(0, "0", initialFilters, getType);
  };

  componentDidUpdate(prevProps) {}

  render() {
    return (
      <div style={{ padding: this.props.givenScope ? 0 : 48 }}>
        <div
          style={{
            marginBottom: 0,
            width: "100%",
            display: "flex",
            alignItems: "flex-start",
          }}
        >
          <div style={{}}>
            {this.props.givenScope === true ? (
              <h4 style={{ marginBottom: 24, marginRight: 12 }}>
                {this.props.title}
              </h4>
            ) : (
              <h3 style={{ marginBottom: 12, marginRight: 12 }}>
                Reconciliation
              </h3>
            )}

            <div
              className="basier-p2"
              style={{display: "flex", alignItems: "center", paddingLeft: this.props.givenScope === true ? 24 : 0, paddingRight: this.props.givenScope === true ? 24 : 0 }}
            >
              <div
                className="opacity-hover basier-p4-caps"
                onClick={() => this.setScope("transaction")}
                style={{
                  cursor: "pointer",
                  color:
                    this.state.scope === "transaction" ? "#0078DB" : "#6A7E93",
                  fontWeight: this.state.scope === "transaction" ? 900 : 400,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div>Transactions</div>
                {this.props.givenScope !== true && (
                  <div style={{ display: "flex", alignItems: "center" }} >
                    {this.props.transactions !== undefined &&
                    this.props.transactions.tx_review > 0 ? (
                      <div
                        style={{
                          marginLeft: 6,
                          width: 28,
                          height: 16,
                          borderRadius: 8,
                          backgroundColor:
                            this.state.scope === "transaction"
                              ? "#0078DB"
                              : "#6A7E93",
                          display: "inline-flex",
                          justifyContent: "center",
                          alignItems: "center",
                          color: "white",
                          padding: 4,
                          fontWeight: 900,
                          fontSize: 10,
                        }}
                        className="basier"
                      >
                        {
                          this.props.transactions.tx_review
                        }
                      </div>
                    ) : null}
                  </div>
                )}
              </div>
              <div style={{ marginLeft: 6, marginRight: 6 }}>|</div>

              <div
                className="opacity-hover basier-p4-caps"
                onClick={() => this.setScope("position")}
                style={{
                  cursor: "pointer",
                  color:
                    this.state.scope === "position" ? "#0078DB" : "#6A7E93",
                  fontWeight: this.state.scope === "position" ? 900 : 400,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div>Positions</div>
                {this.props.givenScope !== true && (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {this.props.transactions !== undefined &&
                    this.props.transactions.ps_review > 0 ? (
                      <div
                        style={{
                          marginLeft: 6,
                          width: 28,
                          height: 16,
                          borderRadius: 8,
                          backgroundColor:
                            this.state.scope === "position"
                              ? "#0078DB"
                              : "#6A7E93",
                          display: "inline-flex",
                          justifyContent: "center",
                          alignItems: "center",
                          color: "white",
                          padding: 4,
                          fontWeight: 900,
                          fontSize: 10,
                        }}
                        className="basier"
                      >
                        {
                          this.props.transactions.ps_review
                        }
                      </div>
                    ) : null}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {this.props.transactions !== undefined &&
          this.props.transactions.filters !== undefined &&
          this.props.transactions.transactions !== undefined && (
            <div style={{
              paddingLeft: this.props.givenScope === true ? 24 : 0,
              paddingRight: this.props.givenScope === true ? 24 : 0
              }}>
            <TableWrapper
              accountType={this.props.type}
              accountScope={this.props.accountScope}
              account={this.props.account}
              page={this.state.page}
              scope={this.state.scope}
              filters={this.state.filters}
              filterValues={this.state.filterValues}
              resetPage={this.resetPage}
              resetFilter={this.resetFilter}
              setFilter={this.setFilter}
              getData={this.getData}
              transactions={this.props.transactions}
              movePage={this.movePage}
            />
            </div>
          )}

        {/* {this.state.scope === "transaction" &&
          this.props.transactions !== undefined &&
          this.props.transactions.transactions !== undefined && (
            <TransactionReconcile
              account={this.props.account}
              type={this.props.type}
              transactions={this.props.transactions}
            />
          )}

        {this.state.scope === "position" &&
          this.props.transactions !== undefined &&
          this.props.transactions.positions !== undefined && (
            <PositionReconcile
              account={this.props.account}
              type={this.props.type}
              transactions={this.props.transactions.positions}
            />
          )} */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    transactions: state.data.importedTransactions,
    portfolios: state.auth.list_portfolios,
    user_id: state.auth.user_id,
    token: state.auth.token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setMenuPortfolio: (path) => dispatch(actions.setMenuPortfolio(path)),
    setCurrentView: (view) => dispatch(actions.setCurrentView(view)),
    getImports: (data) => dispatch(actions.getImports(...data)),
    showSnack: (data) => dispatch(interActions.showSnack(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReconcileOverview);
