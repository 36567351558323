import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

const PurpleSwitch = withStyles({
  switchBase: {
    //color: '#A2DEFD',
    height: 0,
    '&$checked': {
      color: '#0078DB',
    },
    '&$checked + $track': {
      //backgroundColor: '#A2DEFD',
    },
    
  },
  checked: {
  },
  track: {
    //backgroundColor: '#A2DEFD'
  },
})(Switch);


const CustomizedSwitches = (props) => {
  
    const classes = props

    return (
        <FormControlLabel
            control={
            <PurpleSwitch
                checked={props.status}
                onChange={props.handleSwitchChange}
                color="primary"
            />
            }
        />
  );
}

export default CustomizedSwitches