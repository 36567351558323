import React from 'react';
import { connect } from 'react-redux';
import PendingCoins from './PendingCoins'
import AddUsersUpload from './AddUsersUpload'
import ExchangeConfirm from './ExchangeCheck'
import { backendUrl } from '../../../../variables'
import axios from 'axios';
import * as appwideActions from "../../../../store/actions/appwide";
import * as dataActions from "../../../../store/actions/data";

class PendingUpload extends React.PureComponent {

    updateServer = (confirm_type, payload) => {
        this.props.updateState({pending: false, success: false, error: false})
        const data = {
            user: this.props.user_id, 
            upload_id: this.props.uploadId,
            payload: payload, 
            confirm_type: confirm_type
        }
        //this.setState(state => ({...state, loading: true}))
        let headers = { "content-type": "application/json" };
        if (this.props.token !== null && this.props.token !== undefined) {

            headers['Authorization'] = `Token ${this.props.token}`

        }

        const url_call = `${backendUrl}/v1/resume_upload/`

        axios.post(url_call, data, {headers: headers})
            .then(json => {

                if (json['data']['status'] === 'pending') {
                    this.props.updateState({pending: true, success: false, error: false, task: false, pendingData: json['data'], uploadId: json['data']['upload_id']})
                } else if (json['data']['status'] === 'task') {
                                            
                    const data = json['data']['data'][0]
                    
                    const language = navigator.language || 'en'
                    const options = {year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit' };
                    const parseDate = new Date();
                    const showDate = parseDate.toLocaleDateString(language,options)

                    const newTaskData = {description: `CSV Import`, sub: `Created ${showDate}`, status: 'pending', type: 'import', task_id: data['task_id']}
                    this.props.addTask(newTaskData)
                    this.props.updateState({success: false, pending: false, error: false, task: true})
                
                }  
                
                else if (json['data']['status'] === 'OK') {
                    const request_data = {
                        period: 'max',
                        start: null,
                        end: null,
                      }

                    this.props.componentDataFetch('update', this.props.currentView, this.props.currency_id, this.props.user_id, this.props.currentPortfolio, {}, request_data, this.props.inBitcoin);
                    this.props.getWallets(this.props.user_id);
                    this.props.getUserLimits(this.props.user_id)

                    this.props.updateState({success: true, pending: false, error: false, pendingData: json['data'], uploadId: json['data']['upload_id']})

                }
               
            })
            .catch( e => {

                console.log(e)

            })


    }

    render () {

        return (
            
            <div style={{marginTop: 0}}>
                
                    {this.props.data['type'] === 'confirm_coins' ? 

                        <PendingCoins updateServer={(t, p) => this.updateServer(t, p)} data={this.props.data} uploadId={this.props.uploadId} updateState={this.props.updateState} />
                    
                    :
                    this.props.data['type'] === 'add_users' ? 


                        <AddUsersUpload updateServer={(t, p) => this.updateServer(t, p)} data={this.props.data} uploadId={this.props.uploadId} updateState={this.props.updateState} />
                    
                    :

                    this.props.data['type'] === 'exchange_confirm' ? 


                        <ExchangeConfirm updateServer={(t, p) => this.updateServer(t, p)} data={this.props.data} uploadId={this.props.uploadId} updateState={this.props.updateState} />
                    
                    :

                    null // OTHER TYPES HERE
                    
                    }
                </div>


        )
    }


}

const mapStateToProps = state => {
    return {
        token: state.auth.token,
        user_id: state.auth.user_id,
        currentPortfolio: state.data.menu_selected, 
        inBitcoin: state.data.bitcoinPf, 
        currentView: state.data.currentView, 
        currency_id: state.auth.currency.id, 
    };
};

const mapDispatchToProps = dispatch => {
    return {
        componentDataFetch: (scope, view, currency, user, portfolio, globalGraphData, portfolioGraphData, inBitcoin) =>
        dispatch(dataActions.componentDataFetch(scope, view, currency, user, portfolio, globalGraphData, portfolioGraphData, inBitcoin)),
        getUserLimits: (user) => dispatch(appwideActions.getUserLimits(user)), 
        getWallets: (user) => dispatch(appwideActions.getWallets(user)), 
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(PendingUpload)
