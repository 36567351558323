
import React from "react";
import { connect } from 'react-redux';
import * as actions from "../../store/actions/data";
import * as appwideActions from "../../store/actions/appwide";
import { withRouter} from 'react-router-dom';

// Import FilePond styles

const get = (dict, key) => {
    let value = null
    try { value = dict[key] } catch(e) {value = 'deleted'}
    return value
}
class Settings extends React.Component {

    state = {
        // Set initial files, type 'local' means this is a file
        // that has already been uploaded to the server (see docs)
        files: [], 
        progress: {}
    }

    handleInit() {
    }

    handleSocket(data) {

        

    }

    componentDidMount() {
        this.props.getInvites(this.props.user_id)
    }

    render() {

        return (
           
            <div style={{width: '100%', padding: '0px 48px 48px 48px'}}>
               
               <div className='invitations-sticky' style={{width: '100%', marginTop: 0}}>
                    <div style={{paddingLeft: 24, marginBottom: 40, width: '100%', paddingTop: 48, display: 'flex', alignItems: 'flex-start'}}>
                        <h3 style={{marginBottom: 6}}>Invitations</h3>
                        
                        <div style={{justifySelf: 'flex-end', marginLeft: 'auto', marginRight: 48}}>
                        </div>
                
                    </div>
                </div>
                <div style={{width: '100%', backgroundColor: 'white', marginTop: 24}}>

                    <div className="shaddow-container" style={{minHeight: 200}}>

                        <div style={{borderBottom: '1px solid #E1E5E9', height: 36, display: 'flex', alignItems: 'center', paddingLeft: 24, color: 'black'}} className="basier-p4-caps">
                            Pending invitations
                        </div>

                        <div style={{display: 'flex', alignItems: 'center', paddingLeft: 24, paddingRight: 24, marginTop: 12, marginBottom: 12}} className="basier-p4-caps">

                            <div style={{width: '25%'}}>
                                Date
                            </div>  

                            <div style={{width: '25%'}}>
                                Portfolio
                            </div> 

                            <div style={{width: '25%'}}>
                                Invited by
                            </div>

                            <div style={{width: '25%'}}>
                                Action
                            </div>     

                        </div>

                        { 
                            !this.props.isLoading ? 
                        
                            this.props.invites.filter(v => v.status === 'pending').length > 0 ? 

                            <div className="basier">

                                {this.props.invites.filter(v => v.status === 'pending').map((val, key) => {

                                    let date = new Date(val.created_at)
                                    const language = navigator.language || 'en'
                                    const options = {year: 'numeric', month: 'numeric', day: 'numeric' };
                                    let displayDate = date.toLocaleDateString(language, options)

                                    return (
                                        <div key={val.id} style={{display: 'flex', alignItems: 'center', marginLeft: 24, marginRight: 24, height: 50, borderBottom: '1px solid #E1E5E9'}}>
                                            
                                            <div style={{width: '25%'}}>
                                                {displayDate}
                                            </div>  

                                            <div style={{width: '25%'}}>
                                                {get(val.portfolio, 'name')}
                                            </div> 

                                            <div style={{width: '25%', textTransform: 'capitalize'}}>
                                                {`${val.user_requesting.first_name.toLowerCase()} ${val.user_requesting.last_name.toLowerCase()}`}
                                            </div>

                                            <div style={{width: '25%', display: 'flex', alignItems: 'center'}}>
                                                <div style={{padding: 6, width: 80, display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: 12, backgroundColor: '#CCEDFE', height: 30, borderRadius: 20, fontWeight: 400, color: 'black', fontSize: 12, textTransform: 'uppercase', cursor: 'pointer'}} className="basier opacity-hover" onClick={() => this.props.handleRequestResponse(val, 'approved')}>
                                                    {
                                                        this.props.loading[val.id] === true ? 

                                                        'Loading'

                                                        :

                                                        'Confirm'
                                                    }
                                                </div>
                                                <div style={{padding: 6, width: 80, display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: 12, backgroundColor: '#CCEDFE', height: 30, borderRadius: 20, fontWeight: 400, color: 'black', fontSize: 12, textTransform: 'uppercase', cursor: 'pointer'}} className="basier opacity-hover" onClick={() => this.props.handleRequestResponse(val, 'declined')}>
                                                    Decline
                                                </div>
                                            </div>     
    
                                        </div>
                                    )
                                })
                                }
                            </div>
                            
                            :

                            <div style={{width: '100%', height: '100%', display: 'flex', paddingTop: 24, justifyContent: 'center'}}> No pending invitations</div>
                        :

                        <div style={{display: 'flex', flexDirection: 'column', paddingLeft: 24}}>

                            <div style={{display: 'flex', marginTop: 12}}>

                                <div style={{height: 30, width: 30, borderRadius: 100, marginRight: 24,}} className="pulse-skeleton"> </div>
                                <div style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
                                    <div style={{height: 12, width: '90%', borderRadius: 10, marginRight: 24}} className="pulse-skeleton"> </div>
                                    <div style={{height: 12, width: '70%', borderRadius: 10, marginRight: 24, marginTop: 6}} className="pulse-skeleton"> </div>
                                </div>

                            </div>


                            <div style={{display: 'flex', marginTop: 24}}>

                                <div style={{height: 30, width: 30, borderRadius: 100, marginRight: 24,}} className="pulse-skeleton"> </div>
                                <div style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
                                    <div style={{height: 12, width: '90%', borderRadius: 10, marginRight: 24}} className="pulse-skeleton"> </div>
                                    <div style={{height: 12, width: '70%', borderRadius: 10, marginRight: 24, marginTop: 6}} className="pulse-skeleton"> </div>
                                </div>

                            </div>
                            
                        </div>
                    }

                    </div>

                    <div className="shaddow-container" style={{minHeight: 200}}>

                        <div style={{borderBottom: '1px solid #E1E5E9', height: 36, display: 'flex', alignItems: 'center', paddingLeft: 24, color: 'black'}} className="basier-p4-caps">
                            reviewed invitations
                        </div>

                        <div style={{display: 'flex', alignItems: 'center', paddingLeft: 24, paddingRight: 24, marginTop: 12, marginBottom: 12}} className="basier-p4-caps">

                            <div style={{width: '25%'}}>
                                Date
                            </div>  

                            <div style={{width: '25%'}}>
                                Portfolio
                            </div> 

                            <div style={{width: '25%'}}>
                                Status
                            </div>

                            <div style={{width: '25%'}}>
                                Action
                            </div>     

                        </div>

                        { 
                            !this.props.isLoading ? 
                        
                            this.props.invites.filter(v => (v.status === 'approved' || v.status === 'declined')).length > 0 ? 

                            <div className="basier">

                                {this.props.invites.filter(v => (v.status === 'approved' || v.status === 'declined')).map(val => {

                                    let date = new Date(val.created_at)
                                    const language = navigator.language || 'en'
                                    const options = {year: 'numeric', month: 'numeric', day: 'numeric' };
                                    let displayDate = date.toLocaleDateString(language, options)

                                    return (
                                        <div key={val.id} style={{display: 'flex', alignItems: 'center', marginLeft: 24, marginRight: 24, height: 50, borderBottom: '1px solid #E1E5E9'}}>
                                            
                                            <div style={{width: '25%'}}>
                                                {displayDate}
                                            </div>  

                                            <div style={{width: '25%'}}>
                                                {get(val.portfolio, 'name')}
                                            </div> 

                                            <div style={{width: '25%', textTransform: 'uppercase', fontWeight: 900, color: `${val.status === 'approved' ? '#4CDAC1' : '#F9716B'}` }}>
                                                {val.status === 'approved' ?
                                                    "Confirmed"
                                                :
                                                    "Declined"
                                                }
                                            </div>

                                            <div style={{width: '25%', display: 'flex', alignItems: 'center'}}>
                                                
                                                    {
                                                        val.status === 'approved' ? 

                                                        <div style={{ cursor: 'pointer', color: '#0078DB' }} className="basier opacity-hover" onClick={() => {this.props.history.push(`/portfolio/${val.portfolio.id}`)}}>
                                                            Go to portfolio
                                                        </div>     

                                                        :

                                                        <div style={{ cursor: 'pointer', color: '#0078DB' }} className="basier opacity-hover" onClick={() => this.props.handleRequestResponse(val, 'pending')}>
                                                            Undo decline
                                                        </div>    
                                                    }
                                            </div>
                                           
    
                                        </div>
                                    )
                                })
                                }
                            </div>
                            
                            :

                            <div style={{width: '100%', height: '100%', display: 'flex', paddingTop: 24, justifyContent: 'center'}}> No reviewed invitations</div>
                        :

                        <div style={{display: 'flex', flexDirection: 'column', paddingLeft: 24}}>

                            <div style={{display: 'flex', marginTop: 12}}>

                                <div style={{height: 30, width: 30, borderRadius: 100, marginRight: 24,}} className="pulse-skeleton"> </div>
                                <div style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
                                    <div style={{height: 12, width: '90%', borderRadius: 10, marginRight: 24}} className="pulse-skeleton"> </div>
                                    <div style={{height: 12, width: '70%', borderRadius: 10, marginRight: 24, marginTop: 6}} className="pulse-skeleton"> </div>
                                </div>

                            </div>


                            <div style={{display: 'flex', marginTop: 24}}>

                                <div style={{height: 30, width: 30, borderRadius: 100, marginRight: 24,}} className="pulse-skeleton"> </div>
                                <div style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
                                    <div style={{height: 12, width: '90%', borderRadius: 10, marginRight: 24}} className="pulse-skeleton"> </div>
                                    <div style={{height: 12, width: '70%', borderRadius: 10, marginRight: 24, marginTop: 6}} className="pulse-skeleton"> </div>
                                </div>

                            </div>
                            
                        </div>
                    }


                    </div>

                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        user_id: state.auth.user_id
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setMenuPortfolio: (path) => 
            dispatch(actions.setMenuPortfolio(path)), 
        setCurrentView: (view) => 
            dispatch(actions.setCurrentView(view)),
        getInvites: (data) => dispatch(appwideActions.getInvites(data)),
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Settings));

