import React, { Component } from 'react';

import  Select  from 'react-select';
import { connect } from 'react-redux';


const getValues = (vals) => {
  let arr = [];
  vals.map(v => {
    arr.push(v.value)
  })
  return arr
}  

const styles = {
    control: styles => ({ ...styles, backgroundColor: 'transparent', minHeight: 42, fontSize: 16, color: 'black', dispay:'inline-block'}),
    multiValue: (base,) => {
      return base;
    },
    multiValueLabel: (base, state) => {
      return base;
    },
    
    multiValueRemove: (base, state) => {
      return base;
    },
};


class MenuSelector extends React.Component {

  state = {
    isLoading: false,
    options: [],
    value: null,
  };

  componentDidMount() {
    let defaultOptions = [];
    if (this.props.portfolios !== null && this.props.portfolios !== undefined ) {
        this.props.portfolios.map(val => {
            let temp = {value: val['id'], label: val['name']}
            defaultOptions.push(temp)
        })
    }

    this.setState(state => ({...state, options: defaultOptions, value: defaultOptions}))
    if (this.props.addAll) {
      this.props.updateData(getValues(defaultOptions))
    }

  }

  componentDidUpdate(prevProps) {
      if (prevProps.portfolios !== this.props.portfolios) {

        let defaultOptions = [];
        if (this.props.portfolios !== null && this.props.portfolios !== undefined ) {
            this.props.portfolios.map(val => {
                let temp = {value: val['id'], label: val['name']}
                defaultOptions.push(temp)
            })
        }

        this.setState(state => ({...state, options: defaultOptions}))
        if (this.props.addAll) {
          this.props.updateData(getValues(defaultOptions))
        }

        }

      }

  handleChange = (newValue) => {

    let newVals = [...this.state.value, newValue]
    this.setState(state => ({...state, value: newVals,}));
    this.props.updateData(getValues(newVals))          

  };

  onChange = (value, { action, removedValue }) => {

    switch (action) {
      case 'remove-value':
        this.setState(state => ({...state,
            value: value,
            }));
        this.props.updateData(getValues(value))       
          return;
      case 'pop-value':
        if (removedValue.isFixed) {
          return;
        }
        return;
      case 'clear': {
        value = [];
        this.setState({value: value});
        this.props.updateData(getValues(value))
        return 1;
      }
      default: {
        this.setState(state => ({...state,
            value: value,
          }));
        this.props.updateData(getValues(value))
        return
      }

    }

   

    }


  render() {
    const { isLoading, options, value } = this.state;
    return (
      <Select
        isMulti
        isClearable={true}
        isDisabled={isLoading}
        isLoading={isLoading}
        onChange={this.onChange}
        maxMenuHeight={200}
        minMenuHeight={200}
        //onCreateOption={this.handleCreate}
        options={options}
        value={value}
        styles={styles}
      />
    );
  }
}

const mapStateToProps = state => {
    return {
      portfolios: state.auth.list_portfolios,
      token: state.auth.token,
    };
  };
  
  const mapDispatchToProps = dispatch => {
    return {

    };
  };
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(MenuSelector);
  