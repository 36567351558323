import React from 'react';
import { connect } from 'react-redux';
import {siwtchTransactionType} from '../../../../../../helpers/helpers';
import CoinList from '../../../../../MobileOnly/MobileCurrency'
import TypeList from '../../../../../MobileOnly/MobileType'
import ExchangeList from '../../../../../MobileOnly/ExchangeDrawer'
import MobileSwipeSelect from '../../../../../MobileOnly/MobileSwipeSelect'
import AccountList from '../../../../../MobileOnly/AccountDrawer'
import WalletList from '../../../../../MobileOnly/WalletDrawer'
import * as actions from "../../../../../../store/actions/interactions";
import * as dataActions from "../../../../../../store/actions/data";
import * as appwideActions from "../../../../../../store/actions/appwide";
import Switch from '../../../../../Assets/Switch';
import UserList from '../../../../../MobileOnly/UserDrawer';
import Step1 from './SmallPosition1';
import Step2 from './SmallPosition2';
import Step3 from './SmallPosition3';
import MobileStepper from '@material-ui/core/MobileStepper';
import { withStyles } from '@material-ui/core/styles';
import BackIcon from "../../../../../../Media/CloseIcon.svg";
import loadingSpinner from '../../../../../../Media/loadingSpinner.gif';
import okIcon from '../../../../../../Media/okIcon.svg';
import errorIcon from '../../../../../../Media/errorIcon.svg';
import { backendUrl } from '../../../../../../variables'
import fieldValidator from '../../../../../../helpers/validators' 


import axios from 'axios';

var moment = require('moment');
require('moment/locale/en-gb');

function getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
  
        // Windows Phone must come first because its UA also contains "Android"
      if (/windows phone/i.test(userAgent)) {
          return "Windows Phone";
      }
  
      if (/android/i.test(userAgent)) {
          return "Android";
      }
  
      // iOS detection from: http://stackoverflow.com/a/9039885/177710
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
          return "iOS";
      }
  
      return "unknown";
  }

const styles = theme => ({
 
    root: {
        maxWidth: '100%',
        flexGrow: 1,
        width: '100%',
        justifyContent: 'center',
        display: 'flex',
        backgroundColor: 'white',
        marginTop: 12, 
    }

});


class SmallPositionCreate extends React.PureComponent {


    constructor(props) {

        super(props)
        

          this.state = {
            data: {
                user_id: this.props.user_id
            },
            loading: false, 
            success: false, 
            error: false, 
            open: false,
            coinModal: false,
            scope: 'add',
            step: 0,
            type: null,
            amountOptions: null,
            selectedExchange: null, 
            scopedContracts: null,
            selectedContract: null,
            extraData: {
                date: null,
                pnlCoin: null,
                amount_coin: 'base',
            }, 
            quoteTicker: null,
            validations: {
                exchange: true, 
                date: true,
                contract: true, 
                price: true, 
                amount: true, 
                amount_coin: true, 
                pnl: true, 
            }
        }
  
    }

    onFocus = () => {

        

    }
    
    getRef = async (ref) => {
        return ref;
    }

    handleReset = () => {

        this.setState(
            {
                data: {
                    user_id: this.props.user_id
                },
                loading: false, 
                success: false, 
                error: false, 
                open: false,
                coinModal: false,
                scope: 'add',
                step: 0,
                type: null,
                amountOptions: null,
                selectedExchange: null, 
                scopedContracts: null,
                selectedContract: null,
                extraData: {
                    date: null,
                    pnlCoin: null,
                    amount_coin: 'base',
                }, 
                quoteTicker: null,
                validations: {
                    exchange: true, 
                    date: true,
                    contract: true, 
                    price: true, 
                    amount: true, 
                    amount_coin: true, 
                    pnl: true, 
                }
            }
        )

        // PORTFOLIO ID 
        this.props.callUserList(this.props.portfolioId)
        
        this.setState(state => ({...state, extraData: {...state.extraData, portfolio: this.props.portfolioId, user_transaction: {value: this.props.user_id ,label: `${this.props.user_first_name} ${this.props.user_last_name}`}}}))

        const os = getMobileOperatingSystem()
        this.setState(state => ({...state, os: os}))

        const now = new Date()
        const moment_date = moment(now);
        const reduxDate = moment_date.utc().format();
        this.setState(state => ({...state, extraData: {...state.extraData, date: reduxDate}}))
       

        if (os === "Android") {

            const stateDate = moment_date.local().format("YYYY-MM-DDTHH:mm")
            this.setState(state => ({...state, stateDate: stateDate}))

        } else {

            const stateDate = moment_date.local().format("YYYY-MM-DD")
            const stateTime = moment_date.local().format("HH:mm")
            this.setState(state => ({...state, stateDate: stateDate, stateTime: stateTime}))

        }

    }

    componentDidMount() {
        this.props.setCurrentView(51)
        // PORTFOLIO ID 
        this.props.callUserList(this.props.portfolioId)
        this.setState(state => ({...state, extraData: {...state.extraData, portfolio: this.props.portfolioId, user_transaction: {value: this.props.user_id ,label: `${this.props.user_first_name} ${this.props.user_last_name}`}}}))

        const os = getMobileOperatingSystem()
        this.setState(state => ({...state, os: os}))

        const now = new Date()
        const moment_date = moment(now);
        const reduxDate = moment_date.utc().format();
        this.setState(state => ({...state, extraData: {...state.extraData, date: reduxDate}}))
       

        if (os === "Android") {

            const stateDate = moment_date.local().format("YYYY-MM-DDTHH:mm")
            this.setState(state => ({...state, stateDate: stateDate}))

        } else {

            const stateDate = moment_date.local().format("YYYY-MM-DD")
            const stateTime = moment_date.local().format("HH:mm")
            this.setState(state => ({...state, stateDate: stateDate, stateTime: stateTime}))

        }

        if (this.props.scope === 'edit') {

        } else {

        }

        const last_index = this.props.transaction.number
        const next_index = last_index + 1
        this.props.addTransaction({key: next_index})
        this.setState(state => ({...state, transaction_id: next_index}))
        
    }

    handleTypeSelect = (type) => {
        
        this.setState(state => ({...state, type: type}))
        
        if (type === 'pnl') {
            this.setState(state => ({...state, extraData: {...state.extraData, amount: 0, amount_coin: 'base' } }))
        }

    }

    handleDate = (e) => { //

        e.persist();
        this.setState(state => ({...state, stateDate: e.target.value})) 
        
        if (this.state.os === "Android") {


            const date = new Date(e.target.value)
            const moment_date = moment(date);
            const reduxDate = moment_date.utc().format();
            this.setState(state => ({...state, extraData: {...state.extraData, date: reduxDate}}));

        } else {

            console.log(e)
            if (this.state.stateTime !== null && this.state.stateTime !== undefined) {

                const total_date = `${e.target.value}T${this.state.stateTime}`

                const date = new Date(total_date)

                const moment_date = moment(date);
                const reduxDate = moment_date.utc().format();
                this.setState(state => ({...state, extraData: {...state.extraData, date: reduxDate}}))

            } else {

                const total_date = `${e.target.value}T00:00`
                const date = new Date(total_date)
                const moment_date = moment(date);
                const reduxDate = moment_date.utc().format();
                this.setState(state => ({...state, extraData: {...state.extraData, date: reduxDate}}))

            }


        }


    }

    handleTime = (e) => { //
        e.persist();
        this.setState(state => ({...state, stateTime: e.target.value})) 

        if (this.state.stateDate !== null && this.state.stateDate !== undefined) {

            const total_date = `${this.state.stateDate}T${e.target.value}`
            const fake_utc_date = new Date(total_date)
            const timeOffset = new Date().getTimezoneOffset();
            const real_date = new Date(fake_utc_date.getTime() + (timeOffset*60*1000)) 
            const moment_date = moment(real_date);
            const reduxDate = moment_date.utc().format();
            this.setState(state => ({...state, extraData: {...state.extraData, date: reduxDate}}))

        } else {

            const now = new Date()
            const now_moment_date = moment(now);
            const stateDate = moment_date.local().format("YYYY-MM-DD")
            const total_date = `${stateDate}T${e.target.value}`
            const fake_utc_date = new Date(total_date)
            const timeOffset = new Date().getTimezoneOffset();
            const real_date = new Date(fake_utc_date.getTime() + (timeOffset*60*1000)) 
            const moment_date = moment(real_date);
            const reduxDate = moment_date.utc().format();
            this.setState(state => ({...state, extraData: {...state.extraData, date: reduxDate}}))

        }
    }
    
    exchangeModalOpen = () => { 

        this.setState({ exchangeModal: true});

    };

    coinModalOpen = () => { //

        this.setState({ coinModal: true});

    };

    coinModalClose = () => { //

        this.setState({ coinModal: false});

    };

    handleExchangeSelect = (val) => { 

        let scopedContracts = []
        
        try {

            const rawContacts = this.props.contracts['contracts'].filter(v => v.exchange_id === val.value)
            rawContacts.map(v => {
                scopedContracts.push({value: v['id'], label: v['symbol'], base: v['base'], target: v['target'], image: v['image']})
            })

        } catch(e) {}

        this.setState(state => ({...state, selectedExchange: val, selectedContract: null, scopedContracts: scopedContracts, extraData: {...state.extraData, price: null, size: null}, amountOptions: null, quoteTicker: null}))

        this.exchangeModalClose()

    }

    exchangeModalClose = () => { 

        this.setState({ exchangeModal: false });

    };

    changePriceType = (val) => {
        this.setState(state => ({...state, extraData: {...state.extraData, amount_coin: val}}))
    }

    contractModalOpen = () => { 

        this.setState({ contractModal: true});

    };

    handleContractSelect = (val) => { 
    
        this.setState(state => ({...state, selectedContract: val, amountOptions: [{value: 'base', label: val['base']}, {value: 'target', label: val['target']}], quoteTicker: val['target']}))
        this.contractModalClose()

    }

    contractModalClose = () => { 

        this.setState({ contractModal: false });

    };

    

    handleCoinSelect = (val) => { 

        this.setState(state => ({...state, extraData: {...state.extraData, pnlCoin: val}}));
        this.coinModalClose()

    }

    userModalClose = () => { //

        this.setState({ userModal: false });

    };

    userModalOpen = () => { //

        this.setState({ userModal: true});

    };
    
    handleInput = (e, val) => { //
        e.persist();
        const received = e.target.value
        this.setState(state => ({...state, extraData: {...state.extraData, [val]: received}})) 

    }

    handleUser = (val) => { //

        this.setState(state => ({...state, transactionData: {...state.transactionData, user_transaction: val}}));
        this.userModalClose();

    }

    handleNext = () => {
        let newStep = this.state.step + 1
        if (this.state.type === 'pnl') {
            newStep += 1
        }

        let amount_val = true;
        let price_val = true; 

        if (this.state.step === 0 && this.state.type === 'pnl') {

            amount_val = fieldValidator(this.state.extraData.size, ['isNotEmpty', 'isNumeric'])
            this.setState(state => ({
            ...state, 
            validations: {
                ...state.validations,
                amount: amount_val}
            })
            )

        } 

        else if (this.state.step === 1 && this.state.type !== 'pnl') {

            amount_val = fieldValidator(this.state.extraData.size, ['isNotEmpty'])
                this.setState(state => ({
                ...state, 
                validations: {
                    ...state.validations,
                    amount: amount_val}
                })
            )

            price_val = fieldValidator(this.state.extraData.price, ['isNotEmpty'])
               this.setState(state => ({
               ...state, 
               validations: {
                   ...state.validations,
                   price: price_val}
               })
               )
        
        }
            

        if (amount_val && price_val ) {
            this.setState(state => ({...state, step: newStep}))
        }

        
    }

    handlePrevious = () => {
        let newStep = this.state.step - 1
        if (this.state.type === 'pnl') {
            newStep -= 1
        }
        
        this.setState(state => ({...state, step: newStep}))
    }

    updatePriceType = (v) => {
        this.setState(state => ({...state, priceDisplay: v}))
    }


    submitServer = () => {

        let contract = null; 
        let pnlCoin = null; 

        if (this.state.type !== 'pnl') {
            contract = this.state.selectedContract['value']
        } else {
            pnlCoin = {value: this.state.extraData.pnlCoin.id}
        }

        let data = {
            user_id: this.props.user_id, 
            portfolio: this.props.portfolioId, 
            contract: contract, 
            type: this.state.type,
            ...this.state.extraData, 
            pnlCoin: pnlCoin
        }

        let url_call = `${backendUrl}/v1/positions/`

        let headers = { "content-type": "application/json" };
        if (this.props.token !== null && this.props.token !== undefined) {

            headers['Authorization'] = `Token ${this.props.token}`

        }

        axios({method: 'post', url: url_call, data: data, headers: headers})
            .then(json => {
                
                this.props.refreshAddData()
                this.setState(state => ({...state, loading: false, success: true}))


            })
            .catch(e => {
                this.setState(state => ({...state, loading: false, error: true}))
            })

        
    }

    stepSwitch = (step) => {

        switch(step) {

            case 0: 
                return (

                    <Step1 

                        passState={this.state}
                        selectType = {this.handleTypeSelect}
                        handleDate={this.handleDate}
                        handleTime={this.handleTime}
                        exchangeModalOpen={this.exchangeModalOpen}
                        contractModalOpen={this.contractModalOpen}
                        coinModalOpen={this.coinModalOpen}
                        handleInput={this.handleInput}
                    />

                )
            
            case 1: 
                return (

                    <Step2 
                        passState={this.state}
                        changePriceType={this.changePriceType}
                        handleInput={this.handleInput}


                        handleTypeSelect = {this.handleTypeSelect}
                        selectType = {this.selectType}
                        selectSource={this.selectSource}
                        coinModalClose={this.coinModalClose}
                        handleDate={this.handleDate}
                        handleTime={this.handleTime}
                        typeModalClose={this.typeModalClose}
                        typeModalOpen={this.typeModalOpen}
                        exchangeModalOpen={this.exchangeModalOpen}
                        userModalClose={this.userModalClose}
                        userModalOpen={this.userModalOpen}
                        handleUser={this.handleUser}
                        handleFeeToggle={this.handleFeeToggle}
                        handleWalletToggle={this.handleWalletToggle}
                        />

                )
            
            case 2: 
                return (

                    <Step3 
                        passState={this.state}
                        coinModalOpen={this.coinModalOpen}
                        handleTypeSelect = {this.handleTypeSelect}
                        selectType = {this.selectType}
                        selectSource={this.selectSource}
                        coinModalClose={this.coinModalClose}
                        handleDate={this.handleDate}
                        handleTime={this.handleTime}
                        typeModalClose={this.typeModalClose}
                        typeModalOpen={this.typeModalOpen}
                        exchangeModalOpen={this.exchangeModalOpen}
                        userModalClose={this.userModalClose}
                        userModalOpen={this.userModalOpen}
                        handleInput={this.handleInput}
                        handleUser={this.handleUser}
                        handleFeeToggle={this.handleFeeToggle}
                        handleWalletToggle={this.handleWalletToggle}
                        />

                )
            default: 
                return (<div></div>)
        }
    } 


    backHandler = () => {
        
        if (this.state.step === 0) {
            this.props.cancelAdd()
        } else {
            this.handlePrevious()
        }
    }
    
    render () {

        const { classes } = this.props; 

        return (   
            
                this.state.loading ? 

                    <div>

                        <div style={{backgroundColor: '#F8F9FA', zIndex: 9999999, height: 75, width: '100%', display:'flex', alignItems: 'center', justifyContent: 'space-between', position: 'fixed', top: 0}} id="responsive-dialog-title">

                        </div>
    
                        <div style={{marginTop: 75, marginBottom: 75, backgroundColor: 'white', paddingTop: 0, width: '100%', display: 'flex', justifyContent: 'center'}}>
                                
                                <img style={{ height: 90, width: 90, marginTop: 90}} src={loadingSpinner} alt=""/>

                        </div>
                        
                    </div>

                :


                this.state.success ? 
                    

                    <div>

                        <div style={{backgroundColor: '#F8F9FA', zIndex: 9999999, height: 75, width: '100%', display:'flex', alignItems: 'center', justifyContent: 'space-between', position: 'fixed', top: 0}} id="responsive-dialog-title">

                        </div>

                        <div style={{marginTop: 75, marginBottom: 12, backgroundColor: 'white', paddingTop: 0, width: '100%', display: 'flex', justifyContent: 'center'}}>
                                
                            <img style={{ height: 90, width: 90, marginTop: 90}} src={okIcon} alt=""/>
                            

                        </div>

                        { this.state.passedData ? 

                            <div className="basier" style={{textAlign: 'center', fontSize: 14}}>Succesfully edited transaction</div>

                        : 
                        
                            <React.Fragment>
                                <div className="basier" style={{textAlign: 'center', fontSize: 14}}>Succesfully added transaction</div>

                                <div style={{width: '100%', display: 'flex', justifyContent: 'center', marginTop: 24}} >
                                    <div
                                        disabled={this.state.step === 0}
                                        onClick={() => this.props.cancelAdd()}
                                        className='button-next'
                                        style={{height: 40, borderRadius: 35, marginLeft: 12, marginRight: 12, cursor: 'pointer' }}
                                        >
                                        Close
                                    </div>

                                    <div
                                        disabled={this.state.step === 0}
                                        onClick={this.handleReset}
                                        className='button-next-blue'
                                        style={{height: 40, borderRadius: 35, marginLeft: 12, marginRight: 12, cursor: 'pointer' }}
                                        >
                                        Add another
                                    </div> 
                                </div>
                            </React.Fragment>

                        }

                    </div>


                : 

                this.state.error ?

                <div>

                    <div style={{backgroundColor: '#F8F9FA', zIndex: 9999999, height: 75, width: '100%', display:'flex', alignItems: 'center', justifyContent: 'space-between', position: 'fixed', top: 0}} id="responsive-dialog-title">

                    </div>

                    <div style={{marginTop: 75, marginBottom: 75, backgroundColor: 'white', paddingTop: 0, width: '100%', display: 'flex', justifyContent: 'center'}}>
                            
                        <img style={{ height: 90, width: 90, marginTop: 90}} src={errorIcon} alt=""/>

                    </div>

                    <div style={{width: '100%', display: 'flex', justifyContent: 'center', marginTop: 24}} >
                            <div
                                disabled={this.state.step === 0}
                                onClick={() => this.props.cancelAdd()}
                                className='button-next touchableOpacity'
                                style={{height: 40, borderRadius: 35, marginLeft: 12, marginRight: 12, cursor: 'pointer', backgroundColor: '#F9716B', color: 'white'}}
                                >
                                Cancel
                            </div>

                            <div
                                disabled={this.state.step === 0}
                                onClick={this.submitServer}
                                className='button-blue touchableOpacity'
                                style={{height: 40, borderRadius: 35, marginLeft: 12, marginRight: 12, cursor: 'pointer' }}
                                >
                                Retry
                            </div> 
                    </div>
                
                </div>

                :

                <div>

                    <div style={{backgroundColor: '#F8F9FA', zIndex: 9999999, height: 75, width: '100%', display:'flex', alignItems: 'center', justifyContent: 'space-between', position: 'fixed', top: 0}} id="responsive-dialog-title">
                    
                        <div onClick={this.props.cancelAdd} style={{width: '25%'}}>
                            <img style={{paddingTop: 24}} src={BackIcon} alt=""/>
                        </div>

                        <div className="basier-p4-caps" style={{color: 'black', width: '50%', textAlign: 'center'}}>
                            Derivatives Entry
                        </div>

                        <div style={{width: '25%'}}>
                            
                        </div>

                    </div>
    
                <div style={{marginTop: 75, marginBottom: 75, backgroundColor: 'white', paddingTop: 0, width: '100%'}}>

                        <div style={{marginTop: 12, marginBottom: 12, width: '100%', display: 'flex', justifyContent: 'center'}}>
                            <MobileStepper
                                variant="progress"
                                steps={3}
                                position="static"
                                activeStep={this.state.step}
                                classes={{
                                    root: classes.root,
                                }}
                                />
                        </div>
                        

                        {this.stepSwitch(this.state.step)}

                        { 
                            this.state.step === 0 
                            && ( (this.state.type !== 'pnl' && this.state.type !== null && this.state.selectedContract !== null && this.state.selectedExchange !== null
                            && this.state.scopedContracts !== undefined && this.state.selectedExchange !== undefined) || (this.state.type === 'pnl' && this.state.extraData.pnlCoin !== null) )
                            ?
                            

                            <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                                <div className='button-blue touchableOpacity' onClick={this.handleNext} style={{height: 40, borderRadius: 35, marginLeft: 12, marginRight: 12, cursor: 'pointer'}}>
                                    Next
                                </div>
                            </div>
                            
                            :

                            null
                            
                        }

                        { 
                            this.state.step === 1 ?

                            <div style={{width: '100%', display: 'flex', justifyContent: 'center', marginTop: 24}}>
                                <div
                                    onClick={this.handlePrevious}
                                    className='button-next touchableOpacity'
                                    style={{height: 40, borderRadius: 35, marginLeft: 12, marginRight: 12, cursor: 'pointer' }}
                                >
                                    Back
                                </div>

                                {   
                                   true ?
                                    
                                    <div className='button-blue touchableOpacity' onClick={this.handleNext} style={{height: 40, borderRadius: 35, marginLeft: 12, marginRight: 12, cursor: 'pointer'}}>
                                        Next
                                    </div>


                                   
                                   :

                                   null


                                }
                            </div>


                            
                            :

                            null
                            
                        }

                        { 
                            this.state.step === 2
                            ?

                            <div style={{width: '100%', display: 'flex', justifyContent: 'center', marginTop: 24}}>
                                <div
                                    onClick={this.handlePrevious}
                                    className='button-next touchableOpacity'
                                    style={{height: 40, borderRadius: 35, marginLeft: 12, marginRight: 12, cursor: 'pointer' }}
                                >
                                    Back
                                </div>
                                
                                {   
                                   true ?
                                    
                                    <div className='button-blue touchableOpacity' onClick={this.submitServer} style={{height: 40, borderRadius: 35, marginLeft: 12, marginRight: 12, cursor: 'pointer'}}>
                                        Submit
                                    </div>


                                   
                                   :

                                   null


                                }
                            </div>


                            
                            :

                            null
                            
                        }
                        


                        
                        <CoinList coins={this.props.coins} openModal={this.state.coinModal} closeModal={this.coinModalClose} handleSelect={(res) => this.handleCoinSelect(res)}/>
                         
                        {this.props.contracts !== undefined && this.props.contracts !== null &&
                            <MobileSwipeSelect data={this.props.contracts.exchanges} openModal={this.state.exchangeModal} closeModal={this.exchangeModalClose} handleSelect={(res) => this.handleExchangeSelect(res)}/>
                        }

                        {this.state.scopedContracts !== undefined && this.state.scopedContracts !== null &&
                            <MobileSwipeSelect data={this.state.scopedContracts} openModal={this.state.contractModal} closeModal={this.contractModalClose} handleSelect={(res) => this.handleContractSelect(res)}/>
                        }

                        {this.props.portfolio_users !== [] && this.props.portfolio_users !== null && this.props.portfolio_users !== undefined ? 

                            <UserList users={this.props.portfolio_users} openModal={this.state.userModal} closeModal={this.userModalClose} handleSelect={(res) => this.handleUser(res)}/>
                        
                        : 
                        
                        null 
                        
                        }



                    </div>
                </div>
        )

    }
   
}

const mapStateToProps = state => {
    return {

        currency_symbol: state.auth.currency.symbol,
        currency_id: state.auth.currency.id,
        coins: state.appwide.all_coins,
        transaction: state.interactions.transactionAddData,
        portfolio_users: state.data.scopedUsers, 
        user_id: state.auth.user_id, 
        user_first_name: state.auth.user_first_name,
        user_last_name: state.auth.user_last_name,
        exchanges: state.appwide.exchanges, 
        wallets: state.appwide.wallets,
        inBitcoin: state.data.bitcoinPf, 
        token: state.auth.token,
        priceType: state.interactions.priceType, 
        portfolioHoldings: state.data.portfolioHoldings,
        contracts: state.appwide.contracts
        
    };
};

const mapDispatchToProps = dispatch => {
    return {
        addTransaction: (data) =>
            dispatch(actions.addTransaction(data)),
        updateTransaction: (data) =>
            dispatch(actions.updateTransaction(data)),
        removeTransaction: (data) =>
            dispatch(actions.removeTransaction(data)), 
        callUserList: (portfolio) =>
          dispatch(dataActions.callUserList(portfolio)), 
        getWallets: (user) => dispatch(appwideActions.getWallets(user)), 
        componentDataFetch: (scope, view, currency, user, portfolio, globalGraphData, portfolioGraphData, inBitcoin) =>
            dispatch(dataActions.componentDataFetch(scope, view, currency, user, portfolio, globalGraphData, portfolioGraphData, inBitcoin)), 
        setCurrentView: (v) => dispatch(dataActions.setCurrentView(v))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)((withStyles(styles)(SmallPositionCreate)));
