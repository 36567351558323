import React from 'react';
import { connect } from 'react-redux';
import listIcon from '../../../../../Media/listIcon.svg'
import PositionTable from './PositionTableStraight';

class LargePositions extends React.PureComponent {

    state = {
        open: [], 
        closed: [],
    }
    componentDidMount() {
        if (this.props.portfoliosData[this.props.portfolioId] !== undefined && this.props.portfoliosData[this.props.portfolioId] !== null ) {
            const open = this.props.portfoliosData[this.props.portfolioId].positions.filter(v => v.is_open === true)
            const close = this.props.portfoliosData[this.props.portfolioId].positions.filter(v => v.is_open === false)

            this.setState(state => ({...state, open: open, closed: close}))
        }
    }

    componentDidUpdate(prevProps) {

        if (this.props.portfoliosData[this.props.portfolioId] !== undefined && this.props.portfoliosData[this.props.portfolioId] !== null && prevProps.portfoliosData[this.props.portfolioId] !== this.props.portfoliosData[this.props.portfolioId] ) {
            const open = this.props.portfoliosData[this.props.portfolioId].positions.filter(v => v.is_open === true)
            const close = this.props.portfoliosData[this.props.portfolioId].positions.filter(v => v.is_open === false)

            this.setState(state => ({...state, open: open, closed: close}))
        }

    }

    render () {

        return (
            <div>

                <div style={{width: '100%'}}>

                    <div style={{marginLeft: 12, marginRight: 12, marginTop: 0}}>
                        
                        {this.props.portfoliosData[this.props.portfolioId] !== undefined ?

                            this.props.portfoliosData[this.props.portfolioId].positions.length > 0 ? 

                                <PositionTable open={this.state.open} closed={this.state.closed} portfolioId={this.props.portfolioId} />
                            
                            :

                            <div style={{height: 200, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 12}}>
                                <div style={{height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', padding: 48, flexDirection: 'column'}}>
                                    <div>
                                        <img src={listIcon} alt="" style={{height: 80}} />
                                    </div>
                                    <div className="basier-p2" style={{marginTop: 24}}>
                                        <div>You do not have any positions in this portfolio yet.</div>
                                        <div>Once you add positions you can see them here.</div>
                                    </div>
                                </div>
                            </div>

                        : 
                        null 
                        }
                    
                    </div>

                </div>
            </div>
        )
    }


}

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = dispatch => {
    return {
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(LargePositions)
