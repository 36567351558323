import React from 'react';
import PropTypes from 'prop-types';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import * as actions from "../../../store/actions/interactions";
import * as appwideActions from "../../../store/actions/appwide";
import * as authActions from "../../../store/actions/auth";
import * as dataActions from "../../../store/actions/data";
import refreshSmall from "../../../Media/refreshSmall.svg";
import loaderIcon from "../../../Media/contentLoader.svg";
import okGreen from '../../../Media/okGreen.svg'
import cancelRed from '../../../Media/cancelRed.svg'
import loader from '../../../Media/contentLoader.svg'
import restore from '../../../Media/restore.svg';
import { connect } from 'react-redux';
import BaseModalTop from '../../Portfolio/PortfolioComponents/Transactions/BaseModalTop';
import fieldValidator from '../../../helpers/validators';
import { backendUrl } from '../../../variables';
import { withRouter} from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import TransactionTable from '../../AppWide/ImportedTransactionTable';
import PortfolioSelect from '../../AppWide/TablePortfolioSelector';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import axios from 'axios';
import _pickBy from 'lodash/pickBy';
import _keys from 'lodash/keys';

const styles = theme => ({
    container: {
        width: '100%', 
        padding: '24px 48px', 
        display: 'flex',
        flexDirection: 'column'
    },
    managementBox: {
        width: '100%', 
        height: 70, 
        backgroundColor: '#F8F9FA', 
        display: 'flex', 
        justifyContent: 'space-between', 
        padding: '12px 24px',
    }, 
    transactionBox: {
        height: 380,
        display: 'flex', 
        flexDirection: 'column',
    },
    checkRoot: {
        color: '#6A7E93',
        '&$checked': {
          color: '#0078DB',
        },
      },
      checked: {
          color: '#0078DB'
      },
    });

class ViewConnected extends React.Component {

    state = {
        loading: false,
        success: false, 
        transactions: undefined, 
        checked: {}, 
        selectAll: false,
        scope: 'all', 
        editPf: {}, 
        confirmDelete: false, 
        deleteTreat: 'keep', 
        deleting: false,
        data: {
            portfolio: null,
            account: this.props.data.id
        }, 
        editAssign: false,
    }

    componentDidMount() {

        if (this.props.transactions !== undefined) {
            let scopedTr = null
            if (this.props.scope === 'exchange') {
                scopedTr = this.props.transactions.transactions.filter(v => v.related_account_id == this.props.data.id)
            } else if (this.props.scope === 'storage') {
                scopedTr = this.props.transactions.transactions.filter(v => v.related_wallet_id == this.props.data.id)
            }

            this.setState(state => ({...state, transactions: scopedTr}))
        } 

    }

    componentDidUpdate(prevProps) {

        if (this.props.transactions !== undefined && prevProps.transactions !== this.props.transactions) {

            let scopedTr = null
            if (this.props.scope === 'exchange') {
                scopedTr = this.props.transactions.transactions.filter(v => v.related_account_id == this.props.data.id)
            } else if (this.props.scope === 'storage') {
                scopedTr = this.props.transactions.transactions.filter(v => v.related_wallet_id == this.props.data.id)
            }

            this.setState(state => ({...state, transactions: scopedTr}))
        } 

    }

    
    handleClose = () => {

        this.props.toggleModal({type: null, open: false, payload: null})

      }

    renderTable = (dataScope) => {

        let base = this.state.transactions
        
        if (this.props.account !== null && this.props.account !== undefined) {
            if (this.props.type == 'exchange') {
            base = base.filter(v => v.related_account_id == this.props.account)
            } else {
            base = base.filter(v => v.related_wallet_id == this.props.account)                    
            }
        }
        
        let data = base

        if (dataScope === 'reconciled') {
            data = base.filter(v => (v.related_transaction !== null && v.include === true))
        } else if (dataScope === 'pending') {
            data = base.filter(v => (v.related_transaction === null && v.include === true))
        } else if (dataScope === 'deleted') {
            data = base.filter(v => (v.include === false && v.base_currency !== null))
        } else if (dataScope === 'unsupported') {
            data = base.filter(v => (v.base_currency === null))
        }
        
        return (
            <TransactionTable editPf={this.state.editPf} data={data} selectAll={this.state.selectAll} checked={this.state.checked} selectSingle={(id,scope) => this.selectSingle(id, scope)} handleSelectAll={(scope) => this.selectAll(scope)} />
        )
    }

    selectAll = (scope) => {

        let newChecked = {...this.state.checked}
        if (this.state.selectAll === false) {
            scope.map(val => {
                if (this.state.checked[val.id] !== true) {
                    newChecked[val.id] = true
                }
            })
            
            this.setState(state => ({...state, selectAll: true, checked: newChecked }))

        } else {
            this.setState(state => ({...state, selectAll: false, checked: {} }))
        }

       

    }

    setScope = (scope) => {
        this.setState(state => ({...state, scope: scope }))
    }

    selectSingle = (id, scope) => {
       
       let newChecked = {...this.state.checked}
       if (this.state.checked[id] === true) {

            delete newChecked[id]

       } else {

            newChecked[id] = true

       }

       this.setState(state => ({...state, checked: newChecked}))

       if (Object.keys(newChecked).length === scope.length) {
           if (JSON.stringify(Object.keys(newChecked).sort()) == JSON.stringify(scope.map(e => `${e.id}`).sort())) {
            this.setState(state => ({...state, selectAll: true }))
        } else {
            this.setState(state => ({...state, selectAll: false }))
        }
        } else {
            this.setState(state => ({...state, selectAll: false }))
        }

    }

    toggleDelete = () => {
        this.setState(state => ({...state, confirmDelete: !state.confirmDelete}))
    }

    changeDeleteType = (e) => {
        e.persist();
        this.setState(state => ({...state, deleteTreat: e.target.value}))
    }

    deleteAccount = () => {

        let headers = { "content-type": "application/json" };
        if (this.props.token !== null && this.props.token !== undefined) {

            headers['Authorization'] = `Token ${this.props.token}`

        }
        let url_call = `${backendUrl}/v1/account/${this.props.data.id}?u=${this.props.user_id}&scope=${this.state.deleteTreat}&type=${this.props.scope}`
        this.setState(state => ({...state, deleting: true}))
        axios.delete(url_call, {headers: headers})
        .then(json => {
            this.props.getWallets(this.props.user_id)
            setTimeout(() => this.handleClose(), 200)
        })   
    }

    refreshAccount = () => {

        let headers = { "content-type": "application/json" };
        if (this.props.token !== null && this.props.token !== undefined) {

            headers['Authorization'] = `Token ${this.props.token}`

        }
        let url_call = `${backendUrl}/v1/account/${this.props.data.id}/refresh/`
        
        const data = {
            scope: this.props.scope, 
            user_id: this.props.user_id, 

        }
        axios.post(url_call, data, {headers: headers})
        .then(json => {
            //this.props.getWallets(this.props.user_id)

            const currentTasks = this.props.tasks
            let newTasks = [...currentTasks]
            if (this.props.scope=== 'exchange') {
                newTasks = [{description: `Updating Exchange`, sub: `${this.props.data.exchange_name}`, status: 'pending', type: 'connection', task_id: this.props.data.id}, ...currentTasks]
            } else if (this.props.scope === 'storage') {
                newTasks = [{description: `Updating Wallet`, sub: `${this.props.data.coin_name}`, status: 'pending', type: 'connection', task_id: this.props.data.id}, ...currentTasks]
            }
            
            this.props.setInteraction({label: "tasks", value: newTasks})

            
            setTimeout(() => this.handleClose(), 200)

        })   

        
    }


    assignAll = () => {
        if (this.state.editAssign === true) {
            this.setState(state => ({...state, editAssign: false, data: {...state.data, scope: null, portfolio: null }}))
        }
        else {
            this.setState(state => ({...state, editAssign: true, data: {...state.data, scope: 'create' }}))
        }

    }


    actionAll = (type) => {

        const scoped = _keys(_pickBy(this.state.checked))

        let data = {
            ...this.state.data, 
            ids: scoped, 
            user_id: this.props.user_id
        }

        if (type !== 'assign') {
            data['scope'] = type
        } else {
            this.setState(state => ({...state, assignLoad: true}))
        }
        


        let headers = { "content-type": "application/json" };
        if (this.props.token !== null && this.props.token !== undefined) {

            headers['Authorization'] = `Token ${this.props.token}`

        }

        let url_call = `${backendUrl}/v1/account/bulk/`

        axios.post(
            url_call, 
            data, 
            {headers: headers}
        )
        .then(
            json => {
                this.props.getImports(this.props.user_id)
                this.setState(state => ({...state, selectAll: false, checked: {}, editAssign: false, assignLoad: false }))
                let message = 'Change successful'
                try {
                    const response = json
                    message = response['data']['data']
                } catch(e) {}
                this.props.showSnack({message: message, variant: "success"})

            }
        )
        .catch(
            err => {
                let message = 'An error occured. Please try again.'
                try {
                    const response = err.response
                    message = response['data']['data']
                } catch(e) {}
                this.props.showSnack({message: message, variant: "error"})
            }
        )
        
        


    }

    updatePortfolioBulk = (val) => {
        this.setState(state => ({...state, data: {...state.data, ...val}}))
    }


    render() {

    const { classes } = this.props;

    return (

      <div>
        
          <BaseModalTop forceSingle={true} sub="Account Detail" title={`${this.props.scope === 'storage' ? `${this.props.data.coin_name} wallet` : `${this.props.data.exchange_name} account`}`} handleClose={this.handleClose} onClick={this.backToWelcome} welcome={this.state.welcome} entryType={this.state.type_selected} />

            <div className={classes.container}>

                <div className={classes.managementBox}>
                    <div>
                        {(this.props.data.exchange_image !== null & this.props.data.exchange_image !== undefined && this.props.scope === 'exchange') || (this.props.data.coin_image !== null && this.props.data.coin_image !== undefined && this.props.scope === 'storage') ? 

                            <div style={{display: 'flex', flexDirection: 'column'}}>
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <img src={this.props.scope === 'storage' ? this.props.data.coin_image : this.props.data.exchange_image} alt="" style={{height: 30, width: 30}} />
                                    <div className="basier-p3" style={{color: 'black', textTransform: 'uppercase', marginLeft: 6}}>
                                        {this.props.data.name}
                                    </div>
                                </div>
                                <div className="basier-p4-caps">
                                    {this.props.data.address}
                                </div>
                            </div>
                        : 
                        
                            null 
                        
                        }
                    </div>

                    <div style={{display: 'flex', alignItems: 'center'}}>
                        {!this.state.confirmDelete && 
                        <div onClick={this.refreshAccount} className="opacity-hover" style={{cursor: 'pointer', display: 'flex', alignItems: 'center'}}>
                            <img src={refreshSmall} alt="" style={{height: 16, width: 16}} />
                            <div className="basier-p3" style={{color: '#0078DB', marginLeft: 6}}>Refresh</div>
                        </div>
                        }
                        <button onClick={this.toggleDelete} className="opacity-hover basier-p3" style={{width: 105, color: 'white', backgroundColor: '#F9716B', display: 'flex', justifyContent: 'center', alignItems: 'center', border: 'none', height: 35, borderRadius: 10, 
                        marginLeft: 24, cursor: 'pointer'}}>
                            {this.state.confirmDelete ?
                            'Cancel': 'Delete'}
                        </button>
                    </div>
                </div>
                
                {
                this.state.deleting ? 
                <div className={classes.transactionBox} style={{marginTop: 24, paddingLeft: 6}}>
                    <div style={{width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                        <img src={loaderIcon} alt="" style={{height: 30}} />
                        <div className="basier-p3" style={{marginTop: 12}}>Deleting</div>
                    </div>
                </div>

                :

                this.state.confirmDelete ? 

                    <div className={classes.transactionBox} style={{marginTop: 24, paddingLeft: 6}}>
                        <div style={{width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                            <h3>Confirm deletion</h3>
                            <p>You wish to delete this connected account. Once you do so, transaction updates from the connected exchange to your Wisly account will stop.</p>
                            {this.props.data.length > 0 && 
                            <p>Please specify how you wish for the already imported and reconciled transactions from this account to be treated?</p>}

                            <div>

                                <RadioGroup
                                        name="deleteScope"
                                        value={this.state.deleteTreat}
                                        onChange={this.changeDeleteType}
                                        //style={{display: 'inline-block'}}

                                    >   

                                    <FormControlLabel value="keep" 
                                                        control={<Radio classes={{
                                                            root: classes.checkRoot,
                                                            checked: classes.checked
                                                        }} />} 
                                                        label='Keep the transactions in my portfolios.' />

                                    <FormControlLabel value="cascade" 
                                                        control={<Radio classes={{
                                                            root: classes.checkRoot,
                                                            checked: classes.checked
                                                        }}/>} 
                                                        label="Delete the transactions from my portfolios." />

                                    
                                </RadioGroup>

                            </div>

                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <div className='basier' onClick={this.toggleDelete} 
                                style={{height: 40, borderRadius: 35, marginRight: 12, marginTop: 24, cursor: 'pointer', color: 'white', backgroundColor: '#F9716B', width: 150, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                    Cancel
                                </div>

                                <div className='button-next-blue' onClick={() => this.deleteAccount()} 
                                style={{height: 40, borderRadius: 35, marginLeft: 12,  marginTop: 24, cursor: 'pointer'}}>
                                    Confirm
                                </div>
                            </div>

                        </div>
                    </div>
                    
                :

                <div className={classes.transactionBox} style={{marginTop: 24}}>
                    
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <div className="basier-p4-caps" style={{paddingLeft: 4, color: 'black', marginRight: 24}}>Transactions</div>
                        {
                        this.state.transactions !== undefined && this.state.transactions.length > 0 ? 
                            ['all', 'reconciled', 'pending', 'deleted', 'unsupported'].map((val, key) => {
                            return (
                                <React.Fragment key={key}>
                                    <div onClick={() => this.setScope(val)} className="blue-hover basier-p4-caps" style={{
                                        color: this.state.scope === val ? '#0078DB' : '#6A7E93',
                                        fontWeight: this.state.scope === val ? 900 : 400,
                                        cursor: 'pointer'
                                    }}>
                                        {val}
                                    </div>
                                    {key === 4 ? null : <div style={{marginLeft: 12, marginRight: 12}}>|</div>}
                                </React.Fragment>
                                

                            )
                        })
                        
                        :
                        null }
                        
                    </div>
                    

                    {this.state.transactions !== undefined && this.state.transactions.length > 0 && 
                    <div className="basier-p4" style={{paddingLeft: 12, paddingRight: 6, marginTop: 6, height: 50, display: 'flex', alignItems: 'center', justifyContent: 'flex-start'}}>
                        
                        <div style={{marginRight: 12, display: 'flex'}}>
                            { (Object.keys(this.state.checked).length > 0) && ( this.state.scope !== 'deleted' && (this.state.transactions.filter(v => (v.include === false)).length !== this.state.transactions.length ) ) ? 

                                `${Object.keys(this.state.checked).length} transactions selected`

                            : 
                                this.state.scope !== 'deleted' && (this.state.transactions.filter(v => (v.include === false)).length !== this.state.transactions.length ) ?
                            '0 transactions selected' : null }
                        </div>

                        { (this.state.scope === 'deleted' || (this.state.scope === 'all' && this.state.transactions.filter(v => (v.include === false)).length === this.state.transactions.length ) )&& 
                        <div style={{display: 'flex', width: '100%', justifyContent: 'flex-end'}}>
                            <div onClick={() => this.actionAll('restore')} className="opacity-hover" style={{marginLeft: 12, marginRight: 0, color: '#4CDAC1', cursor: 'pointer', display: 'flex', alignItems: 'center'}}>
                                
                                <div style={{marginRight: 12}}>Restore All</div>
                                <img src={restore} alt="" className="opacity-hover" style={{height: 20, cursor: 'pointer'}} />

                            </div>
                        </div>
                        } 
                        
                                
                        { (Object.keys(this.state.checked).length > 0) && this.state.scope !== 'deleted' && ( this.state.scope !== 'deleted' && (this.state.transactions.filter(v => (v.include === false)).length !== this.state.transactions.length ) ) ?

                            this.state.editAssign ? 

                            <React.Fragment>
                                <div className="opacity-hover" style={{marginLeft: 0, marginRight: 24, color: 'black'}}>
                                        Assign all to:
                                </div>
                                <div style={{width: 180}}>
                                <PortfolioSelect updateServer={(data) => this.updatePortfolioBulk(data)} value={null} data={this.props.portfolios} forceSelect={true} />
                                </div>
                                
                                {this.state.assignLoad ? 
                                    <div style={{marginLeft: 6, marginRight: 6}}>
                                        <img src={loader} style={{height: 24, width: 24}} alt="ok" />
                                    </div>
                                :
                                    <React.Fragment>
                                        <div className="opacity-hover" style={{marginLeft: 6, marginRight: 6, cursor: 'pointer'}} onClick={() => this.actionAll('assign')}>
                                            <img src={okGreen} style={{height: 24, width: 24}} alt="ok" />
                                        </div>
                                        <div className="opacity-hover" style={{marginRight: 12, cursor: 'pointer'}} onClick={() => this.assignAll()}>
                                            <img src={cancelRed} style={{height: 24, width: 24}} alt="no" />
                                        </div>
                                    </React.Fragment>
                                
                                }
                            </React.Fragment>

                            :

                            <React.Fragment>

                                <div onClick={() => this.actionAll('delete')} className="opacity-hover" style={{marginLeft: 12, marginRight: 12, color: '#0078DB', cursor: 'pointer'}}>
                                    Delete All
                                </div>

                                <div>
                                    |
                                </div>

                                <div onClick={() => this.assignAll()} className="opacity-hover" style={{marginLeft: 12, marginRight: 12, color: '#0078DB', cursor: 'pointer'}}>
                                    Assign all to portfolio
                                </div>

                                {/* <div>
                                    |
                                </div>

                                <div onClick={() => this.actionAll('clear')} className="opacity-hover" style={{marginLeft: 12, marginRight: 12, color: '#0078DB', cursor: 'pointer'}}>
                                    Clear assigned portfolio
                                </div> */}
                            </React.Fragment>

                            : 

                            null
                        }

                    
                    
                    </div>}

                    <div style={{marginTop: 6}}>
                        {this.state.transactions === undefined ? 
                            'Loading'
                        : 
                        this.state.transactions.length === 0 ? 
                            
                        
                            <div style={{paddingLeft: 12}}>No Transactions available in the account yet.</div>
                            : 

                        this.renderTable(this.state.scope)

                        }
                    </div>

                </div>
                }


            </div>            

        </div>

    )
  }
}

ViewConnected.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
};


const mapStateToProps = state => {
    return {

        modalPref: state.interactions.transactionAddModal,
        user_id: state.auth.user_id,
        token: state.auth.token,
        transactions: state.data.importedTransactions,
        portfolios: state.auth.list_portfolios,
        tasks: state.interactions.tasks,

    };
};

const mapDispatchToProps = dispatch => {
    return {
       toggleModal: (data) => 
            dispatch(actions.toggleModal(data)), 
        removeTransaction: (data) =>
            dispatch(actions.removeTransaction(data)), 
        setTrTime: (data) => 
            dispatch(actions.setTrTime(data)), 
        setInteraction: (data) => 
            dispatch(actions.setInteraction(data)), 
        getWallets: (user) => dispatch(appwideActions.getWallets(user)), 
        addPort: () => dispatch(appwideActions.addPort()), 
        setPorts: (ports) => dispatch(authActions.setPorts(ports)),
        fetchUserPreferences: (user) => dispatch(authActions.fetchUserPreferences(user)),
        componentDataFetch: (scope, view, currency, user, portfolio, globalGraphData, portfolioGraphData, inBitcoin) =>
            dispatch(dataActions.componentDataFetch(scope, view, currency, user, portfolio, globalGraphData, portfolioGraphData, inBitcoin)), 
        getImports: (data) => dispatch(dataActions.getImports(data)), 
        showSnack: (data) => dispatch(actions.showSnack(data)), 
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withMobileDialog()(withRouter(withStyles(styles)(ViewConnected))));
