import React from 'react';
import PropTypes from 'prop-types';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import * as actions from "../../../store/actions/interactions";
import * as appwideActions from "../../../store/actions/appwide";
import { connect } from 'react-redux';
import BaseModalTop from '../../Portfolio/PortfolioComponents/Transactions/BaseModalTop'
import { backendUrl } from '../../../variables';
import {loadData} from '../../../helpers/helpers'
import NewTransactionProcess from '../../Portfolio/PortfolioComponents/Transactions/NewTransaction/ShortForm/ShortTransactionEntry'
import MatchedDetailBody from './MatchedDetailBody';
import Oops from '../../../Media/oops.svg';
import Axios from 'axios';
import MultipleMatchTransfer from './MultipleMatchTransfer';


var moment = require('moment');
require('moment/locale/en-gb');

class EditTransaction extends React.Component {

  constructor(props) {
    
    super(props);

    this.focusOut = React.createRef();
    this.baseCurencyRef = React.createRef();
    this.quoteCurencyRef = React.createRef();
    this.feeCurrencyRef = React.createRef();
    this.firstFocusExchange = React.createRef();
    

    this.state = {
      data: null, 
      noMatch: null,
      multiple: null,
      selectedMatch: null,
    }

  };

  componentDidMount() {

    this.getData()

  }

  updateState = (data) => {

    if (data.deposit.length === 0 || data.withdraw.length === 0) {
      this.setState(state => ({...state, noMatch: true}))
    }
    

    const currentDescription = this.props.data.transaction_description 
    const returned = currentDescription === "deposit" ? data.withdraw : data.deposit
    const current = currentDescription === "deposit" ? data.deposit : data.withdraw
    let selected, withdraw, deposit;

    if (returned.length === 1) {
      if (currentDescription === "deposit") {
        selected = data.withdraw[0].id
        withdraw = data.withdraw[0]
        deposit = data.deposit
      } else {
        selected = data.deposit[0].id
        withdraw = data.withdraw
        deposit = data.deposit[0]
      }
      this.setState(state => ({...state,  noMatch: false, data: {deposit: deposit, withdraw: withdraw}, selected: selected, multiple: false}))
    } else if (returned.length > 1) {
      this.setState(state => ({...state,  noMatch: false, data: {current: current, candidates: returned}, selected: null, multiple: true}))
    }
    

  }

  confirmSingleMatch = () => {

    const url_call = `${backendUrl}/v1/transaction/${this.props.transactionId}/match/?u=${this.props.user_id}`
    
    const submitData = {
      user_id: this.props.user_id, 
      match_id: this.state.selected,
    }

    let headers = { "content-type": "application/json" };
    if (this.props.token !== null && this.props.token !== undefined) {

        headers['Authorization'] = `Token ${this.props.token}`

    }

    Axios.post(url_call, submitData, {headers: headers})
    .then(json => {
      this.props.getOverallData()
      this.handleClose()
    })
  }

  handleClose = () => {
    this.props.toggleModal({type: null, open: false, payload: null})
  }

  getData = () => {
    let headers = { "content-type": "application/json" };
    if (this.props.token !== null && this.props.token !== undefined) {

        headers['Authorization'] = `Token ${this.props.token}`

    }

    const url_call = `${backendUrl}/v1/transaction/${this.props.transactionId}/match/?u=${this.props.user_id}`

    const transactionDetail = loadData(url_call, headers)
    transactionDetail
        .then(json => {
            const data = json['data']
            this.updateState(data)
        })

}

selectCandidate = (id) => {
  this.setState(state => ({...state, selected: id}))
}


  
  render() {

    
    return (
      <div>
        
          <BaseModalTop forceSingle={true} title="Match Transfer" handleClose={this.handleClose} onClick={this.backToWelcome} welcome={this.state.welcome} entryType={this.state.type_selected} />

          {
          this.state.noMatch ? 
          <div style={{paddingLeft: 48, paddingRight: 48, height: 520, width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column', textAlign: 'center'}}>
          <img src={Oops} alt="" style={{height: 100, marginTop: 60}} />
          <h3 style={{marginTop: 12, fontWeight: 900, color: '#0078DB'}}>
              No matches found
          </h3>
          <div style={{marginTop: 24, fontWeight: 400,}} className="basier-p3">
            We have not found any relevant match for the selected transaction. Please review your transactions and import any missing deposits and withdraws before trying to match the transaction again.
          </div>
        </div>
          :
          this.state.data ?
            <div>
            {
            this.state.multiple === false && 
              <div>
                <MatchedDetailBody passedData={this.state.data} simple={true} />
                <div style={{marginTop: 0}}>
                      <div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>

                          <div className='basier opacity-hover' onClick={this.handleClose} 
                              style={{height: 40, borderRadius: 35, marginRight: 12, marginTop: 12, cursor: 'pointer', color: 'white', backgroundColor: '#F9716B', width: 150, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                              Cancel
                          </div>

                          <div className='button-blue opacity-hover' onClick={() => this.confirmSingleMatch()} 
                              style={{height: 40, borderRadius: 35, marginLeft: 12,  marginTop: 12, cursor: 'pointer'}}>
                              Submit
                          </div>

                      </div> 
                  </div>
                </div>
            }

            {
            this.state.multiple === true && 
              <div>
                <MultipleMatchTransfer passedData={this.state.data} simple={true} selectCandidate={this.selectCandidate} selected={this.state.selected} />
                <div style={{marginTop: 0}}>
                      <div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>

                          <div className='basier opacity-hover' onClick={this.handleClose} 
                              style={{height: 40, borderRadius: 35, marginRight: 12, marginTop: 12, cursor: 'pointer', color: 'white', backgroundColor: '#F9716B', width: 150, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                              Cancel
                          </div>

                          <div className={`button-blue ${this.state.selected ? 'opacity-hover' : ''}`} onClick={this.state.selected ? () => this.confirmSingleMatch() : null} 
                              style={{height: 40, borderRadius: 35, marginLeft: 12,  marginTop: 12, cursor: this.state.selected ?  'pointer' : 'not-allowed'}}>
                              Submit
                          </div>

                      </div> 
                  </div>
                </div>
            }

            </div>
            :
            <div>Loading</div>
          
            }

      </div>

    )
  }
}

EditTransaction.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
};


const mapStateToProps = state => {
    return {

        modalPref: state.interactions.transactionAddModal,
        portfoliosData: state.data.portfoliosData,
        transaction: state.interactions.transactionAddData,
        all_coins: state.appwide.all_coins, 
        exchangeCheck: state.interactions.exchangeCheck, 
        exchanges: state.appwide.exchanges, 
        wallets: state.appwide.wallets, 
        user_id: state.auth.user_id,
        user_first: state.auth.user_first_name,
        user_last: state.auth.user_last_name, 
        token: state.auth.token
        
    };
};

const mapDispatchToProps = dispatch => {
    return {
       toggleModal: (data) => 
            dispatch(actions.toggleModal(data)), 
        removeTransaction: (data) =>
            dispatch(actions.removeTransaction(data)), 
        setTrTime: (data) => 
            dispatch(actions.setTrTime(data)), 
        setInteraction: (data) => 
            dispatch(actions.setInteraction(data)), 
        getWallets: (user) => dispatch(appwideActions.getWallets(user)), 
        getUserLimits: (user) => dispatch(appwideActions.getUserLimits(user)), 
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withMobileDialog()(EditTransaction));
