import React from 'react';
import CoinSearch from '../../../../../AppWide/CoinSearch';
import OriginList from './OriginList'
import ExchangeQuestion from './ExchangeQuestion'
import * as actions from "../../../../../../store/actions/interactions";
import { connect } from 'react-redux';
import WalletQuestion from './WalletQuestion'
class SelectSource extends React.PureComponent {


    state = {
        originSelected: null, 
    }

    changeOrigin = (origin) => {

        this.props.setInteraction({label: 'originSelected', value: origin})
        this.props.updateTransaction({key: this.props.transactionId, label: "exchange", value: null})
        this.props.updateTransaction({key: this.props.transactionId, label: "exchange_address", value: null})
        this.props.updateTransaction({key: this.props.transactionId, label: "account", value: null})
        this.props.updateTransaction({key: this.props.transactionId, label: "storage", value: null})

    }

    render() {

        const question = this.props.transaction['data'][this.props.transactionId]['exchange'] === null || this.props.transaction['data'][this.props.transactionId]['exchange'] === undefined ? "On which exchange?" : this.props.question 
        const firstFocus = this.props.transaction['data'][this.props.transactionId]['exchange'] === null || this.props.transaction['data'][this.props.transactionId]['exchange'] === undefined ? false : true
        return (

            <div>
                
                {this.props.transaction['data'][this.props.transactionId]['exchange'] === null || this.props.transaction['data'][this.props.transactionId]['exchange'] === undefined ? 
                <div style={{

                display: 'flex', 
                height: 60,//height: `${this.props.transaction['data'][this.props.transactionId]['quote_currency'] !== null && this.props.transaction['data'][this.props.transactionId]['quote_currency']  !== undefined ? '60px' : '300px'}`, 
                alignItems: 'baseline',
                transition: 'height 0.3s'
                }}>
                    

                    <div className='basier-p3' style={{marginTop: 12, paddingLeft: 0, width: 500, marginBottom: 6, zIndex: 7000, marginRight: 24, fontWeight:  900}}>{this.props.question}</div>
                    <OriginList changeOrigin={this.changeOrigin} selection={this.props.originSelected} />
                    
                </div>

                : 

                null
                
                }

                <div>

                    {
                        this.props.originSelected === 'exchange'? 
                        
                

                            <ExchangeQuestion 
                                noAdd={this.props.noAdd}
                                exchanges = {this.props.exchanges}
                                transaction = {this.props.transaction}
                                transactionId = {this.props.transactionId}
                                //firstFocusExchange = {this.firstFocusExchange}
                                getExchangeAddress = {this.props.getExchangeAddress}
                                updateTransaction = {this.props.updateTransaction}
                                selectExchange = {this.props.selectExchange}
                                onKeyDownNext = {this.props.onKeyDownNext}
                                secondFocusExchange = {this.secondFocusExchange}
                                selectedBase={this.props.selectedBase}
                                selectedQuote={this.props.selectedQuote}
                                question={question}
                                firstFocus={firstFocus}
                                wallets={this.props.wallets}
                                user_id={this.props.user_id}
                                user_first={this.props.user_first}
                                user_last={this.props.user_last}
                                validation={this.props.validation}
                                
                            />

                        : 

                        this.props.originSelected === 'wallet' ? 

                            this.props.wallets !== null && this.props.wallets !== undefined ? 

                                <WalletQuestion 
                                    allowAll={true}
                                    noAdd = {this.props.noAdd}
                                    wallets = {this.props.wallets}
                                    validation={this.props.validation}
                                    user_id = {this.props.transaction['data'][this.props.transactionId]['user_transaction']}
                                    question="Which storage wallet?"
                                    transaction = {this.props.transaction}
                                    transactionId = {this.props.transactionId}
                                    updateTransaction = {this.props.updateTransaction}
                                    base_currency={this.props.transaction['data'][this.props.transactionId]['base_currency']}
                                    />
                                :
                            
                                null
                        

                        : 

                        null
                    }

                </div>

            </div>

        )
    }

}


const mapStateToProps = state => {
    return {
  
        modalPref: state.interactions.transactionAddModal,
        transaction: state.interactions.transactionAddData,
        tr_date: state.interactions.dateTransaction,
        exchangeCheck: state.interactions.exchangeCheck, 
        originSelected: state.interactions.originSelected,
        exchanges: state.appwide.exchanges,
        wallets: state.appwide.wallets,
        
    };
  };
  
  const mapDispatchToProps = dispatch => {
    return {
        addTransaction: (data) =>
            dispatch(actions.addTransaction(data)),
        updateTransaction: (data) =>
            dispatch(actions.updateTransaction(data)), 
        setTrTime: (data) => 
            dispatch(actions.setTrTime(data)), 
        setInteraction: (data) => 
            dispatch(actions.setInteraction(data)), 
    };
  };
  
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(SelectSource);
