import React from 'react';
import { connect } from 'react-redux';
import ExchangeCard from './SmallExchangeWallet'
import StorageCard from './SmallStorageWallet'
import Plus from '../../../Media/Plus.svg'
import * as actions from '../../../store/actions/interactions';
import { withRouter } from "react-router-dom";
import worker from "../../../workers/walletFlattenWorker";
import WebWorker from "../../../workers/WebWorker";


const getValue = (dict, val) => {
    let ret = null
    try {ret = dict[val]} catch(e) {}
    return ret
}


class SmallWallets extends React.PureComponent {
    state = {
        exchange: null,
        storage: null,
    }

  

    componentDidMount() {
        this.setState(state => ({...state, scope: 'exchange'}))
        this.worker = new WebWorker(worker);

        this.worker.addEventListener('message', event => {
            const exchange = event.data[0]
            const storage = event.data[1]
            
            if (exchange.length > 0 || storage.length > 0) {
                
                this.setState(state => ({...state, 
                                        exchange: exchange, 
                                        storage: storage, 
                                        }))
                
            } else {
                this.setState(state => ({...state, item: [], exchange: [], storage: [], filteredItem: []}))
            }

        });

        if (this.props.data[this.props.user_id] !== undefined) {
            this.worker.postMessage(this.props.data[this.props.user_id])
        }
        
    }

    componentDidUpdate(prevProps) {
        if ( (this.props.data !== prevProps.data && this.props.data[this.props.user_id] !== undefined) ) {
            this.worker.postMessage(this.props.data[this.props.user_id])
        }
    }


    addNewExchange = () => {
        this.goToNew()
    }

    goToNew = () => {

        if( this.props.walletType === 'exchange') {
            this.props.history.push({
                pathname: '/new_ea', 
            })
        } else if (this.props.walletType === 'storage') {
            this.props.history.push({
                pathname: '/new_w', 
                state: {wallet_type: 'storage', exchange: null}
            })
        }
        

    }
    
    changeScope = (scope) => {
        this.props.setMobileWallet(scope);
    }
    render () {

        return (

            <div>

                <div style={{display: 'flex', marginTop: 12,}}>

                    <div style={{
                        width: '50%', height: 40, display: 'flex', 
                        alignItems: 'center', justifyContent: 'center', 
                        backgroundColor: `${this.props.walletType === 'exchange' ? '#0078DB' : '#E1E5E9' }`, 
                        color: `${this.props.walletType === 'exchange' ? 'white' : 'black'}`, 
                        fontWeight: `${this.props.walletType === 'exchange' ? 900 : 400 }`, 
                        textTransform: 'uppercase', marginRight: 6, marginLeft: 12, borderRadius: 10}} 
                        className="basier-p3 touchableOpacity"
                        onClick={() => this.changeScope('exchange')}>
                        Exchange
                    </div>
                    <div 
                        style={{width: '50%', height: 40, display: 'flex', 
                        alignItems: 'center', justifyContent: 'center', 
                        backgroundColor: `${this.props.walletType === 'storage' ? '#0078DB' : '#E1E5E9' }`, 
                        fontWeight: `${this.props.walletType === 'storage' ? 900 : 400 }`, 
                        textTransform: 'uppercase', marginRight: 12, marginLeft: 6, borderRadius: 10, 
                        color: `${this.props.walletType === 'storage' ? 'white' : 'black'}`, }}
                        onClick={() => this.changeScope('storage')}
                        className="basier-p3 touchableOpacity">
                        Storage
                    </div>
                </div>

                <div style={{marginTop: 36}}>

                    <div style={{marginBottom: 12, paddingRight: 24, paddingLeft: 12, display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                        <h3 style={{margin: 0}}>{
                        this.props.walletType === 'exchange'?
                        'Accounts' : 'Wallets'
                        }</h3>
                        <div style={{paddingRight: 24, display: 'flex', alignItems: 'center'}} onClick={this.addNewExchange} className="opacity-active">
                            <img src={Plus} style={{height: 50, width: 50}} alt=""/>
                            <div className="basier" style={{fontSize: 16, textTransform: 'uppercase', marginLeft: 12}}>New</div>
                        </div>
                    </div>
                
                    <div style={{marginBottom: 80}}>
                    {
                    this.props.data !== undefined && this.props.data !== null && this.props.walletType === 'exchange' ? 
                        Object.keys(this.props.data[this.props.user_id]['exchange']).map((val, key) => {

                            return (
                                <ExchangeCard 
                                    setCurrentView={this.props.setCurrentView}
                                    key={key}
                                    openModal={(type, scope, add) => this.props.openModal(type, scope, add)} 
                                    user_id={this.props.user_id}
                                    data={this.state.exchange} 
                                    editWallet={(data) => this.props.editWallet(data)}
                                    exchangeId={val}/>
                            )

                        })
                    :
                    null
                }
                {
                    this.props.data !== undefined && this.props.data !== null && this.props.walletType === 'storage' ? 
                        Object.keys(this.props.data[this.props.user_id]['storage']).map((val, key) => {

                            return (
                                <StorageCard 
                                    setCurrentView={this.props.setCurrentView}
                                    key={key}
                                    openModal={(type, scope, add) => this.props.openModal(type, scope, add)} 
                                    user_id={this.props.user_id}
                                    data={this.state.storage} 
                                    editWallet={(data) => this.props.editWallet(data)}
                                    exchangeId={val}/>
                            )

                        })
                    :
                    null
                }
                </div>
            </div>

            </div>

           
            
        )
    }


}

const mapStateToProps = state => {
    return {
        walletType: state.interactions.mobileWalletType,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setMobileWallet: (data) => dispatch(actions.setMobileWallet(data))
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SmallWallets));
