import React from 'react';
import { connect } from 'react-redux';
import WalletDetail from './WalletDetail'
import TopSection from './TopSection'
import FilterSection from './FilterSection'
import MainSection from './MainSection'

class LargeWalletOverview extends React.PureComponent {

    render () {

        return (
            
            <div style={{padding: 48}}>

                <TopSection openModal={this.props.openModal} token={this.props.token} user_id={this.props.user_id} />
                
                <div style={{marginTop: 48, paddingLeft: 24, paddingRight: 24}}>
                    <MainSection data={this.props.data} givenRoute={this.props.givenRoute} />
                </div>

            </div>
        )
    }


}

const mapStateToProps = state => {
    return {
        user_id: state.auth.user_id,
        token: state.auth.token,
        
    };
};

const mapDispatchToProps = dispatch => {
    return {
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(LargeWalletOverview)
