import React, {Component} from 'react';
import currencyData from "../../../../../../helpers/currency.json";

function renderSingleSymbol(data) {
    if (data.image) {
        return (
            <div style={{
                height: 36,
                width: 36,
                borderRadius: '100%',
                backgroundColor: 'white',
                border: '1px solid #6A7E93',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                overflow: 'hidden',
            }}>
                <img src={data.image} alt="" style={{height: 30, width: 30}}/>
            </div>
        )
    } else {
        return (
            <div style={{
                height: 36,
                width: 36,
                borderRadius: '100%',
                backgroundColor: '#F8F9FA',
                border: '1px solid #6A7E93'
            }}>
                <div className="basier-p3" style={{color: 'black'}}>
                    {data.symbol.slice(0, 1)}
                </div>
            </div>
        )
    }
}

function renderCoinImages(data) {
    if (data.translator) {
        const coinValues = Object.values(data.translator)
        const coins = []
        coinValues.map(singleCoin => {
            if (singleCoin.image) {
                coins.unshift(singleCoin)
            } else {
                coins.push(singleCoin)
            }
        })
        return (
            <div style={{display: 'flex', alignItems: 'center'}}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    {coins.map((coin, k) => {
                        return (
                            <div key={k} style={{zIndex: k > 0 ? 0 : 1, marginLeft: k > 0 ? -16 : 0}}>
                                {renderSingleSymbol(coin)}
                            </div>
                        )
                    })}
                </div>
                <div style={{color: 'black', fontWeight: 'bold', marginLeft: 4}} className="basier-p3">
                    {coins.map((coin, k) => {
                        return (
                            <div>{coin.symbol}</div>
                        )
                    })}
                </div>
            </div>
        )
    } else {
        return <div/>
    }
}

class LPPoolToken extends Component {
    render() {
        const {data} = this.props;
        return (
            <div style={{
                height: 60,
                width: '100%',
                flex: 1,
                display: 'flex',
                alignItems: 'center'
            }}>
                {renderCoinImages(data)}
            </div>
        );
    }
}

export default LPPoolToken;