import React from 'react';
import Tooltip from '../../../../../AppWide/Tooltip';
import { connect } from 'react-redux';
import Switch from '../../../../../Assets/Switch'
import help from '../../../../../../Media/help.svg'
import FormControl from '@material-ui/core/FormControl';

class SourceOfFunds extends React.Component {

  
  render() {

    return (

        <div style={{width: 250,}}>
            { 
                this.props.miningQuestion === true ? 

                <React.Fragment>
                    <div className="basier-p4-caps" style={{marginBottom: 2, color: 'black', fontWeight: 900, display: 'flex', alignItems: 'flex-start'}}>
                        Cost of rewards/mining
                    </div>

                    <div className="basier-p4" style={{lineHeight: '22px', fontStyle: "italic"}}>
                        {this.props.transaction.mining_cost !== undefined && this.props.transaction.mining_cost !== null && this.props.transaction.mining_cost !== ''?
                        
                        <div>
                            {this.props.base !== undefined && this.props.base !== null ? 
                            <span>
                                <span>Unit cost of {this.props.base.coinTicker}:</span>&nbsp;<span>{this.props.transaction.amount !== null && this.props.transaction.amount !== undefined && this.props.transaction.amount !== '' && this.props.transaction.amount !== 0 ? parseFloat(this.props.transaction.mining_cost) / parseFloat(this.props.transaction.amount) : 'tbd'}</span>&nbsp;<span>{this.props.quote !== undefined && this.props.quote !== null ? this.props.quote.coinTicker : null }</span>
                            </span>
                            :
                            null}
                        </div>
                        
                        :
                        
                        <span>You have not entered a rewards/mining cost. As such we will enter these coins with a cost basis of 0.</span>
                        
                        }

                    </div>
                    
                    {this.props.quote !== undefined && this.props.quote !== null && this.props.transaction.mining_cost !== undefined && this.props.transaction.mining_cost !== null && this.props.transaction.mining_cost !== '' ? 
                        <div style={{marginBottom: 0, display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}> 
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <div>Create {this.props.quote.CoinTicker} deposit</div>
                                <Tooltip title="If you select this option, we will automatically create an additional deposit transaction. This transaction will have the amount you entered as Total Cost, and the currency you preovided as Cost currency.">
                                <div><img src={help} style={{height: 18, width: 18, marginLeft: 6}} alt="" /></div>
                                </Tooltip>
                            </div>
                            <Switch status={this.props.addCost} handleSwitchChange={this.props.toggleAddCost} />
                        </div>
                        :
                        null
                        } 

                    <div>
                        {
                            !this.props.addCost && this.props.quote !== undefined && this.props.quote !== null && this.props.balances !== undefined && this.props.balances !== null ? 
                            
                                parseFloat(this.props.balances[this.props.quote.value]) < parseFloat(this.props.transaction.mining_cost) ? 

                                <div className="basier-p4" style={{marginTop: 2, fontStyle: 'italic', color: 'orange'}}>
                                    This will result in a negative balance.
                                </div>

                                :
                                null
                        
                            :

                            null
                        
                        }
                    </div>

                </React.Fragment>

                :

                this.props.feeQuestion === true ? 


                <React.Fragment>
                    <div className="basier-p4-caps" style={{marginBottom: 2, color: 'black', fontWeight: 900, display: 'flex', alignItems: 'flex-start'}}>
                        Fee Treatment
                    </div>

                    <div className="basier-p3" style={{lineHeight: '22px'}}>
                        
                        <div style={{marginBottom: 0, display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}> 
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <div>{
                                ['deposit', 'dividend', 'income', 'gift', 'airdrop', 'fork'].includes(this.props.transaction.transaction_description) ?
                                'Deduct fee '
                                :
                                'Add back fee'
                                }</div>
                                <Tooltip title={
                                        <React.Fragment>
                                          <div style={{marginBottom: 8}}>Select this option if the amount you entered already <b>includes fees</b>.</div>
                                          
                                          {['deposit', 'dividend', 'income', 'gift', 'airdrop', 'fork'].includes(this.props.transaction.transaction_description) &&
                                          <div style={{marginBottom: 4}}>
                                            <span style={{fontWeight: 900}}>For example: </span><span>If you deposit <b>1 BTC</b>, with <b>0.1 in fees</b>, select this option if you entered 1 in the amount field, or disable it if you entered 0.9.</span>
                                          </div>}

                                          {!['deposit', 'dividend', 'income', 'gift', 'airdrop', 'fork'].includes(this.props.transaction.transaction_description) && 
                                          <div>
                                            <span style={{fontWeight: 900}}>For example: </span><span>If you withdraw <b>1 BTC</b>, with <b>0.1</b> in fees, select this option if you entered 0.9 in the amount field, or disable it if you entered 1.</span>
                                          </div>}

                                        </React.Fragment>
                                      
                                    }>
                                <div><img src={help} style={{height: 18, width: 18, marginLeft: 6}} alt="" /></div>
                                </Tooltip>
                            </div>
                            <Switch status={this.props.feeInclude} handleSwitchChange={this.props.toggleFeeInclude} />
                        </div>

                        <div className="basier-p4-caps" style={{marginTop: 4, display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                        {this.props.base !== null && this.props.transaction.amount !== undefined ?
                            <div>You will {['deposit', 'dividend', 'income', 'gift', 'airdrop', 'fork'].includes(this.props.transaction.transaction_description) ?'increase' : 'decrease'} {this.props.base !== null ? this.props.base.coinTicker : null} by {this.props.transaction.amount !== undefined &&  this.props.transaction.amount !== null && this.props.transaction.amount !== '' ? 
                                ['deposit', 'dividend', 'income', 'gift', 'airdrop', 'fork'].includes(this.props.transaction.transaction_description) && this.props.feeInclude === true ? 
                                    parseFloat(this.props.transaction.amount) - (isNaN(parseFloat(this.props.transaction.fee)) === false ? parseFloat(this.props.transaction.fee) : 0)
                                    : 
                                    (!['deposit', 'dividend', 'income', 'gift', 'airdrop', 'fork'].includes(this.props.transaction.transaction_description) ) && this.props.feeInclude === true ? 
                                    parseFloat(this.props.transaction.amount) + (isNaN(parseFloat(this.props.transaction.fee)) === false ? parseFloat(this.props.transaction.fee) : 0)
                                :
                                this.props.transaction.amount

                            : <span className="basier-p4" style={{color: '#CCC', fontStyle: 'italic'}}>tbd</span>}</div> : null }
                        </div>


                    </div>
                    {this.props.invalidBalance ?
                    <div className="basier-p4" style={{marginTop: 2, fontStyle: 'italic', color: 'orange'}}>
                        This will result in a negative balance.
                    </div>
                        :
                    null}
                </React.Fragment>

            


            :
            
            
            this.props.quoteBalance.includes(this.props.transaction.transaction_description) ? 
            
            <React.Fragment>
                <div className="basier-p4-caps" style={{marginBottom: 2, color: 'black', fontWeight: 900, display: 'flex', alignItems: 'flex-start'}}>
                    Source of Funds
                </div>
                
                <div className="basier-p3" style={{lineHeight: '19px', width: 250}}>
                    <div style={{marginBottom: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                        <div>Deduct from balance</div>
                        <Switch status={this.props.deduct === true} handleSwitchChange={this.props.switchSource} />
                    </div>

                    <div style={{marginBottom: 0, display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}> 
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <div>Add new money</div>
                            <Tooltip title={`If you select this option, will add a deposit of ${this.props.quote === null ? 'Quote Currency' : this.props.quote.coinTicker} equivalent to the amount needed to buy ${this.props.base === null ? 'Base Currency' : this.props.base.coinTicker} at the same date as this ${this.props.transaction.transaction_description} entry.`}>
                            <div><img src={help} style={{height: 18, width: 18, marginLeft: 6}} alt="" /></div>
                            </Tooltip>
                        </div>
                        <Switch status={this.props.deduct === false} handleSwitchChange={this.props.switchSource} />
                    </div>

                </div>
                
                <div className="basier-p4" style={{marginTop: 2, fontStyle: 'italic', color: '#0078DB'}}>
                    {this.props.priceType === 'unit' && this.props.transaction.amount !== null && this.props.transaction.amount !== '' && this.props.transaction.amount !== undefined && this.props.transaction.unit_price !== null && this.props.transaction.unit_price !== undefined && this.props.transaction.unit_price !== ''  ?
                        <div>You will spend { (parseFloat(this.props.transaction.amount) * parseFloat(this.props.transaction.unit_price)).toLocaleString('en') } {this.props.quote !== null ? this.props.quote.coinTicker : null}</div>
                    : this.props.priceType === 'total' && this.props.transaction.amount !== null && this.props.transaction.amount !== '' && this.props.transaction.amount !== undefined && this.props.transaction.unit_price !== null && this.props.transaction.unit_price !== undefined && this.props.transaction.unit_price !== '' ?
                        <div>Your average price is { (parseFloat(this.props.transaction.unit_price) / parseFloat(this.props.transaction.amount)).toLocaleString('en') } {this.props.quote !== null ? this.props.quote.coinTicker : null}</div>
                    :

                    null

                    }
                </div>
                {this.props.invalidBalance && this.props.deduct ?
                <div className="basier-p4" style={{marginTop: 2, fontStyle: 'italic', color: 'orange'}}>
                    This will result in a negative balance.
                </div>
                    :
                null}
            </React.Fragment>

            :

            <React.Fragment>
                <div className="basier-p4-caps" style={{marginBottom: 2, color: 'black', fontWeight: 900, display: 'flex', alignItems: 'flex-start'}}>
                Source of funds
                </div>

                <div className="basier-p3" style={{lineHeight: '22px'}}>
                    <div style={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
                    <div>{this.props.base !== null ? this.props.base.coinTicker : null} Available:</div>
                        {this.props.balances !== undefined && this.props.base !== null && this.props.balances[this.props.base.value] !== undefined ? 
                        <div>{
                            this.props.balances[this.props.base.value] > 1 ? 
                                this.props.balances[this.props.base.value].toLocaleString('en', {minimumFractionDigits: 2, maximumFractionDigits: 2})
                                :
                                this.props.balances[this.props.base.value].toLocaleString('en', {minimumFractionDigits: 8, maximumFractionDigits: 8})
                        }</div>
                        :
                        <div style={{color: '#CCC', fontStyle: 'italic'}}>tbd</div>}
                    </div>
                    <div style={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
                        <div>{this.props.base !== null ? this.props.base.coinTicker : null} Sold:</div>
                        <div>
                            {this.props.transaction.amount !== undefined && this.props.base !== null ? 
                            <div>{
                                this.props.transaction.amount
                            }</div>
                            :
                            <div style={{color: '#CCC', fontStyle: 'italic'}}>tbd</div>}
                        </div>
                    </div>
                </div>

                <div className="basier-p4" style={{marginTop: 2, fontStyle: 'italic', color: '#0078DB'}}>
                    {this.props.priceType === 'unit' && this.props.transaction.amount !== null && this.props.transaction.amount !== '' && this.props.transaction.amount !== undefined && this.props.transaction.unit_price !== null && this.props.transaction.unit_price !== undefined && this.props.transaction.unit_price !== ''  ?
                        <div>You will receive { (parseFloat(this.props.transaction.amount) * parseFloat(this.props.transaction.unit_price)).toLocaleString('en') } {this.props.quote !== null ? this.props.quote.coinTicker : null}</div>
                    : this.props.priceType === 'total' && this.props.transaction.amount !== null && this.props.transaction.amount !== '' && this.props.transaction.amount !== undefined && this.props.transaction.unit_price !== null && this.props.transaction.unit_price !== undefined && this.props.transaction.unit_price !== ''  ?
                        <div>Your average price is { (parseFloat(this.props.transaction.unit_price) / parseFloat(this.props.transaction.amount)).toLocaleString('en') } {this.props.quote !== null ? this.props.quote.coinTicker : null}</div>
                    :

                    null

                    }
                </div>


                <div className="basier-p4" style={{marginTop: 2, fontStyle: 'italic', color: 'orange'}}>
                    {this.props.invalidBalance ?
                    <div className="basier-p4" style={{marginTop: 2, fontStyle: 'italic', color: 'orange'}}>
                        This will result in a negative balance.
                    </div>
                        :
                    null}
                </div>
            </React.Fragment>

            }
        </div>

    )
  }
}


const mapStateToProps = state => {
  return {
      
  };
};

const mapDispatchToProps = dispatch => {
  return {
      
  };
};


export default connect(
  mapStateToProps,
  mapDispatchToProps)(SourceOfFunds);
