import React from 'react';
import { connect } from 'react-redux';
import {siwtchTransactionType, formattedNumber} from '../../../../../helpers/helpers';
import contentLoader from '../../../../../Media/contentLoader.svg'
import * as actions from "../../../../../store/actions/interactions";
import currencyDict from '../../../../../helpers/currency.json';

class TransactionTable extends React.PureComponent {

    state = {
        totalPages: Math.max(Math.ceil(this.props.total / 10, 1)),
        shownData: this.props.data, 
        pageProposed: this.props.page + 1,
    }
    
    componentDidUpdate(prevProps) {

        if (this.props.data !== prevProps.data && this.props.data !== null && this.props.data !== undefined) {
            this.setState(state => ({...state, shownData: this.props.data, totalPages: Math.max(Math.ceil(this.props.total / 10, 1)),}))
            //this.goToPage(1)
        }

    }

    onKeyDown = (event) => {
        if (event.key === 13 || event.key === 9) {
            this.commitPageChange()
        } else if (event.keyCode === 13 || event.keyCode === 9) {
            this.commitPageChange()
        }
    }

    movePage = (number) => {
        let newPage = parseInt(this.props.page + 1) + parseInt(number)
        if (newPage > this.state.totalPages) {
            newPage = this.state.totalPages
        } else if (newPage < 1) {
            newPage = 1
        }

        this.setState(state => ({...state, pageProposed: newPage}))
        this.props.getTransactions(newPage - 1)
        window.scrollTo(0, 0)
        this.props.setInteraction({label: 'pin', value: true})
    }

    changeInput = (e) => {
        e.persist();
        const newPage = e.target.value
        this.setState(state => ({...state, pageProposed: newPage}))
    }
    
    goToPage = (newPage) => {


        if (newPage > this.state.totalPages) {
            newPage = this.state.totalPages
        } else if (newPage < 1) {
            newPage = 1
        }

        this.props.getTransactions(newPage - 1)
        window.scrollTo(0, 0)
        this.props.setInteraction({label: 'pin', value: true})


    }


    commitPageChange = () => {
        if (this.state.pageProposed !==this.props.page) {
            let proposedDisplay = this.state.pageProposed
            if (this.state.pageProposed > this.state.totalPages) {
                proposedDisplay = this.state.totalPages
            } else if (this.state.pageProposed < 1) {
                proposedDisplay = 1
            }
            this.setState(state => ({...state, pageProposed: proposedDisplay}))
            this.props.getTransactions(proposedDisplay - 1)
        }
    }


    render () {

        return (


           <div>
               <div>
               {
               this.props.isLoading ?  

                <div style={{marginTop: 48, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}> 
                    <div><img src={contentLoader} alt="" style={{height: 120, width: 120}} /></div>
                    <div>Loading</div>
                </div>

               :

               this.props.data.map((item, key) => {

                let displayDate = null
                try {
                    let date = new Date(item.date)
                    const language = navigator.language || 'en'
                    const options = {year: '2-digit', month: 'numeric', day: 'numeric' };
                    displayDate = date.toLocaleDateString(language, options)
                } catch(e) {}

                    return (
                    <div style={{ 
                        paddingLeft: 12, 
                        paddingRight: 12, 
                        display: 'flex', 
                        borderBottom: '1px solid #E1E5E9',
                        width: '100%',
                        justifyContent: 'space-between',
                        height: 140,
                        alignItems: 'center'
                        }}
                    key={key}
                    value={item.id}
                    onClick={() => this.props.seeDetail(item)}
                    >
                    
                    <div style={{
                        display: 'flex', 
                        alignItems: 'flex-start',
                        justifyContent: 'flex-start',
                        flexDirection: 'column',
                        marginBottom: 7,
                        paddingLeft: 12,
                        paddingRight: 12,
                        marginTop: 7,
                        width: '100%',
                        background: 'white'
                        
                        }}>
                        
                        <div style={{display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between', width: '100%'}}>
                            <div>
                                <div 
                                    className={`basier-p4 transaction-bubble transaction-color-${item.transaction_description}`}
                                    style={{
                                        textTransform: 'uppercase', 
                                        fontWeight: 900, 
                                        marginLeft: 0,
                                    }}
                                
                                    >
                                    {siwtchTransactionType(item.transaction_description)[0]}
                                </div>
                            </div>

                            <div>{displayDate}</div>
                        </div>

                        <div style={{marginTop: 12, width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                            <div className="basier-p4-caps">Coins</div>
                            <div style={{display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'flex-end'}}>
                                    <div>

                                        <div style={{display: 'flex', alignItems: 'center'}}>

                                        {
                                            item.base_img !== "" && item.base_img !== null  ? 
                                            <span><img style={{height: 25, width: 25, marginRight: 12}} src={item.base_img} alt=""/></span>
                                            :
                                            <span style={{height: 25, width: 25, border: '2px solid black', color: 'black', borderRadius: '100%', marginRight: 12, display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 14 }}>
                                                {currencyDict[item.base_ticker] === undefined ? item.base_ticker : currencyDict[item.base_ticker]['symbol'].length > 1 ? currencyDict[item.base_ticker]['symbol'] : currencyDict[item.base_ticker]['symbol'] }
                                            </span>


                                        }   
                                         <div>
                                            <div className='basier-p3' style={{fontWeight: 400, color: 'black'}}>{item.base_ticker}</div>
                                        </div>
                                    </div>
                                   
                                </div>
                                
                                {item.quote !== null && ['buy', 'sell', 'ico'].includes(item.transaction_description) && 
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <h3 className="basier-p1" style={{marginLeft: 6, marginRight: 6, marginBottom: 0, marginTop: 0}}>|</h3>

                                    <div style={{display: 'flex', alignItems: 'center'}}> 

                                        <div>

                                            {item.quote_img !== "" && item.quote_img !== null  ? 
                                            <span><img style={{height: 25, width: 25, marginRight: 12}} src={item.quote_img} alt=""/></span>
                                            :
                                            <span style={{height: 25, width: 25, border: '1px solid black', color: 'black', borderRadius: '100%', marginRight: 12, display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 10 }}>
                                                {currencyDict[item.quote_ticker] === undefined ? item.quote_ticker : currencyDict[item.quote_ticker]['symbol'].length > 1 ? currencyDict[item.quote_ticker]['symbol'] : currencyDict[item.quote_ticker]['symbol'] }
                                            </span>}
                                            
                                            
                                        </div>
                                        
                                        <div>
                                            <div className='basier-p3' style={{fontWeight: 400, color: 'black'}}>{item.quote_ticker}</div>
                                        </div>
                                    </div>
                                </div>
                                }


                            </div>


                        </div>

                        <div style={{marginTop: 12, display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%'}}>
                            <div className="basier-p4-caps">Qty</div> 
                            <div style={{display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'flex-end'}}>
                                <div>
                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                        <div className='basier-p3' style={{fontWeight: 400, color: 'black'}}>{formattedNumber(item.amount)}</div>
                                    </div>
                                
                                </div>
                                
                                {item.quote !== null && ['buy', 'sell', 'ico'].includes(item.transaction_description) && 
                                <div>
                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                        <div className="basier-p4-caps" style={{marginRight: 6, marginLeft: 6}}>
                                            @
                                        </div>

                                        <div style={{display: 'flex', alignItems: 'center'}}>
                                            <div className='basier-p3' style={{fontWeight: 400, color: 'black'}}>{formattedNumber(item.unit_price)}</div>
                                        </div>
                                
                                    </div>
                                </div>
                                }


                            </div>


                        </div>
                    
                    </div>

                    <div style={{width: 30, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.999999 0.856573L7 6.85413L1 12.8541" stroke="#000000" stroke-miterlimit="10"/>
                        </svg>
                    </div>

                    </div>
                    )}


                    )}
                    </div>
                    


                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', marginTop: 24, marginBottom: 72}}>

                    <div className="basier-p2 opacity-hover" style={{marginBottom: 72, padding: 12, border: '1px solid #CCC', borderRadius: 10, textTransform: 'uppercase', color:this.props.page == 0 ? 'gray' : '#0078DB', cursor:this.props.page == 0 ? 'not-allowed' : 'pointer' }} onClick={this.props.page == 0 ? null : () => this.movePage(-1)}>Prev</div>

                    <div style={{marginBottom: 72, display: 'flex', alignItems: 'center', marginLeft: 12, marginRight: 12}}>
                        <div >
                            <input onChange={this.changeInput} onKeyDown={this.onKeyDown} onBlur={this.commitPageChange} className="light-input-back" type="text" value={this.state.pageProposed} style={{width: 60, textAlign: 'center', border: '1px solid #CCC', boxShadow: 'none', padding: '5px 10px'}} />
                        </div>
                        <div style={{marginRight: 12, marginLeft: 12}} >of</div>
                        <div>
                            {this.state.totalPages}
                        </div>
                    </div>

                    <div className="basier-p2 opacity-hover" style={{marginBottom: 72, padding: 12, border: '1px solid #CCC', borderRadius: 10, textTransform: 'uppercase', color:this.props.page == this.state.totalPages - 1 ? 'gray' : '#0078DB', cursor:this.props.page == this.state.totalPages - 1 ? 'not-allowed' : 'pointer' }} onClick={this.props.page == this.state.totalPages - 1 ? null : () => this.movePage(1)}>Next</div>

                    </div>


           </div>
            
        )
    }


}

const mapStateToProps = state => {
    return {
        isLoading: state.loading.GET_TRANSACTION
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setInteraction: (data) => 
            dispatch(actions.setInteraction(data)), 
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(TransactionTable)
