import React from "react";
import {
  formattedNumber,
  siwtchTransactionType,
} from "../../../../../helpers/helpers";
import errorIcon from "../../../../../Media/errorIcon.svg";

const Moment = require("moment");

const Divider = () => {
  return (
    <div
      style={{ width: "100%", height: 1, background: "#E1E5E9", marginTop: 12 }}
    />
  );
};

const ErrorBullet = (props) => {
  return (
    <div
      style={{
        marginLeft: 12,
        display: "flex",
        alignItems: "center",
        marginTop: 3,
        marginBottom: 3,
      }}
      className="basier-p4"
    >
      <div
        style={{
          marginLeft: 6,
          marginRight: 6,
          width: 6,
          height: 6,
          backgroundColor: "#0078DB",
          borderRadius: 6,
        }}
      />
      {props.children}
    </div>
  );
};

function renderErrorCoin(transactionData) {
  const errorQuote = ["buy", "ico", "margin_buy"];

  if (errorQuote.includes(transactionData.transaction_description)) {
    return {
      coin: transactionData.quote,
      image: transactionData.quote,
      amount: transactionData.amount * transactionData.unit_price,
    };
  } else {
    return {
      coin: transactionData.base,
      image: transactionData.base_img,
      amount: transactionData.amount,
    };
  }
}

function renderFixLine(fixes, data, errorAmount, coin) {
  return (
    <div>
      {fixes.map((v, k) => {
        const fixTx = data[v];
        const scopeAmount =
          coin == fixTx.base
            ? fixTx.amount - (fixTx.feeCurr === fixTx.base ? fixTx.fee : 0)
            : fixTx.amount * fixTx.unit_price -
              (fixTx.feeCurr === fixTx.quote ? fixTx.fee : 0);
        const takenFromTx = Math.min(errorAmount, scopeAmount);
        errorAmount = errorAmount - takenFromTx;
        return (
          <ErrorBullet key={k}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <span style={{ fontWeight: "bold", color: "black" }}>
                {formattedNumber(takenFromTx)} {coin}&nbsp;
              </span>
              <div>taken from transaction</div>
              <div
                style={{
                  backgroundColor: "rgba(243,245,246, 1)",
                  padding: "1px 6px",
                  marginLeft: 6,
                  marginRight: 6,
                  color: "black",
                }}
              >
                <span className="basier-p4">
                  <span
                    style={{
                      color: `${
                        siwtchTransactionType(fixTx.transaction_description)[1]
                      }`,
                      fontWeight: "bold",
                    }}
                  >
                    {siwtchTransactionType(fixTx.transaction_description)[0]}{" "}
                  </span>
                  <span>
                    {formattedNumber(fixTx.amount)} {fixTx.base}
                    {fixTx.quote !== null ? `/` : ""}
                    {fixTx.quote !== null ? fixTx.quote : null}{" "}
                    {fixTx.unit_price !== null ? `@ ` : ""}
                    {fixTx.unit_price !== null
                      ? formattedNumber(fixTx.unit_price)
                      : null}
                  </span>
                  <span style={{ fontWeight: "bold" }}>
                    &nbsp;on {Moment(fixTx.date).format("YYYY-MM-DD @ HH:mm")}
                  </span>
                </span>
              </div>
            </div>
          </ErrorBullet>
        );
      })}
    </div>
  );
}

function renderNoFix(amount, price, date, coin) {
  return (
    <ErrorBullet>
      <div className="basier-p4">
        <span style={{ fontWeight: "bold", color: "black" }}>
          {amount !== "remaining" ? 
          formattedNumber(amount)
          : 
          "Remaining"} {coin}
        </span>{" "}
        added on {Moment(date).format("YYYY-MM-DD @ HH:mm")} with a cost basis
        of {formattedNumber(price)}
      </div>
    </ErrorBullet>
  );
}

const ErrorDetail = ({ errorTransaction, errorData, fixData }) => {
  const coinError = renderErrorCoin(errorTransaction);
  const nonAddFixes = errorData.fix_ids.filter((v) => v !== "ADD");
  const addFix = errorData.fix_ids.filter((v) => v === "ADD");
  return (
    <div
      style={{
        width: "100%",
        marginTop: 12,
        marginBottom: 12,
        padding: 12,
        boxShadow: "0px 4px 10px rgba(249, 113, 107, 0.2)",
        border: "1px solid #F9716B4D",
        borderRadius: 20,
        minHeight: 100,
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: '100%',
        }}
      >
        <div
          className="basier-p4-caps"
          style={{ display: "flex", alignItems: "center" }}
        >
          <img
            src={errorIcon}
            alt=""
            style={{ width: 15, marginLeft: 4, marginRight: 4 }}
          />
          <div style={{ color: "#F9716B", fontWeight: "bold" }}>
            Error&nbsp;
          </div>
          <div>
            | {Moment(errorTransaction.date).format("YYYY-MM-DD @ HH:mm")}
          </div>
        </div>
        <div className="basier-p4" style={{ color: "#e0a03d", marginRight: 12, textTransform: 'lowercase', fontStyle: 'italic'}}>Unavailable Balance {nonAddFixes.length > 0 ? ' / date entry error' : null}</div>
      </div>
      <div style={{ marginLeft: 6 }}>
        <div
          style={{ display: "flex", alignItems: "center", marginTop: 12 }}
          className="basier-p3"
        >
          <div>The following transaction</div>
          <div
            style={{
              backgroundColor: "rgba(243,245,246, 1)",
              padding: "1px 6px",
              marginLeft: 6,
              marginRight: 6,
              color: "black",
            }}
          >
            <span className="basier-p4" style={{ textTransform: "uppercase" }}>
              <span
                style={{
                  color: `${
                    siwtchTransactionType(
                      errorTransaction.transaction_description
                    )[1]
                  }`,
                  fontWeight: "bold",
                }}
              >
                {
                  siwtchTransactionType(
                    errorTransaction.transaction_description
                  )[0]
                }{" "}
              </span>
              <span>
                {formattedNumber(errorTransaction.amount)}{" "}
                {errorTransaction.base}
                {errorTransaction.quote !== null ? `/` : ""}
                {errorTransaction.quote !== null
                  ? errorTransaction.quote
                  : null}{" "}
                {errorTransaction.unit_price !== null ? `@ ` : ""}
                {errorTransaction.unit_price !== null
                  ? formattedNumber(errorTransaction.unit_price)
                  : null}
              </span>
              <span></span>
            </span>
          </div>
          <div>resulted in an error.</div>
        </div>
        <Divider />
        <div style={{ marginTop: 12 }}>
          <div
            className="basier-p4-caps"
            style={{
              color: "black",
              fontWeight: "bold",
              textTransform: "uppercase",
            }}
          >
            Issue
          </div>
          <span>
            <span className="basier-p3" style={{ color: "#6A7E93" }}>
              <span style={{ fontWeight: "bold", color: "#F9716B" }}>
                {formattedNumber(errorData.error_amount)} {coinError.coin}
              </span>{" "}
              were used without having previously been deposited or bought.
            </span>
          </span>
        </div>
        <Divider />
        <div style={{ marginTop: 12 }}>
          <div
            className="basier-p4-caps"
            style={{
              color: "black",
              fontWeight: "bold",
              textTransform: "uppercase",
            }}
          >
            Automatic Fixes
          </div>
          <span>
            <span
              className="basier-p4"
              style={{ color: "#6A7E93", fontStyle: "italic" }}
            >
              We have automatically applied the below fixes to correct these
              errors. These fixes will likely still result in incorrect data.{" "}
              <span style={{ color: "#F9716B", fontWeight: "bold" }}>
                We recommend you review your transactions and apply the relevant
                corrections
              </span>{" "}
            </span>
          </span>
        </div>

        <div style={{ marginTop: 6 }}>
          {nonAddFixes.length == 0 && addFix.length > 0
            ? renderNoFix(
                errorData.error_amount,
                0,
                errorTransaction.date,
                coinError.coin
              )
            : 
            nonAddFixes.length > 0 ?
              <div>
                {renderFixLine(
                nonAddFixes,
                fixData,
                errorData.error_amount,
                coinError.coin
                )}
                {addFix.length > 0 && 
                  <div>
                  {renderNoFix(
                    "remaining",
                    0,
                    errorTransaction.date,
                    coinError.coin
                  )}
                  </div>
                }
                <div style={{color: '#e0a03d', fontWeight: 'bold', marginTop: 6}} className="basier-p4">NOTE: The above fixes assume there were date errors in your entries, but that your overall balances are correct. This may not be correct, and missing transactions should be entered.</div>
              </div>
              : 
              null
          }
        </div>
      </div>
    </div>
  );
};

class ErrorLine extends React.PureComponent {
  render() {
    const { data, errors, errorData } = this.props;

    return (
      errors !== undefined &&
      data !== undefined && (
        <div>
          {errors.map((v, k) => {
            const errorDetails = errorData[v];
            const errorTransaction = data[v];
            return (
              errorData !== undefined && (
                <div key={k}>
                  <ErrorDetail
                    errorTransaction={errorTransaction}
                    errorData={errorDetails}
                    fixData={data}
                  />
                </div>
              )
            );
          })}
        </div>
      )
    );
  }
}

export default ErrorLine;
