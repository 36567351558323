export const updateLocal = (data) => {
  if (localStorage.hasOwnProperty("user")) {
    let user = JSON.parse(localStorage.getItem("user"));
    const newData = {
      ...user,
      ...data,
    };
    localStorage.setItem("user", JSON.stringify(newData));

    return true;
  }
  return false;
};

export const getLocalNFTCache = () => {
  if (localStorage.hasOwnProperty("ncc")) {
    const data = JSON.parse(localStorage.getItem("ncc"));
    if (data.time >= new Date()) {
        return data
    }
  }
  return null;
};

export const setLocalNFTCache = (data) => {
    const newDate = new Date();
    const expireDate = newDate.setTime(newDate.getTime() + 4 * 60 * 60 * 1000)
    const existingCache = getLocalNFTCache()
    let cleanedData = {}
    const dataKeys = Object.keys(data);
    dataKeys.forEach(key => {
        cleanedData[key] = {}
        const contractData = data[key]
        const tokenKeys = Object.keys(contractData)
        tokenKeys.forEach(tok => {
            const tokenPrice = contractData[tok]
            if (tokenPrice) {
                cleanedData[key][tok] = tokenPrice
            }
        })

    }) 
    if (!existingCache) {
        const newData = {
            time: expireDate,
            payload: cleanedData
        };
        localStorage.setItem("ncc", JSON.stringify(newData));
        return true;
    } else {
        const newData = {
            ...existingCache,
            payload: {
                ...existingCache.payload, 
                ...cleanedData,
            },
        };
        localStorage.setItem("ncc", JSON.stringify(newData));
        return true;
    }
};
