import React from 'react';
import { connect } from 'react-redux';
import * as actions from "../../../../../../store/actions/interactions";
import ParseDecimalNumber from 'parse-decimal-number';
import ExtraSelect from '../../../../../AppWide/InputExtraSelect';

const cleanValue = (val) => {
    let ret = val;
    try {ret = val.trim() } catch(e) {}
    return ret
}

class InputQuestion extends React.PureComponent {

    handleChange = (e) => {
        e.persist();
        let amount = e.target.value
        this.props.updateTransaction(this.props.label, amount)
    } 

    validateNumber(e) {
        e.persist();

        let amount = e.target.value
        if (this.props.validateNumber === true) {
            amount = ParseDecimalNumber(e.target.value, ',.')
            if (isNaN(amount)) {
                amount = ParseDecimalNumber(e.target.value, '.,')
            }
            if (isNaN(amount)) {
                amount = e.target.value
            }
            
        }
        const valid = this.props.validatorMethod(amount)

        if (valid) {

            this.props.updateTransaction(this.props.label, amount)
            this.props.handlePostSelect(this.props.label, amount)

        }
        return valid
        

        }

    getRef = async (ref) => {
        return ref;
        }
     
    onBlur = (e) => {
        e.persist();
        
        if (this.props.validateNumber === true) {
            if (e.target.value !== '' && e.target.value !== undefined && e.target.value !== null) {
                this.validateNumber(e)
            }
        }


    }

    onKeyDown = (e) => {
        e.persist();
        
        
        if (e.keyCode === 13 || e.keyCode === 9 || e.key === "Enter" || e.key === "Tab") {
            e.preventDefault();    
            let valid = true;
            const isShift = !!e.shiftKey;
            if (!isShift && this.props.validateNumber === true) {
                valid = this.validateNumber(e)
            }

            if (valid) {
                
            
                e.preventDefault();
                
                if (isShift) {
                    this.props.getPreviousRef()
                } else {
                    if (this.props.typeSelector !== undefined) {

                        const ref_needed = this.getRef(this.props.extraRef)
            
                        ref_needed
                        .then(res => {

                            try {res.current.focus({preventScroll: true})} catch(e) {}
                            
                            })


                    } else {
                        this.props.getNextRef();
                    }
                }
            }
        }

    }

    onExtraKeyDown = (e) => {

        e.persist();
        e.preventDefault();
        
        if (e.keyCode === 13 || e.keyCode === 9 || e.key === "Enter" || e.key === "Tab") {
            e.preventDefault();    
            const isShift = !!e.shiftKey;
            
            if (isShift) {
                const ref_needed = this.getRef(this.props.innerRef)
        
                ref_needed
                .then(res => {

                    try {res.current.focus({preventScroll: true})} catch(e) {}
                    
                })

            } else {
                e.preventDefault();    
                this.props.getNextRef();

            }
        }


    }


    render () {

        return (

            <div style={{width: this.props.fullWidth === true ? '100%' : 250}}>
                <div style={{marginBottom: 2, color: 'black', fontWeight: 900}}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <div className="basier-p4-caps">{this.props.labelName}</div>
                        <div className="basier-p4" style={{color: 'gray', fontSize: 10, fontWeight: 400, display: 'flex', alignItems: 'center'}}>
                        {this.props.labelCTA !== undefined && this.props.labelCTA !== null ? 

                            <React.Fragment>
                                <div style={{marginLeft: 6, marginRight: 6}}>|</div>
                                <div style={{fontStyle: 'italic',}}>{this.props.labelPreCTA}</div>
                                <div onClick={this.props.labelCTAAction} style={{ marginLeft: 6, cursor: 'pointer', fontStyle: 'italic'}} className="underline-hover">{this.props.labelCTA}</div>
                            </React.Fragment>
                        
                        :
                        
                            null 
                        
                        }
                        </div>
                    </div>
                    
                </div>
                {this.props.typeSelector === undefined ? 

                    this.props.append === undefined ?

                    <input 
                        type="text" 
                        onChange={(e) => this.handleChange(e)} 
                        onBlur={this.onBlur}
                        ref={this.props.innerRef}  
                        placeholder={this.props.placeholder}
                        value={this.props.transaction[this.props.label] || ''}
                        className={`light-input basier-p3 ${!this.props.validation[this.props.label] === true ? 'error-input' : null}`} 
                        onKeyDown={this.onKeyDown}
                        style={{width: this.props.fullWidth === true ? '100%' : 250 }}
                        //onKeyDown={(e) => this.onKeyDownInput(e, this.props.label, this.priceTypeRef, this.props.transaction['data'][this.props.transactionId]['transaction_description'])} value={this.props.transaction['data'][this.props.transactionId][this.props.label] || ""} 
                    />

                    :


                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <input 
                                type="text" 
                                onChange={(e) => this.handleChange(e)} 
                                ref={this.props.innerRef}
                                onBlur={this.onBlur}
                                value={this.props.transaction[this.props.label] || ''}
                                className={`light-input basier-p3 ${!this.props.validation[this.props.label] === true ? 'error-input' : null}`} 
                                onKeyDown={this.onKeyDown}
                                style={{paddingRight: 68, height: 40, textAlign: 'right', minWidth: 250, width: 250}} />

                            <div className="basier-p3" style={{position: 'relative', right: 65, zIndex: 9001}}>{this.props.append}</div>
                        </div>

                   
                    :
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        
                        

                        <input 
                        type="text" 
                        onChange={(e) => this.handleChange(e)} 
                        onBlur={this.onBlur}
                        ref={this.props.innerRef}  
                        value={this.props.transaction[this.props.label] || ''}
                        className={`light-input basier-p3 ${!this.props.validation[this.props.label] === true ? 'error-input' : null}`} 
                        onKeyDown={this.onKeyDown}
                        style={{width: 150, borderRadius: '5px 0px 0px 5px', textAlign: 'right', height: 40}}
                        //onKeyDown={(e) => this.onKeyDownInput(e, this.props.label, this.priceTypeRef, this.props.transaction['data'][this.props.transactionId]['transaction_description'])} value={this.props.transaction['data'][this.props.transactionId][this.props.label] || ""} 
                        />

                        <ExtraSelect 
                            width={100} 
                            height={40} 
                            innerRef={this.props.extraRef} 
                            onKeyDown={this.onExtraKeyDown}
                            handleSelect={(val) => this.props.handleExtraSelect(val)}  
                            selection={this.props.extraSelection} 
                            options={this.props.extraOptions} 
                            float='right' />

                
                    </div>

                }
                <div className="basier-p4" style={{marginTop: 2, fontStyle: 'italic', color: !this.props.validation[this.props.label] === true ? 'red' : '#0078DB'}}>
                    { this.props.validateNumber === true ? 
                        !this.props.validation[this.props.label] === true?
                            this.props.transaction[this.props.label] === null || this.props.transaction[this.props.label] === undefined || cleanValue(this.props.transaction[this.props.label]) === '' ? 
                            <span>Field cannot be empty</span>
                            :
                            <span>{this.props.errorText}</span>
                        :
                            <span>{this.props.subText}</span>
                    :
                    <span>{this.props.subText}</span>
                    }
                </div>
            </div>
            
        )
    }


}

const mapStateToProps = state => {
    return {
    };
};

/* const mapDispatchToProps = dispatch => {
}; */


export default connect(mapStateToProps, null)(InputQuestion)
