import React from 'react';
import PropTypes from 'prop-types';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import * as actions from "../../../store/actions/interactions";
import * as appwideActions from "../../../store/actions/appwide";
import * as authActions from "../../../store/actions/auth";
import * as dataActions from "../../../store/actions/data";
import { connect } from 'react-redux';
import BaseModalTop from '../../Portfolio/PortfolioComponents/Transactions/BaseModalTop';
import DateTimePicker from 'react-datetime';
import TypeList from '../../Portfolio/PortfolioComponents/Transactions/NewTransaction/QuestionBlocks/TypeList';
import loadingSpinner from '../../../Media/loadingSpinner.gif';
import CoinSearch from '../../AppWide/CoinSearch';
import okIcon from '../../../Media/okIcon.svg';
import SoloPort from '../../../Media/GroupPort.svg';
import FiatLarge from '../../AppWide/FiatLarge';
import OrgLarge from '../../AppWide/OrgList';
import Collablist from '../../AppWide/CollabList';
import axios from 'axios';
import {updatePortfolios} from '../../../helpers/portfolioHelpers';
import fieldValidator from '../../../helpers/validators';
import { backendUrl } from '../../../variables';
import Oops from '../../../Media/oops.svg';
import { withRouter} from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';


const styles = theme => ({
    container: {
        width: '100%', 
        padding: 48, 
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            padding: 12, 
        },
    },
    image: {
        display: 'block', 
        [theme.breakpoints.down('sm')]: {
            display: 'none', 
        },
    },
    ImageText: {
        [theme.breakpoints.down('sm')]: {
            padding: 12 
        },
    },
    subContainer: {
        padding: 12,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            height: '100%',
        },
        [theme.breakpoints.up('md')]: {
            width: '48%',
            height: '100%',
        }
    },
    secondSub: {
        [theme.breakpoints.up('md')]: {
            height: 350,
        }
    }, 
    secondQuestions: {

        [theme.breakpoints.down('sm')]: {
            padding: 0
        },
        [theme.breakpoints.up('md')]: {
            marginTop: 0
        }

    }, 
    input: {
        
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 24, width: '100%', marginTop: 12, 
        },
        [theme.breakpoints.up('md')]: {
            paddingLeft: 24, width: 324, marginTop: 12, 
        }
    },
    fiatContainer: {
        
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        },
        [theme.breakpoints.up('md')]: {
            width: 324
        }
    }
    });

class ResponsiveDialog extends React.Component {

    state = {
        loading: false,
        success: false, 
        data: {
            "requesting_user": this.props.user_id,
            "user": [{ value: this.props.user_id }], 
            "portfolio_ownership": 'organization',
        }, 
        validation: {
            organization: true, 
            organization_name: true, 
            user: true, 
        }
    }

    updateData = (label, val) => {

        this.setState(state => ({...state, data: {...state.data, [label]: val}}))
    }

    handleClose = () => {

        this.props.toggleModal({type: null, open: false, payload: null})
       
      }
    
    goToAccountSettings = () => {
        this.props.history.push('/settings')
        this.handleClose()
    }
    renderIndividual = (classes) => {
        return ( 
            <div style={{display: 'flex', flexDirection: 'column'}}>

                <div className={classes.image}>
                    <img src={SoloPort} alt="" />
                </div>
                <div style={{marginTop: 0}} className={`basier-p4 ${classes.ImageText}`}>
                    Individual portfolios are great for personal investments. You cannot add collaborators on invididual portfolios... but it is really easy to upgrade them to shared portfolio in the settings later on! 
                </div>

            </div>
        )
    }

    updateServer = () => {

        const users_val = fieldValidator(this.state.data['user'], ['isNotEmpty'])
        this.setState(state => ({...state, validation: {...state.validation, user: users_val}}))

        let org = true
        let org_name = true
        if (this.state.data['portfolio_ownership'] === 'organization') {
            org = fieldValidator(this.state.data['organization'], ['isNotEmpty'])
            this.setState(state => ({...state, validation: {...state.validation, organization: org}}))
        }

        let valid = org

        if (valid) {

        //console.log(this.state)
            this.setState(state => ({...state, loading: true}))
            let headers = { "content-type": "application/json" };
            if (this.props.token !== null && this.props.token !== undefined) {

                headers['Authorization'] = `Token ${this.props.token}`

            }

            const url_call = `${backendUrl}/v1/portfolio/${this.props.data.portfolioId}/`
            const dataSend = {
                ...this.state.data, 
                scope: 'convertToMulti', 
                user_id: this.props.user_id
            }
            axios.post(url_call, dataSend, {headers: headers})
                .then(json => {

                    this.props.fetchUserPreferences({user_id: this.props.user_id, rahat_t: this.props.token});
                    this.props.componentDataFetch('update', 291, this.props.currency, this.props.user_id, this.props.data.portfolioId, null, null, this.props.inBitcoin)
                    this.setState(state => ({...state, loading: false, error: false}))
                    this.handleClose()

                })
                .catch( e => {

                this.setState(state => ({...state, loading: false, error: true}))

                })
        
        }

    }

    addCollab = (collab) => {

        this.setState(state => ({...state, data: {...state.data, user: collab}}))

    }

    switchTransactions = (port) => {

        this.props.toggleModal({type: 'add_transaction', open: true, payload: {overridePort: true, overridePortId: port}})

    }

    render() {

    const { classes } = this.props;

    return (

      <div>
        
          <BaseModalTop forceSingle={true} title="Convert portfolio" handleClose={this.handleClose} onClick={this.backToWelcome} welcome={this.state.welcome} entryType={this.state.type_selected} />


          {

            this.state.loading ? 

          <div style={{width: '100%', height: 530, backgroundColor: 'white', opacity: 0.6, display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'absolute', top: 90, zIndex: 13000}}>
              <img style={{height: 64, with: 64}} src={loadingSpinner} alt="Loading..."/>
          </div>


          :

          this.state.success ? 

            <div style={{width: '100%', height: '100%', backgroundColor: 'white', opacity: 1, display: 'flex', flexDirection: 'column', paddingTop: 100, alignItems: 'center', position: 'absolute', top: 90, zIndex: 13000}}>
                <img style={{height: 64, with: 64}} src={okIcon} alt="OK!"/>
                <div style={{marginTop: 24}} className="basier-p2">
                  Portfolio Converted!
                </div>
            </div>

          :

          null 

          }


          

            <div className={classes.container}>

            <React.Fragment>
                <div className={classes.subContainer}>

                    <div>
                        <div className={classes.secondQuestions}>

                            <div className="basier-p4-caps" style={{paddingLeft: 0, color: 'black', textTransform: 'uppercase', fontWeight: 900}}>
                                Organization
                            </div>

                            <div style={{marginTop: 22}}>
                                <OrgLarge validOrg={this.state.validation.organization} validName={this.state.validation.organization_name} handleChange={(label, val) => this.updateData(label,val)} />
                            </div>

                        </div>

                        

                        <div style={{display: 'flex', flexDirection: 'column'}}>

                            <div>
                                <div style={{marginTop: 24}}>
                                    <div className="basier-p4-caps" style={{paddingLeft: 0, color: 'black', textTransform: 'uppercase', fontWeight: 900, marginBottom: 12}}>
                                        Collaborators
                                    </div>
                                    <div>
                                        <Collablist orgId={this.state.data['organization']} handleAdd={(o) => this.addCollab(o)} />
                                    </div>
                                </div>
                            </div>

                        </div>
                    
                    </div>
                
                </div>

                <div className={classes.subContainer}>

                    <div className={classes.secondSub}>

                    <div className={classes.image}>
                        <img src={SoloPort} alt="" />
                    </div>
                    </div>

                    <div style={{height: 150}}>

                        <div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>

                            <div className='basier' onClick={this.handleClose} 
                                style={{height: 40, borderRadius: 35, marginRight: 12, marginTop: 24, cursor: 'pointer', color: 'white', backgroundColor: '#F9716B', width: 150, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                Cancel
                            </div>

                            <div className='button-next-blue' onClick={() => this.updateServer()} 
                                style={{height: 40, borderRadius: 35, marginLeft: 12,  marginTop: 24, cursor: 'pointer'}}>
                                Submit
                            </div>

                        </div> 

                    </div>

                </div>
            </React.Fragment>

            </div>

            

        </div>

    )
  }
}

ResponsiveDialog.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
};


const mapStateToProps = state => {
    return {

        currencyFiatData: state.data.fiatList,
        modalPref: state.interactions.transactionAddModal,
        portfoliosData: state.data.portfoliosData,
        transaction: state.interactions.transactionAddData,
        all_coins: state.appwide.all_coins, 
        exchangeCheck: state.interactions.exchangeCheck, 
        exchanges: state.appwide.exchanges, 
        wallets: state.appwide.wallets, 
        user_id: state.auth.user_id,
        user_first: state.auth.user_first_name,
        user_last: state.auth.user_last_name,
        token: state.auth.token,
        currentView: state.data.currentView,
        currency: state.auth.currency.id,
        graphData: state.data.globalBalance,
        plan: state.auth.subscription,
        limits: state.appwide.limits,
        inBitcoin: state.data.bitcoinPf, 

    };
};

const mapDispatchToProps = dispatch => {
    return {
       toggleModal: (data) => 
            dispatch(actions.toggleModal(data)), 
        removeTransaction: (data) =>
            dispatch(actions.removeTransaction(data)), 
        setTrTime: (data) => 
            dispatch(actions.setTrTime(data)), 
        setInteraction: (data) => 
            dispatch(actions.setInteraction(data)), 
        getWallets: (user) => dispatch(appwideActions.getWallets(user)), 
        addPort: () => dispatch(appwideActions.addPort()), 
        setPorts: (ports) => dispatch(authActions.setPorts(ports)),
        fetchUserPreferences: (user) => dispatch(authActions.fetchUserPreferences(user)),
        componentDataFetch: (scope, view, currency, user, portfolio, globalGraphData, portfolioGraphData, inBitcoin) =>
            dispatch(dataActions.componentDataFetch(scope, view, currency, user, portfolio, globalGraphData, portfolioGraphData, inBitcoin)), 
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withMobileDialog()(withRouter(withStyles(styles)(ResponsiveDialog))));
