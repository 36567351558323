import React from 'react';
import UserList from '../UserList';

class UserSelect extends React.PureComponent {

    componentDidMount() {   
        this.props.updateTransaction(this.props.label, this.props.user_id)
    }

    componentDidUpdate(prevProps) {

        if (this.props.label !== prevProps.label) {
            this.props.updateTransaction(this.props.label, this.props.user_id)
        }

    }

    handleUserChange = (o) => {

        this.props.updateTransaction(this.props.label, o)

    }

    render () {

        return (

            <div>
                <div className="basier-p4-caps" style={{marginBottom: 2, color: 'black', fontWeight: 900, display: 'flex', alignItems: 'center'}}>
                    <div>{this.props.forceLabel !== undefined ? this.props.forceLabel : 'User'}</div>
                </div>
                <UserList backToPrevious={this.props.backToPrevious} propagateKey={true} portfolioId={this.props.portfolioId} handleChange={(o) => this.handleUserChange(o)} prov={false} userPass={this.props.transaction[this.props.label]} height={40} ref={this.props.innerRef} goToNext={this.props.getNextRef} />

            </div>
            
        )
    }


}

export default UserSelect;
