import React from 'react';
import { connect } from 'react-redux';
import BackIcon from "../../../../../Media/BackIcon.svg";
import * as interActions from "../../../../../store/actions/interactions";
import { backendUrl } from '../../../../../variables';
import {loadData} from '../../../../../helpers/helpers';
import bin from '../../../../../Media/bin.svg';
import axios from 'axios';

class SmallGeneral extends React.PureComponent {

    state = {
        edit: false,
        data: null
    }

    componentDidMount() {

        let headers = { "content-type": "application/json" };
        headers["Authorization"] = `Token ${this.props.token}`;

        const data_fetch = loadData(`${backendUrl}/v1/portfolio/uploads/?p=${this.props.portfolioId}&u=${this.props.user_id}`, headers)
        data_fetch
            .then(json => {

                this.setState(state => ({...state, data: json['data']}))

            })
            .catch(e => {
                console.log(e)
            })



    }

    componentDidUpdate(prevProps) {
        if (prevProps.portfolioId !== this.props.portfolioId) {

            let headers = { "content-type": "application/json" };
            headers["Authorization"] = `Token ${this.props.token}`;

            const data_fetch = loadData(`${backendUrl}/v1/portfolio/uploads/?p=${this.props.portfolioId}&u=${this.props.user_id}`, headers)
            data_fetch
                .then(json => {

                    this.setState(state => ({...state, data: json['data']}))

                })
                .catch(e => {
                    console.log(e)
                })

        }

    }

    deleteUpolad = (id) => {

        const sendData = {
            id: id, 
            user_id: this.props.user_id,
            scope: 'delete'
        }

        let headers = { "content-type": "application/json" };
        if (this.props.token !== null && this.props.token !== undefined) {

            headers['Authorization'] = `Token ${this.props.token}`

        }

        const url_call = `${backendUrl}/v1/portfolio/uploads/`
        
        axios.post(url_call, sendData, {headers: headers})
            .then(json => {

                let newUploads = this.state.data.filter(v => v.id !== id)
                
                this.props.showSnack({message: 'User deleted successfully', variant: "success"})
                this.setState(state => ({...state, data: newUploads}))

            })
            .catch(() => {
                this.props.showSnack({message: 'Error in deleting user from portfolio', variant: "error"})
            })
    }


    deleteHandler = (id) => {
        if(window.confirm("Are you sure you wantto delete this upload and the transactions linked to it?")) {
            this.deleteUpolad(id)
        }
    }


    editName = () => {
        this.setState(state => ({...state, nameEdit: true})) 
    }

    editHandler = () => {
        this.setState(state => ({...state, edit: !state.edit}))     
    }

    goBackAction = () => {
        this.props.goBack()
    }

    componentWillUnmount() {
        document.body.ontouchmove = (e) => { 
            return true;
        }
        this.props.setInteraction({label: 'pin', value: true});
    }

    render () {
        

        return (
            
                <React.Fragment>
                    
                            
                    <div style={{position: 'absolute', top: 0, zIndex: 9999999, width: '100%'}}>

                        <div style={{backgroundColor: '#F8F9FA', zIndex: 9999999, height: 75, width: '100%', display:'flex', alignItems: 'center', position: 'fixed', justifyContent: 'space-between'}} id="responsive-dialog-title">

                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <div onClick={() => this.goBackAction()}>
                                        <img style={{paddingTop: 24}} src={BackIcon} alt=""/>
                                    </div>
                                </div>

                                <div className="basier-p2" style={{color: 'black', fontWeight: 900, textTransform: 'uppercase'}}>Uploads</div>

                                <div>
                                    <div className="basier"  style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end', fontSize: '1.2rem'}}>
                                        
                                        {this.state.edit ? 
                                        
                                        <React.Fragment>
                                            <div className="modalActionButton touchableOpacity"  onClick={this.editHandler} style={{marginRight: 24, color: '#0078DB'}}>Done</div>
                                        </React.Fragment>

                                        :
                                        
                                        <React.Fragment>
                                            <div className="modalActionButton touchableOpacity"  onClick={this.editHandler} style={{marginRight: 24, color: '#0078DB'}}>Edit</div>
                                        </React.Fragment>
                                    
                                        }
                                    </div> 
                                </div>

                        </div>
                        <div style={{position: 'fixed', top: 75, height: 200, width: '100%', zIndex: 1200, backgroundColor: 'white'}} />

                        {this.state.data === null ? 
                        null :

                            <div style={{position: 'absolute', top: 75, zIndex: 120000, backgroundColor: 'white', minHeight: 100, width: '100%'}}>
                                
                                {
                                this.state.data.map((val, key) => {

                                    const language = navigator.language || 'en'
                                    const options = {year: 'numeric', month: 'numeric', day: 'numeric' };
                                    const parseDate = new Date(val.created_at);
                                    const showDate = parseDate.toLocaleDateString(language,options)

                                    return (
                                    <div 
                                        className='coin-flat-list-item-nohover' 
                                        key={key}
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center', 
                                            height: 60,
                                            width: '100%',
                                            marginBottom: '1px solid #E1E5E9',
                                            justifyContent: 'space-between',
                                            }}>
                                        
                                        <div style={{paddingLeft: 12, }}>
                                            <div style={{color: 'black'}} className="basier-p2">
                                            {showDate}
                                            </div>
                                            <div className="basier-p4-caps">
                                                {val.first_name} {val.last_name}
                                            </div>
                                        </div>
                                        
                                        {this.state.edit &&
                                        <div style={{paddingRight: 12}} onClick={() => this.deleteHandler(val.id)}>
                                            <img src={bin} style={{height: 30}} alt="delete" />
                                        </div>}

                                    </div>
                                    )

                                })
                                
                                }

                            </div>
                        }

                    </div>

                </React.Fragment>
            
        )
    }


}

const mapStateToProps = state => {
    return {
        user_id: state.auth.user_id, 
        token: state.auth.token,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        showSnack: (data) => dispatch(interActions.showSnack(data)), 
        setInteraction: (data) => 
            dispatch(interActions.setInteraction(data)), 
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(SmallGeneral)



