import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import axios from 'axios';
import { backendUrl } from '../../variables';
import Hidden from '@material-ui/core/Hidden';
import * as dataActions from "../../store/actions/data";
import {paymentLogos} from '../../helpers/helpers'
import PaymentComponent from '../AppWide/Payments/PaymentWrapper';
import * as authActions from "../../store/actions/auth";
import loadingSpinner from '../../Media/loadingSpinner.gif';
import {loadData} from '../../helpers/helpers';
import InvoiceTable from './InvoiceTable';
import pdfIcon from '../../Media/pdfIcon.svg';
import currencyDict from '../../helpers/currency.json'

const styles = theme => ({
    root: {
        '& label.Mui-focused': {
          color: 'green',
        },
        '& .MuiInput-underline:after': {
          borderBottomColor: 'green',
        },
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: 'red',
          },
          '&:hover fieldset': {
            borderColor: 'yellow',
          },
          '&.Mui-focused fieldset': {
            borderColor: 'green',
          },
        },
      },

      paymentWrap: {
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
        [theme.breakpoints.up('md')]: {
            width: 600,
                
        }

      },

      inputSmall: {
        width: 200,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            marginTop: 12,
        },
        [theme.breakpoints.up('md')]: {
            width: 200,
            marginRight: 12
        }
    },
    inputLarge: {
        width: 412,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            marginTop: 12,
        },
        [theme.breakpoints.up('md')]: {
            width: 412,
            marginRight: 12
        }
    },
    inputsWrapper: {
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    }, 
    buttonWidth: {
        [theme.breakpoints.down('sm')]: {
            width: 130,
        },
    }, 
    buttonWrapper: {
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            marginRight: 0
        },
        [theme.breakpoints.up('md')]: {
            paddingRight: 24
        } 
    }, 
    paddingSmall: {
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 12, paddingRight: 12
        },  
    }
  });

class ProfileContainer extends React.PureComponent {
    
    state = {
        input: "",
    }

    getInvoices = () => {
        
       this.props.getRightInv(this.state.input)

    }


    changeInput = (e) => {
      e.persist();
      this.setState(state => ({...state, input: e.target.value}))
    }
   
    render () {

        const { classes } = this.props;

        return (



                <div style={{marginTop: 0, minHeight: 120, maxWidth: '100vw'}}>
                  Admin functions
                  <div>
                    <input type="text" onChange={this.changeInput} value={this.state.input} />
                  </div>
                  <div onClick={this.getInvoices}>Go</div>
                </div>

                       
        )
    }


}

const mapStateToProps = state => {
    return {
        user_first_name: state.auth.user_first_name, 
        user_last_name: state.auth.user_last_name, 
        token: state.auth.token,
        user_id: state.auth.user_id, 
        payment: state.appwide.payMethods, 
        plan: state.appwide.plan,
        subs: state.auth.subscription
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setCurrentView: (view) => 
            dispatch(dataActions.setCurrentView(view)),
        getRightInv: (id) => dispatch(authActions.getRightInv(id)), 
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ProfileContainer));
