import React from 'react';
import { connect } from 'react-redux';
import ReportList from './ReportList';
import BalanceDate from './ReportComponents/BalanceDate';
import PortfolioReport from './ReportComponents/PortfolioReport';
import ValueAtRisk from './ReportComponents/ValueAtRisk';
import VarVisual from './ReportComponents/Visualizations/VarVisual';
import Correlation from './ReportComponents/Correlation';
import BullBear from '../Analysis/Visuals/BullBear';
import Paraboles from '../Analysis/Visuals/Paraboles';

import BackIcon from '../../../../Media/BackIcon.svg'
import { backendUrl } from '../../../../variables';
import {loadData} from '../../../../helpers/helpers'

import Plus from '../../../../Media/Plus.svg'

import BullBearIcon from '../../../../Media/BullBear.svg'
import valueDateIcon from '../../../../Media/valueDate.svg'
import parabolesIcon from '../../../../Media/parabolesIcon.svg'
import CorrMatrixIcon from '../../../../Media/CorrMatrix.svg'
import varIcon from '../../../../Media/varIcon.svg'
import reportIcon from '../../../../Media/reportIcon.svg'
import axios from 'axios'




const getValue = (dict, val) => {
    let ret = null; 
    try {ret = dict[val]} catch(e) {}
    return ret
}

const reportRender = (id) => {

    switch(id) {
        case 'portfolioReport': 
            return {
                name: "Portfolio Period report",
                description: "Generate a report on your portfolio's movements and performance during a defined period. Useful for tax reporting.", 
                image: reportIcon,
            }
            
        case 'valueAtRisk': 
            return {
                name: "Value at Risk",
                description: "Calculate your portfolio's Value At Risk using a custom Monte Carlo simulation.", 
                image: varIcon,
            }
        default:
            return null
    }

}

const analysisRender = (id) => {
    switch (id) { 
        case 'bullBear': 
            return {
                name: "Bull / Bear Analysis",
                description: "Continuously updated analysis of Bitcoin (BTC) bull and bear market cycles.", 
                image: BullBearIcon,
            }
            
        case 'correlationMatrix': 
            return {
                name: "Correlation Matrix",
                description: "Understand how the coins in your portfolio are correlated to one another.", 
                image: CorrMatrixIcon,
            }
        case 'balanceDate': 
            return {
                name: "Value at date",
                description: "Generate your portfolio value and balances at any date, in any currency. Exportable into CSV.", 
                image: valueDateIcon,
            }
        case 'paraboles': 
            return {
                name: "Paraboles",
                description: "Analyse the frequency and characteristics of parabolic returns for your selected crypto.", 
                image: parabolesIcon,
            }
        case 'varVisual': 
            return {
                name: "Value at Risk",
                description: "Calculate your portfolio's Value At Risk using a custom Monte Carlo simulation.", 
                image: varIcon,
        }
        default: 
            return {}
    }

}



class ReportOverview extends React.PureComponent {

    state = {
        type: 'overview',
        reports: null,
    }

    getData() {
        //this.props.setCurrentView(6)
        //this.props.componentDataFetch('get', 6, null, null, this.props.portfolioId, null, null, this.props.inBitcoin)

        let headers = { "content-type": "application/json" };
        headers["Authorization"] = `Token ${this.props.token}`;

        const prod_url = `${backendUrl}/v1/analysis/?p=${this.props.portfolioId}`
        //const preprod_url = `http://localhost:8000/v1/analysis/?p=${this.props.currentPf}`

        loadData(prod_url, headers)
            .then(json => {
                this.setState({reports: json['data'] })
            })
    } 

    componentDidMount() {
        this.getData()
    }

    requestAnalysis = (data) => new Promise ((resolve, reject) => {

      
        let headers = { "content-type": "application/json" };
        headers["Authorization"] = `Token ${this.props.token}`;

        this.setState(state => ({...state, loading: true}))
        axios.post(`${backendUrl}/v1/reports/`, data, {headers: headers})
            .then(json => {

                resolve(json['data'])
                this.getData()
                
            })
            .catch(e => {

                reject('error')

            })

    })

    fetchAnalysis = (type, target) => new Promise ((resolve, reject) => {

        let headers = { "content-type": "application/json" };
        headers["Authorization"] = `Token ${this.props.token}`;

        this.setState(state => ({...state, loading: true}))
        const data_fetch = loadData(`${backendUrl}/v1/analyses/?u=${this.props.user_id}&scope=${type}&target=${target}`, headers)
        data_fetch
            .then(json => {

                resolve(json['data'])
                
            })
            .catch(e => {

                reject('error')

            })

    })

    handleSelect = (id) => {
        this.setState(state => ({...state, type: id}))
    }

    handleReportSwitch = (type, id) => {

        this.setState(state => ({...state, type: type, selectedId: id}))

    }

    renderType = (type) => {
        switch(type) {

            case 'balanceDate': {
                return <BalanceDate portfolioId={this.props.portfolioId} />
            }
            case 'correlationMatrix': {
                return <Correlation portfolioId={this.props.portfolioId} />
            }
            case 'bullBear': {
                return <BullBear portfolioId={this.props.portfolioId} fetchAnalysis={this.fetchAnalysis} />
            }
            case 'paraboles': {
                return <Paraboles portfolioId={this.props.portfolioId} fetchAnalysis={this.fetchAnalysis} />
            }
            case 'portfolioReport': {
                return <PortfolioReport portfolioId={this.props.portfolioId} requestAnalysis={this.requestAnalysis} backAction={() => this.handleSelect('overview')} />
            }
            case 'valueAtRisk': {
                return <ValueAtRisk portfolioId={this.props.portfolioId} requestAnalysis={this.requestAnalysis} backAction={() => this.handleSelect('overview')} />
            }
            case 'varVisual': {
                return <VarVisual portfolioId={this.props.portfolioId} scopeId={this.state.selectedId} />
            }
            default: 
                return null
        }
    }
    render () {

        return (

            <div style={{margin: 62}}>
            {this.state.type === 'overview' ? 

            <ReportList handleSelect={this.handleSelect} reportRender={reportRender} analysisRender={analysisRender} reports={this.state.reports} renderType={this.handleSelect} handleReportSwitch={this.handleReportSwitch} />
            

            :
            
            <React.Fragment>

                <h3 style={{margin: 0}}>{getValue(analysisRender(this.state.type), 'name')}</h3>
                
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <div style={{marginTop: 24, display: 'flex', alignItems: 'center', cursor: 'pointer', width: 'fit-content'}} onClick={() => this.handleSelect('overview')} className="opacity-hover">
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 12}}>
                            <img src={BackIcon} style={{height: 40, width: 40, marginRight: 12}} alt="" />
                        </div>
                        <div className="basier-p4-caps">Back</div>
                    </div> 
                </div>

                <div style={{minHeight: 550, width: '100%', backgroundColor: 'white', borderRadius: 20, marginTop: 24, display: 'flex', boxShadow: '0px 10px 10px rgba(225, 229, 233, 0.3)', border: '1px solid #E1E5E9', }}>
                    {this.renderType(this.state.type)}
                </div>

            </React.Fragment>
            }
            </div>
            
        )
    }


}

const mapStateToProps = state => {
    return {
        user_id: state.auth.user_id, 
        token: state.auth.token
    };
};

const mapDispatchToProps = dispatch => {
    return {
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(ReportOverview)
