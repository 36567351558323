import React from 'react';
import { connect } from 'react-redux';
import UploadTable from './UploadTable';
import {loadData} from '../../../../../helpers/helpers'
import * as interActions from '../../../../../store/actions/interactions';
import * as appwideActions from '../../../../../store/actions/appwide';
import { backendUrl } from '../../../../../variables'
import axios from 'axios';

class AuditWrapper extends React.PureComponent {

    state = {
        uploadData: undefined
    }

    componentDidMount() {

        let headers = { "content-type": "application/json" };
        headers["Authorization"] = `Token ${this.props.token}`;

        const data_fetch = loadData(`${backendUrl}/v1/portfolio/uploads/?p=${this.props.portfolioId}&u=${this.props.user_id}`, headers)
        data_fetch
            .then(json => {

                this.setState(state => ({...state, uploadData: json['data']}))

            })
            .catch(e => {
                console.log(e)
            })



    }

    componentDidUpdate(prevProps) {
        if (prevProps.portfolioId !== this.props.portfolioId) {

            let headers = { "content-type": "application/json" };
            headers["Authorization"] = `Token ${this.props.token}`;

            const data_fetch = loadData(`${backendUrl}/v1/portfolio/uploads/?p=${this.props.portfolioId}&u=${this.props.user_id}`, headers)
            data_fetch
                .then(json => {

                    this.setState(state => ({...state, uploadData: json['data']}))

                })
                .catch(e => {
                    console.log(e)
                })

        }

    }

    deleteUpolad = (id) => {

        const sendData = {
            id: id, 
            user_id: this.props.user_id,
            scope: 'delete'
        }

        let headers = { "content-type": "application/json" };
        if (this.props.token !== null && this.props.token !== undefined) {

            headers['Authorization'] = `Token ${this.props.token}`

        }

        const url_call = `${backendUrl}/v1/portfolio/uploads/`
        
        axios.post(url_call, sendData, {headers: headers})
            .then(json => {

                let newUploads = this.state.uploadData.filter(v => v.id !== id)
                
                this.props.showSnack({message: 'User deleted successfully', variant: "success"})
                this.setState(state => ({...state, uploadData: newUploads}))
                this.props.getUserLimits(this.props.user_id)
            })
            .catch(() => {
                this.props.showSnack({message: 'Error in deleting user from portfolio', variant: "error"})
            })
    }

    render () {

        return (
            this.state.uploadData !== undefined ? 
            <div>
                <UploadTable data={this.state.uploadData} deleteUpolad={(v) => this.deleteUpolad(v)} portfolioId={this.props.portfolioId} />
            </div>
            :
            null
            
        )
    }


}

const mapStateToProps = state => {
    return {
        token: state.auth.token,
        user_id: state.auth.user_id,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        showSnack: (data) => dispatch(interActions.showSnack(data)), 
        getUserLimits: (user) => dispatch(appwideActions.getUserLimits(user)), 
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(AuditWrapper)
