import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Genral from "./Large/General";
import Collabs from "./Large/Collabs";
import AuditWrapper from "./Large/Audit";
import Upload from "./Large/Upload";
import Errors from "./Large/Errors";

const renderTitle = (id) => {
  switch (id) {
    case "general":
      return "General";
    case "audit":
      return "Audit Trail";
    case "collaborators":
      return "Collaborators";
    case "uploads":
      return "Uploads";
      case "errors":
         return "Errors";
    default:
      return "";
  }
};

class LargeSettings extends React.PureComponent {
  constructor(props) {
    super(props);
    let firstSetting = "general";

    if (
      this.props.selectedSetting !== undefined &&
      this.props.selectedSetting !== null &&
      ["general", "collaborators", "uploads", "audit", "errors"].includes(
        this.props.selectedSetting.toLowerCase()
      )
    ) {
      firstSetting = this.props.selectedSetting;
    }
    this.state = {
      selectedIndex: firstSetting,
    };
  }

  componentDidUpdate(prevProps) {
     if (this.props.selectedSetting !== prevProps.selectedSetting) {
        this.setState(state => ({...state, selectedIndex: this.props.selectedSetting}))
     }
  }

  changeMenu = (val) => {
    this.setState({ selectedIndex: val });
  };

  render() {
    return (
      <div
        style={{
          top: "130px",
          width: "100%",
          backgroundColor: "white",
          padding: 48,
          paddingBottom: 12,
        }}
      >
        <h3>Settings</h3>

        <div
          style={{
            height: 550,
            width: "100%",
            backgroundColor: "white",
            borderRadius: 20,
            marginTop: 24,
            display: "flex",
          }}
        >
          <div
            className="basier-p3"
            style={{
              width: 200,
              minWidth: 165,
              height: "100%",
              borderRight: "1px solid #E1E5E9",
              paddingTop: 24,
              textTransform: "uppercase",
            }}
          >
            {["general", "collaborators", "audit", "uploads", "errors"].map((v, k) => {
              const title = renderTitle(v);
              return (
                <div
                  onClick={() => this.changeMenu(v)}
                  className={`${
                    this.state.selectedIndex === v
                      ? ""
                      : "blue-bk-hover opacity-hover"
                  }`}
                  style={{
                    height: 40,
                    marginTop: 6,
                    marginBottom: 6,
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <div
                    className="identifyer"
                    style={{
                      height: "100%",
                      width: 8,
                      backgroundColor:
                        this.state.selectedIndex === v ? "#0078DB" : "white",
                    }}
                  ></div>

                  <div
                    className={`${
                      this.state.selectedIndex === v
                        ? ""
                        : "blue-hover opacity-hover"
                    }`}
                    style={{
                      paddingLeft: 12,
                      color:
                        this.state.selectedIndex === v ? "#0078DB" : "#6A7E93",
                      fontWeight: this.state.selectedIndex === v ? 900 : 400,
                    }}
                  >
                    {title}
                  </div>
                </div>
              );
            })}
         </div>

          <div
            style={{
              paddingLeft: 48,
              paddingRight: 48,
              paddingBottom: 36,
              paddingTop: 12,
              width: "100%",
            }}
          >
            {this.state.selectedIndex === "general" && (
              <div style={{ width: "100%" }}>
                <Genral
                  portfoliosData={this.props.portfoliosData}
                  portfolioId={this.props.portfolioId}
                />
              </div>
            )}

            {this.state.selectedIndex === "collaborators" && (
              <div style={{ width: "100%" }}>
                <Collabs
                  portfoliosData={this.props.portfoliosData}
                  portfolioId={this.props.portfolioId}
                />
              </div>
            )}

            {this.state.selectedIndex === "audit" && (
              <div style={{ width: "100%" }}>
                <AuditWrapper portfolioId={this.props.portfolioId} />
              </div>
            )}

            {this.state.selectedIndex === "uploads" && (
              <div style={{ width: "100%" }}>
                <Upload portfolioId={this.props.portfolioId} />
              </div>
            )}

            {this.state.selectedIndex === "errors" && (
              <div style={{ width: "100%", height: '100%', overflowY: 'scroll' }}>
                 <Errors portfolioId={this.props.portfolioId} />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(LargeSettings));
