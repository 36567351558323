import React from 'react';
import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import * as actions from "../../../../../store/actions/auth";
import loadingSpinner from '../../../../../Media/loadingSpinner.gif'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
/* import HighchartsReact from 'highcharts-react-official'*/
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {nFormatter} from '../../../../../helpers/helpers';
import { backendUrl } from '../../../../../variables';
import {loadData} from '../../../../../helpers/helpers'


/* (function(H) {
    H.Pointer.prototype.reset = function() {
      return undefined;
    };
  
    H.Point.prototype.highlight = function(event) {
      event = this.series.chart.pointer.normalize(event);
      this.onMouseOver(); // Show the hover marker
      //this.series.chart.tooltip.refresh(this); // Show the tooltip
      this.series.chart.xAxis[0].drawCrosshair(event, this); // Show the crosshair
    };
  
    H.syncExtremes = function(e) {
      var thisChart = this.chart;
  
      if (e.trigger !== "syncExtremes") {
        // Prevent feedback loop
        Highcharts.each(Highcharts.charts, function(chart) {
          if (chart && chart !== thisChart) {
            if (chart.xAxis[0].setExtremes) {
              // It is null while updating
              chart.xAxis[0].setExtremes(e.min, e.max, undefined, false, {
                trigger: "syncExtremes"
              });
            }
          }
        });
      }
    };
  })(Highcharts); */

const styles = {
    root: {
      color: '#6A7E93',
      '&$checked': {
        color: '#0078DB',
      },
    },
    checked: {
        color: '#0078DB'
    },
  };


class BalanceGraph extends React.PureComponent {

    constructor(props) {

        super(props);
        this.state = {
            dateSelected: null,
            chart: null,
            data: null
        }

    }

    

    render () {

    
    const component = this
    const { classes } = this.props;

    const options = {
        chart: {
            type: 'line', 
            backgroundColor: 'white',  
            animation: true,
            height: 600,
            events: {
                load: function() {
                  
                  component.setState(state => ({...state, chart: this}))
                  // legend box

                  component.props.data.likely_bull.map(data => {
                    
                    let first = Date.UTC(component.props.data.start_year, component.props.data.start_month -1 , component.props.data.start_day)
                    let start_dater = new Date(first)
                    let stop_dater = new Date(first)
                    let start = start_dater.setMonth(start_dater.getMonth() + data[0])
                    let stop = stop_dater.setMonth(stop_dater.getMonth() + data[1])

                    let options = {
                        color: "rgba(76, 218, 193, 0.25)", 
                        from: start, 
                        to: stop, 
                        label: {
                            text: "Bull?", 
                            style: {
                                color: '#6A7E93', 
                                fontSize: 14,
                                marginTop: 12,
                            }
                        },
                    }
                    this.xAxis[0].addPlotBand(options)

                })

                component.props.data.bull_stops.map(data => {
                    
                    let first = Date.UTC(component.props.data.start_year, component.props.data.start_month -1 , component.props.data.start_day)
                    let start_dater = new Date(first)
                    let stop_dater = new Date(first)
                    let start = start_dater.setMonth(start_dater.getMonth() + data[0])
                    let stop = stop_dater.setMonth(stop_dater.getMonth() + data[1])

                    let options = {
                        color: "rgba(76, 218, 193, 0.25)", 
                        from: start, 
                        to: stop, 
                        label: {
                            text: "Bull", 
                            style: {
                                color: '#6A7E93', 
                                fontSize: 14,
                                marginTop: 12,
                            }
                        },
                    }
                    this.xAxis[0].addPlotBand(options)

                })

                component.props.data.bear_stops.map(data => {
                    
                    let first = Date.UTC(component.props.data.start_year, component.props.data.start_month -1 , component.props.data.start_day)
                    let start_dater = new Date(first)
                    let stop_dater = new Date(first)
                    let start = start_dater.setMonth(start_dater.getMonth() + data[0])
                    let stop = stop_dater.setMonth(stop_dater.getMonth() + data[1])

                    let options = {
                        color: "rgba(249, 113, 107, 0.25)", 
                        from: start, 
                        to: stop, 
                        label: {
                            text: "Bear", 
                            style: {
                                color: '#6A7E93', 
                                fontSize: 14,
                                marginTop: 12,
                            }
                        },
                    }
                    this.xAxis[0].addPlotBand(options)

                })

                component.props.data.likely_bear.map(data => {
                    
                    let first = Date.UTC(component.props.data.start_year, component.props.data.start_month -1 , component.props.data.start_day)
                    let start_dater = new Date(first)
                    let stop_dater = new Date(first)
                    let start = start_dater.setMonth(start_dater.getMonth() + data[0])
                    let stop = stop_dater.setMonth(stop_dater.getMonth() + data[1])

                    let options = {
                        color: "rgba(249, 113, 107, 0.25)", 
                        from: start, 
                        to: stop, 
                        label: {
                            text: "Bear?", 
                            style: {
                                color: '#6A7E93', 
                                fontSize: 14,
                                marginTop: 12,
                            }
                        },
                    }
                    this.xAxis[0].addPlotBand(options)

                })


                }
            }
        },
        credits: false,
        title: false,
        xAxis: {
            className: 'basier-p4-caps',    
            minorGridLineWidth: 0,
            gridLineWidth: 0,
            type: 'datetime',
            minPadding:0,
            maxPadding:0,
            tickLength: 0,
            tickColor: 'red',
            labels: {
                padding: 30,
                style: {
                    fontSize: '12px',
                },
            },  

        },
        yAxis: [{
            title: {
              text: 'Bitcoin Price (USD)'
              
            },
            className: 'basier-p4 graph-labels-x',
            lineWidth: 0,
            gridLineColor: 'white',
          }],
        
        plotOptions:{
            series:{
                allowPointSelect: true, 
                point:{
                animation: false, 
                marker: {
                    enabledThreshold: 3
                },
            }
        }
    },

        series: [

            {
                name: 'Bitcoin',
                data: this.props.data.daily_btc,
                pointStart: Date.UTC(this.props.data.start_year, this.props.data.start_month -1 , this.props.data.start_day),
                pointIntervalUnit: 'day',
                showInLegend: false, 
                color: 'black',
                lineWidth: 3,
                marker: {
                    lineWidth: 2,
                    }
            },

        ],
        legend: {
            enabled: false,
        },
        navigator: {
            enabled: false
        },
        scrollbar: {
            enabled: false
        },
        exporting: {
            enabled: false,
        },
        rangeSelector: {
            enabled: false, 
            buttonPosition: {
                align: 'left', 
            }, 
            inputEnabled: false, 
            buttonSpacing: 12, 
            buttonTheme: { // styles for the buttons
                fill: 'none',
                stroke: 'none',
                'stroke-width': 0,
                r: 8,
                style: {
                    color: '#6A7E93',
                    fontSize: 16, 
                },
                states: {
                    hover: {
                        fill: 'none',
                        style: {
                            color: '#0078DB'
                        },
                    },
                    select: {
                        fill: 'none',
                        style: {
                            color: '#0078DB',
                            fontWeight: 'bold',
                        }
                    }, 
                    // disabled: { ... }
                }
            },
           
            labelStyle: {
                color: 'white',
                display: 'none',
            },
            scrollbar: {
                enabled: false,
            },
        },
      
        tooltip: {
            //followPointer: false,
            floating: false,
            padding:12,
            useHTML: true,
            shared: true,
            split: false,
            shadow: false,
            borderWidth: 0,
            backgroundColor: 'rgba(255,255,255,0.8)',
            formatter: function () {
                let bitcoin = null;
                
                const data = this.points
                bitcoin = data.slice(this.points.length - 1, )[0]
                
                return (


                    `

                    <div>
                        <div class="tooltip-date-corr basier">${Highcharts.dateFormat('%e %b %Y',new Date(this.x))}</div>
                        <div class="corr-divider divider-padding"></div>
                        ${data.slice(0, data.length).map((value, index) => {
                            return `<div key=${index} class="basier" style="text-transform: uppercase; display: flex; font-size: 14px; padding-bottom: 14px; align-items: center;"> <span style="font-weight: 900; color: black">${value.series.name} price:</span> &nbsp; ${value.y.toLocaleString('en', {minimumFractionDigits: 0, maximumFractionDigits: 0})} $</div>`
                        }).join('')}
                    </div>

                    `
                   
                )
    
    
                },
    
            },

           

        
        
    }    

        return (

            <div style={{width: '100%'}}>
                    <div style={{width: '100%'}}>

                        <div id="correl_container" style={{width: '100%', marginLeft: 0, position: 'relative', display: 'flex'}}>
                            
                                <div style={{display: 'flex', alignItems: 'center', height: 600, flex: 1, width: '100%'}}>

                                    <HighchartsReact
                                        highcharts={Highcharts}
                                        constructorType={'stockChart'}
                                        options={options}
                                        style={{width: 450}}
                                        oneToOne={true}
                                        containerProps={{ style: { height: "600px", width: '100%' } }}
                                    />

                                    <div style={{width: 600, height: '100%', overflow: 'scroll', marginLeft: 12, borderLeft: '1px solid #E1E5E9'}}>

                                        <div style={{marginRight: 24, display: 'flex', flexDirection: 'column-reverse'}}>

                                            {
                                                this.props.data.period_data.map((data, index) => {
                                                
                                                const pct_change_intraday = data.type === 'bull' || data.type === 'likely bull'? data.max_pct_intraday : (1 - data.max_pct_intraday)
                                                const pct_change_period = data.type === 'bull'  || data.type === 'likely bull' ? data.max_pct_period : (1 - data.max_pct_period)

                                                return (

                                                    <div key={index} 
                                                        style={{
                                                            width: '100%', padding: 24, borderRadius: 20,
                                                            marginLeft: 12, marginBottom: 12,
                                                            boxShadow: '0px 10px 10px rgba(225, 229, 233, 0.3)', 
                                                            height: 380, position: 'relative',

                                                        }}
                                                        className={`${data.type === 'bull' || data.type === 'likely bull' ? "bull-container" : "bear-container"}`}>
                                                            <div 
                                                                className="bb_container-title basier"
                                                                style={{color: `${data.type === 'bull' || data.type === 'likely bull' ? '#4CDAC1' : '#F9716B' }`}}
                                                                >
                                                                {data.type === 'bull'  || data.type === 'likely bull' ? "Bull" : "Bear"}
                                                            </div>

                                                        <p className="basier-p2" style={{textTransform: 'uppercase', fontWeight: 900}}>{data.period}</p>
                                                        <div className="basier-p4-caps" style={{marginBottom: 6}}>
                                                            <span style={{fontWeight: 900}}>Number of days | </span>
                                                            <span>{data.number_of_days}</span>
                                                        </div>

                                                        <div className="basier-p4-caps" style={{marginBottom: 6}}>
                                                            <span style={{fontWeight: 900}}>Daily {data.type === 'bull' || data.type === 'likely bull' ? 'upside' : 'downside'} | </span>
                                                            <span>{ (pct_change_intraday * 100).toLocaleString('en', {maximumFractionDigits: 0, minimumFractionDigits: 0}) }%</span>
                                                        </div>

                                                        <div className="basier-p4-caps" style={{marginBottom: 6}}>
                                                            <span style={{fontWeight: 900}}>Macro {data.type === 'bull' || data.type === 'likely bull' ? 'upside' : 'downside'} | </span>
                                                            <span>{ (pct_change_period * 100).toLocaleString('en', {maximumFractionDigits: 0, minimumFractionDigits: 0}) }%</span>
                                                        </div>

                                                        <div className="basier-p4-caps" style={{marginBottom: 6}}>
                                                            <span style={{fontWeight: 900}}>daily top / bottom | </span>
                                                            <span>${data.max_price_intraday.toLocaleString('en', {maximumFractionDigits: 0, minimumFractionDigits: 0})}</span>
                                                            <span> / </span>
                                                            <span>${data.min_price_intraday.toLocaleString('en', {maximumFractionDigits: 0, minimumFractionDigits: 0})}</span>
                                                        </div>

                                                        <div className="basier-p4-caps" style={{marginBottom: 6}}>
                                                            <span style={{fontWeight: 900}}>Macro top / bottom | </span>
                                                            <span>${data.max_price_period.toLocaleString('en', {maximumFractionDigits: 0, minimumFractionDigits: 0})}</span>
                                                            <span> / </span>
                                                            <span>${data.min_price_period.toLocaleString('en', {maximumFractionDigits: 0, minimumFractionDigits: 0})}</span>
                                                        </div>

                                                        <div className="basier-p4-caps" style={{marginBottom: 6}}>
                                                            <span style={{fontWeight: 900}}>Min daily vol. | </span>
                                                            <span>${nFormatter(data.min_volume_intraday, 2)}</span>
                                                        </div>

                                                        <div className="basier-p4-caps" style={{marginBottom: 6}}>
                                                            <span style={{fontWeight: 900}}>Max daily vol. | </span>
                                                            <span>${nFormatter(data.max_volume_intraday, 2)}</span>
                                                        </div>

                                                        <div className="basier-p4-caps" style={{marginBottom: 6}}>
                                                            <span style={{fontWeight: 900}}>Min macro vol. | </span>
                                                            <span>${nFormatter(data.min_volume_period, 2)}</span>
                                                        </div>

                                                        <div className="basier-p4-caps" style={{marginBottom: 6}}>
                                                            <span style={{fontWeight: 900}}>Max macro vol. | </span>
                                                            <span>${nFormatter(data.max_volume_period, 2)}</span>
                                                        </div>

                                                        <div className="basier-p4-caps" style={{marginBottom: 6}}>
                                                            <span style={{fontWeight: 900}}>Min daily marketcap. | </span>
                                                            <span>${nFormatter(data.min_mk_intraday, 2)}</span>
                                                        </div>

                                                        <div className="basier-p4-caps" style={{marginBottom: 6}}>
                                                            <span style={{fontWeight: 900}}>Max daily marketcap. | </span>
                                                            <span>${nFormatter(data.max_mk_intraday, 2)}</span>
                                                        </div>

                                                        <div className="basier-p4-caps" style={{marginBottom: 6}}>
                                                            <span style={{fontWeight: 900}}>Min macro marketcap. | </span>
                                                            <span>${nFormatter(data.min_mk_period, 2)}</span>
                                                        </div>

                                                        <div className="basier-p4-caps" style={{marginBottom: 6}}>
                                                            <span style={{fontWeight: 900}}>Max macro marketcap. | </span>
                                                            <span>${nFormatter(data.max_mk_period, 2)}</span>
                                                        </div>




                                                    </div>

                                                )
                                                
                                                })
                                            
                                            
                                            }

                                        </div>
                                    
                                    </div>

                                </div>

                        </div>

                    </div>

                </div>
            
            
        )
    }


        
};

BalanceGraph.propTypes = {
    classes: PropTypes.object.isRequired,
  };
  

const mapStateToProps = state => {
    return {
        currency_symbol: state.auth.currency.symbol,
        currency_name: state.auth.currency.id,
        currentView: state.data.currentView,
        inBitcoin: state.data.bitcoinPf

    };
};

const mapDispatchToProps = dispatch => {
    return {
        setBtcSymbol: (s) =>
            dispatch(actions.setBtcSymbol(s)), 
        
    };
  };

export default connect(
    mapStateToProps,
    mapDispatchToProps
)( withStyles(styles)(BalanceGraph));


