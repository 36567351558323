import React from "react";
import { FixedSizeList as List } from "react-window";
import CloseIcon from '../../Media/CloseIcon.svg'
import Grid from '@material-ui/core/Grid';



class TypeFlatList extends React.PureComponent {

    state = {
        initialItems: [], 
        items: []
    }

    escapeRegexCharacters = (str) => {
        return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
      }

      getListSize = () => {
          return this.state.items.length
      }
      
      getSuggestions = (event) => {
        const value = event.target.value

        const escapedValue = this.escapeRegexCharacters(value.trim());

        if (escapedValue === '') {
           
          this.setState(state => ({...state, items: this.state.initialItems}))
          return [];
        }
      
        const regex = new RegExp('\\b' + escapedValue, 'i');
        
        const filteredList = this.state.initialItems.filter(type => regex.test(this.getSuggestionValue(type)));
        this.setState(state => ({...state, items: filteredList})) 

      }

      getSuggestionValue  = (suggestion) => {
        return `${suggestion.label}`;
      }

      selectType = (val) => {
        this.props.handleSelect(val)
      }
    
      componentDidMount() {

        const transaction_types = {
            "fiat": [
                {ix: 2, value: 'buy', label: 'Buy', helper: 'Use this for buying fiat. Usually done to exchange fiat against fiat.'},
                {ix: 3, value: 'sell', label: 'Sell', helper: 'Use this for selling fiat. Usually done to exchange fiat against fiat.'},
                {ix: 4, value: 'fee', label: 'Fee', helper: 'Use this to enter any fees you may have paid. This could be for example if you forgot to add deposit fees or if your bank took transfer fees.'},
                {ix: 5, value: 'lost', label: 'Loss', helper: 'Use this if you lost fiat or got hacked. This will decrease your crypto balance and show as a net loss.'},
            ], 
            "crypto" : [
                {ix: 1, value: 'ico', label: 'ICO', helper: 'Use this for ICO purchases'},
                {ix: 2, value: 'transfer', label: 'Transfer', helper: 'Use this for transfer of crypto - usually to and from wallets or between exchanges. Overall balance will not change, but any transfer fees will be taken into account.'},
                {ix: 3, value: 'mining', label: 'Rewards', helper: 'Use this to enter any crypto rewards gained through mining or staking. You can also input the fees linked to the activity.'},
                {ix: 4, value: 'fee', label: 'Fee', helper: 'Use this to enter any fees you may have paid. This could be for example for mining, or if you forgot to add trading fees or transfer fees at any point.'},
                {ix: 5, value: 'dividend', label: 'Dividend', helper: 'Use this to enter any crypto gained through dividend schemes.'},
                {ix: 6, value: 'income', label: 'Income', helper: 'Use this to enter any crypto received as income.'},
                {ix: 7, value: 'gift', label: 'Gift', helper: 'Use this to enter any crypto received as a gift.'},
                {ix: 8, value: 'airdrop', label: 'Airdrop', helper: 'Use this to enter any crypto received as airdrops.'},
                {ix: 9, value: 'fork', label: 'Fork', helper: 'Use this to enter any crypto received through a fork.'},
                {ix: 10, value: 'purchase', label: 'Purchase', helper: 'Use this for spending crypto on non-crypto/fiat purchases (e.g. buying a pizza with crypto). This will decrease your crypto balance.'},
                {ix: 11, value: 'donation', label: 'Donation', helper: 'Use this if you donated crypto. This will decrease your crypto balance.'},
                {ix: 12, value: 'lost', label: 'Loss', helper: 'Use this if you lost crypto or got hacked. This will decrease your crypto balance and show as a net loss.'},
            ]
        } 

        this.setState(state => ( {...state, initialItems: transaction_types[this.props.scope], items: transaction_types[this.props.scope] } ))
        
      }

    componentDidUnmount() {
        document.body.ontouchmove = (e) => { 
            return true;
        }
      }

    componentDidUpdate() {
        document.body.ontouchmove = (e) => { 
            return true;
        }
    }

      clearInput = () => {
          document.getElementById('coin-search').value = '';
          this.setState(state => ( {...state, items: this.state.initialItems}))
      }

      Row = ({ data, index, style }) => {
        // Data passed to List as "itemData" is available as props.data
        const item = data[index]
        return (
            <div style={{...style, 
                paddingLeft: 24, 
                paddingRight: 24, 
                }} 
                value={item.value}
                className="coin-flat-list-item basier-p2"
                onClick={() => this.selectType(item.value)}>

                <div>
                    <div className="basier-p2" style={{fontWeight: 900, }}>{item.label}</div>
                </div>

            </div>
        );
    };


      render() {

        const heightVitrual = window.innerHeight - 75; 
        const widthVirtual = window.innerWidth;
        const itemHeight = 50

        return (
            <div className="filter-list">

            <div style={{backgroundColor: '#F8F9FA', 'boxShadow': 'none', height: 75}}>
                <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                > 
        
                <Grid xs={2} sm={2} style={{zIndex: 1500}} className="over-all">
                  <img style={{marginLeft: '12px', paddingTop: '12px'}} src={CloseIcon} alt="" onClick={this.props.closeAction}/> 
                </Grid>
                <Grid xs={7} sm={7} style={{zIndex: 1500}} className="over-all">
                    <input 
                        id="coin-search"
                        autocorrect="off"
                        autocomplete="off"
                        type="text" placeholder="Search" onChange={this.getSuggestions} className="mobile-coin-input"/>
                </Grid>
                <Grid xs={3} sm={3} style={{zIndex: 1500}} className="over-all">
                  <div style={{marginLeft: 24}} className="basier-p2" onClick={this.clearInput}>Clear</div>
                </Grid>

              </Grid>
            </div>
            
            {
                this.state.items !== undefined ? 

                <List
                    height={heightVitrual}
                    itemCount={this.state.items.length}
                    itemData={this.state.items}
                    itemSize={itemHeight}
                    width={widthVirtual}
                    >
                    {this.Row}
                </List>
        
            : 
            
            null 
            
            }
                
              
          </div>
        )
      }

}

export default TypeFlatList