import React from 'react';
import Grid from '@material-ui/core/Grid';
import {connect} from 'react-redux';
import AssetTable from './AssetTableStraight';
import Hidden from '@material-ui/core/Hidden';
import AssetModal from '../../AssetModal';
import Plus from '../../../../../Media/Plus.svg'
import * as actions from '../../../../../store/actions/interactions'
import DetailTabs from '../DetailTabs'
import Stats from '../Stats/Stats'
import worker from "../../../../../workers/balancesWorker";
import WebWorker from "../../../../../workers/WebWorker";
import StorageStats from '../Stats/StorageStats'
import listIcon from '../../../../../Media/listIcon.svg'
import LargeAssetTable from './LargeAssetTable';
import LargePositionsTable from './OverviewPositionsLarge';
import SmallAssetTable from './SmallAssetTable';
import ListHeaders from './ListHeaders';
import DefiOverview from "./Defi/DefiOverview";
import NFTOverview from "./NFT/NFTOverview";
import { getValue } from '../../../../../helpers/helpers';

class AssetList extends React.PureComponent {

    state = {
        active: [],
        small: [],
        exited: [],
    }

    toggleModal = () => {
        //props.toggleTransactionAddModal({open: true});
        this.props.toggleModal({type: 'add_transaction', open: true, payload: {}})
    }

    

    componentDidMount() {

        

        this.worker = new WebWorker(worker);

        this.worker.addEventListener('message', event => {
            const data = event.data
            const active = data[0]
            const small = data[1]
            const exited = data[2]

            this.setState(state => ({
                ...state,
                active: active,
                small: small,
                exited: exited,
            }))

        });

        this.worker.postMessage([this.props.portfoliosData[this.props.portfolioId].balances, this.props.portfoliosData[this.props.portfolioId].total_value])

    }

    componentDidUpdate(prevProps) {

        
        if (prevProps.portfoliosData[this.props.portfolioId] !== undefined && prevProps.portfoliosData[this.props.portfolioId].balances !== this.props.portfoliosData[this.props.portfolioId].balances) {


            this.worker = new WebWorker(worker);

            this.worker.addEventListener('message', event => {
                const data = event.data
                const active = data[0]
                const small = data[1]
                const exited = data[2]

                this.setState(state => ({
                    ...state,
                    active: active,
                    small: small,
                    exited: exited,
                }))

            });

            this.worker.postMessage([this.props.portfoliosData[this.props.portfolioId].balances, this.props.portfoliosData[this.props.portfolioId].total_value])

        }
    }

    render() {


        return (

            <div>
                <Hidden smDown>

                    <div className='title-asset-sticky' style={{width: '100%'}}>
                        <div style={{
                            paddingLeft: 0,
                            marginBottom: 0,
                            width: '100%',
                            paddingTop: 25,
                            display: 'flex',
                            alignItems: 'center'
                        }}>
                            <h3 style={{marginBottom: 6}}>Portfolio details</h3>
                            {this.props.roles[this.props.portfolioId] === 'admin' || this.props.roles[this.props.portfolioId] === 'manager' ?
                                <>
                        <span onClick={() => this.toggleModal()} className="basier-p4-caps"
                              style={{userSelect: 'none', color: 'black', marginLeft: 24, cursor: 'pointer'}}>
                            <span><img src={Plus} alt="" style={{marginRight: 12}}/></span>
                            <span>Add a Transaction</span>
                        </span>
                                </>

                                :
                                null
                            }
                            <div style={{justifySelf: 'flex-end', marginLeft: 'auto', marginRight: 0}}>
                                <DetailTabs value={this.props.value} handleChange={this.props.handleTabChange}/>
                            </div>
                        </div>

                        {this.props.value !== 2 && this.props.value !== 3 &&
                        <div style={{
                            display: 'flex',
                            alignItems: 'flex-end',
                            backgroundColor: 'white',
                            'boxShadow': 'none',
                            height: 30,
                            paddingBottom: 12,
                            marginTop: 30,
                            marginBottom: 12,
                            borderBottom: '1px solid #E1E5E9',
                            width: '100%'
                        }}>
                            <ListHeaders scope={this.props.value}/>

                        </div>
                        }

                    </div>
                    <div style={{width: '100%'}}>
                        {this.props.value === 0 &&
                        <LargeAssetTable portfoliosData={this.props.portfoliosData} portfolioId={this.props.portfolioId}
                                         active={this.state.active} small={this.state.small}
                                         exited={this.state.exited}/>
                        }
                        {this.props.value === 1 &&
                        <LargePositionsTable portfoliosData={this.props.portfoliosData}
                                             portfolioId={this.props.portfolioId}/>
                        }
                        {this.props.value === 2 &&
                        <DefiOverview portfoliosData={this.props.portfoliosData} portfolioId={this.props.portfolioId}/>
                        }
                        {this.props.value === 3 &&
                        <NFTOverview portfolioId={this.props.portfolioId}/>
                        }
                    </div>

                </Hidden>

                <Hidden mdUp>

                    <div style={{width: '100%'}}>
                        <SmallAssetTable portfoliosData={this.props.portfoliosData} portfolioId={this.props.portfolioId}
                                         active={this.state.active} small={this.state.small}
                                         exited={this.state.exited}/>
                    </div>

                </Hidden>

                {this.props.renderModal && this.props.portfoliosData !== undefined ?
                    <AssetModal portfolioId={this.props.portfolioId} coin_in_scope={this.props.scopeAvailable}/> : null}


            </div>


        )

    }

}

const mapStateToProps = state => {
    return {
        currency_symbol: state.auth.currency.symbol,
        portfoliosData: state.data.portfoliosData,
        renderModal: state.interactions.assetModal.open,
        scopeAvailable: state.interactions.assetModal.coin_in_scope,
        roles: state.auth.roles,
        
    };
};

const mapDispatchToProps = dispatch => {
    return {
        toggleModal: (data) =>
            dispatch(actions.toggleModal(data)),
        removeTransaction: (data) =>
            dispatch(actions.removeTransaction(data)),
        setTrTime: (data) =>
            dispatch(actions.setTrTime(data)),
        setInteraction: (data) =>
            dispatch(actions.setInteraction(data)),
    };
};


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AssetList);

