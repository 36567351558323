import React from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Divider from '@material-ui/core/Divider';



class CustomizedExpansionPanels extends React.PureComponent {
  //const [expanded, setExpanded] = React.useState('panel1');
  state = {
    open: false,
  }
  toggleOpen = () => {
    this.setState(state => ({...state, open: !state.open}))
  }
  render() {
  return (

      

      <div>
        <div className="noselect" onClick={this.props.error ? null : this.toggleOpen} style={{height: 50, display: 'flex', alignItems: 'center', justifyContent: 'space-between', cursor: this.props.error ? 'default' : 'pointer'}}>
          <div><span className="basier-p2" style={{ color: this.props.error ? '#F9716B' : null, fontWeight: this.props.error ? 900 : 400 }}>{this.props.title}</span> {!this.props.error && <span style={{marginLeft:4}} className="basier-p4">(click to toggle view)</span>}</div>
          {!this.props.error && <ExpandMoreIcon />}
        </div>
        {
        (this.state.open || this.props.error === true) ? 

        this.props.children
        :
        null 
        }
      {!this.state.open && !this.props.error && <Divider />}
      </div>
      
  );
}
}

export default CustomizedExpansionPanels