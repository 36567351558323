import React from 'react';
import { connect } from 'react-redux';
import OriginList from '../QuestionBlocks/OriginList';

class DestinationTypeQuestion extends React.PureComponent {

    changeOrigin = (v) => {
        this.props.updateState('origin', v)

        if (v === 'wallet') {
            this.props.updateTransaction('exchange', undefined)
            this.props.updateTransaction('account_name', undefined)
            this.props.updateTransaction('account', undefined)
            
        } else {
            
            this.props.updateTransaction('storage', undefined)
            this.props.updateTransaction('storage_name', undefined)
            
        }
    }
    render () {

        return (

            <div>
                <div className="basier-p4-caps" style={{marginBottom: 2, color: 'black', fontWeight: 900, display: 'flex', alignItems: 'center'}}>
                    Wallet type
                </div>

                <OriginList selection={this.props.origin} changeOrigin={(v) => this.changeOrigin(v)} height={40} innerRef={this.props.innerRef} />

            </div>
            
        )
    }


}

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = dispatch => {
    return {
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(DestinationTypeQuestion)
