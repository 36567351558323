import React from 'react';
import Select, { components }  from 'react-select';
import { connect } from 'react-redux';


const colourStyles = {
  control: styles => ({ ...styles, backgroundColor: 'transparent', width: '100%', fontSize: 14, height: 42, border: '0px', color: 'black', cursor: 'text', dispay:'inline-block'}),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {

    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected ? 'white' : isFocused ? '#F3F5F6' : null,
      color: isDisabled
        ? '#F3F5F6'
        : isSelected ? 'black' : 'black',
      opacity: isDisabled
        ? '1'
        : isSelected ? '1' : '0.7',
    };
  },

};

const ValueContainer = ({ children, ...props }) => {
  const { getValue, hasValue } = props;
  const value = getValue();

  if (!hasValue) {
    return (
      <components.ValueContainer {...props}>
        {children}
      </components.ValueContainer>
    );
  }
  return (
    <components.ValueContainer {...props}>
      
      <div style={{textAlign: 'left', display: 'flex', alignItems: 'center', width: '100%',}}>
 
                <img src={value[0].image} style={{height: 30, width: 30}} alt=""/>
                <div style={{marginLeft: 12}}>{value[0].label}</div>

        </div>

    </components.ValueContainer>
  );
};

const Option = props => {
  const {
    data
  } = props
 
  return (

      <components.Option {...props}>

        <div {...props} style={{textAlign: 'left', display: 'flex', alignItems: 'center', width: '100%',}}>
 
                <img src={data.image} style={{height: 30, width: 30}} alt=""/>
                <div style={{marginLeft: 12}}>{data.label}</div>

        </div>
  
      </components.Option>

  );
};

  
class MenuSelector extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      selectedOption: null
    }
  }
  

  componentDidMount() {

  }

  componentDidUpdate(prevProps, prevState) {

    if (this.props.clearer !== prevProps.clearer) {
        this.setState(state => ({...state,  selectedOption: null }));
    }
    
  }

  filterOptions = (candidate, input) => {
    if (input) {
      
      return (
        ( candidate.data.label && candidate.data.label.toLowerCase().includes(input.toLowerCase()) )
        )
    }
    return true;
  };

  handleChange = (selectedOption) => {

    this.setState(state => ({...state,  selectedOption: selectedOption }));
    if (selectedOption !== null && selectedOption !== undefined) {
        this.props.handleChange(selectedOption.value)
    } else {
        this.props.handleChange(null)
    }
    
    
  }

  onFocus = () => {

    this.setState(state => ({...state,  selectedOption: null }));
    this.props.handleChange(null)

  }

  render() {
    const { selectedOption } = this.state;

    return (
      <Select
        value={selectedOption}
        onChange={this.handleChange}
        options={this.props.options}
        onFocus={this.onFocus}
        onMenuOpen={this.onFocus}
        isSearchable={true}
        styles={colourStyles}
        filterOption={this.filterOptions}
        maxMenuHeight={210}
        minMenuHeight={50}
        components={{ ValueContainer, Option }}
        alwaysOpen
        classNamePrefix="react-select-user"
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
          ...theme.colors,
            primary25: '#F3F5F6',
            primary: '#F3F5F6',
          },
        })}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    
  };
};

const mapDispatchToProps = dispatch => {
  return {
   
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MenuSelector);
