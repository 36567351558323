import React from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table';

class ReportTable extends React.PureComponent {

    renderAnalysis = (type, id, url) => {
      switch(type) {
        case 'valueAtRisk': 
          this.props.handleReportSwitch('varVisual', id)
          break;
        case 'portfolioReport': 
          window.open(url, "_blank")
          break;
        default: 
          return null
      }
    }
    render () {


        const columns=[
            { 
                id: 'analysis_id',
                accessor: 'analysis_id', 
                Header: () =>  (
                    <div className='basier-p4-caps' style={{ fontWeight: 900, color: 'black'}}>Name</div>
                ),
                minWidth: 75,
                Cell: row => {

                    return (
                    <div>
                        <div className="basier-p3" style={{}}>
                        {this.props.renderAnalysisName(row.value)['name']}
                        </div>  
                    </div>
                )
                }
            },
            {   
                id: 'created_at',
                accessor: 'created_at', 
                Header: () =>  (
                    <div className='basier-p4-caps' style={{ fontWeight: 900, color: 'black'}}>Date</div>
                ),
                minWidth: 100,
                Cell: row => {
                    const language = navigator.language || 'en'
                    const options = {year: 'numeric', month: 'numeric', day: 'numeric', 'hour': '2-digit', 'minutes': '2-digit' };
                    const parseDate = new Date(row.value);
                    const showDate = parseDate.toLocaleDateString(language,options)

                    return (
                        <div>
                        <div className="basier-p3" style={{}}>
                        {showDate}
                        </div>  
                    </div>
                    )
                }
            },
            {   
                id: 'completed',
                accessor: 'completed', 

                Header: () =>  (
                    <div className='basier-p4-caps' style={{ fontWeight: 900, color: 'black', textAlign: 'center'}}>Status</div>
                ),
                minWidth: 100,
                Cell: row => {
                    
                    
                    return (
                        <div style={{textAlign: 'center'}} className="basier-p3">
                            {row.value === true ? 
                            
                            'Ready'

                            : 

                            'Processing'

                            }
                        </div>
                    )
                }
            },
            {   
                id: 'completed',
                accessor: 'completed', 
                Header: () =>  (
                    <div className='basier-p4-caps' style={{ fontWeight: 900, color: 'black'}}></div>
                ),
                minWidth: 75,
                maxWidth: 75,
                Cell: row => {
                    
                    
                    return (
                        <div style={{textAlign: 'center'}}>
                            {row.value === true ? 
                            
                            <div className="basier-p3 text-link" onClick={() => this.renderAnalysis(row.original.analysis_id, row.original.id, row.original.file)}>
                            View
                            </div>
                            
                            : 

                            null

                            }
                        </div>
                    )
                }
            }
            ]


        return (


            <div style={{width: '100%', marginLeft: 'auto', marginRight: 'auto'}}>

                <ReactTable
                    
                    defaultPageSize={ 10 }
                    //filterable
                    resizable={false}
                    defaultFilterMethod={(filter, row) =>
                    String(row[filter.id]) === filter.value}
                    showPageSizeOptions={false}
                    data={this.props.data}
                    columns={columns}
                    getProps={(state, rowInfo, column) => {
                        return {
                          style: {
                            border: '0px',
                          }
                        };
                      }}
                      getTrGroupProps={(state, rowInfo, column) => {
                        return {
                          style: {
                            border: '0px',
                          }
                        };
                    }}
                    getTheadFilterProps={(state, rowInfo, column) => {
                        return {
                          style: {
                            marginTop: 6,
                            border: 'none'
                          }
                        };
                      }}
                    getPaginationProps={(state, rowInfo, column) => {
                        return {
                          style: {
                           boxShadow: 'none',
                           border: 'none',
                           marginTop: 12,
                          }
                        };
                      }}
                    getTheadProps={(state, rowInfo, column) => {
                        return {
                          style: {
                            boxShadow: 'none', 
                          }
                        };
                      }}
                    getTdProps={(state, rowInfo, column) => {
                        return {
                          style: {
                            overflow: 'visible',
                            paddingLeft: 0, 
                            paddingRight: 0,
                          }
                        };
                      }}
                    getTheadThProps={(state, rowInfo, column) => {
                    return {
                        style: {
                            paddingLeft: 0, 
                            paddingRight: 0,
                            borderBottom: '1px solid black',
                        }
                    };
                    }}
                    getTbodyProps={(state, rowInfo, column) => {
                    return {
                        style: {
                            overflow: 'visible',   
                        
                        }
                    };
                    }}
                    getTableProps={(state, rowInfo, column) => {
                        return {
                            style: {
                            overflow: 'visible',   
                            }
                        };
                        }}
                    getTrProps={(state, rowInfo, column) => {
                        return {
                          style: {
                            display: 'flex', 
                            alignItems: 'center',
                            paddingBottom: 4,
                            paddingTop: 4,
                            height: 50,
                            color: 'black',
                            backgroundColor: rowInfo !== undefined ? rowInfo.index % 2 === 0 ? 'white' : '#F3F5F6' : 'white'
                          }
                        };
                      }}
                />
            
            </div>
            
        )
    }


}

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = dispatch => {
    return {
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(ReportTable)
