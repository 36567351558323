import React from 'react';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { withStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import { siwtchTransactionType } from '../../helpers/helpers';
import Menu from '@material-ui/core/Menu';

const styles = theme =>  ({
  root: {
    border: '1px solid #CCC', 
    height: 30, 
    minWidth: 30, 
    width: 30,
    display: 'flex', 
    justifyContent: 'center', 
    alignItems: 'center'
  },
})

const timeoutLength = 100;

class CustomContextMenu extends React.PureComponent {

constructor(props) {
    super(props);
    //this.anchorRef = React.createRef();
    this.state = {
        open: false, 
        subOpen: {

        },
        onMenu: {

        },
        anchorEl: null,
    } 

}



setOpen = () => {
    
}

getRef = async (ref) => {
    return ref;
}


handleToggle = () => {
    if (this.state.open) {
      this.setState(state => ({...state, open: false, subOpen: {}, onMenu: {}}))
    } else {
      this.setState(state => ({...state, open: true, subOpen: {}, onMenu: {}}))
    }
}



handleHoverOn = (e, k) => {
    this.setState(state => ({...state, subOpen: {[k]: true}, anchorEl: e.currentTarget  })); 
  };

handleClose = (event) => {
    setTimeout( () => {this.setState(state => ({...state, open: false, subOpen: {}, onMenu: {}}))} ,10)  
}

handleHoverOff = () => {
    setTimeout(() => {
        this.setState(state => ({ subOpen: {} }));
       }, timeoutLength);
};

handleHoverMenuOn = (k) => {
    this.setState(state => ({ onMenu: {[k]: true} }));   
  }

  handleHoverMenuOff = () => {
    setTimeout(() => {
        this.setState(state => ({ onMenu: {} }));
       }, timeoutLength);
  }

render () {

const { classes } = this.props;

  // return focus to the button when we transitioned from !open -> open

  return (
      this.props.menuOpen ? 
      <ClickAwayListener onClickAway={(e) => e.button === 0 ? this.props.handleClose() : null}>
        <Paper style={{minWidth: 100, zIndex: 90000, position: 'absolute', top: `${this.props.mouseY - 12 }px`, left: `${this.props.mouseX}px`, padding: 0}}>
            <div className="basier-p4" style={{zIndex: 90, display: 'block', backgroundColor: 'white'}}>
                <MenuList autoFocusItem={this.state.open} id="menu-list-grow" onKeyDown={this.handleListKeyDown}>
                    {this.props.menuItems.map((v, k) => {
                        return (
                        v.length === 3 ?
                        <div key={k} ref={node => {this.anchorEl = node}}>
                            <MenuItem 
                                className="basier-p4" onClick={v[1]}
                                
                                aria-owns={this.state.subOpen[k] ? 'menu-list-grow' : undefined}
                                aria-haspopup="true"
                                //onClick={this.handleToggle}
                                onMouseEnter={(e) => this.handleHoverOn(e, k)}
                                onMouseLeave={this.handleHoverOff}
                                style={{cursor: 'pointer'}}
                                >
                                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                    <div style={{marginRight: 12}}>{v[0]}</div>
                                    <div className="light-arrow right" ></div>

                                </div>
                                
                            </MenuItem>
                            <Popper open={this.state.subOpen[k] || this.state.onMenu[k]} anchorEl={this.anchorEl} placement='right-end' transition disablePortal>
                                {({ TransitionProps, placement }) => (
                                <Grow
                                    {...TransitionProps}
                                    id="menu-list-grow"
                                    //style={{ transformOrigin: 'top right' }}
                                >
                                    <Paper style={{marginLeft:1}}>

                                        <MenuList
                                            onMouseEnter={() => this.handleHoverMenuOn(k)}
                                            onMouseLeave={this.handleHoverMenuOff} 
                                            >
                                            {v[2].map((val, key) => {
                                                return (
                                                    <MenuItem key={k} className="basier-p4" onClick={() => {this.handleToggle(); v[1](val, this.props.selected);}}>
                                                        {val}
                                                    </MenuItem>
                                                )
                                            })}
                                        </MenuList>
                                        
                                    </Paper>
                                </Grow>
                                )}
                            </Popper>
                        </div>
                        :
                        <MenuItem key={k} className="basier-p4" onClick={() => {this.handleToggle(); v[1](this.props.selected);}}>{v[0]}</MenuItem>
                        )
                    }
                    
                    
                    
                    )}
                        
                </MenuList>
            </div>
        </Paper>
      </ClickAwayListener>
        :
      null
  )
}
}

export default withStyles(styles)(CustomContextMenu)