import React from 'react';
import { connect } from 'react-redux';
import AnalysisList from './AnalysisList'
import AnalysisTable from './AnalysisTable'
import OldOverview from './OldAnalysisOverview'

import BackIcon from '../../../../Media/BackIcon.svg'

class ReportOverview extends React.PureComponent {

    state = {
        type: 'overview',
    }

    handleSelect = (id) => {
        this.setState(state => ({...state, type: id}))
    }

    renderType = (type) => {
        switch(type) {
            default: 
                return null
        }
    }
    render () {

        return (

            <div style={{margin: 62}}>
            {this.state.type === 'overview' ? 

            <OldOverview />

            

            :
            
            <React.Fragment>
            <h3>Analysis</h3>
            <div style={{marginTop: 24, display: 'flex', alignItems: 'center', cursor: 'pointer', width: 'fit-content'}} onClick={() => this.handleSelect('overview')} className="opacity-hover">
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 12}}>
                    <img src={BackIcon} style={{height: 40, width: 40, marginRight: 12}} alt="" />
                </div>
                <div className="basier-p4-caps">Back to list</div>
            </div>

            <div style={{minHeight: 550, width: '100%', backgroundColor: 'white', borderRadius: 20, marginTop: 24, display: 'flex', boxShadow: '0px 10px 10px rgba(225, 229, 233, 0.3)', border: '1px solid #E1E5E9', }}>
                {this.renderType(this.state.type)}
            </div>
            </React.Fragment>
            }
            </div>
            
        )
    }


}

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = dispatch => {
    return {
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(ReportOverview)
