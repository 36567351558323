import React from "react";
import { connect } from "react-redux";
import BaseModalTop from "../../Portfolio/PortfolioComponents/Transactions/BaseModalTop";
import * as actions from "../../../store/actions/interactions";
import AuditCompare from "./ModalComps/AuditCompare";
import fieldValidator from "../../../helpers/validators";
import { siwtchTransactionType } from "../../../helpers/helpers";
import CoinSearch from '../../AppWide/CoinSearch';
import Axios from "axios";
import { backendUrl } from "../../../variables";
import loadingSpinner from '../../../Media/loadingSpinner.gif';

class ExportModal extends React.PureComponent {
  state = {
    loading: false
  };

  handleClose = () => {
    this.props.toggleModal({ type: null, open: false, payload: null });
  };

  submit = () => {
    
    this.setState(state => ({...state, loading: true, error: false}))

    if (this.state.base === undefined) {
      return
    }

    const postData = {
      user_id: this.props.user_id, 
      coin_id: this.state.base.value
    }
    
    let headers = { "content-type": "application/json" };
    if (this.props.token !== null && this.props.token !== undefined) {

        headers['Authorization'] = `Token ${this.props.token}`

    }

    const url_call = `${backendUrl}/v1/connectors/transactions/${this.props.data.id}/allocate/`

    Axios.post(url_call, postData, {headers: headers})
        .then(json => {

            this.props.getData()
            setTimeout(() => {

              this.handleClose();
              

          }, 600)
            
        })
        .catch( e => {

        this.setState(state => ({...state, loading: false, error: true}))

        })

    
  }

  selectBaseCoin = (val) => {
    this.setState(state => ({...state, base: val}))
  } 

  render() {
    return (
      <div>
        <BaseModalTop
          forceSingle={true}
          title="Select Coin"
          handleClose={this.handleClose}
          onClick={this.backToWelcome}
          welcome={this.state.welcome}
          entryType={this.state.type_selected}
        />

        {
          this.state.loading ? 

          <div style={{width: '100%', height: 530, backgroundColor: 'white', opacity: 0.6, display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'absolute', top: 90, zIndex: 13000}}>
              <img style={{height: 64, with: 64}} src={loadingSpinner} alt="Loading..."/>
          </div>

          :

        <div
          style={{alignItems: "center", justifyContent: "center", textAlign: 'center', marginTop: 24, padding: 24}}
        >
          <div style={{marginBottom: 24}}>
          <h4>Select coin for import</h4>
          </div>
          <p className="basier-p3">
            Please select the coin you wish to allocate to the selected transaction.
          </p>
          <p className="basier-p3">
            {`${siwtchTransactionType(this.props.data.transaction_description)[0]} ${this.props.data.amount} ${this.props.data.text_name}${this.props.data.quote_ticker != null ? "/" : ""}${this.props.data.quote_ticker !== null ? this.props.data.quote_ticker : ""} ${this.props.data.unit_price !== null ? "@" : ""}${this.props.data.unit_price !== null ? this.props.data.unit_price : ""}`}
          </p>

          <div style={{marginTop: 12, marginBottom: 12, display: 'flex', justifyContent: 'center', textAlign: 'center', height: 250}}>

          <CoinSearch 
            data_filter={this.props.all_coins}
            dataBind={this.state.base}
            ref={null}
            width={250}
            keepInput={true}
            overrideClass={true} 
            focusIn={() => {}}
            clearRedux='base_currency'
            updaterMethod={(key, value) => this.selectBaseCoin(value)}
            handleSelect={(value) => this.selectBaseCoin(value)}
            masterClass="transaction-coin-search"/>

          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: 24,
              justifyContent: "center",
              width: "100%",
              marginRight: 12,
            }}
          >
            <div
              className="basier-p3 opacity-hover"
              style={{
                fontWeight: 900,
                height: 40,
                backgroundColor: "#F9716B",
                width: 150,
                color: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 20,
                marginRight: 12,
                cursor: "pointer",
              }}
              onClick={() => this.handleClose()}
            >
              Cancel
            </div>

            <div
              className="basier-p3 opacity-hover"
              style={{
                fontWeight: 900,
                height: 40,
                backgroundColor: "#0078DB",
                width: 150,
                color: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 20,
                marginRight: 12,
                cursor: "pointer",
              }}
              onClick={this.submit}
            >
              Submit
            </div>
          </div>
        </div>
      }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user_id: state.auth.user_id,
    all_coins: state.appwide.all_coins, 
    token: state.auth.token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleModal: (data) => dispatch(actions.toggleModal(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExportModal);
