import React from 'react'
import PortfolioCard from './PortfolioCard'
import PortfolioAdd from './PortfolioAdd'
import { connect } from 'react-redux';
import {createLoadingSelector} from '../../../store/reducers/selectors'
import { Link } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom'
import Hidden from '@material-ui/core/Hidden';
import SwipeableViews from 'react-swipeable-views';
import Carousel from 'nuka-carousel';
import Slider from "react-slick";
import Tabs from '@material-ui/core/Tabs';
import { withStyles } from '@material-ui/core/styles';
import PortfolioBK from '../../../Media/PortfolioBK.svg'
import contentLoader from '../../../Media/contentLoader.svg'
import {formattedNumber} from '../../../helpers/helpers'

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    
  },
  tabsRoot: {
    flexGrow: 1,
    backgroundColor: '#F8F9FA',
    overflow: 'hidden', 
    height: 500
  },
  tabsIndicator: {
    backgroundColor: '#0078DB',
    left: 0, 
    width: 160, 
    top: 48,
  },
  scrollable: {
    paddingBottom: 20,
  },
});

const options = [];

class PortfolioList extends React.Component {

  state = {
    portfolioList: []
  }
  
  componentDidMount() {

    window.addEventListener('touchstart', this.touchStart);
    window.addEventListener('touchmove', this.preventTouch, {passive: false});

    //this.setState(state => ({...state, portfolioList: this.props.portData}))

    }

    componentDidUpdate(prevProps) {

    /*   if (this.props.portData !== prevProps.portData) {
    
        this.setState(state => ({...state, portfolioList: this.props.portData}))    

      } */
    
    }

    componentWillUnmount() {
        window.removeEventListener('touchstart', this.touchStart);
        window.removeEventListener('touchmove', this.preventTouch, {passive: false});
    }

    touchStart(e) {
        this.firstClientX = e.touches[0].clientX;
        this.firstClientY = e.touches[0].clientY;
    }

    preventTouch(e) {
        const minValue = 15; // threshold

        this.clientX = e.touches[0].clientX - this.firstClientX;
        this.clientY = e.touches[0].clientY - this.firstClientY;

        // Vertical scrolling does not work when you start swiping horizontally.
        if(Math.abs(this.clientX) > minValue){ 
            e.preventDefault();
            e.returnValue = false;
            return false;
        }
    }
    
    render() {

    //const number_port = (Object.keys(this.props.portData).length) + 1
    const to_show = Math.round(window.innerWidth / (280+36))

    const settings = {
      dots: true,
      arrows: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: false,
      variableWidth: true,
      speed: 500,
      initialSlide: 0,
      className: "slider-wrapper",
      adaptiveHeight: true,
      accessibility: true,
      slide:"span",
      touchThreshold: 7,
      responsive: [
        {
          breakpoint: 400,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 0,
            centerMode: true,
            infinite: true,
            adaptiveHeight: true,
          }
        },
      ]
    };
    const { classes } = this.props;
    const symbol_to_use = this.props.inBitcoin === 'bitcoin' ? "₿" : this.props.currency_symbol
    return (
        <div style={{marginBottom: 24}}>
            

              <div style={{height: 410, overflow: 'scroll', paddingLeft: 0, paddingRight: 0, width: '100%', alignItems: 'flex-start'}}>

                    
                        { this.props.portList.map((k, index) => {

                          let date = new Date(k['created_at'])
                          const language = navigator.language || 'en'
                          const options = {year: 'numeric', month: 'numeric', day: 'numeric' };
                          let displayDate = date.toLocaleDateString(language, options)

                          return (
                            
                            
                            <Link style={{width: '100%', height: 'fit-content'}} key={index} component={RouterLink} to={`/portfolio/${k['id']}`}>
                                <div >
                                  <div style= {{
                                    height: 80, 
                                    borderRadius: 20, 
                                    boxShadow: '0px 10px 10px rgba(225, 229, 233, 0.3)',
                                    background:  this.props.portData !== null && this.props.portData !== undefined ?  this.props.portData[k['id']] !== undefined ? this.props.portData[k['id']]['change'] >= 0 ? 'linear-gradient(180deg, #FFFFFF 52.17%, rgba(255, 255, 255, 0) 291.3%), #4CDAC1' : 'linear-gradient(180deg, #FFFFFF 52.17%, rgba(255, 255, 255, 0) 291.3%), red'  : 'linear-gradient(180deg, #FFFFFF 52.17%, rgba(255, 255, 255, 0) 291.3%), #4CDAC1' : 'white', 
                                    display: 'flex', 
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    overflow: 'hidden',
                                    marginBottom: 6,
                                    marginLeft: 6, 
                                    marginRight: 6,
                                    marginTop: 6,
                                    padding: '0px 24px'
                                    }}
                                    className="">
                                        
                                        <div className="truncate" style={{
                                            color: 'black'
                                            }} 
                                            >
                                            <div style={{display: 'flex', alignItems: 'center'}}>
                                              <div className="basier-p2 truncate" style={{fontWeight: 900, flex:1}}>
                                              </div>
                                              {k['name']}
                                              </div>
                                              <div className="basier-p4-caps" style={{color: '#6A7E93'}}>
                                              since {displayDate}
                                              </div>
                                        </div>
                                        

                                        <div style={{color: 'black'}}>
                                            {this.props.portData !== null && this.props.portData !== undefined ?

                                              this.props.portData[k['id']] !== undefined ?
                                              
                                                <div style={{textAlign: 'right'}}>
                                                  <div className="basier-p3" style={{fontWeight: 900}}>
                                                    {symbol_to_use}&nbsp;{formattedNumber(this.props.portData[k['id']]['total_value'])}
                                                  </div>
                                                  <div>
                                                    <span className="basier-p4-caps" style={{
                                                      fontWeight: 900, 
                                                      color: this.props.portData[k['id']]['change'] > 0 ? 'green' : this.props.portData[k['id']]['change'] < 0 ? 'red' : 'gray', 
                                                    }}>
                                                    {this.props.portData[k['id']]['change'] > 0 ? '+' : null}{formattedNumber(this.props.portData[k['id']]['change'], 2)}%</span>&nbsp;<span className="basier-p4" style={{fontStyle: 'italic'}}>since yesterday</span>
                                                  </div>
                                                </div>

                                                :

                                                <div style={{textAlign: 'right'}}>
                                                  <div className="basier-p3" style={{fontWeight: 900}}>
                                                    {symbol_to_use}0.00
                                                  </div>
                                                  <div className="basier-p4-caps">
                                                    -
                                                  </div>
                                                </div>


                                            :
                                            
                                            <img src={contentLoader} style={{width: 60, height: 60}} alt="" />                                            
                                            
                                            }
                                            
                                        </div>
                                  </div>
                                </div>

                            </Link>
                                

                        )
                    }
                    
                    )}
            

            </div>


        </div>
    )

    }
    
}


const graphPortfolioSelector = createLoadingSelector(['UPDATE_ALL_PORTFOLIOS']);

const mapStateToProps = state => {
    return {
        portData: state.data.singleBalances,
        portList: state.auth.list_portfolios,
        currency_symbol: state.auth.currency.symbol,
        inBitcoin: state.data.bitcoinPf, 
        loading_port: graphPortfolioSelector(state),
    };
};

export default connect(
    mapStateToProps,
    null,
)(withStyles(styles)(PortfolioList));