import React from "react";
import okGreen from "../../../../Media/okGreen.svg";
import cancelRed from "../../../../Media/cancelRed.svg";
import refreshSmall from "../../../../Media/refreshSmall.svg";

import axios from "axios";
import { backendUrl } from "../../../../variables";
import { connect } from "react-redux";
import * as appwideActions from "../../../../store/actions/appwide";
import * as actions from "../../../../store/actions/interactions";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  container: {
    width: "100%",
    padding: "24px 48px",
    display: "flex",
    flexDirection: "column",
  },
  managementBox: {
    width: "100%",
    height: 70,
    backgroundColor: "#F8F9FA",
    display: "flex",
    justifyContent: "space-between",
    padding: "12px 24px",
  },
  transactionBox: {
    height: 380,
    display: "flex",
    flexDirection: "row",
  },
  checkRoot: {
    color: "#6A7E93",
    "&$checked": {
      color: "#0078DB",
    },
  },
  checked: {
    color: "#0078DB",
  },
});

const getValue = (dict, val) => {
  let ret = [];
  try {
    ret = dict[val];
  } catch (e) {}
  return ret;
};

class TopSection extends React.PureComponent {
  state = {
    walletEdit: false,
    newAddress: null,
    refreshLoad: false,
  };

  onKeyDown = (e) => {
    switch (e.keyCode) {
      case 27: {
        this.commitEditAddress("cancel");
        break;
      }
      case 13: {
        this.commitEditAddress("ok");
        break;
      }

      default: {
        break;
      }
    }
  };

  deleteWallet = (scope) => {
    switch (true) {
      case this.props.data.type === "exchange" &&
        this.props.data.connected === true:
        return this.deleteConnectedWallet(scope);
      case this.props.data.type === "exchange" &&
        this.props.data.connected === false:
        return this.deleteNonConnectedExchange(scope);
      case this.props.data.type === "storage" &&
        this.props.data.connected === true:
        return this.deleteConnectedWallet(scope);
      case this.props.data.type === "storage" &&
        this.props.data.connected === false:
        return this.deleteNonConntectedWallet(scope);
      default:
        return null;
    }
  };

  deleteNonConnectedExchange = (scope) => {
    let headers = { "content-type": "application/json" };
    if (this.props.token !== null && this.props.token !== undefined) {
      headers["Authorization"] = `Token ${this.props.token}`;
    }

    const url_call = `${backendUrl}/v1/exchange_account/${this.props.data.id}/?u=${this.props.user_id}&scope=${scope}`;

    axios.delete(url_call, { headers: headers }).then((json) => {
      // load new wallet list
      this.props.getWallets(this.props.user_id);
      //console.log(json['data'])
    });
  };

  deleteNonConntectedWallet = (scope) => {
    let headers = { "content-type": "application/json" };
    if (this.props.token !== null && this.props.token !== undefined) {
      headers["Authorization"] = `Token ${this.props.token}`;
    }

    const url_call = `${backendUrl}/v1/wallets/${this.props.data.id}/?tx=${scope}&u=${this.props.user_id}`;

    axios.delete(url_call, { headers: headers }).then((json) => {
      // load new wallet list
      this.props.getWallets(this.props.user_id);
      //console.log(json['data'])
    });
  };

  deleteConnectedWallet = (scope) => {
    let headers = { "content-type": "application/json" };
    if (this.props.token !== null && this.props.token !== undefined) {
      headers["Authorization"] = `Token ${this.props.token}`;
    }
    let url_call = `${backendUrl}/v1/account/${this.props.data.id}/?u=${this.props.user_id}&scope=${scope}&type=${this.props.data.type}`;
    this.setState((state) => ({ ...state, deleting: true }));
    axios.delete(url_call, { headers: headers }).then((json) => {
      this.props.getWallets(this.props.user_id);
    });
  };

  subscopeComponent = (props) => {
    const { classes } = props;
    return (
      <div>
        <div className="basier-p3" style={{ color: "#0078DB" }}>
          What should we do with transactions linked to this account
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <RadioGroup
            name="deleteScope"
            value={props.subValue}
            style={{ display: "flex", flexDirection: "row" }}
            onChange={(e) => props.onSelect(e.target.value)}
            //style={{display: 'inline-block'}}
          >
            <FormControlLabel
              value="keep"
              control={
                <Radio
                  classes={{
                    root: classes.checkRoot,
                    checked: classes.checked,
                  }}
                />
              }
              label="Keep"
            />

            <FormControlLabel
              value="cascade"
              control={
                <Radio
                  classes={{
                    root: classes.checkRoot,
                    checked: classes.checked,
                  }}
                />
              }
              label="Delete"
            />
          </RadioGroup>
        </div>
      </div>
    );
  };

  styledQuestion = withStyles(styles)(this.subscopeComponent);

  confirmDelete = () => {
    this.props.toggleModal({
      type: "confirmation_modal",
      payload: {
        message: `You are about to delete this account.`,
        forceYes: "Delete",
        forceNo: "Cancel",
        subscope: this.styledQuestion,
        subscopeValue: "keep",
        handleConfirm: (scope) => this.deleteWallet(scope),
      },
      open: true,
    });
  };

  showAddressEdit = (address) => {
    this.setState((state) => ({
      ...state,
      walletEdit: true,
      newAddress: address,
    }));
  };

  editAddress = (e) => {
    e.persist();
    this.setState((state) => ({ ...state, newAddress: e.target.value }));
  };

  commitEditAddress = (action) => {
    if (action === "cancel") {
      this.setState((state) => ({ ...state, walletEdit: false }));
    } else if (action === "ok") {
      this.setState((state) => ({ ...state, loading: true }));
      const data = {
        id: this.props.data.id,
        address: this.state.newAddress,
      };

      const newAdd = this.editWallet(data);
      newAdd
        .then((data) => {
          let walletData = getValue(
            this.props.walletsData,
            this.props.data.source_id
          ).filter((val) => val.id === this.props.data.id);
          walletData[0]["address"] = this.state.newAddress;


          const payload = {
            data: walletData,
            coin: this.props.data.source_id,
            user_id: this.props.data.user,
            scope: "storage",
          };

          this.props.updateSingleWallet(payload);
          this.setState((state) => ({ ...state, loading: false }));
        })
        .catch((err) => console.log("err", err));
      this.setState((state) => ({ ...state, walletEdit: false }));
    }
  };

  editWallet = (data) =>
    new Promise((resolve, reject) => {
      //console.log(this.state)
      let headers = { "content-type": "application/json" };
      if (this.props.token !== null && this.props.token !== undefined) {
        headers["Authorization"] = `Token ${this.props.token}`;
      }

      const send_data = {
        ...data,
        user_id: this.props.user_id,
      };

      const url_call = `${backendUrl}/v1/wallets/`;

      axios
        .patch(url_call, send_data, { headers: headers })
        .then((json) => {
          resolve(json["data"]);
        })
        .catch((e) => {
          reject("error");
        });
    });

  componentDidMount() {
    if (this.props.scope === "storage") {
      this.props.getData();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.scope === "storage" &&
      this.props.data.id !== prevProps.data.id
    ) {
      this.props.getData();
    }

    if (this.props.data.id !== prevProps.data.id) {
      this.setState((state) => ({ ...state, refreshLoad: false }));
    }
  }

  refreshAccount = () => {
    this.setState((state) => ({ ...state, refreshLoad: true }));
    let headers = { "content-type": "application/json" };
    if (this.props.token !== null && this.props.token !== undefined) {
      headers["Authorization"] = `Token ${this.props.token}`;
    }
    let url_call = `${backendUrl}/v1/account/${this.props.data.id}/refresh/`;

    const data = {
      scope: this.props.scope,
      user_id: this.props.user_id,
      force: false,
    };
    axios.post(url_call, data, { headers: headers }).then((json) => {
      //this.props.getWallets(this.props.user_id)

      const currentTasks = this.props.tasks;
      let newTasks = [...currentTasks];
      if (this.props.scope === "exchange") {
        newTasks = [
          {
            description: `Updating Exchange`,
            sub: `${this.props.data.source_name}`,
            status: "pending",
            type: "connection",
            task_id: this.props.data.id,
          },
          ...currentTasks,
        ];
      } else if (this.props.scope === "storage") {
        newTasks = [
          {
            description: `Updating Wallet`,
            sub: `${this.props.data.source_name}`,
            status: "pending",
            type: "connection",
            task_id: this.props.data.id,
          },
          ...currentTasks,
        ];
      }

      this.props.setInteraction({ label: "tasks", value: newTasks });
      this.setState((state) => ({ ...state, refreshLoad: false }));
    });
  };

  openCSVModal = () => {

    this.props.toggleModal({type: 'account_csv', open: true, payload: {scope: this.props.scope, context: this.props.data.id, data: null}})
  
  }

  render() {
    const { data } = this.props;

    let limited = false;
    try {
      limited = (this.props.limits.transactions > this.props.limits.transaction_limit && this.props.limits.transaction_limit != -1)
    } catch(e) {}

    return (
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <div style={{ flex: 1, width: 300 }} className="truncate">
          <div
            style={{
              marginBottom: 8,
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            {this.props.scope === "storage" && (
              <img
                alt=""
                src={data.image}
                style={{ height: 30, width: 30, marginRight: 6 }}
              />
            )}
            <div className="truncate">
              <h3
                className="truncate"
                style={{ margin: 0, textTransform: "uppercase" }}
              >
                {data.name}
              </h3>
            </div>
          </div>
          {this.props.scope === "exchange" ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              {data.image !== null ? (
                <img
                  alt=""
                  src={data.image}
                  style={{ height: 18, width: 18, marginRight: 6 }}
                />
              ) : (
                <div
                  style={{
                    height: 18,
                    width: 18,
                    marginRight: 6,
                    textTransform: "uppercase",
                    fontSize: 11,
                    color: "black",
                    backgroundColor: "#CCC",
                    borderRadius: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {data.source_name.slice(0, 1)}
                </div>
              )}
              <div className="basier-p4-caps truncate">{data.source_name}</div>
            </div>
          ) : this.props.scope === "storage" ? (
            <div style={{ paddingLeft: 24 }}>
              {this.state.walletEdit ? (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <input
                    autoFocus={true}
                    autoComplete="false"
                    className="light-input-back basier-p4"
                    style={{
                      flex: 1,
                      minWidth: 250,
                      maxWidth: 500,
                      height: 30,
                      zIndex: 10,
                    }}
                    value={this.state.newAddress || ""}
                    onChange={(e) => this.editAddress(e)}
                    onKeyDown={(e) => this.onKeyDown(e)}
                  />
                  <div
                    style={{
                      width: "30%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{ marginLeft: 6, marginRight: 6 }}
                      onClick={() => this.commitEditAddress("ok")}
                    >
                      <img
                        src={okGreen}
                        style={{ height: 24, width: 24 }}
                        alt="ok"
                      />
                    </div>
                    <div
                      style={{ marginRight: 6 }}
                      onClick={() => this.commitEditAddress("cancel")}
                    >
                      <img
                        src={cancelRed}
                        style={{ height: 24, width: 24 }}
                        alt="no"
                      />
                    </div>
                  </div>
                </div>
              ) : this.state.loading ? (
                <div
                  style={{ height: 20, flex: 1, minWidth: 250 }}
                  className="pulse-skeleton"
                ></div>
              ) : data.address !== null &&
                data.address !== undefined &&
                data.address !== "" ? (
                <div
                  className="truncate"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    minWidth: 250,
                    flex: 1,
                  }}
                >
                  <div
                    className="basier-p4-caps truncate"
                    style={{ marginLeft: 12 }}
                  >
                    {data.address}
                  </div>
                  {data.connected === false && (
                    <div
                      className="basier-p4 opacity-hover"
                      style={{
                        color: "#0078DB",
                        cursor: "pointer",
                        width: "fit-content",
                        marginLeft: 6,
                      }}
                      onClick={() => this.showAddressEdit(data.address)}
                    >
                      edit
                    </div>
                  )}
                </div>
              ) : (
                <div
                  className="basier-p4-caps opacity-hover"
                  style={{
                    color: "#0078DB",
                    cursor: "pointer",
                    width: "fit-content",
                  }}
                  onClick={() => this.showAddressEdit(data.address)}
                >
                  + add address
                </div>
              )}
            </div>
          ) : null}
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            maxWidth: 320,
            minWidth: 200,
            flex: 1,
          }}
        >
          {data.connected && (
            <div className="no-select">
              <div
                onClick={
                  this.state.refreshLoad === false
                    ? limited === false ? () => this.refreshAccount() : null
                    : null
                }
                className={limited ? "" : 'opacity-hover'}
                style={{
                  cursor: limited ? "not-allowed" : "pointer",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {limited ? null : 
                <img
                  src={refreshSmall}
                  alt=""
                  style={{ height: 16, width: 16 }}
                />}
                <div
                  className="basier-p3"
                  style={{ color: limited ? "#E1E5E9" : "#0078DB", marginLeft: 6 }}
                >
                  Refresh
                </div>
              </div>
            </div>
          )}
          {data.type === "storage" && (
            <div>
              {data.address !== null &&
              data.address !== undefined &&
              data.address !== "" &&
              data.link !== null &&
              data.link !== undefined &&
              data.link !== "" ? (
                <a
                  href={`${data.link}${data.address}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button
                    onClick={this.goToExplorer}
                    className="opacity-hover basier-p3"
                    style={{
                      width: 105,
                      color: "white",
                      backgroundColor: "#0078DB",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      border: "none",
                      height: 35,
                      borderRadius: 10,
                      marginLeft: 12,
                      cursor: "pointer",
                    }}
                  >
                    Explorer
                  </button>
                </a>
              ) : (
                <button
                  onClick={this.goToExplorer}
                  className="opacity-hover basier-p3"
                  style={{
                    width: 105,
                    color: "white",
                    backgroundColor: "#0078DB",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    border: "none",
                    height: 35,
                    borderRadius: 10,
                    marginLeft: 12,
                    cursor: "pointer",
                  }}
                >
                  Explorer
                </button>
              )}
            </div>
          )}

          <div>
            <button
              onClick={this.openCSVModal}
              className="opacity-hover basier-p3"
              style={{
                width: 105,
                color: "white",
                backgroundColor: "#0078DB",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                border: "none",
                height: 35,
                borderRadius: 10,
                marginLeft: 12,
                cursor: "pointer",
              }}
            >
              Import CSV
            </button>
          </div>

          <div>
            <button
              onClick={this.confirmDelete}
              className="opacity-hover basier-p3"
              style={{
                width: 105,
                color: "white",
                backgroundColor: "#F9716B",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                border: "none",
                height: 35,
                borderRadius: 10,
                marginLeft: 12,
                cursor: "pointer",
              }}
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user_id: state.auth.user_id,
    token: state.auth.token,
    tasks: state.interactions.tasks,
    limits: state.appwide.limits,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateSingleWallet: (payload) =>
      dispatch(appwideActions.updateSingleWallet(payload)),
    toggleModal: (data) => dispatch(actions.toggleModal(data)),
    getWallets: (user) => dispatch(appwideActions.getWallets(user)),
    setInteraction: (data) => dispatch(actions.setInteraction(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TopSection);
