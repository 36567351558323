import React from 'react';
import { connect } from 'react-redux';
import errorIcon from '../../../../Media/errorIcon.svg'

class ErrorUpload extends React.PureComponent {

    renderError = (data) => {

        return (
            
            <div>
                <div style={{color: '#F9716B', fontWeight: 900}} className="basier-p3">
                    {data.data.message}
                </div>

                <div>
                {['coin_exist', 'type_exist'].includes(data.type) ?

                    <ul>
                    {data.data.scope.map((val, key) => {
                        return (

                            <li key={key}>
                            <div  style={{display: 'flex', alignItems: 'center'}}>
                                <div style={{marginRight: 12, fontWeight: 900, color: 'black'}}>{val['ticker']}:</div>
                                <div>Line{val['lines'].length > 1 ? 's' : null} {val['lines'].join(', ')}</div>
                            </div>
                            </li>

                        )
                    })}
                    </ul>
                
                

                :

                ['number_check'].includes(data.type) ?

                    <ul>
                    {Object.keys(data.data.scope).map((val, key) => {
                        return (

                            <li key={key}>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <div>
                                       <span style={{fontWeight: 900, color: 'black', marginRight: 12}}>Line {val}: </span>{data.data.scope[val].join(' ')}
                                </div>
                            </div>
                            </li>

                        )
                    })}
                    </ul>
                

                :

                ['confirm_dates', 'fee_currency'].includes(data.type) ?
                    <ul>
                        <li>
                            <span style={{fontWeight: 900, color: 'black', marginRight: 12}}>Lines: </span> {data.data.scope.join(', ')}
                        </li>
                    </ul>
                    
                    :

                    null
                }
                </div>
            </div>

        )

    }


    render () {
        console.log('errors', this.props.data['errors'])
        return (
            
            <div style={{paddingRight: 12, marginTop: 24, height: '100%'}}>
                <div style={{width: '100%', display: 'flex', justifyContent: 'center', textAlign: 'center', alignItems: 'center', flexDirection: 'column'}}>
                    <img src={errorIcon} alt="" />
                    <div style={{marginTop: 12}} className="basier-p3">There was an error in your upload. Please check below the errors that were noted.</div>
                </div>


                <div style={{marginTop: 24, paddingLeft: 12, paddingRight:12}}>

                    {this.props.data['errors'].map((val, key) => {
                        
                        return (
                        <div key={key} style={{width: '100%', marginTop: 12}}>
                            {this.renderError(val)}
                        </div>
                        )

                    })
                    
                    }

                </div>

            </div>
            
        )
    }


}

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = dispatch => {
    return {
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(ErrorUpload)
