import React from "react";
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import * as actions from "../../../../../store/actions/interactions";
import AssetDetail from './assetDetail'
import AssetExpansion from './AssetExpansion'
import Divider from '@material-ui/core/Divider';
import {backendUrl } from '../../../../../variables'
import * as dataActions from "../../../../../store/actions/data";
import axios from 'axios';

class AssetTable extends React.PureComponent {

    state = {
        menuOpen: null,
        mouseX: null,
        mouseY: null,
    }
    
    onClick = (e, id) => {
        e.preventDefault();
        e.persist();

        this.setState({
            mouseX: null,
            mouseY: null,
            menuOpen: null,
          });
        
        setTimeout( () => {
            
            this.setState({
                mouseX: e.pageX,
                mouseY: e.pageY,
                menuOpen: id, 
            });

        }, 1)
        
    }
    handleClose = () => {
        this.setState({
            mouseX: null,
            mouseY: null,
            menuOpen: null,
          });
    }



    handleModalOpen = (e) => {

        this.props.toggleAssetModal({open: true, coin: e.currentTarget.getAttribute('value')});
        
    }

    copyValue = (type, item) => {

        let toCopy = ""
        try {toCopy = item[type]} catch(e) {}
        navigator.permissions.query({name: "clipboard-write"}).then(result => {
            if (result.state == "granted" || result.state == "prompt") {
                navigator.clipboard.writeText(toCopy).then( () => {this.props.showSnack({message: 'Value Copied.', variant: "success"})})
            }
          });
        this.handleClose()

    }

    clearBalance = (coin, type, pId, user_id, token) => {

        let headers = { "content-type": "application/json" };
            if (token !== null && token !== undefined) {
        
                headers['Authorization'] = `Token ${token}`
        
            }
    
        const url = `${backendUrl}/v1/balances/clear/`
        const data = {
            pId: pId, 
            cId: coin, 
            user_id: user_id, 
            type: type, 
        }
        axios.post(url, data, {headers: headers})
        .then(j => {
            this.props.componentDataFetch('update', 2, this.props.currency, this.props.user_id, this.props.portfolioId, {}, {}, this.props.bitcoinPf, this.props.token)
            this.props.showSnack({message: 'Balance successfully cleared.', variant: "success"})
        })
    
    }

    clearBalanceConfirm = (type, item) => {
        this.handleClose()
        const clearType = type.toLowerCase();
        const pId = this.props.portfolioId;
        const user_id = this.props.user_id;
        const token = this.props.token;
        const coin = item.coin;

        this.props.toggleModal(
            {
                type: 'confirmation_modal', 
                payload: {
                    message: `Are you sure you want to clear the ${item.coin_ticker} balance?`,
                    handleConfirm: () => this.clearBalance(coin, clearType, pId, user_id, token),
                }, 
                open: true
            }
        )
    }


    menuItems = [
        ['Copy Amount', (item) => this.copyValue('balance', item)],
        ['Copy Value', (item) => this.copyValue('total_value', item)],
        ['Copy Price', (item) => this.copyValue('current_price', item)],
        ['Clear Balance As', (as, item) => this.clearBalanceConfirm(as, item), ['Fee', 'Loss']], 
    ]

    menuItemsClosed = [
        ['Copy Amount', (item) => this.copyValue('balance', item)],
        ['Copy Value', (item) => this.copyValue('total_value', item)],
        ['Copy Price', (item) => this.copyValue('current_price', item)],
    ]

    


    state = {
        active: [],
        exited: [], 
        small: [],
        showErrors: true,
    }
    
    render() {

        
        return (

        

        <div>
           {
               this.props.active.filter(v => v.IsCustom === false).map((item, index) => { 

            const roi = item.total_value / (item.purchase_price * item.balance)
            const history = this.props.history === null ? undefined : this.props.history[item.coin]
            const total_value = this.props.items.total_value
            return (

                <AssetDetail total_value={total_value} handleClose={this.handleClose} menuOpen={this.state.menuOpen} mouseX={this.state.mouseX} mouseY={this.state.mouseY} openContext={(e) => this.onClick(e, item.coin)} menuItems={this.menuItems} status="active" currency_symbol={this.props.currency_symbol} handleModalOpen={this.handleModalOpen} key={index} index={index} item={item} history={history} roi={roi} />                
            
            )
    

            })
        }
        
        {this.props.small.filter(v => v.IsCustom === false).length > 0 &&
        <div style={{marginTop: 24}}>
            <AssetExpansion title="Small balances">

                {
                    this.props.small.filter(v => v.IsCustom === false).map((item, index) => { 

                    const roi = item.total_value / (item.purchase_price * item.balance)
                    const history = this.props.history === null ? undefined : this.props.history[item.coin]
                    const total_value = this.props.items.total_value
                    return (

                        <AssetDetail total_value={total_value} handleClose={this.handleClose} menuOpen={this.state.menuOpen} mouseX={this.state.mouseX} mouseY={this.state.mouseY} openContext={(e) => this.onClick(e, item.coin)} menuItems={this.menuItems} status="small" contained={false} currency_symbol={this.props.currency_symbol} handleModalOpen={this.handleModalOpen} key={index} index={index} item={item} history={history} roi={roi} />                
                    
                    )


                    })
                }

            </AssetExpansion>
        </div>
        }
        
        
        {this.props.exited.filter(v => v.error == false && v.IsCustom === false).length > 0 && 
        <div style={{marginTop: 6}}>
            <AssetExpansion title="Exited & Dust balances">

                {
                    this.props.exited.filter(v => v.error == false && v.IsCustom === false).map((item, index) => { 

                    const roi = item.total_value / (item.purchase_price * item.balance)
                    const history = this.props.history === null ? undefined : this.props.history[item.coin]
                    const total_value = this.props.items.total_value
                    return (

                        <AssetDetail total_value={total_value} handleClose={this.handleClose} menuOpen={this.state.menuOpen} mouseX={this.state.mouseX} mouseY={this.state.mouseY} openContext={(e) => this.onClick(e, item.coin)} menuItems={this.menuItemsClosed} status="exit" contained={false} currency_symbol={this.props.currency_symbol} handleModalOpen={this.handleModalOpen} key={index} index={index} item={item} history={history} roi={roi} />                
                    
                    )


                    })
                }

            </AssetExpansion>
        </div>
        }

        {[...this.props.active.filter(v => v.IsCustom === true && v.is_nft !== true), ...this.props.small.filter(v => v.IsCustom === true && v.is_nft !== true), ...this.props.exited.filter(v => v.IsCustom === true && v.is_nft !== true)].length > 0 && 
        <div style={{marginTop: 6}}>
            <AssetExpansion title="Unsupported Coins">

                {
                    [...this.props.active.filter(v => v.IsCustom === true && v.is_nft !== true), ...this.props.small.filter(v => v.IsCustom === true && v.is_nft !== true), ...this.props.exited.filter(v => v.IsCustom === true && v.is_nft !== true)].map((item, index) => { 

                    const roi = item.total_value / (item.purchase_price * item.balance)
                    const history = this.props.history === null ? undefined : this.props.history[item.coin]
                    const total_value = this.props.items.total_value
                    return (

                        <AssetDetail total_value={total_value} handleClose={this.handleClose} menuOpen={this.state.menuOpen} mouseX={this.state.mouseX} mouseY={this.state.mouseY} openContext={(e) => this.onClick(e, item.coin)} menuItems={this.menuItemsClosed} status="exit" contained={false} currency_symbol={this.props.currency_symbol} handleModalOpen={this.handleModalOpen} key={index} index={index} item={item} history={history} roi={roi} unsupported={true}/>                
                    
                    )


                    })
                }

            </AssetExpansion>
        </div>
        }
        
        {this.props.exited.filter(v => v.error == true).length > 0 && 
        <div style={{marginTop: 6}}>


            <AssetExpansion title="Errors & imprecisions" error={true} open={this.state.showErrors}>

                {
                    this.props.exited.filter(v => v.error == true).map((item, index) => { 

                    const roi = item.total_value / (item.purchase_price * item.balance)
                    const history = this.props.history === null ? undefined : this.props.history[item.coin]
                    const total_value = this.props.items.total_value
                    return (

                        <AssetDetail total_value={total_value} handleClose={this.handleClose} menuOpen={this.state.menuOpen} mouseX={this.state.mouseX} mouseY={this.state.mouseY} openContext={(e) => this.onClick(e, item.coin)} menuItems={this.menuItemsClosed} status="exit" errors={true} contained={false} currency_symbol={this.props.currency_symbol} handleModalOpen={this.handleModalOpen} key={index} index={index} item={item} history={history} roi={roi} />                
                    
                    )


                    })
                }

            </AssetExpansion>
        </div>
        }


    </div>

        )
    }
}

const mapStateToProps = state => {
    return {
        currency_symbol: state.auth.currency.symbol,
        history: state.data.portfoliosHist, 
        user_id: state.auth.user_id, 
        token: state.auth.token,
        currency: state.auth.currency.id,
        inBictoin: state.data.bitcoinPf === 'bitcoin',
        bitcoinPf: state.data.bitcoinPf
    };
};

const mapDispatchToProps = dispatch => {
    return {
        toggleAssetModal: (currency, scope) => 
            dispatch(actions.toggleAssetModal(currency, scope)), 
        showSnack: (data) => dispatch(actions.showSnack(data)),
        toggleModal: (data) => 
            dispatch(actions.toggleModal(data)), 
        componentDataFetch: (scope, view, currency, user, portfolio, globalGraphData, portfolioGraphData, inBitcoin) =>
            dispatch(dataActions.componentDataFetch(scope, view, currency, user, portfolio, globalGraphData, portfolioGraphData, inBitcoin)), 
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AssetTable);