import React from "react";
import { connect } from "react-redux";
import { loadData } from "../../../../../helpers/helpers";
import { backendUrl } from "../../../../../variables";
import { widget } from "../../../../../charting_library/charting_library.js";
import * as actions from "../../../../../store/actions/data";

const getValue = (dict, value) => {
  let ret;
  try {
    ret = dict[value];
  } catch (e) {}
  return ret;
};

class TVWrapper extends React.PureComponent {
  state = {
    portName: "",
  };
  tvWidget = null;

  configurationData = {
    supported_resolutions: ["1D", "3D", "1W", "1M"],
    exchanges: [
      {
        value: "Wisly",
        name: "Wisly",
        desc: "Wisly",
      },
    ],
    symbols_types: [
      {
        name: "crypto",
        value: "crypto",
      },
    ],
  };

  dataFeed = {
    onReady: (callback) => {

      setTimeout(() => callback(this.configurationData));
    },
    searchSymbols: (userInput, exchange, symbolType, onResultReadyCallback) => {

    },
    resolveSymbol: (
      symbolName,
      onSymbolResolvedCallback,
      onResolveErrorCallback
    ) => {
      const symbolInfo = {
        name: symbolName,
        description: this.state.portName,
        type: "crypto",
        session: "24x7",
        timezone: "UTC",
        exchange: "",
        minmov: 1,
        pricescale: 100,
        has_intraday: false,
        has_no_volume: true,
        has_daily: true,
        has_weekly_and_monthly: false,
        supported_resolutions: this.configurationData.supported_resolutions,
        volume_precision: 0,
        data_status: "endofday",
        //disable_resolution_rebuild: false,
      };


      onSymbolResolvedCallback(symbolInfo);
    },
    getBars: (
      symbolInfo,
      resolution,
      from,
      to,
      onHistoryCallback,
      onErrorCallback,
      firstDataRequest
    ) => {

      /* if (!firstDataRequest) {
        return;
      } */

      this.getBars().then((res) => {
        if (res.values.length === 0) {
          // "noData" should be set if there is no data in the requested period.
          onHistoryCallback([], { noData: true });
          return;
        }
        let bars = [];

        res.values.forEach((bar) => {
          if (bar.time >= from && bar.time < to) {
            bars = [
              ...bars,
              {
                time: bar.time * 1000,
                low: bar.low,
                high: bar.high,
                open: bar.open,
                close: bar.close,
              },
            ];
          }
        });

        if (bars.length === 0) {
          onHistoryCallback([], { noData: true });
          return;
        }

        //console.log('bars', bars)
        onHistoryCallback(bars, { noData: false });
      });
    },
    subscribeBars: (
      symbolInfo,
      resolution,
      onRealtimeCallback,
      subscribeUID,
      onResetCacheNeededCallback
    ) => {
      
    },
    unsubscribeBars: (subscriberUID) => {
      
    },
  };
  componentDidMount() {
    const portName = this.props.list_portfolios.filter(
      (v) => v.id == this.props.portfolioId
    )[0].name;
    const portCurrency = this.props.inBitcoin ? "BTC" : this.props.currency_id;
    this.setState((state) => ({ ...state, portName: portName }));
    const g_userID = this.props.user_id;
    const g_storageSaveListID = `${this.props.currency_id}${this.props.portfolioId}`;
    const tvWidget = new widget({
      debug: false,
      symbol: `${portName}:${portCurrency}`, // default symbol
      interval: "1D", // default interval
      fullscreen: false, // displays the chart in the fullscreen mode
      autosize: true,
      container_id: "tv_chart_container",
      datafeed: this.dataFeed,
      auto_save_delay: 1,
      library_path: "../../../../../charting_library/",
      disabled_features: [
        "header_symbol_search",
        "header_compare",
        "study_templates",
      ],
      enabled_features: ["use_localstorage_for_settings"],
      /* save_load_adapter: {
        saveChart: function (data) {

          console.log("TRYING TO SAVE")
          var promise = Promise.resolve();

          function saveChartData(data) {
            var timestamp = Math.floor(new Date().getTime() / 1000, 0);
            var id = g_userID + "_" + data.name;
            var saveObj = {
              id: id,
              name: data.name,
              symbol: data.symbol,
              resolution: data.resolution,
              timestamp: timestamp,
            };
            if (!data.id) data.id = id;
            if (!data.timestamp) data.timestamp = timestamp;
            var saveListStr = localStorage.getItem(g_storageSaveListID);
            var saveList = {};
            if (saveListStr) {
              saveList = JSON.parse(saveListStr);
            }
            saveList[id] = saveObj;
            localStorage.setItem(g_storageSaveListID, JSON.stringify(saveList));
            localStorage.setItem(id, data.content);
            return id;
          }

          return promise.then(function () {
            return saveChartData(data);
          });
        },
        removeChart: function (chartId) {
          var promise = Promise.resolve();
          function removeChartData(chartId) {
            var saveListStr = localStorage.getItem(g_storageSaveListID);
            var saveList = {};
            if (saveListStr) {
              saveList = JSON.parse(saveListStr);
              if (saveList[chartId]) {
                delete saveList[chartId];
              }
            }
            localStorage.setItem(g_storageSaveListID, JSON.stringify(saveList));
            localStorage.removeItem(chartId);
            return null;
          }
          return promise.then(function () {
            return removeChartData(chartId);
          });
        },
        getAllCharts: function () {
          let promise = Promise.resolve();
          function loadChartList() {
            var saveListStr = localStorage.getItem(g_storageSaveListID);
            var chartList = [];
            var returnList = [];
            if (saveListStr) {
              var saveList = JSON.parse(saveListStr);
              chartList = Object.keys(saveList);

              for (var x = 0; x < chartList.length; x++) {
                var chartObj = saveList[chartList[x]];
                if (chartObj) returnList.push(chartObj);
              }
            }
            return returnList;
          }

          return promise.then(function () {
            return loadChartList();
          });
        },
        getChartContent: function (chartId) {
          let promise = Promise.resolve();
          function getChartData(chartId) {
            var chartData = localStorage.getItem(chartId);
            return chartData;
          }
          return promise.then(function () {
            return getChartData(chartId);
          });
        },
      }, */
    });
    this.tvWidget = tvWidget;

    tvWidget.onChartReady(() => {

      let store = JSON.parse(localStorage.getItem("savedTV"))
      if (store !== undefined) {
        if (getValue(store, `${this.props.portfolioId}${this.props.inBitcoin ? 'BTC' : this.props.currency_id}`) !== undefined) {
          tvWidget.load(store[`${this.props.portfolioId}${this.props.inBitcoin ? 'BTC' : this.props.currency_id}`])
        } else {
          tvWidget.save(this.onSave)
        }
     }

      
      tvWidget.subscribe('onAutoSaveNeeded', () => { 
        tvWidget.save(this.onSave)
       })
    });

    //this.getBars();
  }

  onSave = (state) => {

    let store = JSON.parse(localStorage.getItem("savedTV"))
    let newStore = {};
    if (store !== undefined) {
      newStore = {...store, [`${this.props.portfolioId}${this.props.inBitcoin ? 'BTC' : this.props.currency_id}`]: state}
    } else {
      newStore[`${this.props.portfolioId}${this.props.inBitcoin ? 'BTC' : this.props.currency_id}`] = state
    }
    localStorage.setItem('savedTV', JSON.stringify(newStore))

  }

  getBars = () =>
    new Promise((resolve, reject) => {
      const correctedCurrency = this.props.inBitcoin
        ? "bitcoin"
        : this.props.currency_id;
      let headers = { "content-type": "application/json" };
      headers["Authorization"] = `Token ${this.props.token}`;

      if (
        getValue(
          this.props.tvData[this.props.portfolioId],
          correctedCurrency
        ) === undefined
      ) {
        loadData(
          `${backendUrl}/v1/portfolio/balances/ohlc/${this.props.portfolioId}/?c=${correctedCurrency}`,
          headers
        ).then((res) => {
          //console.log("dddd", res);
          this.props.saveTv({
            label: this.props.portfolioId,
            data: res["data"],
          });
          resolve(res["data"]);
        });
      } else {
        resolve(this.props.tvData[this.props.portfolioId][correctedCurrency]);
      }
    });

  componentWillUnmount() {
    if (this.tvWidget !== null) {
      this.tvWidget.remove();
      this.tvWidget = null;
    }
  }

  render() {
    return (
      <div
        style={{ width: "100%", height: 600 }}
        id={"tv_chart_container"}
        className={"TVChartContainer"}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user_id: state.auth.user_id,
    token: state.auth.token,
    portfolioId: state.data.menu_selected,
    list_portfolios: state.auth.list_portfolios,
    currency_id: state.auth.currency.id,
    tvData: state.data.tvData,
    inBitcoin: state.data.bitcoinPf === "bitcoin",
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveTv: (data) => dispatch(actions.saveTv(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TVWrapper);
