import React from 'react';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import Tooltip from '../../../../AppWide/Tooltip';
import questionMark from '../../../../../Media/questionMark.svg'
import currencyDict from '../../../../../helpers/currency.json'


class BalanceGraph extends React.PureComponent {

    renderApproxZero = (bal) => {
        return (

        <div style={{display: 'flex', alignItems: 'center'}}>
            <div style={{marginRight: 6}}>&lt; 1$</div>
            <div>
                <Tooltip title={`Actual balance: ${bal}`} placement="right">
                    <img style={{height: 20, width: 20, marginLeft: 0, marginRight: 12}} src={questionMark} alt=""/>
                </Tooltip>
            </div>
        </div>

        )
    }


    componentDidMount() {
        
    }
    
    render() {


        return (
            
            this.props.balances !== undefined && this.props.balances[this.props.user_id] !== undefined ?

            <div>

                {Object.keys(this.props.balances[this.props.user_id]['storage']).length > 0  ? 

                    Object.keys(this.props.balances[this.props.user_id]['storage']).map((val, key) => {
                        const values = this.props.balances[this.props.user_id]['storage'][val]['wallets'].filter(v => Math.abs(v.value >= 0.1))
                        let len = 1;
                        try {
                            len = values.length
                        } catch(e) {
                            len = 1;
                        }
                        return (
                            len === 0 ? 
                            null
                            :
                            <div key={key} style={{minHeight: 300, width: '100%'}}>
                                <div style={{display: 'flex', alignItems: 'center', marginRight: 48, paddingBottom: 12, paddingTop: 12, paddingLeft: 12, backgroundColor: 'rgba(243, 245, 246, 0.5)' }}>
                                    <div style={{marginRight: 12}}><img src={this.props.balances[this.props.user_id]['storage'][val]['details']['image']} style={{height: 24, width: 24}} alt="coin"/></div>
                                    <h4 style={{margin: 0}}>{this.props.balances[this.props.user_id]['storage'][val]['details']['name']}</h4>
                                </div>
                                
                                <div className="basier-p4-caps" style={{marginTop: 24, marginBottom: 6, display: 'flex', alignItems: 'center', marginLeft: 20}}>
                                    <div style={{width: '25%'}}>Name</div>                                
                                    <div style={{width: '75%'}}>Balance</div>
                                    
                                </div>

                                {
                                    this.props.balances[this.props.user_id]['storage'][val]['wallets'].map((val, key) => {
                                        
                                        return (
                                            Math.abs(val.value) < 0.1 ? null :
                                            <div key={key}>
                                                <div style={{marginTop: 24, marginBottom: 6, display: 'flex', alignItems: 'center', marginLeft: 20}}>
                                                    <div style={{width: '25%',  color: 'black', textTransform: 'uppercase'}} className="basier-p3">{val.wallet_name}</div>
                                                    <div style={{width: '75%', display: 'flex', alignItems: 'center'}}>

                                                        <div style={{color: 'black'}} className="basier-p3">
                                                            {
                                                                val.value < 1 && val.value > -1 ? 
                                                                    this.renderApproxZero(val.balance.toLocaleString('en', {maximumFractionDigits: 8, minimumFractionDigits: 8}))
                                                                :
                                                                val.balance > 1 ?
                                                                val.balance.toLocaleString('en', {maximumFractionDigits: 2, minimumFractionDigits: 2})
                                                                :
                                                                val.balance.toLocaleString('en', {maximumFractionDigits: 8, minimumFractionDigits: 8})
                                                            }
                                                        </div>
                                                        <div style={{marginLeft: 6, display: 'flex', alignItems: 'center'}} className="basier-p3">

                                                            <div>{val.coin_ticker}</div>
                                                        
                                                        </div>

                                                    </div>
                                                    
                                                </div>

                                            </div>
                                        )
                                    })
                                }
                                
                            </div>
                        )
                    })
                    
                    :
                    
                    <div style={{height: 50, fontStyle: 'italic'}}>You do not have any storage balances yet. Add storage data to your transactions to see your balances here.</div>
                }

                </div>
                    
                :

                null
                
            
        )


    }   
};
  

const mapStateToProps = state => {
    return {
        
        balances: state.data.wallet_balance

    };
};

const mapDispatchToProps = dispatch => {
    return {
        
        
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BalanceGraph);


