import React from "react";
import { connect } from "react-redux";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Button from "@material-ui/core/Button";
import { backendUrl } from "../../../variables";
import TablePortfolioSelector from "../../AppWide/TablePortfolioSelector";
import okGreen from "../../../Media/okGreen.svg";
import cancelRed from "../../../Media/cancelRed.svg";
import loader from "../../../Media/contentLoader.svg";
import restore from "../../../Media/restore.svg";
const timeoutLength = 100;

/* const headers = [
    { label: 'User', key: 'user' },
    { label: 'Date', key: 'date' },
    { label: 'Type', key: 'type' },
    { label: 'Base', key: 'base_coin' },
    { label: 'Amount', key: 'amount' },
    { label: 'Price', key: 'unit_price' },
    { label: 'Quote', key: 'quote_coin' },
    { label: 'Fee', key: 'fee' },
    { label: 'Fee_Currency', key: 'fee_curr' },
    { label: 'Comments', key: 'comments' },
    { label: 'Account', key: 'account' },
    { label: 'Destination_User', key: 'dest_user' },
    { label: 'Destination_Account', key: 'dest_account' },
  ]; */

class SelectAction extends React.PureComponent {
  state = {
    open: false,
    onMenu: false,
    exportData: "no data",
    editAssign: false,
    assignLoad: false,
  };

  assignAll = () => {
    if (this.state.editAssign === true) {
      this.setState((state) => ({ ...state, editAssign: false }));
      this.props.setAssign(null, null);
    } else {
      this.setState((state) => ({ ...state, editAssign: true }));
      this.props.setAssign("create", null);
    }
  };

  updatePortfolioBulk = (val) => {
    this.props.setAssign(undefined, val.portfolio);
  };

  saveStreamCSV(filename, text) {
    this.setState({ downloadingCSV: false });
    if (window.navigator.msSaveBlob) {
      // IE 10 and later, and Edge.
      var blobObject = new Blob([text], { type: "text/csv" });
      window.navigator.msSaveBlob(blobObject, filename);
    } else {
      // Everthing else (except old IE).
      // Create a dummy anchor (with a download attribute) to click.
      var anchor = document.createElement("a");
      anchor.download = filename;
      if (window.URL.createObjectURL) {
        // Everything else new.
        var blobObject = new Blob([text], { type: "text/csv" });
        anchor.href = window.URL.createObjectURL(blobObject);
      } else {
        // Fallback for older browsers (limited to 2MB on post-2010 Chrome).
        // Load up the data into the URI for "download."
        anchor.href = "data:text/csv;charset=utf-8," + encodeURIComponent(text);
      }
      // Now, click it.
      if (document.createEvent) {
        var event = document.createEvent("MouseEvents");
        event.initEvent("click", true, true);
        anchor.dispatchEvent(event);
      } else {
        anchor.click();
      }
    }
  }

  exportData() {
    let headers = { "content-type": "application/json" };
    if (this.props.token !== null && this.props.token !== undefined) {
      headers["Authorization"] = `Token ${this.props.token}`;
    }

    fetch(
      `${backendUrl}/v1/transactions/?u=${this.props.user_id}&p=${this.props.portfolioId}&export=export`,
      { headers: headers }
    )
      .then((response) => response.text())
      .then((responseText) =>
        this.saveStreamCSV(`export_${this.props.user_id}.csv`, responseText)
      )
      .catch((error) => {
        console.error("CSV handleDownloadClick:", error);
      });
  }

  updateState = (data, done) => {
    this.setState(
      {
        exportData: data,
      },
      () => {
        done();
      }
    );
  };

  handleHoverOn = () => {
    this.setState((state) => ({ open: true }));
  };

  emptyMenu = () => {
    this.props.setMenuPortfolio(null);
    this.props.setMenuPath(null);
  };

  goToLink = (destination) => {
    this.emptyMenu();
    this.props.history.push(`/${destination}`);
    this.handleClose();
  };

  handleHoverOff = () => {
    setTimeout(() => {
      this.setState((state) => ({ open: false }));
    }, timeoutLength);
  };

  handleHoverMenuOn = () => {
    this.setState((state) => ({ onMenu: true }));
  };

  handleHoverMenuOff = () => {
    setTimeout(() => {
      this.setState((state) => ({ onMenu: false }));
    }, timeoutLength);
  };

  handleToggle = () => {
    this.setState((state) => ({ open: !state.open }));
  };

  handleClose = (event) => {
    this.setState({ open: false });
  };

  restoreAction = () => {
    this.props.actionAll("restore");
  };

  commitAll = () => {
    this.setState((state) => ({ ...state, assignLoad: true }));
    this.props
      .actionAll("assign")
      .then((res) => {
        this.setState((state) => ({ ...state, assignLoad: false, editAssign: false }));
      })
      .catch((e) => {
        this.setState((state) => ({ ...state, assignLoad: false, editAssign: false }));
      });
  };

  render() {
    const open = this.state.open || this.state.onMenu;

    const isSelected = this.props.fullSelect
      ? true
      : this.props.selectAll
      ? true
      : Object.values(this.props.selected).filter((x) => x === true).length > 0
      ? true
      : false;

    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          {this.props.selected !== null && this.props.selected !== undefined ? (
            <div style={{ display: "flex", alignItems: "center" }}>
              <div className="basier-p4-caps">
                {this.props.fullSelect
                  ? "All"
                  : Object.values(this.props.selected).filter((x) => x === true)
                      .length}{" "}
                Selected
              </div>
              {this.props.selectAll === true ? (
                this.props.fullSelect ? null : (
                  <div
                    onClick={this.props.toggleFull}
                    style={{
                      marginLeft: 12,
                      color: "#0078DB",
                      cursor: "pointer",
                    }}
                    className="opacity-hover"
                  >
                    Select All (Across Pages) |
                  </div>
                )
              ) : null}
            </div>
          ) : null}
          {isSelected ? (
            this.state.editAssign ? (
              <React.Fragment>
                <div
                  className="opacity-hover"
                  style={{ marginLeft: 12, marginRight: 24, color: "black" }}
                >
                  Assign all to:
                </div>
                <div style={{ width: 250 }}>
                  <TablePortfolioSelector
                    updateServer={(data) => this.updatePortfolioBulk(data)}
                    value={null}
                    data={this.props.portfolios}
                    forceSelect={true}
                  />
                </div>

                {this.state.assignLoad ? (
                  <div style={{ marginLeft: 6, marginRight: 6 }}>
                    <img
                      src={loader}
                      style={{ height: 24, width: 24 }}
                      alt="ok"
                    />
                  </div>
                ) : (
                  <React.Fragment>
                    <div
                      className="opacity-hover"
                      style={{
                        marginLeft: 6,
                        marginRight: 6,
                        cursor: "pointer",
                      }}
                      onClick={() => this.commitAll()}
                    >
                      <img
                        src={okGreen}
                        style={{ height: 24, width: 24 }}
                        alt="ok"
                      />
                    </div>
                    <div
                      className="opacity-hover"
                      style={{ marginRight: 12, cursor: "pointer" }}
                      onClick={() => this.assignAll()}
                    >
                      <img
                        src={cancelRed}
                        style={{ height: 24, width: 24 }}
                        alt="no"
                      />
                    </div>
                  </React.Fragment>
                )}
              </React.Fragment>
            ) : (
              <React.Fragment>
                <div
                  onClick={() => this.props.actionAll("validate")}
                  className="opacity-hover"
                  style={{
                    marginLeft: 12,
                    marginRight: 12,
                    color: "#0078DB",
                    cursor: "pointer",
                  }}
                >
                  Validate all
                </div>

                <div>|</div>

                <div
                  onClick={() => this.props.actionAll("delete")}
                  className="opacity-hover"
                  style={{
                    marginLeft: 12,
                    marginRight: 12,
                    color: "#0078DB",
                    cursor: "pointer",
                  }}
                >
                  Delete All
                </div>

                <div>|</div>

                <div
                  onClick={() => this.assignAll()}
                  className="opacity-hover"
                  style={{
                    marginLeft: 12,
                    marginRight: 12,
                    color: "#0078DB",
                    cursor: "pointer",
                  }}
                >
                  Assign all to portfolio
                </div>

                {/* <div>|</div>

                <div
                  onClick={() => this.props.actionAll("clear")}
                  className="opacity-hover"
                  style={{
                    marginLeft: 12,
                    marginRight: 12,
                    color: "#0078DB",
                    cursor: "pointer",
                  }}
                >
                  Clear assigned portfolio
                </div> */}
              </React.Fragment>
            )
          ) : null}
        </div>
        {(this.props.scopeView === "deleted" ||
          (this.props.transactions.selectable === 0 &&
            this.props.transactions.transactions.filter(
              (x) => x.include === false
            ).length > 0)) && (
          <div
            className="opacity-hover"
            onClick={this.restoreAction}
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: 6,
              cursor: "pointer",
            }}
          >
            <img src={restore} alt="" style={{ height: 20 }} />
            <div
              style={{ marginLeft: 6, color: "#4CDAC1" }}
              className="basier-p4-caps"
            >
              Restore All Deleted
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    user_id: state.auth.user_id,
    portfolios: state.auth.list_portfolios,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectAction);
