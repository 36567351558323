import React, { Component } from "react";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import nftIcon from "../../../../../../Media/nftIcon.svg";
import LinearProgress from "@material-ui/core/LinearProgress";
import { withStyles } from "@material-ui/core/styles";
//import { loadData } from '../../../../../../helpers/helpers';
import { backendUrl, frontTok } from "../../../../../../variables";
import Axios from "axios";

const getValue = (object, keys, defaultValue) => {
  let ret;
  let i = 0;
  const len = keys.length;
  try {
    while (i < len) {
      ret = object[keys[i]];
      object = ret;
      i++;
    }
  } catch (e) {}
  if (ret === undefined && defaultValue !== undefined) {
    ret = defaultValue;
  }
  return ret;
};

const ColorLinearProgress = withStyles({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: "#E1E5E9",
  },
  barColorPrimary: {
    backgroundColor: "#6A7E93",
  },
})(LinearProgress);

class NFTViewer extends Component {
  state = {
    originalCurrent: this.props.data.original_purchase,
    currentPrice: this.props.data.purchase_price,
    show: false,
  };

  checkPrices = () => {
    // if (!this.props.data.link_price) return;
    // const cache = this.props.priceCache;
    // const existing = getValue(cache, [this.props.data.contract_id, this.props.data.token_id], null)
    // if (existing) {
    //     this.setState(state => ({...state, originalCurrent: existing, currentPrice: existing * this.props.data.quote_price, show: true}))
    //     return
    // }
  };

  componentDidMount() {
    // if (this.props.data.link_price) {
    //     this.checkPrices();
    // }

    const prices = this.props.prices
    if (prices) {
      let thisPrice = getValue(prices, [this.props.data.network, 'prices', this.props.data.id], null)
      if (thisPrice === null) {
        thisPrice = this.props.data.original_purchase || 0
        const adjustedPrice = this.props.data.purchase_price
        this.setState(state => ({...state, originalCurrent: thisPrice, currentPrice: adjustedPrice, show: true }))
      } else {
        const adjustedPrice = thisPrice * this.props.data.quote_price
        this.setState(state => ({...state, originalCurrent: thisPrice, currentPrice: adjustedPrice, show: true }))
      }

    }

  }
  componentDidUpdate(prevProps) {
    // if (this.props.data.link_price) {
    //   const newData = getValue(this.props.priceCache, [this.props.data.contract_id]);
    //   const oldData = getValue(prevProps.priceCache, [prevProps.data.contract_id]);
    //   if (newData !== oldData) {
    //     this.checkPrices();
    //   }
    // }
  }

  render() {
    const { data } = this.props;
    const { show, originalCurrent, currentPrice } = this.state;
    let profit = 0;
    try {
      profit = currentPrice - data.purchase_price;
    } catch (e) {}
    let originalprofit = 0;
    try {
      originalprofit = originalCurrent - data.original_purchase;
    } catch (e) {}
    return (
      <div style={styles.overallBox}>
        <div>
          <div>
            {data.image ? (
              <img
                src={data.image}
                alt="nft"
                style={{ width: 200, height: 200 }}
              />
            ) : (
              <div
                style={{
                  width: 200,
                  height: 200,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <img
                  src={nftIcon}
                  style={{ height: 40, width: 40 }}
                  alt="nft"
                />
                <div
                  className="basier-p4"
                  style={{
                    color: "#6A7E93",
                    fontStyle: "italic",
                    marginTop: 12,
                  }}
                >
                  Unavailable Preview
                </div>
              </div>
            )}
          </div>
          <div
            className="basier-p3 truncate"
            style={{ marginTop: 12, fontWeight: "bold" }}
          >
            {data.name}
          </div>
          <div style={{ marginTop: 12, marginBottom: 4 }}>
            <div className="basier-p4-caps" style={{ color: "#6A7E93" }}>
              Purchase Price
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "space-between",
            }}
          >
            <div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  src={data.quote_img}
                  alt=""
                  style={{ width: 20, height: 20 }}
                />
                <div>
                  {data.original_purchase.toLocaleString("en", {
                    minimumFractionDigits: data.original_purchase > 1 ? 2 : 2,
                    maximumFractionDigits: data.original_purchase > 1 ? 2 : 6,
                  })}
                </div>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", marginTop: 3 }}
              >
                <div style={{ width: 2, height: 2 }}></div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "flex-end",
                flexDirection: "column",
              }}
            >
              <div>
                {this.props.currency_symbol}
                {data.purchase_price.toLocaleString("en", {
                  minimumFractionDigits: data.purchase_price > 1 ? 2 : 2,
                  maximumFractionDigits: data.purchase_price > 1 ? 2 : 6,
                })}
              </div>
            </div>
          </div>
          <div style={{ marginTop: 6, marginBottom: 4 }}>
            <div className="basier-p4-caps" style={{ color: "#6A7E93" }}>
              Current Price
            </div>
          </div>
          {show ? (
            <div
              style={{
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "space-between",
              }}
            >
              <div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img
                    src={data.quote_img}
                    alt=""
                    style={{ width: 20, height: 20 }}
                  />
                  <div>
                    {originalCurrent.toLocaleString("en", {
                      minimumFractionDigits: originalCurrent > 1 ? 2 : 2,
                      maximumFractionDigits: originalCurrent > 1 ? 2 : 6,
                    })}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: 3,
                  }}
                >
                  <div style={{ width: 2, height: 2 }}></div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      color:
                        originalprofit > 0
                          ? "#4CDAC1"
                          : originalprofit < 0
                          ? "#F9716B"
                          : "#6A7E93",
                    }}
                  >
                    {originalprofit > 0 ? (
                      <ArrowDropUpIcon style={{ fontSize: 14 }} />
                    ) : originalprofit < 0 ? (
                      <ArrowDropDownIcon style={{ fontSize: 14 }} />
                    ) : null}
                    <div className="basier-p4">
                      {Math.abs(originalprofit).toLocaleString("en", {
                        minimumFractionDigits:
                          Math.abs(originalprofit) > 1 ? 2 : 2,
                        maximumFractionDigits:
                          Math.abs(originalprofit) > 1 ? 2 : 6,
                      })}
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-end",
                  flexDirection: "column",
                }}
              >
                <div>
                  {this.props.currency_symbol}
                  {currentPrice.toLocaleString("en", {
                    minimumFractionDigits: currentPrice > 1 ? 2 : 2,
                    maximumFractionDigits: currentPrice > 1 ? 2 : 6,
                  })}
                </div>
                <div className="basier-p4">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      color:
                        profit > 0
                          ? "#4CDAC1"
                          : profit < 0
                          ? "#F9716B"
                          : "#6A7E93",
                      marginTop: 6,
                    }}
                  >
                    {profit > 0 ? (
                      <ArrowDropUpIcon style={{ fontSize: 20 }} />
                    ) : profit < 0 ? (
                      <ArrowDropDownIcon style={{ fontSize: 20 }} />
                    ) : null}
                    {this.props.currency_symbol}
                    {Math.abs(profit).toLocaleString("en", {
                      minimumFractionDigits: Math.abs(profit) > 1 ? 2 : 2,
                      maximumFractionDigits: Math.abs(profit) > 1 ? 2 : 6,
                    })}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div
              style={{ display: "flex", alignItems: "center", marginTop: 12 }}
            >
              <div style={{ width: "100%" }}>
                <ColorLinearProgress />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const styles = {
  overallBox: {
    minHeight: 412,
    maxHeight: 412,
    minWidth: 250,
    maxWidth: 250,
    backgroundColor: "white",
    borderRadius: 20,
    marginTop: 10,
    marginBottom: 10,
    marginLeft: 4,
    marginRight: 4,
    display: "flex",
    flexDirection: "column",
    boxShadow: "0px 10px 10px rgba(225, 229, 233, 0.3)",
    border: "1px solid #E1E5E9",
    padding: "24px 24px",
    paddingBottom: 24,
  },
  top: {
    height: 60,
    width: "100%",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    marginBottom: 12,
  },
  bottom: {
    padding: "6px 12px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    border: "1px solid #e0a03d",
    borderRadius: 10,
    backgroundColor: "#e0a03d1A",
    marginTop: 12,
    height: 40,
  },
  bottomContainer: {},
  titleText: {
    fontWeight: "bold",
    textTransform: "uppercase",
  },
  bodyDivider: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
};
export default NFTViewer;
