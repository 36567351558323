import React from "react";
import { Route } from "react-router-dom";
import Hoc from "./hoc/hoc";

import Overview from "./containers/Overview/NewOverview";
import RegisterGo from "./containers/Overview/RegisterGo";
import NotFound from './containers/AppWide/NotFound'
import PortfolioOverview from "./containers/Portfolio/PortfolioOverview";
import AssetDetail from './containers/Portfolio/PortfolioComponents/Overview/Small/AssetDetailPage';
import TrAdd from './containers/Portfolio/PortfolioComponents/Transactions/Small/SmallTransactionEditDetail'; 
import SmallTransactionDetail from './containers/Portfolio/PortfolioComponents/Transactions/Small/TransactionAddComponents/TransactionView'
import SettingsOverview from './containers/Settings/SettingsOverview'
import InvitationOverview from './containers/Invitations/Invitation'
import WalletsOverview from './containers/Wallets/WalletRouter'
//import WalletsOverview from './containers/AppWide/Wallets/WalletOverview'
import CoinOverview from './containers/CoinView/CoinOverview'
import NotificationsOverview from './containers/Notifications/NotifOverview'
import StorageDetail from './containers/AppWide/Wallets/MobileOnly/StorageDetail';
import ExchangeDetail from './containers/AppWide/Wallets/MobileOnly/ExchangeDetail';
import AddExchange from './containers/AppWide/Wallets/MobileOnly/AddExchange';
import AddStorage from './containers/AppWide/Wallets/MobileOnly/AddStorage';
import MobileAudit from './containers/MobileOnly/MobileAuditTrail';
import Watchlist from './containers/Watchlist/WatchlistOverview';
import Reconcile from './containers/Reconcile/ReconcileOverview'
import SupportSSO from './containers/SupportSSO'
import CoinbaseFeedback from "./containers/AppWide/CoinbaseFeedback";
import Reset from "./containers/Login/Reset";

const BaseRouter = () => (
  <Hoc>
    <Route exact path="/reset/:uid/:token" component={Reset} />
    <Route exact path="/reset" component={Reset} />
    <Route exact path="/" component={Overview} />
    <Route exact path="/oauth/coinbase/:status/:scopeId" component={CoinbaseFeedback} />
    <Route path="/portfolio/:portfolio/:section?/:subsection?" component={PortfolioOverview} />
    <Route exact path="/coins/:coin_id" component={CoinOverview} />
    <Route exact path="/position-detail/" component={AssetDetail} />
    <Route exact path="/test" component={Overview} />
    <Route exact path="/indev" component={TrAdd} />
    <Route exact path="/transaction_detail" component={SmallTransactionDetail} />
    <Route exact path="/exchange_detail" component={ExchangeDetail} />
    <Route exact path="/new_ea" component={AddExchange} />
    <Route exact path="/new_w" component={AddStorage} />
    <Route exact path="/wallet_detail" component={StorageDetail} />
    <Route exact path="/settings/:setting" component={SettingsOverview} />
    <Route exact path="/settings" component={SettingsOverview} />
    <Route exact path="/invitations" component={InvitationOverview} />
    <Route exact path="/wallets/:route" component={WalletsOverview} />
    <Route exact path="/wallets" component={WalletsOverview} />
    <Route exact path="/notifications" component={NotificationsOverview} />
    <Route exact path="/watchlist" component={Watchlist} />
    <Route exact path="/reconcile" component={Reconcile} />
    <Route exact path="/support" component={SupportSSO} />
    <Route exact path="/register" component={RegisterGo} />
    <Route exact path="/start" component={RegisterGo} />
    {/* <Route component={NotFound} /> */}
  </Hoc>
);

export default BaseRouter;